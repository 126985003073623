import React, { Component } from "react";

import { connect } from "react-redux";

import home from "assets/svg/home.svg";
import FpoMenu from "assets/svg/FPOMenu.svg";
import communityMenu from "assets/svg/community_menu.svg";
import academyMenuGreen from "assets/svg/academy-menu-green.svg";
import nav_user from "assets/svg/user-icon.svg";
import academyMenu from "assets/svg/academyMenu.svg";
import homeGreen from "assets/svg/home_green.svg";
import FpoMenuGreen from "assets/svg/FPOGreen.svg";
import communityMenuGreen from "assets/svg/community_green.svg";

import "./bottomNav.scss";
import { history } from "service/helpers";

class BottomNavClass extends Component {
  render() {
    let { location } = this.props;

    return (
      <>
        <div className="bottom-nav">
          <div className="col-12 p-0 d-flex justify-content-around">
            <div className="select-menu">
              <div className="nav-icon w-100">
                <div className=" d-flex align-items-center cursor-pointer w-100">
                  <div
                    className="nav-content w-100 d-flex align-items-center flex-column justify-content-between"
                    onClick={() => {
                      history.push("/fpo/home");
                    }}
                  >
                    {location.pathname.includes("/fpo/home") ? (
                      <img src={homeGreen} alt="home" />
                    ) : (
                      <img src={home} alt="home" />
                    )}
                    <p className="nav-label mb-0">Home</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="select-menu">
              <div className="nav-icon w-100">
                <div className=" d-flex align-items-center cursor-pointer w-100">
                  <div
                    className="nav-content w-100 d-flex align-items-center flex-column justify-content-between"
                    onClick={() => {
                      history.push("/fpo/about-us");
                    }}
                  >
                    {location.pathname.includes("/fpo/about-us") ? (
                      <img src={academyMenuGreen} alt="academy" />
                    ) : (
                      <img src={academyMenu} alt="academy" />
                    )}
                    <p className="nav-label mb-0">Academy</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="select-menu">
              <div className="nav-icon w-100">
                <div className=" d-flex align-items-center cursor-pointer w-100">
                  <div className="nav-content w-100 d-flex align-items-center flex-column justify-content-between">
                    <img src={FpoMenu} alt="home" />
                    <p className="nav-label mb-0">FPO</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="select-menu">
              <div className="nav-icon w-100">
                <div className=" d-flex align-items-center cursor-pointer w-100">
                  <div
                    className="nav-content w-100 d-flex align-items-center flex-column justify-content-between"
                    onClick={() => {
                      history.push("/academy/home");
                    }}
                  >
                    {location.pathname.includes("/academy/home") ? (
                      <img src={communityMenuGreen} alt="community" />
                    ) : (
                      <img src={communityMenu} alt="community" />
                    )}
                    <p className="nav-label mb-0">Community</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="select-menu">
              <div className="nav-icon w-100">
                <div className=" d-flex align-items-center cursor-pointer w-100">
                  <div className="nav-content w-100 d-flex align-items-center flex-column justify-content-between">
                    <img
                      src={nav_user}
                      className="nav-user rounded-circle"
                      alt="profile"
                    />
                    <p className="nav-label mb-0">Profile</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({});

let component = BottomNavClass;

export const BottomNav = connect(mapStateToProps, null)(component);
