export const customStyles = {
  placeholder: (base) => ({
    ...base,
    fontSize: 16,
    lineHeight: 25,
    color: "#000",
    opacity: 0.6,
  }),
  indicatorSeparator: (base) => ({
    ...base,
    display: "none",
  }),
  dropdownIndicator: (base) => ({
    ...base,
    padding: 2,
    height: 16,
    width: 16,
    color: "black",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  }),
  singleValue: (base) => ({
    ...base,
    fontSize: 16,
  }),
  control: (base) => ({
    ...base,
    border: "1px solid #919191 !important",
    boxShadow: "0 !important",
    outline: 0,
    background: "#fff",
    height: 45,
    minHeight: 45,
  }),
  valueContainer: (base) => ({
    ...base,
    height: 40,
    padding: "0 6px 0px 10px",
  }),
  indicatorsContainer: (base) => ({
    ...base,
    paddingRight: 5,
  }),
};

export const FPOformFields = {
  entityId: "",
  entity_value: "",
  societyType: "",
  fpo_name: "",
  contact_first_name: "",
  contact_last_name: "",
  contact_number: "",
  emailId: "",
  password: "",
  confirm_password: "",
  district: "",
  pincode: "",
  passwordVisible: false,
  reEnterPasswordVisible: false,
  state: "",
  foundedOn: "",
  village: "",
  logoFileName: "",
  logoFile: "",
  turnOver: "",
  FyearId: "",
  teamLogoFileName: "",
  teamLogo: "",
  resourseInsitituteLogoFileName: "",
  resourseInsitituteLogoFile: "",
  islogoFileUploading: false,
  isTeamLogoFileuploading: false,
  isresourseInsitituteLogoFileUploading: false,
  isinstitutelogoFileUploading: false,
  insitituteLogoFileName: "",
  insitituteLogoFile: "",
  officialWebsite: "",
  facebookLink: "",
  instragamLink: "",
  twitterLink: "",
  acre: "",
  shareCapitals: "",
  femaleMembers: "",
  maleMembers: "",
  membership: "",
  villageCovered: "",
  selected_business_activties: [],
  selected_crops: [],
  cropMapping: [],
  selected_infrastructure: [],
  selected_license: [],
  resourseInstitution: "",
  promotingInstitution: "",
  isPrivacy: false,
};

export const RMFormFields = {
  rmName: "",
  rmID: "",
  rmMobile: "",
  rmEmail: "",
  rmMobileVerified: false,
  rmEmailVerified: false,
  rmPassword: "",
  rmConfirmPassword: "",
  rmPasswordVisible: false,
  rmConfirmPasswordVisible: false,
};

export const formFieldsunderFPO = {
  userRoleId: "",
  fpoId: "",
  firstName: "",
  lastName: "",
  mobileNumber: "",
  emailId: "",
  passwordForUserUnderFPO: "",
  confirmPasswordForUserUnderFPO: "",
  passwordVisibleUnderFPO: false,
  reEnterPasswordVisibleUnderFPO: false,
  fpoName: "",
};
