import React, { Component } from "react";
import { connect } from "react-redux";
import { getDescriptionData } from "service/helperFunctions";

export class PopularTopicsClass extends Component {
  render() {
    let { popularList = [] } = this.props;

    return (
      <div className="popular-topics">
        <div className="row">
          <div className="col-md-12">
            <h5 className="card-title mb-0">Popular topics</h5>
          </div>
        </div>
        <div className="popular-list">
          {popularList.map((list, index) => {
            let {
              categoryName = "",
              likesCount = 0,
              commentsCount = 0,
              title = "",
              forumId,
            } = list;
            return (
              <div
                className={`list-group-item border-bottom-forum`}
                key={index}
              >
                <div className="row">
                  <div className="col-11 ml-auto px-2">
                    <h5 className="fs-18 font-weight-bold text-gold-d0 mb-2">
                      {getDescriptionData(categoryName, 25)}
                    </h5>
                  </div>
                </div>
                <div
                  onClick={() => this.props.handleForumId(forumId)}
                  className="cursor-pointer"
                >
                  <div className="d-flex">
                    <div>
                      <span className="bullet-point float-left"></span>
                    </div>
                    <div className="w-100 word-wrap">
                      <p className="fs-16 line-height-24 text-black-50 mb-1">
                        {getDescriptionData(title, 50)}
                      </p>
                      <div className="d-flex justify-content-between">
                        <div className="d-flex">
                          <div className="d-flex align-items-center mr-4">
                            <i className="fs-16 icon-thumbs-up-color"></i>
                            <p className="mb-0 text-black fs-14 pl-1 fw-500">
                              {likesCount}
                            </p>
                          </div>
                          <div className="d-flex align-items-center">
                            <i className="fs-16 icon-comment"></i>
                            <p className="mb-0 text-black fs-14 pl-1 fw-500">
                              {commentsCount}
                            </p>
                          </div>
                        </div>
                        <div>
                          <p className="text-black fs-14 line-height-20 fw-500 mb-0">
                            Participants : <b>{commentsCount}</b>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export const PopularTopics = connect(null, null)(PopularTopicsClass);
