
import React, { Component } from 'react';
export class Slider extends Component {

    render() {
        let { pointerPosition = 0, handleSlider } = this.props
        return (
            <div className='slider-container w-100'>
                <div className='row w-100'>
                    <div className='col-10 mx-auto'>
                        <div className='slider'>
                            <div
                                className='rounded-circle pointer'
                                style={{ marginLeft: `${pointerPosition}%` }}
                            ></div>
                        </div>
                        <div className='mt-3'>
                            <div className='ml-2 slider-number'>
                                <div className='mr-25 cursor-pointer'
                                    onClick={() => handleSlider(1)}
                                >
                                    {'1'}
                                </div>
                                <div className='mr-25 cursor-pointer'
                                    onClick={() => handleSlider(7)}
                                >
                                    {'7'}
                                </div>
                                <div className='mr-25 cursor-pointer'
                                    onClick={() => handleSlider(14)}
                                >
                                    {'14'}
                                </div>
                                <div className='mr-25 cursor-pointer'
                                    onClick={() => handleSlider(21)}
                                >
                                    {'21'}
                                </div>
                                <div className='cursor-pointer text-right'
                                    onClick={() => handleSlider(30)}
                                >
                                    {"30"}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
