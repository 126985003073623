import React, { Component } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from "swiper";

import default_profile_image from "assets/images/default-profile-img.png";

import "./OurTeam.scss";

export class OurTeam extends Component {
  constructor() {
    super();
    SwiperCore.use([Navigation]);
  }
  render() {
    const params = {
      slidesPerView: 1,
      spaceBetween: 20,
      breakpoints: {
        600: {
          slidesPerView: 1,
        },
        750: {
          slidesPerView: 3,
        },
        1024: {
          slidesPerView: 4,
        },
      },
    };
    let { staffList = [], bodList = [] } = this.props;
    return (
      <>
        <section className="our-team-section">
          <div className="container-sa">
            <div className="row align-items-center">
              <div className="col-sm-12">
                <div className="our-team-description">
                  <h3>Our team</h3>
                  {/* <p>
                    We are team of {staffList.length + bodList.length} people ,
                    with {staffList.length} staffs and {bodList.length} Board of
                    Directors
                  </p> */}
                </div>
              </div>
              <div className="col-sm-12">
                <div className="bg-set">
                  <Swiper {...params}>
                    {staffList.map((list, index) => {
                      let {
                        firstName = "",
                        lastName = "",
                        profileImageUrl = "",
                      } = list;
                      return (
                        <SwiperSlide key={index}>
                          <div className="our-team-card mx-2">
                            <div className="img-thumb-profile">
                              <img
                                src={
                                  profileImageUrl !== null &&
                                  profileImageUrl !== ""
                                    ? profileImageUrl
                                    : default_profile_image
                                }
                                alt="img thumb"
                                className="border-radius-50 shadow"
                                height="113"
                                width="113"
                              />
                            </div>
                            <div className="team-details">
                              <p className="name">
                                {firstName} {lastName}
                              </p>
                              <p className="description">Staff</p>
                            </div>
                          </div>
                        </SwiperSlide>
                      );
                    })}
                    {bodList.map((list, index) => {
                      let {
                        firstName = "",
                        lastName = "",
                        profileImageUrl = "",
                      } = list;
                      return (
                        <SwiperSlide key={index}>
                          <div className="our-team-card mx-2">
                            <div className="img-thumb-profile">
                              <img
                                src={
                                  profileImageUrl !== null &&
                                  profileImageUrl !== ""
                                    ? profileImageUrl
                                    : default_profile_image
                                }
                                alt="img thumb"
                                className="border-radius-50 shadow"
                                height="113"
                                width="113"
                              />
                            </div>
                            <div className="team-details">
                              <p className="name">
                                {firstName} {lastName}
                              </p>
                              <p className="description">Board of Directors</p>
                            </div>
                          </div>
                        </SwiperSlide>
                      );
                    })}
                  </Swiper>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}
