import { combineReducers } from "redux";
import home from "./home";
import keyfinancial from "./keyfinancial";
import Compliance from "./Compliance";
import academy from "./academy";
import RoboCall from "./RoboCall";
import FigCrp from "./FigCrp";
import document from "./document";
import bms from "./bms";
import grading from "./grading";
import rm from "./rm";

export const reducers = combineReducers({
  home,
  keyfinancial,
  Compliance,
  academy,
  roboCall: RoboCall,
  FigCrp,
  document,
  bms,
  grading,
  rm,
});
