import { useEffect, useState } from "react";
import Wave from "wave-visualizer";
import { v4 as uuidv4 } from "uuid";
import { Toast } from "service/toast";
import { fileUpload } from "action/MemberAct";
import { useDispatch } from "react-redux";
const MicRecorder = require("mic-recorder-to-mp3");

const recorder = new MicRecorder({
  bitRate: 128,
});

export const useRecorder = () => {
  const wave = new Wave();

  const dispatch = useDispatch();

  const [isRecording, setIsRecording] = useState(false);

  const [isBlocked, setIsBlocked] = useState(false);

  const [key, setKey] = useState("");

  const [index, setIndex] = useState("");

  useEffect(() => {
    if ("mediaDevices" in navigator) {
      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then(() => {
          setIsBlocked(false);
        })
        .catch(() => {
          setIsBlocked(true);
        });
    } else {
      setIsBlocked(true);
    }
  }, []);

  const startRecording = (event, index) => {
    recorder
      .start()
      .then((data) => {
        wave.fromStream(data, "audio-wave", {
          type: "flower",
          colors: ["#189C5C"],
        });
        wave.visualize();
        setIsRecording(true);
      })
      .catch((e) => {
        console.log(e);
      });
    setKey(event);
    setIndex(index);
  };

  const stopRecording = () => {
    recorder
      .stop()
      .getMp3()
      .then(async ([buffer, blob]) => {
        setIsRecording(false);
        const fpoName = localStorage
          .getItem("userName")
          .toLocaleLowerCase()
          .replace(" ", "-");

        const file = new File(
          buffer,
          `${fpoName}-${new Date()
            .toLocaleDateString()
            .replaceAll(
              "/",
              "-"
            )}-${new Date().getHours()}-${new Date().getMinutes()}-${uuidv4()}.mp3`,
          {
            type: blob.type,
            lastModified: Date.now(),
          }
        );
        let formData = new FormData();
        formData.append("file", file);
        dispatch(fileUpload(formData)).then((response) => {
          let data =
            key === "mainMsg"
              ? {
                  url: response[0].fileUrl,
                  name: key,
                }
              : {
                  url: response[0].fileUrl,
                  name: key,
                  position: index,
                };
          const event = new CustomEvent("stopAudio", { detail: data });
          document.dispatchEvent(event);
          setKey("");
        });
      })
      .catch(() => {
        Toast({ type: "error", message: "We could not retrieve your message" });
      });
  };

  return { startRecording, stopRecording, isRecording, isBlocked };
};
