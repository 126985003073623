import React, { Component, Fragment } from "react";
import MemberCard from "../MemberCard";
import MemberStrip from "../MemberStrip/MemberStrip";
import {
  Pagination,
  CommonPageLoader,
  ConfirmationPopup,
} from "component/common";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { membersCardView, mapFigToMember, getMember } from "action/MemberAct";
import { getMembersCardApiRm } from "action/RmAct";
import "./memberlist.scss";

class MemberListComp extends Component {
  state = {
    memberlist: [],
    pageMeta: {},
    isLoading: false,
    totalMembers: 0,
    selectedId: null,
    figId: null,
    memberId: null,
  };

  //handle change
  handleChange = (val) => {
    let { limit, searchValue, membersCardView, filter, filterType, id } =
      this.props;
    this.setState({
      isLoading: false,
    });
    let payload = {
      limit: limit,
      page: val,
      memberName: searchValue,
      filter: filter,
      filterType: filterType,
    };
    if (id) {
      this.props
        .getMembersCardApiRm(id, payload)
        .then((data) => {
          this.setState({
            memberlist: data.results,
            pageMeta: data.pageMeta,
            totalMembers: data.total,
            isLoading: true,
          });
        })
        .catch(() => {
          this.setState({
            isLoading: true,
          });
        });
    } else {
      membersCardView(payload)
        .then((data) => {
          this.setState({
            memberlist: data.results,
            pageMeta: data.pageMeta,
            totalMembers: data.total,
            isLoading: true,
          });
        })
        .catch(() => {
          this.setState({
            isLoading: true,
          });
        });
    }
  };

  //Lifecycles
  componentDidMount() {
    let { limit, searchValue, membersCardView, filter, filterType, id } =
      this.props;
    let payload = {
      limit: limit,
      page: 1,
      memberName: searchValue,
      filter: filter,
      filterType: filterType,
    };

    id
      ? this.props.getMembersCardApiRm(id, payload).then((data) => {
          this.setState({
            memberlist: data.results,
            pageMeta: data.pageMeta,
            totalMembers: data.total,
            isLoading: true,
          });
        })
      : membersCardView(payload).then((data) => {
          this.setState({
            memberlist: data.results,
            pageMeta: data.pageMeta,
            totalMembers: data.total,
            isLoading: true,
          });
        });
  }

  componentDidUpdate(prevProps) {
    const { isMapping, searchPage } = this.props;
    if (this.props.activeTab == 1 || isMapping) {
      const currentPage = searchPage ? searchPage : 1;
      this.props.limit !== prevProps.limit && this.handleChange(1);
      this.props.searchValue !== prevProps.searchValue && this.handleChange(1);
      this.props.searchPage !== prevProps.searchPage &&
        this.handleChange(currentPage);
    }
  }

  handleSelect = ({ target: { value } }, id) => {
    let { mapFigToMember } = this.props;
    let body = {
      memberId: id,
      figId: value,
    };
    mapFigToMember(body);
  };

  handleKYC = () => {
    const { memberId } = this.state;
    const { getMember } = this.props;
    let token = localStorage.getItem("AuthToken");
    getMember({ id: memberId }).then((data) => {
      window.open(
        `https://kisanpay.azurewebsites.net/?mobile=${data.phone}&fpoid=${data.fpoId}&token=${token}`
      );
    });
  };

  toggleKYC = (id) => {
    this.setState({
      memberId: id,
    });
  };

  render() {
    let {
      pageMeta,
      memberlist,
      isLoading,
      totalMembers,
      selectedId,
      memberId,
    } = this.state;

    let {
      viewAccess = false,
      editAccess = false,
      id,
      isMapping = false,
      figOptions,
      view,
    } = this.props;
    return (
      <Fragment>
        {isLoading ? (
          <Fragment>
            {view === "1" ? (
              <div className={view === "1" ? "scroll-x" : ""}>
                <div style={{ minWidth: "1200px" }}>
                  {memberlist.length != 0 ? (
                    <div className="d-flex container-sa flex-column flex-wrap mt-3 px-0 justify-content-center">
                      {isMapping && (
                        <div className="table_head">
                          <div className="row">
                            <div className="col-3">
                              <b>Name</b>
                            </div>
                            <div className="col-3">
                              <b>Mobile</b>
                            </div>
                            <div className="col-2">
                              <b>Village</b>
                            </div>
                            <div className="col-3">
                              <b>FIG Mapping</b>
                            </div>
                          </div>
                        </div>
                      )}
                      <MemberStrip
                        payload={memberlist}
                        viewAccess={viewAccess}
                        editAccess={editAccess}
                        id={id}
                        isMapping={isMapping}
                        onSelect={this.handleSelect}
                        figOptions={figOptions}
                        toggleKYC={this.toggleKYC}
                      />
                    </div>
                  ) : (
                    <div className="col-12">
                      <p className="py-5 mb-0 text-black-50 fs-22 line-height-25 text-center">
                        No data found
                      </p>
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <div className="row">
                {memberlist.length > 0 ? (
                  memberlist.map((data, index) => {
                    return (
                      <div
                        key={index}
                        className={`col-md-4 col-lg-3  ${
                          index % 4 === 3 ? "pr-md-0" : ""
                        } mb-2 mt-3 px-2 ${index % 4 === 0 ? "pl-md-0" : ""}`}
                      >
                        <MemberCard
                          payload={data}
                          memberDetail={data}
                          isMember={true}
                          viewAccess={viewAccess}
                          editAccess={editAccess}
                          isMapping={isMapping}
                          toggleKYC={this.toggleKYC}
                        />
                      </div>
                    );
                  })
                ) : (
                  <div className="col-12">
                    <p className="py-5 mb-0 text-black-50 fs-22 line-height-25 text-center">
                      No data found
                    </p>
                  </div>
                )}
              </div>
            )}
            <div className="my-0 w-100">
              {pageMeta.pageCount !== undefined && pageMeta.pageCount !== 1 && (
                <Pagination
                  isTotalEntriesNeeded={true}
                  totalMembers={totalMembers}
                  handleChange={this.handleChange}
                  pageMeta={pageMeta}
                />
              )}
            </div>
          </Fragment>
        ) : (
          <CommonPageLoader />
        )}
        <ConfirmationPopup
          isOpen={memberId !== null ? true : false}
          toggle={() => this.toggleKYC(null)}
          message={
            "Are you sure you wish to start Digital KYC for this member? This will trigger an OTP to their Mobile number."
          }
          onSubmit={this.handleKYC}
        />
      </Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      membersCardView,
      getMembersCardApiRm,
      mapFigToMember,
      getMember,
    },
    dispatch
  );
};
let component = MemberListComp;

export const MemberList = connect(null, mapDispatchToProps)(component);
