import React, { Component } from "react";
import { getGstById } from "action/ComplianceAct";

import { CommonPageLoader } from "component/common";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { history } from "service/helpers/index";

import { getFilePathDownload } from "service/api";

import moment from "moment";

import "../Licenseview/style.scss";

const arrow_left = require("assets/svg/add_member/arrow_left.svg");

export class GSTviewClass extends Component {
  constructor(props) {
    super();
    this.state = {
      gstDetails: {},
      isloading: false,
    };
  }
  componentDidMount() {
    let { id } = this.props;
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    this._getData(id);
  }

  _getData = (id) => {
    let { getGstById } = this.props;

    this.setState({ isloading: true });

    getGstById(id).then((data) => {
      this.setState({
        gstDetails: data,
        isloading: false,
      });
    });
  };

  render() {
    let { gstDetails = {}, isloading } = this.state;

    let {
      OriginalLicense,
      districtId,
      fpoId,
      licenseImgFileName,
      licenseImgUrl,
      licenseNumber,
      remarks,
      pincodeId,
      stateId,
      storeAddress,
      village,
      validityDate,
      registrationDate,
    } = gstDetails;

    return (
      <div className="view-bod">
        <div className="d-flex my-3 container-sa px-0">
          <div
            className="d-flex"
            onClick={() => history.push("/fpo/compliance")}
          >
            <img className="cursor-pointer" src={arrow_left} alt="back" />
            <p className="fs-18 font-weight-bold font-Gilroy text-black mb-0 cursor-pointer ml-3">
              {"Back"}
            </p>
          </div>
        </div>
        {!isloading ? (
          <div className="bg-area py-4">
            <div className="container">
              <div className="bg-white p-5 shadow mx-5">
                <div className="row">
                  <div className="col-6">
                    <div className="row">
                      <div className="col-5">
                        <p className="text-black font-weight-bold fs-16">
                          GSTN :
                        </p>
                      </div>
                      <div className="col-7">
                        <p className="text-black-50 fs-16">
                          {licenseNumber ? licenseNumber : "-"}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-5">
                        <p className="text-black font-weight-bold fs-16">
                          GST Registration &nbsp; Date :
                        </p>
                      </div>
                      <div className="col-7">
                        <p className="text-black-50 fs-16">
                          {registrationDate
                            ? moment(registrationDate).format("DD MMMM YYYY")
                            : "-"}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-5">
                        <p className="text-black font-weight-bold fs-16">
                          Expiry Date :
                        </p>
                      </div>
                      <div className="col-7">
                        <p className="text-black-50 fs-16">
                          {validityDate
                            ? moment(validityDate).format("DD MMMM YYYY")
                            : "-"}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-5">
                        <p className="text-black font-weight-bold fs-16">
                          Store Address :
                        </p>
                      </div>
                      <div className="col-7">
                        <p className="text-black-50 fs-16">
                          {storeAddress ? storeAddress : "-"}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-5">
                        <p className="text-black font-weight-bold fs-16">
                          District :
                        </p>
                      </div>
                      <div className="col-7">
                        <p className="text-black-50 fs-16">
                          {districtId ? districtId : "-"}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-5">
                        <p className="text-black font-weight-bold fs-16">
                          GST Registration certificate :
                        </p>
                      </div>
                      <div className="col-7">
                        <p className="text-black-50 fs-16">
                          {licenseImgFileName ? (
                            <a
                              className="fs-16 text-green-18 text-underline cursor-pointer"
                              href={getFilePathDownload + licenseImgUrl}
                              download
                            >
                              {"Download"}
                            </a>
                          ) : (
                            <p className="text-black-50 fs-16">{"-"}</p>
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="row">
                      <div className="col-5">
                        <p className="text-black font-weight-bold fs-16">
                          Original Licence :
                        </p>
                      </div>
                      <div className="col-7">
                        {OriginalLicense ? OriginalLicense : "-"}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-5">
                        <p className="text-black font-weight-bold fs-16">
                          Remarks :
                        </p>
                      </div>
                      <div className="col-7">
                        <p className="text-black-50 fs-16">
                          {remarks ? remarks : "-"}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-5">
                        <p className="text-black font-weight-bold fs-16">
                          Village :
                        </p>
                      </div>
                      <div className="col-7">
                        <p className="text-black-50 fs-16">
                          {village ? village : "-"}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-5">
                        <p className="text-black font-weight-bold fs-16">
                          State :
                        </p>
                      </div>
                      <div className="col-7">
                        <p className="text-black-50 fs-16">
                          {stateId ? stateId : "-"}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-5">
                        <p className="text-black font-weight-bold fs-16">
                          Pincode
                        </p>
                      </div>
                      <div className="col-7">
                        <p className="text-black-50 fs-16">
                          {pincodeId ? pincodeId : "-"}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <>
            <CommonPageLoader />
          </>
        )}
        <div className="view-members"></div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getGstById,
    },
    dispatch
  );
};
let component = GSTviewClass;

export const GSTView = connect(null, mapDispatchToProps)(component);
