import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  addGstComp,
  getAllGstComp,
  getGstCompById,
  updateGstComp,
  deleteGstComp,
  getAllFinancialYearDropdown,
  getAllMasters,
  complianceCurrentTab,
} from "action/ComplianceAct";
import { fileUpload } from "action/MemberAct";
import moment from "moment";

import { history } from "service/helpers";
import { Toast } from "service/toast";
import {
  NormalInput,
  NormalButton,
  NormalDate,
  NormalSelect,
  CommonPageLoader,
  ConfirmationPopup,
} from "component/common";
import SimpleReactValidator from "simple-react-validator";
import trash from "assets/svg/Associations/trash.svg";
import { getAccessdata } from "service/helperFunctions";
const arrow_left = require("assets/svg/add_member/arrow_left.svg");

export class AddGstCompClass extends Component {
  state = {
    financialYear: "",
    returnPeriod: "",
    gstNumber: "",
    dueDate: "",
    actualDate: "",
    dateOfFiling: "",
    salesIncome: "",
    consultantName: "",
    igstPayable: "",
    igstPaid: "",
    cgstPayable: "",
    cgstPaid: "",
    sgstPayable: "",
    sgstPaid: "",
    filingStatusFileName: "",
    filingStatusFileUrl: null,
    isImageUploading: false,
    remarks: "",
    allFinance: [],
    allYear: [],
    allQuater: [],
    allMonth: [],
    returnPeriodQuarterId: "",
    returnPeriodMonthId: "",
    isLoading: false,
    isDelete: false,
  };
  //Validation
  validator = new SimpleReactValidator({
    validators: {
      integer: {
        message: "Only non-negative absolute values are allowed",
        rule: (val, params, validator) => {
          return validator.helpers.testRegex(val, /^\s*([0-9])*$/);
        },
        messageReplace: (message, params) =>
          message.replace(":values", this.helpers.toSentence(params)),
      },
    },
    element: (message) => (
      <span className="error-message text-danger fs-14">{message}</span>
    ),
    autoForceUpdate: this,
  });

  handleInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
    if (e.target.name === "returnPeriod") {
      if (e.target.value === "2") {
        this.setState({
          returnPeriodQuarterId: "1",
          returnPeriodMonthId: "",
        });
      } else if (e.target.value === "3") {
        this.setState({
          returnPeriodMonthId: "1",
          returnPeriodQuarterId: "",
        });
      }
    }
  };
  handleSubmit = () => {
    this.setState({ isLoading: true });
    if (this.validator.allValid()) {
      const {
        financialYear,
        returnPeriod,
        gstNumber,
        dueDate,
        actualDate,
        dateOfFiling,
        salesIncome,
        consultantName,
        igstPayable,
        igstPaid,
        cgstPayable,
        cgstPaid,
        sgstPayable,
        sgstPaid,
        filingStatusFileName,
        filingStatusFileUrl,
        isImageUploading,
        remarks,
        allFinance,
        returnPeriodMonthId,
        returnPeriodQuarterId,
        allYear,
        allMonth,
        allQuater,
      } = this.state;
      if (!isImageUploading) {
        let body = {
          id: this.props.id,
          fymId: parseInt(financialYear),
          fymYear: "",
        };
        if (remarks !== "") {
          body.remarks = remarks;
        }
        if (gstNumber !== "") {
          body.gsstNumber = gstNumber;
        }
        if (returnPeriod !== "") {
          body.returnPeriodYearId = parseInt(returnPeriod);
          let dataYear = allYear.filter((x) => x.value == returnPeriod);
          if (dataYear.length > 0) {
            body.returnPeriodYear = dataYear[0].label;
          }
        }
        if (igstPayable !== "") {
          body.igstPayable = igstPayable;
        }
        if (igstPaid !== "") {
          body.igstPaid = igstPaid;
        }
        if (cgstPayable !== "") {
          body.cgstPayable = cgstPayable;
        }
        if (cgstPaid !== "") {
          body.cgstPaid = cgstPaid;
        }
        if (sgstPayable !== "") {
          body.sgstPayable = sgstPayable;
        }
        if (sgstPaid !== "") {
          body.sgstPaid = sgstPaid;
        }
        if (dueDate !== "") {
          body.dueDate = moment(dueDate).format("YYYY-MM-DD");
        }
        // if (actualDate !== "") {
        //   body.actualDate = moment(actualDate).format("YYYY-MM-DD");
        // }
        if (dateOfFiling !== "") {
          body.dateOfFiling = moment(dateOfFiling).format("YYYY-MM-DD");
        }
        if (consultantName !== "") {
          body.consultantName = consultantName;
        }
        if (filingStatusFileName !== "") {
          body.fillingStatusFileName = filingStatusFileName;
          body.fillingStatusFileUrl = filingStatusFileUrl;
        }
        if (salesIncome !== "") {
          body.salesIncome = salesIncome;
        }

        let dataSet = allFinance.filter((x) => x.value == financialYear);
        if (dataSet.length > 0) {
          body.fymYear = dataSet[0].label;
        }

        if (returnPeriodQuarterId !== "") {
          let dataSet = allQuater.filter(
            (x) => x.value == returnPeriodQuarterId
          );
          if (dataSet.length > 0) {
            body.returnPeriodQuarterId = returnPeriodQuarterId;
            body.returnPeriodQuarter = dataSet[0].label;
          }
        }
        if (returnPeriodMonthId !== "") {
          let dataSet = allMonth.filter((x) => x.value == returnPeriodMonthId);
          if (dataSet.length > 0) {
            body.returnPeriodMonthId = returnPeriodMonthId;
            body.returnPeriodMonth = dataSet[0].label;
          }
        }

        this.props.id
          ? this.props
              .updateGstComp(body)
              .then(() => {
                this.validator.hideMessages();
                this.handleBack();
              })
              .catch(() => {
                this.setState({ isLoading: false });
              })
          : this.props
              .addGstComp(body)
              .then(() => {
                this.validator.hideMessages();
                this.setState({
                  financialYear: "",
                  returnPeriod: "",
                  gstNumber: "",
                  dueDate: "",
                  actualDate: "",
                  dateOfFiling: "",
                  salesIncome: "",
                  consultantName: "",
                  igstPayable: "",
                  cgstPayable: "",
                  cgstPaid: "",
                  igstPaid: "",
                  sgstPayable: "",
                  sgstPaid: "",
                  filingStatusFileName: "",
                  filingStatusFileUrl: null,
                  isImageUploading: false,
                  remarks: "",
                  isLoading: false,
                });
                this.handleBack();
              })
              .catch(() => {
                this.setState({ isLoading: false });
              });
      } else {
        this.setState({ isLoading: false });
        Toast({ type: "error", message: "Please wait Image is uploading" });
      }
    } else {
      this.setState({ isLoading: false });
      this.validator.showMessages();
      this.forceUpdate();
    }
  };
  handleDelete = () => {
    this.setState({ isLoading: true });
    this.props.deleteGstComp(this.props.id).then(() => {
      this.handleBack();
    });
  };
  handleFile = (e) => {
    let file = e.target.files[0];
    if (file !== undefined) {
      this.setState({ filingStatusFileUrl: null }, () => {
        this.setState({
          isImageUploading: true,
          filingStatusFileName: "Uploading ....",
          filingStatusFileUrl: file,
        });
        const formData = new FormData();
        formData.append("file", file);

        this.props
          .fileUpload(formData)
          .then((data) => {
            document.getElementById("handleFile").value = null;
            this.setState({
              filingStatusFileUrl: data[0].fileUrl,
              filingStatusFileName: data[0].fileName,
              isImageUploading: false,
            });
          })
          .catch(() => {
            this.setState({
              filingStatusFileUrl: null,
              filingStatusFileName: "",
              isImageUploading: false,
            });
          });
      });
    }
  };

  componentDidMount() {
    this.props.getAllFinancialYearDropdown().then((data) => {
      let dataSet = [];

      data.financialYearMaster.map((list) => {
        dataSet.push({ value: list.fymId, label: list.fymYear });
      });
      this.setState({
        allFinance: dataSet,
      });
    });
    this.props.getAllMasters().then((data) => {
      let dataYear = [];

      data.returnPeriodYear.map((list) => {
        dataYear.push({
          value: list.returnPeriodId,
          label: list.returnPeriodName,
        });
      });
      let dataMonth = [];

      data.returnPeriodMonth.map((list) => {
        dataMonth.push({
          value: list.returnPeriodMonthId,
          label: list.returnPeriodMonthName,
        });
      });
      let dataQuarter = [];

      data.returnPeriodQuarter.map((list) => {
        dataQuarter.push({
          value: list.returnPeriodQuarterId,
          label: list.returnPeriodQuarterName,
        });
      });
      this.setState(
        {
          allYear: dataYear,
          allMonth: dataMonth,
          allQuater: dataQuarter,
        },
        () => {
          if (this.props.id) {
            this.setState({ isLoading: true });
            this.props.getGstCompById(this.props.id).then((data) => {
              let {
                fymId = "",
                returnPeriodYear = "",
                gsstNumber = "",
                dueDate = "",
                actualDate = "",
                salesIncome = "",
                consultantName = "",
                igstPayable = "",
                igstPaid = "",
                sgstPayable = "",
                sgstPaid = "",
                cgstPayable = "",
                cgstPaid = "",
                fillingStatusFileName = "",
                fillingStatusFileUrl = null,
                remarks = "",
                returnPeriodQuarter = "",
                returnPeriodMonth = "",
                dateOfFiling = "",
              } = data;
              this.setState({
                isLoading: false,
                financialYear: fymId ? fymId : "",
                returnPeriod: this.getReturnPeriod(returnPeriodYear),
                gstNumber: gsstNumber ? gsstNumber : "",
                dueDate: dueDate ? new Date(dueDate) : "",
                actualDate: actualDate ? new Date(actualDate) : "",
                dateOfFiling: dateOfFiling ? new Date(dateOfFiling) : "",
                salesIncome: salesIncome ? salesIncome.toString() : "",
                consultantName: consultantName ? consultantName : "",
                igstPayable: igstPayable ? igstPayable.toString() : "",
                igstPaid: igstPaid ? igstPaid.toString() : "",
                sgstPayable: sgstPayable ? sgstPayable.toString() : "",
                sgstPaid: sgstPaid ? sgstPaid.toString() : "",
                cgstPayable: cgstPayable ? cgstPayable.toString() : "",
                cgstPaid: cgstPaid ? cgstPaid.toString() : "",
                filingStatusFileName: fillingStatusFileName
                  ? fillingStatusFileName
                  : "",
                filingStatusFileUrl: fillingStatusFileUrl
                  ? fillingStatusFileUrl
                  : null,
                remarks: remarks ? remarks : "",
                returnPeriodQuarterId:
                  this.getReturnPeriodQuater(returnPeriodQuarter),
                returnPeriodMonthId:
                  this.getReturnPeriodMonth(returnPeriodMonth),
              });
            });
          }
        }
      );
    });
  }

  getReturnPeriod = (type) => {
    const { allYear } = this.state;
    let data = allYear.find((list) => {
      return type == list.label;
    });
    return data ? data.value : "";
  };

  getReturnPeriodQuater = (type) => {
    const { allQuater } = this.state;
    let data = allQuater.find((list) => {
      return type == list.label;
    });
    return data ? data.value : "";
  };

  getReturnPeriodMonth = (type) => {
    const { allMonth } = this.state;
    let data = allMonth.find((list) => {
      return type == list.label;
    });
    return data ? data.value : "";
  };

  handleRemoveImage = () => {
    this.setState({
      filingStatusFileUrl: null,
      filingStatusFileName: "",
    });
  };

  handleBack = () => {
    this.props.complianceCurrentTab(5);
    history.push("/fpo/compliance");
  };

  toggleDelete = () => {
    let { isDelete } = this.state;
    this.setState({
      isDelete: !isDelete,
    });
  };

  render() {
    const {
      financialYear,
      returnPeriod,
      gstNumber,
      dueDate,
      actualDate,
      dateOfFiling,
      salesIncome,
      consultantName,
      igstPayable,
      igstPaid,
      cgstPayable,
      cgstPaid,
      sgstPayable,
      sgstPaid,
      remarks,
      filingStatusFileName,
      filingStatusFileUrl,
      allFinance,
      allYear,
      allQuater,
      returnPeriodQuarterId,
      allMonth,
      returnPeriodMonthId,
      isLoading,
      isImageUploading,
      isDelete,
    } = this.state;

    let { accessData } = this.props;
    let permissionData = getAccessdata(accessData, "complainceGovernance");

    let { deleteAccess = false } = permissionData;
    return (
      <div>
        <div className="d-flex container-sa my-3 ">
          <div
            onClick={() => this.handleBack()}
            className="d-flex cursor-pointer"
          >
            <img className="cursor-pointer" src={arrow_left} alt="back" />
            <p className="ml-3 add-member-text-back mb-0">
              {this.props.id ? "Edit" : "Add"} GST Return Compliance
            </p>
          </div>
        </div>
        {!isLoading ? (
          <div className="add-form">
            <div className="container bg-white py-4">
              <div className="row">
                <div className="col-12 select-change">
                  <NormalSelect
                    isFullWidthForCompliance={true}
                    label={"Financial year"}
                    placeholder="Select"
                    value={financialYear}
                    arrow={true}
                    name="financialYear"
                    options={allFinance}
                    handleChange={this.handleInput}
                    required={true}
                  />
                  <div className="row select-change">
                    <div className="col-10 ml-auto">
                      {this.validator.message(
                        "financialYear",
                        financialYear,
                        "required"
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <NormalSelect
                    isHalfWidthForMemberProfile={true}
                    label={"Return period"}
                    placeholder="Select"
                    value={returnPeriod}
                    arrow={true}
                    name="returnPeriod"
                    options={allYear}
                    handleChange={this.handleInput}
                    required={true}
                  />
                  <div className="row select-change">
                    <div className="col-8 ml-auto">
                      {this.validator.message(
                        "Return Period",
                        returnPeriod,
                        "required"
                      )}
                    </div>
                  </div>
                </div>
                {returnPeriod == "2" && (
                  <div className="col-6">
                    <NormalSelect
                      isHalfWidthForMemberProfile={true}
                      label={"Return Period Quarter"}
                      placeholder="Select"
                      value={returnPeriodQuarterId}
                      arrow={true}
                      name="returnPeriodQuarterId"
                      options={allQuater}
                      handleChange={this.handleInput}
                    />
                  </div>
                )}
                {returnPeriod == "3" && (
                  <div className="col-6">
                    <NormalSelect
                      isHalfWidthForMemberProfile={true}
                      label={"Return Period Month"}
                      placeholder="Select"
                      value={returnPeriodMonthId}
                      arrow={true}
                      name="returnPeriodMonthId"
                      options={allMonth}
                      handleChange={this.handleInput}
                    />
                  </div>
                )}
              </div>
              <div className="row">
                <div className="col-6">
                  <NormalInput
                    label="GST number"
                    type="text"
                    onChange={this.handleInput}
                    value={gstNumber}
                    name="gstNumber"
                    isHalfWidthForMemberProfile={true}
                    required={true}
                  />
                  <div className="row select-change">
                    <div className="col-8 ml-auto">
                      {this.validator.message(
                        "Gst number",
                        gstNumber,
                        "required|max:20"
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <NormalDate
                    isIcon={true}
                    isHalfWidthForMemberProfile={true}
                    value={dueDate}
                    name="dueDate"
                    placeholder="DD/MM/YYYY"
                    onChange={this.handleInput}
                    mindate={false}
                    maxdate={false}
                    timeInput={false}
                    label={"Due date"}
                    required={true}
                  />
                  <div className="row select-change">
                    <div className="col-8 ml-auto">
                      {this.validator.message("Due date", dueDate, "required")}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                {/* <div className="col-6">
                  <NormalDate
                    isIcon={true}
                    isHalfWidthForMemberProfile={true}
                    value={actualDate}
                    name="actualDate"
                    placeholder="DD/MM/YYYY"
                    onChange={this.handleInput}
                    mindate={false}
                    maxdate={false}
                    timeInput={false}
                    label={"Actual date"}
                  />
                </div> */}
                <div className="col-6">
                  <NormalDate
                    isIcon={true}
                    isHalfWidthForMemberProfile={true}
                    value={dateOfFiling}
                    name="dateOfFiling"
                    placeholder="DD/MM/YYYY"
                    onChange={this.handleInput}
                    mindate={false}
                    maxdate={false}
                    timeInput={false}
                    label={"Date of filing"}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <NormalInput
                    label="Sales or Income"
                    type="number"
                    onChange={this.handleInput}
                    value={salesIncome}
                    name="salesIncome"
                    isHalfWidthForMemberProfile={true}
                    isRupees={true}
                    required={true}
                  />
                  <div className="row select-change">
                    <div className="col-8 ml-auto">
                      {this.validator.message(
                        "sales Income",
                        salesIncome,
                        "required|max:10"
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <NormalInput
                    label="Consultant name"
                    type="text"
                    onChange={this.handleInput}
                    value={consultantName}
                    name="consultantName"
                    isHalfWidthForMemberProfile={true}
                  />
                  <div className="row select-change">
                    <div className="col-8 ml-auto">
                      {this.validator.message(
                        "consultant Name",
                        consultantName,
                        "max:30|alpha_space"
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <NormalInput
                    label="IGST payable"
                    type="number"
                    onChange={this.handleInput}
                    value={igstPayable}
                    name="igstPayable"
                    isHalfWidthForMemberProfile={true}
                    isRupees={true}
                  />
                  <div className="row select-change">
                    <div className="col-8 ml-auto">
                      {this.validator.message(
                        "igst Payable",
                        igstPayable,
                        "max:10"
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <NormalInput
                    label="IGST paid"
                    type="number"
                    onChange={this.handleInput}
                    value={igstPaid}
                    name="igstPaid"
                    isHalfWidthForMemberProfile={true}
                    isRupees={true}
                  />
                  <div className="row select-change">
                    <div className="col-8 ml-auto">
                      {this.validator.message("igst Paid", igstPaid, "max:10")}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <NormalInput
                    label="CGST payable"
                    type="number"
                    onChange={this.handleInput}
                    value={cgstPayable}
                    name="cgstPayable"
                    isHalfWidthForMemberProfile={true}
                    isRupees={true}
                  />
                  <div className="row select-change">
                    <div className="col-8 ml-auto">
                      {this.validator.message(
                        "cgst Payable",
                        cgstPayable,
                        "max:10"
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <NormalInput
                    label="CGST paid"
                    type="number"
                    onChange={this.handleInput}
                    value={cgstPaid}
                    name="cgstPaid"
                    isHalfWidthForMemberProfile={true}
                    isRupees={true}
                  />
                  <div className="row select-change">
                    <div className="col-8 ml-auto">
                      {this.validator.message("cgst Paid", cgstPaid, "max:10")}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <NormalInput
                    label="SGST payable"
                    type="number"
                    onChange={this.handleInput}
                    value={sgstPayable}
                    name="sgstPayable"
                    isHalfWidthForMemberProfile={true}
                    isRupees={true}
                  />
                  <div className="row select-change">
                    <div className="col-8 ml-auto">
                      {this.validator.message(
                        "sgst Payable",
                        sgstPayable,
                        "max:10"
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <NormalInput
                    label="SGST paid"
                    type="number"
                    onChange={this.handleInput}
                    value={sgstPaid}
                    name="sgstPaid"
                    isHalfWidthForMemberProfile={true}
                    isRupees={true}
                  />
                  <div className="row select-change">
                    <div className="col-8 ml-auto">
                      {this.validator.message("sgst Paid", sgstPaid, "max:10")}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <div className="row upload-area">
                    <div className="col-4">
                      <div className="fs-16 text-black-32 font-weight-bold line-height-19">
                        Upload GST Return File
                      </div>
                    </div>
                    <div className="col-8">
                      <div className="d-flex align-items-center btn-file-input">
                        <button className="">
                          <input
                            type="file"
                            className=""
                            accept=".jpg, .png, .jpeg"
                            onChange={this.handleFile}
                            id="handleFile"
                            title=""
                          ></input>
                          {filingStatusFileUrl == null ? (
                            "Choose file"
                          ) : (
                            <>
                              {filingStatusFileName.length == 10
                                ? filingStatusFileName
                                : filingStatusFileName.substring(0, 9)}
                              ...
                            </>
                          )}
                        </button>
                        {filingStatusFileName != "" && !isImageUploading && (
                          <div className="pl-3 position-relative">
                            <img
                              src={filingStatusFileUrl}
                              alt={filingStatusFileName}
                              height="75"
                              width="75"
                            />
                            <i
                              className="icon-Delete text-danger fs-14 pl-2 cursor-pointer position-absolute"
                              onClick={() => this.handleRemoveImage()}
                            ></i>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <NormalInput
                    label="Remarks"
                    type="text"
                    onChange={this.handleInput}
                    value={remarks}
                    name="remarks"
                    isHalfWidthForMemberProfile={true}
                  />
                  <div className="row select-change">
                    <div className="col-8 ml-auto">
                      {this.validator.message(
                        "remarks",
                        remarks,
                        "max:75|alpha_space"
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="pt-5 pb-3 d-flex">
              <div className="mx-auto d-flex">
                {this.props.id && deleteAccess && (
                  <div className="cursor-pointer d-flex align-items-center mr-5">
                    <div
                      className="text-center fs-14 fw-600 text-danger d-flex"
                      onClick={this.toggleDelete}
                    >
                      <img className="mr-2" src={trash} alt="icon" />
                      Delete
                    </div>
                  </div>
                )}
                <NormalButton
                  label={`${this.props.id ? "Update" : "Submit & next"}`}
                  mainbg={true}
                  className="px-3 py-2 fs-14 line-height-26"
                  onClick={this.handleSubmit}
                />
              </div>
            </div>
          </div>
        ) : (
          <>
            {/* Loader */}
            <CommonPageLoader />
          </>
        )}
        <ConfirmationPopup
          isOpen={isDelete}
          toggle={this.toggleDelete}
          onSubmit={this.handleDelete}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  accessData: state.home.accessData,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      addGstComp,
      getAllGstComp,
      getAllFinancialYearDropdown,
      updateGstComp,
      fileUpload,
      getGstCompById,
      deleteGstComp,
      getAllMasters,
      complianceCurrentTab,
    },
    dispatch
  );
};
let component = AddGstCompClass;

export const AddGstComp = connect(
  mapStateToProps,
  mapDispatchToProps
)(component);
