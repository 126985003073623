import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  addBankDetail,
  updateBankDetail,
  getBankDetailById,
  deleteBankDetail,
  keyFinancialCurrentTab,
} from "action/KeyFinancialAct";
import SimpleReactValidator from "simple-react-validator";
import { history } from "service/helpers/index";
import {
  NormalInput,
  NormalButton,
  CommonPageLoader,
  NormalSelect,
  ConfirmationPopup,
} from "component/common";
import trash from "assets/svg/Associations/trash.svg";
import { getAccessdata } from "service/helperFunctions";
const arrow_left = require("assets/svg/add_member/arrow_left.svg");

class BankDetailsClass extends Component {
  constructor(props) {
    super();
    this.state = {
      bankName: "",
      accountNumber: "",
      accountType: "",
      ifscCode: "",
      others: "",
      beneficiaryName: "",
      isDelete: false,
      typeOptions: [
        {
          value: "Current Account",
          label: "Current Account",
        },
        {
          value: "Savings Account",
          label: "Savings Account",
        },
        {
          value: "Cash credit",
          label: "Cash credit",
        },
        {
          value: "Overdraft",
          label: "Overdraft",
        },
        {
          value: "Others",
          label: "Others",
        },
      ],
      isLoading: false,
    };
  }

  validator = new SimpleReactValidator({
    element: (message) => (
      <span className="error-message text-danger fs-14">{message}</span>
    ),
    autoForceUpdate: this,
  });

  handleInput = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  handleDelete = () => {
    this.setState({ isLoading: true });
    this.props.deleteBankDetail(this.props.id).then((data) => {
      this.handleBack();
    });
  };

  toggleDelete = () => {
    let { isDelete } = this.state;
    this.setState({
      isDelete: !isDelete,
    });
  };

  handleSubmit = () => {
    this.setState({ isLoading: true });
    const {
      bankName,
      accountNumber,
      accountType,
      ifscCode,
      beneficiaryName,
      others,
    } = this.state;
    if (this.validator.allValid()) {
      let body = {
        bankName: bankName,
      };
      if (accountNumber !== "") {
        body.beneficiaryBankAccountNumber = accountNumber.toString();
      }
      if (accountType !== "") {
        body.bankAccountType = accountType == "Others" ? others : accountType;
      }
      if (ifscCode !== "") {
        body.IFSCCode = ifscCode;
      }
      if (beneficiaryName !== "") {
        body.beneficiaryName = beneficiaryName;
      }
      this.props.id
        ? this.props
            .updateBankDetail(this.props.id, body)
            .then(() => {
              this.validator.hideMessages();
              this.handleBack();
              this.setState({ isLoading: false });
            })
            .catch(() => {
              this.setState({ isLoading: false });
            })
        : this.props
            .addBankDetail(body)
            .then(() => {
              this.validator.hideMessages();
              this.setState({
                bankName: "",
                accountNumber: "",
                accountType: "",
                ifscCode: "",
                beneficiaryName: "",
                isLoading: false,
              });
              this.handleBack();
              this.setState({ isLoading: false });
            })
            .catch(() => {
              this.setState({ isLoading: false });
            });
    } else {
      this.setState({ isLoading: false });
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  componentDidMount() {
    if (this.props.id) {
      this.setState({ isLoading: true });
      this.props.getBankDetailById(this.props.id).then((data) => {
        const {
          bankName,
          beneficiaryBankAccountNumber,
          bankAccountType,
          IFSCCode,
          beneficiaryName,
        } = data;
        this.setState({
          bankName: bankName ? bankName : "",
          accountNumber: beneficiaryBankAccountNumber
            ? beneficiaryBankAccountNumber
            : "",
          accountType: bankAccountType ? bankAccountType : "",
          ifscCode: IFSCCode ? IFSCCode : "",
          beneficiaryName: beneficiaryName ? beneficiaryName : "",
          isLoading: false,
        });
      });
    }
  }

  handleBack = () => {
    this.props.keyFinancialCurrentTab(6);
    history.push("/fpo/key-financial");
  };

  render() {
    const {
      bankName,
      accountNumber,
      accountType,
      ifscCode,
      beneficiaryName,
      isLoading,
      others,
      typeOptions,
      isDelete,
    } = this.state;

    let { accessData } = this.props;

    let permissionData = getAccessdata(accessData, "keyFinancials");

    let { deleteAccess = false } = permissionData;

    return (
      <>
        {!isLoading ? (
          <div className="key-financials">
            <div className="d-flex container-sa my-3 ">
              <div
                onClick={() => {
                  this.handleBack();
                }}
                className="d-flex cursor-pointer"
              >
                <img className="cursor-pointer" src={arrow_left} alt="back" />
                <p className="ml-3 add-member-text-back mb-0">
                  {this.props.id ? "Edit" : "Add"} Bank Details
                </p>
              </div>
            </div>
            <div className="add-form">
              <div className="container bg-white py-4">
                <div className="row">
                  <div className="col-6">
                    <NormalInput
                      label="Beneficiary bank Account number"
                      type="number"
                      onChange={this.handleInput}
                      value={accountNumber}
                      name="accountNumber"
                      isHalfWidthForMemberProfile={true}
                      required={true}
                    />
                    <div className="row">
                      <div className="col-8 ml-auto">
                        {this.validator.message(
                          "Beneficiary bank Account number",
                          accountNumber,
                          "required|numeric"
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <NormalInput
                      label="Bank name"
                      type="text"
                      onChange={this.handleInput}
                      value={bankName}
                      name="bankName"
                      isHalfWidthForMemberProfile={true}
                      required={true}
                      max={25}
                    />
                    <div className="row">
                      <div className="col-8 ml-auto">
                        {this.validator.message(
                          "Bank Name",
                          bankName,
                          "required|alpha_space"
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <NormalInput
                      label="Beneficiary name"
                      type="text"
                      onChange={this.handleInput}
                      value={beneficiaryName}
                      name="beneficiaryName"
                      isHalfWidthForMemberProfile={true}
                      required={true}
                    />
                    <div className="row">
                      <div className="col-8 ml-auto">
                        {this.validator.message(
                          "Beneficiary name",
                          beneficiaryName,
                          "required|alpha_space"
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <NormalSelect
                      isHalfWidthForMemberProfile={true}
                      label="Bank account type"
                      placeholder="Select"
                      value={accountType}
                      name="accountType"
                      options={typeOptions}
                      arrow={true}
                      handleChange={this.handleInput}
                    />
                  </div>
                  {accountType == "Others" ? (
                    <div className="col-6">
                      <NormalInput
                        label="Others"
                        type="text"
                        onChange={this.handleInput}
                        value={others}
                        name="others"
                        isHalfWidthForMemberProfile={true}
                      />
                      <div className="row">
                        <div className="col-8 ml-auto">
                          {this.validator.message(
                            "others",
                            others,
                            "alpha_space"
                          )}
                        </div>
                      </div>
                    </div>
                  ) : null}
                  <div className="col-6">
                    <NormalInput
                      label="IFSC code"
                      type="text"
                      onChange={this.handleInput}
                      value={ifscCode}
                      name="ifscCode"
                      isHalfWidthForMemberProfile={true}
                    />
                  </div>
                </div>
                <div className="pt-5 pb-3 d-flex">
                  <div className="mx-auto d-flex">
                    {this.props.id && deleteAccess && (
                      <div className="cursor-pointer d-flex align-items-center mr-5">
                        <div
                          className="text-center fs-14 fw-600 text-danger d-flex"
                          onClick={this.toggleDelete}
                        >
                          <img className="mr-2" src={trash} alt="icon" />
                          Delete
                        </div>
                      </div>
                    )}
                    <NormalButton
                      label={`${this.props.id ? "Update" : "Submit"}`}
                      mainbg={true}
                      className="px-3 py-2 fs-14 line-height-26"
                      onClick={this.handleSubmit}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <>
            {/* Loader */}
            <CommonPageLoader />
          </>
        )}
        <ConfirmationPopup
          isOpen={isDelete}
          toggle={this.toggleDelete}
          onSubmit={this.handleDelete}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  accessData: state.home.accessData,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      addBankDetail,
      updateBankDetail,
      getBankDetailById,
      deleteBankDetail,
      keyFinancialCurrentTab,
    },
    dispatch
  );
};
let component = BankDetailsClass;

export const BankDetails = connect(
  mapStateToProps,
  mapDispatchToProps
)(component);
