import React, { Component } from "react";
import { Navbar } from "../../component/common";
import { FaqView } from "../../component/Faq";

export class faq extends Component {
  //lifecycles
  componentDidMount = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  render() {
    return (
      <div>
        <div className="normal-header">{/* <Navbar /> */}</div>
        <FaqView />
      </div>
    );
  }
}
