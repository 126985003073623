import React, { Component } from "react";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  membersView,
  allAssetTypes,
  memberAssetAdd,
  getMemberAsset,
  EditAssetsDetail,
  deleteAsset,
  getMember,
} from "action/MemberAct";
import SimpleReactValidator from "simple-react-validator";

import { NormalInput, NormalSelect, NormalDate } from "component/common";

import { Toast } from "service/toast";

const arrow_up = require("../../../assets/svg/add_member/arrow_up.svg");
class AssetsForm extends Component {
  constructor(props) {
    super();
    this.state = {
      members: [],
      memberId: "",
      all_assets: [],
      assetData: [
        {
          asset_type: "",
          asset_quantity: "",
          acquired_on: "",
          description: "",
          location: "",
          assetStatus: "",
          area: "",
          custom_hire_availability: "Yes",
          memberAssetId: null,
        },
      ],
      assertData: null,
      isDataUploading: false,
      customHireOptions: [
        { value: "Yes", label: "Yes" },
        { value: "No", label: "No" },
      ],
      allAssetsStatusOptions: [
        { value: "Operational", label: "Operational" },
        { value: "Idle", label: "Idle" },
        { value: "Damaged", label: "Damaged" },
      ],
    };
  }

  validator = new SimpleReactValidator({
    element: (message) => (
      <span className="error-message text-danger fs-14">{message}</span>
    ),
    autoForceUpdate: this,
  });

  handleChange = ({ target: { name, value } }) => {
    this.setState({
      [name]: value,
    });
  };

  handleInput = ({ target: { name, value } }, index) => {
    let { assetData } = this.state;
    assetData[index][name] = value;
    this.setState({
      assetData,
    });
  };

  handleAssetAdd = () => {
    let { assetData } = this.state;

    assetData = assetData.concat({
      asset_type: "",
      asset_quantity: "",
      acquired_on: "",
      description: "",
      location: "",
      assetStatus: "",
      area: "",
      custom_hire_availability: "Yes",
      memberAssetId: null,
    });

    this.setState({
      assetData,
      isDataUploading: false,
    });
  };

  handleAssetRemove = (e, index) => {
    let { assetData } = this.state;

    assetData = assetData.filter((s, sidx) => index !== sidx);

    this.setState({
      assetData,
      isDataUploading: false,
    });
  };

  //delete Asset
  handleDelete = (assetId) => {
    let { memberId } = this.state;
    let { deleteAsset } = this.props;
    let body = {
      id: memberId,
      assetId,
    };
    deleteAsset(body).then(() => {
      this._getData(memberId);
    });
  };

  _getData = (id) => {
    this.props.getMember({ id }).then((data) => {
      let memberAsset = data.memberasset;
      let assetDetails = [];
      if (memberAsset.length !== 0) {
        memberAsset.map((list) => {
          assetDetails.push({
            asset_type: list.assetMasterID ? parseInt(list.assetMasterID) : "",
            asset_quantity: list.quantity ? list.quantity.toString() : "",
            acquired_on: list.AcquiredOn ? new Date(list.AcquiredOn) : "",
            location: list.location ? list.location : "",
            description: list.description ? list.description : "",
            asset_status: list.assetStatus ? list.assetStatus : "",
            area: list.area !== null ? list.area : "",
            custom_hire_availability: list.customHireAvailability
              ? list.customHireAvailability
              : "",
            memberAssetId: list.memberAssetId ? list.memberAssetId : null,
          });
        });
        this.setState({
          assertData: memberAsset,
          memberId: memberAsset[0].memberID,
          assetData: assetDetails,
        });
      } else {
        this.setState({
          assetData: [
            {
              asset_type: "",
              asset_quantity: "",
              acquired_on: "",
              description: "",
              location: "",
              assetStatus: "",
              area: "",
              custom_hire_availability: "Yes",
              memberAssetId: null,
            },
          ],
          assertData: null,
        });
      }
    });
  };

  handleSave = () => {
    if (this.validator.allValid()) {
      const { memberId, assertData, assetData } = this.state;
      if (memberId) {
        this.setState({ isDataUploading: true });

        let body = {
          memberID: parseInt(memberId),
          asset: [],
        };

        assetData.map((list) => {
          let assetBody = {};
          if (list.asset_type !== "") {
            assetBody.assetMasterID = parseInt(list.asset_type);
          }
          if (list.asset_quantity !== "") {
            assetBody.quantity = parseInt(list.asset_quantity);
          }
          if (list.acquired_on !== "") {
            assetBody.AcquiredOn = list.acquired_on;
          }
          if (list.description !== "") {
            assetBody.description = list.description;
          }
          if (list.asset_status !== "") {
            assetBody.assetStatus = list.asset_status;
          }
          if (list.area !== "") {
            assetBody.area = list.area;
          }
          if (list.custom_hire_availability !== "") {
            assetBody.customHireAvailability = list.custom_hire_availability;
          }
          if (list.memberAssetId !== null) {
            assetBody.memberAssetId = list.memberAssetId;
          }
          if (list.location !== "") {
            assetBody.location = list.location;
          }

          body.asset.push(assetBody);
        });
        if (assertData == null) {
          if (body.asset.length === assetData.length) {
            this.props.memberAssetAdd(body).then((data) => {
              if (data === 200) {
                this.validator.hideMessages();
                // this._clearData();
                this.props.toggleCollapse(
                  false,
                  false,
                  false,
                  false,
                  true,
                  false
                );
              }
              this.setState({ isDataUploading: false });
            });
          }
        } else {
          if (body.asset.length === assetData.length) {
            this.props.EditAssetsDetail(body).then((data) => {
              if (data === 200) {
                this.validator.hideMessages();
              }
              this.setState({ isDataUploading: false });
            });
          }
        }
      } else {
        Toast({ type: "error", message: "Please add basic profile of member" });
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  // _clearData = () => {
  //   this.setState({
  //     assetData: [
  //       {
  //         asset_type: "",
  //         asset_quantity: "",
  //         acquired_on: "",
  //         description: "",
  //         location: "",
  //         assetStatus: "",
  //         area: "",
  //         custom_hire_availability: "Yes",
  //         memberAssetId: null,
  //       },
  //     ],
  //     assertData: null,
  //   });
  // };

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      if (this.props.isOpen) {
        let { memberId } = this.props;
        this.setState({
          memberId,
        });
        if (memberId) {
          this._getData(memberId);
        }
        // this.props.membersView().then((data) => {
        //   let memberDetials = [];
        //   data.map((list) => {
        //     memberDetials.push({
        //       label: list.memberFirstName + " | " + list.phone,
        //       value: list.memberID,
        //     });
        //   });
        //   this.setState({
        //     members: memberDetials,
        //   });
        // });
        this.props.allAssetTypes().then((data) => {
          let assertsData = [];
          data.map((list) => {
            assertsData.push({
              label: list.assetName,
              value: list.assetMasterID,
            });
          });
          this.setState({
            all_assets: assertsData,
          });
        });
      }
    }
  }
  render() {
    const {
      memberId,
      members,
      all_assets,
      customHireOptions,
      isDataUploading,
      assetData,
      allAssetsStatusOptions,
    } = this.state;

    let { deleteAccess = false } = this.props;

    return (
      <div className="px-md-5 py-4 px-3 add-member-area">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <div className="add-member-form-container-heading">Assets</div>
          <div className="d-flex justify-content-end align-items-center">
            {isDataUploading ? (
              <div className="d-flex align-items-center">
                <div className="mr-3 d-flex align-items-center ">
                  <i className="icon-plus add-more-area"></i>
                </div>
                <div className="add-member-form-container-button">
                  Save & next{" "}
                </div>
              </div>
            ) : (
              <div className="d-flex align-items-center">
                <div className="mr-3 d-flex align-items-center ">
                  {memberId !== "" ? (
                    <i
                      className="icon-plus cursor-pointer add-more-area"
                      onClick={() => this.handleAssetAdd()}
                    ></i>
                  ) : (
                    <i className="icon-plus add-more-area"></i>
                  )}
                </div>
                <div
                  className="add-member-form-container-button cursor-pointer"
                  onClick={this.handleSave}
                >
                  Save & next
                </div>
              </div>
            )}
            <img
              className="ml-3 cursor-pointer"
              src={arrow_up}
              alt="arrow_up"
              height="10"
              width="10"
              name="assets"
              onClick={this.props.collapse}
            />
          </div>
        </div>
        {/* <div className="row w-100">
          <div className="col-12">
            <NormalSelect
              isFullWithForMemberProfile={true}
              label="Member"
              placeholder="Select"
              value={memberId}
              arrow={true}
              name="memberId"
              options={members}
              handleChange={this.handleChange}
              required={true}
            />
            <div className="row">
              <div className="col-10 ml-auto">
                {this.validator.message("member", memberId, "required")}
              </div>
            </div>
          </div>
        </div> */}
        {assetData.map((list, index) => (
          <>
            <div className="row w-100">
              <div className="col-md-12 mt-5">
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <p className="mb-0 font-weight-bold fs-20 ">
                      {index + 1 + ". Asset"}
                    </p>
                  </div>
                  <div className="d-flex align-items-center">
                    {list.memberAssetId !== null && deleteAccess ? (
                      <p
                        className="mb-1 font-weight-bold fs-20 cursor-pointer d-flex align-items-center"
                        onClick={() => this.handleDelete(list.memberAssetId)}
                      >
                        <i className="icon-Delete fs-16" />
                        <span className="text-danger fs-16 pl-3">
                          {"Delete"}
                        </span>
                      </p>
                    ) : index != 0 && deleteAccess ? (
                      <p
                        className="mb-1 font-weight-bold fs-20 cursor-pointer d-flex align-items-center"
                        onClick={(e) => this.handleAssetRemove(e, index)}
                      >
                        <i className="icon-Delete fs-16" />
                        <span className="text-danger fs-16 pl-3">
                          {"Delete"}
                        </span>
                      </p>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            <div className="row w-100">
              <div className="col-md-6">
                <NormalSelect
                  isHalfWidth={true}
                  label="Asset type"
                  placeholder="Select"
                  value={list.asset_type}
                  arrow={true}
                  name="asset_type"
                  options={all_assets}
                  handleChange={(e) => this.handleInput(e, index)}
                  disabled={memberId == "" ? true : false}
                />
              </div>
              <div className="col-md-6">
                <NormalInput
                  isHalfWidth={true}
                  label="Asset quantity"
                  type="number"
                  onChange={(e) => this.handleInput(e, index)}
                  value={list.asset_quantity}
                  name="asset_quantity"
                  disabled={memberId == "" ? true : false}
                />
                <div className="row">
                  <div className="col-8 ml-auto">
                    {this.validator.message(
                      "quantity",
                      list.asset_quantity,
                      "max:8"
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="row w-100">
              <div className="col-md-6">
                <NormalDate
                  isIcon={true}
                  isHalfWidth={true}
                  value={list.acquired_on}
                  name="acquired_on"
                  placeholder="DD/MM/YYYY"
                  onChange={(e) => this.handleInput(e, index)}
                  mindate={false}
                  maxdate={true}
                  timeInput={false}
                  label={"Acquired on"}
                  disabled={memberId == "" ? true : false}
                />
                <NormalSelect
                  isHalfWidth={true}
                  label="Asset status"
                  placeholder="Select"
                  value={list.asset_status}
                  arrow={true}
                  name="asset_status"
                  options={allAssetsStatusOptions}
                  handleChange={(e) => this.handleInput(e, index)}
                  disabled={memberId == "" ? true : false}
                />
                <NormalInput
                  isHalfWidth={true}
                  label="Area"
                  type="text"
                  onChange={(e) => this.handleInput(e, index)}
                  value={list.area}
                  name="area"
                  disabled={memberId == "" ? true : false}
                />
                <div className="row">
                  <div className="col-8 ml-auto">
                    {this.validator.message("area", list.area, "max:50")}
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="row mt-3 pt-2">
                  <div className="col-md-4">
                    <div className="add-member-form-container-title mb-md-0 mb-2">
                      Description
                    </div>
                  </div>
                  <div className="col-md-8">
                    <textarea
                      className="w-100 h-100"
                      type="textarea"
                      name="description"
                      value={list.description}
                      onChange={(e) => this.handleInput(e, index)}
                      rows="2"
                      cols="50"
                      disabled={memberId == "" ? true : false}
                    ></textarea>
                    {this.validator.message(
                      "description",
                      list.description,
                      "max:75"
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="row w-100">
              <div className="col-md-6">
                <NormalInput
                  isHalfWidth={true}
                  label="Location"
                  type="text"
                  onChange={(e) => this.handleInput(e, index)}
                  value={list.location}
                  name="location"
                  disabled={memberId == "" ? true : false}
                />
                <div className="row">
                  <div className="col-md-8 ml-auto">
                    {this.validator.message(
                      "location",
                      list.location,
                      "max:50"
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <NormalSelect
                  isHalfWidth={true}
                  label=" Custom hire availability"
                  placeholder="Select"
                  value={list.custom_hire_availability}
                  arrow={true}
                  name="custom_hire_availability"
                  options={customHireOptions}
                  handleChange={(e) => this.handleInput(e, index)}
                  disabled={memberId == "" ? true : false}
                />
              </div>
            </div>
          </>
        ))}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      membersView,
      allAssetTypes,
      memberAssetAdd,
      getMemberAsset,
      EditAssetsDetail,
      deleteAsset,
      getMember,
    },
    dispatch
  );
};
let component = AssetsForm;

export const Assets = connect(null, mapDispatchToProps)(component);
