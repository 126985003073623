import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getSavedForumList,
  categoryList,
  categoryListChange,
} from "action/forum";
import { bindActionCreators } from "redux";
import { ForumCard } from "../forumCard";
import { SavedForumList } from "../SavedForumList";
import intiated_icon from "assets/svg/intiated-icon.svg";
import partcipated_icon from "assets/svg/partcipated-icon.svg";
import "./myForum.scss";
export class MyForumClass extends Component {
  state = {
    forumList: [],
    pageMeta: {},
    conversationCounts: {},
    savedId: "",
  };

  componentDidMount() {
    this._getSavedForumList(1);
    this.props.categoryList().then(({ success, data }) => {
      if (!!success) {
        let list = data.map(({ id, name }) => ({
          value: id,
          label: name,
          isChecked: false,
        }));
        this.setState({ allCategoryList: list });
        this.props.categoryListChange(list);
      }
    });
  }

  _getSavedForumList = (page) => {
    let { getSavedForumList } = this.props;
    let body = {
      page: page,
      limit: 4,
    };
    getSavedForumList(body).then(({ data }) => {
      let { conversationCounts, pageMeta, results } = data;
      this.setState({
        forumList: results,
        pageMeta: pageMeta,
        conversationCounts: conversationCounts,
      });
    });
  };
  handleSavedId = (id) => {
    this.setState({
      savedId: id,
    });
  };
  handlePageChange = (page) => {
    this._getSavedForumList(page);
  };
  handleReloadSavedForums = () => {
    this._getSavedForumList(1);
  };
  render() {
    let { forumList, pageMeta, conversationCounts = {}, savedId } = this.state;
    let { conversationInitiated = 0, conversationParticipated = 0 } =
      conversationCounts;
    return (
      <div className="my-forum">
        <div className="row">
          <div className="col-8">
            <ForumCard
              myForum={true}
              savedId={savedId}
              getSavedForumList={this._getSavedForumList}
              handleReloadSavedForums={() => this.handleReloadSavedForums()}
            />
          </div>
          <div className="col-4">
            <div className="conversation-box p-3">
              <div className="row w-100">
                <div className="col-3">
                  <div className="d-flex align-items-center h-100 justify-content-center">
                    <img src={intiated_icon} alt="intiated_icon" />
                  </div>
                </div>
                <div className="col-9">
                  <div className="d-flex flex-column">
                    <p className="fw-600 text-green-18 fs-30 line-height-43 mb-0">
                      {conversationInitiated ? conversationInitiated : 0}
                    </p>
                    <p className="fw-600 text-green-18 fs-18 line-height-21 mb-0">
                      Conversations initiated
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="conversation-box p-3">
              <div className="row w-100">
                <div className="col-3">
                  <div className="d-flex align-items-center h-100 justify-content-center">
                    <img src={partcipated_icon} alt="partcipated_icon" />
                  </div>
                </div>
                <div className="col-9">
                  <div className="d-flex flex-column">
                    <p className="fw-600 text-green-18 fs-30 line-height-43 mb-0">
                      {conversationParticipated ? conversationParticipated : 0}
                    </p>
                    <p className="fw-600 text-green-18 fs-18 line-height-21 mb-0">
                      Conversations participated
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-4">
              <SavedForumList
                handlePageChange={this.handlePageChange}
                forumList={forumList}
                pageMeta={pageMeta}
                handleSavedId={this.handleSavedId}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getSavedForumList,
      categoryList,
      categoryListChange,
    },
    dispatch
  );
};

export const MyForumComp = connect(null, mapDispatchToProps)(MyForumClass);
