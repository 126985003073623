import React, { Component } from "react";
import "./style.scss";
import { getLicenseByIdForView } from "action/ComplianceAct";

import { CommonPageLoader } from "component/common";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { history } from "service/helpers/index";

import { getFilePathDownload } from "service/api";

import moment from "moment";

const arrow_left = require("assets/svg/add_member/arrow_left.svg");

export class LicenseviewClass extends Component {
  constructor(props) {
    super();
    this.state = {
      licenseDetails: {},
      isloading: false,
    };
  }
  componentDidMount() {
    let { id } = this.props;
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    this._getData(id);
  }

  _getData = (id) => {
    let { getLicenseByIdForView } = this.props;

    this.setState({ isloading: true });

    getLicenseByIdForView(id).then((data) => {
      this.setState({
        licenseDetails: data,
        isloading: false,
      });
    });
  };

  render() {
    let { licenseDetails = {}, isloading } = this.state;

    let {
      OriginalLicense,
      RenewalAppliced,
      issueDate,
      issuingAuthority,
      licenseNumber,
      productsCovered,
      remarks,
      storeAddress,
      validityDate,
      licenseImgUrl,
      licenseImgFileName = "",
      LicenseName,
    } = licenseDetails;

    return (
      <div className="view-bod">
        <div className="d-flex my-3 container-sa px-0">
          <div
            className="d-flex"
            onClick={() => history.push("/fpo/compliance")}
          >
            <img className="cursor-pointer" src={arrow_left} alt="back" />
            <p className="fs-18 font-weight-bold font-Gilroy text-black mb-0 cursor-pointer ml-3">
              {"Back"}
            </p>
          </div>
        </div>
        {!isloading ? (
          <div className="bg-area py-4">
            <div className="container">
              <div className="bg-white p-5 shadow mx-5">
                <div className="row">
                  <div className="col-6">
                    <div className="row">
                      <div className="col-5">
                        <p className="text-black font-weight-bold fs-16">
                          {"Licence number :"}
                        </p>
                      </div>
                      <div className="col-7">
                        <p className="text-black-50 fs-16">
                          {licenseNumber ? licenseNumber : "-"}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-5">
                        <p className="text-black font-weight-bold fs-16">
                          {"Licence name :"}
                        </p>
                      </div>
                      <div className="col-7">
                        <p className="text-black-50 fs-16">
                          {LicenseName ? LicenseName : "-"}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-5">
                        <p className="text-black font-weight-bold fs-16">
                          {"Issuing authority: "}
                        </p>
                      </div>
                      <div className="col-7">
                        <p className="text-black-50 fs-16">
                          {issuingAuthority ? issuingAuthority : "-"}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-5">
                        <p className="text-black font-weight-bold fs-16">
                          {"Validity date:"}
                        </p>
                      </div>
                      <div className="col-7">
                        <p className="text-black-50 fs-16">
                          {validityDate
                            ? moment(validityDate).format("DD MMMM YYYY")
                            : "-"}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-5">
                        <p className="text-black font-weight-bold fs-16">
                          {"Issue date:"}
                        </p>
                      </div>
                      <div className="col-7">
                        <p className="text-black-50 fs-16">
                          {issueDate
                            ? moment(issueDate).format("DD MMMM YYYY")
                            : "-"}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-5">
                        <p className="text-black font-weight-bold fs-16">
                          {"Renewal applied:"}
                        </p>
                      </div>
                      <div className="col-7">
                        <p className="text-black-50 fs-16">
                          {RenewalAppliced ? RenewalAppliced : "-"}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="row">
                      <div className="col-5">
                        <p className="text-black font-weight-bold fs-16">
                          {"Licence :"}
                        </p>
                      </div>
                      <div className="col-7">
                        {licenseImgFileName ? (
                          <a
                            className="fs-16 text-green-18 text-underline cursor-pointer"
                            href={getFilePathDownload + licenseImgUrl}
                            download
                          >
                            {"Download"}
                          </a>
                        ) : (
                          <p className="text-black-50 fs-16">{"-"}</p>
                        )}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-5">
                        <p className="text-black font-weight-bold fs-16">
                          Store Address:{" "}
                        </p>
                      </div>
                      <div className="col-7">
                        <p className="text-black-50 fs-16">
                          {storeAddress ? storeAddress : "-"}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-5">
                        <p className="text-black font-weight-bold fs-16">
                          {"Products Covered: "}
                        </p>
                      </div>
                      <div className="col-7">
                        <p className="text-black-50 fs-16">
                          {productsCovered ? productsCovered : "-"}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-5">
                        <p className="text-black font-weight-bold fs-16">
                          {"Remarks:"}
                        </p>
                      </div>
                      <div className="col-7">
                        <p className="text-black-50 fs-16">
                          {remarks ? remarks : "-"}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-5">
                        <p className="text-black font-weight-bold fs-16">
                          {"Original license:"}
                        </p>
                      </div>
                      <div className="col-7">
                        <p className="text-black-50 fs-16">
                          {OriginalLicense ? OriginalLicense : "-"}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <>
            {/* Loader */}
            <CommonPageLoader />
          </>
        )}
        <div className="view-members"></div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getLicenseByIdForView,
    },
    dispatch
  );
};
let component = LicenseviewClass;

export const Licenseview = connect(null, mapDispatchToProps)(component);
