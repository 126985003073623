import React, { Component } from "react";
import Card from "./card";
import Strip from "./strip";
import { Pagination, CommonPageLoader } from "component/common";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getAllKeyFinancialInfo } from "action/KeyFinancialAct";
import { getAllKeyFinancialInfoRm } from "action/RmAct";
import "./financialinfolist.scss";
export class FinancialInfoListClass extends Component {
  constructor(props) {
    super();
    this.state = {
      infos: [],
      pageMeta: {},
      page: 1,
      isLoading: false,
    };
  }
  handleKeyFinancialInfo = (page) => {
    let { searchValue, filterBy, filterType, id } = this.props;
    this.setState({ isLoading: true });
    let payload = {
      limit: 4,
      page,
      search: searchValue,
      filterBy: filterBy,
      filterValue: filterType,
    };
    id
      ? this.props.getAllKeyFinancialInfoRm(id, payload).then((data) => {
          this.setState({
            infos: data.results,
            pageMeta: data.pageMeta,
            isLoading: false,
          });
        })
      : this.props.getAllKeyFinancialInfo(payload).then((data) => {
          this.setState({
            infos: data.results,
            pageMeta: data.pageMeta,
            isLoading: false,
          });
        });
  };

  handlePagination = (page) => {
    this.handleKeyFinancialInfo(page);
  };

  componentDidMount() {
    this.handleKeyFinancialInfo(1);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.activeTab != this.props.activeTab) {
      let { activeTab } = this.props;
      if (activeTab == 1) {
        this.handleKeyFinancialInfo(1);
      }
    }
    if (this.props.activeTab == 1) {
      this.props.searchValue !== prevProps.searchValue &&
        this.handleKeyFinancialInfo(1);
      this.props.filterType !== prevProps.filterType &&
        this.handleKeyFinancialInfo(1);
    }
  }

  render() {
    let { pageMeta, infos, isLoading } = this.state;

    let { edit = false } = this.props;

    return (
      <>
        {!isLoading && infos.length === 0 ? (
          <p className="py-4 mb-0 text-black-50 fs-22 line-height-25 text-center">
            No data found
          </p>
        ) : !isLoading ? (
          <>
            {infos.length != 0 ? (
              <>
                <div className="scroll-x">
                  <div
                    className="d-flex container-sa flex-column  flex-wrap mt-3 px-0 justify-content-center"
                    style={
                      this.props.view === "1" ? { minWidth: "1200px" } : null
                    }
                  >
                    <div className="d-flex flex-wrap com-list-card">
                      {infos.map((info, index) => {
                        return this.props.view === "2" ? (
                          <Card
                            payload={info}
                            index={index}
                            key={index}
                            editAccess={edit}
                          />
                        ) : (
                          <Strip
                            payload={info}
                            index={index}
                            key={index}
                            editAccess={edit}
                          />
                        );
                      })}
                    </div>
                  </div>
                </div>
                {pageMeta.pageCount !== undefined &&
                  pageMeta.pageCount !== 1 && (
                    <Pagination
                      handleChange={this.handlePagination}
                      pageMeta={pageMeta}
                    />
                  )}
              </>
            ) : (
              <p className="py-4 mb-0 text-black-50 fs-22 line-height-25 text-center">
                No data found
              </p>
            )}
          </>
        ) : (
          <>
            {/* Loader */}
            <CommonPageLoader />
          </>
        )}
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getAllKeyFinancialInfo,
      getAllKeyFinancialInfoRm,
    },
    dispatch
  );
};

let component = FinancialInfoListClass;

export const FinancialInfoList = connect(null, mapDispatchToProps)(component);
