import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Modal, ModalBody } from "reactstrap";
import { DragFileUpload, NormalSelect, NormalEditor } from "component/common";
import "./createForum.scss";
import SimpleReactValidator from "simple-react-validator";
import {
  createForum,
  getForumById,
  categoryList,
  updateForum,
  categoryListChange,
} from "action/forum";
import { fileUpload, getMember } from "action/MemberAct";
import { Toast } from "service/toast";

export class CreateForumClass extends Component {
  constructor() {
    super();
    this.state = {
      isFormLoader: false,
      forumObj: {
        title: "",
        metaTitle: "",
        slug: "",
        content: "",
        forumCategoryId: "",
        images: [],
        supportingLink: "",
      },
      allCategoryList: [],
      isLoaderFileUpload: false,
    };
  }

  //onlode components
  componentWillMount() {
    this.validator = new SimpleReactValidator({
      element: (message) => (
        <span className="error-message text-danger fs-14">{message}</span>
      ),
      autoForceUpdate: this,
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.setState({ allCategoryList: this.props.list });
      if (this.props.forumId) {
        this._getForumDetails();
      }
    }
  }

  _getForumDetails = () => {
    let { forumId, getForumById } = this.props;
    getForumById(forumId).then(({ data }) => {
      this.setState({
        forumObj: {
          title: data.title,
          metaTitle: data.metaTitle,
          slug: data.slug,
          content: data.content,
          forumCategoryId: data.forumCategoryId,
          images: data.images,
          supportingLink:
            data.supportingLink !== null ? data.supportingLink : "",
        },
      });
    });
  };

  //handleInputChange
  handleInputChange = (e) => {
    let { value, name } = e.target;
    this.setState({
      forumObj: {
        ...this.state.forumObj,
        [name]: value,
      },
    });
  };

  onEditorChange = (editor) => {
    let { forumObj } = this.state;
    forumObj.content = editor.getData();
    this.setState({
      forumObj,
    });
  };
  //handleFormSubmit
  handleFormSubmit = () => {
    let { forumObj } = this.state;
    let { forumId, createForum, updateForum, myForum } = this.props;
    if (this.validator.allValid()) {
      forumObj.metaTitle = forumObj.title;
      forumObj.slug = forumObj.title.replace(" ", "-");
      if (forumObj.supportingLink === "") {
        delete forumObj.supportingLink;
      }

      if (forumId) {
        updateForum(forumObj, forumId)
          .then((data) => {
            this.validator.hideMessages();
            this.setState({ isFormLoader: false });
            if (!!data) {
              this.handleClose();
              if (myForum) {
                this.props.getForumList();
              } else {
                this.props.getForumList("reset");
              }
              this.props.handleReload();
            }
          })
          .catch(() => {
            this.setState({ isFormLoader: false });
          });
      } else {
        createForum(forumObj)
          .then((data) => {
            this.validator.hideMessages();
            this.setState({ isFormLoader: false });
            if (!!data) {
              this.handleClose();

              this.props.handleReload();
            }
          })
          .catch(() => {
            this.setState({ isFormLoader: false });
          });
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  handleFileUpload = (file) => {
    const formData = new FormData();
    formData.append("file", file[0]);
    let { forumObj } = this.state;
    forumObj.images = [];
    this.setState({ isLoaderFileUpload: true, forumObj });
    this.props
      .fileUpload(formData)
      .then((data) => {
        this.setState({ isLoaderFileUpload: false });
        if (!!data) {
          forumObj.images.push({
            alt: data[0].fileName,
            url: data[0].fileUrl,
          });
          this.setState({ forumObj });
        }
      })
      .catch(() => {
        this.setState({ isLoaderFileUpload: false });
      });
  };
  handleClose = () => {
    this.setState({
      forumObj: {
        title: "",
        metaTitle: "",
        slug: "",
        content: "",
        forumCategoryId: "",
        images: [],
        supportingLink: "",
      },
    });
    this.validator.hideMessages();
    this.props.handleCreateModalToggle();
  };
  render() {
    let { show, forumId } = this.props;
    let { forumObj, isFormLoader, allCategoryList, isLoaderFileUpload } =
      this.state;
    return (
      <Modal isOpen={show} className="create-modal create-forum">
        <div className="modal-header">
          <h5 className="modal-title">{forumId ? "Update" : "Create"} forum</h5>
          <div className="d-flex">
            <button
              type="button"
              className="save-btn btn"
              disabled={isFormLoader}
              onClick={() => this.handleClose()}
            >
              {"Cancel"}
            </button>
            <button
              type="button"
              className="save-btn btn"
              disabled={isFormLoader}
              onClick={this.handleFormSubmit}
            >
              {forumId ? "Update" : "Save & publish"}
              {isFormLoader ? (
                <span
                  className="spinner-border spinner-border-sm ml-1"
                  role="status"
                  aria-hidden="true"
                ></span>
              ) : null}
            </button>
          </div>
        </div>
        <ModalBody>
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <label>
                  Write your question
                  <span className="text-danger font-weight-bold pl-2 fs-18">
                    *
                  </span>
                </label>
                <input
                  value={forumObj.title}
                  name="title"
                  onChange={this.handleInputChange}
                  className="form-control"
                />
                {this.validator.message(
                  "Title",
                  forumObj.title,
                  "required|max:75"
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <NormalEditor
                  label="Description"
                  data={forumObj.content}
                  onChange={this.onEditorChange}
                  required={true}
                />
                {this.validator.message(
                  "Content",
                  forumObj.content,
                  "required|max:500"
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <label>
                  Category{" "}
                  <span className="text-danger font-weight-bold pl-2 fs-18">
                    *
                  </span>
                </label>
                <NormalSelect
                  isFullWidthwithoutLabel={true}
                  placeholder="Select"
                  value={forumObj.forumCategoryId}
                  arrow={true}
                  name="forumCategoryId"
                  options={allCategoryList}
                  handleChange={this.handleInputChange}
                  required={true}
                />
                {this.validator.message(
                  "category",
                  forumObj.forumCategoryId,
                  "required"
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <label>Supporting link (if any)</label>
                <input
                  value={forumObj.supportingLink}
                  name="supportingLink"
                  onChange={this.handleInputChange}
                  className="form-control"
                />
                {this.validator.message(
                  "supporting",
                  forumObj.supportingLink,
                  "url"
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 d-flex flex-column align-items-center">
              <DragFileUpload
                className="form-group add-img text-center w-100"
                value={forumObj.images}
                name="imageURL"
                children={
                  <AddImage
                    isLoaderFileUpload={isLoaderFileUpload}
                    images={forumObj.images}
                  />
                }
                handleFileUpload={this.handleFileUpload}
              />
              {this.validator.message("images", forumObj.images, "required")}
              {forumObj.images.length > 0 && (
                <img
                  className="w-25 h-50"
                  src={forumObj.images[0].url}
                  alt={forumObj.images[0].alt}
                />
              )}
            </div>
          </div>
        </ModalBody>
      </Modal>
    );
  }
}

//Add image area
class AddImage extends React.Component {
  render() {
    let { isLoaderFileUpload, images } = this.props;
    return (
      <>
        {images.length === 0 ? (
          <label>
            Add image{" "}
            <span className="text-danger font-weight-bold pl-2 fs-18">*</span>
            {isLoaderFileUpload ? (
              <span
                className="spinner-border spinner-border-sm ml-1"
                role="status"
                aria-hidden="true"
              ></span>
            ) : (
              ""
            )}
          </label>
        ) : (
          <label>{images[0].alt}</label>
        )}
      </>
    );
  }
}

const mapStateToProps = ({ academy }) => ({
  list: academy.categoryList,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      createForum,
      categoryList,
      fileUpload,
      getMember,
      getForumById,
      updateForum,
      categoryListChange,
    },
    dispatch
  );
};

let component = CreateForumClass;

export const CreateForum = connect(
  mapStateToProps,
  mapDispatchToProps
)(component);
