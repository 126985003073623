import React, { Component } from "react";
import { history } from "service/helpers";

import moment from "moment";
const User_Profile_1 = require("assets/svg/Members/User_Profile_1.svg");

export class Strip extends Component {
  render() {
    let { payload, index, activeTab, editAccess } = this.props;
    const {
      id,
      agendaHighlights,
      numberOfAttendees,
      location,
      attendeesListUrl,
      attendeesListFileName,
      supportingDocumentFileName,
      supportingDocumentUrl,
      dateOfLastMeetingD,
    } = payload;
    return (
      <div
        key={index}
        className="container-sa license-strip shadow rounded mb-3"
      >
        <div className="row align-items-center h-100 px-2">
          <div className="col-3">
            <div className="fs-18 fw-600">
              {agendaHighlights
                ? agendaHighlights.length > 50
                  ? agendaHighlights.substring(0, 49) + "..."
                  : agendaHighlights
                : "-"}
            </div>
            <div className="text-black-50">Agenda/Highlights</div>
          </div>
          <div className="col-2">
            <div className="fs-18 fw-600">{location ? location : "-"}</div>
            <div className="text-black-50">Location</div>
          </div>
          <div className="col-2">
            <div className="fs-18 fw-600">
              {dateOfLastMeetingD
                ? moment(dateOfLastMeetingD).format("DD MMM YYYY")
                : "-"}
            </div>
            <div className="text-black-50">Number Of attendees</div>
            <div className="fs-18 fw-600">
              {numberOfAttendees ? numberOfAttendees : "-"}
            </div>
          </div>
          <div className="col-2">
            {attendeesListFileName ? (
              <>
                <div className="fs-18 fw-600 cursor-pointer">
                  <u
                    className="cursor-pointer"
                    onClick={() => window.open(attendeesListUrl, "_blank")}
                  >
                    {"View"}
                  </u>
                </div>
                <div className="text-black-50">Total attendees list</div>
              </>
            ) : null}
          </div>
          <div className="col-2">
            {supportingDocumentFileName ? (
              <>
                <div className="fs-18 fw-600">
                  <u
                    className="cursor-pointer"
                    onClick={() => window.open(supportingDocumentUrl, "_blank")}
                  >
                    {"View"}
                  </u>
                </div>
                <div className="text-black-50">Supporting doc</div>
              </>
            ) : null}
          </div>

          <div className="col-1 d-flex">
            <div
              className="text-black-50 cursor-pointer mr-4"
              onClick={() => {
                activeTab === "1"
                  ? history.push(`/fpo/compliance/bod/${id}`)
                  : history.push(`/fpo/compliance/agm/${id}`);
              }}
            >
              {"View"}
            </div>
            {editAccess && (
              <div
                className="text-black-50 cursor-pointer"
                onClick={() => {
                  activeTab === "1"
                    ? history.push(`/fpo/edit-bod-compliance/${id}`)
                    : history.push(`/fpo/edit-agm/${id}`);
                }}
              >
                {"Edit"}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
