const routers = [
  {
    path: "/",
    exact: true,
    redirect: "/landing/home",
  },
  // {
  //   component: "Register",
  //   path: "/register",
  //   exact: true,
  // },
  {
    component: "Termsandcondition",
    path: "/termsandcondition",
    auth: false,
    exact: true,
  },
  {
    component: "PrivacyPolicy",
    path: "/privacypolicy",
    auth: false,
    exact: true,
  },
  {
    component: "SSOPage",
    path: "/adSsoToken",
    auth: false,
    exact: true,
  },
  {
    component: "AuthLayout",
    path: "/login",
    auth: false,
    exact: false,
    childrens: [
      {
        component: "Login",
        path: "/",
        auth: false,
        exact: true,
      },
      {
        component: "ForgotPass",
        path: "/forgot-pass",
        auth: false,
        exact: true,
      },
      {
        component: "ResetPass",
        path: "/reset-pass",
        auth: false,
        exact: true,
      },
    ],
  },
  {
    component: "PreLoginLayout",
    path: "/landing",
    auth: false,
    exact: false,
    childrens: [
      {
        component: "Home",
        path: "/home",
        auth: false,
        exact: true,
      },
      {
        component: "faq",
        path: "/faq",
        auth: false,
        exact: true,
      },
      {
        component: "newsAndEvents",
        path: "/contents/reportsarticlesevents",
        auth: false,
        exact: true,
      },
      {
        component: "EventsandImpactsView",
        path: "/viewDetails/:name&:id",
        auth: false,
        exact: true,
      },
      {
        component: "SuccessStoriesView",
        path: "/viewDetails/content/:name&:id",
        auth: false,
        exact: true,
      },
      {
        component: "Search",
        path: "/search/:fpoName",
        auth: false,
        exact: true,
      },
      {
        component: "ReportList",
        path: "/report",
        auth: false,
        exact: true,
      },
      {
        component: "Report",
        path: "/report/:id",
        auth: false,
        exact: true,
      },
    ],
  },
  {
    component: "OtherLoginLayout",
    path: "/other",
    auth: false,
    exact: false,
    childrens: [
      {
        component: "Home",
        path: "/home",
        auth: false,
        exact: true,
      },
      {
        component: "faq",
        path: "/faq",
        auth: false,
        exact: true,
      },
      {
        component: "newsAndEvents",
        path: "/contents/newsandevents",
        auth: false,
        exact: true,
      },
      {
        component: "EventsandImpactsView",
        path: "/viewDetails/:name&:id",
        auth: false,
        exact: true,
      },
      {
        component: "SuccessStoriesView",
        path: "/viewDetails/content/:name&:id",
        auth: false,
        exact: true,
      },
    ],
  },
  {
    component: "RmLayout",
    path: "/rm",
    auth: false,
    exact: false,
    childrens: [
      {
        component: "RMHomePage",
        path: "/home",
        auth: false,
        exact: true,
      },
      {
        component: "RMGradingPage",
        path: "/rm-grading",
        auth: false,
        exact: true,
      },
      {
        component: "RMKYMNewPage",
        path: "/kym-new",
        auth: false,
        exact: true,
      },
      {
        component: "MemberListPage",
        path: "/kym-new-list/:id",
        auth: false,
        exact: true,
      },
      {
        component: "MemberDetailsPage",
        path: "/kym-new-details",
        auth: false,
        exact: true,
      },
      {
        component: "Aboutus",
        path: "/about-us/:id",
        auth: false,
        exact: true,
      },
      {
        component: "CapacityBuilding",
        path: "/capacity-building",
        auth: false,
        exact: true,
      },
      // {
      //   component: "Home",
      //   path: "/home",
      //   auth: false,
      //   exact: true,
      // },
      {
        component: "faq",
        path: "/faq",
        auth: false,
        exact: true,
      },
      {
        component: "newsAndEvents",
        path: "/contents/newsandevents",
        auth: false,
        exact: true,
      },
      {
        component: "fpoDueList",
        path: "/fpoduelist",
        auth: false,
        exact: true,
      },
      {
        component: "Associations",
        path: "/fpo/association/:id",
        auth: false,
        exact: true,
      },
      {
        component: "GradingTool",
        path: "/fpo/grading-score/:id",
        auth: false,
        exact: true,
      },
      {
        component: "SurveyTool",
        path: "/grading-tool",
        auth: false,
        exact: true,
      }, //
      {
        component: "RMGradingReportPage",
        path: "/grading-report",
        auth: false,
        exact: true,
      },
      {
        component: "Documents",
        path: "/fpo/documents/:id",
        auth: false,
        exact: true,
      },
      {
        component: "Members",
        path: "/fpo/member/:id",
        auth: false,
        exact: true,
      },
      {
        component: "MemberMapping",
        path: "/fpo/mapping-members/:id",
        auth: false,
        exact: true,
      },
      {
        component: "ViewMember",
        path: "/fpo/view-member/:id",
        auth: false,
        exact: true,
      },
      {
        component: "ViewBod",
        path: "/fpo/view-bod/:fpoid/:id",
        auth: false,
        exact: true,
      },
      {
        component: "ViewStaff",
        path: "/fpo/view-staff/:fpoid/:id",
        auth: false,
        exact: true,
      },
      {
        component: "Events",
        path: "/fpo/events/:id",
        auth: false,
        exact: true,
      },
      {
        component: "Relationship",
        path: "/fpo/relationship/:id",
        auth: false,
        exact: true,
      },
      {
        component: "Compilance",
        path: "/fpo/compliance/:id",
        auth: false,
        exact: true,
      },
      {
        component: "KeyFinancial",
        path: "/fpo/key-financial/:id",
        auth: false,
        exact: true,
      },
      {
        component: "EventsandImpactsView",
        path: "/viewDetails/:name&:id",
        auth: false,
        exact: true,
      },
      {
        component: "SuccessStoriesView",
        path: "/viewDetails/content/:name&:id",
        auth: false,
        exact: true,
      },
      {
        component: "SellRequest",
        path: "/sell-request",
        auth: false,
        exact: false,
      },
      {
        component: "SellRequestDetail",
        path: "/sell-request-detail/:id",
        auth: false,
        exact: false,
      },
      {
        component: "SellRequestDealClosure",
        path: "/deal-closure/:id",
        auth: false,
        exact: false,
      },
      {
        component: "DashboardFpo",
        path: "/dashboard",
        auth: false,
        exact: false,
      },
    ],
  },
  {
    component: "FpoLayout",
    path: "/fpo",
    auth: false,
    exact: false,
    childrens: [
      {
        component: "Home",
        path: "/home",
        auth: false,
        exact: true,
      },
      {
        component: "faq",
        path: "/faq",
        auth: false,
        exact: true,
      },
      {
        component: "Aboutus",
        path: "/about-us",
        auth: false,
        exact: true,
      },
      {
        component: "Aboutus",
        path: "/about-us/:name/:id",
        auth: false,
        exact: true,
      },
      {
        component: "Addform",
        path: "/addaboutus",
        auth: false,
        exact: true,
      },
      {
        component: "IndividualProfile",
        path: "/profile",
        auth: false,
        exact: true,
      },
      {
        component: "Members",
        path: "/member",
        auth: false,
        exact: true,
      },

      {
        component: "MemberMapping",
        path: "/mapping-members",
        auth: false,
        exact: true,
      },
      {
        component: "AddMember",
        path: "/add-member",
        auth: false,
        exact: true,
      },
      {
        component: "AddBOD",
        path: "/add-bod",
        auth: false,
        exact: true,
      },
      {
        component: "AddBOD",
        path: "/add-bod/:id",
        auth: false,
        exact: true,
      },
      {
        component: "Addstaff",
        path: "/add-staff",
        auth: false,
        exact: true,
      },
      {
        component: "Addstaff",
        path: "/add-staff/:id",
        auth: false,
        exact: true,
      },
      {
        component: "ViewStaff",
        path: "/view-staff/:id",
        auth: false,
        exact: true,
      },
      {
        component: "ViewBod",
        path: "/view-bod/:id",
        auth: false,
        exact: true,
      },
      {
        component: "BulkUpload",
        path: "/bulk-upload",
        auth: false,
        exact: true,
      },
      {
        component: "ViewMember",
        path: "/view-member/:id",
        auth: false,
        exact: true,
      },
      {
        component: "EditMember",
        path: "/edit-member",
        auth: false,
        exact: true,
      },
      {
        component: "Relationship",
        path: "/relationship",
        auth: false,
        exact: true,
      },
      {
        component: "Events",
        path: "/events",
        auth: false,
        exact: true,
      },
      {
        component: "EventsandImpactsView",
        path: "/viewDetails/:name&:id",
        auth: false,
        exact: true,
      },
      {
        component: "Documents",
        path: "/documents",
        auth: false,
        exact: true,
      },
      {
        component: "Compilance",
        path: "/compliance",
        auth: false,
        exact: true,
      },
      {
        component: "Viewlicense",
        path: "/compliance/licenseview/:id",
        auth: false,
        exact: true,
      },
      {
        component: "ViewGST",
        path: "/compliance/gst/:id",
        auth: false,
        exact: true,
      },
      {
        component: "ViewReturnGST",
        path: "/compliance/gst-return/:id",
        auth: false,
        exact: true,
      },
      {
        component: "ViewIncomeTds",
        path: "/compliance/income-tds/:id",
        auth: false,
        exact: true,
      },
      {
        component: "ViewIncomeTax",
        path: "/compliance/income-tax/:id",
        auth: false,
        exact: true,
      },
      {
        component: "ViewAgm",
        path: "/compliance/agm/:id",
        auth: false,
        exact: true,
      },
      {
        component: "ViewBodCompliance",
        path: "/compliance/bod/:id",
        auth: false,
        exact: true,
      },
      {
        component: "ViewRegister",
        path: "/compliance/registrar/:id",
        auth: false,
        exact: true,
      },
      {
        component: "addLicense",
        path: "/add-license",
        auth: false,
        exact: true,
      },
      {
        component: "addLicense",
        path: "/edit-license/:id",
        auth: false,
        exact: true,
      },
      {
        component: "addIncomeTax",
        path: "/add-incometax",
        auth: false,
        exact: true,
      },
      {
        component: "addIncomeTax",
        path: "/edit-incometax/:id",
        auth: false,
        exact: true,
      },
      {
        component: "addIncomeTds",
        path: "/add-incometds",
        auth: false,
        exact: true,
      },
      {
        component: "addIncomeTds",
        path: "/edit-incometds/:id",
        auth: false,
        exact: true,
      },
      {
        component: "addRegistrar",
        path: "/add-registrar",
        auth: false,
        exact: true,
      },
      {
        component: "addRegistrar",
        path: "/edit-registrar/:id",
        auth: false,
        exact: true,
      },
      {
        component: "addGst",
        path: "/add-gst",
        auth: false,
        exact: true,
      },
      {
        component: "addGst",
        path: "/edit-gst/:id",
        auth: false,
        exact: true,
      },
      {
        component: "addGstReturn",
        path: "/add-gstreturn",
        auth: false,
        exact: true,
      },
      {
        component: "addGstReturn",
        path: "/edit-gstreturn/:id",
        auth: false,
        exact: true,
      },
      {
        component: "addBodCompliance",
        path: "/add-bod-compliance",
        auth: false,
        exact: true,
      },
      {
        component: "addBodCompliance",
        path: "/edit-bod-compliance/:id",
        auth: false,
        exact: true,
      },
      {
        component: "addAgm",
        path: "/add-agm",
        auth: false,
        exact: true,
      },
      {
        component: "addAgm",
        path: "/edit-agm/:id",
        auth: false,
        exact: true,
      },
      {
        component: "Search",
        path: "/search/:fpoName",
        auth: false,
        exact: true,
      },
      {
        component: "KeyFinancial",
        path: "/key-financial",
        auth: false,
        exact: true,
      },
      {
        component: "AllFinancials",
        path: "/business-plan",
        auth: false,
        exact: true,
      },
      {
        component: "addFinancialInfo",
        path: "/add-financialinfo",
        auth: false,
        exact: true,
      },
      {
        component: "addFinancialInfo",
        path: "/edit-financialinfo/:id",
        auth: false,
        exact: true,
      },
      {
        component: "addCreditHistory",
        path: "/add-credithistory",
        auth: false,
        exact: true,
      },
      {
        component: "addBankDetails",
        path: "/add-bankDetails",
        auth: false,
        exact: true,
      },
      {
        component: "addBankDetails",
        path: "/edit-bankDetails/:id",
        auth: false,
        exact: true,
      },
      {
        component: "addCreditHistory",
        path: "/edit-credithistory/:id",
        auth: false,
        exact: true,
      },
      {
        component: "addRevenue",
        path: "/add-revenue",
        auth: false,
        exact: true,
      },
      {
        component: "addRevenue",
        path: "/edit-revenue/:id",
        auth: false,
        exact: true,
      },
      {
        component: "addPlan",
        path: "/add-plan",
        auth: false,
        exact: true,
      },
      {
        component: "addPlan",
        path: "/edit-plan/:id",
        auth: false,
        exact: true,
      },
      {
        component: "Associations",
        path: "/association",
        auth: false,
        exact: true,
      },
      {
        component: "addAssociation",
        path: "/add-association",
        auth: false,
        exact: true,
      },
      {
        component: "addAssociation",
        path: "/edit-association/:id",
        auth: false,
        exact: true,
      },
      {
        component: "newsAndEvents",
        path: "/contents/reportsarticlesevents",
        auth: false,
        exact: true,
      },
      {
        component: "GradingTool",
        path: "/grading-score",
        auth: false,
        exact: true,
      },
      {
        component: "MembersAnalytics",
        path: "/report",
        auth: false,
        exact: false,
      },
      {
        component: "SellRequest",
        path: "/sell-request",
        auth: false,
        exact: false,
      },
      {
        component: "SellRequestDetail",
        path: "/sell-request-detail/:id",
        auth: false,
        exact: false,
      },
      {
        component: "SellRequestAdd",
        path: "/raise-a-new-request",
        auth: false,
        exact: false,
      },
      {
        component: "SellRequestAdd",
        path: "/edit-request/:id",
        auth: false,
        exact: false,
      },
      {
        component: "SellRequestStageTwo",
        path: "/sell-request-stage-two/:id",
        auth: false,
        exact: false,
      },
      {
        component: "BuyerComparison",
        path: "/buyer-comparison",
        auth: false,
        exact: false,
      },
      {
        component: "CreateBuyerOrder",
        path: "/create-buyer-order",
        auth: false,
        exact: false,
      },
      {
        component: "DashboardFpo",
        path: "/dashboard",
        auth: false,
        exact: false,
      },
      {
        component: "HarvestFormComponent",
        path: "/harvest-details",
        auth: false,
        exact: true,
      },
      {
        component: "RMKYMListPage",
        path: "/rm-kym-list",
        auth: false,
        exact: true,
      },
      {
        component: "Pricing",
        path: "/pricing",
        auth: false,
        exact: true,
      },
      {
        component: "Weather",
        path: "/weather",
        auth: false,
        exact: true,
      },
    ],
  },
  {
    component: "AcadamyLayout",
    path: "/academy",
    auth: false,
    exact: false,
    childrens: [
      {
        component: "AcadamyHome",
        path: "/home",
        auth: false,
        exact: true,
      },
      {
        component: "NotificationList",
        path: "/notification",
        auth: false,
        exact: true,
      },
      {
        component: "AcadamyExplore",
        path: "/explore/:id",
        auth: false,
        exact: true,
      },
      {
        component: "ViewInfomativeVideo",
        path: "/trending-video/:id",
        auth: false,
        exact: true,
      },
      {
        component: "ViewAllVideo",
        path: "/trendingvideos/all",
        auth: false,
        exact: true,
      },
      {
        component: "ViewAllCourses",
        path: "/allcourse",
        auth: false,
        exact: true,
      },
      {
        component: "MyLearning",
        path: "/my-learning",
        auth: false,
        exact: true,
      },
      {
        component: "Courses",
        path: "/courses/:id",
        auth: false,
        exact: true,
      },
      {
        component: "QuizPage",
        path: "/quiz/:id",
        auth: false,
        exact: true,
      },
      {
        component: "QuizPage",
        path: "/quiz/:id/:name",
        auth: false,
        exact: true,
      },
      {
        component: "BlogPage",
        path: "/blog/:id",
        auth: false,
        exact: true,
      },
      {
        component: "ViewallBlog",
        path: "/blog/management/allblog",
        auth: false,
        exact: true,
      },
      {
        component: "CommunityForum",
        path: "/communityForum",
        auth: false,
        exact: true,
      },
      {
        component: "CommunityNotifications",
        path: "/communityForum/notifications",
        auth: false,
        exact: true,
      },
      {
        component: "MyForum",
        path: "/communityForum/myforum",
        auth: false,
        exact: true,
      },
    ],
  },
  {
    component: "FIGLayout",
    path: "/fig",
    auth: false,
    exact: false,
    childrens: [
      {
        component: "FIGPage",
        path: "/home",
        auth: false,
        exact: true,
      },
      {
        component: "ViewFIGMemberPage",
        path: "/member-profile",
        auth: false,
        exact: true,
      },
    ],
  },
  {
    component: "KYMLayout",
    path: "/kym",
    auth: false,
    exact: false,
    childrens: [
      {
        component: "KYMNewPage",
        path: "/kym-new",
        auth: false,
        exact: true,
      },
      {
        component: "KYMMemberListPage",
        path: "/kym-new-list/:id",
        auth: false,
        exact: true,
      },
      {
        component: "KYMMemberDetailsPage",
        path: "/kym-new-details",
        auth: false,
        exact: true,
      },
    ],
  },
];

export default routers;
