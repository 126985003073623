import React, { Component } from "react";
import "./OtherSuccess.scss";
import Swiper from "react-id-swiper";
import { arrow_right, arrow_left, success_stories_story2 } from "./icons";
import { Card } from "./card";

export class OtherSuccess extends Component {
  render() {
    const params = {
      slidesPerView: 2,
      spaceBetween: 5,
      draggable: true,
      navigation: {
        nextEl: "#next-story",
        prevEl: "#prev-story",
      },
      rebuildOnUpdate: true,
    };
    let {
      title,
      otherSuccessList = [],
      isLoader,
      getStoryId,
      handleDelete,
      editAccess = false,
      viewAccess = false,
      deleteAccess = false,
    } = this.props;
    return (
      <div className="other-success">
        <div className="other-success-container">
          <div className="d-flex justify-content-between mb-4">
            <div className="other-success-container-title ">{title}</div>
            <div className="d-flex align-items-end">
              <input
                type="image"
                src={arrow_left}
                alt="left"
                width="40"
                height="40"
                id="prev-story"
                onClick={() => {}}
              />
              <input
                type="image"
                src={arrow_right}
                alt="left"
                width="40"
                height="40"
                id="next-story"
              />
            </div>
          </div>
          <div>
            {otherSuccessList.length > 0 && !!otherSuccessList && !isLoader ? (
              <Swiper {...params}>
                {otherSuccessList.map((story, index) => {
                  return (
                    <div key={index} className="mr-3">
                      <Card
                        story={story}
                        img={success_stories_story2}
                        getId={getStoryId}
                        getStoryId={() =>
                          !!getStoryId
                            ? getStoryId(story.storyId, "stories")
                            : ""
                        }
                        handleDelete={handleDelete}
                        editAccess={editAccess}
                        viewAccess={viewAccess}
                        deleteAccess={deleteAccess}
                      />
                    </div>
                  );
                })}
              </Swiper>
            ) : (
              <p className="py-5 my-5 mb-0 fs-22 text-black w-100 text-center">
                No data found
              </p>
            )}
          </div>
        </div>
      </div>
    );
  }
}
