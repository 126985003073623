import { Member, memberApi } from "service/apiVariables";
import { Toast } from "service/toast";
import { api } from "service/api";
import { addQuery } from "service/helperFunctions";
import { HomeActionType } from "service/actionType";

export const memberBasicAdd = (body) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    api({ ...Member.addMemberBasic, body })
      .then((data) => {
        resolve(data);
        Toast({ type: "success", message: data.message, time: 3000 });
      })
      .catch(({ status, message }) => {
        resolve(status);
        reject(Toast({ type: "error", message }));
      });
  });
};
export const memberHouseHoldAdd = (body) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    api({ ...Member.addMemberHouseHold, body })
      .then(({ status, message }) => {
        resolve(status);
        Toast({ type: "success", message, time: 3000 });
      })
      .catch(({ message, status }) => {
        resolve(status);
        reject(Toast({ type: "error", message }));
      });
  });
};

export const updateMemberHouseHold = (body) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    api({ ...Member.updateMemberHouseHold, body })
      .then(({ status, message }) => {
        resolve(status);
        Toast({ type: "success", message, time: 3000 });
      })
      .catch(({ message, status }) => {
        resolve(status);
        reject(Toast({ type: "error", message }));
      });
  });
};

export const getMemberHouseHold = (body) => (dispatch, getState) => {
  let { getMemberHouseHold } = Member;

  getMemberHouseHold.id = body.id;

  return new Promise((resolve, reject) => {
    api({ ...Member.getMemberHouseHold, body })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

export const getMemberFarmDetail = (body) => (dispatch, getState) => {
  let { getMemberFarmDetail } = Member;

  getMemberFarmDetail.id = body.id;

  return new Promise((resolve, reject) => {
    api({ ...Member.getMemberFarmDetail, body })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

export const getMemberAsset = (body) => (dispatch, getState) => {
  let { getMemberAsset } = Member;

  getMemberAsset.id = body.id;

  return new Promise((resolve, reject) => {
    api({ ...Member.getMemberAsset, body })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};
export const getMemberLiveStock = (body) => (dispatch, getState) => {
  let { getMemberLiveStock } = Member;

  getMemberLiveStock.id = body.id;

  return new Promise((resolve, reject) => {
    api({ ...Member.getMemberLiveStock, body })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};
export const getMemberBankDetail = (body) => (dispatch, getState) => {
  let { getMemberBankDetail } = Member;

  getMemberBankDetail.id = body.id;

  return new Promise((resolve, reject) => {
    api({ ...Member.getMemberBankDetail, body })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

export const memberFramAdd = (body) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    api({ ...Member.addMemberFarm, body })
      .then(({ status, message }) => {
        resolve(status);
        Toast({ type: "success", message, time: 3000 });
      })
      .catch(({ message, status }) => {
        resolve(status);
        reject(Toast({ type: "error", message }));
      });
  });
};

export const updateMemberFarm = (body) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    api({ ...Member.updateMemberFarm, body })
      .then(({ status, message }) => {
        resolve(status);
        Toast({ type: "success", message, time: 3000 });
      })
      .catch(({ message, status }) => {
        resolve(status);
        reject(Toast({ type: "error", message }));
      });
  });
};

export const memberAssetAdd = (body) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    api({ ...Member.addMemberAsset, body })
      .then(({ status, message }) => {
        resolve(status);
        Toast({ type: "success", message, time: 3000 });
      })
      .catch(({ message, status }) => {
        resolve(status);
        reject(Toast({ type: "error", message }));
      });
  });
};
export const memberLivestockAdd = (body) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    api({ ...Member.addMemberLivestock, body })
      .then(({ status, message }) => {
        resolve(status);
        Toast({ type: "success", message, time: 3000 });
      })
      .catch(({ message, status }) => {
        resolve(status);
        reject(Toast({ type: "error", message }));
      });
  });
};
export const memberBankAdd = (body) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    api({ ...Member.addMemberBank, body })
      .then(({ status, message }) => {
        resolve(status);
        Toast({ type: "success", message, time: 3000 });
      })
      .catch(({ message, status }) => {
        resolve(status);
        reject(Toast({ type: "error", message }));
      });
  });
};
export const memberEdit = (body) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    api({ ...Member.EditBasicDetail, body })
      .then((data) => {
        resolve(data);
        Toast({ type: "success", message: data.message, time: 3000 });
      })
      .catch(({ status, message }) => {
        resolve(status);
        reject(Toast({ type: "error", message }));
      });
  });
};



  export const memberEditData = (body) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      api({ ...Member.EditBasicDetail, body })
        .then(({ status, message }) => {
          resolve(status);
          Toast({ type: "success", message, time: 3000 });
        })
        .catch(({ message, status }) => {
          resolve(status);
          reject(Toast({ type: "error", message }));
        });
    });
  };

export const EditAssetsDetail = (body) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    api({ ...Member.EditAssetsDetail, body })
      .then(({ status, message }) => {
        resolve(status);
        Toast({ type: "success", message, time: 3000 });
      })
      .catch(({ message, status }) => {
        resolve(status);
        reject(Toast({ type: "error", message }));
      });
  });
};
export const EditLiveStockDetail = (body) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    api({ ...Member.EditLiveStockDetail, body })
      .then(({ status, message }) => {
        resolve(status);
        Toast({ type: "success", message, time: 3000 });
      })
      .catch(({ message, status }) => {
        resolve(status);
        reject(Toast({ type: "error", message }));
      });
  });
};

export const EditBankDetail = (body) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    api({ ...Member.EditBankDetail, body })
      .then(({ status, message }) => {
        resolve(status);
        Toast({ type: "success", message, time: 3000 });
      })
      .catch(({ message, status }) => {
        resolve(status);
        reject(Toast({ type: "error", message }));
      });
  });
};

export const membersView = () => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    api({ ...Member.getMembersApi })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};
export const membersCardView = (query) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    addQuery(query, Member.getMembersCardApi);
    api({ ...Member.getMembersCardApi })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

export const allState = () => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    api({ ...Member.getStateApi })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

export const getcropBusinessInfra = () => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    api({ ...Member.getcropBusinessInfra })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};
export const getDistrictApiwithState = (body) => (dispatch, getState) => {
  let { getDistrictApiwithState } = Member;

  getDistrictApiwithState.id = body.id;

  return new Promise((resolve, reject) => {
    api({ ...Member.getDistrictApiwithState, body })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

export const getPincodeApiwithStateDistrict =
  (body) => (dispatch, getState) => {
    let { getPincodeApiwithStateDistrict } = Member;

    getPincodeApiwithStateDistrict.id = body.id;

    return new Promise((resolve, reject) => {
      api({ ...Member.getPincodeApiwithStateDistrict, body })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

export const getAboutusMemberDetail = () => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    api({ ...Member.getAboutusMemberDetail })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

export const getAboutusMemberDetailWithId = (id) => (dispatch, getState) => {
  let { getAboutusMemberDetailWithId } = Member;
  getAboutusMemberDetailWithId.id = id;
  return new Promise((resolve, reject) => {
    api({ ...Member.getAboutusMemberDetailWithId })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

export const allCrops = () => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    api({ ...Member.getAllCrops })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

export const updateAboutUsDetails = (body) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    api({ ...Member.updateAboutUsDetails, body })
      .then(({ data, message }) => {
        resolve(data);
        Toast({ type: "success", message, time: 3000 });
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

export const getMember = (body) => (dispatch, getState) => {
  let { getMember } = Member;

  getMember.id = body.id;

  return new Promise((resolve, reject) => {
    api({ ...Member.getMember, body })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

export const getAllFarmLiveStocks = () => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    api({ ...Member.getAllFarmLiveStocks })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};
export const allLiveStocks = () => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    api({ ...Member.getAllLiveStocks })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};
export const allAssetTypes = () => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    api({ ...Member.getAllAssetTypes })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

export const fileUpload =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...Member.fileUpload, body })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

export const currentTab = (payload) => (dispatch) => {
  dispatch({ type: HomeActionType.currentTab, payload });
};

//get member csv
export const getMemberProfileCsv = () => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    api({ ...Member.getMemberProfileCsv })
      .then((data) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

export const getDownloadKYMMembersData = () => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    api({ ...Member.getDownloadKYMMembersData })
      .then((data) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//get BOD csv
export const getBodProfileCsv = () => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    api({ ...Member.getBodProfileCsv })
      .then((data) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//get staff csv
export const getStaffProfileCsv = () => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    api({ ...Member.getStaffProfileCsv })
      .then((data) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//is First Time Login Or Not

export const isFirstTimeLoginOrNot = () => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    api({ ...Member.isFirstTimeLoginOrNot })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//Delete Live stock

export const deleteLiveStock = (body) => (dispatch, getState) => {
  let { deleteLiveStock } = Member;

  deleteLiveStock.id = body.id;
  deleteLiveStock.liveStockId = body.liveStockId;
  return new Promise((resolve, reject) => {
    api({ ...Member.deleteLiveStock })
      .then(({ data, message }) => {
        resolve(data);
        Toast({ type: "success", message });
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//Delete Asset

export const deleteAsset = (body) => (dispatch, getState) => {
  let { deleteAsset } = Member;

  deleteAsset.id = body.id;
  deleteAsset.assetId = body.assetId;
  return new Promise((resolve, reject) => {
    api({ ...Member.deleteAsset })
      .then(({ data, message }) => {
        resolve(data);
        Toast({ type: "success", message });
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//Delete Bank account

export const deleteBankDetails = (body) => (dispatch, getState) => {
  let { deleteBankDetails } = Member;

  deleteBankDetails.id = body.id;
  return new Promise((resolve, reject) => {
    api({ ...Member.deleteBankDetails })
      .then(({ data, message }) => {
        resolve(data);
        Toast({ type: "success", message });
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//delete Household Details

export const deleteHouseholdDetails = (body) => (dispatch, getState) => {
  let { deleteHouseholdDetails } = Member;

  deleteHouseholdDetails.id = body.id;
  return new Promise((resolve, reject) => {
    api({ ...Member.deleteHouseholdDetails })
      .then(({ data, message }) => {
        resolve(data);
        Toast({ type: "success", message });
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//Delete Farm details

export const deletefarmDetails = (body) => (dispatch, getState) => {
  let { deletefarmDetails } = Member;

  deletefarmDetails.id = body.id;
  return new Promise((resolve, reject) => {
    api({ ...Member.deletefarmDetails })
      .then(({ data, message }) => {
        resolve(data);
        Toast({ type: "success", message });
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//Delete Member

export const deleteMember = (body) => (dispatch, getState) => {
  let { deleteMember } = Member;

  deleteMember.id = body.id;
  return new Promise((resolve, reject) => {
    api({ ...Member.deleteMember })
      .then(({ data, message }) => {
        resolve(data);
        Toast({ type: "success", message });
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//get List Of Members To Sms
export const getListOfMembersToSms = () => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    api({ ...Member.getListOfMembersToSms })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//get sms language
export const getSmsLanguage = () => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    api({ ...Member.smsLanguage })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//get Message Details
export const getMessageDetails = () => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    api({ ...Member.getMessageDetails })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//get Message Details by language
export const getMessageDetailsByLanguage = (query) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    addQuery(query, Member.getMessageDetailsByLanguage);
    api({ ...Member.getMessageDetailsByLanguage, query })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//send Sms For Member
export const sendSmsForMember = (body) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    api({ ...Member.sendSmsForMember, body })
      .then(({ data, message }) => {
        resolve(data);
        Toast({ type: "success", message });
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//robo Calling Api
export const roboCallingApi = () => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    api({ ...Member.roboCallingApi })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//get List Of staff To Sms
export const getListOfStaffToSms = () => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    api({ ...Member.getListOfStaffToSms })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//send Sms For staff
export const sendSmsForStaff = (body) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    api({ ...Member.sendSmsForStaff, body })
      .then(({ data, message }) => {
        resolve(data);
        Toast({ type: "success", message });
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//get List Of BOD To Sms
export const getListOfBODToSms = () => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    api({ ...Member.getListOfBODToSms })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//send Sms For BOD
export const sendSmsForBod = (body) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    api({ ...Member.sendSmsForBod, body })
      .then(({ data, message }) => {
        resolve(data);
        Toast({ type: "success", message });
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//get Relationship History List
export const getRelationshipHistoryList = () => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    api({ ...Member.getRelationshipHistoryList })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//update Relationship History
export const updateRelationshipHistory = (body) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    api({ ...Member.updateRelationshipHistory, body })
      .then(({ data, message }) => {
        resolve(data);
        Toast({ type: "success", message });
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

export const getAllFig = () => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    api({ ...Member.getAllFig })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

export const mapFigToMember = (body) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    api({ ...Member.mapFigToMember, body })
      .then(({ data, message }) => {
        resolve(data);
        Toast({ type: "success", message });
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

export const getMemberFPOData =
  (id) =>
  (dispatch, getState, { api, Toast }) => {
    memberApi.getMemberPOData.id = id;
    return new Promise((resolve, reject) => {
      api({ ...memberApi.getMemberPOData })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

  export const getMemberModuleFPOMemberData =
  (id) =>
  (dispatch, getState, { api, Toast }) => {
    memberApi.getMemberModuleFPOMemberData.id = id;
    return new Promise((resolve, reject) => {
      api({ ...memberApi.getMemberModuleFPOMemberData })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

  export const addMemberModuleMember =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...memberApi.addMemberModuleMember, body })
        .then(({ data, message }) => {
          resolve(data);
          Toast({ type: "success", message });
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

  export const updateMemberModuleFPOMemberData =
  (id, body) =>
  (dispatch, getState, { api, Toast }) => {
    memberApi.updateMemberModuleFPOMemberData.id = id;
    return new Promise((resolve, reject) => {
      api({ ...memberApi.updateMemberModuleFPOMemberData, body })
        .then(({ data, message }) => {
          resolve(data);
          Toast({ type: "success", message });
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };