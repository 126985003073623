import React, { Component } from "react";
import { Col, Row } from "reactstrap";
import VideoPlayer from "react-video-js-player";
import { Modal, ModalBody } from "reactstrap";

import "./index.scss";
import { getDescriptionData } from "service/helperFunctions";
const play = require("assets/svg/preIntroVideo/play.svg");
const thumb = require("assets/svg/preIntroVideo/thumb.svg");

export class IntroVideoPre extends Component {
  constructor() {
    super();
    this.state = {
      modal: false,
      video: {
        videoUrl: "",
        description: "",
        title: "",
      },
    };
    this.myref = React.createRef();
  }
  onPlayerReady(player, duration = 5) {
    player.currentTime(duration);
    this.player = player;
  }
  toggle = () => {
    this.setState({ modal: !this.state.modal });
  };
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.video !== this.props.video) {
      this.setState({
        video: this.props.video,
      });
    }
  }
  onVideoPlay(duration) {
    this.setState({
      videoDuration: duration,
    });
  }
  seek(secs) {
    let time = this.player.currentTime() + secs;

    if (time < 0) {
      time = 0;
    }

    this.player.currentTime(time);
  }
  render() {
    const { modal, video, videoDuration = "" } = this.state;
    const { videoUrl = "", description = "", title = "" } = video;
    return (
      <div className="intro-video-pre">
        {videoUrl !== "" && (
          <div className="container-sa">
            <Row>
              <Col sm="8" className="">
                <div className="video-container">
                  <img className="tumbnail" src={thumb} />
                  <div
                    className="play-button cursor-pointer"
                    onClick={this.toggle}
                  >
                    <img className="play-icon" src={play} alt="play" />
                  </div>
                </div>
              </Col>
              <Col sm="4">
                <div className="shadow video-info-container">
                  <div>
                    <div className="title">{getDescriptionData(title, 50)}</div>
                    <div className="description ">
                      {" "}
                      {getDescriptionData(description, 200)}
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        )}
        <Modal
          isOpen={modal}
          toggle={this.toggle}
          className={"popupVideo d-flex justify-content-center"}
        >
          <button onClick={this.toggle} type="button" className="close ">
            <span aria-hidden="true">×</span>
          </button>

          <ModalBody>
            <VideoPlayer
              ref={this.myref}
              controls={true}
              src={videoUrl}
              onReady={this.onPlayerReady.bind(this)}
              onTimeUpdate={this.onVideoPlay.bind(this)}
            />
            {/* <div>
                     {videoDuration > 5 && (
                        <div
                           className='button-backward'
                           onClick={() => this.seek(-5)}
                        >
                           {'<<'}
                        </div>
                     )}
                     <div
                        className='button-forward'
                        onClick={() => this.seek(5)}
                     >
                        {'>>'}
                     </div>
                  </div> */}
          </ModalBody>
        </Modal>
      </div>
    );
  }
}
