import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  addRegistrar,
  getRegistrarById,
  updateRegistrar,
  deleteRegistrar,
  getAllFinancialYearDropdown,
  complianceCurrentTab,
} from "action/ComplianceAct";
import { fileUpload } from "action/MemberAct";
import moment from "moment";

import { history } from "service/helpers/index";
import { Toast } from "service/toast";
import {
  NormalInput,
  NormalButton,
  NormalDate,
  NormalSelect,
  CommonPageLoader,
  ConfirmationPopup,
} from "component/common";
import SimpleReactValidator from "simple-react-validator";
import trash from "assets/svg/Associations/trash.svg";
import { getAccessdata } from "service/helperFunctions";
const arrow_left = require("assets/svg/add_member/arrow_left.svg");

class AddRegistrarClass extends Component {
  state = {
    financialYear: "",
    consultatName: "",
    remarks: "",
    dateOfFiling: "",
    dueDate: "",
    location: "",
    balanceSheetFileUrl: null,
    balanceSheetFileName: "",
    profitLossAccountFileUrl: null,
    profitLossAccountFileName: "",
    auditReportFileUrl: null,
    auditReportFileName: "",
    directorsReportFileUrl: null,
    directorsReportFileName: "",
    isImageUploading: false,
    allFinance: [],
    isLoading: false,
    isprofitLossmageUploading: false,
    isauditReportUploading: false,
    isdirectorsReportUploading: false,
    isDelete: false,
  };

  //Validation
  validator = new SimpleReactValidator({
    element: (message) => (
      <span className="error-message text-danger fs-14">{message}</span>
    ),
    autoForceUpdate: this,
  });

  handleBalanceSheetFile = (e) => {
    let file = e.target.files[0];
    if (file !== undefined) {
      this.setState({ balanceSheetFileUrl: null }, () => {
        this.setState({
          isImageUploading: true,
          balanceSheetFileName: "Uploading ....",
          balanceSheetFileUrl: file,
        });
        const formData = new FormData();
        formData.append("file", file);
        this.setState({ isImageUploading: true });
        this.props
          .fileUpload(formData)
          .then((data) => {
            document.getElementById("handleBalanceSheetFile").value = null;
            this.setState({
              balanceSheetFileUrl: data[0].fileUrl,
              balanceSheetFileName: data[0].fileName,
              isImageUploading: false,
            });
          })
          .catch(() => {
            this.setState({
              balanceSheetFileUrl: null,
              balanceSheetFileName: "",
              isImageUploading: false,
            });
          });
      });
    }
  };

  //handle Profit Loss Account File
  handleProfitLossAccountFile = (e) => {
    let file = e.target.files[0];
    if (file !== undefined) {
      this.setState({ profitLossAccountFileUrl: null }, () => {
        this.setState({
          isprofitLossmageUploading: true,
          profitLossAccountFileName: "Uploading ....",
          profitLossAccountFileUrl: file,
        });

        const formData = new FormData();
        formData.append("file", file);
        this.props
          .fileUpload(formData)
          .then((data) => {
            document.getElementById("handleProfitLossAccountFile").value = null;
            this.setState({
              profitLossAccountFileUrl: data[0].fileUrl,
              profitLossAccountFileName: data[0].fileName,
              isprofitLossmageUploading: false,
            });
          })
          .catch(() => {
            this.setState({
              profitLossAccountFileUrl: null,
              profitLossAccountFileName: "",
              isprofitLossmageUploading: false,
            });
          });
      });
    }
  };

  //handle Audit Report File
  handleAuditReportFile = (e) => {
    let file = e.target.files[0];
    if (file !== undefined) {
      this.setState({ auditReportFileUrl: null }, () => {
        this.setState({
          isauditReportUploading: true,
          auditReportFileName: "Uploading ....",
          auditReportFileUrl: file,
        });
        const formData = new FormData();
        formData.append("file", file);
        this.props
          .fileUpload(formData)
          .then((data) => {
            document.getElementById("handleAuditReportFile").value = null;
            this.setState({
              auditReportFileUrl: data[0].fileUrl,
              auditReportFileName: data[0].fileName,
              isauditReportUploading: false,
            });
          })
          .catch(() => {
            this.setState({
              auditReportFileUrl: null,
              auditReportFileName: "",
              isauditReportUploading: false,
            });
          });
      });
    }
  };

  //handle Directors Report File
  handleDirectorsReportFile = (e) => {
    let file = e.target.files[0];
    if (file !== undefined) {
      this.setState({ directorsReportFileUrl: null }, () => {
        this.setState({
          isdirectorsReportUploading: true,
          directorsReportFileName: "Uploading ....",
          directorsReportFileUrl: file,
        });
        const formData = new FormData();
        formData.append("file", file);
        this.props
          .fileUpload(formData)
          .then((data) => {
            document.getElementById("handleDirectorsReportFile").value = null;
            this.setState({
              directorsReportFileUrl: data[0].fileUrl,
              directorsReportFileName: data[0].fileName,
              isdirectorsReportUploading: false,
            });
          })
          .catch(() => {
            this.setState({
              directorsReportFileUrl: null,
              directorsReportFileName: "",
              isdirectorsReportUploading: false,
            });
          });
      });
    }
  };

  handleInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  handleSubmit = () => {
    this.setState({ isLoading: true });
    if (this.validator.allValid()) {
      const {
        financialYear,
        consultatName,
        remarks,
        isImageUploading,
        dateOfFiling,
        dueDate,
        location,
        balanceSheetFileUrl,
        balanceSheetFileName,
        profitLossAccountFileUrl,
        profitLossAccountFileName,
        auditReportFileUrl,
        auditReportFileName,
        directorsReportFileUrl,
        directorsReportFileName,
        allFinance,
        isprofitLossmageUploading,
        isauditReportUploading,
        isdirectorsReportUploading,
      } = this.state;
      if (
        !isImageUploading &&
        !isprofitLossmageUploading &&
        !isauditReportUploading &&
        !isdirectorsReportUploading
      ) {
        let body = {
          fymId: parseInt(financialYear),
          fymYear: "",
        };
        if (remarks !== "") {
          body.remarks = remarks;
        }
        if (location !== "") {
          body.location = location;
        }
        if (dueDate !== "") {
          body.dueDate = moment(dueDate).format("YYYY-MM-DD");
        }
        if (dateOfFiling !== "") {
          body.dateOfFiling = moment(dateOfFiling).format("YYYY-MM-DD");
        }
        if (consultatName !== "") {
          body.consultatName = consultatName;
        }
        if (directorsReportFileName !== "") {
          body.directorsReportFileName = directorsReportFileName;
          body.directorsReportFileUrl = directorsReportFileUrl;
        }
        if (auditReportFileName !== "") {
          body.auditReportFileName = auditReportFileName;
          body.auditReportFileUrl = auditReportFileUrl;
        }
        if (profitLossAccountFileName !== "") {
          body.profitLossAccountFileName = profitLossAccountFileName;
          body.profitLossAccountFileUrl = profitLossAccountFileUrl;
        }
        if (balanceSheetFileName !== "") {
          body.balanceSheetFileName = balanceSheetFileName;
          body.balanceSheetFileUrl = balanceSheetFileUrl;
        }
        let dataSet = allFinance.filter((x) => x.value == financialYear);
        if (dataSet.length > 0) {
          body.fymYear = dataSet[0].label;
        }
        if (this.props.id) {
          body.id = parseInt(this.props.id);
          this.props
            .updateRegistrar(body)
            .then(() => {
              this.validator.hideMessages();
              this.handleBack();
            })
            .catch(() => {
              this.setState({ isLoading: false });
            });
        } else {
          this.props
            .addRegistrar(body)
            .then(() => {
              this.validator.hideMessages();
              this.setState({
                financialYear: "",
                consultatName: "",
                remarks: "",
                dateOfFiling: "",
                dueDate: "",
                location: "",
                balanceSheetFileUrl: null,
                balanceSheetFileName: "",
                profitLossAccountFileUrl: null,
                profitLossAccountFileName: "",
                auditReportFileUrl: null,
                auditReportFileName: "",
                directorsReportFileUrl: null,
                directorsReportFileName: "",
                isImageUploading: false,
                isLoading: false,
              });
              this.handleBack();
            })
            .catch(() => {
              this.setState({ isLoading: false });
            });
        }
      } else {
        Toast({ type: "error", message: "Please wait Image is uploading" });
        this.setState({ isLoading: false });
      }
    } else {
      this.setState({ isLoading: false });

      this.validator.showMessages();
      this.forceUpdate();
    }
  };
  handleDelete = () => {
    this.setState({ isLoading: true });
    this.props.deleteRegistrar(this.props.id).then(() => {
      this.handleBack();
    });
  };

  toggleDelete = () => {
    let { isDelete } = this.state;
    this.setState({
      isDelete: !isDelete,
    });
  };

  componentDidMount() {
    this.props.getAllFinancialYearDropdown().then((data) => {
      let dataSet = [];

      data.financialYearMaster.map((list) => {
        dataSet.push({ value: list.fymId, label: list.fymYear });
      });
      this.setState({
        allFinance: dataSet,
      });
    });

    if (this.props.id) {
      this.setState({ isLoading: true });

      this.props.getRegistrarById(this.props.id).then((data) => {
        let {
          fymId = "",
          consultatName = "",
          remarks = "",
          dateOfFiling = "",
          location = "",
          balanceSheetFileUrl = null,
          balanceSheetFileName = "",
          profitLossAccountFileUrl = null,
          profitLossAccountFileName = "",
          auditReportFileUrl = null,
          auditReportFileName = "",
          directorsReportFileUrl = null,
          directorsReportFileName = "",
          dueDate = "",
        } = data;
        this.setState({
          isLoading: false,
          financialYear: fymId ? fymId : "",
          consultatName: consultatName ? consultatName : "",
          remarks: remarks ? remarks : "",
          dateOfFiling: dateOfFiling ? new Date(dateOfFiling) : "",
          dueDate: dueDate ? new Date(dueDate) : "",
          location: location ? location : "",
          balanceSheetFileUrl: balanceSheetFileUrl ? balanceSheetFileUrl : null,
          balanceSheetFileName: balanceSheetFileName
            ? balanceSheetFileName
            : "",
          profitLossAccountFileUrl: profitLossAccountFileUrl
            ? profitLossAccountFileUrl
            : null,
          profitLossAccountFileName: profitLossAccountFileName
            ? profitLossAccountFileName
            : "",
          auditReportFileUrl: auditReportFileUrl ? auditReportFileUrl : null,
          auditReportFileName: auditReportFileName ? auditReportFileName : "",
          directorsReportFileUrl: directorsReportFileUrl
            ? directorsReportFileUrl
            : null,
          directorsReportFileName: directorsReportFileName
            ? directorsReportFileName
            : "",
        });
      });
    }
  }

  handleRemoveImage = (picName) => {
    if (picName === "balanceSheet") {
      this.setState({
        balanceSheetFileUrl: null,
        balanceSheetFileName: "",
      });
    } else if (picName === "profitLoss") {
      this.setState({
        profitLossAccountFileUrl: null,
        profitLossAccountFileName: "",
      });
    } else if (picName === "auditReport") {
      this.setState({
        auditReportFileUrl: null,
        auditReportFileName: "",
      });
    } else if (picName === "directorsReport") {
      this.setState({
        directorsReportFileUrl: null,
        directorsReportFileName: "",
      });
    }
  };

  handleBack = () => {
    this.props.complianceCurrentTab(4);
    history.push("/fpo/compliance");
  };

  render() {
    const {
      dateOfFiling,
      dueDate,
      financialYear,
      location,
      consultatName,
      remarks,
      balanceSheetFileUrl,
      balanceSheetFileName,
      profitLossAccountFileUrl,
      profitLossAccountFileName,
      auditReportFileUrl,
      auditReportFileName,
      directorsReportFileUrl,
      directorsReportFileName,
      allFinance,
      isLoading,
      isImageUploading,
      isprofitLossmageUploading,
      isauditReportUploading,
      isdirectorsReportUploading,
      isDelete,
    } = this.state;

    let { accessData } = this.props;
    let permissionData = getAccessdata(accessData, "complainceGovernance");

    let { deleteAccess = false } = permissionData;

    return (
      <div>
        <div className="d-flex container-sa my-3 ">
          <div
            onClick={() => this.handleBack()}
            className="d-flex cursor-pointer"
          >
            <img className="cursor-pointer" src={arrow_left} alt="back" />
            <p className="ml-3 add-member-text-back mb-0">
              {this.props.id ? "Edit" : "Add"} Registrar of Companies
            </p>
          </div>
        </div>
        {!isLoading ? (
          <div className="add-form">
            <div className="container bg-white py-4">
              <div className="row">
                <div className="col-6">
                  <NormalSelect
                    isHalfWidthForMemberProfile={true}
                    label={"Financial year"}
                    placeholder="Select"
                    value={financialYear}
                    arrow={true}
                    name="financialYear"
                    options={allFinance}
                    handleChange={this.handleInput}
                    required={true}
                  />
                  <div className="row">
                    <div className="col-8 ml-auto">
                      {this.validator.message(
                        "financialYear",
                        financialYear,
                        "required"
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <NormalDate
                    isIcon={true}
                    isHalfWidthForMemberProfile={true}
                    value={dueDate}
                    name="dueDate"
                    placeholder="DD/MM/YYYY"
                    onChange={this.handleInput}
                    mindate={false}
                    maxdate={false}
                    timeInput={false}
                    label={"Due date"}
                    required={true}
                  />
                  <div className="row">
                    <div className="col-8 ml-auto">
                      {this.validator.message("Due date", dueDate, "required")}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row compliance-add-row">
                <div className="col-6">
                  <NormalDate
                    isIcon={true}
                    isHalfWidthForMemberProfile={true}
                    value={dateOfFiling}
                    name="dateOfFiling"
                    placeholder="DD/MM/YYYY"
                    onChange={this.handleInput}
                    mindate={false}
                    maxdate={true}
                    timeInput={false}
                    label={"Date of filing"}
                  />
                </div>
                <div className="col-6">
                  <NormalInput
                    label="Location filed"
                    type="text"
                    onChange={this.handleInput}
                    value={location}
                    name="location"
                    isHalfWidthForMemberProfile={true}
                  />
                  <div className="row">
                    <div className="col-8 ml-auto">
                      {this.validator.message(
                        "location",
                        location,
                        "max:50|alpha_space"
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="row compliance-add-row">
                <div className="col-6">
                  <NormalInput
                    label="Consultant name"
                    type="text"
                    onChange={this.handleInput}
                    value={consultatName}
                    name="consultatName"
                    isHalfWidthForMemberProfile={true}
                  />
                  <div className="row">
                    <div className="col-8 ml-auto">
                      {this.validator.message(
                        "consultant Name",
                        consultatName,
                        "max:30|alpha_space"
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <NormalInput
                    label="Remarks"
                    type="text"
                    onChange={this.handleInput}
                    value={remarks}
                    name="remarks"
                    isHalfWidthForMemberProfile={true}
                  />
                  <div className="row">
                    <div className="col-8 ml-auto">
                      {this.validator.message(
                        "remarks",
                        remarks,
                        "max:100|alpha_space"
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <div className="row upload-area">
                    <div className="col-4">
                      <div className="fs-16 text-black-32 font-weight-bold line-height-19">
                        {"Upload Balance Sheet"}
                      </div>
                    </div>
                    <div className="col-8">
                      <div className="d-flex align-items-center btn-file-input">
                        <button className="">
                          <input
                            type="file"
                            className=""
                            accept=".jpg, .png, .jpeg"
                            onChange={this.handleBalanceSheetFile}
                            id="handleBalanceSheetFile"
                            title=""
                          />
                          {balanceSheetFileUrl == null ? (
                            "Choose file"
                          ) : (
                            <>
                              {balanceSheetFileName.length == 10
                                ? balanceSheetFileName
                                : balanceSheetFileName.substring(0, 9)}
                              {"..."}
                            </>
                          )}
                        </button>
                        {balanceSheetFileName != "" && !isImageUploading && (
                          <div className="pl-3 position-relative">
                            <img
                              src={balanceSheetFileUrl}
                              alt={balanceSheetFileName}
                              height="75"
                              width="75"
                            />
                            <i
                              className="icon-Delete text-danger fs-14 pl-2 cursor-pointer position-absolute"
                              onClick={() =>
                                this.handleRemoveImage("balanceSheet")
                              }
                            ></i>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="row upload-area">
                    <div className="col-4">
                      <div className="fs-16 text-black-32 font-weight-bold line-height-19">
                        {"Upload Profit & Loss Statement"}
                      </div>
                    </div>
                    <div className="col-8">
                      <div className="d-flex align-items-center btn-file-input">
                        <button className="">
                          <input
                            type="file"
                            className=""
                            accept=".jpg, .png, .jpeg"
                            onChange={this.handleProfitLossAccountFile}
                            id="handleProfitLossAccountFile"
                            title=""
                          />
                          {profitLossAccountFileUrl == null ? (
                            "Choose file"
                          ) : (
                            <>
                              {profitLossAccountFileName.length == 10
                                ? profitLossAccountFileName
                                : profitLossAccountFileName.substring(0, 9)}
                              {"..."}
                            </>
                          )}
                        </button>
                        {profitLossAccountFileName != "" &&
                          !isprofitLossmageUploading && (
                            <div className="pl-3 position-relative">
                              <img
                                src={profitLossAccountFileUrl}
                                alt={profitLossAccountFileName}
                                height="75"
                                width="75"
                              />
                              <i
                                className="icon-Delete text-danger fs-14 pl-2 cursor-pointer position-absolute"
                                onClick={() =>
                                  this.handleRemoveImage("profitLoss")
                                }
                              ></i>
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <div className="row upload-area">
                    <div className="col-4">
                      <div className="fs-16 text-black-32 font-weight-bold line-height-19">
                        {"Upload Audit Report"}
                      </div>
                    </div>
                    <div className="col-8">
                      <div className="d-flex align-items-center btn-file-input">
                        <button className="">
                          <input
                            type="file"
                            className=""
                            accept=".jpg, .png, .jpeg"
                            onChange={this.handleAuditReportFile}
                            id="handleAuditReportFile"
                            title=""
                          />
                          {auditReportFileUrl == null ? (
                            "Choose file"
                          ) : (
                            <>
                              {auditReportFileName.length == 10
                                ? auditReportFileName
                                : auditReportFileName.substring(0, 9)}
                              {"..."}
                            </>
                          )}
                        </button>
                        {auditReportFileName != "" && !isauditReportUploading && (
                          <div className="pl-3 position-relative">
                            <img
                              src={auditReportFileUrl}
                              alt={auditReportFileName}
                              height="75"
                              width="75"
                            />
                            <i
                              className="icon-Delete text-danger fs-14 pl-2 cursor-pointer position-absolute"
                              onClick={() =>
                                this.handleRemoveImage("auditReport")
                              }
                            ></i>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="row upload-area">
                    <div className="col-4">
                      <div className="fs-16 text-black-32 font-weight-bold line-height-19">
                        {"Upload Director’s report"}
                      </div>
                    </div>
                    <div className="col-8">
                      <div className="d-flex align-items-center btn-file-input">
                        <button className="">
                          <input
                            type="file"
                            className=""
                            accept=".jpg, .png, .jpeg"
                            onChange={this.handleDirectorsReportFile}
                            id="handleDirectorsReportFile"
                            title=""
                          />
                          {directorsReportFileUrl == null ? (
                            "Choose file"
                          ) : (
                            <>
                              {directorsReportFileName.length == 10
                                ? directorsReportFileName
                                : directorsReportFileName.substring(0, 9)}
                              {"..."}
                            </>
                          )}
                        </button>
                        {directorsReportFileName != "" &&
                          !isdirectorsReportUploading && (
                            <div className="pl-3 position-relative">
                              <img
                                src={directorsReportFileUrl}
                                alt={directorsReportFileName}
                                height="75"
                                width="75"
                              />
                              <i
                                className="icon-Delete text-danger fs-14 pl-2 cursor-pointer position-absolute"
                                onClick={() =>
                                  this.handleRemoveImage("directorsReport")
                                }
                              ></i>
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="pt-5 pb-3 d-flex">
              <div className="mx-auto d-flex">
                {this.props.id && deleteAccess && (
                  <div className="cursor-pointer d-flex align-items-center mr-5">
                    <div
                      className="text-center fs-14 fw-600 text-danger d-flex"
                      onClick={this.toggleDelete}
                    >
                      <img className="mr-2" src={trash} alt="icon" />
                      Delete
                    </div>
                  </div>
                )}
                <NormalButton
                  label={`${this.props.id ? "Update" : "Submit & next"}`}
                  mainbg={true}
                  className="px-3 py-2 fs-14 line-height-26"
                  onClick={this.handleSubmit}
                />
              </div>
            </div>
          </div>
        ) : (
          <>
            {/* Loader */}
            <CommonPageLoader />
          </>
        )}
        <ConfirmationPopup
          isOpen={isDelete}
          toggle={this.toggleDelete}
          onSubmit={this.handleDelete}
        />
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  accessData: state.home.accessData,
});
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      addRegistrar,
      fileUpload,
      getRegistrarById,
      getAllFinancialYearDropdown,
      updateRegistrar,
      deleteRegistrar,
      complianceCurrentTab,
    },
    dispatch
  );
};
let component = AddRegistrarClass;

export const AddRegistrar = connect(
  mapStateToProps,
  mapDispatchToProps
)(component);
