import React, { Component } from 'react';
import { getDescriptionData } from 'service/helperFunctions';
import { history } from 'service/helpers';
export class BlogCard extends Component {

    render() {
        const { blogList = {}, handleAddFavourities = {}, handledeleteFavourities = {}, currentIndex = '', } = this.props;

        let {
            blogId = '',
            blogImageFileUrl = '',
            shortDescription = '',
            title = '',
            category = {},
            isFavourite = false,
            isFavouriteAdding = false
        } = blogList

        let {
            categoryName = ''
        } = category
        return (
            <div className='blog-card shadow'>
                <div className="position-relative">
                    <img
                        className='card-img-top '
                        src={blogImageFileUrl}
                        alt='events'
                    />
                    <div id="overlay"></div>
                    <div className="favourite-add-area" onClick={(e) => e.stopPropagation()}>
                        {!isFavouriteAdding ?
                            isFavourite ?
                                <i className="icon-heart-white fs-18 cursor-pointer"
                                    onClick={() => handledeleteFavourities(blogId, currentIndex)}
                                ></i>
                                :
                                <i className="icon-heart-white-border fs-18 cursor-pointer"
                                    onClick={() => handleAddFavourities(blogId, currentIndex)}
                                ></i>
                            :
                            <div
                                className='spinner-border ml-2 spinner-border-sm'
                                role='status'
                            >
                            </div>
                        }
                    </div>
                </div>
                <div className='card-body d-flex flex-column justify-content-between p-3'>
                    <div>
                        <p className="mb-1 fs-18 text-gold-d0 line-height-23 fw-800">{categoryName}</p>
                        <p className="mb-1 fs-16 text-green-18 line-height-23 fw-600">{getDescriptionData(title, 25)}</p>
                        <p className="mb-1 fs-16 text-black-50 line-height-23">{getDescriptionData(shortDescription, 60)}</p>
                    </div>
                    <div
                        className='mx-auto text-green-18 font-weight-bold cursor-pointer fs-16'
                        onClick={() =>
                            history.push(`/academy/blog/${blogId}`)
                        }
                    >
                        {'View more'}
                    </div>
                </div>
            </div>
        );
    }
}

export default BlogCard;
