
import React from "react";
import { useHistory } from "react-router-dom";
import { Col, Row } from "reactstrap";
import { getDescriptionData } from "service/helperFunctions";
import "./style.scss";

export const ReportCard = (props) => {
  const { content, details, detailsKey } = props;
  const { reportImg, title, description } = details;

  const history = useHistory();

  return (
    <div className="new-card rounded shadow">
      <div>
        <img className="thumb" src={reportImg} alt="reportImg" />
      </div>
      <div className="p-3 "> 
        <Row>     
          <Col md={`${content === "News" ? "12" : "10"}`}>
            <div className="font-weight-bold title fs-18 mb-1">
              {getDescriptionData(title, 50)}
            </div>
            <div
              className="desc"
              dangerouslySetInnerHTML={{
                __html: description,
              }}
            ></div>
            <div className="link">
              <p
                className="text-decoration-none text-green-18 cursor-pointer"
                onClick={() =>{
                    history.push({
                        pathname: `/landing/report/${detailsKey}`,
                        state: details
                    });
                }}
              >
                {content === "Reports" ? "Read more" : "Register"}
              </p>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};
