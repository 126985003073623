import React, { Component } from "react";
import BackButton from "component/common/BackButton";
import MemberDetails from "component/RM/RMKYMNew/MemberDetails";
export class MemberDetailsPage extends Component {
  componentDidMount() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  render() {
    return (
      <div>
        <div className="inner-header">
          <div className="h-100 w-100">
            <p className="rm_title d-flex justify-content-center align-items-center h-100 w-100 text-white fs-32 font-weight-bold">
              KYM Survey Form
            </p>
          </div>
        </div>
        <BackButton />
        <MemberDetails id={this.props.match.params.id} module="rm" />
      </div>
    );
  }
}
