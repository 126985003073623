import React, { Component } from "react";

import about_1 from "assets/images/about/image-19.svg";
import about_2 from "assets/images/about/image-17.svg";
import about_3 from "assets/images/about/image-20.svg";
import about_4 from "assets/images/about/image-21.svg";
import about_5 from "assets/images/about/image-16.svg";
import about_6 from "assets/images/about/image-18.svg";
import aboutusProfile from "assets/images/aboutus-profile.png";
import facebook from "assets/images/facebook.png";
import instagram from "assets/images/instagram-logo-vector-download 2.png";
import twitter from "assets/images/Twitter-Logo.png";
import location from "assets/images/location-icon.png";
import camera from "assets/images/camera.png";
import {
  getAccessdata,
  moneyFormatBySymbol,
  letterAvatar,
} from "service/helperFunctions";
import moment from "moment";
import { history } from "service/helpers";
import {
  OurCrops,
  OurTeam,
  OurBusinessActivites,
  Licenses,
  OurInfrastructure,
  Awards,
  SuccessStory,
  Events,
} from "component/Aboutus";

import { CommonPageLoader } from "component/common";

import {
  getAboutusMemberDetail,
  getAboutusMemberDetailWithId,
  updateAboutUsDetails,
  fileUpload,
} from "action/MemberAct";

import {
  getallEventsData,
  getallEventsDataWithId,
} from "action/eventsAndImpactStories";

import { getLicenseData, getLicenseDataWithId } from "action/ComplianceAct";
import { getSignUpEntityType } from "action/RegAct";

import {
  getAboutUsById,
  getComplianceForFPOById,
  getEventsandImpactsOfFPOById,
} from "action/RmAct";

// Redux Connection
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { AboutCard } from "./AboutCard";

import "./about.scss";

import membership from "assets/svg/fpo_gateway/membership.svg";
import fpo_docs from "assets/svg/fpo_gateway/fpo_docs.svg";
import grade_score from "assets/svg/fpo_gateway/grade_score.svg";
import compliance from "assets/svg/fpo_gateway/compliance.svg";
import events_impact from "assets/svg/fpo_gateway/events_impact.svg";
import SimpleReactValidator from "simple-react-validator";
import { FPOformFields } from "component/auth/registration/FPO/RegFpoDatas";

const fpoManagementList = [
  {
    icon: membership,
    name: "Membership",
    link: "/rm/fpo/member",
    serviceName: "membership",
  },
  {
    icon: fpo_docs,
    name: "FPO Documents",
    link: "/rm/fpo/documents",
    serviceName: "fpoDocuments",
  },
  {
    icon: grade_score,
    name: "Grading Profile",
    link: "/rm/fpo/grading-score",
    serviceName: "gradeProfile",
  },
  {
    icon: compliance,
    name: "Compliance & Governance",
    link: "/rm/fpo/compliance",
    serviceName: "complainceGovernance",
  },
  {
    icon: events_impact,
    name: "Events & Impacts Stories",
    link: "/rm/fpo/events",
    serviceName: "eventsImpacts",
  },
  {
    icon: events_impact,
    name: "Key Financials",
    link: "/rm/fpo/key-financial",
    serviceName: "keyFinancials",
  },
  {
    icon: events_impact,
    name: "Associations & Partnerships",
    link: "/rm/fpo/association",
    serviceName: "associationPartnerships",
  },
];

export class AboutClass extends Component {
  state = {
    aboutDetail: {},
    pastEvents: [],
    upcomingEvents: [],
    fposuccessstories: [],
    fpoawards: [],
    isLoading: false,
    pastEvent: false,
    licenseData: [],
    staffList: [],
    bodList: [],
    isfileUploading: false,
    entityList: [],
    societyTypeOptions: [],
    formFieldsFPO: { ...FPOformFields },
  };

  componentDidMount = () => {
    let { name } = this.props;
    if (this.props.id == undefined) {
      let { getAboutusMemberDetail, getallEventsData, getLicenseData } =
        this.props;

      this.setState({ isLoading: true });

      getAboutusMemberDetail().then((data) => {
        this.setState({
          aboutDetail: data,
        });

        getLicenseData().then(({ data }) => {
          this.setState({ licenseData: data });
        });

        getallEventsData().then(({ data }) => {
          let { pastEvents, upcomingEvents, fposuccessstories, fpoawards } =
            data;
          this.setState({
            pastEvents,
            upcomingEvents,
            fposuccessstories,
            fpoawards,
            isLoading: false,
          });
        });
      });
    } else if (name) {
      let {
        getAboutusMemberDetailWithId,
        getLicenseDataWithId,
        getallEventsDataWithId,
        id,
      } = this.props;
      this.setState({ isLoading: true });

      getAboutusMemberDetailWithId(id).then((data) => {
        this.setState({
          aboutDetail: data,
        });

        getLicenseDataWithId(id).then(({ data }) => {
          this.setState({ licenseData: data });
        });

        getallEventsDataWithId(id).then(({ data }) => {
          let { pastEvents, upcomingEvents, fposuccessstories, fpoawards } =
            data;
          this.setState({
            pastEvents,
            upcomingEvents,
            fposuccessstories,
            fpoawards,
            isLoading: false,
          });
        });
      });
    } else {
      let {
        getAboutUsById,
        getComplianceForFPOById,
        getEventsandImpactsOfFPOById,
        id,
      } = this.props;
      this.setState({ isLoading: true });
      getAboutUsById(id).then((data) => {
        this.setState({
          aboutDetail: data,
        });
        getComplianceForFPOById(id).then((data) => {
          this.setState({ licenseData: data });
        });
        getEventsandImpactsOfFPOById(id).then((data) => {
          let { pastEvents, upcomingEvents, fposuccessstories, fpoawards } =
            data;
          this.setState({
            pastEvents,
            upcomingEvents,
            fposuccessstories,
            fpoawards,
            isLoading: false,
          });
        });
      });
    }
    this._getEntityList();
    this.setState({
      formFieldsFPO: { ...FPOformFields },
    });
    let societyTypeData = [
      { value: "pacs", label: "PACS" },
      { value: "dairy", label: "Dairy Society" },
      { value: "veg_fed", label: "Veg Fed Society" },
      { value: "lamps", label: "LAMPS" },
    ];
    this.setState({
      societyTypeOptions: societyTypeData,
    });
  };

  _getEntityList = () => {
    this.props.getSignUpEntityType().then((data) => {
      let entityData = [];
      data.map((list) => {
        entityData.push({ value: list.entityId, label: list.entityName });
      });
      this.setState({
        entityList: entityData,
      });
    });
  };

  _getProfileDetails = () => {
    let { getAboutusMemberDetail } = this.props;
    this.setState({ isLoading: true });
    getAboutusMemberDetail().then((data) => {
      this.setState({
        aboutDetail: data,
        isLoading: false,
      });
    });
  };

  uploadSingleFile = (e) => {
    let file = e.target.files[0];
    const formData = new FormData();
    formData.append("file", file);
    this.setState({ isfileUploading: true, isLoading: true });
    this.props.fileUpload(formData).then((data) => {
      let { aboutDetail } = this.state;
      let body = {
        ...aboutDetail,
        fpoLogoURL: data[0].fileUrl,
        fpoLogoName: data[0].fileName,
      };
      this.props.updateAboutUsDetails(body);
      this.setState({ isfileUploading: false, isLoading: false });
      this._getProfileDetails();
    });
  };

  handleAddPicture = () => {
    let { aboutDetail } = this.state;
    let body = {
      ...aboutDetail,
      fpoLogoURL: "",
      fpoLogoName: "",
    };
    console.log("body", body);
    this.props.updateAboutUsDetails(body);
  };

  eventToggle = () => {
    this.setState({
      pastEvent: !this.state.pastEvent,
    });
  };

  handleChange = ({ target: { name, value } }) => {
    let { formFieldsFPO } = this.state;
    formFieldsFPO[name] = value;
    this.setState({
      formFieldsFPO: { ...formFieldsFPO },
    });
  };

  render() {
    let {
      aboutDetail,
      isLoading,
      pastEvents,
      upcomingEvents,
      fposuccessstories,
      fpoawards,
      formFieldsFPO,
      entityList,
      societyTypeOptions,
      // licenseData,
    } = this.state;

    let {
      businessMapping = [],
      fpoLogoURL,
      fpoName,
      foundedOn,
      facebookLink,
      instagramLink,
      twitterLink,
      officialWebsiteLink,
      promotingInstitution,
      promotingInstitutionLogo,
      resourcingInstitution,
      ResourcingInstitutionLogoURL,
      maleMembers,
      femaleMembers,
      membershipBase,
      villagesCovered,
      totalAverage,
      shareCapital,
      infraMapping = [],
      pinCodes = {},
      pincodeId,
      stateId,
      stateMaster = {},
      districtId,
      districtNames = {},
      cropMapping = [],
      licenseMapping = [],
      revenueDetails = {},
      teamMembers = {},
      pincode,
      isfileUploading,
      fpoLogoName,
      turnOver,
      entityId,
      companyCin,
      trust_registration_number,
      cooperative_registration_number,
      societyType,
    } = aboutDetail;

    // console.log("aboutDetail", aboutDetail);

    let { bodList = [], staffList = [] } = teamMembers ? teamMembers : {};
    // let turnOver =
    //   revenueDetails !== null &&
    //   revenueDetails.turnOver !== null &&
    //   revenueDetails.fymYear !== null
    //     ? moneyFormatBySymbol(aboutDetail.turnOver, true)
    //     : "-";

    let { accessData, name } = this.props;

    let crops = cropMapping
      .reduce((acc, item) => {
        return `${acc} , ${item.cropsDetails.cropName}`;
      }, "")
      .slice(2);

    return (
      <div>
        {!isLoading ? (
          <>
            <div className="container-sa w-100">
              <div className="about-us-content">
                <div className="row align-items-center mtm-110">
                  <div className="fpo-logo">
                    <div className="fpo-logo-area">
                      <div className="poistion-relative">
                        <img
                          className="user-profile"
                          src={
                            fpoLogoURL !== null && fpoLogoURL !== ""
                              ? fpoLogoURL
                              : "https://upload.wikimedia.org/wikipedia/commons/thumb/a/ac/No_image_available.svg/1024px-No_image_available.svg.png"
                          }
                          alt="fpo-logo"
                        />
                        {/* {fpoLogoURL !== "" && fpoLogoURL !== null ? (
                          <div
                            className="remove-area cursor-pointer"
                            onClick={(e) =>
                              this.handleRemovePicture(
                                e,
                                fpoLogoURL,
                                fpoLogoName
                              )
                            }
                          >
                            <div className="btn-file-profileImage">
                              <p className="mb-0 d-flex justify-content-center align-items-center flex-column pt-2 h-100">
                                <i className="icon-Delete text-white fs-20"></i>
                                {"Delete"}
                              </p>
                            </div>
                          </div>
                        ) : null} */}
                        {fpoLogoURL !== "" && fpoLogoURL !== null ? (
                          <div className="update-area">
                            <div className="btn-file-profileImage">
                              <p className="mb-0 d-flex justify-content-center align-items-center flex-column pt-2 h-100">
                                <img src={camera} alt="camera" />
                                {fpoLogoURL !== "" && fpoLogoURL !== null
                                  ? "Update"
                                  : "Add"}
                              </p>
                              <input
                                type="file"
                                className=""
                                accept=".jpg, .png, .jpeg"
                                onChange={this.uploadSingleFile}
                                id="fileUpload"
                                title=""
                              />
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-10 about_fpo_name">
                    <div className="d-flex flex-column justify-content-center">
                      <p className="fs-44 line-height-38 font-weight-bold mt-1 mb-2">
                        {fpoName}
                      </p>
                      {/* {foundedOn !== null ? (
                        <p className="text-white fs-16 line-height-19 mb-0">
                          {"Founded on "}
                          {moment(foundedOn).format("DD MMMM YYYY")}
                        </p>
                      ) : null} */}
                    </div>
                    <div>
                      <div className="d-flex pt-3 align-items-center">
                        {facebookLink !== "" ? (
                          <a
                            href={
                              facebookLink &&
                              facebookLink.includes("http" || "https")
                                ? facebookLink
                                : `https://${facebookLink}`
                            }
                            target="_blank"
                          >
                            <img
                              src={facebook}
                              className="mr-4"
                              alt="instagram"
                            />
                          </a>
                        ) : null}
                        {instagramLink !== "" ? (
                          <a
                            href={
                              instagramLink &&
                              instagramLink.includes("http" || "https")
                                ? instagramLink
                                : `https://${instagramLink}`
                            }
                            target="_blank"
                          >
                            <img
                              src={instagram}
                              className="mr-4 ml-2"
                              alt="instagram"
                            />
                          </a>
                        ) : null}
                        {twitterLink !== "" ? (
                          <a
                            href={
                              twitterLink &&
                              twitterLink.includes("http" || "https")
                                ? twitterLink
                                : `https://${twitterLink}`
                            }
                            target="_blank"
                          >
                            <img
                              src={twitter}
                              className="mr-4 ml-2"
                              alt="twitter"
                            />
                          </a>
                        ) : null}
                        {officialWebsiteLink !== "" ? (
                          <a
                            className="mr-4"
                            href={
                              officialWebsiteLink &&
                              officialWebsiteLink.includes("http" || "https")
                                ? officialWebsiteLink
                                : `https://${officialWebsiteLink}`
                            }
                            target="_blank"
                          >
                            {officialWebsiteLink}
                          </a>
                        ) : null}
                        {/* {districtId != null ||
                        stateId != null ||
                        pincodeId != null ? (
                          <>
                            <img
                              src={location}
                              className="mr-3 ml-2"
                              alt="location"
                            />
                            <p className="fs-16 text-black mb-0">
                              {districtId != null && districtId !== undefined
                                ? districtNames.districtName
                                : null}
                              {stateId != null && stateId !== undefined ? (
                                <>, {stateMaster.stateName}</>
                              ) : null}
                              {pincodeId != null && pincodeId !== undefined ? (
                                <> - {pinCodes.pincode}</>
                              ) : null}
                              {pincode != null && `- ${pincode}`}
                            </p>
                          </>
                        ) : null} */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="institute-card">
                <div className="d-flex flex-md-row gap-md-0 gap-4 flex-column card-area justify-content-around pt-5">
                  {promotingInstitution !== null ||
                  promotingInstitutionLogo !== null ? (
                    <div className="about-us-card">
                      <div className="d-flex w-100">
                        <div className="col-4 pl-0 d-flex align-items-center">
                          <img
                            className="border-radius-50"
                            src={
                              promotingInstitutionLogo !== null
                                ? promotingInstitutionLogo
                                : letterAvatar("Promoting Institution", 60)
                            }
                            alt="promotingInstitutionLogo"
                          />
                        </div>
                        <div className="col-8 pl-0 d-flex flex-column justify-content-center">
                          <p className="mb-0 pb-1 fs-18 text-black-50">
                            {"Promoting institution"}
                          </p>
                          <p className="mb-0 fs-22 text-black-32 font-weight-bold">
                            {promotingInstitution !== null
                              ? promotingInstitution
                              : ""}
                          </p>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {resourcingInstitution !== null ||
                  ResourcingInstitutionLogoURL !== null ? (
                    <div className="d-flex about-us-card">
                      <div className="col-4 pl-0 d-flex align-items-center">
                        <img
                          className="border-radius-50"
                          src={
                            ResourcingInstitutionLogoURL !== null
                              ? ResourcingInstitutionLogoURL
                              : letterAvatar("Resource Institution", 60)
                          }
                          alt="institutionLogo"
                        />
                      </div>
                      <div className="col-8 pl-0 d-flex flex-column justify-content-center">
                        <p className="mb-0 pb-1 fs-18 text-black-50">
                          {"Resource institution"}
                        </p>
                        <p className="mb-0 fs-22 text-black-32 font-weight-bold">
                          {resourcingInstitution !== null
                            ? resourcingInstitution
                            : ""}
                        </p>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="institute-card mb-4">
                <div className="d-flex flex-md-row gap-md-0 gap-4 flex-column justify-content-around">
                  <div className="about-us-card mr-3">
                    <div className="d-flex justify-content-center w-100">
                      <div>
                        <p className="mb-0 pb-1 fs-18 text-black-50 text-center">
                          {"Entity Type"}
                        </p>
                        <p className="mb-0 fs-22 text-black-32 font-weight-bold text-center">
                          {this.state.entityList?.map((val) => {
                            return (
                              <>
                                {Number(val?.value) === Number(entityId)
                                  ? val?.label
                                  : ""}
                              </>
                            );
                          })}
                        </p>
                      </div>
                    </div>
                  </div>
                  {Number(entityId) === 2 || Number(entityId) === 3 ? (
                    <div className="about-us-card mr-3">
                      <div className="d-flex justify-content-center w-100">
                        <div>
                          {Number(entityId) === 2 ? (
                            <div>
                              <p className="mb-0 pb-1 fs-18 text-black-50 text-center">
                                CIN
                              </p>
                              <p className="mb-0 fs-22 text-black-32 font-weight-bold text-center">
                                {companyCin}
                              </p>
                            </div>
                          ) : Number(entityId) === 3 ? (
                            <div>
                              <p className="mb-0 pb-1 fs-18 text-black-50 text-center">
                                Society Type
                              </p>
                              <p className="mb-0 fs-22 text-black-32 font-weight-bold text-center">
                                {societyType}
                              </p>
                              <p className="mb-0 pb-1 fs-18 text-black-50 text-center pt-2">
                                Cooperative Registration Number
                              </p>
                              <p className="mb-0 fs-22 text-black-32 font-weight-bold text-center">
                                {cooperative_registration_number}
                              </p>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  ) : null}

                  {Number(entityId) === 4 ? (
                    <div className="about-us-card mr-3">
                      <div className="d-flex justify-content-center w-100">
                        <div>
                          <p className="mb-0 pb-1 fs-18 text-black-50 text-center">
                            {"Trust Registration Number"}
                          </p>
                          <p className="mb-0 fs-22 text-black-32 font-weight-bold text-center">
                            {trust_registration_number}
                          </p>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="row">
                <div className="col-md-3 col-sm-6 col-12">
                  <div className="about-detail-card">
                    <p className="mb-0 pb-1 fs-18 text-black-50">
                      {"Founded On"}
                    </p>
                    <p className="mb-0 fs-22 text-black-32 font-weight-bold">
                      {foundedOn !== null
                        ? moment(foundedOn).format("DD MMMM YYYY")
                        : "-"}
                    </p>
                  </div>
                </div>
                <div className="col-md-3 col-sm-6 col-12">
                  <div className="about-detail-card">
                    <p className="mb-0 pb-1 fs-18 text-black-50">{"State"}</p>
                    <p className="mb-0 fs-22 text-black-32 font-weight-bold">
                      {stateId != null && stateId !== undefined
                        ? stateMaster.stateName
                        : null}
                    </p>
                  </div>
                </div>
                <div className="col-md-3 col-sm-6 col-12">
                  <div className="about-detail-card">
                    <p className="mb-0 pb-1 fs-18 text-black-50">
                      {"District"}
                    </p>
                    <p className="mb-0 fs-22 text-black-32 font-weight-bold">
                      {districtId != null && districtId !== undefined
                        ? districtNames.districtName
                        : null}
                    </p>
                  </div>
                </div>
                <div className="col-md-3 col-sm-6 col-12">
                  <div className="about-detail-card">
                    <p className="mb-0 pb-1 fs-18 text-black-50">{"Pincode"}</p>
                    <p className="mb-0 fs-22 text-black-32 font-weight-bold">
                      {pincode !== null ? pincode : "-"}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {this.props.id && name === undefined ? (
              <div className="container my-3">
                <div className="row">
                  <div className="col-8 mx-auto">
                    <div className="fpo-management-list shadow rounded px-3 py-4">
                      <div className="row">
                        <div className="col-12">
                          <h3 className="fpo-card-header fs-24 primary-color-dark mb-3">
                            {"FPO Management"}
                          </h3>
                        </div>
                      </div>
                      <div className="row">
                        {fpoManagementList.map((list, index) => {
                          let permissionData = getAccessdata(
                            accessData,
                            list?.serviceName
                          );
                          let { viewAccess = false } = permissionData;
                          return (
                            viewAccess && (
                              <div className="col-6" key={index}>
                                <div className="d-flex">
                                  <div
                                    className="d-flex d-flex align-items-center w-100 p-2 hover-primary-dark"
                                    onClick={() => {
                                      history.push(
                                        `${list?.link}/${this.props.id}`
                                      );
                                    }}
                                  >
                                    <div className="mr-4">
                                      <img src={list?.icon} alt="icon.svg" />
                                    </div>
                                    <div className="d-flex align-items-center fpo-card-body">
                                      {list?.name}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
            <div className="container-sa">
              <section className="about-us-wrapper">
                {/* <div className="col-sm-6">
                    <div className="about-left-content">
                      <p className="fs-20 font-weight-bold">
                        We are a collective of{" "}
                        {membershipBase !== null ? membershipBase : 0}
                        {femaleMembers && maleMembers
                          ? parseInt(maleMembers) + parseInt(femaleMembers)
                          : femaleMembers !== null
                          ? femaleMembers
                          : maleMembers !== null
                          ? maleMembers
                          : 0}
                        small & marginal farmers hailing from{" "}
                        {villagesCovered !== null ? villagesCovered : 0}{" "}
                        villages
                        {stateId != null && stateId !== undefined
                          ? ` from ${stateMaster.stateName} district`
                          : ""}
                        .
                        {crops !== "" && crops
                          ? `We predominantly grown ${crops}.`
                          : ""}
                        We are a growing company with increasing memberbase and
                        business revenue.
                      </p>
                      <h4>
                        {"Membership base "}
                        {membershipBase !== null ? membershipBase : 0}
                      </h4>
                      {stateId != null && stateId !== undefined ? (
                        <>
                          <p className="mb-2 pb-1 fs-30 font-weight-bold line-height-35 text-black">
                            {stateMaster.stateName}
                          </p>
                          <p className="mb-0 fs-18 line-height-25 text-black">
                            {"FPO Location"}
                          </p>
                        </>
                      ) : null}
                    </div>
                  </div> */}

                <div className="row">
                  <div className="col-md-3 col-sm-6 col-12">
                    <AboutCard
                      img={about_4}
                      title={"Members"}
                      number={membershipBase !== null ? membershipBase : 0}
                    />
                  </div>
                  <div className="col-md-3 col-sm-6 col-12">
                    <AboutCard
                      img={about_1}
                      title={"Male farmers"}
                      number={
                        maleMembers !== null
                          ? moneyFormatBySymbol(maleMembers, true)
                          : 0
                      }
                    />
                  </div>
                  <div className="col-md-3 col-sm-6 col-12">
                    <AboutCard
                      img={about_2}
                      title={"Female farmers"}
                      number={
                        femaleMembers !== null
                          ? moneyFormatBySymbol(femaleMembers, true)
                          : 0
                      }
                    />
                  </div>
                  <div className="col-md-3 col-sm-6 col-12">
                    <AboutCard
                      img={about_3}
                      title={"Turnover"}
                      number={moneyFormatBySymbol(turnOver, true)}
                    />
                  </div>
                  <div className="col-md-3 col-sm-6 col-12">
                    <AboutCard
                      img={about_4}
                      title={"share capital"}
                      number={
                        shareCapital !== null
                          ? moneyFormatBySymbol(shareCapital, true)
                          : 0
                      }
                    />
                  </div>
                  <div className="col-md-3 col-sm-6 col-12">
                    <AboutCard
                      img={about_5}
                      title={"Acres farmland"}
                      number={totalAverage !== null ? totalAverage : 0}
                    />
                  </div>
                  <div className="col-md-3 col-sm-6 col-12">
                    <AboutCard
                      img={about_6}
                      title={" Villages"}
                      number={villagesCovered !== null ? villagesCovered : 0}
                    />
                  </div>
                </div>
              </section>
            </div>
            {cropMapping.length != 0 ? (
              <OurCrops cropMapping={cropMapping} />
            ) : null}
            {bodList.length === 0 && staffList.length === 0 ? null : (
              <OurTeam bodList={bodList} staffList={staffList} />
            )}
            {businessMapping && businessMapping.length != 0 ? (
              <OurBusinessActivites businessMapping={businessMapping} />
            ) : null}
            {licenseMapping.length !== 0 ? (
              <Licenses licenseData={licenseMapping} />
            ) : null}
            {infraMapping?.length != 0 ? (
              <OurInfrastructure infraMapping={infraMapping} />
            ) : null}
            {/* {pastEvents?.length !== 0 || upcomingEvents?.length !== 0 ? (
              <div className="d-flex align-items-center pl-4 background-events rounded bg-blue-f9">
                <Events
                  fpoevents={this.state.pastEvent ? pastEvents : upcomingEvents}
                  eventToggle={this.eventToggle}
                  pastEvent={!this.state.pastEvent}
                />
              </div>
            ) : null}
            {fpoawards.length !== 0 ? (
              <div className="d-flex align-items-center pl-4 background-awards rounded">
                <Awards fpoawards={fpoawards} />
              </div>
            ) : null}
            {fposuccessstories.length !== 0 ? (
              <div className="bg-blue-f9">
                <SuccessStory fposuccessstories={fposuccessstories} />
              </div>
            ) : null} */}
          </>
        ) : (
          <>
            {/* Loader */}
            <CommonPageLoader />
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  accessData: state.home.accessData,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getAboutusMemberDetail,
      getallEventsData,
      getLicenseData,
      getAboutUsById,
      getComplianceForFPOById,
      getEventsandImpactsOfFPOById,
      getAboutusMemberDetailWithId,
      getLicenseDataWithId,
      getallEventsDataWithId,
      updateAboutUsDetails,
      fileUpload,
      getSignUpEntityType,
    },
    dispatch
  );
};

export const About = connect(mapStateToProps, mapDispatchToProps)(AboutClass);
