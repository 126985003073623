import React, { Component } from "react";

import { TabPane, TabContent } from "reactstrap";
import { Pagination, CommonPageLoader } from "component/common";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getAllAgm, getAllBod } from "action/ComplianceAct";
import { getAllAgmRm, getAllBodRm } from "action/RmAct";

import { Card } from "./Card";
import { Strip } from "./Strip";

import { debounceFunction } from "service/debounce";
import { Search } from "pages";

class BodTabClass extends Component {
  constructor() {
    super();
    this.state = {
      activeTab: "1",
      view: "1",
      pageMetaBod: {},
      pageMetaAgm: {},
      page: 1,
      BodInfo: [],
      AgmInfo: [],
      searchValue: "",
      search: "",
      istabOneLoading: false,
      istabTwoLoading: false,
    };
  }

  handlePaginationAgm = (page) => {
    this.handleAgm(page);
  };
  handlePaginationBod = (page) => {
    this.handleBod(page);
  };

  handleAgm = (page) => {
    let { searchValue } = this.state;
    let { getAllAgm, limit, id } = this.props;
    let payload = {
      limit,
      page,
      search: searchValue,
    };
    this.setState({ istabTwoLoading: true });
    id
      ? this.props.getAllAgmRm(id, payload).then((data) => {
          let dataSet = [];
          data.results.map((result) => {
            dataSet.push({
              id: result.id,
              agendaHighlights: result.agendaHighlights,
              numberOfAttendees: result.numberOfAttendees,
              location: result.location,
              dateOfLastMeetingD: result.dateOfLastMeetingD,
              attendeesListUrl: result.attendeesListUrl,
              supportingDocumentUrl: result.supportingDocumentUrl,
            });
          });

          this.setState({
            AgmInfo: dataSet,
            pageMetaAgm: data.pageMeta,
            istabTwoLoading: false,
          });
        })
      : getAllAgm(payload).then((data) => {
          let dataSet = [];
          data.results.map((result) => {
            dataSet.push({
              id: result.id,
              agendaHighlights: result.agendaHighlights,
              numberOfAttendees: result.numberOfAttendees,
              location: result.location,
              dateOfLastMeetingD: result.dateOfLastMeetingD,
              attendeesListUrl: result.attendeesListUrl,
              supportingDocumentUrl: result.supportingDocumentUrl,
            });
          });

          this.setState({
            AgmInfo: dataSet,
            pageMetaAgm: data.pageMeta,
            istabTwoLoading: false,
          });
        });
  };
  handleBod = (page) => {
    let { searchValue } = this.state;
    let { getAllBod, limit, id } = this.props;
    let payload = {
      limit,
      page,
      search: searchValue,
    };
    this.setState({ istabOneLoading: true });
    id
      ? this.props.getAllBodRm(id, payload).then((data) => {
          let dataSet = [];
          data.results.map((result) => {
            dataSet.push({
              id: result.id,
              agendaHighlights: result.agendaHighlights,
              numberOfAttendees: result.numberOfAttendees,
              location: result.location,
              dateOfLastMeetingD: result.dateOfLastMeetingD,
              attendeesListUrl: result.attendeesListUrl,
              supportingDocumentUrl: result.supportingDocumentUrl,
            });
          });
          this.setState({
            BodInfo: dataSet,
            pageMetaBod: data.pageMeta,
            istabOneLoading: false,
          });
        })
      : getAllBod(payload).then((data) => {
          let dataSet = [];
          data.results.map((result) => {
            dataSet.push({
              id: result.id,
              agendaHighlights: result.agendaHighlights,
              numberOfAttendees: result.numberOfAttendees,
              location: result.location,
              dateOfLastMeetingD: result.dateOfLastMeetingD,
              attendeesListUrl: result.attendeesListUrl,
              supportingDocumentUrl: result.supportingDocumentUrl,
            });
          });
          this.setState({
            BodInfo: dataSet,
            pageMetaBod: data.pageMeta,
            istabOneLoading: false,
          });
        });
  };

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.setState({
        search: "",
        searchValue: "",
      });
      if (this.props.mainactiveTab == 6) {
        this.props.activeTab === "1" ? this.handleBod(1) : this.handleAgm(1);
      }
      this.setState({
        activeTab: this.props.activeTab,
      });
    }
  }

  handleInput = ({ target: { name, value } }) => {
    //Debounce Function
    this.setState({ search: value });
    debounceFunction(() => this.SearchFunction(value), 1000);
  };

  SearchFunction = (searchValue) => {
    //debounce function
    this.setState({ searchValue });
    this.state.activeTab === "1" ? this.handleBod(1) : this.handleAgm(1);
  };

  render() {
    let {
      activeTab,
      BodInfo = [],
      AgmInfo = [],
      pageMetaBod,
      pageMetaAgm,
      view,
      istabOneLoading,
      istabTwoLoading,
      search,
    } = this.state;

    let { editAccess } = this.props;

    return (
      <>
        <section className="incometax-section w-100">
          <div className="container-sa">
            <div className="incometax-tab d-flex flex-column">
              <div className="compilance-filters">
                <div className="row align-items-center">
                  <div className="col-sm-9 px-md-5">
                    <ul className="right-filter-align">
                      <li>
                        <span
                          className={`icon-list-view mr-3 pr-1 fs-20 cursor-pointer ${
                            this.state.view === "1"
                              ? "view-active mr-3"
                              : "view"
                          }`}
                          onClick={() => {
                            this.setState({
                              view: "1",
                            });
                          }}
                        ></span>
                        <span
                          className={` icon-grid-view fs-20 cursor-pointer  ${
                            this.state.view === "2" ? "view-active" : "view"
                          }`}
                          onClick={() => {
                            this.setState({
                              view: "2",
                            });
                          }}
                        ></span>
                      </li>
                    </ul>
                  </div>
                  <div className="col-sm-3">
                    <input
                      type-="text"
                      className="form-control form-control-sa"
                      placeholder="Search"
                      value={search}
                      onChange={(e) => this.handleInput(e)}
                    />
                  </div>
                </div>
              </div>
            </div>

            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                {!istabOneLoading && BodInfo.length === 0 ? (
                  <p className="py-4 mb-0 text-black-50 fs-22 line-height-25 text-center">
                    {"No data found"}
                  </p>
                ) : !istabOneLoading ? (
                  <>
                    {BodInfo.length != 0 ? (
                      <>
                        <div className="scroll-x">
                          <div
                            className="d-flex container-sa flex-column  flex-wrap mt-3 px-0 justify-content-center"
                            style={view === "1" ? { minWidth: "1200px" } : null}
                          >
                            <div className="d-flex flex-wrap com-list-card">
                              {BodInfo.map((info, index) =>
                                view === "2" ? (
                                  <Card
                                    payload={info}
                                    index={index}
                                    activeTab={activeTab}
                                    editAccess={editAccess}
                                    key={index}
                                  />
                                ) : (
                                  <Strip
                                    payload={info}
                                    key={index}
                                    index={index}
                                    activeTab={activeTab}
                                    editAccess={editAccess}
                                  />
                                )
                              )}
                            </div>
                          </div>
                        </div>
                        {pageMetaBod && activeTab === "1" && (
                          <Pagination
                            handleChange={this.handlePaginationBod}
                            pageMeta={pageMetaBod}
                          />
                        )}
                      </>
                    ) : (
                      <p className="py-4 mb-0 text-black-50 fs-22 line-height-25 text-center">
                        {"No data found"}
                      </p>
                    )}
                  </>
                ) : (
                  <>
                    {/* Loader */}
                    <CommonPageLoader />
                  </>
                )}
              </TabPane>
              <TabPane tabId="2">
                {!istabTwoLoading && AgmInfo.length === 0 ? (
                  <p className="py-4 mb-0 text-black-50 fs-22 line-height-25 text-center">
                    {"No data found"}
                  </p>
                ) : !istabTwoLoading ? (
                  <>
                    {AgmInfo.length != 0 ? (
                      <>
                        <div className="scroll-x">
                          <div
                            className="d-flex container-sa flex-column  flex-wrap mt-3 px-0 justify-content-center"
                            style={view === "1" ? { minWidth: "1200px" } : null}
                          >
                            <div className="d-flex flex-wrap com-list-card">
                              {AgmInfo.map((info, index) =>
                                view === "2" ? (
                                  <Card
                                    payload={info}
                                    index={index}
                                    key={index}
                                    activeTab={activeTab}
                                    editAccess={editAccess}
                                  />
                                ) : (
                                  <Strip
                                    payload={info}
                                    index={index}
                                    key={index}
                                    activeTab={activeTab}
                                    editAccess={editAccess}
                                  />
                                )
                              )}
                            </div>
                          </div>
                        </div>
                        {pageMetaAgm && activeTab === "2" && (
                          <Pagination
                            handleChange={this.handlePaginationAgm}
                            pageMeta={pageMetaAgm}
                          />
                        )}
                      </>
                    ) : (
                      <p className="py-4 mb-0 text-black-50 fs-22 line-height-25 text-center">
                        {"No data found"}
                      </p>
                    )}
                  </>
                ) : (
                  <>
                    {/* Loader */}
                    <CommonPageLoader />
                  </>
                )}
              </TabPane>
            </TabContent>
          </div>
        </section>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getAllAgm,
      getAllBod,
      getAllAgmRm,
      getAllBodRm,
    },
    dispatch
  );
};

let component = BodTabClass;

export const BodTab = connect(null, mapDispatchToProps)(component);
