import { CustomSelect, NormalDate, NormalInput, NormalSelect } from 'component/common';
import React, { Component } from 'react';
import { useSelector } from 'react-redux';
import { CheckboxGroup } from '../CheckboxGroup';
import { RadioGroup } from '../RadioGroup';

export const FieldsTemplate = (props) => {

  const attributes = props?.attributes;
  const simpleValidator = props?.simpleValidator;
  const stateAttr = props?.stateAttr;

  const { stateList, districtList, facilities, licenses, positions, products, cropsList } = useSelector(
    (state) => state.grading
  );

  return (
    <div className="row">
      {
        attributes.map((attribute, key) => {
          let _className = "";
          if (attribute?.conditions) {
            let conditions = attribute?.conditions;
            if (stateAttr[conditions?.attribute] instanceof Array && conditions?.values.length === 1 && stateAttr[conditions?.attribute].includes(conditions?.values[0])) {
              _className = conditions?.action === "hide" ? "d-none" : "";
            } else if (
              (conditions?.values.includes(stateAttr[conditions?.attribute])) ||
              (conditions?.criteria && conditions?.criteria === "lt" && stateAttr[conditions?.attribute] < conditions?.values[0]) ||
              (conditions?.criteria && conditions?.criteria === "gt" && stateAttr[conditions?.attribute] > conditions?.values[0])
            ) {
              _className = conditions?.action === "hide" ? "d-none" : "";
            } else if (
              (!conditions?.values.includes(stateAttr[conditions?.attribute])) ||
              (conditions?.criteria && conditions?.criteria === "lt" && stateAttr[conditions?.attribute] > conditions?.values[0]) ||
              (conditions?.criteria && conditions?.criteria === "gt" && stateAttr[conditions?.attribute] < conditions?.values[0])
            ) {
              _className = conditions?.action === "hide" ? "" : "d-none";
            }

          }

          let requiredCond = 'required';
          if (attribute?.errConditions && attribute?.errConditions.criteria) {
            requiredCond = `required|${attribute?.errConditions.criteria}`;
          }

          if (attribute?.name === "rsnhighdebt") {
            let ebitdalastfinyr = parseFloat(stateAttr["ebitdalastfinyr"]);
            let totltloans = parseFloat(stateAttr["totltloans"]);
            let totstloans = parseFloat(stateAttr["totstloans"]);

            let debtPercent = (ebitdalastfinyr / (totltloans + totstloans)) * 100;
            _className = debtPercent <= 33 ? "" : "d-none";
          }

          let attributeVal = stateAttr[attribute.name];
          if (attribute.field === "input" && attribute.alt) {
            attributeVal = stateAttr[attribute.alt];
          }
          let colMdVal = 6;
          if (attribute.col) {
            colMdVal = attribute.col;
          }
          if(attribute.field === "radioGroup"){
            colMdVal = 12;
          }

          //
          return (
            <React.Fragment key={key}>
              {
                _className != "d-none" && (
                  <>
                    <div className={`col-md-${colMdVal} col-sm-12  my-1 ${attribute?.conditions ? "ml-4 col-cond" : ""}`}>
                      {
                        attribute.field === "text" && (
                          <>
                            <label className='mt-4 fs-18'>
                              <b>{attribute.label}</b>
                              {
                                (props?.required) && (
                                  <span className="text-danger font-weight-bold pl-2 fs-18">
                                    {"*"}
                                  </span>
                                )
                              }
                            </label> <br />
                          </>
                        )
                      }
                      {
                        attribute.field === "input" && (
                          <>
                            <NormalInput
                              label={attribute.label}
                              type={attribute.type}
                              value={attributeVal}
                              placeholder={attribute.placeholder || ""}
                              name={attribute.name}
                              required={attribute.required}
                              onChange={props?.handleInput?.bind(this)}
                              disabled={attribute.disabled || false}
                              className="grading-normal-input form-control"
                              labelClassName="grading-normal-label"
                            />
                            {
                              attribute.note && (
                                <p className="grading-normal-hint" ><i>({attribute.note})</i></p>
                              )
                            }
                            {attribute.required && simpleValidator.current.message(attribute.label, stateAttr[attribute.name], requiredCond)}
                          </>
                        )
                      }
                      {
                        attribute.field === "radioGroup" && (
                          <>
                            <RadioGroup
                              title={attribute.label}
                              name={attribute.name}
                              selValue={stateAttr[attribute.name]}
                              onChange={(e) => {
                                return props?.handleRadio(attribute.name, e);
                              }}
                              groupList={attribute.list}
                              required={attribute.required}
                            />
                            {
                              attribute.note && (
                                <p className="grading-normal-hint" ><i>({attribute.note})</i></p>
                              )
                            }
                            {attribute.required && simpleValidator.current.message(attribute.error || "", stateAttr[attribute.name], 'required')}
                          </>
                        )
                      }
                      {
                        attribute.field === "checkboxGroup" && (
                          <>
                            <CheckboxGroup
                              title={attribute.label}
                              name={attribute.name}
                              selValue={stateAttr[attribute.name]}
                              onChange={(e) => {
                                return props?.handleCheckbox(attribute.name, e);
                              }}
                              required={attribute.required}
                              groupList={
                                (attribute.name === "facilities1" && facilities && facilities["Governance"]) ? facilities["Governance"] :
                                  (attribute.name === "facilities2" && facilities && facilities["Outreach"]) ? facilities["Outreach"] :
                                    (attribute.name === "facilities3" && facilities && facilities["Operations"]) ? facilities["Operations"] :
                                      (attribute.name === "licenses" && licenses) ? licenses :
                                        (attribute.name === "positions" && positions) ? positions :
                                          (attribute.name === "products" && products) ? products : []
                              }
                            />
                            {
                              attribute.note && (
                                <p className="fs-11" ><i>({attribute.note})</i></p>
                              )
                            }
                            {attribute.required && simpleValidator.current.message(attribute.error || "", stateAttr[attribute.name], 'required')}
                          </>
                        )
                      }
                      {
                        attribute.field === "date" && (
                          <>
                            <NormalDate
                              isIcon={attribute.name || true}
                              value={stateAttr[attribute.name]}
                              name={attribute.name}
                              placeholder={attribute.placeholder || "DD/MM/YYYY"}
                              onChange={props?.handleInput?.bind(this)}
                              mindate={attribute.mindate || false}
                              maxdate={attribute.maxdate || true}
                              timeInput={attribute.timeInput || false}
                              label={attribute.label}
                              required={attribute.required}
                              className="form-control w-100 grading-normal-date"
                              labelClassName="grading-normal-label"
                            />
                            {attribute.required && simpleValidator.current.message(attribute.label, stateAttr[attribute.name], 'required')}
                          </>
                        )
                      }
                      {
                        attribute.field === "select" && (
                          <>
                            <label className='grading-normal-label mt-24px'>
                              <b>{attribute.label}</b>
                              {
                                attribute.required && (
                                  <span className="text-danger font-weight-bold pl-2 fs-18">
                                    *
                                  </span>
                                )
                              }
                            </label>
                            <CustomSelect
                              label={attribute.label}
                              placeholder={attribute.placeholder || "Select"}
                              value={stateAttr[attribute.name]}
                              arrow={attribute.arrow || true}
                              name={attribute.name}
                              isSearchable={attribute.isSearchable || true}
                              isClearable={attribute.isClearable || false}
                              options={
                                (attribute.name === "selState") ? stateList :
                                  (attribute.name === "selDistrict") ? districtList :
                                    (["selCrops1", "selCrops2", "selCrops3", "selCrops4", "selCrops5"].includes(attribute.name)) ? cropsList : []
                              }
                              required={attribute.required}

                              onChange={props?.handleInput?.bind(this)}
                              className="grading-normal-select"
                            />
                            {attribute.required && simpleValidator.current.message(attribute.label, stateAttr[attribute.name], 'required')}
                          </>
                        )
                      }
                    </div>
                    {
                      (!attribute.col && !attribute.conditions && !["radioGroup"].includes(attribute.field)) && (
                        <div className={"col-md-6 col-sm-12  my-1 "}></div>
                      )
                    }
                  </>
                )
              }
            </React.Fragment>
          )
        })
      }
    </div>
  )
}