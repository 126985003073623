import React, { Component } from "react";
import html2canvas from "html2canvas";
import moment from "moment-timezone";
import { jsPDF } from "jspdf";
import "./style.scss";
import classnames from "classnames";
import { CommonPageLoader, NormalButton } from "component/common";
import {
  optionData1,
  optionData2,
  optionData3,
  optionData4,
  optionData5,
  optionData6,
  optionData7,
  optionData8,
  optionDataForFPo,
} from "./Data";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as Highcharts from "highcharts";
import more from "highcharts/highcharts-more";
import HighchartsReact from "highcharts-react-official";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import GradingComparisonChart from "./include/GradingComparisonChart";
import SelectCheckbox from "./include/SelectCheckbox";
import { GradingReport } from "./include/GradingReport";
import { getGradingSurveyList } from "action/BodAct";
import { getGradingDetails } from "action/GradingAct";
import { getGradingDropdownList } from "action/GradingAct";
import NoSurvey from "./include/NoSurvey";
import { Toast } from "service/toast";
more(Highcharts);

export class GradingToolCompClass extends Component {
  state = {
    activeTab: "1",
    outreachPercentage: optionData1,
    governance: optionData2,
    operations: optionData3,
    management: optionData4,
    socioEconomic: optionData5,
    technology: optionData6,
    accountsFinance: optionData7,
    compliance: optionData8,
    fpoData: optionDataForFPo,
    isLoading: false,
    fpoName: "",
    isDownload: false,
    fpoGradingDetail: {},
    grades: [
      "A+ : Ready to Excel : 90-100%",
      "A : Ready to Establish : 70-89%",
      "B : Ready to Engage : 50-69%",
      "C : Ready to Enable : 20-49%",
      "D : Ready to Build : 0-19%",
    ],
    compareSurvey: [],
    surveyReport: null,
    dropdownList: [],
    selectedSurvey: [],
    showDownloadbutton: true,
  };

  toggle = (tab) => {
    this.setState({
      activeTab: tab,
    });
  };

  handleChange = (value) => {
    if (value.length < 1) {
      return;
    } else if (value.length > 3) {
      Toast({ type: "error", message: "Only three surveys can be selected" });
      return null;
    }
    this.setState({
      compareSurvey: value,
      surveyReport: null,
    });
  };

  componentDidMount() {
    const { getGradingSurveyList, getGradingDropdownListApi, isFPO } =
      this.props;
    const fpoID = localStorage.getItem("fpoId");
    const api = isFPO
      ? getGradingSurveyList()
      : getGradingDropdownListApi(fpoID);
    api
      .then((res) => {
        let selected = [];
        if (res.length > 3) {
          selected = res.slice(res.length - 3, res.length);
        } else {
          selected = res;
        }
        this.setState({
          dropdownList: res,
          compareSurvey: selected,
        });
      })
      .catch(() => {});
  }

  donwloadScore = () => {
    this.setState(
      {
        isDownload: true,
      },
      () => {
        let { activeTab } = this.state;
        const invoiceOne = document.getElementById("score-card-one");
        const invoiceTwo = document.getElementById("score-card-two");
        const invoice = activeTab === "1" ? invoiceOne : invoiceTwo;
        html2canvas(invoice, {
          useCORS: true,
          allowTaint: true,
          scrollY: 0,
        }).then((canvas) => {
          const image = { type: "jpeg", quality: 0.98 };
          const margin = [0.5, 0.5];

          var imgWidth = 8.5;
          var pageHeight = 11;

          var innerPageWidth = imgWidth - margin[0] * 2;
          var innerPageHeight = pageHeight - margin[1] * 2;

          // Calculate the number of pages.
          var pxFullHeight = canvas.height;
          var pxPageHeight = Math.floor(canvas.width * (pageHeight / imgWidth));
          var nPages = Math.ceil(pxFullHeight / pxPageHeight);

          // Define pageHeight separately so it can be trimmed on the final page.
          var pageHeight = innerPageHeight;

          // Create a one-page canvas to split up the full image.
          var pageCanvas = document.createElement("canvas");
          var pageCtx = pageCanvas.getContext("2d");
          pageCanvas.width = canvas.width;
          pageCanvas.height = pxPageHeight;

          // Initialize the PDF.
          var pdf = new jsPDF("p", "in", [8.5, 11]);

          for (var page = 0; page < nPages; page++) {
            // Trim the final page to reduce file size.
            if (page === nPages - 1 && pxFullHeight % pxPageHeight !== 0) {
              pageCanvas.height = pxFullHeight % pxPageHeight;
              pageHeight =
                (pageCanvas.height * innerPageWidth) / pageCanvas.width;
            }

            // Display the page.
            var w = pageCanvas.width;
            var h = pageCanvas.height;
            pageCtx.fillStyle = "white";
            pageCtx.fillRect(0, 0, w, h);
            pageCtx.drawImage(canvas, 0, page * pxPageHeight, w, h, 0, 0, w, h);

            // Add the page to the PDF.
            if (page > 0) pdf.addPage();
            var imgData = pageCanvas.toDataURL(
              "image/" + image.type,
              image.quality
            );
            pdf.addImage(
              imgData,
              image.type,
              margin[1],
              margin[0],
              innerPageWidth,
              pageHeight
            );
          }
          pdf.save(
            activeTab === "1" ? "grading-score.pdf" : "comparison-chart.pdf"
          );
          this.setState({
            isDownload: false,
          });
        });
      }
    );
  };

  showDownload = (type) => {
    if (!type) {
      this.setState({ showDownloadbutton: false });
    }
  };

  render() {
    let {
      fpoGradingDetail,
      isNoData,
      isDownload,
      activeTab,
      dropdownList,
      showDownloadbutton,
    } = this.state;

    return (
      <section className="grading-tool" style={{ backgroundColor: "#F9FDF9" }}>
        <div className="container-sa py-4">
          {isNoData ? (
            <div className="row">
              <div className="col-8 mx-auto">
                <p className="py-5 mb-0 text-black-50 fs-22 fw-600 line-height-25 text-center">
                  {"No survey done"}
                </p>
              </div>
            </div>
          ) : (
            <>
              <div className="mb-3">
                {/* <h3 className="font-weight-bold text-center mb-4">Grading</h3> */}
                <div className="row justify-content-between mb-5">
                  <div className="col-lg-3 col-md-12 col-sm-12 pt-1 mt-lg-0 mt-2 order-1 order-lg-0">
                    <div className="row justify-content-center">
                      <div className="col-lg-12 col-sm-8 col-md-6">
                        {activeTab === "2" && dropdownList?.length > 1 && (
                          <SelectCheckbox
                            isMulti={true}
                            options={this.state.dropdownList}
                            handleChange={this.handleChange}
                            selectedOptions={this.state.compareSurvey}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-12 d-flex justify-content-center flex-wrap order-0 order-lg-1">
                    <Nav
                      tabs
                      className="grading-score-tab d-flex mx-auto mx-sm-0"
                    >
                      <NavItem>
                        <NavLink
                          className={`${classnames({
                            active: activeTab === "1",
                          })} p-0`}
                          onClick={() => this.toggle("1")}
                        >
                          <button
                            className={`btn ${
                              activeTab === "1" ? "active" : ""
                            }`}
                          >
                            Report
                          </button>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={`${classnames({
                            active: activeTab === "2",
                          })} p-0`}
                          onClick={() => this.toggle("2")}
                        >
                          <button
                            className={`btn ${
                              activeTab === "2" ? "active" : ""
                            }`}
                          >
                            Comparison Chart
                          </button>
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </div>
                  <div className="col-lg-3 col-sm-12 d-flex justify-content-center justify-content-lg-end order-2">
                    {((activeTab === "1" && showDownloadbutton) ||
                      (activeTab === "2" && dropdownList.length > 1)) && (
                      <NormalButton
                        label={
                          activeTab === "1"
                            ? isDownload
                              ? "Downloading...."
                              : "Download Report"
                            : isDownload
                            ? "Downloading...."
                            : "Download Chart"
                        }
                        mainbg={true}
                        className="fs-14 line-height-26 mt-2 mb-2 al grading-score-btn"
                        style={{ width: 100, display: "unset" }}
                        onClick={() => this.donwloadScore()}
                        disabled={isDownload}
                        isPopup={true}
                      />
                    )}
                  </div>
                </div>
              </div>
              <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                  <div id="score-card-one">
                    <GradingReport
                      showDownload={this.showDownload}
                      isFPO={this.props.isFPO}
                    />
                  </div>
                </TabPane>
                <TabPane tabId="2">
                  <div id="score-card-two">
                    {dropdownList?.length > 1 ? (
                      <GradingComparisonChart
                        selectedSurvey={this.state.compareSurvey}
                        isFPO={this.props.isFPO}
                      />
                    ) : (
                      <NoSurvey dropdownList={dropdownList} />
                    )}
                  </div>
                </TabPane>
              </TabContent>
            </>
          )}
        </div>
      </section>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getGradingSurveyList,
      getGradingDropdownListApi: getGradingDropdownList,
    },
    dispatch
  );
};

export const GradingToolComp = connect(
  null,
  mapDispatchToProps
)(GradingToolCompClass);
