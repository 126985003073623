import React, { Component } from "react";
import {
  CustomSelect,
  DragFileUpload,
  NormalEditor,
} from "../../../../../common";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// Redux Connection
import { connect } from "react-redux";
import pdfImage from "assets/svg/Documents/pdf.svg";
export class AwardsClass extends Component {
  constructor(props) {
    super(props);
    this.state = {
      awardDocs: [],
    };
  }

  componentDidMount() {
    let { awardObj = {} } = this.props;
    let { awardDocs = [] } = awardObj;
    this.setState({ awardDocs });
  }

  removepostImage = (index) => {
    let { awardDocs } = this.state;
    awardDocs.splice(index, 1);
    this.setState(
      {
        awardDocs,
      },
      () => {
        this.props.removepostImage(index);
      }
    );
  };

  componentDidUpdate = (prevProps) => {
    if (prevProps != this.props) {
      this.setState({
        awardDocs:
          this.props.awardObj && this.props.awardObj.awardDocs
            ? this.props.awardObj.awardDocs
            : [],
      });
    }
  };

  render() {
    let {
      validator,
      awardObj,
      handleInputChange,
      handleDateChange,
      handleFileUpload,
      isLoderFileUpload,
      onEditorChange,
      uploadCoverImg,
      removeCoverImg,
      removepostImage,
    } = this.props;
    let { awardDocs } = this.state;

    return (
      <div>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label className="required">
                Event name/title
                <span className="text-danger font-weight-bold pl-2 fs-18">
                  *
                </span>
              </label>
              <input
                className="form-control"
                value={awardObj.title}
                name="title"
                onChange={handleInputChange}
              />
              {validator.message(
                "title",
                awardObj.title,
                "required|max:50|alpha_space"
              )}
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label>
                Received date
                <span className="text-danger font-weight-bold pl-2 fs-18">
                  *
                </span>
              </label>
              <div className="input-group">
                <DatePicker
                  selected={awardObj.receivedDate}
                  className="form-control border-right-0"
                  onChange={handleDateChange}
                  showMonthDropdown={true}
                  showYearDropdown={true}
                  maxDate={new Date()}
                />
                <div className="input-group-append">
                  <span className="input-group-text" id="basic-addon2">
                    <img src="/icon/calendar.svg" alt="Girl in a jacket" />
                  </span>
                </div>
              </div>
              {validator.message(
                "Received Date",
                awardObj.receivedDate,
                "required"
              )}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label>
                Event location
                <span className="text-danger font-weight-bold pl-2 fs-18">
                  *
                </span>
              </label>
              <input
                className="form-control"
                value={awardObj.location}
                name="location"
                onChange={handleInputChange}
              />
              {validator.message(
                "Location",
                awardObj.location,
                "required|max:50|alpha_space"
              )}
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label>
                Received from (Agency/ organisation/ media)
                <span className="text-danger font-weight-bold pl-2 fs-18">
                  *
                </span>
              </label>
              <input
                className="form-control"
                value={awardObj.receivedFrom}
                name="receivedFrom"
                onChange={handleInputChange}
              />
              {validator.message(
                "Received From",
                awardObj.receivedFrom,
                "required|max:50|alpha_space"
              )}
            </div>
          </div>
          <div className="col-md-6 mb-3">
            <label className="event-cover">
              Upload Cover Image{" "}
              <span className="text-danger font-weight-bold pl-2 fs-18">*</span>
            </label>
            <div className="d-flex align-items-center w-100 btn-file-input">
              <button className="mr-2">
                <input
                  type="file"
                  accept=".jpg, .png, .jpeg"
                  onChange={(e) => uploadCoverImg(e, "awardDocs")}
                  id="coverImg"
                />
                Upload Image
              </button>
              {validator.message(
                "cover image",
                awardObj.awardDocs[0]?.fileName,
                "required"
              )}
              {awardObj.awardDocs.length != 0 && (
                <div className="pl-3 position-relative">
                  <img
                    src={awardObj.awardDocs[0]?.documentUrl}
                    alt=""
                    height="75"
                    width="75"
                  />
                  <i
                    className="icon-Delete text-danger fs-14 pl-2 cursor-pointer position-absolute"
                    onClick={() => removeCoverImg("awardDocs")}
                  ></i>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 mb-4">
            <NormalEditor
              label="Description"
              data={awardObj.shortDescription}
              onChange={onEditorChange}
              required={true}
            />
            {validator.message(
              "Description",
              awardObj.shortDescription,
              "required"
            )}
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label>Attachments</label>
              <DragFileUpload
                className="form-control drag-drop d-flex align-items-center"
                value={awardObj.awardDocs}
                name="imageURL"
                acceptFileFormat={["jpg", "jpeg", "png"]}
                children={
                  <HelloMessage isLoderFileUpload={isLoderFileUpload} />
                }
                handleFileUpload={(e) => handleFileUpload(e, "awardImages")}
              />
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-group">
              <div className="image-upload-area">
                {awardObj.awardImages.length !== 0 &&
                  awardObj.awardImages.map((list, index) => (
                    <div className="position-relative" key={index}>
                      <img
                        src={list.documentUrl}
                        alt="..."
                        className="image-upload position-relative"
                      />
                      <div className="close-icon">
                        <span
                          className="icon-close fs-10 cursor-pointer"
                          onClick={() => removepostImage(index, "awardImages")}
                        ></span>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label>Supporting Documents</label>
              <DragFileUpload
                className="form-control drag-drop d-flex align-items-center"
                value={awardObj.awardPdf}
                name="imageURL"
                acceptFileFormat={["pdf"]}
                children={
                  <HelloMessage isLoderFileUpload={isLoderFileUpload} />
                }
                handleFileUpload={(e) => handleFileUpload(e, "awardPdf")}
              />
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-group">
              <div className="image-upload-area">
                {awardObj.awardPdf.length !== 0 &&
                  awardObj.awardPdf.map((list, index) => (
                    <div className="position-relative" key={index}>
                      <img
                        src={pdfImage}
                        alt="..."
                        className="image-upload position-relative"
                      />
                      <div className="close-icon">
                        <span
                          className="icon-close fs-10 cursor-pointer"
                          onClick={() => removepostImage(index, "awardPdf")}
                        ></span>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label className="required">
                Name of the award received
                <span className="text-danger font-weight-bold pl-2 fs-18">
                  *
                </span>
              </label>
              <input
                className="form-control"
                value={awardObj.awardName}
                name="awardName"
                onChange={handleInputChange}
              />
              {validator.message(
                "Award name",
                awardObj.awardName,
                "required|max:50"
              )}
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label>Supporting link </label>
              <input
                className="form-control"
                value={awardObj.supportingLink}
                name="supportingLink"
                onChange={handleInputChange}
              />
              {validator.message(
                "supporting link",
                awardObj.supportingLink,
                "url"
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export const AwardsTab = connect(null, null)(AwardsClass);

class HelloMessage extends React.Component {
  render() {
    let { isLoderFileUpload } = this.props;
    return (
      <div className=" d-flex upload-drag">
        <div className="ml-3">
          <h4>Upload photo</h4>
          <label>Drag & drop your file here</label>
        </div>
        <div className="ml-3 mr-3 align-self-center">
          <label>or</label>
        </div>
        <div className="ml-5 align-self-center">
          <button
            type="button"
            className="btn mainbg-btn brows-btn outline-btn float-right"
          >
            Browse files{" "}
            {isLoderFileUpload ? (
              <span
                className="spinner-border spinner-border-sm ml-1"
                role="status"
                aria-hidden="true"
              ></span>
            ) : null}
          </button>
        </div>
      </div>
    );
  }
}
