import React, { Component } from "react";
import { AddGstComp } from "component/compliance";
import { Navbar } from "../../component/common";
import { UserAuth } from "helpers/UserAuth";
class addGstReturnClass extends Component {
  render() {
    return (
      <div>
        <div className="normal-header">{/* <Navbar /> */}</div>
        <AddGstComp id={this.props.match.params.id} />
      </div>
    );
  }
}

export const addGstReturn = UserAuth(addGstReturnClass, {
  service: "complainceGovernance",
});
