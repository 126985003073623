import React, { Component } from "react";
import "./uploadcomponent.scss";
import { Modal, ModalBody } from "reactstrap";
import Dropzone from "react-dropzone";
import { getFilePathDownload } from "service/api";

import {
  getCompanyKycBulkdownload,
  getFinancialBulkdownload,
  getDirectdocBulkdownload,
  getcreditdataBulkdownload,
  getstatutoryBulkdownload,
} from "action/DocumentAct";

import {
  getCompanyKycBulkdownloadOfFpo,
  getFinancialBulkdownloadOfFpo,
  getDirectdocBulkdownloadOfFpo,
  getcreditdataBulkdownloadOfFpo,
  getstatutoryBulkdownloadOfFpo,
} from "action/RmAct";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { UploadPopup } from "./UploadPopup";
import { getDescriptionData } from "service/helperFunctions";

import moment from "moment";

import { getAccessdata } from "service/helperFunctions";
import { Pagination } from "component/common";

const xlsxIcon = require("../../../assets/svg/Documents/xlsxIcon.png");
const pdf = require("../../../assets/svg/Documents/pdf.svg");
const trash = require("../../../assets/svg/Documents/trash.svg");
const eye = require("../../../assets/svg/Documents/eye.svg");
const downloadIcon = require("../../../assets/svg/Documents/download.svg");
const close = require("../../../assets/svg/Documents/close.svg");
const zoom_in = require("../../../assets/svg/Documents/zoom_in.svg");
const zoom_out = require("../../../assets/svg/Documents/zoom_out.svg");

const arrow_left = require("../../../assets/svg/Documents/arrow-left.svg");
const arrow_right = require("../../../assets/svg/Documents/arrow-right.svg");
const plus = require("../../../assets/svg/Documents/plus.svg");
const editIcon = require("../../../assets/svg/Documents/edit-icon.svg");

export class UploadComponentClass extends Component {
  constructor(props) {
    super();
    this.state = {
      file: null,
      modal: false,
      zoom: 1,
      file_type: "",
      documents: [],
      currentIndex: 1,
      csvUrl: "",
      openPopup: false,
      fileDetails: false,
      title: "KYC Company",
      isEdit: false,
      editData: {},
      pageMeta: {
        pageCount: undefined,
        page: 1,
      },
    };
  }
  componentDidUpdate(prevProps) {
    if (prevProps != this.props) {
      this.setState({ documents: this.props.documents });
    }
    const { modal } = this.state;
    if (!modal) {
      this.state.editData = "";
    }
  }

  toggle = (name) => {
    this.setState((prevState) => ({
      [name]: !prevState[name],
    }));
  };

  componentDidMount() {
    if (this.props.documents !== undefined) {
      this.setState({ documents: this.props.documents });
    }
  }

  handleDelete = (id) => {
    let { currentIndex, documents } = this.state;
    this.props.handleDelete(id);
    if (documents.length == 1) {
      this.setState({ currentIndex: 1 });
    } else {
      if (currentIndex - 1 === 0) {
        this.setState({ currentIndex: 1 });
      } else {
        this.setState({ currentIndex: currentIndex - 1 });
      }
    }
  };

  handleEditOption = (data) => {
    this.setState({ isEdit: true, editData: data });
    this.toggle("openPopup");
  };

  handlePageChange = ({ page }, document) => {
    let { pageMeta } = this.state;
    this.setState({ currentIndex: page });
    this.setState({
      pageMeta: { ...pageMeta, page },
    });
  };

  toggleTrue = () => {
    this.setState({ modal: true });
  };

  toggleFalse = () => {
    this.setState({ modal: false });
  };

  handleZoomIn = () => {
    let zoomVal = this.state.zoom;
    if (zoomVal < 3) {
      this.setState({
        zoom: zoomVal + 1,
      });
    }
  };

  handleZoomOut = () => {
    let zoomVal = this.state.zoom;
    if (zoomVal > 1) {
      this.setState({
        zoom: zoomVal - 1,
      });
    }
  };

  handlePagination = (direction) => {
    let { currentIndex } = this.state;
    let page = currentIndex;
    let currentPage = eval(`${page} ${direction} ${1}`);
    this.setState({ currentIndex: currentPage });
  };

  getCsvUrl = (id) => {
    let { activeTab, fpoId } = this.props;
    if (fpoId) {
      let { getDownloadAction } = "";
      if (activeTab == 1) {
        getDownloadAction = this.props.getCompanyKycBulkdownloadOfFpo;
      } else if (activeTab == 2) {
        getDownloadAction = this.props.getstatutoryBulkdownloadOfFpo;
      } else if (activeTab == 3) {
        getDownloadAction = this.props.getDirectdocBulkdownloadOfFpo;
      } else if (activeTab == 4) {
        getDownloadAction = this.props.getFinancialBulkdownloadOfFpo;
      } else {
        getDownloadAction = this.props.getcreditdataBulkdownloadOfFpo;
      }

      if (getDownloadAction !== "") {
        let body = {
          docId: id,
          fpoId,
        };
        getDownloadAction(body).then((data) => {
          this.setState(
            {
              csvUrl:
                "https://portal.fpogateway.com/fpomgmt/api/v1/download/zip?zipFileName=" +
                data.zipFileName,
            },
            () => {
              setTimeout(() => {
                document.getElementById("csv-download").click();
                this.setState({ csvUrl: "" });
              }, 4000);
            }
          );
        });
      }
    } else {
      let { getDownloadAction } = "";
      if (activeTab == 1) {
        getDownloadAction = this.props.getCompanyKycBulkdownload;
      } else if (activeTab == 2) {
        getDownloadAction = this.props.getstatutoryBulkdownload;
      } else if (activeTab == 3) {
        getDownloadAction = this.props.getDirectdocBulkdownload;
      } else if (activeTab == 4) {
        getDownloadAction = this.props.getFinancialBulkdownload;
      } else {
        getDownloadAction = this.props.getcreditdataBulkdownload;
      }

      if (getDownloadAction !== "") {
        let body = {
          id,
        };
        getDownloadAction(body).then((data) => {
          this.setState(
            {
              csvUrl:
                "https://portal.fpogateway.com/fpomgmt/api/v1/download/zip?zipFileName=" +
                data.zipFileName,
            },
            () => {
              setTimeout(() => {
                document.getElementById("csv-download").click();
                this.setState({ csvUrl: "" });
              }, 4000);
            }
          );
        });
      }
    }
  };

  onDropImage = async (acceptedFiles) => {
    this.setState(
      {
        fileDetails: acceptedFiles[0],
      },
      () => {
        this.toggle("openPopup");
      }
    );
  };

  render() {
    let { currentIndex, documents, csvUrl, openPopup, fileDetails, pageMeta } =
      this.state;

    let {
      id,
      activeTab,
      title,
      documentTitle,
      documentDetail = {},
      financialYearData = [],
      quartermasterData = [],
      monthData = [],
      documentName = "",
    } = this.props;

    let { documentType = "" } = documentDetail;

    let { accessData } = this.props;
    let permissionData = getAccessdata(accessData, "fpoDocuments");

    let {
      download = false,
      createAccess = false,
      deleteAccess = false,
    } = permissionData;

    return (
      <div className="upload-component d-flex justify-content-center align-items-center">
        {documents.length == 0 ? (
          <>
            {createAccess ? (
              <Dropzone onDrop={this.onDropImage}>
                {({ getRootProps }) => (
                  <div
                    {...getRootProps()}
                    style={{ outline: "none" }}
                    className="d-flex flex-column align-items-center w-100 h-100 justify-content-center"
                    onClick={() => this.toggle("openPopup")}
                  >
                    <div className="mb-2 upload-component-title">
                      {"Upload files "}
                    </div>
                    <div className="mb-2 upload-component-ins font-weight-bold">
                      {"Drag & drop your file here"}
                    </div>
                    <div className="mb-2 upload-component-ins  font-weight-bold">
                      {"or"}
                    </div>
                    <div>
                      <button>Browse files</button>
                    </div>
                  </div>
                )}
              </Dropzone>
            ) : (
              <div className="d-flex flex-column align-items-center w-100 h-100 justify-content-center">
                <div className="mb-2 upload-component-ins font-weight-bold">
                  {"You have no access to upload documents"}
                </div>
              </div>
            )}
          </>
        ) : (
          <div className="rounded align-items-center justify-content-between">
            <div className="h-100 d-flex flex-column justify-content-center">
              <div style={{ width: "243px" }}>
                {documents.map((list, index) => {
                  let checkPdf =
                    list?.documentName?.includes(".pdf") ||
                    list?.documentName?.includes(".PDF") ||
                    list?.documentName?.includes(".Pdf");
                  let renderPdf = checkPdf
                    ? list.documentName.split(".")[0] + ".pdf"
                    : list.documentName;
                  return (
                    currentIndex === index + 1 && (
                      <>
                        <div className="d-flex flex-column align-items-center justify-content-center">
                          {renderPdf?.includes(".pdf") ? (
                            <img
                              style={{
                                width: "136px",
                                height: "150px",
                              }}
                              src={pdf}
                              alt="pdf"
                            />
                          ) : list.documentName.includes("xlsx") ? (
                            <img
                              style={{
                                width: "136px",
                                height: "150px",
                              }}
                              src={xlsxIcon}
                              alt="xlsx"
                            />
                          ) : (
                            <img
                              style={{
                                width: "136px",
                                height: "150px",
                              }}
                              className="shadow rounded"
                              src={list?.documentPath}
                              alt="file"
                            />
                          )}
                          <p className="mb-1 text-center font-weight-bold fs-14 mt-2">
                            {list.reportName !== null
                              ? getDescriptionData(list.reportName, 20)
                              : ""}
                          </p>
                          {activeTab == 4 || activeTab == 5 ? (
                            <>
                              {/* audited financials */}
                              {documentType === "audited_financials" ? (
                                <p className="mb-1 text-center font-weight-bold fs-12">
                                  {list.financialFyYear !== null
                                    ? list.financialFyYear
                                    : ""}
                                </p>
                              ) : null}
                              {/* provisional financials */}
                              {documentType === "provisional_financials" ? (
                                <p className="mb-1 text-center font-weight-bold fs-12">
                                  {list.financialAsOnDate !== null
                                    ? moment(list.financialAsOnDate).format(
                                        "DD MMMM YYYY"
                                      )
                                    : ""}
                                </p>
                              ) : null}
                              {/* debtors aing, top, customers, top suppliers, borrowing profile ,creditor ageing*/}
                              {documentType === "debtors_ageing" ||
                              documentType === "top_customers" ||
                              documentType === "top_suppliers" ||
                              documentType === "borrowing_profile" ||
                              documentType === "COMPANYENTITY" ||
                              documentType === "INDIVIDUALBUREAU" ||
                              documentType === "creditor_ageing" ? (
                                <p className="mb-1 text-center font-weight-bold fs-12">
                                  {list.reportAsOnDate !== null
                                    ? moment(list.reportAsOnDate).format(
                                        "DD MMMM YYYY"
                                      )
                                    : ""}
                                </p>
                              ) : null}
                              {/* Bank statement */}
                              {documentType === "bank_statement" ? (
                                <p className="mb-1 text-center font-weight-bold fs-12">
                                  {list.reportFromDate !== null ? (
                                    <>
                                      {moment(list.reportFromDate).format(
                                        "DD MMMM YYYY"
                                      )}{" "}
                                      -{" "}
                                    </>
                                  ) : null}
                                  {list.reportToDate !== null
                                    ? moment(list.reportToDate).format(
                                        "DD MMMM YYYY"
                                      )
                                    : ""}
                                </p>
                              ) : null}
                              {/* Gst returns */}
                              {documentType === "gst_returns" ? (
                                <p className="mb-1 text-center font-weight-bold fs-12">
                                  {list.reportFyYear !== null ? (
                                    <>{list.reportFyYear} to </>
                                  ) : null}
                                  {list.reportQuarter !== null
                                    ? list.reportQuarter
                                    : null}
                                </p>
                              ) : null}
                              {/* it returns */}
                              {documentType === "it_returns" ? (
                                <p className="mb-1 text-center font-weight-bold fs-12">
                                  {list.reportFyYear !== null
                                    ? list.reportFyYear
                                    : null}
                                </p>
                              ) : null}
                              {/* sales report, purchase report */}
                              {documentType === "sales_report" ||
                              documentType === "purchase_report" ? (
                                <p className="mb-1 text-center font-weight-bold fs-12">
                                  {list.reportStartMonth !== null ? (
                                    <>{list.reportStartMonth}, </>
                                  ) : null}
                                  {list.reportStartYear !== null ? (
                                    <>{list.reportStartYear} to </>
                                  ) : null}
                                  {list.reportEndMonth !== null ? (
                                    <>{list.reportEndMonth}, </>
                                  ) : null}
                                  {list.reportEndYear !== null
                                    ? list.reportEndYear
                                    : null}
                                </p>
                              ) : null}
                            </>
                          ) : null}
                        </div>
                      </>
                    )
                  );
                })}
              </div>
              {documents.length > 1 ? (
                <Pagination
                  handleChange={(e) => this.handlePageChange(e)}
                  pageMeta={pageMeta}
                  uploadDocument={true}
                  totalDocuments={documents?.length}
                />
              ) : (
                <div style={{ height: "40px" }}></div>
              )}
            </div>
            <div
              className="d-flex flex-row justify-content-between mt-3"
              style={{ padding: "0 25px" }}
            >
              {createAccess && (
                <i
                  className="icon-add fs-20 cursor-pointer"
                  onClick={() => {
                    this.toggle("openPopup");
                    this.setState({ isEdit: false });
                  }}
                />
              )}
              <i
                className="icon-eye cursor-pointer fs-20"
                onClick={this.toggleTrue}
              />
              <i
                className="icon-edit fs-20 cursor-pointer"
                onClick={() =>
                  this.handleEditOption(documents[currentIndex - 1])
                }
              />
              {download ? (
                documents.length !== 0 ? (
                  <>
                    {csvUrl === "" ? (
                      <span onClick={() => this.getCsvUrl(id)}>
                        <img
                          className="mb-3 cursor-pointer"
                          src={downloadIcon}
                          alt="downloadIcon"
                        />
                      </span>
                    ) : (
                      <a
                        href={csvUrl}
                        id="csv-download"
                        download
                        className="text-decoration-none"
                      >
                        <img
                          className="mb-3 cursor-pointer"
                          src={downloadIcon}
                          alt="downloadIcon"
                        />
                      </a>
                    )}
                  </>
                ) : null
              ) : null}
              {deleteAccess && (
                <i
                  className="icon-delete text-danger cursor-pointer fs-20"
                  onClick={() =>
                    this.handleDelete(documents[currentIndex - 1].id)
                  }
                />
              )}
            </div>
          </div>
        )}
        <Modal
          isOpen={this.state.modal}
          toggle={this.toggleFalse}
          scrollable={true}
          size="lg"
        >
          <ModalBody>
            <div className="d-flex flex-md-row flex-column-reverse justify-content-between">
              <div className="d-flex flex-column w-100">
                <div>
                  {documents.map((list, index) => {
                    let checkPdf =
                      list?.documentName?.includes(".pdf") ||
                      list?.documentName?.includes(".PDF") ||
                      list?.documentName?.includes(".Pdf");
                    let renderPdf = checkPdf
                      ? list.documentName.split(".")[0] + ".pdf"
                      : list.documentName;
                    return (
                      <>
                        {currentIndex === index + 1 && (
                          <div className="d-flex justify-content-center img-view">
                            {renderPdf?.includes(".pdf") ? (
                              <iframe
                                className="pdf-view"
                                id="iframepdf"
                                src={list?.documentPath}
                                title="pdf-view"
                              />
                            ) : list.documentName.includes("xlsx") ? (
                              <img
                                style={{
                                  width: "136px",
                                  height: "150px",
                                }}
                                src={xlsxIcon}
                                alt="xlsx"
                              />
                            ) : (
                              <img
                                className={
                                  this.state.zoom === 1
                                    ? "shadow rounded zoom-1"
                                    : this.state.zoom === 2
                                    ? "shadow rounded zoom-2 "
                                    : "shadow rounded zoom-3 "
                                }
                                src={list?.documentPath}
                                alt="file"
                              />
                            )}
                          </div>
                        )}
                      </>
                    );
                  })}
                </div>
              </div>
              <div
                className="d-flex flex-row-reverse flex-md-column align-items-center"
                style={{ gap: "10px" }}
              >
                <img
                  onClick={this.toggleFalse}
                  className="mb-3 cursor-pointer"
                  src={close}
                  alt="close"
                />
                <img
                  onClick={this.handleZoomOut}
                  className="mb-3"
                  src={zoom_out}
                  alt="zoom_out"
                />
                <img
                  onClick={this.handleZoomIn}
                  className="mb-3"
                  src={zoom_in}
                  alt="zoom_in"
                />
                {download && documents.length !== 0 ? (
                  <a
                    href={
                      getFilePathDownload +
                      documents[currentIndex - 1]?.documentPath
                    }
                    download
                  >
                    <img
                      onClick={this.toggleFalse}
                      className="mb-3"
                      src={downloadIcon}
                      alt="downloadIcon"
                    />
                  </a>
                ) : null}
              </div>
            </div>
          </ModalBody>
        </Modal>
        <UploadPopup
          documentDetail={documentDetail}
          id={id}
          title={title}
          documentTitle={documentTitle}
          isOpen={openPopup}
          toggle={() => this.toggle("openPopup")}
          handleUpload={this.props.handleUpload}
          handleEditOption={this.props.handleEditOption}
          financialYearData={financialYearData}
          quartermasterData={quartermasterData}
          monthData={monthData}
          file={fileDetails}
          isXlsxFileAllowed={
            documentDetail.documentType === "projections_and_business_plan"
              ? true
              : documentDetail.documentType === "provisional_financials"
              ? true
              : activeTab === "5"
              ? true
              : false
          }
          isEdit={this.state.isEdit}
          editData={this.state.editData}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  accessData: state.home.accessData,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getCompanyKycBulkdownload,
      getFinancialBulkdownload,
      getDirectdocBulkdownload,
      getcreditdataBulkdownload,
      getstatutoryBulkdownload,
      getCompanyKycBulkdownloadOfFpo,
      getFinancialBulkdownloadOfFpo,
      getDirectdocBulkdownloadOfFpo,
      getcreditdataBulkdownloadOfFpo,
      getstatutoryBulkdownloadOfFpo,
    },
    dispatch
  );
};
let component = UploadComponentClass;

export const UploadComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(component);
