import { KeyFinancial } from "service/apiVariables";
import { Toast } from "service/toast";
import { api } from "service/api";
import { addQuery } from "service/helperFunctions";
import { keyFinancialType } from "service/actionType";

//Get All Financial summary List API
export const getAllKeyFinancialInfo = (query) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    addQuery(query, KeyFinancial.getAllKeyFinancialInfo);
    api({ ...KeyFinancial.getAllKeyFinancialInfo })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//Get Financial summary by Id API
export const getKeyFinancialInfoById = (id) => (dispatch, getState) => {
  KeyFinancial.getKeyFinancialInfoById.id = id;
  return new Promise((resolve, reject) => {
    api({ ...KeyFinancial.getKeyFinancialInfoById })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//Add Financial summary API
export const addKeyFinancialInfo = (body) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    api({ ...KeyFinancial.addKeyFinancialInfo, body })
      .then(({ data, message }) => {
        resolve(data);
        Toast({ type: "success", message, time: 5000 });
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//Update Financial summary API
export const updateKeyFinancialInfo = (body) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    api({ ...KeyFinancial.updateKeyFinancialInfo, body })
      .then(({ data, message }) => {
        resolve(data);
        Toast({ type: "success", message, time: 5000 });
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//Delete Financial summary API
export const deleteKeyFinancialInfo = (id) => (dispatch, getState) => {
  KeyFinancial.deleteKeyFinancialInfo.id = id;
  return new Promise((resolve, reject) => {
    api({ ...KeyFinancial.deleteKeyFinancialInfo })
      .then(({ data, message }) => {
        resolve(data);
        Toast({ type: "success", message, time: 5000 });
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//Get Credit History API
export const getAllCreditHistory = (query) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    addQuery(query, KeyFinancial.getAllCreditHistory);
    api({ ...KeyFinancial.getAllCreditHistory })
      .then(({ data, message }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//Get Credit History prefill API
export const getCreditHistoryById = (id) => (dispatch, getState) => {
  KeyFinancial.getCreditHistoryById.id = id;
  return new Promise((resolve, reject) => {
    api({ ...KeyFinancial.getCreditHistoryById })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//Add Credit History API
export const addCreditHistory = (body) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    api({ ...KeyFinancial.addCreditHistory, body })
      .then(({ data, message }) => {
        resolve(data);
        Toast({ type: "success", message, time: 5000 });
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//Update Credit History API
export const updateCreditHistory = (body) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    api({ ...KeyFinancial.updateCreditHistory, body })
      .then(({ data, message }) => {
        resolve(data);
        Toast({ type: "success", message, time: 5000 });
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//Delete Credit History API
export const deleteCreditHistory = (id) => (dispatch, getState) => {
  KeyFinancial.deleteCreditHistory.id = id;
  return new Promise((resolve, reject) => {
    api({ ...KeyFinancial.deleteCreditHistory })
      .then(({ data, message }) => {
        resolve(data);
        Toast({ type: "success", message, time: 5000 });
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//Get All API
export const getAllRevenue = (query) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    addQuery(query, KeyFinancial.getAllRevenue);
    api({ ...KeyFinancial.getAllRevenue })
      .then(({ data, message }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//Get Revenue Prefill API
export const getRevenueById = (id) => (dispatch, getState) => {
  KeyFinancial.getRevenueById.id = id;
  return new Promise((resolve, reject) => {
    api({ ...KeyFinancial.getRevenueById })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//Get Revenue API
export const addRevenue = (body) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    api({ ...KeyFinancial.addRevenue, body })
      .then(({ data, message }) => {
        resolve(data);
        Toast({ type: "success", message, time: 5000 });
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//Update Revenue API
export const updateRevenue = (body) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    api({ ...KeyFinancial.updateRevenue, body })
      .then(({ data, message }) => {
        resolve(data);
        Toast({ type: "success", message, time: 5000 });
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//Delete Revenue API
export const deleteRevenue = (id) => (dispatch, getState) => {
  KeyFinancial.deleteRevenue.id = id;
  return new Promise((resolve, reject) => {
    api({ ...KeyFinancial.deleteRevenue })
      .then(({ data, message }) => {
        resolve(data);
        Toast({ type: "success", message, time: 5000 });
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//Get All Plan API
export const getAllPlan = () => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    api({ ...KeyFinancial.getAllPlan })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//Get Plan Prefill API
export const getPlanById = (id) => (dispatch, getState) => {
  KeyFinancial.getPlanById.id = id;
  return new Promise((resolve, reject) => {
    api({ ...KeyFinancial.getPlanById })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//Add Plan API
export const addPlan = (body) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    api({ ...KeyFinancial.addPlan, body })
      .then(({ data, message }) => {
        resolve(data);
        Toast({ type: "success", message, time: 5000 });
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//Update Plan API
export const updatePlan = (body) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    api({ ...KeyFinancial.updatePlan, body })
      .then(({ data, message }) => {
        resolve(data);
        Toast({ type: "success", message, time: 5000 });
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//Delete Plan API
export const deletePlan = (id) => (dispatch, getState) => {
  KeyFinancial.deletePlan.id = id;
  return new Promise((resolve, reject) => {
    api({ ...KeyFinancial.deletePlan })
      .then(({ data, message }) => {
        resolve(data);
        Toast({ type: "success", message, time: 5000 });
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//Get All Financial Institution Dropdown API
export const getAllFinancialInstitution = (query) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    addQuery(query, KeyFinancial.getAllFinancialInstitution);
    api({ ...KeyFinancial.getAllFinancialInstitution })
      .then(({ data, message }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//Get All Activity Dropdown API
export const getAllActivity = (id) => (dispatch, getState) => {
  KeyFinancial.getAllActivity.id = id;
  return new Promise((resolve, reject) => {
    api({ ...KeyFinancial.getAllActivity })
      .then(({ data, message }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//Get All Financial Year Dropdown API
export const getAllFinancialYear = (query) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    addQuery(query, KeyFinancial.getAllFinancialYear);
    api({ ...KeyFinancial.getAllFinancialYear })
      .then(({ data, message }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//download Key Financial Csv For Fpo
export const downloadKeyFinancialCsvForFpo =
  (tabName) => (dispatch, getState) => {
    let { downloadKeyFinancialCsvForFpo } = KeyFinancial;
    downloadKeyFinancialCsvForFpo.tabName = tabName;
    return new Promise((resolve, reject) => {
      api({ ...KeyFinancial.downloadKeyFinancialCsvForFpo })
        .then((data) => {
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

export const keyFinancialCurrentTab = (payload) => (dispatch) => {
  dispatch({ type: keyFinancialType.keyFinancialCurrentTab, payload });
};

//get key financial list data
export const getkeyfinanciallistdata = () => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    api({ ...KeyFinancial.getkeyfinanciallistdata })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//get Role Analysis data
export const getRoleAnalysisdata = () => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    api({ ...KeyFinancial.getRoleAnalysisdata })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//get performance data
export const getperformancedata = () => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    api({ ...KeyFinancial.getperformancedata })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//Get Credit History prefill API
export const getBankDetailById = (id) => (dispatch, getState) => {
  KeyFinancial.getBankDetailById.id = id;
  return new Promise((resolve, reject) => {
    api({ ...KeyFinancial.getBankDetailById })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//Add Credit History API
export const addBankDetail = (body) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    api({ ...KeyFinancial.addBankDetail, body })
      .then(({ data, message }) => {
        resolve(data);
        Toast({ type: "success", message, time: 5000 });
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//Update Credit History API
export const updateBankDetail = (id, body) => (dispatch, getState) => {
  KeyFinancial.updateBankDetail.id = id;
  return new Promise((resolve, reject) => {
    api({ ...KeyFinancial.updateBankDetail, body })
      .then(({ data, message }) => {
        resolve(data);
        Toast({ type: "success", message, time: 5000 });
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//Delete Credit History API
export const deleteBankDetail = (id) => (dispatch, getState) => {
  KeyFinancial.deleteBankDetail.id = id;
  return new Promise((resolve, reject) => {
    api({ ...KeyFinancial.deleteBankDetail })
      .then(({ data, message }) => {
        resolve(data);
        Toast({ type: "success", message, time: 5000 });
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

// Get All Bank Details
export const getAllBankDetails = (query) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    addQuery(query, KeyFinancial.getAllBankDetails);
    api({ ...KeyFinancial.getAllBankDetails })
      .then(({ data, message }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};
