import { PreLogin } from 'service/apiVariables';
import { Toast } from 'service/toast';
import { api } from 'service/api';
import { addQuery } from 'service/helperFunctions';

export const getPreNewsAndTestimonals = () => (dispatch, getState) => {
   return new Promise((resolve, reject) => {
      api({ ...PreLogin.getNewsAndTestimonals })
         .then(({ data }) => {
            resolve(data);
         })
         .catch(({ message }) => {
            reject(Toast({ type: 'error', message }));
         });
   });
};
//Get All FAQs API
export const getFaqAndHeaderCount = (query) => (dispatch, getState) => {
   return new Promise((resolve, reject) => {
      addQuery(query, PreLogin.getFaqAndHeaderCount);
      api({ ...PreLogin.getFaqAndHeaderCount })
         .then(({ data }) => {
            resolve(data);
         })
         .catch(({ message }) => {
            reject(Toast({ type: 'error', message }));
         });
   });
};
export const getIntroVideo = () => (dispatch, getState) => {
   return new Promise((resolve, reject) => {
      api({ ...PreLogin.getIntroVideo })
         .then(({ data }) => {
            resolve(data);
         })
         .catch(({ message }) => {
            reject(Toast({ type: 'error', message }));
         });
   });
};
export const getForumList = () => (dispatch, getState) => {
   return new Promise((resolve, reject) => {
      api({ ...PreLogin.getForumList })
         .then(({ data }) => {
            resolve(data);
         })
         .catch(({ message }) => {
            message !== null &&
               reject(Toast({ type: 'error', message }));
         });
   });
};
export const getGatewayList = () => (dispatch, getState) => {
   return new Promise((resolve, reject) => {
      api({ ...PreLogin.getGatewayList })
         .then(({ data }) => {
            resolve(data);
         })
         .catch(({ message }) => {
            reject(Toast({ type: 'error', message }));
         });
   });
};
export const getBlogList = () => (dispatch, getState) => {
   return new Promise((resolve, reject) => {
      api({ ...PreLogin.getBlogList })
         .then(({ data }) => {
            resolve(data);
         })
         .catch(({ message }) => {
            reject(Toast({ type: 'error', message }));
         });
   });
};
export const getCoursesAndInformativeVideos = () => (dispatch, getState) => {
   return new Promise((resolve, reject) => {
      api({ ...PreLogin.getCoursesAndInformativeVideos })
         .then(({ data }) => {
            resolve(data);
         })
         .catch(({ message }) => {
            reject(Toast({ type: 'error', message }));
         });
   });
};
export const getCalenderList = (query) => (
   dispatch,
   getState,
   { api, Toast }
) => {
   return new Promise((resolve, reject) => {
      addQuery(query, PreLogin.getCalenderList);

      api({ ...PreLogin.getCalenderList })
         .then(({ data, message }) => {
            resolve(data);
         })
         .catch(({ message }) => {
            reject(Toast({ type: 'error', message }));
         });
   });
};
