import React, { Component } from "react";
import { RMHome } from "component/RM";

export class RMHomePage extends Component {
  componentDidMount() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  render() {
    return (
      <div>
        <div className="inner-header">
          <div className="h-100 w-100">
            <p className="rm_title d-flex justify-content-center align-items-center h-100 w-100 text-white fs-32 font-weight-bold">
              RM Dashboard
            </p>
          </div>
        </div>
        <RMHome module="rm" />
      </div>
    );
  }
}
