import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import moment from "moment";
import "./index.scss";
export const CustomDate = (props) => {
  useEffect(() => {}, [props.list]);
  return (
    <div className="custom-date-rm customDatePickerWidth">
      <DatePicker
        renderCustomHeader={({
          date,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
          subMonths,
        }) => (
          <div
            style={{
              margin: 10,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <button
              onClick={() => {
                decreaseMonth();
              }}
              disabled={prevMonthButtonDisabled}
            >
              {"<"}
            </button>
            <div className="month-year">{moment(date).format("MMMM YYYY")}</div>

            <button
              onClick={() => {
                increaseMonth();
              }}
              disabled={nextMonthButtonDisabled}
            >
              {">"}
            </button>
          </div>
        )}
        inline
        disabled={true}
        highlightDates={[...props.list]}
        onMonthChange={(date) => {
          props.getList({
            month: moment(date).format("MM"),
            year: moment(date).format("YYYY"),
          });
        }}
      />
    </div>
  );
};
