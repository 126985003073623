import React from "react";
import { Modal, ModalBody } from "reactstrap";
import "./style.scss";

const BooksPopup = (props) => {
  const { isOpen, toggle, booksData } = props;
  const { title = "", redirectURL = {} } = booksData || {};

  return (
    <Modal isOpen={isOpen} centered={true} className="ipl-modal">
      <ModalBody>
        <div className="books-popup">
          <i className="fas fa-times" onClick={() => toggle()}></i>
          <div className="w-100 mt-3 d-flex align-items-center flex-column">
            <h1>{title}</h1>
            <div className="d-flex align-items-center mt-3">
              <button
                class="btn cursor-pointer mainbg-btn px-3 py-2 fs-14 line-height-26 mr-3"
                onClick={() => window.open(redirectURL?.home, "_blank")}
              >
                BMS Home
              </button>
              <button
                class="btn cursor-pointer mainbg-btn px-3 py-2 fs-14 line-height-26"
                onClick={() =>
                  window.open(redirectURL?.mandiProcurement, "_blank")
                }
              >
                Mandi Procurement module
              </button>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default BooksPopup;
