import React, { useState } from 'react'
import ReactSelect, { components } from 'react-select'

function SelectCheckbox({ isMulti = false, handleChange, options = [], selectedOptions = null, closeMenuOnSelect = true }) {

    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            height: 30,
            paddingBottom: 0
        }),
        valueContainer: (base) => ({
            ...base,
            minHeight: 47,
            maxHeight: 47,
            overflowY: "auto"
        })
    };
    return (
        <ReactSelect
            options={options}
            isMulti={isMulti}
            closeMenuOnSelect={closeMenuOnSelect}
            hideSelectedOptions={false}
            components={{
                Option
            }}
            onChange={handleChange}
            allowSelectAll={true}
            value={selectedOptions}
            style={customStyles}
            theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                colors: {
                    ...theme.colors,
                    primary25: '#BADCCA',
                    primary: '#189C5C',
                },
            })}
			classNamePrefix="Select"
        />
    )
}

const Option = (props) => {
    return (
        <div>
            <components.Option {...props}>
                <input
                    type="checkbox"
                    checked={props.isSelected}
                    onChange={() => null}
                />{" "}
                <label>{props.label}</label>
            </components.Option>
        </div>
    );
};

export default SelectCheckbox