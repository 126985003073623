import React, { Component } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination } from "swiper";
import moment from "moment";
import { Card } from "./card";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getNewsAndTestimonals } from "action/HomeAct";
import "./success_story.scss";
const signature1 = require("assets/svg/success_stories/Signature1.svg");
export class SuccessStoryClass extends Component {
  constructor(props) {
    super();
    SwiperCore.use([Navigation, Pagination]);
    this.state = {
      stories: [],
    };
  }

  //Lifecycles
  componentDidMount() {
    this._getStoryFromProps();
  }

  //get story details
  _getStoryFromProps = () => {
    let dataSet = [];
    this.props.successstories &&
      this.props.successstories.map((data) => {
        dataSet.push({
          img: data.uploadImage,
          title: data.title,
          date: moment(data.publishOn).format("MMM DD, YYYY"),
          content: data.description,
          signature: signature1,
          successId: data.successId,
        });
      });
    this.setState({
      stories: dataSet,
    });
  };

  //lifecycles
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.successstories !== this.props.successstories) {
      this._getStoryFromProps();
    }
  }

  render() {
    const params = {
      spaceBetween: 10,
      loop: false,
      pagination: {
        clickable: true,
      },
      breakpoints: {
        600: {
          slidesPerView: 1,
        },
        768: {
          slidesPerView: 3,
        },
      },
    };

    return (
      <div className="pb-4">
        <div className="container-sa d-flex mb-4">
          <p className="mx-auto fs-32 fw-800 text-gold card-success-header mb-2 mt-4 pt-2">
            Success stories
          </p>
        </div>
        <div className="container-fluid">
          <Swiper {...params}>
            {this.state.stories.map((story, index) => {
              return (
                <SwiperSlide key={index}>
                  <span className="mr-3">
                    <Card story={story} />
                  </span>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getNewsAndTestimonals,
    },
    dispatch
  );
};
let component = SuccessStoryClass;

export const SuccessStory = connect(null, mapDispatchToProps)(component);
