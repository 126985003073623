import React, { Component } from "react";
import moment from "moment";
import { Col, Row } from "reactstrap";
import { getDescriptionData } from "service/helperFunctions";
export default class Card extends Component {
  render() {
    const { content, details } = this.props;
    const { thumb, date, title, description, linkUrl } = details;
    return (
      <div className="new-card rounded shadow">
        <div>
          <img className="thumb" src={thumb} alt="thumb" />
        </div>
        <div className="p-3 ">
          {content === "News" && (
            <div className="mb-2">
              <span className="fs-30 font-weight-bold mr-2 date">
                {moment(date).format("DD")}
              </span>
              <span className="font-weight-bold">
                {moment(date).format("MMM YYYY")}
              </span>
            </div>
          )}
          <Row>
            {content === "Events" && (
              <Col
                md="2"
                className="d-flex flex-sm-column flex-row align-items-center"
              >
                <div className="font-weight-bold mb-sm-2 mb-0">
                  {moment(date).format("MMM")}
                </div>
                <div className="fs-30 font-weight-bold mr-sm-2 ml-sm-0 ml-2 date">
                  {moment(date).format("DD")}
                </div>
              </Col>
            )}
            <Col md={`${content === "News" ? "12" : "10"}`}>
              <div className="font-weight-bold title fs-18 mb-1">
                {getDescriptionData(title, 50)}
              </div>
              <div
                className="desc"
                dangerouslySetInnerHTML={{
                  __html: description,
                }}
              ></div>
              <div className="link">
                <a
                  href={
                    linkUrl && linkUrl.includes("http" || "https")
                      ? linkUrl
                      : `https://${linkUrl}`
                  }
                  className="text-decoration-none text-green-18"
                  target="_blank"
                >
                  {content === "News" ? "Read more" : "Register"}
                </a>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}
