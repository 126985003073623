import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getGateway, updateBMS, updateTermsCondition } from "action/GatewayAct";
import { BMSPopup } from "component/common";
import { IPLpopup } from "component/common/IPLpopup";
import SellPopup from "component/common/SellPopup";
import BooksPopup from "component/common/BooksPopup";
import LanguagePopup from "component/common/LanguagePopup";
import { TermsConditionPopup } from "component/common/TermsConditionPopup";
import { Toast } from "service/toast";
class GatewayComp extends Component {
  state = {
    gateway: [],
    partner: [],
    bms: "",
    isOpen: false,
    bmsURL: "",
    isIplPopup: false,
    isSellPopup: false,
    sellData: {},
    isBooksPopup: false,
    booksData: {},
    languagePopup: false,
    termsConditionPopup: false,
    isMandi: false,
    isPrivacy: false,
  };

  componentDidMount() {
    let { getGateway } = this.props;
    let { languagePopup, termsConditionPopup } = this.state;
    let isLanguage = localStorage.getItem("languagePopup");
    let isMandi = JSON.parse(localStorage.getItem("isMandi"));
    if (isLanguage === "1") {
      this.setState({ languagePopup: !languagePopup });
    }
    this.setState({ termsConditionPopup: !termsConditionPopup });
    getGateway().then(({ gatewayServices, partnerServices }) => {
      this.setState({
        gateway: gatewayServices,
        partner: partnerServices,
        isMandi: isMandi,
      });
    });
  }

  handleRedirect = (url) => {
    if (this.props.bms) {
      window.open(url, "_blank");
    } else {
      this.setState({
        bmsURL: url,
        isOpen: true,
      });
    }
  };

  toggle = () => {
    let { isOpen } = this.state;
    this.setState({
      isOpen: !isOpen,
    });
  };

  handleChange = ({ target: { name, value, checked } }) => {
    this.setState({
      bms: checked ? value : "",
    });
    this.setState({
      isPrivacy: checked,
    });
  };

  onSubmit = () => {
    let { updateTermsCondition } = this.props;
    const { isPrivacy } = this.state;
    if (!isPrivacy) {
      Toast({
        type: "error",
        message:
          "Please agree to the Terms & Conditions and Privacy Policy to continue using the application",
      });
      return null;
    } else {
      let body = {
        isTCAccepted: 1,
      };
      updateTermsCondition(body).then((message) => {
        Toast({
          type: "success",
          message:
            "Terms & Conditions and Privacy Policy updated successfully!",
        });
        localStorage.setItem("isTCAccepted", true);
      });
      this.handleTermsConditionpopup();
    }
  };

  //handle Ipl popup
  handleIplpopup = () => {
    let { isIplPopup } = this.state;
    this.setState({ isIplPopup: !isIplPopup });
  };

  handleSellpopup = (list) => {
    let { isSellPopup } = this.state;
    this.setState({ sellData: list, isSellPopup: !isSellPopup });
  };

  handleBooksPopup = (list) => {
    let { isBooksPopup } = this.state;
    this.setState({ booksData: list, isBooksPopup: !isBooksPopup });
  };

  handleLanguagepopup = () => {
    let { languagePopup } = this.state;
    this.setState({ languagePopup: !languagePopup });
    localStorage.setItem("languagePopup", 2);
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  handleTermsConditionpopup = () => {
    let { termsConditionPopup } = this.state;
    this.setState({ termsConditionPopup: !termsConditionPopup });
  };

  render() {
    const {
      gateway,
      partner,
      isOpen,
      bms,
      isIplPopup,
      isSellPopup,
      sellData,
      isBooksPopup,
      booksData,
      languagePopup,
      termsConditionPopup,
      isPrivacy,
      isMandi,
    } = this.state;

    return (
      <Fragment>
        <div className="w-100 mt-2 ">
          <div className="gateway-card-header text-gold mb-3">
            Gateway Services
          </div>
          <div className="row">
            {gateway &&
              gateway.map((list, index) => {
                return (
                  <div className="col-md-3 col-sm-4 col-6" key={index}>
                    <div
                      className="d-flex flex-column align-items-center cursor-pointer gateway-link"
                      onClick={() =>
                        list.title === "Explore Loans"
                          ? this.handleIplpopup()
                          : list.title === "Sell Produce"
                          ? this.handleSellpopup(list)
                          : list.title === "Manage My Books"
                          ? isMandi
                            ? this.handleBooksPopup(list)
                            : window.open(list?.redirectURL?.home, "_blank")
                          : window.open(
                              list?.redirectURL?.redirectURL,
                              "_blank"
                            )
                      }
                    >
                      <div className="rounded-circle p-4 shadow mb-3 gateway-image-container">
                        <img
                          className="gateway-image "
                          src={list?.icon}
                          alt="gw-icon.svg"
                        />
                      </div>
                      <div className="font-weight-bold container-60 text-center d-flex justify-content-center mb-1">
                        {list?.title}
                      </div>
                      {/* <div className="font-weight-bold text-break text-center d-flex justify-content-center mb-2">
                        {"(Coming soon)"}
                      </div> */}
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
        <BMSPopup
          isOpen={isOpen}
          toggle={this.toggle}
          onChange={this.handleChange}
          bms={bms}
          onSubmit={this.onSubmit}
        />
        <IPLpopup isOpen={isIplPopup} toggle={this.handleIplpopup} />
        <SellPopup
          isOpen={isSellPopup}
          toggle={this.handleSellpopup}
          sellData={sellData}
        />
        <BooksPopup
          isOpen={isBooksPopup}
          toggle={this.handleBooksPopup}
          booksData={booksData}
        />
        {/* <LanguagePopup
          isOpen={languagePopup}
          toggle={this.handleLanguagepopup}
        /> */}
        <TermsConditionPopup
          isOpen={
            localStorage.getItem("isTCAccepted") === "true"
              ? false
              : termsConditionPopup
          }
          toggle={this.handleTermsConditionpopup}
          onChange={this.handleChange}
          terms={isPrivacy}
          onSubmit={this.onSubmit}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  bms: state.bms.isBms,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getGateway,
      updateBMS,
      updateTermsCondition,
    },
    dispatch
  );
};
let component = GatewayComp;

export const Gateway = connect(mapStateToProps, mapDispatchToProps)(component);
