import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import { getDescriptionData } from "service/helperFunctions";
import { history } from "service/helpers";
import "./index.scss";
export class ForumPre extends Component {
  state = {
    forumList: [],
  };

  //lifecycles
  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.setState({
        forumList: this.props.forumList.results,
      });
    }
  }
  componentDidMount() {
    this.setState({
      forumList: this.props.forumList.results,
    });
  }
  render() {
    const { forumList = [] } = this.state;

    return (
      <div className="bg-white">
        <div className="container-sa">
          <Row>
            <Col sm="5">
              <div className="forum-list-container">
                <div className="title">Community Forums</div>
                {forumList.length !== 1 ? (
                  forumList.length > 0 &&
                  forumList.map((list, index) => {
                    return (
                      <React.Fragment key={index}>
                        {index !== 0 && (
                          <>
                            <div>
                              <div className="forum-title">
                                <h2>{getDescriptionData(list.title, 40)}</h2>
                              </div>
                              <div>
                                <div className="forum-description position-relative">
                                  <Row>
                                    <Col sm="1">
                                      <span className="forum-bullets position-absolute"></span>
                                    </Col>
                                    <Col sm="9">
                                      <div
                                        className="cursor-pointer"
                                        onClick={() => {
                                          history.push("/login");
                                        }}
                                        dangerouslySetInnerHTML={{
                                          __html: getDescriptionData(
                                            list.content,
                                            100
                                          ),
                                        }}
                                      ></div>
                                    </Col>
                                    <Col sm="2">
                                      <div className="d-flex align-items-center">
                                        <i className="icon-leaf fs-16 pr-2"></i>
                                        {list.likesCount}
                                      </div>
                                    </Col>
                                  </Row>
                                </div>
                              </div>
                              <div className="forum-actions">
                                <div className="mr-4">
                                  <i className="pr-2 icon-thumbs-up-color fs-18 " />
                                  {list.likesCount}
                                </div>
                                <div>
                                  <i className="pr-2 icon-comment fs-18" />
                                  {list.commentsCount}
                                </div>
                              </div>
                            </div>
                            {index + 1 != forumList.length && <hr />}
                          </>
                        )}
                      </React.Fragment>
                    );
                  })
                ) : (
                  <p className="py-5 my-5 text-white fw-600 fs-22 line-height-25 text-center">
                    {"No forum here !!!"}
                  </p>
                )}
              </div>
            </Col>
            <Col sm="7">
              <div className="forum-container">
                {forumList.length > 0 && (
                  <>
                    <img
                      className="img mb-3"
                      src={forumList[0]?.images[0]?.url}
                      alt="banner"
                    />
                    <div className="d-flex flex-column justify-content-between">
                      <div>
                        <div>
                          <Row>
                            <Col sm="9">
                              <div className="forum-title">
                                <h2>
                                  {getDescriptionData(forumList[0]?.title, 40)}
                                </h2>
                              </div>
                            </Col>
                            <Col sm="2" className="d-flex align-items-center">
                              <div className="forum-actions">
                                <div className="mr-4">
                                  <i className="pr-2 icon-thumbs-up-color fs-18 " />
                                  {forumList[0]?.likesCount}
                                </div>
                                <div>
                                  <i className="pr-2 icon-comment fs-18" />
                                  {forumList[0]?.commentsCount}
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </div>
                        <div
                          className="forum-content cursor-pointer"
                          dangerouslySetInnerHTML={{
                            __html: getDescriptionData(
                              forumList[0]?.content,
                              300
                            ),
                          }}
                          onClick={() => {
                            history.push("/login");
                          }}
                        ></div>
                        <div className="d-flex align-items-center">
                          <i className="icon-leaf fs-16 pr-2"></i>
                          <span className="fw-600 ">
                            Post : {forumList[0]?.likesCount}
                          </span>
                        </div>
                      </div>
                      <div className="view-all">
                        <p onClick={() => history.push("/login")}>View all</p>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}
