import React, { Component } from "react";
import { Navbar } from "../../component/common";
import { UserAuth } from "helpers/UserAuth";
import { AddPlan } from "component/KeyFinancial";

class addPlanClass extends Component {
  render() {
    return (
      <div>
        <div className="inner-header">
          {/* <Navbar /> */}
          <div className="h-100 w-100">
            <p className="d-flex justify-content-center align-items-center h-100 w-100 text-white fs-32 font-weight-bold">
              FPO Documents
            </p>
          </div>
        </div>
        <AddPlan id={this.props.match.params.id} />
      </div>
    );
  }
}

export const addPlan = UserAuth(addPlanClass, { service: "keyFinancials" });
