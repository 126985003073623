import React, { Component } from "react";
import SimpleReactValidator from "simple-react-validator";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Toast } from "service/toast";
import { getAllFinancialYearDropdown } from "action/ComplianceAct";
export class FpoRegStepTwoClass extends Component {
  constructor() {
    super();
    this.state = {
      allFinancialYear: [],
    };
    this.membershipRef = React.createRef();
    this.maleRef = React.createRef();
    this.femaleRef = React.createRef();
    this.acreRef = React.createRef();
  }

  validator = new SimpleReactValidator({
    element: (message) => (
      <span className="error-message text-danger fs-14">{message}</span>
    ),
    autoForceUpdate: this,
    validators: {
      url: {
        message: "The :attribute must be a valid format.",
        rule: (val, params, validator) => {
          return (
            validator.helpers.testRegex(
              val,
              /^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+\.[a-z]+(\/[a-zA-Z0-9#]+\/?)*$/
            ) && params.indexOf(val) === -1
          );
        },
        messageReplace: (message, params) =>
          message.replace("", this.helpers.toSentence(params)),
      },
    },
  });

  componentDidMount() {
    this.getFinancialYear();
  }

  //handle submit
  handleSubmit = (e) => {
    e.preventDefault();
    const { formFieldsFPO } = this.props;
    const { membership, femaleMembers, acre } = formFieldsFPO;
    if (membership === "") {
      this.membershipRef.current.focus();
    } else if (parseInt(membership) < parseInt(femaleMembers)) {
      this.maleRef.current.focus();
      return null;
    } else if (femaleMembers === "") {
      this.femaleRef.current.focus();
    } else if (acre === "") {
      this.acreRef.current.focus();
    }
    if (this.validator.allValid()) {
      let {
        islogoFileUploading,
        isTeamLogoFileuploading,
        isresourseInsitituteLogoFileUploading,
        isinstitutelogoFileUploading,
      } = formFieldsFPO;
      if (
        !isinstitutelogoFileUploading &&
        !islogoFileUploading &&
        !isresourseInsitituteLogoFileUploading &&
        !isTeamLogoFileuploading
      ) {
        this.props.handlePageChange("step3");
      } else {
        Toast({ type: "error", message: "Please wait image is uploading" });
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  getFinancialYear() {
    this.props.getAllFinancialYearDropdown().then((data) => {
      let financialData = [];
      data.financialYearMaster.map((list) => {
        financialData.push({ value: list.fymId, label: list.fymYear });
      });
      this.setState({
        allFinancialYear: financialData,
      });
    });
  }

  render() {
    const {
      formFieldsFPO,
      handleChange,
      uploadLogoFile,
      handleRemoveImage,
      uploadTeamLogoFile,
      uploadResourseInstitution,
      uploadInstitutionFile,
      handlePageChange,
    } = this.props;

    let {
      logoFileName,
      islogoFileUploading,
      teamLogoFileName,
      isTeamLogoFileuploading,
      resourseInstitution,
      isresourseInsitituteLogoFileUploading,
      resourseInsitituteLogoFileName,
      promotingInstitution,
      isinstitutelogoFileUploading,
      insitituteLogoFileName,
      officialWebsite,
      facebookLink,
      instragamLink,
      twitterLink,
      acre,
      shareCapitals,
      membership,
      femaleMembers,
      maleMembers,
      villageCovered,
      turnOver,
      FyearId,
    } = formFieldsFPO;

    let { allFinancialYear } = this.state;

    return (
      <div>
        <div className="row mb-3">
          <div className="col-12">
            <p className="text-black-1c fw-600 mb-0 text-center fs-18">
              Step 2 of 3
            </p>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-md-6 col-12 mb-3">
            <label>FPO logo</label>
            <div className="upload-area">
              <div className="d-flex flex-column justify-content-start">
                <div className="btn-file-input">
                  <button className="">
                    <input
                      type="file"
                      className=""
                      accept=".jpg, .png, .jpeg"
                      onChange={uploadLogoFile}
                      id="uploadLogoFile"
                      title=""
                    />
                    {logoFileName == "" ? (
                      "Choose file"
                    ) : (
                      <>
                        {logoFileName.length == 10
                          ? logoFileName
                          : logoFileName.substring(0, 9)}
                        ...
                      </>
                    )}
                  </button>
                </div>
                {logoFileName != "" && !islogoFileUploading && (
                  <div className="d-flex mb-1 align-items-center justify-content-between delete-area-width">
                    <div className="text-danger">Remove</div>
                    <i
                      className="icon-Delete text-danger fs-14 pl-2 cursor-pointer"
                      onClick={() => handleRemoveImage("logoFile")}
                    ></i>
                  </div>
                )}
              </div>
              {/* {this.validator.message("logo", logoFileName, "required")} */}
            </div>
          </div>
          <div className="col-md-6 col-12 mb-3">
            <label>Team photo</label>
            <div className="upload-area">
              <div className="d-flex flex-column justify-content-start">
                <div className="btn-file-input">
                  <button className="">
                    <input
                      type="file"
                      className=""
                      accept=".jpg, .png, .jpeg"
                      onChange={uploadTeamLogoFile}
                      id="uploadTeamLogoFile"
                      title=""
                    />
                    {teamLogoFileName == "" ? (
                      "Choose file"
                    ) : (
                      <>
                        {teamLogoFileName.length == 10
                          ? teamLogoFileName
                          : teamLogoFileName.substring(0, 9)}
                        ...
                      </>
                    )}
                  </button>
                </div>
                {teamLogoFileName != "" && !isTeamLogoFileuploading && (
                  <div className="d-flex mb-1 align-items-center justify-content-between delete-area-width">
                    <div className="text-danger">{"Remove"}</div>
                    <i
                      className="icon-Delete text-danger fs-14 pl-2 cursor-pointer"
                      onClick={() => handleRemoveImage("teamlogoFile")}
                    ></i>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className=" row">
          <div className="col-md-6 col-12 mb-3">
            <label>Promoting institution</label>
            <input
              type="text"
              className="form-control w-100"
              name="promotingInstitution"
              value={promotingInstitution}
              onChange={handleChange}
            />
            {this.validator.message(
              "promotingInstitution",
              promotingInstitution,
              "max:75"
            )}
          </div>
          <div className="col-md-6 col-12 mb-3">
            <label>Promoting institution Logo</label>
            <div className="upload-area">
              <div className="d-flex flex-column justify-content-start">
                <div className="btn-file-input">
                  <button className="">
                    <input
                      type="file"
                      className=""
                      accept=".jpg, .png, .jpeg"
                      onChange={uploadInstitutionFile}
                      id="uploadInstitutionFile"
                      title=""
                    />
                    {insitituteLogoFileName == "" ? (
                      "Choose file"
                    ) : (
                      <>
                        {insitituteLogoFileName.length == 10
                          ? insitituteLogoFileName
                          : insitituteLogoFileName.substring(0, 9)}
                        ...
                      </>
                    )}
                  </button>
                </div>
                {insitituteLogoFileName != "" && !isinstitutelogoFileUploading && (
                  <div className="d-flex mb-1 align-items-center justify-content-between delete-area-width">
                    <div className="text-danger">{"Remove"}</div>
                    <i
                      className="icon-Delete text-danger fs-14 pl-2 cursor-pointer"
                      onClick={() => handleRemoveImage("insitituteLogoFile")}
                    ></i>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className=" row">
          <div className="col-md-6 col-12 mb-3">
            <label>Resource institution</label>
            <input
              type="text"
              className="form-control w-100"
              name="resourseInstitution"
              value={resourseInstitution}
              onChange={handleChange}
              max={75}
            />
          </div>
          <div className="col-md-6 col-12 mb-3">
            <label>Promoting institution Logo</label>
            <div className="upload-area">
              <div className="d-flex flex-column justify-content-start">
                <div className="btn-file-input">
                  <button className="">
                    <input
                      type="file"
                      className=""
                      accept=".jpg, .png, .jpeg"
                      onChange={uploadResourseInstitution}
                      id="uploadResourseInstitution"
                      title=""
                    />
                    {resourseInsitituteLogoFileName == "" ? (
                      "Choose file"
                    ) : (
                      <>
                        {resourseInsitituteLogoFileName.length == 10
                          ? resourseInsitituteLogoFileName
                          : resourseInsitituteLogoFileName.substring(0, 9)}
                        ...
                      </>
                    )}
                  </button>
                </div>
                {resourseInsitituteLogoFileName != "" &&
                  !isresourseInsitituteLogoFileUploading && (
                    <div className="d-flex mb-1 align-items-center justify-content-between delete-area-width">
                      <div className="text-danger">{"Remove"}</div>
                      <i
                        className="icon-Delete text-danger fs-14 pl-2 cursor-pointer"
                        onClick={() => handleRemoveImage("resourseInsititute")}
                      ></i>
                    </div>
                  )}
              </div>
            </div>
          </div>
        </div>
        <div className=" row">
          <div className="col-md-6 col-12 mb-3">
            <label>Official website</label>
            <input
              type="text"
              className="form-control w-100"
              name="officialWebsite"
              value={officialWebsite}
              onChange={handleChange}
            />
            {this.validator.message("official Website", officialWebsite, "url")}
          </div>
          <div className="col-md-6 col-12 mb-3">
            <label>Facebook link</label>
            <input
              type="text"
              className="form-control w-100"
              name="facebookLink"
              value={facebookLink}
              onChange={handleChange}
            />
            {this.validator.message("facebook Link", facebookLink, "url")}
          </div>
        </div>
        <div className=" row">
          <div className="col-md-6 col-12 mb-3">
            <label>Twitter link</label>
            <input
              type="text"
              className="form-control w-100"
              name="twitterLink"
              value={twitterLink}
              onChange={handleChange}
            />
            {this.validator.message("twitter Link", twitterLink, "url")}
          </div>
          <div className="col-md-6 col-12 mb-3">
            <label>Instagram link</label>
            <input
              type="text"
              className="form-control w-100"
              name="instragamLink"
              value={instragamLink}
              onChange={handleChange}
            />
            {this.validator.message("instragam Link", instragamLink, "url")}
          </div>
        </div>
        <div className=" row">
          <div className="col-md-6 col-12 mb-3">
            <label>Villages covered</label>
            <input
              type="text"
              className="form-control w-100"
              name="villageCovered"
              value={villageCovered}
              onChange={handleChange}
            />
            {this.validator.message(
              "village Covered",
              villageCovered,
              "numeric|max:10"
            )}
          </div>
          <div className="col-md-6 col-12 mb-3">
            <label>
              Membership<span className="text-danger font-weight-bold">*</span>
            </label>
            <input
              type="text"
              className="form-control w-100"
              name="membership"
              value={membership}
              onChange={handleChange}
              ref={this.membershipRef}
            />
            {this.validator.message(
              "membership",
              membership,
              "required|numeric|max:10"
            )}
          </div>
        </div>
        <div className=" row">
          <div className="col-md-6 col-12 mb-3">
            <label>
              Female members
              <span className="text-danger font-weight-bold">*</span>
            </label>
            <input
              type="text"
              className="form-control w-100"
              name="femaleMembers"
              value={femaleMembers}
              onChange={handleChange}
              ref={this.femaleRef}
            />
            {this.validator.message(
              "female Members",
              femaleMembers,
              "required|numeric|max:10"
            )}
          </div>
          <div className="col-md-6 col-12 mb-3">
            <label>
              Male members
              <span className="text-danger font-weight-bold">*</span>
            </label>
            <input
              type="text"
              className="form-control w-100"
              name="maleMembers"
              value={maleMembers}
              onChange={handleChange}
              ref={this.maleRef}
              readOnly={true}
            />
            {this.validator.message(
              "male Members",
              maleMembers,
              "required|numeric|max:10"
            )}
          </div>
        </div>
        <div className=" row">
          <div className="col-md-6 col-12 mb-3">
            <label>Share capital</label>
            <input
              type="text"
              className="form-control w-100"
              name="shareCapitals"
              value={shareCapitals}
              onChange={handleChange}
            />
            {this.validator.message(
              "share Capitals",
              shareCapitals,
              "numeric|max:10"
            )}
          </div>
          <div className="col-md-6 col-12 mb-3">
            <label>
              Total Acreage
              <span className="text-danger font-weight-bold">*</span>
            </label>
            <input
              type="text"
              className="form-control w-100"
              name="acre"
              value={acre}
              onChange={handleChange}
              ref={this.acreRef}
            />
            {this.validator.message("acreage", acre, "required|numeric|max:10")}
          </div>
          <div className="col-md-6 col-12 mb-3 mt-md-2">
            <label>Turn Over</label>
            <input
              type="text"
              className="form-control w-100"
              name="turnOver"
              value={turnOver}
              onChange={handleChange}
            />
            {this.validator.message("turnOver", turnOver, "numeric|max:10")}
          </div>
          <div className="col-md-6 col-12 mb-3 mt-md-2">
            <label>{"Financial Year"}</label>
            <select
              className="custom-select form-control"
              name="FyearId"
              value={FyearId}
              onChange={handleChange}
            >
              <option value="" disabled defaultValue>
                {"Select"}
              </option>
              {allFinancialYear.map((list, index) => (
                <option key={index} value={list.label}>
                  {list.label}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="d-flex justify-content-center mt-4">
          <input
            className="btn w-75 py-2 font-weight-bold"
            type="submit"
            value="Next"
            onClick={this.handleSubmit}
          />
        </div>
        <div className="d-flex justify-content-center">
          <button
            className="back-button btn"
            onClick={() => handlePageChange("step1")}
          >
            {"< Back"}
          </button>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getAllFinancialYearDropdown,
    },
    dispatch
  );
};
let component = FpoRegStepTwoClass;

export const FpoRegStepTwo = connect(null, mapDispatchToProps)(component);
