import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import { Modal, ModalBody } from "reactstrap";
import {
  NormalInput,
  NormalButton,
  NormalDate,
  NormalSelect,
} from "component/common";
import { fileUpload } from "action/MemberAct";
import SimpleReactValidator from "simple-react-validator";
import { Toast } from "service/toast";
import "./uploadPopup.scss";

export class UploadPopupClass extends Component {
  state = {
    fileName: "",
    file: "",
    isFileUploading: false,
    financialYearId: "",
    financialYearName: "",
    asOnDate: "",
    fromDate: "",
    toDate: "",
    financialYearIdforCreditData: "",
    financialYearNameforCreditData: "",
    reportQuarter: "",
    reportQuarterName: "",
    asOnDateforCreditData: "",
    reportStartYear: "",
    reportEndYear: "",
    startMonth: "",
    endMonth: "",
    startMonthName: "",
    endMonthName: "",
    reportName: "",
    isDataUploading: false,
  };

  //validation
  validator = new SimpleReactValidator({
    validators: {
      checkDateValidation: {
        message: "End date will be greater than Start date",
        rule: (val, params, validator) => {
          let startDate = this.state.fromDate;
          let endDate = this.state.toDate;
          if (
            new Date(startDate) > new Date(endDate) ||
            new Date(endDate) < new Date(startDate)
          ) {
            // set date error validation true
            return false;
          } else {
            // null or false date error validation
            return true;
          }
        },
      },
      checkYearValidation: {
        message: "End year will be greater than Start year",
        rule: (val, params, validator) => {
          let startyear = this.state.reportStartYear;
          let endYear = this.state.reportEndYear;
          if (
            new Date(startyear) > new Date(endYear) ||
            new Date(endYear) < new Date(startyear)
          ) {
            // set year error validation true
            return false;
          } else {
            // null or false year error validation
            return true;
          }
        },
      },
      checkMonthValidation: {
        message: "End month should be greater than Start month",
        rule: (val, params, validator) => {
          let startyear = this.state.reportStartYear;
          let endYear = this.state.reportEndYear;
          let startMonth = this.state.startMonth;
          let endMonth = this.state.endMonth;
          if (
            (Number(startMonth) >= Number(endMonth) &&
              moment(startyear).year() >= moment(endYear).year()) ||
            (Number(startMonth) <= Number(endMonth) &&
              moment(startyear).year() > moment(endYear).year())
          ) {
            // set year error validation true
            return false;
          } else {
            // null or false year error validation
            return true;
          }
        },
      },
    },
    element: (message) => (
      <span className="error-message text-danger fs-14">{message}</span>
    ),
    autoForceUpdate: this,
  });

  componentDidUpdate(prevProps) {
    if (!prevProps.file && this.props.file) {
      let fileValue = this.props.file;
      this.onUpload(fileValue);
    }
    const { editData } = this.props;
    let newStartDate = new Date(editData?.reportStartYear, "00", "01");
    let newEndDate = new Date(editData?.reportStartYear, "00", "01");
    let newStartMonth = "";
    let newEndMonth = "";
    if (editData?.reportStartMonth === "January") {
      newStartMonth = 1;
    } else if (editData?.reportStartMonth === "February") {
      newStartMonth = 2;
    } else if (editData?.reportStartMonth === "March") {
      newStartMonth = 3;
    } else if (editData?.reportStartMonth === "April") {
      newStartMonth = 4;
    } else if (editData?.reportStartMonth === "May") {
      newStartMonth = 5;
    } else if (editData?.reportStartMonth === "June") {
      newStartMonth = 6;
    } else if (editData?.reportStartMonth === "July") {
      newStartMonth = 7;
    } else if (editData?.reportStartMonth === "August") {
      newStartMonth = 8;
    } else if (editData?.reportStartMonth === "September") {
      newStartMonth = 9;
    } else if (editData?.reportStartMonth === "October") {
      newStartMonth = 10;
    } else if (editData?.reportStartMonth === "November") {
      newStartMonth = 11;
    } else if (editData?.reportStartMonth === "December") {
      newStartMonth = 12;
    }
    if (editData?.reportEndMonth === "January") {
      newEndMonth = 1;
    } else if (editData?.reportEndMonth === "February") {
      newEndMonth = 2;
    } else if (editData?.reportEndMonth === "March") {
      newEndMonth = 3;
    } else if (editData?.reportEndMonth === "April") {
      newEndMonth = 4;
    } else if (editData?.reportEndMonth === "May") {
      newEndMonth = 5;
    } else if (editData?.reportEndMonth === "June") {
      newEndMonth = 6;
    } else if (editData?.reportEndMonth === "July") {
      newEndMonth = 7;
    } else if (editData?.reportEndMonth === "August") {
      newEndMonth = 8;
    } else if (editData?.reportEndMonth === "September") {
      newEndMonth = 9;
    } else if (editData?.reportEndMonth === "October") {
      newEndMonth = 10;
    } else if (editData?.reportEndMonth === "November") {
      newEndMonth = 11;
    } else if (editData?.reportEndMonth === "December") {
      newEndMonth = 12;
    }
    if (this.props.isEdit) {
      if (this.props.editData !== prevProps.editData) {
        this.setState({
          financialYearId: editData?.fymId,
          financialYearName: "",
          asOnDate: moment(editData?.financialAsOnDate).toDate(),
          fromDate: moment(editData?.reportFromDate).toDate(),
          toDate: moment(editData?.reportToDate).toDate(),
          financialYearIdforCreditData: editData?.fymId,
          financialYearNameforCreditData: "",
          reportQuarter: editData?.creditDocId,
          reportQuarterName: "",
          asOnDateforCreditData: moment(editData?.reportAsOnDate).toDate(),
          reportStartYear: moment(newStartDate).toDate(),
          reportEndYear: moment(newEndDate).toDate(),
          startMonth: newStartMonth,
          endMonth: newEndMonth,
          startMonthName: "",
          endMonthName: "",
          reportName: editData?.reportName,
        });
      }
    }
  }
  //Handle inputs
  handleInput = ({ target: { name, value } }) => {
    let { financialYearData, quartermasterData, monthData } = this.props;
    this.setState(
      {
        [name]: value,
      },
      () => {
        if (name === "financialYearId") {
          let filteredData = financialYearData.filter((x) => x.value == value);
          this.setState({ financialYearName: filteredData[0].label });
        } else if (name === "financialYearIdforCreditData") {
          let filteredData = financialYearData.filter((x) => x.value == value);
          this.setState({
            financialYearNameforCreditData: filteredData[0].label,
          });
        } else if (name === "reportQuarter") {
          let filteredDataForQuarter = quartermasterData.filter(
            (x) => x.value == value
          );
          this.setState({
            reportQuarterName: filteredDataForQuarter[0].label,
          });
        } else if (name === "startMonth") {
          let filteredDataForstartMonth = monthData.filter(
            (x) => x.value == value
          );
          this.setState({
            startMonthName: filteredDataForstartMonth[0].label,
          });
        } else if (name === "endMonth") {
          let filteredDataForendMonth = monthData.filter(
            (x) => x.value == value
          );
          this.setState({
            endMonthName: filteredDataForendMonth[0].label,
          });
        }
      }
    );
  };

  //file upload
  fileHandle = (e) => {
    let fileValue = e.target.files[0];
    this.onUpload(fileValue);
  };

  onUpload = (fileValue) => {
    if (fileValue !== undefined) {
      this.setState({ file: "" }, () => {
        this.setState({
          isFileUploading: true,
          fileName: "Uploading ....",
          file: fileValue,
        });
        const formData = new FormData();
        formData.append("file", fileValue);
        this.props
          .fileUpload(formData)
          .then((data) => {
            document.getElementById("fileUpload").value = null;
            this.setState({
              fileName: data[0].fileName,
              file: data[0].fileUrl,
              isFileUploading: false,
            });
          })
          .catch(() => {
            this.setState({
              fileName: "",
              file: "",
              isFileUploading: false,
            });
          });
      });
    }
  };

  //handle back
  handleBack = () => {
    let { isFileUploading } = this.state;
    if (!isFileUploading) {
      this._clearData();
      this.props.toggle();
    } else {
      Toast({ type: "error", message: "Please wait file is uploading" });
    }
  };

  //Clear data
  _clearData = () => {
    this.setState(
      {
        fileName: "",
        file: "",
        isFileUploading: false,
        financialYearId: "",
        financialYearName: "",
        asOnDate: "",
        fromDate: "",
        toDate: "",
        financialYearIdforCreditData: "",
        financialYearNameforCreditData: "",
        reportQuarter: "",
        reportQuarterName: "",
        asOnDateforCreditData: "",
        reportStartYear: "",
        reportEndYear: "",
        startMonth: "",
        endMonth: "",
        startMonthName: "",
        endMonthName: "",
        reportName: "",
        isDataUploading: false,
      },
      () => {
        this.validator.hideMessages();
      }
    );
  };

  handleRemoveImage = () => {
    this.setState({
      fileName: "",
      file: "",
    });
  };

  //handle submit
  handleSubmit = () => {
    let { id, documentDetail = {} } = this.props;

    let { documentType = "" } = documentDetail;

    if (this.validator.allValid()) {
      let {
        file,
        fileName,
        isFileUploading,
        financialYearId,
        financialYearName,
        asOnDate,
        fromDate,
        toDate,
        financialYearNameforCreditData,
        financialYearIdforCreditData,
        reportQuarterName,
        asOnDateforCreditData,
        reportStartYear,
        reportEndYear,
        startMonthName,
        endMonthName,
        reportName,
      } = this.state;
      if (!isFileUploading) {
        this.setState({ isDataUploading: true });
        let body = {
          id,
          fileName,
          file,
          financialYearId,
          asOnDate,
          documentType,
          financialYearName,
          fromDate,
          toDate,
          financialYearNameforCreditData,
          financialYearIdforCreditData,
          reportQuarterName,
          reportStartYear,
          reportEndYear,
          asOnDateforCreditData,
          startMonthName,
          endMonthName,
          reportName,
        };
        this.props.handleUpload(body);
      } else {
        Toast({ type: "error", message: "Please wait file is uploading" });
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  handleEditOption = () => {
    let { documentDetail = {}, editData } = this.props;
    let { documentType = "" } = documentDetail;
    if (this.validator.allValid()) {
      let {
        file,
        fileName,
        financialYearId,
        financialYearName,
        asOnDate,
        fromDate,
        toDate,
        financialYearNameforCreditData,
        financialYearIdforCreditData,
        reportQuarterName,
        asOnDateforCreditData,
        reportStartYear,
        reportEndYear,
        startMonthName,
        endMonthName,
        reportName,
      } = this.state;
      let body = {
        id: editData?.id,
        fileName,
        file,
        financialYearId,
        asOnDate,
        documentType,
        financialYearName,
        fromDate,
        toDate,
        financialYearNameforCreditData,
        financialYearIdforCreditData,
        reportQuarterName,
        reportStartYear,
        reportEndYear,
        asOnDateforCreditData,
        startMonthName,
        endMonthName,
        reportName,
      };
      this.props.handleEditOption(body);
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  render() {
    let {
      className = "modal-dialog-centered upload-popup-box",
      isOpen = true,
      title = "",
      documentTitle = "",
      documentDetail = {},
      financialYearData = [],
      quartermasterData = [],
      monthData = [],
      isXlsxFileAllowed = false,
      isEdit,
      editData,
    } = this.props;

    let { documentType = "" } = documentDetail;

    let {
      fileName,
      file,
      asOnDate,
      financialYearId,
      fromDate,
      toDate,
      financialYearIdforCreditData,
      reportQuarter,
      asOnDateforCreditData,
      reportStartYear,
      reportEndYear,
      startMonth,
      endMonth,
      reportName,
      isDataUploading,
      isFileUploading,
    } = this.state;

    this.validator.purgeFields();

    return (
      <Modal isOpen={isOpen} className={className} size="lg">
        <ModalBody className="p-md-4 p-2">
          <div className="d-flex justify-content-end">
            <i
              className="icon-close fs-16 cursor-pointer pb-md-0 pb-2"
              onClick={() => this.handleBack()}
            ></i>
          </div>
          <div className="upload-popup">
            <p className="upload-popup-heading">
              {title + " - " + documentTitle}
            </p>
            <div className="mb-4">
              <NormalInput
                isFullWidth={true}
                label="Report name"
                type="text"
                value={reportName}
                name="reportName"
                placeholder="Enter here"
                onChange={this.handleInput}
                required={true}
              />
              <div className="row">
                <div className="col-md-10 ml-auto">
                  {this.validator.message(
                    "report name",
                    reportName,
                    "required|max:25"
                  )}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                {documentType === "provisional_financials" ? (
                  <>
                    <NormalDate
                      isHalfWidth={true}
                      isIcon={true}
                      value={asOnDate}
                      name="asOnDate"
                      placeholder="DD/MM/YYYY"
                      onChange={this.handleInput}
                      mindate={false}
                      maxdate={false}
                      timeInput={false}
                      label={"As on date"}
                      required={true}
                    />
                    <div className="row">
                      <div className="col-md-8 ml-auto">
                        {this.validator.message("date", asOnDate, "required")}
                      </div>
                    </div>
                  </>
                ) : null}
                {documentType === "audited_financials" ? (
                  <>
                    <NormalSelect
                      isHalfWidth={true}
                      label={"Enter year"}
                      placeholder="Select"
                      value={financialYearId}
                      arrow={true}
                      name="financialYearId"
                      options={financialYearData}
                      handleChange={this.handleInput}
                      required={true}
                    />
                    <div className="row">
                      <div className="col-md-8 ml-auto">
                        {this.validator.message(
                          "financial year",
                          financialYearId,
                          "required"
                        )}
                      </div>
                    </div>
                  </>
                ) : null}
              </div>
            </div>

            {/* Bank statement */}
            {documentType === "bank_statement" ? (
              <div className="row">
                <div className="col-md-6">
                  <NormalDate
                    isHalfWidth={true}
                    isIcon={true}
                    value={fromDate}
                    name="fromDate"
                    placeholder="DD/MM/YYYY"
                    onChange={this.handleInput}
                    mindate={false}
                    maxdate={false}
                    timeInput={false}
                    label={"Select from date"}
                    required={true}
                  />
                  <div className="row">
                    <div className="col-md-8 ml-auto">
                      {this.validator.message(
                        "from date",
                        fromDate,
                        "required"
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <NormalDate
                    isHalfWidth={true}
                    isIcon={true}
                    value={toDate}
                    name="toDate"
                    disabled={fromDate === "" ? true : false}
                    placeholder="DD/MM/YYYY"
                    onChange={this.handleInput}
                    mindate={false}
                    maxdate={false}
                    timeInput={false}
                    label={"Select to date"}
                    required={true}
                  />
                  <div className="row">
                    <div className="col-md-8 ml-auto">
                      {this.validator.message(
                        "end date",
                        toDate,
                        "required|checkDateValidation"
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ) : null}

            {/* Gst returns */}
            {documentType === "gst_returns" ? (
              <div className="row">
                <div className="col-md-6">
                  <NormalSelect
                    isHalfWidth={true}
                    label={"Enter year"}
                    placeholder="Select"
                    value={financialYearIdforCreditData}
                    arrow={true}
                    name="financialYearIdforCreditData"
                    options={financialYearData}
                    handleChange={this.handleInput}
                    required={true}
                  />
                  <div className="row">
                    <div className="col-md-8 ml-auto">
                      {this.validator.message(
                        "financial year",
                        financialYearIdforCreditData,
                        "required"
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <NormalSelect
                    isHalfWidth={true}
                    label={"Select quarter"}
                    placeholder="Select"
                    value={reportQuarter}
                    arrow={true}
                    name="reportQuarter"
                    options={quartermasterData}
                    handleChange={this.handleInput}
                    required={true}
                  />
                  <div className="row">
                    <div className="col-md-8 ml-auto">
                      {this.validator.message(
                        "Quarter",
                        reportQuarter,
                        "required"
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
            {/* it returns */}
            {documentType === "it_returns" ? (
              <div className="row">
                <div className="col-md-6">
                  <NormalSelect
                    isHalfWidth={true}
                    label={"Enter year"}
                    placeholder="Select"
                    value={financialYearIdforCreditData}
                    arrow={true}
                    name="financialYearIdforCreditData"
                    options={financialYearData}
                    handleChange={this.handleInput}
                    required={true}
                  />
                  <div className="row">
                    <div className="col-md-8 ml-auto">
                      {this.validator.message(
                        "financial year",
                        financialYearIdforCreditData,
                        "required"
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
            {/* debtors aing, top, customers, top suppliers, borrowing profile ,creditor ageing*/}
            {documentType === "debtors_ageing" ||
            documentType === "top_customers" ||
            documentType === "top_suppliers" ||
            documentType === "borrowing_profile" ||
            documentType === "creditor_ageing" ||
            documentType === "COMPANYENTITY" ||
            documentType === "INDIVIDUALBUREAU" ? (
              <div className="row">
                <div className="col-md-6">
                  <NormalDate
                    isHalfWidth={true}
                    isIcon={true}
                    value={asOnDateforCreditData}
                    name="asOnDateforCreditData"
                    placeholder="DD/MM/YYYY"
                    onChange={this.handleInput}
                    mindate={false}
                    maxdate={false}
                    timeInput={false}
                    label={"As on date"}
                    required={true}
                  />
                  <div className="row">
                    <div className="col-md-8 ml-auto">
                      {this.validator.message(
                        "date",
                        asOnDateforCreditData,
                        "required"
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
            {/* sales report, purchase report */}
            {documentType === "sales_report" ||
            documentType === "purchase_report" ? (
              <div className="row">
                <div className="col-md-6">
                  <NormalDate
                    isHalfWidth={true}
                    isIcon={true}
                    value={reportStartYear}
                    name="reportStartYear"
                    placeholder="YYYY"
                    onChange={this.handleInput}
                    mindate={false}
                    maxdate={false}
                    timeInput={false}
                    label={"Start year"}
                    dateFormat="yyyy"
                    showYearPicker={true}
                    required={true}
                  />
                  <div className="row">
                    <div className="col-md-8 ml-auto">
                      {this.validator.message(
                        "start year",
                        reportStartYear,
                        "required"
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <NormalDate
                    isHalfWidth={true}
                    isIcon={true}
                    value={reportEndYear}
                    name="reportEndYear"
                    placeholder="YYYY"
                    onChange={this.handleInput}
                    mindate={false}
                    maxdate={false}
                    timeInput={false}
                    label={"End year"}
                    dateFormat="yyyy"
                    showYearPicker={true}
                    required={true}
                  />
                  <div className="row">
                    <div className="col-md-8 ml-auto">
                      {this.validator.message(
                        "end year",
                        reportEndYear,
                        "required|checkYearValidation"
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <NormalSelect
                    isHalfWidth={true}
                    label={"Start Month"}
                    placeholder="Select"
                    value={startMonth}
                    arrow={true}
                    name="startMonth"
                    options={monthData}
                    handleChange={this.handleInput}
                    required={true}
                  />
                  <div className="row">
                    <div className="col-8 ml-auto">
                      {this.validator.message(
                        "start month",
                        startMonth,
                        "required"
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <NormalSelect
                    isHalfWidth={true}
                    label={"End month"}
                    placeholder="Select"
                    value={endMonth}
                    arrow={true}
                    name="endMonth"
                    options={monthData}
                    handleChange={this.handleInput}
                    required={true}
                  />
                  <div className="row">
                    <div className="col-8 ml-auto">
                      {this.validator.message(
                        "end month",
                        endMonth,
                        "required|checkMonthValidation"
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
            {!isEdit && (
              <div className="normal-upload">
                <div className="row">
                  <div className="col-md-2 pr-0 d-flex align-items-center mb-md-0 mb-2">
                    <div className="fs-16 text-black-32 font-weight-bold line-height-19">
                      {"Upload"}
                      <span className="text-danger font-weight-bold pl-2 fs-18">
                        *
                      </span>
                    </div>
                  </div>
                  <div className="col-md-10">
                    <div className="d-flex flex-column justify-content-start">
                      <div className="btn-file-input">
                        <button className="">
                          <input
                            type="file"
                            className=""
                            accept={
                              isXlsxFileAllowed
                                ? ".jpg,.pdf,.jpeg,.xlsx"
                                : ".jpg, .pdf, .jpeg"
                            }
                            onChange={this.fileHandle}
                            id="fileUpload"
                            title=""
                          />
                          {fileName == "" ? (
                            "Upload file"
                          ) : (
                            <>
                              {fileName.length == 10
                                ? fileName
                                : fileName.substring(0, 9)}
                              {"..."}
                            </>
                          )}
                        </button>
                      </div>
                      {fileName != "" && !isFileUploading && (
                        <div className="d-flex mb-3 align-items-center justify-content-between delete-area-width">
                          <div className="text-danger">{"Delete"}</div>
                          <i
                            className="icon-Delete text-danger fs-14 pl-2 cursor-pointer"
                            onClick={() => this.handleRemoveImage()}
                          ></i>
                        </div>
                      )}
                    </div>
                    {this.validator.message("document file", file, "required")}
                  </div>
                </div>
              </div>
            )}
          </div>
          {!isEdit && (
            <div className="document-popup-required">
              <ul>
                <li className="document-footer-body mt-3">
                  Accepted formats : Jpg, Jpeg, Pdf, Xlsx
                </li>
              </ul>
            </div>
          )}
          <div className="d-flex w-100">
            {isDataUploading ? (
              <NormalButton
                label="Submit"
                mainbg={true}
                className="py-2 mx-auto fs-14 line-height-26 submit-button"
              />
            ) : (
              <NormalButton
                label="Submit"
                mainbg={true}
                className="py-2 mx-auto fs-14 line-height-26 submit-button"
                onClick={() => {
                  this.props.isEdit
                    ? this.handleEditOption()
                    : this.handleSubmit();
                }}
              />
            )}
          </div>
        </ModalBody>
      </Modal>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      fileUpload,
    },
    dispatch
  );
};
let component = UploadPopupClass;

export const UploadPopup = connect(null, mapDispatchToProps)(component);
