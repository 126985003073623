import React, { Component, Fragment } from "react";
import { NormalInput, NormalSelect, ConfirmationPopup } from "component/common";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  membersView,
  memberHouseHoldAdd,
  updateMemberHouseHold,
  getMember,
  getMemberHouseHold,
  deleteHouseholdDetails,
} from "action/MemberAct";
import SimpleReactValidator from "simple-react-validator";

const arrow_up = require("../../../assets/svg/add_member/arrow_up.svg");
class HouseHoldForm extends Component {
  constructor(props) {
    super();
    this.state = {
      members: [],
      memberId: "",
      male_members: "",
      female_members: "",
      houseHoldData: null,
      isDataUploading: false,
      isDelete: false,
    };
  }

  validator = new SimpleReactValidator({
    element: (message) => (
      <span className="error-message text-danger fs-14">{message}</span>
    ),
    autoForceUpdate: this,
  });

  handleChange = ({ target: { name, value } }) => {
    this.setState(
      {
        [name]: value,
      },
      () => {
        if (name == "memberId") {
          this.props.getMemberHouseHold({ id: value }).then((data) => {
            if (data == null) {
              this.setState({
                male_members: "",
                female_members: "",
                houseHoldData: null,
              });
            } else {
              this.setState({
                houseHoldData: data,
                memberId: data.memberID,
                male_members: data.maleMembers,
                female_members: data.femaleMembers,
              });
            }
          });
        }
      }
    );
  };

  //delete houseHold
  handleDelete = () => {
    this.setState({ isDataUploading: true });
    let { memberId } = this.state;
    let { deleteHouseholdDetails } = this.props;
    let body = {
      id: memberId,
    };
    deleteHouseholdDetails(body).then(() => {
      this._clearData();
    });
  };

  toggleDelete = () => {
    let { isDelete } = this.state;
    this.setState({
      isDelete: !isDelete,
    });
  };

  _clearData = () => {
    this.setState({
      male_members: "",
      female_members: "",
      houseHoldData: null,
      isDataUploading: false,
    });
  };

  handleSave = (e) => {
    if (this.validator.allValid()) {
      const { memberId, male_members, female_members, houseHoldData } =
        this.state;

      this.setState({ isDataUploading: true });

      let body = {
        memberID: parseInt(memberId),
      };
      if (female_members !== "") {
        body.femaleMembers = parseInt(female_members);
      }
      if (male_members !== "") {
        body.maleMembers = parseInt(male_members);
      }

      if (houseHoldData == null) {
        this.props.memberHouseHoldAdd(body).then((data) => {
          if (data === 200) {
            this.validator.hideMessages();
            this.props.toggleCollapse(false, false, true, false, false, false);
          }
          this.setState({ isDataUploading: false });
        });
      } else {
        this.props.updateMemberHouseHold(body).then((data) => {
          this.setState({ isDataUploading: false });
          if (data === 200) {
            this.validator.hideMessages();
            this.props.toggleCollapse(false, false, true, false, false, false);
          }
        });
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      if (this.props.isOpen) {
        this.props.membersView().then((data) => {
          let memberDetials = [];
          data.map((list) => {
            memberDetials.push({
              label: list.memberFirstName + " | " + list.phone,
              value: list.memberID,
            });
          });
          this.setState({
            members: memberDetials,
          });
        });
        let { id } = this.props;
        this.props.getMember({ id }).then((data) => {
          let household = data.household;
          if (household.length !== 0) {
            this.setState({
              houseHoldData: household,
              memberId: household[0].memberID,
              male_members: household[0].maleMembers
                ? household[0].maleMembers
                : "",
              female_members: household[0].femaleMembers
                ? household[0].femaleMembers
                : "",
            });
          } else {
            this.setState({ houseHoldData: null, memberId: id });
          }
        });
      }
    }
  }

  render() {
    const {
      memberId,
      members,
      male_members,
      female_members,
      isDataUploading,
      houseHoldData,
      isDelete,
    } = this.state;

    let { deleteAccess = false } = this.props;

    return (
      <Fragment>
        <div className="px-md-5 py-4 px-3 add-member-area">
          <div className="d-flex flex-md-row flex-column justify-content-between align-items-md-center align-items-start mb-3">
            <div className="add-member-form-container-heading w-md-50 w-100">
              House hold
            </div>
            <div className="d-flex justify-content-md-end justify-content-between align-items-center mt-md-0 mt-2 w-md-50 w-100">
              {isDataUploading ? (
                <div className="d-flex align-items-center h-100">
                  {houseHoldData != null && deleteAccess && (
                    <div className="mr-3 d-flex align-items-center h-100">
                      <p className="mb-1 font-weight-bold fs-20 d-flex align-items-center mt-2">
                        <i className="icon-Delete fs-18" />
                        <span className="text-danger fs-18 pl-3">
                          {"Delete"}
                        </span>
                      </p>
                    </div>
                  )}
                  <div className="add-member-form-container-button d-flex align-items-center">
                    {"Save & next"}
                  </div>
                </div>
              ) : (
                <div className="d-flex align-items-center h-100">
                  {houseHoldData != null && deleteAccess && (
                    <div className="mr-3 d-flex align-items-center h-100">
                      <p
                        className="mb-1 font-weight-bold fs-20 cursor-pointer d-flex align-items-center mt-2"
                        onClick={() => this.toggleDelete()}
                      >
                        <i className="icon-Delete fs-18" />
                        <span className="text-danger fs-18 pl-3">
                          {"Delete"}
                        </span>
                      </p>
                    </div>
                  )}
                  <div
                    className="add-member-form-container-button cursor-pointer d-flex align-items-center"
                    onClick={this.handleSave}
                  >
                    {"Save & next"}
                  </div>
                </div>
              )}
              <img
                className="ml-3 cursor-pointer"
                src={arrow_up}
                alt="arrow_up"
                name="house_hold"
                height="10"
                width="10"
                onClick={this.props.collapse}
              />
            </div>
          </div>
          {/* <div className="row w-100">
          <div className="col-md-12">
            <NormalSelect
              isFullWithForMemberProfile={true}
              label="Member"
              placeholder="Select"
              value={memberId}
              arrow={true}
              name="memberId"
              options={members}
              handleChange={this.handleChange}
              required={true}
              disabled={true}
            />
            <div className="row">
              <div className="col-10 ml-auto">
                {this.validator.message("member", memberId, "required")}
              </div>
            </div>
          </div>
        </div> */}
          <div className="row w-100">
            <div className="col-md-6">
              <NormalInput
                isHalfWidth={true}
                label="Male members"
                type="number"
                onChange={this.handleChange}
                value={male_members}
                name="male_members"
                disabled={memberId == "" ? true : false}
              />
              <div className="row">
                <div className="col-md-8 ml-auto">
                  {this.validator.message(
                    "male members",
                    male_members,
                    "max:10"
                  )}
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <NormalInput
                isHalfWidth={true}
                label="Female members"
                type="number"
                onChange={this.handleChange}
                value={female_members}
                name="female_members"
                disabled={memberId == "" ? true : false}
              />
              <div className="row">
                <div className="col-md-8 ml-auto">
                  {this.validator.message(
                    "female members",
                    female_members,
                    "max:10"
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <ConfirmationPopup
          isOpen={isDelete}
          toggle={this.toggleDelete}
          onSubmit={this.handleDelete}
        />
      </Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      membersView,
      memberHouseHoldAdd,
      getMember,
      updateMemberHouseHold,
      getMemberHouseHold,
      deleteHouseholdDetails,
    },
    dispatch
  );
};
let component = HouseHoldForm;

export const HouseHold = connect(null, mapDispatchToProps)(component);
