import React, { Component } from "react";
import { Navbar } from "component/common";
import { Profile } from "component/fpo/home/index";
export class IndividualProfile extends Component {
  //lifecycles
  componentDidMount = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  render() {
    return (
      <>
        <div className="header">{/* <Navbar /> */}</div>
        <Profile id={this.props.match.params.id} />
      </>
    );
  }
}
