import { authApi } from "../service/apiVariables";
import { HomeActionType } from "service/actionType";
import { history } from "service/helpers";
import { storeLoginData } from "service/helperFunctions";

// login
export const login =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...authApi.userLogin, body })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

//Verify Password
export const verifyPassword =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...authApi.verifyPassword, body })
        .then(({ message, data }) => {
          storeLoginData(data);
          resolve(true);
          Toast({ type: "success", message, time: 5000 });
        })
        .catch(({ message }) => {
          history.push("/login");
          reject(Toast({ type: "error", message }));
        });
    });
  };

// OTP login
export const otpLogin =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...authApi.otpLogin, body })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

//verify Otp
export const verifyOtp =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...authApi.verifyOtp, body })
        .then(({ message, data }) => {
          storeLoginData(data);
          resolve(true);
          Toast({ type: "success", message, time: 5000 });
        })
        .catch(({ message }) => {
          history.push("/login");
          reject(Toast({ type: "error", message }));
        });
    });
  };

//forgot password link
export const forgotPasswordLink =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...authApi.forgotPasswordLink, body })
        .then(({ data, message }) => {
          Toast({ type: "success", message, time: 5000 });
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

//forgot password validation
export const forgotPasswordTokeValidation =
  () =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...authApi.forgotPasswordTokeValidation, isfortgotPass: true })
        .then(({ status }) => {
          resolve(status);
        })
        .catch(({ message, status }) => {
          resolve(status);
          Toast({ type: "error", message });
        });
    });
  };

//change Password
export const changePassword =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...authApi.changePassword, body, isfortgotPass: true })
        .then(({ data, message }) => {
          Toast({ type: "success", message, time: 5000 });
          resolve(data);
        })
        .catch(({ message, status }) => {
          resolve(status);
          Toast({ type: "error", message });
        });
    });
  };

//get Access Details
export const getAccessDetails =
  () =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...authApi.getAccessDetails })
        .then(({ data }) => {
          dispatch({ type: HomeActionType.accessData, payload: data.service });
          resolve(data);
        })
        .catch(({ message, status }) => {
          resolve(status);
          Toast({ type: "error", message });
        });
    });
  };

//change Password After Login
export const changePasswordAfterLogin =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...authApi.changePasswordAfterLogin, body })
        .then(({ data, message }) => {
          Toast({
            type: "success",
            message: "Password changed successfully",
            time: 5000,
          });
          resolve(data);
        })
        .catch(({ message, status }) => {
          reject(status);
          Toast({ type: "error", message });
        });
    });
  };

//remove Profile Picture
export const removeProfilePicture =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...authApi.removeProfilePicture, body })
        .then(({ data, message }) => {
          Toast({ type: "success", message, time: 5000 });
          resolve(data);
        })
        .catch(({ message, status }) => {
          reject(status);
          Toast({ type: "error", message });
        });
    });
  };

//logout Api
export const logoutApi =
  () =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...authApi.logoutApi })
        .then(({ data, message }) => {
          Toast({ type: "success", message, time: 5000 });
          resolve(data);
        })
        .catch(({ message, status }) => {
          reject(status);
          Toast({ type: "error", message });
        });
    });
  };

// Verify FPO Token
export const verifyFPO =
  () =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...authApi.verifyFpo, isFpo: true })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ message, status }) => {
          reject(status);
          Toast({ type: "error", message, time: 5000 });
        });
    });
  };

// Google Login
export const googleLogin =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...authApi.googleLogin, body })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ message, status }) => {
          reject(status);
          Toast({ type: "error", message, time: 5000 });
        });
    });
  };

//Update profile Details
export const updateProfile =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...authApi.changeBasicDetails, body })
        .then(({ data, message }) => {
          Toast({ type: "success", message, time: 5000 });
          resolve(data);
        })
        .catch(({ message, status }) => {
          reject(status);
          Toast({ type: "error", message });
        });
    });
  };

//get Access Token For Power Bi
export const getAccessTokenForPowerBi =
  () =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...authApi.getAccessTokenForPowerBi })
        .then((data) => {
          resolve(data);
        })
        .catch(({ message, status }) => {
          reject(status);
          Toast({ type: "error", message });
        });
    });
  };

export const FIGCRPOTPLogin =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...authApi.FIGCRPOTPLogin, body })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

export const FIGCRPOTPLoginVerification =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...authApi.FIGCRPOTPLoginVerification, body })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

export const FIGCRPResendOTP =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...authApi.FIGCRPResendOTP, body })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

export const SSOLogin =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...authApi.SSOLogin, body })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };
