import React, { useEffect, useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import { MemberList } from "./MemberList";
import VoiceRecorder from "./VoiceRecorder";
import { getCallRecords } from "action/RoboCall";
import moment from "moment-timezone";
import { useDispatch } from "react-redux";
import { getFilePathDownload } from "service/api";

import "./roboCalling.scss";

export const RoboCall = ({ isOpen, toggle, activeTab, smsAccess }) => {
  let [activeIndex, setActiveIndex] = useState(0);

  const dispatch = useDispatch();

  const [callHistory, setCallHistory] = useState([]);

  useEffect(() => {
    getCallHistory();
  }, [activeTab]);

  const downloadCsv = async (fileUrl) => {
    window.open(getFilePathDownload + fileUrl);
  };

  const userType = () => {
    switch (activeTab) {
      case "1":
        return "member";
      case "2":
        return "bod";
      case "3":
        return "staff";
      default:
        return "member";
    }
  };

  const getCallHistory = async () => {
    dispatch(getCallRecords({ userType: userType() })).then((data) => {
      setCallHistory(data);
    });
  };

  const SuccessMsg = () => {
    return (
      <div className="robocall-success">
        <span>
          Your audio has been successfully submitted. The Robocall will be
          launched after admin approval.
        </span>
      </div>
    );
  };

  const CallHistory = () => {
    return (
      <div className="robocall-history">
        <div className="call-history-title">
          <i
            className="fas fa-arrow-left"
            onClick={() => setActiveIndex(0)}
          ></i>
          <b>Call History</b>
        </div>
        <table>
          <thead>
            <tr>
              <th>S.No</th>
              <th>Alert Name</th>
              <th>Call Date</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {callHistory.length > 0 &&
              callHistory.map((list, index) => {
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{list.alertName}</td>
                    <td>
                      {moment(list.createdAt)
                        .tz("Asia/Kolkata")
                        .format("DD-MM-YYYY, hh:mm a")}
                    </td>
                    <td>
                      <button onClick={() => downloadCsv(list.fileUrl)}>
                        Download CSV
                      </button>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    );
  };

  const closePopUp = () => {
    toggle();
    setActiveIndex(0);
  };

  const tab = [
    <VoiceRecorder
      onNext={() => setActiveIndex(1)}
      showCallHistory={() => setActiveIndex(3)}
      showBtn={callHistory.length > 0 ? true : false}
    />,
    <MemberList
      activeTab={activeTab}
      smsAccess={smsAccess}
      onNext={() => setActiveIndex(2)}
      goBack={() => setActiveIndex(0)}
    />,
    <SuccessMsg />,
    <CallHistory />,
  ];

  return (
    <Modal isOpen={isOpen} toggle={closePopUp}>
      <ModalBody>
        <div className="robocall-main">
          <i className="fas fa-times" onClick={() => closePopUp()}></i>
          {tab[activeIndex]}
        </div>
      </ModalBody>
    </Modal>
  );
};
