import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Modal, ModalBody } from "reactstrap";
import {
  NormalInput,
  NormalButton,
  NormalDate,
  NormalSelect,
} from "component/common";
import SimpleReactValidator from "simple-react-validator";
import {
  getfpoStateList,
  getfpoDistrictList,
  addRecommentForm,
  sellRequestDetail,
  getMarketCentreListRM
} from "action/SellrequestAct";
import { CommonPageLoader } from "component/common";

export class RecommendPopupClass extends Component {
  state = {
    expectedQuantityperdelivery: "",
    priceOffered: "",
    durationofContract: "",
    totalQuantity: "",
    expectedPaymentAfter: "",
    frequencyofdelivery: "",
    durationunit: "",
    paymentunit: "Days",
    state: "",
    district: "",
    districtOption: [],
    frequencyofdeliveryOption: [],
    weeksMonthOption: [],
    stateOption: [],
    isLoading: false,
    detail: {},
    frequencyofdeliveryOther: '',
    latestPrice: {}
  };

  //validation
  validator = new SimpleReactValidator({
    element: (message) => (
      <span className="error-message text-danger fs-14">{message}</span>
    ),
    autoForceUpdate: this
  });

  componentDidMount = () => {
    this.detailApi();
    this.setState({ isLoading: true });
    this.props.getfpoStateList().then((data) => {
      
      let stateData = [];

      data.map((list) => {
        stateData.push({ value: list.stateId, label: list.stateName });
      });

      this.setState({
        stateOption: stateData,
        isLoading: false
      });
    });
    this.setState({
      weeksMonthOption: [
        { value: "Week", label: "Week" },
        { value: "Month", label: "Month" },
      ],
      frequencyofdeliveryOption: [
        { value: "One time", label: "One time" },
        { value: "Weekly", label: "Weekly" },
        { value: "Monthly", label: "Monthly" },
        { value: "Other", label: "Other" },
      ],
    })
  }

  detailApi() {
    this.setState({ isLoading: true });
    this.props.sellRequestDetail(this.props.documentTitle).then((data) => {
      
      this.setState({
        detail: data[0],
        isLoading: false,
      });
    });
  }
  handleSubmit = () => {
    if (this.validator.allValid()) {
      this.RecommendSubmit();
    } else {
      
      this.validator.showMessages();
      this.forceUpdate();
    }
  }

  RecommendSubmit = () => {
    let postdata = {
      "fpo_sell_request_id": this.props.documentTitle,
      "expected_quantity_per_delivery": this.state.expectedQuantityperdelivery,
      "frequency_of_delivery": this.state.frequencyofdelivery == 'Other' ? this.state.frequencyofdeliveryOther : this.state.frequencyofdelivery,
      "price_offered": this.state.priceOffered,
      "duration_of_contract_type": this.state.durationunit,
      "duration_of_contract": this.state.durationofContract == '' ? 0 : this.state.durationofContract,
      "total_quantity": this.state.totalQuantity,
      "expected_payment_after": this.state.expectedPaymentAfter || 0,
      "expected_payment_after_type": this.state.paymentunit,
      "state": this.state.state || 0,
      "district": this.state.district || 0
    }

    if (this.state.latestPrice?.Modal_Price) {
      postdata['destination_market_price'] = this.state.latestPrice?.Modal_Price;
    }
    
    if (this.state.latestPrice?.Date) {
        postdata['destination_market_price_date'] = this.state.latestPrice?.Date;
    }

    this.props.addRecommentForm(postdata).then((data) => {
      
      this.handleBack();
    });
  }

  componentDidUpdate(prevProps) {
  }
  //handle back
  handleBack = () => {
    this.props.toggle();
  };

  handleInput = ({ target: { name, value } }) => {
    this.setState({
      [name]: value,
    });
    if (name === 'state') {
      this.stateChange(value)
    }
    if (name === 'district') {
      this.districtChange(value)
    }

    if (name === 'frequencyofdelivery' && value !== 'Other') {
      this.setState({
        frequencyofdeliveryOther: ''
      })
    }
  }

  stateChange = (value) => {
    this.setState({ district: '', isLoading: true })
    this.props.getfpoDistrictList(value, {
      commodityName: this.state.detail?.commodity_name
    }).then((data) => {
      
      let districtData = [];

      data.map((list) => {
        districtData.push({ value: list.districtId, label: list.districtName });
      });
      this.setState({
        districtOption: districtData,
        isLoading: false
      });
      
    });
  }

  districtChange = (value) => {
    var disctrictname = this.state.districtOption.filter(list => list.value === Number(value));
    this.props.getMarketCentreListRM({
      "commodityName": this.state.detail?.commodity_name,
      "districtName": disctrictname[0].label,
      // "Market_Centre": data[0]?.fpo_destination_market
  }).then((data) => {
      
      this.setState({
        latestPrice: data[0]
      });
  });
  }

  render() {
    let {
      className = "modal-dialog-centered upload-popup-box",
      isOpen = true,
      title = "",
      documentTitle = "",
    } = this.props;
    const {
      expectedQuantityperdelivery,
      priceOffered,
      durationofContract,
      totalQuantity,
      expectedPaymentAfter,
      frequencyofdelivery,
      frequencyofdeliveryOther,
      durationunit,
      paymentunit,
      state,
      district,
      districtOption,
      frequencyofdeliveryOption,
      weeksMonthOption,
      stateOption,
      isLoading,
      detail
    } = this.state;
    this.validator.purgeFields();
    return (
      <Modal isOpen={isOpen} className={className} size="lg">
        {isLoading === true ?
          <CommonPageLoader /> : ''
        }
        <ModalBody className="p-4 recommendbuyer">
          <div className="flex justify-between">
            <p className="recommend-popup-heading">
              {title + " - " + documentTitle}
            </p>
            <i className="icon-close fs-16 cursor-pointer"
              onClick={() => this.handleBack()} ></i>
          </div>
          <hr />
          <div className="relative d-flex items-center mb-20 sup-width">
            <label>Expected Quantity per delivery: <span className="text-danger font-weight-bold pl-0 fs-18">*</span></label>
            <div className="d-flex w-50">
              <div className="w-100">
                <NormalInput
                  label=""
                  type="number"
                  name="expectedQuantityperdelivery"
                  value={expectedQuantityperdelivery}
                  onChange={this.handleInput}
                  isHalfWidth={false}
                  isFullWidth={false}
                  required={true}
                />
                <div className="absolute quapop"><small className="d-block mt-qu ml-2">{detail?.custom_measurement == '' ? detail?.unit_of_measurement_name : detail?.custom_measurement}</small></div>
                {this.validator.message(
                  "expectedQuantity per delivery",
                  expectedQuantityperdelivery,
                  "required"
                )}
              </div>
            </div>
          </div>

          <div className="relative d-flex items-center mb-20 sup-width">
            <label>Frequency of delivery:</label>
            <div className="d-flex w-50">
              <div className={frequencyofdelivery == 'Other' ? 'w-50per inpa0 mr-2' : 'w-100 inpa0'}>
                <NormalSelect
                  isFullWidth={true}
                  label={""}
                  placeholder="Frequency of delivery:"
                  name="frequencyofdelivery"
                  value={frequencyofdelivery}
                  handleChange={this.handleInput}
                  arrow={true}
                  isHalfWidth={false}
                  isFullWidth={false}
                  required={true}
                  options={frequencyofdeliveryOption}
                />
              </div>
              {frequencyofdelivery == 'Other' ?
                <div className="w-50per inpa0">

                  <NormalInput
                    label=""
                    type="text"
                    name="frequencyofdeliveryOther"
                    value={frequencyofdeliveryOther}
                    placeholder=""
                    onChange={this.handleInput}
                    isHalfWidth={false}
                    isFullWidth={false}
                    required={true}
                  />
                </div>
                : ''}
            </div>
          </div>

          <div className="relative d-flex items-center mb-20 sup-width">
            <label>Price Offered(₹): <span className="text-danger font-weight-bold pl-0 fs-18">*</span></label>
            <div className="d-flex w-50">
              <div className="w-100">
                <NormalInput
                  label=""
                  type="number"
                  name="priceOffered"
                  value={priceOffered}
                  onChange={this.handleInput}
                  isHalfWidth={false}
                  isFullWidth={false}
                  required={true}
                />
                <div className="absolute quapop"><small className="d-block mt-qu ml-2">Per {detail?.custom_measurement == '' ? detail?.unit_of_measurement_name : detail?.custom_measurement}</small></div>
                {this.validator.message(
                  "priceOffered",
                  priceOffered,
                  "required"
                )}
              </div>
            </div>
          </div>

          {
            frequencyofdelivery != 'One time' ?

              <div className="relative d-flex items-center mb-20 sup-width">
                <label>Duration of Contract:
                </label>
                <div className="d-flex w-50">
                  <div className="w-50per mr-2 inpa0">

                    <NormalInput
                      label=""
                      type="number"
                      name="durationofContract"
                      value={durationofContract}
                      onChange={this.handleInput}
                      isHalfWidth={false}
                      isFullWidth={false}
                      required={true}
                    />

                  </div>
                  <div className="w-50per inpa0">

                    <NormalSelect
                      isFullWidth={true}
                      label={""}
                      placeholder="Week/Month"
                      name="durationunit"
                      value={durationunit}
                      handleChange={this.handleInput}
                      arrow={true}
                      isHalfWidth={false}
                      isFullWidth={false}
                      required={true}
                      options={weeksMonthOption}
                    />
                    {durationofContract != '' ? this.validator.message(
                      "duration unit",
                      durationunit,
                      "required"
                    ) : ''}
                  </div>
                </div>
              </div>
              : ''}

          <div className="relative d-flex items-center mb-20 sup-width">
            <label>Total Quantity: <span className="text-danger font-weight-bold pl-0 fs-18">*</span></label>
            <div className="d-flex w-50">
              <div className="w-100">

                <NormalInput
                  label=""
                  type="number"
                  name="totalQuantity"
                  value={totalQuantity}
                  onChange={this.handleInput}
                  isHalfWidth={false}
                  isFullWidth={false}
                  required={true}
                />
                <div className="absolute quapop"><small className="d-block mt-qu ml-2">{detail?.custom_measurement == '' ? detail?.unit_of_measurement_name : detail?.custom_measurement}</small></div>
                {this.validator.message(
                  "totalQuantity",
                  totalQuantity,
                  "required"
                )}
              </div>
            </div>
          </div>

          <div className="relative d-flex items-center mb-20 sup-width">
            <label>Expected Payment After:</label>
            <div className="d-flex w-50">

              <div className="w-100">
                <NormalInput
                  label=""
                  type="number"
                  name="expectedPaymentAfter"
                  value={expectedPaymentAfter}
                  onChange={this.handleInput}
                  isHalfWidth={false}
                  isFullWidth={false}
                  required={true}
                />
                <div className="absolute quapop"><small className="d-block mt-qu ml-2">Days</small></div>
                {/* {this.validator.message(
                  "expectedPaymentAfter",
                  expectedPaymentAfter,
                  "required"
                )} */}
              </div>

              {/* <div className="w-50per mr-2">
                <NormalInput
                  label=""
                  type="number"
                  name="expectedPaymentAfter"
                  value={expectedPaymentAfter}
                  onChange={this.handleInput}
                  isHalfWidth={false}
                  isFullWidth={false}
                  required={true}
                />
                {this.validator.message(
                  "expectedPaymentAfter",
                  expectedPaymentAfter,
                  "required"
                )}
              </div>
              <div className="w-50per">

                <NormalSelect
                  isFullWidth={true}
                  label={""}
                  placeholder="Week/Month"
                  name="paymentunit"
                  value={paymentunit}
                  handleChange={this.handleInput}
                  arrow={true}
                  isHalfWidth={false}
                  isFullWidth={false}
                  required={true}
                  options={weeksMonthOption}
                />
                {this.validator.message(
                  "payment unit",
                  paymentunit,
                  "required"
                )}
              </div>
             */}

            </div>
          </div>


          <div className="relative d-flex items-center mb-20 sup-width">
            <label>District Location:
              {/* <span className="text-danger font-weight-bold pl-0 fs-18">*</span> */}
            </label>
            <div className="d-flex w-50">
              <div className="w-50per mr-2">
                <NormalSelect
                  isFullWidth={true}
                  label={""}
                  placeholder="State"
                  name="state"
                  value={state}
                  handleChange={this.handleInput}
                  arrow={true}
                  isHalfWidth={false}
                  isFullWidth={false}
                  required={true}
                  options={stateOption}
                />
                {/* {this.validator.message(
                  "state",
                  state,
                  "required"
                )} */}
              </div>
              <div className="w-50per">
                <NormalSelect
                  isFullWidth={true}
                  label={""}
                  placeholder="District"
                  name="district"
                  value={district}
                  handleChange={this.handleInput}
                  arrow={true}
                  isHalfWidth={false}
                  isFullWidth={false}
                  required={true}
                  options={districtOption}
                  disabled={state === ''}
                />
                {/* {this.validator.message(
                  "district",
                  district,
                  "required"
                )} */}
              </div>
            </div>
          </div>
          <hr />
          <div className="d-flex w-100 newrecpop">
            <NormalButton
              label="Send"
              mainbg={true}
              onClick={() => this.handleSubmit()}
              className="py-2 fs-14 line-height-26 mr-2 submit-button"
            />
            {/* <NormalButton
              label="Approve"
              mainbg={true}
              onClick={() => this.handleSubmit()}
              className="py-2 fs-14 line-height-26 submit-button approve-button"
            /> */}
          </div>
        </ModalBody>
      </Modal>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getfpoStateList,
      getfpoDistrictList,
      addRecommentForm,
      sellRequestDetail,
      getMarketCentreListRM
    },
    dispatch
  );
};
let component = RecommendPopupClass;

export const RecommendPopup = connect(null, mapDispatchToProps)(component);
