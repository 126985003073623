import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { getSuccessStoryById } from "action/HomeAct";
import { CommonPageLoader } from "component/common";
import moment from "moment";
import "./style.scss";
import { history } from "service/helpers";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from "swiper";
import arrow_left from "assets/svg/add_member/arrow_left.svg";
import left_arrow from "assets/images/icon/arrow-left.svg";
import right_arrow from "assets/svg/posts/arrow-right.svg";
import defaultStoryImage from "assets/images/DefaultSuccessStory.jpg";
import defaultEventImage from "assets/images/DefaultEvent.jpg";

class SuccessStoryPreClass extends Component {
  constructor() {
    super();
    SwiperCore.use([Navigation]);
    this.state = {
      location: "",
      description: "",
      isLoading: false,
      title: "",
      shortDescription: "",
      publishOn: "",
      author: "",
      storyDocs: [],
      isCreatedBy: false,
      supportingLink: "",
      fpoDetail: {},
    };
  }

  componentDidMount() {
    let { id, name } = this.props;

    this.setState({ isLoading: true });

    if (name === "successStories") {
      this._getsuccessStoryById(id);
    }
  }

  _getsuccessStoryById = (id) => {
    this.props.getSuccessStoryById(id).then((data) => {
      let { publishOn, title, description, author, uploadImage } = data;
      this.setState({
        isLoading: false,
        publishOn,
        title,
        description,
        author,
        uploadImage,
      });
    });
  };

  handleBack = () => {
    history.goBack();
  };

  componentDidUpdate(prevProps) {
    if (prevProps.id != this.props.id) {
      let { id, name } = this.props;
      this.setState({ isLoading: true });
      if (name === "successStories") {
        this._getsuccessStoryById(id);
      }
    }
  }

  render() {
    let { name } = this.props;

    let {
      description,
      isLoading,
      title,
      shortDescription,
      publishOn,
      author,
      storyDocs,
      supportingLink,
      isCreatedBy,
      fpoDetail,
      uploadImage,
    } = this.state;

    const params = {
      slidesPerView: 1,
      spaceBetween: 20,
      breakpoints: {
        500: {
          slidesPerView: 1,
        },
        768: {
          slidesPerView: 1,
        },
      },
      navigation: {
        nextEl: "#academy-next",
        prevEl: "#academy-prev",
      },
    };

    return (
      <>
        <div className="d-flex my-3 container-sa px-0">
          <div
            onClick={() => {
              this.handleBack();
            }}
            className="d-flex"
          >
            <img className="cursor-pointer" src={arrow_left} alt="back" />
            <p className="fs-18 font-weight-bold text-black mb-0 cursor-pointer ml-3">
              {name === "events"
                ? "Our Events"
                : name === "awards"
                ? "Awards & Recognition"
                : "Success stories"}
            </p>
          </div>
        </div>

        <div className="view-eventsandImpacts bg-blue-f9 h-100">
          {isLoading ? (
            <>
              {/* Loader */}
              <CommonPageLoader />
            </>
          ) : (
            <div className="container-sa py-5">
              <div className="row">
                <div className="col-8">
                  {name !== "events" && (
                    <div className="award-title-detail pb-2">
                      {title !== null ? title : ""}
                    </div>
                  )}
                  {name === "successstories" || name === "successStories" ? (
                    <div className="agenda-detail pb-2">
                      {author !== null ? author : ""}
                    </div>
                  ) : null}
                  {name === "events" ? (
                    <></>
                  ) : name === "awards" ? (
                    <></>
                  ) : (
                    <div className="row px-3 mb-3">
                      <div className="d-flex pr-2">
                        <p className="fs-14 fw-500 text-black-1c mb-0">
                          <span className="icon-event-link"></span>{" "}
                          {"Published date :"}
                        </p>
                        <p className="fs-14 fw-500 text-gold-d0 pl-2 mb-0">
                          {publishOn !== null
                            ? moment(publishOn).format("DD MMMM YYYY")
                            : ""}
                        </p>
                      </div>
                      {name !== "successStories" && (
                        <div className="margin-right-black-50"></div>
                      )}
                      <div className="d-flex">
                        {supportingLink !== null && supportingLink !== "" ? (
                          <div
                            className={`d-flex ${
                              name !== "events" ? " px-2" : ""
                            }`}
                          >
                            <p className="fs-14 fw-500 text-black-1c mb-0">
                              <span className="icon-event-link"></span>{" "}
                              {" Supporting link : "}
                            </p>
                            <a
                              href={supportingLink}
                              className="fs-14 fw-500 text-gold-d0 mb-0 pl-1"
                              target="_blank"
                            >
                              {" "}
                              {supportingLink}
                            </a>
                          </div>
                        ) : null}
                        {!isCreatedBy && name !== "successStories" && (
                          <>
                            <div className="margin-right-black-50 mx-2"></div>
                            <div className="d-flex">
                              <div className="d-flex">
                                <p className="fs-14 fw-500 text-black-1c mb-0">
                                  {"FPO :"}
                                </p>
                                <p className="fs-14 fw-500 text-gold-d0 mb-0 pl-1">
                                  {" "}
                                  {fpoDetail?.fpoName},{" "}
                                  {fpoDetail?.stateMaster?.stateName}
                                </p>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  )}
                  <div className="pt-3">
                    {name === "successStories" ? (
                      uploadImage !== null ? (
                        <div className="img-area">
                          <img src={uploadImage} alt="uploadImage" />
                        </div>
                      ) : (
                        <div className="img-area">
                          <img
                            src={defaultEventImage}
                            alt="defaultEventImage"
                          />
                        </div>
                      )
                    ) : (
                      name === "successstories" &&
                      (storyDocs.length !== 0 ? (
                        <Swiper {...params}>
                          {storyDocs.map((list, index) => (
                            <SwiperSlide key={index}>
                              {list.fileName.includes(".pdf") ? (
                                <iframe
                                  className="pdf-view"
                                  src={list.documentUrl}
                                />
                              ) : (
                                <div className="img-area">
                                  <img
                                    src={list.documentUrl}
                                    alt="documentUrl"
                                  />
                                </div>
                              )}
                            </SwiperSlide>
                          ))}
                        </Swiper>
                      ) : (
                        <div className="img-area">
                          <img
                            src={defaultStoryImage}
                            alt="defaultStoryImage"
                          />
                        </div>
                      ))
                    )}
                  </div>
                  {storyDocs.length > 1 ? (
                    <div className="d-flex justify-content-center mt-3">
                      <input
                        type="image"
                        src={left_arrow}
                        alt="left"
                        width="40"
                        height="40"
                        id="academy-prev"
                      />
                      <input
                        type="image"
                        src={right_arrow}
                        alt="left"
                        width="40"
                        height="40"
                        id="academy-next"
                      />
                    </div>
                  ) : null}
                  <div className="pt-3">
                    <p
                      className="text-black fs-16 fw-500 line-height-34"
                      dangerouslySetInnerHTML={
                        name === "awards"
                          ? {
                              __html:
                                shortDescription !== null
                                  ? shortDescription
                                  : "",
                            }
                          : {
                              __html: description !== null ? description : "",
                            }
                      }
                    ></p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getSuccessStoryById,
    },
    dispatch
  );
};

let component = SuccessStoryPreClass;

export const SuccessStoryPre = connect(null, mapDispatchToProps)(component);
