import React, { Component } from "react";
import { Navbar } from "component/common";
import { RelationshipTab } from "component/Relationship";
import { connect } from "react-redux";
import { getAccessdata } from "service/helperFunctions";
import { UserAuth } from "helpers/UserAuth";
export class RelationshipClass extends Component {
  render() {
    let { accessData, match } = this.props;

    let permissionData = getAccessdata(accessData, "membership");

    let { edit = false } = permissionData;
    return (
      <div>
        <div className="inner-header">
          {/* <Navbar /> */}
          <div className="h-100 w-100">
            <p className="d-flex justify-content-center align-items-center h-100 w-100 text-white fs-32 font-weight-bold">
              Member Engagement
            </p>
          </div>
        </div>
        <RelationshipTab
          edit={edit}
          id={match.params.id}
          match={this.props.match}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  accessData: state.home.accessData,
});

let component = UserAuth(RelationshipClass, { service: "membership" });

export const Relationship = connect(mapStateToProps, null)(component);
