import React, { Component } from "react";

export class Pagination extends Component {
  handlePagination = (direction) => {
    try {
      let { page = 1 } = this.props.pageMeta || {};
      let pageNumber = eval(`${page} ${direction} ${1}`);

      this.props.handleChange({ page: pageNumber });
    } catch (err) {
      console.log(err);
    }
  };

  render() {
    let { pageCount = 10, page = 1 } = this.props.pageMeta || {};
    if (pageCount <= 1) {
      return "";
    }

    return (
      <div className="pagination-area">
        <div className="d-flex text-center w-100 justify-content-center pr-4">
          <p className="m-0 d-flex align-items-center">
            <button
              className={`table-left-arrow ${
                page === 1 ? "btn-effect" : "cursor-pointer btn-effect"
              }`}
              disabled={page == 1}
              onClick={(e) => page != 1 && this.handlePagination("-")}
            >
              <div
                className={`rounded-circle d-flex align-items-center justify-content-center ${
                  page === 1 ? "bg-white" : "bg-green-34"
                }`}
              >
                <span
                  className={`icon-keyboard-arrow-left fs-24 p-1 ${
                    page === 1
                      ? "text-green-18 border-green-18 rounded-circle"
                      : "text-white border-transparent"
                  } `}
                />
              </div>
            </button>

            <button
              className={`btn-effect table-right-arrow ml-2 ${
                page === pageCount ? "" : "cursor-pointer"
              }`}
              disabled={page == pageCount}
              onClick={() => page != pageCount && this.handlePagination("+")}
            >
              <div
                className={`rounded-circle d-flex align-items-center justify-content-center ${
                  page === pageCount ? "bg-white" : "bg-green-34"
                } `}
              >
                <span
                  className={`icon-keyboard-arrow-right fs-24 p-1 ${
                    page === pageCount
                      ? "text-green-18 border-green-18 rounded-circle"
                      : "text-white border-transparent"
                  } `}
                />
              </div>
            </button>
          </p>
        </div>
      </div>
    );
  }
}
