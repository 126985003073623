import React, { Component } from "react";
import { Form } from "reactstrap";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  membersView,
  allCrops,
  memberFramAdd,
  fileUpload,
  getAllFarmLiveStocks,
  getMemberFarmDetail,
  updateMemberFarm,
  deletefarmDetails,
} from "action/MemberAct";
import SimpleReactValidator from "simple-react-validator";

import { NormalInput, NormalSelect } from "component/common";

import { Toast } from "service/toast";

const arrow_up = require("../../../assets/svg/add_member/arrow_up.svg");
const upload = require("../../../assets/svg/add_member/upload.svg");

class FarmForm extends Component {
  constructor(props) {
    super();
    this.state = {
      members: [],
      memberId: "",
      plot_acreage: "",
      plot_geolocation_file: null,
      rabi_crops: [],
      selected_rabi_crops: [],
      kharif_crops: [],
      selected_kharif_crops: [],
      livestock_crops: [],
      selected_livestock_crops: [],
      plot_geolocation_file_name: "",
      isFileUploading: false,
      farmData: null,
      isDataUploading: false,
    };
  }

  validator = new SimpleReactValidator({
    element: (message) => (
      <span className="error-message text-danger fs-14">{message}</span>
    ),
    autoForceUpdate: this,
  });

  handleFile = (e) => {
    //upload address proof
    let file = e.target.files[0];
    if (file !== undefined) {
      this.setState({ plot_geolocation_file: null }, () => {
        this.setState({
          isFileUploading: true,
          plot_geolocation_file_name: "Uploading ....",
          plot_geolocation_file: file,
        });
        const formData = new FormData();
        formData.append("file", file);

        this.props
          .fileUpload(formData)
          .then((data) => {
            document.getElementById("handleFile").value = null;
            this.setState({
              plot_geolocation_file: data[0].fileUrl,
              plot_geolocation_file_name: data[0].fileName,
              isFileUploading: false,
            });
          })
          .catch(() => {
            this.setState({
              plot_geolocation_file: null,
              plot_geolocation_file_name: "",
              isFileUploading: false,
            });
          });
      });
    }
  };

  handleChange = ({ target: { name, value } }) => {
    this.setState({
      [name]: value,
    });
  };

  handleRabiCropSelect = (crop) => {
    let { selected_rabi_crops } = this.state;

    if (
      !selected_rabi_crops.find((r_crop) => {
        return r_crop === crop;
      })
    ) {
      this.setState({ selected_rabi_crops: [] }, () => {
        selected_rabi_crops.push(crop);
        this.setState({ selected_rabi_crops });
      });
    } else {
      let index = selected_rabi_crops.findIndex((r_crop) => {
        return r_crop === crop;
      });
      if (index !== -1) {
        this.setState((prevState) => {
          prevState.selected_rabi_crops.splice(index, 1);
        });
      }
    }
  };

  handleKharifCropSelect = (crop) => {
    let { selected_kharif_crops } = this.state;
    if (
      !selected_kharif_crops.find((r_crop) => {
        return r_crop === crop;
      })
    ) {
      this.setState({ selected_kharif_crops: [] }, () => {
        selected_kharif_crops.push(crop);
        this.setState({ selected_kharif_crops });
      });
    } else {
      let index = selected_kharif_crops.findIndex((r_crop) => {
        return r_crop === crop;
      });
      if (index !== -1) {
        this.setState((prevState) => {
          prevState.selected_kharif_crops.splice(index, 1);
        });
      }
    }
  };

  handleLivestockCropSelect = (crop) => {
    let { selected_livestock_crops } = this.state;
    if (
      !selected_livestock_crops.find((r_crop) => {
        return r_crop === crop;
      })
    ) {
      this.setState({ selected_livestock_crops: [] }, () => {
        selected_livestock_crops.push(crop);
        this.setState({ selected_livestock_crops });
      });
    } else {
      let index = selected_livestock_crops.findIndex((r_crop) => {
        return r_crop === crop;
      });
      if (index !== -1) {
        this.setState((prevState) => {
          prevState.selected_livestock_crops.splice(index, 1);
        });
      }
    }
  };

  handleSave = (e) => {
    if (this.validator.allValid()) {
      const {
        memberId,
        plot_acreage,
        plot_geolocation_file,
        isFileUploading,
        plot_geolocation_file_name,
        selected_livestock_crops,
        selected_rabi_crops,
        selected_kharif_crops,
        farmData,
      } = this.state;
      if (memberId) {
        if (!isFileUploading) {
          let body = {
            memberID: parseInt(memberId),
            plotAcreage: plot_acreage,
            PlotcropId: [1],
            RabicropId:
              selected_rabi_crops.length !== 0 ? selected_rabi_crops : "",
            livecropId:
              selected_livestock_crops.length !== 0
                ? selected_livestock_crops
                : "",
            KharifcropId:
              selected_kharif_crops.length !== 0 ? selected_kharif_crops : "",
            entryDate: new Date(),
          };

          if (plot_geolocation_file_name !== "") {
            body.plotGeolocationName = plot_geolocation_file_name;
            body.plotGeolocation = plot_geolocation_file;
          }

          if (plot_acreage !== "") {
            body.plotAcreage = plot_acreage;
          }

          this.setState({ isDataUploading: true });
          if (farmData == null) {
            this.props
              .memberFramAdd(body)
              .then((data) => {
                if (data == 200) {
                  this.validator.hideMessages();
                  // this._clearData();
                  this.props.toggleCollapse(
                    false,
                    false,
                    false,
                    true,
                    false,
                    false
                  );
                }
                this.setState({ isDataUploading: false });
              })
              .catch(() => {
                this.setState({ isDataUploading: false });
              });
          } else {
            this.props
              .updateMemberFarm(body)
              .then((data) => {
                if (data == 200) {
                  this.validator.hideMessages();
                  this.props.toggleCollapse(
                    false,
                    false,
                    false,
                    true,
                    false,
                    false
                  );
                }
                this.setState({ isDataUploading: false });
              })
              .catch(() => {
                this.setState({ isDataUploading: false });
              });
          }
        } else {
          Toast({ type: "error", message: "Please wait Image is uploading" });
        }
      } else {
        Toast({
          type: "error",
          message: "Please add basic profile of member",
        });
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  //delete farm data
  handleDelete = () => {
    this.setState({ isDataUploading: true });
    let { memberId } = this.state;
    let { deletefarmDetails } = this.props;
    let body = {
      id: memberId,
    };
    deletefarmDetails(body).then(() => {
      this._getMemberFarmDetail(memberId);
    });
  };

  //get farm details
  _getMemberFarmDetail = (id) => {
    this.props.getMemberFarmDetail({ id }).then((data) => {
      if (data == null) {
        this.setState({
          plot_acreage: "",
          plot_geolocation_file: null,
          selected_rabi_crops: [],
          selected_kharif_crops: [],
          selected_livestock_crops: [],
          plot_geolocation_file_name: "",
          isDataUploading: false,
          farmData: null,
        });
      } else {
        this.setState({
          farmData: data,
          memberId: data.memberID,
          plot_acreage: data.plotAcreage ? data.plotAcreage : "",
          plot_geolocation_file: data.plotGeolocation
            ? data.plotGeolocation
            : "",
          selected_rabi_crops: data.rabiMapId,
          selected_kharif_crops: data.kharifMapId,
          selected_livestock_crops: data.livecropId,
          plot_geolocation_file_name: data.plotGeolocationName
            ? data.plotGeolocationName
            : "",
        });
      }
    });
  };

  // _clearData = () => {
  //   this.setState({
  //     memberId: "",
  //     plot_acreage: "",
  //     plot_geolocation_file: null,
  //     selected_rabi_crops: [],
  //     selected_kharif_crops: [],
  //     selected_livestock_crops: [],
  //     plot_geolocation_file_name: "",
  //     farmData: null,
  //     isDataUploading: false,
  //   });
  // };

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      if (this.props.isOpen) {
        let { memberId } = this.props;
        this.setState({
          memberId,
        });
        this._getMemberFarmDetail(memberId);
        // this.props.membersView().then((data) => {
        //   let memberDetials = [];
        //   data.map((list) => {
        //     memberDetials.push({
        //       label: list.memberFirstName + " | " + list.phone,
        //       value: list.memberID,
        //     });
        //   });
        //   this.setState({
        //     members: memberDetials,
        //   });
        // });
        this.props.allCrops().then((data) => {
          this.setState({
            kharif_crops: data,
            rabi_crops: data,
            crops: data,
          });
        });
        this.props.getAllFarmLiveStocks().then((data) => {
          this.setState({
            livestock_crops: data,
          });
        });
      }
    }
  }

  handleRemoveImage = () => {
    this.setState({
      plot_geolocation_file: null,
      plot_geolocation_file_name: "",
    });
  };

  render() {
    const {
      memberId,
      plot_acreage,
      members,
      plot_geolocation_file,
      plot_geolocation_file_name,
      rabi_crops,
      selected_rabi_crops,
      kharif_crops,
      selected_kharif_crops,
      livestock_crops,
      selected_livestock_crops,
      isDataUploading,
      farmData,
      isFileUploading,
    } = this.state;

    let { deleteAccess = false } = this.props;

    return (
      <div className="px-md-5 px-3 py-4 add-member-area">
        <div className="d-flex justify-content-between mb-3">
          <div className="add-member-form-container-heading w-25">Crops</div>
          <div className="d-flex justify-content-end w-75 align-items-center">
            {isDataUploading ? (
              <div className="d-flex align-items-center h-100">
                {farmData != null && deleteAccess && (
                  <div className="mr-3 d-flex align-items-center h-100">
                    <p className="mb-1 font-weight-bold fs-20 d-flex align-items-center">
                      <i className="icon-Delete fs-18" />
                      <span className="text-danger fs-18 pl-3">{"Delete"}</span>
                    </p>
                  </div>
                )}
                <div className="add-member-form-container-button d-flex align-items-center">
                  {"Save & next"}
                </div>
              </div>
            ) : (
              <div className="d-flex align-items-center h-100">
                {deleteAccess && farmData != null && (
                  <div className="mr-3 d-flex align-items-center h-100">
                    <p
                      className="mb-1 font-weight-bold fs-20 cursor-pointer d-flex align-items-center"
                      onClick={() => this.handleDelete()}
                    >
                      <i className="icon-Delete fs-18" />
                      <span className="text-danger fs-18 pl-3">{"Delete"}</span>
                    </p>
                  </div>
                )}
                <div
                  className="add-member-form-container-button cursor-pointer d-flex align-items-center"
                  onClick={this.handleSave}
                >
                  {"Save & next"}
                </div>
              </div>
            )}
            <img
              className="ml-3 cursor-pointer"
              src={arrow_up}
              alt="arrow_up"
              height="10"
              width="10"
              name="farm"
              onClick={this.props.collapse}
            />
          </div>
        </div>
        <div className="row">
          {/* <div className="col-6">
            <NormalSelect
              isHalfWidthForMemberProfile={true}
              label="Member"
              placeholder="Select"
              value={memberId}
              arrow={true}
              name="memberId"
              options={members}
              handleChange={this.handleChange}
              required={true}
            />
            <div className="row">
              <div className="col-8 ml-auto">
                {this.validator.message("member", memberId, "required")}
              </div>
            </div>
          </div> */}
          <div className="col-md-12">
            <NormalInput
              isFullWidth={true}
              label="Plot acreage"
              type="number"
              onChange={this.handleChange}
              value={plot_acreage}
              name="plot_acreage"
              disabled={memberId == "" ? true : false}
              required={true}
            />
            <div className="row">
              <div className="col-md-10 ml-auto">
                {this.validator.message(
                  "plot_acreage",
                  plot_acreage,
                  "required|max:4"
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="form-container mt-4 pt-3">
          <Form className="d-flex flex-column">
            <div className="mb-4">
              <div className="add-member-form-container-proof">Uploads</div>
              <div className="d-flex justify-content-between align-items-center mb-2 pb-1 container-width">
                <div className="add-member-form-container-proof-sub">
                  Plot geolocation file
                </div>
                {plot_geolocation_file != null ? (
                  <div>
                    {!isFileUploading ? (
                      <i
                        className="icon-Delete fs-14 cursor-pointer"
                        onClick={() => this.handleRemoveImage()}
                      ></i>
                    ) : null}
                  </div>
                ) : null}
              </div>
              <div className="add-member-form-container-proof-container d-flex justify-content-center align-items-center">
                <input
                  type="file"
                  accept=".jpg, .pdf, .jpeg"
                  name="plot_geolocation_file"
                  onChange={this.handleFile}
                  disabled={memberId == "" ? true : false}
                  id="handleFile"
                  title=""
                />
                {plot_geolocation_file != null ? (
                  <div className="h-100">
                    <img
                      src={plot_geolocation_file}
                      alt={plot_geolocation_file_name}
                      className="h-100"
                    />
                  </div>
                ) : (
                  <>
                    <img className="mr-2" src={upload} alt="upload" />
                    {"Upload files"}
                  </>
                )}
              </div>
            </div>
            <div className="farm-crops-container">
              <div className="add-member-form-container-proof mb-3">
                {"Select rabi crops (October - March)"}
              </div>
              <div className="flex-wrap d-flex">
                {rabi_crops.map((crop, index) => {
                  return (
                    <div
                      key={index}
                      className={`d-flex flex-column ${
                        selected_rabi_crops.find((s_crop) => {
                          return s_crop === crop.cropId;
                        }) !== undefined
                          ? "crops-container-selected mr-2 mb-3"
                          : "crops-container mr-2 mb-3"
                      } `}
                      onClick={() => {
                        this.handleRabiCropSelect(crop.cropId);
                        this.forceUpdate();
                      }}
                    >
                      <img
                        className="crops-container-img"
                        src={crop.cropIconUrl}
                        alt="crops"
                      />
                      <p className="mb-0 fs-10 text-green-18">
                        {crop.cropName}
                      </p>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="farm-crops-container">
              <div className="add-member-form-container-proof mb-3">
                {"Select kharif crops (July - October)"}
              </div>
              <div className="flex-wrap d-flex">
                {kharif_crops.map((crop, index) => {
                  return (
                    <div
                      key={index}
                      className={`d-flex flex-column ${
                        selected_kharif_crops.find((s_crop) => {
                          return s_crop === crop.cropId;
                        }) !== undefined
                          ? "crops-container-selected mr-2 mb-3"
                          : "crops-container mr-2 mb-3"
                      } `}
                      onClick={() => {
                        this.handleKharifCropSelect(crop.cropId);
                        this.forceUpdate();
                      }}
                    >
                      <img
                        className="crops-container-img"
                        src={crop.cropIconUrl}
                        alt="crops"
                      />
                      <p className="mb-0 fs-10 text-green-18">
                        {crop.cropName}
                      </p>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="farm-crops-container">
              <div className="add-member-form-container-proof mb-3">
                {"Livestock crops"}
              </div>
              <div className="flex-wrap d-flex">
                {livestock_crops.map((crop, index) => {
                  return (
                    <div
                      key={index}
                      className={`d-flex flex-column ${
                        selected_livestock_crops.find((s_crop) => {
                          return s_crop === crop.cropId;
                        }) !== undefined
                          ? "crops-container-selected mr-2 mb-3"
                          : "crops-container mr-2 mb-3"
                      } `}
                      onClick={() => {
                        this.handleLivestockCropSelect(crop.cropId);
                        this.forceUpdate();
                      }}
                    >
                      <img
                        className="crops-container-img"
                        src={crop.cropIconUrl}
                        alt="crops"
                      />
                      <p className="mb-0 fs-10 text-green-18">
                        {crop.cropName}
                      </p>
                    </div>
                  );
                })}
              </div>
            </div>
          </Form>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      membersView,
      allCrops,
      memberFramAdd,
      fileUpload,
      getMemberFarmDetail,
      getAllFarmLiveStocks,
      updateMemberFarm,
      deletefarmDetails,
    },
    dispatch
  );
};
let component = FarmForm;

export const Farm = connect(null, mapDispatchToProps)(component);
