import React from "react";
import RMInput from "component/RM/Common/RMInput";
import RMSelect from "component/RM/Common/RMSelect";
import RMDate from "component/RM/Common/RMDate";
import RMRadio from "component/RM/Common/RMRadio";
import { genderOptions, educationOptions } from "../dataVariables";

const BasicInfo = (props) => {
  const {
    memberData,
    handleMemberChange,
    stateOptions,
    districtOptions,
    validator,
    fieldRef,
  } = props;

  return (
    <div>
      <RMInput
        label="FPO Name (to which the member belongs)"
        name="fpo_name"
        value={memberData.fpo_name}
        max={150}
        onChange={(e) => handleMemberChange(e)}
        isRequired={true}
        readOnly={true}
        ref={fieldRef}
      />
      {validator.current.message(
        "fpo_name",
        memberData.fpo_name,
        "required|min:3|max:150"
      )}
      <RMSelect
        label="State"
        name="state"
        placeholder="Select State"
        value={memberData.state}
        onChange={(e) => handleMemberChange(e)}
        option={stateOptions}
        isRequired={true}
        disabled={true}
        ref={fieldRef}
      />
      {validator.current.message("state", memberData.state, "required")}
      <RMSelect
        label="District"
        name="district"
        placeholder="Select District"
        value={memberData.district}
        onChange={(e) => handleMemberChange(e)}
        option={districtOptions}
        disabled={memberData.state === ""}
        isRequired={true}
        ref={fieldRef}
      />
      {validator.current.message("district", memberData.district, "required")}
      <RMInput
        label="1. Unique ID given by Authorities"
        name="unique_id"
        value={memberData.unique_id}
        max={20}
        onChange={(e) => handleMemberChange(e)}
      />
      <RMInput
        label="2. Name"
        name="name"
        value={memberData.name}
        max={75}
        onChange={(e) => handleMemberChange(e)}
        allowOnlyText={true}
        isRequired={true}
        ref={fieldRef}
      />
      {validator.current.message(
        "name",
        memberData.name,
        "required|min:3|max:75"
      )}
      <RMRadio
        label="3. Gender"
        name="gender"
        className="mb-1"
        checked={memberData.gender}
        onChange={(e) => handleMemberChange(e)}
        option={genderOptions}
        isRequired={true}
        ref={fieldRef}
      />
      {validator.current.message("gender", memberData.gender, "required")}
      <RMDate
        label="4. Member Date-Of-Birth"
        name="dob"
        selected={memberData.dob}
        onChange={(e) => handleMemberChange(e)}
        isRequired={true}
        ref={fieldRef}
      />
      {validator.current.message("dob", memberData.dob, "required")}
      <RMInput
        label="5. Mobile Number"
        name="mobile"
        value={memberData.mobile}
        max={10}
        onChange={(e) => handleMemberChange(e)}
        allowOnlyNumber={true}
        isRequired={true}
        ref={fieldRef}
      />
      {validator.current.message("mobile", memberData.mobile, "required|phone")}
      <RMInput
        label="6. Other Phone number"
        name="other_mobile"
        value={memberData.other_mobile}
        max={10}
        onChange={(e) => handleMemberChange(e)}
        allowOnlyNumber={true}
      />
      <RMInput
        label="7. Village"
        name="village"
        value={memberData.village}
        max={30}
        onChange={(e) => handleMemberChange(e)}
        allowOnlyText={true}
        isRequired={true}
        ref={fieldRef}
      />
      {validator.current.message("village", memberData.village, "required")}
      <RMInput
        label="8. Block"
        name="block"
        value={memberData.block}
        max={20}
        onChange={(e) => handleMemberChange(e)}
        allowOnlyText={true}
        isRequired={true}
        ref={fieldRef}
      />
      {validator.current.message("block", memberData.block, "required")}
      <RMInput
        label="9. Pincode"
        name="pincode"
        value={memberData.pincode}
        max={6}
        onChange={(e) => handleMemberChange(e)}
        allowOnlyNumber={true}
        isRequired={true}
        ref={fieldRef}
      />
      {validator.current.message(
        "pincode",
        memberData.pincode,
        "required|numeric|min:6"
      )}
      <RMInput
        label="10. Father's Name"
        name="father_name"
        value={memberData.father_name}
        max={75}
        onChange={(e) => handleMemberChange(e)}
        allowOnlyText={true}
        isRequired={true}
        ref={fieldRef}
      />
      {validator.current.message(
        "father_name",
        memberData.father_name,
        "required|min:3|max:75"
      )}
      <RMRadio
        label="11. Education"
        name="education"
        checked={memberData.education}
        onChange={(e) => handleMemberChange(e)}
        option={educationOptions}
        isVertical={true}
        isRequired={true}
        ref={fieldRef}
      />
      {validator.current.message("education", memberData.education, "required")}
    </div>
  );
};

export default BasicInfo;
