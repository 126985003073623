import React, { Component } from "react";
import DatePicker from "react-datepicker";
import "./date.scss";
// import "react-datepicker/dist/react-datepicker.css";

export class NormalDate extends Component {
  render() {
    let {
      className = "form-control w-100",
      placeholder = "DD/MM/YY",
      onChange,
      value = "",
      name,
      disabled = false,
      isReadOnly = false,
      showYearPicker = false,
      showFullMonthYearPickerStatus = false,
      showMonthYearPickerStatus = false,
      showYearDropdown = true,
      showMonthDropdown = true,
      maxdate = "false",
      mindate = "false",
      timeInput = "false",
      label = "",
      isFullWidth = false,
      isHalfWidth = false,
      isIcon = false,
      required = false,
      isHalfWidthForMemberProfile = false,
      dateFormat = "dd/MM/yyyy",
      peekNextMonth = true,
      isYearPicker = false,
      isregPage = false,
      showTimeSelectOnly = false,
      timeFormat = "HH:mm",
      timeIntervals,
      labelClassName = "fs-16 text-black-32 font-Gilroy-Bold font-weight-bold line-height-19",
    } = this.props;

    return (
      <div className="normal-date-input">
        <form
          autocomplete="off"
          onSubmit={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <div className="row d-flex align-items-center">
            <div
              className={`${
                isregPage
                  ? ""
                  : isFullWidth
                  ? "col-md-2"
                  : isHalfWidth
                  ? "col-md-4"
                  : "col-md-3"
              } pr-0 `}
            >
              {label !== "" ? (
                <div className={labelClassName}>
                  {label}
                  {required ? (
                    <span className="text-danger font-weight-bold pl-2 fs-18">
                      *
                    </span>
                  ) : null}
                </div>
              ) : null}
            </div>
            <div
              className={`${
                isregPage
                  ? "col-md-12"
                  : isFullWidth
                  ? "col-md-10"
                  : isHalfWidth || isHalfWidthForMemberProfile
                  ? "col-md-8"
                  : "col-md-9"
              }  `}
            >
              {!isReadOnly ? (
                <>
                  {!isYearPicker ? (
                    <DatePicker
                      // onKeyDown={(e) =>
                      //   e.keyCode !== 8 ? e.preventDefault() : ""
                      // }
                      disabledKeyboardNavigation
                      timeInputLabel={timeInput && "Time:"}
                      showTimeInput={timeInput}
                      className={className}
                      disabled={disabled}
                      autoComplete={"off"}
                      placeholderText={placeholder}
                      selected={value}
                      showYearPicker={showYearPicker}
                      closeOnScroll={true}
                      showMonthYearPicker={showMonthYearPickerStatus}
                      showFullMonthYearPicker={showFullMonthYearPickerStatus}
                      showMonthDropdown={showMonthDropdown}
                      showYearDropdown={showYearDropdown}
                      dropdownMode="select"
                      peekNextMonth={peekNextMonth}
                      dateFormat={dateFormat}
                      maxDate={maxdate && new Date()}
                      minDate={mindate && new Date()}
                      showTimeSelectOnly={showTimeSelectOnly}
                      timeFormat={timeFormat}
                      timeIntervals={timeIntervals}
                      onChange={(date) => {
                        let body = {};
                        body = {
                          target: {
                            name: name,
                            value: date,
                          },
                        };
                        onChange(body);
                      }}
                      name={name}
                      popperPlacement="bottom"
                      popperModifiers={{
                        flip: {
                          behavior: ["bottom"],
                        },
                      }}
                    />
                  ) : (
                    <DatePicker
                      // onKeyDown={(e) =>
                      //   e.keyCode !== 8 ? e.preventDefault() : ""
                      // }
                      disabledKeyboardNavigation
                      className={className}
                      disabled={disabled}
                      placeholderText={placeholder}
                      selected={value}
                      showYearPicker
                      dateFormat="yyyy"
                      onChange={(date) => {
                        let body = {};
                        body = {
                          target: {
                            name: name,
                            value: date,
                          },
                        };
                        onChange(body);
                      }}
                      name={name}
                      popperPlacement="bottom"
                      popperModifiers={{
                        flip: {
                          behavior: ["bottom"],
                        },
                      }}
                    />
                  )}
                  {isIcon ? (
                    <i className="icon-chevron-down calender-icon fs-20"></i>
                  ) : null}
                </>
              ) : (
                <p>{value}</p>
              )}
            </div>
          </div>
        </form>
      </div>
    );
  }
}
