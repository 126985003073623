import React from "react";
import { Modal, ModalBody } from "reactstrap";

const TermsPolicyPopup = ({ isOpen, toggle }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} centered={true}>
      <ModalBody>
        <div className="memberList-popup">
          <i className="fas fa-times" onClick={toggle}></i>
          <div className="px-3">
            <div className="pt-3">
              <b>
                <p>
                  This FPO has not accepted the latest{" "}
                  <span className="fw-600 text-dark">Terms & Conditions</span>{" "}
                  and
                  <span className="fw-600 text-dark pl-2">
                    Privacy Policy
                  </span>{" "}
                  for using FPO Gateway application. You will be able to proceed
                  further once the FPO has accepted the same.
                </p>
              </b>
            </div>
            <div className="memberList-btn">
              <button onClick={toggle}>Ok</button>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default TermsPolicyPopup;
