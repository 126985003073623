import { generateQuery, addQuery } from "./helperFunctions";
export const authApi = {
  userLogin: {
    api: "user/auth/password/login",
    method: "post",
    baseURL: "auth",
  },
  verifyPassword: {
    api: "user/auth/password/verify",
    method: "post",
    baseURL: "auth",
  },
  otpLogin: {
    api: "user/auth/otp/login",
    method: "post",
    baseURL: "auth",
  },
  verifyOtp: {
    api: "user/auth/otp/verify",
    method: "post",
    baseURL: "auth",
  },
  forgotPasswordLink: {
    api: "user/auth/forgot/password",
    method: "post",
    baseURL: "auth",
  },
  forgotPasswordTokeValidation: {
    api: "user/auth/check/forgot/password/token",
    method: "get",
    baseURL: "auth",
  },
  changePassword: {
    api: "user/auth/change/forgot/password",
    method: "post",
    baseURL: "auth",
  },
  getAccessDetails: {
    api: "user/role/management/roles/permission/list",
    method: "get",
    baseURL: "auth",
  },
  changePasswordAfterLogin: {
    api: "user/auth/change/password",
    method: "post",
    baseURL: "auth",
  },
  removeProfilePicture: {
    api: "user/delete/userProfile",
    method: "delete",
    baseURL: "auth",
  },
  logoutApi: {
    api: "user/auth/logout",
    method: "post",
    baseURL: "auth",
  },
  verifyFpo: {
    api: "user/auth/verify/fpo/token",
    method: "get",
    baseURL: "auth",
  },
  googleLogin: {
    api: "user/auth/google/login",
    method: "post",
    baseURL: "auth",
  },
  changeBasicDetails: {
    api: "user/auth/change/basicDetails",
    method: "put",
    baseURL: "auth",
  },
  getAccessTokenForPowerBi: {
    api: "",
    method: "get",
    baseURL: "powerBi",
  },
  FIGCRPOTPLogin: {
    api: "user/auth/figCrp/otp/login",
    method: "post",
    baseURL: "auth",
  },
  FIGCRPOTPLoginVerification: {
    api: "user/auth/figCrp/otp/verify",
    method: "post",
    baseURL: "auth",
  },
  FIGCRPResendOTP: {
    api: "user/auth/otp/resendMobile/otp",
    method: "post",
    baseURL: "auth",
  },
  SSOLogin: {
    api: "user/auth/rm/sso/login",
    method: "post",
    baseURL: "auth",
  },
};

export const sumunnatiRegistration = {
  registrationApi: {
    api: "user/samunati/signup",
    method: "post",
    baseURL: "auth",
  },
  roleApi: {
    api: "roles/list/samunatti",
    method: "get",
    baseURL: "auth",
  },
};

export const FpoRegistration = {
  registrationApi: {
    api: "user/fpo/signup",
    method: "post",
    baseURL: "auth",
  },
  registrationApiUnderFpo: {
    api: "user/fpo/user/signup",
    method: "post",
    baseURL: "auth",
  },
  roleApi: {
    api: "roles/list/fpo",
    method: "get",
    baseURL: "auth",
  },
  fpoList: {
    api: "fpo/management/fpo/list/signup",
    method: "get",
    baseURL: "member",
  },
  signUpEntityType: {
    api: "fpo/management/fpo/entityType/list",
    method: "get",
    baseURL: "member",
  },
  emailAndNumberValidation: {
    api: "user/fpo/signup/validate",
    method: "post",
    baseURL: "auth",
  },
};

export const ExternalRegistration = {
  registrationApi: {
    api: "user/external/signup",
    method: "post",
    baseURL: "auth",
  },
  roleApi: {
    api: "roles/list/organization",
    method: "get",
    baseURL: "auth",
  },
};

export const IndividualRegistration = {
  registrationApi: {
    api: "user/individual/signup",
    method: "post",
    baseURL: "auth",
  },
  roleApi: {
    api: "roles/list/individual",
    method: "get",
    baseURL: "auth",
  },
};

export const Member = {
  addMemberBasic: {
    api: "members/fpo/members",
    method: "post",
    baseURL: "member",
  },
  addMemberHouseHold: {
    api: "members/fpo/members/household",
    method: "post",
    baseURL: "member",
  },
  updateMemberHouseHold: {
    api: "members/fpo/members/household/update",
    method: "put",
    baseURL: "member",
  },
  getMemberHouseHold: {
    url: "members/fpo/members/gethousehold/",
    method: "get",
    id: null,
    baseURL: "member",
    get api() {
      return this.url + this.id;
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getMemberAsset: {
    url: "members/fpo/members/getasset/",
    method: "get",
    id: null,
    baseURL: "member",
    get api() {
      return this.url + this.id;
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getMemberLiveStock: {
    url: "members/fpo/members/getlivestock/",
    method: "get",
    id: null,
    baseURL: "member",
    get api() {
      return this.url + this.id;
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getMemberBankDetail: {
    url: "members/fpo/members/getbankaccount/",
    method: "get",
    id: null,
    baseURL: "member",
    get api() {
      return this.url + this.id;
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getMemberFarmDetail: {
    url: "members/fpo/members/getfarm/",
    method: "get",
    id: null,
    baseURL: "member",
    get api() {
      return this.url + this.id;
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  addMemberFarm: {
    api: "members/fpo/members/farminfo",
    method: "post",
    baseURL: "member",
  },
  updateMemberFarm: {
    api: "members/fpo/members/farminfo/update",
    method: "put",
    baseURL: "member",
  },
  addMemberAsset: {
    api: "members/fpo/members/asset",
    method: "post",
    baseURL: "member",
  },
  addMemberLivestock: {
    api: "members/fpo/members/livestock",
    method: "post",
    baseURL: "member",
  },
  addMemberBank: {
    api: "members/fpo/members/bankaccount",
    method: "post",
    baseURL: "member",
  },
  EditBasicDetail: {
    api: "members/fpo/members/update",
    method: "put",
    baseURL: "member",
  },
  updateMemberData: {
    url: "members/fpo/members/update",
    method: "put",
    baseURL: "member",
  },
  EditAssetsDetail: {
    api: "members/fpo/members/asset/update",
    method: "put",
    baseURL: "member",
  },
  EditLiveStockDetail: {
    api: "members/fpo/members/livestock/update",
    method: "put",
    baseURL: "member",
  },
  EditBankDetail: {
    api: "members/fpo/members/bankaccount/update",
    method: "put",
    baseURL: "member",
  },
  getMembersApi: {
    api: "members/fpo/allmembers",
    method: "get",
    baseURL: "member",
  },
  getMember: {
    url: "members/fpo/profile/",
    method: "get",
    id: null,
    baseURL: "member",
    get api() {
      return this.url + this.id;
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getMembersCardApi: {
    url: "members/fpo/memberscard",
    method: "get",
    baseURL: "member",
    query: {
      limit: 5,
      page: 1,
      memberName: "",
      filter: null,
      filterType: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getAllCrops: {
    api: "members/fpo/members/allcrops",
    method: "get",
    baseURL: "member",
  },
  getAllFarmLiveStocks: {
    api: "members/fpo/members/farmlivestocktypes",
    method: "get",
    baseURL: "member",
  },
  getAllLiveStocks: {
    api: "members/fpo/members/livestocktypes",
    method: "get",
    baseURL: "member",
  },
  getAllAssetTypes: {
    api: "members/fpo/members/assettype",
    method: "get",
    baseURL: "member",
  },
  fileUpload: {
    api: "documents/file/upload1",
    method: "post",
    baseURL: "file",
  },
  getStateApi: {
    api: "members/fpo/members/allstate",
    method: "get",
    baseURL: "member",
  },
  getDistrictApiwithState: {
    url: "members/fpo/members/alldistrict/",
    method: "get",
    baseURL: "member",
    id: null,
    get api() {
      return this.url + this.id;
    },
    set addQuery({ key, payload }) {
      this[key] = payload;
    },
  },
  getPincodeApiwithStateDistrict: {
    url: "members/fpo/members/allpincode/",
    method: "get",
    baseURL: "member",
    id: null,
    get api() {
      return this.url + this.id;
    },
  },
  getAboutusMemberDetail: {
    api: "fpo/management/fpo/my/profile",
    method: "get",
    baseURL: "member",
  },
  getAboutusMemberDetailWithId: {
    url: "fpo/management/fpo/profile/details/",
    method: "get",
    baseURL: "member",
    id: null,
    get api() {
      return this.url + this.id;
    },
  },
  updateAboutUsDetails: {
    api: "fpo/management/fpo/profile/update",
    method: "put",
    baseURL: "member",
  },
  getcropBusinessInfra: {
    api: "fpo/management/fpo/cropBusinessInfra/list",
    method: "get",
    baseURL: "member",
  },
  getMemberProfileCsv: {
    api: "members/fpo/members/download/csv",
    method: "get",
    baseURL: "member",
  },
  getDownloadKYMMembersData: {
    api: "fpo/management/fpo/download/kym/members",
    method: "post",
    baseURL: "member",
  },
  getBodProfileCsv: {
    api: "fpo/management/bod/list/download/csv",
    method: "get",
    baseURL: "member",
  },
  getStaffProfileCsv: {
    api: "fpo/management/staff/list/download/csv",
    method: "get",
    baseURL: "member",
  },
  isFirstTimeLoginOrNot: {
    api: "fpo/management/fpo/profile/updated",
    method: "get",
    baseURL: "member",
  },
  deleteLiveStock: {
    url: "members/fpo/members/farminfo/",
    method: "delete",
    baseURL: "member",
    id: null,
    liveStockId: null,
    get api() {
      return this.url + this.id + "/" + this.liveStockId;
    },
    set addQuery({ key, payload }) {
      this[key] = payload;
    },
  },
  deleteAsset: {
    url: "members/fpo/members/asset/",
    method: "delete",
    baseURL: "member",
    id: null,
    assetId: null,
    get api() {
      return this.url + this.id + "/" + this.assetId;
    },
    set addQuery({ key, payload }) {
      this[key] = payload;
    },
  },
  deleteBankDetails: {
    url: "members/fpo/members/bankaccount/",
    method: "delete",
    baseURL: "member",
    id: null,
    get api() {
      return this.url + this.id;
    },
    set addQuery({ key, payload }) {
      this[key] = payload;
    },
  },
  deleteHouseholdDetails: {
    url: "members/fpo/members/household/",
    method: "delete",
    baseURL: "member",
    id: null,
    get api() {
      return this.url + this.id;
    },
    set addQuery({ key, payload }) {
      this[key] = payload;
    },
  },
  deletefarmDetails: {
    url: "members/fpo/members/farminfo/",
    method: "delete",
    baseURL: "member",
    id: null,
    get api() {
      return this.url + this.id;
    },
    set addQuery({ key, payload }) {
      this[key] = payload;
    },
  },
  deleteMember: {
    url: "members/fpo/members/",
    method: "delete",
    baseURL: "member",
    id: null,
    get api() {
      return this.url + this.id;
    },
    set addQuery({ key, payload }) {
      this[key] = payload;
    },
  },
  getListOfMembersToSms: {
    api: "members/list/sms",
    method: "get",
    baseURL: "member",
  },
  getMessageDetails: {
    api: "fpo/sms/templates",
    method: "get",
    baseURL: "member",
  },
  getMessageDetailsByLanguage: {
    url: "fpo/sms/templates",
    method: "get",
    baseURL: "member",
    query: {
      language: "",
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  sendSmsForMember: {
    api: "members/send/sms",
    method: "post",
    baseURL: "member",
  },
  smsLanguage: {
    api: "fpo/sms/language",
    baseURL: "member",
    method: "get",
  },
  roboCallingApi: {
    api: "fpo/robocall",
    method: "get",
    baseURL: "member",
  },
  getListOfStaffToSms: {
    api: "fpo/management/staff/list/send/sms",
    method: "get",
    baseURL: "member",
  },
  sendSmsForStaff: {
    api: "fpo/management/staff/send/sms",
    method: "post",
    baseURL: "member",
  },
  getListOfBODToSms: {
    api: "fpo/management/bod/list/send/sms",
    method: "get",
    baseURL: "member",
  },
  sendSmsForBod: {
    api: "fpo/management/bod/send/sms",
    method: "post",
    baseURL: "member",
  },
  getRelationshipHistoryList: {
    api: "relationhistory",
    method: "get",
    baseURL: "event",
  },
  updateRelationshipHistory: {
    api: "relationhistory",
    method: "post",
    baseURL: "event",
  },
  getAllFig: {
    api: "fig",
    method: "get",
    baseURL: "member",
  },
  mapFigToMember: {
    api: "member-mapping",
    method: "post",
    baseURL: "member",
  },
};

export const roboCall = {
  getCallRecords: {
    api: "fpo/robocall/robocallAudio/tracksheet",
    method: "post",
    baseURL: "member",
  },
  sendRoboCallRequest: {
    api: "fpo/robocall/send/robocall/request",
    method: "post",
    baseURL: "member",
  },
  sendRoboCallAudio: {
    api: "fpo/robocall/robocallAudio",
    method: "post",
    baseURL: "member",
  },
  getRoboCallById: {
    api: "fpo/robocall/robocallAudio",
    method: "get",
    baseURL: "member",
  },
  sendToolTipById: {
    url: "fpo/management/tooltip/",
    method: "put",
    baseURL: "member",
    id: null,
    get api() {
      return this.url + this.id;
    },
    set addQuery({ key, payload }) {
      this[key] = payload;
    },
  },
  getToolById: {
    url: "fpo/management/fpo/tooltip/",
    method: "get",
    baseURL: "member",
    id: null,
    get api() {
      return this.url + this.id;
    },
  },
};

export const Bodandstaff = {
  addBod: {
    api: "fpo/management/bod/add",
    method: "post",
    baseURL: "member",
  },
  updateBod: {
    api: "fpo/management/bod/update",
    method: "put",
    baseURL: "member",
  },
  getAddressProofDropdown: {
    api: "fpo/management/list/address/proof/dropdown",
    method: "get",
    baseURL: "member",
  },
  getIdentityProofDropdown: {
    api: "fpo/management/list/identity/proof/dropdown",
    method: "get",
    baseURL: "member",
  },
  getEducationDropdown: {
    api: "fpo/management/list/education/qualification/dropdown",
    method: "get",
    baseURL: "member",
  },
  addStaff: {
    api: "fpo/management/staff/add",
    method: "post",
    baseURL: "member",
  },
  updateStaff: {
    api: "fpo/management/staff/update",
    method: "put",
    baseURL: "member",
  },
  getBodList: {
    url: "fpo/management/bod/all/list",
    method: "get",
    baseURL: "member",
    query: {
      limit: 4,
      page: 1,
      search: "",
      filter: null,
      filterType: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getStaffList: {
    url: "fpo/management/staff/all/list",
    method: "get",
    baseURL: "member",
    query: {
      limit: 4,
      page: 1,
      search: "",
      filter: null,
      filterType: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getBODById: {
    url: "fpo/management/bod/list/",
    method: "get",
    baseURL: "member",
    id: "",
    get api() {
      return this.url + this.id;
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getStaffById: {
    url: "fpo/management/staff/list/",
    method: "get",
    baseURL: "member",
    id: "",
    get api() {
      return this.url + this.id;
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getBulkUploadfile: {
    api: "fpo/management/fpo/download/sampleExcel",
    method: "get",
    baseURL: "member",
  },
  addBulkUpload: {
    api: "fpo/management/fpo/bulkUpload/members",
    method: "post",
    baseURL: "member",
  },
  getKYMBulkUploadfile: {
    api: "fpo/management/fpo/download/kym/sampleExcel",
    method: "get",
    baseURL: "member",
  },
  addKYMBulkUpload: {
    api: "fpo/management/fpo/bulkUpload/kymForm",
    method: "post",
    baseURL: "member",
  },
  deleteBOD: {
    url: "fpo/management/bod/",
    method: "delete",
    baseURL: "member",
    id: null,
    get api() {
      return this.url + this.id;
    },
    set addQuery({ key, payload }) {
      this[key] = payload;
    },
  },
  deleteStaff: {
    url: "fpo/management/staff/",
    method: "delete",
    baseURL: "member",
    id: null,
    get api() {
      return this.url + this.id;
    },
    set addQuery({ key, payload }) {
      this[key] = payload;
    },
  },
  getGradingDetail: {
    api: "fpo/management/fpo/grading",
    method: "get",
    baseURL: "member",
  },
  getGradingSurvey: {
    url: "barChart/barChart/",
    method: "get",
    baseURL: "member",
    ids: "",
    get api() {
      return this.url + this.ids;
    },
  },
  getGradingSurveyList: {
    api: "barChart/dropdownList",
    method: "get",
    baseURL: "member",
  },
};

export const Gateway = {
  getGateway: {
    api: "gatways",
    method: "get",
    baseURL: "gateway",
  },
  getBMS: {
    api: "gatways/agree/bms",
    method: "get",
    baseURL: "gateway",
  },
  updateBMS: {
    api: "gatways/agree/bms",
    method: "post",
    baseURL: "gateway",
  },
  updateTermsCondition: {
    api: "user/auth/updateTncStatus",
    method: "put",
    baseURL: "auth",
  },
};

export const Home = {
  getNewsAndTestimonals: {
    api: "content/fpo/home",
    method: "get",
    baseURL: "admin",
  },
  getAcademy: {
    api: "academy/course/home",
    method: "get",
    baseURL: "academy",
  },
  getSuccessStoryById: {
    url: "content/stories/stories/getById/",
    method: "get",
    baseURL: "admin",
    id: null,
    get api() {
      return this.url + this.id;
    },
    set addQuery({ key, payload }) {
      this[key] = payload;
    },
  },
  updateCalenderTask: {
    api: "fpo/task/update/taskstatus",
    method: "post",
    baseURL: "member",
  },
  complianceNotification: {
    api: "compliance/notification/task",
    method: "get",
    baseURL: "compliance",
  },
  getCalenderListPost: {
    url: "fpo/task/get",
    method: "get",
    baseURL: "member",
    query: {
      year: "",
      month: "",
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getComplianceCalender: {
    url: "compliance/notification/calendar/task",
    method: "get",
    baseURL: "compliance",
    query: {
      year: "",
      month: "",
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
};
export const PreLogin = {
  getNewsAndTestimonals: {
    api: "content/prelogin/list",
    method: "get",
    baseURL: "admin",
  },
  getFaqAndHeaderCount: {
    url: "fpo/prelogin",
    method: "get",
    baseURL: "member",
    query: {
      limit: 5,
      page: 1,
      search: "",
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getIntroVideo: {
    api: "academy/introvideo/user/getvideo",
    method: "get",
    baseURL: "academy",
  },
  getForumList: {
    api: "community/prelogin/list",
    method: "get",
    baseURL: "forum",
  },
  getGatewayList: {
    api: "gatways/prelogin",
    method: "get",
    baseURL: "gateway",
  },
  getBlogList: {
    api: "academy/prelogin/blog/latest",
    method: "get",
    baseURL: "academy",
  },
  getCoursesAndInformativeVideos: {
    api: "academy/prelogin/homepage/list",
    method: "get",
    baseURL: "academy",
  },
  getCalenderList: {
    url: "fpo/task/prelogin",
    method: "get",
    baseURL: "member",
    query: {
      year: "",
      month: "",
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
};

export const RmLogin = {
  getFpoList: {
    api: "fpo/management/rmmapping/fpolist",
    method: "get",
    baseURL: "member",
  },
  getAboutUsById: {
    url: "fpo/management/rmmapping/",
    method: "get",
    baseURL: "member",
    id: null,
    get api() {
      return this.url + this.id;
    },
    set addQuery({ key, payload }) {
      this[key] = payload;
    },
  },
  getComplianceForFPOById: {
    url: "compliance/rm/license/",
    method: "get",
    baseURL: "compliance",
    id: null,
    get api() {
      return this.url + this.id;
    },
    set addQuery({ key, payload }) {
      this[key] = payload;
    },
  },
  getEventsandImpactsOfFPOById: {
    url: "eventandimpact/get/events/story/awards/",
    method: "get",
    baseURL: "event",
    id: null,
    get api() {
      return this.url + this.id;
    },
    set addQuery({ key, payload }) {
      this[key] = payload;
    },
  },
  getCalenderListRm: {
    url: "fpo/task/rm",
    method: "get",
    baseURL: "member",
    query: {
      year: "",
      month: "",
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getCalenderFpoListRm: {
    url: "fpo/task/rm/fpoduelist",
    method: "get",
    baseURL: "member",
    query: {
      page: "1",
      limit: "3",
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getAssociationList: {
    url: "association/rm/",
    method: "get",
    baseURL: "member",
    id: null,
    query: {
      limit: 5,
      page: 1,
      search: "",
      filterBy: null,
      filterValue: null,
    },
    get api() {
      return this.url + this.id + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  downlaodAssociationandPartnersOfFPO: {
    url: "association/rm/download/csv/",
    method: "get",
    baseURL: "member",
    id: null,
    get api() {
      return this.url + this.id;
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getGradingDetailsOfFPO: {
    url: "fpo/management/grading/rm/",
    method: "get",
    baseURL: "member",
    id: null,
    get api() {
      return this.url + this.id;
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },

  //Key financials
  getAllKeyFinancialInfoRm: {
    url: "keyfinancial/rm/financials/",
    method: "get",
    baseURL: "keyfinancial",
    id: null,
    query: {
      limit: 4,
      page: 1,
      search: "",
      filterBy: null,
      filterValue: null,
    },
    get api() {
      return this.url + this.id + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },

  getAllCreditHistoryRm: {
    url: "keyfinancial/rm/credits/",
    method: "get",
    baseURL: "keyfinancial",
    id: null,
    query: {
      limit: 4,
      page: 1,
      search: "",
      filterBy: null,
      filterValue: null,
    },
    get api() {
      return this.url + this.id + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },

  getAllRevenueRm: {
    url: "keyfinancial/rm/revenue/",
    method: "get",
    baseURL: "keyfinancial",
    id: null,
    query: {
      limit: 4,
      page: 1,
      search: "",
      filterBy: null,
      filterValue: null,
    },
    get api() {
      return this.url + this.id + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },

  getAllPlanRm: {
    url: "keyfinancial/rm/businessdlpment/",
    method: "get",
    baseURL: "keyfinancial",
    id: null,
    get api() {
      return this.url + this.id;
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },

  //compliance
  getAllLicenseRm: {
    url: "compliance/rm/license/list/",
    method: "get",
    baseURL: "compliance",
    id: null,
    query: {
      limit: 5,
      page: 1,
      search: "",
      filterBy: null,
      filterValue: null,
    },
    get api() {
      return this.url + this.id + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },

  getAllGstRm: {
    url: "compliance/rm/gstinfo/",
    method: "get",
    baseURL: "compliance",
    id: null,
    query: {
      limit: 5,
      page: 1,
      search: "",
      filterBy: null,
      filterValue: null,
    },
    get api() {
      return this.url + this.id + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },

  getAllIncomeTdsRm: {
    url: "compliance/rm/incometds/",
    method: "get",
    baseURL: "compliance",
    id: null,
    query: {
      limit: 5,
      page: 1,
      search: "",
      filterBy: null,
      filterValue: null,
    },
    get api() {
      return this.url + this.id + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },

  getAllIncomeTaxRm: {
    url: "compliance/rm/incometax/",
    method: "get",
    baseURL: "compliance",
    id: null,
    query: {
      limit: 5,
      page: 1,
      search: "",
      filterBy: null,
      filterValue: null,
    },
    get api() {
      return this.url + this.id + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },

  getAllRegistrarRm: {
    url: "compliance/rm/registrar/",
    method: "get",
    baseURL: "compliance",
    id: null,
    query: {
      limit: 5,
      page: 1,
      search: "",
      filterBy: null,
      filterValue: null,
    },
    get api() {
      return this.url + this.id + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },

  getAllGstCompRm: {
    url: "compliance/rm/gstcomp/",
    method: "get",
    baseURL: "compliance",
    id: null,
    query: {
      limit: 5,
      page: 1,
      search: "",
      filterBy: null,
      filterValue: null,
    },
    get api() {
      return this.url + this.id + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },

  getAllAgmRm: {
    url: "compliance/rm/agminfo/",
    method: "get",
    baseURL: "compliance",
    id: null,
    query: {
      limit: 5,
      page: 1,
      search: "",
    },
    get api() {
      return this.url + this.id + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },

  getAllBodRm: {
    url: "compliance/rm/bodinfo/",
    method: "get",
    baseURL: "compliance",
    id: null,
    query: {
      limit: 5,
      page: 1,
      search: "",
    },
    get api() {
      return this.url + this.id + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },

  //members
  getMembersCardApiRm: {
    url: "fpo/management/rmmapping/members/",
    method: "get",
    baseURL: "member",
    id: null,
    query: {
      limit: 4,
      page: 1,
      search: "",
      filterBy: null,
      filterValue: null,
    },
    get api() {
      return this.url + this.id + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },

  //BOD
  getBodListRm: {
    url: "fpo/management/rmmapping/bod/",
    method: "get",
    baseURL: "member",
    id: null,
    query: {
      limit: 4,
      page: 1,
      search: "",
      filter: null,
      filterType: null,
    },
    get api() {
      return this.url + this.id + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },

  //Staff
  getStaffListRm: {
    url: "fpo/management/rmmapping/staff/",
    method: "get",
    baseURL: "member",
    id: null,
    query: {
      limit: 4,
      page: 1,
      search: "",
      filter: null,
      filterType: null,
    },
    get api() {
      return this.url + this.id + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getDocumentsOfFPO: {
    url: "fpo/management/rm/",
    method: "get",
    baseURL: "member",
    id: null,
    tabName: null,
    get api() {
      return this.url + this.tabName + "/view/" + this.id;
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getCompanyKycBulkdownloadOfFpo: {
    url: "fpo/management/rm/fpo/companykyc/download",
    method: "get",
    baseURL: "member",
    query: {
      fpoId: null,
      docId: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getFinancialBulkdownloadOfFpo: {
    url: "fpo/management/rm/fpo/financial/download",
    method: "get",
    baseURL: "member",
    query: {
      fpoId: null,
      docId: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getDirectdocBulkdownloadOfFpo: {
    url: "fpo/management/rm/fpo/directdoc/download",
    method: "get",
    baseURL: "member",
    query: {
      fpoId: null,
      docId: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getcreditdataBulkdownloadOfFpo: {
    url: "fpo/management/rm/fpo/creditdata/download",
    method: "get",
    baseURL: "member",
    query: {
      fpoId: null,
      docId: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getstatutoryBulkdownloadOfFpo: {
    url: "fpo/management/rm/fpo/statutory/download",
    method: "get",
    baseURL: "member",
    query: {
      fpoId: null,
      docId: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getAllFpoEventsandImpactForRm: {
    url: "fpo/management/rm/fpo/statutory/download",
    method: "get",
    baseURL: "member",
    query: {
      fpoId: null,
      docId: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getGatewayForRm: {
    api: "gatways/rm",
    method: "get",
    baseURL: "gateway",
  },
  getMemberProfileCsvofFPO: {
    url: "fpo/management/rm/member/list/download/csv/",
    method: "get",
    baseURL: "member",
    id: null,
    get api() {
      return this.url + this.id;
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getBodProfileCsvofFPO: {
    url: "fpo/management/rm/bod/list/download/csv/",
    method: "get",
    baseURL: "member",
    id: null,
    get api() {
      return this.url + this.id;
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getStaffProfileCsvofFPO: {
    url: "fpo/management/rm/staff/list/download/csv/",
    method: "get",
    baseURL: "member",
    id: null,
    get api() {
      return this.url + this.id;
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getRelationshipHistoryListofFpo: {
    url: "relationhistory/",
    method: "get",
    baseURL: "event",
    id: null,
    get api() {
      return this.url + this.id;
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  downloadcomplianceCsv: {
    url: "compliance/rm/",
    method: "get",
    baseURL: "compliance",
    id: null,
    tabName: null,
    get api() {
      return this.url + this.tabName + "/csv/" + this.id;
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  downloadKeyFinancialCsv: {
    url: "keyfinancial/rm/",
    method: "get",
    baseURL: "keyfinancial",
    id: null,
    tabName: null,
    get api() {
      return this.url + this.tabName + "/csv/" + this.id;
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  bannerApiForRm: {
    api: "fpo/rm/banner/count",
    method: "get",
    baseURL: "member",
  },
  getBODByIdForRM: {
    url: "fpo/management/rm/bod/",
    method: "get",
    baseURL: "member",
    id: null,
    fpoId: null,
    get api() {
      return this.url + this.fpoId + "/" + this.id;
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getStaffByIdForRM: {
    url: "fpo/management/rm/staff/",
    method: "get",
    baseURL: "member",
    id: null,
    fpoId: null,
    get api() {
      return this.url + this.fpoId + "/" + this.id;
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getkeyfinanciallistdataById: {
    url: "financial/analysis/rm/",
    method: "get",
    baseURL: "keyfinancial",
    id: null,
    get api() {
      return this.url + this.id;
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getRoleAnalysisdataById: {
    url: "financial/analysis/rm/ratio/",
    method: "get",
    baseURL: "keyfinancial",
    id: null,
    get api() {
      return this.url + this.id;
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getperformancedataById: {
    url: "financial/analysis/rm/performance/",
    method: "get",
    baseURL: "keyfinancial",
    id: null,
    get api() {
      return this.url + this.id;
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
};

export const Documemts = {
  addFpoDocuments: {
    url: "fpo/management/fpo/",
    method: "post",
    baseURL: "member",
    tabName: null,
    get api() {
      return this.url + this.tabName + "/document";
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getMasterData: {
    api: "fpo/management/fpo/financialMaster",
    method: "get",
    baseURL: "member",
  },
  viewFpoDocuments: {
    url: "fpo/management/fpo/",
    method: "get",
    baseURL: "member",
    tabName: null,
    get api() {
      return this.url + this.tabName + "/view";
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  deleteFpoDocuments: {
    url: "fpo/management/fpo/",
    method: "put",
    baseURL: "member",
    tabName: null,
    get api() {
      return this.url + this.tabName + "/delete";
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getCompanyKycBulkdownload: {
    url: "fpo/management/fpo/companykyc/download",
    method: "get",
    baseURL: "member",
    id: null,
    get api() {
      if (this.id !== null) {
        return this.url + "/" + this.id;
      } else {
        return this.url;
      }
    },
    set addQuery({ key, payload }) {
      this[key] = payload;
    },
  },
  getFinancialBulkdownload: {
    url: "fpo/management/fpo/financial/download",
    method: "get",
    baseURL: "member",
    id: null,
    get api() {
      if (this.id !== null) {
        return this.url + "/" + this.id;
      } else {
        return this.url;
      }
    },
    set addQuery({ key, payload }) {
      this[key] = payload;
    },
  },
  getDirectdocBulkdownload: {
    url: "fpo/management/fpo/directdoc/download",
    method: "get",
    baseURL: "member",
    id: null,
    get api() {
      if (this.id !== null) {
        return this.url + "/" + this.id;
      } else {
        return this.url;
      }
    },
    set addQuery({ key, payload }) {
      this[key] = payload;
    },
  },
  getcreditdataBulkdownload: {
    url: "fpo/management/fpo/creditdata/download",
    method: "get",
    baseURL: "member",
    id: null,
    get api() {
      if (this.id !== null) {
        return this.url + "/" + this.id;
      } else {
        return this.url;
      }
    },
    set addQuery({ key, payload }) {
      this[key] = payload;
    },
  },
  getstatutoryBulkdownload: {
    url: "fpo/management/fpo/statutory/download",
    method: "get",
    baseURL: "member",
    id: null,
    get api() {
      if (this.id !== null) {
        return this.url + "/" + this.id;
      } else {
        return this.url;
      }
    },
    set addQuery({ key, payload }) {
      this[key] = payload;
    },
  },
  statutoryEdit: {
    api: "fpo/management/fpo/statutory/document",
    method: "put",
    baseURL: "member",
  },
  companyKYCEdit: {
    api: "fpo/management/fpo/companykyc/document",
    method: "put",
    baseURL: "member",
  },
  directDocEdit: {
    api: "fpo/management/fpo/direct/document",
    method: "put",
    baseURL: "member",
  },
  financialEdit: {
    api: "fpo/management/fpo/financial/document",
    method: "put",
    baseURL: "member",
  },
  creditDataEdit: {
    api: "fpo/management/fpo/credit/document",
    method: "put",
    baseURL: "member",
  },
};

export const Compliance = {
  getMonthdata: {
    api: "compliance/getmasters",
    method: "get",
    baseURL: "compliance",
  },
  addLicense: {
    api: "compliance/license",
    method: "post",
    baseURL: "compliance",
  },
  updateLicense: {
    api: "compliance/license",
    method: "put",
    baseURL: "compliance",
  },
  deleteLicense: {
    url: "compliance/license/",
    method: "delete",
    baseURL: "compliance",
    id: null,
    get api() {
      return this.url + this.id;
    },
    set addQuery({ key, payload }) {
      this[key] = payload;
    },
  },
  getLicenseById: {
    url: "compliance/license/id/",
    method: "get",
    baseURL: "compliance",
    id: null,
    get api() {
      return this.url + this.id;
    },
    set addQuery({ key, payload }) {
      this[key] = payload;
    },
  },
  getLicenseByIdForView: {
    url: "compliance/license/",
    method: "get",
    baseURL: "compliance",
    id: null,
    get api() {
      return this.url + this.id;
    },
    set addQuery({ key, payload }) {
      this[key] = payload;
    },
  },
  getAllLicense: {
    url: "compliance/license",
    method: "get",
    baseURL: "compliance",
    query: {
      limit: 5,
      page: 1,
      search: "",
      filterBy: null,
      filterValue: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },

  addIncomeTds: {
    api: "compliance/incometds",
    method: "post",
    baseURL: "compliance",
  },

  updateIncomeTds: {
    api: "compliance/incometds",
    method: "put",
    baseURL: "compliance",
  },
  deleteIncomeTds: {
    url: "compliance/incometds/",
    method: "delete",
    baseURL: "compliance",
    id: null,
    get api() {
      return this.url + this.id;
    },
    set addQuery({ key, payload }) {
      this[key] = payload;
    },
  },
  getAllIncomeTds: {
    url: "compliance/incometds",
    method: "get",
    baseURL: "compliance",
    query: {
      limit: 5,
      page: 1,
      search: "",
      filterBy: null,
      filterValue: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getIncomeTdsById: {
    url: "compliance/incometds/id/",
    method: "get",
    baseURL: "compliance",
    id: null,
    get api() {
      return this.url + this.id;
    },
    set addQuery({ key, payload }) {
      this[key] = payload;
    },
  },
  addIncomeTax: {
    api: "compliance/incometax",
    method: "post",
    baseURL: "compliance",
  },
  updateIncomeTax: {
    api: "compliance/incometax",
    method: "put",
    baseURL: "compliance",
  },
  deleteIncomeTax: {
    url: "compliance/incometax/",
    method: "delete",
    baseURL: "compliance",
    id: null,
    get api() {
      return this.url + this.id;
    },
    set addQuery({ key, payload }) {
      this[key] = payload;
    },
  },
  getAllIncomeTax: {
    url: "compliance/incometax",
    method: "get",
    baseURL: "compliance",
    query: {
      limit: 5,
      page: 1,
      search: "",
      filterBy: null,
      filterValue: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getIncomeTaxById: {
    url: "compliance/incometax/id/",
    method: "get",
    baseURL: "compliance",
    id: null,
    get api() {
      return this.url + this.id;
    },
    set addQuery({ key, payload }) {
      this[key] = payload;
    },
  },
  addRegistrar: {
    api: "compliance/registrar",
    method: "post",
    baseURL: "compliance",
  },
  updateRegistrar: {
    api: "compliance/registrar",
    method: "put",
    baseURL: "compliance",
  },
  deleteRegistrar: {
    url: "compliance/registrar/",
    method: "delete",
    baseURL: "compliance",
    id: null,
    get api() {
      return this.url + this.id;
    },
    set addQuery({ key, payload }) {
      this[key] = payload;
    },
  },
  getAllRegistrar: {
    url: "compliance/registrar",
    method: "get",
    baseURL: "compliance",
    query: {
      limit: 5,
      page: 1,
      search: "",
      filterBy: null,
      filterValue: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getRegistrarById: {
    url: "compliance/registrar/id/",
    method: "get",
    baseURL: "compliance",
    id: null,
    get api() {
      return this.url + this.id;
    },
    set addQuery({ key, payload }) {
      this[key] = payload;
    },
  },
  addGst: {
    api: "compliance/gstinfo",
    method: "post",
    baseURL: "compliance",
  },
  updateGst: {
    api: "compliance/gstinfo",
    method: "put",
    baseURL: "compliance",
  },
  deleteGst: {
    url: "compliance/gstinfo/",
    method: "delete",
    baseURL: "compliance",
    id: null,
    get api() {
      return this.url + this.id;
    },
    set addQuery({ key, payload }) {
      this[key] = payload;
    },
  },
  getAllGst: {
    url: "compliance/gstinfo",
    method: "get",
    baseURL: "compliance",
    query: {
      limit: 5,
      page: 1,
      search: "",
      filterBy: null,
      filterValue: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getGstById: {
    url: "compliance/gstinfo/id/",
    method: "get",
    baseURL: "compliance",
    id: null,
    get api() {
      return this.url + this.id;
    },
    set addQuery({ key, payload }) {
      this[key] = payload;
    },
  },
  addGstComp: {
    api: "compliance/gstcomp",
    method: "post",
    baseURL: "compliance",
  },
  updateGstComp: {
    api: "compliance/gstcomp",
    method: "put",
    baseURL: "compliance",
  },
  deleteGstComp: {
    url: "compliance/gstcomp/",
    method: "delete",
    baseURL: "compliance",
    id: null,
    get api() {
      return this.url + this.id;
    },
    set addQuery({ key, payload }) {
      this[key] = payload;
    },
  },
  getAllGstComp: {
    url: "compliance/gstcomp",
    method: "get",
    baseURL: "compliance",
    query: {
      limit: 5,
      page: 1,
      search: "",
      filterBy: null,
      filterValue: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getGstCompById: {
    url: "compliance/gstcomp/id/",
    method: "get",
    baseURL: "compliance",
    id: null,
    get api() {
      return this.url + this.id;
    },
    set addQuery({ key, payload }) {
      this[key] = payload;
    },
  },
  addAgm: {
    api: "compliance/agminfo",
    method: "post",
    baseURL: "compliance",
  },
  updateAgm: {
    api: "compliance/agminfo",
    method: "put",
    baseURL: "compliance",
  },
  deleteAgm: {
    url: "compliance/agminfo/",
    method: "delete",
    baseURL: "compliance",
    id: null,
    get api() {
      return this.url + this.id;
    },
    set addQuery({ key, payload }) {
      this[key] = payload;
    },
  },
  getAllAgm: {
    url: "compliance/agminfo",
    method: "get",
    baseURL: "compliance",
    query: {
      limit: 5,
      page: 1,
      search: "",
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getAgmById: {
    url: "compliance/agminfo/id/",
    method: "get",
    baseURL: "compliance",
    id: null,
    get api() {
      return this.url + this.id;
    },
    set addQuery({ key, payload }) {
      this[key] = payload;
    },
  },
  addBod: {
    api: "compliance/bodinfo",
    method: "post",
    baseURL: "compliance",
  },
  updateBod: {
    api: "compliance/bodinfo",
    method: "put",
    baseURL: "compliance",
  },
  deleteBod: {
    url: "compliance/bodinfo/",
    method: "delete",
    baseURL: "compliance",
    id: null,
    get api() {
      return this.url + this.id;
    },
    set addQuery({ key, payload }) {
      this[key] = payload;
    },
  },
  getAllBod: {
    url: "compliance/bodinfo",
    method: "get",
    baseURL: "compliance",
    query: {
      limit: 5,
      page: 1,
      search: "",
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getBodById: {
    url: "compliance/bodinfo/id/",
    method: "get",
    baseURL: "compliance",
    id: null,
    get api() {
      return this.url + this.id;
    },
    set addQuery({ key, payload }) {
      this[key] = payload;
    },
  },

  getAllMasters: {
    api: "compliance/getmasters",
    method: "get",
    baseURL: "compliance",
  },
  getAllLicenseAndFinance: {
    api: "compliance/licensedropdown",
    method: "get",
    baseURL: "compliance",
  },
  getAllFinancialYearDropdown: {
    api: "fpo/management/fpo/financialMaster",
    method: "get",
    baseURL: "member",
  },
  downloadComplianceCsvForFPO: {
    url: "compliance/",
    method: "get",
    baseURL: "compliance",
    tabName: null,
    get api() {
      return this.url + this.tabName + "/csv";
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getLicenseData: {
    api: "compliance/license/aboutus",
    method: "get",
    baseURL: "compliance",
  },
  getLicenseDataWithId: {
    url: "compliance/license/fpo/aboutus/",
    method: "get",
    baseURL: "compliance",
    id: null,
    get api() {
      return this.url + this.id;
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
};

export const Sellrequest = {
  getcommodityList: {
    url: "bms/list/commodityList",
    method: "get",
    baseURL: "sellrequest",
    query: {},
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getfponameList: {
    url: "bms/list/impactDashboard/fpoList",
    method: "get",
    baseURL: "sellrequest",
    query: {},
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getFpoDetails: {
    url: "user/fpo/details",
    method: "get",
    baseURL: "sellrequest",
    query: {},
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getfpoStateList: {
    url: "user/fpo/state/list",
    method: "get",
    baseURL: "sellrequest",
    query: {},
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getfpoDistrictList: {
    url: "user/fpo/district/list",
    method: "get",
    baseURL: "sellrequest",
    id: null,
    query: {
      commodityName: null,
    },
    get api() {
      if (this.id) {
        return this.url + "/" + this.id + generateQuery(this.query);
      } else {
        return this.url;
      }
    },
    set addQuery({ key, payload }) {
      this[key] = payload;
    },
  },
  getMarketCentreList: {
    api: "user/fpo/marketcentre/list",
    method: "post",
    baseURL: "sellrequest",
  },
  getMarketCentreListRM: {
    api: "bms/sellRequestRecommandBuyer/mandiPrice",
    method: "post",
    baseURL: "sellrequest",
  },
  sellRequestList: {
    api: "user/fpo/sellRequest/list",
    method: "post",
    baseURL: "sellrequest",
  },
  getfpocommodityList: {
    url: "user/fpo/commodity/list",
    method: "get",
    baseURL: "sellrequest",
    query: {},
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getrmcommodityList: {
    url: "bms/list/impactDashboard/commodityList",
    method: "get",
    baseURL: "sellrequest",
    query: {},
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getfpomeasurementList: {
    url: "user/fpo/measurement/list",
    method: "get",
    baseURL: "sellrequest",
    query: {},
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getfpoIndustryist: {
    url: "user/fpo/industry/list",
    method: "get",
    baseURL: "sellrequest",
    query: {},
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getlist: {
    api: "bms/list/buyerComparsion",
    method: "post",
    baseURL: "sellrequest",
  },
  addRequest: {
    api: "user/fpo/sellRequest",
    method: "post",
    baseURL: "sellrequest",
  },
  addRecommentForm: {
    api: "bms/sellRequestRecommandBuyer",
    method: "post",
    baseURL: "sellrequest",
  },
  sellRequestDetail: {
    url: "user/fpo/sellRequest",
    method: "get",
    baseURL: "sellrequest",
    id: null,
    get api() {
      if (this.id) {
        return this.url + "/" + this.id;
      } else {
        return this.url;
      }
    },
    set addQuery({ key, payload }) {
      this[key] = payload;
    },
  },
  fileUpload: {
    api: "documents/file/upload",
    method: "post",
    baseURL: "file",
  },
  getStageTwoList: {
    url: "bms/sellRequestRecommandBuyer/list",
    method: "get",
    baseURL: "sellrequest",
    id: null,
    get api() {
      if (this.id) {
        return this.url + "/" + this.id;
      } else {
        return this.url;
      }
    },
    set addQuery({ key, payload }) {
      this[key] = payload;
    },
  },
  stageTwoAction: {
    api: "bms/update/recommandBuyerStatus",
    method: "post",
    baseURL: "sellrequest",
  },
  dashboardImpact: {
    api: "bms/list/impactDashboard",
    method: "post",
    baseURL: "sellrequest",
  },
};

export const fpoSearch = {
  getAllFpoUser: {
    url: "fpo/management/fpo/search",
    method: "get",
    baseURL: "member",
    query: {
      search: "",
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
};

export const KeyFinancial = {
  getAllKeyFinancialInfo: {
    url: "keyfinancial/financials/allfinancials",
    method: "get",
    baseURL: "keyfinancial",
    query: {
      limit: 4,
      page: 1,
      search: "",
      filterBy: null,
      filterValue: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getKeyFinancialInfoById: {
    url: "keyfinancial/financials/prefill/",
    method: "get",
    baseURL: "keyfinancial",
    id: null,
    get api() {
      return this.url + this.id;
    },
    set addQuery({ key, payload }) {
      this[key] = payload;
    },
  },
  addKeyFinancialInfo: {
    api: "keyfinancial/financials/add",
    method: "post",
    baseURL: "keyfinancial",
  },
  updateKeyFinancialInfo: {
    api: "keyfinancial/financials/update",
    method: "put",
    baseURL: "keyfinancial",
  },
  deleteKeyFinancialInfo: {
    url: "keyfinancial/financials/delete/",
    method: "delete",
    baseURL: "keyfinancial",
    id: null,
    get api() {
      return this.url + this.id;
    },
    set addQuery({ key, payload }) {
      this[key] = payload;
    },
  },
  getAllCreditHistory: {
    url: "keyfinancial/credits/allcredits",
    method: "get",
    baseURL: "keyfinancial",
    query: {
      limit: 4,
      page: 1,
      search: "",
      filterBy: null,
      filterValue: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getCreditHistoryById: {
    url: "keyfinancial/credits/prefill/",
    method: "get",
    baseURL: "keyfinancial",
    id: null,
    get api() {
      return this.url + this.id;
    },
    set addQuery({ key, payload }) {
      this[key] = payload;
    },
  },
  addCreditHistory: {
    api: "keyfinancial/credits/add",
    method: "post",
    baseURL: "keyfinancial",
  },
  deleteCreditHistory: {
    url: "keyfinancial/credits/delete/",
    method: "delete",
    baseURL: "keyfinancial",
    id: null,
    get api() {
      return this.url + this.id;
    },
    set addQuery({ key, payload }) {
      this[key] = payload;
    },
  },
  updateCreditHistory: {
    api: "keyfinancial/credits/update",
    method: "put",
    baseURL: "keyfinancial",
  },
  getAllFinancialInstitution: {
    api: "keyfinancial/institutions",
    method: "get",
    baseURL: "keyfinancial",
  },

  getAllRevenue: {
    url: "keyfinancial/revenue/allrevenue",
    method: "get",
    baseURL: "keyfinancial",
    query: {
      limit: 4,
      page: 1,
      search: "",
      filterBy: null,
      filterValue: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getRevenueById: {
    url: "keyfinancial/revenue/prefill/",
    method: "get",
    baseURL: "keyfinancial",
    id: null,
    get api() {
      return this.url + this.id;
    },
    set addQuery({ key, payload }) {
      this[key] = payload;
    },
  },
  addRevenue: {
    api: "keyfinancial/revenue/add",
    method: "post",
    baseURL: "keyfinancial",
  },
  deleteRevenue: {
    url: "keyfinancial/revenue/delete/",
    method: "delete",
    baseURL: "keyfinancial",
    id: null,
    get api() {
      return this.url + this.id;
    },
    set addQuery({ key, payload }) {
      this[key] = payload;
    },
  },
  updateRevenue: {
    api: "keyfinancial/revenue/update",
    method: "put",
    baseURL: "keyfinancial",
  },

  getAllActivity: {
    url: "keyfinancial/revenue/activitys/",
    method: "get",
    baseURL: "keyfinancial",
    id: "",
    get api() {
      return this.url + this.id;
    },
    set addQuery({ key, payload }) {
      this[key] = payload;
    },
  },
  getAllFinancialYear: {
    api: "keyfinancial/fyears",
    method: "get",
    baseURL: "keyfinancial",
  },

  getAllPlan: {
    api: "keyfinancial/businessdlpment/allbusinessdlpment",
    method: "get",
    baseURL: "keyfinancial",
  },
  getPlanById: {
    url: "keyfinancial/businessdlpment/prefill/",
    method: "get",
    baseURL: "keyfinancial",
    id: null,
    get api() {
      return this.url + this.id;
    },
    set addQuery({ key, payload }) {
      this[key] = payload;
    },
  },
  addPlan: {
    api: "keyfinancial/businessdlpment/add",
    method: "post",
    baseURL: "keyfinancial",
  },
  updatePlan: {
    api: "keyfinancial/businessdlpment/update",
    method: "put",
    baseURL: "keyfinancial",
  },
  deletePlan: {
    url: "keyfinancial/businessdlpment/delete/",
    method: "delete",
    baseURL: "keyfinancial",
    id: null,
    get api() {
      return this.url + this.id;
    },
    set addQuery({ key, payload }) {
      this[key] = payload;
    },
  },
  downloadKeyFinancialCsvForFpo: {
    url: "keyfinancial/",
    method: "get",
    baseURL: "keyfinancial",
    tabName: null,
    get api() {
      return this.url + this.tabName + "/download/csv";
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getkeyfinanciallistdata: {
    api: "financial/analysis",
    method: "get",
    baseURL: "keyfinancial",
  },
  getRoleAnalysisdata: {
    api: "financial/analysis/ratio",
    method: "get",
    baseURL: "keyfinancial",
  },
  getperformancedata: {
    api: "financial/analysis/performance",
    method: "get",
    baseURL: "keyfinancial",
  },
  getBankDetailById: {
    url: "keyfinancial/bank/",
    method: "get",
    baseURL: "keyfinancial",
    id: null,
    get api() {
      return this.url + this.id;
    },
    set addQuery({ key, payload }) {
      this[key] = payload;
    },
  },
  addBankDetail: {
    api: "keyfinancial/bank",
    method: "post",
    baseURL: "keyfinancial",
  },
  deleteBankDetail: {
    url: "keyfinancial/bank/",
    method: "delete",
    baseURL: "keyfinancial",
    id: null,
    get api() {
      return this.url + this.id;
    },
    set addQuery({ key, payload }) {
      this[key] = payload;
    },
  },
  updateBankDetail: {
    url: "keyfinancial/bank/",
    method: "put",
    baseURL: "keyfinancial",
    id: null,
    get api() {
      return this.url + this.id;
    },
    set addQuery({ key, payload }) {
      this[key] = payload;
    },
  },
  getAllBankDetails: {
    url: "keyfinancial/bank",
    method: "get",
    baseURL: "keyfinancial",
    query: {
      limit: 4,
      page: 1,
      search: "",
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
};

export const Association = {
  addAssociation: {
    api: "association/add",
    method: "post",
    baseURL: "member",
  },
  updateAssociation: {
    api: "association/update",
    method: "put",
    baseURL: "member",
  },
  deleteAssociation: {
    url: "association/delete/",
    method: "delete",
    baseURL: "member",
    id: null,
    get api() {
      return this.url + this.id;
    },
    set addQuery({ key, payload }) {
      this[key] = payload;
    },
  },
  getAllInstitutiontypes: {
    api: "association/institutiontypes",
    method: "get",
    baseURL: "member",
  },
  getAllAssociationtypes: {
    api: "association/associationtypes",
    method: "get",
    baseURL: "member",
  },
  getAllAssociations: {
    url: "association/allassociations",
    method: "get",
    baseURL: "member",
    query: {
      limit: 5,
      page: 1,
      search: "",
      filterBy: null,
      filterValue: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getAssociationById: {
    url: "association/association/",
    method: "get",
    baseURL: "member",
    id: null,
    get api() {
      return this.url + this.id;
    },
    set addQuery({ key, payload }) {
      this[key] = payload;
    },
  },
  getAssociationCsv: {
    api: "association/download/csv",
    method: "get",
    baseURL: "member",
  },
};

export const Faq = {
  getAllFaqs: {
    url: "faq/web/allquestions",
    method: "get",
    baseURL: "member",
    query: {
      limit: 5,
      page: 1,
      search: "",
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
};

export const Feedback = {
  addFeedback: {
    api: "feedback/create",
    method: "post",
    baseURL: "member",
  },
};

export const academyApi = {
  mylearnings: {
    url: "academy/mylearnings/",
    method: "get",
    baseURL: "academy",
    id: null,
    get api() {
      if (this.id) {
        return this.url + "/" + this.id;
      } else {
        return this.url;
      }
    },
    set addQuery({ key, payload }) {
      this[key] = payload;
    },
  },
  myFavorities: {
    api: "academy/favourites/list",
    method: "get",
    baseURL: "academy",
  },
  myFavoritiesForVideos: {
    api: "academy/trendingvideo/favourite/list",
    method: "get",
    baseURL: "academy",
  },
  myFavoritiesForBlog: {
    api: "academy/blog/favourite/list",
    method: "get",
    baseURL: "academy",
  },
  onGoingUpdate: {
    api: "academy/mylearnings",
    method: "post",
    baseURL: "academy",
  },
  courseList: {
    url: "academy/course",
    method: "get",
    baseURL: "academy",
    id: null,
    get api() {
      if (this.id) {
        return this.url + "/" + this.id;
      } else {
        return this.url;
      }
    },
    set addQuery({ key, payload }) {
      this[key] = payload;
    },
  },
  getViewersCountForChapters: {
    url: "academy/course/chapters/viewers/count",
    method: "get",
    baseURL: "academy",
    id: null,
    get api() {
      if (this.id) {
        return this.url + "/" + this.id;
      } else {
        return this.url;
      }
    },
    set addQuery({ key, payload }) {
      this[key] = payload;
    },
  },
  quizList: {
    url: "academy/course/quiz",
    method: "get",
    baseURL: "academy",
    id: null,
    get api() {
      if (this.id) {
        return this.url + "/" + this.id;
      } else {
        return this.url;
      }
    },
    set addQuery({ key, payload }) {
      this[key] = payload;
    },
  },
  retakeQuiz: {
    url: "academy/course/quiz/retake",
    method: "get",
    baseURL: "academy",
    id: null,
    get api() {
      return this.url + "/" + this.id;
    },
    set addQuery({ key, payload }) {
      this[key] = payload;
    },
  },
  quizAnswer: {
    url: "academy/course/quiz/answer",
    method: "post",
    baseURL: "academy",
    id: null,
    get api() {
      if (this.id) {
        return this.url + "/" + this.id;
      } else {
        return this.url;
      }
    },
    set addQuery({ key, payload }) {
      this[key] = payload;
    },
  },
  academyHomePage: {
    api: "academy/home",
    method: "get",
    baseURL: "academy",
  },
  intoducingVideo: {
    api: "academy/introvideo/user/getvideo",
    method: "get",
    baseURL: "academy",
  },
  academyExplore: {
    api: "academy/category/list/web",
    method: "get",
    baseURL: "academy",
  },
  academyExploreById: {
    url: "academy/course/getcourse",
    method: "get",
    baseURL: "academy",
    query: {
      filterValue: "",
      search: "",
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getEventNews: {
    api: "content/fpo/home",
    method: "get",
    baseURL: "admin",
  },
  addfaviorites: {
    api: "academy/favourites/add",
    method: "post",
    baseURL: "academy",
  },
  deletefaviorites: {
    url: "academy/favourites/remove",
    method: "delete",
    baseURL: "academy",
    chapterId: null,
    get api() {
      return this.url + "/" + this.chapterId;
    },
    set addQuery({ key, payload }) {
      this[key] = payload;
    },
  },
  getRecentViewList: {
    url: "academy/course/chapters/viewers/list",
    method: "get",
    baseURL: "academy",
    courseId: null,
    get api() {
      return this.url + "/" + this.courseId;
    },
    set addQuery({ key, payload }) {
      this[key] = payload;
    },
  },
  addReview: {
    api: "academy/review/add",
    method: "post",
    baseURL: "academy",
  },
  getReviewList: {
    url: "academy/review/list/",
    method: "get",
    baseURL: "academy",
    id: null,
    query: {
      limit: 4,
      page: 1,
    },
    get api() {
      return this.url + this.id + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  addReviewForBlog: {
    api: "academy/blog/review/add",
    method: "post",
    baseURL: "academy",
  },
  getReviewListForBlog: {
    url: "academy/blog/review/list/",
    method: "get",
    baseURL: "academy",
    id: null,
    query: {
      limit: 4,
      page: 1,
    },
    get api() {
      return this.url + this.id + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getRelatedBlogs: {
    url: "academy/blog/web/other/blogs?blogId=",
    method: "get",
    baseURL: "academy",
    blogId: null,
    categoryId: null,
    get api() {
      return this.url + this.blogId + "&categoryId=" + this.categoryId;
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getAllBlogsList: {
    url: "academy/blog/viewall/list",
    method: "get",
    baseURL: "academy",
    query: {
      limit: 6,
      page: 1,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getAllTrendingVideosList: {
    url: "academy/trendingvideo/viewall/list",
    method: "get",
    baseURL: "academy",
    query: {
      limit: 9,
      page: 1,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getAllCourseList: {
    url: "academy/course/viewall/list",
    method: "get",
    baseURL: "academy",
    query: {
      limit: 9,
      page: 1,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getUserProfileDetails: {
    api: "user/get/userprofile",
    method: "get",
    baseURL: "auth",
  },
  updateProfilePicture: {
    api: "user/update/userProfile",
    method: "put",
    baseURL: "auth",
  },
  infomativeDetailApi: {
    url: "academy/trendingvideo/list/web",
    method: "get",
    baseURL: "academy",
    id: null,
    get api() {
      return this.url + "/" + this.id;
    },
    set addQuery({ key, payload }) {
      this[key] = payload;
    },
  },
  addfavouriteVideo: {
    api: "academy/trendingvideo/favourite/add",
    method: "post",
    baseURL: "academy",
  },
  deletefavouriteVideo: {
    url: "academy/trendingvideo/favourite/remove",
    method: "delete",
    baseURL: "academy",
    videoId: null,
    get api() {
      return this.url + "/" + this.videoId;
    },
    set addQuery({ key, payload }) {
      this[key] = payload;
    },
  },
  addfavouriteBlog: {
    api: "academy/blog/favourite/add",
    method: "post",
    baseURL: "academy",
  },
  deletefavouriteBlog: {
    url: "academy/blog/favourite/remove",
    method: "delete",
    baseURL: "academy",
    id: null,
    get api() {
      return this.url + "/" + this.id;
    },
    set addQuery({ key, payload }) {
      this[key] = payload;
    },
  },
  addReviewForVideo: {
    api: "academy/trendingvideo/review/add",
    method: "post",
    baseURL: "academy",
  },
  relatedTrendingVideo: {
    url: "academy/trendingvideo/related/video",
    method: "get",
    baseURL: "academy",
    id: null,
    get api() {
      return this.url + "/" + this.id;
    },
    set addQuery({ key, payload }) {
      this[key] = payload;
    },
  },
  getUserListForShareForCourse: {
    url: "user/list/search",
    method: "get",
    baseURL: "auth",
    query: {
      search: "",
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  academyShare: {
    api: "academy/share/notification",
    method: "post",
    baseURL: "academy",
  },
};

export const blogApi = {
  blogList: {
    api: "academy/blog/web/list",
    method: "get",
    baseURL: "academy",
  },
  blogDetail: {
    url: "academy/blog/web/list",
    method: "get",
    baseURL: "academy",
    id: null,
    get api() {
      if (this.id) {
        return this.url + "/" + this.id;
      } else {
        return this.url;
      }
    },
    set addQuery({ key, payload }) {
      this[key] = payload;
    },
  },
};

export const eventsAndImpactStories = {
  getFpoAllEventsByUser: {
    url: "eventandimpact/get/allstories",
    method: "get",
    baseURL: "event",
    id: null,
    get api() {
      if (this.id) {
        return this.url + "/" + this.id;
      } else {
        return this.url;
      }
    },
  },
  getAllFpoEventsandImpact: {
    api: "eventandimpact/get/allevents",
    method: "get",
    baseURL: "event",
  },
  getAllAgendaMaster: {
    api: "fpo/agendaMaster",
    method: "get",
    baseURL: "event",
  },

  addFpoEvent: {
    api: "eventandimpact/add/event",
    method: "post",
    baseURL: "event",
  },
  getEvent: {
    url: "eventandimpact/get/event",
    method: "get",
    baseURL: "event",
    id: null,
    get api() {
      if (this.id) {
        return this.url + "/" + this.id;
      } else {
        return this.url;
      }
    },
  },
  getRecommendedEvents: {
    url: "eventandimpact/get/other/events",
    method: "get",
    baseURL: "event",
    query: {
      limit: 4,
      page: 1,
      eventId: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getRecommendedAwards: {
    url: "eventandimpact/get/other/awards",
    method: "get",
    baseURL: "event",
    query: {
      limit: 4,
      page: 1,
      awardId: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getRecommendedStories: {
    url: "eventandimpact/get/other/story",
    method: "get",
    baseURL: "event",
    query: {
      limit: 4,
      page: 1,
      storyId: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  deleteAwards: {
    url: "eventandimpact/delete/award",
    method: "delete",
    baseURL: "event",
    id: null,
    get api() {
      return this.url + "/" + this.id;
    },
    set addQuery({ key, payload }) {
      this[key] = payload;
    },
  },
  deleteStories: {
    url: "eventandimpact/delete/story",
    method: "delete",
    baseURL: "event",
    id: null,
    get api() {
      return this.url + "/" + this.id;
    },
    set addQuery({ key, payload }) {
      this[key] = payload;
    },
  },
  deleteEvents: {
    url: "eventandimpact/delete/event",
    method: "delete",
    baseURL: "event",
    id: null,
    get api() {
      return this.url + "/" + this.id;
    },
    set addQuery({ key, payload }) {
      this[key] = payload;
    },
  },
  updateEvent: {
    api: "eventandimpact/update/event",
    method: "put",
    baseURL: "event",
  },
  createAward: {
    api: "eventandimpact/add/award",
    method: "post",
    baseURL: "event",
  },
  getAward: {
    url: "eventandimpact/get/award",
    method: "get",
    baseURL: "event",
    id: null,
    get api() {
      if (this.id) {
        return this.url + "/" + this.id;
      } else {
        return this.url;
      }
    },
  },
  updateAward: {
    api: "eventandimpact/update/award",
    method: "put",
    baseURL: "event",
  },
  createStory: {
    api: "eventandimpact/add/story",
    method: "post",
    baseURL: "event",
  },
  updateStory: {
    api: "eventandimpact/update/story",
    method: "put",
    baseURL: "event",
  },
  getStory: {
    url: "eventandimpact/get/story",
    method: "get",
    baseURL: "event",
    id: null,
    get api() {
      if (this.id) {
        return this.url + "/" + this.id;
      } else {
        return this.url;
      }
    },
  },
  getallEventsData: {
    api: "eventandimpact/get/events/story/awards",
    method: "get",
    baseURL: "event",
  },
  getallEventsDataWithId: {
    url: "eventandimpact/get/events/story/awards/fpo/",
    method: "get",
    baseURL: "event",
    id: null,
    get api() {
      return this.url + this.id;
    },
  },
};

export const forum = {
  create: {
    api: "community/forum",
    method: "post",
    baseURL: "forum",
  },
  updateForum: {
    url: "community/forum/",
    method: "put",
    baseURL: "forum",
    id: null,
    get api() {
      return this.url + "/" + this.id;
    },
    set addQuery({ key, payload }) {
      this[key] = payload;
    },
  },
  deleteForum: {
    url: "community/forum",
    method: "delete",
    baseURL: "forum",
    id: null,
    get api() {
      return this.url + "/" + this.id;
    },
    set addQuery({ key, payload }) {
      this[key] = payload;
    },
  },
  forumList: {
    url: "community/forum/list",
    method: "get",
    baseURL: "forum",
    query: {
      limit: "",
      categoryId: "",
      page: 1,
      forumId: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  yourForumList: {
    api: "community/forum/my/list",
    method: "get",
    baseURL: "forum",
  },
  forumComment: {
    api: "community/comment",
    method: "post",
    baseURL: "forum",
  },
  getForumComment: {
    api: "community/comment",
    method: "get",
    baseURL: "forum",
    id: null,
  },
  getForumComments: {
    url: "community/comment/list/",
    method: "get",
    baseURL: "forum",
    id: null,
    query: {
      limit: 5,
      page: 1,
    },
    get api() {
      return this.url + this.id + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  forumLike: {
    api: "community/forum/like",
    method: "post",
    baseURL: "forum",
  },
  forumUnLike: {
    api: "community/forum/unlike",
    method: "post",
    baseURL: "forum",
  },
  deleteComment: {
    url: "community/comment/",
    method: "delete",
    baseURL: "forum",
    id: null,
    get api() {
      return this.url + this.id;
    },
    set addQuery({ key, payload }) {
      this[key] = payload;
    },
  },
  commentLike: {
    api: "community/comment/like",
    method: "post",
    baseURL: "forum",
  },
  commentUnLike: {
    api: "community/comment/unlike",
    method: "post",
    baseURL: "forum",
  },
  getPopularTopics: {
    api: "community/forum/populartopics/list",
    method: "get",
    baseURL: "forum",
  },
  getCategoryDetails: {
    api: "community/category/list/forumcount",
    method: "get",
    baseURL: "forum",
  },
  getNotificationList: {
    url: "community/forum/notification",
    baseURL: "forum",
    method: "get",
    query: {
      limit: 10,
      page: 1,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  readallNotification: {
    api: "community/forum/notifications/readall",
    method: "get",
    baseURL: "forum",
  },
  categoryList: {
    api: "community/category/list",
    method: "get",
    baseURL: "forum",
  },
  saveForum: {
    api: "community/forum/save",
    method: "post",
    baseURL: "forum",
  },
  getForumById: {
    url: "community/forum/",
    method: "get",
    baseURL: "forum",
    id: null,
    get api() {
      return this.url + this.id;
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getForumDetailsHome: {
    api: "community/forum/homepage/list",
    method: "get",
    baseURL: "forum",
  },
  getSavedForumList: {
    url: "community/forum/get/saveforum",
    method: "get",
    baseURL: "forum",
    query: {
      limit: 20,
      page: 1,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getSavedForumById: {
    url: "community/forum/saveforum/",
    method: "get",
    baseURL: "forum",
    id: null,
    get api() {
      return this.url + this.id;
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
};

export const contents = {
  getNewsAndEvents: {
    api: "content/academy/home",
    method: "get",
    baseURL: "admin",
  },
  getAllEvents: {
    url: "content/event/get/events/web",
    method: "get",
    baseURL: "admin",
    query: {
      limit: 5,
      page: 1,
      search: "",
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getAllNews: {
    url: "content/news/news/get/web",
    method: "get",
    baseURL: "admin",
    query: {
      limit: 6,
      page: 1,
      search: "",
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },

  getAllReports: {
    url: "content/reports/list",
    method: "get",
    baseURL: "admin",
    query: {
      limit: 6,
      page: 1,
      search: "",
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getAllReportsById: {
    url: "content/reports/get/",
    method: "get",
    baseURL: "admin",
    id: null,
    get api() {
      return this.url + this.id;
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
};

export const certificates = {
  list: {
    url: "academy/my/certificates/list",
    method: "get",
    baseURL: "academy",
    id: null,
    query: {
      limit: null,
      page: null,
    },
    get api() {
      return this.url + generateQuery(this.query) + "/" + this.id;
    },
    set addQuery({ key, value }) {
      this.query[key] = value;
    },
  },
  downloadCerificate: {
    url: "academy/my/certificates/download",
    method: "get",
    baseURL: "academy",
    id: null,
    get api() {
      return this.url + "/" + this.id;
    },
  },
  getCertificatesData: {
    url: "academy/my/certificates/list/course",
    method: "get",
    baseURL: "academy",
    id: null,
    get api() {
      return this.url + "/" + this.id;
    },
    set addQuery({ key, value }) {
      this.query[key] = value;
    },
  },
};

export const noification = {
  getNotificationList: {
    url: "eventandimpact/notification/get/notifications",
    baseURL: "event",
    method: "get",
    query: {
      limit: 10,
      page: 1,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getNotificationCount: {
    api: "eventandimpact/notification/get/notification/notViewed/count",
    baseURL: "event",
    method: "get",
  },
  updateAllNotificationStatus: {
    api: "eventandimpact/notification/update/notification",
    baseURL: "event",
    method: "get",
  },
  updateSingleNotificationStatus: {
    url: "eventandimpact/notification/update/notification/",
    baseURL: "event",
    method: "get",
    id: null,
    get api() {
      return this.url + this.id;
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
};

export const pricingandWeather = {
  commodityList: {
    api: "pricing/commodity/list",
    baseURL: "pricingandWeather",
    method: "get",
  },
  mandiList: {
    url: "pricing/mandi/list",
    baseURL: "pricingandWeather",
    method: "get",
    query: {
      stateName: "",
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  commodityByMandi: {
    url: "pricing/comadity/ByMandiId",
    baseURL: "pricingandWeather",
    method: "get",
    query: {
      mandiName: "",
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  mandiByPriceList: {
    url: "pricing/mandi/list/ByPrice",
    baseURL: "pricingandWeather",
    method: "get",
    query: {
      stateName: "",
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getGraphDataforOneMandi: {
    url: "pricing/home",
    baseURL: "pricingandWeather",
    method: "get",
    query: {
      commodity: "",
      apmc: "",
      variety: "",
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  oneMandiDiffCommodity: {
    url: "pricing/one/mandi/differnt/commodity",
    baseURL: "pricingandWeather",
    method: "get",
    query: {
      commodity: "",
      apmc: "",
      days: "7",
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  oneCommodityDiffMandi: {
    url: "pricing/one/commodity/differnt/mandi",
    baseURL: "pricingandWeather",
    method: "get",
    query: {
      commodity: "",
      apmcOne: "",
      apmcTwo: "",
      variety: "",
      days: "7",
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getWeatherDetails: {
    api: "weather/fpo",
    baseURL: "pricingandWeather",
    method: "get",
  },
  getWeatherDetailsForHome: {
    api: "weather/fpo/home",
    baseURL: "pricingandWeather",
    method: "get",
  },
  getStateOptions: {
    api: "pricing/states",
    baseURL: "pricingandWeather",
    method: "get",
  },
  getStateByPrice: {
    url: "pricing/state/Byprice",
    baseURL: "pricingandWeather",
    method: "get",
    query: {
      commodityId: "",
      variety: "",
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getMandiByPriceAndCommodity: {
    url: "pricing/mandi/Byprice",
    baseURL: "pricingandWeather",
    method: "get",
    query: {
      commodityId: "",
      state: "",
      variety: "",
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getCommodityVariety: {
    url: "pricing/commodity/variety",
    baseURL: "pricingandWeather",
    method: "get",
    query: {
      commodityId: "",
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
};

export const RM = {
  getCapacityBuildingList: {
    url: "capacity/list",
    baseURL: "member",
    method: "get",
    query: {
      limit: 4,
      search: "",
      page: 1,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getFPOlistCapacityBuilding: {
    api: "capacity/fpo/list",
    baseURL: "member",
    method: "get",
  },
  addCapacityBuilding: {
    api: "capacity/add",
    baseURL: "member",
    method: "post",
  },
  getAllMasters: {
    api: "fpo/management/grading-masters",
    baseURL: "member",
    method: "get",
  },
  getSurveyDetails: {
    url: "fpo/management/grading-details",
    baseURL: "member",
    method: "get",
    id: null,
    query: {},
    get api() {
      return this.url + "/" + this.id + generateQuery(this.query);
    },
  },
  postSurveyDetails: {
    url: "fpo/management/grading-details",
    baseURL: "member",
    method: "post",
    id: null,
    query: {},
    get api() {
      return this.url + "/" + this.id + generateQuery(this.query);
    },
  },
  getRMFPOList: {
    url: "fpo/management/rm/fpo/list",
    baseURL: "member",
    method: "get",
    query: {
      limit: 10,
      search: "",
      page: 1,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getFPOMemberList: {
    url: "fpo/management/rmmapping/rm/fpo/members/",
    baseURL: "member",
    method: "get",
    id: null,
    query: {
      limit: 10,
      search: "",
      page: 1,
    },
    get api() {
      return this.url + this.id + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getRMFPOData: {
    url: "fpo/management/rmmapping/fpo/profile",
    baseURL: "member",
    method: "get",
    id: null,
    query: {},
    get api() {
      return this.url + "/" + this.id + generateQuery(this.query);
    },
  },
  getRMFPOMemberData: {
    url: "fpo/management/rmmapping/rm/getRmDetails",
    baseURL: "member",
    method: "get",
    id: null,
    query: {},
    get api() {
      return this.url + "/" + this.id + generateQuery(this.query);
    },
  },
  updateRMFPOMemberData: {
    url: "fpo/management/rmmapping/rm/member/update",
    baseURL: "member",
    method: "put",
    id: null,
    query: {},
    get api() {
      return this.url + "/" + this.id + generateQuery(this.query);
    },
  },
  addKYMNew: {
    api: "fpo/management/rmmapping/rm/kym-new",
    baseURL: "member",
    method: "post",
  },
  // getCropsCategory: {
  //   api: "harvest/get/KymClassification/list",
  //   baseURL: "member",
  //   method: "get",
  // },
  // getCropsClassification: {
  //   url: "harvest/get/CropListByid",
  //   baseURL: "member",
  //   method: "get",
  //   id: null,
  //   query: {},
  //   get api() {
  //     return this.url + "/" + this.id + generateQuery(this.query);
  //   },
  // },
  getCropsCategory: {
    url: "harvest/get/KymClassification/list",
    baseURL: "member",
    method: "get",
    query: {
      kymclassificationId: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getCropsClassification: {
    url: "harvest/get/cropClassification/list",
    baseURL: "member",
    method: "get",
    query: {
      kymclassificationId: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getGradingDetails: {
    url: "fpo/management/rmmapping/fpo/grading",
    baseURL: "member",
    method: "get",
    id: null,
    query: {},
    get api() {
      return this.url + "/" + this.id + generateQuery(this.query);
    },
  },
  getGradingDropdownList: {
    url: "fpo/management/rmmapping/fpo/barchart/list",
    baseURL: "member",
    method: "get",
    id: null,
    query: {},
    get api() {
      return this.url + "/" + this.id + generateQuery(this.query);
    },
  },
  getGradingGraphDetails: {
    url: "fpo/management/rmmapping/fpo/barchart",
    baseURL: "member",
    method: "get",
    id: null,
    query: {
      ids: null,
    },
    get api() {
      return this.url + "/" + this.id + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
};

export const FIGCRP = {
  getCRPList: {
    url: "crp/list",
    method: "get",
    baseURL: "member",
    query: {
      limit: 10,
      page: 1,
      search: "",
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getCRPListRm: {
    url: "crp/list/fpo/",
    method: "get",
    baseURL: "member",
    id: null,
    query: {
      limit: 10,
      page: 1,
      search: "",
    },
    get api() {
      return this.url + this.id + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  addCRP: {
    api: "crp/add",
    method: "post",
    baseURL: "member",
  },
  getCRPById: {
    url: "crp/list/",
    method: "get",
    baseURL: "member",
    id: null,
    get api() {
      return this.url + this.id;
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  updateCRP: {
    api: "crp/update",
    method: "put",
    baseURL: "member",
  },
  getFIGList: {
    url: "fig/list",
    method: "get",
    baseURL: "member",
    query: {
      limit: 10,
      page: 1,
      search: "",
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getFIGListRm: {
    url: "fig/list/fpo/",
    method: "get",
    baseURL: "member",
    id: null,
    query: {
      limit: 10,
      page: 1,
      search: "",
    },
    get api() {
      return this.url + this.id + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  addFIG: {
    api: "fig/add",
    method: "post",
    baseURL: "member",
  },
  updateFIG: {
    api: "fig/update",
    method: "put",
    baseURL: "member",
  },
  getFIGById: {
    url: "fig/list/",
    method: "get",
    baseURL: "member",
    id: null,
    get api() {
      return this.url + this.id;
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getMemberListforMapping: {
    url: "member-mapping/list?figId=",
    method: "get",
    baseURL: "member",
    id: null,
    get api() {
      return this.url + this.id;
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  addMemberToFIG: {
    api: "member-mapping/add",
    method: "post",
    baseURL: "member",
  },
  addFIGToCRP: {
    api: "fig-mapping/add",
    method: "post",
    baseURL: "member",
  },
  getFIGListforMapping: {
    url: "fig-mapping/list?crpId=",
    method: "get",
    baseURL: "member",
    id: null,
    get api() {
      return this.url + this.id;
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  deleteFIG: {
    url: "fig/",
    method: "delete",
    baseURL: "member",
    id: null,
    get api() {
      return this.url + this.id;
    },
    set addQuery({ key, payload }) {
      this[key] = payload;
    },
  },
  deleteCRP: {
    url: "crp/",
    method: "delete",
    baseURL: "member",
    id: null,
    get api() {
      return this.url + this.id;
    },
    set addQuery({ key, payload }) {
      this[key] = payload;
    },
  },
};

export const samIPL = {
  updateMobile: {
    api: "user/update/MobileNumber",
    method: "put",
    baseURL: "auth",
  },
  updateOtp: {
    api: "user/auth/otp/verify/otp",
    method: "post",
    baseURL: "auth",
  },
  resendOtp: {
    api: "user/auth/otp/resendMobile/otp",
    method: "post",
    baseURL: "auth",
  },
  getEntityList: {
    api: "fpo/management/fpo/entityType/list",
    method: "get",
    baseURL: "member",
  },
  updateEntity: {
    api: "user/update/entityType",
    method: "put",
    baseURL: "auth",
  },
  getEntityIPLUrl: {
    api: "gatways/samIpl/redirect",
    method: "post",
    baseURL: "gateway",
  },
};

export const Harvest = {
  addHarvest: {
    api: "harvest",
    method: "post",
    baseURL: "member",
  },
  harvestCrops: {
    api: "harvest/crops",
    method: "get",
    baseURL: "member",
  },
  getHarvest: {
    url: "harvest",
    method: "get",
    baseURL: "member",
    tab: null,
    query: {
      activeTab: "Kharif",
      limit: 10,
      page: 1,
    },
    get api() {
      return (
        this.url +
        "/" +
        this.tab +
        "?page=" +
        this.query.page +
        "&limit=" +
        this.query.limit
      );
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  updateHarvest: {
    url: "harvest/",
    method: "put",
    baseURL: "member",
    id: null,
    get api() {
      return this.url + this.id;
    },
    set addQuery({ key, payload }) {
      this[key] = payload;
    },
  },
  deletHarvest: {
    url: "harvest/",
    method: "delete",
    baseURL: "member",
    id: null,
    get api() {
      return this.url + this.id;
    },
    set addQuery({ key, payload }) {
      this[key] = payload;
    },
  },
};

export const kymApi = {
  kymOTPLogin: {
    api: "user/auth/otp/kym/send/otp",
    method: "post",
    baseURL: "auth",
  },
  KYMOTPVerify: {
    api: "user/auth/otp/kym/verify/otp",
    method: "post",
    baseURL: "auth",
  },
  createKymUser: {
    api: "user/fpo/kym/create",
    method: "post",
    baseURL: "auth",
  },
  deleteKymUser: {
    url: "user/fpo/kym/delete/",
    method: "delete",
    baseURL: "auth",
    id: null,
    get api() {
      return this.url + this.id;
    },
    set addQuery({ key, payload }) {
      this[key] = payload;
    },
  },
  getKYMFPOList: {
    api: "user/fpo/kym/list",
    method: "get",
    baseURL: "auth",
  },
  getKYMNewFPOList: {
    url: "kym/fpo/list",
    baseURL: "member",
    method: "get",
    query: { limit: 10, search: "", page: 1 },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getKYMNewFPOMemberList: {
    url: "kym/fpo/members",
    baseURL: "member",
    method: "get",
    id: null,
    query: { limit: 10, search: "", page: 1 },
    get api() {
      return this.url + "/" + this.id + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getKYMFPOData: {
    url: "kym/fpo/profile",
    baseURL: "member",
    method: "get",
    id: null,
    query: {},
    get api() {
      return this.url + "/" + this.id + generateQuery(this.query);
    },
  },
  getKYMModuleFPOMemberData: {
    url: "kym/member",
    baseURL: "member",
    method: "get",
    id: null,
    query: {},
    get api() {
      return this.url + "/" + this.id + generateQuery(this.query);
    },
  },
  updateKYMModuleFPOMemberData: {
    url: "kym/member/update",
    baseURL: "member",
    method: "put",
    id: null,
    query: {},
    get api() {
      return this.url + "/" + this.id + generateQuery(this.query);
    },
  },
  addKYMModuleMember: {
    api: "kym/member/create",
    baseURL: "member",
    method: "post",
  },
};

export const memberApi = {
  getMemberPOData: {
    url: "kym/fpo/profile",
    baseURL: "member",
    method: "get",
    id: null,
    query: {},
    get api() {
      return this.url + "/" + this.id + generateQuery(this.query);
    },
  },
  getMemberModuleFPOMemberData: {
    url: "kym/member",
    baseURL: "member",
    method: "get",
    id: null,
    query: {},
    get api() {
      return this.url + "/" + this.id + generateQuery(this.query);
    },
  },
  addMemberModuleMember: {
    api: "kym/member/create",
    baseURL: "member",
    method: "post",
  },
  updateMemberModuleFPOMemberData: {
    url: "kym/member/update",
    baseURL: "member",
    method: "put",
    id: null,
    query: {},
    get api() {
      return this.url + "/" + this.id + generateQuery(this.query);
    },
  },
};
