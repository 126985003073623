import React from "react";
import { history } from "service/helpers";

function Strip(props) {
  const { payload, editAccess } = props;
  const {
    bankName,
    beneficiaryBankAccountNumber,
    bankAccountType,
    IFSCCode,
    beneficiaryName,
    bankId,
    others,
  } = payload;
  return (
    <div className="container-sa license-strip shadow rounded mb-3">
      <div className="row align-items-center h-100 px-2">
        <div className="col-3">
          <div className="fs-18 fw-600">
            {beneficiaryBankAccountNumber ? beneficiaryBankAccountNumber : "-"}
          </div>
          <div className="text-black-50">{"Account Number"}</div>
        </div>
        <div className="col-2">
          <div className="fs-18 fw-600">{bankName ? bankName : "-"}</div>
          <div className="text-black-50">{"Bank Name"}</div>
        </div>
        <div className="col-2">
          <div className="fs-18 fw-600">
            {bankAccountType
              ? bankAccountType == "Others"
                ? others
                : bankAccountType
              : "-"}
          </div>
          <div className="text-black-50">{"Account Type"}</div>
        </div>
        <div className="col-2">
          <div className="fs-18 fw-600">{IFSCCode ? IFSCCode : "-"}</div>
          <div className="text-black-50">{"IFSC Code"}</div>
        </div>
        <div className="col-2">
          <div className="fs-18 fw-600">
            {beneficiaryName ? beneficiaryName : "-"}
          </div>
          <div className="text-black-50">{"Beneficiary Name"}</div>
        </div>
        <div className="col-1">
          {editAccess && (
            <div
              className="text-black-50 cursor-pointer"
              onClick={() => history.push(`/fpo/edit-bankDetails/${bankId}`)}
            >
              {"Edit"}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Strip;
