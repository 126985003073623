import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  getEventById,
  getAwardById,
  getStoryById,
  getRecommendedEvents,
  getRecommendedAwards,
  getRecommendedStories,
} from "action/eventsAndImpactStories";

import { getSuccessStoryById } from "action/HomeAct";
import { CommonPageLoader, NormalButton } from "component/common";
import { OtherEvents } from "../index";
import moment from "moment";
import "./style.scss";
import { history } from "service/helpers";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from "swiper";
import arrow_left from "assets/svg/add_member/arrow_left.svg";
import left_arrow from "assets/images/icon/arrow-left.svg";
import right_arrow from "assets/svg/posts/arrow-right.svg";
import defaultAwardsImage from "assets/images/DefaultAward.jpg";
import defaultStoryImage from "assets/images/DefaultSuccessStory.jpg";
import defaultEventImage from "assets/images/DefaultEvent.jpg";
import { FileViewer } from "component/common/FileViewer";
import ImageViewer from "./imageViewer";

class ViewPageforEventsandImpactsClass extends Component {
  constructor() {
    super();
    SwiperCore.use([Navigation]);
    this.state = {
      eventTitle: "",
      agendaName: "",
      impactStory: "",
      eventDate: "",
      location: "",
      organizer: "",
      description: "",
      isLoading: false,
      receivedDate: "",
      receivedFrom: "",
      awardName: "",
      title: "",
      shortDescription: "",
      publishOn: "",
      author: "",
      registrationLink: "",
      awardDocs: [],
      storyDocs: [],
      eventDocs: [],
      eventImages: [],
      docUrl: [],
      isCreatedBy: false,
      supportingLink: "",
      eventStatus: false,
      otherRecommededEvents: [],
      pageMeta: {},
      otherRecommededAwards: [],
      otherRecommededStories: [],
      fpoDetail: {},
    };
  }

  componentDidMount() {
    let { id, name } = this.props;

    this.setState({ isLoading: true });

    if (name === "events") {
      this._getEventById(id);
    } else if (name === "awards") {
      this._getAwardsById(id);
    } else if (name === "successstories") {
      this._getStoryById(id);
    } else if (name === "successStories") {
      this._getsuccessStoryById(id);
    }
  }

  _getsuccessStoryById = (id) => {
    this.props.getSuccessStoryById(id).then((data) => {
      let { publishOn, title, description, author, uploadImage } = data;
      this.setState({
        isLoading: false,
        publishOn,
        title,
        description,
        author,
        uploadImage,
      });
    });
  };

  _getStoryById = (storyId) => {
    this.props.getStoryById(storyId).then(({ data }) => {
      let {
        publishOn,
        title,
        location,
        description,
        author,
        isCreatedBy,
        supportingLink,
        storyDocs,
        fpoDetail,
        successPdf,
      } = data;

      this.setState({
        location,
        publishOn,
        title,
        description,
        author,
        isCreatedBy,
        supportingLink,
        storyDocs,
        fpoDetail,
        docUrl: successPdf ?? [],
      });
      this._getRecommendedStories(1);
    });
  };

  _getAwardsById = (awardid) => {
    this.props.getAwardById(awardid).then(({ data }) => {
      let {
        receivedDate,
        receivedFrom,
        title,
        location,
        shortDescription,
        awardDocs,
        isCreatedBy,
        supportingLink,
        awardName,
        fpoDetail,
        awardPdf,
      } = data;

      this.setState({
        location,
        receivedDate,
        receivedFrom,
        title,
        shortDescription,
        awardDocs,
        isCreatedBy,
        supportingLink,
        awardName,
        docUrl: awardPdf ?? [],
        fpoDetail,
      });
    });
    this._getRecommendedAwards(1);
  };

  _getEventById = (eventId) => {
    this.props.getEventById(eventId).then(({ data }) => {
      let {
        agendaName,
        impactStory,
        eventDate,
        supportingLink,
        location,
        organizer,
        description,
        registrationLink,
        isCreatedBy,
        eventDocs,
        eventImages,
        eventPdf,
        eventStatus,
        eventTitle,
        fpoDetail,
      } = data;

      this.setState({
        agendaName,
        impactStory,
        eventDate,
        location,
        organizer,
        description,
        registrationLink,
        isCreatedBy,
        eventDocs,
        eventImages,
        docUrl: eventPdf ?? [],
        supportingLink,
        eventStatus,
        eventTitle,
        fpoDetail,
      });
      this._getRecommendedEvents(1);
    });
  };

  _getRecommendedEvents = (page) => {
    let { id, getRecommendedEvents } = this.props;

    getRecommendedEvents({ page, eventId: id }).then((data) => {
      this.setState({
        otherRecommededEvents: data.results,
        isLoading: false,
        pageMeta: data.pageMeta,
      });
    });
  };

  _getRecommendedAwards = (page) => {
    let { id, getRecommendedAwards } = this.props;

    getRecommendedAwards({ page, awardId: id }).then((data) => {
      this.setState({
        otherRecommededAwards: data.results,
        isLoading: false,
        pageMeta: data.pageMeta,
      });
    });
  };

  _getRecommendedStories = (page) => {
    let { id, getRecommendedStories } = this.props;

    getRecommendedStories({ page, storyId: id }).then((data) => {
      this.setState({
        otherRecommededStories: data.results,
        isLoading: false,
        pageMeta: data.pageMeta,
      });
    });
  };

  handleBack = () => {
    history.goBack();
  };

  handlePageChange = (page) => {
    let { name, id } = this.props;
    if (name === "events") {
      this._getRecommendedEvents(page);
    } else if (name === "awards") {
      this._getRecommendedAwards(page);
    } else if (name === "successstories") {
      this._getRecommendedStories(1);
    } else if (name === "successStories") {
      this._getsuccessStoryById(id);
    }
  };

  componentDidUpdate(prevProps) {
    if (prevProps.id != this.props.id) {
      let { id, name } = this.props;
      this.setState({ isLoading: true });
      if (name === "events") {
        this._getEventById(id);
      } else if (name === "awards") {
        this._getAwardsById(id);
      } else if (name === "successstories") {
        this._getStoryById(id);
      } else if (name === "successStories") {
        this._getsuccessStoryById(id);
      }
    }
  }

  render() {
    let { name } = this.props;

    let {
      eventTitle,
      agendaName,
      impactStory,
      eventDate,
      location,
      organizer,
      description,
      isLoading,
      receivedDate,
      receivedFrom,
      awardName,
      title,
      shortDescription,
      publishOn,
      author,
      registrationLink,
      awardDocs,
      storyDocs,
      eventDocs,
      supportingLink,
      isCreatedBy,
      eventStatus,
      otherRecommededEvents,
      pageMeta,
      otherRecommededAwards,
      otherRecommededStories,
      fpoDetail,
      uploadImage,
      eventImages,
      docUrl,
    } = this.state;

    const params = {
      slidesPerView: 1,
      spaceBetween: 20,
      breakpoints: {
        500: {
          slidesPerView: 1,
        },
        768: {
          slidesPerView: 1,
        },
      },
      navigation: {
        nextEl: "#academy-next",
        prevEl: "#academy-prev",
      },
    };

    return (
      <>
        <div className="d-flex my-3 container-sa px-0">
          <div
            onClick={() => {
              this.handleBack();
            }}
            className="d-flex"
          >
            <img className="cursor-pointer" src={arrow_left} alt="back" />
            <p className="fs-18 font-weight-bold text-black mb-0 cursor-pointer ml-3">
              {name === "events"
                ? "Our Events"
                : name === "awards"
                ? "Awards & Recognition"
                : "Success stories"}
            </p>
          </div>
        </div>

        <div className="view-eventsandImpacts bg-blue-f9 h-100">
          {isLoading ? (
            <>
              {/* Loader */}
              <CommonPageLoader />
            </>
          ) : (
            <div className="container-sa py-5">
              <div className="row">
                <div className="col-8">
                  {name === "events" ? (
                    <div className="row">
                      <div className="col-9">
                        <div className="agenda-detail">
                          {agendaName !== null ? agendaName : ""}
                        </div>
                        <div className="impact-story-detail pb-2">
                          {impactStory}
                        </div>
                      </div>
                      <div className="col-3">
                        {registrationLink !== null &&
                        registrationLink !== "" &&
                        eventStatus ? (
                          <NormalButton
                            label={"Register"}
                            mainbg={true}
                            className="px-3 py-2 fs-14 line-height-26 w-100"
                            onClick={() =>
                              registrationLink.includes("http" || "https")
                                ? window.open(registrationLink, "_blank")
                                : window.open(
                                    `https://${registrationLink}`,
                                    "_blank"
                                  )
                            }
                          />
                        ) : null}
                      </div>
                    </div>
                  ) : null}
                  {name === "events" && (
                    <div className="award-title-detail pb-2">
                      {eventTitle !== null ? eventTitle : ""}
                    </div>
                  )}
                  {name !== "events" && (
                    <div className="award-title-detail pb-2">
                      {title !== null ? title : ""}
                    </div>
                  )}
                  {name === "successstories" || name === "successStories" ? (
                    <div className="agenda-detail pb-2">
                      {author !== null ? author : ""}
                    </div>
                  ) : null}
                  {name === "events" ? (
                    <>
                      <div className="row px-3 mb-3">
                        <div className="d-flex pr-2">
                          <p className="fs-14 fw-500 text-black-1c mb-0">
                            <span className="icon-event-location"></span>{" "}
                            {"Event date :"}
                          </p>
                          <p className="fs-14 fw-500 text-gold-d0 pl-2 mb-0">
                            {eventDate !== null
                              ? moment(eventDate).format("DD MMMM YYYY")
                              : ""}
                          </p>
                        </div>
                        <div className="margin-right-black-50"></div>
                        <div className="d-flex px-2">
                          <p className="fs-14 fw-500 text-black-1c mb-0">
                            <span className="icon-event-location"></span>{" "}
                            {"location of event :"}
                          </p>
                          <p className="fs-14 fw-500 text-gold-d0 pl-2 mb-0">
                            {location !== null ? location : "-"}
                          </p>
                        </div>
                        <div className="margin-right-black-50"></div>
                        <div className="d-flex px-2">
                          <p className="fs-14 fw-500 text-black-1c mb-0">
                            {"Event orgnanizer :"}
                          </p>
                          <p className="fs-14 fw-500 text-gold-d0 mb-0 pl-1">
                            {" "}
                            {organizer !== null ? organizer : "-"}
                          </p>
                        </div>
                      </div>
                      <div className="d-flex">
                        {supportingLink !== null && supportingLink !== "" ? (
                          <div className="d-flex">
                            <p className="fs-14 fw-500 text-black-1c mb-0">
                              <span className="icon-event-link"></span>{" "}
                              {"Supporting link :"}
                            </p>
                            <a
                              href={supportingLink}
                              target="_blank"
                              className="fs-14 fw-500 text-gold-d0 mb-0 pl-1"
                            >
                              {" "}
                              {supportingLink}
                            </a>
                          </div>
                        ) : null}
                        {!isCreatedBy && (
                          <>
                            <div className="margin-right-black-50 mx-2"></div>
                            <div className="d-flex">
                              <div className="d-flex">
                                <p className="fs-14 fw-500 text-black-1c mb-0">
                                  {"FPO :"}
                                </p>
                                <p className="fs-14 fw-500 text-gold-d0 mb-0 pl-1">
                                  {" "}
                                  {fpoDetail?.fpoName},{" "}
                                  {fpoDetail?.stateMaster?.stateName}
                                </p>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </>
                  ) : name === "awards" ? (
                    <>
                      <div className="d-flex mb-3">
                        <div className="d-flex pr-2">
                          <p className="fs-14 fw-500 text-black-1c mb-0">
                            <span className="icon-event-link"></span>{" "}
                            {"Received date :"}
                          </p>
                          <p className="fs-14 fw-500 text-gold-d0 pl-2 mb-0">
                            {receivedDate !== null
                              ? moment(receivedDate).format("DD MMMM YYYY")
                              : ""}
                          </p>
                        </div>
                        <div className="margin-right-black-50"></div>
                        <div className="d-flex px-2">
                          <p className="fs-14 fw-500 text-gold-d0 mb-0">
                            Recieved from{" "}
                            {receivedFrom !== null ? receivedFrom : "-"}
                          </p>
                        </div>
                        <div className="margin-right-black-50"></div>
                        <div className="d-flex px-2">
                          <p className="fs-14 fw-500 text-black-1c mb-0">
                            <span className="icon-event-location"></span>{" "}
                            {"location of event :"}
                          </p>
                          <p className="fs-14 fw-500 text-gold-d0 pl-2 mb-0">
                            {location !== null ? location : "-"}
                          </p>
                        </div>
                      </div>
                      <div className="d-flex">
                        <div className="d-flex">
                          <p className="fs-14 fw-500 text-black-1c mb-0">
                            {"Name of the award :"}
                          </p>
                          <p className="fs-14 fw-500 text-gold-d0 mb-0">
                            {" "}
                            {awardName}
                          </p>
                        </div>
                        {supportingLink !== null && supportingLink !== "" ? (
                          <>
                            <div className="margin-right-black-50 mx-2"></div>
                            <div className="d-flex">
                              <p className="fs-14 fw-500 text-black-1c mb-0">
                                <span className="icon-event-link"></span>{" "}
                                {"Supporting link :"}
                              </p>
                              <a
                                href={supportingLink}
                                className="fs-14 fw-500 text-gold-d0 mb-0"
                                target="_blank"
                              >
                                {" "}
                                {supportingLink}
                              </a>
                            </div>
                          </>
                        ) : null}
                        {!isCreatedBy && (
                          <>
                            <div className="margin-right-black-50 mx-2"></div>
                            <div className="d-flex">
                              <div className="d-flex">
                                <p className="fs-14 fw-500 text-black-1c mb-0">
                                  {"FPO :"}
                                </p>
                                <p className="fs-14 fw-500 text-gold-d0 mb-0 pl-1">
                                  {" "}
                                  {fpoDetail.fpoName},{" "}
                                  {fpoDetail?.stateMaster?.stateName}
                                </p>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </>
                  ) : (
                    <div className="row px-3 mb-3">
                      <div className="d-flex pr-2">
                        <p className="fs-14 fw-500 text-black-1c mb-0">
                          <span className="icon-event-link"></span>{" "}
                          {"Published date :"}
                        </p>
                        <p className="fs-14 fw-500 text-gold-d0 pl-2 mb-0">
                          {publishOn !== null
                            ? moment(publishOn).format("DD MMMM YYYY")
                            : ""}
                        </p>
                      </div>
                      {name !== "successStories" && (
                        <div className="margin-right-black-50"></div>
                      )}
                      <div className="d-flex">
                        {supportingLink !== null && supportingLink !== "" ? (
                          <div
                            className={`d-flex ${
                              name !== "events" ? " px-2" : ""
                            }`}
                          >
                            <p className="fs-14 fw-500 text-black-1c mb-0">
                              <span className="icon-event-link"></span>{" "}
                              {" Supporting link : "}
                            </p>
                            <a
                              href={supportingLink}
                              className="fs-14 fw-500 text-gold-d0 mb-0 pl-1"
                              target="_blank"
                            >
                              {" "}
                              {supportingLink}
                            </a>
                          </div>
                        ) : null}
                        {!isCreatedBy && name !== "successStories" && (
                          <>
                            <div className="margin-right-black-50 mx-2"></div>
                            <div className="d-flex">
                              <div className="d-flex">
                                <p className="fs-14 fw-500 text-black-1c mb-0">
                                  {"FPO :"}
                                </p>
                                <p className="fs-14 fw-500 text-gold-d0 mb-0 pl-1">
                                  {" "}
                                  {fpoDetail.fpoName},{" "}
                                  {fpoDetail?.stateMaster?.stateName}
                                </p>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  )}
                  <div className="pt-3">
                    {name === "events" ? (
                      eventDocs.length > 1 ? (
                        <Swiper {...params}>
                          {eventDocs.map((list, index) => (
                            <SwiperSlide key={index}>
                              {list.fileName.includes(".pdf") ? (
                                <iframe
                                  className="pdf-view"
                                  src={list.documentUrl}
                                />
                              ) : (
                                <div className="img-area">
                                  <img
                                    src={list.documentUrl}
                                    alt="documentUrl"
                                  />
                                </div>
                              )}
                            </SwiperSlide>
                          ))}
                        </Swiper>
                      ) : (
                        <div className="img-area">
                          <img
                            src={eventDocs[0]?.documentUrl}
                            alt="documentUrl"
                          />
                        </div>
                      )
                    ) : name === "successStories" ? (
                      uploadImage !== null ? (
                        <div className="img-area">
                          <img src={uploadImage} alt="uploadImage" />
                        </div>
                      ) : (
                        <div className="img-area">
                          <img
                            src={defaultEventImage}
                            alt="defaultEventImage"
                          />
                        </div>
                      )
                    ) : name === "successstories" ? (
                      storyDocs.length > 1 ? (
                        <Swiper {...params}>
                          {storyDocs.map((list, index) => (
                            <SwiperSlide key={index}>
                              {list.fileName.includes(".pdf") ? (
                                <iframe
                                  className="pdf-view"
                                  src={list.documentUrl}
                                />
                              ) : (
                                <div className="img-area">
                                  <img
                                    src={list.documentUrl}
                                    alt="documentUrl"
                                  />
                                </div>
                              )}
                            </SwiperSlide>
                          ))}
                        </Swiper>
                      ) : (
                        <div className="img-area">
                          <img
                            src={storyDocs[0]?.documentUrl}
                            alt="defaultStoryImage"
                          />
                        </div>
                      )
                    ) : awardDocs.length > 1 ? (
                      <Swiper {...params}>
                        {awardDocs.map((list, index) => (
                          <SwiperSlide key={index}>
                            {list.fileName.includes(".pdf") ? (
                              <iframe
                                className="pdf-view"
                                src={list.documentUrl}
                              />
                            ) : (
                              <div className="img-area">
                                <img src={list.documentUrl} alt="documentUrl" />
                              </div>
                            )}
                          </SwiperSlide>
                        ))}
                      </Swiper>
                    ) : (
                      <div className="img-area">
                        <img
                          src={awardDocs[0]?.documentUrl}
                          alt="defaultAwardsImage"
                        />
                      </div>
                    )}
                  </div>
                  {eventDocs.length > 1 ||
                  storyDocs.length > 1 ||
                  awardDocs.length > 1 ? (
                    <div className="d-flex justify-content-center mt-3">
                      <input
                        type="image"
                        src={left_arrow}
                        alt="left"
                        width="40"
                        height="40"
                        id="academy-prev"
                      />
                      <input
                        type="image"
                        src={right_arrow}
                        alt="left"
                        width="40"
                        height="40"
                        id="academy-next"
                      />
                    </div>
                  ) : null}
                  <div className="event-description pt-3">
                    <p
                      className="text-black fs-16 fw-500 line-height-34"
                      dangerouslySetInnerHTML={
                        name === "awards"
                          ? {
                              __html:
                                shortDescription !== null
                                  ? shortDescription
                                  : "",
                            }
                          : {
                              __html: description !== null ? description : "",
                            }
                      }
                    ></p>
                  </div>
                  <div className="d-flex">
                    <div>
                      <FileViewer document={docUrl} />
                    </div>
                    {eventImages?.length > 0 ? (
                      <div className="mx-md-3">
                        <ImageViewer images={eventImages} />
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-4">
                  <OtherEvents
                    activeTabName={name}
                    otherRecommededEvents={otherRecommededEvents}
                    pageMeta={pageMeta}
                    handlePageChange={this.handlePageChange}
                    otherRecommededAwards={otherRecommededAwards}
                    otherRecommededStories={otherRecommededStories}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getEventById,
      getAwardById,
      getStoryById,
      getSuccessStoryById,
      getRecommendedEvents,
      getRecommendedAwards,
      getRecommendedStories,
    },
    dispatch
  );
};

let component = ViewPageforEventsandImpactsClass;

export const ViewPageforEventsandImpacts = connect(
  null,
  mapDispatchToProps
)(component);
