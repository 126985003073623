import React, { Component } from "react";
import { Navbar } from "../../component/common";
import { connect } from "react-redux";
import { AddAssociation } from "component/associations";
import { getAccessdata } from "service/helperFunctions";
import { UserAuth } from "helpers/UserAuth";
class addAssociationClass extends Component {
  componentDidMount() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }
  render() {
    let { accessData } = this.props;

    let permissionData = getAccessdata(accessData, "associationPartnerships");

    let { deleteAccess = false } = permissionData;
    return (
      <div>
        <div className="inner-header">
          {/* <Navbar /> */}
          <div className="h-100 w-100">
            <p className="d-flex justify-content-center align-items-center h-100 w-100 text-white fs-32 font-weight-bold">
              Associations & Partnerships
            </p>
          </div>
        </div>
        <AddAssociation
          id={this.props.match.params.id}
          deleteAccess={deleteAccess}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  accessData: state.home.accessData,
});
let component = UserAuth(addAssociationClass, {
  service: "associationPartnerships",
});

export const addAssociation = connect(mapStateToProps, null)(component);
