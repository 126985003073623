import React, { Component } from "react";
import { history } from "service/helpers";
import { connect } from "react-redux";
import { arrow } from "./icons";
import "./fpo.scss";
import profile from "assets/svg/fpoduelist.svg";
export class FpoClass extends Component {
  state = {
    fpo: this.props.fpoList,
  };
  componentDidUpdate(prevProps) {
    if (prevProps.fpoList !== this.props.fpoList) {
      this.setState({
        fpo: this.props.fpoList,
      });
    }
  }
  render() {
    let { accessData } = this.props;
    let { fpo } = this.state;
    return (
      <div className="shadow rounded px-3 py-4 fpo-move-up fpo-re-card mb-4 bg-white w-100">
        <div className="mb-3">
          <h3 className="fpo-card-header primary-color-dark">
            {"My FPO List"}
          </h3>
        </div>
        {fpo.length !== 0 ? (
          fpo.map((comp, index) => {
            let { fpoDetails } = comp;
            return (
              <div key={index}>
                <div className="d-flex d-flex align-items-center w-100 justify-content-between p-2 hover-primary-dark">
                  <div className="mr-2">
                    <img
                      className="fpolist-img"
                      src={
                        fpoDetails.fpoLogoURL ? fpoDetails.fpoLogoURL : profile
                      }
                    />
                  </div>
                  <div
                    className="w-75 d-flex align-items-center fpo-card-body"
                    onClick={() => {
                      history.push(`/rm/about-us/${comp.fpoId}`);
                    }}
                  >
                    {fpoDetails.fpoName}
                  </div>
                  <div>
                    <img src={arrow} alt="membership.svg" />
                  </div>
                </div>
                {index !== fpo.length - 1 && (
                  <div className="dropdown-divider" />
                )}
              </div>
            );
          })
        ) : (
          <div className="d-flex justify-content-center fs-16 py-5 text-black-50 fw-600">
            No users found
          </div>
        )}
      </div>
    );
  }
}

let component = FpoClass;

export const Fpo = connect(null, null)(component);
