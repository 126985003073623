import React, { Component, Fragment } from "react";

import {
  FpoGateway,
  FpoGatewayRm,
  LocalWeather,
  FpoProfile,
  PriceDetails,
  Academy,
  Posts,
  Streaks,
  SuccessStory,
  Footer,
  Header,
  HeaderPre,
  EventNewsSection,
  Testimonials,
  FeedbackContent,
  IntroVideoPre,
  ForumPre,
  WhatsNewPre,
  InformativeVideosPre,
  AcademyPre,
  ComplianceCalender,
  ComplianceCalenderRm,
  ComplianceCalenderPre,
} from "component/fpo/home";
import { NewsAndEvents } from "component/Contents";

import {
  getAboutusMemberDetail,
  isFirstTimeLoginOrNot,
} from "action/MemberAct";
import { getForumDetailsHome } from "action/forum";
import {
  getPreNewsAndTestimonals,
  getFaqAndHeaderCount,
  getIntroVideo,
  getForumList,
  getGatewayList,
  getBlogList,
  getCoursesAndInformativeVideos,
  getCalenderList,
} from "action/PreAct";
import { getNewsAndTestimonals, getCalenderListPost } from "action/HomeAct";
import {
  getFpoList,
  getCalenderListRm,
  getCalenderFpoListRm,
  bannerApiForRm,
} from "action/RmAct";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { Navbar } from "component/common";
import moment from "moment";
import { history } from "service/helpers";
export class HomeClass extends Component {
  state = {
    isUpdated: false,
    fpoList: [],
    fpoDetails: {},
    popularTopics: [],
    forumList: [],
    isAuth: false,
    preNewsAndTestimonals: {},
    preHeaderCounts: {},
    preIntroVideo: {},
    preForum: {},
    preGateway: [],
    preBlog: [],
    preCourses: [],
    preInformativeVideos: [],
    preCalenderList: [],
    calenderListDate: {},
    FpoDueListRm: [],
    rmDetails: {},
    memberCount: 0,
  };

  //Lifecycle
  componentDidMount() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    let {
      isFirstTimeLoginOrNot,
      bannerApiForRm,
      getCalenderListRm,
      getCalenderFpoListRm,
      getFpoList,
      getAboutusMemberDetail,
    } = this.props;
    localStorage.getItem("AuthToken") &&
      this.setState({
        isAuth: true,
      });
    if (
      localStorage.getItem("AuthToken") &&
      localStorage.getItem("userGroupName") === "fpo"
    ) {
      getAboutusMemberDetail().then((data) => {
        const { membershipBase } = data;
        this.setState({
          memberCount: membershipBase,
        });
      });
      isFirstTimeLoginOrNot().then((data) => {
        let { isUpdated } = data;
        this.setState({ isUpdated, fpoDetails: data, isRm: false });
        this.props.getForumDetailsHome().then(({ data }) => {
          this.setState({
            popularTopics:
              data.popularTopics !== null ? data.popularTopics : [],
            forumList: data.forums !== null ? data.forums.rows : [],
          });
        });
        this.props.getNewsAndTestimonals().then((data) => {
          this.setState({
            preNewsAndTestimonals: data,
          });
        });
        this.props
          .getCalenderListPost({
            month: moment(new Date()).format("MM"),
            year: moment(new Date()).format("YYYY"),
          })
          .then((data) => {
            this.setState({
              preCalenderList: data.getTaskDetails,
              calenderListDate: data.query,
            });
          });
      });
    } else if (
      (localStorage.getItem("userGroupName") === "samunnati" ||
        localStorage.getItem("userRoleName") === "Relationship Manager") &&
      localStorage.getItem("AuthToken")
    ) {
      bannerApiForRm().then((data) => {
        this.setState({
          rmDetails: data.counts,
        });
      });
      getFpoList().then((data) => {
        this.setState({
          fpoList: data,
        });
      });
      getCalenderListRm({
        month: moment(new Date()).format("MM"),
        year: moment(new Date()).format("YYYY"),
      }).then((data) => {
        this.setState({
          preCalenderList: data.getTaskDetails,
          calenderListDate: data.query,
        });
      });
      getCalenderFpoListRm().then((data) => {
        this.setState({
          FpoDueListRm: data.results,
        });
      });
      this.props.getNewsAndTestimonals().then((data) => {
        this.setState({
          preNewsAndTestimonals: data,
        });
      });
    } else if (!localStorage.getItem("AuthToken")) {
      this.preLoginApis();
    } else {
      this.props.getForumDetailsHome().then(({ data }) => {
        this.setState({
          popularTopics: data.popularTopics !== null ? data.popularTopics : [],
          forumList: data.forums !== null ? data.forums.rows : [],
        });
      });
      this.props.getBlogList().then((data) => {
        this.setState({
          preBlog: data,
        });
      });
      this.props.getPreNewsAndTestimonals().then((data) => {
        this.setState({
          preNewsAndTestimonals: data,
        });
      });
      this.props
        .getCalenderList({
          month: moment(new Date()).format("MM"),
          year: moment(new Date()).format("YYYY"),
        })
        .then((data) => {
          this.setState({
            preCalenderList: data.getTaskDetails,
            calenderListDate: data.query,
          });
        });
    }
  }

  //pre login apis
  preLoginApis = () => {
    this.props.getPreNewsAndTestimonals().then((data) => {
      this.setState({
        preNewsAndTestimonals: data,
      });
    });
    this.props.getFaqAndHeaderCount().then((data) => {
      this.setState({
        preHeaderCounts: data.counts,
      });
    });
    this.props.getIntroVideo().then((data) => {
      this.setState({
        preIntroVideo: data,
      });
    });
    this.props.getForumList().then((data) => {
      this.setState({
        preForum: data,
      });
    });
    this.props.getBlogList().then((data) => {
      this.setState({
        preBlog: data,
      });
    });
    this.props.getGatewayList().then((data) => {
      this.setState({
        preGateway: data,
      });
    });
    this.props.getCoursesAndInformativeVideos().then((data) => {
      this.setState({
        preCourses: data.courses,
        preInformativeVideos: data.trendingVideoList,
      });
    });
    this.props
      .getCalenderList({
        month: moment(new Date()).format("MM"),
        year: moment(new Date()).format("YYYY"),
      })
      .then((data) => {
        this.setState({
          preCalenderList: data.getTaskDetails,
          calenderListDate: data.query,
        });
      });
  };

  //Calender list
  getCalenderListFromComponent = ({ month = "12", year = "2020" } = {}) => {
    this.props
      .getCalenderList({
        month,
        year,
      })
      .then((data) => {
        this.setState({
          preCalenderList: data.getTaskDetails,
          calenderListDate: data.query,
        });
      });
  };

  //Calender list
  getCalenderListPostFromComponent = ({ month = "12", year = "2020" } = {}) => {
    this.props
      .getCalenderListPost({
        month,
        year,
      })
      .then((data) => {
        this.setState({
          preCalenderList: data.getTaskDetails,
          calenderListDate: data.query,
        });
      });
  };

  //Calender list
  getCalenderListRmFromComponent = ({ month = "12", year = "2020" } = {}) => {
    this.props
      .getCalenderListRm({
        month,
        year,
      })
      .then((data) => {
        this.setState({
          preCalenderList: data.getTaskDetails,
          calenderListDate: data.query,
        });
      });
  };

  render() {
    let {
      isUpdated,
      fpoDetails,
      popularTopics,
      forumList,
      isAuth,
      preNewsAndTestimonals,
      preHeaderCounts,
      preIntroVideo,
      preForum,
      preGateway,
      preBlog,
      preCourses,
      preInformativeVideos,
      preCalenderList,
      calenderListDate,
      fpoList,
      FpoDueListRm,
      rmDetails,
      memberCount,
    } = this.state;

    return (
      <div className="background-home">
        {/* <Navbar /> */}
        {isAuth ? (
          <Header
            memberCount={memberCount}
            fpoDetails={fpoDetails}
            rmDetails={rmDetails}
          />
        ) : (
          <HeaderPre counts={preHeaderCounts} />
        )}
        {!isAuth && preIntroVideo !== null && (
          <IntroVideoPre video={preIntroVideo} />
        )}
        {!isAuth && <WhatsNewPre gateway={preGateway} blog={preBlog} />}

        {/* FPO and Gateway Services */}
        {isAuth && localStorage.getItem("userGroupName") === "fpo" ? (
          <FpoGateway />
        ) : localStorage.getItem("userRoleName") === "Relationship Manager" ||
          localStorage.getItem("userGroupName") === "samunnati" ? (
          <FpoGatewayRm fpoList={fpoList} />
        ) : null}

        {/* {!isAuth && preForum.results && preForum.results.length !== 0 && (
          <ForumPre forumList={preForum} />
        )} */}
        {/* {!isAuth && preInformativeVideos.length !== 0 && (
          <InformativeVideosPre videos={preInformativeVideos} />
        )} */}

        {/* Pre Compliance Calender */}
        {/* {!isAuth ? (
          <ComplianceCalenderPre
            list={preCalenderList}
            listDate={calenderListDate}
            getList={this.getCalenderListFromComponent}
            isAuth={false}
          />
        ) : null} */}

        {!isAuth
          ? preCourses.length > 0 && <AcademyPre courses={preCourses} />
          : ""}

        {/* Weather, Feedback and Price details */}
        {isAuth && localStorage.getItem("userGroupName") === "fpo" && (
          <div className="container-sa">
            <div className="row">
              <div className="col-md-4 my-4">
                <div className="mb-4">
                  <LocalWeather />
                </div>
                <div>
                  {isUpdated ? null : ( // <FeedbackContent />
                    <FpoProfile />
                  )}
                </div>
              </div>
              <div className="col-md-8 my-4">
                <PriceDetails />
              </div>
            </div>
          </div>
        )}

        {/* Compliance Calender */}
        {isAuth && localStorage.getItem("userGroupName") === "fpo" ? (
          <ComplianceCalender isAuth={isAuth} />
        ) : (
          isAuth &&
          localStorage.getItem("userRoleName") === "Relationship Manager" && (
            <ComplianceCalenderRm
              list={preCalenderList}
              listDate={calenderListDate}
              getList={this.getCalenderListRmFromComponent}
              FpoDueListRm={FpoDueListRm}
              isAuth={isAuth}
            />
          )
        )}

        {/* Academy for FPOs */}
        {/* All */}
        {isAuth && (
          <>
            <Academy />

            {forumList.length !== 0 && popularTopics.length !== 0 ? (
              <Fragment>
                <div className="pt-4 d-flex justify-content-center">
                  <p className="my-0 fs-32 fw-800 text-gold-d0">
                    Join the discussion
                  </p>
                </div>
                <div className="d-flex background-posts justify-content-center align-items-center">
                  <div className="container-sa">
                    <div className="row h-100">
                      <div className="col-md-6 pr-1">
                        <div className="mr-2 post-container">
                          <Posts forumList={forumList} />
                        </div>
                      </div>
                      <div className="col-md-6 pl-1 mb-3">
                        <Streaks popularTopics={popularTopics} />
                      </div>
                    </div>
                  </div>
                </div>
              </Fragment>
            ) : (
              <div className="d-flex background-posts justify-content-center align-items-center">
                <div className="w-100">
                  <div className="row pb-4 w-100 h-100">
                    <p className="mx-auto my-0 fs-32 fw-800 text-gold-d0">
                      Join the discussion
                    </p>
                  </div>
                  <div className="container-sa">
                    <p className="fs-20 text-center line-height-28 text-black-1c fw-600">
                      No records found!!!
                    </p>
                    <p
                      className="fs-18 text-center line-height-28 text-green-18 fw-600 text-underline cursor-pointer"
                      onClick={() => history.push("/academy/communityForum")}
                    >
                      Click here to create a new forum
                    </p>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
        <NewsAndEvents showAll={false} />
        {/* {(preNewsAndTestimonals?.news?.length > 0 ||
          preNewsAndTestimonals?.events?.length > 0) && (
          <EventNewsSection
            news={preNewsAndTestimonals.news}
            events={preNewsAndTestimonals.events}
            isPrelogin={true}
          />
        )} */}
        {preNewsAndTestimonals?.successstories?.length > 0 && (
          <div className="background-success-story">
            <SuccessStory
              successstories={preNewsAndTestimonals.successstories}
            />
          </div>
        )}
        {preNewsAndTestimonals?.testimonials?.length > 0 && (
          <div className="background-testimonials">
            <Testimonials testimonials={preNewsAndTestimonals.testimonials} />
          </div>
        )}
        <div>
          <Footer />
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      isFirstTimeLoginOrNot,
      getForumDetailsHome,
      getPreNewsAndTestimonals,
      getNewsAndTestimonals,
      getFaqAndHeaderCount,
      getIntroVideo,
      getForumList,
      getGatewayList,
      getBlogList,
      getCoursesAndInformativeVideos,
      getCalenderList,
      getCalenderListPost,
      getFpoList,
      getCalenderListRm,
      getCalenderFpoListRm,
      bannerApiForRm,
      getAboutusMemberDetail,
    },
    dispatch
  );
};
let component = HomeClass;

export const Home = connect(null, mapDispatchToProps)(component);
