import React from "react";
import { Modal, ModalBody } from "reactstrap";
import { seasonOption, unitOption } from "../../../dataVariables";

const HarvestEdit = (props) => {
  const {
    openPopup,
    setOpenPopup,
    formData,
    handleChange,
    updateData,
    cropOptions,
    showEdit,
    setShowEdit,
    onDelete,
    error,
  } = props;

  const popupClose = () => {
    setOpenPopup(false);
    setShowEdit(true);
  };

  return (
    <Modal
      isOpen={openPopup}
      toggle={setOpenPopup}
      className="modal-dialog-centered add-crp-fig"
      size={"md"}
    >
      <ModalBody className="p-md-4">
        <div>
          <div className="modal-label mb-5">
            <h5>
              {showEdit
                ? "Edit Crop details"
                : "Do you want to delete this Crop details?"}
            </h5>
            <i onClick={popupClose} className="icon-close cursor-pointer" />
          </div>
          {showEdit ? (
            <div className="col-8 m-auto">
              <div className="d-flex flex-column">
                <label>
                  Select Season
                  <span className="text-danger font-weight-bold">*</span>
                </label>
                <select
                  className="custom-select form-control"
                  name="season"
                  value={formData.season}
                  onChange={(e) => handleChange(e)}
                >
                  <option value="" disabled defaultValue>
                    {"Select"}
                  </option>
                  {seasonOption.map((list, index) => (
                    <option key={index} value={list.value}>
                      {list.label}
                    </option>
                  ))}
                </select>
              </div>
              <div className="d-flex flex-column">
                <label>
                  Crop
                  <span className="text-danger font-weight-bold">*</span>
                </label>
                <select
                  className="custom-select form-control"
                  name="crop"
                  value={formData?.crop}
                  onChange={(e) => handleChange(e)}
                >
                  <option value="" disabled defaultValue>
                    Select
                  </option>
                  {cropOptions.map((list, index) => (
                    <option key={index} value={list.value}>
                      {list.label}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <label>
                  Variety
                  <span className="text-danger font-weight-bold">*</span>
                </label>
                <input
                  type="text"
                  className="form-control w-100"
                  name="variety"
                  value={formData?.variety}
                  maxLength={20}
                  onChange={(e) => handleChange(e)}
                />
              </div>
              <div>
                <label>
                  Expected quantity
                  <span className="text-danger font-weight-bold">*</span>
                </label>
                <input
                  type="number"
                  className="form-control w-100"
                  name="quantity"
                  value={formData?.quantity}
                  maxLength={20}
                  onKeyDown={(evt) => evt.key === "e" && evt.preventDefault()}
                  onChange={(e) => handleChange(e)}
                />
              </div>
              <div>
                <label>
                  Expected price per unit
                  <span className="text-danger font-weight-bold">*</span>
                </label>
                <input
                  type="number"
                  className="form-control w-100"
                  name="price"
                  value={formData?.price}
                  maxLength={20}
                  onKeyDown={(evt) => evt.key === "e" && evt.preventDefault()}
                  onChange={(e) => handleChange(e)}
                />
              </div>
              <div className="d-flex flex-column">
                <label>
                  Unit of measurement
                  <span className="text-danger font-weight-bold">*</span>
                </label>
                <select
                  className="custom-select form-control"
                  name="unit"
                  value={formData?.unit}
                  onChange={(e) => handleChange(e)}
                >
                  <option value="" disabled defaultValue>
                    Select
                  </option>
                  {unitOption.map((list, index) => (
                    <option key={index} value={list.value}>
                      {list.label}
                    </option>
                  ))}
                </select>
              </div>
              {formData?.unit === "Others" && (
                <div>
                  <label>
                    Other Unit
                    <span className="text-danger font-weight-bold">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control w-100"
                    name="otherunit"
                    value={formData?.others}
                    maxLength={20}
                    onChange={(e) => handleChange(e)}
                  />
                </div>
              )}
              <div className="blog-button-alig d-flex flex-column align-items-center justify-content-center">
                {error && (
                  <p className="error-message text-danger fs-14 mb-0">
                    Please Fill all the fields
                  </p>
                )}
                <button onClick={() => updateData()} className="btn">
                  Update Data
                </button>
              </div>
            </div>
          ) : (
            <div className="delete-button">
              <button onClick={() => onDelete("delete")}>Agree</button>
              <button onClick={popupClose}>Cancel</button>
            </div>
          )}
        </div>
      </ModalBody>
    </Modal>
  );
};

export default HarvestEdit;
