import { pricingandWeather } from "service/apiVariables";
import { Toast } from "service/toast";
import { api } from "service/api";
import { addQuery } from "service/helperFunctions";

//commodity List
export const commodityList = () => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    api({ ...pricingandWeather.commodityList })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//mandi List
export const mandiList = (query) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    addQuery(query, pricingandWeather.mandiList);
    api({ ...pricingandWeather.mandiList })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//commodity By Mandi List
export const commodityByMandiList = (query) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    addQuery(query, pricingandWeather.commodityByMandi);
    api({ ...pricingandWeather.commodityByMandi })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//mandi by price List
export const mandiByPriceList = (query) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    addQuery(query, pricingandWeather.mandiByPriceList);
    api({ ...pricingandWeather.mandiByPriceList })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//get Graph Data for One Mandi
export const getGraphDataforOneMandi = (query) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    addQuery(query, pricingandWeather.getGraphDataforOneMandi);
    api({ ...pricingandWeather.getGraphDataforOneMandi })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//one Mandi Diff Commodity
export const oneMandiDiffCommodity = (query) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    addQuery(query, pricingandWeather.oneMandiDiffCommodity);
    api({ ...pricingandWeather.oneMandiDiffCommodity })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//one Commodity Diff Mandi
export const oneCommodityDiffMandi = (query) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    addQuery(query, pricingandWeather.oneCommodityDiffMandi);
    api({ ...pricingandWeather.oneCommodityDiffMandi })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//get Weather Details
export const getWeatherDetails = () => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    api({ ...pricingandWeather.getWeatherDetails })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//get Weather Details For Home
export const getWeatherDetailsForHome = () => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    api({ ...pricingandWeather.getWeatherDetailsForHome })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//get state list
export const getStateList = () => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    api({ ...pricingandWeather.getStateOptions })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//get state by price
export const getStateByPrice = (query) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    addQuery(query, pricingandWeather.getStateByPrice);
    api({ ...pricingandWeather.getStateByPrice })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//get state by price
export const getMandiByPriceAndCommodity = (query) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    addQuery(query, pricingandWeather.getMandiByPriceAndCommodity);
    api({ ...pricingandWeather.getMandiByPriceAndCommodity })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

// get commodity variety
export const getCommodityVariety = (query) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    addQuery(query, pricingandWeather.getCommodityVariety);
    api({ ...pricingandWeather.getCommodityVariety })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

// get latest price
export const getLatestPrice = (query) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    addQuery(query, pricingandWeather.getLatestPrice);
    api({ ...pricingandWeather.getLatestPrice })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};
