const initialState = {
  isBms: true,
};

export default (state = Object.assign({}, initialState), { type, payload }) => {
  switch (type) {
    case "BMS":
      return {
        ...state,
        isBms: payload,
      };
    default:
      return state;
  }
};
