import React, { Component } from "react";
import aboutusProfile from "assets/images/aboutus-profile.png";
import "./style.scss";
import bottomImage from "assets/images/individualProfileBottomImage.png";
import camera from "assets/images/camera.png";
import { CommonPageLoader, NormalInput, NormalButton } from "component/common";
import SimpleReactValidator from "simple-react-validator";
import {
  changePasswordAfterLogin,
  removeProfilePicture,
  logoutApi,
  updateProfile,
} from "action/AuthAct";
import { logout } from "service/utilities";
import { PasswordStrengthMeter } from "component/common";
// Redux Connection
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { encryptData } from "service/helperFunctions";
import { getUserProfileDetails, updateProfilePicture } from "action/Acadamy";

import { fileUpload } from "action/MemberAct";

export class ProfileClass extends Component {
  state = {
    userDetails: {},
    isfileUploading: false,
    isLoading: false,
    isReadOnly: true,
    userData: {},
    newPassword: "",
    currentPassword: "",
    reenterPassword: "",
  };

  //validation
  validator = new SimpleReactValidator({
    element: (message) => (
      <span className="error-message text-danger fs-14">{message}</span>
    ),
    autoForceUpdate: this,
    validators: {
      password: {
        message: "The :attribute must be a valid format.",
        rule: (val, params, validator) => {
          return (
            validator.helpers.testRegex(
              val,
              /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$^+=!*()@%&]).{8,20}$/
            ) && params.indexOf(val) === -1
          );
        },
        messageReplace: (message, params) =>
          message.replace("", this.helpers.toSentence(params)),
        required: true,
      },
      newpassword: {
        message: "The :attribute must be a valid format.",
        rule: (val, params, validator) => {
          return (
            validator.helpers.testRegex(
              val,
              /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$^+=!*()@%&]).{8,20}$/
            ) && params.indexOf(val) === -1
          );
        },
        messageReplace: (message, params) =>
          message.replace("", this.helpers.toSentence(params)),
        required: true,
      },
      checkPassword: {
        message: "Given :attribute does not match",
        rule: function (val, params) {
          return val === params[0];
        },
      },
    },
  });

  componentDidMount = () => {
    this.setState({ isLoading: true });
    this._getProfileDetails();
  };

  _getProfileDetails = () => {
    let { getUserProfileDetails } = this.props;

    getUserProfileDetails().then((data) => {
      this.setState({ userDetails: data, isLoading: false });
    });
  };

  uploadSingleFile = (e) => {
    let file = e.target.files[0];

    const formData = new FormData();
    formData.append("file", file);
    this.setState({ isfileUploading: true, isLoading: true });

    this.props.fileUpload(formData).then((data) => {
      this.props
        .updateProfilePicture({ profileImageUrl: data[0].fileUrl })
        .then(() => {
          this._getProfileDetails();
          this.setState({ isfileUploading: false, isLoading: false });
        });
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.validator.allValid()) {
      let { changePasswordAfterLogin } = this.props;

      let { newPassword, currentPassword, reenterPassword } = this.state;

      let body = {
        currentPassword: encryptData(currentPassword),
        newPassword: encryptData(newPassword),
        confirmNewPassword: encryptData(reenterPassword),
      };

      changePasswordAfterLogin(body).then(() => {
        this.validator.hideMessages();
        this.setState({
          newPassword: "",
          currentPassword: "",
          reenterPassword: "",
        });
      });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  handleInput = ({ target: { name, value } }) => {
    this.setState({
      [name]: value,
    });
  };

  handleRemovePicture = () => {
    let { removeProfilePicture } = this.props;
    removeProfilePicture().then(() => {
      this._getProfileDetails();
    });
  };

  onEdit = () => {
    let { userDetails } = this.state;
    let {
      mobileNumber = "",
      emailId = "",
      roleTitle = "",
      jobTitle = "",
      contactName = "",
      userRoles = {},
      firstName = "",
      lastName = "",
      profession = "",
    } = userDetails;

    let { userRoleName = "", userGroup = {} } = userRoles;
    if (userGroup.userGroupName === "fpo") {
      let data = {
        firstName: firstName,
        lastName: lastName == "" ? null : lastName,
        mobileNumber: mobileNumber,
        roleTitle: roleTitle,
        emailId: emailId,
      };
      this.setState({
        userData: data,
        isReadOnly: false,
      });
    } else if (userGroup.userGroupName === "individual") {
      let data = {
        contactName: contactName,
        mobileNumber: mobileNumber,
        profession: profession,
        emailId: emailId,
      };
      this.setState({
        userData: data,
        isReadOnly: false,
      });
    } else {
      let data = {
        contactName: contactName,
        mobileNumber: mobileNumber,
        jobTitle: jobTitle,
        emailId: emailId,
      };
      this.setState({
        userData: data,
        isReadOnly: false,
      });
    }
  };

  handleProfile = (e) => {
    let { name, value } = e.target;
    let { userData } = this.state;
    let formData = { ...userData, [name]: value };
    this.setState({
      userData: formData,
    });
  };

  updateProfileDetail = (e) => {
    e.preventDefault();
    if (this.validator.allValid()) {
      let { logoutApi, updateProfile } = this.props;
      let { userData, userDetails } = this.state;
      let body = {
        ...userData,
        isMobileNumberChanged:
          (userDetails.mobileNumber == null ? "" : userDetails.mobileNumber) !==
          userData.mobileNumber
            ? true
            : false,
        isEmailChanged:
          (userDetails.emailId == null ? "" : userDetails.emailId) !==
          userData.emailId
            ? true
            : false,
      };
      updateProfile(body).then(() => {
        if (body.isMobileNumberChanged || body.isEmailChanged) {
          logoutApi().then(() => {
            logout();
          });
        } else {
          this.validator.hideMessages();
          this.setState(
            {
              isReadOnly: true,
            },
            () => this._getProfileDetails()
          );
        }
      });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  render() {
    let {
      userDetails = {},
      userData = {},
      isfileUploading,
      isLoading,
      newPassword,
      currentPassword,
      reenterPassword,
      isReadOnly,
    } = this.state;

    let {
      profileImageUrl = "",
      fpoName = "",
      mobileNumber = "",
      emailId = "",
      roleTitle = "",
      contactName = "",
      jobTitle = "",
      userRoles = {},
      firstName = "",
      lastName = "",
      profession = "",
      organizationType = "",
      organizationName = "",
    } = userDetails;

    let { userRoleName = "", userGroup = {} } = userRoles;

    this.validator.purgeFields();

    return (
      <>
        {!isLoading ? (
          <>
            <div className="individual-profile-area w-100">
              <div className="container-sa w-100">
                <div className="row">
                  <div className="col-md-2 pr-0 d-flex flex-column justify-content-center">
                    <div className="fpo-logo mx-auto">
                      <div className="fpo-logo-area">
                        <div className="poistion-relative">
                          <img
                            src={
                              profileImageUrl !== "" && profileImageUrl !== null
                                ? profileImageUrl
                                : aboutusProfile
                            }
                            className="user-profile"
                          />
                          {profileImageUrl !== "" &&
                          profileImageUrl !== null ? (
                            <div
                              className="remove-area cursor-pointer"
                              onClick={() => this.handleRemovePicture()}
                            >
                              <div className="btn-file-profileImage">
                                <p className="mb-0 d-flex justify-content-center align-items-center flex-column pt-2 h-100">
                                  <i className="icon-Delete text-white fs-20"></i>
                                  {"Delete"}
                                </p>
                              </div>
                            </div>
                          ) : null}
                          {!isfileUploading ? (
                            <div className="update-area">
                              <div className="btn-file-profileImage">
                                <p className="mb-0 d-flex justify-content-center align-items-center flex-column pt-2 h-100">
                                  <img src={camera} alt="camera" />
                                  Update
                                </p>
                                <input
                                  type="file"
                                  className=""
                                  accept=".jpg, .png, .jpeg"
                                  onChange={this.uploadSingleFile}
                                  id="fileUpload"
                                  title=""
                                />
                              </div>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div>
                      <NormalButton
                        label="Edit Profile"
                        mainbg={true}
                        className="w-90 mx-auto fs-14 line-height-26 mt-4"
                        onClick={() => this.onEdit()}
                      />
                    </div>
                  </div>
                  <div className="col-md-10 pl-0">
                    <div className="d-flex align-items-end fpo-name w-100 pl-3">
                      {userGroup.userGroupName === "fpo" ? (
                        <p className="fs-30 line-height-35 mt-1 mb-2 font-weight-bold">
                          {fpoName}
                        </p>
                      ) : userGroup.userGroupName !== "individual" ? (
                        <p className="fs-30 line-height-35 mt-1 mb-2 font-weight-bold">
                          {contactName}
                        </p>
                      ) : userGroup.userGroupName !== "samunnati" ? (
                        <p className="fs-30 line-height-35 mt-1 mb-2 font-weight-bold">
                          {userRoleName}
                        </p>
                      ) : (
                        <p className="fs-30 line-height-35 mt-1 mb-2 font-weight-bold">
                          {organizationName}
                          {"("}
                          {organizationType}
                          {")"}
                        </p>
                      )}
                    </div>
                    {isReadOnly && (
                      <div className="col-md-8">
                        <div className="row mt-3">
                          {userGroup.userGroupName === "fpo" ? (
                            <div className="col-md-6">
                              <p className="fs-14 mb-2 line-height-16 text-grey-5a">
                                {"Contact Person name"}
                              </p>
                              <p className="fs-16 line-height-19 text-black font-weight-bold">
                                {firstName} {lastName}
                              </p>
                            </div>
                          ) : (
                            <div className="col-md-6">
                              <p className="fs-14 mb-2 line-height-16 text-grey-5a">
                                {"Contact Person name"}
                              </p>
                              <p className="fs-16 line-height-19 text-black font-weight-bold">
                                {contactName}
                              </p>
                            </div>
                          )}
                          {userGroup.userGroupName === "fpo" ? (
                            <>
                              <div className="col-md-6">
                                <p className="fs-14 mb-2 line-height-16 text-grey-5a">
                                  Job title
                                </p>
                                <p className="fs-16 line-height-19 text-black font-weight-bold">
                                  {roleTitle ? roleTitle : "-"}
                                </p>
                              </div>
                            </>
                          ) : userGroup.userGroupName === "individual" ? (
                            <>
                              <div className="col-md-6">
                                <p className="fs-14 mb-2 line-height-16 text-grey-5a">
                                  Profession
                                </p>
                                <p className="fs-16 line-height-19 text-black font-weight-bold">
                                  {profession ? profession : "-"}
                                </p>
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="col-md-6">
                                <p className="fs-14 mb-2 line-height-16 text-grey-5a">
                                  Job Title
                                </p>
                                <p className="fs-16 line-height-19 text-black font-weight-bold">
                                  {jobTitle ? jobTitle : "-"}
                                </p>
                              </div>
                            </>
                          )}

                          <div className="col-md-6">
                            <p className="fs-14 mb-2 line-height-16 text-grey-5a">
                              Phone number
                            </p>
                            <p className="fs-16 line-height-19 text-black font-weight-bold">
                              {mobileNumber ? mobileNumber : "-"}
                            </p>
                          </div>
                          <div className="col-md-6">
                            {userGroup.userGroupName === "fpo" ||
                            userGroup.userGroupName === "samunnati" ? (
                              <>
                                <p className="fs-14 mb-2 line-height-16 text-grey-5a">
                                  Email id
                                </p>
                                <p className="fs-16 line-height-19 text-black font-weight-bold">
                                  {emailId}
                                </p>
                              </>
                            ) : (
                              <>
                                <p className="fs-14 mb-2 line-height-16 text-grey-5a">
                                  {"Organization Email id"}
                                </p>
                                <p className="fs-16 line-height-19 text-black font-weight-bold">
                                  {emailId}
                                </p>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="individual-profile">
              {isReadOnly && (
                <div className="container-sa w-100">
                  <div className="row">
                    <div className="col-md-10 ml-auto">
                      <div className="row">
                        <div className="col-md-6">
                          <NormalInput
                            label="Current password"
                            type="text"
                            onChange={this.handleInput}
                            value={currentPassword}
                            placeholder="Enter here"
                            name="currentPassword"
                            required={true}
                          />
                          <div className="row">
                            <div className="col-md-9 ml-auto">
                              {this.validator.message(
                                "current password",
                                currentPassword,
                                "required|min:7|max:15"
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <NormalInput
                            label="New password"
                            type="text"
                            onChange={this.handleInput}
                            value={newPassword}
                            placeholder="Enter here"
                            name="newPassword"
                            required={true}
                          />
                          <div className="row">
                            <div className="col-md-9 ml-auto">
                              {this.validator.message(
                                "password",
                                newPassword,
                                "required|min:7|max:15|newpassword"
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <NormalInput
                            label="Re-enter new password"
                            type="text"
                            onChange={this.handleInput}
                            value={reenterPassword}
                            placeholder="Enter here"
                            name="reenterPassword"
                            required={true}
                          />
                          <div className="row">
                            <div className="col-md-9 ml-auto">
                              {this.validator.message(
                                "password",
                                reenterPassword,
                                `required|min:7|max:15|checkPassword:${newPassword}`
                              )}
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-9 ml-auto">
                              <PasswordStrengthMeter data={reenterPassword} />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-5">
                        <div className="col-md-2 mx-auto">
                          <NormalButton
                            label="Submit"
                            mainbg={true}
                            className="px-3 py-2 w-100 mx-auto fs-14 line-height-26"
                            onClick={(e) => this.handleSubmit(e)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {!isReadOnly && (
                <div className="col-md-6 offset-md-2">
                  <div className="d-flex flex-row mb-2 flex-column registration-area mt-3">
                    <div className="row mb-2">
                      {userGroup.userGroupName === "fpo" ? (
                        <>
                          <div className="col-md-6 mb-4">
                            <label>
                              {"First Name "}
                              <span className="text-danger font-weight-bold">
                                *
                              </span>
                            </label>
                            <input
                              type="text"
                              className="form-control w-100"
                              placeholder="Enter here"
                              name="firstName"
                              value={userData.firstName}
                              onChange={this.handleProfile}
                            />
                            {this.validator.message(
                              "firstName",
                              userData.firstName,
                              "required|alpha_space|min:3|max:30"
                            )}
                          </div>
                          <div className="col-md-6 mb-4">
                            <label>{"Last Name "}</label>
                            <input
                              type="text"
                              className="form-control w-100"
                              placeholder="Enter here"
                              name="lastName"
                              value={userData.lastName}
                              onChange={this.handleProfile}
                            />
                            {this.validator.message(
                              "lastName",
                              userData.lastName,
                              "alpha_space|min:3|max:30"
                            )}
                          </div>
                        </>
                      ) : (
                        <div className="col-md-6 mb-4">
                          <label>
                            {"Contact Name "}
                            <span className="text-danger font-weight-bold">
                              *
                            </span>
                          </label>
                          <input
                            type="text"
                            className="form-control w-100"
                            placeholder="Enter here"
                            name="contactName"
                            value={userData.contactName}
                            onChange={this.handleProfile}
                          />
                          {this.validator.message(
                            "contactName",
                            userData.contactName,
                            "required|alpha_space|min:3|max:30"
                          )}
                        </div>
                      )}
                      <div className="col-md-6 mb-4">
                        <label>
                          {"Phone number "}
                          <span className="text-danger font-weight-bold">
                            *
                          </span>
                        </label>
                        <input
                          type="text"
                          className="form-control w-100"
                          name="mobileNumber"
                          value={userData.mobileNumber}
                          onChange={this.handleProfile}
                          maxLength={10}
                          placeholder="Enter Here"
                        />
                        {this.validator.message(
                          "mobileNumber",
                          userData.mobileNumber,
                          "required|phone"
                        )}
                      </div>
                      <div className="col-md-6 mb-4">
                        <label>
                          {userGroup.userGroupName === "individual"
                            ? "Profession"
                            : "Job Title"}
                          <span className="text-danger font-weight-bold">
                            *
                          </span>
                        </label>
                        <input
                          type="text"
                          className="form-control w-100"
                          name={
                            userGroup.userGroupName === "individual"
                              ? "profession"
                              : userGroup.userGroupName === "fpo"
                              ? "roleTitle"
                              : "jobTitle"
                          }
                          value={
                            userGroup.userGroupName === "individual"
                              ? userData.profession
                              : userGroup.userGroupName === "fpo"
                              ? userData.roleTitle
                              : userData.jobTitle
                          }
                          placeholder="Enter Here"
                          onChange={this.handleProfile}
                        />
                        {this.validator.message(
                          userGroup.userGroupName === "individual"
                            ? "profession"
                            : userGroup.userGroupName === "fpo"
                            ? "roleTitle"
                            : "jobTitle",
                          userGroup.userGroupName === "individual"
                            ? userData.profession
                            : userGroup.userGroupName === "fpo"
                            ? userData.roleTitle
                            : userData.jobTitle,
                          "required|alpha_space|min:3|max:30"
                        )}
                      </div>
                      <div className="col-md-6">
                        <label>
                          {"Email id "}
                          <span className="text-danger font-weight-bold">
                            *
                          </span>
                        </label>
                        <input
                          type="text"
                          className="form-control w-100"
                          name="emailId"
                          value={userData.emailId}
                          placeholder="Enter Here"
                          onChange={this.handleProfile}
                        />
                        {this.validator.message(
                          "emailId",
                          userData.emailId,
                          "required|email"
                        )}
                      </div>
                    </div>
                    <div className="col-12 edit_profile_btn">
                      <NormalButton
                        label="Cancel"
                        mainbg={true}
                        className="px-3 py-2 mx-auto fs-14 line-height-26"
                        onClick={() => this.setState({ isReadOnly: true })}
                      />
                      <NormalButton
                        label="Submit"
                        mainbg={true}
                        className="px-3 py-2  mx-auto fs-14 line-height-26"
                        onClick={(e) => this.updateProfileDetail(e)}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="view-fpo-members">
              <img src={bottomImage} alt="bottomImage" />
            </div>
          </>
        ) : (
          <>
            {/* Loader */}
            <CommonPageLoader />
          </>
        )}
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getUserProfileDetails,
      updateProfilePicture,
      fileUpload,
      changePasswordAfterLogin,
      removeProfilePicture,
      logoutApi,
      updateProfile,
    },
    dispatch
  );
};

export const Profile = connect(null, mapDispatchToProps)(ProfileClass);
