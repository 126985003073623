import React, { Component } from "react";
import { getDescriptionData, letterAvatar } from "service/helperFunctions";
import { Toast } from "service/toast";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  commentLike,
  commentUnLike,
  getForumComment,
  getForumComments,
  forumComment,
  deleteComment,
} from "action/forum";
import { fileUpload } from "action/MemberAct";
import moment from "moment";

export class CommentCardClass extends Component {
  state = {
    isPostComment: false,
    isCommentsList: false,
    forumCommentContent: "",
    isAttachmentUploading: false,
    attachmentUrl: null,
    attachmentImgFileName: "",
    subCommentsIndex: 1,
  };
  handleFile = (e) => {
    let file = e.target.files[0];
    if (file !== undefined) {
      this.setState({ attachmentUrl: null }, () => {
        this.setState({
          isAttachmentUploading: true,
          attachmentImgFileName: "Uploading ....",
          attachmentUrl: file,
        });
        const formData = new FormData();
        formData.append("file", file);

        this.props
          .fileUpload(formData)
          .then((data) => {
            document.getElementById("handleFile").value = null;
            this.setState({
              attachmentUrl: data[0].fileUrl,
              attachmentImgFileName: data[0].fileName,
              isAttachmentUploading: false,
            });
          })
          .catch(() => {
            this.setState({
              attachmentImgFileName: "",
              attachmentUrl: "",
              isAttachmentUploading: false,
            });
          });
      });
    }
  };
  handleForumComment = (id) => {
    let { forumCommentContent, isAttachmentUploading, attachmentUrl } =
      this.state;
    if (forumCommentContent.trim().length !== 0) {
      if (!isAttachmentUploading) {
        let body = {
          forumId: this.props.forumId,
          content: forumCommentContent,
          parentId: id,
        };
        attachmentUrl && (body.attachment = attachmentUrl);
        this.props
          .forumComment(body)
          .then((data) => {
            this.setState({
              forumCommentContent: "",
              attachmentImgFileName: "",
              attachmentUrl: null,
            });
            this.togglePost();
            // this.props.getForumList();
            this.props.handleGetComments();
          })
          .catch(() => {
            this.setState({ isFormLoder: false });
          });
      } else {
        Toast({ type: "error", message: "Please wait Image is uploading" });
      }
    } else {
      Toast({ type: "error", message: "Comment can't be empty" });
    }
  };
  handleCommentLikeUlike = (id) => {
    let { comment } = this.props;
    let body = {
      commentId: id,
    };
    let apiCall = !comment.isLiked
      ? this.props.commentLike(body)
      : this.props.commentUnLike(body);
    apiCall
      .then((data) => {
        this.props.handleGetComments();
      })
      .catch(() => {
        this.setState({ isFormLoder: false });
      });
  };
  handleCommentInput = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  };
  togglePost = () => {
    this.setState({
      isPostComment: !this.state.isPostComment,
    });
  };
  toggleCommentList = () => {
    this.setState({
      isCommentsList: !this.state.isCommentsList,
    });
  };
  handlePagination = (operation) => {
    if (this.props.comment?.subComments?.length > 0) {
      switch (operation) {
        case "+": {
          if (
            this.state.subCommentsIndex <
            this.props.comment?.subComments?.length
          ) {
            this.setState({
              subCommentsIndex: this.state.subCommentsIndex + 1,
            });
          }
          break;
        }
        case "-": {
          if (this.state.subCommentsIndex > 1) {
            this.setState({
              subCommentsIndex: this.state.subCommentsIndex - 1,
            });
          }
          break;
        }
        default: {
          break;
        }
      }
    }
  };
  handleDelete = (id) => {
    this.props.deleteComment(id).then((data) => {
      this.props.handleGetComments();
      this.props.getForumList();
    });
  };
  render() {
    const { comment } = this.props;
    const {
      isPostComment,
      isCommentsList,
      forumCommentContent,
      attachmentImgFileName,
      attachmentUrl,
      subCommentsIndex,
    } = this.state;
    const { subComments } = comment;
    return (
      <>
        <div className="col-md-12 user-profile mb-1">
          <div className="media">
            <img
              className="mr-3"
              src={letterAvatar(comment.userDetails.userName, 39)}
              alt="avatar"
            />
            <div className="media-body">
              <h5 className="my-0 text-black line-height-23 fs-16">
                {comment.userDetails.userName} .
                {` ${moment(comment.createdAt, "YYYY-MM-DD HH:mm:ss").toNow(
                  true
                )} ago`}
              </h5>
            </div>
            {comment.isCommentCreatedByUser && (
              <div className="mr-4">
                <i
                  className="icon-Delete cursor-pointer text-danger"
                  onClick={() => this.handleDelete(comment.id)}
                ></i>
              </div>
            )}
          </div>
        </div>
        {comment.attachment && (
          <div className="col-md-10 ml-5 mt-2">
            <img
              className="card-img comment-img"
              src={comment.attachment && comment.attachment}
              alt="post_img"
            />
          </div>
        )}
        <div className="col-md-12 comments-text">
          <label className="content-sub text-black-1c fs-16 line-height-23">
            {comment.content}
          </label>
        </div>
        <div className="col-md-12 actions-section ">
          <ul className="mb-1">
            <li className="d-flex align-items-center">
              <i
                className={`pr-2 cursor-pointer ${
                  comment.isLiked
                    ? "icon-thumbs-up fs-18"
                    : "icon-thumbs-up-color fs-18"
                } `}
                onClick={() => this.handleCommentLikeUlike(comment.id)}
              />{" "}
              {comment.commentLikesCounts ? comment.commentLikesCounts : 0}
            </li>
            <li className="d-flex align-items-center">
              <i
                className="pr-2 icon-comment fs-18 cursor-pointer"
                onClick={this.toggleCommentList}
              />{" "}
              {comment.commentCounts ? comment.commentCounts : 0}
            </li>
            <li
              className="d-flex align-items-center cursor-pointer"
              onClick={() => this.togglePost()}
            >
              <i className="pr-2 icon-corner-up-left fs-18" />
              {"  Reply"}
            </li>
          </ul>
        </div>
        {isPostComment && (
          <>
            <div className="col-md-11 my-3 position-relative post-comment ml-5">
              <textarea
                className="form-control"
                placeholder="Type here"
                name="forumCommentContent"
                value={forumCommentContent}
                onChange={this.handleCommentInput}
                rows="3"
              ></textarea>
              <div className="attachment">
                <input
                  type="file"
                  className=""
                  accept=".jpg, .png, .jpeg"
                  onChange={this.handleFile}
                  id="handleFile"
                  title=""
                />
                <i className="icon-link fs-18" />{" "}
                {attachmentUrl == null
                  ? "Add attachments"
                  : getDescriptionData(attachmentImgFileName, 15)}
              </div>
            </div>
            <div className="col-md-12 comments-text mb-3">
              <button
                type="button"
                className="btn mainbg-btn post-btn"
                onClick={() => this.handleForumComment(comment.id)}
              >
                Post comment
              </button>
            </div>
          </>
        )}
        <div className="col-md-12 comments-text border-left my-3">
          {isCommentsList && subComments.length > 0 && (
            <>
              <div className="row">
                <div className="col-md-11 user-profile mb-1">
                  <div className="media">
                    <img
                      className="mr-3"
                      src={letterAvatar(
                        subComments[subCommentsIndex - 1].userDetails.userName,
                        39
                      )}
                      alt="avatar"
                    />
                    <div className="media-body">
                      <h5 className="">
                        {subComments[subCommentsIndex - 1].userDetails.userName}{" "}
                        .{" "}
                        {`${moment(
                          subComments[subCommentsIndex - 1]?.createdAt,
                          "YYYY-MM-DD HH:mm:ss"
                        ).toNow(true)} ago`}
                      </h5>
                    </div>
                    {subComments[subCommentsIndex - 1]
                      .isCommentCreatedByUser && (
                      <div className="mr-4">
                        <i
                          className="icon-Delete cursor-pointer text-danger"
                          onClick={() =>
                            this.handleDelete(
                              subComments[subCommentsIndex - 1]?.id
                            )
                          }
                        ></i>
                      </div>
                    )}
                  </div>
                </div>
                {subComments[subCommentsIndex - 1]?.attachment && (
                  <div className="col-md-10 ml-5">
                    <img
                      className="card-img comment-img"
                      src={
                        subComments[subCommentsIndex - 1]?.attachment &&
                        subComments[subCommentsIndex - 1]?.attachment
                      }
                      alt="avatar"
                    />
                  </div>
                )}
                <div className="col-md-11 comments-text">
                  <label className="content-sub">
                    {subComments[subCommentsIndex - 1]?.content}
                  </label>
                </div>
              </div>
              {subComments.length > 1 && (
                <div className="d-flex flex-column align-items-center comment-pagination">
                  <span
                    className={`icon-keyboard-arrow-right fs-25 rotate-90 cursor-pointer ${
                      subCommentsIndex === 1 ? "disabled" : ""
                    }`}
                    onClick={() => this.handlePagination("-")}
                  ></span>
                  <div className="fw-600">
                    {subComments.length - subCommentsIndex}
                  </div>
                  <span
                    className={`icon-keyboard-arrow-left fs-25 rotate-90 cursor-pointer ${
                      subCommentsIndex === subComments.length ? "disabled" : ""
                    }`}
                    onClick={() => this.handlePagination("+")}
                  ></span>
                </div>
              )}
            </>
          )}
        </div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      commentLike,
      commentUnLike,
      getForumComment,
      getForumComments,
      forumComment,
      fileUpload,
      deleteComment,
    },
    dispatch
  );
};

let component = CommentCardClass;

export const CommentCard = connect(null, mapDispatchToProps)(component);
