import React, { Component } from "react";
import { getIncomeTaxById } from "action/ComplianceAct";

import { CommonPageLoader } from "component/common";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { history } from "service/helpers/index";

import { getFilePathDownload } from "service/api";
import { currencyConvert } from "service/helperFunctions";

import moment from "moment";

import "../Licenseview/style.scss";

const arrow_left = require("assets/svg/add_member/arrow_left.svg");

export class IncomeTaxViewClass extends Component {
  constructor(props) {
    super();
    this.state = {
      incomeDetails: {},
      isloading: false,
    };
  }
  componentDidMount() {
    let { id } = this.props;
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    this._getData(id);
  }

  _getData = (id) => {
    let { getIncomeTaxById } = this.props;

    this.setState({ isloading: true });

    getIncomeTaxById(id).then((data) => {
      this.setState({
        incomeDetails: data,
        isloading: false,
      });
    });
  };

  render() {
    let { incomeDetails = {}, isloading } = this.state;

    let {
      consultatName,
      filingStatusFileName,
      filingStatusFileUrl,
      fymYear,
      incomeOrLoss,
      incomeTaxPaid,
      incomeTaxPayable,
      remarks,

      OriginalLicense,
      districtId,
      fpoId,
      licenseImgFileName,
      licenseImgUrl,
      licenseNumber,
      pincodeId,
      stateId,
      storeAddress,
      village,
      validityDate,
      registrationDate,
    } = incomeDetails;

    return (
      <div className="view-bod">
        <div className="d-flex my-3 container-sa px-0">
          <div
            className="d-flex"
            onClick={() => history.push("/fpo/compliance")}
          >
            <img className="cursor-pointer" src={arrow_left} alt="back" />
            <p className="fs-18 font-weight-bold font-Gilroy text-black mb-0 cursor-pointer ml-3">
              {"Back"}
            </p>
          </div>
        </div>
        {!isloading ? (
          <div className="bg-area py-4">
            <div className="container">
              <div className="bg-white p-5 shadow mx-5">
                <div className="row">
                  <div className="col-6">
                    <div className="row">
                      <div className="col-5">
                        <p className="text-black font-weight-bold fs-16">
                          Financial year :
                        </p>
                      </div>
                      <div className="col-7">
                        <p className="text-black-50 fs-16">
                          {fymYear ? fymYear : "-"}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-5">
                        <p className="text-black font-weight-bold fs-16">
                          Income tax payable :
                        </p>
                      </div>
                      <div className="col-7">
                        <p className="text-black-50 fs-16">
                          {incomeTaxPayable
                            ? currencyConvert(incomeTaxPayable)
                            : "-"}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-5">
                        <p className="text-black font-weight-bold fs-16">
                          Consultant name :
                        </p>
                      </div>
                      <div className="col-7">
                        <p className="text-black-50 fs-16">
                          {consultatName ? consultatName : "-"}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-5">
                        <p className="text-black font-weight-bold fs-16">
                          Remarks :
                        </p>
                      </div>
                      <div className="col-7">
                        <p className="text-black-50 fs-16">
                          {remarks ? remarks : "-"}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="row">
                      <div className="col-5">
                        <p className="text-black font-weight-bold fs-16">
                          Taxable Income :
                        </p>
                      </div>
                      <div className="col-7">
                        <p className="text-black-50 fs-16">
                          {incomeOrLoss > 0
                            ? currencyConvert(incomeOrLoss)
                            : "-"}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-5">
                        <p className="text-black font-weight-bold fs-16">
                          Income Tax Paid :
                        </p>
                      </div>
                      <div className="col-7">
                        <p className="text-black-50 fs-16">
                          {incomeTaxPaid ? currencyConvert(incomeTaxPaid) : "-"}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-5">
                        <p className="text-black font-weight-bold fs-16">
                          Income Tax file :
                        </p>
                      </div>
                      <div className="col-7">
                        <p className="text-black-50 fs-16">
                          {filingStatusFileName ? (
                            <a
                              className="fs-16 text-green-18 text-underline cursor-pointer"
                              href={getFilePathDownload + filingStatusFileUrl}
                              download
                            >
                              {"Download"}
                            </a>
                          ) : (
                            <p className="text-black-50 fs-16">{"-"}</p>
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <>
            <CommonPageLoader />
          </>
        )}
        <div className="view-members"></div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getIncomeTaxById,
    },
    dispatch
  );
};
let component = IncomeTaxViewClass;

export const IncomeTaxView = connect(null, mapDispatchToProps)(component);
