import React, { Component, Fragment } from "react";
import { Pagination, CommonPageLoader } from "component/common";
import { getCRPList, getCRPListRm } from "action/FigCrpAct";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { AddCRPPopup } from "../AddCRPPopup";
import CrpCard from "./Card";

export class CRPListClass extends Component {
  state = {
    isLoading: false,
    id: null,
    isPopupOpen: false,
  };

  //handle Change
  handleChange = (val) => {
    let { getCRPListApi, getCRPListRmApi, searchValue, id } = this.props;
    this.setState({ isLoading: true });
    let payload = {
      limit: 10,
      page: val,
      search: searchValue,
    };
    if (id) {
      getCRPListRmApi(id, payload)
        .then((data) => {
          data && this.setState({ isLoading: false });
        })
        .catch(() => this.setState({ isLoading: false }));
    } else {
      getCRPListApi(payload)
        .then((data) => {
          data && this.setState({ isLoading: false });
        })
        .catch(() => this.setState({ isLoading: false }));
    }
  };

  componentDidMount() {
    let { activeTab, isMappingPage = false } = this.props;
    if (isMappingPage && activeTab === "1") {
      this.handleChange(1);
    }
  }

  componentDidUpdate(prevProps) {
    let { activeTab, searchValue, isMappingPage = false } = this.props;
    if (activeTab === "4" || (isMappingPage && activeTab === "1")) {
      searchValue !== prevProps.searchValue && this.handleChange(1);
    }
    if (prevProps.activeTab != activeTab) {
      if (isMappingPage && activeTab === "1") {
        this.handleChange(1);
      }
      if (activeTab === "4") {
        this.handleChange(1);
      }
    }
  }

  handleEdit = (id) => {
    let { isPopupOpen } = this.state;
    this.setState({
      isPopupOpen: !isPopupOpen,
      id: id ? id : null,
    });
  };

  render() {
    let { isLoading, isPopupOpen, id } = this.state;
    let {
      editAccess = false,
      crpMembers = [],
      isMappingPage = false,
      view,
    } = this.props;
    let { results = [], pageMeta = {} } = crpMembers || {};

    return (
      <React.Fragment>
        {!isLoading ? (
          results.length !== 0 ? (
            <div className="d-flex container-sa flex-column flex-wrap mt-3 px-0 justify-content-center">
              <div className="row">
                {results.map((member, index) => {
                  let { name = "", emailId, mobileNumber, crpId } = member;
                  return (
                    <Fragment>
                      {view == "1" ? (
                        <div className="col-12 mb-3" key={index}>
                          <div className="member-strip shadow rounded p-3 d-flex align-items-center w-100">
                            <div className="row w-100">
                              <div
                                className={`${
                                  !isMappingPage ? "col-md-3" : "col-md-4"
                                } col-12 mt-md-0 mt-2`}
                              >
                                <div className="d-flex flex-column justify-content-center h-100">
                                  <div className="member-card-font-content mb-1">
                                    {name}
                                  </div>
                                  <div className="member-card-font-heading">
                                    CRP name
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-4 col-12 mt-md-0 mt-2">
                                <div className="d-flex flex-column justify-content-center h-100">
                                  <div className="member-card-font-content mb-1">
                                    {emailId ? emailId : "-"}
                                  </div>
                                  <div className="member-card-font-heading">
                                    CRP email
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-4 col-12 mt-md-0 mt-2">
                                <div className="d-flex flex-column justify-content-center h-100">
                                  <div className="member-card-font-content mb-1">
                                    {mobileNumber ? mobileNumber : "-"}
                                  </div>
                                  <div className="member-card-font-heading">
                                    Mobile number
                                  </div>
                                </div>
                              </div>
                              {!isMappingPage && (
                                <div className="col-md-1 col-12 mt-md-0 mt-2">
                                  <div className="d-flex align-items-center justify-content-center h-100">
                                    {editAccess && (
                                      <div
                                        className="fs-16 line-height-19 text-black-50 fw-600 cursor-pointer"
                                        onClick={() => this.handleEdit(crpId)}
                                      >
                                        Edit
                                      </div>
                                    )}
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="col-md-3 col-lg-3 col-6  mb-2 mt-3 px-2">
                          <CrpCard {...member} />
                        </div>
                      )}
                    </Fragment>
                  );
                })}
              </div>
              <div className="my-0 w-100">
                {pageMeta.pageCount !== undefined &&
                  pageMeta.pageCount !== 1 && (
                    <Pagination
                      handleChange={this.handleChange}
                      pageMeta={pageMeta}
                    />
                  )}
              </div>
            </div>
          ) : (
            <p className="py-5 mb-0 text-black-50 fs-22 line-height-25 text-center">
              No data found
            </p>
          )
        ) : (
          <CommonPageLoader />
        )}
        <AddCRPPopup
          isOpen={isPopupOpen}
          toggle={() => this.handleEdit()}
          id={id}
          fpoId={this.props.id}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  crpMembers: state.FigCrp.crpMembers,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getCRPListApi: getCRPList,
      getCRPListRmApi: getCRPListRm,
    },
    dispatch
  );
};

export const CRPList = connect(
  mapStateToProps,
  mapDispatchToProps
)(CRPListClass);
