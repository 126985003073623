import React, { Component } from "react";
const farm = require("../../../assets/svg/view_member/farm.svg");

export class Farm extends Component {
  render() {
    let { memebrFarmInfo } = this.props;

    let {
      rabicropmapping = [],
      livecropmapping = [],
      kharifcropmapping = [],
      plotAcreage = "",
    } = memebrFarmInfo;
    return (
      <div>
        {rabicropmapping.length === 0 &&
        livecropmapping.length === 0 &&
        kharifcropmapping.length === 0 ? null : (
          <>
            <div className="d-flex align-items-end justify-content-between mb-3">
              <div className="d-flex align-items-center">
                <img className="mr-3 bg-area-profile" src={farm} alt="farm" />
                <span className="member-profile-card-title">Crops</span>
              </div>
              <div className="d-flex mr-3">
                <p className="text-black-1c fs-16 font-weight-bold mb-0">
                  {"Scroll"}
                  <i className="icon-arrow-down pl-2"></i>
                </p>
              </div>
            </div>
            <div className="member-profile-card rounded shadow farm-card">
              <div className="d-flex flex-wrap justify-content-center align-items-center">
                <div className="col-12">
                  <div className="row">
                    {rabicropmapping.map(
                      (data, index) =>
                        data.cropsDetails !== null && (
                          <div
                            className={`col-md-4 col-6 ${
                              index % 3 == 0 ? "pl-md-0" : ""
                            } ${index % 3 == 2 ? "pr-md-0" : ""}`}
                            key={index}
                          >
                            <div className="d-flex flex-column align-items-center mb-4 pb-2">
                              <img
                                className="mb-1"
                                src={data.cropsDetails.cropIconUrl}
                                alt="wheat"
                                height="64"
                              />
                              <p className="mb-2 fs-12 text-green-51">
                                {data.cropsDetails && data.cropsDetails.cropName
                                  ? data.cropsDetails.cropName
                                  : null}
                              </p>
                              {plotAcreage ? (
                                <div className="member-profile-card-heading">
                                  {plotAcreage} {"acres"}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        )
                    )}
                  </div>
                  <div className="row">
                    {kharifcropmapping.map(
                      (data, index) =>
                        data.cropsDetails !== null && (
                          <div
                            className={`col-md-4 col-6 ${
                              index % 3 == 0 ? "pl-md-0" : ""
                            } ${index % 3 == 2 ? "pr-md-0" : ""}`}
                            key={index}
                          >
                            <div className="d-flex flex-column align-items-center mb-4 pb-2">
                              <img
                                className="mb-1"
                                src={data.cropsDetails.cropIconUrl}
                                alt="wheat"
                                height="64"
                              />
                              <p className="mb-2 fs-12 text-green-51">
                                {data.cropsDetails.cropName}
                              </p>
                              <div className="member-profile-card-heading">
                                {plotAcreage} acres
                              </div>
                            </div>
                          </div>
                        )
                    )}
                  </div>
                  <div className="row">
                    {livecropmapping.map(
                      (data, index) =>
                        data.cropsDetails !== null && (
                          <div
                            className={`col-md-4 col-6 ${
                              index % 3 == 0 ? "pl-md-0" : ""
                            } ${index % 3 == 2 ? "pr-md-0" : ""}`}
                            key={index}
                          >
                            <div className="d-flex flex-column align-items-center mb-4 pb-2">
                              <img
                                className="mb-1"
                                src={data.cropsDetails.cropIconUrl}
                                alt="wheat"
                                height="64"
                              />
                              <p className="mb-2 fs-12 text-green-51">
                                {data.cropsDetails.cropName}
                              </p>
                              <div className="member-profile-card-heading">
                                {plotAcreage} acres
                              </div>
                            </div>
                          </div>
                        )
                    )}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
}
