import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./navbar.scss";
import { history } from "service/helpers";
import { debounceFunction } from "service/debounce";
import { getAllFpoUser } from "action/SearchAct";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import bell from "assets/images/bell.svg";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { logout } from "service/utilities";
import { getGateway, updateBMS, getBMS } from "action/GatewayAct";
import { isFirstTimeLoginOrNot } from "action/MemberAct";
import { getAccessdata } from "service/helperFunctions";
import { logoutApi } from "action/AuthAct";
import {
  membership,
  fpo_docs,
  engage_prof,
  grade_score,
  compliance,
  events_impact,
} from "./icons";
import { getUserProfileDetails } from "action/Acadamy";
import { getNotificationCount } from "action/notification";
import { EventEmitter } from "helpers/EventEmitter";
import { BMSPopup, NormalButton } from "component/common";
import KYMPopup from "../KYMPopup";
import { AddFeedback } from "component/fpo/home/FeedbackContent/AddFeedback";
import SideBar from "./SideBar";

const User_Profile_1 = require("assets/svg/Members/User_Profile_1.svg");
const nav_logo = require("../../../assets/svg/logo.svg");
const nav_user = require("../../../assets/images/default-profile-img.png");
const nav_logo_white = require("../../../assets/svg/logowhite.svg");
const nav_search = require("../../../assets/svg/search-icon.svg");
const apps_icon = require("../../../assets/svg/apps-icon.svg");

const fpo = [
  {
    icon: membership,
    name: "Member Engagement",
    link: "/fpo/member",
    serviceName: "membership",
  },
  {
    icon: fpo_docs,
    name: "FPO Documents",
    link: "/fpo/documents",
    serviceName: "fpoDocuments",
  },
  {
    icon: grade_score,
    name: "Grading Profile",
    link: "/fpo/grading-score",
    serviceName: "gradeProfile",
  },
  {
    icon: compliance,
    name: "Compliance & Governance",
    link: "/fpo/compliance",
    serviceName: "complainceGovernance",
  },
  {
    icon: events_impact,
    name: "Events & Impacts Stories",
    link: "/fpo/events",
    serviceName: "eventsImpacts",
  },
  {
    icon: events_impact,
    name: "Key Financials",
    link: "/fpo/key-financial",
    serviceName: "keyFinancials",
  },
  {
    icon: events_impact,
    name: "Associations & Partnerships",
    link: "/fpo/association",
    serviceName: "associationPartnerships",
  },
];

export class NavbarClass extends Component {
  constructor() {
    super();
    this.state = {
      searchFpo: "",
      color: "",
      dropdownOpen: false,
      searchOpen: true,
      searchResults: [],
      page: 1,
      limit: 8,
      pageMeta: {},
      msg: "",
      active: false,
      fpoDetails: {},
      notifyCount: 0,
      serviceActive: false,
      gateway: [],
      userDetails: {},
      isOpen: false,
      navOpen: false,
      bms: "",
      bmsPopup: false,
      bmsURL: "",
      isRM: false,
      isGradingTool: false,
      kymPopup: false,
    };
  }

  //gateway details
  _getGateway = () => {
    this.props.getGateway().then((data) => {
      if (data) {
        this.setState({
          gateway: data.gatewayServices,
        });
      }
    });
  };

  // _getBms = () => {
  //   this.props.getBMS();
  // };

  _getNotificationCount = () => {
    this.props.getNotificationCount().then((data) => {
      let count = data.nonViewedCount;
      this.setState({
        notifyCount: count,
      });
    });
  };

  handleSearch = (e) => {
    this.setState({ searchFpo: e.target.value, searchResults: [], msg: "" });
    e.target.value !== "" &&
      debounceFunction(() => this.SearchFunction(this.state.searchFpo), 1000);
  };

  SearchFunction = (searchValue) => {
    let body = {
      search: searchValue === "" ? "-" : searchValue,
      page: 1,
      limit: 8,
    };
    searchValue.length > 0 &&
      this.props.getAllFpoUser(body).then((data) => {
        let dataSet = [];
        data.map((result) => {
          dataSet.push({
            profile: result.fpoLogoURL,
            fpoName: result.fpoName,
          });
        });
        data.length === 0
          ? this.setState({ msg: "No results" })
          : this.setState({ msg: "" });
        this.setState({
          searchResults: dataSet,
        });
      });
  };

  toggle = () => {
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen,
    }));
  };

  toggleSearch = () => {
    this.setState((prevState) => ({
      searchOpen: !prevState.searchOpen,
      searchFpo: "",
      searchResults: [],
      msg: "",
    }));
  };

  listenScrollEvent = (e) => {
    let { headerFixed } = this.props;
    let { matches } = window.matchMedia("(min-width: 750px)");
    if ((window.scrollY > 1 || headerFixed) && matches) {
      this.setState({ color: "header-bg" });
    } else {
      this.setState({ color: "" });
    }
  };

  handleDropdown = () => {
    if (!this.state.active) {
      document.addEventListener(
        "click",
        this.handleOutsideClickForProfile,
        false
      );
    } else {
      document.removeEventListener(
        "click",
        this.handleOutsideClickForProfile,
        false
      );
    }
    this.setState((prevState) => ({
      active: !prevState.active,
    }));
  };

  handleOutsideClickForProfile = (e) => {
    if (this.node != null) {
      if (this.node.contains(e.target)) {
        return;
      }
    }
    this.handleDropdown();
  };

  //handle Service Dropdown
  handleServiceDropdown = () => {
    if (!this.state.serviceActive) {
      document.addEventListener(
        "click",
        this.handleOutsideClickForService,
        false
      );
    } else {
      document.removeEventListener(
        "click",
        this.handleOutsideClickForService,
        false
      );
    }
    this.setState((prevState) => ({
      serviceActive: !prevState.serviceActive,
    }));
  };

  handleOutsideClickForService = (e) => {
    if (this.node != null) {
      if (this.node.contains(e.target)) {
        return;
      }
    }
    this.handleServiceDropdown();
  };

  handleLogout = () => {
    this.props.logoutApi().then(() => {
      logout();
    });
  };

  componentDidMount() {
    let isRM = this.props.location?.pathname.includes("/rm");
    let isGradingTool = this.props.location?.pathname.includes("/grading-tool");
    this.setState({ isRM: isRM, isGradingTool: isGradingTool });
    if (!isRM) {
      let { headerFixed, isFirstTimeLoginOrNot } = this.props;
      this.setState({ headerFixed });
      if (headerFixed) {
        this.setState({ color: "header-bg" });
      } else {
        this.setState({ color: "" });
      }
      window.scrollTo({
        top: 1,
      });
      window.addEventListener("scroll", this.listenScrollEvent);
      if (localStorage.getItem("AuthToken")) {
        this._getProfileDetails();
        if (localStorage.getItem("userGroupName") === "fpo") {
          isFirstTimeLoginOrNot().then((data) => {
            let { isUpdated } = data;
            this.setState({ isUpdated, fpoDetails: data });
            EventEmitter.init(localStorage.getItem("userMasterId"));
          });
        }
        EventEmitter.addEventListener(
          "navbar-menu",
          this._getNotificationCount
        );
        this._getNotificationCount();
        if (
          localStorage.getItem("userGroupName") === "samunnati" ||
          localStorage.getItem("userGroupName") === "fpo"
        ) {
          this._getGateway();
          // this._getBms();
        }
      }
    }

    let isLanguage = localStorage.getItem("languagePopup");
    if (isLanguage === "1" || isRM) {
      let addScript = document.createElement("script");
      addScript.setAttribute(
        "src",
        "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
      );
      document.body.appendChild(addScript);
      window.googleTranslateElementInit = this.googleTranslateElementInit;
    }
  }

  googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      {
        pageLanguage: "en",
        includedLanguages: "ta,en,hi,te,ur,gu,kn,mr,or",
        layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE,
      },
      "google_translate_element"
    );
  };

  _getProfileDetails = () => {
    let { getUserProfileDetails } = this.props;

    getUserProfileDetails().then((data) => {
      this.setState({ userDetails: data });
    });
  };

  componentWillUnmount() {
    EventEmitter.removeEvent("navbar-menu");
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps != this.props) {
      let isRM = this.props.location?.pathname.includes("/rm");
      let isGradingTool =
        this.props.location?.pathname.includes("/grading-tool");
      this.setState({
        notifyCount: this.props.notificationCount,
        isRM: isRM,
        isGradingTool: isGradingTool,
      });
    }
    if (
      prevProps.profileImage !== this.props.profileImage &&
      prevProps.profileImage !== null
    ) {
      this._getProfileDetails();
    }
  };

  toggleFeedBack = () => {
    let { isOpen } = this.state;
    this.setState({
      isOpen: !isOpen,
    });
  };

  toggleNav = () => {
    let { navOpen } = this.state;
    this.setState({
      navOpen: !navOpen,
    });
  };

  handleRedirect = (url) => {
    if (this.props.bms) {
      window.open(url, "_blank");
    } else {
      this.setState({
        bmsURL: url,
        bmsPopup: true,
      });
    }
  };

  toggleBms = () => {
    let { bmsPopup } = this.state;
    this.setState({
      bmsPopup: !bmsPopup,
    });
  };

  toggleKYM = () => {
    let { kymPopup } = this.state;
    let isTCAccepted = localStorage.getItem("isTCAccepted") === "true";
    this.setState({
      kymPopup: !isTCAccepted ? false : !kymPopup,
    });
  };

  handleChange = ({ target: { name, value, checked } }) => {
    this.setState({
      bms: checked ? value : "",
    });
  };

  onSubmit = () => {
    let { updateBMS } = this.props;
    let { bmsURL } = this.state;
    // updateBMS().then(() => {
    //   this.toggleBms();
    //   window.open(bmsURL, "_blank");
    // });
  };

  render() {
    let {
      color,
      dropdownOpen,
      searchOpen,
      searchFpo,
      searchResults,
      msg,
      fpoDetails,
      notifyCount,
      active,
      serviceActive,
      gateway = [],
      userDetails,
      isOpen,
      navOpen,
      bms,
      bmsPopup,
      isRM,
      isGradingTool,
      kymPopup,
    } = this.state;
    let { accessData, isLanding = false } = this.props;
    let isLanguage = localStorage.getItem("languagePopup");

    return (
      <React.Fragment>
        {isRM ? (
          <div
            className={`header-navbar mobile-fix ${color} ${
              isLanding ? "d-md-block d-none header-bg" : ""
            }`}
          >
            <div className="container-sa">
              <div className="d-flex justify-content-between align-items-center d-md-none d-block">
                <div className="mr-2 navbar-brand d-md-none d-block">
                  <i
                    className="fas fa-bars text-white mr-2 cursor-pointer"
                    onClick={this.toggleNav}
                  ></i>
                  <img
                    src={nav_logo_white}
                    alt="Bidar.svg"
                    height="45"
                    width="100"
                  />
                </div>
              </div>
              <div className={`navbar px-0`}>
                <span className="mr-2 navbar-brand d-md-block d-none">
                  <img
                    src={nav_logo_white}
                    alt="Bidar.svg"
                    height="45"
                    width="100"
                  />
                </span>
                <div className="d-flex">
                  {isLanguage === "1" && (
                    <div id="google_translate_element" className="mx-2"></div>
                  )}
                  {!isGradingTool ? (
                    <button
                      className="logout_button"
                      onClick={this.handleLogout}
                    >
                      Logout
                    </button>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <>
            {localStorage.getItem("AuthToken") ? (
              <div
                className={`header-navbar mobile-fix ${color} ${
                  isLanding ? "d-md-block d-none header-bg" : ""
                }`}
              >
                <div className="container-sa">
                  <div className="d-flex justify-content-between align-items-center d-md-none d-block">
                    <div className="mr-2 navbar-brand d-md-none d-block">
                      <i
                        className="fas fa-bars text-white mr-2 cursor-pointer"
                        onClick={this.toggleNav}
                      ></i>
                      <img
                        src={nav_logo_white}
                        alt="Bidar.svg"
                        height="45"
                        width="100"
                      />
                    </div>
                    <div className="d-flex">
                      {/* <div id="navbar-menu">
                    <div className="d-flex align-items-center notification-point pr-2 mr-3">
                      <Link to="/academy/notification">
                        <div className="position-relative cursor-pointer">
                          {notifyCount && notifyCount !== 0 ? (
                            <span className="bell-notification">
                              &nbsp;{notifyCount}
                            </span>
                          ) : null}
                          <span className="">
                            <img
                              src={bell}
                              className="notify-bell"
                              alt="bell"
                            />
                          </span>
                        </div>
                      </Link>
                    </div>
                  </div> */}
                      {/* {localStorage.getItem("userGroupName") === "fpo" && (
                    <div
                      className="mr-3 cursor-pointer service-dropdown"
                      onClick={this.handleServiceDropdown}
                    >
                      <img src={apps_icon} alt="apps" />
                      <div
                        className={`dropdown ${serviceActive ? "active" : ""}`}
                      >
                        <div className="row">
                          {gateway &&
                            gateway.map((list, index) => (
                              <div className="col-4" key={index}>
                                <div
                                  className="d-flex flex-column align-items-center cursor-pointer gateway-link mb-3"
                                  onClick={() =>
                                    window.open(list?.redirectURL, "_blank")
                                  }
                                >
                                  <div className="rounded-circle p-3 shadow mb-2 gateway-image-container">
                                    <img
                                      className="gateway-image"
                                      src={list?.icon}
                                      alt="gw-icon.svg"
                                    />
                                  </div>
                                  <p className="gateway-title mb-0">
                                    {list?.title}
                                  </p>
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                  )} */}
                    </div>
                  </div>
                  <div className={`navbar px-0`}>
                    <span className="mr-2 navbar-brand d-md-block d-none">
                      <img
                        src={nav_logo_white}
                        alt="Bidar.svg"
                        height="45"
                        width="100"
                      />
                    </span>
                    <ul className="navbar-nav menu-list mr-auto d-md-flex d-none flex-md-row">
                      <li className="nav-item">
                        <Link
                          className="nav-link"
                          to={
                            localStorage.getItem("userGroupName") !==
                              "samunnati" &&
                            localStorage.getItem("userGroupName") !== "fpo"
                              ? "/other/home"
                              : localStorage.getItem("userRoleName") ===
                                "Relationship Manager"
                              ? "/rm/home"
                              : "/fpo/home"
                          }
                        >
                          {"Home"}
                        </Link>
                      </li>
                      {localStorage.getItem("userGroupName") === "fpo" && (
                        <li className="nav-item">
                          <Link className="nav-link" to="/fpo/about-us">
                            {"About Us"}
                          </Link>
                        </li>
                      )}
                      <li className="nav-item">
                        <Link
                          className="nav-link"
                          to={
                            localStorage.getItem("userGroupName") !==
                              "samunnati" &&
                            localStorage.getItem("userGroupName") !== "fpo"
                              ? "/other/faq"
                              : localStorage.getItem("userRoleName") ===
                                "Relationship Manager"
                              ? "/rm/faq"
                              : "/fpo/faq"
                          }
                        >
                          FAQ
                        </Link>
                      </li>
                      {localStorage.getItem("userGroupName") === "fpo" && (
                        <li className="nav-item" onClick={this.toggleKYM}>
                          <div className="nav-link cursor-pointer">
                            Create KYM user
                          </div>
                        </li>
                      )}
                      {/* {localStorage.getItem("isLighter") != null &&
                    localStorage.getItem("isLighter") != 1 && (
                      <li className="nav-item">
                        <Link className="nav-link" to="/academy/home">
                          {"Academy"}
                        </Link>
                      </li>
                    )}
                  {localStorage.getItem("userRoleName") != "fpo" && (
                    <li className="nav-item">
                      <Link className="nav-link" to="/academy/home">
                        {"Academy"}
                      </Link>
                    </li>
                  )} */}
                      {(localStorage.getItem("userGroupName") === "fpo" ||
                        localStorage.getItem("userGroupName") === "Ceo") &&
                        !window.location.pathname.includes("fpo/home") && (
                          <li className="nav-item my-auto">
                            <UncontrolledDropdown>
                              <DropdownToggle
                                caret
                                tag="span"
                                data-toggle="dropdown"
                                className="btn text-white font-weight-bold py-0 bg-transparent border-transparent"
                              >
                                {"FPO Management"}
                              </DropdownToggle>
                              <DropdownMenu>
                                {fpo.map(
                                  (
                                    { serviceName, icon, name, link },
                                    index
                                  ) => {
                                    let permissionData = getAccessdata(
                                      accessData,
                                      serviceName
                                    );
                                    let { viewAccess = false } = permissionData;
                                    return (
                                      viewAccess && (
                                        <DropdownItem
                                          onClick={() =>
                                            history.push(`${link}`)
                                          }
                                          key={index}
                                        >
                                          <div className="d-flex align-items-center">
                                            <img
                                              src={icon}
                                              className="mr-3"
                                              alt={name}
                                            />
                                            <p className="mb-0">{name}</p>
                                          </div>
                                        </DropdownItem>
                                      )
                                    );
                                  }
                                )}
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </li>
                        )}
                    </ul>
                    {isLanguage === "1" && (
                      <div id="google_translate_element"></div>
                    )}
                    <div className="nav-right d-flex justify-content-md-center align-items-center justify-content-start">
                      {localStorage.getItem("userGroupName") === "fpo" ||
                      localStorage.getItem("userGroupName") === "samunnati" ? (
                        <>
                          <div className="mr-4 position-relative search-nav">
                            <input
                              // autoFocus
                              className="form-control bg-white pr-4"
                              type="text"
                              placeholder="Search other FPOs by name, state"
                              value={searchFpo}
                              onChange={this.handleSearch}
                              name="searchFpo"
                              id="search-area"
                              onKeyUp={(event) => {
                                if (
                                  event.code === "NumpadEnter" ||
                                  event.keyCode === 13
                                ) {
                                  if (searchFpo.length > 0) {
                                    history.push(`/fpo/search/${searchFpo}`);
                                    this.toggleSearch();
                                  }
                                } else if (event.keyCode === 27) {
                                  this.toggleSearch();
                                }
                              }}
                            />
                            {/* list */}
                            <div className="bg-white rounded shadow w-100 position-absolute search-suggestion">
                              {searchResults.length !== 0 ? (
                                searchResults.map((searchResult, index) => {
                                  return (
                                    <div
                                      className="align-items-center cursor-pointer search-result"
                                      onClick={() => {
                                        history.push(
                                          `/fpo/search/${searchResult.fpoName}`
                                        );
                                        this.toggleSearch();
                                      }}
                                      key={index}
                                    >
                                      <div className="col-3">
                                        <img
                                          className="rounded search-suggestion-profile"
                                          src={
                                            searchResult.profile
                                              ? searchResult.profile
                                              : User_Profile_1
                                          }
                                          alt="profile"
                                        />
                                      </div>
                                      <div className="col-9">
                                        {searchResult.fpoName}
                                      </div>
                                    </div>
                                  );
                                })
                              ) : (
                                <div className="w-100">
                                  <div className="col-8 mx-auto text-center">
                                    {msg}
                                  </div>
                                </div>
                              )}
                            </div>
                            <div className="mr-2 cursor-pointer search-area-header">
                              <i className="icon-search" />
                              {/* <img
                              name="search"
                              src={nav_search}
                              alt="search"
                              // onClick={() => {
                              //   if (searchOpen === false) {
                              //     this.toggleSearch();
                              //   } else {
                              //     this.toggleSearch();
                              //     searchFpo.length > 0 &&
                              //       history.push(`/fpo/search/${searchFpo}`);
                              //   }
                              // }}
                            /> */}
                            </div>
                          </div>
                          {/* {searchOpen === true ? (
                        
                      ) : (
                        <div className="mr-4 cursor-pointer">
                          <img
                            name="searchOpen"
                            src={nav_search}
                            alt="search"
                            onClick={this.toggleSearch}
                          />
                        </div>
                      )} */}
                        </>
                      ) : null}
                      {/* <div id="navbar-menu" className="d-md-block d-none">
                    <div className="d-flex align-items-center notification-point pr-2 mr-3">
                      <Link to="/academy/notification">
                        <div className="position-relative cursor-pointer">
                          {notifyCount && notifyCount !== 0 ? (
                            <span className="bell-notification">
                              &nbsp;{notifyCount}
                            </span>
                          ) : null}
                          <span className="">
                            <img
                              src={bell}
                              className="notify-bell"
                              alt="bell"
                            />
                          </span>
                        </div>
                      </Link>
                    </div>
                  </div> */}
                      {/* {localStorage.getItem("userGroupName") === "fpo" && (
                    <div
                      className="mr-3 cursor-pointer service-dropdown d-md-block d-none"
                      onClick={this.handleServiceDropdown}
                    >
                      <img src={apps_icon} alt="apps" />
                      <div
                        className={`dropdown ${serviceActive ? "active" : ""}`}
                      >
                        <div className="row">
                          {gateway &&
                            gateway.map((list, index) => (
                              <div className="col-4" key={index}>
                                <div
                                  className="d-flex flex-column align-items-center cursor-pointer gateway-link mb-3"
                                  onClick={() =>
                                    list?.title === "Manage My Books"
                                      ? this.handleRedirect(list?.redirectURL)
                                      : window.open(list?.redirectURL, "_blank")
                                  }
                                >
                                  <div className="rounded-circle p-3 shadow mb-2 gateway-image-container">
                                    <img
                                      className="gateway-image"
                                      src={list?.icon}
                                      alt="gw-icon.svg"
                                    />
                                  </div>
                                  <p className="gateway-title mb-0">
                                    {list?.title}
                                  </p>
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                  )} */}
                      <div
                        className="cursor-pointer profile profile-align profile-dropdown d-md-block d-none"
                        onClick={this.handleDropdown}
                      >
                        {userDetails.profileImageUrl &&
                        userDetails.profileImageUrl !== null ? (
                          <img
                            className="rounded-circle"
                            src={userDetails.profileImageUrl}
                            alt="user"
                          />
                        ) : (
                          <img
                            className="rounded-circle"
                            src={nav_user}
                            alt="user"
                          />
                        )}
                        <ul className={`dropdown ${active ? "active" : ""}`}>
                          <li
                            className="cursor-pointer"
                            onClick={() => history.push("/fpo/profile")}
                          >
                            {"My profile"}
                          </li>
                          {localStorage.getItem("userGroupName") === "fpo" && (
                            <li
                              className="cursor-pointer pt-0"
                              onClick={() => this.toggleFeedBack()}
                            >
                              Feedback
                            </li>
                          )}
                          {/* <div id="hr-line" className="w-100 m-0"></div> */}
                          <li
                            onClick={() => this.handleLogout()}
                            className="cursor-pointer pt-0"
                          >
                            {"Logout"}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div
                className={`header-navbar mobile-fix pre-header-bg ${
                  isLanding ? "header-bg" : ""
                }`}
              >
                <div className="container-sa">
                  <div className={`navbar px-0`}>
                    <span className="mr-2 navbar-brand">
                      <img
                        src={nav_logo}
                        alt="Bidar.svg"
                        height="45"
                        width="100"
                      />
                    </span>
                    <ul className="navbar-nav menu-list mr-auto d-md-flex d-none flex-row">
                      <li className="nav-item">
                        <Link className="nav-link" to="/landing/home">
                          {"Home"}
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link" to="/landing/faq">
                          {"FAQ"}
                        </Link>
                      </li>
                      {/* <li className="nav-item">
                    <Link className="nav-link" to="/login">
                      {"Academy"}
                    </Link>
                  </li> */}
                    </ul>
                    <div className="d-flex justify-content-center align-items-center">
                      <div>
                        <ul className="navbar-nav menu-list flex-row">
                          <li className="nav-item">
                            <Link className="nav-link" to="/login">
                              {"Login"}
                            </Link>
                          </li>
                          {/* <li className="nav-item">
                            <NormalButton
                              label="Signup"
                              mainbg={true}
                              className="px-3 py-2 fs-14 line-height-26 ml-4 login-button"
                              onClick={() => history.push("/register")}
                            />
                          </li> */}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
        <AddFeedback toggle={this.toggleFeedBack} open={isOpen} />
        <SideBar
          isOpen={navOpen}
          toggle={this.toggleNav}
          accessData={accessData}
          fpo={fpo}
          onLogout={this.handleLogout}
          toggleFeedBack={this.toggleFeedBack}
          profileImg={userDetails.profileImageUrl}
        />
        <BMSPopup
          isOpen={bmsPopup}
          toggle={this.toggleBms}
          onChange={this.handleChange}
          bms={bms}
          onSubmit={this.onSubmit}
        />
        {kymPopup ? (
          <KYMPopup isOpen={kymPopup} toggle={this.toggleKYM} />
        ) : null}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  accessData: state.home.accessData,
  profileImage: state.home.profileImage,
  notificationCount: state.academy.notificationCount,
  bms: state.bms.isBms,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getAllFpoUser,
      isFirstTimeLoginOrNot,
      getNotificationCount,
      getGateway,
      getUserProfileDetails,
      logoutApi,
      updateBMS,
      getBMS,
    },
    dispatch
  );
};
let component = NavbarClass;

export const Navbar = connect(mapStateToProps, mapDispatchToProps)(component);
