import React, { Component } from "react";
import { Navbar } from "component/common";
import { GradingToolComp } from "component/GradingToolComp";
import { UserAuth } from "helpers/UserAuth";
class GradingToolClass extends Component {
  componentDidMount() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }
  render() {
    return (
      <div>
        <div className="normal-header">{/* <Navbar /> */}</div>
        <div>
          <GradingToolComp id={this.props.match.params.id} isFPO={true} />
        </div>
      </div>
    );
  }
}

export const GradingTool = UserAuth(GradingToolClass, {
  service: "gradeProfile",
});
