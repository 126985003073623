import { Sellrequest } from "service/apiVariables";
import { Toast } from "service/toast";
import { api } from "service/api";
import { addQuery } from "service/helperFunctions";

//Get License Dropdown List Items
export const getcommodityList = () => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    api({ ...Sellrequest.getcommodityList })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};
export const getfponameList = () => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    api({ ...Sellrequest.getfponameList })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};
export const sellRequestList = (body) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    api({ ...Sellrequest.sellRequestList, body })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};
// 
export const getfpocommodityList = () => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    api({ ...Sellrequest.getfpocommodityList })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

export const getrmcommodityList = () => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    api({ ...Sellrequest.getrmcommodityList })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

export const getfpomeasurementList = () => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    api({ ...Sellrequest.getfpomeasurementList })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

export const getFpoDetails = () => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    api({ ...Sellrequest.getFpoDetails })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

export const getfpoIndustryist = () => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    api({ ...Sellrequest.getfpoIndustryist })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

export const getfpoStateList = () => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    api({ ...Sellrequest.getfpoStateList })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

// export const getfpoDistrictList  = (id) => (dispatch, getState, { api, Toast }) => {
//   return new Promise((resolve, reject) => {
//     Sellrequest.getfpoDistrictList.addQuery = { key: "id", payload: id };

//     api({ ...Sellrequest.getfpoDistrictList })
//       .then(({ data }) => {
//         resolve(data);
//       })
//       .catch(({ message }) => {
//         reject(Toast({ type: "error", message }));
//       });
//   });
// };

//Get All Registrar API
export const getfpoDistrictList = (id, query) => (dispatch, getState) => {
  Sellrequest.getfpoDistrictList.id = id;
  Sellrequest.getfpoDistrictList.query = query;
  return new Promise((resolve, reject) => {
     addQuery(query, Sellrequest.getfpoDistrictList);
     api({ ...Sellrequest.getfpoDistrictList })
        .then(({ data }) => {
           resolve(data);
        })
        .catch(({ message }) => {
           reject(Toast({ type: 'error', message }));
        });
  });
};

export const getAlRequest = (body) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        api({ ...Sellrequest.getlist, body })
            .then(({ status, message, data }) => {
                resolve(data);
                // Toast({ type: 'success', message, time: 3000 });
            })
            .catch(({ message, status }) => {
                resolve(status);
                reject(Toast({ type: 'error', message }));
            });
    });
};

export const addSellRequest = (body) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
      api({ ...Sellrequest.addRequest, body })
          .then(({ status, message, data }) => {
              resolve(data,status);
              Toast({ type: 'success', message, time: 3000 });
          })
          .catch(({ message, status }) => {
              resolve(status);
              reject(Toast({ type: 'error', message }));
          });
  });
};

export const getMarketCentreList = (body) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
      api({ ...Sellrequest.getMarketCentreList, body })
          .then(({ status, message, data }) => {
              resolve(data,status);
              // Toast({ type: 'success', message, time: 3000 });
          })
          .catch(({ message, status }) => {
              resolve(status);
              reject(Toast({ type: 'error', message }));
          });
  });
};
export const getMarketCentreListRM = (body) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
      api({ ...Sellrequest.getMarketCentreListRM, body })
          .then(({ status, message, data }) => {
              resolve(data,status);
              // Toast({ type: 'success', message, time: 3000 });
          })
          .catch(({ message, status }) => {
              resolve(status);
              reject(Toast({ type: 'error', message }));
          });
  });
};
// List API
export const sellRequestDetail = (id) => (dispatch, getState, { api, Toast }) => {
  return new Promise((resolve, reject) => {
    Sellrequest.sellRequestDetail.addQuery = { key: "id", payload: id };

    api({ ...Sellrequest.sellRequestDetail })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//fileupload
export const fileUpload =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...Sellrequest.fileUpload, body })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

export const addRecommentForm = (body) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
      api({ ...Sellrequest.addRecommentForm, body })
          .then(({ status, message, data }) => {
              resolve(data,status);
              Toast({ type: 'success', message, time: 3000 });
          })
          .catch(({ message, status }) => {
              resolve(status);
              reject(Toast({ type: 'error', message }));
          });
  });
};

export const getStageTwoList = (id) => (dispatch, getState, { api, Toast }) => {
  return new Promise((resolve, reject) => {
    Sellrequest.getStageTwoList.addQuery = { key: "id", payload: id };

    api({ ...Sellrequest.getStageTwoList })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

export const stageTwoAction = (body) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
      api({ ...Sellrequest.stageTwoAction, body })
          .then(({ status, message, data }) => {
              resolve(data,status);
              Toast({ type: 'success', message, time: 3000 });
          })
          .catch(({ message, status }) => {
              resolve(status);
              reject(Toast({ type: 'error', message }));
          });
  });
};

export const dashboardImpact = (body) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
      api({ ...Sellrequest.dashboardImpact, body })
          .then(({ status, message, data }) => {
              resolve(data,status);
              // Toast({ type: 'success', message, time: 3000 });
          })
          .catch(({ message, status }) => {
              resolve(status);
              reject(Toast({ type: 'error', message }));
          });
  });
};
