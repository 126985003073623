import React, { Fragment } from "react";
import { NavLink } from "react-router-dom";
import "./RMSidebar.scss";

const nav_user = require("assets/images/default-profile-img.png");

const RMSidebar = (props) => {
  const { isOpen, toggle, onLogout, profileImg = false, module, isRM } = props;

  const routeList = [
    { title: "Home", link: `/${module}/home` },
    { title: "Grading Survey", link: `/${module}/rm-grading` },
    { title: "KYM Survey", link: `/${module}/kym-new` },
  ];

  return (
    <Fragment>
      <div className={`rm_sidebar ${isOpen ? "open" : ""}`}>
        <div className="user-profile-pic">
          <b>Hi {localStorage.getItem("userName")}</b>
          <img src={profileImg ? profileImg : nav_user} alt="user" />
        </div>
        <ul>
          {routeList.map(({ title, link }, index) => {
            if (!isRM && (index === 0 || index === 1)) return null;
            return (
              <NavLink key={index} to={link} onClick={toggle}>
                <li>{title}</li>
              </NavLink>
            );
          })}
          <li onClick={onLogout}>Logout</li>
        </ul>
      </div>
      <div
        className={`nav-overlay ${isOpen ? "open" : ""}`}
        onClick={() => toggle()}
      ></div>
    </Fragment>
  );
};

export default RMSidebar;
