import React, { Component } from "react";
import { history } from "service/helpers";
import arrow_up from "assets/svg/weather/arrow_up.svg";
import cloud from "assets/svg/weather/cloud.svg";
import "./local_weather.scss";
import { Collapse } from "reactstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getWeatherDetailsForHome } from "action/PricingandWeatherAct";
export class LocalWeatherClass extends Component {
  state = {
    collapseType: true,
    fpoLocation: {},
    hourlyWeatherReport: {},
    isLoading: false,
  };

  toggleCollapse = () => {
    //Local Weather open or close action
    let collapseType = !this.state.collapseType;
    this.setState({ collapseType });
  };

  componentDidMount() {
    let { getWeatherDetailsForHome } = this.props;
    this.setState({ isLoading: true });
    getWeatherDetailsForHome()
      .then((data) => {
        if (
          data.HourlyWeatherReport === null ||
          data.HourlyWeatherReport === undefined
        ) {
          this.setState({
            fpoLocation: data.fpoLocation,
            hourlyWeatherReport: {
              temperature: "",
              wxphraselong: "",
              iconcode: "",
            },
            isLoading: false,
          });
        } else if (data !== null) {
          this.setState({
            fpoLocation: data.fpoLocation,
            hourlyWeatherReport: data.HourlyWeatherReport,
            isLoading: false,
          });
        } else {
          this.setState({
            fpoLocation: {
              Village: "",
              districtNames: {},
              stateMaster: {},
            },
            hourlyWeatherReport: {
              temperature: "",
              wxphraselong: "",
              iconcode: "",
            },
            isLoading: false,
          });
        }
      })
      .catch(() => {
        this.setState({ isLoading: false });
      });
  }

  render() {
    let {
      collapseType,
      fpoLocation = {},
      hourlyWeatherReport,
      isLoading,
    } = this.state;

    let { districtNames = {}, stateMaster = {} } =
      fpoLocation !== null ? fpoLocation : {};

    let {
      temperature = "",
      wxphraselong = "",
      iconcode = "",
    } = hourlyWeatherReport;
    return (
      <div className="background-weather p-3">
        <div
          className="d-flex flex-column rounded"
          data-toggle={collapseType}
          onClick={() => this.toggleCollapse(`collapseType`)}
        >
          <div className="d-flex justify-content-between">
            <div className="primary-color-dark weather-card-header">
              {"Local Weather"}
            </div>
            <div className=" d-flex justify-content-center">
              <img
                src={arrow_up}
                alt="arrow.svg"
                className={`${
                  collapseType ? "rotate-0" : "rotate-180"
                } arrow-size`}
              />
            </div>
          </div>
        </div>
        <Collapse isOpen={collapseType}>
          {!isLoading ? (
            <>
              <div className="mb-3 d-flex justify-content-between">
                <p className="weather-card-sub mb-0">
                  {districtNames ? districtNames.districtName : ""}
                  {districtNames && stateMaster ? "," : ""}
                  {stateMaster ? stateMaster.stateName : ""}
                </p>
                <p
                  className="weather-card-sub mb-0 cursor-pointer"
                  onClick={() => window.open("/fpo/weather", "_blank")}
                >
                  {"View details"}
                </p>
              </div>
              {temperature !== "" && wxphraselong !== "" && iconcode !== "" ? (
                <div className="d-flex justify-content-between">
                  <div className="primary-color-dark d-flex flex-column justify-content-center">
                    <div className="weather-card-temp">{temperature}&deg;</div>
                    <div className="weather-card-climate">{wxphraselong}</div>
                  </div>
                  <div className=" d-flex justify-content-center">
                    <img
                      height="100"
                      width="100"
                      src={require(`assets/svg/WeatherSvgs/${
                        iconcode.toString().length == 1
                          ? "0" + iconcode
                          : iconcode
                      }.svg`)}
                      alt="cloud.svg"
                    />
                  </div>
                </div>
              ) : (
                <div className="d-flex justify-content-center align-items-center">
                  <p className="d-flex justify-content-center align-items-center text-black-50 fw-700 fs-18 py-4">
                    {"No data here !!!"}
                  </p>
                </div>
              )}
            </>
          ) : (
            <div className="d-flex justify-content-center align-items-center py-5">
              <div className="spinner-border ml-2 spinner-border-sm"></div>
            </div>
          )}
        </Collapse>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getWeatherDetailsForHome,
    },
    dispatch
  );
};

export const LocalWeather = connect(
  null,
  mapDispatchToProps
)(LocalWeatherClass);
