import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import arrow_left from 'assets/svg/add_member/arrow_left.svg';
import { connect } from 'react-redux';
import { Pagination } from 'component/Faq/FaqView/pagination';
import { CourseCard } from "./CourseCard"
import { history } from "service/helpers";
import { getAllCourseList } from "action/Acadamy"
import { CommonPageLoader } from "component/common"
import "./courseCard.scss"

export class ViewAllCourseClass extends Component {
    state = {
        courseList: [],
        pageMeta: {},
        isLoading: false
    }

    //life cycles
    componentDidMount() {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
        this._getAllCourseList(1)
    }

    //get all course list
    _getAllCourseList = (page) => {
        let { getAllCourseList } = this.props
        this.setState({ isLoading: true })
        getAllCourseList({ page }).then((data) => {
            this.setState({
                courseList: data.results,
                pageMeta: data.pageMeta,
                isLoading: false
            })
        })
    }

    //handle page change
    handlePageChange = (page) => {
        this._getAllCourseList(page)
    }

    render() {
        let { courseList, pageMeta, isLoading } = this.state
        return (
            <>
                <div className="container-sa">
                    <div className='d-flex py-3 w-100'>
                        <div className='d-flex' onClick={() => {
                            history.push('/academy/home');
                        }} >
                            <img
                                className='cursor-pointer'
                                src={arrow_left}
                                alt='back'
                            />
                            <p className='ml-3 text-black fs-18 line-height-25 font-weight-bold mb-0 cursor-pointer'>
                                {'Courses'}
                            </p>
                        </div>
                    </div>
                </div>
                <section className="trending-view-all">
                    <div className="container">
                        {!isLoading ?
                            <div className="row py-5">
                                <div className="col-12">
                                    <div className="row">
                                        {courseList.map((list) => (
                                            <div className="col-4 mb-3">
                                                <CourseCard
                                                    videoDetails={list}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <Pagination
                                    handleChange={this.handlePageChange}
                                    pageMeta={pageMeta}
                                />
                            </div>
                            :
                            <>
                                {/* loading */}
                                <CommonPageLoader />
                            </>
                        }
                    </div>
                </section>
            </>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        getAllCourseList
    }, dispatch)
}

let component = ViewAllCourseClass;

export const ViewAllCourse = connect(null, mapDispatchToProps)(component)
