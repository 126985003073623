import React, { useEffect } from "react";
import { withRouter } from "react-router";
import BackButton from "component/common/BackButton";
import MemberDetails from "component/RM/RMKYMNew/MemberDetails";

const KYMMemberDetailsPage = (props) => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  });

  return (
    <div>
      <div className="inner-header">
        <div className="h-100 w-100">
          <p className="rm_title d-flex justify-content-center align-items-center h-100 w-100 text-white fs-32 font-weight-bold">
            KYM Survey Form
          </p>
        </div>
      </div>
      <BackButton />
      <MemberDetails id={props.match.params.id} module="kym" />
    </div>
  );
};

export default withRouter(KYMMemberDetailsPage);
