import React, { Component } from "react";
import { Navbar } from "component/common";
import { ViewPageforEventsandImpacts } from "component/fpo/events";
import { SuccessStoryPre } from "component/fpo/events";
import { UserAuth } from "helpers/UserAuth";
class EventsandImpactsViewClass extends Component {
  //lifecycles
  componentDidMount = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  render() {
    return (
      <div>
        <div className="normal-header">{/* <Navbar /> */}</div>
        {localStorage.getItem("AuthToken") ? (
          <ViewPageforEventsandImpacts
            name={this.props.match.params.name}
            id={this.props.match.params.id}
          />
        ) : (
          <SuccessStoryPre
            name={this.props.match.params.name}
            id={this.props.match.params.id}
          />
        )}
      </div>
    );
  }
}

export const EventsandImpactsView = UserAuth(EventsandImpactsViewClass, {
  service: "eventsImpacts",
});
