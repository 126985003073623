import React, { Component } from "react";
const live = require("../../../assets/svg/view_member/live.svg");
const cow = require("../../../assets/svg/view_member/cow.svg");
export class LiveStock extends Component {
  render() {
    let { liveStock } = this.props;
    return (
      <div>
        <div className="d-flex align-items-end justify-content-between mb-3">
          <div className="d-flex align-items-center">
            <img className="mr-3 bg-area-profile" src={live} alt="live" />
            <span className="member-profile-card-title">Livestock</span>
          </div>
          <div className="d-flex mr-3">
            <p className="text-black-1c fs-16 font-weight-bold mb-0">
              {"Scroll"}
              <i className="icon-arrow-down pl-2"></i>
            </p>
          </div>
        </div>
        <div className="member-profile-card rounded shadow livestock-card">
          {liveStock.map((list, index) => (
            <div
              key={index}
              className={`d-flex justify-content-center flex-column ${
                index + 1 == liveStock.length ? "mb-0" : "mb-5"
              }`}
            >
              <div className="d-flex align-items-center mr-5 mb-3">
                <img className="mr-4" src={cow} alt="cow" />
                <div className="member-profile-card-heading fs-26">
                  {list.liveStockMaster?.liveStockName}
                </div>
              </div>
              <div className="d-flex justify-content-between">
                <div>
                  <div className="member-assets-card-heading pb-1">
                    {"Count"}
                  </div>
                  <div className="member-profile-card-heading">
                    {list.liveStockCount ? list.liveStockCount : "-"}
                  </div>
                </div>
                <div>
                  <div className="member-assets-card-heading pb-1">
                    {"Avg Age"}
                  </div>
                  <div className="member-profile-card-heading">
                    {list.liveStockAvgAge ? list.liveStockAvgAge : "-"}
                  </div>
                </div>
                <div>
                  <div className="member-assets-card-heading pb-1">
                    {"Insurance"}
                  </div>
                  <div className="member-profile-card-heading">
                    {list.liveStockInsurance ? list.liveStockInsurance : "-"}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}
