import React, { Component } from "react";
import { connect } from "react-redux";
import { getDescriptionData } from "service/helperFunctions";
import { Pagination } from "component/Faq/FaqView/pagination";
export class SavedForumListClass extends Component {
  render() {
    let { forumList = [], pageMeta = {}, handlePageChange } = this.props;
    return (
      <>
        {forumList.length !== 0 ? (
          <div className="saved-forum-card p-4">
            <h5 className="fs-18 text-black line-height-26 mb-0 font-weight-bold">
              Saved post
            </h5>
            {forumList.map((list, index) => (
              <div className="list-group-item" key={index}>
                {list.categoryName ? (
                  <div className="row">
                    <div className="col-11 ml-auto px-2">
                      <div className="d-flex justify-content-between">
                        <h5 className="fs-16 font-weight-bold text-gold-d0 mb-2">
                          {getDescriptionData(list.categoryName, 40)}
                        </h5>
                      </div>
                    </div>
                  </div>
                ) : null}
                <div
                  className="d-flex cursor-pointer"
                  onClick={() => this.props.handleSavedId(list.forumId)}
                >
                  <span className="bullet-point float-left"></span>
                  {list.title ? (
                    <p className="fs-16 line-height-24 text-black-50 mb-1 ">
                      {getDescriptionData(list.title, 75)}
                    </p>
                  ) : (
                    "------"
                  )}
                </div>
              </div>
            ))}
            <Pagination handleChange={handlePageChange} pageMeta={pageMeta} />
          </div>
        ) : null}
      </>
    );
  }
}

export const SavedForumList = connect(null, null)(SavedForumListClass);
