import React, { Component } from "react";
import "./select.scss";
export class NormalSelect extends Component {
  render() {
    let {
      className = "form-control select-form-control w-100",
      options = [],
      keyName = "label",
      valueName = "value",
      handleChange,
      value = "",
      name = "",
      placeholder = "Select",
      disabled = false,
      arrow = false,
      isReadOnly = false,
      label = "",
      isFullWidth = false,
      isHalfWidth = false,
      isHalfWidthForMemberProfile = false,
      isFullWithForMemberProfile = false,
      isFullWidthForCompliance = false,
      isFullWidthwithoutLabel = false,
      isEndArea = false,
      required = false,
      labelClassName="fs-16 text-black-32 font-weight-bold line-height-19"
    } = this.props;
    return (
      <>
        {!isReadOnly ? (
          <div className={`${arrow ? "select-section" : "Select"}`}>
            <form
              autocomplete="off"
              onSubmit={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              <div className="row d-flex align-items-center">
                {label !== "" && (
                  <div
                    className={`${
                      isFullWidth
                        ? "col-md-2"
                        : isHalfWidth || isEndArea
                        ? "col-md-4"
                        : isFullWithForMemberProfile || isFullWidthForCompliance
                        ? "col-md-2"
                        : "col-md-3"
                    } pr-0 `}
                  >
                    {label !== "" ? (
                      <div className={labelClassName}>
                        {label}
                        {required ? (
                          <span className="text-danger font-weight-bold pl-2 fs-18">
                            *
                          </span>
                        ) : null}
                      </div>
                    ) : null}
                  </div>
                )}
                <div
                  className={`${
                    isFullWidth ||
                    isFullWithForMemberProfile ||
                    isFullWidthForCompliance
                      ? "col-md-10"
                      : isHalfWidth || isEndArea
                      ? "col-md-8"
                      : isHalfWidthForMemberProfile
                      ? "col-md-8"
                      : isFullWidthwithoutLabel
                      ? "col-md-12"
                      : "col-md-9"
                  }  ${isFullWidthForCompliance && "pl-3"}`}
                >
                  <select
                    className={className}
                    value={value}
                    disabled={disabled}
                    onChange={(e) => {
                      let body = {
                        target: {
                          name: e.target.name,
                          value: e.target.value, // === "" ? e.target.value : Number(e.target.value)
                        },
                      };
                      handleChange(body);
                    }}
                    name={name}
                  >
                    <option value="" disabled defaultValue>
                      {placeholder}
                    </option>
                    {options.length !== 0 ? (
                      options.map((option, index) => (
                        <option value={option[valueName]} key={index}>
                          {option[keyName]}
                        </option>
                      ))
                    ) : (
                      <option value="" disabled>
                        No Options
                      </option>
                    )}
                  </select>
                  {arrow && <span className="icon-down down-icon fs-16"></span>}
                </div>
              </div>
            </form>
          </div>
        ) : (
          <p>{value}</p>
        )}
      </>
    );
  }
}
