import React, { Component, Fragment } from "react";
import { Pagination, CommonPageLoader } from "component/common";
import { getFIGList, getFIGListRm } from "action/FigCrpAct";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { MapFIGPopup } from "./MapFIGPopup";
import { FIGViewPopup } from "./MapFIGPopup/FIGViewPopup";
import { AddFIGPopup } from "../AddFIGPopup";
import { DeleteFIGPopup } from "./DeleteFIG";
import FigCard from "../FIGList/Card";

export class FIGListMappingClass extends Component {
  state = {
    isLoading: false,
    id: null,
    isOpen: false,
    fig: null,
    isPopupOpen: false,
    isViewPopupOpen: false,
    isDeletePopup: false,
  };

  //handle Change
  handleChange = (val) => {
    let { limit, getFIGListApi, getFIGListRmApi, searchValue, id } = this.props;
    this.setState({ isLoading: true });
    let payload = {
      limit: limit,
      page: val,
      search: searchValue,
    };
    if (id) {
      getFIGListRmApi(id, payload)
        .then((data) => {
          data && this.setState({ isLoading: false });
        })
        .catch(() => this.setState({ isLoading: false }));
    } else {
      getFIGListApi(payload)
        .then((data) => {
          data && this.setState({ isLoading: false });
        })
        .catch(() => this.setState({ isLoading: false }));
    }
  };

  handleListData = () => {
    this.setState({ isLoading: true });
    let { getFIGListApi } = this.props;
    let payload = { limit: 10, page: 1, search: "" };
    getFIGListApi(payload);
    this.setState({ isLoading: false });
  };

  componentDidUpdate(prevProps) {
    if (this.props.activeTab == 3) {
      const { searchPage } = this.props;
      this.props.activeTab != prevProps.activeTab && this.handleChange(1);
      this.props.limit !== prevProps.limit && this.handleChange(1);
      this.props.searchValue !== prevProps.searchValue && this.handleChange(1);
      this.props.searchPage !== prevProps.searchPage &&
        this.handleChange(searchPage ? searchPage : 1);
    }
  }

  handleEdit = (id) => {
    let { isPopupOpen } = this.state;
    this.setState({
      isPopupOpen: !isPopupOpen,
      id: id ? id : null,
    });
  };

  handleView = (id) => {
    let { isViewPopupOpen } = this.state;
    this.setState({
      isViewPopupOpen: !isViewPopupOpen,
      id: id ? id : null,
    });
  };

  toggleFig = (id = null) => {
    let { isOpen } = this.state;
    this.setState({
      isOpen: !isOpen,
      fig: id,
    });
  };

  handleDelete = (id) => {
    let { isDeletePopup } = this.state;
    this.setState({
      isDeletePopup: !isDeletePopup,
      id: id ? id : null,
    });
  };

  render() {
    let {
      isLoading,
      isPopupOpen,
      id,
      isViewPopupOpen,
      isOpen,
      fig,
      isDeletePopup,
    } = this.state;
    let { editAccess = false, figMembers = {}, view } = this.props;
    let { results = [], pageMeta = {} } = figMembers || {};
    return (
      <Fragment>
        {!isLoading ? (
          <Fragment>
            {view === "1" ? (
              <div className="scroll-x">
                <div style={{ minWidth: "1100px" }}>
                  <div className="contaienr-sa">
                    <div className="table_head">
                      <div className="row">
                        <div className="col-3">
                          <b>FIG Name</b>
                        </div>
                        <div className="col-2">
                          <b>FIG Lead Name</b>
                        </div>
                        <div className="col-2">
                          <b>FIG Mobile</b>
                        </div>
                        <div className="col-2 d-flex justify-content-center">
                          <b>Members Mapped</b>
                        </div>
                        <div className="col-3 d-flex justify-content-center">
                          <b>Action</b>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex container-sa flex-column flex-wrap mt-3 px-0 justify-content-center">
                    {results && results.length > 0 ? (
                      results.map((member, index) => {
                        let {
                          name = "",
                          emailId,
                          figId,
                          leadName,
                          mobileNumber,
                          totalMemberMapped = 0,
                        } = member;
                        return (
                          <div className="mb-3" key={index}>
                            <div className="member-strip shadow rounded p-3 d-flex align-items-center w-100">
                              <div className="row w-100">
                                <div className="col-3 mt-md-0 mt-2">
                                  <div className="d-flex flex-column justify-content-center h-100">
                                    <div className="member-card-font-content mb-2">
                                      {name}
                                    </div>
                                    {/* <div className="member-card-font-heading">
                                      FIG name
                                    </div> */}
                                  </div>
                                </div>
                                <div className="col-2 mt-md-0 mt-2">
                                  <div className="d-flex flex-column justify-content-center h-100">
                                    <div className="member-card-font-content mb-2">
                                      {leadName ? leadName : "-"}
                                    </div>
                                    {/* <div className="member-card-font-heading">
                                      FIG Lead name
                                    </div> */}
                                  </div>
                                </div>
                                <div className="col-2 mt-md-0 mt-2">
                                  <div className="d-flex flex-column justify-content-center h-100">
                                    <div className="member-card-font-content mb-2">
                                      {mobileNumber ? mobileNumber : "-"}
                                    </div>
                                    {/* <div className="member-card-font-heading">
                                      FIG email
                                    </div> */}
                                  </div>
                                </div>
                                <div className="col-2 d-flex justify-content-center mt-md-0 mt-2">
                                  <div className="member-card-font-content mb-2">
                                    {totalMemberMapped}
                                  </div>
                                </div>
                                <div className="col-3 mt-md-0 mt-2">
                                  <div className="d-flex align-items-center justify-content-center h-100">
                                    <div
                                      className="mr-4 cursor-pointer fs-16 fw-600 text-black-50"
                                      onClick={() => this.handleView(figId)}
                                    >
                                      View
                                    </div>
                                    {editAccess && (
                                      <Fragment>
                                        <div
                                          className="fs-16 line-height-19 mr-3 text-black-50 fw-600 cursor-pointer"
                                          onClick={() => this.toggleFig(figId)}
                                        >
                                          Edit
                                        </div>
                                        <div
                                          className="fs-16 line-height-19 mr-3 text-danger fw-600 cursor-pointer"
                                          onClick={() =>
                                            this.handleDelete(figId)
                                          }
                                        >
                                          Delete
                                        </div>
                                        <div
                                          className="fs-16 text-black-50 fw-600 cursor-pointer"
                                          onClick={() => this.handleEdit(figId)}
                                        >
                                          Map Member's
                                        </div>
                                      </Fragment>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <div className="col-12">
                        <p className="py-5 mb-0 text-black-50 fs-22 line-height-25 text-center">
                          No data found
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <div className="row">
                {results && results.length > 0 ? (
                  results.map((member, index) => {
                    return (
                      <div
                        className="col-md-4 col-lg-3 mb-2 mt-3 px-2"
                        key={index}
                      >
                        <FigCard
                          {...member}
                          onEdit={(e) => this.handleEdit(e)}
                          onView={(e) => this.handleView(e)}
                          isMapping={true}
                          editAccess={editAccess}
                        />
                      </div>
                    );
                  })
                ) : (
                  <div className="col-12">
                    <p className="py-5 mb-0 text-black-50 fs-22 line-height-25 text-center">
                      No data found
                    </p>
                  </div>
                )}
              </div>
            )}
            <div className="my-0 w-100">
              {pageMeta.pageCount !== undefined && pageMeta.pageCount !== 1 && (
                <Pagination
                  handleChange={this.handleChange}
                  pageMeta={pageMeta}
                />
              )}
            </div>
          </Fragment>
        ) : (
          <CommonPageLoader />
        )}
        <MapFIGPopup
          isOpen={isPopupOpen}
          toggle={() => this.handleEdit()}
          id={id}
          fpoId={this.props.id}
        />
        <FIGViewPopup
          isOpen={isViewPopupOpen}
          toggle={() => this.handleView()}
          id={id}
        />
        <DeleteFIGPopup
          isOpen={isDeletePopup}
          toggle={() => this.handleDelete()}
          id={id}
          handleChange={() => this.handleListData()}
        />
        <AddFIGPopup
          isOpen={isOpen}
          toggle={() => this.toggleFig()}
          id={fig}
          fpoId={this.props.id}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  figMembers: state.FigCrp.figMembers,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getFIGListApi: getFIGList,
      getFIGListRmApi: getFIGListRm,
    },
    dispatch
  );
};

export const FIGListMapping = connect(
  mapStateToProps,
  mapDispatchToProps
)(FIGListMappingClass);
