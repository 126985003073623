import React, { Component } from "react";
import './dashboard.scss';
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
Highcharts.setOptions({
    lang: {
        thousandsSep: ',',
    }
});
export class Barchart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {}
        }
    }
    componentDidMount() {

        setTimeout(() => {
            this.setState({ data: this.props.options });
        }, 10);
    }

    render() {
        const {
            data
        } = this.state;
        return (
            <div>

                <HighchartsReact
                    highcharts={Highcharts}
                    options={data}
                />


            </div>
        );
    }
}