import React, { Fragment, Component } from "react";
import { CommonPageLoader } from "component/common";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { getperformancedata } from "action/KeyFinancialAct";
import { formatToINRWithoutSymbol } from "service/helperFunctions";
import { Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import { getperformancedataById } from "action/RmAct";
import "./style.scss";

export default class PerformanceTabClass extends Component {
  state = {
    isLoading: false,
    performanceData: [],
    innerTabs: 1,
    yearArray: [],
  };

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      if (this.props.activeTab == 5 && this.props.financialsTabs == 3) {
        this._getPerformancelistData();
      }
    }
  }

  _getPerformancelistData = () => {
    this.setState({ isLoading: true });
    let { getperformancedataById, id, getperformancedata } = this.props;
    if (id) {
      getperformancedataById(id).then((data) => {
        this._setChartData(data);
        let yearList = [];
        data.map((list) => {
          yearList.push({ year: list.year });
        });
        this.setState({ yearArray: yearList });
      });
    } else {
      getperformancedata().then((data) => {
        this._setChartData(data);
        let yearList = [];
        data.map((list) => {
          yearList.push({ year: list.year });
        });
        this.setState({ yearArray: yearList });
      });
    }
  };

  _setChartData = (data) => {
    let performanceDataList = [];
    let { innerTabs } = this.state;
    data.map((list, index) => {
      if (innerTabs === index + 1) {
        list.tableData.map((list) => {
          if (list.thresholdValues.parameter === "Revenues") {
            let tabledata = this.setoptionData(list, "Revenue");
            performanceDataList.push(tabledata);
          } else if (list.thresholdValues.parameter === "shareHolderFund") {
            let tabledata = this.setoptionData(list, "Shareholders’s Fund");
            performanceDataList.push(tabledata);
          } else if (list.thresholdValues.parameter === "PAT") {
            let tabledata = this.setoptionData(list, "Profit After Tax");
            performanceDataList.push(tabledata);
          } else if (list.thresholdValues.parameter === "balancesheetSize") {
            let tabledata = this.setoptionData(list, "Balance Sheet Size");
            performanceDataList.push(tabledata);
          }
        });
      }
    });
    this.setState({ performanceData: performanceDataList, isLoading: false });
    if (performanceDataList.length == data.length) {
    }
  };

  setoptionData = (data, title) => {
    let optionsData = {
      chart: {
        type: "gauge",
        height: 300,
        plotBackgroundColor: null,
        plotBackgroundImage: null,
        plotBorderWidth: 0,
        plotShadow: false,
      },

      title: {
        text: "",
      },

      pane: {
        startAngle: -150,
        endAngle: 150,
        center: ["50%", "50%"],
        size: 225,
        background: [
          {
            backgroundColor: "#8a8a8a",
            borderWidth: 0,
            outerRadius: "109%",
            innerRadius: "103%",
          },
        ],
      },

      // the value axis
      yAxis: {
        min: 0,
        max: 0,
        minorTickWidth: 1,
        minorTickLength: 0,
        tickPixelInterval: 0,
        tickWidth: 0,
        labels: {
          rotation: "auto",
          enabled: false,
        },
        title: {
          text: "",
        },
        plotBands: [
          {
            from: 0,
            to: 10,
            thickness: "25",
            color: "#e84841",
          },
          {
            from: 10,
            to: 25,
            thickness: "25",
            color: "#ffff6b",
          },
          {
            from: 25,
            to: 100,
            thickness: "25",
            color: "#01934c",
          },
        ],
      },
      credits: {
        enabled: false,
      },
      plotOptions: {
        series: {
          dataLabels: {
            enabled: false,
          },
        },
      },
      series: [
        {
          name: "Rs",
          data: [],
          tooltip: {
            valueSuffix: "",
          },
        },
      ],
    };
    optionsData.yAxis.max = data.thresholdValues.peak;
    optionsData.yAxis.plotBands[0].from = 0;
    optionsData.yAxis.plotBands[0].to = data.thresholdValues.threshold;
    optionsData.yAxis.plotBands[1].from = data.thresholdValues.threshold;
    optionsData.yAxis.plotBands[1].to = data.thresholdValues.median;
    optionsData.yAxis.plotBands[2].from = data.thresholdValues.median;
    optionsData.yAxis.plotBands[2].to = data.thresholdValues.peak;
    optionsData.series[0].data.push(parseInt(data.value));
    let body = {
      chartTitle: title,
      options: optionsData,
      chartData: data.value,
      thresholdValues: data.thresholdValues,
    };
    return body;
  };

  toggle = (tab) => {
    let { innerTabs } = this.state;
    if (innerTabs !== tab) {
      this.setState(
        {
          innerTabs: tab,
        },
        () => {
          this._getPerformancelistData();
        }
      );
    }
  };

  render() {
    let { performanceData, isLoading, innerTabs, yearArray } = this.state;
    let { fpoName } = this.props;

    return (
      <div className="performance-tab" id="financialPerformanceReport">
        <div className="fs-22 fw-600 text-black text-center mt-3">
          Financial performance of {fpoName}
        </div>
        <div className="container-sa align-items-center">
          <Nav className="tab-inner-area d-flex justify-content-center">
            {yearArray.map((data, index) => (
              <NavItem key={index}>
                <NavLink
                  className={classnames({ active: innerTabs === index + 1 })}
                  onClick={() => this.toggle(index + 1)}
                >
                  <button
                    className={`btn ${innerTabs === index + 1 ? "active" : ""}`}
                  >
                    {data.year}
                  </button>
                </NavLink>
              </NavItem>
            ))}
          </Nav>
        </div>
        <div className="pt-2">
          {!isLoading && performanceData.length === 0 ? (
            <p className="py-4 mb-0 text-black-50 fs-22 line-height-25 text-center">
              {"No data found"}
            </p>
          ) : !isLoading ? (
            <Fragment>
              {performanceData.length != 0 ? (
                <div className="d-flex container-sa flex-column  flex-wrap mt-3 px-0 justify-content-center">
                  <div className="row graph-list-card">
                    {performanceData.map((list, index) => {
                      let {
                        options = {},
                        chartTitle = "",
                        chartData = "",
                        thresholdValues = {},
                      } = list;

                      let {
                        median = 0,
                        peak = 0,
                        threshold = 0,
                      } = thresholdValues;

                      return (
                        <div className="col-3 mb-3" key={index}>
                          <div className="chart-card">
                            {options.series.length !== 0 ? (
                              <>
                                <div className="chart-header">
                                  <h3 className="mb-4 text-black fs-18 fw-600 text-center">
                                    {chartTitle}
                                  </h3>
                                </div>
                                <HighchartsReact
                                  highcharts={Highcharts}
                                  options={options}
                                />
                                <div className="mb-3">
                                  <div className="d-flex justify-content-center align-items-center">
                                    <span className="round-color-red"></span>
                                    <p className="mb-0 ml-2 text-black-50 fs-14 fw-500">
                                      {0}
                                      {" - "}
                                      {formatToINRWithoutSymbol(threshold)}
                                    </p>
                                  </div>
                                  <div className="d-flex justify-content-center align-items-center">
                                    <span className="round-color-yellow"></span>
                                    <p className="mb-0 ml-2 text-black-50 fs-14 fw-500">
                                      {formatToINRWithoutSymbol(threshold)}
                                      {" - "}
                                      {formatToINRWithoutSymbol(median)}
                                    </p>
                                  </div>
                                  <div className="d-flex justify-content-center align-items-center">
                                    <span className="round-color-green"></span>
                                    <p className="mb-0 ml-2 text-black-50 fs-14 fw-500">
                                      {formatToINRWithoutSymbol(median)}
                                      {" - "}
                                      {formatToINRWithoutSymbol(peak)}
                                    </p>
                                  </div>
                                </div>
                                <div className="d-flex justify-content-center w-100 align-items-center">
                                  <i className="icon-rupee fs-18 text-green-18"></i>
                                  <h3 className="text-green-18 fs-18 fw-700 text-center mb-0 ml-1">
                                    {formatToINRWithoutSymbol(chartData)}
                                  </h3>
                                </div>
                              </>
                            ) : (
                              <p className="py-5 my-5 text-black-50 fs-22 line-height-25 text-center">
                                No data found !!!
                              </p>
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              ) : (
                <p className="py-4 mb-0 text-black-50 fs-22 line-height-25 text-center">
                  {"No data found"}
                </p>
              )}
            </Fragment>
          ) : (
            <>
              {/* Loader */}
              <CommonPageLoader />
            </>
          )}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getperformancedata,
      getperformancedataById,
    },
    dispatch
  );
};

let component = PerformanceTabClass;

export const PerformanceTab = connect(null, mapDispatchToProps)(component);
