import React, { forwardRef } from "react";

const RMRadio = forwardRef((props, ref) => {
  const {
    name,
    checked,
    className = "",
    onChange,
    option,
    label = "",
    isVertical = false,
    isRequired = false,
  } = props;
  const requiredClass = "text-danger font-weight-bold";
  const radioClass = "custom-control custom-radio d-flex align-items-center";

  return (
    <div className="mb-2">
      <label>
        {label}
        {isRequired && <span className={requiredClass}>*</span>}
      </label>
      <div className={`d-flex ml-3 w-100 ${isVertical ? "flex-column" : null}`}>
        {option?.map((item, index) => (
          <div key={index} className={`${radioClass} ${className}`}>
            <input
              type="radio"
              name={name}
              id={item?.value}
              value={item?.value}
              checked={checked === item?.value}
              onChange={onChange}
              ref={ref}
              required={isRequired}
            />
            <label className="pl-1 mb-0" htmlFor={item?.value}>
              {item?.label}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
});

export default RMRadio;
