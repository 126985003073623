import React, { Component } from "react";

import { OurCropsCard } from "./OurCropsCard";

import "./ourCrops.scss";

export class OurCrops extends Component {
  render() {
    let { cropMapping } = this.props;
    let crops = cropMapping
      .reduce((acc, item) => {
        return `${acc} , ${item.cropsDetails.cropName}`;
      }, "")
      .slice(2);
    return (
      <>
        <section className="Our-Crops-section">
          <div className="container-sa">
            <div className="row">
              <div className="col-sm-12">
                <div className="our-corps-description">
                  <h3>Our Crops</h3>
                  {/* <p>{crops} are the main crops of our memberbase</p> */}
                </div>
              </div>
            </div>
            <div className="our-crops-area">
              {cropMapping.map((list, index) => {
                let { cropsDetails } = list;
                return (
                  <OurCropsCard
                    key={index}
                    className=""
                    title={cropsDetails.cropName}
                    img={cropsDetails.cropIconUrl}
                  />
                );
              })}
            </div>
          </div>
        </section>
      </>
    );
  }
}
