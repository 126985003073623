import React, { Component } from "react";
import rightCircle from "assets/images/play-circle.png";
import { history } from "service/helpers";
import "./TrendingNow.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import { getDescriptionData } from "service/helperFunctions";
import moment from "moment";

export class TrendingVideo extends Component {
  render() {
    const params = {
      slidesPerView: 1,
      navigation: true,
      draggable: true,
      spaceBetween: 20,
      breakpoints: {
        500: {
          slidesPerView: 2,
        },
        768: {
          slidesPerView: 4,
        },
      },
    };

    let { trendingVideos = [], title, isfromFavouriteList } = this.props;
    return (
      <>
        <section className="video-section mx-3">
          <div className="container-sa">
            <div className="d-flex justify-content-between mb-4 pb-1 mx-5">
              <div>
                <p className="text-black-1c fs-26 line-height-28 font-weight-bold mb-0">
                  {title ? title : "Informative videos"}
                </p>
              </div>
              <div>
                <p
                  className="mb-0 text-black-50 fs-18 text-underline line-height-23 fw-700 cursor-pointer"
                  onClick={() => history.push("/academy/trendingvideos/all")}
                >
                  {"View all"}
                </p>
              </div>
            </div>
          </div>
          <div className="video-list">
            {isfromFavouriteList ? (
              <Swiper {...params}>
                {trendingVideos.map((data, index) => {
                  let { trendingvideo } = data;
                  return (
                    <SwiperSlide key={index + "video"}>
                      <div className="">
                        <div
                          className="video-card"
                          onClick={() =>
                            history.push(
                              `/academy/trending-video/${trendingvideo.trendingvideoId}`
                            )
                          }
                        >
                          <div
                            className="video-thumb"
                            style={{
                              backgroundPosition: "center",
                              backgroundRepeat: "no-repeat",
                              height: "340px",
                              backgroundImage:
                                "url(" +
                                `${trendingvideo.thumbnailImageUrl}` +
                                ")",
                            }}
                          >
                            <div className="d-flex justify-content-between h-100 flex-column p-3 video-body">
                              <div className="video-content pt-3">
                                <h5 className="fs-24 line-height-31 font-weight-bold mb-1">
                                  {" "}
                                  {getDescriptionData(
                                    trendingvideo.title,
                                    25
                                  )}{" "}
                                </h5>
                                <p className="line-height-23 fw-600">
                                  {moment(trendingvideo.publishDate).format(
                                    "MMM Do, YYYY"
                                  )}
                                </p>
                              </div>
                              <div className="play-link w-100 pb-1">
                                <img src={rightCircle} alt="rightCircle" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            ) : (
              <Swiper {...params}>
                {trendingVideos.map((data, index) => (
                  <SwiperSlide key={index + "video"}>
                    <div className="">
                      <div
                        className="video-card"
                        onClick={() =>
                          history.push(
                            `/academy/trending-video/${data.trendingvideoId}`
                          )
                        }
                      >
                        <div
                          className="video-thumb"
                          style={{
                            backgroundPosition: "center",
                            backgroundRepeat: "no-repeat",
                            height: "340px",
                            backgroundImage:
                              "url(" + `${data.thumbnailImageUrl}` + ")",
                          }}
                        >
                          <div className="d-flex justify-content-between h-100 flex-column p-3 video-body">
                            <div className="video-content pt-3">
                              <h5 className="fs-22 line-height-31 font-weight-bold mb-1">
                                {" "}
                                {getDescriptionData(data.title, 25)}{" "}
                              </h5>
                              <p className="line-height-23 fw-600 text-white">
                                {moment(data.publishDate).format(
                                  "MMM Do, YYYY"
                                )}
                              </p>
                            </div>
                            <div className="play-link w-100 pb-1">
                              <img src={rightCircle} alt="rightCircle" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            )}
          </div>
        </section>
      </>
    );
  }
}
