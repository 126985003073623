import React from "react";
import RMInput from "component/RM/Common/RMInput";
import RMSelect from "component/RM/Common/RMSelect";
import { seasonOption } from "../dataVariables";

const CropDetails = (props) => {
  const {
    cropData,
    handleCropChange,
    handleRemoveClick,
    showCropError,
    cropCurrentIndex,
    handleAddClick,
  } = props;

  return (
    <div className="d-flex flex-column mb-3 crop-details">
      {cropData.map((item, index) => {
        return (
          <React.Fragment key={index}>
            <div className="d-flex justify-content-between align-items-end w-100 mt-3">
              <div className="row col-9">
                <div className="d-flex flex-column mr-3">
                  <RMSelect
                    label="Season"
                    name="season"
                    value={item?.season}
                    onChange={(e) => handleCropChange(e, index)}
                    option={seasonOption}
                    isRequired={true}
                  />
                </div>
                <div className="d-flex flex-column mr-3">
                  <RMSelect
                    label="Category"
                    name="classification"
                    value={item?.classification}
                    onChange={(e) => handleCropChange(e, index)}
                    option={item?.categoryOptions}
                    isRequired={true}
                  />
                </div>
                <div className="d-flex flex-column mr-3">
                  <RMSelect
                    label="Crops"
                    name="cropName"
                    value={item?.cropName}
                    onChange={(e) => handleCropChange(e, index)}
                    option={item?.cropOptions}
                    isRequired={true}
                  />
                </div>
                {item?.cropName === "85" && (
                  <div className="mr-3">
                    <RMInput
                      label="Other Crops"
                      name="othercrop"
                      value={item?.othercrop}
                      onChange={(e) => handleCropChange(e, index)}
                      isRequired={item?.cropName === "85"}
                    />
                  </div>
                )}
              </div>
              <div className="col-1">
                {cropData.length !== 1 && (
                  <button
                    className="add-button red-bg"
                    onClick={() => {
                      handleRemoveClick(index, "crop");
                    }}
                  >
                    Delete
                  </button>
                )}
              </div>
            </div>
            <div className="mt-4">
              {showCropError && cropCurrentIndex === index && (
                <p className="error-message text-danger fs-14 mb-0">
                  Please Fill all the fields
                </p>
              )}
              {cropData.length - 1 === index && (
                <button
                  className="add-button"
                  onClick={() => {
                    handleAddClick(index, "crop");
                  }}
                >
                  Add more Crops
                </button>
              )}
            </div>
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default CropDetails;
