import React from "react";

const NoSurvey = ({ dropdownList }) => {
  return (
    <div className="fs-20 fw-500 text-black-50 line-height-21 text-center">
      The Comparison Chart is not available for your FPO since{" "}
      {dropdownList?.length === 0 ? "no" : "only one"} Grading survey has been
      completed.
    </div>
  );
};

export default NoSurvey;
