import React, { Component } from 'react';

import "./Banner.scss"

export class Banner extends Component {
   constructor() {
      super();
      this.state = {};
   }
   render() {
      let { } = this.state
      let { minSize = false } = this.props
      return (
         <>
            <section className={`aca-banner-section ${minSize ? ' hideContent ' : ''}`}>
               <div className="container">
                  <div className="aca-banner-content">
                     <h2>Academy for FPOs</h2>
                     <p>An e-knowledge sharing platform to provide professional learning and training to FPOs and other ecosystem stakeholders</p>
                  </div>
               </div>
            </section>
         </>
      );
   }
}
