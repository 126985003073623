import React, { Component } from "react";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fileUpload } from "action/MemberAct";
import {
  addAssociation,
  updateAssociation,
  deleteAssociation,
  getAssociationById,
  getAllAssociationtypes,
  getAllInstitutiontypes,
} from "action/AssociationAct";
import { DragFileUpload, NormalDate } from "component/common";
import SimpleReactValidator from "simple-react-validator";
import { Toast } from "service/toast";
import { history } from "service/helpers/index";
import {
  NormalInput,
  NormalButton,
  NormalSelect,
  CommonPageLoader,
  ConfirmationPopup,
} from "component/common";
import "./style.scss";
import trash from "assets/svg/Associations/trash.svg";
import arrow_left from "assets/svg/add_member/arrow_left.svg";
import moment from "moment";
class AddAssociationClass extends Component {
  constructor(props) {
    super();
    this.state = {
      InstitutionOptions: [],
      InstitutionId: "",
      InstitutionName: "",
      AssociationOptions: [],
      AssociationId: "",
      AssociatedSince: "",
      InstitutionFileUrl: "",
      InstitutionFileName: "",
      isImageUploading: false,
      isLoading: false,
      isDelete: false,
    };
  }
  validator = new SimpleReactValidator({
    element: (message) => (
      <span className="error-message text-danger fs-14">{message}</span>
    ),

    autoForceUpdate: this,
  });

  handleInput = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  handleDelete = () => {
    this.setState({ isLoading: true });
    this.props.deleteAssociation(this.props.id).then((data) => {
      history.push("/fpo/association");
      this.setState({ isLoading: false });
    });
  };

  toggleDelete = () => {
    let { isDelete } = this.state;
    this.setState({
      isDelete: !isDelete,
    });
  };

  handleSubmit = () => {
    this.setState({ isLoading: true });
    if (this.validator.allValid()) {
      const {
        InstitutionId,
        InstitutionName,
        AssociationOptions,
        AssociationId,
        AssociatedSince,
        InstitutionFileUrl,
        InstitutionFileName,
        isImageUploading,
      } = this.state;
      if (!isImageUploading) {
        let body = {
          institutionTypeId: parseInt(InstitutionId),
          institutionName: InstitutionName,
        };

        if (AssociatedSince !== "") {
          body.associatedSince = moment(AssociatedSince).format("YYYY");
        }

        if (AssociationId !== "") {
          body.associationTypeId = parseInt(AssociationId);
          let associationData = AssociationOptions.filter(
            (x) => x.value === parseInt(AssociationId)
          );
          associationData.length > 0 &&
            (body.associationType = associationData[0].label);
        }

        if (InstitutionFileName !== "") {
          body.logoName = InstitutionFileName;
          body.logoUrl = InstitutionFileUrl;
        }

        if (this.props.id) {
          body.associationId = parseInt(this.props.id);
          this.props
            .updateAssociation(body)
            .then(() => {
              history.push("/fpo/association");
              this.setState({ isLoading: false });
            })
            .catch(() => {
              this.setState({ isLoading: false });
            });
        } else {
          this.props
            .addAssociation(body)
            .then(() => {
              this.validator.hideMessages();
              this._clearData();
              history.goBack();
            })
            .catch(() => {
              this.setState({ isLoading: false });
            });
        }
      } else {
        Toast({ type: "error", message: "Please wait Image is uploading" });
        this.setState({ isLoading: false });
      }
    } else {
      this.setState({ isLoading: false });
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  _clearData = () => {
    this.setState({
      InstitutionId: "",
      InstitutionName: "",
      AssociationId: "",
      AssociatedSince: "",
      InstitutionFileUrl: "",
      InstitutionFileName: "",
      isLoading: false,
    });
  };

  handleFile = (files) => {
    let file = files[0];
    if (file !== undefined) {
      this.setState({ InstitutionFileUrl: "" }, () => {
        let { InstitutionFileUrl, InstitutionFileName } = this.state;

        InstitutionFileName = "Uploading ....";
        InstitutionFileUrl = file;

        const formData = new FormData();
        formData.append("file", file);

        this.props.fileUpload(formData).then((data) => {
          InstitutionFileUrl = data[0].fileUrl;
          InstitutionFileName = data[0].fileName;
          this.setState({
            InstitutionFileUrl,
            InstitutionFileName,
            isImageUploading: false,
          });
        });

        this.setState({
          InstitutionFileUrl,
          InstitutionFileName,
          isImageUploading: true,
        });
      });
    }
  };
  componentDidMount() {
    this.props.getAllInstitutiontypes().then((data) => {
      let institutionData = [];

      data.map((list) => {
        institutionData.push({
          value: list.institutionTypeId,
          label: list.institutionType,
        });
      });
      this.setState({
        InstitutionOptions: institutionData,
      });
    });
    this.props.getAllAssociationtypes().then((data) => {
      let associationData = [];

      data.map((list) => {
        associationData.push({
          value: list.associationTypeId,
          label: list.associationType,
        });
      });
      this.setState({
        AssociationOptions: associationData,
      });
    });
    if (this.props.id) {
      this.setState({ isLoading: true });
      this.props.getAssociationById(this.props.id).then((data) => {
        this.setState({
          InstitutionId: data.institutionTypeId ? data.institutionTypeId : "",
          InstitutionName: data.institutionName ? data.institutionName : "",
          AssociationId: data.associationTypeId ? data.associationTypeId : "",
          AssociatedSince: data.associatedSince
            ? new Date("01/01/" + data.associatedSince)
            : "",
          InstitutionFileUrl: data.logoUrl ? data.logoUrl : "",
          InstitutionFileName: data.logoName ? data.logoName : "",
          isLoading: false,
        });
      });
    }
  }

  handleRemoveImage = () => {
    this.setState({
      InstitutionFileUrl: "",
      InstitutionFileName: "",
    });
  };
  render() {
    const {
      InstitutionOptions,
      InstitutionId,
      InstitutionName,
      AssociationOptions,
      AssociationId,
      AssociatedSince,
      InstitutionFileName,
      InstitutionFileUrl,
      isImageUploading,
      isLoading,
      isDelete,
    } = this.state;
    let { deleteAccess = false } = this.props;
    return (
      <div>
        <div className="d-flex container-sa my-3 ">
          <div
            onClick={() => {
              history.push("/fpo/association");
            }}
            className="d-flex cursor-pointer"
          >
            <img className="cursor-pointer" src={arrow_left} alt="back" />
            <p className="ml-3 add-member-text-back mb-0">
              {this.props.id ? "Edit " : "Add "}
              {"associations & partnerships details"}
            </p>
          </div>
        </div>
        {!isLoading ? (
          <div className="add-form">
            <div className="container">
              <div className="row">
                <div className="row col-9 bg-white mx-auto pb-4">
                  <div className="col-12">
                    <NormalSelect
                      isFullWidth={true}
                      label={"Institution type"}
                      placeholder="Select"
                      value={InstitutionId}
                      name="InstitutionId"
                      options={InstitutionOptions}
                      arrow={true}
                      handleChange={this.handleInput}
                      required={true}
                    />
                    <div className="row">
                      <div className="col-10 ml-auto">
                        {this.validator.message(
                          "Institution type",
                          InstitutionId,
                          "required"
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <NormalInput
                      label="Institution name"
                      type="text"
                      onChange={this.handleInput}
                      value={InstitutionName}
                      name="InstitutionName"
                      isFullWidth={true}
                      required={true}
                    />
                    <div className="row">
                      <div className="col-10 ml-auto">
                        {this.validator.message(
                          "Institution name",
                          InstitutionName,
                          "required|max:40"
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <NormalSelect
                      isFullWidth={true}
                      label={"Association type"}
                      placeholder="Select"
                      value={AssociationId}
                      name="AssociationId"
                      options={AssociationOptions}
                      arrow={true}
                      handleChange={this.handleInput}
                    />
                  </div>
                  <div className="col-12">
                    <NormalDate
                      isIcon={true}
                      isFullWidth={true}
                      value={AssociatedSince}
                      name="AssociatedSince"
                      placeholder="YYYY"
                      onChange={this.handleInput}
                      mindate={false}
                      maxdate={true}
                      timeInput={false}
                      label={"Associated since"}
                      isYearPicker={true}
                    />
                  </div>
                  <div className="col-9 mx-auto mt-5">
                    <div className="upload cursor-pointer">
                      {InstitutionFileName != "" && !isImageUploading && (
                        <div className="d-flex justify-content-end mb-3 align-items-center">
                          <div>
                            {InstitutionFileName.length == 10
                              ? InstitutionFileName
                              : InstitutionFileName.substring(0, 9)}
                            ...
                          </div>
                          <i
                            className="icon-Delete text-danger fs-14 pl-2 cursor-pointer"
                            onClick={() => this.handleRemoveImage()}
                          ></i>
                        </div>
                      )}
                      <DragFileUpload
                        className="form-control drag-drop d-flex align-items-center"
                        value={InstitutionFileUrl}
                        name="imageURL"
                        children={
                          <HelloMessage
                            isImageUploading={isImageUploading}
                            fileName={InstitutionFileName}
                            fileUrl={InstitutionFileUrl}
                          />
                        }
                        handleFileUpload={this.handleFile}
                      />
                    </div>
                  </div>
                  <div className="col-6 mt-5 row mx-auto p-0">
                    {this.props.id && deleteAccess && (
                      <div className=" ml-auto col-6 cursor-pointer d-flex align-items-center">
                        <div
                          className="text-center fs-14 fw-600 text-danger"
                          onClick={this.toggleDelete}
                        >
                          <img className="mr-2" src={trash} alt="icon" />
                          Delete
                        </div>
                      </div>
                    )}
                    <div className="col-6 p-0 mx-auto">
                      <NormalButton
                        label={`${this.props.id ? "Update" : "Submit & next"}`}
                        mainbg={true}
                        className="px-2 py-2 mx-auto fs-14 line-height-26 font-Gilroy w-100"
                        onClick={this.handleSubmit}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <>
            {/* Loader */}
            <CommonPageLoader />
          </>
        )}
        <ConfirmationPopup
          isOpen={isDelete}
          toggle={this.toggleDelete}
          onSubmit={this.handleDelete}
        />
      </div>
    );
  }
}

class HelloMessage extends React.Component {
  render() {
    let { isImageUploading, fileName, fileUrl } = this.props;
    return (
      <div className=" d-flex upload-drag justify-content-center w-100">
        <div className="ml-3">
          <h4>Upload photo</h4>
          <label>Drag & drop your file here</label>
        </div>
        <div className="ml-3 mr-3 align-self-center">
          <label>or</label>
        </div>
        <div className="ml-5 align-self-center">
          <button
            type="button"
            className="btn mainbg-btn brows-btn outline-btn float-right"
          >
            {fileUrl == "" ? (
              "Choose file"
            ) : (
              <>
                {fileName.length == 10 ? fileName : fileName.substring(0, 9)}
                ...
              </>
            )}
            {isImageUploading ? (
              <span
                className="spinner-border spinner-border-sm ml-1"
                role="status"
                aria-hidden="true"
              ></span>
            ) : null}
          </button>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      addAssociation,
      getAssociationById,
      getAllAssociationtypes,
      getAllInstitutiontypes,
      updateAssociation,
      deleteAssociation,
      fileUpload,
    },
    dispatch
  );
};
let component = AddAssociationClass;

export const AddAssociation = connect(null, mapDispatchToProps)(component);
