import React, { Component } from "react";
import { Navbar, CommonPageLoader } from "component/common";
import { OtherFpos } from "./other_fpos/OtherFpos";
import { AwardsOtherFpos } from "./awards_other_fpos/AwardsOtherFpos";
import { OtherSuccess } from "./other_success/OtherSuccess";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getAllFpoEventsandImpact } from "action/eventsAndImpactStories";
import { UpcomingEvents } from "./UpcomingEvents/UpcomingEvents";
import "./allevents.scss";
class AllEventsClass extends Component {
  constructor(props) {
    super();
    this.state = {
      allstoriesList: {},
      isLoader: false,
    };
  }

  componentDidMount = () => {
    this.setState({ isLoader: true });
    let { getAllFpoEventsandImpact } = this.props;
    getAllFpoEventsandImpact()
      .then(({ status, data }) => {
        this.setState({ isLoader: false });
        if (status === 200) {
          this.setState({ allstoriesList: data });
        }
      })
      .catch(() => {
        this.setState({ isLoader: false });
      });
  };

  render() {
    let {
      allstoriesList: {
        pastEvents = [],
        fpoawards = [],
        fposuccessstories = [],
        upcomingEvents = [],
      },
      isLoader,
    } = this.state;

    let {
      editAccess = false,
      viewAccess = false,
      createAccess = false,
      deleteAccess = false,
      id,
    } = this.props;
    return (
      <div className="allevents">
        {/* <Navbar /> */}
        <div className="container-fluid stories-header"></div>
        <div className="container stories-header-box">
          <div className="d-flex justify-content-center">
            <div className="stories-header-text text-white">
              {"Events And Impact Stories"}
            </div>
          </div>
        </div>
        <div className="toggle-events d-flex justify-content-center w-100">
          <div className="pt-4 rounded toggle-width d-flex justify-content-center ">
            <div className="d-flex w-50 justify-content-between">
              <div className="d-flex align-items-center flex-column">
                <div className="">
                  <input
                    className="toggle-btn text-white font-weight-light h5"
                    type="button"
                    value={`${id ? "FPO" : "Our"} Stories`}
                    onClick={() => this.props.handleToggle("Stories")}
                  />
                </div>
              </div>
              <div className="d-flex align-items-center flex-column">
                <div className="font-weight-bold text-white h5 mb-3">
                  {"All events & Impact stories"}
                </div>
                <div className="toggle-selected"></div>
              </div>
            </div>
          </div>
        </div>
        {isLoader ? (
          <CommonPageLoader />
        ) : (
          <>
            {pastEvents.length == 0 &&
            fpoawards.length == 0 &&
            fposuccessstories.length == 0 &&
            upcomingEvents.length === 0 ? (
              <p className="py-5 mb-0 fs-22 text-black-50 w-100 text-center">
                No data found
              </p>
            ) : null}
            {/* Other FPOs */}
            {pastEvents.length !== 0 ? (
              <div>
                <OtherFpos
                  yourEventList={pastEvents}
                  isLoader={isLoader}
                  editAccess={editAccess}
                  createAccess={createAccess}
                  viewAccess={viewAccess}
                />
              </div>
            ) : null}
            {upcomingEvents.length !== 0 ? (
              <UpcomingEvents
                title="Upcoming events"
                upcomingEvents={upcomingEvents}
                ourStories={false}
                editAccess={editAccess}
                viewAccess={viewAccess}
                createAccess={createAccess}
                deleteAccess={deleteAccess}
              />
            ) : null}
            {fpoawards.length !== 0 ? (
              <div>
                <AwardsOtherFpos
                  awardsOtherFposList={fpoawards}
                  isLoader={isLoader}
                  title="Awards & recognition of other FPOs"
                  editAccess={editAccess}
                  viewAccess={viewAccess}
                  deleteAccess={deleteAccess}
                />
              </div>
            ) : null}
            {fposuccessstories.length !== 0 ? (
              <div>
                <OtherSuccess
                  otherSuccessList={fposuccessstories}
                  isLoader={isLoader}
                  title="Success stories of other FPOs"
                  editAccess={editAccess}
                  viewAccess={viewAccess}
                  deleteAccess={deleteAccess}
                />
              </div>
            ) : null}
          </>
        )}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getAllFpoEventsandImpact,
    },
    dispatch
  );
};

let component = AllEventsClass;

export const AllEvents = connect(null, mapDispatchToProps)(component);
