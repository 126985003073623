import { NormalButton } from 'component/common';
import React, { Component } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import { FieldsTemplate } from '../common/FieldsTemplate';

export const InputMarketing = (props) => {

  const [, forceUpdate] = React.useState();
  const [inputMarketingInfo, setInputMarketingInfo] = React.useState({
    products: []
  });
  const [inputMarketingAttr, setInputMarketingInfoAttr] = React.useState([
    {
      label: "Products available through input shop", field: "checkboxGroup", type: "number", name: "products", required: true,
      list: [

      ]
    },
    {
      label: "Mention if others", field: "input", type: "text", name: "inputshopothers", required: false,
      conditions: { attribute: "products", values: [10], action: "show" },
    },
    { label: "What Percentage (%) of member farmers availing different FPO services", field: "input", type: "number", name: "percentawareofinput", note: "In percentage: For example if value is 55.5 % enter 55.5", required: true },
    {
      label: "Availability of inputs to members- Place", field: "radioGroup", type: "number", name: "ipstoredist", note: "Please consider the point of Aggregation. eg input aggregation taking place at BODs house/ member farmers house", required: true,
      list: [
        { label: "Available within 4 KM for majority of farmers", value: "within4km" },
        { label: "Available within 4-6 KM for majority of farmers", value: "4to6km" },
        { label: "Available within 6-8 KM for majority of farmers", value: "6to8km" },
        { label: "Available within 8-10 KM for majority of farmers", value: "8to10km" },
        { label: "Available in more than 10 KM for majority of farmers", value: "morethan10km" },
        { label: "No input shops", value: "noinputshops" },
      ]
    },
    {
      label: "Availability of inputs to members –Accessibility", field: "radioGroup", type: "number", name: "ipaccessformem", note: "Please consider the point of Aggregation. eg input aggregation taking place at BODs house/ member farmers house", required: true,
      list: [
        { label: "Available to more than 80% of farmers and non-member farmers", value: "ipabove80pc" },
        { label: "Available to 60-80% of farmers", value: "ip60to80pc" },
        { label: "Available to 30-60% of farmers", value: "ip30to60pc" },
        { label: "Available to 15-30% of farmers", value: "ip15to30pc" },
        { label: "Available to 1-15% of farmers", value: "ip1to15pc" },
        { label: "No input shop", value: "noinputshop" },
      ]
    },

    {
      label: "Reason for not availing services from input shop by members?", field: "radioGroup", type: "number", name: "rsnnotuseip", note: "Please consider the point of Aggregation. eg input aggregation taking place at BODs house/ member farmers house", required: true,
      conditions: { attribute: "ipaccessformem", values: ["ip15to30pc", 'ip1to15pc'], action: "show" },
      list: [
        { label: "Availability of better products at cheaper prices in local shops", value: "cheaperavlbl" },
        { label: "High demand of only few products", value: "fewproddmnd" },
        { label: "No input shop", value: "noipshop" },
        { label: "Inability to procure inputs form suppliers", value: "cantrchsupp" },
      ]
    },

    {
      label: " Availability of inputs to members – timeliness", field: "radioGroup", type: "number", name: "ipontimetomem", note: "Please consider the point of Aggregation. eg input aggregation taking place at BODs house/ member farmers house", required: true,
      list: [
        { label: "Available 15 days before the monsoon", value: "15daysbeforemonsoon" },
        { label: "Available before the monsoon", value: "justbeforemonsoon" },
        { label: "Available at the time of monsoon", value: "duringmonsoon" },
        { label: "Available after the monsoon", value: "aftermonsoon" },
        { label: "Available throughout the Year", value: "yearlong" },
        { label: "Not at all available", value: "notavailable" },
      ]
    },
    {
      label: "Whether there is door delivery facility for inputs?", field: "radioGroup", type: "number", name: "ipdoordelivery", required: true,
      list: [
        { label: "Yes", value: "yes" },
        { label: "No", value: "no" },
      ]
    },
    { label: "What is average difference (%) between the market price and FPO's price for Input products?", field: "input", type: "number", name: "ipdiffinprice", note: "Example: if value is 55.5 % enter 55.5", required: true },
    {
      label: "Rational for low price realization?",
      field: "radioGroup", type: "number", name: "rsnlowpricerealiz", required: false,
      note: "Make a selection only if difference between market price and FPO's price for Input products is less than 10%",
      conditions: { attribute: "ipdiffinprice", values: [10], action: "show", criteria: "lt" },
      list: [
        { value: "lowmembase", label: "Low volume of purchase" },
        { value: "loworlessprof", label: "Presence of middlemen" },
        { value: "capinvprojs", label: "Reduction in margin due to credit purchase" },
        { value: "credtomems", label: "High transporation cost" }
      ]
    },

    { label: "Names of Institutional Suppliers associated with FPO?", field: "input", type: "text", name: "namesupfpo", required: true },
    { label: "Number of Institutional Suppliers associated with FPO", field: "input", type: "number", name: "noofinstsupp", required: true },
    {
      label: "Reason for less number of institutional suppliers?",
      field: "radioGroup", type: "number", name: "rsnlowinstsupp", required: false,
      note: "Make a selection only if number of institutional suppliers is less than 2",
      conditions: { attribute: "noofinstsupp", values: [2], action: "show", criteria: "lt" },
      list: [
        { value: "lowmembase", label: "Low volume of demand" },
        { value: "loworlessprof", label: "Lack of market linkage" },
        { value: "capinvprojs", label: "Inability to negotiate better deal" },
        { value: "credtomems", label: "Lack of interest from FPO management" }
      ]
    },

    {
      label: "How long you have been associated with major Supplier (in years)", field: "input", type: "number", name: "assocwithsupp", placeholder: "0", required: true,
      errConditions: { criteria: "oneDigit" }
    },

    {
      label: " Does the FPC have all the licenses required for doing business?", field: "radioGroup", type: "number", name: "hasalllicences", note: "If into seed sales- seed license, pesticide sale- pesticide license, Fertilizer sale- Fertilizer license, Seed production- Seed certification, Processing- FSSAI", required: true,
      list: [
        { label: "Yes, have all required licenses", value: "yesallreqlicences" },
        { label: "Have majority, but missing for one", value: "exceptforone" },
        { label: "Have majority, but missing for two", value: "exceptfortwo" },
        { label: "Not having the required licenses", value: "nolicenses" },
      ]
    },

    {
      label: "Why certain required licenses are not availed?", field: "radioGroup", type: "number", name: "whymisslicense", note: "If into seed sales- seed license, pesticide sale- pesticide license, Fertilizer sale- Fertilizer license, Seed production- Seed certification, Processing- FSSAI", required: true,
      conditions: { attribute: "hasalllicences", values: ["exceptforone", 'exceptfortwo', 'nolicenses'], action: "show" },
      list: [
        { label: "Lack of Awareness", value: "notaware" },
        { label: "Lack of interest from FPO management", value: "feelscomplex" },
        { label: "Administrative issues in procuring the licneses from government offices", value: "nopropstaff" },
        { label: "Only required licenses procured", value: "nomoney" },
      ]
    },

    {
      label: " Whether FPC is able to get input at wholesale price", field: "radioGroup", type: "number", name: "getsipatwholesale", note: "If into seed sales- seed license, pesticide sale- pesticide license, Fertilizer sale- Fertilizer license, Seed production- Seed certification, Processing- FSSAI", required: true,
      list: [
        { label: "Yes, always", value: "always" },
        { label: "Yes, Majority of times", value: "mosttimes" },
        { label: "Yes, Sometimes", value: "sometimes" },
        { label: "Rarely", value: "rarely" },
        { label: "Not at all", value: "notatall" },
        { label: "Not into input business", value: "noinputbusiness" },
      ]
    },


  ]);

  //validation
  const simpleValidator = React.useRef(new SimpleReactValidator({
    validators: {
      oneDigit: {
        message: "Please enter a value lessthan 10.",
        rule: (val, params, validator) => {
          return (
            validator.helpers.testRegex(
              val,
              /^[0-9]{1}$/
            )
          );
        },
        messageReplace: (message, params) =>
          message.replace("", this.helpers.toSentence(params)),
        required: true,
      },
    },
    element: (message) => (
      <span className="error-message text-danger fs-14">{message}</span>
    ),
    autoForceUpdate: this
  }));
  const handleSubmit = (status) => {
    const formValid = simpleValidator.current.allValid();
    if (formValid) {
      let payload = { ...inputMarketingInfo, status };
      if (inputMarketingInfo.percentawareofinput) {
        payload.percentawareofinput = parseFloat(inputMarketingInfo.percentawareofinput);
      }
      if (inputMarketingInfo.hasalllicences && ["exceptforone", 'exceptfortwo', 'nolicenses'].includes(inputMarketingInfo.hasalllicences) && !inputMarketingInfo.whymisslicense) {
        alert('Why certain required licenses are not availed? is required in Input Marketing');
        props.errorAccordion();
        return false;
      }
      props.updateSurvey("inputMarketingInfo", payload);

    } else {
      simpleValidator.current.showMessages()
      forceUpdate(1);
      props.errorAccordion();
    }
  }
  const handleInput = ({ target: { name, value } }) => {
    setInputMarketingInfo({
      ...inputMarketingInfo,
      [name]: value,
    });
  };
  const handleCheckbox = (name, value) => {
    let list = [...inputMarketingInfo[name]];
    let index = list.indexOf(value);
    if (index >= 0) {
      list.splice(index, 1);
    } else {
      list.push(value);
    }
    setInputMarketingInfo({
      ...inputMarketingInfo,
      [name]: list,
    });
  };

  const handleRadio = (name, value) => {
    setInputMarketingInfo({
      ...inputMarketingInfo,
      [name]: value,
    });
  };

  React.useEffect(() => {
    if (props?.surveyDetails) {
      let _inputMarketingInfo = {
        "percentawareofinput": props?.surveyDetails.percentawareofinput,
        "ipstoredist": props?.surveyDetails.ipstoredist,
        "ipaccessformem": props?.surveyDetails.ipaccessformem,
        "ipontimetomem": props?.surveyDetails.ipontimetomem,
        "ipdoordelivery": props?.surveyDetails.ipdoordelivery,
        "ipdiffinprice": props?.surveyDetails.ipdiffinprice,
        "noofinstsupp": props?.surveyDetails.noofinstsupp,
        "hasalllicences": props?.surveyDetails.hasalllicences,
        "whymisslicense": props?.surveyDetails.whymisslicense,
        "getsipatwholesale": props?.surveyDetails.getsipatwholesale,
        "rsnnotuseip": props?.surveyDetails.rsnnotuseip,
        "namesupfpo": props?.surveyDetails.namesupfpo,

      }
      if (props?.surveyDetails.products) {
        _inputMarketingInfo.products = props?.surveyDetails.products;
      }
      if (props?.surveyDetails.assocwithsupp) {
        _inputMarketingInfo.assocwithsupp = parseInt(props?.surveyDetails.assocwithsupp);
      }

      setInputMarketingInfo({
        ...inputMarketingInfo,
        ..._inputMarketingInfo
      });
    }
  }, [props?.surveyDetails]);

  React.useEffect(() => {
    if (props?.submitSurveyClick) {
      handleSubmit('completed');
    }
  }, [props?.submitSurveyClick]);

  return (
    <div >
      {/* <h5><b>Basic details of FPO</b></h5> */}
      <div className="container-sa position-relative" >

        <FieldsTemplate
          attributes={inputMarketingAttr}
          simpleValidator={simpleValidator}
          stateAttr={inputMarketingInfo}
          handleInput={handleInput}
          handleRadio={handleRadio}
          handleCheckbox={handleCheckbox}
        />

        <div className="row mt-4">
          <div className="col-md-5 col-sm-12  my-1">
            <NormalButton
              label="Save & Next"
              mainbg={true}
              className="px-3 py-2 mx-auto fs-14 line-height-26 font-Gilroy saveNext"
              onClick={() => handleSubmit('draft')}
              isPopup={true}
            />
          </div>
        </div>
      </div>
    </div>
  )
}