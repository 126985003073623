import React from "react";
import RMInput from "component/RM/Common/RMInput";
import RMRadio from "component/RM/Common/RMRadio";
import RMMultiSelect from "component/RM/Common/RMMultiSelect";
import {
  machineryOptions,
  insuranceOptions,
  vehicleOptions,
  houseTypeOptions,
  houseOptions,
  maritalOptions,
} from "../dataVariables";

const FarmingDetails = (props) => {
  const {
    selectedMachinery,
    setSelectedMachinery,
    memberData,
    handleMemberChange,
    selectedInsurance,
    setSelectedInsurance,
    selectedVehicle,
    setSelectedVehicle,
    validator,
    fieldRef,
  } = props;

  const { machinery, vehicle, insurance } = memberData;
  const machineryOther = machinery.includes("Others");
  const vehicleOther = vehicle.includes("Others");
  const insuranceOther = insurance.includes("Other");

  return (
    <div>
      <h3 className="fs-20 fw-600 my-4">Farming</h3>
      <RMMultiSelect
        label="15. What Agriculture Equipment/Machinery do you own?"
        value={selectedMachinery}
        onChange={setSelectedMachinery}
        options={machineryOptions}
        isRequired={true}
      />
      {validator.current.message("machinery", selectedMachinery, "required")}
      {machineryOther && (
        <div className="ml-3">
          <RMInput
            label="Mention details of other equipment if you selected 'Other' above"
            name="machinery_other"
            value={memberData.machinery_other}
            onChange={(e) => handleMemberChange(e)}
            isRequired={machineryOther ? true : false}
            ref={machineryOther ? fieldRef : null}
          />
          {validator.current.message(
            "machinery_other",
            memberData.machinery_other,
            "required"
          )}
        </div>
      )}
      <RMMultiSelect
        label="16. What type of Insurance services do you currently have?"
        value={selectedInsurance}
        onChange={setSelectedInsurance}
        options={insuranceOptions}
        isRequired={true}
      />
      {validator.current.message("insurance", selectedInsurance, "required")}
      {insuranceOther && (
        <div className="ml-3">
          <RMInput
            label="Mention details of other insurance if you selected 'Other' above"
            name="inusrance_other"
            value={memberData.inusrance_other}
            onChange={(e) => handleMemberChange(e)}
            isRequired={insuranceOther ? true : false}
            ref={insuranceOther ? fieldRef : null}
          />
          {validator.current.message(
            "inusrance_other",
            memberData.inusrance_other,
            "required"
          )}
        </div>
      )}
      <RMMultiSelect
        label="17. Vehicle"
        value={selectedVehicle}
        onChange={setSelectedVehicle}
        options={vehicleOptions}
        isRequired={true}
      />
      {validator.current.message("vehicle", selectedVehicle, "required")}
      {vehicleOther && (
        <div className="ml-3">
          <RMInput
            label="Mention details of other vehicle if you selected 'Other' above"
            name="vehicle_other"
            value={memberData.vehicle_other}
            onChange={(e) => handleMemberChange(e)}
            isRequired={vehicleOther ? true : false}
            ref={vehicleOther ? fieldRef : null}
          />
          {validator.current.message(
            "vehicle_other",
            memberData.vehicle_other,
            "required"
          )}
        </div>
      )}
      <RMRadio
        label="18. House Type"
        name="house"
        checked={memberData.house}
        onChange={(e) => handleMemberChange(e)}
        option={houseTypeOptions}
        isRequired={true}
        ref={fieldRef}
      />
      {validator.current.message("house", memberData.house, "required")}
      {memberData?.house === "pucca" && (
        <div className="ml-3">
          <RMRadio
            label="House"
            name="house_type"
            checked={memberData.house_type}
            onChange={(e) => handleMemberChange(e)}
            option={houseOptions}
            isRequired={memberData?.house === "pucca" ? true : false}
            ref={memberData?.house === "pucca" ? fieldRef : null}
          />
          {validator.current.message(
            "house_type",
            memberData.house_type,
            "required"
          )}
        </div>
      )}
      <RMInput
        label="19. Annual Gross Income (Rs.)"
        name="income"
        value={memberData.income}
        onChange={(e) => handleMemberChange(e)}
        allowOnlyNumber={true}
        isRequired={true}
        ref={fieldRef}
      />
      {validator.current.message("income", memberData.income, "required")}
      <RMInput
        label="20. Member Aadhar Card Number"
        name="aadhaar"
        value={memberData.aadhaar}
        max={12}
        onChange={(e) => handleMemberChange(e)}
        allowOnlyNumber={true}
      />
      <RMInput
        label="21. Member PAN Card Number"
        name="pan"
        value={memberData.pan}
        onChange={(e) => handleMemberChange(e)}
      />
      <RMRadio
        label="22. Marital Status"
        name="marital"
        checked={memberData.marital}
        onChange={(e) => handleMemberChange(e)}
        option={maritalOptions}
        isRequired={true}
      />
      {validator.current.message("marital", memberData.marital, "required")}
      {memberData?.marital === "Yes" && (
        <div className="ml-3">
          <RMInput
            label="23. Spouse's Name"
            name="spouse_name"
            value={memberData.spouse_name}
            onChange={(e) => handleMemberChange(e)}
            allowOnlyText={true}
            isRequired={memberData?.marital === "Yes" ? true : false}
            ref={memberData?.marital === "Yes" ? fieldRef : null}
          />
          {validator.current.message(
            "spouse_name",
            memberData.spouse_name,
            "required"
          )}
          <RMInput
            label="24. Spouse's Aadhar Card Number"
            name="spouse_aadhaar"
            value={memberData.spouse_aadhaar}
            max={12}
            onChange={(e) => handleMemberChange(e)}
            allowOnlyNumber={true}
          />
          <RMInput
            label="25. Spouse's PAN Card Number"
            name="spouse_pan"
            value={memberData.spouse_pan}
            onChange={(e) => handleMemberChange(e)}
          />
        </div>
      )}
    </div>
  );
};

export default FarmingDetails;
