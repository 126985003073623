import React, { Component } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination } from "swiper";
import Moment from "react-moment";
import { connect } from "react-redux";
import { getDescriptionData } from "service/helperFunctions";
import defaultStoryImage from "assets/images/DefaultSuccessStory.jpg";
import { history } from "service/helpers";
import "./success_story.scss";
export class SuccessStoryClass extends Component {
  constructor() {
    super();
    SwiperCore.use([Navigation, Pagination]);
  }
  render() {
    const params = {
      slidesPerView: 3,
      spaceBetween: 10,
      loop: false,
      pagination: {
        clickable: true,
      },
      breakpoints: {
        500: {
          slidesPerView: 2,
        },
        768: {
          slidesPerView: 3,
        },
      },
    };

    let { fposuccessstories } = this.props;

    return (
      <div className="pb-4">
        <div className="container-sa d-flex mb-4 pt-4">
          <p className="mx-auto fs-32 fw-800 text-gold card-success-header mb-2 mt-4 pt-2">
            Success stories
          </p>
        </div>
        <div className="container-fluid about-us-success">
          <Swiper {...params}>
            {fposuccessstories.map((story, index) => {
              let {
                storyDocs = [],
                title,
                description,
                publishOn,
                storyId,
              } = story;
              return (
                <SwiperSlide key={index}>
                  <div className="mr-3">
                    <div className="d-flex flex-column align-items-center">
                      <div className="">
                        {storyDocs.length !== 0 ? (
                          <img
                            className="card-success-profile"
                            src={storyDocs[0].documentUrl}
                            alt={storyDocs[0].fileName}
                          />
                        ) : (
                          <img
                            className="card-success-profile"
                            src={defaultStoryImage}
                            alt="story.svg"
                          />
                        )}
                      </div>
                      <div className="card-success-content">
                        <div className="mx-auto p-3 bg-white card-success w-75">
                          <div className="d-flex justify-content-center flex-column">
                            <div>
                              <p className="fs-14 line-height-24 text-grey-1c pr-1 mb-0">
                                Published date
                              </p>
                            </div>
                            <div className="d-flex align-items-center mb-1">
                              <div className="your-events-card-date mr-2">
                                <Moment format="DD">{publishOn}</Moment>
                              </div>
                              <div className="your-events-card-month fs-14">
                                <Moment format="MMMM YYYY">{publishOn}</Moment>
                              </div>
                            </div>
                          </div>
                          <div className="fs-18 fw-600 mb-2 text-green">
                            {title !== "" ? getDescriptionData(title, 30) : ""}
                          </div>
                          <div className="mb-2 fs-14 gray-4f card-success-story line-height-28">
                            {description !== ""
                              ? getDescriptionData(description, 125)
                              : ""}
                          </div>
                          <div className="row card-success-footer w-100">
                            <div
                              className="col-7 fs-14 fw-600 text-green-18 align-self-center cursor-pointer"
                              onClick={() =>
                                history.push(
                                  `/fpo/viewDetails/successstories&${storyId}`
                                )
                              }
                            >
                              {"Read more"}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>
    );
  }
}
let component = SuccessStoryClass;

export const SuccessStory = connect(null, null)(component);
