import React, { Component } from "react";
import "../ListView/style.scss";
import { history } from "service/helpers/index";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
    addSellRequest,
    getfpocommodityList,
    getfpomeasurementList,
    getfpoIndustryist,
    getfpoStateList,
    getfpoDistrictList,
    getMarketCentreList,
    getFpoDetails,
    sellRequestDetail,
    fileUpload,
    getMarketCentreListRM
} from "action/SellrequestAct";
import { Pagination, CommonPageLoader } from "component/common";

import {
    NormalSelect,
    NormalInput,
    NormalButton,
    NormalCheckbox,
    NormalRadio,
    NormalDate,

} from "component/common";
import moment from "moment";
import SimpleReactValidator from "simple-react-validator";
import { commodityList } from "action/PricingandWeatherAct";
const city_svg = require("../../../../assets/svg/SellRequest/city.svg");
const state_svg = require("../../../../assets/svg/SellRequest/state.svg");
const arrow_left_white = require("assets/svg/add_member/arrow_left_white.svg");
const commodity_svg = require("../../../../assets/svg/SellRequest/commodity.svg");
const name_svg = require("../../../../assets/svg/SellRequest/name.svg");

// Define a default UI for filtering

export class NewAddClass extends Component {
    state = {
        fpoMandiName: "",
        fpoCommodity: "",
        fpoUnitMeasurement: "",
        industry: "",
        qualitySpecification: "",
        expectedPricePerUnit: "",
        contactNumber: "",
        existingStockLevel: "",
        promisedProcurementPrice: "",
        numberOfFarmers: "",
        weeklyProcurementQuantity: "",
        monthlyProcurementQuantity: "",
        expectedTimelineOfPayment: "",
        expectedTimelineOfPaymentType: "Days",
        dateAvailableFrom: "",
        dateAvailableTo: "",
        fpoDestinationState: "",
        fpoDestinationDistrict: "",
        fpoDestinationMarket: "",
        location: "",
        commodityOption: [],
        measurementOption: [],
        industryOption: [],
        stateOption: [],
        districtOption: [],
        marketOption: [],
        fpoDetails: {},
        isLoading: false,
        variety: '',
        fpoUnitMeasurementName: '',
        daysHoursWeeks: [],
        custom_measurement: '',
        custom_measurement_blur: false,
        latestPrice: {},
        marketDefaultOption: [],
        attachmentUrl: null,
        attachmentFileName: "",
        isImageUploading: false,
        CommodityData: [],
        attachmentError: '',
        attachmentExtension: '',
        commodityName: '',
        mandiprice: 0
    };

    //validation
    validator = new SimpleReactValidator({
        element: (message) => (
            <span className="error-message text-danger fs-14">{message}</span>
        ),
        autoForceUpdate: this
    });
    componentDidMount = () => {
        this.getFpoDetails()
        if (this.props.id) {
            this.editForm()
        }
        this.props.getfpocommodityList().then((data) => {
            
            let response = [];

            data.map((list) => {
                response.push({ value: list.cropId, label: list.cropName });
            });
            // 
            // response.push({ value: 0, label: 'Others' });
            this.setState({
                CommodityData: data,
                commodityOption: response
            });
        });
        this.props.getfpomeasurementList().then((data) => {
            
            let measurementData = [];

            data.map((list) => {
                measurementData.push({ value: list.id, label: list.type });
            });
            this.setState({
                measurementOption: measurementData,
            });
        });
        this.props.getfpoIndustryist().then((data) => {
            
            let industryData = [];

            data.map((list) => {
                industryData.push({ value: list.id, label: list.industry_type });
            });
            this.setState({
                industryOption: industryData,
            });
        });
        this.props.getfpoStateList().then((data) => {
            
            let stateData = [];

            data.map((list) => {
                stateData.push({ value: list.stateId, label: list.stateName });
            });
            this.setState({
                stateOption: stateData,
            });
        });
        this.setState({
            daysHoursWeeks: [
                { value: "Daily", label: "Daily" },
                { value: "Monthly", label: "Monthly" },
                { value: "Weekly", label: "Weekly" }
            ]
        })
    };
    editForm = () => {
        let { id } = this.props;
        this.setState({ isLoading: true });
        this.props.sellRequestDetail(id).then((data) => {
            
            if (data.length > 0) {
                var result = data[0];
                this.setState({
                    fpoCommodity: result.fpo_commodity !== null && result.fpo_commodity !== ''
                        ? Number(result.fpo_commodity) : '',
                    industry: result.fpo_industry !== null && result.fpo_industry !== ''
                        ? Number(result.fpo_industry) : '',
                    qualitySpecification: result.quality_specification,
                    fpoUnitMeasurement: result.unit_of_measurement !== null && result.unit_of_measurement !== ''
                        ? Number(result.unit_of_measurement) : '',
                    expectedPricePerUnit: result.expected_price_per_unit !== null && result.expected_price_per_unit !== ''
                        ? Number(result.expected_price_per_unit) : '',
                    existingStockLevel: result.existing_stock_level,
                    promisedProcurementPrice: result.promised_procurement_price !== null && result.promised_procurement_price !== ''
                        ? Number(result.promised_procurement_price) : '',
                    numberOfFarmers: result.number_of_farmers !== null && result.number_of_farmers !== ''
                        ? Number(result.number_of_farmers) : '',
                    weeklyProcurementQuantity: result.weekly_procurement_quantity !== null && result.weekly_procurement_quantity !== ''
                        ? Number(result.weekly_procurement_quantity) : '',
                    monthlyProcurementQuantity: result.monthly_procurement_quantity !== null && result.monthly_procurement_quantity !== ''
                        ? result.monthly_procurement_quantity : '',
                    expectedTimelineOfPayment: result.expected_timeline_for_date_of_procurement !== null && result.expected_timeline_for_date_of_procurement !== ''
                        ? Number(result.expected_timeline_for_date_of_procurement) : '',
                    expectedTimelineOfPaymentType: result.expected_timeline_for_date_of_procurement_type,
                    dateAvailableTo: result?.date_available_till && result?.date_available_till !== '' ? new Date(result?.date_available_till) : '',
                    dateAvailableFrom: result?.date_available_from && result?.date_available_from !== '' ? new Date(result?.date_available_from) : '',
                    fpoDestinationState: result.fpo_destination_state,
                    fpoDestinationDistrict: result.fpo_destination_district,
                    fpoDestinationMarket: result.fpo_destination_market,
                    contactNumber: result.contact_number,
                    location: result.location_of_availability_produce,
                    variety: result.variety,
                    custom_measurement: result.unit_of_measurement_name === 'Other' ? result.custom_measurement : '',
                    fpoUnitMeasurementName: result.unit_of_measurement_name,
                    isLoading: false,
                    custom_measurement_blur: true,
                }, () => {
                    if (result?.fpo_attachment_url && result?.fpo_attachment_url !== '') {
                        this.setState({
                            attachmentUrl: result.fpo_attachment_url,
                            attachmentFileName: 'Attachment',
                            attachmentExtension: result.fpo_attachment_url.split('.').pop()
                        });
                    }
                    if (result.fpo_destination_state !== 0) {
                        this.stateChange(result.fpo_destination_state)
                    }
                    this.setState({
                        fpoDestinationDistrict: result.fpo_destination_district,
                    });

                    setTimeout(() => {
                        this.districtChange(result.fpo_destination_district);
                        this.setState({
                            fpoDestinationMarket: result.fpo_destination_market,
                        });
                    }, 2000);
                });
            }
        });
    };
    getFpoDetails = () => {
        this.setState({ isLoading: true });
        this.props.getFpoDetails().then((data) => {
            
            this.setState({
                fpoDetails: data[0],
                fpoMandiName: data[0]?.mandi
            });
            this.setState({ isLoading: false });
        });

    }
    handleSubmit = () => {
        if (this.validator.allValid()) {
            // alert(moment(this.state.dateAvailableFrom).format("YYYY-MM-DD"));
            
            let postdata = {
                // "fpo_mandi": this.state.fpoMandiName,
                "fpo_commodity": this.state.fpoCommodity !== null && this.state.fpoCommodity !== ''
                    ? Number(this.state.fpoCommodity) : '',
                "fpo_industry": this.state.industry !== null && this.state.industry !== ''
                    ? Number(this.state.industry) : '',
                "quality_specification": this.state.qualitySpecification,
                "unit_of_measurement": this.state.fpoUnitMeasurement !== null && this.state.fpoUnitMeasurement !== ''
                    ? Number(this.state.fpoUnitMeasurement) : '',
                "expected_price_per_unit": this.state.expectedPricePerUnit !== null && this.state.expectedPricePerUnit !== ''
                    ? Number(this.state.expectedPricePerUnit) : '',
                "existing_stock_level": this.state.existingStockLevel,
                "promised_procurement_price": this.state.promisedProcurementPrice !== null && this.state.promisedProcurementPrice !== ''
                    ? Number(this.state.promisedProcurementPrice) : '',
                "number_of_farmers": this.state.numberOfFarmers !== null && this.state.numberOfFarmers !== ''
                    ? Number(this.state.numberOfFarmers) : '',
                "weekly_procurement_quantity": this.state.weeklyProcurementQuantity !== null && this.state.weeklyProcurementQuantity !== ''
                    ? Number(this.state.weeklyProcurementQuantity) : '',
                "monthly_procurement_quantity": this.state.monthlyProcurementQuantity !== null && this.state.monthlyProcurementQuantity !== ''
                    ? this.state.monthlyProcurementQuantity : '',
                "expected_timeline_for_date_of_procurement": this.state.expectedTimelineOfPayment !== null && this.state.expectedTimelineOfPayment !== ''
                    ? Number(this.state.expectedTimelineOfPayment) : '',
                "expected_timeline_for_date_of_procurement_type": this.state.expectedTimelineOfPaymentType,
                "date_available_from": moment(this.state.dateAvailableFrom).format("YYYY-MM-DD"),
                "date_available_till": this.state.dateAvailableTo !== '' ? moment(this.state.dateAvailableTo).format("YYYY-MM-DD") : '',
                "fpo_destination_state": this.state.fpoDestinationState,
                "fpo_destination_district": this.state.fpoDestinationDistrict,
                "fpo_destination_market": this.state.fpoDestinationMarket,
                "contact_number": this.state.contactNumber,
                "location_of_availability_produce": this.state.location,
                "variety": this.state.variety,
                "custom_measurement": this.state.fpoUnitMeasurementName === 'Other' ? this.state.custom_measurement : '',
                "fpo_attachment_url": this.state.attachmentUrl,
            };

            if (this.state.latestPrice?.Modal_Price) {
                postdata['market_price'] = this.state.latestPrice?.Modal_Price;
            }
            
            if (this.state.latestPrice?.Date) {
                postdata['market_price_date'] = this.state.latestPrice?.Date;
            }

            if (this.props.id) {
                postdata['id'] = this.props.id
            }

            var postarray = this.removeEmpty(postdata);
            this.props.addSellRequest(postarray).then((data) => {
                
                history.push("/fpo/sell-request");
                // this.props.id ? history.push(`/fpo/sell-request-detail/${this.props.id}`) : history.push("/fpo/sell-request");
            });
        } else {
            
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    handleRemoveImage = () => {
        this.setState({
            attachmentUrl: "",
            attachmentFileName: "",
            attachmentExtension: ""
        });
    };

    handleFile = (e) => {
        let file = e.target.files[0];
        if (file !== undefined && file.size <= 10e6) {
            this.setState({ attachmentUrl: null, attachmentError: '' }, () => {
                this.setState({
                    isImageUploading: true,
                    attachmentFileName: "Uploading ....",
                    attachmentUrl: file,
                });
                const formData = new FormData();
                formData.append("file", file);

                this.props
                    .fileUpload(formData)
                    .then((data) => {
                        document.getElementById("handleFile").value = null;
                        this.setState({
                            attachmentUrl: data[0].fileUrl,
                            attachmentFileName: data[0].fileName,
                            attachmentExtension: data[0].fileUrl.split('.').pop(),
                            isImageUploading: false,
                        });
                    })
                    .catch(() => {
                        this.setState({
                            attachmentUrl: null,
                            attachmentFileName: "",
                            isImageUploading: false,
                        });
                    });
            });
        } else {
            this.setState({ attachmentError: 'Please choose file upto 10MB' });
            setTimeout(() => {
                this.setState({ attachmentError: '' });
            }, 2000);
        }
    };

    onKeyPress(event) {
        
        return (event.charCode >= 65 && event.charCode <= 90) || (event.charCode >= 97 && event.charCode <= 122);
    }

    handleInput = ({ target: { name, value } }) => {
        if (name == 'contactNumber') {
            const re = /^[0-9]*$/;
            if (re.test(value) && value.length <= 10) {
                this.setState({ [name]: value });
            }
        } else {
            this.setState({
                [name]: value,
            });
        }
        if (name === 'fpoDestinationState') {
            this.stateChange(value)
        }
        if (name === 'fpoDestinationMarket') {
            this.marketChange(value);
        }
        if (name === 'fpoDestinationDistrict') {
            this.districtChange(value)
        }
        if (name === 'fpoCommodity') {
            this.commodityChange(value);
            var list = this.state.commodityOption.filter(list => list.value === Number(value));

            this.setState({ commodityName: list[0]?.label });
        }

        if (name === 'fpoUnitMeasurement') {
            var list = this.state.measurementOption.filter(list => list.value === Number(value));
            
            if (list.length > 0) {
                this.setState({
                    fpoUnitMeasurementName: list[0]?.label
                })
            }
            if (value !== 6 || value !== '6') {
                this.setState({ custom_measurement: '' });
            }

        }

        if (name === 'custom_measurement') {
            this.setState({ custom_measurement_blur: false });
        }

    }
    removeEmpty(obj) {
        for (var propName in obj) {
            if (obj[propName] === null || obj[propName] === undefined || obj[propName] === "") {
                delete obj[propName];
            }
        }
        return obj
    }

    stateChange = (value) => {
        this.setState({ fpoDestinationDistrict: '', fpoDestinationMarket: '', isLoading: true })
        var list = this.state.commodityOption.filter(list => list.value === Number(this.state.fpoCommodity));
        
        let payload = {
            commodityName: list[0]?.label
        };
        this.props.getfpoDistrictList(value, payload).then((data) => {
            
            let districtData = [];

            data.map((list) => {
                districtData.push({ value: list.districtId, label: list.districtName });
            });
            this.setState({
                districtOption: districtData,
                isLoading: false
            });
            
        });
        this.languageChange();

    }

    districtChange = (value) => {
        this.setState({ fpoDestinationMarket: '' }, () => {
            
            var list = this.state.commodityOption.filter(list => list.value === Number(this.state.fpoCommodity));
            
            var disctrictname = this.state.districtOption.filter(list => list.value === Number(value));
            if (list.length > 0 && disctrictname.length > 0) {
                this.setState({ isLoading: true }, () => {
                    this.props.getMarketCentreListRM({
                        "commodityName": list[0].label,
                        "districtName": disctrictname[0].label,
                        // "Market_Centre": data[0]?.fpo_destination_market
                    }).then((data) => {
                        
                        var mandiprice = 0;
                        if (data.length > 0) {
                            mandiprice = data[0]?.Modal_Price
                        } else {
                            mandiprice = 0;
                        }
                        this.setState({
                            mandiprice: mandiprice
                        });
                    });

                    this.props.getMarketCentreList({
                        "commodityName": list[0].label,
                        "districtName": disctrictname[0].label,
                    }).then((data) => {
                        
                        let marketData = [];

                        data.map((list) => {
                            if (list?.Modal_Price && list?.Modal_Price !== '' && list?.Modal_Price !== 0) {
                                marketData.push({ value: list.Market_Centre, label: list.Market_Centre });
                            }
                        });
                        this.setState({
                            marketOption: marketData,
                            isLoading: false,
                            marketDefaultOption: data
                        }, () => {
                            this.marketChange(this.state.fpoDestinationMarket);
                        });
                    });

                });
            }
        })
        this.languageChange();


    }
    commodityChange = (value) => {
        
        this.setState({ fpoDestinationDistrict: '', fpoDestinationMarket: '' }, () => {
            var response = this.state.CommodityData.filter(list => list.cropId === Number(value));
            
            // alert();'
            if (response.length > 0) {
                
                this.setState({ industry: response[0].industry_id });
            }

            var list = this.state.commodityOption.filter(list => list.value === Number(value));
            // var disctrictname = this.state.districtOption.filter(list => list.value === Number(this.state.fpoDestinationDistrict));
            // if (list.length > 0 && disctrictname.length > 0) {
            //     this.setState({ isLoading: true }, () => {
            //         this.props.getMarketCentreList({
            //             "commodityName": list[0].label,
            //             "districtName": disctrictname[0].label,
            //         }).then((data) => {
            //             
            //             let marketData = [];

            //             data.map((list) => {
            //                 if (list?.Modal_Price && list?.Modal_Price !== '' && list?.Modal_Price !== 0) {
            //                     marketData.push({ value: list.Market_Centre, label: list.Market_Centre });
            //                 }
            //             });
            //             this.setState({
            //                 marketOption: marketData,
            //                 isLoading: false
            //             });
            //         });
            //     });
            // }
            if (list.length > 0 && this.state.fpoDestinationState != '') {
                let payload = {
                    commodityName: list[0]?.label
                };
                this.setState({ isLoading: true }, () => {
                    this.props.getfpoDistrictList(this.state.fpoDestinationState, payload).then((data) => {
                        
                        let districtData = [];

                        data.map((list) => {
                            districtData.push({ value: list.districtId, label: list.districtName });
                        });
                        this.setState({
                            districtOption: districtData,
                            isLoading: false
                        });
                        
                    });
                });
            }

            this.languageChange();

        })
    }
    languageChange = () => {
        var lang = document.querySelector("html").getAttribute('lang');
        // document.querySelector("html").setAttribute('lang','')
        // document.querySelector("html").setAttribute('lang','hi')
        // new window.google.translate.TranslateElement({pageLanguage: 'hi', layout: window.google.translate.TranslateElement.FloatPosition.TOP_LEFT}, 'google_translate_element')
        
    }
    marketChange = (value) => {
        
        if (this.state.marketDefaultOption?.length > 0) {
            
            var latestPrice = this.state.marketDefaultOption.filter(list => list.Market_Centre === value);
            if (latestPrice.length > 0) {
                
                this.setState({ latestPrice: latestPrice[0] });
            } else {
                
                this.setState({ latestPrice: {} });
            }
        } else {
            
            this.setState({ latestPrice: {} });
        }
        this.languageChange();
        
    }
    custom_measurementChange = ({ target: { name, value } }) => {
        this.setState({ custom_measurement_blur: true });
    }
    measurement(type = '') {
        var value = '';
        if (this.state.fpoUnitMeasurementName === 'Other') {
            value = '';
            if (this.state.custom_measurement_blur === true) {
                value = this.state.custom_measurement;
            }
        } else {
            value = this.state.fpoUnitMeasurementName;
        }
        // this.state.fpoUnitMeasurementName === 'Other' && this.custom_measurement_blur === true ? value = this.state.custom_measurement : value = this.state.fpoUnitMeasurementName;
        if (type !== '') {
            value = 'Per ' + value;
        } else {
            value = value;
        }
        return value;
    }

    render() {
        const {
            fpoMandiName,
            fpoCommodity,
            fpoUnitMeasurement,
            industry,
            qualitySpecification,
            expectedPricePerUnit,
            contactNumber,
            existingStockLevel,
            promisedProcurementPrice,
            numberOfFarmers,
            weeklyProcurementQuantity,
            monthlyProcurementQuantity,
            dateAvailableFrom,
            dateAvailableTo,
            fpoDestinationState,
            fpoDestinationDistrict,
            fpoDestinationMarket,
            location,
            commodityOption,
            measurementOption,
            industryOption,
            stateOption,
            districtOption,
            marketOption,
            fpoDetails,
            isLoading,
            variety,
            fpoUnitMeasurementName,
            daysHoursWeeks,
            expectedTimelineOfPayment,
            expectedTimelineOfPaymentType,
            custom_measurement,
            latestPrice,
            attachmentUrl,
            attachmentFileName,
            isImageUploading,
            attachmentError,
            attachmentExtension,
            commodityName,
            mandiprice
        } = this.state;
        this.validator.purgeFields();

        return (
            <div className="add-re-scr">
                {isLoading === true ?
                    <CommonPageLoader /> : ''
                }
                <div className="container-sa position-relative mt-4">
                    {/* INFO SECTION */}
                    <div className="add-form-section">
                        <div className="row">
                            <h2 className="m-0 fs-18 mt-0 font-weight-bold">{fpoDetails?.fpoName}</h2>


                            <div className="col pr-0">
                                <div onClick={() => history.push("/fpo/sell-request")} className="text-right d-flex align-items-center justify-content-end">
                                    {/* <div onClick={() => this.props.id ? history.push(`/fpo/sell-request-detail/${this.props.id}`) : history.push("/fpo/sell-request")} className="text-right d-flex align-items-center justify-content-end"> */}
                                    <p className="btn btn-secondary btn-sm add-member-text-back mt-0 mb-0 cursor-pointer d-flex align-items-center justify-content-center w-150">
                                        <img className="mr-2" src={arrow_left_white} alt="name" width="15" />
                                        Back
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* INFO SECTION */}
                    <div className="clearfix"></div>
                    {/* ADD FORM SECTION */}
                    <div className="">
                        <div className="row ">
                            <div className="col-4 let-fom ">
                                <div className="form-ele px-0  v-input">
                                    {/*  */}
                                    <div className="col-sm-12 select-section full-width">
                                        <div className="row d-flex align-items-center ">

                                            <div className="d-flex col-sm-12">
                                                <div className="w-50 mr-1 ml-1">
                                                    <label className=" lab-wi">Commodity:<span className="text-danger font-weight-bold pl-0 fs-18">*</span>
                                                    </label>
                                                    <NormalSelect
                                                        isFullWidth={true}
                                                        label={""}
                                                        placeholder="Commodity"
                                                        name="fpoCommodity"
                                                        handleChange={this.handleInput}
                                                        arrow={true}
                                                        options={commodityOption}
                                                        value={fpoCommodity}
                                                        isHalfWidth={false}
                                                        isFullWidth={false}
                                                        required={true}
                                                    />
                                                    {this.validator.message(
                                                        "fpoCommodity",
                                                        fpoCommodity,
                                                        "required"
                                                    )}
                                                </div>

                                                <div className={fpoUnitMeasurementName === 'Other' ? 'w-50 ml-1 mr-1' : 'w-50 ml-1 mr-1'}>

                                                    <label className=" lab-wi">Unit:<span className="text-danger font-weight-bold pl-0 fs-18">*</span></label>
                                                    <NormalSelect
                                                        isFullWidth={true}
                                                        label={""}
                                                        placeholder="Unit Measurement"
                                                        name="fpoUnitMeasurement"
                                                        value={fpoUnitMeasurement}
                                                        arrow={true}
                                                        isHalfWidth={false}
                                                        isFullWidth={false}
                                                        required={true}
                                                        options={measurementOption}
                                                        handleChange={this.handleInput}
                                                    />
                                                    {this.validator.message(
                                                        "Unit",
                                                        fpoUnitMeasurement,
                                                        "required"
                                                    )}

                                                </div>

                                                {
                                                    fpoUnitMeasurementName === 'Other' ?
                                                        <div className="w-50 ml-1 commonpadding mb-10">
                                                            <label className=" lab-wi">
                                                                Measurement<span className="text-danger font-weight-bold pl-0 fs-18">*</span></label>
                                                            {/* <div className="normal-input">
                                                                <form
                                                                    autocomplete="off"
                                                                    onSubmit={(e) => {
                                                                        e.preventDefault();
                                                                        e.stopPropagation();
                                                                    }}
                                                                >
                                                                    <div className="row d-flex align-items-center">
                                                                        <div className="col-3">
                                                                        <div className="col-9"> */}

                                                            <input
                                                                className="form-control measurement"
                                                                label="Measurement"
                                                                type="text"
                                                                name="custom_measurement"
                                                                onChange={this.handleInput}
                                                                onBlur={this.custom_measurementChange}
                                                                value={custom_measurement}
                                                                isHalfWidth={false}
                                                                isFullWidth={false}
                                                                required={true}
                                                            />
                                                            {/* </div>
                                                                        </div>
                                                                    </div>
                                                                </form>
                                                            </div> */}
                                                            {this.validator.message(
                                                                "measurement",
                                                                custom_measurement,
                                                                "required"
                                                            )}
                                                        </div>
                                                        : ''
                                                }



                                            </div>
                                        </div>
                                    </div>
                                    {/*  */}
                                    <div className="col-sm-12 select-section full-width">
                                        <div className="row d-flex align-items-center ">

                                            <div className="d-flex col-sm-12 unit-det ">
                                                <div className="w-50 ml-1 mr-1" >

                                                    <label className=" lab-wi">Category:<span className="text-danger font-weight-bold pl-0 fs-18">*</span></label>

                                                    <NormalSelect
                                                        isFullWidth={true}
                                                        label={""}
                                                        placeholder="Category"
                                                        name="industry"
                                                        value={industry}
                                                        arrow={true}
                                                        isHalfWidth={false}
                                                        isFullWidth={false}
                                                        required={true}
                                                        options={industryOption}
                                                        handleChange={this.handleInput}
                                                    />
                                                    {this.validator.message(
                                                        "category",
                                                        industry,
                                                        "required"
                                                    )}



                                                </div>
                                                <div className="w-50 mr-1 ml-1">
                                                    <label className=" lab-wi">Available for Sale
                                                        :<span className="text-danger font-weight-bold pl-0 fs-18">*</span></label>
                                                    <div className="d-flex">
                                                        <NormalInput
                                                            label=""
                                                            type="number"
                                                            onChange={this.handleInput}
                                                            name="existingStockLevel"
                                                            value={existingStockLevel}
                                                            isHalfWidth={false}
                                                            isFullWidth={false}
                                                            required={true}
                                                        />
                                                        <div className="absolute quapop"><small className="d-block mt-qu ml-2">{this.measurement()}</small></div>
                                                    </div>
                                                    {this.validator.message(
                                                        "existingStockLevel",
                                                        existingStockLevel,
                                                        "required"
                                                    )}
                                                </div>
                                                <div className="w-50 mr-1 ml-1">
                                                    <label className=" lab-wi">Expected Price:<span className="text-danger font-weight-bold pl-0 fs-18">*</span>

                                                    </label>
                                                    <div className="d-flex pertxt">
                                                        <NormalInput
                                                            label=""
                                                            type="number"
                                                            name="expectedPricePerUnit"
                                                            onChange={this.handleInput}
                                                            value={expectedPricePerUnit}
                                                            isHalfWidth={false}
                                                            isFullWidth={false}
                                                            required={true}
                                                        />
                                                        <div className="absolute quapop"><small className="d-block mt-qu ml-2">{this.measurement('rupees')}</small></div>
                                                    </div>

                                                    {this.validator.message(
                                                        "expectedPrice",
                                                        expectedPricePerUnit,
                                                        "required"
                                                    )}
                                                </div>



                                            </div>
                                        </div>
                                    </div>
                                    {/*  */}

                                    <div className="col-sm-12 select-section full-width">
                                        <div className="row d-flex align-items-center ">

                                            <div className="d-flex col-sm-12 unit-det">

                                                <div className="w-50 ml-1 mr-1">
                                                    <label className=" lab-wi">Contact Number:</label>
                                                    <div className="relativecont">
                                                        <span className="contxt">+91</span>
                                                        <NormalInput
                                                            label="Contact Number"
                                                            type="text"
                                                            name="contactNumber"
                                                            onChange={this.handleInput}
                                                            value={contactNumber}
                                                            isHalfWidth={false}
                                                            isFullWidth={false}
                                                            required={false}
                                                            maxLength={10}
                                                        />
                                                    </div>
                                                    {this.validator.message(
                                                        "contactNumber",
                                                        contactNumber,
                                                        "phone|min:10|max:10"
                                                    )}
                                                </div>

                                            </div>
                                        </div>
                                    </div>



                                    {/*  */}
                                    <div className="col-sm-12 select-section full-width">
                                        <div className="row d-flex align-items-center ">

                                            <div className="d-flex col-sm-12 unit-det">
                                                <div className="w-50 mr-1 ml-1">
                                                    <label className=" lab-wi">Available&nbsp;at&nbsp;Location:<span className="text-danger font-weight-bold pl-0 fs-18">*</span>

                                                    </label>
                                                    <NormalInput
                                                        label="Location of availability of produce - entered by FPO"
                                                        type="text"
                                                        onChange={this.handleInput}
                                                        name="location"
                                                        value={location}
                                                        isHalfWidth={false}
                                                        isFullWidth={false}
                                                        required={true}
                                                    />
                                                    {this.validator.message(
                                                        "location",
                                                        location,
                                                        "required"
                                                    )}
                                                </div>
                                                <div className="w-50 ml-1 mr-1">
                                                    <label className=" lab-wi">Available From: <span className="text-danger font-weight-bold pl-0 fs-18">*</span>
                                                    </label>
                                                    <NormalDate
                                                        isIcon={true}
                                                        onChange={this.handleInput}
                                                        name="dateAvailableFrom"
                                                        value={dateAvailableFrom}
                                                        placeholder="DD/MM/YYYY"
                                                        mindate={false}
                                                        maxdate={false}
                                                        timeInput={false}
                                                        dateAvailableFrom
                                                        label={""}
                                                        required={true}
                                                    />
                                                    {this.validator.message(
                                                        "dateAvailableFrom",
                                                        dateAvailableFrom,
                                                        "required"
                                                    )}
                                                </div>

                                                <div className="w-50 ml-1 mr-1">
                                                    <label className=" lab-wi">Available Till:
                                                    </label>
                                                    <NormalDate
                                                        isIcon={true}
                                                        onChange={this.handleInput}
                                                        name="dateAvailableTo"
                                                        value={dateAvailableTo}
                                                        placeholder="DD/MM/YYYY"
                                                        mindate={false}
                                                        maxdate={false}
                                                        timeInput={false}
                                                        dateAvailableTo
                                                        label={""}
                                                    />

                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                    {/*  */}
                                    <div className="col-sm-12 select-section full-width">
                                        <div className="row d-flex align-items-center ">
                                            <div className="col-3 pr-0 pl-0 ">
                                                <div className="fs-16 text-black-32 font-weight-bold line-height-19">
                                                    Nearest Market:
                                                    {/* <span className="text-danger font-weight-bold pl-0 fs-18">*</span> */}
                                                </div>
                                            </div>

                                            <div className="d-flex col-sm-12">
                                                <div className="w-33 mr-1 ml-1">
                                                    {/* <label>State: <span className="text-danger font-weight-bold pl-0 fs-18">*</span></label> */}
                                                    <NormalSelect
                                                        isFullWidth={true}
                                                        label={""}
                                                        placeholder="State"
                                                        handleChange={this.handleInput}
                                                        name="fpoDestinationState"
                                                        value={fpoDestinationState}
                                                        arrow={true}
                                                        isHalfWidth={false}
                                                        isFullWidth={false}
                                                        required={true}
                                                        options={stateOption}
                                                    />
                                                    {/* {this.validator.message(
                                                        "fpoDestinationState",
                                                        fpoDestinationState,
                                                        "required"
                                                    )} */}
                                                </div>



                                                <div className="w-33 mr-1 ml-1">
                                                    {/* <label>District: <span className="text-danger font-weight-bold pl-0 fs-18">*</span></label> */}
                                                    <NormalSelect
                                                        isFullWidth={true}
                                                        label={""}
                                                        placeholder="District"
                                                        handleChange={this.handleInput}
                                                        name="fpoDestinationDistrict"
                                                        value={fpoDestinationDistrict}
                                                        arrow={true}
                                                        isHalfWidth={false}
                                                        isFullWidth={false}
                                                        required={true}
                                                        options={districtOption}
                                                        disabled={fpoDestinationState === ''}
                                                    />
                                                    {/* {this.validator.message(
                                                        "fpoDestinationDistrict",
                                                        fpoDestinationDistrict,
                                                        "required"
                                                    )} */}
                                                </div>

                                                <div className="w-33 mr-1 ml-1">
                                                    {/* <label>Market: <span className="text-danger font-weight-bold pl-0 fs-18">*</span></label> */}
                                                    <NormalSelect
                                                        isFullWidth={true}
                                                        label={""}
                                                        placeholder="Market"
                                                        handleChange={this.handleInput}
                                                        name="fpoDestinationMarket"
                                                        value={fpoDestinationMarket}
                                                        arrow={true}
                                                        isHalfWidth={false}
                                                        isFullWidth={false}
                                                        required={true}
                                                        options={marketOption}
                                                        disabled={fpoDestinationDistrict === '' || fpoCommodity === ''}
                                                    />
                                                    {/* {this.validator.message(
                                                        "fpoDestinationMarket",
                                                        fpoDestinationMarket,
                                                        "required"
                                                    )} */}
                                                </div>


                                            </div>

                                        </div></div>
                                    {/*  */}




                                </div>
                            </div>
                            <div className="col-7  rht-frm">

                                <h3>Details:</h3>
                                <hr />

                                <div className='col-sm-12 mb-20 sm-wi '>
                                    <div className="d-flex fl-col">
                                        <div className="w-50">
                                            <NormalInput
                                                label="Quality Specification"
                                                type="text"
                                                name="qualitySpecification"
                                                onChange={this.handleInput}
                                                value={qualitySpecification}
                                                isHalfWidth={false}
                                                isFullWidth={false}
                                                required={true}
                                            />
                                            {this.validator.message(
                                                "qualitySpecification",
                                                qualitySpecification,
                                                "required"
                                            )}
                                        </div>

                                        <div className="w-50">
                                            <NormalInput
                                                label="Variety"
                                                type="text"
                                                name="variety"
                                                onChange={this.handleInput}
                                                value={variety}
                                                isHalfWidth={false}
                                                isFullWidth={false}
                                                required={true}
                                            />
                                            {this.validator.message(
                                                "variety",
                                                variety,
                                                "required"
                                            )}
                                        </div>
                                    </div>




                                </div>



                                <div className='col-sm-12 mb-20 sm-wi '>
                                    <div className="d-flex fl-col">
                                        <div className="w-50 supplycapacity">
                                            {/* <NormalInput
                                                label="Supply Capacity"
                                                type="text"
                                                name="qualitySpecification"
                                                onChange={this.handleInput}
                                                value={qualitySpecification}
                                                isHalfWidth={false}
                                                isFullWidth={false}
                                                required={true}
                                            /> */}
                                            <label>Supply Capacity:</label>

                                            <NormalSelect
                                                isFullWidth={true}
                                                label={""}
                                                handleChange={this.handleInput}
                                                arrow={true}
                                                className="form-control"
                                                name="monthlyProcurementQuantity"
                                                value={monthlyProcurementQuantity}
                                                isHalfWidth={false}
                                                isFullWidth={false}
                                                required={true}
                                                options={daysHoursWeeks}
                                            />

                                        </div>

                                        <div className="relative w-50 sup-width">
                                            {
                                                monthlyProcurementQuantity != '' ?
                                                    <React.Fragment>
                                                        <NormalInput
                                                            label="Quantity"
                                                            type="number"
                                                            onChange={this.handleInput}
                                                            name="weeklyProcurementQuantity"
                                                            value={weeklyProcurementQuantity}
                                                            isHalfWidth={false}
                                                            isFullWidth={false}
                                                            required={false}
                                                        />
                                                        <div className="absolute quapop quantityper"><small className="d-block mt-qu ml-2">{this.measurement()}</small></div>
                                                    </React.Fragment>
                                                    : ''}
                                        </div>
                                    </div>




                                </div>


                                {/*  */}

                                {/* <div className='col-sm-12 mb-20 sm-wi unit-det mt-5'>
                                    <div className="row">
                                        <label><b>Supply Capacity:</b></label>
                                    </div>

                                    <div className="relative d-flex mb-20 sup-width">
                                        <NormalInput
                                            label="Weekly: "
                                            type="number"
                                            onChange={this.handleInput}
                                            name="weeklyProcurementQuantity"
                                            value={weeklyProcurementQuantity}
                                            isHalfWidth={false}
                                            isFullWidth={false}
                                            required={false}
                                        />
                                        <div className="absolute quapop"><small className="d-block mt-qu ml-2">{this.measurement()}</small></div>
                                    </div>
                                    <div className="relative d-flex mb-20 sm-wi  sup-width unit-det ">

                                        <NormalInput
                                            label="Monthly:"
                                            type="number"
                                            onChange={this.handleInput}
                                            name="monthlyProcurementQuantity"
                                            value={monthlyProcurementQuantity}
                                            isHalfWidth={false}
                                            isFullWidth={false}
                                            required={false}
                                        />
                                        <div className="absolute quapop"><small className="d-block mt-qu ml-2">{this.measurement()}</small></div>
                                    </div>
                                </div> */}

                                {/*  */}

                                <h3 className="mt-5">Farmer Benefit:</h3>

                                <hr />

                                <div className='col-sm-12 mb-20 sm-wi'>
                                    <NormalInput
                                        label="No of farmers engaged:"
                                        type="number"
                                        onChange={this.handleInput}
                                        name="numberOfFarmers"
                                        value={numberOfFarmers}
                                        isHalfWidth={false}
                                        isFullWidth={false}
                                        required={true}
                                    />
                                    {this.validator.message(
                                        "numberOfFarmers",
                                        numberOfFarmers,
                                        "required"
                                    )}
                                </div>

                                <div className='col-sm-12 mb-20 sm-wi unit-det '>

                                    <div className="relative  w-100 sup-width">

                                        <NormalInput
                                            label="Promised Procurement Price"
                                            type="number"
                                            onChange={this.handleInput}
                                            name="promisedProcurementPrice"
                                            value={promisedProcurementPrice}
                                            isHalfWidth={false}
                                            isFullWidth={false}
                                            required={true}
                                        />
                                        {this.validator.message(
                                            "promisedProcurementPrice",
                                            promisedProcurementPrice,
                                            "required"
                                        )}
                                        <div className="absolute quapop"><small className="d-block mt-qu ml-2">{this.measurement('rupees')}</small></div>
                                    </div>


                                </div>

                                <div className='col-sm-12 mb-20 sm-wi '>
                                    <div className="d-flex fl-col expectedwrp">
                                        <div className="w-50 relative">
                                            <NormalInput
                                                label="Expected Timeline of payment"
                                                type="text"
                                                onChange={this.handleInput}
                                                name="expectedTimelineOfPayment"
                                                value={expectedTimelineOfPayment}
                                                isHalfWidth={false}
                                                isFullWidth={false}
                                            />
                                            <div className="absolute quapop"><small className="d-block mt-qu ml-2">Days</small></div>
                                        </div>

                                        <div className="w-42">
                                            <div className="normal-input">
                                                <div className="row d-flex align-items-center">
                                                    <div className="col-3 pr-0 ">
                                                        <div className="fs-16 text-black-32 font-weight-bold line-height-19 false">Attachment <small>(Photo or Video)</small></div>
                                                    </div>
                                                    <div className="col-9  ">
                                                        <div className="flex  align-items-center btn-file-input ">
                                                            <button className="">
                                                                <input
                                                                    type="file"
                                                                    className=""
                                                                    accept=".jpg, .png, .jpeg, .mp4"
                                                                    onChange={this.handleFile}
                                                                    id="handleFile"
                                                                    title=""
                                                                />
                                                                {attachmentUrl === null ? (
                                                                    "Choose file"
                                                                ) : (
                                                                    <>
                                                                        {attachmentFileName.length === 20
                                                                            ? attachmentFileName
                                                                            : attachmentFileName.substring(0, 19)}
                                                                        {"..."}
                                                                    </>
                                                                )}
                                                            </button>
                                                            {attachmentFileName !== "" && !isImageUploading && (
                                                                <div className="pl-3 position-absolute filesize">

                                                                    {attachmentExtension === 'mp4' ?
                                                                        (
                                                                            <video
                                                                                src={attachmentUrl}
                                                                                alt={attachmentFileName}
                                                                            />
                                                                        ) : (
                                                                            <img
                                                                                src={attachmentUrl}
                                                                                alt={attachmentFileName}
                                                                            />
                                                                        )
                                                                    }
                                                                    <i
                                                                        className="icon-Delete text-danger fs-14 pl-2 cursor-pointer position-absolute"
                                                                        onClick={() => this.handleRemoveImage()}
                                                                    ></i>
                                                                </div>
                                                            )}
                                                        </div>
                                                        {attachmentError !== '' ? (<span className="error-message text-danger fs-14">{attachmentError}</span>) : ('')}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>




                                </div>

                                {/* 
                                <div className='col-sm-12 mb-20 '>


                                    <div className="d-flex">
                                        <div className="relative expectedwrp">
                                            
                                        </div>

                                        <div className="expectedwrp">
                                            
                                        </div> */}


                                {/* <div className="absolute">
                                            <NormalSelect
                                                isFullWidth={true}
                                                label={""}
                                                placeholder="Select days/hours/weeks"
                                                handleChange={this.handleInput}
                                                name="expectedTimelineOfPaymentType"
                                                value={expectedTimelineOfPaymentType}
                                                arrow={true}
                                                isHalfWidth={false}
                                                isFullWidth={false}
                                                options={daysHoursWeeks}
                                            />
                                        </div> */}
                                {/* </div> */}
                                {/* {expectedTimelineOfPaymentType !== '' && expectedTimelineOfPayment === '' ?
                                        this.validator.message(
                                            "expectedTimelineOfPayment",
                                            expectedTimelineOfPayment,
                                            "required"
                                        ) : ''}
                                    {expectedTimelineOfPayment !== '' && expectedTimelineOfPaymentType === '' ?
                                        this.validator.message(
                                            "expectedTimelineOfPaymentType",
                                            expectedTimelineOfPaymentType,
                                            "required"
                                        ) : ''} */}


                                {/* </div> */}

                                {/* <div className="col-sm-12">
                                    <div className="normal-input">
                                        
                                    </div>
                                </div> */}
                                <hr className="mt-5" />
                                <div className="cu-btn ">
                                    <NormalButton
                                        label="Submit"
                                        onClick={() => this.handleSubmit()}
                                        className="px-3 py-2 ml-auto  fs-14 line-height-26 mainbg-btn"
                                    />
                                </div>
                            </div>

                            {
                                this.state.fpoDestinationMarket !== '' ?
                                    <div className="col-2 price-frm">
                                        {/* mandiprice >= */}
                                        {
                                            latestPrice?.Modal_Price ?
                                                <div className="lastpri">
                                                    <b>Latest price: </b><br />
                                                    <hr />
                                                    {/* className="greentxt" */}
                                                    <span >
                                                        {latestPrice?.Market_Centre} {'₹' + latestPrice?.Modal_Price} ({moment(latestPrice?.Date).format("DD-MM-YYYY")})
                                                        {
                                                            latestPrice?.Variety !== 'Other' && latestPrice?.Variety !== commodityName ?
                                                                <p>Variety: {latestPrice?.Variety}</p>
                                                                : ''}
                                                    </span>

                                                </div>
                                                : ''
                                        }
                                    </div>
                                    : ''
                            }



                            {/* DISPLAYING WHILE CHOOSING FPO Commodity VALUE */}


                            {/* ADD FORM SECTION */}

                        </div>
                        <div>&nbsp;</div>

                    </div>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            addSellRequest,
            getfpocommodityList,
            getfpomeasurementList,
            getfpoIndustryist,
            getfpoStateList,
            getfpoDistrictList,
            getMarketCentreList,
            getFpoDetails,
            sellRequestDetail,
            fileUpload,
            getMarketCentreListRM
        },
        dispatch
    );
};
let component = NewAddClass;

export const NewAddView = connect(
    null,
    mapDispatchToProps
)(component);