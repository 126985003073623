import React, { Component } from "react";
import "./index.scss";
import { Col, Row } from "reactstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Pagination, CommonPageLoader } from "component/common";

import moment from "moment";
import { getCalenderFpoListRm } from "action/RmAct";

import arrow_left from "assets/svg/add_member/arrow_left.svg";
import { history } from "service/helpers";
const profile = require("assets/svg/fpoduelist.svg");
export class FpoDueListClass extends Component {
  state = {
    fpoList: [],
    page: 1,
    pageMeta: {},
    isLoading: false,
  };

  //handle fpo due list
  handleFpoList = (page) => {
    this.setState({
      isLoading: true,
    });
    let query = {
      page: page ? page : 1,
      limit: 4,
    };
    this.props
      .getCalenderFpoListRm(query)
      .then((data) => {
        this.setState({
          fpoList: data.results,
          pageMeta: data.pageMeta,
          isLoading: false,
        });
      })
      .catch((err) => {
        this.setState({
          isLoading: false,
        });
      });
  };

  //handle month change
  handlePagination = (page) => {
    this.handleFpoList(page);
  };

  //lifecycles
  componentDidMount() {
    this.handleFpoList();
  }

  render() {
    const { fpoList, pageMeta, isLoading } = this.state;
    return (
      <div className="fpoduelist">
        <div className="action-section">
          <div className="container-sa">
            <div className="d-flex align-items-center">
              <div
                onClick={() => history.goBack()}
                className="d-flex align-items-center cursor-pointer"
              >
                <img className="cursor-pointer" src={arrow_left} alt="back" />
                <p className="back-title">Back</p>
              </div>
            </div>
          </div>
        </div>
        <div className="content-section">
          <div className="container-sa">
            <div className="content-action mb-4">
              <div className="row">
                <div className="col-3 ml-auto">
                  <input
                    type-="text"
                    className="form-control form-control-sa"
                    placeholder="Search"
                    onChange={(e) => this.handleInput(e)}
                  />
                </div>
              </div>
            </div>
            {!isLoading ? (
              <div className="list-section">
                {fpoList.length > 0 &&
                  fpoList.map(
                    ({
                      fpoDetails,
                      fpoTasks: { taskName, dueDate, dueDays },
                      index,
                    }) => {
                      return (
                        <div className="list-card" key={index}>
                          <Row>
                            <Col sm="1">
                              <img
                                className="list-img"
                                src={
                                  fpoDetails.fpoLogoURL
                                    ? fpoDetails.fpoLogoURL
                                    : profile
                                }
                                alt="profile"
                              />
                            </Col>
                            <Col sm="5">
                              <div>
                                <p className="list-content">{taskName}</p>
                              </div>
                              <div>
                                <p className="list-heading">{"Task Name"}</p>
                              </div>
                            </Col>
                            <Col sm="3">
                              <div>
                                <p className="list-content">
                                  {moment(dueDate).format("D MMM YYYY")}
                                </p>
                              </div>
                              <div>
                                <p className="list-heading">{"Due Date"}</p>
                              </div>
                            </Col>
                            <Col sm="3">
                              <div>
                                <p className="list-content">{dueDays}</p>
                              </div>
                              <div>
                                <p className="list-heading">{"Days Pending"}</p>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      );
                    }
                  )}
                {pageMeta && (
                  <Pagination
                    handleChange={this.handlePagination}
                    pageMeta={pageMeta}
                  />
                )}
              </div>
            ) : (
              <CommonPageLoader />
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getCalenderFpoListRm,
    },
    dispatch
  );
};
let component = FpoDueListClass;

export const FpoDueList = connect(null, mapDispatchToProps)(component);
