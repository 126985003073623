import React, { Fragment, useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useHistory } from "react-router-dom";
import {
  getFPOMemberList,
  getCropsCategoryRedux,
  getCropsClassificationRedux,
} from "action/GradingAct";
import { getKYMNewFPOMemberList } from "action/KYMAct";
import { CommonPageLoader, Pagination } from "component/common";
import { debounceFunction } from "service/debounce";
import ".././style.scss";

const tableHeader = [
  { title: "FPO Member ID" },
  { title: "Name" },
  { title: "Mobile" },
  { title: "Know Your Member" },
  { title: "Percentage of Completion" },
];

const MemberList = ({
  id,
  module = "rm",
  getFPOMemberListApi,
  getKYMNewFPOMemberListApi,
  getCropsCategoryReduxApi,
  getCropsClassificationReduxApi,
  categoryOptions,
  cropOptions,
}) => {
  const history = useHistory();
  const [memberList, setMemberList] = useState([]);
  const [pageMeta, setPageMeta] = useState({});
  const [loading, setLoading] = useState(false);
  const isRM = module === "rm";

  const getFPOList = useCallback(
    (page = 1, search = "") => {
      if (id) {
        const api = isRM ? getFPOMemberListApi : getKYMNewFPOMemberListApi;
        setLoading(true);
        api(id, { page, search })
          .then(({ results, pageMeta }) => {
            setMemberList(results);
            setPageMeta(pageMeta);
            setLoading(false);
          })
          .catch(() => setLoading(false));
      }
    },
    [getFPOMemberListApi, getKYMNewFPOMemberListApi, id, isRM]
  );

  useEffect(() => {
    getFPOList(1);
  }, [getFPOList]);

  const getCategoryOptions = useCallback(() => {
    if (categoryOptions?.length === 0) getCropsCategoryReduxApi();
  }, [getCropsCategoryReduxApi, categoryOptions]);

  useEffect(() => {
    getCategoryOptions();
  }, [getCategoryOptions]);

  const getCropOptions = useCallback(() => {
    if (cropOptions?.length === 0) getCropsClassificationReduxApi();
  }, [getCropsClassificationReduxApi, cropOptions]);

  useEffect(() => {
    getCropOptions();
  }, [getCropOptions]);

  const handleSearch = (value) => {
    debounceFunction(() => {
      getFPOList(1, value);
    }, 1000);
  };

  const handlePagination = (page) => getFPOList(page);

  return (
    <div className="container-sa my-3">
      <div className="rm-dashboard">
        <div className="d-flex align-items-center justify-content-between kym_new_list">
          <h1 className="add-farmer">
            To start KYM survey for a new farmer,{" "}
            <span
              className="text-green-18"
              onClick={() => history.push(`/${module}/kym-new-details`)}
            >
              click here
            </span>
          </h1>
          <div className="mt-md-0 mt-3">
            <input
              type="text"
              className="form-control form-control-sa w-350"
              placeholder="Search by Member ID, Name or Mobile"
              onChange={({ target: { value } }) => handleSearch(value)}
            />
          </div>
        </div>
        {loading ? (
          <CommonPageLoader />
        ) : (
          <Fragment>
            {memberList.length > 0 ? (
              <div className="d-flex flex-column flex-wrap mt-3 px-0 justify-content-center table_responsive">
                <table>
                  <thead>
                    <tr>
                      {tableHeader?.map((item, index) => (
                        <th key={index}>{item?.title}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {memberList?.map(
                      (
                        {
                          memberID,
                          memberFirstName,
                          memberLastName,
                          phone,
                          ansquerycal,
                        },
                        index
                      ) => (
                        <React.Fragment key={index}>
                          <tr>
                            <td>{memberID}</td>
                            <td>
                              {memberFirstName} {memberLastName || ""}
                            </td>
                            <td>{phone || "-"}</td>
                            <td
                              className="edit-button"
                              onClick={() =>
                                history.push(
                                  `/${module}/kym-new-details?memberID=${memberID}`
                                )
                              }
                            >
                              View/Edit KYM
                            </td>
                            <td>{ansquerycal}%</td>
                          </tr>
                          <tr className="trans-bg">
                            <td className="empty-height" height="12"></td>
                          </tr>
                        </React.Fragment>
                      )
                    )}
                  </tbody>
                </table>
              </div>
            ) : (
              <div className="col-12">
                <p className="py-5 mb-0 text-black-50 fs-22 line-height-25 text-center">
                  No data found
                </p>
              </div>
            )}
            <Pagination handleChange={handlePagination} pageMeta={pageMeta} />
          </Fragment>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  categoryOptions: state.rm.categoryOptions,
  cropOptions: state.rm.cropOptions,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getFPOMemberListApi: getFPOMemberList,
      getKYMNewFPOMemberListApi: getKYMNewFPOMemberList,
      getCropsCategoryReduxApi: getCropsCategoryRedux,
      getCropsClassificationReduxApi: getCropsClassificationRedux,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(MemberList);
