import React, { Component } from "react";
import { SearchView } from "component/Search";
import { Navbar } from "../../component/common";

export class Search extends Component {
  //lifecycles
  componentDidMount = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  render() {
    return (
      <div>
        <div className="normal-header">{/* <Navbar /> */}</div>
        <SearchView fpoName={this.props.match.params.fpoName} />
      </div>
    );
  }
}
