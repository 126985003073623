import React, { Component } from "react";
import "./company.scss";
import { connect } from "react-redux";
import { CommonPageLoader, ConfirmationPopup } from "component/common";
import { bindActionCreators } from "redux";
import {
  addFpoDocuments,
  viewFpoDocuments,
  deleteFpoDocuments,
  getCompanyKycBulkdownload,
  companyKYCEdit,
} from "action/DocumentAct";

import {
  getDocumentsOfFPO,
  getCompanyKycBulkdownloadOfFpo,
} from "action/RmAct";
import downloadIcon from "assets/images/icon/download-icon.svg";
import { UploadComponent } from "../UploadComponent";
import { getAccessdata } from "service/helperFunctions";
const docIcon = require("../../../assets/svg/Documents/docIcon.png");

class CompanyDoc extends Component {
  state = {
    documentsData: [],
    isLoading: false,
    bulkDownloadLink: "",
    deleteId: null,
  };

  //upload document
  handleUpload = (body) => {
    let { id, fileName, file, reportName } = body;
    this.props
      .addFpoDocuments(
        {
          companyKycId: id,
          documentName: fileName,
          documentPath: file,
          reportName: reportName,
        },
        "companykyc"
      )
      .then(() => {
        this._getcompanyDocuments();
      });
  };

  //Edit Document
  handleEditOption = (body) => {
    let { id, reportName } = body;
    this.props.companyKYCEdit({ id: id, reportName: reportName }).then(() => {
      this._getcompanyDocuments();
    });
  };

  //Delete document
  handleDelete = () => {
    let { deleteId } = this.state;
    this.props
      .deleteFpoDocuments(
        {
          id: deleteId,
        },
        "companykyc"
      )
      .then(() => {
        this._getcompanyDocuments();
      });
  };

  toggleDelete = (id) => {
    this.setState({
      deleteId: id,
    });
  };

  //Life cycles
  componentDidMount() {
    let { activeTab } = this.props;
    if (activeTab == 1) {
      this._getcompanyDocuments();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      if (this.props.activeTab == 1) {
        this._getcompanyDocuments();
      }
    }
  }
  _getcompanyDocuments = () => {
    this.setState({ isLoading: true });
    let { id } = this.props;
    if (id) {
      this.props.getDocumentsOfFPO(id, "companykyc").then((data) => {
        this._setData(data);
      });
    } else {
      this.props.viewFpoDocuments("companykyc").then((data) => {
        this._setData(data);
      });
    }
  };

  _setData = (data) => {
    let companyData = [];
    data.map((list) => {
      companyData.push({
        id: list.companyKycId,
        title: list.documentName,
        body: list.description,
        documents: list.companyKYCs,
        documentType: list.documentType,
      });
    });
    this.setState({
      documentsData: companyData,
      isLoading: false,
    });
  };

  getCsvUrl = () => {
    let { getCompanyKycBulkdownload, id, getCompanyKycBulkdownloadOfFpo } =
      this.props;

    if (id) {
      let body = {
        fpoId: id,
      };
      getCompanyKycBulkdownloadOfFpo(body).then((data) => {
        this._downloadData(data);
      });
    } else {
      let body = {
        id: null,
      };
      getCompanyKycBulkdownload(body).then((data) => {
        this._downloadData(data);
      });
    }
  };

  _downloadData = (data) => {
    this.setState(
      {
        bulkDownloadLink:
          "https://portal.fpogateway.com/fpomgmt/api/v1/download/zip?zipFileName=" +
          data.zipFileName,
      },
      () => {
        document.getElementById("csv-download").click();
        this.setState({ bulkDownloadLink: "" });
      }
    );
  };

  render() {
    let { documentsData, isLoading, bulkDownloadLink, deleteId } = this.state;

    let { accessData, id } = this.props;
    let permissionData = getAccessdata(accessData, "fpoDocuments");

    let { download = false } = permissionData;
    return (
      <div className="d-flex flex-column">
        {download && (
          <div className="container-sa">
            <div className="d-flex justify-content-end">
              {bulkDownloadLink === "" ? (
                <span
                  className="text-green-18 font-weight-bold fs-18 text-underline cursor-pointer pr-md-5 pr-0 mr-md-3 mr-0"
                  onClick={() => this.getCsvUrl()}
                >
                  <img
                    src={downloadIcon}
                    className="mr-2"
                    height="20"
                    width="20"
                  />
                  {"Download all"}
                </span>
              ) : (
                <a
                  id="csv-download"
                  className="text-black-50 font-weight-bold fs-18 text-underline cursor-pointer pr-md-5 pr-0 mr-md-3 mr-0"
                  href={bulkDownloadLink}
                  download
                >
                  <img
                    src={downloadIcon}
                    className="mr-2"
                    height="20"
                    width="20"
                  />
                  {"Download all"}
                </a>
              )}
            </div>
          </div>
        )}
        <div className="container-sa">
          {!isLoading ? (
            <div className="row">
              {documentsData.map((documentDetail, index) => {
                return (
                  <div
                    className="col-md-3 document-card d-flex align-items-end"
                    key={index}
                  >
                    <div className="document-card-margin">
                      <div className="document-titleBlock">
                        <div className="d-flex align-items-center mb-1">
                          <img
                            className="mr-3"
                            src={docIcon}
                            height="30"
                            width="30"
                            alt="docIcon.svg"
                          />
                          <div className="document-title">
                            {documentDetail.title}
                          </div>
                        </div>
                        <div className="document-body mb-1">
                          {documentDetail.body}
                        </div>
                      </div>
                      <div>
                        <UploadComponent
                          documentDetail={documentDetail}
                          documentTitle={documentDetail.title}
                          title={"KYC Company"}
                          handleUpload={this.handleUpload}
                          handleEditOption={this.handleEditOption}
                          handleDelete={this.toggleDelete}
                          id={documentDetail.id}
                          documents={documentDetail.documents}
                          activeTab={this.props.activeTab}
                          fpoId={id}
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <>
              {/* Loader */}
              <CommonPageLoader />
            </>
          )}
          <ConfirmationPopup
            isOpen={deleteId !== null ? true : false}
            toggle={() => this.toggleDelete(null)}
            onSubmit={this.handleDelete}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  accessData: state.home.accessData,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      addFpoDocuments,
      viewFpoDocuments,
      deleteFpoDocuments,
      getCompanyKycBulkdownload,
      getDocumentsOfFPO,
      getCompanyKycBulkdownloadOfFpo,
      companyKYCEdit,
    },
    dispatch
  );
};
let component = CompanyDoc;

export const Company = connect(mapStateToProps, mapDispatchToProps)(component);
