import React, { Component } from "react";
import { Navbar } from "component/common";
import { IncomeTdsView } from "component/compliance";
import { UserAuth } from "helpers/UserAuth";

class ViewIncomeTdsClass extends Component {
  render() {
    return (
      <div>
        <div className="normal-header">{/* <Navbar /> */}</div>
        <IncomeTdsView id={this.props.match.params.id} />
      </div>
    );
  }
}

export const ViewIncomeTds = UserAuth(ViewIncomeTdsClass, {
  service: "complainceGovernance",
});
