import React from "react";
import { getDescriptionData } from "service/helperFunctions";
import { history } from "service/helpers";
import Moment from "react-moment";
import defaultEventImage from "assets/images/DefaultEvent.jpg";
export default function Card(props) {
  const {
    event: {
      description,
      eventDate,
      agendaName,
      location,
      eventId,
      eventDocs,
      eventTitle,
    },
    viewAccess = false,
  } = props;
  return (
    <>
      <div className="shadow card-move-down mr-4">
        <div className="card your-events-card w-100">
          {eventDocs.length !== 0 ? (
            <img
              className="card-img-top"
              src={eventDocs[0].documentUrl}
              alt={eventDocs[0].fileName}
            />
          ) : (
            <img
              className="card-img-top"
              src={defaultEventImage}
              alt="events"
            />
          )}
          <div className="card-body d-flex flex-column justify-content-between p-3">
            <div>
              <div className="d-flex justify-content-between">
                <div>
                  <div>
                    <h6 className="fs-14 text-black-1c mb-0">Event date</h6>
                  </div>
                  <div className="d-flex align-items-center">
                    <div className="your-events-card-date mr-2">
                      <Moment format="DD">{eventDate}</Moment>
                    </div>
                    <div className="your-events-card-month">
                      <Moment format="MMMM YYYY">{eventDate}</Moment>
                    </div>
                  </div>
                </div>
              </div>
              <div className="your-events-card-heading">{agendaName}</div>
              <div className="text-green-18 font-weight-bold fs-19">
                {eventTitle !== null ? getDescriptionData(eventTitle, 20) : ""}
              </div>
              <div
                className="text-wrap your-events-card-body"
                dangerouslySetInnerHTML={{
                  __html:
                    description !== null
                      ? getDescriptionData(description, 35)
                      : "",
                }}
              ></div>
              <div className="mb-1">
                <div>
                  <h6 className="fs-14 line-height-24 text-black-1c mb-0">
                    Event location
                  </h6>
                </div>
                <div className="d-flex pr-3">
                  <p className="fs-18 mb-0 pr-2">
                    <i className="icon-location-outline text-green-18"></i>
                  </p>
                  <p className="fs-14 line-height-24 text-black-1c mb-0">
                    {location !== "" ? getDescriptionData(location, 15) : "-"}
                  </p>
                </div>
              </div>
            </div>
            {viewAccess ? (
              <div
                className="mx-auto text-green-18 font-weight-bold cursor-pointer fs-16"
                onClick={() =>
                  history.push(`/fpo/viewDetails/events&${eventId}`)
                }
              >
                {"View more"}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
}
