import React from 'react';
import './style.scss';
export const NormalRadio = ({
   className = 'form-control',
   label = '',
   onChange,
   name,
   isLink = false,
   isFullWidth = false,
   isHalfWidth = false,
   isHalfWidthForMemberProfile = false,
   required = false,
   checked1 = false,
   checked2 = false,
}) => {
   return (
      <div className='normal-input radio-inputs '>
         <div className='row d-flex align-items-center mt-4'>
            <div
               className={`${
                  isFullWidth ? 'col-2' : isHalfWidth ? 'col-4' : 'col-3'
               } p-0 `}
            >
               {label !== '' ? (
                  <div className='fs-16 text-black-32 font-weight-bold line-height-19 '>
                     {label}{' '}
                     {required && (
                        <span className='text-danger font-weight-bold'>*</span>
                     )}
                  </div>
               ) : (
                  ''
               )}
            </div>
            <div
               className={`row px-0 mx-0 ${
                  isFullWidth
                     ? 'col-10'
                     : isHalfWidth || isHalfWidthForMemberProfile
                     ? 'col-8'
                     : 'col-9'
               }  `}
            >
               <div className='col-6 d-flex align-items-center'>
                  <input
                     className='mr-2'
                     type='radio'
                     id={name + 'Yes'}
                     name={name}
                     value='Yes'
                     checked={checked1}
                     onChange={(e) => {
                        let body = {};

                        body = {
                           target: {
                              name: e.target.name,
                              value: e.target.value,
                           },
                        };

                        onChange(body);
                     }}
                  />
                  <label
                     className='fs-16 text-black-32 font-weight-bold line-height-19 mt-2'
                     for={name + 'Yes'}
                  >
                     Yes
                  </label>
               </div>
               <div className='col-6 d-flex align-items-center'>
                  <input
                     className='mr-2'
                     type='radio'
                     id={name + 'No'}
                     name={name}
                     value='No'
                     checked={checked2}
                     onChange={(e) => {
                        let body = {};

                        body = {
                           target: {
                              name: e.target.name,
                              value: e.target.value,
                           },
                        };

                        onChange(body);
                     }}
                  />
                  <label
                     className='fs-16 text-black-32 font-weight-bold line-height-19 mt-2'
                     for={name + 'No'}
                  >
                     No
                  </label>
               </div>
               {isLink ? (
                  <span className='link-area'>
                     <i className='fs-24 icon-link'></i>
                  </span>
               ) : (
                  ''
               )}
            </div>
         </div>
      </div>
   );
};
