import React, { Component, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import arrow_up_sort from "assets/svg/SellRequest/ascending.svg";
import arrow_down_sort from "assets/svg/SellRequest/decending.svg";
import sort_both from "assets/svg/SellRequest/sortboth.svg";
import { history } from "service/helpers/index";
import { useTable, usePagination, useSortBy, useGlobalFilter } from 'react-table'
import "./style.scss";
import {
    getAlRequest, sellRequestList, getfpocommodityList,
    getrmcommodityList,
    getfponameList,
    getfpoStateList
} from "action/SellrequestAct";
import { Pagination, CommonPageLoader, NormalSelect } from "component/common";
import { debounceFunction } from "service/debounce";
import moment from "moment";
import { RecommendPopup } from "./RecommendPopup";
import { MultiSelect } from "react-multi-select-component";
import filter from "assets/images/icon/filter.svg";
function Table({ columns, data, onHeaderClick }) {

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page, // Instead of using 'rows', we'll use page,
    } = useTable(
        {
            columns,
            data,
            manualSortBy: true,
            initialState: {
                pageIndex: 0, hiddenColumns: [
                    localStorage.getItem("userGroupName") === "fpo" && localStorage.getItem("userRoleName") === "fpo" ? 'fpoName' : '',
                    localStorage.getItem("userGroupName") === "fpo" && localStorage.getItem("userRoleName") === "fpo" ? 'location_of_availability_produce' : '',
                ]
            },
        },
        useGlobalFilter,
        useSortBy,
        usePagination
    )

    // Render the UI for your table
    return (
        <>
            <table {...getTableProps()} className={localStorage.getItem("userGroupName") === "fpo" && localStorage.getItem("userRoleName") === "fpo" ? 'table sell-table fpo' : 'table sell-table rm'}>
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th className="no-sort" {...column.getHeaderProps(column.getSortByToggleProps())} onClick={() => onHeaderClick(column)}>
                                    <div className="d-flex align-items-center position-relative justify-content-between nowrap">
                                        {column.render('Header')}
                                        <span className="totarrow">
                                            {
                                                column.render('Header') !== 'Action' && column.render('Header') !== 'Status' && column.render('Header') !== '' ?
                                                    column.sortDirection === 'Asc' ? (
                                                        <span className="ascwrp"><img src={arrow_up_sort} alt="arrow.svg" /></span>
                                                    ) : column.sortDirection === 'Desc' ? (
                                                        <span className="descwrp"><img src={arrow_down_sort} alt="arrow.svg" /></span>
                                                    ) : <span className="both"><img src={sort_both} alt="arrow.svg" /></span>

                                                    : ''
                                            }
                                        </span>
                                    </div>
                                </th>
                            ))}
                        </tr>

                    ))}

                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map((row, i) => {
                        prepareRow(row)
                        var status = row.original.status;
                        var value = '';
                        if (localStorage.getItem("userGroupName") === "fpo" && localStorage.getItem("userRoleName") === "fpo") {
                            if (status == 2) {
                                value = 'unread';
                            } else {
                                value = ''
                            }
                        } else {
                            if (status == 1) {
                                value = 'unread';
                            } else if (status == 3) {
                                value = 'unread';
                            } else {
                                value = ''
                            }
                        }
                        return (
                            <tr {...row.getRowProps()} className={value}>
                                {row.cells.map(cell => {
                                    return <td {...cell.getCellProps()} >{cell.render('Cell')}</td>
                                })}
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </>
    )
}

export class ListClass extends Component {
    constructor() {
        super();
        this.state = {
            pageMeta: {},
            accessors: "id",
            sortDirection: "Desc",
            data: [],
            searchValue: '',
            columns: [],
            openPopup: false,
            current_id: 0,
            fpoName: '',
            fpoNameList: [],
            state: '',
            stateList: [],
            commodityList: [],
            fpoCommodity: [0],
            active: false,
            fposelected: [],
            stateselected: [],
            isLoading: true,
        }
    }
    handleInput = ({ target: { name, value } }) => {
        //Debounce Function
        if (name == 'fpoName' || name == 'state') {
            this.setState({
                [name]: value,
            }, () => {
                this.handleLicense(1);
            });
        } else {
            debounceFunction(() => this.SearchFunction(value), 1000);
        }
    };

    setSelected = (e) => {
        this.setState({
            selected: e,
        });
        var fpoCommodity = []
        if (e.length > 0) {
            e.forEach(element => {
                fpoCommodity.push(element.value);
            });
        } else {
            fpoCommodity = [0];
        }
        this.setState({
            fpoCommodity: fpoCommodity,
        }, () => {
            this.handleLicense(1);
        });
    }

    toggle = (name) => {
        this.setState((prevState) => ({
            [name]: !prevState[name],
        }), () => {
            if (this.state.openPopup == false) {
                this.handleLicense(1);
            }
        });
    };

    SearchFunction = (searchValue) => {
        //debounce function
        this.setState({ searchValue });
        this.handleLicense(1);
    };
    getsortDirection(type) {
        return this.state?.accessors === type ? this.state?.sortDirection : ''
    }

    async getCommodityList() {
        var list = localStorage.getItem("userGroupName") === "fpo" && localStorage.getItem("userRoleName") === "fpo" ? this.props.getfpocommodityList() : this.props.getrmcommodityList();
        await list.then((data) => {
            let response = [];
            var fpoCommodity = [];

            data.map((list) => {
                response.push({ value: list.cropId, label: list.cropName });
                fpoCommodity.push(list.cropId);
            });
            this.setState({
                fpoCommodity: fpoCommodity,
                selected: response,
                commodityList: response
            });
        });
    }

    async getFpoNameList() {
        var list = this.props.getfponameList();
        await list.then((data) => {
            let response = [];
            var name = [];


            data.map((list) => {
                response.push({ value: list.fpoId, label: list.fpoName });
                name.push(list.fpoId);
            });
            this.setState({
                fpoName: name,
                fposelected: response,
                fpoNameList: response
            }
                // , () => {
                //     this.impactApi();
                // }
            );
        });
    }

    async getStateList() {
        var list = this.props.getfpoStateList();
        await list.then((data) => {
            let response = [];
            var name = [];

            data.map((list) => {
                response.push({ value: list.stateId, label: list.stateName });
                name.push(list.stateId);
            });
            this.setState({
                state: [],
                stateselected: [],
                stateList: response
            }
            );
        });
    }

    setSelectedFpoName = (e) => {
        this.setState({
            fposelected: e,
        });
        var fpoName = []
        if (e.length > 0) {
            e.forEach(element => {
                fpoName.push(element.value);
            });
        } else {
            fpoName = [0];
        }
        this.setState({
            fpoName: fpoName,
        }, () => {
            this.handleLicense(1);
        });
    }

    setSelectedstate = (e) => {
        this.setState({
            stateselected: e,
        });
        var state = []
        if (e.length > 0) {
            e.forEach(element => {
                state.push(element.value);
            });
        } else {
            state = [];
        }
        this.setState({
            state: state,
        }, () => {
            this.handleLicense(1);
        });
    }

    handleLicense = async (page) => {
        let limit = 10;
        this.setState({ isLoading: true });
        let payload = {
            limit,
            page,
            search: this.state.searchValue,
            order_by: this.state.accessors,
            order_type: this.state.sortDirection,
            "commodityIds": this.state.fpoCommodity,
            "fpoIds": this.state.fpoName,
            "stateIds": this.state.state
        };
        await this.props.sellRequestList(payload).then((data) => {
            this.setState({
                data: data?.results?.map(row => ({
                    icon: row.status,
                    id: row.id,
                    date_available_from: row?.date_available_from !== '' ? moment(row?.date_available_from).format("DD MMM YYYY") : '',
                    fpoName: row.fpoName,
                    location_of_availability_produce: row.location_of_availability_produce,
                    commodity_name: row.commodity_name,
                    variety: row.variety,
                    existing_stock_level: row.existing_stock_level,
                    custom_measurement: row.custom_measurement,
                    expected_price_per_unit: row.expected_price_per_unit,
                    status: row.status,
                    action: row.status
                })),
                pageMeta: data.pageMeta,
                isLoading: false,
            });
        });
    };

    //handle page change
    handlePagination = (page) => {
        this.handleLicense(page.page);
    };

    openpopupFunction = (type, id) => {
        this.setState({ current_id: id });
        this.toggle("openPopup");
    };
    sort = async (column) => {
        switch (column.sortDirection) {
            case '':
                this.setState({ sortDirection: 'Asc', accessors: column.id }, () => {
                    this.handleLicense(1);
                    this.setstateColumns();
                });
                break;
            case 'Asc':
                this.setState({ sortDirection: 'Desc', accessors: column.id }, () => {
                    this.handleLicense(1);
                    this.setstateColumns();
                });
                break;
            case 'Desc':
                this.setState({ sortDirection: '', accessors: column.id }, () => {
                    this.handleLicense(1);
                    this.setstateColumns();
                });
                break;
        }
    }

    //Lifecycles
    async componentDidMount() {
        await this.setstateColumns();
        // this.handleLicense(1);
        await this.getCommodityList();
        await this.getFpoNameList();
        await this.getStateList();
        // setTimeout(() => {
        await this.handleLicense(1);
        // }, 1000);
    }

    //open dropdown
    handleDropdown = () => {
        this.setState((prevState) => ({
            active: !prevState.active,
        }));
    };

    //close popup when clicked outside
    handleOutsideClick = (e) => {
        if (this.node != null) {
            if (this.node.contains(e.target)) {
                return;
            }
        }
        this.handleDropdown();
    };
    setstateColumns() {
        this.setState({
            columns: [               
                {
                    Header: 'Req ID',
                    accessor: 'id',
                    sortType: 'basic',
                    sortDirection: this.getsortDirection('id'),
                    Cell: row => {
                        return (
                            <a onClick={() => localStorage.getItem("userGroupName") === "fpo" && localStorage.getItem("userRoleName") === "fpo" ? history.push(`/fpo/sell-request-detail/${row.cell.value}`) : history.push(`/rm/sell-request-detail/${row.cell.value}`)} className="cursor-pointer view-detial-screen">
                                {row.cell.value}
                            </a>
                        )
                    }
                },
                {
                    Header: 'Date',
                    accessor: 'date_available_from',
                    sortDirection: this.getsortDirection('date_available_from'),
                },
                {
                    Header: 'Location',
                    accessor: 'location_of_availability_produce',
                    show: false,
                    sortDirection: this.getsortDirection('location_of_availability_produce'),
                },
                {
                    Header: 'Fpo Name',
                    accessor: 'fpoName',
                    show: false,
                    sortDirection: this.getsortDirection('fpoName'),
                },
                {
                    Header: 'Commodity',
                    accessor: 'commodity_name',
                    sortDirection: this.getsortDirection('commodity_name'),
                },
                {
                    Header: 'Variety',
                    accessor: 'variety',
                    sortDirection: this.getsortDirection('variety'),
                },
                {
                    Header: 'Quantity',
                    accessor: 'existing_stock_level',
                    sortDirection: this.getsortDirection('existing_stock_level'),
                    Cell: row => {
                        var value = '';
                        var type = parseFloat(row.cell.value).toFixed(2)
                        var number = Number(type).toLocaleString('en-IN');
                        if (isNaN(type) === false && type !== null && type !== '' && type !== 0) {
                            value = number;
                        } else if (typeof row.cell.value === 'string' && row.cell.value !== 'NaN') {
                            value = row.cell.value;
                        }
                        return (
                            value
                        )
                    }
                },
                {
                    Header: 'Unit',
                    accessor: 'custom_measurement',
                    sortDirection: this.getsortDirection('custom_measurement'),
                },
                {
                    Header: 'Exp Price Per Unit',
                    accessor: 'expected_price_per_unit',
                    sortDirection: this.getsortDirection('expected_price_per_unit'),
                    Cell: row => {
                        var value = '';
                        var type = parseFloat(row.cell.value).toFixed(2)
                        var number = Number(type).toLocaleString('en-IN');
                        if (isNaN(type) === false && type !== null && type !== '' && type !== 0) {
                            value = number;
                        } else if (typeof row.cell.value === 'string' && row.cell.value !== 'NaN') {
                            value = row.cell.value;
                        }
                        return (
                            '₹ ' + value
                        )
                    }
                },
                {
                    Header: 'Status',
                    accessor: 'status',
                    Cell: row => {
                        let button;
                        let fpo = localStorage.getItem("userGroupName") === "fpo" && localStorage.getItem("userRoleName") === "fpo";
                        if (row.cell.value === 1) {
                            button = fpo == true ? 'Request Sent' : 'Verification';
                        } else if (row.cell.value === 2) {
                            button = fpo == true ? 'Approval' : 'Recommendation';
                        } else if (row.cell.value === 3) {
                            button = fpo == true ? 'Deal Closure' : 'Deal Closure';
                        } else if (row.cell.value === 4) {
                            button = fpo == true ? 'Done' : 'Done';
                        } else if (row.cell.value === 5) {
                            button = 'Declined';
                        }
                        var value = row.cell.value;
                        return (
                            <div className="statustxt">
                                {
                                    value === 5 ? <div className="declined">Declined</div>
                                        :

                                        <div className="row ms-progressbar">
                                            <div className={`col-xs-3 ms-progressbar-step ${value === 1 || value === 2 || value === 3 || value === 4 ? 'complete' : 'disabled'}`}>
                                                <div className="progress">
                                                    <div className="progress-bar"></div>
                                                </div>
                                                <span className={`ms-progressbar-dot ${value === 4 ? 'green-status' : ''}`}></span>
                                                <div className="text-center ms-progressbar-step-number">&nbsp;</div>
                                            </div>


                                            <div className={`col-xs-3 ms-progressbar-step ${value === 2 || value === 3 || value === 4 ? 'complete' : 'disabled'}`}>
                                                <div className="progress">
                                                    <div className="progress-bar"></div>
                                                </div>
                                                <span className={`ms-progressbar-dot ${value === 4 ? 'green-status' : ''}`}></span>
                                                <div className="text-center ms-progressbar-step-number">&nbsp;</div>
                                            </div>

                                            <div className={`col-xs-3 ms-progressbar-step ${value === 3 || value === 4 ? 'complete' : 'disabled'}`}>
                                                <div className="progress">
                                                    <div className="progress-bar"></div>
                                                </div>
                                                <span className={`ms-progressbar-dot ${value === 4 ? 'green-status' : ''}`}></span>
                                                <div className="text-center ms-progressbar-step-number">&nbsp;</div>
                                            </div>

                                            <div className={`col-xs-3 ms-progressbar-step ${value === 4 ? 'complete' : 'disabled'}`}>
                                                <div className="progress">
                                                    <div className="progress-bar"></div>
                                                </div>
                                                <span className={`ms-progressbar-dot ${value === 4 ? 'green-status' : ''}`}></span>
                                                <div className="text-center ms-progressbar-step-number">&nbsp;</div>
                                            </div>
                                        </div>
                                }
                                {
                                    value !== 5 ?
                                        <p className="mb-0 text-center st-text">
                                            {button}  </p> : ''
                                }

                            </div>
                        )
                    }
                },
                {
                    Header: 'Action',
                    accessor: 'action',
                    show: false,
                    Cell: row => {
                        let status = row.cell.value;
                        let id = row.cell?.row?.values?.id;
                        return (
                            <React.Fragment>
                                {
                                    localStorage.getItem("userGroupName") === "fpo" && localStorage.getItem("userRoleName") === "fpo" ?
                                        <React.Fragment>
                                            <div className="flex items-center w-100px">
                                                {
                                                    status != 4 && status != 5 ?
                                                        < span >
                                                            <a
                                                                className="edit-button"
                                                                onClick={() => history.push("/fpo/edit-request/" + id)}
                                                            >
                                                                {"Edit"}
                                                            </a>
                                                        </span> : ''
                                                }
                                                {
                                                    status === 2
                                                        ?
                                                        <span className="ml-2">
                                                            <a
                                                                className="edit-button"
                                                                onClick={() => history.push("/fpo/sell-request-stage-two/" + id)}
                                                            >
                                                                {"Approve"}
                                                            </a>
                                                        </span>
                                                        : ''
                                                }
                                            </div>
                                        </React.Fragment>
                                        :
                                        <React.Fragment>
                                            {
                                                status === 1 ?
                                                    <div className="grr-btn">
                                                        <a
                                                            className="edit-button"
                                                            onClick={() => this.openpopupFunction("openPopup", id)}
                                                        >
                                                            {"Recommend Buyer"}
                                                        </a>
                                                    </div> : ''
                                            }
                                            {
                                                status === 3 ?
                                                    <div className="grr-btn">
                                                        <a
                                                            className="edit-button"
                                                            onClick={() => history.push("/rm/deal-closure/" + id)}
                                                        >
                                                            {"Approve"}
                                                        </a>
                                                    </div> : ''
                                            }
                                        </React.Fragment>
                                }
                            </React.Fragment >
                        );
                    }
                }
            ]
        })
    }
    render() {
        let {
            columns, data, isLoading, pageMeta, openPopup, current_id, active,
            fposelected,
            fpoNameList,
            stateselected,
            stateList,
            selected,
            commodityList,
        } = this.state;
        return (
            <div>
                <section className="member-section pt-1 ">
                    <div className="container-sa position-relative">
                        <div className="members-tab  d-flex justify-content-center align-items-center ">
                            <div className="d-flex justify-content-center w-100 position-relative">
                                <div className="d-flex align-items-center ml-auto pt-3 mb-2 samunnatiimpact menu">
                                    {
                                        localStorage.getItem("userGroupName") === "fpo" && localStorage.getItem("userRoleName") === "fpo" ?
                                            <button
                                                className="add-button"
                                                onClick={() => history.push("/fpo/raise-a-new-request")}
                                            >
                                                {"Raise a new Request"}
                                            </button>
                                            :
                                            <div className="cursor-pointer filter-dropdown ml-4"  >
                                                <p className="m-0" onClick={this.handleDropdown}>
                                                    <img src={filter} alt="filter" />
                                                    <span className="fs-12 font-Gilroy ml-2 font-weight-bold">
                                                        Filter
                                                    </span>
                                                </p>
                                                {
                                                    active == true ?
                                                        <div className={`dropdown ${active == true ? "active" : ""}`}>
                                                            <div className="flex justify-between pophead">
                                                                <p className="recommend-popup-heading">Filter</p>
                                                                <i onClick={this.handleDropdown} className="icon-close fs-16 cursor-pointer"></i>
                                                            </div>
                                                            <ul className="h-100 w-100 d-flex flex-column flex-wrap">
                                                                <span className="fs-12 font-Gilroy font-weight-bold">Commodity</span>
                                                                <MultiSelect
                                                                    options={commodityList}
                                                                    value={selected}
                                                                    onChange={this.setSelected}
                                                                    labelledBy="Select"
                                                                    className="mul-sel"
                                                                />
                                                                <span className="fs-12 font-Gilroy font-weight-bold">FPO Name</span>
                                                                <MultiSelect
                                                                    options={fpoNameList}
                                                                    value={fposelected}
                                                                    onChange={this.setSelectedFpoName}
                                                                    labelledBy="FPO Name"
                                                                    className="mul-sel"
                                                                />
                                                                <span className="fs-12 font-Gilroy font-weight-bold">Location</span>
                                                                <MultiSelect
                                                                    options={stateList}
                                                                    value={stateselected}
                                                                    onChange={this.setSelectedstate}
                                                                    labelledBy="State"
                                                                    className="mul-sel"
                                                                />
                                                            </ul>
                                                        </div>
                                                        : ''
                                                }
                                            </div>}

                                </div>

                            </div>
                        </div>

                        <div className={localStorage.getItem("userGroupName") === "fpo" && localStorage.getItem("userRoleName") === "fpo" ? 'searchrecords float-right' : 'searchrecords searchrecordsrm  float-right'}>
                            <input
                                className="form-control"
                                onChange={(e) => this.handleInput(e)}
                                placeholder="Search Sell Request"
                            />
                            <i className="icon-search"></i>
                        </div>
                        <>
                            {!isLoading && data.length === 0 ? (
                                <p className={localStorage.getItem("userGroupName") === "fpo" && localStorage.getItem("userRoleName") === "fpo" ? 'py-4 mb-0 text-black-50 fs-22 line-height-25 text-center' : 'py-4 mb-0 text-black-50 fs-22 line-height-25 text-center nodtfn'}>
                                    No data found
                                </p>
                            ) : !isLoading ? (
                                <>
                                    {data.length !== 0 ? (
                                        <>
                                            <div className="tableresponsive">
                                                <Table columns={columns} data={data} onHeaderClick={this.sort} />
                                            </div>

                                            {pageMeta.pageCount !== undefined &&
                                                pageMeta.pageCount !== 1 && (
                                                    <Pagination
                                                        handleChange={this.handlePagination}
                                                        pageMeta={pageMeta}
                                                    />
                                                )}
                                        </>
                                    ) : null}
                                </>
                            ) : (
                                <>
                                    {/* Loader */}
                                    <CommonPageLoader />
                                </>
                            )}
                        </>
                    </div>
                </section>
                {openPopup == true ?
                    <RecommendPopup
                        title={'Recommend Buyer'}
                        documentTitle={current_id}
                        isOpen={openPopup}
                        toggle={() => this.toggle("openPopup")}
                    /> : ''}
            </div>

        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getAlRequest,
            sellRequestList,
            getfpocommodityList,
            getrmcommodityList,
            getfponameList,
            getfpoStateList
        },
        dispatch
    );
};
let component = ListClass;

export const ListView = connect(
    null,
    mapDispatchToProps
)(component);
