import React, { Component } from "react";
import Select, { components } from "react-select";
import "./select.scss";
import { AbIf } from "../index";
import AsyncPaginate from "react-select-async-paginate";

export class CustomSelect extends Component {
  //change select
  handleChange = (newValue) => {
    let { isMulti } = this.props;
    if (!!isMulti) {
      let body = {
        target: {
          name: this.props.name,
          value: [],
        },
      };
      if (!!newValue && newValue.length) {
        newValue.forEach((array) => {
          let obj = {
            value: array.value,
            label: array.label,
          };
          body.target.value.push(obj);
        });
      }
      this.props.onChange(body);
    } else {
      let body = {
        target: {
          name: this.props.name,
          value: newValue ? newValue.value : "",
          label: newValue ? newValue.label : "",
        },
      };

      this.props.onChange(body);
    }
  };
  render() {
    const customStyles = {
      control: (base) => ({
        ...base,
        height: 10,
        minHeight: 10,
      }),
    };
    let {
      className = "",
      options = [
        { label: "hello", value: 0 },
        { label: "test", value: 1 },
      ],
      name = "",
      disabled = false,
      defaultValue = options[0],
      placeholder = "",
      isRtl = false,
      isClearable = true,
      isLoading = false,
      isSearchable = false,
      isMulti = false,
      async = false,
      value = "",
      debounceTimeout = 3000,
      cacheUniq = "",
    } = this.props;
    const DropdownIndicator = (props) => {
      return (
        components.DropdownIndicator && (
          <components.DropdownIndicator {...props}>
            <span
              className={`${
                props.selectProps.menuIsOpen
                  ? "icon-down up-icon "
                  : "icon-down "
              } icon-color black`}
            ></span>
          </components.DropdownIndicator>
        )
      );
    };

    return (
      <>
        <AbIf show={!async}>
          <Select
            className={className}
            classNamePrefix="Select"
            isDisabled={disabled}
            isLoading={isLoading}
            isClearable={isClearable}
            isRtl={isRtl}
            isSearchable={isSearchable}
            name={name}
            options={options}
            onChange={this.handleChange}
            isMulti={isMulti}
            placeholder={placeholder}
            styles={customStyles}
            value={
              !!options && options.length > 0
                ? options.find((data) => data.value === value)
                  ? options.find((data) => data.value === value)
                  : null
                : null
            }
            components={{ DropdownIndicator }}
          />
        </AbIf>

        <AbIf show={async}>
          <AsyncPaginate
            debounceTimeout={debounceTimeout}
            className={className}
            isDisabled={disabled}
            isClearable={isClearable}
            isSearchable={isSearchable}
            classNamePrefix="Select"
            defaultValue={placeholder === "" ? defaultValue : ""}
            placeholder={placeholder}
            cacheUniq={cacheUniq}
            onMenuClose={this.onMenuClose}
            isMulti={isMulti}
            value={value}
            loadOptions={options}
            components={{ DropdownIndicator }}
            onChange={this.handleChange}
          />
        </AbIf>
      </>
    );
  }
}
