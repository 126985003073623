import React, { Component } from "react";
import SimpleReactValidator from "simple-react-validator";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { individualReg, individualRoles } from "action/RegAct";
import { Toast } from "service/toast";
import { encryptData } from "service/helperFunctions";
import { PasswordStrengthMeter } from "component/common";
import CheckBox from "component/common/CheckBox";

class Individual extends Component {
  constructor() {
    super();
    this.state = {
      contact_first_name: "",
      profession: "",
      contact_number: "",
      email_id: "",
      password: "",
      confirm_password: "",
      // roles: [],
      passwordVisible: false,
      reEnterPasswordVisible: false,
      isPrivacy: false,
    };
  }

  validator = new SimpleReactValidator({
    element: (message) => (
      <span className="error-message text-danger fs-14">{message}</span>
    ),
    autoForceUpdate: this,
    validators: {
      password: {
        message: "The :attribute must be a valid format.",
        rule: (val, params, validator) => {
          return (
            validator.helpers.testRegex(
              val,
              /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$^+=!*()@%&]).{8,20}$/
            ) && params.indexOf(val) === -1
          );
        },
        messageReplace: (message, params) =>
          message.replace("", this.helpers.toSentence(params)),
        required: true,
      },
      checkPassword: {
        message: "Given :attribute does not match",
        rule: function (val, params) {
          return val === params[0];
        },
      },
    },
  });

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.validator.allValid()) {
      const {
        contact_first_name,
        profession,
        contact_number,
        email_id,
        password,
        confirm_password,
        isPrivacy,
      } = this.state;
      let { backToLogi } = this.props;
      if (password === confirm_password) {
        if (isPrivacy) {
          this.props
            .individualReg({
              contactName: contact_first_name,
              emailId: email_id,
              profession: profession,
              password: encryptData(password),
              mobileNumber: contact_number,
              isPrivacy: isPrivacy,
            })
            .then(() => {
              backToLogi(true);
            });
        } else {
          Toast({
            type: "error",
            message:
              "Please Accept Terms & Conditions and Privacy Policy",
            time: 5000,
          });
        }
      } else {
        let message = "Password does not match";
        Toast({ type: "error", message, time: 5000 });
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };
  componentDidMount() {
    //  this.props.individualRoles().then((data) => {
    //    this.setState({
    //      roles: data,
    //    });
    //  });
  }

  toggle = (key) => {
    this.setState((prevState) => ({
      [key]: !prevState[key],
    }));
  };

  render() {
    const {
      contact_first_name,
      contact_number,
      email_id,
      password,
      confirm_password,
      passwordVisible,
      reEnterPasswordVisible,
      profession,
      isPrivacy,
      // roles,
    } = this.state;
    return (
      <div className="d-flex flex-row mb-2 flex-column registration-area mt-3">
        <div className="row">
          <div className="col-md-6 col-12 mb-2">
            <label>
              {"Contact Person name "}
              <span className="text-danger font-weight-bold">*</span>
            </label>
            <input
              type="text"
              className="form-control w-100"
              placeholder="Enter here"
              name="contact_first_name"
              value={contact_first_name}
              onChange={(e) => {
                this.handleChange(e);
              }}
              autoComplete="off"
            />
            {this.validator.message(
              "contact_first_name",
              contact_first_name,
              "required|alpha_space|min:3|max:30"
            )}
          </div>

          <div className="col-md-6 col-12 my-2">
            <label>
              {"Contact number "}
              <span className="text-danger font-weight-bold">*</span>
            </label>
            <input
              type="text"
              className="form-control w-100"
              name="contact_number"
              value={contact_number}
              placeholder=""
              onChange={this.handleChange}
              maxLength={10}
              autoComplete="off"
            />
            {this.validator.message(
              "contact_number",
              contact_number,
              "required|phone"
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-12 my-2">
            <label>
              Profession
              <span className="text-danger font-weight-bold">*</span>
            </label>
            <input
              type="text"
              className="form-control w-100"
              name="profession"
              value={profession}
              onChange={this.handleChange}
              autoComplete="off"
            />
            {/* <select
                     className='custom-select form-control'
                     name='userRoleId'
                     value={userRoleId}
                     onChange={this.handleChange}
                  >
                     {roles.map((role, index) => {
                        return (
                           <option
                              key={index}
                              name={role.userRoleName}
                              value={role.userRoleId}
                           >
                              {role.userRoleName}
                           </option>
                        );
                     })}
                  </select> */}
            {this.validator.message(
              "profession",
              profession,
              "required|alpha_space|min:3|max:30"
            )}
          </div>
          <div className="col-md-6 col-12 my-2">
            <label>
              {"Email id "}
              <span className="text-danger font-weight-bold">*</span>
            </label>
            <input
              type="text"
              className="form-control w-100"
              name="email_id"
              value={email_id}
              onChange={this.handleChange}
              autoComplete="off"
            />
            {this.validator.message("email_id", email_id, "required|email")}
          </div>
        </div>

        <div className="row">
          <div className="col-md-6 col-12 my-2">
            <label>
              {"Password "}
              <span className="text-danger font-weight-bold">*</span>
            </label>
            <div className="input-group">
              <input
                type={passwordVisible ? "text" : "password"}
                className="form-control w-100"
                name="password"
                value={password}
                onChange={this.handleChange}
                autoComplete={passwordVisible ? "nope" : "new-password"}
              />
              <div className="input-group-addon right">
                <span
                  onClick={(e) => this.toggle("passwordVisible")}
                  className={`cursor-pointer icon-${
                    passwordVisible ? "eye" : "eye-hide"
                  } fs-24`}
                ></span>
              </div>
              {this.validator.message(
                "password",
                password,
                "required|min:7|max:15|password"
              )}
              <PasswordStrengthMeter data={password} />
            </div>
          </div>
          <div className="col-md-6 col-12 my-2">
            <label>
              {"Confirm password "}
              <span className="text-danger font-weight-bold">*</span>
            </label>
            <div className="input-group">
              <input
                type={reEnterPasswordVisible ? "text" : "password"}
                className="form-control w-100"
                name="confirm_password"
                value={confirm_password}
                onChange={this.handleChange}
                autoComplete={reEnterPasswordVisible ? "nope" : "new-password"}
              />
              <div className="input-group-addon right">
                <span
                  onClick={(e) => this.toggle("reEnterPasswordVisible")}
                  className={`cursor-pointer icon-${
                    reEnterPasswordVisible ? "eye" : "eye-hide"
                  } fs-24`}
                ></span>
              </div>
              {this.validator.message(
                "confirm password",
                confirm_password,
                `required|checkPassword:${password}`
              )}
            </div>
          </div>
          {password && (
            <div className="col-md-12">
              <b className="fs-14">
                Only the following special characters are allowed :
                #$^+=!*()@%&amp;
              </b>
            </div>
          )}
        </div>
        <div className="d-flex justify-content-center mt-4">
          <CheckBox
            label="I agree to the Terms & Conditions and Privacy Policy"
            onChange={this.handleChange}
            name="isPrivacy"
            isChecked={isPrivacy}
          />
        </div>
        <div className="d-flex justify-content-center mt-4">
          <input
            className="btn w-75 py-2 font-weight-bold"
            type="submit"
            value="Submit"
            onClick={this.handleSubmit}
          />
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      individualReg,
      individualRoles,
    },
    dispatch
  );
};
let component = Individual;

export const IndividualReg = connect(null, mapDispatchToProps)(component);
