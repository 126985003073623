import React, { Component, Fragment } from "react";
import SimpleReactValidator from "simple-react-validator";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  getDistrictApiwithState,
  allState,
  fileUpload,
  getPincodeApiwithStateDistrict,
  memberEdit,
  getMember,
  deleteMember,
} from "action/MemberAct";

import {
  NormalInput,
  NormalSelect,
  NormalDate,
  ConfirmationPopup,
} from "component/common";

import moment from "moment";

import { Toast } from "service/toast";

import { history } from "service/helpers";

import "react-datepicker/dist/react-datepicker.css";
const arrow_up = require("../../../assets/svg/add_member/arrow_up.svg");
const upload = require("../../../assets/svg/add_member/upload.svg");
class BasicForm extends Component {
  constructor(props) {
    super();
    this.state = {
      first_name: "",
      last_name: "",
      gender: "",
      address: "",
      pincode: "",
      phone_number: "",
      smart_phone: "",
      dob: "",
      village: "",
      district: "",
      state: "",
      startDate: new Date(),
      profile_photo: null,
      profilePicName: "",
      isProfilePicUploading: false,
      address_proof: null,
      address_proof_name: "",
      isaddressProofUploading: false,
      identity_proof: null,
      identity_proof_name: "",
      isIdentityProofUploading: false,
      allState: [],
      allDistrict: [],
      allPincode: [],
      genderOption: [
        { value: "Male", label: "Male" },
        { value: "Female", label: "Female" },
        { value: "Others", label: "Others" },
      ],
      smartPhoneOptions: [
        { value: "Yes", label: "Yes" },
        { value: "No", label: "No" },
      ],
      isDataUploading: false,
      isDelete: false,
    };
  }

  validator = new SimpleReactValidator({
    element: (message) => (
      <span className="error-message text-danger fs-14">{message}</span>
    ),
    autoForceUpdate: this,
  });

  handleChange = ({ target: { name, value } }) => {
    if (name === "state") {
      this.setState(
        {
          [name]: value,
          district: "",
          // pincode: "",
        },
        () => {
          this.props.getDistrictApiwithState({ id: value }).then((data) => {
            let allDistrictOption = [];
            data.map((list) => {
              allDistrictOption.push({
                label: list.districtName,
                value: list.districtId,
              });
            });
            this.setState({
              allDistrict: allDistrictOption,
            });
          });
        }
      );
    } else if (name === "district") {
      this.setState(
        {
          [name]: value,
          // pincode: "",
        },
        () => {
          // this.props
          //   .getPincodeApiwithStateDistrict({ id: value })
          //   .then((data) => {
          //     let allPincodeOption = [];
          //     data.map((list) => {
          //       allPincodeOption.push({
          //         label: list.pincode,
          //         value: list.pincodeId,
          //       });
          //     });
          //     this.setState({
          //       allPincode: allPincodeOption,
          //     });
          //   });
        }
      );
    } else {
      this.setState({
        [name]: value,
      });
    }
  };

  //delete Basic detail
  handleDelete = () => {
    this.setState({ isDataUploading: true });
    let { id } = this.props;
    let { deleteMember } = this.props;
    let body = {
      id,
    };
    deleteMember(body).then(() => {
      history.push("/fpo/member");
    });
  };

  toggleDelete = () => {
    let { isDelete } = this.state;
    this.setState({
      isDelete: !isDelete,
    });
  };

  uploadProfilePicture = (e) => {
    //upload profile picture
    let file = e.target.files[0];
    if (file !== undefined) {
      this.setState({ profile_photo: null }, () => {
        this.setState({
          isProfilePicUploading: true,
          profilePicName: "Uploading ....",
          profile_photo: file,
        });
        const formData = new FormData();
        formData.append("file", file);

        this.props
          .fileUpload(formData)
          .then((data) => {
            document.getElementById("uploadProfilePicture").value = null;
            this.setState({
              profile_photo: data[0].fileUrl,
              profilePicName: data[0].fileName,
              isProfilePicUploading: false,
            });
          })
          .catch(() => {
            this.setState({
              profile_photo: null,
              profilePicName: "",
              isProfilePicUploading: false,
            });
          });
      });
    }
  };

  handleAddressProofFile = (e) => {
    //upload address proof
    let file = e.target.files[0];
    if (file !== undefined) {
      this.setState({ address_proof: null }, () => {
        this.setState({
          isaddressProofUploading: true,
          address_proof_name: "Uploading ....",
          address_proof: file,
        });
        const formData = new FormData();
        formData.append("file", file);

        this.props
          .fileUpload(formData)
          .then((data) => {
            document.getElementById("handleAddressProofFile").value = null;
            this.setState({
              address_proof: data[0].fileUrl,
              address_proof_name: data[0].fileName,
              isaddressProofUploading: false,
            });
          })
          .catch(() => {
            this.setState({
              address_proof: null,
              address_proof_name: "",
              isaddressProofUploading: false,
            });
          });
      });
    }
  };

  handleIdentityProofFile = (e) => {
    //upload identity proof
    let file = e.target.files[0];
    if (file !== undefined) {
      this.setState({ identity_proof: null }, () => {
        this.setState({
          isIdentityProofUploading: true,
          identity_proof_name: "Uploading ....",
          identity_proof: file,
        });
        const formData = new FormData();
        formData.append("file", file);

        this.props
          .fileUpload(formData)
          .then((data) => {
            document.getElementById("handleIdentityProofFile").value = null;
            this.setState({
              identity_proof: data[0].fileUrl,
              identity_proof_name: data[0].fileName,
              isIdentityProofUploading: false,
            });
          })
          .catch(() => {
            this.setState({
              identity_proof: null,
              identity_proof_name: "",
              isIdentityProofUploading: false,
            });
          });
      });
    }
  };

  handleSave = (e) => {
    if (this.validator.allValid()) {
      const {
        first_name,
        last_name,
        gender,
        address,
        pincode,
        phone_number,
        smart_phone,
        dob,
        village,
        district,
        state,
        profile_photo,
        identity_proof,
        address_proof,
        profilePicName,
        address_proof_name,
        identity_proof_name,
        isProfilePicUploading,
        isaddressProofUploading,
        isIdentityProofUploading,
      } = this.state;
      if (
        !isProfilePicUploading &&
        !isaddressProofUploading &&
        !isIdentityProofUploading
      ) {
        this.setState({ isDataUploading: true });
        let { id } = this.props;
        let body = {
          memberID: parseInt(id),
          memberFirstName: first_name,
          village: village,
          memberLastName: last_name !== "" ? last_name : null,
          phone: phone_number !== "" ? phone_number : null,
          address: address !== "" ? address : null,
          pincode: pincode !== "" ? parseInt(pincode) : null,
          memberPhotoName: profile_photo !== null ? profile_photo : null,
          memberPhotoUrl: profilePicName !== "" ? profilePicName : "",
        };
        if (gender !== "") {
          body.gender = gender;
        }
        if (identity_proof_name !== "") {
          body.identityProofDocumentUrl = identity_proof;
          body.identityProofName = identity_proof_name;
        }
        if (address_proof_name !== "") {
          body.addressProofDocumentUrl = address_proof;
          body.addressProofName = address_proof_name;
        }
        if (profilePicName !== "") {
          body.memberPhotoUrl = profile_photo;
          body.memberPhotoName = profilePicName;
        }
        if (smart_phone !== "") {
          body.hasSmartPhone = smart_phone;
        }
        if (dob !== "") {
          body.memberDOB = moment(dob).format("YYYY-MM-DD");
        }
        if (state != "") {
          body.stateId = parseInt(state);
        }
        if (district !== "") {
          body.districtId = parseInt(district);
        }
        // if (pincode !== "") {
        //   body.pincodeId = parseInt(pincode);
        // }
        this.props.memberEdit(body).then(() => {
          this.validator.hideMessages();
          this.props.toggleCollapse(false, true, false, false, false, false);
        });
      } else {
        Toast({ type: "error", message: "Please wait Image is uploading" });
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  componentDidMount() {
    this.props.allState().then((data) => {
      let allStateOption = [];
      data.map((list, index) => {
        allStateOption.push({ label: list.stateName, value: list.stateId });
      });
      this.setState({
        allState: allStateOption,
      });
    });

    let { id } = this.props;
    this.props.getMember({ id }).then((data) => {
      let memberDetail = data;
      this.setState({
        first_name:
          memberDetail.memberFirstName !== null
            ? memberDetail.memberFirstName
            : "",
        last_name:
          memberDetail.memberLastName !== null
            ? memberDetail.memberLastName
            : "",
        gender: memberDetail.gender ? memberDetail.gender : "",
        address: memberDetail.address ? memberDetail.address : "",
        pincode: memberDetail.pincode ? String(memberDetail.pincode) : "",
        phone_number: memberDetail.phone,
        smart_phone: memberDetail.hasSmartPhone
          ? memberDetail.hasSmartPhone
          : "",
        dob: memberDetail.memberDOB ? new Date(memberDetail.memberDOB) : "",
        village: memberDetail.village ? memberDetail.village : "",
        district: memberDetail.districtId ? memberDetail.districtId : "",
        state: memberDetail.stateId ? memberDetail.stateId : "",
        profile_photo: memberDetail.memberPhotoUrl
          ? memberDetail.memberPhotoUrl
          : null,
        identity_proof: memberDetail.identityProofDocumentUrl
          ? memberDetail.identityProofDocumentUrl
          : null,
        address_proof: memberDetail.addressProofDocumentUrl
          ? memberDetail.addressProofDocumentUrl
          : null,
        profilePicName: memberDetail.memberPhotoName
          ? memberDetail.memberPhotoName
          : "",
        address_proof_name: memberDetail.identityProofName
          ? memberDetail.identityProofName
          : "",
        identity_proof_name: memberDetail.identityProofName
          ? memberDetail.identityProofName
          : "",
      });
      if (data.stateId) {
        this.props
          .getDistrictApiwithState({ id: data.stateId })
          .then((data) => {
            let allDistrictOption = [];
            data.map((list) => {
              allDistrictOption.push({
                label: list.districtName,
                value: list.districtId,
              });
            });
            this.setState({
              allDistrict: allDistrictOption,
            });
          });
      }
      if (data.districtId) {
        // this.props
        //   .getPincodeApiwithStateDistrict({ id: data.districtId })
        //   .then((data) => {
        //     let allPincodeOption = [];
        //     data.map((list) => {
        //       allPincodeOption.push({
        //         label: list.pincode,
        //         value: list.pincodeId,
        //       });
        //     });
        //     this.setState({
        //       allPincode: allPincodeOption,
        //     });
        //   });
      }
    });
  }

  handleRemoveImage = (picName) => {
    if (picName === "profile") {
      this.setState({
        profile_photo: null,
        profilePicName: "",
      });
    } else if (picName === "addressProof") {
      this.setState({
        address_proof: null,
        address_proof_name: "",
      });
    } else if (picName === "identityProof") {
      this.setState({
        identity_proof: null,
        identity_proof_name: "",
      });
    }
  };

  render() {
    const {
      first_name,
      last_name,
      gender,
      address,
      pincode,
      phone_number,
      smart_phone,
      dob,
      village,
      district,
      state,
      allDistrict,
      allState,
      profile_photo,
      identity_proof,
      address_proof,
      profilePicName,
      address_proof_name,
      identity_proof_name,
      allPincode,
      genderOption,
      smartPhoneOptions,
      isDataUploading,
      isProfilePicUploading,
      isaddressProofUploading,
      isIdentityProofUploading,
      isDelete,
    } = this.state;

    let { id, deleteAccess = false } = this.props;

    return (
      <Fragment>
        <div className="px-md-5 px-3 py-4 add-member-area">
          <div className="d-flex flex-md-row flex-column justify-content-between align-items-md-center align-items-start mb-3">
            <div className="add-member-form-container-heading w-md-50 w-100">
              {"Member profile"}
            </div>
            <div className="d-flex justify-content-md-end justify-content-between align-items-center mt-md-0 mt-2 w-md-50 w-100">
              {isDataUploading ? (
                <div className="d-flex align-items-center h-100">
                  {id && deleteAccess && (
                    <div className="mr-3 d-flex align-items-center h-100">
                      <p className="mb-1 font-weight-bold fs-20 d-flex align-items-center mt-2">
                        <i className="icon-Delete fs-18 text-danger"></i>
                        <span className="text-danger fs-18 pl-3">Delete</span>
                      </p>
                    </div>
                  )}
                  <div className="add-member-form-container-button d-flex align-items-center">
                    {"Save & next"}
                  </div>
                </div>
              ) : (
                <div className="d-flex align-items-center h-100">
                  {id && deleteAccess && (
                    <div className="mr-3 d-flex align-items-center h-100">
                      <p
                        className="mb-1 font-weight-bold fs-20 cursor-pointer d-flex align-items-center mt-2"
                        onClick={() => this.toggleDelete()}
                      >
                        <i className="icon-Delete fs-18" />
                        <span className="text-danger fs-18 pl-3">
                          {"Delete"}
                        </span>
                      </p>
                    </div>
                  )}
                  <div
                    className="add-member-form-container-button cursor-pointer d-flex align-items-center"
                    onClick={this.handleSave}
                  >
                    {"Save & next"}
                  </div>
                </div>
              )}
              <img
                className="ml-3 cursor-pointer"
                src={arrow_up}
                alt="arrow_up"
                name="basic_profile"
                height="10"
                width="10"
                onClick={this.props.collapse}
              />
            </div>
          </div>
          <div>
            <div className="row">
              <div className="col-md-6">
                <NormalInput
                  isHalfWidthForMemberProfile={true}
                  label="First name"
                  type="text"
                  onChange={this.handleChange}
                  value={first_name}
                  name="first_name"
                  required={true}
                />
                <div className="row">
                  <div className="col-md-9 ml-auto">
                    {this.validator.message(
                      "first_name",
                      first_name,
                      "required|alpha_space|min:3|max:30"
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <NormalInput
                  isHalfWidthForMemberProfile={true}
                  label="Phone number"
                  type="text"
                  onChange={this.handleChange}
                  value={phone_number}
                  name="phone_number"
                  // required={true}
                  max={10}
                />
                <div className="row">
                  <div className="col-md-9 ml-auto">
                    {this.validator.message(
                      "phone_number",
                      phone_number,
                      "phone"
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <NormalInput
                  isHalfWidthForMemberProfile={true}
                  label="Last name"
                  type="text"
                  onChange={this.handleChange}
                  value={last_name}
                  name="last_name"
                  // required={true}
                />
                {/* <div className="row">
                <div className="col-md-9 ml-auto">
                  {this.validator.message(
                    "last_name",
                    last_name,
                    "required|alpha_space|min:1|max:30"
                  )}
                </div>
              </div> */}
              </div>
              <div className="col-md-6">
                <NormalSelect
                  isHalfWidthForMemberProfile={true}
                  label="Smart phone"
                  placeholder="Select"
                  value={smart_phone}
                  arrow={true}
                  name="smart_phone"
                  options={smartPhoneOptions}
                  handleChange={this.handleChange}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <NormalSelect
                  isHalfWidthForMemberProfile={true}
                  label="Gender"
                  placeholder="Select"
                  value={gender}
                  arrow={true}
                  name="gender"
                  options={genderOption}
                  handleChange={this.handleChange}
                  // required={true}
                />
                {/* <div className="row">
                <div className="col-md-9 ml-auto">
                  {this.validator.message("gender", gender, "required")}
                </div>
              </div> */}
              </div>
              <div className="col-md-6">
                <NormalDate
                  isIcon={true}
                  isHalfWidthForMemberProfile={true}
                  value={dob}
                  name="dob"
                  placeholder="DD/MM/YYYY"
                  onChange={this.handleChange}
                  mindate={false}
                  maxdate={true}
                  timeInput={false}
                  label={"DOB"}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="row mt-3 pt-1">
                  <div className="col-md-3">
                    <div className="add-member-form-container-title mb-md-0 mb-2">
                      Profile photo{" "}
                    </div>
                  </div>
                  <div className="col-md-7">
                    <div className="w-100">
                      <input
                        className="w-50 h-100 position-absolute opacity-0"
                        type="file"
                        name="profile_photo"
                        accept=".jpg, .png, .jpeg"
                        onChange={this.uploadProfilePicture}
                        id="uploadProfilePicture"
                        title=""
                      />
                      <div className="custom-file-upload">Choose file</div>
                      {profile_photo != null && (
                        <div className="d-flex align-items-center">
                          {profilePicName}
                          {profilePicName !== "" ? (
                            <i
                              className="icon-Delete fs-14 pl-2 cursor-pointer"
                              onClick={() => this.handleRemoveImage("profile")}
                            ></i>
                          ) : null}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="row mt-3 pt-1">
                  <div className="col-md-3">
                    <div className="add-member-form-container-title mb-md-0 mb-2">
                      Address
                    </div>
                  </div>
                  <div className="col-md-8">
                    <textarea
                      className="w-100 h-100"
                      type="textarea"
                      name="address"
                      value={address}
                      onChange={this.handleChange}
                      rows="2"
                      cols="50"
                    ></textarea>
                    {this.validator.message("address", address, "max:100")}
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <NormalInput
                  isHalfWidthForMemberProfile={true}
                  label="Village"
                  type="text"
                  onChange={this.handleChange}
                  value={village}
                  name="village"
                  required={true}
                />
                <div className="row">
                  <div className="col-md-9 ml-auto">
                    {this.validator.message(
                      "village",
                      village,
                      "required|max:20|alpha_space"
                    )}
                  </div>
                </div>
                <NormalSelect
                  isHalfWidthForMemberProfile={true}
                  label="State"
                  placeholder="Select"
                  value={state}
                  arrow={true}
                  name="state"
                  options={allState}
                  handleChange={this.handleChange}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <NormalSelect
                  isHalfWidthForMemberProfile={true}
                  label="District"
                  placeholder="Select"
                  value={district}
                  arrow={true}
                  name="district"
                  options={allDistrict}
                  handleChange={this.handleChange}
                  disabled={state == "" ? true : false}
                />
              </div>
              <div className="col-md-6">
                <NormalInput
                  isHalfWidthForMemberProfile={true}
                  label="Pincode"
                  type="text"
                  onChange={this.handleChange}
                  value={pincode}
                  name="pincode"
                  max={6}
                  required={false}
                />
                <div className="row">
                  <div className="col-md-9 ml-auto">
                    {this.validator.message(
                      "pincode",
                      pincode,
                      "numeric|min:6"
                    )}
                  </div>
                </div>
                {/* <NormalSelect
                isHalfWidthForMemberProfile={true}
                label="Pincode"
                placeholder="Select"
                value={pincode}
                arrow={true}
                name="pincode"
                options={allPincode}
                disabled={state != "" && district != "" ? false : true}
                handleChange={this.handleChange}
              /> */}
              </div>
            </div>
            <div>
              <div className="add-member-form-container-proof mt-4 pt-3">
                {"Proof"}
              </div>
              <div className="d-flex flex-md-row flex-column">
                <div className="mr-md-5 mr-0">
                  <div className="d-flex justify-content-between align-items-center mb-2 pb-1">
                    <div className="add-member-form-container-proof-sub mb-md-0 mb-2">
                      {"Address proof"}
                    </div>
                    {address_proof != null ? (
                      <div>
                        {!isaddressProofUploading ? (
                          <i
                            className="icon-Delete fs-14 cursor-pointer"
                            onClick={() =>
                              this.handleRemoveImage("addressProof")
                            }
                          ></i>
                        ) : null}
                      </div>
                    ) : null}
                  </div>
                  <div className="add-member-form-container-proof-container d-flex justify-content-center align-items-center mb-md-0 mb-4">
                    <input
                      type="file"
                      accept=".jpg, .pdf, .jpeg"
                      name="address_proof"
                      onChange={this.handleAddressProofFile}
                      id="handleAddressProofFile"
                      title=""
                    />

                    {address_proof != null ? (
                      <div className="h-100">
                        <img
                          src={address_proof}
                          alt={address_proof_name}
                          className="h-100"
                        />
                      </div>
                    ) : (
                      <>
                        <img className="mr-2" src={upload} alt="upload" />
                        {"Upload files"}
                      </>
                    )}
                  </div>
                </div>
                <div>
                  <div className="d-flex justify-content-between align-items-center mb-2 pb-1">
                    <div className="add-member-form-container-proof-sub mb-md-0 mb-2">
                      {"Identity proof"}
                    </div>
                    {identity_proof != null ? (
                      <div>
                        {!isIdentityProofUploading ? (
                          <i
                            className="icon-Delete fs-14 cursor-pointer"
                            onClick={() =>
                              this.handleRemoveImage("addressProof")
                            }
                          ></i>
                        ) : null}
                      </div>
                    ) : null}
                  </div>
                  <div className="add-member-form-container-proof-container d-flex justify-content-center align-items-center">
                    <input
                      type="file"
                      accept=".jpg, .pdf, .jpeg"
                      name="identity_proof"
                      onChange={this.handleIdentityProofFile}
                      id="handleIdentityProofFile"
                      title=""
                    />
                    {identity_proof !== null ? (
                      <div className="h-100">
                        <img
                          src={identity_proof}
                          alt={identity_proof_name}
                          className="h-100"
                        />
                      </div>
                    ) : (
                      <>
                        <img className="mr-2" src={upload} alt="upload" />
                        {"Upload files"}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ConfirmationPopup
          isOpen={isDelete}
          toggle={this.toggleDelete}
          onSubmit={this.handleDelete}
        />
      </Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getDistrictApiwithState,
      allState,
      fileUpload,
      getPincodeApiwithStateDistrict,
      memberEdit,
      getMember,
      deleteMember,
    },
    dispatch
  );
};
let component = BasicForm;

export const Basic = connect(null, mapDispatchToProps)(component);
