import React, { Component } from "react";
import { UploadComponent } from "../UploadComponent";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { CommonPageLoader, ConfirmationPopup } from "component/common";
import moment from "moment";
import {
  addFpoDocuments,
  viewFpoDocuments,
  deleteFpoDocuments,
  getMasterData,
  getcreditdataBulkdownload,
  creditDataEdit,
} from "action/DocumentAct";
import { getAccessdata } from "service/helperFunctions";
import { getMonthdata } from "action/ComplianceAct";
import downloadIcon from "assets/images/icon/download-icon.svg";
import {
  getDocumentsOfFPO,
  getcreditdataBulkdownloadOfFpo,
} from "action/RmAct";
const docIcon = require("../../../assets/svg/Documents/docIcon.png");
class CreditDoc extends Component {
  state = {
    documentsData: [],
    isLoading: false,
    financialYearData: [],
    quartermasterData: [],
    monthData: [],
    bulkDownloadLink: "",
    deleteId: null,
  };

  //upload document
  handleUpload = (data) => {
    let {
      id,
      fileName,
      file,
      fromDate,
      toDate,
      documentType,
      reportQuarterName,
      financialYearNameforCreditData,
      financialYearIdforCreditData,
      asOnDateforCreditData,
      reportStartYear,
      reportEndYear,
      startMonthName,
      endMonthName,
      reportName,
    } = data;

    let body = {
      creditDocId: id,
      documentName: fileName,
      documentPath: file,
      reportName: reportName,
      reportFyYear: null,
      fymId: null,
      reportQuarter: null,
      reportFromDate: null,
      reportToDate: null,
      reportAsOnDate: null,
      reportStartYear: null,
      reportEndYear: null,
      reportStartMonth: null,
      reportEndMonth: null,
    };

    let { addFpoDocuments } = this.props;
    if (documentType === "bank_statement") {
      //bank statement
      body.reportFromDate = moment(fromDate).format("YYYY-MM-DD");
      body.reportToDate = moment(toDate).format("YYYY-MM-DD");
    } else if (documentType === "gst_returns") {
      //Gst returns
      body.reportFyYear = financialYearNameforCreditData;
      body.fymId = financialYearIdforCreditData;
      body.reportQuarter = reportQuarterName;
    } else if (documentType === "it_returns") {
      //It returns
      body.reportFyYear = financialYearNameforCreditData;
      body.fymId = financialYearIdforCreditData;
    } else if (
      documentType === "debtors_ageing" ||
      documentType === "top_customers" ||
      documentType === "top_suppliers" ||
      documentType === "borrowing_profile" ||
      documentType === "COMPANYENTITY" ||
      documentType === "INDIVIDUALBUREAU"
    ) {
      // debtors_aging , Top customers, top suppliers, borrowing profile
      body.reportAsOnDate = moment(asOnDateforCreditData).format("YYYY-MM-DD");
    } else if (
      documentType === "sales_report" ||
      documentType === "purchase_report"
    ) {
      //sales report , Purchase report
      body.reportStartYear = moment(reportStartYear).format("YYYY");
      body.reportEndYear = moment(reportEndYear).format("YYYY");
      body.reportStartMonth = startMonthName;
      body.reportEndMonth = endMonthName;
    }

    //API call
    addFpoDocuments(body, "credit").then(() => {
      this._getcreditlDocuments();
    });
  };

  //Edit Document
  handleEditOption = (data) => {
    let {
      id,
      fromDate,
      toDate,
      documentType,
      reportQuarterName,
      financialYearNameforCreditData,
      financialYearIdforCreditData,
      asOnDateforCreditData,
      reportStartYear,
      reportEndYear,
      startMonthName,
      endMonthName,
      reportName,
    } = data;

    let body = {
      id: id,
      reportName: reportName,
      reportFyYear: null,
      fymId: null,
      reportQuarter: null,
      reportFromDate: null,
      reportToDate: null,
      reportAsOnDate: null,
      reportStartYear: null,
      reportEndYear: null,
      reportStartMonth: null,
      reportEndMonth: null,
    };

    if (documentType === "bank_statement") {
      //bank statement
      body.reportFromDate = moment(fromDate).format("YYYY-MM-DD");
      body.reportToDate = moment(toDate).format("YYYY-MM-DD");
    } else if (documentType === "gst_returns") {
      //Gst returns
      body.reportFyYear = financialYearNameforCreditData;
      body.fymId = financialYearIdforCreditData;
      body.reportQuarter = reportQuarterName;
    } else if (documentType === "it_returns") {
      //It returns
      body.reportFyYear = financialYearNameforCreditData;
      body.fymId = financialYearIdforCreditData;
    } else if (
      documentType === "debtors_ageing" ||
      documentType === "top_customers" ||
      documentType === "top_suppliers" ||
      documentType === "borrowing_profile" ||
      documentType === "COMPANYENTITY" ||
      documentType === "INDIVIDUALBUREAU"
    ) {
      // debtors_aging , Top customers, top suppliers, borrowing profile
      body.reportAsOnDate = moment(asOnDateforCreditData).format("YYYY-MM-DD");
    } else if (
      documentType === "sales_report" ||
      documentType === "purchase_report"
    ) {
      //sales report , Purchase report
      body.reportStartYear = moment(reportStartYear).format("YYYY");
      body.reportEndYear = moment(reportEndYear).format("YYYY");
      body.reportStartMonth = startMonthName;
      body.reportEndMonth = endMonthName;
    }
    this.props.creditDataEdit(body).then(() => {
      this._getcreditlDocuments();
    });
  };

  //Delete document
  handleDelete = () => {
    let { deleteId } = this.state;
    this.props
      .deleteFpoDocuments(
        {
          id: deleteId,
        },
        "creditdata"
      )
      .then(() => {
        this._getcreditlDocuments();
      });
  };

  toggleDelete = (id) => {
    this.setState({
      deleteId: id,
    });
  };

  //Life cycles
  componentDidMount() {
    let { activeTab } = this.props;
    if (activeTab == 5) {
      this._getcreditlDocuments();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      if (this.props.activeTab == 5) {
        this._getcreditlDocuments();
      }
    }
  }

  //Get credit documents data
  _getcreditlDocuments = () => {
    this.setState({ isLoading: true });
    let { id } = this.props;
    if (id) {
      this.props.getDocumentsOfFPO(id, "creditdata").then((data) => {
        this._setData(data);
      });
    } else {
      this.props.viewFpoDocuments("creditdata").then((data) => {
        this._setData(data);
      });
    }
  };

  _setData = (data) => {
    let creditData = [];
    data.map((list) => {
      creditData.push({
        id: list.creditDocId,
        title: list.documentName,
        body: list.description,
        documents: list.creditData,
        documentType: list.documentType,
      });
    });
    this.setState({
      documentsData: creditData,
      isLoading: false,
    });
    //get financial master data
    this.props.getMasterData().then((masterData) => {
      let {
        financialYearMaster = [],
        quartermasterData = [],
        monthData = [],
      } = masterData;
      let financialYearDetails = [],
        quartermasterDetails = [];
      financialYearMaster.map((list) => {
        financialYearDetails.push({ label: list.fymYear, value: list.fymId });
      });
      quartermasterData.map((list) => {
        quartermasterDetails.push({
          label: list.quarterName,
          value: list.quarterId,
        });
      });
      let monthDetails = [];
      monthData.map((list) => {
        monthDetails.push({ value: list.monthId, label: list.monthName });
      });
      this.setState({
        financialYearData: financialYearDetails,
        quartermasterData: quartermasterDetails,
        monthData: monthDetails,
      });
    });
  };

  getCsvUrl = () => {
    let { getcreditdataBulkdownload, id, getcreditdataBulkdownloadOfFpo } =
      this.props;
    if (id) {
      let body = {
        fpoId: id,
      };
      getcreditdataBulkdownloadOfFpo(body).then((data) => {
        this._downloadData(data);
      });
    } else {
      let body = {
        id: null,
      };
      getcreditdataBulkdownload(body).then((data) => {
        this._downloadData(data);
      });
    }
  };

  _downloadData = (data) => {
    this.setState(
      {
        bulkDownloadLink:
          "https://portal.fpogateway.com/fpomgmt/api/v1/download/zip?zipFileName=" +
          data.zipFileName,
      },
      () => {
        document.getElementById("csv-download").click();
        this.setState({ bulkDownloadLink: "" });
      }
    );
  };

  render() {
    let {
      documentsData,
      isLoading,
      financialYearData,
      quartermasterData,
      monthData,
      bulkDownloadLink,
      deleteId,
    } = this.state;
    let { accessData, id } = this.props;
    let permissionData = getAccessdata(accessData, "fpoDocuments");

    let { download = false } = permissionData;
    return (
      <div className="d-flex flex-column">
        {download && (
          <div className="container-sa">
            <div className="d-flex justify-content-end">
              {bulkDownloadLink === "" ? (
                <span
                  className="text-black-50 font-weight-bold fs-18 text-underline cursor-pointer pr-md-5 pr-0 mr-md-3 mr-0"
                  onClick={() => this.getCsvUrl()}
                >
                  <img
                    src={downloadIcon}
                    className="mr-2"
                    height="20"
                    width="20"
                    alt="downloadIcon"
                  />
                  {"Download all"}
                </span>
              ) : (
                <a
                  id="csv-download"
                  className="text-black-50 font-weight-bold fs-18 text-underline cursor-pointer pr-md-5 pr-0 mr-md-3 mr-0"
                  href={bulkDownloadLink}
                  download
                >
                  <img
                    src={downloadIcon}
                    className="mr-2"
                    height="20"
                    width="20"
                    alt="downloadIcon"
                  />
                  {"Download all"}
                </a>
              )}
            </div>
          </div>
        )}
        <div className="container-sa">
          {!isLoading ? (
            <div className="row">
              {documentsData.map((documentDetail, index) => {
                return (
                  <div className="col-md-3 d-flex align-items-end" key={index}>
                    <div className="document-card-margin">
                      <div className="document-titleBlock">
                        <div className="d-flex align-items-center mb-1">
                          <img
                            className="mr-3"
                            src={docIcon}
                            height="30"
                            width="30"
                            alt="docIcon.svg"
                          />
                          <div className="document-title">
                            {documentDetail.title}
                          </div>
                        </div>
                        <div className="document-body mb-1">
                          {documentDetail.body}
                        </div>
                      </div>
                      <div>
                        <UploadComponent
                          documentDetail={documentDetail}
                          documentTitle={documentDetail.title}
                          title={"Credit"}
                          handleUpload={this.handleUpload}
                          handleEditOption={this.handleEditOption}
                          handleDelete={this.toggleDelete}
                          id={documentDetail.id}
                          documents={documentDetail.documents}
                          activeTab={this.props.activeTab}
                          financialYearData={financialYearData}
                          quartermasterData={quartermasterData}
                          monthData={monthData}
                          fpoId={id}
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <>
              {/* Loader */}
              <CommonPageLoader />
            </>
          )}
          <ConfirmationPopup
            isOpen={deleteId !== null ? true : false}
            toggle={() => this.toggleDelete(null)}
            onSubmit={this.handleDelete}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  accessData: state.home.accessData,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      addFpoDocuments,
      viewFpoDocuments,
      deleteFpoDocuments,
      getMasterData,
      getMonthdata,
      getcreditdataBulkdownload,
      getDocumentsOfFPO,
      getcreditdataBulkdownloadOfFpo,
      creditDataEdit,
    },
    dispatch
  );
};
let component = CreditDoc;

export const Credit = connect(mapStateToProps, mapDispatchToProps)(component);
