import { samIPL } from "service/apiVariables";
import { Toast } from "service/toast";
import { api } from "service/api";

//update Mobile
export const updateMobile = (body) => () => {
  return new Promise((resolve, reject) => {
    api({ ...samIPL.updateMobile, body })
      .then(({ data, message }) => {
        resolve(data);
        Toast({ type: "success", message });
      })
      .catch(({ message }) => reject(Toast({ type: "error", message })));
  });
};

// update Otp
export const updateOtp = (body) => () => {
  return new Promise((resolve, reject) => {
    api({ ...samIPL.updateOtp, body })
      .then(({ data, message }) => {
        resolve(data);
        Toast({ type: "success", message });
      })
      .catch(({ message }) => reject(Toast({ type: "error", message })));
  });
};

// resend Otp
export const resendOtp = (body) => () => {
  return new Promise((resolve, reject) => {
    api({ ...samIPL.resendOtp, body })
      .then(({ data, message }) => {
        resolve(data);
        Toast({ type: "success", message });
      })
      .catch(({ message }) => reject(Toast({ type: "error", message })));
  });
};

// get Entity List
export const getEntityList = () => () => {
  return new Promise((resolve, reject) => {
    api({ ...samIPL.getEntityList })
      .then(({ data }) => resolve(data))
      .catch(({ message }) => reject(Toast({ type: "error", message })));
  });
};

// update Entity
export const updateEntity = (body) => () => {
  return new Promise((resolve, reject) => {
    api({ ...samIPL.updateEntity, body })
      .then(({ data, message }) => {
        resolve(data);
        Toast({ type: "success", message });
      })
      .catch(({ message }) => reject(Toast({ type: "error", message })));
  });
};

// get Entity IPL Url
export const getEntityIPLUrl = (body) => () => {
  return new Promise((resolve, reject) => {
    api({ ...samIPL.getEntityIPLUrl, body })
      .then(({ data }) => resolve(data))
      .catch(({ data }) => resolve(data));
  });
};
