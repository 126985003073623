import React, { Component } from "react";

import {
  academyExploreById,
  academyExplore,
  pageChange,
  searchChange,
} from "action/Acadamy";

import {
  Banner,
  AcadamyMenu,
  TrendingNow,
  TrendingVideo,
} from "component/Acadamy";

import { history } from "service/helpers";

import { FilteredBlog } from "../../component/Acadamy/Blog/FilteredBlog";

import { CommonPageLoader } from "component/common";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

const arrow_left = require("assets/svg/add_member/arrow_left.svg");

export class AcadamyExploreClass extends Component {
  constructor() {
    super();
    this.state = {
      categoryName: "",
      courseData: [],
      blogData: [],
      trendingVideos: [],
      loading: false,
    };
  }

  componentDidMount() {
    let { id } = this.props.match.params;
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    let { search } = this.props;
    this.setState({ loading: true });
    this.props.academyExplore().then((data) => {
      this.setState({ dropdownList: data }, () => {
        if (id !== undefined) {
          data.map((list) => {
            if (list.courseCategoryId == id) {
              this.setState({ categoryName: list.categoryName });
            }
            if (id == 0) {
              this.setState({ categoryName: "" });
            }
          });
        }
      });
    });
    var query = {
      filterValue: id == 0 ? "" : id,
      search: search == "" ? "" : search,
    };
    this.props.academyExploreById(query).then((list) => {
      if (list !== null) {
        this.setState({
          blogData: list.blogs,
          trendingVideos: list.trendingVideos,
          courseData: list.courses,
          loading: false,
        });
      } else {
        this.setState({
          loading: false,
        });
      }
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      let { id } = this.props.match.params;
      let { search } = this.props;

      this.setState({ loading: true });
      this.props.academyExplore().then((data) => {
        this.setState({ dropdownList: data }, () => {
          if (id !== undefined) {
            data.map((list) => {
              if (list.courseCategoryId == id) {
                this.setState({ categoryName: list.categoryName });
              }
              if (id == 0) {
                this.setState({ categoryName: "" });
              }
            });
          }
        });
      });
      var query = {
        filterValue: id == 0 ? "" : id,
        search: search == "" ? "" : search,
      };
      this.props.academyExploreById(query).then((list) => {
        if (list !== null) {
          this.setState({
            blogData: list.blogs,
            trendingVideos: list.trendingVideos,
            courseData: list.courses,
            loading: false,
          });
        } else {
          this.setState({
            loading: false,
          });
        }
      });
    }
  }

  render() {
    let {
      courseData,
      blogData,
      trendingVideos,
      categoryName,
      loading,
    } = this.state;
    return (
      <>
        <Banner />
        <AcadamyMenu
          id={this.props.match.params.id}
          categoryName={categoryName}
          isBack={true}
          handleChange={this.handleChange}
        />
        <div className="d-flex container-sa my-3 ">
          <div
            onClick={() => {
              this.props.pageChange(0);
              this.props.searchChange("");
              history.push("/academy/home");
            }}
            className="d-flex"
          >
            <img className="cursor-pointer" src={arrow_left} alt="back" />
            <p className="ml-3 add-member-text-back mb-0 cursor-pointer">
              Home
            </p>
          </div>
        </div>
        {!loading ? (
          <div className="h-100">
            {courseData.length > 0 ? (
              <TrendingNow trendingCourseData={courseData} title="Courses" />
            ) : categoryName == "" ? null : (
              <div className="container-sa py-5">
                <h4 className="text-center font-weight-bold fs-26">
                  {"Courses"}
                </h4>
                <p className="my-3 text-center text-black-50 fs-20">
                  No data found
                </p>
              </div>
            )}
            {blogData.length > 0 ? (
              <FilteredBlog blogList={blogData} />
            ) : categoryName == "" ? null : (
              <div className="container-sa py-5">
                <h4 className="text-center font-weight-bold fs-26">{"Blog"}</h4>
                <p className="my-3 text-center text-black-50 fs-20">
                  No data found
                </p>
              </div>
            )}

            {trendingVideos.length > 0 ? (
              <TrendingVideo
                trendingVideos={trendingVideos}
                title="Informative videos"
              />
            ) : categoryName == "" ? null : (
              <div className="container-sa py-5">
                <h4 className="text-center font-weight-bold fs-26">
                  {"Informative videos"}
                </h4>
                <p className="my-3 text-center text-black-50 fs-20">
                  No data found
                </p>
              </div>
            )}
          </div>
        ) : (
          <>
            {/* Loader */}
            <CommonPageLoader />
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  search: state.academy.searchValue,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      academyExploreById,
      academyExplore,
      pageChange,
      searchChange,
    },
    dispatch
  );
};

let component = AcadamyExploreClass;

export const AcadamyExplore = connect(
  mapStateToProps,
  mapDispatchToProps
)(component);
