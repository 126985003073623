import React, { Component } from "react";
import { history } from "service/helpers";
import { Navbar } from "../component/common";
export class OtherLoginLayout extends Component {
  componentDidMount() {
    let authToken = localStorage.getItem("AuthToken");
    if (!authToken) history.push("/");
  }

  render() {
    let { children } = this.props;
    return (
      <>
        <Navbar />
        {children}
      </>
    );
  }
}
