import React, { Component } from "react";
// English.
import { getDescriptionData, letterAvatar } from "service/helperFunctions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Toast } from "service/toast";
import { CommentCard } from "./CommentCard";
import { CreateForum } from "component/Acadamy";
import {
  forumLike,
  forumUnLike,
  commentLike,
  commentUnLike,
  forumComment,
  getForumComment,
  getForumComments,
  deleteForum,
  saveForum,
} from "action/forum";
import { fileUpload } from "action/MemberAct";

import moment from "moment";
export class CardClass extends Component {
  state = {
    active: false,
    isPostComment: false,
    commentsList: [],
    attachmentUrl: null,
    attachmentImgFileName: "",
    isAttachmentUploading: false,
    forumCommentContent: "",
    isCreateModal: false,
    forumId: "",
    isBookmarkDisabled: false,
    isCommentsList: false,
    isCommentsLoading: false,
    readMore: false,
  };

  handleFile = (e) => {
    let file = e.target.files[0];
    if (file !== undefined) {
      this.setState({ attachmentUrl: null }, () => {
        this.setState({
          isAttachmentUploading: true,
          attachmentImgFileName: "Uploading ....",
          attachmentUrl: file,
        });
        const formData = new FormData();
        formData.append("file", file);

        this.props
          .fileUpload(formData)
          .then((data) => {
            document.getElementById("handleFile").value = null;
            this.setState({
              attachmentUrl: data[0].fileUrl,
              attachmentImgFileName: data[0].fileName,
              isAttachmentUploading: false,
            });
          })
          .catch(() => {
            this.setState({
              attachmentImgFileName: "",
              attachmentUrl: "",
              isAttachmentUploading: false,
            });
          });
      });
    }
  };

  //handleCommentInput
  handleCommentInput = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  };

  //handleForumComment
  handleForumComment = (id) => {
    let { forumCommentContent, isAttachmentUploading, attachmentUrl } =
      this.state;
    if (
      forumCommentContent.trim().length !== 0 &&
      !(forumCommentContent.trim().length > 500)
    ) {
      if (!isAttachmentUploading) {
        let body = {
          forumId: id,
          content: forumCommentContent,
        };
        attachmentUrl && (body.attachment = attachmentUrl);
        this.props
          .forumComment(body)
          .then((data) => {
            this.setState({
              forumCommentContent: "",
              attachmentImgFileName: "",
              attachmentUrl: null,
            });
            this.props.getForumList("reset");
            this.handleGetComments(this.props.list.id);

            this.togglePost();
          })
          .catch(() => {
            this.setState({ isFormLoder: false });
          });
      } else {
        Toast({ type: "error", message: "Please wait Image is uploading" });
      }
    } else {
      if (forumCommentContent.trim().length === 0) {
        Toast({ type: "error", message: "Comment can't be empty" });
      } else {
        Toast({
          type: "error",
          message: "Comment length can't be greater than 500",
        });
      }
    }
  };

  handleLikeUlike = (id) => {
    let { list } = this.props;
    let body = {
      forumId: id,
    };
    let apiCall = !list.isLiked
      ? this.props.forumLike(body)
      : this.props.forumUnLike(body);
    apiCall
      .then((data) => {
        this.props.getForumList("reset");
      })
      .catch(() => {
        this.setState({ isFormLoder: false });
      });
  };

  handleGetComments = (i) => {
    this.setState({
      isCommentsLoading: true,
    });
    this.props
      .getForumComments(i, {
        limit: 20,
        page: 1,
      })
      .then(({ success, data }) => {
        this.setState({
          commentsList: data.results,
          isCommentsLoading: false,
        });
      })
      .catch(() => {
        this.setState({ isFormLoder: false });
      });
  };

  toggleRead = () => {
    this.setState({
      readMore: !this.state.readMore,
    });
  };

  togglePost = () => {
    this.setState({
      isPostComment: !this.state.isPostComment,
    });
  };

  toggleCommentList = () => {
    !this.state.isCommentsList && this.handleGetComments(this.props.list.id);
    this.setState({
      isCommentsList: !this.state.isCommentsList,
    });
  };

  handleDropdown = () => {
    if (!this.state.active) {
      document.addEventListener("click", this.handleOutsideClick, false);
    } else {
      document.removeEventListener("click", this.handleOutsideClick, false);
    }
    this.setState((prevState) => ({
      active: !prevState.active,
    }));
  };

  handleOutsideClick = (e) => {
    if (this.node != null) {
      if (this.node.contains(e.target)) {
        return;
      }
    }
    this.handleDropdown();
  };

  //handle delete
  handleDelete = (id) => {
    this.props.deleteForum(id).then((data) => {
      this.props.getForumList("reset");
    });
  };

  handleBookmark = (id, savedStatus) => {
    this.setState({
      isBookmarkDisabled: true,
    });
    let { saveForum, myForum = false } = this.props;

    let body = {
      isSaved: true,
      forumId: parseInt(id),
    };
    if (savedStatus) {
      body.isSaved = !savedStatus;
    }
    saveForum(body).then(() => {
      this.setState({
        isBookmarkDisabled: false,
      });
      if (myForum) {
        this.props.handleReloadSavedForums();
      }
      this.props.getForumList("reset");
    });
  };

  handleEdit = (id) => {
    this.handleCreateModalToggle();
    this.setState({ forumId: id });
  };

  //handleCreateModalToggle modal window
  handleCreateModalToggle = () => {
    let { isCreateModal } = this.state;
    this.setState({ isCreateModal: !isCreateModal });
  };

  render() {
    const { list } = this.props;
    const {
      title,
      content,
      userDetails: { userName },
      createdAt,
      likesCount,
      commentsCount,
      images,
      isCurrentUser,
      isLiked,
      id,
      isSaved = false,
      category,
    } = list;
    const {
      active,
      isPostComment,
      commentsList,
      forumCommentContent,
      attachmentUrl,
      attachmentImgFileName,
      isCreateModal,
      forumId,
      isBookmarkDisabled,
      isCommentsList,
      isCommentsLoading,
      readMore,
    } = this.state;
    return (
      <div className="forum-card mb-3">
        <div className="row mb-4">
          <div className="col-md-10">
            <div className="media user-profile">
              <img
                className="mr-3"
                src={letterAvatar(userName, 39)}
                alt="avatar"
              />
              <div className="media-body">
                <h5>
                  {userName} .{" "}
                  {`${moment(createdAt, "YYYY-MM-DD HH:mm:ss").toNow(
                    true
                  )} ago`}
                </h5>
              </div>
            </div>
          </div>
          <div className="col-md-2 d-flex align-items-center justify-content-end">
            <i
              className={`${
                isSaved ? "icon-bookmark-green" : "icon-bookmark"
              } fs-20 cursor-pointer text-dark`}
              onClick={() => {
                if (!isBookmarkDisabled) {
                  this.handleBookmark(id, isSaved);
                }
              }}
            ></i>
            <div className="position-relative d-flex">
              {isCurrentUser && (
                <>
                  <i
                    className="icon-horizontal-dots fs-20 pl-4 cursor-pointer"
                    onClick={this.handleDropdown}
                  ></i>
                  <div
                    className={`${
                      active ? "view-more-option d-block" : "d-none"
                    }`}
                  >
                    <ul>
                      <li onClick={() => this.handleEdit(id)}>{"Edit"}</li>
                      <li onClick={() => this.handleDelete(id)}>{"Delete"}</li>
                    </ul>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="media category">
              <div className="media-body">
                <h5 className="mt-0 mb-1">{category?.name}</h5>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="media title-card">
              <div className="media-body">
                <h5 className="mt-1 mb-1 line-height-24">{title}</h5>
              </div>
            </div>
          </div>
          <div className="col-md-12 sub-text mb-2">
            <span
              dangerouslySetInnerHTML={{
                __html: !readMore ? getDescriptionData(content, 200) : content,
              }}
            ></span>
            {content.length > 200 ? (
              <>
                {readMore && "..."}
                <sapn
                  className="text-green cursor-pointer"
                  onClick={this.toggleRead}
                >
                  {!readMore ? "read more" : "read less"}
                </sapn>
              </>
            ) : null}
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <img
              className="card-img post-img"
              src={
                images.length > 0 ? images[0].url : "/image/Rectangle 343.png"
              }
              alt="post_img"
            />
          </div>
          <div className="col-md-12 post-action">
            <ul className="mb-0">
              <li className="d-flex align-items-center">
                <i
                  className={`pr-2 ${
                    isLiked
                      ? "icon-thumbs-up fs-18"
                      : "icon-thumbs-up-color fs-18"
                  } `}
                  onClick={() => this.handleLikeUlike(list.id)}
                />{" "}
                {likesCount ? likesCount : 0}
              </li>
              <li className="d-flex align-items-center">
                <i
                  className="pr-2 icon-comment fs-18"
                  onClick={this.toggleCommentList}
                />{" "}
                {commentsCount ? commentsCount : 0}
              </li>
              <li
                className="d-flex align-items-center cursor-pointer"
                onClick={() => this.togglePost()}
              >
                <i className="pr-2 icon-corner-up-left fs-18" />
                {"  Reply"}
              </li>
            </ul>
          </div>
          {isPostComment && (
            <>
              <div className="col-md-12 mb-3 position-relative post-comment">
                <textarea
                  className="form-control"
                  placeholder="Type here"
                  name="forumCommentContent"
                  value={forumCommentContent}
                  onChange={this.handleCommentInput}
                  rows="3"
                ></textarea>
                <div className="attachment">
                  <input
                    type="file"
                    className=""
                    accept=".jpg, .png, .jpeg"
                    onChange={this.handleFile}
                    id="handleFile"
                    title=""
                  />
                  <i className="icon-link fs-18" />{" "}
                  {attachmentUrl == null
                    ? "Add attachments"
                    : getDescriptionData(attachmentImgFileName, 15)}
                </div>
              </div>
              <div className="col-md-12 comments-text mb-4">
                <button
                  type="button"
                  className="btn mainbg-btn post-btn"
                  onClick={() => this.handleForumComment(list.id)}
                >
                  Post comment
                </button>
              </div>
            </>
          )}
        </div>
        {isCommentsList &&
          (isCommentsLoading ? (
            <div className="d-flex justify-content-center align-items-center py-3">
              <p className="fs-18 text-black-50 line-height-23 mb-0">Loading</p>
              <div className="spinner-border ml-2 spinner-border-sm"></div>
            </div>
          ) : (
            <div className="comments-section">
              <div className="row ">
                <div className="col-md-12 comment-scroll">
                  {commentsList.map((comment, index) => {
                    return (
                      <div key={index} className="row">
                        <CommentCard
                          comment={comment}
                          getForumList={this.props.getForumList}
                          forumId={list.id}
                          handleGetComments={() =>
                            this.handleGetComments(list.id)
                          }
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          ))}
        <CreateForum
          show={isCreateModal}
          forumId={forumId}
          handleCreateModalToggle={this.handleCreateModalToggle}
          reload={this.props.reload}
          getForumList={this.props.getForumList}
          myForum={this.props.myForum}
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      forumLike,
      forumUnLike,
      forumComment,
      getForumComment,
      getForumComments,
      deleteForum,
      fileUpload,
      saveForum,
    },
    dispatch
  );
};

let component = CardClass;

export const Card = connect(null, mapDispatchToProps)(component);
