import React, { Component } from 'react';
import './RegisterCard.scss';
import { history } from 'service/helpers';
import moment from 'moment';
import User_Profile_1 from 'assets/svg/Members/User_Profile_1.svg';

import long_arrow from 'assets/svg/price_details/long_arrow.svg';
export class RegisterCard extends Component {
   render() {
      const {
         id,
         consultatName,
         fymYear,
         dueDate,
         dateOfFiling,
         location,
         remarks,
         balanceSheetFileUrl,
         profitLossAccountFileUrl,
         auditReportFileUrl,
         directorsReportFileUrl,
      } = this.props.payload;
      const { index, editAccess } = this.props;
      return (
         <div key={index}
            className={`col-md-3 col-lg-3 col-6 ${index % 4 === 3 ? 'pr-md-0' : ''} mb-2 mt-3 px-2 ${index % 4 === 0 ? 'pl-md-0' : ''}`}
         >
            <div className='register-card rounded shadow cursor-pointer'>
               <div onClick={() => { editAccess && history.push(`/fpo/edit-registrar/${id}`) }}  >
                  <div className='d-flex justify-content-between mb-4'>
                     <div className='d-flex align-items-center h-100'>
                        <div className='mr-2 pr-1 position-relative profile-container'>
                           <img
                              src={User_Profile_1}
                              alt='member_profile'
                              className='position-absolute'
                           />
                           <div className='position-absolute text-white fs-20 font-weight-bold'>
                              {consultatName ? consultatName.charAt(0) : '-'}
                           </div>
                        </div>
                        <div>
                           <div className='register-card-member-name mb-1'>
                              {consultatName ? consultatName : '-'}
                           </div>
                           <div className='register-card-subtitle'>
                              {fymYear ? fymYear : '-'}
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className='d-flex justify-content-between mb-4'>
                     <div className='col-sm-6 pl-0'>
                        <div className='line-height-21 font-Gilroy text-black-50 fs-16 mb-3'>
                           {'Due date'}
                        </div>
                        <div className='line-height-21 font-Gilroy-Bold fs-18 text-black-1c font-weight-bold'>
                           {dueDate ? moment(dueDate).format('DD MMMM YYYY') : '-'}
                        </div>
                     </div>
                     <div className='col-sm-6 pr-0'>
                        <div className='line-height-21 font-Gilroy text-black-50 fs-16 mb-3'>
                           {'Date of filing'}
                        </div>
                        <div className='line-height-21 font-Gilroy-Bold fs-18 text-black-1c font-weight-bold'>
                           {dateOfFiling ? moment(dateOfFiling).format('DD MMMM YYYY') : '-'}
                        </div>
                     </div>
                  </div>
                  <div className='d-flex justify-content-between mb-4'>
                     <div>
                        <div className='line-height-21 font-Gilroy text-black-50 fs-16 mb-3'>
                           {'Locations filed	'}
                        </div>
                        <div className='line-height-21 font-weight-bold font-Gilroy-Bold fs-18 text-black-1c'>
                           {location ? location : '-'}
                        </div>
                     </div>
                  </div>
                  <div className='d-flex justify-content-between mb-4 height-80'>
                     <div>
                        <div className='line-height-21 font-Gilroy text-black-50 fs-16 mb-3'>
                           {'Remarks'}
                        </div>
                        <div className='line-height-21 font-weight-bold font-Gilroy-Bold fs-18 text-black-1c'>
                           {remarks && remarks.length > 50 ? `${remarks.substring(0, 50)}...` : remarks}
                        </div>
                     </div>
                  </div>
               </div>
               {balanceSheetFileUrl ?
                  <div
                     className='d-flex mb-4 justify-content-between'
                     onClick={() => window.open(balanceSheetFileUrl, '_blank')}
                  >
                     <div className='text-green font-weight-bold mr-2 fs-16'>
                        {'Balance sheet file'}
                     </div>
                     <div className='mr-4'>
                        <img src={long_arrow} alt='arrow.svg' />
                     </div>
                  </div>
                  : null}
               {profitLossAccountFileUrl ?
                  <div
                     className='d-flex mb-4 justify-content-between'
                     onClick={() => window.open(profitLossAccountFileUrl, '_blank')}
                  >
                     <div className='text-green font-weight-bold mr-2 fs-16'>
                        {'Profit loss account file'}
                     </div>
                     <div className='mr-4'>
                        <img src={long_arrow} alt='arrow.svg' />
                     </div>
                  </div>
                  : null}
               {auditReportFileUrl ?
                  <div
                     className='d-flex mb-4 justify-content-between'
                     onClick={() => window.open(auditReportFileUrl, '_blank')}
                  >
                     <div className='text-green font-weight-bold mr-2 fs-16'>
                        {'Auditor Report File'}
                     </div>
                     <div className='mr-4'>
                        <img src={long_arrow} alt='arrow.svg' />
                     </div>
                  </div>
                  : null}
               {directorsReportFileUrl ?
                  <div
                     className='d-flex mb-4 justify-content-between'
                     onClick={() => window.open(directorsReportFileUrl, '_blank')}
                  >
                     <div className='text-green font-weight-bold mr-2 fs-16'>
                        {'Directors report file'}
                     </div>
                     <div className='mr-4'>
                        <img src={long_arrow} alt='arrow.svg' />
                     </div>
                  </div>
                  : null}
            </div>
         </div>
      );
   }
}
