import React, { Component } from "react";
import "./style.scss";
import { NormalInput, NormalButton } from "component/common";
import { Collapse } from "reactstrap";
import { Pagination } from "./pagination";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getAllFaqs } from "action/FaqAct";
import { getFaqAndHeaderCount } from "action/PreAct";

const faqProfile = require("assets/svg/faq/faq.svg");
export class FaqViewClass extends Component {
  state = {
    searchValue: "",
    limit: 5,
    pageMeta: {},
    open: [true, false, false, false, false],
    faqs: [],
  };
  //handle inputs
  handleInput = (e) => {
    const { name, value } = e.target;
    this.setState(
      {
        [name]: value,
      },
      () => {
        if (value === "") {
          this.handleFaqs(1);
        }
      }
    );
  };

  //handle page change
  handlePagination = (page) => {
    this.handleFaqs(page);
  };

  //handle collapse
  handleToggle = (id) => {
    let openData = [];
    this.state.open.map((open, index) => {
      if (index === id) {
        openData.push(!open);
      } else {
        openData.push(false);
      }
    });
    this.setState({
      open: openData,
    });
  };

  //get faq data
  handleFaqs = (page) => {
    const { limit, searchValue } = this.state;
    localStorage.getItem("AuthToken")
      ? this.props
          .getAllFaqs({ limit, page, search: searchValue })
          .then((data) => {
            const { results, pageMeta } = data;
            this.setState({
              faqs: results,
              pageMeta: pageMeta,
            });
          })
      : this.props
          .getFaqAndHeaderCount({ limit, page, search: searchValue })
          .then((data) => {
            this.setState({
              faqs: data.allQuestionsList.results,
              pageMeta: data.allQuestionsList.pageMeta,
            });
          });
  };

  //life cylces
  componentDidMount() {
    this.handleFaqs(1);
  }

  render() {
    const { searchValue, open, faqs, pageMeta = {} } = this.state;
    return (
      <div>
        <div className="container-sa faq">
          <div className="mb-5">
            <p className="faq-title-bg">Frequently Asked Questions</p>
            <p className="faq-title font-weight-600">
              Frequently Asked Questions
            </p>
          </div>
          <div className="row w-100">
            <div className="col-12 p-5">
              {faqs.length != 0 ? (
                <div className="faq-area">
                  {faqs.map((faq, index) => {
                    return (
                      <div key={index} className="px-4 py-3 shadow mb-3">
                        <div
                          className="d-flex align-items-center w-100 cursor-pointer px-3"
                          onClick={() => this.handleToggle(index)}
                        >
                          <div className="font-weight-bold w-75">
                            {faq.question}
                          </div>
                          {open[index] ? (
                            <div className="font-weight-bold mx-auto w-25 text-right">
                              <span className="icon-minus-solid text-green-34 fs-22 "></span>
                            </div>
                          ) : (
                            <div className="font-weight-bold mx-auto w-25 text-right">
                              <span className="icon-add-outline text-green-34 fs-22"></span>
                            </div>
                          )}
                        </div>
                        <div>
                          <Collapse isOpen={open[index]}>
                            <div>
                              <div className="divider mb-3 mt-3"></div>
                              <div
                                className="px-3 text-black-50 content-area"
                                dangerouslySetInnerHTML={{
                                  __html: faq.answer,
                                }}
                              ></div>
                            </div>
                          </Collapse>
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <p className="py-5 my-5 text-black-50 fs-22 line-height-25 text-center">
                  No data found
                </p>
              )}
              <div className="mt-5">
                {pageMeta.pageCount !== undefined &&
                  pageMeta.pageCount !== 1 && (
                    <Pagination
                      handleChange={this.handlePagination}
                      pageMeta={pageMeta}
                    />
                  )}
              </div>
            </div>
            {/* <div className="col-5 p-4 pt-5 mt-4">
              <div className="d-flex align-items-center justify-content-center flex-column">
                <div>
                  <img className="mb-4" src={faqProfile} alt="fqa" />
                </div>
                <div className="fw-500 fs-36 mb-3">ASK US</div>
                <p className="mx-4 text-center">
                  {
                    "Any queries related to FPO Gateway and other services will be answered here."
                  }
                </p>
                <div className="text-start w-100">
                  <p className="fw-600">Search Keyword</p>
                  <div className="row margin-top-fix">
                    <div className="col-12 p-0">
                      <NormalInput
                        type="text"
                        onChange={this.handleInput}
                        value={searchValue}
                        name="searchValue"
                        isFullWidth={true}
                        placeholder="Enter Here"
                      />
                    </div>
                  </div>
                </div>
                <div className="mt-5">
                  <NormalButton
                    label="Search"
                    mainbg={true}
                    className="mx-auto fs-18 line-height-26 px-4 py-2"
                    onClick={() => {
                      this.handleFaqs(1);
                    }}
                  />
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getAllFaqs,
      getFaqAndHeaderCount,
    },
    dispatch
  );
};

let component = FaqViewClass;

export const FaqView = connect(null, mapDispatchToProps)(component);
