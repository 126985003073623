import React, { Component } from "react";
import { Navbar } from "component/common";
import { MembersTab } from "component/Members";
import { MembersMappingView } from "component/Members/MembersMappingView";
import { connect } from "react-redux";
import { getAccessdata } from "service/helperFunctions";
import { UserAuth } from "helpers/UserAuth";
export class MemberMappingClass extends Component {
  render() {
    let { accessData } = this.props;
    let permissionData = getAccessdata(accessData, "membership");

    let {
      edit = false,
      createAccess = false,
      viewAccess = false,
    } = permissionData;

    return (
      <React.Fragment>
        <div className="inner-header">
          {/* <Navbar /> */}
          <div className="h-100 w-100">
            <p className="d-flex justify-content-center align-items-center h-100 w-100 text-white fs-32 font-weight-bold">
              Member Engagement
            </p>
          </div>
        </div>
        <MembersTab
          createAccess={createAccess}
          id={this.props.match.params.id}
          match={this.props.match}
        />
        <MembersMappingView
          edit={edit}
          viewAccess={viewAccess}
          createAccess={createAccess}
          id={this.props.match.params.id}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  accessData: state.home.accessData,
});

let component = UserAuth(MemberMappingClass, { service: "membership" });

export const MemberMapping = connect(mapStateToProps, null)(component);
