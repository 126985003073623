import React, { Component } from "react";
import SearchCard from "./SearchCard";
import SearchStrip from "./SearchStrip";
import SearchMap from "./SearchMap";
import { getAllFpoUser } from "action/SearchAct";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Row, Col } from "reactstrap";

export class SearchListClass extends Component {
  constructor(props) {
    super();
    this.state = {
      searchResults: [],
      pageMeta: {},
      center: {
        lat: 0,
        lng: 0,
      },
      mapData: [],
    };
  }

  // handle Change
  handleChange = (val, name) => {
    var { mapData } = this.state;
    this.props
      .getAllFpoUser({
        search: name ? name : this.props.fpoName ? this.props.fpoName : "",
      })
      .then((data) => {
        let dataSet = [];
        var lat = 0;
        var lng = 0;
        data.map((result) => {
          dataSet.push({
            isLoginedInUser: result.isUserLoggedIn,
            fpoId: result.fpoId,
            profile: result.fpoLogoURL,
            ComapanyName: result.fpoName,
            location: `${
              result.districtNames
                ? `${result.districtNames.districtName}, `
                : "-"
            } ${result.stateMaster ? result.stateMaster.stateName : ""} `,

            crops:
              result.cropMapping.length !== 0
                ? "" +
                  result.cropMapping.map((crop) => crop.cropsDetails.cropName)
                : "",
            lat: result.fpolatlong !== null ? result.fpolatlong.lat : null,
            lng: result.fpolatlong !== null ? result.fpolatlong.lon : null,
          });
          lat += result.fpolatlong !== null ? result.fpolatlong.lat : null;
          lng += result.fpolatlong !== null ? result.fpolatlong.lon : null;
        });
        mapData = dataSet.filter((data) => {
          return data.lat !== null;
        });
        this.setState({
          searchResults: dataSet,
          center: {
            lat: lat / mapData.length,
            lng: lng / mapData.length,
          },
          mapData,
        });
      });
  };

  //life cycles
  componentDidMount() {
    this.handleChange(1, this.props.fpoName);
  }
  componentWillReceiveProps(prevProps) {
    prevProps.fpoName !== this.props.fpoName &&
      this.handleChange(1, prevProps.fpoName);
  }
  render() {
    const { searchResults, center, mapData } = this.state;
    return (
      <div className="mx-auto search-list">
        <div className="container-sa">
          <Row>
            <Col sm="12">
              {this.props.view === "3" && (
                <div className=" clear-top">
                  <div className="container-sa">
                    <div className="row">
                      {searchResults.map((searchResult) => {
                        return (
                          <div className="col-md-3 col-sm-5 col-8 mb-4">
                            <SearchCard payload={searchResult} />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              )}
              {this.props.view === "2" && (
                <div className="clear-top">
                  {searchResults.map((searchResult) => {
                    return (
                      <div className="mb-4 mx-auto">
                        <SearchStrip payload={searchResult} />
                      </div>
                    );
                  })}
                </div>
              )}
              {this.props.view === "1" && (
                <div className="clear-top-map">
                  <div className="mx-auto">
                    <SearchMap payload={mapData} center={center} />
                  </div>
                </div>
              )}
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getAllFpoUser,
    },
    dispatch
  );
};
let component = SearchListClass;

export const SearchList = connect(null, mapDispatchToProps)(component);
