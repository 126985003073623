import React, { Component } from "react";
import SimpleReactValidator from "simple-react-validator";
import "./samunnati.scss";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { samunnatiRoles, samunnatiReg } from "action/RegAct";
import { Toast } from "service/toast";
import { encryptData } from "service/helperFunctions";
import { PasswordStrengthMeter } from "component/common";
import CheckBox from "component/common/CheckBox";

class Samunnati extends Component {
  constructor(props) {
    super();
    this.state = {
      contact_name: "",
      job_title: "",
      team_name: "Relationship Manager",
      organization_email_id: "",
      password: "",
      confirm_password: "",
      contact_number: "",
      roles: [],
      userRoleId: "2",
      passwordVisible: false,
      reEnterPasswordVisible: false,
      isPrivacy: false,
    };
  }

  //validation
  validator = new SimpleReactValidator({
    element: (message) => (
      <span className="error-message text-danger fs-14">{message}</span>
    ),
    autoForceUpdate: this,
    validators: {
      password: {
        message: "The :attribute must be a valid format.",
        rule: (val, params, validator) => {
          return (
            validator.helpers.testRegex(
              val,
              /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$^+=!*()@%&]).{8,20}$/
            ) && params.indexOf(val) === -1
          );
        },
        messageReplace: (message, params) =>
          message.replace("", this.helpers.toSentence(params)),
        required: true,
      },
      checkPassword: {
        message: "Given :attribute does not match",
        rule: function (val, params) {
          return val === params[0];
        },
      },
    },
  });

  handleChange = ({ target: { name, value } }) => {
    this.setState({
      [name]: value,
    });
  };

  //handle role change
  handleRoleChange = ({ target: { name, value } }) => {
    this.setState({
      team_name: name,
      [name]: value,
    });
  };

  toggle = (key) => {
    this.setState((prevState) => ({
      [key]: !prevState[key],
    }));
  };

  //handle submit
  handleSubmit = (e) => {
    e.preventDefault();
    if (this.validator.allValid()) {
      if (this.state.password === this.state.confirm_password) {
        const {
          contact_name,
          job_title,
          team_name,
          organization_email_id,
          password,
          contact_number,
          userRoleId,
          isPrivacy,
        } = this.state;
        let { backToLogi } = this.props;
        if (isPrivacy) {
          this.props
            .samunnatiReg({
              userRoleId: userRoleId,
              contactName: contact_name,
              jobTitle: job_title,
              teamName: team_name,
              emailId: organization_email_id,
              password: encryptData(password),
              mobileNumber: contact_number,
              isPrivacy: isPrivacy,
            })
            .then(() => {
              backToLogi(true);
            });
        } else {
          Toast({
            type: "error",
            message: "Please Accept Terms & Conditions and Privacy Policy",
            time: 5000,
          });
        }
      } else {
        let message = "Password does not match";
        Toast({ type: "error", message, time: 5000 });
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };
  //lifecycles
  componentDidMount() {
    this.props.samunnatiRoles().then((data) => {
      this.setState({
        roles: data,
      });
    });
  }
  render() {
    const {
      contact_name,
      job_title,
      organization_email_id,
      password,
      confirm_password,
      contact_number,
      roles,
      userRoleId,
      passwordVisible,
      reEnterPasswordVisible,
      isPrivacy,
    } = this.state;
    return (
      <form
        autocomplete="off"
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <div className="d-flex flex-row mb-2 flex-column registration-area mt-3">
          <div className="row mb-2">
            <div className="col-12">
              <label>
                {"Contact Person name "}
                <span className="text-danger font-weight-bold">*</span>
              </label>
              <input
                type="text"
                className="form-control w-100"
                placeholder=""
                name="contact_name"
                value={contact_name}
                onChange={this.handleChange}
                // onBlur={() => this.validator.showMessageFor('contact_name')}
              />
              {this.validator.message(
                "contact_name",
                contact_name,
                "required|alpha_space|min:3|max:30"
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-12 my-2">
              <label>Job title</label>
              <input
                type="text"
                className="form-control w-100"
                name="job_title"
                value={job_title}
                onChange={this.handleChange}
              />
              {this.validator.message("job tile", job_title, "max:30")}
            </div>
            <div className="col-md-6 col-12 my-2">
              <label>Team name</label>
              <select
                className="custom-select form-control "
                name="userRoleId"
                value={userRoleId}
                onChange={this.handleRoleChange}
              >
                <option value="" disabled defaultValue>
                  {"Select"}
                </option>
                {roles.map((role, index) => {
                  return (
                    <option
                      key={index}
                      name={role.userRoleName}
                      value={role.userRoleId}
                    >
                      {role.userRoleName}
                    </option>
                  );
                })}
              </select>
              {this.validator.message("userRoleId", userRoleId, "required")}
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-12 my-2">
              <label>
                Contact number
                <span className="text-danger font-weight-bold">*</span>
              </label>
              <input
                type="text"
                className="form-control w-100"
                name="contact_number"
                value={contact_number}
                onChange={this.handleChange}
                maxLength={10}
                autoComplete="none"
              />
              {this.validator.message(
                "contact_number",
                contact_number,
                "required|phone"
              )}
            </div>
            <div className="col-md-6 col-12 my-2">
              <label>
                Organization email id
                <span className="text-danger font-weight-bold">*</span>
              </label>
              <input
                type="text"
                className="form-control w-100"
                name="organization_email_id"
                value={organization_email_id}
                onChange={this.handleChange}
              />
              {this.validator.message(
                "organization email id",
                organization_email_id,
                "required|email"
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-12 my-2">
              <label>
                {"Password "}
                <span className="text-danger font-weight-bold">*</span>
              </label>
              <div className="input-group">
                <input
                  type={passwordVisible ? "text" : "password"}
                  className="form-control w-100"
                  name="password"
                  value={password}
                  onChange={this.handleChange}
                />
                <div className="input-group-addon right">
                  <span
                    onClick={(e) => this.toggle("passwordVisible")}
                    className={`cursor-pointer icon-${
                      passwordVisible ? "eye" : "eye-hide"
                    } fs-24`}
                  ></span>
                </div>
                {this.validator.message(
                  "password",
                  password,
                  "required|min:7|max:15|password"
                )}
                <PasswordStrengthMeter data={password} />
              </div>
            </div>
            <div className="col-md-6 col-12 my-2">
              <label>
                {"Confirm password "}
                <span className="text-danger font-weight-bold">*</span>
              </label>
              <div className="input-group">
                <input
                  type={reEnterPasswordVisible ? "text" : "password"}
                  className="form-control w-100"
                  name="confirm_password"
                  value={confirm_password}
                  onChange={this.handleChange}
                />
                <div className="input-group-addon right">
                  <span
                    onClick={(e) => this.toggle("reEnterPasswordVisible")}
                    className={`cursor-pointer icon-${
                      reEnterPasswordVisible ? "eye" : "eye-hide"
                    } fs-24`}
                  ></span>
                </div>
                {this.validator.message(
                  "confirm password",
                  confirm_password,
                  `required|checkPassword:${password}`
                )}
              </div>
            </div>
            {password && (
              <div className="col-md-12">
                <b className="fs-14">
                  Only the following special characters are allowed :
                  #$^+=!*()@%&amp;
                </b>
              </div>
            )}
          </div>
          <div className="d-flex justify-content-center mt-4">
            <CheckBox
              label="I agree to the Terms & Conditions and Privacy Policy"
              onChange={this.handleChange}
              name="isPrivacy"
              isChecked={isPrivacy}
            />
          </div>
          <div className="d-flex justify-content-center mt-4">
            <input
              className="btn w-75 py-2 font-weight-bold"
              type="submit"
              value="Submit"
              onClick={this.handleSubmit}
            />
          </div>
        </div>
      </form>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      samunnatiReg,
      samunnatiRoles,
    },
    dispatch
  );
};
let component = Samunnati;

export const SamunnatiReg = connect(null, mapDispatchToProps)(component);
