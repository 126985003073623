import React, { Component } from 'react';
import { BodTab } from '../BodTab';
export class BodList extends Component {
   render() {
      let { editAccess = false } = this.props;
      return (
         <div className='d-flex flex-wrap mt-3 px-0 justify-content-center'>
            <BodTab
               activeTab={this.props.activeTab}
               view={this.props.view}
               mainactiveTab={this.props.mainactiveTab}
               limit={this.props.limit}
               editAccess={editAccess}
               id={this.props.id}
            />
         </div>
      );
   }
}
