import React, { Component } from "react";
import SimpleReactValidator from "simple-react-validator";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Toast } from "service/toast";

export class FpoRegStepThreeClass extends Component {
  validator = new SimpleReactValidator({
    element: (message) => (
      <span className="error-message text-danger fs-14">{message}</span>
    ),
    autoForceUpdate: this,
  });

  //handle submit
  handleSubmit = (e) => {
    e.preventDefault();
    let { formFieldsFPO } = this.props;
    let {
      selected_business_activties,
      selected_crops,
      selected_infrastructure,
      selected_license,
    } = formFieldsFPO;
    if (
      selected_business_activties.length !== 0 &&
      selected_crops.length !== 0 &&
      selected_infrastructure.length !== 0 &&
      selected_license.length !== 0
    ) {
      this.props.handleSubmit(e);
    } else {
      if (selected_business_activties.length === 0) {
        Toast({
          type: "error",
          message: "Please select atleast one business activity",
        });
      } else if (selected_crops.length === 0) {
        Toast({
          type: "error",
          message: "Please select atleast one crop",
        });
      } else if (selected_infrastructure.length === 0) {
        Toast({
          type: "error",
          message: "Please select atleast one infrastructure",
        });
      } else if (selected_license.length === 0) {
        Toast({
          type: "error",
          message: "Please select atleast one license",
        });
      }
    }
  };

  render() {
    const {
      formFieldsFPO,
      handlePageChange,
      business_activties,
      crops_list,
      handlebusinessActivties,
      handlecrops,
      license_list,
      infrastructure_list,
      handleInfrasture,
      handleLicense,
      handleCropVariety,
    } = this.props;

    let {
      selected_business_activties = [],
      selected_crops = [],
      selected_infrastructure = [],
      selected_license = [],
    } = formFieldsFPO;

    return (
      <div>
        <div className="row mb-3">
          <div className="col-12">
            <p className="text-black-1c fw-600 mb-0 text-center fs-18">
              Step 3 of 3
            </p>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-12">
            <div className="about-us-selection-area mb-3">
              Business activities{" "}
              <span className="text-danger font-weight-bold">*</span>
            </div>
            <div className="flex-wrap d-flex">
              {business_activties.map((activity, index) => {
                return (
                  <div
                    key={index}
                    className={`d-flex flex-column  mr-md-2 mr-1 mb-2 ${
                      selected_business_activties.find((s_crop) => {
                        return s_crop === activity.businessId;
                      }) !== undefined
                        ? "activity-selected"
                        : "activity-container"
                    } `}
                    onClick={() => {
                      handlebusinessActivties(activity.businessId);
                    }}
                  >
                    <img
                      className="crops-container-img"
                      src={activity.businessIconUrl}
                      alt="crops"
                    />
                    <p className="mb-0 fs-10 text-green-18 text-center mt-1">
                      {activity.businessName}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-12">
            <div className="about-us-selection-area mb-3">
              Crops <span className="text-danger font-weight-bold">*</span>
            </div>
            <div className="flex-wrap d-flex">
              {crops_list.map((crop, index) => {
                return (
                  <div
                    key={index}
                    className={`d-flex flex-column  mr-md-2 mr-1 mb-2 ${
                      selected_crops.find((s_crop) => {
                        return s_crop === crop.cropId;
                      }) !== undefined
                        ? "activity-selected"
                        : "activity-container"
                    } `}
                  >
                    <div
                      onClick={() => {
                        handlecrops(crop.cropId);
                      }}
                    >
                      <img
                        className="crops-container-img"
                        src={crop.cropIconUrl}
                        alt="crops"
                      />
                      <p className="mb-0 fs-10 text-green-18 text-center mt-1">
                        {crop.cropName}
                      </p>
                    </div>
                    <div>
                      <input
                        placeholder="variety 1"
                        name="varietyOne"
                        onChange={handleCropVariety.bind(this, crop.cropId)}
                      />
                      <input
                        placeholder="variety 2"
                        name="varietyTwo"
                        onChange={handleCropVariety.bind(this, crop.cropId)}
                      />
                      <input
                        placeholder="variety 3"
                        name="varietyThree"
                        onChange={handleCropVariety.bind(this, crop.cropId)}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-12">
            <div className="about-us-selection-area mb-3">
              Infrastructure
              <span className="text-danger font-weight-bold">*</span>
            </div>
            <div className="flex-wrap d-flex">
              {infrastructure_list.map((crop, index) => {
                return (
                  <div
                    key={index}
                    className={`d-flex flex-column mr-md-2 mr-1 mb-2 ${
                      selected_infrastructure.find((s_crop) => {
                        return s_crop === crop.infrastructureId;
                      }) !== undefined
                        ? "activity-selected"
                        : "activity-container"
                    } `}
                    onClick={() => {
                      handleInfrasture(crop.infrastructureId);
                    }}
                  >
                    <img
                      className="crops-container-img"
                      src={crop.InfrastructureIconUrl}
                      alt="crops"
                    />
                    <p className="mb-0 fs-10 text-green-18 text-center mt-1">
                      {crop.infrastructureName}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <div className="row mb-3">
          <div className="col-12">
            <div className="about-us-selection-area mb-3">
              License
              <span className="text-danger font-weight-bold">*</span>
            </div>
            <div className="flex-wrap d-flex">
              {license_list.map((crop, index) => {
                return (
                  <div
                    key={index}
                    className={`d-flex flex-column  mr-md-2 mr-1 mb-2 ${
                      selected_license.find((s_crop) => {
                        return s_crop === crop.id;
                      }) !== undefined
                        ? "activity-selected"
                        : "activity-container"
                    } `}
                    onClick={() => {
                      handleLicense(crop.id);
                    }}
                  >
                    <img
                      className="crops-container-img"
                      src={crop.licenseIconUrl}
                      alt="crops"
                    />
                    <p className="mb-0 fs-10 text-green-18 text-center mt-1">
                      {crop.licenseName}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <div className="d-flex justify-content-center mt-4">
          <input
            className="btn w-75 py-2 font-weight-bold"
            type="submit"
            value="Submit"
            onClick={this.handleSubmit}
          />
        </div>
        <div className="d-flex justify-content-center">
          <button
            className="back-button btn"
            onClick={() => handlePageChange("step2")}
          >
            {"< Back"}
          </button>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};
let component = FpoRegStepThreeClass;

export const FpoRegStepThree = connect(null, mapDispatchToProps)(component);
