import React, { Component } from "react";
import { UploadComponent } from "../UploadComponent";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  addFpoDocuments,
  viewFpoDocuments,
  deleteFpoDocuments,
  getstatutoryBulkdownload,
  statutoryEdit,
} from "action/DocumentAct";
import { getAccessdata } from "service/helperFunctions";
import { CommonPageLoader, ConfirmationPopup } from "component/common";
import { getDocumentsOfFPO, getstatutoryBulkdownloadOfFpo } from "action/RmAct";
import downloadIcon from "assets/images/icon/download-icon.svg";
const docIcon = require("../../../assets/svg/Documents/docIcon.png");
class StatutoryDoc extends Component {
  state = {
    documentsData: [],
    isLoading: false,
    bulkDownloadLink: "",
    deleteId: null,
  };

  //upload document
  handleUpload = (body) => {
    let { id, fileName, file, reportName } = body;
    this.props
      .addFpoDocuments(
        {
          statutoryDocumentId: id,
          documentName: fileName,
          documentPath: file,
          reportName: reportName,
        },
        "statutory"
      )
      .then(() => {
        this._getStatutoryDocuments();
      });
  };

  //Edit Document
  handleEditOption = (body) => {
    let { id, reportName } = body;
    this.props.statutoryEdit({ id: id, reportName: reportName }).then(() => {
      this._getStatutoryDocuments();
    });
  };

  //Delete document
  handleDelete = () => {
    let { deleteId } = this.state;
    this.props
      .deleteFpoDocuments(
        {
          id: deleteId,
        },
        "statutory"
      )
      .then(() => {
        this._getStatutoryDocuments();
      });
  };

  toggleDelete = (id) => {
    this.setState({
      deleteId: id,
    });
  };

  //Life cycles
  componentDidMount() {
    let { activeTab } = this.props;
    if (activeTab == 2) {
      this._getStatutoryDocuments();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      if (this.props.activeTab == 2) {
        this._getStatutoryDocuments();
      }
    }
  }

  _getStatutoryDocuments = () => {
    this.setState({ isLoading: true });
    let { id } = this.props;
    if (id) {
      this.props.getDocumentsOfFPO(id, "statutory").then((data) => {
        this._setData(data);
      });
    } else {
      this.props.viewFpoDocuments("statutory").then((data) => {
        this._setData(data);
      });
    }
  };

  _setData = (data) => {
    let statutoryData = [];
    data.map((list) => {
      statutoryData.push({
        id: list.statutoryDocumentId,
        title: list.documentName,
        body: list.description,
        documents: list.statutoryLicenses,
        documentType: list.documentType,
      });
    });
    this.setState({
      documentsData: statutoryData,
      isLoading: false,
    });
  };

  getCsvUrl = () => {
    let { getstatutoryBulkdownload, id, getstatutoryBulkdownloadOfFpo } =
      this.props;
    if (id) {
      let body = {
        fpoId: id,
      };
      getstatutoryBulkdownloadOfFpo(body).then((data) => {
        this._downloadData(data);
      });
    } else {
      let body = {
        id: null,
      };
      getstatutoryBulkdownload(body).then((data) => {
        this._downloadData(data);
      });
    }
  };

  _downloadData = (data) => {
    this.setState(
      {
        bulkDownloadLink:
          "https://portal.fpogateway.com/fpomgmt/api/v1/download/zip?zipFileName=" +
          data.zipFileName,
      },
      () => {
        document.getElementById("csv-download").click();
        this.setState({ bulkDownloadLink: "" });
      }
    );
  };

  render() {
    let { documentsData, isLoading, bulkDownloadLink, deleteId } = this.state;
    let { accessData, id } = this.props;
    let permissionData = getAccessdata(accessData, "fpoDocuments");

    let { download = false } = permissionData;
    return (
      <div className="d-flex flex-column">
        {download && (
          <div className="container-sa">
            <div className="d-flex justify-content-end">
              {bulkDownloadLink === "" ? (
                <span
                  className="text-black-50 font-weight-bold fs-18 text-underline cursor-pointer pr-md-5 pr-0 mr-md-3 mr-0"
                  onClick={() => this.getCsvUrl()}
                >
                  <img
                    src={downloadIcon}
                    className="mr-2"
                    height="20"
                    width="20"
                    alt="downloadIcon"
                  />
                  {"Download all"}
                </span>
              ) : (
                <a
                  id="csv-download"
                  className="text-black-50 font-weight-bold fs-18 text-underline cursor-pointer pr-md-5 pr-0 mr-md-3 mr-0"
                  href={bulkDownloadLink}
                  download
                >
                  <img
                    src={downloadIcon}
                    className="mr-2"
                    height="20"
                    alt="downloadIcon"
                    width="20"
                  />
                  {"Download all"}
                </a>
              )}
            </div>
          </div>
        )}
        <div className="container-sa">
          {!isLoading ? (
            <div className="row">
              {documentsData.map((documentDetail, index) => {
                return (
                  <div className="col-md-3 d-flex align-items-end" key={index}>
                    <div className="document-card-margin">
                      <div className="document-titleBlock">
                        <div className="d-flex align-items-center mb-1">
                          <img
                            className="mr-3"
                            src={docIcon}
                            height="30"
                            width="30"
                            alt="docIcon.svg"
                          />
                          <div className="document-title">
                            {documentDetail.title}
                          </div>
                        </div>
                        <div className="document-body mb-1">
                          {documentDetail.body}
                        </div>
                      </div>
                      <div>
                        <UploadComponent
                          documentTitle={documentDetail.title}
                          title={"Statutory Licenses"}
                          documentDetail={documentDetail}
                          handleUpload={this.handleUpload}
                          handleEditOption={this.handleEditOption}
                          handleDelete={this.toggleDelete}
                          id={documentDetail.id}
                          documents={documentDetail.documents}
                          activeTab={this.props.activeTab}
                          fpoId={id}
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <>
              {/* Loader */}
              <CommonPageLoader />
            </>
          )}
          <ConfirmationPopup
            isOpen={deleteId !== null ? true : false}
            toggle={() => this.toggleDelete(null)}
            onSubmit={this.handleDelete}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  accessData: state.home.accessData,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      addFpoDocuments,
      viewFpoDocuments,
      deleteFpoDocuments,
      getstatutoryBulkdownload,
      getDocumentsOfFPO,
      getstatutoryBulkdownloadOfFpo,
      statutoryEdit,
    },
    dispatch
  );
};
let component = StatutoryDoc;

export const Statutory = connect(
  mapStateToProps,
  mapDispatchToProps
)(component);
