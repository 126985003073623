import { fpoSearch } from 'service/apiVariables';
import { Toast } from 'service/toast';
import { api } from 'service/api';
import { addQuery } from 'service/helperFunctions';

//Get FPO Search Results API
export const getAllFpoUser = (query) => (dispatch, getState) => {
   return new Promise((resolve, reject) => {
      addQuery(query, fpoSearch.getAllFpoUser);
      api({ ...fpoSearch.getAllFpoUser })
         .then(({ data }) => {
            resolve(data);
         })
         .catch(({ message }) => {
            reject(Toast({ type: 'error', message }));
         });
   });
};
