import React, { Component } from "react";
import "./viewmember.scss";
import { MemberProfile } from "./MemberProfile";
import { MemberAssets } from "./MemberAssets";
import { Farm } from "./Farm";
import { LiveStock } from "./LiveStock";
import { Bank } from "./Bank";
import { getMember } from "action/MemberAct";

import { CommonPageLoader } from "component/common";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { history } from "service/helpers/index";
const arrow_left = require("../../../assets/svg/add_member/arrow_left.svg");
export class ViewMembershipClass extends Component {
  state = {
    memberDetail: {},
    isloading: false,
  };
  //Lifecycles
  componentDidMount() {
    let { id, getMember } = this.props;
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    this.setState({ isloading: true });
    getMember({ id }).then((data) => {
      this.setState({ memberDetail: data, isloading: false });
    });
  }

  render() {
    let { memberDetail = {}, isloading } = this.state;

    let {
      memberasset = [],
      memberfarminfo = {},
      memberLiveStockInfo = [],
      memberBankAccount = [],
    } = memberDetail;

    return (
      <div className="">
        <div className="d-flex my-3 container-sa px-0">
          <div onClick={() => history.goBack()} className="d-flex">
            <img className="cursor-pointer" src={arrow_left} alt="back" />
            <p className="fs-18 font-weight-bold font-Gilroy text-black mb-0 cursor-pointer ml-3">
              {"Member detail page"}
            </p>
          </div>
        </div>
        {!isloading ? (
          <div className="bg-area">
            <div className="container-sa">
              <MemberProfile memberDetail={memberDetail} />
              <div className="member-assets">
                <div className="row">
                  {memberfarminfo ? (
                    <div className="col-md-4">
                      <Farm memebrFarmInfo={memberfarminfo} />
                    </div>
                  ) : (
                    ""
                  )}
                  {memberLiveStockInfo.length !== 0 ? (
                    <div className="col-md-4 mt-md-0 mt-4">
                      <LiveStock liveStock={memberDetail.memberLiveStockInfo} />
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="col-md-4 mt-md-0 mt-4">
                    {memberBankAccount.length !== 0 ? (
                      <Bank
                        memberBankAccount={memberDetail.memberBankAccount[0]}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              {memberasset.length !== 0 ? (
                <MemberAssets memberAsset={memberasset} />
              ) : (
                ""
              )}
            </div>
          </div>
        ) : (
          <>
            {/* Loader */}
            <CommonPageLoader />
          </>
        )}
        <div className="view-members"></div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getMember,
    },
    dispatch
  );
};
let component = ViewMembershipClass;

export const ViewMembership = connect(null, mapDispatchToProps)(component);
