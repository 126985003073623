import React, { Component } from 'react';
import './style.scss';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import arrow_left from 'assets/svg/add_member/arrow_left.svg';
import { history } from "service/helpers"
import { NormalButton, CommonPageLoader } from "component/common"
import nav_user from 'assets/images/default-profile-img.png';
import { CreateCapacityBuildingPopup } from "../CreateCapacityBuildingPopup";
import { debounceFunction } from 'service/debounce';
import {
    getCapacityBuildingList
} from "action/RmAct";
import moment from "moment";
import { Pagination } from 'component/Faq/FaqView/pagination';

export class CapacityBuildingClass extends Component {

    state = {
        isPopupOpen: false,
        searchValue: '',
        capacityList: [],
        pageMeta: {},
        isLoading: false
    };

    //life cycles
    componentDidMount() {
        this._getCapacityBuildingList(1, '')
    }

    //get list data
    _getCapacityBuildingList = (page, searchValue) => {
        let { getCapacityBuildingList } = this.props
        this.setState({ isLoading: true })
        getCapacityBuildingList({ page, search: searchValue }).then((data) => {
            this.setState({
                capacityList: data.results,
                pageMeta: data.pageMeta,
                isLoading: false
            })
        })
    }

    handleToggle = (name) => {
        this.setState((prevState) => ({
            [name]: !prevState[name]
        }));
    };

    handleSearch = ({ target: { value } }) => {
        //Debounce Function
        debounceFunction(() => this.SearchFunction(value), 1000);
    }

    SearchFunction = (searchValue) => {
        //debounce function
        this._getCapacityBuildingList(1, searchValue)
        this.setState({ searchValue });
    };

    //handle pagination
    handlePagination = (page) => {
        this._getCapacityBuildingList(page, '')
    }

    render() {
        let { isPopupOpen, capacityList, isLoading, pageMeta } = this.state

        return (
            <div className="capacity-building">
                <div className='d-flex my-3 container-sa px-0'>
                    <div className="d-flex justify-content-between align-items-center w-100">
                        <div className='d-flex align-items-center' onClick={() => history.goBack()}>
                            <img className='cursor-pointer' src={arrow_left} alt='back' />
                            <p className='fs-18 font-weight-bold text-black mb-0 cursor-pointer ml-3'>
                                {' Capacity building'}
                            </p>
                        </div>
                        <div >
                            <NormalButton
                                label={'Create'}
                                mainbg={true}
                                className='px-5 py-2 fs-14 line-height-26'
                                onClick={() => this.handleToggle('isPopupOpen')}
                            />
                        </div>
                    </div>
                </div>
                <div className="capacity-building-content bg-blue-f9">
                    <div className='container-sa px-0'>
                        <div className="my-3 w-100">
                            <div className='col-3 px-0 search-area ml-auto'>
                                <input
                                    type-='text'
                                    className='form-control form-control-sa'
                                    placeholder='Search'
                                    onChange={(e) => this.handleSearch(e)}
                                />
                            </div>
                        </div>
                        {isLoading ?
                            <CommonPageLoader />
                            : capacityList.length !== 0 ?
                                <>
                                    {capacityList.map((list) => {
                                        let {
                                            advisoryService = '',
                                            date = '',
                                            noOfAttendees = 0,
                                            fpoDetails = {}
                                        } = list
                                        return (

                                            <div className="capacity-building-card mb-3">
                                                <div className="d-flex w-100 h-100 justify-content-center align-items-center">
                                                    <div className="col-1">
                                                        <img
                                                            className='rounded-circle profile'
                                                            src={fpoDetails && fpoDetails.fpoLogoURL ? fpoDetails.fpoLogoURL : nav_user}
                                                            alt='user'
                                                        />
                                                    </div>
                                                    <div className="col-4 my-auto h-100">
                                                        <p className="fs-18 fw-700 line-height-23 text-black-1c mb-1">
                                                            {fpoDetails && fpoDetails.fpoName ? fpoDetails.fpoName : '-'}
                                                        </p>
                                                        <p className="fs-16 fw-500 line-height-19 text-black-50 mb-0">FPO Name</p>
                                                    </div>
                                                    <div className="col-3 my-auto h-100">
                                                        <p className="fs-18 fw-700 line-height-23 text-black-1c mb-1">{advisoryService}</p>
                                                        <p className="fs-16 fw-500 line-height-19 text-black-50 mb-0">Type of advisory service</p>
                                                    </div>
                                                    <div className="col-2 my-auto h-100">
                                                        <p className="fs-18 fw-700 line-height-23 text-black-1c mb-1">{noOfAttendees}</p>
                                                        <p className="fs-16 fw-500 line-height-19 text-black-50 mb-0">No. of attendees </p>
                                                    </div>
                                                    <div className="col-2 my-auto h-100">
                                                        <p className="fs-18 fw-700 line-height-23 text-black-1c mb-1">
                                                            {date !== null ? moment(date).format('DD MMM YYYY') : '-'}
                                                        </p>
                                                        <p className="fs-16 fw-500 line-height-19 text-black-50 mb-0">Date </p>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                    <Pagination
                                        handleChange={this.handlePagination}
                                        pageMeta={pageMeta}
                                    />
                                </>
                                :
                                <p className="py-5 my-5 text-black-50 fs-22 line-height-25 text-center">No data found !!!</p>
                        }
                    </div>
                </div>
                <CreateCapacityBuildingPopup
                    isOpen={isPopupOpen}
                    toggle={() => this.handleToggle('isPopupOpen')}
                    handleChange={() => this._getCapacityBuildingList(1, '')}
                />
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getCapacityBuildingList
        },
        dispatch
    );
};

let component = CapacityBuildingClass;

export const CapacityBuildingComp = connect(null, mapDispatchToProps)(component);
