import React from "react";

import "./style.scss";

const CrpCard = ({
  name,
  emailId,
  mobileNumber,
  crpId,
  isMapping,
  editAccess,
  onEdit,
  onView,
  figMappedCount = 0,
}) => {
  return (
    <div className="crp_card">
      {/* <div className="crp_detail">
        <span>CRP ID : &nbsp;</span>
        <b>{crpId}</b>
      </div> */}
      <div className="crp_detail">
        <span>Name : &nbsp;</span>
        <b>{name}</b>
      </div>
      <div className="crp_detail">
        <span>Email ID : &nbsp;</span>
        <b>{emailId}</b>
      </div>
      <div className="crp_detail">
        <span>Mobile Number : &nbsp;</span>
        <b>{mobileNumber}</b>
      </div>
      {isMapping && (
        <div className="crp_detail">
          <span>FIG Mapped : &nbsp;</span>
          <b>{figMappedCount}</b>
        </div>
      )}
      {isMapping && (
        <div className="d-flex justify-content-between">
          <div
            className="mr-4 cursor-pointer fs-16 fw-600 text-black-50"
            onClick={() => onView(crpId)}
          >
            View
          </div>
          {editAccess && (
            <div
              className="fs-16 text-black-50 fw-600 cursor-pointer"
              onClick={() => onEdit(crpId)}
            >
              Map FIG's
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default CrpCard;
