import React, { Component } from "react";
import { Modal, ModalBody } from "reactstrap";
import CheckBox from "component/common/CheckBox";
import SimpleReactValidator from "simple-react-validator";
import { termsandPolicyLinks } from "service/helperFunctions";
import "./style.scss";

export class TermsConditionPopup extends Component {
  //validation
  validator = new SimpleReactValidator({
    element: (message) => (
      <span className="error-message text-danger fs-14">{message}</span>
    ),
    autoForceUpdate: this,
  });

  onAgree = () => {
    let { onSubmit } = this.props;
    if (this.validator.allValid()) {
      onSubmit();
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  render() {
    let { isOpen, toggle, onChange, terms } = this.props;

    return (
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        centered={true}
        backdrop="static"
        keyboard={false}
        className="termsCondition-modal"
      >
        <ModalBody>
          <div className="termsCondition-popup">
            <div className="mb-3">
              <h1>
                Please note that there has been a change to the{" "}
                <span className="fw-600">Terms & Conditions</span> and{" "}
                <span className="fw-600">Privacy Policy</span>. Read the updated
                content by clicking the below links and accept the same to
                proceed.
              </h1>
            </div>
            <div className="d-flex justify-content-center">
              <div>
                {termsandPolicyLinks(true)}
                <div className="d-flex flex-column mt-3">
                  <CheckBox
                    label="I agree to the Terms & Conditions and Privacy Policy"
                    onChange={onChange}
                    name="terms"
                    value={true}
                    isChecked={terms}
                    needLine={true}
                    hover={false}
                  />
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-center">
              <div>
                <button onClick={this.onAgree} className="proceed-btn mt-3">
                  Proceed
                </button>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    );
  }
}
