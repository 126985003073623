import React, { Component } from 'react';
import backIcon from '../../assets/images/icon/arrow-left.svg'
import { history } from 'service/helpers';
import { Banner } from '../../component/Acadamy';
import { BlogView } from '../../component/Blog';
import { CommonPageLoader } from "component/common"
import { blogDetailApi } from "action/Acadamy";
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
export class BlogHomeClass extends Component {

    state = {
        blogDetail: {},
        isLoading: false,
        viewCountforBlock: 0
    }
    //lifecycles
    componentDidMount = () => {
        this._getBlogDetail()
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }

    _getBlogDetail = () => {
        this.setState({ isLoading: true })

        this.props.blogDetailApi(this.props.match.params.id).then((data) => {
            this.setState({ blogDetail: data.blogList, viewCountforBlock: data.blogsCount, isLoading: false })
        })
    }


    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            this._getBlogDetail()
        }
    }

    render() {

        let { blogDetail, isLoading, viewCountforBlock } = this.state
        return (
            <>
                <Banner minSize={true} />
                <div className='d-flex container-sa my-3 '>
                    <div onClick={() => history.goBack()} className='d-flex cursor-pointer' >
                        <img
                            className='cursor-pointer'
                            src={backIcon}
                            alt='back'
                        />
                        <p className='ml-3 add-member-text-back mb-0'>
                            {'Courses'}
                        </p>
                    </div>
                </div>
                {isLoading ?
                    <>
                        {/* Loader */}
                        <CommonPageLoader />
                    </>
                    :
                    <BlogView
                        blogDetail={blogDetail}
                        handleChange={() => this._getBlogDetail()}
                        viewCountforBlock={viewCountforBlock}
                    />
                }
            </>
        );
    }
}
const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        blogDetailApi
    }, dispatch)
}

let component = BlogHomeClass;

export const BlogHome = connect(null, mapDispatchToProps)(component)
