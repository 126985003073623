import React, { Component } from "react";
import Joyride from "react-joyride";
import "./style.scss";

import { NormalButton } from "component/common";

import SimpleReactValidator from "simple-react-validator";

import { history } from "service/helpers/index";

import {
  getBulkUploadfile,
  addBulkUpload,
  getKYMBulkUploadfile,
  addKYMBulkUpload,
} from "action/BodAct";

// Redux Connection
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { currentTab } from "action/MemberAct";
import { getToolTipById, sendToolTipById } from "action/RoboCall";

const upload = require("../../../assets/svg/add_member/upload.svg");
const arrow_left = require("assets/svg/add_member/arrow_left.svg");
const downloadIcon = require("assets/svg/Members/download-icon.svg");

export class BulkuploadClass extends Component {
  state = {
    csvUrl: "",
    bulkFile: "",
    bulkFileName: "",
    type: "",
    run: false,
    isSkip: false,
    tooltipContent: [
      {
        target: ".tooltip-one",
        content: "Download the excel template from here",
        disableBeacon: true,
        isFixed: true,
      },
      {
        target: ".tooltip-two",
        content: "Attach the filled template here",
        disableBeacon: true,
        isFixed: true,
      },
      {
        target: ".tooltip-three",
        content: "Upload the attached template here",
        disableBeacon: true,
        isFixed: true,
      },
    ],
  };

  //vaidation
  validator = new SimpleReactValidator({
    element: (message) => (
      <span className="error-message text-danger fs-14">{message}</span>
    ),
    autoForceUpdate: this,
  });

  handleIdentityProofFile = (e) => {
    //upload identity proof
    let file = e.target.files[0];
    if (file !== undefined) {
      this.setState({ bulkFile: "" }, () => {
        let { bulkFile, bulkFileName } = this.state;
        document.getElementById("handleIdentityProofFile").value = null;
        bulkFile = file;
        bulkFileName = file.name;
        this.setState({
          bulkFile,
          bulkFileName,
        });
      });
    }
  };

  componentDidMount() {
    const queryParams = new URLSearchParams(window?.location?.search);
    const type = queryParams.get("type");
    this.setState({
      type: type,
    });
    if (type === "fpo") {
      this.props.getBulkUploadfile().then((path) => {
        this.setState({
          csvUrl: path,
        });
      });
    } else {
      this.props.getKYMBulkUploadfile().then((path) => {
        this.setState({
          csvUrl: path,
        });
      });
    }
    this.props
      .getToolTipById(JSON.parse(localStorage.getItem("fpoId")))
      .then((data) => {
        let filterData = [];
        filterData = data?.filter((i) =>Number(i?.toolTipId) === 6);
        setTimeout(() => {
          this.setState({
            run: filterData.length === 0 ? true : false,
          });
        }, 200);
      });
  }

  handleSubmit = () => {
    if (this.validator.allValid()) {
      let { bulkFile, type } = this.state;
      const formData = new FormData();
      formData.append("file", bulkFile);
      this.setState({ isDataUploading: true });
      if (type === "fpo") {
        this.props
          .addBulkUpload(formData)
          .then(() => {
            this.setState({
              isDataUploading: false,
              bulkFileName: "",
              bulkFile: "",
            });
            this.props.currentTab(1);
            history.goBack();
          })
          .catch(() => {
            this.setState({
              isDataUploading: false,
            });
          });
      } else {
        this.props
          .addKYMBulkUpload(formData)
          .then(() => {
            this.setState({
              isDataUploading: false,
              bulkFileName: "",
              bulkFile: "",
            });
            this.props.currentTab(1);
            history.goBack();
          })
          .catch(() => {
            this.setState({
              isDataUploading: false,
            });
          });
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  callback = (data) => {
    let { isSkip } = this.state;
    if (data?.action === "skip") {
      this.setState({ isSkip: true });
    }
    if (!isSkip) {
      if (data?.action === "reset") {
        let body = { key:"6" };
        this.props.sendToolTipById(
          JSON.parse(localStorage.getItem("fpoId")),
          body
        );
      }
    }
  };

  render() {
    const {
      isDataUploading,
      csvUrl,
      bulkFile,
      bulkFileName,
      type,
      run,
      tooltipContent,
    } = this.state;

    return (
      <div className="add-bod-area">
        <Joyride
          steps={tooltipContent}
          run={run}
          callback={this.callback}
          continuous
          hideBackButton
          hideCloseButton
          scrollToFirstStep
          showProgress
          showSkipButton
          disableScrolling
          styles={{
            options: {
              zIndex: 10000,
            },
            buttonNext: {
              color: "#FFFFFF",
              backgroundColor: "#0a8c5b",
            },
          }}
        />
        <div className="d-flex container-sa">
          <div className="d-flex bod-header-area w-100">
            <div className="d-flex">
              <img
                className="cursor-pointer"
                src={arrow_left}
                alt="back"
                onClick={() => history.goBack()}
              />
              <p className="ml-3 text-black fs-18 line-height-25 font-weight-bold mb-0 cursor-pointer">
                {`Bulk Upload ${type === "fpo" ? "Basic Profile" : "KYM"} data`}
              </p>
            </div>
          </div>
        </div>
        <div className="add-bod">
          <div className="container">
            <div className="add-bod-form-container pt-4">
              <div>
                <p className="fs-16 line-height-37 text-black">
                  Please <b>download</b> the Excel template file for{" "}
                  {type === "fpo" ? "Member" : "KYM"} bulk upload{" "}
                  <a
                    className="text-green-18 fs-16 line-height-37 cursor-pointer font-weight-bold tooltip-one"
                    href={csvUrl}
                    download
                  >
                    <img
                      className="cursor-pointer text-green pr-1"
                      src={downloadIcon}
                      alt="downloadIcon"
                      height="17"
                      width="17"
                    />
                    here
                  </a>
                </p>
                <p className="fs-16 line-height-37 text-black">
                  Please fill the template file and upload it below (Click
                  <b> Attach File</b>, select the file, and click{" "}
                  <b>Upload {type === "fpo" ? "Basic Profile" : "KYM"} Data</b>
                  ). Please do not change any column names.
                </p>
              </div>
              <div className="d-flex justify-content-center pt-5 mt-2 pb-4">
                <div>
                  <div className="fs-16 line-height-19 text-black-32 mb-2 pb-1 font-weight-bold">
                    {"Report Upload"}
                  </div>
                  <div className="add-member-form-container-proof-container d-flex justify-content-center align-items-center">
                    <input
                      type="file"
                      accept=".xlsx"
                      name="bulkFile"
                      onChange={this.handleIdentityProofFile}
                      id="handleIdentityProofFile"
                      title=""
                    />
                    {bulkFile !== "" ? (
                      bulkFileName
                    ) : (
                      <button className="bulk-upload-btn tooltip-two">
                        <i className="far fa-file-upload"></i>
                        <b>{"Attach File"}</b>
                      </button>
                    )}
                  </div>
                  {this.validator.message("bulkFile", bulkFile, "required", {
                    className: "text-danger",
                  })}
                </div>
              </div>
              <div className="mx-auto w-100 mt-5">
                <NormalButton
                  label={
                    isDataUploading
                      ? "Uploading..."
                      : `Upload ${type === "fpo" ? "Basic Profile" : "KYM"}  Data`
                  }
                  mainbg={true}
                  disabled={isDataUploading}
                  className="mx-auto fs-14 line-height-16 tooltip-three"
                  onClick={() => this.handleSubmit()}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      currentTab,
      getBulkUploadfile,
      addBulkUpload,
      getKYMBulkUploadfile,
      addKYMBulkUpload,
      sendToolTipById,
      getToolTipById,
    },
    dispatch
  );
};

export const Bulkupload = connect(null, mapDispatchToProps)(BulkuploadClass);
