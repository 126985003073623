import React, { Component } from "react";
import { UploadComponent } from "../UploadComponent";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import {
  addFpoDocuments,
  viewFpoDocuments,
  deleteFpoDocuments,
  getMasterData,
  getFinancialBulkdownload,
  financialEdit,
} from "action/DocumentAct";
import { getAccessdata } from "service/helperFunctions";
import { CommonPageLoader, ConfirmationPopup } from "component/common";
import downloadIcon from "assets/images/icon/download-icon.svg";
import { getDocumentsOfFPO, getFinancialBulkdownloadOfFpo } from "action/RmAct";
const docIcon = require("../../../assets/svg/Documents/docIcon.png");
class FinancialDoc extends Component {
  state = {
    documentsData: [],
    isLoading: false,
    financialYearData: [],
    bulkDownloadLink: "",
    deleteId: null,
  };
  //upload document
  handleUpload = (data) => {
    let {
      id,
      fileName,
      file,
      documentType,
      asOnDate,
      financialYearName,
      financialYearId,
      reportName,
    } = data;

    let body = {
      financialDocId: id,
      documentName: fileName,
      documentPath: file,
      reportName: reportName,
      financialFyYear: null,
      fymId: null,
      financialAsOnDate: null,
    };
    if (documentType == "provisional_financials") {
      //Provisional financials
      body.financialAsOnDate = moment(asOnDate).format("YYYY-MM-DD");
    } else if (documentType == "audited_financials") {
      //Audited financials
      body.fymId = financialYearId;
      body.financialFyYear = financialYearName;
    }

    this.props.addFpoDocuments(body, "financial").then(() => {
      this._getFinancialDocuments();
    });
  };

  //Edit Document
  handleEditOption = (data) => {
    let {
      id,
      documentType,
      asOnDate,
      financialYearName,
      financialYearId,
      reportName,
    } = data;

    let body = {
      id: id,
      reportName: reportName,
      financialFyYear: null,
      fymId: null,
      financialAsOnDate: null,
    };
    if (documentType == "provisional_financials") {
      //Provisional financials
      body.financialAsOnDate = moment(asOnDate).format("YYYY-MM-DD");
    } else if (documentType == "audited_financials") {
      //Audited financials
      body.fymId = financialYearId;
      body.financialFyYear = financialYearName;
    }
    this.props.financialEdit(body).then(() => {
      this._getFinancialDocuments();
    });
  };

  //Delete document
  handleDelete = (id) => {
    let { deleteId } = this.state;
    this.props
      .deleteFpoDocuments(
        {
          id: deleteId,
        },
        "financial"
      )
      .then(() => {
        this._getFinancialDocuments();
      });
  };

  toggleDelete = (id) => {
    this.setState({
      deleteId: id,
    });
  };

  //Life cycles
  componentDidMount() {
    let { activeTab } = this.props;
    if (activeTab == 4) {
      this._getFinancialDocuments();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      if (this.props.activeTab == 4) {
        this._getFinancialDocuments();
      }
    }
  }

  //get financial documents
  _getFinancialDocuments = () => {
    this.setState({ isLoading: true });
    let { id } = this.props;
    if (id) {
      this.props.getDocumentsOfFPO(id, "financial").then((data) => {
        this._setData(data);
      });
    } else {
      this.props.viewFpoDocuments("financial").then((data) => {
        this._setData(data);
      });
    }
  };

  _setData = (data) => {
    let statutoryData = [];
    data.map((list) => {
      statutoryData.push({
        id: list.financialDocId,
        title: list.documentName,
        body: list.description,
        documents: list.financials,
        documentType: list.documentType,
      });
    });
    this.setState({
      documentsData: statutoryData,
      isLoading: false,
    });
    //Get masterData
    this.props.getMasterData().then((masterData) => {
      let { financialYearMaster = [] } = masterData;
      let financialYearDetails = [];
      financialYearMaster.map((list) => {
        financialYearDetails.push({ label: list.fymYear, value: list.fymId });
      });
      this.setState({
        financialYearData: financialYearDetails,
      });
    });
  };

  getCsvUrl = () => {
    let { getFinancialBulkdownload, id, getFinancialBulkdownloadOfFpo } =
      this.props;
    if (id) {
      let body = {
        fpoId: id,
      };
      getFinancialBulkdownloadOfFpo(body).then((data) => {
        this._downloadData(data);
      });
    } else {
      let body = {
        id: null,
      };
      getFinancialBulkdownload(body).then((data) => {
        this._downloadData(data);
      });
    }
  };

  _downloadData = (data) => {
    this.setState(
      {
        bulkDownloadLink:
          "https://portal.fpogateway.com/fpomgmt/api/v1/download/zip?zipFileName=" +
          data.zipFileName,
      },
      () => {
        document.getElementById("csv-download").click();
        this.setState({ bulkDownloadLink: "" });
      }
    );
  };

  render() {
    let {
      documentsData,
      isLoading,
      financialYearData,
      bulkDownloadLink,
      deleteId,
    } = this.state;
    let { accessData, id } = this.props;
    let permissionData = getAccessdata(accessData, "fpoDocuments");

    let { download = false } = permissionData;
    return (
      <div className="d-flex flex-column">
        {download && (
          <div className="container-sa">
            <div className="d-flex justify-content-end">
              {bulkDownloadLink === "" ? (
                <span
                  className="text-black-50 font-weight-bold fs-18 text-underline cursor-pointer pr-md-5 pr-0 mr-md-3 mr-0"
                  onClick={() => this.getCsvUrl()}
                >
                  <img
                    src={downloadIcon}
                    className="mr-2"
                    height="20"
                    width="20"
                  />
                  {"Download all"}
                </span>
              ) : (
                <a
                  id="csv-download"
                  className="text-black-50 font-weight-bold fs-18 text-underline cursor-pointer pr-md-5 pr-0 mr-md-3 mr-0"
                  href={bulkDownloadLink}
                  download
                >
                  <img
                    src={downloadIcon}
                    className="mr-2"
                    height="20"
                    width="20"
                  />
                  {"Download all"}
                </a>
              )}
            </div>
          </div>
        )}
        <div className="container-sa">
          {!isLoading ? (
            <div className="row">
              {documentsData.map((documentDetail, index) => {
                return (
                  <div className="col-md-3 d-flex align-items-end" key={index}>
                    <div className="document-card-margin">
                      <div className="document-titleBlock">
                        <div className="d-flex align-items-center mb-1">
                          <img
                            className="mr-3"
                            src={docIcon}
                            height="30"
                            width="30"
                            alt="docIcon.svg"
                          />
                          <div className="document-title">
                            {documentDetail.title}
                          </div>
                        </div>
                        <div className="document-body mb-1">
                          {documentDetail.body}
                        </div>
                      </div>
                      <div>
                        <UploadComponent
                          documentTitle={documentDetail.title}
                          title={"Financials"}
                          documentDetail={documentDetail}
                          handleUpload={this.handleUpload}
                          handleEditOption={this.handleEditOption}
                          handleDelete={this.toggleDelete}
                          id={documentDetail.id}
                          documents={documentDetail.documents}
                          activeTab={this.props.activeTab}
                          financialYearData={financialYearData}
                          fpoId={id}
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <>
              {/* Loader */}
              <CommonPageLoader />
            </>
          )}
          <ConfirmationPopup
            isOpen={deleteId !== null ? true : false}
            toggle={() => this.toggleDelete(null)}
            onSubmit={this.handleDelete}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  accessData: state.home.accessData,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      addFpoDocuments,
      viewFpoDocuments,
      deleteFpoDocuments,
      getMasterData,
      getFinancialBulkdownload,
      getDocumentsOfFPO,
      getFinancialBulkdownloadOfFpo,
      financialEdit,
    },
    dispatch
  );
};
let component = FinancialDoc;

export const Financial = connect(
  mapStateToProps,
  mapDispatchToProps
)(component);
