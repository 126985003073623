import React, { Component } from 'react';
import './AwardsOtherFpos.scss';
import { arrow_left, arrow_right } from './icons';
import Swiper from 'react-id-swiper';
import Card from './card';

export class AwardsOtherFpos extends Component {
   render() {
      const params = {
         slidesPerView: 4,
         spaceBetween: 15,
         loop: false,
         navigation: {
            nextEl: '#next-btnAw',
            prevEl: '#prev-btnAw',
         },
         rebuildOnUpdate: true,
      };
      let {
         title,
         awardsOtherFposList = [],
         getAwardId,
         handleDelete,
         editAccess = false,
         viewAccess = false,
         deleteAccess = false,
      } = this.props;
      return (
         <div className='AwardsOtherFpos'>
            <div className='AwardsOtherFpos-container'>
               <div className='d-flex justify-content-between mb-4'>
                  <div className='AwardsOtherFpos-container-title'>{title}</div>
                  <div className='d-flex align-items-end'>
                     <input
                        type='image'
                        src={arrow_left}
                        alt='left'
                        width='40'
                        height='40'
                        id='prev-btnAw'
                        onClick={() => { }}
                     />
                     <input
                        type='image'
                        src={arrow_right}
                        alt='left'
                        width='40'
                        height='40'
                        id='next-btnAw'
                     />
                  </div>
               </div>
               <div>
                  {awardsOtherFposList.length > 0 && !!awardsOtherFposList ? (
                     <Swiper {...params}>
                        {awardsOtherFposList.map((award, index) => {
                           return (
                              <div key={index} className=''>
                                 <Card
                                    award={award}
                                    index={index}
                                    getAwardId={() => getAwardId ? getAwardId(award.awardId, 'awards') : ''}
                                    getId={getAwardId}
                                    handleDelete={handleDelete}
                                    editAccess={editAccess}
                                    viewAccess={viewAccess}
                                    deleteAccess={deleteAccess}
                                 />
                              </div>
                           );
                        })}
                     </Swiper>
                  ) : (
                        <p className='py-5 my-5 mb-0 fs-22 text-black w-100 text-center'>
                           No data found
                        </p>
                     )}
               </div>
            </div>
         </div>
      );
   }
}
