import { roboCall } from "service/apiVariables";
import { Toast } from "service/toast";
import { api } from "service/api";

export const audioMessage = (data) => {
  return {
    type: "AUDIO_MESSAGE",
    payload: data,
  };
};

export const getRoboCallById = () => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    api({ ...roboCall.getRoboCallById })
      .then(({ data, message }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

export const sendRoboCallAudio = (body) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    api({ ...roboCall.sendRoboCallAudio, body })
      .then(({ data, message }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

export const getCallRecords = (body) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    api({ ...roboCall.getCallRecords, body })
      .then(({ data, message }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

export const sendRoboCallRequest = (body) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    api({ ...roboCall.sendRoboCallRequest, body })
      .then(({ data, message }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

export const sendToolTipById = (id, body) => (dispatch, getState) => {
  roboCall.sendToolTipById.id = id;
  return new Promise((resolve, reject) => {
    api({ ...roboCall.sendToolTipById, body })
      .then(({ data, message }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

export const getToolTipById = (id) => (dispatch, getState) => {
  roboCall.getToolById.id = id;
  return new Promise((resolve, reject) => {
    api({ ...roboCall.getToolById })
      .then(({ data, message }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};
