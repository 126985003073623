import { eventsAndImpactStories } from '../service/apiVariables';
import { addQuery } from 'service/helperFunctions';

export const getFpoAllEventsByUser = (id) => (
   dispatch,
   getState,
   { api, Toast }
) => {
   let { getFpoAllEventsByUser } = eventsAndImpactStories;
   getFpoAllEventsByUser.id = id ? id : '';
   return new Promise((resolve, reject) => {
      api({ ...getFpoAllEventsByUser })
         .then((data) => {
            resolve(data);
         })
         .catch(({ message }) => {
            reject(Toast({ type: 'error', message }));
         });
   });
};

export const getAllFpoEventsandImpact = () => (
   dispatch,
   getState,
   { api, Toast }
) => {
   return new Promise((resolve, reject) => {
      api({ ...eventsAndImpactStories.getAllFpoEventsandImpact })
         .then((data) => {
            resolve(data);
         })
         .catch(({ message }) => {
            reject(Toast({ type: 'error', message }));
         });
   });
};

export const createEvent = (body) => (dispatch, getState, { api, Toast }) => {
   return new Promise((resolve, reject) => {
      api({ ...eventsAndImpactStories.addFpoEvent, body })
         .then(({ data, message }) => {
            Toast({ type: 'success', message, time: 2000 });

            resolve(data);
         })
         .catch(({ message }) => {
            reject(Toast({ type: 'error', message }));
         });
   });
};

export const getEventById = (id) => (dispatch, getState, { api, Toast }) => {
   let { getEvent } = eventsAndImpactStories;
   getEvent.id = id ? id : '';
   return new Promise((resolve, reject) => {
      api({ ...getEvent, id })
         .then((data) => {
            resolve(data);
         })
         .catch(({ message }) => {
            reject(Toast({ type: 'error', message }));
         });
   });
};

//get recommended events
export const getRecommendedEvents = (query) => (
   dispatch,
   getState,
   { api, Toast }
) => {
   return new Promise((resolve, reject) => {
      addQuery(query, eventsAndImpactStories.getRecommendedEvents);

      api({ ...eventsAndImpactStories.getRecommendedEvents })
         .then(({ data, message }) => {
            resolve(data);
         })
         .catch(({ message }) => {
            reject(Toast({ type: 'error', message }));
         });
   });
};

//get recommended awards
export const getRecommendedAwards = (query) => (
   dispatch,
   getState,
   { api, Toast }
) => {
   return new Promise((resolve, reject) => {
      addQuery(query, eventsAndImpactStories.getRecommendedAwards);

      api({ ...eventsAndImpactStories.getRecommendedAwards })
         .then(({ data, message }) => {
            resolve(data);
         })
         .catch(({ message }) => {
            reject(Toast({ type: 'error', message }));
         });
   });
};

//get Recommended Stories
export const getRecommendedStories = (query) => (
   dispatch,
   getState,
   { api, Toast }
) => {
   return new Promise((resolve, reject) => {
      addQuery(query, eventsAndImpactStories.getRecommendedStories);

      api({ ...eventsAndImpactStories.getRecommendedStories })
         .then(({ data, message }) => {
            resolve(data);
         })
         .catch(({ message }) => {
            reject(Toast({ type: 'error', message }));
         });
   });
};

// delete Awards
export const deleteAwards = (id) => (dispatch, getState, { api, Toast }) => {
   let { deleteAwards } = eventsAndImpactStories;
   deleteAwards.id = id;
   return new Promise((resolve, reject) => {
      api({ ...deleteAwards })
         .then(({ data, message }) => {
            resolve(data);
            Toast({ type: 'success', message, time: 2000 });
         })
         .catch(({ message }) => {
            reject(Toast({ type: 'error', message }));
         });
   });
};

//delete Stories
export const deleteStories = (id) => (dispatch, getState, { api, Toast }) => {
   let { deleteStories } = eventsAndImpactStories;
   deleteStories.id = id;
   return new Promise((resolve, reject) => {
      api({ ...deleteStories })
         .then(({ data, message }) => {
            resolve(data);
            Toast({ type: 'success', message, time: 2000 });
         })
         .catch(({ message }) => {
            reject(Toast({ type: 'error', message }));
         });
   });
};

//delete Events
export const deleteEvents = (id) => (dispatch, getState, { api, Toast }) => {
   let { deleteEvents } = eventsAndImpactStories;
   deleteEvents.id = id;
   return new Promise((resolve, reject) => {
      api({ ...deleteEvents })
         .then(({ data, message }) => {
            resolve(data);
            Toast({ type: 'success', message, time: 2000 });
         })
         .catch(({ message }) => {
            reject(Toast({ type: 'error', message }));
         });
   });
};

export const updateEvent = (body) => (dispatch, getState, { api, Toast }) => {
   return new Promise((resolve, reject) => {
      api({ ...eventsAndImpactStories.updateEvent, body })
         .then(({ data, message }) => {
            Toast({ type: 'success', message, time: 2000 });
            resolve(data);
         })
         .catch(({ message }) => {
            reject(Toast({ type: 'error', message }));
         });
   });
};

export const createAward = (body) => (dispatch, getState, { api, Toast }) => {
   return new Promise((resolve, reject) => {
      api({ ...eventsAndImpactStories.createAward, body })
         .then(({ data, message }) => {
            Toast({ type: 'success', message, time: 2000 });
            resolve(data);
         })
         .catch(({ message }) => {
            reject(Toast({ type: 'error', message }));
         });
   });
};

export const getAwardById = (id) => (dispatch, getState, { api, Toast }) => {
   let { getAward } = eventsAndImpactStories;
   getAward.id = id ? id : '';
   return new Promise((resolve, reject) => {
      api({ ...eventsAndImpactStories.getAward })
         .then((data) => {
            resolve(data);
         })
         .catch(({ message }) => {
            reject(Toast({ type: 'error', message }));
         });
   });
};

export const updateAwardById = (body) => (
   dispatch,
   getState,
   { api, Toast }
) => {
   return new Promise((resolve, reject) => {
      api({ ...eventsAndImpactStories.updateAward, body })
         .then(({ data, message }) => {
            Toast({ type: 'success', message, time: 2000 });

            resolve(data);
         })
         .catch(({ message }) => {
            reject(Toast({ type: 'error', message }));
         });
   });
};

export const createStory = (body) => (dispatch, getState, { api, Toast }) => {
   return new Promise((resolve, reject) => {
      api({ ...eventsAndImpactStories.createStory, body })
         .then(({ data, message }) => {
            Toast({ type: 'success', message, time: 2000 });

            resolve(data);
         })
         .catch(({ message }) => {
            reject(Toast({ type: 'error', message }));
         });
   });
};

export const updateStory = (body) => (dispatch, getState, { api, Toast }) => {
   return new Promise((resolve, reject) => {
      api({ ...eventsAndImpactStories.updateStory, body })
         .then(({ data, message }) => {
            Toast({ type: 'success', message, time: 2000 });

            resolve(data);
         })
         .catch(({ message }) => {
            reject(Toast({ type: 'error', message }));
         });
   });
};

//get all Events Data
export const getallEventsData = () => (
   dispatch,
   getState,
   { api, Toast }
) => {
   return new Promise((resolve, reject) => {
      api({ ...eventsAndImpactStories.getallEventsData })
         .then((data) => {
            resolve(data);
         })
         .catch(({ message }) => {
            reject(Toast({ type: 'error', message }));
         });
   });
};

//getallEventsDataWithId
export const getallEventsDataWithId = (id) => (
   dispatch,
   getState,
   { api, Toast }
) => {
   let { getallEventsDataWithId } = eventsAndImpactStories
   getallEventsDataWithId.id = id
   return new Promise((resolve, reject) => {
      api({ ...eventsAndImpactStories.getallEventsDataWithId })
         .then((data) => {
            resolve(data);
         })
         .catch(({ message }) => {
            reject(Toast({ type: 'error', message }));
         });
   });
};

export const getStoryById = (id) => (dispatch, getState, { api, Toast }) => {
   let { getStory } = eventsAndImpactStories;
   getStory.id = id ? id : '';
   return new Promise((resolve, reject) => {
      api({ ...eventsAndImpactStories.getStory })
         .then((data) => {
            resolve(data);
         })
         .catch(({ message }) => {
            reject(Toast({ type: 'error', message }));
         });
   });
};

export const getAllAgendaMaster = () => (
   dispatch,
   getState,
   { api, Toast }
) => {
   return new Promise((resolve, reject) => {
      api({ ...eventsAndImpactStories.getAllAgendaMaster }).then((data) => {
         resolve(data);
      });
   });
};
