import { rmCropType } from "service/actionType";

const initialState = {
  categoryOptions: [],
  cropOptions: [],
};

export default (state = Object.assign({}, initialState), { type, payload }) => {
  switch (type) {
    case rmCropType.categoryOptions:
      return {
        ...state,
        categoryOptions: payload,
      };
    case rmCropType.cropOptions:
      return {
        ...state,
        cropOptions: payload,
      };
    default:
      return state;
  }
};
