import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { updateHarvest, deletHarvest } from "action/HarvestAct";
import { harvestCrops } from "action/HarvestAct";
import { formatToINRWithoutSymbol } from "service/helperFunctions";
import { Pagination } from "component/common";
import { harvestTableHeader, harvestEditForm } from "../../dataVariables";
import HarvestEdit from "./HarvestEdit";

const CropData = (props) => {
  const {
    cropData,
    pageMeta,
    handlePagination,
    getharvestData,
    updateHarvestApi,
    deletHarvestApi,
    harvestCropsApi,
  } = props;
  const [openPopup, setOpenPopup] = useState(false);
  const [cropOptions, setCropOptions] = useState([]);
  const [showEdit, setShowEdit] = useState(true);
  const [deleteData, setDeleteData] = useState({});
  const [error, setError] = useState(false);
  const [formData, setFormData] = useState({ ...harvestEditForm });

  const handleChange = (e) => {
    setError(false);
    const { name, value } = e.target || {};
    setFormData({ ...formData, [name]: value });
  };

  const onUpdate = (item) => {
    getCrop();
    setFormData({
      harvestID: item?.harvestId,
      fpoID: item?.fpoId,
      season: item?.seasonType,
      crop: parseInt(item?.cropId),
      variety: item?.variety,
      quantity: item?.qty,
      price: item?.price,
      unit: item?.unitOfMeasurement,
      others: item?.others,
    });
    setOpenPopup(true);
  };

  const getCrop = () => {
    harvestCropsApi().then((data) => {
      let newData = [];
      data.forEach((list) => {
        newData.push({
          label: list.cropName,
          value: list.cropId,
        });
      });
      setCropOptions(newData);
    });
  };

  const updateData = () => {
    let { season, crop, quantity, price, unit, others } = formData;
    let validation =
      season === "" ||
      crop === "" ||
      quantity === "" ||
      price === "" ||
      unit === "";
    if (validation) {
      setError(true);
      return null;
    } else {
      setError(false);
    }
    if (unit === "Others") {
      if (others === "") {
        setError(true);
        return null;
      }
    } else {
      setError(false);
    }
    let body = {
      harvestId: formData?.harvestID,
      fpoId: formData?.fpoID,
      seasonType: formData?.season,
      cropId: formData?.crop,
      variety: formData?.variety,
      qty: formData?.quantity,
      price: formData?.price,
      unitOfMeasurement: formData?.unit,
      others: formData?.others,
    };
    updateHarvestApi(body.harvestId, body);
    harvestApi();
  };

  const onDelete = (type) => {
    if (type === "openModal") {
      setShowEdit(false);
      setOpenPopup(true);
    } else if (type === "delete") {
      deletHarvestApi(deleteData?.harvestId);
      harvestApi();
    }
  };

  const harvestApi = () => {
    setOpenPopup(false);
    setTimeout(() => {
      getharvestData(1);
    }, 200);
  };

  return (
    <div style={{ minWidth: "1200px" }}>
      {cropData.length !== 0 ? (
        <div className="d-flex container-sa flex-column flex-wrap mt-3 px-0 justify-content-center">
          <table>
            <thead>
              {harvestTableHeader?.map((item, index) => (
                <th key={index}>{item?.title}</th>
              ))}
            </thead>
            <tbody>
              {cropData?.map((item, index) => (
                <React.Fragment key={index}>
                  <tr>
                    <td>{item?.cropsmaster?.cropName}</td>
                    <td>{item?.variety}</td>
                    <td>{item?.qty}</td>
                    <td>
                      {item?.unitOfMeasurement === "Others"
                        ? item?.others
                        : item?.unitOfMeasurement}
                    </td>
                    <td>Rs. {formatToINRWithoutSymbol(item?.price)}</td>
                    <td className="d-flex">
                      <div
                        className="edit-button mr-3"
                        onClick={() => onUpdate(item)}
                      >
                        Edit
                      </div>
                      <div
                        className="delete-button"
                        onClick={() => {
                          onDelete("openModal");
                          setDeleteData(item);
                        }}
                      >
                        Delete
                      </div>
                    </td>
                  </tr>
                  <tr class="trans-bg">
                    <td class="empty-height" height="12"></td>
                  </tr>
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="col-12">
          <p className="py-5 mb-0 text-black-50 fs-22 line-height-25 text-center">
            No data found
          </p>
        </div>
      )}
      {openPopup && (
        <HarvestEdit
          openPopup={openPopup}
          setOpenPopup={setOpenPopup}
          formData={formData}
          handleChange={handleChange}
          updateData={updateData}
          cropOptions={cropOptions}
          showEdit={showEdit}
          setShowEdit={setShowEdit}
          onDelete={onDelete}
          error={error}
        />
      )}
      <Pagination handleChange={handlePagination} pageMeta={pageMeta} />
    </div>
  );
};

let mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      updateHarvestApi: updateHarvest,
      deletHarvestApi: deletHarvest,
      harvestCropsApi: harvestCrops,
    },
    dispatch
  );
};

export default connect(null, mapDispatchToProps)(CropData);
