import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./otp.scss";
import { Toast } from "service/toast";
import {
  verifyOtp,
  otpLogin,
  getAccessDetails,
  FIGCRPOTPLoginVerification,
  FIGCRPResendOTP,
} from "action/AuthAct";
import { kymOTPLogin, KYMOTPVerify } from "action/KYMAct";
import SimpleReactValidator from "simple-react-validator";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import OtpInput from "react-otp-input";
import { history } from "service/helpers";
import { termsandPolicyLinks } from "service/helperFunctions";
class OtpAuthClass extends Component {
  constructor() {
    super();
    this.state = {
      otp: "",
      isValide: false,
    };
    this.validator = new SimpleReactValidator({
      validators: {
        element: (message) => (
          <span className="error-message font-md">{message}</span>
        ),
        autoForceUpdate: this,
      },
    });
  }
  handleChange = (otp) => {
    this.setState({
      otp,
    });
  };

  handleResend = () => {
    let body;
    let api;
    let { emailOrMobNum, isEmail } = this.props.data;
    let { user_type } = this.props;
    if (user_type === "fpo") {
      body = {
        emailOrMobNum: emailOrMobNum,
        isEmail: isEmail,
      };
    } else if (user_type === "kym") {
      body = { mobileNumber: emailOrMobNum };
    } else {
      body = { mobileNumber: emailOrMobNum };
    }
    if (user_type === "fpo") {
      api = this.props.otpLogin(body);
    } else if (user_type === "kym") {
      api = this.props.kymOTPLogin(body);
    } else {
      api = this.props.FIGCRPResendOTP(body);
    }
    api
      .then(() => {
        let message = "Otp sent successfully";
        Toast({ type: "success", message, time: 5000 });
      })
      .catch({});
  };

  handleOtp = (e) => {
    e.preventDefault();
    let body;
    let { otp } = this.state;
    let { user_type } = this.props;
    if (otp.length < 6) {
      let message = "Please enter OTP";
      Toast({ type: "error", message, time: 5000 });
      this.setState({ isValide: true });
    } else {
      this.setState({ isValide: false });
      const { emailOrMobNum, isEmail } = this.props.data;
      if (user_type === "fpo") {
        body = {
          userGroupName: user_type,
          emailOrMobNum: emailOrMobNum,
          isEmail: isEmail,
          otp: parseInt(otp),
        };
      } else if (user_type === "kym") {
        body = {
          mobileNumber: emailOrMobNum,
          otp: parseInt(otp),
        };
      } else {
        body = {
          emailOrMobNum: emailOrMobNum,
          isEmail: false,
          userTypeRole: user_type,
          otp: parseInt(otp),
        };
      }
      this.setState({ loginLoader: true });
      if (user_type === "fpo") {
        this.props
          .verifyOtp(body)
          .then(() => {
            localStorage.setItem("languagePopup", 1);
            localStorage.setItem("userGroupName", "fpo");
            localStorage.setItem("userRoleName", "fpo");
            history.push("/fpo/home");
          })
          .catch(() => {
            this.setState({ loginLoader: false });
          });
      } else if (user_type === "kym") {
        this.props
          .KYMOTPVerify(body)
          .then((data) => {
            this.handleSuccessRedirection(data, user_type, "/kym/kym-new");
          })
          .catch(() => this.setState({ loginLoader: false }));
      } else {
        this.props
          .FIGCRPOTPLoginVerification(body)
          .then((data) =>
            this.handleSuccessRedirection(data, user_type, "/fig/home")
          )
          .catch(() => {
            this.setState({ loginLoader: false });
          });
      }
    }
  };

  handleSuccessRedirection = (data, user_type, route) => {
    const isKYM = user_type === "kym";
    Toast({
      type: "success",
      message: "User login successful",
      time: 5000,
    });
    if (isKYM) localStorage.setItem("userName", data?.userName);
    localStorage.setItem("AuthToken", data?.token);
    localStorage.setItem(
      "userRoleName",
      isKYM ? data?.userRoles?.userRoleName : data?.userTypeRole
    );
    history.push(route);
  };

  render() {
    let { otp, isValide, loginLoader } = this.state;
    let { user_type } = this.props;

    return (
      <div className="w-100 mt-3 d-flex align-items-center flex-column">
        <label className="font-weight-bold text-success mb-5 h4">
          {"Mobile Verification"}
        </label>
        <label className="font-weight-bold mb-5 d-flex justify-content-center text-center">
          {`Enter the OTP received on your Mobile Number${
            user_type === "fpo" ? "/Email ID" : ""
          }`}
        </label>
        <div className="mb-3">
          <OtpInput
            value={otp}
            onChange={this.handleChange}
            numInputs={6}
            isInputNum={true}
            separator={<span>{"\xa0\xa0"}</span>}
            inputStyle="otp"
          />
          {isValide ? (
            <small id="emailHelp" className="form-text text-danger">
              {"Enter Valid OTP"}
            </small>
          ) : null}
          {this.validator.message("otp", otp, "required", {
            className: "text-danger",
          })}
        </div>
        <div className="d-flex justify-content-center mt-2 w-100">
          <button
            className="btn w-100 py-2 font-weight-bold mainbg-btn"
            disabled={loginLoader}
            onClick={this.handleOtp}
          >
            Verify OTP{" "}
            {loginLoader ? (
              <div
                className="spinner-border ml-2 spinner-border-sm"
                role="status"
              >
                <span className="sr-only">Loading...</span>
              </div>
            ) : null}
          </button>
        </div>
        <div className="mt-2 text-center">
          <label>If you did not receive any OTP, please click</label>
          <span
            className="ml-1 text-dark font-weight-bold cursor-pointer"
            onClick={this.handleResend}
          >
            <u>{"Resend OTP"}</u>
          </span>
        </div>
        {user_type === "fpo" && (
          <div className="d-flex justify-content-center mt-2">
            <label>Use password instead?</label>
            <Link
              className="ml-1 text-dark font-weight-bold"
              to={"#"}
              onClick={this.props.togglePass}
            >
              <u>Password</u>
            </Link>
          </div>
        )}
        {termsandPolicyLinks()}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      verifyOtp,
      otpLogin,
      getAccessDetails,
      FIGCRPOTPLoginVerification,
      FIGCRPResendOTP,
      kymOTPLogin,
      KYMOTPVerify,
    },
    dispatch
  );
};

let component = OtpAuthClass;

export const OtpAuth = connect(null, mapDispatchToProps)(component);
