import React, { Component } from "react";
import OtpInput from "react-otp-input";
import { Modal, ModalBody } from "reactstrap";
import SimpleReactValidator from "simple-react-validator";
import { NormalButton } from "../NormalButton";
import { NormalInput } from "../NormalInput";
import {
  updateMobile,
  updateOtp,
  resendOtp,
  getEntityList,
  updateEntity,
  getEntityIPLUrl,
} from "action/samIPlAct";
import { getUserProfileDetails } from "action/Acadamy";
import { getAboutusMemberDetail } from "action/MemberAct";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import membership from "assets/svg/fpo_gateway/membership.svg";
import finance from "assets/svg/fpo_gateway/events_impact.svg";
import iplImage from "assets/images/iplLoans.png";

import "./style.scss";
import { history } from "helpers";

class IPLpopupClass extends Component {
  state = {
    formfields: {
      mobileNumber: "",
      cin: "",
      isCin: "Farmer Producer Company (FPC)",
      otp: "",
    },
    displayCin: false,
    isOtp: false,
    entityList: [],
    timeout: 60,
    fpoBazaar: false,
    isCheckPopup: true,
    isForFpo: false,
    isForFml: false,
    showFml: false,
    isFmlCondition: false,
  };

  componentDidUpdate(prevprops) {
    if (prevprops !== this.props) {
      let { getEntityListApi, isOpen, getAboutusMemberDetail } = this.props;
      if (isOpen) {
        getEntityListApi().then((data) => this.setState({ entityList: data }));
      }
      getAboutusMemberDetail().then((res) => {
        if (res?.stateMaster?.stateName === "Uttar Pradesh") {
          this.setState({ showFml: true });
        }
      });
    }
  }

  getEntityRedirectUrlApi = (isApiCall = false) => {
    let { getEntityIPLUrlApi, getUserProfileDetailsApi } = this.props;
    let body = {
      program_type: "ipl",
    };
    getEntityIPLUrlApi(body).then(
      ({
        redirectURL,
        isMobileVerified = false,
        isEntityChecked = false,
        isFpoBazaar = false,
      }) => {
        this.setState({ fpoBazaar: isFpoBazaar });
        if (!isMobileVerified) {
          this.setState({ displayCin: false, isOtp: false }, () => {
            let { formfields } = this.state;
            getUserProfileDetailsApi().then((data) => {
              formfields.mobileNumber = data.mobileNumber;
              this.setState({ formfields });
            });
          });
        } else if (!isEntityChecked) {
          this.setState({ displayCin: true });
        } else {
          this.handleClose();
          window.open(redirectURL, "_blank");
        }
        if (isApiCall) {
          this.setState({ displayCin: true, isOtp: false });
        }
      }
    );
  };

  fmlRedirectUrlApi = (isApiCall = false) => {
    let { getEntityIPLUrlApi, getUserProfileDetailsApi, toggle } = this.props;
    let body = {
      program_type: "fml",
    };
    getEntityIPLUrlApi(body).then(
      ({
        redirectURL,
        isMobileVerified = false,
        isEntityChecked = false,
        isFpoBazaar = false,
      }) => {
        this.setState({ fpoBazaar: isFpoBazaar });
        if (redirectURL) {
          this.setState({
            formfields: {
              mobileNumber: "",
              cin: "",
              isCin: "Farmer Producer Company",
              otp: "",
            },
            displayCin: false,
            isOtp: false,
            isCheckPopup: true,
            isForFpo: false,
            isForFml: false,
            isFmlCondition: false,
          });
          toggle();
        }
        if (!isMobileVerified) {
          this.setState({ displayCin: false, isOtp: false }, () => {
            let { formfields } = this.state;
            getUserProfileDetailsApi().then((data) => {
              formfields.mobileNumber = data.mobileNumber;
              this.setState({ formfields });
            });
          });
        } else if (!isEntityChecked) {
          this.setState({ displayCin: true });
        } else {
          this.handleClose();
          window.open(redirectURL, "_blank");
        }
        if (isApiCall) {
          this.setState({ displayCin: true, isOtp: false });
        }
      }
    );
  };

  //validation
  validator = new SimpleReactValidator({
    element: (message) => (
      <span className="error-message text-danger fs-14">{message}</span>
    ),
    autoForceUpdate: this,
  });

  //validation otp
  validatorOtp = new SimpleReactValidator({
    element: (message) => (
      <span className="error-message text-danger fs-14">{message}</span>
    ),
    autoForceUpdate: this,
  });

  //validator Cin
  validatorCin = new SimpleReactValidator({
    validators: {
      cin: {
        message: "Please enter valid CIN number",
        rule: (val, params, validator) => {
          return validator.helpers.testRegex(
            val,
            /^([L|U]{1})([0-9]{5})([A-Za-z]{2})([0-9]{4})([A-Za-z]{3})([0-9]{6})$/
          );
        },
        messageReplace: (message, params) =>
          message.replace("", this.helpers.toSentence(params)),
        required: true,
      },
    },
    element: (message) => (
      <span className="error-message text-danger fs-14">{message}</span>
    ),
    autoForceUpdate: this,
  });

  //handle Input
  handleInput = ({ target: { name, value } }) => {
    let { formfields } = this.state;
    formfields[name] = value;
    this.setState({ ...formfields });
  };

  //handle Submit
  handleSubmit = () => {
    if (this.validator.allValid()) {
      let { updateMobileApi } = this.props;
      let { formfields } = this.state;
      updateMobileApi({ mobileNumber: formfields.mobileNumber }).then(() => {
        this.setState({ timeout: 60, isOtp: true }, () => {
          this.myInterval = setInterval(() => {
            const { timeout } = this.state;
            if (timeout > 0) {
              this.setState(({ timeout }) => ({
                timeout: timeout - 1,
              }));
            }
            if (timeout === 0) {
              clearInterval(this.myInterval);
            }
          }, 1000);
        });
      });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  //handle Close
  handleClose = () => {
    let { toggle } = this.props;
    this.setState({
      formfields: {
        mobileNumber: "",
        cin: "",
        isCin: "Farmer Producer Company",
        otp: "",
      },
      displayCin: false,
      isOtp: false,
      isCheckPopup: true,
      isForFpo: false,
      isForFml: false,
      isFmlCondition: false,
    });
    toggle();
    this.validatorCin.hideMessages();
    this.validator.hideMessages();
    this.validatorOtp.hideMessages();
  };

  handleRedirect = () => {
    this.getEntityRedirectUrlApi();
  };

  getEntityIPLUrlApiFunc = () => {
    let { getEntityIPLUrlApi } = this.props;
    getEntityIPLUrlApi().then(({ redirectURL }) => {
      this.handleClose();
      window.open(redirectURL, "_blank");
    });
  };

  //handle CIN Submit
  handleCINSubmit = () => {
    let { isCin, formfields, entityList } = this.state;
    let { updateEntityApi } = this.props;
    if (isCin === "Farmer Producer Company (FPC)") {
      if (this.validatorCin.allValid()) {
        let entity = entityList.filter((x) => x.entityName === isCin);
        let payload = {
          entityId: entity?.[0]?.entityId,
          companyCin: formfields.cin,
        };
        updateEntityApi(payload).then(() => this.getEntityIPLUrlApiFunc());
      } else {
        this.validatorCin.showMessages();
        this.forceUpdate();
      }
    } else {
      let entity = entityList.filter((x) => x.entityName === isCin);
      let payload = { entityId: entity?.[0]?.entityId };
      updateEntityApi(payload).then(() => this.getEntityIPLUrlApiFunc());
    }
  };

  //handle Submit OTP
  handleSubmitOTP = () => {
    if (this.validatorOtp.allValid()) {
      let { updateOtpApi } = this.props;
      let { formfields } = this.state;
      let body = {
        mobileNumber: formfields.mobileNumber,
        mobileOtp: formfields.otp,
      };
      updateOtpApi(body).then(() => {
        this.setState({
          formfields: { isCin: "Farmer Producer Company", ...formfields },
        });
        this.getEntityRedirectUrlApi(true);
      });
    } else {
      this.validatorOtp.showMessages();
      this.forceUpdate();
    }
  };

  //handle Radio
  handleRadio = ({ target: { value } }) => {
    let { formfields } = this.state;
    formfields["isCin"] = value;
    this.setState({ ...formfields });
    this.validatorCin.hideMessages();
  };

  handleCheck = () => {
    this.setState({ isCheckPopup: false, isForFpo: true });
  };

  handleIplCheck = () => {
    this.setState({ isForFpo: false }, () => this.getEntityRedirectUrlApi());
  };

  handleFmlCheck = () => {
    this.setState({
      isCheckPopup: false,
      isForFpo: false,
      isForFml: true,
      displayCin: false,
    });
  };

  handleFmlCondition = (val) => {
    this.setState({
      isCheckPopup: false,
      isForFpo: false,
      isForFml: false,
      isFmlCondition: val,
      displayCin: false,
    });
    if (val === false) {
      this.fmlRedirectUrlApi();
    }
  };

  //handle Resend
  handleResend = () => {
    let { resendOtpApi } = this.props;
    let { formfields } = this.state;
    this.setState({ timeout: 60 });
    resendOtpApi({ mobileNumber: formfields.mobileNumber }).then(() => {
      this.myInterval = setInterval(() => {
        const { timeout } = this.state;
        if (timeout > 0) {
          this.setState(({ timeout }) => ({ timeout: timeout - 1 }));
        }
        if (timeout === 0) {
          clearInterval(this.myInterval);
        }
      }, 1000);
    });
  };

  render() {
    let { isOpen } = this.props;

    let {
      formfields,
      displayCin,
      isOtp,
      entityList,
      timeout,
      fpoBazaar,
      isCheckPopup,
      isForFpo,
      isForFml,
      isFmlCondition,
      showFml,
    } = this.state;

    let { mobileNumber, cin, isCin, otp } = formfields;

    console.log(this.state.isFmlCondition, "oiu");

    return (
      <Modal isOpen={isOpen} centered={true} className="ipl-modal">
        <ModalBody>
          <div className="ipl-popup">
            <i className="fas fa-times" onClick={() => this.handleClose()}></i>
            {(isCheckPopup || isForFpo || isForFml) && (
              <label className="font-weight-bold text-center text-success mb-3 h4 w-100">
                Explore Loans
              </label>
            )}
            {isCheckPopup ? (
              <div className="row">
                <div className="col-md-4 col-6">
                  <div
                    className="d-flex flex-column justify-content-center align-items-center cursor-pointer gateway-link"
                    onClick={() => this.handleCheck()}
                  >
                    <div className="rounded-circle p-4 shadow mb-3 gateway-image-container">
                      <img
                        className="gateway-image d-flex justify-content-center mx-auto"
                        src={finance}
                        alt="gw-icon.svg"
                      />
                    </div>
                    <div className="font-weight-bold container-60 text-center d-flex justify-content-center mb-1">
                      Loans for FPO
                    </div>
                  </div>
                </div>
                <div className="col-md-4 col-6">
                  <div className="d-flex flex-column justify-content-center align-items-center cursor-pointer gateway-link">
                    <div className="rounded-circle p-4 shadow mb-3 gateway-image-container">
                      <img
                        className="gateway-image d-flex justify-content-center mx-auto"
                        src={membership}
                        alt="gw-icon.svg"
                      />
                    </div>
                    <div className="font-weight-bold container-60 text-center d-flex justify-content-center mb-1">
                      Farmer Finance
                    </div>
                    <div className="font-weight-bold text-break text-center d-flex justify-content-center mb-2">
                      {"(Launching Soon)"}
                    </div>
                  </div>
                </div>
              </div>
            ) : isForFpo ? (
              <div>
                <div className="row">
                  <div className="col-md-4 col-6">
                    <div
                      className="d-flex flex-column justify-content-center align-items-center cursor-pointer gateway-link"
                      onClick={() => this.handleIplCheck()}
                    >
                      <div className="rounded-circle p-4 shadow mb-3 gateway-image-container">
                        <img
                          className="gateway-image d-flex justify-content-center mx-auto"
                          src={iplImage}
                          alt="ipl"
                        />
                      </div>
                      <div className="font-weight-bold container-60 text-center d-flex justify-content-center mb-1">
                        SAM IPL / IPL PLUS
                      </div>
                    </div>
                  </div>
                  <div
                    className={`${
                      showFml ? "d-block" : "d-none"
                    } col-md-4 col-6`}
                  >
                    <div
                      className="d-flex flex-column justify-content-center align-items-center cursor-pointer gateway-link"
                      onClick={() => this.handleFmlCheck()}
                    >
                      <div className="rounded-circle p-4 shadow mb-3 gateway-image-container">
                        <img
                          className="gateway-image d-flex justify-content-center mx-auto"
                          src={iplImage}
                          alt="FML"
                        />
                      </div>
                      <div className="font-weight-bold container-60 text-center d-flex justify-content-center mb-1">
                        FML
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-4 d-flex w-100">
                  <div className="d-flex justify-content-center w-100">
                    <NormalButton
                      label="Back"
                      isPopup={true}
                      outline={true}
                      className="px-3 py-2 fs-14 line-height-26"
                      onClick={() =>
                        this.setState({ isForFpo: false, isCheckPopup: true })
                      }
                    />
                  </div>
                </div>
              </div>
            ) : isForFml ? (
              <div>
                <div className="row">
                  <label className="font-weight-bold text-center text-success mb-3 h4 w-100">
                    RM of this FPC is Yash Chaturvedi/ Kshitij Saxena/ Abhay
                    Shrivatsava
                  </label>
                  <div className="col-12">
                    <div className="d-flex justify-content-center">
                      <div>
                        <div className="d-flex flex-row">
                          <div className="pr-2">
                            <NormalButton
                              label="Yes"
                              isPopup={true}
                              outline={true}
                              className="Fml-Yes-Button"
                              onClick={() => {
                                this.handleClose();
                                this.handleFmlCondition(false);
                              }}
                            />
                          </div>
                          <div className="pl-2">
                            <NormalButton
                              label="No"
                              isPopup={true}
                              outline={true}
                              className="Fml-No-Button"
                              onClick={() => this.handleFmlCondition(true)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-4 d-flex w-100">
                  <div className="d-flex justify-content-center w-100">
                    <NormalButton
                      label="Back"
                      isPopup={true}
                      outline={true}
                      className="px-3 py-2 fs-14 line-height-26"
                      onClick={() =>
                        this.setState({ isForFpo: true, isForFml: false })
                      }
                    />
                  </div>
                </div>
              </div>
            ) : isFmlCondition ? (
              <div>
                <div className="row">
                  <label className="text-center text-success mb-3 h4 w-100 mt-3 px-3">
                    Sorry this product is currently available only to UP state &
                    above RMs only
                  </label>
                </div>
                <div className="mt-4 d-flex w-100">
                  <div className="d-flex justify-content-center w-100">
                    <NormalButton
                      label="Back"
                      isPopup={true}
                      outline={true}
                      className="px-3 py-2 fs-14 line-height-26"
                      onClick={() =>
                        this.setState({
                          displayCin: false,
                          isOtp: false,
                          isCheckPopup: false,
                          isForFpo: true,
                          isForFml: false,
                          isFmlCondition: false,
                        })
                      }
                    />
                  </div>
                </div>
              </div>
            ) : isOtp ? (
              <div className="w-100 mt-3 d-flex align-items-center flex-column">
                <label className="font-weight-bold text-success mb-3 h4">
                  Mobile Verification
                </label>
                <label className="font-weight-bold mb-3 d-flex justify-content-center">
                  Enter the OTP received on your Mobile Number
                </label>
                <div className="mb-4">
                  <OtpInput
                    value={otp}
                    onChange={(otp) => {
                      this.handleInput({ target: { name: "otp", value: otp } });
                    }}
                    numInputs={6}
                    isInputNum={true}
                    separator={<span>{"\xa0\xa0"}</span>}
                    inputStyle="otp"
                  />
                  {this.validatorOtp.message(
                    "otp",
                    otp,
                    "required|min:6|max:6"
                  )}
                </div>
                <div className="d-flex justify-content-center mt-2">
                  {timeout !== 0 ? (
                    <React.Fragment>
                      <label>Please enter the OTP within</label>
                      <div className="ml-1 text-green-18 font-weight-bold">
                        {timeout}
                      </div>
                      <label>&nbsp;secs</label>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <label>
                        If you did not receive any OTP, please click
                      </label>
                      <div
                        className="ml-1 text-green-18 font-weight-bold cursor-pointer"
                        onClick={() => this.handleResend()}
                      >
                        Resend
                      </div>
                    </React.Fragment>
                  )}
                </div>
                <div className="mt-4 d-flex w-100">
                  <div className="d-flex justify-content-center w-100">
                    <NormalButton
                      label="Cancel"
                      isPopup={true}
                      outline={true}
                      className="px-3 py-2 fs-14 line-height-26 mr-3"
                      onClick={() => this.setState({ isOtp: false })}
                    />
                    <NormalButton
                      label="Proceed"
                      isPopup={true}
                      mainbg={true}
                      className="px-3 py-2 fs-14 line-height-26"
                      onClick={() => this.handleSubmitOTP()}
                    />
                  </div>
                </div>
              </div>
            ) : displayCin ? (
              <div>
                <div className="mb-2 mt-3">
                  {entityList.map((list) => (
                    <div className="custom-control custom-radio d-flex align-items-center pb-3">
                      <input
                        type="radio"
                        name="isCin"
                        value={list.entityName}
                        checked={isCin === list.entityName}
                        onChange={this.handleRadio}
                      />
                      <label className="font-weight-bold pl-1 mb-0">
                        {list.entityName}
                      </label>
                    </div>
                  ))}
                </div>
                {isCin === "Farmer Producer Company (FPC)" && (
                  <div>
                    <NormalInput
                      label="CIN"
                      onChange={this.handleInput}
                      value={cin}
                      placeholder="Enter CIN"
                      name="cin"
                    />
                    <div className="row d-flex align-items-center">
                      <div className="col-md-9 ml-auto">
                        {this.validatorCin.message(
                          "cin number",
                          cin,
                          "required|cin"
                        )}
                      </div>
                    </div>
                  </div>
                )}
                <div className="mt-4 d-flex w-100">
                  <div className="d-flex justify-content-center w-100">
                    <NormalButton
                      label="Update & Next"
                      isPopup={true}
                      mainbg={true}
                      className="px-3 py-2 fs-14 line-height-26"
                      onClick={() => this.handleCINSubmit()}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <div>
                <NormalInput
                  label="Mobile number"
                  type="number"
                  onChange={this.handleInput}
                  value={mobileNumber}
                  placeholder="Enter mobile number"
                  name="mobileNumber"
                />
                <div className="row d-flex align-items-center">
                  <div className="col-md-9 ml-auto">
                    {this.validator.message(
                      "mobile number",
                      mobileNumber,
                      "required|min:10|max:10"
                    )}
                  </div>
                </div>
                <div className="mt-4">
                  {fpoBazaar && (
                    <p className="font-weight-bold">
                      This FPO is a part of FPO Bazaar federation
                    </p>
                  )}
                  <p>
                    *Your IPL application will continue with this number. Please
                    verify your number to proceed.
                  </p>
                  <NormalButton
                    label="Verify Number with OTP"
                    mainbg={true}
                    className="px-3 py-2 mx-auto fs-14 line-height-26 font-Gilroy"
                    onClick={() => this.handleSubmit()}
                  />
                </div>
              </div>
            )}
          </div>
        </ModalBody>
      </Modal>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      updateMobileApi: updateMobile,
      updateOtpApi: updateOtp,
      resendOtpApi: resendOtp,
      getEntityListApi: getEntityList,
      updateEntityApi: updateEntity,
      getUserProfileDetailsApi: getUserProfileDetails,
      getEntityIPLUrlApi: getEntityIPLUrl,
      getAboutusMemberDetail: getAboutusMemberDetail,
    },
    dispatch
  );
};

export const IPLpopup = connect(null, mapDispatchToProps)(IPLpopupClass);
