import React, { Fragment, Component } from "react";
import { history } from "service/helpers";
import "./memberstrip.scss";
class MemberStrip extends Component {
  render() {
    const { payload, isMapping, figOptions, onSelect } = this.props;
    const { editAccess = false } = this.props;

    const tableHeader = [
      { title: "Name" },
      { title: "Mobile" },
      { title: "Village" },
      { title: "Know Your Member" },
      { title: "Percentage of Completion" },
    ];

    return (
      <>
        {isMapping ? (
          <>
            {payload.map(
              ({
                memberFirstName,
                phone,
                village,
                memberMapping,
                memberID,
              }) => {
                return (
                  <div className="member-strip shadow rounded p-3 d-flex align-items-center pr-2 w-100 mb-3">
                    <div className="row w-100">
                      <div className="col-3">
                        <div className="member-card-font-content mb-2">
                          {memberFirstName}
                        </div>
                      </div>
                      <div className="col-3">
                        <div className="member-card-font-content mb-2">
                          {phone}
                        </div>
                      </div>
                      <div className="col-2">
                        <div className="member-card-font-content mb-2 ml-3">
                          {village}
                        </div>
                      </div>
                      <div className="col-2 fig_dropdown">
                        <Fragment>
                          <select
                            placeholder="Select FIG"
                            defaultValue={
                              memberMapping ? memberMapping.figId : ""
                            }
                            onChange={(e) => onSelect(e, memberID)}
                          >
                            <option value="" disabled>
                              Select FIG
                            </option>
                            {figOptions.map(({ label, value }, index) => {
                              return (
                                <option key={index} value={value}>
                                  {label}
                                </option>
                              );
                            })}
                          </select>
                        </Fragment>
                      </div>
                    </div>
                  </div>
                );
              }
            )}
          </>
        ) : (
          <Fragment>
            {payload.length > 0 ? (
              <div className="d-flex flex-column flex-wrap mt-3 px-0 justify-content-center table_responsive">
                <table>
                  <thead>
                    <tr>
                      {tableHeader?.map((item, index) => (
                        <th key={index}>{item?.title}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {payload?.map(
                      (
                        {
                          memberID,
                          memberFirstName,
                          phone,
                          village,
                          ansquerycal,
                        },
                        index
                      ) => (
                        <React.Fragment key={index}>
                          <tr>
                            <td>{memberFirstName || "-"}</td>
                            <td>{phone || "-"}</td>
                            <td>{village || "-"}</td>
                            {editAccess && (
                              <td
                                className="cursor-pointer fs-16 edit-button"
                                onClick={() =>
                                  history.push(
                                    `/fpo/edit-member?memberID=${memberID}`
                                  )
                                }
                              >
                                View/Edit
                              </td>
                            )}
                            <td>{ansquerycal}%</td>
                          </tr>
                          <tr className="trans-bg">
                            <td className="empty-height" height="12"></td>
                          </tr>
                        </React.Fragment>
                      )
                    )}
                  </tbody>
                </table>
              </div>
            ) : (
              <div className="col-12">
                <p className="py-5 mb-0 text-black-50 fs-22 line-height-25 text-center">
                  No data found
                </p>
              </div>
            )}
          </Fragment>
        )}
      </>
    );
  }
}

export default MemberStrip;
