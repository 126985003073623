import React, { Component } from "react";
import moment from "moment";
import { history } from "service/helpers";
import { currencyConvert } from "service/helperFunctions";

const User_Profile_1 = require("assets/svg/Members/User_Profile_1.svg");

export class IncomeTdsStrip extends Component {
  render() {
    let { payload, index, editAccess } = this.props;

    const {
      id,
      member_profile,
      consultatName,
      financialYear,
      dueDate,
      tdsDeducted,
      tdsPaid,
    } = payload;
    return (
      <div
        key={index}
        className="container-sa license-strip shadow rounded mb-3"
      >
        <div className="row align-items-center h-100 px-2">
          <div className="col-1">
            <img
              className="rounded-circle income-card-profile"
              src={
                member_profile && member_profile.includes("https://")
                  ? member_profile
                  : User_Profile_1
              }
              alt="profile"
            />
          </div>
          <div className="col-2">
            <div className="fs-18 fw-600">
              {consultatName ? consultatName : "-"}
            </div>
            <div className="text-black-50">Consultant name</div>
          </div>

          <div className="col-2">
            <div className="fs-18 fw-600">{financialYear}</div>
            <div className="text-black-50">Financial year</div>
          </div>
          <div className="col-2">
            <div className="fs-18 fw-600">
              {dueDate ? moment(dueDate).format("DD MMMM YYYY") : "-"}
            </div>
            <div className="text-black-50">Due date</div>
          </div>
          <div className="col-2">
            <div className="fs-18 fw-600">
              {tdsDeducted ? currencyConvert(tdsDeducted) : "-"}
            </div>
            <div className="text-black-50">
              {"TDS deducted ( "} <span className="icon-rupee"></span> {" )"}
            </div>
          </div>
          <div className="col-2">
            <div className="fs-18 fw-600">
              {tdsPaid ? currencyConvert(tdsPaid) : "-"}
            </div>
            <div className="text-black-50">
              {"TDS paid ( "} <span className="icon-rupee"></span> {" )"}
            </div>
          </div>
          <div className="col-1 d-flex">
            <div
              className="text-black-50 cursor-pointer mr-4"
              onClick={() => history.push(`/fpo/compliance/income-tds/${id}`)}
            >
              {"View"}
            </div>
            {editAccess && (
              <div
                className="text-black-50 cursor-pointer"
                onClick={() => history.push(`/fpo/edit-incometds/${id}`)}
              >
                {"Edit"}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
