import React, { Component } from "react";
import { Link } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import {
  otpLogin,
  verifyFPO,
  googleLogin,
  getAccessDetails,
  FIGCRPOTPLogin,
} from "action/AuthAct";
import { kymOTPLogin } from "action/KYMAct";
import { connect } from "react-redux";
import { GoogleLogin } from "react-google-login";
import { bindActionCreators } from "redux";
import msIcon from "assets/svg/ms_signin_light.svg";
import { history } from "service/helpers";
import { termsandPolicyLinks } from "service/helperFunctions";
export class OtpLoginClass extends Component {
  constructor(props) {
    super();
    this.state = {
      emailOrMobNum: "",
      loginLoader: false,
      user_type: "fpo",
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleOtp = this.handleOtp.bind(this);

    this.validator = new SimpleReactValidator({
      validators: {
        email: {
          message: "must be a valid Mobile Number or Email.",
          rule: (val, params, validator) => {
            return (
              validator.helpers.testRegex(val, /^[0-9]{10}$/) ||
              validator.helpers.testRegex(
                val,
                /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}/
              )
            );
          },
          messageReplace: (message, params) =>
            message.replace("", this.helpers.toSentence(params)),
          required: true,
        },
        element: (message) => (
          <span className="error-message font-md">{message}</span>
        ),
        autoForceUpdate: this,
      },
    });
  }

  componentDidMount() {
    let url_string = window.location.href;
    let url = new URL(url_string);
    let token = url.searchParams.get("token");
    if (token) {
      localStorage.setItem("AuthToken", token);
      this.props
        .verifyFPO()
        .then((data) => {
          const { userRoles } = data;
          localStorage.setItem("fpoId", data?.fpoId);
          localStorage.setItem(
            "userGroupName",
            userRoles?.userGroup?.userGroupName
          );
          localStorage.setItem("userName", data.userName);
          localStorage.setItem(
            "isMandi",
            parseInt(data?.isMandiProc) === 1 ? true : false
          );
          localStorage.setItem("userRoleName", userRoles?.userRoleName);
          if (localStorage.getItem("userGroupName") == "fpo") {
            localStorage.setItem(
              "isLightHouse",
              data.LHFPO_IND ? data.LHFPO_IND : false
            );
          }
          localStorage.getItem("userRoleName") == "Relationship Manager"
            ? history.push("/rm/home")
            : localStorage.getItem("userGroupName") == "fpo"
            ? history.push("/fpo/home")
            : history.push("/other/home");
          if (userRoles?.userGroup?.userGroupName == "fpo") {
            this.props.getAccessDetails();
          }
        })
        .catch(() => {
          history.push("/");
          localStorage.clear();
        });
    }
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleOtp = (e, user_type) => {
    e.preventDefault();
    let body;
    let api;
    let { emailOrMobNum } = this.state;
    if (user_type !== "fpo") {
      if (emailOrMobNum?.length === 10) {
        this.validator.fields.mobile_number_or_email = true;
        this.validator.errorMessages.mobile_number_or_email = null;
      }
    }
    if (this.validator.allValid()) {
      let { otpLogin, FIGCRPOTPLogin, kymOTPLogin } = this.props;
      if (user_type === "fpo") {
        body = {
          emailOrMobNum: emailOrMobNum,
          isEmail: emailOrMobNum.includes("@") ? true : false,
        };
      } else if (user_type === "kym") {
        body = {
          mobileNumber: emailOrMobNum,
        };
      } else {
        body = {
          emailOrMobNum: emailOrMobNum,
          isEmail: false,
          userTypeRole: user_type === "crp" ? "crp" : "fig",
        };
      }
      this.setState({ loginLoader: true });
      if (user_type === "fpo") {
        api = otpLogin(body);
      } else if (user_type === "kym") {
        api = kymOTPLogin(body);
      } else {
        api = FIGCRPOTPLogin(body);
      }
      api
        .then((data) => {
          this.setState({ loginLoader: false });
          this.props.toggleOtpAuth(emailOrMobNum, user_type);
        })
        .catch(() => {
          this.setState({ loginLoader: false });
        });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  responseGoogle = (response) => {
    let body = {
      email: response.profileObj.email,
      idToken: response.tokenId,
    };
    this.props.googleLogin(body).then((data) => {
      localStorage.setItem("AuthToken", data.token);
      const { userRoles } = data;
      localStorage.setItem(
        "userGroupName",
        userRoles?.userGroup?.userGroupName
      );
      localStorage.setItem("isKymUser", data.isKym == 1 ? true : false);
      localStorage.setItem(
        "isLightHouse",
        data.LHFPO_IND ? data.LHFPO_IND : false
      );
      localStorage.setItem("userRoleName", userRoles?.userRoleName);
      localStorage.setItem("userName", data.userName);
      localStorage.getItem("userRoleName") == "Relationship Manager"
        ? history.push("/rm/home")
        : localStorage.getItem("userGroupName") == "fpo"
        ? history.push("/fpo/home")
        : history.push("/other/home");
      if (userRoles?.userGroup?.userGroupName == "fpo") {
        this.props.getAccessDetails();
      }
    });
  };
  responseGoogleFailure = (error) => {
    console.log(error);
  };

  handleRadio = (e, isLabel = false) => {
    const value = isLabel ? e : e.target.value;
    this.setState({
      emailOrMobNum: "",
      user_type: value,
    });
  };

  render() {
    let { emailOrMobNum, loginLoader, user_type } = this.state;

    return (
      <div className="w-100 fig-crp-login">
        <div className="d-flex justify-content-center flex-row mb-2 flex-wrap">
          <div className="custom-control custom-radio d-flex align-items-center pb-3">
            <input
              type="radio"
              id="fpo"
              name="user_type"
              value="fpo"
              checked={user_type === "fpo"}
              onChange={(e) => this.handleRadio(e)}
            />
            <label
              className="font-weight-bold pl-1 mb-0"
              onClick={() => this.handleRadio("fpo", true)}
            >
              FPO
            </label>
          </div>
          <div className="custom-control custom-radio d-flex align-items-center pb-3">
            <input
              type="radio"
              id="rm"
              name="user_type"
              value="rm"
              checked={user_type === "rm"}
              onChange={(e) => this.handleRadio(e)}
            />
            <label
              className="font-weight-bold pl-1 mb-0"
              onClick={() => this.handleRadio("rm", true)}
            >
              Samunnati RM
            </label>
          </div>
          <div className="custom-control custom-radio d-flex align-items-center pb-3">
            <input
              type="radio"
              id="kym"
              name="user_type"
              value="kym"
              checked={user_type === "kym"}
              onChange={(e) => this.handleRadio(e)}
            />
            <label
              className="font-weight-bold pl-1 mb-0"
              onClick={() => this.handleRadio("kym", true)}
            >
              KYM User
            </label>
          </div>
          {/* <div className="custom-control custom-radio d-flex align-items-center pb-3">
            <input
              type="radio"
              id="crp"
              name="user_type"
              value="crp"
              checked={user_type === "crp"}
              onChange={this.handleRadio}
            />
            <label className="font-weight-bold pl-1 mb-0">CRP</label>
          </div>
          <div className="custom-control custom-radio d-flex align-items-center pb-3">
            <input
              type="radio"
              id="fig"
              name="user_type"
              value="fig"
              checked={user_type === "fig"}
              onChange={this.handleRadio}
            />
            <label className="font-weight-bold pl-1 mb-0">FIG</label>
          </div> */}
        </div>
        {user_type === "rm" ? (
          <div className="text-center my-md-4 cursor-pointer">
            <img
              src={msIcon}
              alt="msIcon"
              onClick={() =>
                window.open(
                  `https://login.microsoftonline.com/${process.env.REACT_APP_MSID_PREPROD}/oauth2/v2.0/authorize?client_id=${process.env.REACT_APP_MSCLIENTID_PREPROD}&response_type=code&redirect_uri=https://fpogateway.com/adSsoToken&response_mode=query&scope=https://graph.microsoft.com/User.Read&state=1234`,
                  "_blank"
                )
              }
            />
          </div>
        ) : (
          <>
            <label className="font-weight-bold" htmlFor="mobileOrEmail">
              Mobile Number{" "}
              {/* {(user_type === "fpo" || user_type === "rm") && "or Email address"} */}
            </label>
            <input
              type="text"
              className="form-control w-100 bg-light"
              id="mobileOrEmail"
              name="emailOrMobNum"
              value={emailOrMobNum}
              onChange={this.handleChange}
              maxLength={user_type !== "fpo" ? 10 : null}
            />
            {this.validator.message(
              user_type === "fpo" ? "mobile_number_or_email" : "mobile_number",
              emailOrMobNum,
              user_type === "fpo"
                ? "required|email|min:7|max:50"
                : "required|numeric|min:10|max:10",
              { className: "text-danger" }
            )}
            <div className="mt-3 d-flex justify-content-center">
              <button
                className="btn w-100 py-2 font-weight-bold mainbg-btn"
                disabled={loginLoader}
                onClick={(e) => this.handleOtp(e, user_type)}
              >
                Login with Mobile/OTP{" "}
                {loginLoader ? (
                  <div
                    className="spinner-border ml-2 spinner-border-sm"
                    role="status"
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                ) : null}
              </button>
            </div>
            {user_type === "fpo" && (
              <>
                <div className="d-flex justify-content-center">
                  <label className="my-2">or</label>
                </div>
                <div className="d-flex justify-content-center">
                  <button
                    className="btn w-100 py-2 font-weight-bold mainbg-btn outline-btn"
                    onClick={this.props.togglePass}
                  >
                    Login with Email/Password
                  </button>
                </div>
                <GoogleLogin
                  className="btn-google w-100 d-flex justify-content-center my-4"
                  clientId="1023428040603-1pca6qha0s3tckomh9arj1a1gj3ibund.apps.googleusercontent.com"
                  buttonText="Login with Google"
                  onSuccess={this.responseGoogle}
                  onFailure={this.responseGoogleFailure}
                />
              </>
            )}
          </>
        )}
        {/* {user_type !== "kym" && (
          <div className="d-flex justify-content-center mt-2">
            <label>New to FPO Gateway?</label>
            <label className="ml-1"> Register</label>
            <Link className="ml-1 text-dark font-weight-bold" to={"/register"}>
              {" "}
              <u>here</u>
            </Link>
          </div>
        )} */}
        {termsandPolicyLinks()}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      otpLogin,
      verifyFPO,
      googleLogin,
      getAccessDetails,
      FIGCRPOTPLogin,
      kymOTPLogin,
    },
    dispatch
  );
};

let component = OtpLoginClass;

export const OtpLogin = connect(null, mapDispatchToProps)(component);
