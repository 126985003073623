import React, { Component } from "react";
import SimpleReactValidator from "simple-react-validator";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  memberBasicAdd,
  getDistrictApiwithState,
  allState,
  fileUpload,
  getPincodeApiwithStateDistrict,
  memberEdit,
} from "action/MemberAct";
import moment from "moment";

import { NormalInput, NormalSelect, NormalDate } from "component/common";

import { Toast } from "service/toast";

import "react-datepicker/dist/react-datepicker.css";
import upload from "assets/svg/add_member/upload.svg";
const arrow_up = require("../../../assets/svg/add_member/arrow_up.svg");

class BasicForm extends Component {
  constructor(props) {
    super();
    this.state = {
      first_name: "",
      last_name: "",
      gender: "",
      address: "",
      pincode: "",
      phone_number: "",
      smart_phone: "",
      dob: "",
      village: "",
      district: "",
      state: "",
      startDate: new Date(),
      profile_photo: null,
      profilePicName: "",
      isProfilePicUploading: false,
      address_proof: null,
      address_proof_name: "",
      isaddressProofUploading: false,
      identity_proof: null,
      identity_proof_name: "",
      isIdentityProofUploading: false,
      allState: [],
      allDistrict: [],
      allPincode: [],
      isDataUploading: false,
      genderOption: [
        { value: "Male", label: "Male" },
        { value: "Female", label: "Female" },
        { value: "Others", label: "Others" },
      ],
      smartPhoneOptions: [
        { value: "Yes", label: "Yes" },
        { value: "No", label: "No" },
      ],
      memberID: null,
    };
  }

  validator = new SimpleReactValidator({
    element: (message) => (
      <span className="error-message text-danger fs-14">{message}</span>
    ),
    autoForceUpdate: this,
  });

  handleChange = ({ target: { name, value } }) => {
    if (name === "state") {
      this.setState(
        {
          [name]: value,
          district: "",
          // pincode: "",
        },
        () => {
          this.props.getDistrictApiwithState({ id: value }).then((data) => {
            let allDistrictOption = [];
            data.map((list) => {
              allDistrictOption.push({
                label: list.districtName,
                value: list.districtId,
              });
            });
            this.setState({
              allDistrict: allDistrictOption,
            });
          });
        }
      );
    } else if (name === "district") {
      this.setState(
        {
          [name]: value,
          // pincode: "",
        },
        () => {
          // this.props
          //   .getPincodeApiwithStateDistrict({ id: value })
          //   .then((data) => {
          //     let allPincodeOption = [];
          //     data.map((list) => {
          //       allPincodeOption.push({
          //         label: list.pincode,
          //         value: list.pincodeId,
          //       });
          //     });
          //     this.setState({
          //       allPincode: allPincodeOption,
          //     });
          //   });
        }
      );
    } else {
      this.setState({
        [name]: value,
      });
    }
  };

  uploadProfilePicture = (e) => {
    //upload profile picture
    let file = e.target.files[0];
    if (file !== undefined) {
      this.setState({ profile_photo: null }, () => {
        this.setState({
          isProfilePicUploading: true,
          profilePicName: "Uploading ....",
          profile_photo: file,
        });
        const formData = new FormData();
        formData.append("file", file);

        this.props
          .fileUpload(formData)
          .then((data) => {
            document.getElementById("uploadProfilePicture").value = null;
            this.setState({
              profile_photo: data[0].fileUrl,
              profilePicName: data[0].fileName,
              isProfilePicUploading: false,
            });
          })
          .catch(() => {
            this.setState({
              profile_photo: null,
              profilePicName: "",
              isProfilePicUploading: false,
            });
          });
      });
    }
  };

  handleAddressProofFile = (e) => {
    //upload address proof
    let file = e.target.files[0];
    if (file !== undefined) {
      this.setState({ address_proof: null }, () => {
        this.setState({
          isaddressProofUploading: true,
          address_proof_name: "Uploading ....",
          address_proof: file,
        });
        const formData = new FormData();
        formData.append("file", file);

        this.props
          .fileUpload(formData)
          .then((data) => {
            document.getElementById("handleAddressProofFile").value = null;
            this.setState({
              address_proof: data[0].fileUrl,
              address_proof_name: data[0].fileName,
              isaddressProofUploading: false,
            });
          })
          .catch(() => {
            this.setState({
              address_proof: null,
              address_proof_name: "",
              isaddressProofUploading: false,
            });
          });
      });
    }
  };

  handleIdentityProofFile = (e) => {
    //upload identity proof
    let file = e.target.files[0];
    if (file !== undefined) {
      this.setState({ identity_proof: null }, () => {
        this.setState({
          isIdentityProofUploading: true,
          identity_proof_name: "Uploading ....",
          identity_proof: file,
        });
        const formData = new FormData();
        formData.append("file", file);

        this.props
          .fileUpload(formData)
          .then((data) => {
            document.getElementById("handleIdentityProofFile").value = null;
            this.setState({
              identity_proof: data[0].fileUrl,
              identity_proof_name: data[0].fileName,
              isIdentityProofUploading: false,
            });
          })
          .catch(() => {
            this.setState({
              identity_proof: null,
              identity_proof_name: "",
              isIdentityProofUploading: false,
            });
          });
      });
    }
  };

  handleSave = (e) => {
    if (this.validator.allValid()) {
      const {
        first_name,
        last_name,
        gender,
        address,
        pincode,
        phone_number,
        smart_phone,
        dob,
        village,
        district,
        state,
        profile_photo,
        identity_proof,
        address_proof,
        profilePicName,
        address_proof_name,
        identity_proof_name,
        isProfilePicUploading,
        isaddressProofUploading,
        isIdentityProofUploading,
        memberID,
      } = this.state;
      if (
        !isProfilePicUploading &&
        !isaddressProofUploading &&
        !isIdentityProofUploading
      ) {
        this.setState({ isDataUploading: true });
        let body = {
          memberFirstName: first_name,
          village: village,
          // memberLastName: last_name,
          // gender: gender,
          // phone: phone_number,
        };
        if (last_name !== "") {
          body.memberLastName = last_name;
        }
        if (gender !== "") {
          body.gender = gender;
        }
        if (phone_number !== "") {
          body.phone = phone_number;
        }
        if (identity_proof_name !== "") {
          body.identityProofDocumentUrl = identity_proof;
          body.identityProofName = identity_proof_name;
        }
        if (address_proof_name !== "") {
          body.addressProofDocumentUrl = address_proof;
          body.addressProofName = address_proof_name;
        }
        if (profilePicName !== "") {
          body.memberPhotoUrl = profile_photo;
          body.memberPhotoName = profilePicName;
        }
        if (smart_phone !== "") {
          body.hasSmartPhone = smart_phone;
        }
        // if (village !== "") {
        //   body.village = village;
        // }
        if (dob !== "") {
          body.memberDOB = moment(dob).format("YYYY-MM-DD");
        }
        if (address !== "") {
          body.address = address;
        }
        if (state != "") {
          body.stateId = parseInt(state);
        }
        if (district !== "") {
          body.districtId = parseInt(district);
        }
        if (pincode !== "") {
          // body.pincodeId = parseInt(pincode);
          body.pincode = parseInt(pincode);
        }
        if (memberID) {
          body.memberID = parseInt(memberID);
          this.props
            .memberEdit(body)
            .then(() => {
              this.validator.hideMessages();
              this.props.setmemberId(memberID);
              this.props.toggleCollapse(
                false,
                true,
                false,
                false,
                false,
                false
              );
              this.setState({ isDataUploading: false });
            })
            .catch(() => {
              this.setState({ isDataUploading: false });
            });
        } else {
          this.props
            .memberBasicAdd(body)
            .then((data) => {
              if (data.status === 200) {
                this.validator.hideMessages();
                // this._clearData();
                this.setState({ memberID: data.data.memberID });
                this.props.setmemberId(data.data.memberID);
                this.props.toggleCollapse(
                  false,
                  true,
                  false,
                  false,
                  false,
                  false
                );
              }
              this.setState({ isDataUploading: false });
            })
            .catch(() => {
              this.setState({ isDataUploading: false });
            });
        }
      } else {
        Toast({ type: "error", message: "Please wait Image is uploading" });
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  // _clearData = () => {
  //   this.setState({
  //     first_name: "",
  //     last_name: "",
  //     gender: "",
  //     address: "",
  //     pincode: "",
  //     phone_number: "",
  //     smart_phone: "",
  //     dob: "",
  //     village: "",
  //     district: "",
  //     state: "",
  //     startDate: new Date(),
  //     profile_photo: null,
  //     profilePicName: "",
  //     address_proof: null,
  //     address_proof_name: "",
  //     identity_proof: null,
  //     identity_proof_name: "",
  //     isDataUploading: false,
  //   });
  // };

  componentDidMount() {
    this.props.allState().then((data) => {
      let allStateOption = [];
      data.map((list) => {
        allStateOption.push({ label: list.stateName, value: list.stateId });
      });
      this.setState({
        allState: allStateOption,
      });
    });
  }

  handleRemoveImage = (picName) => {
    if (picName === "profile") {
      this.setState({
        profile_photo: null,
        profilePicName: "",
      });
    } else if (picName === "addressProof") {
      this.setState({
        address_proof: null,
        address_proof_name: "",
      });
    } else if (picName === "identityProof") {
      this.setState({
        identity_proof: null,
        identity_proof_name: "",
      });
    }
  };

  render() {
    const {
      first_name,
      last_name,
      gender,
      address,
      pincode,
      phone_number,
      smart_phone,
      dob,
      village,
      district,
      state,
      allDistrict,
      allState,
      allPincode,
      profile_photo,
      identity_proof,
      address_proof,
      profilePicName,
      address_proof_name,
      identity_proof_name,
      genderOption,
      smartPhoneOptions,
      isDataUploading,
      isProfilePicUploading,
      isaddressProofUploading,
      isIdentityProofUploading,
    } = this.state;
    return (
      <div className="px-md-5 px-3 py-4 add-member-area">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <div className="add-member-form-container-heading">
            Member profile
          </div>
          <div className="d-flex justify-content-end align-items-center">
            {isDataUploading ? (
              <div className="add-member-form-container-button">
                Save & next{" "}
              </div>
            ) : (
              <div
                className="add-member-form-container-button cursor-pointer"
                onClick={this.handleSave}
              >
                {"Save & next"}
              </div>
            )}

            <img
              className="ml-3 cursor-pointer"
              src={arrow_up}
              alt="arrow_up"
              height="10"
              width="10"
              name="basic_profile"
              onClick={this.props.collapse}
            />
          </div>
        </div>
        <div>
          <div className="row">
            <div className="col-md-6">
              <NormalInput
                isHalfWidthForMemberProfile={true}
                label="First name"
                type="text"
                onChange={this.handleChange}
                value={first_name}
                name="first_name"
                required={true}
              />
              <div className="row">
                <div className="col-md-9 ml-auto">
                  {this.validator.message(
                    "first_name",
                    first_name,
                    "required|alpha_space|min:3|max:20"
                  )}
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <NormalInput
                isHalfWidthForMemberProfile={true}
                label="Phone number"
                type="text"
                onChange={this.handleChange}
                value={phone_number}
                name="phone_number"
                // required={true}
                max={10}
              />
              <div className="row">
                <div className="col-md-9 ml-auto">
                  {this.validator.message(
                    "phone_number",
                    phone_number,
                    "phone"
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <NormalInput
                isHalfWidthForMemberProfile={true}
                label="Last name"
                type="text"
                onChange={this.handleChange}
                value={last_name}
                name="last_name"
                // required={true}
              />
              {/* <div className="row">
                <div className="col-9 ml-auto">
                  {this.validator.message(
                    "last_name",
                    last_name,
                    "required|alpha_space|min:1|max:20"
                  )}
                </div>
              </div> */}
            </div>
            <div className="col-md-6">
              <NormalSelect
                isHalfWidthForMemberProfile={true}
                label="Smart phone"
                placeholder="Select"
                value={smart_phone}
                arrow={true}
                name="smart_phone"
                options={smartPhoneOptions}
                handleChange={this.handleChange}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <NormalSelect
                isHalfWidthForMemberProfile={true}
                label="Gender"
                placeholder="Select"
                value={gender}
                arrow={true}
                name="gender"
                options={genderOption}
                handleChange={this.handleChange}
                // required={true}
              />
              {/* <div className="row">
                <div className="col-md-9 ml-auto">
                  {this.validator.message("gender", gender, "required")}
                </div>
              </div> */}
            </div>
            <div className="col-md-6">
              <NormalDate
                isIcon={true}
                isHalfWidthForMemberProfile={true}
                value={dob}
                name="dob"
                placeholder="DD/MM/YYYY"
                onChange={this.handleChange}
                mindate={false}
                maxdate={true}
                timeInput={false}
                label={"DOB"}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="row mt-3 pt-1">
                <div className="col-md-3">
                  <div className="add-member-form-container-title mb-md-0 mb-2">
                    Profile photo{" "}
                  </div>
                </div>
                <div className="col-md-7">
                  <div className="w-100">
                    <input
                      className="w-50 h-100 position-absolute opacity-0"
                      type="file"
                      name="profile_photo"
                      accept=".jpg, .png, .jpeg"
                      onChange={this.uploadProfilePicture}
                      id="uploadProfilePicture"
                      title=""
                    />
                    <div className="custom-file-upload">Choose file</div>
                    {profile_photo != null && (
                      <div className="d-flex align-items-center">
                        {profilePicName}
                        {!isProfilePicUploading ? (
                          <i
                            className="icon-Delete fs-14 pl-2 cursor-pointer"
                            onClick={() => this.handleRemoveImage("profile")}
                          ></i>
                        ) : null}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="row mt-3 pt-1">
                <div className="col-md-3">
                  <div className="add-member-form-container-title mb-md-0 mb-2">
                    Address{" "}
                  </div>
                </div>
                <div className="col-md-8">
                  <textarea
                    className="w-100 h-100"
                    type="textarea"
                    name="address"
                    value={address}
                    onChange={this.handleChange}
                    rows="2"
                    cols="50"
                  ></textarea>
                  {this.validator.message("address", address, "max:100")}
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <NormalInput
                isHalfWidthForMemberProfile={true}
                label="Village"
                type="text"
                onChange={this.handleChange}
                value={village}
                name="village"
                required={true}
              />
              <div className="row">
                <div className="col-md-9 ml-auto">
                  {this.validator.message(
                    "village",
                    village,
                    "required|max:25|alpha_space"
                  )}
                </div>
              </div>
              <NormalSelect
                isHalfWidthForMemberProfile={true}
                label="State"
                placeholder="Select"
                value={state}
                arrow={true}
                name="state"
                options={allState}
                handleChange={this.handleChange}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <NormalSelect
                isHalfWidthForMemberProfile={true}
                label="District"
                placeholder="Select"
                value={district}
                arrow={true}
                name="district"
                options={allDistrict}
                handleChange={this.handleChange}
                disabled={state == "" ? true : false}
              />
            </div>
            <div className="col-md-6">
              <NormalInput
                isHalfWidthForMemberProfile={true}
                label="Pincode"
                type="text"
                onChange={this.handleChange}
                value={pincode}
                name="pincode"
                max={6}
                required={false}
              />
              <div className="row">
                <div className="col-md-9 ml-auto">
                  {this.validator.message("pincode", pincode, "numeric|min:6")}
                </div>
              </div>
              {/* <NormalSelect
                isHalfWidthForMemberProfile={true}
                label="Pincode"
                placeholder="Select"
                value={pincode}
                arrow={true}
                name="pincode"
                options={allPincode}
                disabled={state != "" && district != "" ? false : true}
                handleChange={this.handleChange}
              /> */}
            </div>
          </div>
          <div>
            <div className="add-member-form-container-proof mt-4 pt-3">
              {"Proof"}
            </div>
            <div className="d-flex flex-md-row flex-column">
              <div className="mr-md-5 mr-0">
                <div className="d-flex justify-content-between align-items-center mb-2 pb-1">
                  <div className="add-member-form-container-proof-sub mb-md-0 mb-2">
                    {"Address proof"}
                  </div>
                  {address_proof != null ? (
                    <div>
                      {!isaddressProofUploading ? (
                        <i
                          className="icon-Delete fs-14 cursor-pointer"
                          onClick={() => this.handleRemoveImage("addressProof")}
                        ></i>
                      ) : null}
                    </div>
                  ) : null}
                </div>
                <div className="add-member-form-container-proof-container d-flex justify-content-center align-items-center mb-md-0 mb-4">
                  <input
                    type="file"
                    accept=".jpg, .pdf, .jpeg"
                    name="address_proof"
                    onChange={this.handleAddressProofFile}
                    id="handleAddressProofFile"
                    title=""
                  />

                  {address_proof != null ? (
                    <div className="h-100">
                      <img
                        src={address_proof}
                        alt={address_proof_name}
                        className="h-100"
                      />
                    </div>
                  ) : (
                    <>
                      <img className="mr-2" src={upload} alt="upload" />
                      {"Upload files"}
                    </>
                  )}
                </div>
              </div>
              <div>
                <div className="d-flex justify-content-between align-items-center mb-2 pb-1">
                  <div className="add-member-form-container-proof-sub mb-md-0 mb-2">
                    {"Identity proof"}
                  </div>
                  {identity_proof != null ? (
                    <div>
                      {!isIdentityProofUploading ? (
                        <i
                          className="icon-Delete fs-14 cursor-pointer"
                          onClick={() => this.handleRemoveImage("addressProof")}
                        ></i>
                      ) : null}
                    </div>
                  ) : null}
                </div>
                <div className="add-member-form-container-proof-container d-flex justify-content-center align-items-center">
                  <input
                    type="file"
                    accept=".jpg, .pdf, .jpeg"
                    name="identity_proof"
                    onChange={this.handleIdentityProofFile}
                    id="handleIdentityProofFile"
                    title=""
                  />
                  {identity_proof !== null ? (
                    <div className="h-100">
                      <img
                        src={identity_proof}
                        alt={identity_proof_name}
                        className="h-100"
                      />
                    </div>
                  ) : (
                    <>
                      <img className="mr-2" src={upload} alt="upload" />
                      {"Upload files"}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      memberBasicAdd,
      getDistrictApiwithState,
      allState,
      getPincodeApiwithStateDistrict,
      fileUpload,
      memberEdit,
    },
    dispatch
  );
};
let component = BasicForm;

export const Basic = connect(null, mapDispatchToProps)(component);
