import React, { Component } from "react";
import infoMan from "../../../assets/images/academy/info-man.png";
import infothumb from "../../../assets/images/academy/info-thumb.png";
import "./AcadamyInfo.scss";
import play from "../../../assets/images/icon/play.svg";
import { Modal, ModalBody } from "reactstrap";
import { getDescriptionData } from "service/helperFunctions";
import { history } from "service/helpers";
import VideoPlayer from "react-video-js-player";
export class AcadamyInfo extends Component {
  constructor() {
    super();
    this.player = {};
    this.state = {
      modal: false,
      videoDuration: 0,
    };
    this.myref = React.createRef();
  }

  onPlayerReady(player, duration) {
    player.currentTime(duration);

    this.player = player;
  }
  onVideoPlay(duration) {
    this.setState({
      videoDuration: duration,
    });
  }

  toggle = () => {
    this.setState({ modal: !this.state.modal });
  };
  seek(secs) {
    let time = this.player.currentTime() + secs;

    if (time < 0) {
      time = 0;
    }

    this.player.currentTime(time);
  }
  render() {
    let { modal, player, videoDuration } = this.state;
    const { className, introducingVideoData = {}, blogList = [] } = this.props;

    let { title = "", videoUrl = "", description = "" } = introducingVideoData;

    return (
      <>
        <section className="academy-info">
          <div className="container-sa">
            <div className="row">
              <div className="col-md-9">
                <div className="info-lg-card">
                  <div className="content-info d-flex flex-column justify-content-between">
                    <div>
                      <h3>{getDescriptionData(title, 30)}</h3>
                      <p className="text-black fs-18 mb-2">
                        {getDescriptionData(description, 50)}
                      </p>
                    </div>
                    <div>
                      <p
                        className="text-black font-weight-bold cursor-pointer mb-0"
                        onClick={() => this.toggle()}
                      >
                        {"View"}
                      </p>
                    </div>
                  </div>
                  <div className="img-video-play">
                    <img src={infoMan} alt="infoMan" />
                    <div className="play-button" onClick={() => this.toggle()}>
                      <img
                        className="play-icon"
                        src={play}
                        alt="play"
                        srcSet=""
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                {blogList.length !== 0 ? (
                  <div className="info-sm-card">
                    <div className="thumb-info">
                      <img
                        src={
                          blogList[0].blogImageFileUrl
                            ? blogList[0].blogImageFileUrl
                            : infothumb
                        }
                      />
                    </div>
                    <div className="info-sm-content">
                      <div className="d-flex flex-column justify-content-between h-100">
                        <div>
                          <h5>
                            {blogList[0].title &&
                              getDescriptionData(blogList[0].title, 20)}
                          </h5>
                          <p>
                            {blogList[0].shortDescription &&
                              getDescriptionData(
                                blogList[0].shortDescription,
                                40
                              )}
                          </p>
                        </div>
                        <div className="text-right">
                          <p
                            className="fs-16 text-black-50 fw-500 cursor-pointer mb-0"
                            onClick={() =>
                              history.push(
                                `/academy/blog/${blogList[0].blogId}`
                              )
                            }
                          >
                            Read more
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          <Modal
            isOpen={modal}
            toggle={this.toggle}
            className={
              className + " " + "popupVideo d-flex justify-content-center"
            }
          >
            <button onClick={this.toggle} type="button" className="close ">
              <span aria-hidden="true">×</span>
            </button>

            <ModalBody>
              <VideoPlayer
                ref={this.myref}
                controls={true}
                src={videoUrl}
                onReady={this.onPlayerReady.bind(this)}
                onTimeUpdate={this.onVideoPlay.bind(this)}
              />
              {/* <div>
                        {videoDuration > 5 && (
                           <div
                              className='button-backward'
                              onClick={() => this.seek(-5)}
                           >
                              {'<<'}
                           </div>
                        )}
                        <div
                           className='button-forward'
                           onClick={() => this.seek(5)}
                        >
                           {'>>'}
                        </div>
                     </div> */}
            </ModalBody>
          </Modal>
        </section>
      </>
    );
  }
}
