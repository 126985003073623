import React, { Component } from "react";
import "./forgotpassword.scss";
import SimpleReactValidator from "simple-react-validator";

import { forgotPasswordLink } from "action/AuthAct";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { history } from "service/helpers";

const forgot_pass = require("assets/svg/forgot_pass.svg");
export class ForgotPasswordClass extends Component {
  constructor() {
    super();
    this.state = {
      emailOrMobNum: "",
      sent: false,
      link: "",
    };
    this.validator = new SimpleReactValidator({
      validators: {
        email: {
          message: "must be a valid mobile number or Email.",
          rule: (val, params, validator) => {
            return (
              validator.helpers.testRegex(val, /^[0-9]{10}$/) ||
              validator.helpers.testRegex(
                val,
                /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}/
              )
            );
          },
          messageReplace: (message, params) =>
            message.replace("", this.helpers.toSentence(params)),
          required: true,
        },
        element: (message) => (
          <span className="error-message font-md">{message}</span>
        ),
        autoForceUpdate: this,
      },
    });
  }
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  handleLink = () => {
    //call reset link api
    const { emailOrMobNum } = this.state;
    let body = {
      emailOrMobNum: emailOrMobNum,
      isEmail: emailOrMobNum.includes("@") ? true : false,
    };
    this.props.forgotPasswordLink(body);
  };
  handleSubmit = (e) => {
    e.preventDefault();
    if (this.validator.allValid()) {
      const { emailOrMobNum } = this.state;
      let body = {
        emailOrMobNum: emailOrMobNum,
        isEmail: emailOrMobNum.includes("@") ? true : false,
      };
      this.props.forgotPasswordLink(body).then(() => {
        this.setState({
          sent: true,
        });
      });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };
  render() {
    const { emailOrMobNum, sent } = this.state;
    return (
      <div className="w-100 mt-3 d-flex align-items-center flex-column forgot-password">
        <label className="font-weight-bold text-success mb-4 h4">
          Forgot Password
        </label>
        {!sent ? (
          <>
            <label className="font-weight-bold mb-4 text-center forgot-password-msg text-break">
              Enter your email id to get the reset password link
            </label>
            <div className="w-100">
              <label className="font-weight-bold" htmlFor="mobileOrEmail">
                Email
              </label>
              <input
                type="text"
                className="form-control w-100 bg-light"
                id="emailOrMobNum"
                name="emailOrMobNum"
                value={emailOrMobNum}
                onChange={this.handleChange}
              />
              {this.validator.message(
                "email or mobile number",
                emailOrMobNum,
                "required|email|min:7|max:50",
                {
                  className: "text-danger",
                }
              )}
            </div>
            <div className="d-flex mt-5 w-100">
              <input
                className="btn w-100 py-2 font-weight-bold"
                type="submit"
                value="Next"
                onClick={this.handleSubmit}
              />
            </div>
            <div>
              <p className="text-center fs-18 pt-3 ">
                Already a member?{" "}
                <b
                  className="cursor-pointer"
                  onClick={() => {
                    history.push("/login");
                  }}
                >
                  <u>Log in</u>
                </b>
              </p>
            </div>
          </>
        ) : (
          <>
            <label className="font-weight-bold mb-5 text-center forgot-password-msg text-break">
              We have sent reset link to your registered {emailOrMobNum}{" "}
              {emailOrMobNum.includes("@") ? "email id" : "mobile number"}
            </label>

            <div className="w-100 d-flex justify-content-center mb-5">
              <img src={forgot_pass} alt="forgot" />
            </div>
            <div
              className="mb-4 d-flex justify-content-center flex-column"
              onClick={this.props.toggleForgot}
            >
              <div>
                <sapn>{"If you didn’t recevie a link!"}</sapn>
                <span
                  className="pass-login-link ml-2"
                  onClick={this.handleLink}
                >
                  {"Resend"}
                </span>
              </div>
              <div>
                <p className="text-center fs-18 pt-3 ">
                  Already a member?{" "}
                  <b
                    className="cursor-pointer"
                    onClick={() => {
                      history.push("/login");
                    }}
                  >
                    <u>Log in</u>
                  </b>
                </p>
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      forgotPasswordLink,
    },
    dispatch
  );
};

let component = ForgotPasswordClass;

export const ForgotPassword = connect(null, mapDispatchToProps)(component);
