import React, { Component } from "react";
import { Navbar, CommonPageLoader } from "component/common";
import "./Stories.scss";
import { CreateEvents } from "./create_events";
import { YourEvents } from "./your_events/your_events";
import { AwardsOtherFpos } from "../all_events/awards_other_fpos/AwardsOtherFpos";
import { OtherSuccess } from "../all_events/other_success/OtherSuccess";
import { UpcomingEvents } from "../all_events/UpcomingEvents/UpcomingEvents";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  getFpoAllEventsByUser,
  deleteAwards,
  deleteStories,
  deleteEvents,
} from "action/eventsAndImpactStories";

import { getEventsandImpactsOfFPOById } from "action/RmAct";

class StoriesClass extends Component {
  state = {
    allstoriesList: [],
    isLoader: false,
    eventId: "",
    isCreatModal: false,
    tabType: "",
    awardId: "",
    storyId: "",
  };

  //Lifecycles
  componentDidMount = () => {
    this.getAllEvent();
  };

  //getevent List
  getAllEvent = () => {
    this.setState({ isLoader: true });
    let { id, getFpoAllEventsByUser, getEventsandImpactsOfFPOById } =
      this.props;
    if (id) {
      getEventsandImpactsOfFPOById(id)
        .then((data) => {
          this.setState({
            isLoader: false,
            allstoriesList: data,
          });
        })
        .catch(() => {
          this.setState({ isLoader: false });
        });
    } else {
      getFpoAllEventsByUser()
        .then(({ status, data }) => {
          this.setState({ isLoader: false });
          if (status === 200) {
            this.setState({ allstoriesList: data });
          }
        })
        .catch(() => {
          this.setState({ isLoader: false });
        });
    }
  };

  //handleGetEventId
  handleGetUpdateId = (id, tabType) => {
    if (tabType === "event") {
      this.setState({ eventId: id, isCreatModal: !!id, tabType });
    } else if (tabType === "awards") {
      this.setState({ awardId: id, isCreatModal: !!id, tabType });
    } else if (tabType === "stories") {
      this.setState({ storyId: id, isCreatModal: !!id, tabType });
    }
  };

  //delete awards
  handleDeleteAwards = (id) => {
    this.props.deleteAwards(id).then(() => {
      this.getAllEvent();
    });
  };

  //delete success stories
  handleDeleteStories = (id) => {
    this.props.deleteStories(id).then(() => {
      this.getAllEvent();
    });
  };

  //delete events
  handleDeleteEvents = (id) => {
    this.props.deleteEvents(id).then(() => {
      this.getAllEvent();
    });
  };

  clearEditId = () => {
    this.setState({
      awardId: "",
      storyId: "",
      eventId: "",
    });
  };

  render() {
    let {
      allstoriesList: {
        upcomingEvents = [],
        fpoawards = [],
        fposuccessstories = [],
        pastEvents = [],
      },
      isLoader,
      eventId,
      isCreatModal,
      awardId,
      tabType,
      storyId,
    } = this.state;
    let {
      editAccess = false,
      viewAccess = false,
      deleteAccess = false,
      createAccess = false,
      id,
      handleToggle,
    } = this.props;
    return (
      <div className="stories">
        {/* <Navbar /> */}
        <div className="container-fluid stories-header"></div>
        <div className="container stories-header-box">
          <div className="d-flex justify-content-center">
            <div className="stories-header-text text-white">
              {"Events And Impact Stories"}
            </div>
          </div>
        </div>
        <div className="toggle-events d-flex justify-content-center w-100">
          <div className="pt-4 rounded toggle-width d-flex justify-content-center ">
            <div className="d-flex w-50 justify-content-between">
              <div className="d-flex align-items-center flex-column">
                <div className="font-weight-bold text-white h5 mb-3">
                  {`${id ? "FPO" : "Our"} Stories`}
                </div>
                <div className="toggle-selected"></div>
              </div>
              <div className="d-flex align-items-center flex-column">
                <div className="">
                  <input
                    className="toggle-btn text-white font-weight-light h5"
                    type="button"
                    value="All events & Impact stories"
                    onClick={() => handleToggle("AllEvents")}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {createAccess && (
          <div>
            <CreateEvents
              eventId={eventId}
              awardId={awardId}
              storyId={storyId}
              tabType={tabType}
              getAllEvent={this.getAllEvent}
              isCreatModal={isCreatModal}
              handleCreateModel={this.handleGetUpdateId}
              clearEditId={() => this.clearEditId()}
            />
          </div>
        )}
        {isLoader ? (
          <CommonPageLoader />
        ) : (
          <>
            {pastEvents.length === 0 &&
            fpoawards.length === 0 &&
            fposuccessstories.length === 0 &&
            upcomingEvents.length === 0 ? (
              <p className="py-5 mb-0 fs-22 text-black-50 w-100 text-center">
                {"No data found"}
              </p>
            ) : null}
            {pastEvents.length !== 0 ? (
              <div>
                <YourEvents
                  title="Our Past events"
                  yourEventList={pastEvents}
                  isLoader={isLoader}
                  getEventId={this.handleGetUpdateId}
                  handleDelete={this.handleDeleteEvents}
                  editAccess={editAccess}
                  viewAccess={viewAccess}
                  deleteAccess={deleteAccess}
                />
              </div>
            ) : null}
            {upcomingEvents.length !== 0 ? (
              <UpcomingEvents
                title="Our Upcoming events"
                upcomingEvents={upcomingEvents}
                ourStories={true}
                getEventId={this.handleGetUpdateId}
                handleDelete={this.handleDeleteEvents}
                editAccess={editAccess}
                viewAccess={viewAccess}
                deleteAccess={deleteAccess}
              />
            ) : null}
            {fpoawards.length !== 0 ? (
              <div>
                {/* <Awards /> */}
                <AwardsOtherFpos
                  awardsOtherFposList={fpoawards}
                  isLoader={isLoader}
                  title="Awards & recognition"
                  getAwardId={this.handleGetUpdateId}
                  handleDelete={this.handleDeleteAwards}
                  editAccess={editAccess}
                  viewAccess={viewAccess}
                  deleteAccess={deleteAccess}
                />
              </div>
            ) : null}
            {fposuccessstories.length !== 0 ? (
              <div>
                <OtherSuccess
                  otherSuccessList={fposuccessstories}
                  isLoader={isLoader}
                  title=" Success stories"
                  getStoryId={this.handleGetUpdateId}
                  handleDelete={this.handleDeleteStories}
                  editAccess={editAccess}
                  viewAccess={viewAccess}
                  deleteAccess={deleteAccess}
                />
              </div>
            ) : null}
          </>
        )}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getFpoAllEventsByUser,
      deleteEvents,
      deleteAwards,
      deleteStories,
      getEventsandImpactsOfFPOById,
    },
    dispatch
  );
};

let component = StoriesClass;

export const Stories = connect(null, mapDispatchToProps)(component);
