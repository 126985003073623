import React, { Component } from "react";
import { RegisterCard } from "../RegisterCard";
import { RegisterStrip } from "../RegisterStrip";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getAllRegistrar } from "action/ComplianceAct";
import { getAllRegistrarRm } from "action/RmAct";
import { Pagination, CommonPageLoader } from "component/common";

class RegisterListClass extends Component {
  constructor(props) {
    super();
    this.state = {
      Registrars: [],
      page: 1,
      pageMeta: {},
      isLoading: false,
    };
  }
  handlePagination = (page) => {
    this.handleRegistrar(page);
  };

  handleRegistrar = (page) => {
    let { limit, searchValue, filterValue, filterBy, id } = this.props;
    this.setState({ isLoading: true });
    let payload = {
      limit,
      page,
      search: searchValue,
      filterValue,
      filterBy,
    };
    id
      ? this.props.getAllRegistrarRm(id, payload).then((data) => {
          this.setState({
            Registrars: data.results,
            pageMeta: data.pageMeta,
            isLoading: false,
          });
        })
      : this.props.getAllRegistrar(payload).then((data) => {
          this.setState({
            Registrars: data.results,
            pageMeta: data.pageMeta,
            isLoading: false,
          });
        });
  };

  componentDidUpdate(prevProps) {
    if (this.props.activeTab == 4) {
      this.props.limit !== prevProps.limit && this.handleRegistrar(1);
      this.props.searchValue !== prevProps.searchValue &&
        this.handleRegistrar(1);
      this.props.filterValue !== prevProps.filterValue &&
        this.handleRegistrar(1);
    }
    if (prevProps.activeTab != this.props.activeTab) {
      let { activeTab } = this.props;
      if (activeTab == 4) {
        this.handleRegistrar(1);
      }
    }
  }

  render() {
    const { Registrars, pageMeta, isLoading } = this.state;
    let { editAccess = false } = this.props;
    return (
      <>
        {!isLoading && Registrars.length === 0 ? (
          <p className="py-4 mb-0 text-black-50 fs-22 line-height-25 text-center">
            No data found
          </p>
        ) : !isLoading ? (
          <>
            {Registrars.length != 0 ? (
              <>
                <div className="scroll-x">
                  <div
                    className="d-flex container-sa flex-column  flex-wrap mt-3 px-0 justify-content-center"
                    style={
                      this.props.view === "1" ? { minWidth: "1200px" } : null
                    }
                  >
                    <div className="d-flex flex-wrap com-list-card">
                      {Registrars.map((registrar, index) => {
                        return this.props.view === "2" ? (
                          <RegisterCard
                            payload={registrar}
                            index={index}
                            editAccess={editAccess}
                            key={index}
                          />
                        ) : (
                          <RegisterStrip
                            payload={registrar}
                            index={index}
                            editAccess={editAccess}
                            key={index}
                          />
                        );
                      })}
                    </div>
                  </div>
                </div>
                {pageMeta.pageCount !== undefined &&
                  pageMeta.pageCount !== 1 && (
                    <Pagination
                      handleChange={this.handlePagination}
                      pageMeta={pageMeta}
                    />
                  )}
              </>
            ) : (
              <p className="py-4 mb-0 text-black-50 fs-22 line-height-25 text-center">
                No data found
              </p>
            )}
          </>
        ) : (
          <>
            {/* Loader */}
            <CommonPageLoader />
          </>
        )}
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getAllRegistrar,
      getAllRegistrarRm,
    },
    dispatch
  );
};

let component = RegisterListClass;

export const RegisterList = connect(null, mapDispatchToProps)(component);
