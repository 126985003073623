import { Reports } from "component/Report";
import React, { useEffect } from "react";

export const Report = () => {
  useEffect(() => {
    window.onunload = function () {
      window.scrollTo(0, 0);
    };
  }, []);
  return <Reports />;
};
