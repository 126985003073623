import React from 'react'
import { Collapse } from 'reactstrap';
import './Accordion.scss'
import ArrowDown from '../../../../../../assets/images/icon/drop-arrow.svg';

const Accordion = ({ className = "", children }) => {
    return (
        <div className={`fpo-accordion ${className}`}>
            {
                children
            }
        </div>
    )
}

const Item = ({ className = "", children }) => {
    return (
        <div className={`fpo-accordion-item ${className}`}>
            {
                children
            }
        </div>
    )
}

const Header = ({ className = "", onClick = {}, iconClass = "", children }) => {
    return (
        <div className={`fpo-accordion-header ${className}`} onClick={onClick}>
            <div className='header-text'>{children}</div>
            <div className="icon">
                <img src={ArrowDown} className={`accordion-icon ${iconClass}`}/>
            </div>
        </div>
    )
}

const Body = ({ className = "", isOpen, children }) => {
    return (
        <Collapse isOpen={isOpen}>
            <div className={`fpo-accordion-body ${className}`}>
                {
                    children
                }
            </div>
        </Collapse>
    )
}

Accordion.Item = Item;
Accordion.Header = Header;
Accordion.Body = Body;

export default Accordion;