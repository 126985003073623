export const mainTabHeading = [
  { title: "New record" },
  { title: "View past records" },
];

export const tabHeading = [
  { title: "Kharif" },
  { title: "Rabi" },
  { title: "Zaid" },
];

export const harvestTableHeader = [
  { title: "Crop" },
  { title: "Variety" },
  { title: "Quantity" },
  { title: "Unit" },
  { title: "Price" },
  { title: "Action" },
];

export const cropForm = {
  cropId: "",
  variety: "",
  qty: "",
  unitOfMeasurement: "",
  others: "",
  price: "",
};

export const harvestEditForm = {
  harvestID: "",
  fpoID: "",
  season: "",
  crop: "",
  variety: "",
  quantity: "",
  price: "",
  unit: "",
  others: "",
};

export const seasonOption = [
  { label: "Kharif", value: "Kharif" },
  { label: "Rabi", value: "Rabi" },
  { label: "Zaid", value: "Zaid" },
];

export const unitOption = [
  { label: "Tonne", value: "Tonne" },
  { label: "Quintal", value: "Quintal" },
  { label: "KG", value: "KG" },
  { label: "Gram", value: "Gram" },
  { label: "Bag", value: "Bag" },
  { label: "Others", value: "Others" },
];
