import React, { Component } from "react";
import faverate from "../../../assets/images/icon/faverate.svg";
import faverateColor from "assets/images/icon/heart.svg";
import share from "../../../assets/images/icon/share.svg";
import SimpleReactValidator from "simple-react-validator";
import { SharePopup } from "../SharePopup";
import VideoPlayer from "react-video-js-player";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getDescriptionData } from "service/helperFunctions";
import { history } from "service/helpers";
import play from "../../../assets/images/icon/play.svg";

import {
  addfavouriteVideo,
  deletefavouriteVideo,
  addReviewForVideo,
} from "action/Acadamy";

import "./InfomativeView.scss";

export class InfomativeViewClass extends Component {
  constructor() {
    super();
    this.state = {
      isFavouriteAdding: false,
      review: "",
      isSharePopup: false,
    };
    this.validator = new SimpleReactValidator();
    this.myref = React.createRef();
  }

  onPlayerReady(player, duration = 5) {
    player.currentTime(duration);
    this.player = player;
  }

  handleAddFavourities = (trendingId) => {
    let body = {
      trendingvideoId: trendingId,
    };

    let { addfavouriteVideo } = this.props;

    this.setState({ isFavouriteAdding: true });

    addfavouriteVideo(body).then(() => {
      this.props.handleChange();
      this.validator.hideMessages();
      this.setState({ isFavouriteAdding: false });
    });
  };

  handledeleteFavourities = (trendingId) => {
    let body = {
      trendingvideoId: trendingId,
    };

    let { deletefavouriteVideo } = this.props;

    this.setState({ isFavouriteAdding: true });

    deletefavouriteVideo(body).then(() => {
      this.props.handleChange();
      this.validator.hideMessages();
      this.setState({ isFavouriteAdding: false });
    });
  };

  handleInput = ({ target: { name, value } }) => {
    this.setState({
      [name]: value,
    });
  };

  handleSubmit = (trendingvideoId) => {
    if (this.validator.allValid()) {
      let { review } = this.state;
      let { addReviewForVideo } = this.props;
      let body = {
        trendingvideoId,
        reviewComment: review,
      };

      addReviewForVideo(body).then(() => {
        this.validator.hideMessages();
        this.setState({ review: "" });
      });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  handleChangePage = (trendingvideoId) => {
    history.push(`/academy/trending-video/${trendingvideoId}`);
  };

  //open share popup
  togglePopup = (key) => {
    this.setState((prevState) => ({
      [key]: !prevState[key],
    }));
  };

  onVideoPlay(duration) {
    this.setState({
      videoDuration: duration,
    });
  }
  seek(secs) {
    let time = this.player.currentTime() + secs;

    if (time < 0) {
      time = 0;
    }

    this.player.currentTime(time);
  }

  render() {
    let {
      isFavouriteAdding,
      review,
      isSharePopup,
      videoDuration = "",
    } = this.state;
    let { infomativeDetail = {}, relatedVideos = [] } = this.props;

    let {
      trendingvideoFileUrl = "",
      description = "",
      thumbnailImageUrl = "",
      title = "",
      isFavourite = false,
      trendingvideoId,
      author = "Sample",
      viewsCount = 0,
    } = infomativeDetail;

    return (
      <section className="infomative-video-play">
        <div className="container-sa">
          <div className="row">
            <div className="col-sm-8">
              <div className="video-player position-relative">
                <VideoPlayer
                  ref={this.myref}
                  controls={true}
                  src={trendingvideoFileUrl}
                  poster={thumbnailImageUrl}
                  onReady={this.onPlayerReady.bind(this)}
                  onTimeUpdate={this.onVideoPlay.bind(this)}
                />
                {/* <div>
                                    {videoDuration > 5 && (
                                        <div
                                            className='button-backward'
                                            onClick={() => this.seek(-5)}
                                        >
                                            {'<<'}
                                        </div>
                                    )}
                                    <div
                                        className='button-forward'
                                        onClick={() => this.seek(5)}
                                    >
                                        {'>>'}
                                    </div>
                                </div> */}
              </div>
              <div className="row mt-3">
                <div className="col-sm-12">
                  <h4>{title}</h4>
                  <p dangerouslySetInnerHTML={{ __html: description }}></p>
                </div>
              </div>
              <div className="info-details-section">
                <div className="row">
                  <div className="col-sm-8">
                    <div className="name-card">
                      <div className="pick-letter color">
                        {author.substring(0, 1)}
                      </div>
                      <div>{author}</div>
                    </div>
                  </div>
                  <div className="col-sm-4 my-auto">
                    <div className="info-icon-list my-auto">
                      <ul>
                        <li className="text-green-18 fw-600">
                          {viewsCount} {"views"}
                        </li>
                        {isFavouriteAdding && isFavourite ? (
                          <li>
                            <img src={faverateColor} alt="faverateColor" />
                          </li>
                        ) : isFavouriteAdding && !isFavourite ? (
                          <li>
                            <img src={faverate} alt="faverate" />
                          </li>
                        ) : isFavourite ? (
                          <li
                            className="cursor-pointer"
                            onClick={() =>
                              this.handledeleteFavourities(trendingvideoId)
                            }
                          >
                            <img src={faverateColor} alt="faverateColor" />
                          </li>
                        ) : (
                          <li
                            className="cursor-pointer"
                            onClick={() =>
                              this.handleAddFavourities(trendingvideoId)
                            }
                          >
                            <img src={faverate} alt="faverate" />
                          </li>
                        )}
                        <li
                          className="cursor-pointer"
                          onClick={() => this.togglePopup("isSharePopup")}
                        >
                          <img src={share} alt="share" />
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <hr />
                <div className="info-add-review mb-4">
                  <h4>Add review</h4>
                  <div className="text-area">
                    <textarea
                      name="review"
                      value={review}
                      id=""
                      cols="30"
                      rows="2"
                      onChange={this.handleInput}
                    ></textarea>
                    <div
                      className="send-area cursor-pointer"
                      onClick={() => {
                        this.handleSubmit(trendingvideoId);
                      }}
                    >
                      <i className="icon-send fs-20"></i>
                    </div>
                  </div>
                  {this.validator.message(
                    "review",
                    review,
                    "required|max:100",
                    { className: "text-danger" }
                  )}
                </div>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="mb-5">
                {relatedVideos.length > 0 ? (
                  <h4 className="mt-3 fs-20 line-height-30 text-black">
                    Recommended videos
                  </h4>
                ) : null}
                {relatedVideos.map((data, index) => {
                  let {
                    title = "",
                    description = "",
                    trendingvideoId = "",
                  } = data;
                  return (
                    index < 4 && (
                      <div
                        key={index + "upComing"}
                        onClick={() => this.handleChangePage(trendingvideoId)}
                        className="up-next-card cursor-pointer"
                      >
                        <div className="row align-items-center">
                          <div className="col-sm-6">
                            <div className="up-next-img-thumb play-center">
                              <img
                                src={data.thumbnailImageUrl}
                                alt="thumbnailImageUrl"
                                srcSet=""
                              />
                              <div className="play-button">
                                <img
                                  className="play-icon"
                                  src={play}
                                  alt="play"
                                  srcSet=""
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="up-next-content">
                              <h4>{getDescriptionData(title, 20)}</h4>
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: getDescriptionData(description, 50),
                                }}
                              ></p>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <SharePopup
          isOpen={isSharePopup}
          toggle={() => this.togglePopup("isSharePopup")}
          isInfomativeVideo={true}
          trendingvideoId={trendingvideoId}
        />
      </section>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      deletefavouriteVideo,
      addfavouriteVideo,
      addReviewForVideo,
    },
    dispatch
  );
};

let component = InfomativeViewClass;

export const InfomativeView = connect(null, mapDispatchToProps)(component);
