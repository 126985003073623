import React, { Component } from "react";
import { Navbar } from "../../component/common";
import { KeyFinancialView } from "../../component/KeyFinancial";

export class KeyFinancial extends Component {
  componentDidMount() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }
  render() {
    return (
      <div>
        <div className="inner-header">
          {/* <Navbar /> */}
          <div className="h-100 w-100">
            <p className="d-flex justify-content-center align-items-center h-100 w-100 text-white fs-32 font-weight-bold">
              Key Financials
            </p>
          </div>
        </div>
        <KeyFinancialView id={this.props.match.params.id} />
      </div>
    );
  }
}
