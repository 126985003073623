import React, { Component, Fragment } from "react";
import { NormalInput, NormalSelect, ConfirmationPopup } from "component/common";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  membersView,
  allLiveStocks,
  memberLivestockAdd,
  EditLiveStockDetail,
  getMemberLiveStock,
  getMember,
  deleteLiveStock,
} from "action/MemberAct";
import SimpleReactValidator from "simple-react-validator";

import arrow_up from "assets/svg/add_member/arrow_up.svg";

class LiveStockForm extends Component {
  constructor(props) {
    super();
    this.state = {
      members: [],
      memberId: "",
      liveStockdata: [
        {
          livestock_id: "",
          live_stock_breed: "",
          live_stock_count: "",
          live_stock_avg_age: "",
          live_stock_insurance: "Yes",
          liveStockID: null,
        },
      ],
      all_livestock: [],
      isDataUploading: false,
      livestockInsurance: [
        { value: "Yes", label: "Yes" },
        { value: "No", label: "No" },
      ],
      live_stocks_data: null,
      deleteId: null,
    };
  }

  validator = new SimpleReactValidator({
    element: (message) => (
      <span className="error-message text-danger fs-14">{message}</span>
    ),
    autoForceUpdate: this,
  });

  handleChange = ({ target: { name, value } }) => {
    this.setState(
      {
        [name]: value,
      },
      () => {
        if (name == "memberId") {
          this.props.getMemberLiveStock({ id: value }).then((data) => {
            if (data.length == 0) {
              this.setState({
                liveStockdata: [
                  {
                    livestock_id: "",
                    live_stock_breed: "",
                    live_stock_count: "",
                    live_stock_avg_age: "",
                    live_stock_insurance: "",
                    liveStockID: null,
                  },
                ],
                live_stocks_data: null,
              });
            } else {
              let getliveStockData = [];
              data.map((list) => {
                getliveStockData.push({
                  livestock_id: parseInt(list.liveStockMasterID),
                  live_stock_breed: list.liveStockBreed,
                  live_stock_count: list.liveStockCount.toString(),
                  live_stock_avg_age: list.liveStockAvgAge.toString(),
                  live_stock_insurance: list.liveStockInsurance,
                  liveStockID: list.liveStockID ? list.liveStockID : null,
                });
              });
              this.setState({
                live_stocks_data: data,
                memberId: data[0].memberID,
                liveStockdata: getliveStockData,
              });
            }
          });
        }
      }
    );
  };

  handleInput = ({ target: { name, value } }, index) => {
    let { liveStockdata } = this.state;
    liveStockdata[index][name] = value;
    this.setState({
      liveStockdata,
    });
  };

  handleLiveStockAdd = () => {
    let { liveStockdata } = this.state;

    liveStockdata = liveStockdata.concat({
      livestock_id: "",
      live_stock_breed: "",
      live_stock_count: "",
      live_stock_avg_age: "",
      live_stock_insurance: "Yes",
      liveStockID: null,
    });

    this.setState({
      liveStockdata,
      isDataUploading: false,
    });
  };

  handleLiveStockRemove = (e, index) => {
    let { liveStockdata } = this.state;

    liveStockdata = liveStockdata.filter((s, sidx) => index !== sidx);

    this.setState({
      liveStockdata,
      isDataUploading: false,
    });
  };

  //delete live stock
  handleDelete = () => {
    let { memberId, deleteId } = this.state;
    let { deleteLiveStock } = this.props;
    let body = {
      id: memberId,
      liveStockId: deleteId,
    };
    deleteLiveStock(body).then(() => {
      let { id } = this.props;
      this._getData(id);
    });
  };

  toggleDelete = (id) => {
    this.setState({
      deleteId: id,
    });
  };

  handleSave = () => {
    if (this.validator.allValid()) {
      const { memberId, liveStockdata, live_stocks_data } = this.state;

      this.setState({ isDataUploading: true });

      let body = {
        memberID: parseInt(memberId),
        livestock: [],
      };

      liveStockdata.map((list) => {
        let livestockBody = {};
        if (list.livestock_id !== "") {
          livestockBody.liveStockMasterID = parseInt(list.livestock_id);
        }
        if (list.live_stock_count !== "") {
          livestockBody.liveStockCount = parseInt(list.live_stock_count);
        }
        if (list.live_stock_avg_age !== "") {
          livestockBody.liveStockAvgAge = parseInt(list.live_stock_avg_age);
        }
        if (list.live_stock_insurance !== "") {
          livestockBody.liveStockInsurance = list.live_stock_insurance;
        }
        if (list.live_stock_breed !== "") {
          livestockBody.liveStockBreed = list.live_stock_breed;
        }
        if (list.liveStockID !== null) {
          livestockBody.liveStockID = list.liveStockID;
        }

        body.livestock.push(livestockBody);
      });

      if (live_stocks_data == null) {
        if (body.livestock.length === liveStockdata.length) {
          this.props.memberLivestockAdd(body).then((data) => {
            if (data === 200) {
              this.validator.hideMessages();
              this.props.toggleCollapse(
                false,
                false,
                false,
                false,
                false,
                true
              );
            }
            this.setState({ isDataUploading: false });
          });
        }
      } else {
        if (body.livestock.length === liveStockdata.length) {
          this.props.EditLiveStockDetail(body).then((data) => {
            if (data === 200) {
              this.validator.hideMessages();
              this.props.toggleCollapse(
                false,
                false,
                false,
                false,
                false,
                true
              );
            }
            this.setState({ isDataUploading: false });
          });
        }
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      if (this.props.isOpen) {
        this.props.membersView().then((data) => {
          let memberDetials = [];
          data.map((list) => {
            memberDetials.push({
              label: list.memberFirstName + " | " + list.phone,
              value: list.memberID,
            });
          });
          this.setState({
            members: memberDetials,
          });
        });
        this.props.allLiveStocks().then((data) => {
          let livestockData = [];
          data.map((list) => {
            livestockData.push({
              label: list.liveStockName,
              value: list.liveStockMasterID,
            });
          });
          this.setState({
            all_livestock: livestockData,
          });
        });
        let { id } = this.props;
        this._getData(id);
      }
    }
  }

  _getData = (id) => {
    this.props.getMember({ id }).then((data) => {
      let memberLiveStockInfo = data.memberLiveStockInfo;
      let LiveStockInfo = [];
      if (memberLiveStockInfo.length !== 0) {
        memberLiveStockInfo.map((list) => {
          LiveStockInfo.push({
            livestock_id: list.liveStockMasterID
              ? parseInt(list.liveStockMasterID)
              : "",
            live_stock_breed: list.liveStockBreed ? list.liveStockBreed : "",
            live_stock_count: list.liveStockCount
              ? list.liveStockCount.toString()
              : "",
            live_stock_avg_age: list.liveStockAvgAge
              ? list.liveStockAvgAge.toString()
              : "",
            live_stock_insurance: list.liveStockInsurance
              ? list.liveStockInsurance
              : "",
            liveStockID: list.liveStockID ? list.liveStockID : null,
          });
        });
        this.setState({
          memberId: memberLiveStockInfo[0].memberID,
          live_stocks_data: memberLiveStockInfo,
          liveStockdata: LiveStockInfo,
        });
      } else {
        this.setState({
          memberId: id,
          live_stocks_data: null,
          liveStockdata: [
            {
              livestock_id: "",
              live_stock_breed: "",
              live_stock_count: "",
              live_stock_avg_age: "",
              live_stock_insurance: "Yes",
              liveStockID: null,
            },
          ],
        });
      }
    });
  };

  render() {
    const {
      members,
      memberId,
      all_livestock,
      livestockInsurance,
      isDataUploading,
      liveStockdata,
      deleteId,
    } = this.state;
    let { deleteAccess = false } = this.props;
    return (
      <Fragment>
        <div className="px-md-5 px-3 py-4 add-member-area">
          <div className="d-flex flex-md-row flex-column justify-content-between align-items-md-center align-items-start mb-3">
            <div className="add-member-form-container-heading w-md-50 w-100">
              Live stock
            </div>
            <div className="d-flex justify-content-md-end justify-content-between align-items-center mt-md-0 mt-2 w-md-50 w-100">
              {isDataUploading ? (
                <div className="d-flex align-items-center">
                  <div className="mr-3 d-flex align-items-center">
                    <i className="icon-plus add-more-area"></i>
                  </div>
                  <div className="add-member-form-container-button">
                    {"Save & next "}
                  </div>
                </div>
              ) : (
                <div className="d-flex align-items-center">
                  <div className="mr-3 d-flex align-items-center ">
                    {memberId !== "" ? (
                      <i
                        className="icon-plus cursor-pointer add-more-area"
                        onClick={() => this.handleLiveStockAdd()}
                      ></i>
                    ) : (
                      <i className="icon-plus add-more-area"></i>
                    )}
                  </div>
                  <div
                    className="add-member-form-container-button cursor-pointer"
                    onClick={this.handleSave}
                  >
                    {"Save & next"}
                  </div>
                </div>
              )}
              <img
                className="ml-3 cursor-pointer"
                src={arrow_up}
                alt="arrow_up"
                name="live_stock"
                height="10"
                width="10"
                onClick={this.props.collapse}
              />
            </div>
          </div>
          {/* <div className="row w-100">
          <div className="col-12">
            <NormalSelect
              isFullWithForMemberProfile={true}
              label="Member"
              placeholder="Select"
              value={memberId}
              arrow={true}
              name="memberId"
              options={members}
              handleChange={this.handleChange}
              required={true}
              disabled={true}
            />
            <div className="row">
              <div className="col-10 ml-auto">
                {this.validator.message("member", memberId, "required")}
              </div>
            </div>
          </div>
        </div> */}
          {liveStockdata.map((list, index) => (
            <React.Fragment key={index}>
              <div className="row w-100">
                <div className="col-md-12 mt-5">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <p className="mb-0 font-weight-bold fs-20 ">
                        {index + 1 + ". Live stock"}
                      </p>
                    </div>
                    <div className="d-flex align-items-center">
                      {list.liveStockID !== null && deleteAccess ? (
                        <p
                          className="mb-1 font-weight-bold fs-20 cursor-pointer d-flex align-items-center"
                          onClick={() => this.toggleDelete(list.liveStockID)}
                        >
                          <i className="icon-Delete fs-16" />
                          <span className="text-danger fs-16 pl-3">
                            {"Delete"}
                          </span>
                        </p>
                      ) : index != 0 && deleteAccess ? (
                        <p
                          className="mb-1 font-weight-bold fs-20 cursor-pointer d-flex align-items-center"
                          onClick={(e) => this.handleLiveStockRemove(e, index)}
                        >
                          <i className="icon-Delete fs-16" />
                          <span className="text-danger fs-16 pl-3">
                            {"Delete"}
                          </span>
                        </p>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row w-100">
                <div className="col-md-12">
                  <NormalSelect
                    isFullWithForMemberProfile={true}
                    label="Livestock"
                    placeholder="Select"
                    value={list.livestock_id}
                    arrow={true}
                    name="livestock_id"
                    options={all_livestock}
                    handleChange={(e) => this.handleInput(e, index)}
                    disabled={memberId == "" ? true : false}
                  />
                </div>
              </div>
              <div className="row w-100">
                <div className="col-md-6">
                  <NormalInput
                    isHalfWidth={true}
                    label="Livestock breed"
                    type="text"
                    onChange={(e) => this.handleInput(e, index)}
                    value={list.live_stock_breed}
                    name="live_stock_breed"
                    disabled={memberId == "" ? true : false}
                  />
                  <div className="row">
                    <div className="col-md-8 ml-auto">
                      {this.validator.message(
                        "live stock breed",
                        list.live_stock_breed,
                        "max:20"
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <NormalInput
                    isHalfWidth={true}
                    label="Livestock count"
                    type="number"
                    onChange={(e) => this.handleInput(e, index)}
                    value={list.live_stock_count}
                    name="live_stock_count"
                    disabled={memberId == "" ? true : false}
                  />
                  <div className="row">
                    <div className="col-8 ml-auto">
                      {this.validator.message(
                        "live stock count",
                        list.live_stock_count,
                        "max:8"
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row w-100">
                <div className="col-md-6">
                  <NormalInput
                    isHalfWidth={true}
                    label="Livestock average age"
                    type="number"
                    onChange={(e) => this.handleInput(e, index)}
                    value={list.live_stock_avg_age}
                    name="live_stock_avg_age"
                    disabled={memberId == "" ? true : false}
                  />
                  <div className="row">
                    <div className="col-md-8 ml-auto">
                      {this.validator.message(
                        "live stock average age",
                        list.live_stock_avg_age,
                        "max:3"
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <NormalSelect
                    isHalfWidth={true}
                    label="Livestock insurance"
                    placeholder="Select"
                    value={list.live_stock_insurance}
                    arrow={true}
                    name="live_stock_insurance"
                    options={livestockInsurance}
                    handleChange={(e) => this.handleInput(e, index)}
                    disabled={memberId == "" ? true : false}
                  />
                </div>
              </div>
            </React.Fragment>
          ))}
        </div>
        <ConfirmationPopup
          isOpen={deleteId !== null ? true : false}
          toggle={() => this.toggleDelete(null)}
          onSubmit={this.handleDelete}
        />
      </Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      membersView,
      allLiveStocks,
      memberLivestockAdd,
      EditLiveStockDetail,
      getMemberLiveStock,
      getMember,
      deleteLiveStock,
    },
    dispatch
  );
};
let component = LiveStockForm;

export const LiveStock = connect(null, mapDispatchToProps)(component);
