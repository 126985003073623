import React from "react";
import { logout } from "service/utilities";
import "./FIGNavbar.scss";

// const nav_user = require("../../../assets/images/default-profile-img.png");
const nav_logo_white = require("../../../assets/svg/logowhite.svg");

const FIGNavbar = () => {
  const handleLogout = () => {
    logout();
  };

  return (
    <div className="fig-header-navbar mobile-fix">
      <div className="container-sa">
        <div className="d-flex justify-content-between align-items-center d-md-none d-block">
          <div className="mr-2 navbar-brand d-md-none d-block">
            <i className="fas fa-bars text-white mr-2 cursor-pointer"></i>
            <img src={nav_logo_white} alt="Bidar.svg" height="45" width="100" />
          </div>
        </div>
        <div className={`navbar px-0`}>
          <span className="mr-2 navbar-brand d-md-block d-none">
            <img src={nav_logo_white} alt="Bidar.svg" height="45" width="100" />
          </span>
          <div className="nav-right d-flex justify-content-end align-items-center">
            <div className="cursor-pointer profile profile-align profile-dropdown d-md-block d-none">
              {/* <img
                className="rounded-circle"
                src={nav_user}
                alt="user"
                onClick={handleLogout}
              /> */}
              <button className="logout_button" onClick={handleLogout}>
                Logout
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FIGNavbar;
