import React, { Component, Fragment } from "react";
import "./style.scss";

import {
  NormalInput,
  NormalSelect,
  NormalDate,
  CommonTextArea,
  NormalButton,
  ConfirmationPopup,
} from "component/common";

import moment from "moment";

import SimpleReactValidator from "simple-react-validator";
import { getAccessdata } from "service/helperFunctions";
import { history } from "service/helpers/index";

import {
  addBod,
  updateBod,
  getAddressProofDropdown,
  getIdentityProofDropdown,
  getEducationDropdown,
  getBODById,
  deleteBOD,
} from "action/BodAct";

// Redux Connection
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  getDistrictApiwithState,
  allState,
  fileUpload,
  getPincodeApiwithStateDistrict,
  currentTab,
} from "action/MemberAct";

import { Toast } from "service/toast";

const arrow_left = require("assets/svg/add_member/arrow_left.svg");
const pdf = require("assets/svg/Documents/pdf.svg");

export class AddBodClass extends Component {
  state = {
    first_name: "",
    last_name: "",
    gender: "",
    address: "",
    pincode: "",
    contact: "",
    designation: "",
    experiance: "",
    qualification: "",
    smartPhoneOption: "",
    appointedOn: "",
    din: "",
    address_proof: "",
    smart_phone: "",
    dob: "",
    fatherName: "",
    panNumber: "",
    village: "",
    district: "",
    state: "",
    addressProofFile: "",
    profile_photo: "",
    profilePicName: "",
    address_proof_name: "",
    identity_proof: "",
    identity_proof_file: "",
    identity_proof_name: "",
    isProfilePicUploading: false,
    isaddressProofUploading: false,
    isIdentityProofUploading: false,
    allState: [],
    allDistrict: [],
    allPincode: [],
    addressProofOption: [],
    educationQualificationOption: [],
    identityProofOption: [],
    emailId: "",
    userMasterId: "",
    genderOptions: [
      { value: "Male", label: "Male" },
      { value: "Female", label: "Female" },
      { value: "Other", label: "Other" },
    ],
    smartPhoneOptions: [
      { value: "Yes", label: "Yes" },
      { value: "No", label: "No" },
    ],
    isDataUploading: false,
    isLoading: false,
    isDelete: false,
  };
  //validation
  validator = new SimpleReactValidator({
    element: (message) => (
      <span className="error-message text-danger fs-14">{message}</span>
    ),
    autoForceUpdate: this,
  });

  handleInput = ({ target: { name, value } }) => {
    if (name === "state") {
      this.setState(
        {
          [name]: value,
          district: "",
        },
        () => {
          this.props.getDistrictApiwithState({ id: value }).then((data) => {
            let allDistrictOptions = [];
            data.map((list) => {
              allDistrictOptions.push({
                value: list.districtId,
                label: list.districtName,
              });
            });
            this.setState({
              allDistrict: allDistrictOptions,
            });
          });
        }
      );
    } else {
      this.setState({
        [name]: value,
      });
    }
  };

  uploadProofFile = (e) => {
    //upload profile picture
    let file = e.target.files[0];
    if (file !== undefined) {
      this.setState({ profile_photo: "" }, () => {
        this.setState({
          isProfilePicUploading: true,
          profilePicName: "Uploading ....",
          profile_photo: file,
        });
        const formData = new FormData();
        formData.append("file", file);

        this.props
          .fileUpload(formData)
          .then((data) => {
            document.getElementById("uploadProofFile").value = null;
            this.setState({
              profile_photo: data[0].fileUrl,
              profilePicName: data[0].fileName,
              isProfilePicUploading: false,
            });
          })
          .catch(() => {
            this.setState({
              profile_photo: "",
              profilePicName: "",
              isProfilePicUploading: false,
            });
          });
      });
    }
  };

  handleAddressProofFile = (e) => {
    //upload address proof
    let file = e.target.files[0];
    if (file !== undefined) {
      this.setState({ addressProofFile: "" }, () => {
        this.setState({
          isaddressProofUploading: true,
          address_proof_name: "Uploading ....",
          addressProofFile: file,
        });
        const formData = new FormData();
        formData.append("file", file);

        this.props
          .fileUpload(formData)
          .then((data) => {
            document.getElementById("handleAddressProofFile").value = null;
            this.setState({
              addressProofFile: data[0].fileUrl,
              address_proof_name: data[0].fileName,
              isaddressProofUploading: false,
            });
          })
          .catch(() => {
            this.setState({
              addressProofFile: "",
              address_proof_name: "",
              isaddressProofUploading: false,
            });
          });
      });
    }
  };

  handleIdentityProofFile = (e) => {
    //upload identity proof
    let file = e.target.files[0];
    if (file !== undefined) {
      this.setState({ identity_proof_file: "" }, () => {
        this.setState({
          isIdentityProofUploading: true,
          identity_proof_name: "Uploading ....",
          identity_proof_file: file,
        });
        const formData = new FormData();
        formData.append("file", file);

        this.props
          .fileUpload(formData)
          .then((data) => {
            document.getElementById("handleIdentityProofFile").value = null;
            this.setState({
              identity_proof_file: data[0].fileUrl,
              identity_proof_name: data[0].fileName,
              isIdentityProofUploading: false,
            });
          })
          .catch(() => {
            this.setState({
              identity_proof_file: "",
              identity_proof_name: "",
              isIdentityProofUploading: false,
            });
          });
      });
    }
  };

  //life cycles
  componentDidMount() {
    let {
      allState,
      getAddressProofDropdown,
      getIdentityProofDropdown,
      getEducationDropdown,
      id,
    } = this.props;
    allState().then((data) => {
      let allStateOptions = [];
      data.map((list) => {
        allStateOptions.push({
          value: list.stateId,
          label: list.stateName,
        });
      });
      this.setState({
        allState: allStateOptions,
      });
    });
    getAddressProofDropdown().then((data) => {
      let addressProofOptions = [];
      data.map((list) => {
        addressProofOptions.push({
          value: list.kycDocID,
          label: list.kycDoc,
        });
      });
      this.setState({
        addressProofOption: addressProofOptions,
      });
    });

    getIdentityProofDropdown().then((data) => {
      let identityProofOptions = [];
      data.map((list) => {
        identityProofOptions.push({
          value: list.kycDocID,
          label: list.kycDoc,
        });
      });
      this.setState({
        identityProofOption: identityProofOptions,
      });
    });
    getEducationDropdown().then((data) => {
      let educationQualificationOptions = [];
      data.map((list) => {
        educationQualificationOptions.push({
          value: list.educationID,
          label: list.qualificationType,
        });
      });
      this.setState({
        educationQualificationOption: educationQualificationOptions,
      });
    });

    if (id !== undefined) {
      this.setState({ isLoading: true });
      this._getData(id);
    }
  }

  _getData = (id) => {
    let { getBODById } = this.props;

    let body = {
      id: id,
    };

    getBODById(body).then((data) => {
      this.setState({
        first_name: data.firstName,
        last_name: data.lastName,
        gender: data.gender,
        address: data.address ? data.address : "",
        pincode: data.pincode ? data.pincode : "",
        contact: data.mobileNumber,
        designation: data.designation ? data.designation : "",
        experiance: data.experience ? data.experience.toString() : "",
        qualification: data.educationID ? data.educationID : "",
        appointedOn: data.AppointedOn ? new Date(data.AppointedOn) : "",
        din: data.din ? data.din : "",
        address_proof: data.addressProofId ? data.addressProofId : "",
        smart_phone:
          data.hasSmartPhone == true
            ? "Yes"
            : data.hasSmartPhone == false
            ? "No"
            : "",
        dob: data.dob ? new Date(data.dob) : "",
        village: data.village ? data.village : "",
        district: data.districtId ? data.districtId : "",
        state: data.stateId ? data.stateId : "",
        addressProofFile: data.addressProofUrl ? data.addressProofUrl : "",
        profile_photo: data.profileImageUrl ? data.profileImageUrl : "",
        profilePicName: data.profileImageName ? data.profileImageName : "",
        address_proof_name: data.addressProofName ? data.addressProofName : "",
        identity_proof: data.identityProofId ? data.identityProofId : "",
        identity_proof_file: data.identityProofUrl ? data.identityProofUrl : "",
        identity_proof_name: data.identityProofName
          ? data.identityProofName
          : "",
        emailId: data.emailId ? data.emailId : "",
        userMasterId: data.userMasterId,
        panNumber: data.panNumber ? data.panNumber : "",
        fatherName: data.fatherName ? data.fatherName : "",
        isLoading: false,
      });

      if (data.stateId !== null && data.stateId !== undefined) {
        this.props
          .getDistrictApiwithState({ id: data.stateId })
          .then((data) => {
            let allDistrictOptions = [];
            data.map((list) => {
              allDistrictOptions.push({
                value: list.districtId,
                label: list.districtName,
              });
            });
            this.setState({
              allDistrict: allDistrictOptions,
            });
          });
      }
    });
  };

  //delete Bod
  handleDelete = () => {
    this.setState({ isDataUploading: true });
    let { id } = this.props;
    let { deleteBOD } = this.props;
    let body = {
      id,
    };
    deleteBOD(body).then(() => {
      this.props.currentTab(2);
      history.push("/fpo/member");
    });
  };

  toggleDelete = () => {
    let { isDelete } = this.state;
    this.setState({
      isDelete: !isDelete,
    });
  };

  handleSubmit = () => {
    let { id } = this.props;

    let {
      first_name,
      last_name,
      gender,
      designation,
      experiance,
      emailId,
      qualification,
      appointedOn,
      din,
      address_proof,
      address,
      pincode,
      contact,
      smart_phone,
      dob,
      village,
      district,
      state,
      profilePicName,
      profile_photo,
      identity_proof,
      identity_proof_file,
      addressProofFile,
      address_proof_name,
      identity_proof_name,
      isIdentityProofUploading,
      isaddressProofUploading,
      isProfilePicUploading,
      userMasterId,
      fatherName,
      panNumber,
    } = this.state;
    if (this.validator.allValid()) {
      if (
        !isIdentityProofUploading &&
        !isaddressProofUploading &&
        !isProfilePicUploading
      ) {
        this.setState({
          isDataUploading: true,
        });

        let body = {
          firstName: first_name,
          lastName: last_name,
          mobileNumber: contact,
          emailId: emailId,
          gender: gender,
          bodId: "",
          userMasterId: "",
        };
        if (profilePicName !== "") {
          body.profileImageName = profilePicName;
          body.profileImageUrl = profile_photo;
        }
        if (address_proof_name !== "") {
          body.addressProofName = address_proof_name;
          body.addressProofUrl = addressProofFile;
        }
        if (din !== "") {
          body.din = din;
        }
        if (designation !== "") {
          body.designation = designation;
        }
        if (qualification !== "") {
          body.educationID = parseInt(qualification);
        }
        if (appointedOn !== "") {
          body.AppointedOn = moment(appointedOn).format("YYYY-MM-DD");
        }
        if (dob !== "") {
          body.dob = moment(dob).format("YYYY-MM-DD");
        }
        if (address !== "") {
          body.address = address;
        }
        if (experiance !== "") {
          body.experience = parseInt(experiance);
        }
        if (identity_proof !== "") {
          body.identityProofId = parseInt(identity_proof);
        }
        if (address_proof !== "") {
          body.addressProofId = parseInt(address_proof);
        }
        if (address !== "") {
          body.address = address;
        }
        if (smart_phone !== "") {
          body.hasSmartPhone =
            smart_phone === "Yes" ? true : smart_phone === "No" ? false : "";
        }
        if (village != "") {
          body.village = village;
        }
        if (state != "") {
          body.stateId = parseInt(state);
        }
        if (district !== "") {
          body.districtId = parseInt(district);
        }
        if (pincode !== "") {
          body.pincode = parseInt(pincode);
        }
        if (identity_proof_name !== "") {
          body.identityProofName = identity_proof_name;
          body.identityProofUrl = identity_proof_file;
        }
        if (fatherName != "") {
          body.fatherName = fatherName;
        }
        if (panNumber != "") {
          body.panNumber = panNumber;
        }
        if (id == undefined) {
          delete body.userMasterId;
          delete body.bodId;

          this.props.addBod(body).then((data) => {
            if (data === 200) {
              this.validator.hideMessages();
              this.props.currentTab(2);
              history.push("/fpo/member");
              this._clearData();
            }
            this.setState({
              isDataUploading: false,
            });
          });
        } else {
          body.bodId = id;
          body.userMasterId = userMasterId;

          this.props.updateBod(body).then((data) => {
            if (data === 200) {
              this.validator.hideMessages();
              this.props.currentTab(2);
              history.push("/fpo/member");
            }
            this.setState({
              isDataUploading: false,
            });
          });
        }
      } else {
        Toast({ type: "error", message: "Please wait Image is uploading" });
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  _clearData = () => {
    this.setState({
      first_name: "",
      last_name: "",
      gender: "",
      address: "",
      pincode: "",
      contact: "",
      designation: "",
      experiance: "",
      qualification: "",
      smartPhoneOption: "",
      appointedOn: "",
      din: "",
      address_proof: "",
      smart_phone: "",
      dob: "",
      village: "",
      district: "",
      state: "",
      addressProofFile: "",
      profile_photo: "",
      profilePicName: "",
      address_proof_name: "",
      identity_proof: "",
      identity_proof_file: "",
      identity_proof_name: "",
      emailId: "",
      userMasterId: "",
      fatherName: "",
      panNumber: "",
    });
  };

  handleRemoveImage = (picName) => {
    if (picName === "profile") {
      this.setState({
        profile_photo: "",
        profilePicName: "",
      });
    } else if (picName === "addressProof") {
      this.setState({
        addressProofFile: "",
        address_proof_name: "",
      });
    } else if (picName === "identityProof") {
      this.setState({
        identity_proof_file: "",
        identity_proof_name: "",
      });
    }
  };

  isPdf = (url) => {
    let format = String(url).split(".")[String(url).split(".").length - 1];
    if (format == "pdf") {
      return true;
    } else {
      return false;
    }
  };

  render() {
    const {
      first_name,
      last_name,
      gender,
      emailId,
      genderOptions,
      designation,
      experiance,
      qualification,
      smartPhoneOption,
      smartPhoneOptions,
      appointedOn,
      din,
      address_proof,
      identityProofOption,
      addressProofOption,
      educationQualificationOption,
      address,
      pincode,
      contact,
      smart_phone,
      dob,
      village,
      district,
      state,
      allDistrict,
      allState,
      allPincode,
      profilePicName,
      profile_photo,
      identity_proof,
      addressProofFile,
      identity_proof_file,
      address_proof_name,
      identity_proof_name,
      isDataUploading,
      isProfilePicUploading,
      isaddressProofUploading,
      isIdentityProofUploading,
      fatherName,
      panNumber,
      isDelete,
    } = this.state;

    let { id, accessData } = this.props;
    let permissionData = getAccessdata(accessData, "membership");

    let { deleteAccess = false } = permissionData;

    return (
      <Fragment>
        <div className="add-bod-area">
          <div className="d-flex container-sa">
            <div className="d-flex bod-header-area w-100">
              <div
                className="d-flex"
                onClick={() => history.push("/fpo/member")}
              >
                <img className="cursor-pointer" src={arrow_left} alt="back" />
                <p className="ml-3 text-black fs-18 line-height-25 font-weight-bold mb-0 cursor-pointer">
                  {id ? "Edit" : "Add"} BOD
                </p>
              </div>
              <div>
                {isDataUploading ? (
                  <div className="d-flex align-items-center h-100">
                    {id && deleteAccess ? (
                      <div className="mr-3 d-flex align-items-center h-100">
                        <p className="mb-1 font-weight-bold fs-20 d-flex align-items-center">
                          <i className="icon-Delete fs-18" />
                          <span className="text-danger fs-18 btn pl-3">
                            Delete
                          </span>
                        </p>
                      </div>
                    ) : null}
                    <div>
                      <NormalButton
                        label="Submit & next"
                        mainbg={true}
                        className="mx-auto fs-14 line-height-16"
                      />
                    </div>
                  </div>
                ) : (
                  <div className="d-flex align-items-center h-100">
                    {id && deleteAccess ? (
                      <div className="mr-3 d-flex align-items-center h-100">
                        <p
                          className="mb-1 font-weight-bold fs-20 cursor-pointer d-flex align-items-center"
                          onClick={() => this.toggleDelete()}
                        >
                          <i className="icon-Delete fs-18" />
                          <span className="text-danger fs-18 btn pl-3">
                            {"Delete"}
                          </span>
                        </p>
                      </div>
                    ) : null}
                    <div>
                      <NormalButton
                        label="Submit & next"
                        mainbg={true}
                        className="mx-auto fs-14 line-height-16"
                        onClick={() => this.handleSubmit()}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="add-bod">
            <div className="container">
              <div className="add-bod-form-container">
                <div className="px-md-5 py-md-4 px-0 py-0">
                  <div className="px-md-3 px-0">
                    <div className="row">
                      <div className="col-md-6">
                        <NormalInput
                          required={true}
                          label="First name"
                          type="text"
                          onChange={this.handleInput}
                          value={first_name}
                          placeholder="Enter here"
                          name="first_name"
                        />
                        <div className="row">
                          <div className="col-md-9 ml-auto">
                            {this.validator.message(
                              "first name",
                              first_name,
                              "required|alpha_space|max:20"
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <NormalInput
                          required={true}
                          label="Last name"
                          type="text"
                          onChange={this.handleInput}
                          value={last_name}
                          placeholder="Enter here"
                          name="last_name"
                        />
                        <div className="row">
                          <div className="col-md-9 ml-auto">
                            {this.validator.message(
                              "last name",
                              last_name,
                              "required|alpha_space|max:20"
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <NormalInput
                          label="Father name"
                          type="text"
                          onChange={this.handleInput}
                          value={fatherName}
                          placeholder="Enter here"
                          name="fatherName"
                        />
                      </div>
                      <div className="col-md-6">
                        <NormalInput
                          label="Email"
                          type="text"
                          onChange={this.handleInput}
                          value={emailId}
                          placeholder="Enter here"
                          name="emailId"
                        />
                        <div className="row">
                          <div className="col-9 ml-auto">
                            {this.validator.message(
                              "emailId",
                              emailId,
                              "email"
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <NormalSelect
                          label={"Gender"}
                          placeholder="Select"
                          value={gender}
                          arrow={true}
                          name="gender"
                          options={genderOptions}
                          handleChange={this.handleInput}
                          required={true}
                        />
                        <div className="row">
                          <div className="col-md-9 ml-auto">
                            {this.validator.message(
                              "gender",
                              gender,
                              "required"
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="row mt-3 pt-1 upload-area">
                          <div className="col-md-3 pr-0">
                            <div className="fs-16 text-black-32 font-weight-bold line-height-19">
                              {"Profile photo"}
                            </div>
                          </div>
                          <div className="col-md-9">
                            <div className="d-flex align-items-center w-100 btn-file-input">
                              <button className="">
                                <input
                                  type="file"
                                  className=""
                                  accept=".jpg, .png, .jpeg"
                                  onChange={this.uploadProofFile}
                                  id="uploadProofFile"
                                  title=""
                                />
                                {profile_photo == "" ? (
                                  "Choose file"
                                ) : (
                                  <>
                                    {profilePicName.length == 10
                                      ? profilePicName
                                      : profilePicName.substring(0, 9)}
                                    {"..."}
                                  </>
                                )}
                              </button>
                              {profilePicName != "" &&
                                !isProfilePicUploading && (
                                  <div className="pl-3 position-relative">
                                    <img
                                      src={profile_photo}
                                      alt={profilePicName}
                                      height="75"
                                      width="75"
                                    />
                                    <i
                                      className="icon-Delete text-danger fs-14 pl-2 cursor-pointer position-absolute"
                                      onClick={() =>
                                        this.handleRemoveImage("profile")
                                      }
                                    ></i>
                                  </div>
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <NormalDate
                          isIcon={true}
                          value={dob}
                          name="dob"
                          placeholder="dd/mm/yyyy"
                          onChange={this.handleInput}
                          mindate={false}
                          maxdate={true}
                          timeInput={false}
                          label={"DOB"}
                        />
                      </div>
                      <div className="col-md-6">
                        <NormalInput
                          label="Designation"
                          type="text"
                          onChange={this.handleInput}
                          value={designation}
                          name="designation"
                        />
                        <div className="row">
                          <div className="col-md-9 ml-auto">
                            {this.validator.message(
                              "designation",
                              designation,
                              "max:50|alpha_space"
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <NormalInput
                          label="Experience (in years)"
                          type="number"
                          onChange={this.handleInput}
                          value={experiance}
                          name="experiance"
                        />
                        <div className="row">
                          <div className="col-md-9 ml-auto">
                            {this.validator.message(
                              "experiance",
                              experiance,
                              "max:2"
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <NormalSelect
                          label="Education qualification"
                          placeholder="Select"
                          value={qualification}
                          arrow={true}
                          name="qualification"
                          options={educationQualificationOption}
                          handleChange={this.handleInput}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <NormalInput
                          label="Contact number"
                          type="text"
                          onChange={this.handleInput}
                          value={contact}
                          name="contact"
                          required={true}
                          max={10}
                        />
                        <div className="row">
                          <div className="col-md-9 ml-auto">
                            {this.validator.message(
                              "contact",
                              contact,
                              "required|phone"
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <NormalSelect
                          label="Smart phone"
                          placeholder="Select"
                          value={smart_phone}
                          arrow={true}
                          name="smart_phone"
                          options={smartPhoneOptions}
                          handleChange={this.handleInput}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <CommonTextArea
                          isFullWidth={true}
                          className="form-control"
                          value={address}
                          name="address"
                          onChange={this.handleInput}
                          resize={false}
                          rows="3"
                          label="Address"
                        />
                        <div className="row">
                          <div className="col-md-9 ml-auto">
                            {this.validator.message(
                              "address",
                              address,
                              "max:100"
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <NormalInput
                          label="Village"
                          type="text"
                          onChange={this.handleInput}
                          value={village}
                          name="village"
                        />
                        <div className="row">
                          <div className="col-md-9 ml-auto">
                            {this.validator.message(
                              "village",
                              village,
                              "max:50|alpha_space"
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <NormalSelect
                          label="State"
                          placeholder="Select"
                          value={state}
                          arrow={true}
                          name="state"
                          options={allState}
                          handleChange={this.handleInput}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <NormalSelect
                          label="District"
                          placeholder="Select"
                          value={district}
                          arrow={true}
                          name="district"
                          options={allDistrict}
                          disabled={state == "" ? true : false}
                          handleChange={this.handleInput}
                        />
                      </div>
                      <div className="col-md-6">
                        <NormalInput
                          label="Pincode"
                          type="text"
                          onChange={this.handleInput}
                          value={pincode}
                          name="pincode"
                          max={6}
                        />
                        <div className="row">
                          <div className="col-md-9 ml-auto">
                            {this.validator.message(
                              "pincode",
                              pincode,
                              "min:6|max:6|numeric"
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <NormalDate
                          isIcon={true}
                          value={appointedOn}
                          name="appointedOn"
                          placeholder="dd/mm/yyyy"
                          onChange={this.handleInput}
                          mindate={false}
                          maxdate={true}
                          timeInput={false}
                          label={"Appointed on"}
                        />
                      </div>
                      <div className="col-md-6">
                        <NormalInput
                          label="DIN"
                          type="number"
                          onChange={this.handleInput}
                          value={din}
                          name="din"
                        />
                        <div className="row">
                          <div className="col-9 ml-auto">
                            {this.validator.message("din", din, "max:20")}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <NormalInput
                          label="PAN Number"
                          type="text"
                          onChange={this.handleInput}
                          value={panNumber}
                          placeholder="Enter here"
                          name="panNumber"
                        />
                        <div className="row">
                          <div className="col-md-9 ml-auto">
                            {this.validator.message(
                              "bod pan number",
                              panNumber,
                              "alpha_num"
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <NormalSelect
                          label="Identity proof"
                          placeholder="Select"
                          value={identity_proof}
                          arrow={true}
                          name="identity_proof"
                          options={identityProofOption}
                          handleChange={this.handleInput}
                        />
                      </div>
                      <div className="col-md-6">
                        <div className="row mt-3 pt-1 upload-area">
                          <div className="col-md-3 pr-0">
                            <div className="fs-16 text-black-32 font-weight-bold line-height-19">
                              {"Identity proof document"}
                            </div>
                          </div>
                          <div className="col-md-9">
                            <div className="d-flex align-items-center w-100 btn-file-input">
                              <button className="">
                                <input
                                  type="file"
                                  className=""
                                  accept="image/jpg, image/png, image/jpeg, application/pdf"
                                  onChange={this.handleIdentityProofFile}
                                  id="handleIdentityProofFile"
                                  title=""
                                />
                                {identity_proof_file == "" ? (
                                  "Choose file"
                                ) : (
                                  <>
                                    {identity_proof_name.length == 10
                                      ? identity_proof_name
                                      : identity_proof_name.substring(0, 9)}
                                    {"..."}
                                  </>
                                )}
                              </button>
                              {identity_proof_name != "" &&
                                !isIdentityProofUploading && (
                                  <div className="pl-3 position-relative">
                                    <img
                                      src={
                                        this.isPdf(identity_proof_file)
                                          ? pdf
                                          : identity_proof_file
                                      }
                                      alt={identity_proof_name}
                                      height="75"
                                      width="75"
                                    />
                                    <i
                                      className="icon-Delete text-danger fs-14 pl-2 cursor-pointer position-absolute"
                                      onClick={() =>
                                        this.handleRemoveImage("identityProof")
                                      }
                                    ></i>
                                  </div>
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <NormalSelect
                          label="Address proof"
                          placeholder="Select"
                          value={address_proof}
                          arrow={true}
                          name="address_proof"
                          options={addressProofOption}
                          handleChange={this.handleInput}
                        />
                      </div>
                      <div className="col-md-6">
                        <div className="row mt-3 pt-1 upload-area">
                          <div className="col-md-3 pr-0">
                            <div className="fs-16 text-black-32 font-weight-bold line-height-19">
                              {"Address proof document"}
                            </div>
                          </div>
                          <div className="col-md-9">
                            <div className="d-flex align-items-center w-100 btn-file-input">
                              <button className="">
                                <input
                                  type="file"
                                  className=""
                                  accept="image/jpg, image/png, image/jpeg, application/pdf"
                                  onChange={this.handleAddressProofFile}
                                  id="handleAddressProofFile"
                                  title=""
                                />
                                {addressProofFile == "" ? (
                                  "Choose file"
                                ) : (
                                  <>
                                    {address_proof_name.length == 10
                                      ? address_proof_name
                                      : address_proof_name.substring(0, 9)}
                                    {"..."}
                                  </>
                                )}
                              </button>
                              {address_proof_name != "" &&
                                !isaddressProofUploading && (
                                  <div className="pl-3 position-relative">
                                    <img
                                      src={
                                        this.isPdf(addressProofFile)
                                          ? pdf
                                          : addressProofFile
                                      }
                                      alt={address_proof_name}
                                      height="75"
                                      width="75"
                                    />
                                    <i
                                      className="icon-Delete text-danger fs-14 pl-2 cursor-pointer position-absolute"
                                      onClick={() =>
                                        this.handleRemoveImage("addressProof")
                                      }
                                    ></i>
                                  </div>
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ConfirmationPopup
          isOpen={isDelete}
          toggle={this.toggleDelete}
          onSubmit={this.handleDelete}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  accessData: state.home.accessData,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      fileUpload,
      getDistrictApiwithState,
      allState,
      getPincodeApiwithStateDistrict,
      addBod,
      getAddressProofDropdown,
      getIdentityProofDropdown,
      getEducationDropdown,
      currentTab,
      getBODById,
      updateBod,
      deleteBOD,
    },
    dispatch
  );
};

export const AddBod = connect(mapStateToProps, mapDispatchToProps)(AddBodClass);
