import { Gateway } from "service/apiVariables";
import { Toast } from "service/toast";
import { api } from "service/api";

export const getGateway = () => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    api({ ...Gateway.getGateway })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

export const getBMS = () => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    api({ ...Gateway.getBMS })
      .then(({ data }) => {
        dispatch({ type: "BMS", payload: data });
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

export const updateTermsCondition = (body) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    api({ ...Gateway.updateTermsCondition, body })
      .then(({ message }) => resolve(message))
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

export const updateBMS = (body) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    api({ ...Gateway.updateBMS, body })
      .then(({ data, message }) => {
        resolve(data);
        Toast({ type: "success", message });
        dispatch({ type: "BMS", payload: true });
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};
