import React, { Component } from "react";
import { Card } from "./card";
import { Strip } from "./strip";
import { Pagination, CommonPageLoader } from "component/common";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getAllGstComp } from "action/ComplianceAct";
import { getAllGstCompRm } from "action/RmAct";
export class GstReturnListClass extends Component {
  constructor() {
    super();
    this.state = {
      Gsts: [],
      pageMeta: {},
      page: 1,
      isLoading: false,
    };
  }

  handleGst = (page) => {
    let { limit, searchValue, filterValue, filterBy, id } = this.props;
    this.setState({ isLoading: true });
    let payload = {
      limit,
      page,
      search: searchValue,
      filterValue,
      filterBy,
    };
    id
      ? this.props.getAllGstCompRm(id, payload).then((data) => {
          this.setState({
            Gsts: data.results,
            pageMeta: data.pageMeta,
            isLoading: false,
          });
        })
      : this.props.getAllGstComp(payload).then((data) => {
          this.setState({
            Gsts: data.results,
            pageMeta: data.pageMeta,
            isLoading: false,
          });
        });
  };

  handlePagination = (page) => {
    this.handleGst(page);
  };

  componentDidUpdate(prevProps) {
    if (this.props.activeTab == 5) {
      this.props.limit !== prevProps.limit && this.handleGst(1);
      this.props.searchValue !== prevProps.searchValue && this.handleGst(1);
      this.props.filterValue !== prevProps.filterValue && this.handleGst(1);
    }
    if (prevProps.activeTab != this.props.activeTab) {
      let { activeTab } = this.props;
      if (activeTab == 5) {
        this.handleGst(1);
      }
    }
  }

  render() {
    const { Gsts, pageMeta, isLoading } = this.state;
    let { editAccess = false } = this.props;
    return (
      <>
        {!isLoading && Gsts.length === 0 ? (
          <p className="py-4 mb-0 text-black-50 fs-22 line-height-25 text-center">
            No data found
          </p>
        ) : !isLoading ? (
          <>
            {Gsts.length != 0 ? (
              <>
                <div className="scroll-x">
                  <div
                    className="d-flex container-sa flex-column  flex-wrap mt-3 px-0 justify-content-center"
                    style={
                      this.props.view === "1" ? { minWidth: "1200px" } : null
                    }
                  >
                    <div className="d-flex flex-wrap com-list-card">
                      {Gsts.map((Gst, index) => {
                        return this.props.view === "2" ? (
                          <Card
                            payload={Gst}
                            index={index}
                            editAccess={editAccess}
                          />
                        ) : (
                          <Strip
                            payload={Gst}
                            index={index}
                            editAccess={editAccess}
                          />
                        );
                      })}
                    </div>
                  </div>
                </div>
                {pageMeta && (
                  <Pagination
                    handleChange={this.handlePagination}
                    pageMeta={pageMeta}
                  />
                )}
              </>
            ) : (
              <p className="py-4 mb-0 text-black-50 fs-22 line-height-25 text-center">
                No data found
              </p>
            )}
          </>
        ) : (
          <>
            {/* Loader */}
            <CommonPageLoader />
          </>
        )}
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getAllGstComp,
      getAllGstCompRm,
    },
    dispatch
  );
};

let component = GstReturnListClass;

export const GstReturnList = connect(null, mapDispatchToProps)(component);
