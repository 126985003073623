import React, { Component } from "react";
import RMNavbar from "component/common/RMNavbar";
import { history } from "service/helpers";
export class KYMLayout extends Component {
  componentDidMount() {
    let authToken = localStorage.getItem("AuthToken");
    if (!authToken) history.push("/");
  }

  render() {
    let { children } = this.props;

    return (
      <div>
        <RMNavbar location={this.props.location} module="kym" />
        {children}
      </div>
    );
  }
}
