import React, { Component } from "react";
import { Fpo } from "./fpo";
import { Gateway } from "./gateway";

export class FpoGateway extends Component {
  render() {
    return (
      <div className="container-sa pb-1">
        {/* FPO Management & Gateway Services */}
        <div className="background-fpo w-100">
          <div className="gateway_wrapper">
            <div className="row">
              <div className="col-md-4">
                {/* FPO */}
                <Fpo />
              </div>
              <div className="col-md-8">
                {/* Gateway Services */}
                <Gateway />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
