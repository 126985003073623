import React from 'react';
import { history } from 'service/helpers';
import { currencyConvert } from 'service/helperFunctions';

function Strip(props) {
   const { payload, index, editAccess } = props;
   const { revenueId, businessDetails, fymYear, revenueActivity } = payload;
   return (
      <div
         key={index}
         className='container-sa license-strip shadow rounded mb-3'
      >
         <div className='row align-items-center h-100 px-2'>
            <div className='col-2'>
               <div className='fs-18 fw-600'>{fymYear}</div>
               <div className='text-black-50'>{'Financial year'}</div>
            </div>
            <div className='col-6'>
               <div className='fs-18 fw-600'>
                  {businessDetails.businessName}
               </div>
               <div className='text-black-50'>{'Activity'}</div>
            </div>
            <div className='col-3'>
               <div className='fs-18 fw-600'>
                  {revenueActivity ? currencyConvert(revenueActivity) : '-'}
               </div>
               <div className='text-black-50'>
                  {'Revenue ( '} <span className='icon-rupee'></span> {' )'}
               </div>
            </div>
            {editAccess &&
               <div className='col-1'>
                  <div
                     className='text-black-50 cursor-pointer'
                     onClick={() => history.push(`/fpo/edit-revenue/${revenueId}`)}
                  >
                     {'Edit'}
                  </div>
               </div>
            }
         </div>
      </div>
   );
}

export default Strip;
