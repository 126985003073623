import { FIGCRPType } from "service/actionType";

const initialState = {
  crpMembers: {},
  figMembers: {},
};

export default (state = Object.assign({}, initialState), { type, payload }) => {
  switch (type) {
    case FIGCRPType.crpMembers:
      return {
        ...state,
        crpMembers: payload,
      };
    case FIGCRPType.figMembers:
      return {
        ...state,
        figMembers: payload,
      };
    default:
      return state;
  }
};
