import React from "react";
import "./documentfooter.scss";
export function DocumentFooter() {
  return (
    <div className="document-footer mt-3 p-3">
      <div className="container">
        <div className="document-footer-heading mb-4 ">Note:</div>
        <div className="d-flex">
          <ul className="mr-5">
            <li className="document-footer-body mb-4">
              Accepted formats : Jpg, Jpeg, Pdf, Xlsx
            </li>
            {/* <li className='document-footer-body '>
                     All documents are mandatory to upload
                  </li>
                  <li className='document-footer-body'>
                     Please upload original documents
                  </li> */}
          </ul>
          {/* <ul className=''>
                  <li className='document-footer-body mb-4'>
                     Keep all originals handy as we may ask for them if required
                  </li>
                  
               </ul> */}
        </div>
      </div>
    </div>
  );
}
