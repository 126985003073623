import React, { Component } from "react";
import Moment from "react-moment";
import { getDescriptionData } from "service/helperFunctions";
import { history } from "service/helpers";
import defaultAwardsImage from "assets/images/DefaultAward.jpg";
export class Card extends Component {
  state = {
    active: false,
  };
  handleDropdown = () => {
    if (!this.state.active) {
      document.addEventListener("click", this.handleOutsideClick, false);
    } else {
      document.removeEventListener("click", this.handleOutsideClick, false);
    }
    this.setState((prevState) => ({
      active: !prevState.active,
    }));
  };

  handleOutsideClick = (e) => {
    if (this.node != null) {
      if (this.node.contains(e.target)) {
        return;
      }
    }
    this.handleDropdown();
  };

  render() {
    const {
      story: { title, description, publishOn, storyDocs, storyId },
      getId,
      getStoryId,
      handleDelete,
      editAccess = false,
      viewAccess = false,
      deleteAccess = false,
    } = this.props;

    let { active } = this.state;

    return (
      <div className="success-card">
        {storyDocs.length !== 0 ? (
          <img
            className="success-card-img rounded"
            src={storyDocs[0].documentUrl}
            alt={storyDocs[0].fileName}
          />
        ) : (
          <img
            className="success-card-img rounded"
            src={defaultAwardsImage}
            alt="awards"
          />
        )}
        <div className="success-card-body h-100 justify-content-end">
          <div className="success-card-body-title mb-3">{title}</div>
          <div className="d-flex mb-3">
            <div
              className="success-card-body-content"
              dangerouslySetInnerHTML={{
                __html:
                  description !== "" ? getDescriptionData(description, 80) : "",
              }}
            ></div>
          </div>
          <div className="award-card-body-date d-flex">
            <div className="d-flex flex-column success-card-body-date">
              <p className="fs-14 fw-500 line-height-20 mb-1">Published Date</p>
              <Moment format="MMM DD YYYY">{publishOn}</Moment>
            </div>
            {viewAccess || editAccess || deleteAccess ? (
              <div className="ml-auto mt-auto">
                <div className="ml-auto mt-auto">
                  <div>
                    <div className="position-relative">
                      <i
                        className="icon-horizontal-dots fs-20 cursor-pointer"
                        onClick={this.handleDropdown}
                      ></i>
                      <div
                        className={`${
                          active ? "view-more-option d-block" : "d-none"
                        }`}
                      >
                        <ul>
                          {getId && editAccess && (
                            <li onClick={getStoryId}>{"Edit"}</li>
                          )}
                          {viewAccess && (
                            <li
                              onClick={() =>
                                history.push(
                                  `/fpo/viewDetails/successstories&${storyId}`
                                )
                              }
                            >
                              {"View"}
                            </li>
                          )}
                          {getId && deleteAccess && (
                            <li onClick={() => handleDelete(storyId)}>
                              {"Delete"}
                            </li>
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}
