import { academyType } from "service/actionType";

const initialState = {
  searchValue: "",
  page: 0,
  categoryList: [],
  notificationCount: 0,
};

export default (state = Object.assign({}, initialState), { type, payload }) => {
  switch (type) {
    case academyType.academySearch:
      return {
        ...state,
        searchValue: payload,
      };
    case academyType.academyPage:
      return {
        ...state,
        page: payload,
      };
    case academyType.categoryList:
      return {
        ...state,
        categoryList: payload,
      };
    case academyType.notificationCount:
      return {
        ...state,
        notificationCount: payload,
      };
    default:
      return state;
  }
};
