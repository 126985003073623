import React, { Component, Fragment } from "react";
import Joyride from "react-joyride";
import Multiselect from "multiselect-react-dropdown";
import {
  getListOfMembersToSms,
  getListOfStaffToSms,
  getListOfBODToSms,
} from "action/MemberAct";
import {
  NormalCheckbox,
  CommonPageLoader,
  Pagination,
  NormalDate,
} from "component/common";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Toast } from "service/toast";
import {
  getToolTipById,
  sendRoboCallRequest,
  sendToolTipById,
} from "action/RoboCall";
import { debounceFunction } from "service/debounce";
import SimpleReactValidator from "simple-react-validator";
import "../../sendSmsPopup/forward.scss";

export class MemberListClass extends Component {
  constructor() {
    super();
    this.state = {
      userList: [],
      isLoading: false,
      selecteduserList: [],
      isSelectAll: false,
      message: "",
      searchValue: "",
      isButtonClicked: false,
      isLaunchDetails: false,
      scheduleDate: "",
      scheduleTime: "",
      currentTime: false,
      pageMeta: {
        pageCount: undefined,
        page: 1,
      },
      searchPage: 1,
      memberRun: false,
      launchRun: false,
      isSkip: false,
      cropOptions: [],
      villageOptions: [],
      figOptions: [],
      filterOptions: [],
      sampleOption: [],
      searchedUserList: [],
    };
    this.cropsRef = React.createRef();
    this.villageRef = React.createRef();
    this.figRef = React.createRef();
  }

  //lifeCycles
  componentDidMount = () => {
    this._getUserProfileList();
    this.props
      .getToolTipById(JSON.parse(localStorage.getItem("fpoId")))
      .then((data) => {
        let filterDataOne = [];
        let filterDataTwo = [];
        filterDataOne = data?.filter((i) => Number(i?.toolTipId) === 2);
        filterDataTwo = data?.filter((i) => Number(i?.toolTipId) === 3);
        this.setState({
          memberRun: filterDataOne?.length === 0 ? true : false,
          launchRun: filterDataTwo?.length === 0 ? true : false,
        });
      });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.activeTab !== this.props.activeTab) {
      this._getUserProfileList();
      this.props
        .getToolTipById(JSON.parse(localStorage.getItem("fpoId")))
        .then((data) => {
          let filterDataOne = [];
          let filterDataTwo = [];
          filterDataOne = data?.filter((i) => Number(i?.toolTipId) === 2);
          filterDataTwo = data?.filter((i) => Number(i?.toolTipId) === 3);
          this.setState({
            memberRun: filterDataOne?.length === 0 ? true : false,
            launchRun: filterDataTwo?.length === 0 ? true : false,
          });
        });
    }
    if (prevState.isLaunchDetails !== this.state.isLaunchDetails) {
      this.setState({
        isSkip: false,
      });
    }
  }

  _getUserProfileList = () => {
    let { pageMeta } = this.state;
    let { activeTab, smsAccess } = this.props;
    if (smsAccess) {
      let { userList, selecteduserList } = this.state;
      userList = [];
      selecteduserList = [];
      this.setState({ userList, selecteduserList });
      if (activeTab === "1") {
        this.props.getListOfMembersToSms().then((data) => {
          let memberList = [];
          data.map((list) => {
            memberList.push({
              ...list,
              isChecked: false,
              crops:
                list.crops && list.crops.length > 0
                  ? String(list.crops.join(",")).toLowerCase().split(",")
                  : [],
            });
          });

          let crops = [];
          let newCrops = [];
          let finalCrops = [];
          memberList.map((list) => {
            crops.push(list.crops);
          });
          crops.forEach((list) => list.forEach((crop) => newCrops.push(crop)));
          let uniqueCrops = [...new Set(newCrops)];
          let newUniqueCrops = uniqueCrops.filter((list) => list !== null);
          newUniqueCrops.forEach((list) => {
            finalCrops.push({
              label: list,
              value: list,
              type: "crops",
            });
          });

          let village = [];
          let finalVillage = [];
          memberList.map((list) => {
            village.push(list.village);
          });
          let uniqueVillage = [...new Set(village)];
          let newUnqueVillage = uniqueVillage.filter((list) => list !== null);
          newUnqueVillage.forEach((list) => {
            finalVillage.push({
              label: list,
              value: list,
              type: "village",
            });
          });

          let fig = [];
          let finalFIG = [];
          memberList.map((list) => {
            fig.push(list.figName);
          });
          let uniqueFIG = [...new Set(fig)];
          let newUniqueFIG = uniqueFIG.filter((list) => list !== null);
          newUniqueFIG.forEach((list) => {
            finalFIG.push({
              label: list,
              value: list,
              type: "fig",
            });
          });

          this.setState({
            userList: [...memberList],
            isLoading: true,
            pageMeta: { ...pageMeta, pageCount: Math.ceil(data.length / 10) },
            cropOptions: finalCrops,
            villageOptions: finalVillage,
            figOptions: finalFIG,
          });
        });
      } else if (activeTab === "2") {
        this.props.getListOfBODToSms().then((data) => {
          let staffList = [];
          data.map((list) => {
            list.isChecked = false;
            staffList.push(list);
          });
          this.setState({
            userList: [...staffList],
            pageMeta: { ...pageMeta, pageCount: Math.ceil(data.length / 10) },
            isLoading: true,
          });
        });
      } else if (activeTab === "3") {
        this.props.getListOfStaffToSms().then((data) => {
          let staffList = [];
          data.map((list) => {
            list.isChecked = false;
            staffList.push(list);
          });
          this.setState({
            userList: [...staffList],
            pageMeta: { ...pageMeta, pageCount: Math.ceil(data.length / 10) },
            isLoading: true,
          });
        });
      }
    }
  };

  //handle select users
  handleToggle = (id) => {
    let { userList, selecteduserList, searchedUserList } = this.state;
    let { activeTab } = this.props;
    let index = "";
    if (activeTab === "1") {
      index = userList.findIndex((x) => x.memberID === id);
    } else if (activeTab === "2") {
      index = userList.findIndex((x) => x.bodID === id);
    } else if (activeTab === "3") {
      index = userList.findIndex((x) => x.staffId === id);
    }

    userList[index].isChecked = !userList[index].isChecked;
    if (userList[index].isChecked) {
      if (activeTab === "1") {
        selecteduserList.push(userList[index].memberID);
      } else if (activeTab === "2") {
        selecteduserList.push(userList[index].bodID);
      } else if (activeTab === "3") {
        selecteduserList.push(userList[index].staffId);
      }
      this.setState({ userList, selecteduserList });
      if (selecteduserList.length !== userList.length) {
        this.setState({ isSelectAll: false });
      } else {
        this.setState({ isSelectAll: true });
      }

      let newUserList = [];
      newUserList.push(userList[index].memberID);
      let finalList = [...new Set(newUserList)];
      this.setState({
        searchedUserList: finalList,
      });
    } else {
      if (index === 0) {
        selecteduserList.shift();
        searchedUserList.shift();
      } else {
        selecteduserList.pop();
        searchedUserList.pop();
      }
      if (selecteduserList.length !== userList.length) {
        this.setState({ isSelectAll: false });
      }
      this.setState({
        selecteduserList,
      });
      if (selecteduserList?.length === 0) {
        this.cropsRef.current.resetSelectedValues();
        this.villageRef.current.resetSelectedValues();
        this.figRef.current.resetSelectedValues();
        this.setState({
          selecteduserList: [],
          searchedUserList: [],
          filterOptions: [],
        });
      }
    }
  };

  //handle select all users
  handleToggleForSelectAll = (isChecked) => {
    let { userList, selecteduserList, isSelectAll, searchValue } = this.state;
    let { activeTab } = this.props;
    // selecteduserList = [];
    let search = searchValue.toLocaleLowerCase().trim();
    let filteredList =
      search.length > 0
        ? userList.filter((list) => {
            const {
              crops = [],
              memberFirstName = "",
              memberID = "",
              memberLastName = null,
              village = null,
              firstName = "",
              lastName = null,
              figName = null,
              bodID = "",
              staffId = "",
            } = list;
            if (activeTab === "1") {
              // if (crops.includes(search)) {
              //   return list;
              // } else
              if (
                String(memberFirstName)
                  .concat(" ", memberLastName ? memberLastName : "")
                  .toLocaleLowerCase()
                  .includes(search) ||
                String(memberID).toLocaleLowerCase().includes(search)
                // ||
                // (village &&
                //   String(village).toLocaleLowerCase().includes(search)) ||
                // (figName &&
                //   String(figName).toLocaleLowerCase().includes(search))
              ) {
                return list;
              }
            } else if (activeTab === "2") {
              if (
                String(firstName)
                  .concat(" ", lastName ? lastName : "")
                  .toLocaleLowerCase()
                  .includes(search) ||
                String(bodID).toLocaleLowerCase().includes(search) ||
                (village &&
                  String(village).toLocaleLowerCase().includes(search))
              ) {
                return list;
              }
            } else if (activeTab === "3") {
              if (
                String(memberFirstName)
                  .concat(" ", lastName ? lastName : "")
                  .toLocaleLowerCase()
                  .includes(search) ||
                String(staffId).toLocaleLowerCase().includes(search)
              ) {
                return list;
              }
            }
          })
        : userList;
    this.setState({ selecteduserList, isSelectAll: !isSelectAll }, () => {
      filteredList.map((list) => {
        if (isChecked === true) {
          list.isChecked = false;
          selecteduserList = [];
        } else {
          list.isChecked = true;
          if (activeTab === "1") {
            selecteduserList.push(list.memberID);
          } else if (activeTab === "2") {
            selecteduserList.push(list.bodID);
          } else if (activeTab === "3") {
            selecteduserList.push(list.staffId);
          }
        }
      });
      let uniqueList = [...new Set(selecteduserList)];
      if (
        uniqueList?.length === 0 &&
        searchValue?.length === 0 &&
        isSelectAll
      ) {
        this.cropsRef.current.resetSelectedValues();
        this.villageRef.current.resetSelectedValues();
        this.figRef.current.resetSelectedValues();
        this.setState({
          userList,
          selecteduserList: [],
          searchedUserList: [],
          filterOptions: [],
        });
        return null;
      }
      this.setState({ userList, selecteduserList: uniqueList });
    });
  };

  //search inputs
  handleSearchInput = (event) => {
    let { selecteduserList, pageMeta } = this.state;
    let { value } = event.target;
    if (selecteduserList?.length > 0) {
      this.setState({
        isSelectAll: false,
      });
    }
    debounceFunction(
      () =>
        this.setState({
          searchValue: value,
          searchPage: 1,
          pageMeta: { ...pageMeta, page: 1 },
        }),
      500
    );
  };

  validator = new SimpleReactValidator({
    validators: {
      element: (message) => (
        <span className="text-danger error-message font-md">{message}</span>
      ),
      autoForceUpdate: this,
    },
  });

  handleChange = (e) => {
    let { name, value } = e.target || {};
    let date = new Date();
    if (name === "currentTime") {
      if (value === true) {
        this.setState({
          scheduleDate: date,
          scheduleTime: date,
        });
      } else {
        this.setState({
          scheduleDate: "",
          scheduleTime: "",
        });
      }
    }
    this.setState({
      [name]: value,
    });
  };

  nextScreen = () => {
    let { selecteduserList } = this.state;
    let { memberRun, launchRun } = this.state;
    if (selecteduserList.length !== 0) {
      this.setState({
        isLaunchDetails: true,
        memberRun: memberRun,
      });
      setTimeout(() => {
        this.setState({
          launchRun: launchRun,
        });
      }, 200);
    } else {
      Toast({ type: "error", message: "Please select atleast one user" });
    }
  };

  handleSubmit = async () => {
    if (this.validator.allValid()) {
      let { activeTab, audioDetail, onNext, sendRoboCallRequest } = this.props;
      let { selecteduserList, userList, scheduleDate, scheduleTime } =
        this.state;
      this.setState({ isButtonClicked: true });
      if (selecteduserList.length === userList.length) {
        let body = {
          ...audioDetail,
          sendAll: true,
          scheduleDate,
          scheduleTime,
        };
        if (activeTab === "1") {
          let data = { ...body, userType: "member" };
          sendRoboCallRequest(data).then(() => {
            onNext();
          });
        } else if (activeTab === "2") {
          let data = { ...body, userType: "bod" };
          sendRoboCallRequest(data).then(() => {
            onNext();
          });
        } else if (activeTab === "3") {
          let data = { ...body, userType: "staff" };
          sendRoboCallRequest(data).then(() => {
            onNext();
          });
        }
      } else {
        let body = {
          ...audioDetail,
          sendAll: false,
          memberIds: selecteduserList,
          scheduleDate,
          scheduleTime,
        };
        if (activeTab === "1") {
          let data = { ...body, userType: "member" };
          sendRoboCallRequest(data).then(() => {
            onNext();
          });
        } else if (activeTab === "2") {
          let data = { ...body, userType: "bod" };
          sendRoboCallRequest(data).then(() => {
            onNext();
          });
        } else if (activeTab === "3") {
          let data = { ...body, userType: "staff" };
          sendRoboCallRequest(data).then(() => {
            onNext();
          });
        }
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  handlePageChange = ({ page }, isSearch) => {
    let { pageMeta } = this.state;
    if (isSearch) {
      this.setState({
        searchPage: page,
      });
    } else {
      this.setState({
        pageMeta: { ...pageMeta, page },
      });
    }
  };

  onSelect = (list, changingList, method) => {
    let { filterOptions, searchedUserList } = this.state;
    let concatinateList = filterOptions.concat(list);
    let filteredConcatenatedList = (concatinateList = concatinateList.filter(
      (value, index, self) =>
        index ===
        self.findIndex(
          (list) => list.value === value.value && list.label === value.label
        )
    ));

    if (method === "remove") {
      filteredConcatenatedList = filteredConcatenatedList.filter(function (
        item
      ) {
        return item.value !== changingList.value;
      });
    }

    this.setState({ filterOptions: filteredConcatenatedList });
    let { userList } = this.state;
    let newArray = [];
    let unCheck = [];
    for (let i = 0; i < filteredConcatenatedList.length; i++) {
      userList.filter((newList) => {
        const { crops = [], village = null, figName = null } = newList;
        let filterBy =
          filteredConcatenatedList[i].type === "crops"
            ? crops
            : filteredConcatenatedList[i].type === "village"
            ? village
            : figName;
        let filterMethod =
          filteredConcatenatedList[i].type === "crops"
            ? filterBy.includes(filteredConcatenatedList[i].value)
            : filterBy === filteredConcatenatedList[i].value;
        if (filterMethod) {
          newArray.push(newList);
        }
      });
    }

    let searchedList = [];
    for (let i = 0; i < searchedUserList.length; i++) {
      userList.filter((newList) => {
        if (searchedUserList[i] === newList?.memberID) {
          searchedList.push(newList);
        }
      });
    }

    let concatenateTwoArrays = newArray.concat(searchedList);
    let setSelectedUserList = [];
    concatenateTwoArrays.map((list) => {
      list.isChecked = true;
      setSelectedUserList.push(list.memberID);
    });

    if (filteredConcatenatedList.length === 0 && searchedList.length === 0) {
      userList.map((list) => {
        list.isChecked = false;
      });
    }

    let uniqueList = [...new Set(setSelectedUserList)];
    let concatenatedList = uniqueList;
    let finalList = [...new Set(concatenatedList)];

    unCheck = userList.filter(function (item) {
      return finalList.indexOf(item.memberID) === -1;
    });

    unCheck.map((list) => {
      list.isChecked = false;
    });

    this.setState({ selecteduserList: finalList });
  };

  callback = (data) => {
    let { isLaunchDetails, isSkip } = this.state;
    if (data?.action === "skip") {
      this.setState({ isSkip: true });
    }
    if (!isSkip) {
      if (data?.action === "reset") {
        let body = isLaunchDetails
          ? { key: "3" }
          : { key: "2" };
        this.props.sendToolTipById(
          JSON.parse(localStorage.getItem("fpoId")),
          body
        );
      }
    }
  };

  render() {
    let {
      userList = [],
      isLoading,
      selecteduserList = [],
      isSelectAll,
      searchValue,
      isButtonClicked,
      pageMeta,
      searchPage,
      isLaunchDetails,
      scheduleDate,
      scheduleTime,
      currentTime,
      memberRun,
      launchRun,
      cropOptions,
      villageOptions,
      figOptions,
    } = this.state;

    let { activeTab, goBack } = this.props;

    const title = {
      margin: "10px 0px 10px 15px",
      color: "#189c5c",
      fontSize: "20px",
      display: "flex",
      gap: "10px",
      alignItems: "center",
    };

    let search = searchValue.toLocaleLowerCase().trim();

    let userData =
      search.length > 0
        ? userList.filter((list) => {
            const {
              crops = [],
              memberFirstName = "",
              memberID = "",
              memberLastName = null,
              village = null,
              firstName = "",
              lastName = null,
              figName = null,
              bodID = "",
              staffId = "",
            } = list;
            if (activeTab === "1") {
              // if (crops.includes(search)) {
              //   return list;
              // } else
              if (
                String(memberFirstName)
                  .concat(" ", memberLastName ? memberLastName : "")
                  .toLocaleLowerCase()
                  .includes(search) ||
                String(memberID).toLocaleLowerCase().includes(search)
                //  ||
                // (village &&
                //   String(village).toLocaleLowerCase().includes(search)) ||
                // (figName &&
                //   String(figName).toLocaleLowerCase().includes(search))
              ) {
                return list;
              }
            } else if (activeTab === "2") {
              if (
                String(firstName)
                  .concat(" ", lastName ? lastName : "")
                  .toLocaleLowerCase()
                  .includes(search) ||
                String(bodID).toLocaleLowerCase().includes(search) ||
                (village &&
                  String(village).toLocaleLowerCase().includes(search))
              ) {
                return list;
              }
            } else if (activeTab === "3") {
              if (
                String(firstName)
                  .concat(" ", lastName ? lastName : "")
                  .toLocaleLowerCase()
                  .includes(search) ||
                String(staffId).toLocaleLowerCase().includes(search)
              ) {
                return list;
              }
            }
          })
        : selecteduserList?.length > 0
        ? userList.sort((item) => {
            if (item.isChecked) {
              return -1;
            }
            if (!item.isChecked) {
              return 1;
            }
            return 0;
          })
        : userList;

    let memberTooltipContent = [
      {
        target: ".member-one",
        content: "Select this to send message to all the members of the FPO",
        disableBeacon: true,
        isFixed: true,
      },
      {
        target: ".member-two",
        // content: "Search for specific farmers by name",
        content:
          "Search for specific farmers by name, Crop type or Village name (use select all for selecting the searched items all at once)",
        disableBeacon: true,
        isFixed: true,
      },
    ];

    let launchTooltipContent = [
      {
        target: ".launch-one",
        content: "Select date and time for when call is to be launched",
        disableBeacon: true,
        isFixed: true,
      },
    ];

    if (userList?.length === 0) {
      delete memberTooltipContent[0];
    }

    return (
      <Fragment>
        <Joyride
          steps={isLaunchDetails ? launchTooltipContent : memberTooltipContent}
          run={isLaunchDetails ? launchRun : memberRun}
          callback={this.callback}
          continuous
          hideBackButton
          hideCloseButton
          scrollToFirstStep
          showProgress
          showSkipButton
          styles={{
            options: {
              zIndex: 10000,
            },
            buttonNext: {
              color: "#FFFFFF",
              backgroundColor: "#0a8c5b",
            },
          }}
        />
        <div style={title}>
          <i
            className="fas fa-arrow-left"
            onClick={() => {
              isLaunchDetails
                ? this.setState({ isLaunchDetails: false })
                : goBack();
            }}
            style={{
              fontSize: "16px",
              fontWeight: "normal",
              cursor: "pointer",
              marginTop: "-2px",
            }}
          ></i>
          <b>{isLaunchDetails ? "Schedule Call" : "Member List"}</b>
        </div>
        {isLaunchDetails ? (
          <div className="launching-details send-message mt-3">
            <div className="send-section launch-one">
              <div className="col-md-12 col-12 d-flex flex-column mb-3">
                <label className="fw-18 font-weight-bold">
                  Date
                  <span className="text-danger font-weight-bold"> *</span>
                </label>
                <NormalDate
                  isIcon={true}
                  value={scheduleDate}
                  name="scheduleDate"
                  placeholder="Select Date"
                  onChange={this.handleChange}
                  mindate={false}
                  maxdate={false}
                  timeInput={false}
                  isregPage={true}
                />
                {this.validator.message(
                  "scheduleDate",
                  scheduleDate,
                  "required"
                )}
              </div>
              <div className="col-md-12 col-12 d-flex flex-column mb-3">
                <label className="fw-18 font-weight-bold">
                  Time
                  <span className="text-danger font-weight-bold"> *</span>
                </label>
                <NormalDate
                  isIcon={true}
                  value={scheduleTime}
                  name="scheduleTime"
                  placeholder="Select Time"
                  onChange={this.handleChange}
                  showTimeSelect={true}
                  showTimeSelectOnly={true}
                  timeFormat="HH:mm"
                  timeIntervals={15}
                  isregPage={true}
                  dateFormat="HH:mm"
                />
                {this.validator.message(
                  "scheduleTime",
                  scheduleTime,
                  "required"
                )}
              </div>
              <div className="col-md-12 col-12 d-flex mt-3 mb-3">
                <NormalCheckbox
                  label="Launch Call now"
                  name="currentTime"
                  value={currentTime}
                  checked={currentTime === true}
                  onChange={this.handleChange}
                />
              </div>
              <div className="d-flex justify-content-end mt-2">
                <button
                  className="col-3 btn w-100 text-white bg-green d-flex align-items-center justify-content-center "
                  onClick={this.handleSubmit}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div className="send-message">
            <div className="send-section">
              <div id="modal-container-send-message">
                {userList.length !== 0 && (
                  <div className="px-3" id="check-in">
                    {selecteduserList.length !== 0 ? (
                      <p className="my-0 mr-2">
                        {selecteduserList.length} Selected
                      </p>
                    ) : null}
                    <div className="d-flex member-one">
                      <h5 className="text-green-18 fs-16 mr-3 line-height-24 font-weight-bold">
                        Select all
                      </h5>
                      <NormalCheckbox
                        className="custom-checkbox check-box"
                        value=""
                        name={"toggleEvent"}
                        checked={isSelectAll}
                        onChange={() =>
                          this.handleToggleForSelectAll(isSelectAll)
                        }
                      />
                    </div>
                  </div>
                )}
                <div className="col-12">
                  <input
                    type="text"
                    className="form-control form-control-sa member-two"
                    placeholder={
                      activeTab === "1"
                        ? // ? "Search by name, village, crop or FIG name"
                          "Search by name"
                        : "Search by name or village"
                    }
                    onChange={this.handleSearchInput}
                  />
                </div>
                <div className="col-12">
                  <Multiselect
                    options={cropOptions}
                    onSelect={(selectedList, selectedItem) =>
                      this.onSelect(selectedList, selectedItem, "add")
                    }
                    onRemove={(selectedList, removedItem) =>
                      this.onSelect(selectedList, removedItem, "remove")
                    }
                    displayValue="label"
                    showCheckbox={true}
                    placeholder="Filter by crops"
                    closeIcon={true}
                    showArrow={true}
                    ref={this.cropsRef}
                    className="mt-2"
                  />
                  <Multiselect
                    options={villageOptions}
                    onSelect={(selectedList, removedItem) =>
                      this.onSelect(selectedList, removedItem, "add")
                    }
                    onRemove={(selectedList, removedItem) =>
                      this.onSelect(selectedList, removedItem, "remove")
                    }
                    displayValue="label"
                    showCheckbox={true}
                    placeholder="Filter by village"
                    closeIcon={true}
                    showArrow={true}
                    ref={this.villageRef}
                    className="mt-2"
                  />
                  <Multiselect
                    options={figOptions}
                    onSelect={(selectedList, removedItem) =>
                      this.onSelect(selectedList, removedItem, "add")
                    }
                    onRemove={(selectedList, removedItem) =>
                      this.onSelect(selectedList, removedItem, "remove")
                    }
                    displayValue="label"
                    showCheckbox={true}
                    placeholder="Filter by FIG"
                    closeIcon={true}
                    showArrow={true}
                    ref={this.figRef}
                    className="mt-2"
                  />
                </div>
                {isLoading ? (
                  <>
                    {userData.length !== 0 ? (
                      userData
                        .slice(
                          search.length == 0
                            ? (pageMeta.page - 1) * 10
                            : (searchPage - 1) * 10,
                          search.length == 0
                            ? pageMeta.page * 10
                            : searchPage * 10
                        )
                        .map((list, index) => {
                          let {
                            memberFirstName = "",
                            memberLastName = "",
                            contact = null,
                            isChecked,
                            firstName = "",
                            crops = [],
                            lastName = "",
                            mobileNumber = "",
                            memberID,
                            bodID,
                            village = null,
                            figName = null,
                            staffId,
                          } = list;
                          return (
                            <div key={index}>
                              <div
                                className={`px-3 d-flex justify-content-between align-items-center ${
                                  isChecked ? "bg-blue-f9" : "bg-white"
                                }`}
                              >
                                <div className="details m-0">
                                  {activeTab === "1" ? (
                                    <div className="">
                                      <p className="user-name">
                                        {memberFirstName} {memberLastName}
                                      </p>
                                      <p className="contact">{contact}</p>
                                    </div>
                                  ) : (
                                    <div className="">
                                      <p className="user-name">
                                        {firstName} {lastName}
                                      </p>
                                      <p className="contact">{mobileNumber}</p>
                                    </div>
                                  )}
                                  <div>
                                    {activeTab === "1" && (
                                      <div>
                                        <b>Crops : </b>
                                        <span>
                                          {crops.length > 0
                                            ? crops.join(", ")
                                            : "-"}
                                        </span>
                                      </div>
                                    )}
                                    <div>
                                      <b>Village : </b>
                                      <span>{village ? village : "-"}</span>
                                    </div>
                                    <div>
                                      <b>FIG name : </b>
                                      <span>{figName ? figName : "-"}</span>
                                    </div>
                                  </div>
                                </div>
                                <div className="" id="check-in">
                                  <NormalCheckbox
                                    className="custom-checkbox check-box"
                                    value=""
                                    name={`toggleEvent-${index}`}
                                    checked={isChecked}
                                    onChange={() =>
                                      this.handleToggle(
                                        memberID
                                          ? memberID
                                          : bodID
                                          ? bodID
                                          : staffId
                                      )
                                    }
                                  />
                                </div>
                              </div>
                              <div id="hr-line"></div>
                            </div>
                          );
                        })
                    ) : (
                      <p className="py-4 mb-0 text-black-50 fs-22 line-height-25 text-center">
                        {"No data found"}
                      </p>
                    )}
                  </>
                ) : (
                  <CommonPageLoader />
                )}
              </div>
            </div>
            {(search.length == 0
              ? pageMeta.pageCount > 1
              : Math.ceil(userData.length / 10) > 1) && (
              <Pagination
                isTotalEntriesNeeded={true}
                totalMembers={
                  search.length == 0 ? userList.length : userData.length
                }
                handleChange={(e) =>
                  this.handlePageChange(e, search.length > 0 ? true : false)
                }
                pageMeta={
                  search.length == 0
                    ? pageMeta
                    : {
                        pageCount: Math.ceil(userData.length / 10),
                        page: searchPage,
                      }
                }
              />
            )}
            <div className="d-flex justify-content-end align-items-center my-4">
              <button
                className="col-3 w-100 next-btn d-flex align-items-center justify-content-center "
                onClick={() => {
                  this.nextScreen();
                }}
              >
                Next
              </button>
            </div>
          </div>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    audioDetail: state.roboCall.audioDetail,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getListOfMembersToSms,
      getListOfStaffToSms,
      getListOfBODToSms,
      sendRoboCallRequest,
      sendToolTipById,
      getToolTipById,
    },
    dispatch
  );
};

let component = MemberListClass;

export const MemberList = connect(
  mapStateToProps,
  mapDispatchToProps
)(component);
