import React, { Component } from 'react';

export class NormalButton extends Component {
   render() {
      const {
         className = '',
         label = '',
         onClick,
         id,
         disabled = false,
         outline = false,
         mainbg = false,
         link = false,
         normal = false,
         danger = false,
         rightIcon = '',
         isPopup = false,
      } = this.props;

      return (
         <div className={`${isPopup ? '' : 'w-100'} `}>
            <button
               id={id}
               className={`btn cursor-pointer ${outline ? 'outline-btn' : ''} ${
                  mainbg ? 'mainbg-btn' : ''
                  } ${normal ? 'normal-btn' : ''} ${danger ? 'danger-btn' : ''} ${
                  link ? 'delete-btn' : ''
                  } ${className}`}
               onClick={onClick}
               disabled={disabled}
            >
               {label}
               {rightIcon !== '' ? (
                  <span className={`btn-right-icon ${rightIcon}`}></span>
               ) : (
                     ''
                  )}
            </button>
         </div>
      );
   }
}
