import { Bodandstaff } from "service/apiVariables";
import { Toast } from "service/toast";
import { api } from "service/api";
import { addQuery } from "service/helperFunctions";

//Add BOD
export const addBod = (body) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    api({ ...Bodandstaff.addBod, body })
      .then(({ status, message }) => {
        resolve(status);
        Toast({ type: "success", message, time: 3000 });
      })
      .catch(({ message, status }) => {
        resolve(status);
        reject(Toast({ type: "error", message }));
      });
  });
};

//update Bod
export const updateBod = (body) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    api({ ...Bodandstaff.updateBod, body })
      .then(({ status, message }) => {
        resolve(status);
        Toast({ type: "success", message, time: 3000 });
      })
      .catch(({ message, status }) => {
        resolve(status);
        reject(Toast({ type: "error", message }));
      });
  });
};

//Get Address proof Dropdown
export const getAddressProofDropdown = () => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    api({ ...Bodandstaff.getAddressProofDropdown })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//Get Identity proof Dropdown
export const getIdentityProofDropdown = () => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    api({ ...Bodandstaff.getIdentityProofDropdown })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//get Education Dropdown

export const getEducationDropdown = () => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    api({ ...Bodandstaff.getEducationDropdown })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//Add Staff

export const addStaff = (body) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    api({ ...Bodandstaff.addStaff, body })
      .then(({ status, message }) => {
        resolve(status);
        Toast({ type: "success", message, time: 3000 });
      })
      .catch(({ message, status }) => {
        resolve(status);
        reject(Toast({ type: "error", message }));
      });
  });
};

//Update Staff

export const updateStaff = (body) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    api({ ...Bodandstaff.updateStaff, body })
      .then(({ status, message }) => {
        resolve(status);
        Toast({ type: "success", message, time: 3000 });
      })
      .catch(({ message, status }) => {
        resolve(status);
        reject(Toast({ type: "error", message }));
      });
  });
};

//Get Bod List

export const getBodList = (query) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    addQuery(query, Bodandstaff.getBodList);
    api({ ...Bodandstaff.getBodList })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//get Staff List

export const getStaffList = (query) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    addQuery(query, Bodandstaff.getStaffList);
    api({ ...Bodandstaff.getStaffList })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//get Bod By Id

export const getBODById = (body) => (dispatch, getState) => {
  let { getBODById } = Bodandstaff;

  getBODById.id = body.id;
  return new Promise((resolve, reject) => {
    api({ ...Bodandstaff.getBODById, body })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//get Staff By Id

export const getStaffById = (body) => (dispatch, getState) => {
  let { getStaffById } = Bodandstaff;

  getStaffById.id = body.id;
  return new Promise((resolve, reject) => {
    api({ ...Bodandstaff.getStaffById, body })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//get Bulk upload file

export const getBulkUploadfile = () => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    api({ ...Bodandstaff.getBulkUploadfile })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//Add Bulk Upload

export const addBulkUpload = (body) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    api({ ...Bodandstaff.addBulkUpload, body })
      .then(({ data, message }) => {
        resolve(data);
        Toast({ type: "success", message });
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//get KYM Bulk upload file

export const getKYMBulkUploadfile = () => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    api({ ...Bodandstaff.getKYMBulkUploadfile })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//Add KYM Bulk Upload

export const addKYMBulkUpload = (body) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    api({ ...Bodandstaff.addKYMBulkUpload, body })
      .then(({ data, message }) => {
        resolve(data);
        Toast({ type: "success", message });
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//Delete BOD

export const deleteBOD = (body) => (dispatch, getState) => {
  let { deleteBOD } = Bodandstaff;

  deleteBOD.id = body.id;
  return new Promise((resolve, reject) => {
    api({ ...Bodandstaff.deleteBOD })
      .then(({ data, message }) => {
        resolve(data);
        Toast({ type: "success", message });
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//Delete Staff

export const deleteStaff = (body) => (dispatch, getState) => {
  let { deleteStaff } = Bodandstaff;

  deleteStaff.id = body.id;
  return new Promise((resolve, reject) => {
    api({ ...Bodandstaff.deleteStaff })
      .then(({ data, message }) => {
        resolve(data);
        Toast({ type: "success", message });
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//get Grading Detail
export const getGradingDetail = () => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        api({ ...Bodandstaff.getGradingDetail })
            .then(({ data }) => {
                resolve(data);
            })
            .catch(({ message }) => {
                reject(Toast({ type: 'error', message }));
            });
    });
};

//get Grading Surveys
export const getGradingSurvey = (ids) => (dispatch, getState) => {
    const { getGradingSurvey } = Bodandstaff;

    getGradingSurvey.ids = ids;
    return new Promise((resolve, reject) => {
        api({ ...Bodandstaff.getGradingSurvey })
            .then(({ data }) => {
                resolve(data);
            })
            .catch(({ message }) => {
                reject(Toast({ type: 'error', message }));
            });
    });
};

//get Grading Survey's dropdown list
export const getGradingSurveyList = (ids) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        api({ ...Bodandstaff.getGradingSurveyList })
            .then(({ data }) => {
                resolve(data);
            })
            .catch(({ message }) => {
                reject(Toast({ type: 'error', message }));
            });
    });
};
