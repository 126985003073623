import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import "./style.scss";
import { getAlRequest, getcommodityList } from "action/SellrequestAct";
import { CommonPageLoader } from "component/common";

export class ListClass extends Component {
    constructor() {
        super();
        this.state = {
            comodityList: [],
            list: [],
            isLoading: false,
            productId: 0,
            fromDate: '', // WIP
            toDate: '',
            timeperiod: 3
        }
    }
    getcommodityList() {
        var d = new Date();
        this.setState({ isLoading: true });
        this.props.getcommodityList().then((data) => {
            this.setState({
                comodityList: data,
                productId: data[0]?.id,
                fromDate: '1-' + (d.getMonth() - 3) + '-' + d.getFullYear(),
                toDate: d.getDate() + '-' + d.getMonth() + '-' + d.getFullYear(),
                isLoading: false
            }, () => {
                this.getData();
            });
        });
    }
    getData() {
        this.setState({ isLoading: true });
        var records = {
            result: [],
            total: {}
        };
        this.props.getAlRequest({
            productId: this.state.productId,
            fromDate: this.state.fromDate, // WIP
            toDate: this.state.toDate,
        }).then((data) => {
            if (data.length > 0) {
                var price_offered_per_unit_count = 0
                var price_unit_dest_market_price_count = 0
                var buyer_demand_quantity_count = 0
                var fpo_total_selling_price_count = 0
                var fpo_total_logistics_cost_count = 0
                var farmer_engaged_count = 0
                var total_procurement_cost_count = 0
                var fpo_total_margin_count = 0
                var buyer_credit_period_count = 0
                data.map((value, i) => {
                    price_offered_per_unit_count += value.price_offered_per_unit != null ? parseInt(value.price_offered_per_unit) : 0;
                    price_unit_dest_market_price_count += value.price_unit_dest_market_price != null ? parseInt(value.price_unit_dest_market_price) : 0;
                    buyer_demand_quantity_count += value.buyer_demand_quantity != null ? parseInt(value.buyer_demand_quantity) : 0;
                    fpo_total_selling_price_count += value.fpo_total_selling_price != null ? parseInt(value.fpo_total_selling_price) : 0;
                    fpo_total_logistics_cost_count += value.fpo_total_logistics_cost != null ? parseInt(value.fpo_total_logistics_cost) : 0;
                    farmer_engaged_count += value.farmer_engaged != null ? parseInt(value.farmer_engaged) : 0;
                    total_procurement_cost_count += value.total_procurement_cost != null ? parseInt(value.total_procurement_cost) : 0;
                    fpo_total_margin_count += value.fpo_total_margin != null ? parseInt(value.fpo_total_margin) : 0;
                    buyer_credit_period_count += value.buyer_credit_period != null ? parseInt(value?.buyer_credit_period.split(" days")[0]) : 0;
                    records['result'][i] = value;
                });
                records['total']['price_offered_per_unit_count'] = price_offered_per_unit_count / data.length;
                records['total']['price_unit_dest_market_price_count'] = price_unit_dest_market_price_count / data.length;
                records['total']['buyer_demand_quantity_count'] = buyer_demand_quantity_count / data.length;
                records['total']['fpo_total_selling_price_count'] = fpo_total_selling_price_count / data.length;
                records['total']['fpo_total_logistics_cost_count'] = fpo_total_logistics_cost_count / data.length;
                records['total']['farmer_engaged_count'] = farmer_engaged_count / data.length;
                records['total']['total_procurement_cost_count'] = total_procurement_cost_count / data.length;
                records['total']['fpo_total_margin_count'] = fpo_total_margin_count / data.length;
                records['total']['buyer_credit_period_count'] = buyer_credit_period_count / data.length;

            }
            this.setState({
                list: records,
                isLoading: false
            });
        });
    }
    handleInput(e) {
        this.setState({ productId: e.target.value }, () => {
            this.getData();
        });
    }
    timePeriod(e) {
        var value = e.target.value;
        var d = new Date();
        var month = 1;
        var year = 0;
        if (value == 3) {
            month = d.getMonth() - 3;
            year = d.getFullYear();
        } else if (value == 6) {
            month = d.getMonth() - 6;
            year = d.getFullYear();
        } else if (value == 1) {
            month = d.getMonth();
            year = d.getFullYear() - 1;
        }

        var startdate = '1-' + month + '-' + year;
        var enddate = d.getDate() + '-' + d.getMonth() + '-' + d.getFullYear();
        this.setState(
            {
                timeperiod: value,
                fromDate: startdate,
                toDate: enddate
            }
            , () => {
                this.getData();
            });
    }
    componentDidMount() {
        this.getcommodityList()
    }
    avg(type) {
        var total = this.state?.list?.total;
        if (total) {
            return isNaN(total[type]) == false ? total[type].toLocaleString('en-IN', { maximumFractionDigits: 2 }) : 0;
        }
        return '';
    }
    ruppees(type) {
        return (isNaN(type) == false && type != null && type != '') ? parseInt(type).toLocaleString('en-IN', { maximumFractionDigits: 2 }) : 0;
    }
    render() {
        let {
            comodityList, list, isLoading, productId, timeperiod
        } = this.state;
        return (
            <div>
                <section className="member-section pt-3  buyer-compare-sec">
                    <div className="members-tab pb-3 bg-blue-f9  ">
                        <div className="container-sa position-relative justify-content-start d-flex align-items-center">
                            <div></div>
                            <div className="d-flex  align-items-center ">
                                <select
                                    className="form-control mr-3"
                                    onChange={(e) => this.handleInput(e)}
                                    value={productId}
                                >
                                    <option disabled selected>Commodity</option>
                                    {comodityList.map((answer, i) => {
                                        return (<option value={answer.id}>{answer.name}</option>)
                                    })}
                                </select>
                                <select className="form-control"
                                    onChange={(e) => this.timePeriod(e)}
                                    value={timeperiod}>
                                    <option disabled selected>Time Period</option>
                                    <option value={3}>Last 3 months</option>
                                    <option value={6}>Last 6 months</option>
                                    <option value={1}>Last Year</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="container-sa position-relative">

                        <>
                            {!isLoading && list?.result?.length === 0 ? (
                                <p className="py-4 mb-0 text-black-50 fs-22 line-height-25 text-center">
                                    No list found
                                </p>
                            ) : !isLoading ? (
                                <>
                                    {list?.result?.length != 0 ? (
                                        <>
                                        <div class="table-responsive">

                                            <table className="table buyer-comparison-table">
                                                <tr>
                                                    <th width="25%" class="pb-1">&nbsp;</th>
                                                    {
                                                        list?.result?.map((answer, i) => {
                                                            return (
                                                                <th class="pb-1">{answer.customer_name}</th>
                                                            )
                                                        })
                                                    }
                                                    <th class="pb-1">Avg<br /><small>(All Buyers Considered)</small></th>
                                                </tr>
                                                <tr>
                                                    <th>Location</th>
                                                    {
                                                        list?.result?.map((answer, i) => {
                                                            return (
                                                                <th >{answer.customer_location}</th>
                                                            )
                                                        })
                                                    }
                                                    <th>&nbsp;</th>
                                                </tr>
                                                <tr>
                                                    <td>Quantity Sold (Quintal)</td>
                                                    {
                                                        list?.result?.map((answer, i) => {
                                                            return (
                                                                <td class="pb-1">{this.ruppees(answer.buyer_demand_quantity)} </td>
                                                            )
                                                        })
                                                    }
                                                    <td>{this.avg('buyer_demand_quantity_count')}</td>
                                                </tr>
                                                <tr>
                                                    <td>Price Offered (Rs./Unit)</td>
                                                    {
                                                        list?.result?.map((answer, i) => {
                                                            return (
                                                                <td class="pb-1">₹{this.ruppees(answer.price_offered_per_unit)}</td>
                                                            )
                                                        })
                                                    }
                                                    <td>₹{this.avg('price_offered_per_unit_count')}</td>
                                                </tr>
                                                <tr>
                                                    <td>Destination Market Price (Rs./Unit)</td>
                                                    {
                                                        list?.result?.map((answer, i) => {
                                                            return (
                                                                <td class="pb-1">{answer.price_unit_dest_market_price != '' && answer.price_unit_dest_market_price != 0 ? '₹ ' + this.ruppees(answer.price_unit_dest_market_price) : 'Unavailable'} </td>
                                                            )
                                                        })
                                                    }
                                                    <td>{this.avg('price_unit_dest_market_price_count') != '' && this.avg('price_unit_dest_market_price_count') != 0 ? '₹ ' + this.avg('price_unit_dest_market_price_count') : 'Unavailable'}</td>
                                                </tr>
                                                <tr>
                                                    <td>Procurement Costs</td>
                                                    {
                                                        list?.result?.map((answer, i) => {
                                                            return (
                                                                <td class="pb-1">₹{this.ruppees(answer.total_procurement_cost)} </td>
                                                            )
                                                        })
                                                    }
                                                    <td>₹{this.avg('total_procurement_cost_count')}</td>
                                                </tr>
                                                <tr>
                                                    <td>FPO Logistics Cost</td>
                                                    {
                                                        list?.result?.map((answer, i) => {
                                                            return (
                                                                <td class="pb-1">₹{this.ruppees(answer.fpo_total_logistics_cost)} </td>
                                                            )
                                                        })
                                                    }
                                                    <td>₹{this.avg('fpo_total_logistics_cost_count')}</td>
                                                </tr>

                                                <tr>
                                                    <td>FPO Total Selling Price</td>
                                                    {
                                                        list?.result?.map((answer, i) => {
                                                            return (
                                                                <td class="pb-1">₹{this.ruppees(answer.fpo_total_selling_price)} </td>
                                                            )
                                                        })
                                                    }
                                                    <td>₹{this.avg('fpo_total_selling_price_count')}</td>
                                                </tr>
                                                <tr>
                                                    <td>FPO Total Margins</td>
                                                    {
                                                        list?.result?.map((answer, i) => {
                                                            return (
                                                                <td class="pb-1">₹{this.ruppees(answer.fpo_total_margin)} </td>
                                                            )
                                                        })
                                                    }
                                                    <td>₹{this.avg('fpo_total_margin_count')}</td>
                                                </tr>

                                                <tr>
                                                    <td>Farmers Engaged</td>
                                                    {
                                                        list?.result?.map((answer, i) => {
                                                            return (
                                                                <td class="pb-1">{this.ruppees(answer.farmer_engaged)} </td>
                                                            )
                                                        })
                                                    }
                                                    <td>{this.avg('farmer_engaged_count')}</td>
                                                </tr>


                                                <tr>
                                                    <td>Buyer Credit Period</td>
                                                    {
                                                        list?.result?.map((answer, i) => {
                                                            return (
                                                                <td class="pb-1">{answer.buyer_credit_period != '' && answer.buyer_credit_period != null ? answer.buyer_credit_period : 0} </td>
                                                            )
                                                        })
                                                    }
                                                    <td>{this.avg('buyer_credit_period_count')} days</td>
                                                </tr>
                                            </table>
                                        </div>
                                        </>
                                    ) : null}
                                </>
                            ) : (
                                <>
                                    {/* Loader */}
                                    <CommonPageLoader />
                                </>
                            )}
                        </>
                    </div>
                </section>
            </div>
        );
    }
}


const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getAlRequest,
            getcommodityList
        },
        dispatch
    );
};
let component = ListClass;

export const ListView = connect(
    null,
    mapDispatchToProps
)(component);