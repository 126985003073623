import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  membersView,
  memberHouseHoldAdd,
  getMemberHouseHold,
  updateMemberHouseHold,
  deleteHouseholdDetails,
} from "action/MemberAct";
import SimpleReactValidator from "simple-react-validator";
import { Toast } from "service/toast";
import { NormalInput } from "component/common";

const arrow_up = require("../../../assets/svg/add_member/arrow_up.svg");
class HouseHoldForm extends Component {
  constructor(props) {
    super();
    this.state = {
      members: [],
      memberId: "",
      male_members: "",
      female_members: "",
      houseHoldData: null,
      isDataUploading: false,
    };
  }

  validator = new SimpleReactValidator({
    element: (message) => (
      <span className="error-message text-danger fs-14">{message}</span>
    ),
    autoForceUpdate: this,
  });

  handleChange = ({ target: { name, value } }) => {
    this.setState({
      [name]: value,
    });
  };

  // delete houseHold
  handleDelete = () => {
    this.setState({ isDataUploading: true });
    let { memberId } = this.state;
    let { deleteHouseholdDetails } = this.props;
    let body = {
      id: memberId,
    };
    deleteHouseholdDetails(body).then(() => {
      this._getMemberHouseHold(memberId);
    });
  };

  //get details
  _getMemberHouseHold = (id) => {
    this.props.getMemberHouseHold({ id }).then((data) => {
      if (data === null) {
        this.setState({
          male_members: "",
          female_members: "",
          houseHoldData: null,
        });
      } else {
        this.setState({
          houseHoldData: data,
          memberId: data.memberID,
          male_members: data.maleMembers ? data.maleMembers : "",
          female_members: data.femaleMembers ? data.femaleMembers : "",
        });
      }
    });
  };

  // _clearData = () => {
  //   this.setState({
  //     memberId: "",
  //     male_members: "",
  //     female_members: "",
  //     houseHoldData: null,
  //     isDataUploading: false,
  //   });
  // };

  handleSave = (e) => {
    if (this.validator.allValid()) {
      const { memberId, male_members, female_members, houseHoldData } =
        this.state;
      if (memberId) {
        this.setState({ isDataUploading: true });

        let body = {
          memberID: parseInt(memberId),
        };

        if (female_members !== "") {
          body.femaleMembers = parseInt(female_members);
        }
        if (male_members !== "") {
          body.maleMembers = parseInt(male_members);
        }
        if (houseHoldData == null) {
          this.props.memberHouseHoldAdd(body).then((data) => {
            if (data === 200) {
              this.validator.hideMessages();
              // this._clearData();
              this.props.toggleCollapse(
                false,
                false,
                true,
                false,
                false,
                false
              );
            }
            this.setState({ isDataUploading: false });
          });
        } else {
          this.props.updateMemberHouseHold(body).then((data) => {
            this.setState({ isDataUploading: false });
            if (data === 200) {
              this.validator.hideMessages();
              this.props.toggleCollapse(
                false,
                false,
                true,
                false,
                false,
                false
              );
            }
          });
        }
      } else {
        Toast({
          type: "error",
          message: "Please add basic profile of member",
        });
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      if (this.props.isOpen) {
        let { memberId } = this.props;
        this.setState({
          memberId,
        });
        this._getMemberHouseHold(memberId);
        // this.props.membersView().then((data) => {
        //   let memberDetials = [];
        //   data.map((list) => {
        //     memberDetials.push({
        //       label: list.memberFirstName + " | " + list.phone,
        //       value: list.memberID,
        //     });
        //   });
        //   this.setState({
        //     members: memberDetials,
        //   });
        // });
      }
    }
  }

  render() {
    const {
      memberId,
      members,
      male_members,
      female_members,
      isDataUploading,
      houseHoldData,
    } = this.state;

    let { deleteAccess = false } = this.props;

    return (
      <div className="px-md-5 py-4 px-3 add-member-area">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <div className="add-member-form-container-heading">House hold</div>
          <div className="d-flex justify-content-end align-items-center">
            {isDataUploading ? (
              <div className="d-flex align-items-center h-100">
                {deleteAccess && houseHoldData && (
                  <div className="mr-3 d-flex align-items-center h-100">
                    <p className="mb-1 font-weight-bold fs-20 d-flex align-items-center">
                      <i className="icon-Delete fs-18" />
                      <span className="text-danger fs-18 pl-3">{"Delete"}</span>
                    </p>
                  </div>
                )}
                <div className="add-member-form-container-button d-flex align-items-center">
                  {"Save & next"}
                </div>
              </div>
            ) : (
              <div className="d-flex align-items-center h-100">
                {deleteAccess && houseHoldData && (
                  <div className="mr-3 d-flex align-items-center h-100">
                    <p
                      className="mb-1 font-weight-bold fs-20 cursor-pointer d-flex align-items-center"
                      onClick={() => this.handleDelete()}
                    >
                      <i className="icon-Delete fs-18" />
                      <span className="text-danger fs-18 pl-3">{"Delete"}</span>
                    </p>
                  </div>
                )}
                <div
                  className="add-member-form-container-button cursor-pointer d-flex align-items-center"
                  onClick={this.handleSave}
                >
                  {"Save & next"}
                </div>
              </div>
            )}
            <img
              className="ml-3 cursor-pointer"
              src={arrow_up}
              alt="arrow_up"
              height="10"
              width="10"
              name="house_hold"
              onClick={this.props.collapse}
            />
          </div>
        </div>
        {/* <div className="row w-100">
          <div className="col-12">
            <NormalSelect
              isFullWithForMemberProfile={true}
              label="Member"
              placeholder="Select"
              value={memberId}
              arrow={true}
              name="memberId"
              options={members}
              handleChange={this.handleChange}
              required={true}
            />
            <div className="row">
              <div className="col-10 ml-auto">
                {this.validator.message("member", memberId, "required")}
              </div>
            </div>
          </div>
        </div> */}
        <div className="row w-100">
          <div className="col-md-6">
            <NormalInput
              isHalfWidth={true}
              label="Male members"
              type="number"
              onChange={this.handleChange}
              value={male_members}
              name="male_members"
              disabled={memberId == "" ? true : false}
            />
            <div className="row">
              <div className="col-md-8 ml-auto">
                {this.validator.message("male members", male_members, "max:10")}
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <NormalInput
              isHalfWidth={true}
              label="Female members"
              type="number"
              onChange={this.handleChange}
              value={female_members}
              name="female_members"
              disabled={memberId == "" ? true : false}
            />
            <div className="row">
              <div className="col-md-8 ml-auto">
                {this.validator.message(
                  "female members",
                  female_members,
                  "max:10"
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      membersView,
      memberHouseHoldAdd,
      getMemberHouseHold,
      updateMemberHouseHold,
      deleteHouseholdDetails,
    },
    dispatch
  );
};
let component = HouseHoldForm;

export const HouseHold = connect(null, mapDispatchToProps)(component);
