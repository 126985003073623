import { academyApi, blogApi } from "../service/apiVariables";
import { addQuery } from "service/helperFunctions";
import { academyType, HomeActionType } from "service/actionType";

// My learning
export const myLearningApi = (id) => (dispatch, getState, { api, Toast }) => {
  academyApi.mylearnings.addQuery = { key: "id", payload: id };

  return new Promise((resolve, reject) => {
    api({ ...academyApi.mylearnings })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

// Cours List API
export const courseListApi = (id) => (dispatch, getState, { api, Toast }) => {
  academyApi.courseList.addQuery = { key: "id", payload: id };

  return new Promise((resolve, reject) => {
    api({ ...academyApi.courseList })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//getViewersCountForChapters
export const getViewersCountForChapters = (id) => (
  dispatch,
  getState,
  { api, Toast }
) => {
  let { getViewersCountForChapters } = academyApi;

  getViewersCountForChapters.id = id;

  return new Promise((resolve, reject) => {
    api({ ...academyApi.getViewersCountForChapters })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

// Cours List API
export const onGoingUpdateApi = (body) => (
  dispatch,
  getState,
  { api, Toast }
) => {
  return new Promise((resolve, reject) => {
    api({ ...academyApi.onGoingUpdate, body })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

// My favourities
export const myFavorities = () => (dispatch, getState, { api, Toast }) => {
  return new Promise((resolve, reject) => {
    api({ ...academyApi.myFavorities })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

// My favourities for videos
export const myFavoritiesForVideos = () => (
  dispatch,
  getState,
  { api, Toast }
) => {
  return new Promise((resolve, reject) => {
    api({ ...academyApi.myFavoritiesForVideos })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

// My favourities for blog
export const myFavoritiesForBlog = () => (
  dispatch,
  getState,
  { api, Toast }
) => {
  return new Promise((resolve, reject) => {
    api({ ...academyApi.myFavoritiesForBlog })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

// Cours List API
export const quizListApi = (id) => (dispatch, getState, { api, Toast }) => {
  let { quizList } = academyApi;

  quizList.id = id;

  return new Promise((resolve, reject) => {
    api({ ...academyApi.quizList })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//retakeQuiz
export const retakeQuiz = (id) => (dispatch, getState, { api, Toast }) => {
  let { retakeQuiz } = academyApi;

  retakeQuiz.id = id;

  return new Promise((resolve, reject) => {
    api({ ...academyApi.retakeQuiz })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

// Quiz save and answer api

export const quizAnswerAPI = (body, id) => (
  dispatch,
  getState,
  { api, Toast }
) => {
  return new Promise((resolve, reject) => {
    academyApi.quizAnswer.addQuery = { key: "id", payload: id };

    api({ ...academyApi.quizAnswer, body })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

// Add favourities

export const addfaviorites = (body) => (dispatch, getState, { api, Toast }) => {
  return new Promise((resolve, reject) => {
    api({ ...academyApi.addfaviorites, body })
      .then(({ data, message }) => {
        Toast({ type: "success", message, time: 2000 });

        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

// Delete favourities

export const deletefaviorites = (body) => (
  dispatch,
  getState,
  { api, Toast }
) => {
  let { deletefaviorites } = academyApi;

  deletefaviorites.chapterId = body.chapterId;

  return new Promise((resolve, reject) => {
    api({ ...academyApi.deletefaviorites })
      .then(({ data, message }) => {
        resolve(data);

        Toast({ type: "success", message, time: 2000 });
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//Recents View list

export const getRecentViewList = (body) => (
  dispatch,
  getState,
  { api, Toast }
) => {
  let { getRecentViewList } = academyApi;

  getRecentViewList.courseId = body.courseId;

  return new Promise((resolve, reject) => {
    api({ ...academyApi.getRecentViewList, body })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

// Add Review

export const addReview = (body) => (dispatch, getState, { api, Toast }) => {
  return new Promise((resolve, reject) => {
    api({ ...academyApi.addReview, body })
      .then(({ data, message }) => {
        Toast({ type: "success", message, time: 2000 });

        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

// get Review List

export const getReviewList = (id, query) => (
  dispatch,
  getState,
  { api, Toast }
) => {
  let { getReviewList } = academyApi;

  getReviewList.id = id;

  return new Promise((resolve, reject) => {
    addQuery(query, academyApi.getReviewList);

    api({ ...academyApi.getReviewList })
      .then(({ data, message }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};
// Add Review for blog

export const addReviewForBlog = (body) => (
  dispatch,
  getState,
  { api, Toast }
) => {
  return new Promise((resolve, reject) => {
    api({ ...academyApi.addReviewForBlog, body })
      .then(({ data, message }) => {
        Toast({ type: "success", message, time: 2000 });

        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

// get Review List for blog

export const getReviewListForBlog = (blogId, query) => (
  dispatch,
  getState,
  { api, Toast }
) => {
  let { getReviewListForBlog } = academyApi;

  getReviewListForBlog.id = blogId;

  return new Promise((resolve, reject) => {
    addQuery(query, academyApi.getReviewListForBlog);

    api({ ...academyApi.getReviewListForBlog })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//Get Related Blogs
export const getRelatedBlogs = (blogId, categoryId) => (
  dispatch,
  getState,
  { api, Toast }
) => {
  let { getRelatedBlogs } = academyApi;

  getRelatedBlogs.blogId = blogId;
  getRelatedBlogs.categoryId = categoryId;

  return new Promise((resolve, reject) => {
    api({ ...academyApi.getRelatedBlogs })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//get All Blogs List
export const getAllBlogsList = (query) => (
  dispatch,
  getState,
  { api, Toast }
) => {
  return new Promise((resolve, reject) => {
    addQuery(query, academyApi.getAllBlogsList);
    api({ ...academyApi.getAllBlogsList })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//get All Trending Videos List
export const getAllTrendingVideosList = (query) => (
  dispatch,
  getState,
  { api, Toast }
) => {
  return new Promise((resolve, reject) => {
    addQuery(query, academyApi.getAllTrendingVideosList);
    api({ ...academyApi.getAllTrendingVideosList })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//getAllCourseList
export const getAllCourseList = (query) => (
  dispatch,
  getState,
  { api, Toast }
) => {
  return new Promise((resolve, reject) => {
    addQuery(query, academyApi.getAllCourseList);
    api({ ...academyApi.getAllCourseList })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

// Get explore List

export const academyExplore = () => (dispatch, getState, { api, Toast }) => {
  return new Promise((resolve, reject) => {
    api({ ...academyApi.academyExplore })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//Academy Explore By Id

export const academyExploreById = (query) => (
  dispatch,
  getState,
  { api, Toast }
) => {
  let { academyExploreById } = academyApi;

  return new Promise((resolve, reject) => {
    addQuery(query, academyApi.academyExploreById);

    api({ ...academyApi.academyExploreById })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

// Cours List API
export const blogListApi = (body) => (dispatch, getState, { api, Toast }) => {
  return new Promise((resolve, reject) => {
    api({ ...blogApi.blogList, body })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

// Cours List API
export const blogDetailApi = (id) => (dispatch, getState, { api, Toast }) => {
  return new Promise((resolve, reject) => {
    blogApi.blogDetail.addQuery = { key: "id", payload: id };

    api({ ...blogApi.blogDetail })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

// Academy Home Page API
export const academyHomePageAPI = (body) => (
  dispatch,
  getState,
  { api, Toast }
) => {
  return new Promise((resolve, reject) => {
    api({ ...academyApi.academyHomePage, body })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//intoducing Video
export const intoducingVideo = (body) => (
  dispatch,
  getState,
  { api, Toast }
) => {
  return new Promise((resolve, reject) => {
    api({ ...academyApi.intoducingVideo, body })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

// Academy Home Page API
export const getEventNewsApi = () => (dispatch, getState, { api, Toast }) => {
  return new Promise((resolve, reject) => {
    api({ ...academyApi.getEventNews })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

// Get user Profile Detail
export const getUserProfileDetails = () => (
  dispatch,
  getState,
  { api, Toast }
) => {
  return new Promise((resolve, reject) => {
    api({ ...academyApi.getUserProfileDetails })
      .then(({ data }) => {
        resolve(data);
        dispatch({
          type: HomeActionType.profileImage,
          payload: data.profileImageUrl,
        });
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

// Update profile picture
export const updateProfilePicture = (body) => (
  dispatch,
  getState,
  { api, Toast }
) => {
  return new Promise((resolve, reject) => {
    api({ ...academyApi.updateProfilePicture, body })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//Get informative details
export const infomativeDetailApi = (id) => (
  dispatch,
  getState,
  { api, Toast }
) => {
  let { infomativeDetailApi } = academyApi;

  infomativeDetailApi.id = id;

  return new Promise((resolve, reject) => {
    api({ ...academyApi.infomativeDetailApi })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//add favourite Video

export const addfavouriteVideo = (body) => (
  dispatch,
  getState,
  { api, Toast }
) => {
  return new Promise((resolve, reject) => {
    api({ ...academyApi.addfavouriteVideo, body })
      .then(({ data, message }) => {
        Toast({ type: "success", message, time: 2000 });

        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//delete favourite Video

export const deletefavouriteVideo = (body) => (
  dispatch,
  getState,
  { api, Toast }
) => {
  let { deletefavouriteVideo } = academyApi;

  deletefavouriteVideo.videoId = body.trendingvideoId;

  return new Promise((resolve, reject) => {
    api({ ...academyApi.deletefavouriteVideo })
      .then(({ data, message }) => {
        Toast({ type: "success", message, time: 2000 });

        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//addfavouriteBlog
export const addfavouriteBlog = (body) => (
  dispatch,
  getState,
  { api, Toast }
) => {
  return new Promise((resolve, reject) => {
    api({ ...academyApi.addfavouriteBlog, body })
      .then(({ data, message }) => {
        Toast({ type: "success", message, time: 2000 });

        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//delete favourite Video

export const deletefavouriteBlog = (body) => (
  dispatch,
  getState,
  { api, Toast }
) => {
  let { deletefavouriteBlog } = academyApi;

  deletefavouriteBlog.id = body.blogId;

  return new Promise((resolve, reject) => {
    api({ ...academyApi.deletefavouriteBlog })
      .then(({ data, message }) => {
        Toast({ type: "success", message, time: 2000 });

        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//addReviewForVideo
export const addReviewForVideo = (body) => (
  dispatch,
  getState,
  { api, Toast }
) => {
  return new Promise((resolve, reject) => {
    api({ ...academyApi.addReviewForVideo, body })
      .then(({ data, message }) => {
        Toast({ type: "success", message, time: 2000 });

        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//related Trending Video
export const relatedTrendingVideo = (id) => (
  dispatch,
  getState,
  { api, Toast }
) => {
  let { relatedTrendingVideo } = academyApi;

  relatedTrendingVideo.id = id;

  return new Promise((resolve, reject) => {
    api({ ...academyApi.relatedTrendingVideo })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//get User List For Share For Course

export const getUserListForShareForCourse = (query) => (
  dispatch,
  getState,
  { api, Toast }
) => {
  return new Promise((resolve, reject) => {
    addQuery(query, academyApi.getUserListForShareForCourse);

    api({ ...academyApi.getUserListForShareForCourse })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//academyShare

export const academyShare = (body) => (dispatch, getState, { api, Toast }) => {
  return new Promise((resolve, reject) => {
    api({ ...academyApi.academyShare, body })
      .then(({ data, message }) => {
        Toast({ type: "success", message });

        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

export const searchChange = (payload) => (dispatch) => {
  dispatch({ type: academyType.academySearch, payload });
};
export const pageChange = (payload) => (dispatch) => {
  dispatch({ type: academyType.academyPage, payload });
};
