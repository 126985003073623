import React, { Component } from "react";
import { CommonPageLoader } from "component/common";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "./style.scss";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { getRoleAnalysisdata } from "action/KeyFinancialAct";
import { formatToINR } from "service/helperFunctions";
import { getRoleAnalysisdataById } from "action/RmAct";
export default class RatioAnalysisClass extends Component {
  state = {
    isLoading: false,
    roleAnalysisData: [],
  };

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      if (this.props.activeTab == 5 && this.props.financialsTabs == 2) {
        this._getRoleAnalysisdata();
      }
    }
  }

  _getRoleAnalysisdata = () => {
    let { getRoleAnalysisdataById, id } = this.props;
    this.setState({ isLoading: true });
    if (id) {
      getRoleAnalysisdataById(id).then((data) => {
        this._setChartData(data);
      });
    } else {
      this.props.getRoleAnalysisdata().then((data) => {
        this._setChartData(data);
      });
    }
  };

  _setChartData = (data) => {
    let roleAnalysisDataList = [];
    data.map((list) => {
      if (list.keyName === "debtToEquity") {
        let tabledata = this.setoptionData(list, "Dept Equity Ratio", "Ratio");
        roleAnalysisDataList.push(tabledata);
      } else if (list.keyName === "ebitda") {
        let tabledata = this.setoptionDataForCurrency(list, "EBITDA", "EBITDA");
        roleAnalysisDataList.push(tabledata);
      } else if (list.keyName === "currentRatio") {
        let tabledata = this.setoptionData(
          list,
          "Current Ratio",
          "Current Ratio"
        );
        roleAnalysisDataList.push(tabledata);
      } else if (list.keyName === "financeToEbitda") {
        let tabledata = this.setoptionDataForPercentage(
          list,
          "Finance Cost : EBITDA",
          "Finance Cost"
        );
        roleAnalysisDataList.push(tabledata);
      } else if (list.keyName === "patToRevenue") {
        let tabledata = this.setoptionData(list, "PAT : Revenue", "Revenue");
        roleAnalysisDataList.push(tabledata);
      } else if (list.keyName === "fixedToTotalAssets") {
        let tabledata = this.setoptionData(
          list,
          "Fixed Assets to Total Assets",
          "Assets"
        );
        roleAnalysisDataList.push(tabledata);
      }
    });
    this.setState({ roleAnalysisData: roleAnalysisDataList, isLoading: false });
    if (roleAnalysisDataList.length == data.length) {
    }
  };

  setoptionDataForPercentage = (data, title, legendName) => {
    let optionsData = {
      chart: {
        type: "column",
        height: 300,
      },
      title: {
        text: "",
      },
      xAxis: {
        categories: [],
      },
      yAxis: {
        min: 0,
        title: {
          text: "",
        },
        labels: {
          formatter: function () {
            var val = Math.round(this.value) + "%";
            return val;
          },
        },
      },
      tooltip: {
        pointFormat:
          '<span style="color:{series.color}">{series.name}</span>: <b>{point.y} %</b><br/>',
        shared: true,
      },
      plotOptions: {
        column: {
          stacking: "normal",
        },
      },
      credits: {
        enabled: false,
      },
      series: [
        {
          name: "",
          data: [],
          borderRadius: 4,
          pointWidth: 15,
          color: "#01934C",
        },
      ],
    };

    data.tableData.map((list) => {
      optionsData.xAxis.categories.push(list.year);
      optionsData.series[0].data.push(parseInt(list.value / 100));
      optionsData.series[0].name = legendName;
    });
    let body = {
      chartTitle: title,
      options: optionsData,
    };
    return body;
  };

  setoptionDataForCurrency = (data, title, legendName) => {
    let optionsData = {
      chart: {
        type: "column",
        height: 300,
      },
      title: {
        text: "",
      },
      xAxis: {
        categories: [],
      },
      yAxis: {
        min: 0,
        title: {
          text: "",
        },
        labels: {
          formatter: function () {
            var val = Math.round(this.value);
            if (val >= 1000000000) {
              val = formatToINR(val / 10000000) + " M";
            } else if (val >= 10000000) {
              val = formatToINR(val / 10000000) + " C";
            } else if (val >= 100000) {
              val = formatToINR(val / 100000) + " L";
            } else if (val >= 1000) {
              val = formatToINR(val / 1000) + " K";
            }
            return val;
          },
        },
      },
      tooltip: {
        pointFormat:
          '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b><br/>',
        shared: true,
      },
      plotOptions: {
        column: {
          stacking: "normal",
        },
      },
      credits: {
        enabled: false,
      },
      series: [
        {
          name: "",
          data: [],
          borderRadius: 4,
          pointWidth: 15,
          color: "#01934C",
        },
      ],
    };

    data.tableData.map((list) => {
      optionsData.xAxis.categories.push(list.year);
      optionsData.series[0].data.push(parseInt(list.value));
      optionsData.series[0].name = legendName;
    });
    let body = {
      chartTitle: title,
      options: optionsData,
    };
    return body;
  };

  setoptionData = (data, title, legendName) => {
    let optionsData = {
      chart: {
        type: "column",
        height: 300,
      },
      title: {
        text: "",
      },
      xAxis: {
        categories: [],
      },
      yAxis: {
        min: 0,
        title: {
          text: "",
        },
        labels: {
          formatter: function () {
            var val = Math.round(this.value).toFixed(2);
            return val;
          },
        },
      },
      tooltip: {
        pointFormat:
          '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b><br/>',
        shared: true,
      },
      plotOptions: {
        column: {
          stacking: "normal",
        },
      },
      credits: {
        enabled: false,
      },
      series: [
        {
          name: "",
          data: [],
          borderRadius: 4,
          pointWidth: 15,
          color: "#01934C",
        },
      ],
    };

    data.tableData.map((list) => {
      optionsData.xAxis.categories.push(list.year);
      optionsData.series[0].data.push(parseInt(list.value));
      optionsData.series[0].name = legendName;
    });
    let body = {
      chartTitle: title,
      options: optionsData,
    };
    return body;
  };

  render() {
    let { roleAnalysisData, isLoading } = this.state;
    return (
      <div className="key-financial-tab pt-2">
        {!isLoading && roleAnalysisData.length === 0 ? (
          <p className="py-4 mb-0 text-black-50 fs-22 line-height-25 text-center">
            {"No data found"}
          </p>
        ) : !isLoading ? (
          <>
            {roleAnalysisData.length != 0 ? (
              <div className="d-flex container-sa flex-column  flex-wrap mt-3 px-0 justify-content-center">
                <div className="row graph-list-card">
                  {roleAnalysisData.map((list, index) => {
                    let { options = {}, chartTitle = "" } = list;
                    return (
                      <div className="col-4 mb-3" key={index}>
                        <div className="chart-card">
                          {options.series.length !== 0 ? (
                            <>
                              <div className="chart-header">
                                <h3 className="mb-4 text-black fs-18 fw-600 text-center">
                                  {chartTitle}
                                </h3>
                              </div>
                              <HighchartsReact
                                highcharts={Highcharts}
                                options={options}
                              />
                            </>
                          ) : (
                            <p className="py-5 my-5 text-black-50 fs-22 line-height-25 text-center">
                              No data found !!!
                            </p>
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : (
              <p className="py-4 mb-0 text-black-50 fs-22 line-height-25 text-center">
                {"No data found"}
              </p>
            )}
          </>
        ) : (
          <>
            {/* Loader */}
            <CommonPageLoader />
          </>
        )}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getRoleAnalysisdata,
      getRoleAnalysisdataById,
    },
    dispatch
  );
};

let component = RatioAnalysisClass;

export const RatioAnalysis = connect(null, mapDispatchToProps)(component);
