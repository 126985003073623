import { Chart, registerables } from 'chart.js';
import React from 'react'

function GradingBarGraph({ graphLabel, datasets = [], maxSize = 100, breakSize = 20, graphId = "barChart", height = 220 }) {

    React.useEffect(() => {
        Chart.register(...registerables);
        const ctx = document.getElementById(graphId);
        const myChart = new Chart(ctx, {
            type: 'scatter',
            data: {
                labels: graphLabel,
                datasets: datasets
            },
            options: {
                layout: {
                    padding: {
                        right: 15
                    },
                },
                scales: {
                    x: {
                        type: 'category',
                        labels: graphLabel,
                        ticks: {
                            color: "#306D4C",
                            font: {
                                size: 12,
                                weight: "bold",
                                family: "Gilroy"
                            }
                        },
                        grid: {
                            lineWidth: 0
                        }
                    },
                    y: {

                        beginAtZero: true,
                        max: maxSize,
                        ticks: {
                            stepSize: breakSize,
                        },
                        grid: {
                            borderDash: [2, 2],
                            borderColor: "#E8E8E8"
                        }
                    }
                },
                plugins: {
                    tooltip: {
                        enabled: false,
                        position: 'nearest',
                        external: externalTooltipHandler
                    },
                    legend: {
                        display: true,
                        align: "end",
                        labels: {
                            boxWidth: 16,
                            boxHeight: 10,
                            font: {
                                size: 14
                            }
                        },
                    }
                }
            },
            plugins: [
                {
                    id: "barAvatar",
                    beforeDraw(chart) {
                        const { ctx, data } = chart;
                        ctx.save();
                        data.datasets.forEach(function (dataset, i) {
                            var meta = chart.getDatasetMeta(i);
                            meta.data.forEach(function (bar, index) {
                                var data = dataset.data[index];
                                ctx.fillText(data + "%", bar.x - 10, bar.y - 8);
                            });
                        });
                    }
                }
            ]
        });
        myChart.data.datasets = datasets;
        myChart.options.scales.x.labels = graphLabel;
        myChart.update();

        return () => {
            myChart.destroy();
        }
    }, [datasets, graphLabel]);

    const getOrCreateTooltip = (chart, context) => {
        let tooltipEl = chart.canvas.parentNode.querySelector('div');

        if (!tooltipEl) {
            tooltipEl = document.createElement('div');
            tooltipEl.style.background = '#fff';
            tooltipEl.style.borderRadius = '3px';
            tooltipEl.style.color = '#000';
            tooltipEl.style.opacity = 1;
            tooltipEl.style.pointerEvents = 'none';
            tooltipEl.style.position = 'absolute';
            tooltipEl.style.transform = 'translate(-50%, 0)';
            tooltipEl.style.transition = 'all .1s ease';
            tooltipEl.style.boxShadow = "rgb(0 0 0 / 42%) 0px 0px 20px";

            const table = document.createElement('table');
            table.style.margin = '0px';

            tooltipEl.appendChild(table);
            chart.canvas.parentNode.appendChild(tooltipEl);
        }

        return tooltipEl;
    };

    const externalTooltipHandler = (context) => {
        // Tooltip Element
        const { chart, tooltip } = context;
        const tooltipEl = getOrCreateTooltip(chart, context);

        // Hide if no tooltip
        if (tooltip.opacity === 0) {
            tooltipEl.style.opacity = 0;
            return;
        }

        // Set Text
        if (tooltip.body) {
            const titleLines = tooltip.title || [];
            const bodyLines = tooltip.body.map(b => b.lines);

            const tableHead = document.createElement('thead');

            titleLines.forEach(title => {
                const tr = document.createElement('tr');
                tr.style.borderWidth = 0;

                const th = document.createElement('th');
                th.style.borderWidth = 0;
                const text = document.createTextNode(title);

                th.appendChild(text);
                tr.appendChild(th);
                tableHead.appendChild(tr);
            });

            const tableBody = document.createElement('tbody');
            bodyLines.forEach((body, i) => {
                const colors = tooltip.labelColors[i];

                const span = document.createElement('span');
                span.style.display = 'block';
                span.style.fontSize = "12px";
                span.style.fontFamily = "Gilroy";
                span.style.fontWeight = "600";
                span.style.color = '#000000';

                const tr = document.createElement('tr');
                tr.style.backgroundColor = 'inherit';
                tr.style.borderWidth = 0;

                const td = document.createElement('td');
                td.style.borderWidth = 0;
                td.style.paddingBottom = "0px";
                if (context.tooltip.dataPoints.length > 0) {
                    const span2 = document.createElement('span');
                    span2.style.display = 'block';
                    span2.style.color = '#F97943';
                    span2.style.fontSize = "16px";
                    span2.style.textAlign = "center";
                    span.style.fontFamily = "Gilroy";
                    span.style.fontWeight = "600";
                    const text = document.createTextNode(context.tooltip.dataPoints[0].dataset.label);
                    span.appendChild(text);
                    span2.appendChild(document.createTextNode(context.tooltip.dataPoints[0].parsed.y + "%"));
                    td.appendChild(span);
                    td.appendChild(span2);
                } else {
                    span.style.background = colors.backgroundColor;
                    span.style.borderColor = colors.borderColor;
                    span.style.borderWidth = '2px';
                    span.style.marginRight = '10px';
                    span.style.height = '10px';
                    span.style.width = '10px';
                    span.style.display = 'block';
                    const text = document.createTextNode(body);
                    td.appendChild(span);
                    td.appendChild(text);
                }
                tr.appendChild(td);
                tableBody.appendChild(tr);
            });

            const tableRoot = tooltipEl.querySelector('table');

            // Remove old children
            while (tableRoot.firstChild) {
                tableRoot.firstChild.remove();
            }

            // Add new children
            tableRoot.appendChild(tableHead);
            tableRoot.appendChild(tableBody);
        }

        const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

        // Display, position, and set styles for font
        tooltipEl.style.opacity = 1;
        tooltipEl.style.left = positionX + tooltip.caretX + 'px';
        if (context.tooltip.dataPoints.length > 0 && context.tooltip.dataPoints[0].parsed.y > 75) {
            tooltipEl.className = "grading-chart-tooltip-b";
            tooltipEl.style.top = positionY + (tooltip.caretY + 10) + 'px';
        } else if (context.tooltip.dataPoints.length > 0 && context.tooltip.dataPoints[0].parsed.y > 30) {
            tooltipEl.className = "grading-chart-tooltip";
            tooltipEl.style.top = positionY + (tooltip.caretY - 70) + 'px';
        } else {
            tooltipEl.className = "grading-chart-tooltip";
            tooltipEl.style.top = positionY + (tooltip.caretY - 70) + 'px';
        }
        tooltipEl.style.font = tooltip.options.bodyFont.string;
        tooltipEl.style.padding = tooltip.options.padding + 'px ' + tooltip.options.padding + 'px';
    };

    return (
        <canvas id={graphId} height={height}></canvas>
    )
}

export default GradingBarGraph