import React from "react";
import moment from "moment";
import { getDescriptionData } from "service/helperFunctions";

export default function Event({ newsList = [], eventList = [], auth }) {
  return (
    <>
      {auth ? (
        <div>
          {newsList.map((event, index) => {
            return index != 0 ? (
              <div
                key={index}
                className={`row align-items-center ${
                  event.length !== index + 1 && "mb-3"
                }`}
              >
                <div className="col-sm-12">
                  <div className="row">
                    <div className="col-sm-3 col-4">
                      <div className="rounded">
                        <img
                          src={event.uploadImage}
                          width="90"
                          height="90"
                          alt="event.svg"
                        />
                      </div>
                    </div>
                    <div className="col-sm-9 col-8">
                      <div className="row">
                        <div className="col-md-9 text-left">
                          <p className="fs-18 line-height-30 text-grey-7b mb-1 font-weight-bold">
                            {"News"}
                          </p>
                        </div>
                        <div className="col-md-3 text-right">
                          {moment(event.publishOn).format("Do MMM")}
                        </div>
                      </div>
                      <a
                        href={
                          event.sourceHyperlink &&
                          event.sourceHyperlink.includes("http" || "https")
                            ? event.sourceHyperlink
                            : `https://${event.sourceHyperlink}`
                        }
                        className="text-decoration-none text-green-18"
                        target="_blank"
                      >
                        <div className="mb-1 fs-16 line-height-27 fw-600 text-gold-d0">
                          {getDescriptionData(event.newsTitle, 40)}
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            ) : null;
          })}
          {eventList.map((event, index) => {
            return newsList.length > 0 || index != 0 ? (
              <div
                key={index}
                className={`row align-items-center ${
                  event.length !== index + 1 && "mb-3"
                }`}
              >
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-sm-3 col-4">
                      <div className="rounded">
                        <img src={event.uploadImage} alt="event.svg" />
                      </div>
                    </div>
                    <div className="col-sm-9 col-8">
                      <div className="row">
                        <div className="col-md-9 text-left">
                          <p className="fs-18 line-height-30 text-grey-7b mb-1 font-weight-bold">
                            {"Event"}
                          </p>
                        </div>
                        <div className="col-md-3 text-right">
                          {moment(event.publishOn).format("Do MMM")}
                        </div>
                      </div>
                      <a
                        href={
                          event.registrationLink &&
                          event.registrationLink.includes("http" || "https")
                            ? event.registrationLink
                            : `https://${event.registrationLink}`
                        }
                        className="text-decoration-none text-green-18"
                        target="_blank"
                      >
                        <div className="mb-1 fs-16 line-height-27 fw-600 text-gold-d0">
                          {getDescriptionData(event.eventTitle, 40)}
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            ) : null;
          })}
        </div>
      ) : (
        <div className="border-right">
          {newsList.map((event, index) => {
            return index != 0 ? (
              <React.Fragment key={index}>
                <div
                  key={index}
                  className={`row align-items-center ${
                    event.length !== index + 1 && "mb-3"
                  }`}
                >
                  <div className="col-sm-12">
                    <div className="row">
                      <div className="col-sm-2 col-4">
                        <div className="rounded">
                          <img
                            className="img"
                            src={event.uploadImage}
                            width="80"
                            height="80"
                            alt="event.svg"
                          />
                        </div>
                      </div>
                      <div className="col-sm-10 col-8 news-list-center">
                        <div className="row">
                          <div className="col-md-9 text-left ">
                            <div className="d-flex">
                              <p className="fs-18 text-dark font-weight-bold">
                                {getDescriptionData(event.newsTitle, 100)}
                              </p>
                            </div>
                          </div>
                          <div className="col-md-3 text-right">
                            <span className="fs-18 fw-600">
                              {moment(event.publishOn).format("Do")}
                            </span>{" "}
                            <span className="fs-18 fw-500">
                              {moment(event.publishOn).format("MMM")}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr className="border" />
              </React.Fragment>
            ) : null;
          })}
          {eventList.map((event, index) => {
            return newsList.length > 0 || index != 0 ? (
              <React.Fragment key={index}>
                <div
                  key={index}
                  className={`row align-items-center ${
                    event.length !== index + 1 && "mb-3"
                  }`}
                >
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-sm-2 col-4">
                        <div className="rounded">
                          <img
                            className="img"
                            src={event.uploadImage}
                            width="80"
                            height="80"
                            alt="event.svg"
                          />
                        </div>
                      </div>
                      <div className="col-sm-10 col-8 news-list-center">
                        <div className="row">
                          <div className="col-md-9 text-left ">
                            <div className="d-flex">
                              <p className="fs-18 text-dark font-weight-bold">
                                {getDescriptionData(event.eventTitle, 100)}
                              </p>
                            </div>
                          </div>
                          <div className="col-md-3 text-right">
                            <span className="fs-18 fw-600">
                              {moment(event.publishOn).format("Do")}
                            </span>{" "}
                            <span className="fs-18 fw-500">
                              {moment(event.publishOn).format("MMM")}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {index + 1 != eventList.length && <hr className="border" />}
              </React.Fragment>
            ) : null;
          })}
        </div>
      )}
    </>
  );
}
