import React, { Component } from "react";
import "./addmember.scss";
import { Collapse } from "reactstrap";

import { Basic } from "./basic";
import { HouseHold } from "./houseHold";
import { Farm } from "./Farm";
import { Assets } from "./assets";
import { LiveStock } from "./liveStock";
import { Bank } from "./bank";
import { getAccessdata } from "service/helperFunctions";
// Redux Connection
import { connect } from "react-redux";
import { history } from "service/helpers/index";
const arrow_left = require("assets/svg/add_member/arrow_left.svg");
const arrow_down = require("assets/svg/add_member/arrow_down.svg");

export class AddMembershipClass extends Component {
  state = {
    basic_profile: true,
    farm: false,
    assets: false,
    live_stock: false,
    bank: false,
    house_hold: false,
    memberId: null,
  };

  toggleCollapse = (val1, val2, val3, val4, val5, val6) => {
    this.setState({
      basic_profile: val1,
      house_hold: val2,
      farm: val3,
      assets: val4,
      live_stock: val5,
      bank: val6,
    });
  };
  collapse = (e) => {
    this.setState({
      [e.target.name]: false,
    });
  };

  setmemberId = (id) => {
    this.setState({ memberId: id });
  };

  render() {
    const {
      basic_profile,
      farm,
      assets,
      live_stock,
      bank,
      house_hold,
      memberId,
    } = this.state;
    let { accessData } = this.props;
    let permissionData = getAccessdata(accessData, "membership");

    let { deleteAccess = false } = permissionData;
    return (
      <div>
        <div className="d-flex container-sa my-3 ">
          <div
            onClick={() => {
              history.push("/fpo/member");
            }}
            className="d-flex"
          >
            <img className="cursor-pointer" src={arrow_left} alt="back" />
            <p className="ml-3 add-member-text-back mb-0 cursor-pointer">
              Add member
            </p>
          </div>
        </div>
        <div className="add-member">
          <div className="add-member-form-container">
            {!basic_profile && (
              <div
                className="d-flex justify-content-between cursor-pointer add-member-form-container-collapse align-items-center px-md-4 px-2 rounded mb-3"
                onClick={() =>
                  this.toggleCollapse(true, false, false, false, false, false)
                }
              >
                <div className="add-member-form-container-heading">
                  Member profile
                </div>
                <div>
                  <img src={arrow_down} alt="arrow_down" />
                </div>
              </div>
            )}
            <Collapse isOpen={basic_profile}>
              <Basic
                isOpen={basic_profile}
                collapse={this.collapse}
                toggleCollapse={this.toggleCollapse}
                setmemberId={this.setmemberId}
              />
            </Collapse>
            {!house_hold && (
              <div
                className="d-flex justify-content-between cursor-pointer add-member-form-container-collapse align-items-center px-md-4 px-2 rounded mb-3"
                onClick={() =>
                  this.toggleCollapse(false, true, false, false, false, false)
                }
              >
                <div className="add-member-form-container-heading">
                  House hold
                </div>
                <div>
                  <img src={arrow_down} alt="arrow_down" />
                </div>
              </div>
            )}
            <Collapse isOpen={house_hold}>
              <HouseHold
                isOpen={house_hold}
                collapse={this.collapse}
                toggleCollapse={this.toggleCollapse}
                deleteAccess={deleteAccess}
                memberId={memberId}
              />
            </Collapse>
            {!farm && (
              <div
                className="d-flex justify-content-between cursor-pointer add-member-form-container-collapse align-items-center px-md-4 px-2 rounded mb-3"
                onClick={() =>
                  this.toggleCollapse(false, false, true, false, false, false)
                }
              >
                <div className="add-member-form-container-heading">Crops</div>
                <div>
                  <img src={arrow_down} alt="arrow_down" />
                </div>
              </div>
            )}
            <Collapse isOpen={farm}>
              <Farm
                isOpen={farm}
                collapse={this.collapse}
                toggleCollapse={this.toggleCollapse}
                deleteAccess={deleteAccess}
                memberId={memberId}
              />
            </Collapse>
            {!assets && (
              <div
                className="d-flex justify-content-between cursor-pointer add-member-form-container-collapse align-items-center px-md-4 px-2 rounded mb-3"
                onClick={() =>
                  this.toggleCollapse(false, false, false, true, false, false)
                }
              >
                <div className="add-member-form-container-heading">Assets</div>
                <div>
                  <img src={arrow_down} alt="arrow_down" />
                </div>
              </div>
            )}
            <Collapse isOpen={assets}>
              <Assets
                isOpen={assets}
                collapse={this.collapse}
                toggleCollapse={this.toggleCollapse}
                deleteAccess={deleteAccess}
                memberId={memberId}
              />
            </Collapse>
            {!live_stock && (
              <div
                className="d-flex justify-content-between cursor-pointer add-member-form-container-collapse align-items-center px-md-4 px-2 rounded mb-3"
                onClick={() =>
                  this.toggleCollapse(false, false, false, false, true, false)
                }
              >
                <div className="add-member-form-container-heading">
                  Live stock
                </div>
                <div>
                  <img src={arrow_down} alt="arrow_down" />
                </div>
              </div>
            )}
            <Collapse isOpen={live_stock}>
              <LiveStock
                isOpen={live_stock}
                collapse={this.collapse}
                toggleCollapse={this.toggleCollapse}
                deleteAccess={deleteAccess}
                memberId={memberId}
              />
            </Collapse>
            {!bank && (
              <div
                className="d-flex justify-content-between cursor-pointer add-member-form-container-collapse align-items-center px-md-4 px-2 rounded mb-3"
                onClick={() =>
                  this.toggleCollapse(false, false, false, false, false, true)
                }
              >
                <div className="add-member-form-container-heading">Bank</div>
                <div>
                  <img src={arrow_down} alt="arrow_down" />
                </div>
              </div>
            )}
            <Collapse isOpen={bank}>
              <Bank
                isOpen={bank}
                collapse={this.collapse}
                toggleCollapse={this.toggleCollapse}
                deleteAccess={deleteAccess}
                memberId={memberId}
              />
            </Collapse>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  accessData: state.home.accessData,
});

export const AddMembership = connect(mapStateToProps, null)(AddMembershipClass);
