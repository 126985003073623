import { certificates } from "../service/apiVariables";
import { addQuery } from "service/helperFunctions";

//get certificate list
export const certificatesList = (query, id) => (
  dispatch,
  getState,
  { api, Toast }
) => {
  let { list } = certificates;
  list.id = id ? id : "";
  addQuery(query, certificates.list);
  return new Promise((resolve, reject) => {
    api({ ...certificates.list })
      .then((data) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//download Cerificate
export const downloadCerificate = (id) => (
  dispatch,
  getState,
  { api, Toast }
) => {
  let { downloadCerificate } = certificates;
  downloadCerificate.id = id;
  return new Promise((resolve, reject) => {
    api({ ...certificates.downloadCerificate })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

// get Certificate Data
export const getCertificatesData = (id) => (
  dispatch,
  getState,
  { api, Toast }
) => {
  let { getCertificatesData } = certificates;
  getCertificatesData.id = id;
  return new Promise((resolve, reject) => {
    api({ ...certificates.getCertificatesData })
      .then((data) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};
