import React, { Component, Fragment } from "react";
import { Pagination, CommonPageLoader } from "component/common";
import { getFIGList, getFIGListRm } from "action/FigCrpAct";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { AddFIGPopup } from "../AddFIGPopup";
import FigCard from "./Card";

export class FIGListClass extends Component {
  state = {
    isLoading: false,
    id: null,
    isPopupOpen: false,
  };

  //handle Change
  handleChange = (val) => {
    let { getFIGListApi, getFIGListRmApi, searchValue, id } = this.props;
    this.setState({ isLoading: true });
    let payload = {
      limit: 10,
      page: val,
      search: searchValue,
    };
    if (id) {
      getFIGListRmApi(id, payload)
        .then((data) => {
          data && this.setState({ isLoading: false });
        })
        .catch(() => this.setState({ isLoading: false }));
    } else {
      getFIGListApi(payload)
        .then((data) => {
          data && this.setState({ isLoading: false });
        })
        .catch(() => this.setState({ isLoading: false }));
    }
  };

  componentDidUpdate(prevProps) {
    if (this.props.activeTab == 5) {
      this.props.searchValue !== prevProps.searchValue && this.handleChange(1);
    }
    if (prevProps.activeTab != this.props.activeTab) {
      if (this.props.activeTab == 5) {
        this.handleChange(1);
      }
    }
  }

  handleEdit = (id) => {
    let { isPopupOpen } = this.state;
    this.setState({
      isPopupOpen: !isPopupOpen,
      id: id ? id : null,
    });
  };

  render() {
    let { isLoading, isPopupOpen, id } = this.state;
    let { editAccess = false, figMembers = {}, view } = this.props;
    let { results = [], pageMeta = {} } = figMembers || {};
    return (
      <React.Fragment>
        {!isLoading ? (
          results.length !== 0 ? (
            <div className="d-flex container-sa flex-column flex-wrap mt-3 px-0 justify-content-center">
              <div className="row">
                {results.map((member, index) => {
                  let {
                    name = "",
                    emailId,
                    mobileNumber,
                    figId,
                    leadName,
                  } = member;
                  return (
                    <Fragment>
                      {view == "1" ? (
                        <div className="col-12 mb-3" key={index}>
                          <div className="member-strip shadow rounded p-3 d-flex align-items-center w-100">
                            <div className="row w-100">
                              <div className="col-md-3 col-12 mt-md-0 mt-2">
                                <div className="d-flex flex-column justify-content-center h-100">
                                  <div className="member-card-font-content mb-2">
                                    {name}
                                  </div>
                                  <div className="member-card-font-heading">
                                    FIG name
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-3 col-12 mt-md-0 mt-2">
                                <div className="d-flex flex-column justify-content-center h-100">
                                  <div className="member-card-font-content mb-2">
                                    {leadName ? leadName : "-"}
                                  </div>
                                  <div className="member-card-font-heading">
                                    FIG Lead name
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-3 col-12 mt-md-0 mt-2">
                                <div className="d-flex flex-column justify-content-center h-100">
                                  <div className="member-card-font-content mb-2">
                                    {emailId ? emailId : "-"}
                                  </div>
                                  <div className="member-card-font-heading">
                                    FIG email
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-2 col-12 mt-md-0 mt-2">
                                <div className="d-flex flex-column justify-content-center h-100">
                                  <div className="member-card-font-content mb-2">
                                    {mobileNumber ? mobileNumber : "-"}
                                  </div>
                                  <div className="member-card-font-heading">
                                    Mobile number
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-1 col-12 mt-md-0 mt-2">
                                <div className="d-flex align-items-center justify-content-center h-100">
                                  {editAccess && (
                                    <div
                                      className="fs-16 line-height-19 text-black-50 cursor-pointer"
                                      onClick={() => this.handleEdit(figId)}
                                    >
                                      Edit
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="col-md-3 col-lg-3 col-6  mb-2 mt-3 px-2">
                          <FigCard {...member} />
                        </div>
                      )}
                    </Fragment>
                  );
                })}
              </div>
              <div className="my-0 w-100">
                {pageMeta.pageCount !== undefined &&
                  pageMeta.pageCount !== 1 && (
                    <Pagination
                      handleChange={this.handleChange}
                      pageMeta={pageMeta}
                    />
                  )}
              </div>
            </div>
          ) : (
            <p className="py-5 mb-0 text-black-50 fs-22 line-height-25 text-center">
              No data found
            </p>
          )
        ) : (
          <CommonPageLoader />
        )}
        <AddFIGPopup
          isOpen={isPopupOpen}
          toggle={() => this.handleEdit()}
          id={id}
          fpoId={this.props.id}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  figMembers: state.FigCrp.figMembers,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getFIGListApi: getFIGList,
      getFIGListRmApi: getFIGListRm,
    },
    dispatch
  );
};

export const FIGList = connect(
  mapStateToProps,
  mapDispatchToProps
)(FIGListClass);
