import React, { Component } from "react";
import backIcon from "../../assets/images/icon/arrow-left.svg";
import {
  Banner,
  CoursesVideoPlayList,
  CoursesUpNext,
  CoursesDetails,
} from "../../component/Acadamy";
import { history } from "service/helpers";
import {
  courseListApi,
  onGoingUpdateApi,
  getRecentViewList,
  myFavorities,
  getViewersCountForChapters,
} from "action/Acadamy";

import { CommonPageLoader } from "component/common";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Toast } from "service/toast";

export class CoursesClass extends Component {
  constructor() {
    super();
    this.state = {
      coursesData: "",
      OnGoingCoursesData: [],
      quizes: [],
      loading: false,
      chapter: 0,
      section: 0,
      currenstSectionid: "",
      currentCourseId: "",
      currentTimeline: "",
      chapterFileUrl: "",
      fileType: "",
      videoPlayerRest: true,
      recentViewList: [],
      recentViewCountData: {},
    };
  }

  componentWillMount = () => {
    this._getCourseDetails();
  };

  _getCourseDetails = () => {
    let search = this.props.location.search;
    let params = new URLSearchParams(search);
    let favorites = params.get("favorites");

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    this.props.courseListApi(this.props.match.params.id).then((data) => {
      let { sections = [], quizes, myLearningDetails } = data;

      this.setState({
        quizes: quizes,
        coursesData: data,
        sectionsData: sections,
      });
      if (favorites === null) {
        if (myLearningDetails) {
          let sectionIndex = sections.findIndex(
            (x) => x.sectionId === myLearningDetails.currenstSectionid
          );

          let chaptersIndex = sections[sectionIndex].chapters.findIndex(
            (x) => x.chapterId === myLearningDetails.currentChapterId
          );

          let chapterFileName =
            sections[sectionIndex].chapters[chaptersIndex].chapterFileName;
          let fileType =
            sections[sectionIndex].chapters[chaptersIndex].fileType;
          // sections[sectionIndex].

          this.setState(
            {
              currenstSectionid: myLearningDetails.currenstSectionid,
              currentCourseId: myLearningDetails.currentCourseId,
              currentChaptersId: myLearningDetails.currentChapterId,
              currentTimeline: myLearningDetails.currentTimeline,
              selectedSectionsData: sections[sectionIndex],
              loading: true,
              fileType,
              chapterFileUrl: chapterFileName,
              sectionIndex,
              chaptersIndex,
            },
            () => {
              let body = {
                courseId: myLearningDetails.currentChapterId,
              };
              this.props.getRecentViewList(body).then((data) => {
                if (data) {
                  this.setState({ recentViewList: data });
                }
              });
              this._getViewersCountForChapters(
                myLearningDetails.currentChapterId
              );
            }
          );
        } else {
          if (sections.length > 0) {
            this.checkNextCourse(sections[0]);
          }
        }
      } else {
        this.props.myFavorities(`filter/${data}`).then((data) => {
          let fetFavIndex = data.findIndex(
            (data) => data.favouriteId === Number(favorites)
          );

          let sectionIndex = sections.findIndex(
            (x) => x.sectionId === data[fetFavIndex].chapters.sectionId
          );

          let chaptersIndex = sections[sectionIndex].chapters.findIndex(
            (x) => x.chapterId === data[fetFavIndex].chapters.chapterId
          );

          let chapterFileName =
            sections[sectionIndex].chapters[chaptersIndex].chapterFileName;
          let fileType =
            sections[sectionIndex].chapters[chaptersIndex].fileType;

          this.setState(
            {
              currenstSectionid: data[fetFavIndex].chapters.sectionId,
              currentCourseId: data[fetFavIndex].courses.courseId,
              currentChaptersId: data[fetFavIndex].chapters.chapterId,
              currentTimeline: 0,
              selectedSectionsData: sections[sectionIndex],
              loading: true,
              fileType,
              chapterFileUrl: chapterFileName,
              sectionIndex,
              chaptersIndex,
            },
            () => {
              let body = {
                courseId: data[fetFavIndex].chapters.chapterId,
              };
              this.props.getRecentViewList(body).then((data) => {
                if (data) {
                  this.setState({ recentViewList: data });
                }
              });
              this._getViewersCountForChapters(
                data[fetFavIndex].chapters.chapterId
              );
            }
          );
        });
      }
    });
  };

  _getViewersCountForChapters = (id) => {
    this.props.getViewersCountForChapters(id).then((data) => {
      this.setState({ recentViewCountData: data });
    });
  };

  _getdetail = () => {
    this.props.courseListApi(this.props.match.params.id).then((data) => {
      let { sections = [], quizes, myLearningDetails } = data;

      this.setState({
        quizes: quizes,
        coursesData: data,
        sectionsData: sections,
      });

      if (myLearningDetails) {
        let sectionIndex = sections.findIndex(
          (x) => x.sectionId === myLearningDetails.currenstSectionid
        );

        let chaptersIndex = sections[sectionIndex].chapters.findIndex(
          (x) => x.chapterId === myLearningDetails.currentChapterId
        );

        let chapterFileName =
          sections[sectionIndex].chapters[chaptersIndex].chapterFileName;
        let fileType = sections[sectionIndex].chapters[chaptersIndex].fileType;
        // sections[sectionIndex].

        this.setState(
          {
            currenstSectionid: myLearningDetails.currenstSectionid,
            currentCourseId: myLearningDetails.currentCourseId,
            currentChaptersId: myLearningDetails.currentChapterId,
            currentTimeline: myLearningDetails.currentTimeline,
            selectedSectionsData: sections[sectionIndex],
            loading: true,
            fileType,
            chapterFileUrl: chapterFileName,
            sectionIndex,
            chaptersIndex,
          },
          () => {
            let body = {
              courseId: myLearningDetails.currentChapterId,
            };
            this.props.getRecentViewList(body).then((data) => {
              if (data) {
                this.setState({ recentViewList: data });
              }
            });
            this._getViewersCountForChapters(
              myLearningDetails.currentChapterId
            );
          }
        );
      } else {
        if (sections.length > 0) {
          this.checkNextCourse(sections[0]);
        }
      }
    });
  };

  checkNextCourse = (data) => {
    window.scrollTo(0, 0);
    this.setState({ videoPlayerRest: false });

    let { chapters = [] } = data;

    if (chapters.length > 0) {
      this.setState(
        {
          selectedSectionsData: data,
          currenstSectionid: data.sectionId,
          currentChaptersId: chapters[0].chapterId,
          fileType: chapters[0].fileType,
          chapterFileUrl: chapters[0].chapterFileName,
          currentCourseId: data.courseId,
          loading: true,
          sectionIndex: 0,
          chaptersIndex: 0,
        },
        () => {
          let body = {
            courseId: chapters[0].chapterId,
          };
          this.props.getRecentViewList(body).then((data) => {
            if (data) {
              this.setState({ recentViewList: data });
            }
          });
          this._getViewersCountForChapters(chapters[0].chapterId);
        }
      );
    } else {
      this.setState({
        selectedSectionsData: data,
        currenstSectionid: data.sectionId,
        loading: true,
      });
    }

    setTimeout(() => {
      this.setState({ videoPlayerRest: true });
    }, 300);
  };

  updateChapterId = (ChapterId, chaptersIndex, fileTypeData) => {
    this.setState({
      currentChaptersId: ChapterId,
      chaptersIndex: chaptersIndex,
      fileType: fileTypeData,
    });
  };

  handelAutoSectionChange = () => {
    let { sectionIndex, sectionsData } = this.state;

    if (sectionIndex + 1 === sectionsData.length) {
      Toast({
        type: "success",
        message:
          "you successfully complete the video tutorial please complete the quiz",
      });
      this._getCourseDetails();
    }

    if (sectionIndex + 1 < sectionsData.length) {
      return new Promise((resolve, reject) => {
        this.setState({
          selectedSectionsData: sectionsData[sectionIndex + 1],
          sectionIndex: sectionIndex + 1,
          chaptersIndex: 0,
          currenstSectionid: sectionsData[sectionIndex + 1].sectionId,
        });

        if (sectionsData[sectionIndex + 1].chapters.length > 0) {
          this.setState(
            {
              currentChaptersId:
                sectionsData[sectionIndex + 1].chapters[0].chapterId,
              chaptersIndex: 0,
              fileType: sectionsData[sectionIndex + 1].chapters[0].fileType,
            },
            () => {
              resolve(sectionsData[sectionIndex + 1].chapters[0]);
              let body = {
                courseId: sectionsData[sectionIndex + 1].chapters[0].chapterId,
              };
              this.props.getRecentViewList(body).then((data) => {
                if (data) {
                  this.setState({ recentViewList: data });
                }
              });
              this._getViewersCountForChapters(
                sectionsData[sectionIndex + 1].chapters[0].chapterId
              );
            }
          );
        }
      });
    }
  };

  render() {
    let {
      sectionsData,
      selectedSectionsData,
      quizes = [],
      coursesData,
      loading,
      currenstSectionid,
      currentCourseId,
      currentTimeline,
      currentChaptersId,
      chapterFileUrl,
      sectionIndex,
      chaptersIndex,
      fileType,
      videoPlayerRest,
      recentViewList,
      recentViewCountData,
    } = this.state;
    let { onGoingUpdateApi } = this.props;
    return (
      <>
        <div className="min-vh-100">
          <Banner minSize={true} />
          <div className="d-flex container-sa my-3 ">
            <div
              onClick={() => history.goBack()}
              className="d-flex cursor-pointer"
            >
              <img className="cursor-pointer" src={backIcon} alt="back" />
              <p className="ml-3 add-member-text-back mb-0">{"Courses"}</p>
            </div>
          </div>
          {loading ? (
            <>
              <CoursesVideoPlayList
                videoPlayerRest={videoPlayerRest}
                fileType={fileType}
                coursesData={coursesData}
                selectedSectionsData={selectedSectionsData}
                onGoingUpdateApi={onGoingUpdateApi}
                currenstSectionid={currenstSectionid}
                currentCourseId={currentCourseId}
                currentTimeline={currentTimeline}
                currentChaptersId={currentChaptersId}
                chapterFileUrl={chapterFileUrl}
                updateChapterId={this.updateChapterId}
                sectionIndex={sectionIndex}
                chaptersIndex={chaptersIndex}
                handelAutoSectionChange={this.handelAutoSectionChange}
              />
              <div className="bg-blue-f9">
                <div className="container-sa py-4">
                  <div className="row">
                    <div className="col-sm-8">
                      <CoursesDetails
                        coursesData={coursesData}
                        selectedSectionsData={selectedSectionsData}
                        handleChange={this._getdetail}
                        recentViewList={recentViewList}
                        chaptersIndex={chaptersIndex}
                        recentViewCountData={recentViewCountData}
                        currentChaptersId={currentChaptersId}
                      />
                    </div>
                    <div className="col-sm-4">
                      <CoursesUpNext
                        handelUpComing={this.checkNextCourse}
                        sectionsData={sectionsData}
                        quizes={quizes}
                        coursesData={coursesData}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              {/* Loader */}
              <CommonPageLoader />
            </>
          )}
        </div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      courseListApi,
      onGoingUpdateApi,
      getRecentViewList,
      getViewersCountForChapters,
      myFavorities,
    },
    dispatch
  );
};

let component = CoursesClass;

export const Courses = connect(null, mapDispatchToProps)(component);
