import React from "react";

export const NormalTextArea = ({
  className = "form-control",
  placeholder = "",
  label = "",
  onChange,
  value = "",
  name,
  rows = "3",
  disabled = false,
  isLink = false,
  isFullWidth = false,
  isHalfWidth = false,
  isHalfWidthForMemberProfile = false,
  required = false,
}) => {
  return (
    <div className="normal-input">
      <form
        autocomplete="off"
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <div className="row d-flex">
          <div
            className={`${
              isFullWidth ? "col-2" : isHalfWidth ? "col-4" : "col-3"
            } pr-0 `}
          >
            {label !== "" ? (
              <div className="fs-16 text-black-32 font-weight-bold line-height-19 mt-2">
                {label}{" "}
                {required && (
                  <span className="text-danger font-weight-bold">*</span>
                )}
              </div>
            ) : null}
          </div>
          <div
            className={`${
              isFullWidth
                ? "col-10"
                : isHalfWidth || isHalfWidthForMemberProfile
                ? "col-8"
                : "col-9"
            }  `}
          >
            <textarea
              className={`h-100 ${isLink ? `${className} islink` : className}`}
              name={name}
              disabled={disabled}
              value={value}
              min={0}
              rows={rows}
              placeholder={placeholder}
              onChange={(e) => {
                let body = {};

                body = {
                  target: {
                    name: e.target.name,
                    value: e.target.value,
                  },
                };

                onChange(body);
              }}
            />
            {isLink ? (
              <span className="link-area">
                <i className="fs-24 icon-link"></i>
              </span>
            ) : null}
          </div>
        </div>
      </form>
    </div>
  );
};
