import React from "react";
import { Modal, ModalBody } from "reactstrap";
import "./style.scss";

const FPONextPopup = () => {
  const handleRedirection = () => {
    window.open("https://fpo.fponext.com/");
  };

  return (
    <Modal
      isOpen
      centered={true}
      backdrop="static"
      keyboard={false}
      className="fpoNext-modal"
    >
      <ModalBody>
        <div className="fpoNext-popup">
          <div className="mb-3">
            <h1>
              <strong>Exciting News!</strong> We've upgraded to{" "}
              <strong>FPONext</strong>, the advanced evolution of FPOGateway.
              Continue your activities seamlessly – from{" "}
              <strong>
                Member management, Grading, Robocall & SMS services
              </strong>{" "}
              to <strong>IPL loan applications and more</strong> – on our
              enhanced platform. Tap the '<strong>Continue to FPONext</strong>'
              button below to explore new possibilities awaiting you!
            </h1>
          </div>
          <div className="d-flex justify-content-center">
            <div>
              <button onClick={handleRedirection} className="continue-btn mt-3">
                Continue to FPONext
              </button>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default FPONextPopup;
