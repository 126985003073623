import React, { useState } from "react";
import { Modal, ModalBody } from "reactstrap";

import "./FileViewer.scss";

const pdf = require("../../../assets/svg/Documents/pdf.svg");
const arrow_left = require("../../../assets/svg/Documents/arrow-left.svg");
const arrow_right = require("../../../assets/svg/Documents/arrow-right.svg");
const eye = require("../../../assets/svg/Documents/eye.svg");

export const FileViewer = ({ document }) => {
  let [activeIndex, setActiveIndex] = useState(0);

  let [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const next = () => {
    if (activeIndex == document.length - 1) {
      setActiveIndex(0);
    } else {
      setActiveIndex(activeIndex + 1);
    }
  };

  const previous = () => {
    if (activeIndex == 0) {
      setActiveIndex(document.length - 1);
    } else {
      setActiveIndex(activeIndex - 1);
    }
  };

  if (document.length == 0) return null;

  return (
    <>
      <h4>Documents</h4>
      <div className="file-viewer">
        <span>{document[activeIndex]?.fileName}</span>
        <div className="pdf-file">
          <img src={pdf} className="pdf-icon" />
          {document.length > 1 && (
            <div className="file-arrow">
              <div>
                <img src={arrow_left} onClick={() => previous()} />
              </div>
              <div>
                <img src={arrow_right} onClick={() => next()} />
              </div>
            </div>
          )}
          <div className="view-file">
            <img src={eye} onClick={() => toggle()} />
          </div>
        </div>
      </div>
      <Modal isOpen={isOpen} toggle={toggle} size="lg">
        <ModalBody>
          <div className="file-modal">
            <iframe src={document[activeIndex]?.documentUrl}></iframe>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};
