import React, { Component } from "react";
import { history } from "service/helpers";
import moment from "moment";
import long_arrow from "assets/svg/price_details/long_arrow.svg";
import "./bodtab.scss";

export class Card extends Component {
  handleRoute = (id, activeTab) => {
    let { editAccess } = this.props;
    if (editAccess) {
      if (activeTab === "1") {
        history.push(`/fpo/edit-bod-compliance/${id}`);
      } else {
        history.push(`/fpo/edit-agm/${id}`);
      }
    }
  };
  render() {
    let { payload, index, activeTab } = this.props;
    const {
      id,
      agendaHighlights,
      numberOfAttendees,
      location,
      dateOfLastMeetingD,
      attendeesListUrl,
      supportingDocumentUrl,
    } = payload;
    return (
      <div
        key={index}
        className={`col-md-3 col-lg-3 col-6 ${
          index % 4 === 3 ? "pr-md-0" : ""
        } mb-2 mt-3 px-2 ${index % 4 === 0 ? "pl-md-0" : ""}`}
      >
        <div className="bod-card rounded shadow cursor-pointer">
          <div onClick={() => this.handleRoute(id, activeTab)}>
            {agendaHighlights ? (
              <div className="row mb-4">
                <div className="col-sm-12 ">
                  <div className="line-height-21 font-Gilroy-Bold fs-18 text-black-1c font-weight-bold height-100">
                    {agendaHighlights && agendaHighlights.length > 90
                      ? agendaHighlights.substring(0, 89) + "..."
                      : agendaHighlights}
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            <div className="row mb-4">
              <div className="col-sm-12 ">
                <div className="line-height-21 font-Gilroy text-black-50 fs-16 mb-3">
                  {"Number of attendees"}
                </div>
                <div className="line-height-21 font-Gilroy-Bold fs-18 text-black-1c font-weight-bold">
                  {numberOfAttendees ? numberOfAttendees : "-"}
                </div>
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-sm-6 ">
                <div className="line-height-21 font-Gilroy text-black-50 fs-16 mb-3">
                  {"Location"}
                </div>
                <div className="line-height-21 font-Gilroy-Bold fs-18 text-black-1c font-weight-bold">
                  {location ? location : "-"}
                </div>
              </div>
              <div className="col-sm-6 pr-0">
                <div className="line-height-21 font-Gilroy text-black-50 fs-16 mb-3">
                  {"Date"}
                </div>
                <div className="line-height-21 font-Gilroy-Bold fs-18 text-black-1c font-weight-bold">
                  {dateOfLastMeetingD
                    ? moment(dateOfLastMeetingD).format("DD/MM/YYYY")
                    : "-"}
                </div>
              </div>
            </div>
          </div>
          {attendeesListUrl ? (
            <div
              className="d-flex mb-3"
              onClick={() => window.open(attendeesListUrl, "_blank")}
            >
              <div className="text-green font-weight-bold mr-2 fs-16 gilroy">
                {"Total attendees list"}
              </div>
              <div>
                <img src={long_arrow} alt="arrow.svg" />
              </div>
            </div>
          ) : null}
          {supportingDocumentUrl ? (
            <div
              className="d-flex mb-3"
              onClick={() => window.open(supportingDocumentUrl, "_blank")}
            >
              <div className="text-green font-weight-bold mr-2 fs-16 gilroy">
                {"Supporting doc"}
              </div>
              <div>
                <img src={long_arrow} alt="arrow.svg" />
              </div>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}
