import React, { useState, useEffect, useCallback, useRef } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import moment from "moment/moment";
import SimpleReactValidator from "simple-react-validator";
import {
  allState,
  getDistrictApiwithState,
  getMemberFPOData,
  getMemberModuleFPOMemberData,
  updateMemberModuleFPOMemberData,
  addMemberModuleMember,
  getMember,
  getAboutusMemberDetail,
  memberEditData,
  memberBasicAdd,
} from "action/MemberAct";
import {
  getRMFPOData,
  getRMFPOMemberData,
  updateRMFPOMemberData,
  addKYMNew,
  getCropsCategory,
  getCropsClassification,
  getCropsCategoryRedux,
  getCropsClassificationRedux,
} from "action/GradingAct";
import {
  getKYMFPOData,
  getKYMModuleFPOMemberData,
  updateKYMModuleFPOMemberData,
  addKYMModuleMember,
} from "action/KYMAct";
import BasicInfo from "./BasicInfo";
import LandDetails from "./LandDetails";
import CropDetails from "./CropDetails";
import LiveStockDetails from "./LiveStockDetails";
import FarmingDetails from "./FarmingDetails";
import { Toast } from "service/toast";
import { history } from "service/helpers";
import { CommonPageLoader } from "component/common";
import {
  memberDataVariables,
  cropDataVariables,
  liveStockDataVariables,
} from "./dataVariables";

const MemberDetails = (props) => {
  const {
    module = "rm",
    allStateApi,
    getDistrictApiwithStateApi,
    getRMFPODataApi,
    getRMFPOMemberDataApi,
    updateRMFPOMemberDataApi,
    addKYMNewApi,
    getKYMFPODataApi,
    getKYMModuleFPOMemberDataApi,
    updateKYMModuleFPOMemberDataApi,
    addKYMModuleMemberApi,
    updateMemberModuleFPOMemberDataApi,
    addMemberModuleMemberApi,
    getMemberModuleFPOMemberDataApi,
    getMemberFPODataApi,
    getMember,
    memberEditData,
    memberBasicAdd,
    getAboutusMemberDetail,
    getCropsCategoryApi,
    getCropsClassificationApi,
    getCropsCategoryReduxApi,
    getCropsClassificationReduxApi,
    categoryOptions,
    cropOptions,
  } = props;
  const queryParams = new URLSearchParams(window?.location?.search);
  const memberID = queryParams.get("memberID");
  const isRM = module === "rm";
  const fieldRef = useRef();
  const [, forceUpdate] = useState();
  const [loading, setLoading] = useState(false);
  const [stateOptions, setStateoptions] = useState([]);
  const [districtOptions, setDistrictoptions] = useState([]);
  const [showCropError, setShowCropError] = useState(false);
  const [showLiveStockError, setShowLiveStockError] = useState(false);
  const [cropCurrentIndex, setCropCurrentindex] = useState(null);
  const [liveStockCurrentIndex, setLiveStockCurrentindex] = useState(null);
  const [selectedMachinery, setSelectedMachinery] = useState([]);
  const [selectedInsurance, setSelectedInsurance] = useState([]);
  const [selectedVehicle, setSelectedVehicle] = useState([]);
  const [memberData, setMemberData] = useState({ ...memberDataVariables });
  const [cropData, setCropData] = useState([{ ...cropDataVariables }]);
  const [liveStockData, setLiveStockData] = useState([
    { ...liveStockDataVariables },
  ]);

  useEffect(() => {
    const cropDatas = [...cropData];
    cropDatas[0].categoryOptions = categoryOptions;
    cropDatas[0].cropOptions = cropOptions;
    setCropData(cropDatas);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryOptions, cropOptions]);

  const stateApi = useCallback(() => {
    allStateApi().then((data) => {
      let stateData = [];
      data.forEach((list) => {
        stateData.push({ value: list.stateId, label: list.stateName });
      });
      setStateoptions(stateData);
    });
  }, [allStateApi]);

  useEffect(() => {
    stateApi();
  }, [stateApi]);

  const districtApi = useCallback(
    (value) => {
      getDistrictApiwithStateApi({ id: value }).then((data) => {
        let districtData = [];
        data.forEach((list) => {
          districtData.push({
            value: list.districtId,
            label: list.districtName,
          });
        });
        setDistrictoptions(districtData);
      });
    },
    [getDistrictApiwithStateApi]
  );

  const getCategoryOptionsAdd = useCallback(() => {
    if (categoryOptions?.length === 0) getCropsCategoryReduxApi();
  }, [getCropsCategoryReduxApi, categoryOptions]);

  useEffect(() => {
    getCategoryOptionsAdd();
  }, [getCategoryOptionsAdd]);

  const getCropOptions = useCallback(() => {
    if (cropOptions?.length === 0) getCropsClassificationReduxApi();
  }, [getCropsClassificationReduxApi, cropOptions]);

  useEffect(() => {
    getCropOptions();
  }, [getCropOptions]);

  const getCategoryOptions = (id, index) => {
    let body = { kymclassificationId: id };
    getCropsCategoryApi(body).then((data) => {
      const updateCropData = [...cropData];
      let categoryData = [];
      data.forEach((list) =>
        categoryData.push({ value: list.id, label: list.name })
      );
      updateCropData[index].categoryOptions = categoryData;
      if (categoryData.length === 1) {
        updateCropData[index].classification = categoryData[0].value.toString();
      }
      setCropData(updateCropData);
    });
  };

  const getClassificationOptions = (id, index) => {
    let body = { kymclassificationId: id };
    getCropsClassificationApi(body).then((data) => {
      const updateCropData = [...cropData];
      let classificationData = [];
      data.forEach((list) =>
        classificationData.push({
          value: list.id,
          label: list.name,
          masterID: list.kymClassiMasterId,
        })
      );
      updateCropData[index].cropOptions = classificationData;
      setCropData(updateCropData);
    });
  };

  const getFPOData = useCallback(() => {
    const fpoID =
      module === "member"
        ? localStorage.getItem("fpoId")
        : localStorage.getItem("rmFPOID");

    if (fpoID) {
      const apiCheck = isRM ? getRMFPODataApi : getKYMFPODataApi;
      const api = module === "member" ? getAboutusMemberDetail : apiCheck;

      api(fpoID).then((data) => {
        setMemberData((prevState) => {
          return {
            ...prevState,
            fpo_name:data?.fpoName,
            state: data?.stateId,
          };
        });
        districtApi(data?.stateId);
      });
    }
  }, [
    getRMFPODataApi,
    getKYMFPODataApi,
    isRM,
    module,
    getAboutusMemberDetail,
    districtApi,
  ]);

  useEffect(() => {
    getFPOData();
  }, [getFPOData]);

  const getMemberData = useCallback(() => {
    if (memberID) {
      setLoading(true);
      const apiCheck = isRM
        ? getRMFPOMemberDataApi
        : getKYMModuleFPOMemberDataApi;
      const api = module === "member" ? getMember : apiCheck;
      api(module === "member" ? { id: memberID } : memberID)
        .then((data) => {
          console.log("data", JSON.stringify(data));
          const {
            district,
            memberLandDetails,
            equipments,
            vehicles,
            insurances,
            crops = [],
            liveStocks = [],
          } = data;
          const insuranceData = [
            insurances?.insurenceA,
            insurances?.insurenceB,
            insurances?.insurenceC,
            insurances?.insurenceD,
            insurances?.insurenceE,
            insurances?.insurenceF,
          ];
          const newMachineryData = [];
          const newInsuranceData = [];
          const newVehicleData = [];
          equipments.forEach((list) => {
            newMachineryData.push({
              value: list.assetName,
              label: list.assetName,
            });
          });
          insuranceData.forEach((list) => {
            if (list !== "")
              newInsuranceData.push({ value: list, label: list });
          });
          vehicles.forEach((list) => {
            newVehicleData.push({
              value: list.vehicleName,
              label: list.vehicleName,
            });
          });
          setSelectedMachinery(newMachineryData);
          setSelectedInsurance(newInsuranceData);
          setSelectedVehicle(newVehicleData);
          setMemberData((prevState) => {
            return {
              ...prevState,
              district: district?.districtId,
              unique_id: data?.UniqueID,
              name: data?.memberFirstName,
              gender: data?.gender,
              dob: new Date(data?.memberDOB),
              mobile: data?.phone,
              other_mobile: data?.memOtherPhone,
              village: data?.village,
              block: data?.block,
              pincode: data?.pincode,
              father_name: data?.fathername,
              education: data?.eduqual,
              total_land: memberLandDetails?.plotAcreage,
              own_irrigated: memberLandDetails?.ownirrigatedland,
              own_rainfed: memberLandDetails?.ownrainfedland,
              lease_irrigated: memberLandDetails?.leaseirrigatedland,
              lease_rainfed: memberLandDetails?.leaserainfedland,
              machinery_isOther: data?.otherEqupment ? true : false,
              machinery_other: data?.otherEqupment,
              insurance_isOther: insurances?.otherInsurence ? true : false,
              inusrance_other: insurances?.otherInsurence,
              vehicle_isOther: data?.otherVehicle ? true : false,
              vehicle_other: data?.otherVehicle,
              house: data?.housetype,
              house_type: data?.house,
              income: data?.annualgrossincome,
              aadhaar: insurances?.aadhar,
              pan: insurances?.pan,
              marital: data?.marital,
              spouse_name: data?.spousename,
              spouse_aadhaar: data?.spouse_aadhar_kyc_number,
              spouse_pan: data?.spouse_pan_kyc_number,
            };
          });

          const cropData = [];
          const totalCrops = crops?.length;
          crops.map(async (list, index) => {
            cropData.push({
              ...list,
              categoryOptions: [],
              cropOptions: [],
            });

            await getCropsCategoryApi().then((data) => {
              let cropsData = [];
              data.forEach((list) =>
                cropsData.push({
                  value: list.id,
                  label: list.name,
                })
              );
              cropData[index].categoryOptions = cropsData;
            });

            let body = { kymclassificationId: list.classification };
            await getCropsClassificationApi(body).then((data) => {
              let classificationData = [];
              data.forEach(async (list) => {
                classificationData.push({
                  value: list.id,
                  label: list.name,
                  masterID: list.kymClassiMasterId,
                });
              });
              cropData[index].cropOptions = classificationData;
            });

            setCropData(
              totalCrops === 0 ? [{ ...cropDataVariables }] : [...cropData]
            );
          });

          const totalLiveStocks = liveStocks?.length;
          liveStocks.forEach((list) => delete list["id"]);
          setLiveStockData(
            totalLiveStocks === 0
              ? [{ ...liveStockDataVariables }]
              : [...liveStocks]
          );
          setLoading(false);
        })
        .catch(() => setLoading(false));
    }
  }, [
    getRMFPOMemberDataApi,
    getKYMModuleFPOMemberDataApi,
    memberID,
    isRM,
    module,
    getCropsClassificationApi,
    getCropsCategoryApi,
    getMember,
  ]);

  useEffect(() => {
    getMemberData();
  }, [getMemberData]);

  const handleMemberChange = (e) => {
    const { name, value } = e.target || e || {};
    setMemberData({ ...memberData, [name]: value });
    if (name === "state") {
      districtApi(value);
    }
    let isLand =
      name === "own_irrigated" ||
      name === "own_rainfed" ||
      name === "lease_irrigated" ||
      name === "lease_rainfed";
    if (isLand) {
      setMemberData((prevState) => {
        return {
          ...prevState,
          total_land:
            parseFloat(prevState.own_irrigated || 0.0) +
            parseFloat(prevState.own_rainfed || 0.0) +
            parseFloat(prevState.lease_irrigated || 0.0) +
            parseFloat(prevState.lease_rainfed || 0.0),
        };
      });
    }
  };

  const handleCropChange = (e, index) => {
    setCropCurrentindex(index);
    const { name, value } = e.target || e || {};
    const list = [...cropData];
    list[index][name] = value;
    setCropData(list);
    const currentData = cropData[index];
    if (
      name === "classification" &&
      currentData.categoryOptions.length === 19
    ) {
      getClassificationOptions(value, index);
    } else if (name === "cropName" && currentData.cropOptions.length === 87) {
      const selectedItem = currentData.cropOptions.find(
        (list) => parseInt(list.value) === parseInt(value)
      );
      getCategoryOptions(selectedItem.masterID, index);
    }
  };

  const handleLiveStockChange = (e, index) => {
    setLiveStockCurrentindex(index);
    const { name, value } = e.target || e || {};
    const list = [...liveStockData];
    list[index][name] = value;
    setLiveStockData(list);
  };

  const handleAddClick = (index, type) => {
    if (type === "crop") {
      setCropCurrentindex(index);
      let currentCropData = cropData[index];
      let { season, classification, cropName, othercrop } = currentCropData;
      let validationOne =
        season === "" ||
        classification === "" ||
        cropName === "" ||
        othercrop === "";
      let validationTwo =
        season === "" || classification === "" || cropName === "";
      let cropValidation =
        cropData[index].cropName === "85" ? validationOne : validationTwo;
      if (cropValidation) {
        setShowCropError(true);
        return null;
      } else {
        setShowCropError(false);
      }
      setCropData([
        ...cropData,
        {
          season: "",
          classification: "",
          cropName: "",
          othercrop: "",
          categoryOptions: categoryOptions,
          cropOptions: cropOptions,
        },
      ]);
    } else if (type === "liveStock") {
      setLiveStockCurrentindex(index);
      let currentLiveStockData = liveStockData[index];
      let { liveStockName, qty, otherLiveStock } = currentLiveStockData;
      let validationOne =
        liveStockName === "" || qty === "" || otherLiveStock === "";
      let validationTwo = liveStockName === "" || qty === "";
      let liveStockValidation =
        liveStockData[index].liveStockName === "Others"
          ? validationOne
          : validationTwo;
      if (liveStockValidation) {
        setShowLiveStockError(true);
        return null;
      } else {
        setShowLiveStockError(false);
      }
      setLiveStockData([
        ...liveStockData,
        { liveStockName: "", qty: "", otherLiveStock: "" },
      ]);
    }
  };

  const handleRemoveClick = (index, type) => {
    if (type === "crop") {
      const crop = [...cropData];
      crop.splice(index, 1);
      setCropData(crop);
    } else if (type === "liveStock") {
      const liveStock = [...liveStockData];
      liveStock.splice(index, 1);
      setLiveStockData(liveStock);
    }
  };

  const validator = useRef(
    new SimpleReactValidator({
      element: (message) => (
        <span className="error-message text-danger fs-14">{message}</span>
      ),
      autoForceUpdate: this,
    })
  );

  useEffect(() => {
    let machineryData = [];
    let vehicleData = [];
    let insuranceData = [];
    machineryData = selectedMachinery.map((val) => {
      return val.value;
    });
    vehicleData = selectedVehicle.map((val) => {
      return val.value;
    });
    insuranceData = selectedInsurance.map((val) => {
      return val.value;
    });
    setMemberData((prevState) => {
      return {
        ...prevState,
        machinery: machineryData,
        vehicle: vehicleData,
        insurance: insuranceData,
      };
    });
  }, [selectedMachinery, selectedVehicle, selectedInsurance]);

  const handleSubmit = (event) => {
    event.preventDefault();
    const { machinery, vehicle, insurance, house, pan, marital, spouse_pan } =
      memberData;
    const machineryOther = machinery.includes("Others");
    const vehicleOther = vehicle.includes("Others");
    const insuranceOther = insurance.includes("Other");
    const isMarital = marital === "Yes";
    const isHousePucca = house === "pucca";

    if (house && !isHousePucca) {
      validator.current.fields.house_type = true;
      validator.current.errorMessages.house_type = null;
    }
    if (machinery?.length > 0 && !machineryOther) {
      validator.current.fields.machinery_other = true;
      validator.current.errorMessages.machinery_other = null;
    }
    if (vehicle?.length > 0 && !vehicleOther) {
      validator.current.fields.vehicle_other = true;
      validator.current.errorMessages.vehicle_other = null;
    }
    if (insurance?.length > 0 && !insuranceOther) {
      validator.current.fields.inusrance_other = true;
      validator.current.errorMessages.inusrance_other = null;
    }
    if (marital && marital !== "Yes") {
      validator.current.fields.spouse_name = true;
      validator.current.errorMessages.spouse_name = null;
    }

    if (validator.current.allValid()) {
      const { season, classification, cropName, othercrop } = cropData?.[0];
      const { liveStockName, qty, otherLiveStock } = liveStockData?.[0];
      const isCropOther = cropName === "85" && othercrop === "";
      const isLiveStockOther =
        liveStockName === "Others" && otherLiveStock === "";
      if (
        season === "" ||
        cropName === "" ||
        classification === "" ||
        isCropOther
      ) {
        Toast({ type: "error", message: "Please enter crop details" });
        return null;
      }
      if (liveStockName === "" || qty === "" || isLiveStockOther) {
        Toast({ type: "error", message: "Please enter livestock details" });
        return null;
      }

      let panReegex = /([A-Z]){5}([0-9]){4}([A-Z]){1}$/;
      if (pan && !panReegex.test(pan)) {
        Toast({ type: "error", message: "Please enter valid PAN for member" });
        return null;
      }
      if (spouse_pan && !panReegex.test(spouse_pan)) {
        Toast({ type: "error", message: "Please enter valid PAN for spouse" });
        return null;
      }

      const cropDetails = [...cropData];
      cropDetails.forEach((list) => {
        delete list["categoryOptions"];
        delete list["cropOptions"];
      });

      let body = {
        fpoId:
          module === "member"
            ? parseInt(localStorage.getItem("fpoId"))
            : parseInt(localStorage.getItem("rmFPOID")),
        memberID: parseInt(memberID),
        fpoName: memberData?.fpo_name,
        UniqueID: memberData?.unique_id,
        state: memberData?.state.toString(),
        district: memberData?.district.toString(),
        memberFirstName: memberData?.name,
        phone: memberData?.mobile,
        memOtherPhone: memberData?.other_mobile,
        gender: memberData?.gender,
        memberDOB: moment(memberData?.dob).format("YYYY-MM-DD"),
        village: memberData?.village,
        block: memberData?.block,
        pincode: memberData?.pincode,
        fathername: memberData?.father_name,
        eduqual: memberData?.education,
        plotAcreage: parseInt(memberData?.total_land),
        ownirrigatedland: parseInt(memberData?.own_irrigated),
        ownrainfedland: parseInt(memberData?.own_rainfed),
        leaseirrigatedland: parseInt(memberData?.lease_irrigated),
        leaserainfedland: parseInt(memberData?.lease_rainfed),
        crops: cropDetails,
        liveStocks: liveStockData,
        equipments: machinery,
        otherEquipment: machineryOther ? memberData?.machinery_other : "",
        vehicles: vehicle,
        otherVehicle: vehicleOther ? memberData?.vehicle_other : "",
        insurances: insurance,
        otherInsurance: insuranceOther ? memberData?.inusrance_other : "",
        housetype: memberData?.house,
        house: isHousePucca ? memberData?.house_type : "",
        annualgrossincome: parseInt(memberData?.income),
        aadhar_kyc_number: memberData?.aadhaar,
        pan_kyc_number: memberData?.pan,
        marital: memberData?.marital,
        spousename: isMarital ? memberData?.spouse_name : "",
        spouse_aadhar_kyc_number: isMarital ? memberData?.spouse_aadhaar : "",
        spouse_pan_kyc_number: isMarital ? memberData?.spouse_pan : "",
        entryDate: moment(new Date()).format("YYYY-MM-DD"),
        snleaserainfedland: null,
        snleaseirrigatedland: null,
        snownrainfedland: null,
        snownirrigatedland: null,
      };
      if (!memberID) {
        delete body.memberID;
      } else if (module !== "member") {
        delete body.memberID;
      }
      const api = (apiFunction, memberId, body) => {
        if (memberId) {
          apiFunction(memberId, body).then(() => history.goBack());
        } else {
          apiFunction(body).then(() => history.goBack());
        }
      };
      if (module === "member") {
        if (memberID) {
          api(memberEditData, body);
        } else {
          api(memberBasicAdd, body);
        }
      } else {
        if (isRM) {
          if (memberID) {
            api(updateRMFPOMemberDataApi, memberID, body);
          } else {
            api(addKYMNewApi, body);
          }
        } else {
          if (memberID) {
            api(updateKYMModuleFPOMemberDataApi, memberID, body);
          } else {
            api(addKYMModuleMemberApi, body);
          }
        }
      }
      console.log("body", JSON.stringify(body));
    } else {
      validator.current.showMessages();
      forceUpdate(1);
      Toast({ type: "error", message: "Please fill all the required fields" });
      const inputRefs = document.querySelectorAll("#myform input, select");
      for (let i = 0; i < inputRefs.length; i++) {
        if (!inputRefs[i].value && inputRefs[i].required) {
          inputRefs[i].focus();
          break;
        }
      }
    }
  };

  return (
    <div className="d-flex container-sa my-3 col-md-8">
      {loading ? (
        <CommonPageLoader />
      ) : (
        <div className="harvest-details w-100" id="myform">
          <BasicInfo
            memberData={memberData}
            handleMemberChange={handleMemberChange}
            stateOptions={stateOptions}
            districtOptions={districtOptions}
            validator={validator}
            fieldRef={fieldRef}
          />
          <LandDetails
            memberData={memberData}
            handleMemberChange={handleMemberChange}
            validator={validator}
            fieldRef={fieldRef}
          />
          <div>
            <h3 className="fs-20 fw-600 my-4">Crop Details</h3>
            <label>
              13. What crops do you generally grow?
              <span className="text-danger font-weight-bold">*</span>
            </label>
            <CropDetails
              cropData={cropData}
              handleCropChange={handleCropChange}
              handleRemoveClick={handleRemoveClick}
              showCropError={showCropError}
              cropCurrentIndex={cropCurrentIndex}
              handleAddClick={handleAddClick}
            />
            <label className="mt-3">
              14. Livestock
              <span className="text-danger font-weight-bold">*</span>
            </label>
            <LiveStockDetails
              liveStockData={liveStockData}
              handleLiveStockChange={handleLiveStockChange}
              liveStockCurrentIndex={liveStockCurrentIndex}
              handleRemoveClick={handleRemoveClick}
              showLiveStockError={showLiveStockError}
              handleAddClick={handleAddClick}
            />
          </div>
          <FarmingDetails
            selectedMachinery={selectedMachinery}
            setSelectedMachinery={setSelectedMachinery}
            memberData={memberData}
            handleMemberChange={handleMemberChange}
            selectedInsurance={selectedInsurance}
            setSelectedInsurance={setSelectedInsurance}
            selectedVehicle={selectedVehicle}
            setSelectedVehicle={setSelectedVehicle}
            validator={validator}
            fieldRef={fieldRef}
          />
          <div className="my-2 d-flex justify-content-end">
            <button
              className="add-button text-white bg-green"
              onClick={handleSubmit}
            >
              Submit form
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  categoryOptions: state.rm.categoryOptions,
  cropOptions: state.rm.cropOptions,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      allStateApi: allState,
      getDistrictApiwithStateApi: getDistrictApiwithState,
      getRMFPODataApi: getRMFPOData,
      getRMFPOMemberDataApi: getRMFPOMemberData,
      updateRMFPOMemberDataApi: updateRMFPOMemberData,
      addKYMNewApi: addKYMNew,
      getKYMFPODataApi: getKYMFPOData,
      getKYMModuleFPOMemberDataApi: getKYMModuleFPOMemberData,
      updateKYMModuleFPOMemberDataApi: updateKYMModuleFPOMemberData,
      addKYMModuleMemberApi: addKYMModuleMember,
      getMemberFPODataApi: getMemberFPOData,
      getMemberModuleFPOMemberDataApi: getMemberModuleFPOMemberData,
      addMemberModuleMemberApi: addMemberModuleMember,
      updateMemberModuleFPOMemberDataApi: updateMemberModuleFPOMemberData,
      getCropsCategoryApi: getCropsCategory,
      getCropsClassificationApi: getCropsClassification,
      getCropsCategoryReduxApi: getCropsCategoryRedux,
      getCropsClassificationReduxApi: getCropsClassificationRedux,
      getMember: getMember,
      getAboutusMemberDetail: getAboutusMemberDetail,
      memberBasicAdd: memberBasicAdd,
      memberEditData: memberEditData,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(MemberDetails));
