import { NormalButton, NormalInput2 } from 'component/common';
import React, { Component, useEffect, useState } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import { FieldsTemplate } from '../common/FieldsTemplate';
import { RadioGroup2 } from '../common/RadioGroup';
import { addComma } from '../common/utils';

export const FinancialRatios = (props) => {
    const [formData, setFormData] = useState({});
    const [, forceUpdate] = React.useState();

    const formAttr = [
        {
            label: "Whether the FPO incurred any loss during previous financial year?",
            field: "radioGroup", type: "number", name: "lossprevfinyr", required: true,
            list: [
                { value: "yes", label: "Yes" },
                { value: "no", label: "No" }
            ]
        },
        {
            label: "Total Long Term Loans (in Rs.)", field: "input", type: "text", alt: "totltloansStr", name: "totltloans", required: true, placeholder: "", note: "If data not available enter 0",
            errConditions: { criteria: "lte:999999999" }
        },
        {
            label: "Total Short Term Loans (in Rs.)", field: "input", type: "text", alt: "totstloansStr", name: "totstloans", required: true, placeholder: "", note: "if data not available enter 0",
            errConditions: { criteria: "lte:999999999" }
        },
        {
            label: "Total Current Assets (in Rs.)", field: "input", type: "text", alt: "totcurrassetsStr", name: "totcurrassets", required: true, placeholder: "", note: "if data not available enter 0",
            errConditions: { criteria: "lte:999999999" }
        },
        {
            label: "Total current Liabilities (in Rs.)", field: "input", type: "text", alt: "totcurrliabsStr", name: "totcurrliabs", required: true, placeholder: "", note: "if data not available enter 0",
            errConditions: { criteria: "lte:999999999" }
        },
        {
            label: "EBITDA for last financial year (in Rs.)", field: "input", type: "text", alt: "ebitdalastfinyrStr", name: "ebitdalastfinyr", required: true, placeholder: "", note: "if data not available enter 0",
            errConditions: { criteria: "lte:999999999" }
        },
        {
            label: "Reason for higher amount of debts as compared to earnings?",
            field: "radioGroup", type: "number", name: "rsnhighdebt", required: false,
            note: "This dialog applies only when there are higher amounts of debts as compared to earnings",
            // conditions: { attribute: "avgBODAttenPC", values: [33], action: "show", criteria: "ltneq", eqAttribute: ["ebitdalastfinyr", "totltloans", "totstloans"] },
            list: [
                { value: "captaldebt", label: "Debt taken for capital expenditure" },
                { value: "reducedsales", label: "Decline in sales" },
                { value: "highintrates", label: "High rates of interest" }
            ]
        },
        {
            label: "Revenue for the last financial year (in Rs.)", field: "input", type: "text", alt: "revforlastfinyrStr", name: "revforlastfinyr", required: true, placeholder: "", note: "if data not available enter 0",
            errConditions: { criteria: "lte:999999999" }
        },
        {
            label: "Revenue for previous year to the last financial year (in Rs.)", field: "input", type: "text", alt: "revforprevfinyrStr", name: "revforprevfinyr", required: true, placeholder: "", note: "if data not available enter 0",
            errConditions: { criteria: "lte:999999999" }
        },
        {
            label: "Reason for reduced revenue growth?",
            field: "radioGroup", type: "number", name: "rsn4reducrev", required: false,
            note: "This dialog applies only if revenue growth is less than 10%",
            conditions: { attribute: "revforprevfinyr", values: [10], action: "show", criteria: "lt" },
            list: [
                { value: "nobusplan1", label: "Lack of business plan" },
                { value: "lowyield", label: "Poor yield compared to last year" },
                { value: "nomktlink", label: "No Market linkages, transportation or storage for output" },
                { value: "lowipsale", label: "Low input sales" }
            ]
        },
    ];

    React.useEffect(() => {
        if (props?.surveyDetails) {
            let _formData = {
                lossprevfinyr: props?.surveyDetails.lossprevfinyr,
                totltloans: parseFloat(props?.surveyDetails.totltloans) || 0,
                totltloansStr: addComma(props?.surveyDetails.totltloans) || 0,
                totstloans: parseFloat(props?.surveyDetails.totstloans) || 0,
                totstloansStr: addComma(props?.surveyDetails.totstloans) || 0,
                totcurrassets: parseFloat(props?.surveyDetails.totcurrassets) || 0,
                totcurrassetsStr: addComma(props?.surveyDetails.totcurrassets) || 0,
                totcurrliabs: parseFloat(props?.surveyDetails.totcurrliabs) || 0,
                totcurrliabsStr: addComma(props?.surveyDetails.totcurrliabs) || 0,
                ebitdalastfinyr: parseFloat(props?.surveyDetails.ebitdalastfinyr) || 0,
                ebitdalastfinyrStr: addComma(props?.surveyDetails.ebitdalastfinyr) || 0,
                rsnhighdebt: props?.surveyDetails.rsnhighdebt,
                revforlastfinyr: parseFloat(props?.surveyDetails.revforlastfinyr) || 0,
                revforlastfinyrStr: addComma(props?.surveyDetails.revforlastfinyr) || 0,
                revforprevfinyr: parseFloat(props?.surveyDetails.revforprevfinyr) || 0,
                revforprevfinyrStr: addComma(props?.surveyDetails.revforprevfinyr) || 0,
            };

            setFormData({
                ...formData,
                ..._formData
            });
        }
    }, [props?.surveyDetails]);

    React.useEffect(() => {
        if (props?.submitSurveyClick) {
            handleSubmit('completed');
        }
    }, [props?.submitSurveyClick]);

    const handleInput = ({ target: { name, value } }) => {
        let data = {
            [name]: value,
        }
        if (["totltloans", "totstloans", "totcurrassets", "totcurrliabs", "ebitdalastfinyr", "revforlastfinyr", "revforprevfinyr"].includes(name)) {
            data[`${name}Str`] = addComma(value);
            let _value = value.replace(/,/g, '');
            data[`${name}`] = parseFloat(_value);
        }
        setFormData((preVal) => {
            return {
                ...preVal,
                ...data,
            }
        })
    };

    const handleRadio = (name, value) => {
        setFormData((preVal) => {
            return {
                ...preVal,
                [name]: value,
            }
        })
    }

    //validation
    const simpleValidator = React.useRef(new SimpleReactValidator({
        validators: {
            lte: {
                message: "Please enter a value lessthan 100.",
                rule: (val, params, validator) => {
                    return (
                        val <= parseInt(params[0])
                    );
                },
                messageReplace: (message, params) =>
                    message.replace("100", parseInt(params[0])),
                required: true,
            },
        },
        element: (message) => (
            <span className="error-message text-danger fs-14">{message}</span>
        ),
        autoForceUpdate: this
    }));

    const handleSubmit = (status) => {
        const formValid = simpleValidator.current.allValid();
        if (formValid) {
            let payload = { ...formData, status };
            props.updateSurvey("financialRatiosInfo", payload);

        } else {
            simpleValidator.current.showMessages()
            forceUpdate(1);
            props.errorAccordion();
        }
    }

    return (
        <div>
            <div className="container-sa position-ralative">
                {/* <h5>Technology and Services</h5> */}
                <FieldsTemplate
                    attributes={formAttr}
                    simpleValidator={simpleValidator}
                    stateAttr={formData}
                    handleInput={handleInput}
                    handleRadio={handleRadio}
                // handleCheckbox={handleCheckbox}
                />
                <div className="row mt-4">
                    <div className="col-md-5 col-12 col-sm-12 my-1">
                        <NormalButton
                            label="Save as draft"
                            mainbg={true}
                            className="px-3 py-2 mx-auto fs-14 line-height-26 font-Gilroy saveNext"
                            onClick={() => handleSubmit('draft')}
                            isPopup={true}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}