import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { history } from "../helpers";
import { CommonPageLoader } from "component/common";
import { getAccessdata, getToken } from "service/helperFunctions";
import { getAccessDetails } from "action/AuthAct";
import { bindActionCreators } from "redux";
import { logout } from "service/utilities";
const UserAuthComponent = (WrappedComponent, { service = "" }) => {
  class HOC extends React.Component {
    state = {
      isLoading: true,
      hasAccess: false,
    };

    componentDidMount() {
      let token = getToken();
      if (!token) history.push("/");

      if (token) {
        this.fetchUser(service);
      }
    }

    fetchUser = async (service = "") => {
      let { accessData } = this.props;
      if (accessData.length === 0) {
        let userRoleName = localStorage.getItem("userRoleName");
        if (
          userRoleName !== "Others Organization" &&
          userRoleName !== "Individual" &&
          userRoleName !== "Risk And Audit Team" &&
          userRoleName !== "Credit Team"
        ) {
          this.props.getAccessDetails().then((data) => {
            let permissionData = getAccessdata(data.service, service);

            let { viewAccess = false } = permissionData;
            this.setState({ isLoading: false, hasAccess: viewAccess }, () => {
              if (!this.state.hasAccess) {
                logout();
              }
            });
          });
        }
      } else {
        let permissionData = getAccessdata(accessData, service);

        let { viewAccess = false } = permissionData;
        this.setState({ isLoading: false, hasAccess: viewAccess }, () => {
          if (!this.state.hasAccess) {
            logout();
          }
        });
      }
    };

    render() {
      const { isLoading, hasAccess } = this.state;

      return isLoading ? (
        <CommonPageLoader />
      ) : (
        <>
          {hasAccess ? (
            <>
              <WrappedComponent {...this.props} />
            </>
          ) : null}
        </>
      );
    }
  }
  return HOC;
};

const mapStateToProps = (state) => ({
  accessData: state.home.accessData,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getAccessDetails,
    },
    dispatch
  );
};

const UserAuth = compose(
  connect(mapStateToProps, mapDispatchToProps),
  UserAuthComponent
);
export { UserAuth };
