import React, { Component } from "react";
import "./events_list.scss";

import Event from "./event";
import { history } from "service/helpers";
export class EventsList extends Component {
  render() {
    let { newsList, eventList } = this.props;
    return (
      <>
        {localStorage.getItem("AuthToken") ? (
          <div className="background-events-list px-3">
            <div className="row mb-5">
              <div className="col-12 d-flex align-items-center">
                <div className="col-5 text-gold post-news-title font-weight-bold h3 mb-0">
                  News {"&"} Events
                </div>
                <div className="line-horizontal col-5 align-middle"></div>
                <div className="col-2 text-right">
                  <label
                    className=" text-gold gold-a fw-30"
                    onClick={() => {
                      history.push(
                        localStorage.getItem("userGroupName") !== "samunnati" &&
                          localStorage.getItem("userGroupName") !== "fpo"
                          ? `/other/contents/newsandevents`
                          : localStorage.getItem("userRoleName") ===
                            "Relationship Manager"
                          ? `/rm/contents/newsandevents`
                          : `/fpo/contents/newsandevents`
                      );
                    }}
                  >
                    View all
                  </label>
                </div>
              </div>
            </div>
            <div>
              <Event newsList={newsList} eventList={eventList} auth={true} />
            </div>
          </div>
        ) : (
          <div className="background-events-list px-3 position-relative pt-4">
            <div className="news-title">{"News & Events"}</div>
            <div>
              <Event newsList={newsList} eventList={eventList} auth={false} />
            </div>
          </div>
        )}
      </>
    );
  }
}
