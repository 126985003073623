import React, { Component } from "react";
import { Navbar } from "../../component/common";
import { MembersTab, MembersView } from "../../component/Members";
import { connect } from "react-redux";
import { getAccessdata } from "service/helperFunctions";
import { UserAuth } from "helpers/UserAuth";
export class MembersClass extends Component {
  componentDidMount = () => {
    window.scrollTo({
      top: 1,
      behavior: "smooth",
    });
  };
  render() {
    let { accessData } = this.props;
    let permissionData = getAccessdata(accessData, "membership");

    let {
      download = false,
      edit = false,
      sms = false,
      viewAccess = false,
      createAccess = false,
    } = permissionData;

    return (
      <div>
        <div className="inner-header">
          {/* <Navbar /> */}
          <div className="h-100 w-100">
            <p className="d-flex justify-content-center align-items-center h-100 w-100 text-white fs-32 font-weight-bold">
              Member Engagement
            </p>
          </div>
        </div>
        <MembersTab
          createAccess={createAccess}
          id={this.props.match.params.id}
          match={this.props.match}
        />
        <MembersView
          download={download}
          edit={edit}
          sms={sms}
          viewAccess={viewAccess}
          createAccess={createAccess}
          id={this.props.match.params.id}
          match={this.props.match}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  accessData: state.home.accessData,
});

let component = UserAuth(MembersClass, { service: "membership" });

export const Members = connect(mapStateToProps, null)(component);
