import { NormalButton } from 'component/common';
import React, { Component } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import { FieldsTemplate } from '../common/FieldsTemplate';

export const Compliances = (props) => {

  const [, forceUpdate] = React.useState();
  const [compliances, setCompliances] = React.useState({
    licenses: []
  });
  const [compliancesAttr, setCompliancesAttr] = React.useState([
    {
      label: "Which are the licenses and compliances possessed by the FPO?", field: "checkboxGroup", type: "number", name: "licenses", required: true,
      list: [

      ]
    },
    {
      label: "Mention if others", field: "input", type: "text", name: "licenseothers", required: false,
      conditions: { attribute: "licenses", values: [16], action: "show" },
    },

    {
      label: "Are all records like legal certificates, Licenses displayed in FPCs office?", field: "radioGroup", type: "number", name: "alldocsinfpooffc", required: true,
      list: [
        { label: "Yes, always", value: "yesalways" },
        { label: "Yes, maximum of times", value: "yesmaxtimes" },
        { label: "Rarely", value: "rarely" },
        { label: "only when there is inspection by authority", value: "onlyatinspection" },
        { label: "Not at all", value: "notatall" },
      ]
    },

    {
      label: "How are internal audits conducted?", field: "radioGroup", type: "number", name: "internauditproc", required: true,
      list: [
        { label: "Fortnightly", value: "audfortnightly" },
        { label: "Quarterly", value: "audquarterly" },
        { label: "Biannually", value: "audbiannually" },
        { label: "Annually", value: "audannually" },
        { label: "Biennially", value: "audbiennially" },
        { label: "Once in more than 2 years", value: "audmore2years" },
        { label: "FPO is less than one year old hence audit is not conducted", value: "oneyearless" },
        { label: "No Internal audits", value: "nointernalaudits" },
      ]
    },

    {
      label: "Why is there no periodic conduct of internal audits?", field: "radioGroup", type: "number", name: "whylowaudits", required: true,
      conditions: { attribute: "internauditproc", values: ["audmore2years", "audbiennially", "audannually"], action: "show" },
      list: [
        { label: "Lack of Awareness", value: "notaware" },
        { label: "FPO feels it to be a complex Process", value: "feelscomplex" },
        { label: "Lack of Proper staff to carry out the accounting related activities", value: "nopropstaff" },
        { label: "Lack of money to spend in these activities", value: "nomoney" },
      ]
    },

    {
      label: "How are external audits conducted?", field: "radioGroup", type: "number", name: "extauditproc", required: true,
      list: [
        { label: ">Annually with full implementation of corrections", value: "beyondannual" },
        { label: "Annually with full implementation of correction", value: "annualfull" },
        { label: "Annually with partial implementation of corrections", value: "annualpartial" },
        { label: "Biennially with full implementation of corrections", value: "bienneialfull" },
        { label: "Annually without implementation of corrections", value: "annualnocorrect" },
        { label: "FPO is less than one year old hence audit is not conducted", value: "oneyearlesse" },
        { label: "No External audits", value: "noexternalaudits" },
      ]
    },

    {
      label: "Do you issue share certificate to the members?", field: "radioGroup", type: "number", name: "sharecerttomem", required: true,
      list: [
        { label: "Yes", value: "yes" },
        { label: "No", value: "no" },
      ]
    },

    {
      label: "Do you issue payment vouchers?", field: "radioGroup", type: "number", name: "payvouch", required: true,
      list: [
        { label: "Yes", value: "yes" },
        { label: "No", value: "no" },
      ]
    },
    {
      label: "Do you issue Receipt vouchers?", field: "radioGroup", type: "number", name: "recptvouch", required: true,
      list: [
        { label: "Yes", value: "yes" },
        { label: "No", value: "no" },
      ]
    },
    { label: "What is the percentage of insurance coverage (crop/ livestock/ life/ health)?", field: "input", type: "number", name: "inscoverPC", note: "Example: if value is 55.5 % enter 55.5 (Enter 0 if data is not available)", required: true },
  ]);

  React.useEffect(() => {
    if (props?.surveyDetails) {
      let _compliances = {
        alldocsinfpooffc: props?.surveyDetails.alldocsinfpooffc,
        internauditproc: props?.surveyDetails.internauditproc,
        whylowaudits: props?.surveyDetails.whylowaudits,
        extauditproc: props?.surveyDetails.extauditproc,
        sharecerttomem: props?.surveyDetails.sharecerttomem,
        payvouch: props?.surveyDetails.payvouch,
        recptvouch: props?.surveyDetails.recptvouch,
        inscoverPC: props?.surveyDetails.inscoverPC,
      };
      if (props?.surveyDetails?.licenses) {
        _compliances.licenses = props?.surveyDetails?.licenses;
      }

      //
      setCompliances({
        ...compliances,
        ..._compliances
      });
    }
  }, [props?.surveyDetails]);

  React.useEffect(() => {
    if (props?.submitSurveyClick) {
      handleSubmit('completed');
    }
  }, [props?.submitSurveyClick]);

  //validation
  const simpleValidator = React.useRef(new SimpleReactValidator({
    element: (message) => (
      <span className="error-message text-danger fs-14">{message}</span>
    ),
    autoForceUpdate: this
  }));
  const handleSubmit = (status) => {
    const formValid = simpleValidator.current.allValid();
    if (formValid) {
      let payload = { ...compliances, status };
      props.updateSurvey("compliances", payload);

    } else {
      simpleValidator.current.showMessages()
      forceUpdate(1);
      props.errorAccordion();
    }
  }
  const handleInput = ({ target: { name, value } }) => {
    setCompliances({
      ...compliances,
      [name]: value,
    });
  };
  const handleCheckbox = (name, value) => {
    let list = [...compliances[name]];
    let index = list.indexOf(value);
    if (index >= 0) {
      list.splice(index, 1);
    } else {
      list.push(value);
    }
    setCompliances({
      ...compliances,
      [name]: list,
    });
  };

  const handleRadio = (name, value) => {
    setCompliances({
      ...compliances,
      [name]: value,
    });
  };


  return (
    <div >
      <div className="container-sa position-relative" >

        <FieldsTemplate
          attributes={compliancesAttr}
          simpleValidator={simpleValidator}
          stateAttr={compliances}
          handleInput={handleInput}
          handleRadio={handleRadio}
          handleCheckbox={handleCheckbox}
        />

        <div className="row mt-4">
          <div className="col-md-5 col-sm-12  my-1">
            <NormalButton
              label="Save & Next"
              mainbg={true}
              className="px-3 py-2 mx-auto fs-14 line-height-26 font-Gilroy saveNext"
              onClick={() => handleSubmit('draft')}
              isPopup={true}
            />
          </div>
        </div>
      </div>
    </div>
  )
}