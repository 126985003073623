import React, { Component } from "react";

import { ResetPassword } from "../../component/auth/login/index";
const Logo = require("../../assets/svg/logo.svg");
export class ResetPass extends Component {
  render() {
    return (
      <>
        <form>
          <div className=" d-flex align-items-center flex-column">
            <img className="w-50 mb-4 " src={Logo} alt="Logo" height="75" />
            <ResetPassword
              token={new URLSearchParams(this.props.location.search).get(
                "token"
              )}
            />
          </div>
        </form>
      </>
    );
  }
}
