import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { letterAvatar, getDescriptionData } from "service/helperFunctions";
import { CreateForum } from "component/Acadamy";
import { getCategoryDetails } from "action/forum";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from "swiper";
import arrow_left from "assets/svg/Events/AllEvents/arrow-left.svg";
import arrow_right from "assets/svg/Events/AllEvents/arrow-right.svg";

export class QuestionLinkClass extends Component {
  constructor() {
    super();
    SwiperCore.use([Navigation]);
    this.state = {
      isCreateModal: false,
      categoryData: [],
    };
  }

  //handleCreateModalToggle modal window
  handleCreateModalToggle = () => {
    let { isCreateModal } = this.state;
    this.setState({ isCreateModal: !isCreateModal });
  };

  componentDidMount() {
    let { getCategoryDetails } = this.props;
    getCategoryDetails().then(({ data }) => {
      this.setState({ categoryData: data });
    });
  }

  render() {
    const params = {
      slidesPerView: 3,
      spaceBetween: 20,
      breakpoints: {
        500: {
          slidesPerView: 2,
        },
        768: {
          slidesPerView: 3,
        },
      },
      navigation: {
        nextEl: "#academy-next",
        prevEl: "#academy-prev",
      },
    };

    let { filteredValue } = this.props;

    let { isCreateModal, categoryData } = this.state;

    let userName = localStorage.getItem("userName");

    return (
      <div className="question-link">
        <div
          className="ask-question cursor-pointer"
          onClick={this.handleCreateModalToggle}
        >
          <div className="row">
            <div className="col-md-6">
              <div className="d-flex align-items-center user-profile">
                <img
                  className="mr-3"
                  src={letterAvatar(userName ? userName : "", 49)}
                  alt="avatar"
                />
                <div className="media-body">
                  <h5>{userName ? userName : ""}</h5>
                </div>
              </div>
            </div>
            <div className="col-md-6 d-flex justify-content-end align-items-center">
              <p className="text-black d-flex fs-16 line-height-26 mb-0 align-items-center">
                <i className="icon-link mr-2"></i>Share a link
              </p>
              <button type="button" className="btn post-btn ml-3">
                Post
              </button>
            </div>
            <div className="col-md-12 mt-4">
              <p className="question-title mb-0">Post your question</p>
            </div>
          </div>
        </div>
        {categoryData.length !== 0 ? (
          <div className="row mb-5">
            <div className="d-flex justify-content-end px-3 pb-3 w-100">
              {filteredValue !== "" && filteredValue ? (
                <div
                  className="text-danger fs-16 fw-600 pr-3 cursor-pointer"
                  onClick={() => this.props.handleCategoryFilter("")}
                >
                  Remove filter
                </div>
              ) : null}
              <input
                type="image"
                src={arrow_left}
                alt="left"
                width="30"
                height="30"
                id="academy-prev"
              />
              <input
                type="image"
                src={arrow_right}
                alt="left"
                width="30"
                height="30"
                id="academy-next"
              />
            </div>
            <Swiper {...params}>
              {categoryData.map((list, index) => {
                let {
                  categoryImage = "",
                  name = "",
                  forumCount = 0,
                  id = "",
                } = list;
                return (
                  <SwiperSlide key={index}>
                    <div
                      className="card question-cards mx-auto cursor-pointer"
                      onClick={() => this.props.handleCategoryFilter(id)}
                    >
                      <img
                        className="card-img"
                        src={categoryImage}
                        alt="avatar"
                      />
                      <div className="card-img-overlay">
                        <div className="card-details">
                          <h5 className="fs-22 line-height-29 fw-600 text-white">
                            {getDescriptionData(name, 30)}
                          </h5>
                          <p className="mb-0 text-white line-height-24">
                            Post : {forumCount}
                          </p>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        ) : null}
        <CreateForum
          show={isCreateModal}
          handleCreateModalToggle={this.handleCreateModalToggle}
          handleReload={this.props.handleReload}
          myForum={this.props.myForum}
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getCategoryDetails,
    },
    dispatch
  );
};

let component = QuestionLinkClass;

export const QuestionLink = connect(null, mapDispatchToProps)(component);
