import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { QuestionLink } from "../questionLink";
import { ForumCard } from "../forumCard";
import { PopularTopics } from "../popularTopics";
import { getPopularTopics } from "action/forum";
import "./discussionForm.scss";
export class DiscussionForumClass extends Component {
  state = {
    popularList: [],
    forumId: "",
    reload: false,
  };

  //Lifecycles

  componentDidMount() {
    let { getPopularTopics } = this.props;

    getPopularTopics().then(({ data }) => {
      this.setState({ popularList: data });
    });
  }

  handleForumId = (id) => {
    this.setState({
      forumId: id,
    });
  };
  handleReload = () => {
    this.setState({
      reload: !this.state.reload,
    });
  };
  render() {
    let { popularList, forumId } = this.state;
    let { filteredValue, handleCategoryFilter } = this.props;
    return (
      <div className="row">
        <div className="col-md-12 col-xs-12">
          <QuestionLink
            handleCategoryFilter={handleCategoryFilter}
            handleReload={this.handleReload}
            filteredValue={filteredValue}
          />
        </div>
        <div className="col-md-8 col-xs-12">
          <ForumCard
            filteredValue={filteredValue}
            forumId={forumId}
            reload={this.state.reload}
            handleReload={this.handleReload}
            myForum={false}
          />
        </div>
        <div className="col-md-4 col-xs-12">
          {popularList.length !== 0 ? (
            <PopularTopics
              popularList={popularList}
              handleForumId={this.handleForumId}
            />
          ) : null}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getPopularTopics,
    },
    dispatch
  );
};

let component = DiscussionForumClass;

export const DiscussionForum = connect(null, mapDispatchToProps)(component);
