import React, { Component } from 'react';

export const CheckboxGroup = (props) => {
  return (
    <div className="form-group normal-checkbox-group">
      <label className='grading-normal-label'>
        <b>{props.title}</b>
        {
          (props?.required) && (
            <span className="text-danger font-weight-bold pl-2 fs-18">
              {"*"}
            </span>
          )
        }
      </label> <br />
      {
        props.groupList.map((obj, key) => {
          return (
            <div key={key} className="d-flex">
              <input
                className='mt-1'
                key={obj.value}
                type="checkbox"
                name={props.name}
                checked={props.selValue.indexOf(obj.value) >= 0}
                value={obj.value} onChange={() => props.onChange(obj.value)} />
              <span className='pl-2'>{obj.label}</span>

            </div>
          );
        })
      }
    </div>
  )
}