import React, { useState, useEffect } from "react";
import downloadIcon from "assets/images/icon/download-icon.svg";
import filterIcon from "assets/images/icon/filter.svg";
import { NormalButton } from "component/common";
import FIGMemberListStripView from "./StripView";
import FIGMemberListGridView from "./GridView";
import "./FIGHome.scss";

const FIG = () => {
  const [isStripView, setStripView] = useState(true);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div className="fig-member">
      <div className="inner-header">
        <div className="h-100 w-100">
          <p className="d-flex justify-content-center align-items-center h-100 w-100 text-white fs-32 font-weight-bold">
            Member Engagement
          </p>
        </div>
      </div>
      <div className="container-sa mt-5 mb-5">
        <div className="search-limit mb-3">
          <div className="row align-items-center">
            <div className="col-md-4"></div>
            <div className="col-md-3 d-flex justify-content-md-end justify-content-center">
              <NormalButton
                label="Robo Calling"
                mainbg={true}
                isPopup={true}
                className={`px-3 py-2 fs-14 line-height-26`}
              />
              <NormalButton
                label="Send SMS"
                mainbg={true}
                isPopup={true}
                className={`px-3 py-2 fs-14 line-height-26 ml-3 w-100`}
              />
            </div>
            <div className="col-md-2 text-center px-0 mt-md-0 mt-2">
              show
              <select className="form-control-sa ml-2 py-2 px-4">
                <option value="10">10</option>
                <option value="20">20</option>
              </select>
            </div>
            <div className="col-md-3 mt-md-0 mt-3">
              <input
                type="text"
                className="form-control form-control-sa"
                placeholder="Search"
              />
            </div>
          </div>
        </div>
        <ul className="right-filter-align d-flex align-items-center menu justify-content-end">
          <li className="cursor-pointer filter-dropdown">
            <img src={filterIcon} alt="filter" />
            <span className="fs-16 line-height-19 text-green-18 ml-2">
              Filter
            </span>
          </li>
          <li>
            <p
              id="csv-download"
              className="text-decoration-none cursor-pointer text-green-18 mb-0"
            >
              <img src={downloadIcon} className="mr-2" alt="download" />
              Download list
            </p>
          </li>
          <li>
            <span
              onClick={() => setStripView(true)}
              className={`icon-list-view mr-3 fs-20 ${
                isStripView ? "view-active" : "view"
              }`}
            />
            <span
              onClick={() => setStripView(false)}
              className={`icon-grid-view mr-3 fs-20 ${
                !isStripView ? "view-active" : "view"
              }`}
            />
          </li>
        </ul>
        {isStripView ? <FIGMemberListStripView /> : <FIGMemberListGridView />}
      </div>
    </div>
  );
};

export default FIG;
