import React, { Component } from "react";
import { CommonPageLoader } from "component/common";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "./style.scss";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { getkeyfinanciallistdata } from "action/KeyFinancialAct";
import { formatToINR } from "service/helperFunctions";
import { getkeyfinanciallistdataById } from "action/RmAct";
export default class KeyFinancialsTabClass extends Component {
  state = {
    isLoading: false,
    finanacialData: [],
  };

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      if (this.props.activeTab == 5 && this.props.financialsTabs == 1) {
        this._getkeyfinanciallistdata();
      }
    }
  }

  _getkeyfinanciallistdata = () => {
    let { id, getkeyfinanciallistdata, getkeyfinanciallistdataById } =
      this.props;
    this.setState({ isLoading: true });
    if (id) {
      getkeyfinanciallistdataById(id).then((data) => {
        this._setChartData(data);
      });
    } else {
      getkeyfinanciallistdata().then((data) => {
        this._setChartData(data);
      });
    }
  };

  _setChartData = (data) => {
    let finanacialDataList = [];
    data.map((list) => {
      if (list.keyName === "totalRevenues") {
        let tabledata = this.setoptionData(list, "Total Revenue", "Revenue");
        finanacialDataList.push(tabledata);
      } else if (list.keyName === "compositionOfLiabilities") {
        let tabledata = this.setoptionDataWithTwoSeries(
          list,
          "Composition of Liabilities",
          "Current Liabilities",
          "Non-Current Liabilities",
          "liablilities"
        );
        finanacialDataList.push(tabledata);
      } else if (list.keyName === "expensesAndTaxes") {
        let tabledata = this.setoptionData(
          list,
          "Expenses & Taxes",
          "Expenses & Taxes"
        );
        finanacialDataList.push(tabledata);
      } else if (list.keyName === "shareHolderFund") {
        let tabledata = this.setoptionData(
          list,
          "Shareholder’s Funds",
          "Funds"
        );
        finanacialDataList.push(tabledata);
      } else if (list.keyName === "profitAfterTax") {
        let tabledata = this.setoptionData(
          list,
          "Profit after tax",
          "Profit after tax"
        );
        finanacialDataList.push(tabledata);
      } else if (list.keyName === "compositionOfAssets") {
        let tabledata = this.setoptionDataWithTwoSeries(
          list,
          "Composition of Assets",
          "Current Assets",
          "Non-Current Assets",
          "assetsChart"
        );
        finanacialDataList.push(tabledata);
      }
    });
    this.setState({ finanacialData: finanacialDataList, isLoading: false });
    if (finanacialDataList.length == 6) {
    }
  };

  setoptionData = (data, title, legendName) => {
    let optionsData = {
      chart: {
        type: "column",
        height: 300,
      },
      title: {
        text: "",
      },
      xAxis: {
        categories: [],
      },
      yAxis: {
        min: 0,
        title: {
          text: "",
        },
        labels: {
          formatter: function () {
            var val = Math.round(this.value);
            if (val >= 1000000000) {
              val = formatToINR(val / 10000000) + " M";
            } else if (val >= 10000000) {
              val = formatToINR(val / 10000000) + " C";
            } else if (val >= 100000) {
              val = formatToINR(val / 100000) + " L";
            } else if (val >= 1000) {
              val = formatToINR(val / 1000) + " K";
            }
            return val;
          },
        },
      },
      tooltip: {
        pointFormat:
          '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b><br/>',
        shared: true,
      },
      plotOptions: {
        column: {
          stacking: "normal",
        },
      },
      credits: {
        enabled: false,
      },
      series: [
        {
          name: "",
          data: [],
          borderRadius: 4,
          pointWidth: 15,
          color: "#01934C",
        },
      ],
    };

    data.tableData.map((list) => {
      optionsData.xAxis.categories.push(list.year);
      optionsData.series[0].data.push(parseInt(list.value));
      optionsData.series[0].name = legendName;
    });
    let body = {
      chartTitle: title,
      options: optionsData,
    };
    return body;
  };

  setoptionDataWithTwoSeries = (
    data,
    title,
    legendName1,
    legendName2,
    chartName
  ) => {
    let optionsData = {
      chart: {
        type: "column",
        height: 300,
      },
      title: {
        text: "",
      },
      xAxis: {
        categories: [],
      },
      yAxis: {
        min: 0,
        title: {
          text: "",
        },
        labels: {
          formatter: function () {
            var val = Math.round(this.value);
            if (val >= 1000000000) {
              val = formatToINR(val / 10000000) + " M";
            } else if (val >= 10000000) {
              val = formatToINR(val / 10000000) + " C";
            } else if (val >= 100000) {
              val = formatToINR(val / 100000) + " L";
            } else if (val >= 1000) {
              val = formatToINR(val / 1000) + " K";
            }
            return val;
          },
        },
      },
      tooltip: {
        pointFormat:
          '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b><br/>',
        shared: true,
      },
      plotOptions: {
        column: {
          stacking: "normal",
        },
      },
      credits: {
        enabled: false,
      },
      series: [
        {
          name: "",
          data: [],
          borderRadius: 4,
          pointWidth: 15,
          color: "#01934C",
        },
        {
          name: "",
          data: [],
          borderRadius: 4,
          pointWidth: 15,
          color: "#F58D45",
        },
      ],
    };
    if (chartName === "assetsChart") {
      data.currentAssets.map((list) => {
        optionsData.xAxis.categories.push(list.year);
        optionsData.series[0].data.push(parseInt(list.value));
        optionsData.series[0].name = legendName1;
      });
      data.nonCurrentAssets.map((list) => {
        optionsData.series[1].data.push(parseInt(list.value));
        optionsData.series[1].name = legendName2;
      });
    } else {
      data.currentLiabilities.map((list) => {
        optionsData.xAxis.categories.push(list.year);
        optionsData.series[0].data.push(parseInt(list.value));
        optionsData.series[0].name = legendName1;
      });
      data.nonCurrentLiabilities.map((list) => {
        optionsData.series[1].data.push(parseInt(list.value));
        optionsData.series[1].name = legendName2;
      });
    }

    let body = {
      chartTitle: title,
      options: optionsData,
    };
    return body;
  };

  render() {
    let { finanacialData, isLoading } = this.state;
    return (
      <div className="key-financial-tab pt-2">
        {!isLoading && finanacialData.length === 0 ? (
          <p className="py-4 mb-0 text-black-50 fs-22 line-height-25 text-center">
            {"No data found"}
          </p>
        ) : !isLoading ? (
          <>
            {finanacialData.length != 0 ? (
              <div className="d-flex container-sa flex-column  flex-wrap mt-3 px-0 justify-content-center">
                <div className="row graph-list-card">
                  {finanacialData.map((list, index) => {
                    let { options = {}, chartTitle = "" } = list;
                    return (
                      <div className="col-4 mb-3" key={index}>
                        <div className="chart-card">
                          {options.series.length !== 0 ? (
                            <>
                              <div className="chart-header">
                                <h3 className="mb-4 text-black fs-18 fw-600 text-center">
                                  {chartTitle}
                                </h3>
                              </div>
                              <HighchartsReact
                                highcharts={Highcharts}
                                options={options}
                              />
                            </>
                          ) : (
                            <p className="py-5 my-5 text-black-50 fs-22 line-height-25 text-center">
                              No data found !!!
                            </p>
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : (
              <p className="py-4 mb-0 text-black-50 fs-22 line-height-25 text-center">
                {"No data found"}
              </p>
            )}
          </>
        ) : (
          <>
            {/* Loader */}
            <CommonPageLoader />
          </>
        )}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getkeyfinanciallistdata,
      getkeyfinanciallistdataById,
    },
    dispatch
  );
};

let component = KeyFinancialsTabClass;

export const KeyFinancialsTab = connect(null, mapDispatchToProps)(component);
