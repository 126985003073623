import React, { useEffect, useState } from "react";
import ReactHtmlParser from "react-html-parser";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { history } from "service/helpers";
import { getAllReportsById } from "action/contentAct";
import "./style.scss";
import { bindActionCreators } from "redux";
const arrow_left = require("assets/svg/add_member/arrow_left.svg");

const Report = (props) => {
  const { id } = useParams();
  const [reportDetails, setReportDetails] = useState(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    props.getAllReportsById(id).then((res) => {
      setReportDetails(res);
    });
  }, []);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="row reportContainer" id="reportContainer">
      <div className="col-lg-12 col-12 pt-5 mt-5">
        <div className="container-sa">
          <div className="news-events-tab pb-sm-3 pb-1 pt-sm-3 pt-1">
            <div>
              <div className="d-flex my-3">
                <div
                  onClick={() => history.goBack()}
                  className="d-flex cursor-pointer"
                >
                  <img className="cursor-pointer" src={arrow_left} alt="back" />
                  <p className="ml-3 add-member-text-back mb-0">
                    {"All Reports"}
                  </p>
                </div>
              </div>
              <p className="mb-0 fs-36 fw-800">{reportDetails?.reportTitle}</p>
              <img
                src={reportDetails?.uploadImage}
                className="img-fluid pt-2 reportImgClass"
                alt="img"
              />
              <p className="pt-3 mb-0">
                {ReactHtmlParser(reportDetails?.description)}
              </p>
              {reportDetails?.reportType === "text" && (
                <p className="mb-0">
                  {ReactHtmlParser(reportDetails?.content)}
                </p>
              )}
              {reportDetails?.reportType === "document" && (
                <div className="pt-5">
                  {windowWidth <= 768 ? (
                    <object
                      type="application/pdf"
                      data={`${reportDetails?.uploadDoc}#toolbar=0&view=FitH`}
                      title="pdf"
                      height={"600px"}
                      width={"100%"}
                    />
                  ) : (
                    <iframe
                      className="border-0"
                      src={`${reportDetails?.uploadDoc}#toolbar=0&view=FitH`}
                      title="pdf"
                      height={"600px"}
                      width={"100%"}
                      id="myIframe"
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getAllReportsById,
    },
    dispatch
  );
};

export const Reports = connect(null, mapDispatchToProps)(Report);
