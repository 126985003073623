import React, { Component } from "react";
import {
  CustomSelect,
  DragFileUpload,
  NormalButton,
  NormalEditor,
} from "component/common";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import pdfImage from "assets/svg/Documents/pdf.svg";
// Redux Connection
import { connect } from "react-redux";

export class EventClass extends Component {
  constructor(props) {
    super(props);
    this.state = {
      eventDocs: [],
      eventStatus: "completed",
    };
  }

  componentDidMount() {
    let { eventObj = {} } = this.props;
    let { eventDocs = [] } = eventObj;
    this.setState({ eventDocs });
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps != this.props) {
      this.setState({
        eventDocs:
          this.props.eventObj && this.props.eventObj.eventDocs
            ? this.props.eventObj.eventDocs
            : [],
        eventStatus:
          this.props.eventObj && this.props.eventObj.eventStatus
            ? this.props.eventObj.eventStatus
            : "completed",
      });
    }
  };

  handleRadio = (value) => {
    this.setState(
      {
        eventStatus: value,
      },
      () => {
        this.props.handleRadio(value);
      }
    );
  };

  render() {
    let {
      validator,
      eventObj,
      handleInputChange,
      handleDateChange,
      handleFileUpload,
      isLoderFileUpload,
      agendaDataList,
      eventId,
      onEditorChange,
      uploadCoverImg,
      removeCoverImg,
      removepostImage,
    } = this.props;
    let { eventDocs, eventStatus } = this.state;
    return (
      <div className="create-events">
        <div className="d-flex flex-row mb-3">
          {eventId && eventObj.eventStatus === "completed" ? (
            <div className="custom-control custom-radio d-flex align-items-center">
              <input
                type="radio"
                id="completed"
                name="eventStatus"
                value="completed"
                checked={eventStatus === "completed"}
                onChange={() => this.handleRadio("completed")}
              />
              <label className="font-weight-bold pl-1 mb-0" htmlFor="completed">
                {"Past events"}
              </label>
            </div>
          ) : !eventId ? (
            <div className="custom-control custom-radio d-flex align-items-center">
              <input
                type="radio"
                id="completed"
                name="eventStatus"
                value="completed"
                checked={eventStatus === "completed"}
                onChange={() => this.handleRadio("completed")}
              />
              <label className="font-weight-bold pl-1 mb-0" htmlFor="completed">
                {"Past events"}
              </label>
            </div>
          ) : null}
          {eventId && eventObj.eventStatus === "upcoming" ? (
            <div className="custom-control custom-radio d-flex align-items-center">
              <input
                type="radio"
                id="upcoming"
                name="eventStatus"
                value="upcoming"
                checked={eventStatus === "upcoming"}
                onChange={() => this.handleRadio("upcoming")}
              />
              <label className="font-weight-bold pl-1 mb-0" htmlFor="upcoming">
                {"Upcoming events"}
              </label>
            </div>
          ) : !eventId ? (
            <div className="custom-control custom-radio d-flex align-items-center">
              <input
                type="radio"
                id="upcoming"
                name="eventStatus"
                value="upcoming"
                checked={eventStatus === "upcoming"}
                onChange={() => this.handleRadio("upcoming")}
              />
              <label className="font-weight-bold pl-1 mb-0" htmlFor="upcoming">
                {"Upcoming events"}
              </label>
            </div>
          ) : null}
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label>
                Title of the event
                <span className="text-danger font-weight-bold pl-2 fs-18">
                  *
                </span>
              </label>
              <input
                className="form-control"
                value={eventObj.eventTitle}
                name="eventTitle"
                onChange={handleInputChange}
              />
              {validator.message(
                "event title",
                eventObj.eventTitle,
                "required|max:50|alpha_space"
              )}
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label>
                Event date
                <span className="text-danger font-weight-bold pl-2 fs-18">
                  *
                </span>
              </label>
              <div className="input-group">
                <DatePicker
                  selected={eventObj.eventDate}
                  className="form-control border-right-0"
                  onChange={handleDateChange}
                  maxDate={
                    eventStatus === "completed" &&
                    new Date().setDate(new Date().getDate() - 1)
                  }
                  minDate={eventStatus === "upcoming" && new Date()}
                  showMonthDropdown={true}
                  showYearDropdown={true}
                />
                <div className="input-group-append">
                  <span className="input-group-text" id="basic-addon2">
                    <img src="/icon/calendar.svg" alt="Girl in a jacket" />
                  </span>
                </div>
              </div>
              {validator.message("Event date", eventObj.eventDate, "required")}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label>
                Location
                <span className="text-danger font-weight-bold pl-2 fs-18">
                  *
                </span>
              </label>
              <input
                className="form-control"
                value={eventObj.location}
                name="location"
                onChange={handleInputChange}
              />
              {validator.message(
                "Location",
                eventObj.location,
                "required|max:30|alpha_space"
              )}
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label>
                Agenda
                <span className="text-danger font-weight-bold pl-2 fs-18">
                  *
                </span>
              </label>
              <CustomSelect
                value={eventObj.agenda}
                options={agendaDataList}
                name="agenda"
                onChange={handleInputChange}
              />
              {validator.message("Agenda", eventObj.agenda, "required")}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label>
                Organizer
                <span className="text-danger font-weight-bold pl-2 fs-18">
                  *
                </span>
              </label>
              <input
                className="form-control"
                value={eventObj.organizer}
                name="organizer"
                onChange={handleInputChange}
              />
              {validator.message(
                "Organizer",
                eventObj.organizer,
                "required|max:50|alpha_space"
              )}
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label>Supporting link</label>
              <input
                className="form-control"
                value={eventObj.supportingLink}
                name="supportingLink"
                onChange={handleInputChange}
              />
              {validator.message(
                "supportingLink",
                eventObj.supportingLink,
                "required"
              )}
            </div>
          </div>
          <div className="col-md-6 mb-3">
            <label className="event-cover">
              Upload Cover Image{" "}
              <span className="text-danger font-weight-bold pl-2 fs-18">*</span>
            </label>
            <div className="d-flex align-items-center w-100 btn-file-input">
              <button className="mr-2">
                <input
                  type="file"
                  accept=".jpg, .png, .jpeg"
                  onChange={(e) => uploadCoverImg(e, "eventDocs")}
                  id="coverImg"
                />
                Upload Image
              </button>
              {validator.message(
                "cover image",
                eventObj.eventDocs[0]?.fileName,
                "required"
              )}
              {eventObj.eventDocs.length != 0 && (
                <div className="pl-3 position-relative">
                  <img
                    src={eventObj.eventDocs[0]?.documentUrl}
                    alt=""
                    height="75"
                    width="75"
                  />
                  <i
                    className="icon-Delete text-danger fs-14 pl-2 cursor-pointer position-absolute"
                    onClick={() => removeCoverImg("eventDocs")}
                  ></i>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 mb-4">
            <NormalEditor
              label="Description"
              data={eventObj.description}
              onChange={onEditorChange}
              required={true}
            />
            {validator.message("Description", eventObj.description, "required")}
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label>Supporting Images</label>
              <DragFileUpload
                className="form-control drag-drop d-flex align-items-center"
                value={eventObj.eventImages}
                name="imageURL"
                acceptFileFormat={["jpg", "jpeg", "png"]}
                children={
                  <HelloMessage isLoderFileUpload={isLoderFileUpload} />
                }
                handleFileUpload={(e) => handleFileUpload(e, "eventImages")}
              />
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-group">
              <div className="image-upload-area">
                {eventObj.eventImages.length !== 0 &&
                  eventObj.eventImages.map((list, index) => (
                    <div className="position-relative" key={index}>
                      <img
                        src={list.documentUrl}
                        alt="..."
                        className="image-upload position-relative"
                      />
                      <div className="close-icon">
                        <span
                          className="icon-close fs-10 cursor-pointer"
                          onClick={() => removepostImage(index, "eventImages")}
                        ></span>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label>Supporting Documents</label>
              <DragFileUpload
                className="form-control drag-drop d-flex align-items-center"
                value={eventObj.eventPdf}
                name="imageURL"
                acceptFileFormat={["pdf"]}
                children={
                  <HelloMessage isLoderFileUpload={isLoderFileUpload} />
                }
                handleFileUpload={(e) => handleFileUpload(e, "eventPdf")}
              />
            </div>
          </div>
        </div>
        <div>
          <div className="form-group">
            <div className="image-upload-area">
              {eventObj.eventPdf.length !== 0 &&
                eventObj.eventPdf.map((list, index) => (
                  <div className="position-relative" key={index}>
                    <img
                      src={pdfImage}
                      alt="..."
                      className="image-upload position-relative"
                    />
                    <div className="close-icon">
                      <span
                        className="icon-close fs-10 cursor-pointer"
                        onClick={() => removepostImage(index, "eventPdf")}
                      ></span>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label>Registration link</label>
              <input
                className="form-control"
                value={eventObj.registrationLink}
                name="registrationLink"
                onChange={handleInputChange}
              />
              {validator.message(
                "registrationLink",
                eventObj.registrationLink,
                "url"
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export const EventTab = connect(null, null)(EventClass);

class HelloMessage extends React.Component {
  render() {
    let { isLoderFileUpload } = this.props;
    return (
      <div className=" d-flex upload-drag">
        <div className="ml-3">
          <h4>Upload photo</h4>
          <label>Drag & drop your file here</label>
        </div>
        <div className="ml-3 mr-3 align-self-center">
          <label>or</label>
        </div>
        <div className="ml-5 align-self-center">
          <button
            type="button"
            className="btn mainbg-btn brows-btn outline-btn float-right"
          >
            Browse files{" "}
            {isLoderFileUpload ? (
              <span
                className="spinner-border spinner-border-sm ml-1"
                role="status"
                aria-hidden="true"
              ></span>
            ) : null}
          </button>
        </div>
      </div>
    );
  }
}
