import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import { getHarvest } from "action/HarvestAct";
import { CommonPageLoader } from "component/common";
// import { debounceFunction } from "service/debounce";
import { tabHeading } from "../dataVariables";
import CropData from "./CropData";

const PastRecord = (props) => {
  const { getHarvestApi, mainTab } = props;
  const [activeTab, setActiveTab] = useState("Kharif");
  const [cropData, setCropData] = useState([]);
  const [pageMeta, setPagemeta] = useState({});
  const [loading, setLoading] = useState(false);

  const getharvestData = useCallback(
    (page) => {
      setLoading(true);
      let body = {
        page: page,
        limit: 10,
      };
      getHarvestApi(activeTab, body)
        .then((data) => {
          setCropData(data?.results);
          setPagemeta(data?.pageMeta);
          setLoading(false);
        })
        .catch(() => setLoading(false));
    },
    [getHarvestApi, activeTab]
  );

  useEffect(() => {
    if (mainTab === "View past records") getharvestData(1);
  }, [getharvestData, mainTab]);

  const handlePagination = (page) => {
    getharvestData(page);
  };

  // const searchHandler = (key) => {
  //   debounceFunction(() => getharvestData(1, key), 1000);
  // };

  return (
    <div className="container-sa my-3">
      <div className="harvest-details">
        <Nav tabs className="tab-sammunaty w-100 d-flex justify-content-center">
          {tabHeading?.map((item, index) => {
            return (
              <NavItem key={index}>
                <NavLink
                  className={`${classnames({
                    active: activeTab === item?.title,
                  })} px-2`}
                  onClick={() => {
                    setActiveTab(item?.title);
                  }}
                >
                  {item?.title}
                </NavLink>
              </NavItem>
            );
          })}
        </Nav>
        {/* <div className="d-flex justify-content-end w-100 my-3">
          <input
            type-="text"
            className="form-control form-control-sa col-3"
            placeholder="Search"
            onChange={(e) => searchHandler(e.target.value)}
          />
        </div> */}
        {loading ? (
          <CommonPageLoader />
        ) : (
          <TabContent activeTab={activeTab}>
            {tabHeading?.map((item, index) => {
              return (
                <TabPane key={index} tabId={item?.title}>
                  <CropData
                    activeTab={activeTab}
                    cropData={cropData}
                    pageMeta={pageMeta}
                    handlePagination={handlePagination}
                    getharvestData={getharvestData}
                  />
                </TabPane>
              );
            })}
          </TabContent>
        )}
      </div>
    </div>
  );
};

let mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ getHarvestApi: getHarvest }, dispatch);
};

export default connect(null, mapDispatchToProps)(PastRecord);
