import React, { Component } from "react";
import { Navbar } from "component/common";
import { RegisterView } from "component/compliance";
import { UserAuth } from "helpers/UserAuth";

class ViewRegisterClass extends Component {
  render() {
    return (
      <div>
        <div className="normal-header">{/* <Navbar /> */}</div>
        <RegisterView id={this.props.match.params.id} />
      </div>
    );
  }
}

export const ViewRegister = UserAuth(ViewRegisterClass, {
  service: "complainceGovernance",
});
