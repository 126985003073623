import { NormalButton } from 'component/common';
import React, { Component } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import { FieldsTemplate } from '../common/FieldsTemplate';

export const Operations = (props) => {

  const [, forceUpdate] = React.useState();
  const [operationsInfo, setOperationsInfo] = React.useState({
    facilities3: []
  });
  const [operationsInfoAttr, setOperationsInfoAttr] = React.useState([
    {
      label: "What are different facilities that are available through the FPO", field: "checkboxGroup", type: "number", name: "facilities3", required: true,
      list: [

      ]
    },
    {
      label: "Mention if others", field: "input", type: "text", name: "facilitiesothers", required: false,
      conditions: { attribute: "facilities3", values: [41], action: "show" },
    },
    {
      label: "How many business activities your FPC is currently engaged in?", field: "radioGroup", type: "number", name: "noofbusactbyfpo", required: true,
      list: [
        { label: "More than 5", value: "morethan5" },
        { label: "3-5 activities", value: "3to5" },
        { label: "2 activities", value: "two" },
        { label: "Single activity", value: "single" },
      ]
    },
    {
      label: "In how many commodities your FPC has intervention?", field: "radioGroup", type: "number", name: "noofcommoditiesbyfpo", required: true,
      list: [
        { label: "More than 5", value: "morethan5" },
        { label: "3-5 activities", value: "3to5" },
        { label: "2 activities", value: "two" },
        { label: "Single activity", value: "single" },
      ]
    },
    { label: "What are the different activities?", field: "input", type: "text", name: "diffactivities", note: "( ex: Input Shop, Output aggregation, Processing,)", required: true },
    { label: "Percentage of member farmers availing different FPO services", field: "input", type: "number", name: "percentusingfposervices", note: "Example: if value is 55.5 % enter 55.5", required: true },

    {
      label: "Does the FPC have 3 to 5 years of business plan developed?", field: "radioGroup", type: "number", name: "busplanready", required: true,
      list: [
        { label: "Yes, the activities are based on the business plan", value: "businessplan" },
        { label: "Yes, prepared by the POPI/RI during the formation of FPC", value: "popirigiven" },
        { label: "Yes, But the plan is not in alignment with the business activities of FPO", value: "notaligned" },
        { label: "No business plan is available", value: "noplan" },
        { label: "FPO is less than one year old hence preparation of business plan is in progress", value: "fpoone" },
      ]
    },

    {
      label: "Reason for No business plan?", field: "radioGroup", type: "number", name: "rsnnobusplan", required: true,
      conditions: { attribute: "busplanready", values: ["noplan"], action: "show" },
      list: [
        { label: "No or less knowledge of business plan development", value: "nolessknowl" },
        { label: "No surity about future business", value: "futurunsur" },
        { label: "Don’t think deveoping business plan is of any use", value: "notuseful" },
        { label: "Business plan developed but less accuracy hence rejected", value: "planrejected" },
      ]
    },

    {
      label: "Is there any periodic review and changes in FPC business plan?", field: "radioGroup", type: "number", name: "busplanreview", required: true,
      list: [
        { label: "Yes,once in 6 months", value: "halfyearly" },
        { label: "Yes, annually", value: "annually" },
        { label: "Yes, once in 2 years", value: "twoyears" },
        { label: "Yes, once in 3 years", value: "threeyears" },
        { label: "Never done", value: "never" },
        { label: "FPO is less than one year old hence preparation of business plan is in progress", value: "fpoones" },
      ]
    },

    {
      label: "Is FPC operating as per the Business Plan developed?", field: "radioGroup", type: "number", name: "workingperbusplan", required: true,
      list: [
        { label: "Yes, Fully", value: "yesfully" },
        { label: "Yes, Partially", value: "partially" },
        { label: "No", value: "no" },
        { label: "FPO is less than one year old hence preparation of business plan is in progress", value: "fpooneyears" },
      ]
    },

    {
      label: "Do you have significant business portfolio throughout the year?", field: "radioGroup", type: "number", name: "busthrutheyear", note: "whether the FPC is actively engaged in business activity throughout the year)", required: true,
      list: [
        { label: "Yes, significant volume of business throughout the year", value: "fullyear" },
        { label: "Yes, significant volume of business for 6-8 months", value: "6to8months" },
        { label: "Yes , Significant volume of business during the cropping season", value: "cropseason" },
        { label: "Very low volume of business throughout the year", value: "lowvolume" },
      ]
    },

    {
      label: "Is there any brand name under which farmers are selling their produce?", field: "radioGroup", type: "number", name: "brandnameexists", note: "whether the FPC is actively engaged in business activity throughout the year)", required: true,
      list: [
        { label: "The produce has a brand of its own with quality in line with the market requirement. The produce is thus highly valued in the market.", value: "hasbrand" },
        { label: "FPO ensures certain quality standards for the produce sold. Hence there is a demand and recognition for the produce in the market", value: "hasstandards" },
        { label: "No quality standards are followed by the FPO while aggregating and selling the produce in the market. Most of the times the produce does not align with market quality requirements", value: "nostandards" },
      ]
    },


  ]);
  //validation
  const simpleValidator = React.useRef(new SimpleReactValidator({
    element: (message) => (
      <span className="error-message text-danger fs-14">{message}</span>
    ),
    autoForceUpdate: this
  }));
  const handleSubmit = (status) => {
    const formValid = simpleValidator.current.allValid();
    if (formValid) {
      let payload = { ...operationsInfo, status };
      props.updateSurvey("operationsInfo", payload);

    } else {
      simpleValidator.current.showMessages()
      forceUpdate(1);
      props.errorAccordion();
    }
  }
  const handleInput = ({ target: { name, value } }) => {
    setOperationsInfo({
      ...operationsInfo,
      [name]: value,
    });
  };
  const handleCheckbox = (name, value) => {
    let list = [...operationsInfo[name]];
    let index = list.indexOf(value);
    if (index >= 0) {
      list.splice(index, 1);
    } else {
      list.push(value);
    }
    setOperationsInfo({
      ...operationsInfo,
      [name]: list,
    });
  };

  const handleRadio = (name, value) => {
    setOperationsInfo({
      ...operationsInfo,
      [name]: value,
    });
  };

  //
  React.useEffect(() => {
    if (props?.surveyDetails) {
      let _operationsInfo = {

        "brandnameexists": props?.surveyDetails.brandnameexists,
        "busthrutheyear": props?.surveyDetails.busthrutheyear,
        "workingperbusplan": props?.surveyDetails.workingperbusplan,
        "busplanreview": props?.surveyDetails.busplanreview,
        "busplanready": props?.surveyDetails.busplanready,
        "rsnnobusplan": props?.surveyDetails.rsnnobusplan,
        "percentusingfposervices": props?.surveyDetails.percentusingfposervices,
        "diffactivities": props?.surveyDetails.diffactivities,
        "noofcommoditiesbyfpo": props?.surveyDetails.noofcommoditiesbyfpo,
        "noofbusactbyfpo": props?.surveyDetails.noofbusactbyfpo,
      }
      if (props?.surveyDetails?.facilities3) {
        _operationsInfo.facilities3 = props?.surveyDetails?.facilities3;
      }

      setOperationsInfo({
        ...operationsInfo,
        ..._operationsInfo
      });
    }
  }, [props?.surveyDetails]);

  React.useEffect(() => {
    if (props?.submitSurveyClick) {
      handleSubmit('completed');
    }
  }, [props?.submitSurveyClick]);

  return (
    <div >
      {/* <h5><b>Basic details of FPO</b></h5> */}
      <div className="container-sa position-relative" >

        <FieldsTemplate
          attributes={operationsInfoAttr}
          simpleValidator={simpleValidator}
          stateAttr={operationsInfo}
          handleInput={handleInput}
          handleRadio={handleRadio}
          handleCheckbox={handleCheckbox}
        />

        <div className="row mt-4">
          <div className="col-md-5 col-sm-12  my-1">
            <NormalButton
              label="Save & Next"
              mainbg={true}
              className="px-3 py-2 mx-auto fs-14 line-height-26 font-Gilroy saveNext"
              onClick={() => handleSubmit('draft')}
              isPopup={true}
            />
          </div>
        </div>
      </div>
    </div>
  )
}