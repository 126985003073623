import React, { Component } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper";
import "./headerpre.scss";
import member from "assets/svg/preHeader/member.svg";
import states from "assets/svg/preHeader/states.svg";
import farmers from "assets/svg/preHeader/farmers.svg";
import femaleMembers from "assets/svg/preHeader/femaleMembers.svg";
import { formatToINRWithoutSymbol } from "service/helperFunctions";
export class HeaderPre extends Component {
  constructor() {
    super();
    SwiperCore.use([Navigation, Pagination, Autoplay]);
    this.state = {
      counts: [],
    };
  }
  componentDidUpdate(prevProps) {
    if (prevProps.counts !== this.props.counts) {
      this.setState({
        counts: this.props.counts,
      });
    }
  }
  render() {
    const params = {
      slidesPerView: 1,
      loop: false,
      speed: 1000,
      autoplay: {
        delay: 3000,
        disableOnInteraction: false,
      },
      pagination: {
        clickable: true,
      },
      breakpoints: {
        500: {
          slidesPerView: 1,
        },
        768: {
          slidesPerView: 1,
        },
      },
    };
    const { counts } = this.state;
    return (
      <div className="prelogin-header">
        <Swiper {...params}>
          {counts?.length > 0 &&
            counts.map((data, index) => {
              console.log(data);
              return (
                <SwiperSlide key={index}>
                  <div
                    className="headerPre"
                    style={{ backgroundImage: `url('${data.imageUrl}')` }}
                  >
                    <div className="background-body">
                      <div className="container-sa h-100">
                        <div className="d-flex align-items-center justify-content-between h-100">
                          <div className="row">
                            <div className="col-sm-5 col-12 pl-sm-5 pl-0">
                              <h1 className="mb-3 fw-500 header-title">
                                {data.description}
                              </h1>
                            </div>
                            <div className="col-sm-7 col-12 d-flex justify-content-end my-auto pr-sm-5 pr-0 pl-0 ml-0">
                              <div className="d-flex text-white">
                                <div className="d-flex align-items-start ml-3 ">
                                  {index == 0 || index == 2 ? (
                                    <img
                                      className="mr-2"
                                      src={member}
                                      alt="member"
                                    />
                                  ) : (
                                    <img
                                      className="mr-2"
                                      src={farmers}
                                      alt="member"
                                    />
                                  )}
                                  {index == 0 ? (
                                    <div className="info-back">
                                      <h3 className="mb-0 fw-600 fs-30">
                                        {formatToINRWithoutSymbol(
                                          data.noOfFpos
                                        )}
                                      </h3>
                                      <p className="fw-600 fs-20 line-height-23">
                                        No. of FPOs
                                      </p>
                                    </div>
                                  ) : index == 1 ? (
                                    <div className="info-back">
                                      <h3 className="mb-0 fw-600 fs-30">
                                        {formatToINRWithoutSymbol(
                                          data.districtCount
                                        )}
                                      </h3>
                                      <p className="fw-600 fs-20 line-height-23">
                                        Districts
                                      </p>
                                    </div>
                                  ) : (
                                    <div className="info-back">
                                      {/* <h3 className="mb-0 fw-600 fs-30">
                                        {data.turnOver
                                          ? moneyFormatBySymbol(
                                              data.turnOver,
                                              true
                                            )
                                          : 0}
                                      </h3>
                                      <p className="fw-600 fs-20 line-height-23">
                                        Turn Over
                                      </p> */}
                                      <h3 className="mb-0 fw-600 fs-30">
                                        {formatToINRWithoutSymbol(
                                          counts[0].noOfStates
                                        )}
                                      </h3>
                                      <p className="fw-600 fs-20 line-height-23">
                                        {"States & UTs"}
                                      </p>
                                    </div>
                                  )}
                                </div>
                                <div className="d-flex align-items-start ml-3 ">
                                  {index == 0 ? (
                                    <img
                                      className="mr-2"
                                      src={states}
                                      alt="states.svg"
                                    />
                                  ) : (
                                    <img
                                      className="mr-2"
                                      src={farmers}
                                      alt="states.svg"
                                    />
                                  )}
                                  {index == 0 ? (
                                    <div className="info-back">
                                      <h3 className="mb-0 fw-600 fs-30">
                                        {formatToINRWithoutSymbol(
                                          data.noOfFarmers
                                        )}
                                      </h3>
                                      <p className="fw-600 fs-20 line-height-23">
                                        {"No. of farmers"}
                                      </p>
                                    </div>
                                  ) : (
                                    index == 1 && (
                                      <div className="info-back">
                                        <h3 className="mb-0 fw-600 fs-30">
                                          {formatToINRWithoutSymbol(
                                            data.totalAcerage
                                          )}
                                        </h3>
                                        <p className="fw-600 fs-20 line-height-23">
                                          Total Acreage
                                        </p>
                                      </div>
                                    )
                                  )}
                                </div>
                                <div className="d-flex align-items-start ml-3 ">
                                  {index == 2 && (
                                    <div className="info-back">
                                      <h3 className="mb-0 fw-600 fs-30">
                                        {formatToINRWithoutSymbol(
                                          data.noOfVillages
                                        )}
                                      </h3>
                                      <p className="fw-600 fs-20 line-height-23">
                                        Villages
                                      </p>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
        </Swiper>
      </div>
    );
  }
}
