import React, { Component } from "react";
import "./CreateEvents.scss";
import { Modal, ModalBody } from "reactstrap";
import { fileUpload } from "action/MemberAct";
import {
  createEvent,
  getEventById,
  createAward,
  getAwardById,
  getStoryById,
  updateAwardById,
  createStory,
  updateStory,
  updateEvent,
  getAllAgendaMaster,
} from "action/eventsAndImpactStories";
import "react-datepicker/dist/react-datepicker.css";
// Redux Connection
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SimpleReactValidator from "simple-react-validator";
import { EventTab } from "./tabs/event";
import { AwardsTab } from "./tabs/awards";
import { StoriesClass } from "./tabs/stories";
import { Toast } from "service/toast";
export class CreateEventsClass extends Component {
  constructor(props) {
    super(props);
    this.eventTab = React.createRef();
    this.state = {
      isCreatModal: false,
      isLoderFileUpload: false,
      isFormLoder: false,
      activeTab: "event",
      eventObj: {
        type: "event",
        eventDate: "",
        location: "",
        agenda: "",
        description: "",
        organizer: "",
        eventTitle: "",
        eventDocs: [],
        eventImages: [],
        eventPdf: [],
        registrationLink: "",
        supportingLink: "",
        eventStatus: "completed",
        agendaName: "",
      },
      validatorShow: false,
      agendaDataList: [],
      awardObj: {
        receivedDate: "",
        title: "",
        location: "",
        receivedFrom: "",
        awardDocs: [],
        awardPdf: [],
        awardImages: [],
        shortDescription: "",
        supportingLink: "",
        awardName: "",
      },
      storyObj: {
        title: "",
        supportingLink: "",
        description: "",
        publishOn: "",
        storyDocs: [],
        successImages: [],
        successPdf: [],
      },
    };
  }
  validator = new SimpleReactValidator({
    element: (message) => (
      <span className="error-message text-danger fs-14">{message}</span>
    ),
    autoForceUpdate: this,
    validators: {
      url: {
        message: "The :attribute must be a valid format.",
        rule: (val, params, validator) => {
          if (val !== "") {
            return (
              validator.helpers.testRegex(
                val,
                /^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+\.[a-z]+(\/[a-zA-Z0-9#]+\/?)*$/
              ) && params.indexOf(val) === -1
            );
          } else {
            return true;
          }
        },
        messageReplace: (message, params) =>
          message.replace("", this.helpers.toSentence(params)),
        required: true,
      },
      checkPassword: {
        message: "Given :attribute does not match",
        rule: function (val, params) {
          return val === params[0];
        },
      },
    },
  });

  //onload components
  componentWillMount() {
    this.props.getAllAgendaMaster().then(({ data: { agendaData } }) => {
      const agendaDataList = agendaData.map(({ agendaName, agendaId }) => ({
        label: agendaName,
        value: agendaId,
      }));
      this.setState({ agendaDataList });
    });
  }

  getEventByid = () => {
    let { eventId } = this.props;
    this.props.getEventById(eventId).then(({ data, status }) => {
      if (status === 200) {
        data.type = "event";
        data.eventId = eventId;
        data.eventDate = new Date(data.eventDate);
        const agendaId = this.state.agendaDataList.filter(
          (list) => list.label == data.agendaName
        );
        var todayDate = new Date(); //Today Date
        var eventDate = new Date(data.eventDate);
        data.supportingLink = data.supportingLink ? data.supportingLink : "";
        data.eventImages = data.eventImages ?? [];
        data.eventPdf = data.eventPdf ?? [];
        if (todayDate > eventDate) {
          data.eventStatus = "completed";
        } else {
          data.eventStatus = "upcoming";
        }
        agendaId.length > 0 && (data.agenda = agendaId[0].value);
        this.setState({ eventObj: data });
      }
    });
  };

  getAwardId = () => {
    let { awardId } = this.props;
    this.props.getAwardById(awardId).then(({ data, status }) => {
      if (status === 200) {
        data.awardId = awardId;
        data.receivedDate = new Date(data.receivedDate);
        data.supportingLink = data.supportingLink ? data.supportingLink : "";
        data.awardImages = data.awardImages ?? [];
        data.awardPdf = data.awardPdf ?? [];
        this.setState({ awardObj: data });
      }
    });
  };

  getStoryId = () => {
    let { storyId } = this.props;
    this.props.getStoryById(storyId).then(({ data, status }) => {
      if (status === 200) {
        data.storyId = storyId;
        data.publishOn = new Date(data.publishOn);
        data.supportingLink = data.supportingLink ? data.supportingLink : "";
        data.successImages = data.successImages ?? [];
        data.successPdf = data.successPdf ?? [];
        this.setState({ storyObj: data });
      }
    });
  };

  componentDidUpdate(prevProps) {
    let { isCreatModal, eventId, awardId, storyId, tabType } = this.props;
    let { activeTab } = this.state;
    activeTab = tabType;
    if (
      isCreatModal !== prevProps.isCreatModal &&
      (!!eventId || !!awardId || !!storyId)
    ) {
      this.setState({ isCreatModal, activeTab });
      if (activeTab === "event") {
        if (!!eventId) {
          this.getEventByid();
        }
      } else if (activeTab === "awards") {
        if (!!awardId) {
          this.getAwardId();
        }
      } else if (activeTab === "stories") {
        if (!!storyId) {
          this.getStoryId();
        }
      }
    }
  }

  handleInputChange = (e) => {
    let { value, name } = e.target;
    let { activeTab, eventObj, awardObj, storyObj } = this.state;
    if (activeTab === "event") {
      eventObj[name] = value;
      this.setState({
        eventObj: { ...eventObj },
      });
    } else if (activeTab === "awards") {
      awardObj[name] = value;
      this.setState({
        awardObj: { ...awardObj },
      });
    } else if (activeTab === "stories") {
      storyObj[name] = value;
      this.setState({
        storyObj: { ...storyObj },
      });
    }
  };

  onEditorChange = (editor) => {
    let { storyObj, awardObj, eventObj, activeTab } = this.state;
    if (activeTab === "event") {
      eventObj.description = editor.getData();
      this.setState({
        eventObj: { ...eventObj },
      });
    } else if (activeTab === "awards") {
      awardObj.shortDescription = editor.getData();
      this.setState({
        awardObj: { ...awardObj },
      });
    } else if (activeTab === "stories") {
      storyObj.description = editor.getData();
      this.setState({
        storyObj: { ...storyObj },
      });
    }
  };

  handleDateChange = (e) => {
    let { activeTab, awardObj, eventObj, storyObj } = this.state;
    if (activeTab === "event") {
      this.setState({
        eventObj: {
          ...eventObj,
          eventDate: e,
        },
      });
    } else if (activeTab === "awards") {
      this.setState({
        awardObj: {
          ...awardObj,
          receivedDate: e,
        },
      });
    } else if (activeTab === "stories") {
      this.setState({
        storyObj: {
          ...storyObj,
          publishOn: e,
        },
      });
    }
  };

  removepostImage = (index, key) => {
    let { eventObj, awardObj, activeTab, storyObj } = this.state;
    if (activeTab === "event") {
      eventObj[key].splice(index, 1);
      this.setState({
        eventObj: { ...eventObj },
      });
    } else if (activeTab === "awards") {
      awardObj[key].splice(index, 1);
      this.setState({
        awardObj: { ...awardObj },
      });
    } else if (activeTab === "stories") {
      storyObj[key].splice(index, 1);
      this.setState({
        storyObj: { ...storyObj },
      });
    }
  };

  uploadCoverImg = (event, key) => {
    event.persist();
    let { activeTab, awardObj, eventObj, storyObj } = this.state;
    let accept = ["image/jpeg", "image/png", "image/jpg"];
    if (accept.includes(event.target.files[0].type)) {
      let formData = new FormData();
      const file = event.target.files[0];
      formData.append("file", file);
      if (activeTab === "event") {
        this.props.fileUpload(formData).then((data) => {
          eventObj[key][0] = {
            fileName: data[0].fileName,
            documentUrl: data[0].fileUrl,
          };
          this.setState({
            eventObj: { ...eventObj },
          });
        });
      } else if (activeTab === "awards") {
        this.props.fileUpload(formData).then((data) => {
          awardObj[key][0] = {
            fileName: data[0].fileName,
            documentUrl: data[0].fileUrl,
          };
          this.setState({
            awardObj: { ...awardObj },
          });
        });
      } else if (activeTab === "stories") {
        this.props.fileUpload(formData).then((data) => {
          storyObj[key][0] = {
            fileName: data[0].fileName,
            documentUrl: data[0].fileUrl,
          };
          this.setState({
            storyObj: { ...storyObj },
          });
        });
      }
    } else {
      Toast({
        type: "error",
        message: "Please upload png, jpeg, jpg file only",
      });
    }
  };

  removeCoverImg = (key) => {
    let { activeTab, awardObj, eventObj, storyObj } = this.state;
    if (activeTab === "event") {
      eventObj[key] = [];
      this.setState({
        eventObj: { ...eventObj },
      });
    } else if (activeTab === "awards") {
      awardObj[key] = [];
      this.setState({
        awardObj: { ...awardObj },
      });
    } else if (activeTab === "stories") {
      storyObj[key] = [];
      this.setState({
        storyObj: { ...storyObj },
      });
    }
  };

  handleFileUpload = (files, key) => {
    let file = files[0];
    const formData = new FormData();
    formData.append("file", file);
    let { activeTab, awardObj, eventObj, storyObj } = this.state;
    this.setState({ isLoderFileUpload: true });
    if (activeTab === "event") {
      if (eventObj[key].length <= 4) {
        // eventObj[key].push({
        //   fileName: file.name,
        //   documentUrl: URL.createObjectURL(file),
        // });
        this.props
          .fileUpload(formData)
          .then((data) => {
            this.setState({ isLoderFileUpload: false });
            let currentIndex = eventObj[key].length;
            // eventObj[key].map((list, index) => {
            //   if (list.fileName === data[0].actualFileName) {
            //     currentIndex = index;
            //   }
            // });
            eventObj[key][currentIndex] = {
              fileName: data[0].fileName,
              documentUrl: data[0].fileUrl,
            };
            this.setState({
              eventObj: { ...eventObj },
            });
          })
          .catch(() => {
            this.setState({ isLoderFileUpload: false });
          });
      } else {
        Toast({ type: "error", message: "Only 5 files can be uploaded" });
        this.setState({ isLoderFileUpload: false });
      }
    } else if (activeTab === "awards") {
      if (awardObj[key].length <= 4) {
        // awardObj[key].push({
        //   fileName: file.name,
        //   documentUrl: URL.createObjectURL(file),
        // });
        this.props
          .fileUpload(formData)
          .then((data) => {
            this.setState({ isLoderFileUpload: false });
            let currentIndex = awardObj[key].length;
            // awardObj[key].map((list, index) => {
            //   if (list.fileName === data[0].actualFileName) {
            //     currentIndex = index;
            //   }
            // });
            awardObj[key][currentIndex] = {
              fileName: data[0].fileName,
              documentUrl: data[0].fileUrl,
            };
            this.setState({
              awardObj: { ...awardObj },
            });
          })
          .catch(() => {
            this.setState({ isLoderFileUpload: false });
          });
      } else {
        Toast({ type: "error", message: "Only 5 files can be uploaded" });
        this.setState({ isLoderFileUpload: false });
      }
    } else if (activeTab === "stories") {
      if (storyObj[key].length <= 4) {
        // storyObj[key].push({
        //   fileName: file.name,
        //   documentUrl: URL.createObjectURL(file),
        // });
        this.props
          .fileUpload(formData)
          .then((data) => {
            this.setState({ isLoderFileUpload: false });
            let currentIndex = storyObj[key].length;
            // storyObj[key].map((list, index) => {
            //   if (list.fileName === data[0].actualFileName) {
            //     currentIndex = index;
            //   }
            // });
            storyObj[key][currentIndex] = {
              fileName: data[0].fileName,
              documentUrl: data[0].fileUrl,
            };
            this.setState({
              storyObj: { ...storyObj },
            });
          })
          .catch(() => {
            this.setState({ isLoderFileUpload: false });
          });
      } else {
        Toast({ type: "error", message: "Only 5 files can be uploaded" });
        this.setState({ isLoderFileUpload: false });
      }
    }
  };

  handleSubmit = () => {
    let {
      activeTab,
      eventObj,
      awardObj,
      isCreatModal,
      storyObj,
      agendaDataList,
    } = this.state;
    let { eventId, awardId, storyId, getAllEvent, clearEditId } = this.props;
    let apiCall;
    if (activeTab === "event") {
      let eventNewObj = {
        type: "event",
        eventDate: "",
        location: "",
        agenda: "",
        description: "",
        organizer: "",
        eventTitle: "",
        eventDocs: [],
        eventImages: [],
        eventPdf: [],
        registrationLink: "",
        supportingLink: "",
        eventStatus: "completed",
        agendaName: "",
      };

      const DataObj = agendaDataList.filter(
        (list) => list.value === eventObj.agenda
      );
      let eventObjCopy = { ...eventObj };
      DataObj.length > 0 && (eventObjCopy.agenda = DataObj[0].value.toString());
      DataObj.length > 0
        ? (eventObjCopy.agendaName = DataObj[0].label)
        : (eventObjCopy.agendaName = "");
      if (this.validator.allValid()) {
        if (eventObjCopy.eventDate !== "") {
          apiCall = eventId
            ? this.props.updateEvent(eventObjCopy)
            : this.props.createEvent(eventObjCopy);
          apiCall
            .then((data) => {
              clearEditId && clearEditId();
              this.validator.hideMessages();
              this.setState({ isFormLoder: false });
              if (!!data) {
                this.setState({
                  isCreatModal: !isCreatModal,
                  eventObj: eventNewObj,
                });
                this.props.handleCreateModel("", activeTab);
                getAllEvent();
              }
            })
            .catch(() => {
              this.setState({ isFormLoder: false });
            });
          this.setState({ validatorShow: true });
          this.validator.showMessages();
          this.forceUpdate();
        }
      } else {
        this.setState({ validatorShow: true });
        this.validator.showMessages();
        this.forceUpdate();
      }
    } else if (activeTab === "awards") {
      if (this.validator.allValid()) {
        apiCall = awardId
          ? this.props.updateAwardById(awardObj)
          : this.props.createAward(awardObj);
        apiCall
          .then((data) => {
            clearEditId && clearEditId();
            this.validator.hideMessages();
            this.setState({ isFormLoder: false });
            if (!!data) {
              let awardObj = {
                receivedDate: "",
                title: "",
                location: "",
                receivedFrom: "",
                shortDescription: "",
                supportingLink: "",
                awardPdf: [],
                awardImages: [],
                awardDocs: [],
                awardName: "",
              };
              this.setState({ isCreatModal: !isCreatModal, awardObj });
              this.props.handleCreateModel("", activeTab);
              getAllEvent();
            }
          })
          .catch(() => {
            this.setState({ isFormLoder: false });
          });
      } else {
        this.setState({ validatorShow: true });
        this.validator.showMessages();
        this.forceUpdate();
      }
    } else if (activeTab === "stories") {
      if (this.validator.allValid()) {
        apiCall = storyId
          ? this.props.updateStory(storyObj)
          : this.props.createStory(storyObj);
        apiCall
          .then((data) => {
            clearEditId && clearEditId();
            this.validator.hideMessages();
            this.setState({ isFormLoder: false });
            if (!!data) {
              let storyObj = {
                title: "",
                supportingLink: "",
                description: "",
                publishOn: "",
                storyDocs: [],
                successPdf: [],
                successImages: [],
              };
              this.setState({
                isCreatModal: !isCreatModal,
                storyObj,
                validatorShow: false,
              });
              this.props.handleCreateModel("", activeTab);
              getAllEvent();
            }
          })
          .catch(() => {
            this.setState({ isFormLoder: false });
          });
      } else {
        this.setState({ validatorShow: true });
        this.validator.showMessages();
        this.forceUpdate();
      }
    }
  };
  handleCancel = () => {
    this.validator.hideMessages();
    let { activeTab } = this.state;
    let { clearEditId } = this.props;
    let eventObj = {
      type: "event",
      eventDate: "",
      location: "",
      agenda: "",
      description: "",
      organizer: "",
      eventTitle: "",
      eventDocs: [],
      eventImages: [],
      eventPdf: [],
      registrationLink: "",
      supportingLink: "",
      eventStatus: "completed",
    };
    let awardObj = {
      receivedDate: "",
      title: "",
      location: "",
      receivedFrom: "",
      awardDocs: [],
      awardImages: [],
      awardPdf: [],
      shortDescription: "",
      supportingLink: "",
      awardName: "",
    };
    let storyObj = {
      title: "",
      supportingLink: "",
      description: "",
      publishOn: "",
      storyDocs: [],
      successImages: [],
      successPdf: [],
    };
    clearEditId && clearEditId();
    this.setState({
      isCreatModal: false,
      eventObj,
      awardObj,
      storyObj,
      validatorShow: false,
    });
    this.props.handleCreateModel("", activeTab);
  };
  handleRadio = (value) => {
    let { eventObj } = this.state;
    eventObj.eventStatus = value;
    this.setState({
      eventObj,
    });
  };

  render() {
    let {
      isCreatModal,
      isFormLoder,
      activeTab,
      eventObj,
      validatorShow,
      awardObj,
      storyObj,
      isLoderFileUpload,
      agendaDataList,
    } = this.state;
    let { eventId, awardId, storyId } = this.props;
    this.validator.purgeFields();
    return (
      <div className="create-event">
        <div className="h-100 create-event-background rounded">
          <div className="d-flex justify-content-end align-items-center h-100">
            <input
              className="button"
              type="button"
              value="Create"
              onClick={() => this.setState({ isCreatModal: !isCreatModal })}
            />
          </div>
        </div>

        <Modal isOpen={isCreatModal} className="createEvent-modal mx-auto">
          <div className="modal-header">
            <div className="row">
              <div className="col-md-6">
                <h5 className="modal-title">
                  {!!eventId || !!awardId || !!storyId
                    ? "Update"
                    : "Create new"}
                </h5>
              </div>
              <div className="col-md-6">
                <button
                  type="button"
                  className="save-btn btn float-right"
                  disabled={isFormLoder}
                  onClick={this.handleSubmit}
                >
                  {!!eventId || !!awardId || !!storyId ? "Update" : "Save"}{" "}
                  {isFormLoder ? (
                    <span
                      className="spinner-border spinner-border-sm ml-1"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  ) : null}
                </button>
                <button
                  type="button"
                  className="save-btn btn float-right"
                  disabled={isFormLoder}
                  onClick={this.handleCancel}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
          <ModalBody>
            <div className="row forum-tabs">
              <div className="col-md-6 col-xs-6">
                <ul className="nav nav-pills">
                  {(storyId === undefined || storyId === "") &&
                  (awardId === undefined || awardId === "") ? (
                    <li
                      className={`nav-item ${
                        activeTab === "event" ? "active" : ""
                      }`}
                      onClick={() => {
                        this.setState({ activeTab: "event" });
                        this.validator.hideMessages();
                      }}
                    >
                      Event
                    </li>
                  ) : null}
                  {(storyId === undefined || storyId === "") &&
                  (eventId === undefined || eventId === "") ? (
                    <li
                      className={`nav-item ${
                        activeTab === "awards" ? "active" : ""
                      }`}
                      onClick={() => {
                        this.setState({ activeTab: "awards" });
                        this.validator.hideMessages();
                      }}
                    >
                      Award
                    </li>
                  ) : null}
                  {(eventId === undefined || eventId === "") &&
                  (awardId === undefined || awardId === "") ? (
                    <li
                      className={`nav-item ${
                        activeTab === "stories" ? "active" : ""
                      }`}
                      onClick={() => {
                        this.setState({ activeTab: "stories" });
                        this.validator.hideMessages();
                      }}
                    >
                      Success stories
                    </li>
                  ) : null}
                </ul>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                {activeTab === "event" ? (
                  <EventTab
                    validator={this.validator}
                    validatorShow={validatorShow}
                    agendaDataList={agendaDataList}
                    isLoderFileUpload={isLoderFileUpload}
                    handleFileUpload={this.handleFileUpload}
                    handleDateChange={this.handleDateChange}
                    eventObj={eventObj}
                    handleInputChange={this.handleInputChange}
                    eventId={eventId}
                    removepostImage={this.removepostImage}
                    onEditorChange={this.onEditorChange}
                    handleRadio={this.handleRadio}
                    uploadCoverImg={this.uploadCoverImg}
                    removeCoverImg={this.removeCoverImg}
                  />
                ) : activeTab === "awards" ? (
                  <AwardsTab
                    validator={this.validator}
                    isLoderFileUpload={isLoderFileUpload}
                    validatorShow={validatorShow}
                    handleFileUpload={this.handleFileUpload}
                    handleDateChange={this.handleDateChange}
                    awardObj={awardObj}
                    handleInputChange={this.handleInputChange}
                    awardId={awardId}
                    onEditorChange={this.onEditorChange}
                    removepostImage={this.removepostImage}
                    uploadCoverImg={this.uploadCoverImg}
                    removeCoverImg={this.removeCoverImg}
                  />
                ) : activeTab === "stories" ? (
                  <StoriesClass
                    validator={this.validator}
                    isLoderFileUpload={isLoderFileUpload}
                    validatorShow={validatorShow}
                    handleFileUpload={this.handleFileUpload}
                    handleDateChange={this.handleDateChange}
                    handleInputChange={this.handleInputChange}
                    onEditorChange={this.onEditorChange}
                    storyId={storyId}
                    storyObj={storyObj}
                    removepostImage={this.removepostImage}
                    uploadCoverImg={this.uploadCoverImg}
                    removeCoverImg={this.removeCoverImg}
                  />
                ) : null}
              </div>
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      fileUpload,
      createEvent,
      updateEvent,
      getEventById,
      getAwardById,
      createAward,
      updateAwardById,
      getStoryById,
      createStory,
      updateStory,
      getAllAgendaMaster,
    },
    dispatch
  );
};

export const CreateEvents = connect(
  null,
  mapDispatchToProps
)(CreateEventsClass);
