
const initialState = {
  stateList: [],
  districtList: [],
  facilities: null,
  licenses: null,
  positions: null,
  products: null,
  cropsList: []
};

export default (state = Object.assign({}, initialState), { type, payload }) => {
  switch (type) {
    case "GRADING_MASTERS":
      let _state = payload.states.map((state) => {
        return { label: state.stateName, value: state.stateId }
      });
      let districts = payload.districts.map((district) => {
        return { label: district.districtName, value: district.districtId }
      });
      let facilities = payload.facilities.reduce((acc, facilitie, i) => {
        if (!acc[facilitie.category]) {
          acc[facilitie.category] = [];
        }
        acc[facilitie.category].push({ label: facilitie.facilityName, value: facilitie.id });
        return acc;
      }, {});
      let licenses = payload.licenses.map((license) => {
        return { label: license.licenseName, value: license.id }
      });
      let positions = payload.positions.map((position) => {
        return { label: position.positionName, value: position.id }
      });
      let products = payload.products.map((product) => {
        return { label: product.productName, value: product.id }
      });
      let crops = payload.crops.map((crop) => {
        return { label: crop.cropName, value: crop.cropId }
      });

      //
      return {
        ...state,
        stateList: _state,
        districtList: districts,
        facilities: facilities,
        licenses: licenses,
        positions: positions,
        products: products,
        cropsList: crops
      };
    case "DISTRICTS_WITH_STATEWISE":
      let _districts = payload && payload.map((district) => {
        return { label: district.districtName, value: district.districtId }
      });
      return {
        ...state,
        districtList: _districts,
      }
    default:
      return state;
  }
};
