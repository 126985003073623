import { Harvest } from "service/apiVariables";
import { Toast } from "service/toast";
import { addQuery } from "service/helperFunctions";
import { api } from "service/api";

export const addHarvest = (body) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    api({ ...Harvest.addHarvest, body })
      .then(({ data, message }) => {
        resolve(data);
        Toast({ type: "success", message, time: 5000 });
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

export const harvestCrops = (body) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    api({ ...Harvest.harvestCrops, body })
      .then(({ data, message }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

export const getHarvest = (tab, query) => () => {
  Harvest.getHarvest.tab = tab;
  return new Promise((resolve, reject) => {
    addQuery(query, Harvest.getHarvest);
    api({ ...Harvest.getHarvest })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

export const updateHarvest = (id, body) => (dispatch, getState) => {
  Harvest.updateHarvest.id = id;
  return new Promise((resolve, reject) => {
    api({ ...Harvest.updateHarvest, body })
      .then(({ data, message }) => {
        resolve(data);
        Toast({ type: "success", message, time: 5000 });
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

export const deletHarvest = (id) => () => {
  Harvest.deletHarvest.id = id;
  return new Promise((resolve, reject) => {
    api({ ...Harvest.deletHarvest })
      .then(({ data, message }) => {
        resolve(data);
        Toast({ type: "success", message });
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};
