import React, { Component } from "react";
import { history } from "service/helpers";
import { About } from "component/Aboutus";
import { Navbar } from "component/common";
import downloadIcon from "assets/images/icon/download-icon.svg";
import "./style.scss";

export class Aboutus extends Component {
  componentDidMount() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  handleDownload = () => {
    document.execCommand("print");
  };

  render() {
    return (
      <div id="aboutUsFpo">
        <div className="header-about">
          {/* <Navbar /> */}
          {this.props.match.params.name === undefined
            ? this.props.match.params.id === undefined &&
              localStorage.getItem("userGroupName") === "fpo" &&
              (localStorage.getItem("userRoleName") === "fpo" ||
                localStorage.getItem("userGroupName") === "Ceo") && (
                <div className="edit-about-detail-button w-100">
                  <div className="container-sa">
                    <button
                      className="btn d-flex justify-content-center mt-4"
                      onClick={() => {
                        history.push("/fpo/addaboutus");
                      }}
                    >
                      <i className="icon-edit fs-14 mr-2"></i>
                      {"Edit FPO profile"}
                    </button>
                  </div>
                </div>
              )
            : ""}
          <div className="h-100 w-100">
            <p className="d-flex justify-content-center align-items-center h-100 w-100 text-white fs-32 font-weight-bold">
              About Us
            </p>
          </div>
        </div>
        <div className="edit-about-detail-button w-100">
          <div className="container-sa">
            <div className="d-flex justify-content-end">
              <div>
                <button
                  className="aboutUsDownloadBtn mt-4"
                  onClick={this.handleDownload}
                >
                  <img src={downloadIcon} className="mr-2" alt="download" />
                  {"Download"}
                </button>
              </div>
            </div>
          </div>
        </div>
        <About
          id={this.props.match.params.id}
          name={this.props.match.params.name}
        />
      </div>
    );
  }
}
