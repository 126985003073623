import React, { Component, Fragment } from "react";
import { history } from "service/helpers";
import classnames from "classnames";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
} from "reactstrap";
import Card from "./card";
import "./newsandevents.scss";
import { Pagination, CommonPageLoader, NormalButton } from "component/common";
import { debounceFunction } from "service/debounce";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getAllEvents, getAllNews, getAllReports } from "action/contentAct";
import { ReportCard } from "component/Report/ReportList/ReportCard";
const arrow_left = require("assets/svg/add_member/arrow_left.svg");

export class NewsAndEventsClass extends Component {
  constructor() {
    super();
    this.state = {
      activeTab: "1",
      newsList: [],
      eventsList: [],
      reportList: [],
      page: "1",
      pageMeta: {},
      searchValue: "",
      search: "",
      istabOneLoading: false,
      istabTwoLoading: false,
      istabThreeLoading: false,
    };
  }

  //toggle area
  toggle = (tab) => {
    this.setState({
      searchValue: "",
      search: "",
      activeTab: tab,
    });
    tab === "1" && this.getReports(1, "");
    tab === "2" && this.getNews(1, "");
    tab === "3" && this.getEvents(1, "");
  };

  //handle pagination
  handlePagination = (page) => {
    this.state.activeTab === "1" && this.getReports(page.page, "");
    this.state.activeTab === "2" && this.getNews(page.page, "");
    this.state.activeTab === "3" && this.getEvents(page.page, "");
  };

  //handle input
  handleInput = ({ target: { name, value } }) => {
    //Debounce Function
    this.setState({
      search: value,
    });
    debounceFunction(() => this.SearchFunction(value), 1000);
  };

  SearchFunction = (searchValue) => {
    //debounce function
    this.setState({ searchValue });
    this.state.activeTab === "1" && this.getReports(1, this.state.searchValue);
    this.state.activeTab === "2" && this.getNews(1, this.state.searchValue);
    this.state.activeTab === "3" && this.getEvents(1, this.state.searchValue);
  };

  //get news
  getNews = (page, searchValue) => {
    const { showAll = true } = this.props;
    this.setState({
      istabOneLoading: true,
    });
    this.props
      .getAllNews({ limit: showAll ? 6 : 3, search: searchValue, page })
      .then((data) => {
        const { results, pageMeta } = data;
        let newsData = [];
        results.map((result) => {
          newsData.push({
            thumb: result.uploadImage,
            date: result.publishOn,
            title: result.newsTitle,
            description: result.description,
            linkUrl: result.sourceHyperlink,
          });
        });
        this.setState({
          newsList: newsData,
          pageMeta,
          istabTwoLoading: false,
        });
      })
      .catch(() => {
        this.setState({
          istabTwoLoading: false,
        });
      });
  };

  //get events
  getEvents = (page, searchValue) => {
    const { showAll = true } = this.props;
    this.setState({
      istabTwoLoading: true,
    });
    this.props
      .getAllEvents({ limit: showAll ? 6 : 3, search: searchValue, page })
      .then((data) => {
        const { results, pageMeta } = data;
        let eventsData = [];
        results.map((result) => {
          eventsData.push({
            thumb: result.uploadImage,
            date: result.publishOn,
            title: result.eventTitle,
            description: result.description,
            linkUrl: result.sourceHyperlink,
          });
        });
        this.setState({
          eventsList: eventsData,
          pageMeta,
          istabThreeLoading: false,
        });
      })
      .catch(() => {
        this.setState({
          istabThreeLoading: false,
        });
      });
  };

  getReports = (page, searchValue) => {
    const { showAll = true } = this.props;
    this.setState({
      istabThreeLoading: true,
    });
    this.props
      .getAllReports({ limit: showAll ? 6 : 3, search: searchValue, page })
      .then((data) => {
        const { results, pageMeta } = data;
        let newsData = [];
        results.map((result) => {
          newsData.push({
            type: result?.reportType,
            title: result?.reportTitle,
            description: result?.description,
            content: result?.content,
            reportImg: result?.uploadImage,
            uploadDoc: result?.uploadDoc,
            reportId: result?.reportId,
          });
        });
        this.setState({
          reportList: newsData,
          pageMeta,
          istabOneLoading: false,
        });
      })
      .catch(() => {
        this.setState({
          istabOneLoading: false,
        });
      });
  };

  handleViewAll = () => {
    localStorage.getItem("AuthToken")
      ? history.push(
          localStorage.getItem("userGroupName") !== "samunnati" &&
            localStorage.getItem("userGroupName") !== "fpo"
            ? `/other/contents/newsandevents`
            : localStorage.getItem("userRoleName") === "Relationship Manager"
            ? `/rm/contents/newsandevents`
            : `/fpo/contents/reportsarticlesevents`
        )
      : history.push("/landing/contents/reportsarticlesevents");
  };

  //life cylces
  componentDidMount() {
    this.getReports();
  }

  render() {
    const {
      activeTab,
      eventsList,
      newsList,
      reportList,
      search = "",
      pageMeta = {},
      istabOneLoading,
      istabTwoLoading,
      istabThreeLoading,
    } = this.state;
    const { showAll = true } = this.props;
    return (
      <Fragment>
        {showAll && (
          <div className="d-flex container-sa my-3">
            <div
              onClick={() => history.goBack()}
              className="d-flex cursor-pointer"
            >
              <img className="cursor-pointer" src={arrow_left} alt="back" />
              <p className="ml-3 add-member-text-back mb-0">
                {"Reports, Articles & Events"}
              </p>
            </div>
          </div>
        )}
        <div className="bg-blue-f9">
          <div className="container-sa">
            <div className="news-events-tab pb-sm-3 pb-1 pt-sm-3 pt-1">
              <div className="d-flex justify-content-center pb-sm-5 pb-2">
                <div className="scroll-x">
                  <Nav
                    tabs
                    className="tab-sammunaty"
                    style={{ width: "max-content" }}
                  >
                    <NavItem>
                      <NavLink
                        className={`${classnames({
                          active: activeTab === "1",
                        })} p-0`}
                        onClick={() => this.toggle("1")}
                      >
                        <button
                          className={`btn ${activeTab === "1" ? "active" : ""}`}
                        >
                          Reports
                        </button>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={`${classnames({
                          active: activeTab === "2",
                        })} p-0`}
                        onClick={() => this.toggle("2")}
                      >
                        <button
                          className={`btn ${activeTab === "2" ? "active" : ""}`}
                        >
                          Articles
                        </button>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={`${classnames({
                          active: activeTab === "3",
                        })} p-0`}
                        onClick={() => this.toggle("3")}
                      >
                        <button
                          className={`btn ${activeTab === "3" ? "active" : ""}`}
                        >
                          Events
                        </button>
                      </NavLink>
                    </NavItem>
                  </Nav>
                </div>
              </div>
              {showAll && (
                <div className="news-events-tab d-flex flex-column">
                  <div className="row align-items-center justify-content-end">
                    <div className="col-sm-3">
                      <input
                        type-="text"
                        className="form-control form-control-sa"
                        placeholder="Search"
                        value={search}
                        onChange={(e) => this.handleInput(e)}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="2">
                {!istabTwoLoading && newsList.length === 0 ? (
                  <p className="py-4 mb-0 text-black-50 fs-22 line-height-25 text-center">
                    No data found
                  </p>
                ) : !istabTwoLoading ? (
                  <Row>
                    {newsList.map((news, index) => {
                      return (
                        <Col md="4" key={index} className="mb-5">
                          <Card content="News" details={news} />
                        </Col>
                      );
                    })}
                  </Row>
                ) : (
                  <CommonPageLoader />
                )}
              </TabPane>
              <TabPane tabId="3">
                {!istabThreeLoading && eventsList.length === 0 ? (
                  <p className="py-4 mb-0 text-black-50 fs-22 line-height-25 text-center">
                    {"No data found"}
                  </p>
                ) : !istabThreeLoading ? (
                  <Row>
                    {eventsList.map((event, index) => {
                      return (
                        <Col md="4" key={index} className="mb-5">
                          <Card content="Events" details={event} />
                        </Col>
                      );
                    })}
                  </Row>
                ) : (
                  <CommonPageLoader />
                )}
              </TabPane>

              <TabPane tabId="1">
                {!istabOneLoading && reportList?.length === 0 ? (
                  <p className="py-4 mb-0 text-black-50 fs-22 line-height-25 text-center">
                    {"No data found"}
                  </p>
                ) : !istabOneLoading ? (
                  <Row>
                    {reportList?.map((report, index) => {
                      return (
                        <Col md="4" key={index} className="mb-5">
                          <ReportCard
                            content="Reports"
                            details={report}
                            detailsKey={report?.reportId}
                          />
                        </Col>
                      );
                    })}
                  </Row>
                ) : (
                  <CommonPageLoader />
                )}
              </TabPane>
            </TabContent>
            {pageMeta && pageMeta.page && showAll ? (
              <Pagination
                handleChange={this.handlePagination}
                pageMeta={pageMeta}
              />
            ) : null}
            {!showAll &&
              ((activeTab === "2" && newsList.length >= 3) ||
                (activeTab === "3" && eventsList.length >= 3) ||
                (activeTab === "1" && reportList?.length >= 3)) && (
                <div className="view_all_btn">
                  <NormalButton
                    label="View All"
                    isPopup={true}
                    mainbg={true}
                    onClick={() => this.handleViewAll()}
                  />
                </div>
              )}
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getAllNews,
      getAllEvents,
      getAllReports,
    },
    dispatch
  );
};

let component = NewsAndEventsClass;

export const NewsAndEvents = connect(null, mapDispatchToProps)(component);
