import React, { Component } from "react";
import SimpleReactValidator from "simple-react-validator";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { externalReg, externalRoles } from "action/RegAct";
import { Toast } from "service/toast";
import { encryptData } from "service/helperFunctions";
import { PasswordStrengthMeter } from "component/common";
import CheckBox from "component/common/CheckBox";
class Other extends Component {
  constructor() {
    super();
    this.state = {
      organization_type: "",
      organization_name: "",
      contact_name: "",
      job_title: "",
      contact_number: "",
      organization_email_id: "",
      password: "",
      confirm_password: "",
      roles: [],
      passwordVisible: false,
      reEnterPasswordVisible: false,
      isPrivacy: false,
    };
  }

  validator = new SimpleReactValidator({
    element: (message) => (
      <span className="error-message text-danger fs-14">{message}</span>
    ),
    autoForceUpdate: this,
    validators: {
      password: {
        message: "The :attribute must be a valid format.",
        rule: (val, params, validator) => {
          return (
            validator.helpers.testRegex(
              val,
              /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$^+=!*()@%&]).{8,20}$/
            ) && params.indexOf(val) === -1
          );
        },
        messageReplace: (message, params) =>
          message.replace("", this.helpers.toSentence(params)),
        required: true,
      },
      checkPassword: {
        message: "Given :attribute does not match",
        rule: function (val, params) {
          return val === params[0];
        },
      },
    },
  });

  handleChange = ({ target: { name, value } }) => {
    this.setState({
      [name]: value,
    });
  };

  handlePhotoUpload = (e) => {
    this.setState({
      photo: e.target.files[0],
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.validator.allValid()) {
      const {
        organization_type,
        organization_name,
        contact_name,
        job_title,
        contact_number,
        organization_email_id,
        password,
        confirm_password,
        isPrivacy,
      } = this.state;
      let { backToLogi } = this.props;
      if (password === confirm_password) {
        if (isPrivacy) {
          this.props
            .externalReg({
              organizationType: organization_type,
              organizationName: organization_name,
              contactName: contact_name,
              jobTitle: job_title,
              emailId: organization_email_id,
              password: encryptData(password),
              mobileNumber: contact_number,
              isPrivacy: isPrivacy,
            })
            .then(() => {
              backToLogi(true);
            });
        } else {
          Toast({
            type: "error",
            message:
              "Please Accept Terms & Conditions and Privacy Policy",
            time: 5000,
          });
        }
      } else {
        let message = "Password does not match";
        Toast({ type: "error", message, time: 5000 });
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  componentDidMount() {
    this.props.externalRoles().then((data) => {
      this.setState({
        roles: data,
      });
    });
  }

  toggle = (key) => {
    this.setState((prevState) => ({
      [key]: !prevState[key],
    }));
  };

  render() {
    const {
      organization_type,
      organization_name,
      contact_name,
      contact_number,
      organization_email_id,
      password,
      confirm_password,
      passwordVisible,
      reEnterPasswordVisible,
      job_title,
      isPrivacy,
    } = this.state;
    return (
      <div className="d-flex flex-row mb-2 flex-column registration-area mt-3">
        <div className="row mb-2">
          <div className="col-md-6 col-12 my-2">
            <label>
              Organization type{" "}
              <span className="text-danger font-weight-bold">*</span>
            </label>
            <input
              type="text"
              className="form-control w-100"
              name="organization_type"
              value={organization_type}
              onChange={this.handleChange}
              autoComplete="nope"
            />
            {this.validator.message(
              "organization_type",
              organization_type,
              "required|alpha_space|min:3|max:30"
            )}
          </div>

          <div className="col-md-6 col-12 my-2">
            <label>
              Organization name{" "}
              <span className="text-danger font-weight-bold">*</span>
            </label>
            <input
              type="text"
              className="form-control w-100"
              name="organization_name"
              value={organization_name}
              onChange={this.handleChange}
              autoComplete="nope"
            />
            {this.validator.message(
              "organization_name",
              organization_name,
              "required|alpha_space|min:3|max:30"
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-12 my-2">
            <label>
              Contact Person name{" "}
              <span className="text-danger font-weight-bold">*</span>
            </label>
            <input
              type="text"
              className="form-control w-100"
              placeholder=""
              name="contact_name"
              value={contact_name}
              onChange={this.handleChange}
              autoComplete="nope"
            />
            {this.validator.message(
              "contact_name",
              contact_name,
              "required|alpha_space|min:3|max:30"
            )}
          </div>
          <div className="col-md-6 col-12 my-2">
            <label>
              Job title
              <span className="text-danger font-weight-bold">*</span>
            </label>
            <input
              type="text"
              className="form-control w-100"
              name="job_title"
              value={job_title}
              onChange={this.handleChange}
              autoComplete="nope"
            />
            {this.validator.message("job title", job_title, "required")}
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-12 my-2">
            <label>
              Contact number{" "}
              <span className="text-danger font-weight-bold">*</span>
            </label>
            <input
              type="text"
              className="form-control w-100"
              name="contact_number"
              value={contact_number}
              onChange={this.handleChange}
              maxLength={10}
              autoComplete="nope"
            />
            {this.validator.message(
              "contact_number",
              contact_number,
              "required|phone"
            )}
          </div>
          <div className="col-md-6 col-12 my-2">
            <label>
              {"Organization email id "}
              <span className="text-danger font-weight-bold">*</span>
            </label>
            <input
              type="text"
              className="form-control w-100"
              name="organization_email_id"
              value={organization_email_id}
              onChange={this.handleChange}
              autoComplete="off"
            />
            {this.validator.message(
              "organization_email_id",
              organization_email_id,
              "required|email"
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-12 my-2">
            <label>
              Password <span className="text-danger font-weight-bold">*</span>
            </label>
            <div className="input-group">
              <input
                type={passwordVisible ? "text" : "password"}
                className="form-control w-100"
                name="password"
                value={password}
                onChange={this.handleChange}
                autoComplete={passwordVisible ? "nope" : "new-password"}
              />
              <div className="input-group-addon right">
                <span
                  onClick={(e) => this.toggle("passwordVisible")}
                  className={`cursor-pointer icon-${
                    passwordVisible ? "eye" : "eye-hide"
                  } fs-24`}
                ></span>
              </div>
              {this.validator.message(
                "password",
                password,
                "required|min:7|max:15|password"
              )}
              <PasswordStrengthMeter data={password} />
            </div>
          </div>
          <div className="col-md-6 col-12 my-2">
            <label>
              Confirm password{" "}
              <span className="text-danger font-weight-bold">*</span>
            </label>
            <div className="input-group">
              <input
                type={reEnterPasswordVisible ? "text" : "password"}
                className="form-control w-100"
                name="confirm_password"
                value={confirm_password}
                onChange={this.handleChange}
                autoComplete={reEnterPasswordVisible ? "nope" : "new-password"}
              />
              <div className="input-group-addon right">
                <span
                  onClick={(e) => this.toggle("reEnterPasswordVisible")}
                  className={`cursor-pointer icon-${
                    reEnterPasswordVisible ? "eye" : "eye-hide"
                  } fs-24`}
                ></span>
              </div>
              {this.validator.message(
                "confirm password",
                confirm_password,
                `required|checkPassword:${password}`
              )}
            </div>
          </div>
          {password && (
            <div className="col-md-12">
              <b className="fs-14">
                Only the following special characters are allowed :
                #$^+=!*()@%&amp;
              </b>
            </div>
          )}
        </div>
        <div className="d-flex justify-content-center mt-4">
          <CheckBox
            label="I agree to the Terms & Conditions and Privacy Policy"
            onChange={this.handleChange}
            name="isPrivacy"
            isChecked={isPrivacy}
          />
        </div>
        <div className="d-flex justify-content-center mt-4">
          <input
            className="btn w-75 py-2 font-weight-bold"
            type="submit"
            value="Submit"
            onClick={this.handleSubmit}
          />
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      externalReg,
      externalRoles,
    },
    dispatch
  );
};
let component = Other;

export const ExternalReg = connect(null, mapDispatchToProps)(component);
