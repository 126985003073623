import React, { Component } from "react";
import { connect } from "react-redux";
import { Navbar } from "component/common";
import { ViewAllCourse } from "component/Acadamy";

export class ViewAllCourseClass extends Component {
  //life cycles
  componentDidMount = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  render() {
    return (
      <>
        <div className="normal-header">{/* <Navbar /> */}</div>
        <ViewAllCourse />
      </>
    );
  }
}

export const ViewAllCourses = connect(null, null)(ViewAllCourseClass);
