import React, { Component } from "react";
import { history } from "service/helpers";
import { Navbar } from "../component/common";
export class FpoLayout extends Component {
  componentDidMount() {
    window.scrollTo({
      top: 1,
      behavior: "smooth",
    });
    let authToken = localStorage.getItem("AuthToken");
    let isTCAccepted = localStorage.getItem("isTCAccepted") === "true";
    if (authToken) {
      if (!isTCAccepted) {
        history.push("/landing/home");
      }
    } else {
      history.push("/");
    }
  }

  render() {
    let { children } = this.props;
    let isTCAccepted = localStorage.getItem("isTCAccepted") === "true";
    return (
      <div className="fpo-layout">
        {isTCAccepted ? <Navbar /> : null}
        {children}
      </div>
    );
  }
}
