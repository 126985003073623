import React, { Component } from "react";
import { Navbar } from "../../component/common";
import { AssociationsView } from "../../component/associations";
import { connect } from "react-redux";
import { getAccessdata } from "service/helperFunctions";
import { UserAuth } from "helpers/UserAuth";
class AssociationsClass extends Component {
  componentDidMount() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  render() {
    let { accessData } = this.props;
    let permissionData = getAccessdata(accessData, "associationPartnerships");

    let {
      download = false,
      edit = false,
      createAccess = false,
    } = permissionData;

    return (
      <div>
        <div className="inner-header">
          {/* <Navbar /> */}
          <div className="h-100 w-100">
            <p className="d-flex justify-content-center align-items-center h-100 w-100 text-white fs-32 font-weight-bold">
              Associations & Partnerships
            </p>
          </div>
        </div>
        <AssociationsView
          downloadAccess={download}
          edit={edit}
          createAccess={createAccess}
          id={this.props.match.params.id}
        />
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  accessData: state.home.accessData,
});

let component = UserAuth(AssociationsClass, {
  service: "associationPartnerships",
});

export const Associations = connect(mapStateToProps, null)(component);
