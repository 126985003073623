import { Faq } from 'service/apiVariables';
import { Toast } from 'service/toast';
import { api } from 'service/api';
import { addQuery } from 'service/helperFunctions';

//Get All FAQs API
export const getAllFaqs = (query) => (dispatch, getState) => {
   return new Promise((resolve, reject) => {
      addQuery(query, Faq.getAllFaqs);
      api({ ...Faq.getAllFaqs })
         .then(({ data, message }) => {
            resolve(data);
         })
         .catch(({ message }) => {
            reject(Toast({ type: 'error', message }));
         });
   });
};
