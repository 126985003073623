import React, { Component } from "react";
import { Col, Row } from "reactstrap";
import { CustomDate } from "./CustomDate";
import moment from "moment";

import "./index.scss";
import { history } from "service/helpers";
import { getDescriptionData } from "service/helperFunctions";
const profile = require("assets/svg/fpoduelist.svg");

export class ComplianceCalenderRm extends Component {
  state = {
    calenderList: [],
    highlightDates: [],
    tasks: [],
    month: "",
    year: "",
    calenderContentstatus: [],
  };
  setMonthYear = ({ month = "12", year = "2020" }) => {
    this.setState({
      month,
      year,
    });
  };

  componentDidMount() {
    this.setState({
      calenderList: this.props.list,
      month: this.props.listDate.month,
      year: this.props.listDate.year,
    });
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.list !== this.props.list) {
      let listDate = [];
      let listContent = [];
      let listStatus = [];
      this.props.list.length > 0 && !this.props.isAuth
        ? this.props.list.map((list) => {
            listDate.push(new Date(list.dueDate));
            listContent.push(list.taskName);
          })
        : this.props.list.map((list) => {
            listDate.push(new Date(list.dueDate));
            listContent.push(list.taskName);
            listStatus.push(list.taskStatus);
          });
      this.setState({
        highlightDates: listDate,
        calenderList: this.props.list,
        tasks: listContent,
        calenderContentstatus: listStatus,
        month: this.props.listDate.month,
        year: this.props.listDate.year,
      });
    }
  }
  render() {
    const { calenderList, highlightDates, tasks, calenderContentstatus } =
      this.state;
    const { isAuth, FpoDueListRm } = this.props;
    return (
      <div className="compliance-calender-rm">
        <div className="container-sa">
          <Row>
            <Col sm="6">
              <div className="calender-section">
                <div className="title">{"Important dates to remember"}</div>
                <div className="calender-container">
                  <CustomDate
                    getList={this.props.getList}
                    list={highlightDates}
                    tasks={tasks}
                    setMonthYear={this.setMonthYear}
                    status={calenderContentstatus}
                    isAuth={this.props.isAuth}
                  />
                </div>
              </div>
            </Col>
            <Col sm="3">
              <div className="calender-events">
                <div className="list-title">
                  {this.props.listDate?.month
                    ? moment(this.props.listDate.month).format("MMMM")
                    : ""}{" "}
                  {this.props.listDate?.year}
                </div>
                {calenderList.length > 0 ? (
                  calenderList.map((list, index) => {
                    let status = list.taskStatus;
                    let fpoTasks = isAuth ? list.fpoTasks : list;
                    return (
                      <div
                        key={index}
                        className={`calender-list mb-3 d-flex justify-content-between bg-green-db`}
                      >
                        <div>
                          <div className="list-content mb-3">
                            Due on{" "}
                            {list.dueDate
                              ? moment(list.dueDate).format("DD MMM yyyy")
                              : "-"}
                          </div>
                          <div className="list-content">
                            Task name: {list.taskName}
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className="text-center mt-5">
                    <h5>No task for this month</h5>
                  </div>
                )}
              </div>
            </Col>
            <Col sm="3">
              <div className="fpo-due-list-contianer shadow">
                <div className="d-flex align-items-center justify-content-between mb-4">
                  <div className="container-title">
                    <h5>Due list of FPO</h5>
                  </div>
                  <div className="view-all">
                    <p onClick={() => history.push("/rm/fpoduelist")}>
                      View all
                    </p>
                  </div>
                </div>
                <div>
                  {FpoDueListRm ? (
                    FpoDueListRm.length > 0 &&
                    FpoDueListRm.map(
                      (
                        {
                          fpoDetails,
                          fpoTasks: { taskName, dueDate, dueDays },
                        },
                        index
                      ) => {
                        return (
                          <div className="" key={index}>
                            <Row>
                              <Col sm="3" className="pr-0">
                                <img
                                  className="list-img"
                                  src={
                                    fpoDetails.fpoLogoURL
                                      ? fpoDetails.fpoLogoURL
                                      : profile
                                  }
                                  alt="profile"
                                />
                              </Col>
                              <Col sm="9">
                                <div>
                                  <div>
                                    <h4 className="task-username fw-800">
                                      {fpoDetails && fpoDetails.fpoName
                                        ? getDescriptionData(
                                            fpoDetails.fpoName,
                                            25
                                          )
                                        : null}
                                    </h4>
                                  </div>
                                  <div>
                                    <h4 className="task-name">
                                      {getDescriptionData(taskName, 40)}
                                    </h4>
                                  </div>
                                  <div>
                                    <p className="task-due-date">
                                      Due on{" "}
                                      {moment(dueDate).format("D MMM YYYY")}
                                    </p>
                                  </div>
                                  <div>
                                    {dueDays !== null &&
                                    dueDays.includes("overdue") ? (
                                      <h3 className="task-due-days-red">
                                        {dueDays}
                                      </h3>
                                    ) : (
                                      <h3 className="task-due-days">
                                        {dueDays}
                                      </h3>
                                    )}
                                  </div>
                                </div>
                              </Col>
                            </Row>
                            {index + 1 !== FpoDueListRm.length && <hr />}
                          </div>
                        );
                      }
                    )
                  ) : (
                    <div className="d-flex justify-content-center fs-16 py-5 text-black-50 fw-600">
                      No users found
                    </div>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}
