import React, { Component } from "react";
import { getDescriptionData } from "service/helperFunctions";
import { history } from "service/helpers";
import { Swiper, SwiperSlide } from "swiper/react";
import "./index.scss";
const play = require("assets/svg/preIntroVideo/play.svg");
export class InformativeVideosPre extends Component {
  render() {
    const params = {
      slidesPerView: 4,
      spaceBetween: 30,
      loop: false,
      breakpoints: {
        500: {
          slidesPerView: 2,
        },
        768: {
          slidesPerView: 3,
        },
      },
    };
    const { videos = [] } = this.props;
    return (
      <div className="informative-container">
        <div className="title">Informative videos</div>
        <div className="card-container">
          <Swiper {...params}>
            {videos?.length > 0 &&
              videos.map((video, index) => {
                return (
                  <SwiperSlide key={index}>
                    <div className="card-item">
                      <div>
                        <img
                          className="card-img"
                          src={video.thumbnailImageUrl}
                        />
                      </div>
                      <div
                        className="play-button"
                        onClick={() => history.push("/login")}
                      >
                        <img className="play-icon" src={play} alt="play" />
                      </div>
                      <div
                        className={`info-content ${
                          (index + 1) % 2 !== 0 ? "dark" : "light"
                        }`}
                        dangerouslySetInnerHTML={{
                          __html:
                            video.description.length > 75
                              ? getDescriptionData(video.description, 74)
                              : video.description,
                        }}
                        onClick={() => history.push("/login")}
                      />
                    </div>
                  </SwiperSlide>
                );
              })}
          </Swiper>
        </div>
      </div>
    );
  }
}
