import React, { Component } from "react";

import { OurInfrastructureCard } from "./OurInfrastructureCard";

import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from "swiper";

import "./OurInfrastructure.scss";

export class OurInfrastructure extends Component {
  constructor() {
    super();
    SwiperCore.use([Navigation]);
  }
  render() {
    const params = {
      slidesPerView: 2,
      spaceBetween: 30,
      loop: false,
      draggable: true,
      breakpoints: {
        600: {
          slidesPerView: 2,
        },
        768: {
          slidesPerView: 4,
        },
        1100: {
          slidesPerView: 6,
        },
      },
    };
    let { infraMapping } = this.props;
    return (
      <>
        <div className="infrastruture-wrapper">
          <div className="container-sa">
            <h4>Our Infrastructure</h4>
            <div className="row">
              <Swiper {...params} className="w-100">
                {infraMapping.map((data, index) => {
                  let { infraDetails } = data;
                  return (
                    <SwiperSlide key={index}>
                      <div className="">
                        <OurInfrastructureCard
                          img={infraDetails.InfrastructureIconUrl}
                          title={infraDetails.infrastructureName}
                        />
                      </div>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div>
          </div>
        </div>
      </>
    );
  }
}
