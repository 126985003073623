import React from "react";

import "./style.scss";

const FigCard = ({
  name,
  emailId,
  mobileNumber,
  figId,
  leadName,
  onEdit,
  onView,
  isMapping,
  editAccess,
  totalMemberMapped = 0,
}) => {
  return (
    <div className="fig_card">
      {/* <div className="fig_detail">
        <span>FIG ID : &nbsp;</span>
        <b>{figId}</b>
      </div> */}
      <div className="fig_detail">
        <span>Name : &nbsp;</span>
        <b>{name}</b>
      </div>
      <div className="fig_detail">
        <span>Email ID : &nbsp;</span>
        <b>{emailId}</b>
      </div>
      <div className="fig_detail">
        <span>Mobile Number : &nbsp;</span>
        <b>{mobileNumber}</b>
      </div>
      <div className="fig_detail">
        <span>Lead Name : &nbsp;</span>
        <b>{leadName}</b>
      </div>
      {isMapping && (
        <div className="fig_detail">
          <span>Members Mapped : &nbsp;</span>
          <b>{totalMemberMapped}</b>
        </div>
      )}
      {isMapping && (
        <div className="d-flex justify-content-between">
          <div
            className="mr-4 cursor-pointer fs-16 fw-600 text-black-50"
            onClick={() => onView(figId)}
          >
            View
          </div>
          {editAccess && (
            <div
              className="fs-16 text-black-50 fw-600 cursor-pointer"
              onClick={() => onEdit(figId)}
            >
              Map Member's
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default FigCard;
