import { Feedback } from 'service/apiVariables';
import { Toast } from 'service/toast';
import { api } from 'service/api';

//Add Feedback API
export const addFeedback = (body) => (dispatch, getState) => {
   return new Promise((resolve, reject) => {
      api({ ...Feedback.addFeedback, body })
         .then(({ data, message }) => {
            resolve(data);
            Toast({ type: 'success', message, time: 5000 });
         })
         .catch(({ message }) => {
            reject(Toast({ type: 'error', message }));
         });
   });
};
