import React, { Component } from "react";
import { Navbar, CommonPageLoader } from "component/common";
import "./style.scss";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import CustomEvents from "highcharts-custom-events";
import { ToggleSlider } from "./toggleSlider.js";
import { history } from "service/helpers";
import GoogleMap from "google-map-react";
import arrow_left from "assets/svg/add_member/arrow_left.svg";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getWeatherDetails } from "action/PricingandWeatherAct";
import moment from "moment";
// import moment from "moment-timezone";
const Marker = ({ children }) => children;

CustomEvents(Highcharts);
class WeatherClass extends Component {
  state = {
    options: {
      chart: {
        type: "area",
        height: 200,
      },
      title: {
        text: "",
      },
      xAxis: {
        categories: [],
        labels: {
          events: {
            click: (pos) => {
              let index =
                this.state.hourlyWeatherReport.length > 0 &&
                this.state.hourlyWeatherReport.findIndex((report) => {
                  return (
                    moment(report.validtimelocal).format("H:SS") ==
                    pos.target.innerHTML
                  );
                });
              this.setState({
                weatherDetailHourIndex: index !== -1 ? index : 0,
              });
            },
          },
          style: {
            cursor: "pointer",
          },
        },
      },
      yAxis: {
        title: {
          enabled: false,
        },
      },
      plotOptions: {
        line: {
          dataLabels: {
            enabled: true,
          },
        },
      },
      series: [
        {
          name: "Temperature",
          data: [],
        },
      ],
    },
    popoverOpen: false,
    isApiLoading: false,
    isLoading: false,
    dailyWeatherReport: [],
    hourlyWeatherReport: [],
    fpoLocation: {},
    weatherDetailHourIndex: 0,
    fpoLocationDetails: {},
  };

  static defaultProps = {
    center: [13.0619, 80.2468],
    zoom: 15,
    greatPlaceCoords: {
      lat: 13.0619,
      lng: 80.2468,
    },
  };

  //life cycle
  componentDidMount() {
    this._getWeatherDetails();
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  //get weather details
  _getWeatherDetails = () => {
    let { getWeatherDetails } = this.props;
    this.setState({ isApiLoading: true });
    getWeatherDetails().then((data) => {
      this.setState(
        {
          dailyWeatherReport: data.DailyWeatherReport,
          hourlyWeatherReport: data.HourlyWeatherReport,
          isApiLoading: false,
          isLoading: true,
          fpoLocation: data.fpoLocation,
          fpoLocationDetails: data.fpoLocationDetails,
        },
        () => {
          this.setGraphData(data.HourlyWeatherReport);
        }
      );
    });
  };

  //set data in graph
  setGraphData = (reportList) => {
    let { options } = this.state;
    let optionsData = reportList;
    let { xAxis = {}, series = [] } = options;
    xAxis.categories = [];
    series[0].data = [];
    this.setState({
      options,
    });
    optionsData.map((list) => {
      xAxis.categories.push(moment(list.validtimelocal).format("H:SS"));
      series[0].data.push(list.temperature ? parseInt(list.temperature) : 0);
    });
    this.setState({
      options,
      isLoading: false,
    });
  };

  //toggle area
  toggle = () => {
    this.setState((prevState) => ({
      popoverOpen: !prevState.popoverOpen,
    }));
  };

  render() {
    let {
      options,
      fpoLocation,
      dailyWeatherReport,
      hourlyWeatherReport,
      isApiLoading,
      isLoading,
      weatherDetailHourIndex,
      fpoLocationDetails = {},
    } = this.state;

    // let {
    //   cloudcover = "",
    //   datetime = "",
    //   iconcode = "",
    //   precipchance = "",
    //   relativehumidity = "",
    //   temperature = "",
    //   validTimeLocal = "",
    //   windspeed = "",
    //   wxphraselong = "",
    // } = hourlyWeatherReport.length > 0
    //   ? hourlyWeatherReport[weatherDetailHourIndex]
    //     ? hourlyWeatherReport[weatherDetailHourIndex]
    //     : hourlyWeatherReport[0]
    //   : [];

    let {
      cloudcover = "",
      datetime = "",
      iconcode = "",
      precipchance = "",
      relativehumidity = "",
      temperature = "",
      validTimeLocal = "",
      windspeed = "",
      wxphraselong = "",
    } = hourlyWeatherReport.length > 0
      ? hourlyWeatherReport.find(({ validtimelocal }) => {
          return (
            moment(validtimelocal).format("H") == moment(new Date()).format("H")
          );
        })
      : {};

    let { lat = "", lon = "" } = fpoLocation;

    let {
      districtNames = {},
      stateMaster = {},
      pinCodes = {},
    } = fpoLocationDetails !== null ? fpoLocationDetails : {};

    return (
      <div>
        <div className="normal-header">{/* <Navbar /> */}</div>
        <div className="container-sa">
          <div className="d-flex justify-content-between py-3 ">
            <div
              onClick={() => history.push("/")}
              className="d-flex cursor-pointer align-items-center"
            >
              <img className="cursor-pointer" src={arrow_left} alt="back" />
              <p className="ml-3 add-member-text-back mb-0">{"Weather"}</p>
            </div>
          </div>
        </div>
        <div className="weather-section bg-blue-f9">
          {!isApiLoading ? (
            <div className="container-sa">
              <div className="row">
                <div className="col-8">
                  <h4 className="text-green-18 fs-24 line-height-28 font-weight-bold">
                    {"Hourly weather"}
                  </h4>
                  <p className="text-black-4f fs-20 line-height-30 fw-500 mb-4">
                    {"Today is generally sunny, despite a few afternoon clouds"}
                  </p>
                  <div className="d-flex flex-column p-4 weather-area mb-3">
                    <div className="d-flex justify-content-between align-items-center">
                      <div>
                        <p className="text-green-18 fs-20 line-height-23 fw-500 mb-2">
                          {/* {moment(datetime).format("dddd, Do MMMM, YYYY")} */}
                          {moment(new Date()).format("dddd, Do MMMM, YYYY")}
                        </p>
                      </div>
                      <div className="d-flex align-items-center">
                        <p className="text-black fs-22 line-height-28 font-weight-bold mb-0 pr-4">
                          {/* {moment(validTimeLocal).format("h A")} */}
                          {moment(new Date()).format("h A")}
                        </p>
                        <i
                          className="icon-reload fs-22 cursor-pointer"
                          onClick={() => this._getWeatherDetails()}
                        ></i>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-4">
                        <p className="text-black fs-16 line-height-19 fw-500 mb-2 pb-1">
                          {wxphraselong}
                        </p>
                        <div className="d-flex align-items-center">
                          {iconcode !== "" ? (
                            <img
                              height="70"
                              width="70"
                              src={require(`assets/svg/WeatherSvgs/${
                                iconcode.length === 1
                                  ? `0${iconcode}`
                                  : iconcode
                              }.svg`)}
                              alt="cloud.svg"
                            />
                          ) : null}
                          <p className="ml-2 pl-1 mb-0 text-black-1c line-height-48 fs-36 font-weight-bold">
                            {temperature}&deg;C
                          </p>
                        </div>
                        {/* <p className="text-black-4f fs-16 line-height-19 fw-500 mb-2 pb-1">
                                                    {'Thunder Not Expected'}
                                                </p> */}
                      </div>
                      <div className="col-3 d-flex flex-column justify-content-center">
                        <div className="row mb-2">
                          <div className="col-2 px-1 text-center">
                            <i className="icon-wind-navigate fs-18"></i>
                          </div>
                          <div className="col-10">
                            <p className="text-black-4f fs-16 line-height-19 fw-500 mb-0">
                              {windspeed}km/hr
                            </p>
                          </div>
                        </div>
                        <div className="row mb-2">
                          <div className="col-2 px-1 text-center">
                            <i className="icon-carbon-wind fs-22"></i>
                          </div>
                          <div className="col-10">
                            <p className="text-black-4f fs-16 line-height-19 fw-500 mb-0">
                              Precipitation {precipchance}%
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-3 d-flex flex-column justify-content-center">
                        <div className="row mb-2">
                          <div className="col-2 px-1 text-center">
                            <i className="icon-wind fs-18"></i>
                          </div>
                          <div className="col-10">
                            <p className="text-black-4f fs-16 line-height-19 fw-500 mb-0">
                              Clouds cover {cloudcover}%
                            </p>
                          </div>
                        </div>
                        <div className="row mb-2">
                          <div className="col-2 px-1 text-center">
                            <i className="icon-humidity fs-20"></i>
                          </div>
                          <div className="col-10">
                            <p className="text-black-4f fs-16 line-height-19 fw-500 mb-0">
                              Humidity {relativehumidity}%
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mt-4 mb-3">
                      <div className="w-100 background-price-charts">
                        {isLoading ? (
                          <div className="d-flex justify-content-center align-items-center py-5">
                            <div className="spinner-border ml-2 spinner-border-sm"></div>
                          </div>
                        ) : (
                          <HighchartsReact
                            highcharts={Highcharts}
                            options={options}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  {dailyWeatherReport && dailyWeatherReport.length !== 0 ? (
                    <div className="pt-3 toggle-area">
                      <h4 className="text-green-18 fs-24 line-height-28 font-weight-bold">
                        Daily Weather
                      </h4>
                      {dailyWeatherReport.map((list, index) => (
                        <ToggleSlider dailyData={list} key={index} />
                      ))}
                    </div>
                  ) : null}
                </div>
                <div className="col-4 ">
                  <div className="google-map">
                    <GoogleMap
                      bootstrapURLKeys={{
                        key: "AIzaSyAYb0HaLfz3pOvVAE4ovmFtz0kNt5baOPo",
                      }}
                      defaultCenter={
                        lat !== "" && lon !== ""
                          ? [lat, lon]
                          : this.props.center
                      }
                      defaultZoom={this.props.zoom}
                      options={{
                        fullscreenControl: false,
                        zoomControl: false,
                      }}
                    >
                      <Marker
                        lat={lat !== "" ? lat : 13.0619}
                        lng={lon !== "" ? lon : 80.2468}
                      >
                        <div className="search-map-pointer">
                          <div className="rounded-circle map-profile p-1 cursor-pointer search-map-pointer">
                            {" "}
                          </div>
                        </div>
                      </Marker>
                    </GoogleMap>
                    <div className="fpo-map-detail">
                      <h1>
                        {districtNames !== null
                          ? districtNames.districtName
                          : ""}
                        {districtNames !== null ? ", " : ""}
                        {stateMaster !== null ? stateMaster.stateName : ""}
                        {stateMaster !== null ? ", " : ""}
                        {pinCodes !== null ? pinCodes.pincode : ""}
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <CommonPageLoader />
          )}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getWeatherDetails,
    },
    dispatch
  );
};

export const Weather = connect(null, mapDispatchToProps)(WeatherClass);
