import React, { Component } from "react";

import certificate from "assets/images/certificate.png";
// Redux Connection
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import "./certificates.scss";
import { certificatesList, downloadCerificate } from "action/certificates";
import Moment from "react-moment";
import { getDescriptionData } from "service/helperFunctions";
export class CertificatesClass extends Component {
  state = {
    certificatesList: [],
    query: {
      page: 1,
      limit: 20,
    },
  };

  //onload components
  componentWillMount() {
    this.getCertificatesList();
  }

  //get Certificates List
  getCertificatesList = () => {
    let { query } = this.state;
    this.props.certificatesList(query).then(({ data: { results }, status }) => {
      this.setState({ certificatesList: results });
    });
  };

  //handle Certificate Download
  handleCertificateDownload = (id) => {
    this.props.downloadCerificate(id).then((data) => {
      window.open(
        "https://portal.fpogateway.com/academy/api/v1/download/certificate?fileName=" +
          data.filename,
        "_blank"
      );
    });
  };

  render() {
    let { certificatesList } = this.state;
    return (
      <div className="certificates">
        <div className="container-sa">
          {certificatesList.length !== 0 ? (
            <div className="row">
              {certificatesList.map(
                ({ course: { title }, courseId, completionDate }, index) => (
                  <div
                    className="col-4 mt-3"
                    key={index}
                    onClick={() => this.handleCertificateDownload(courseId)}
                  >
                    <div className="certificate-card">
                      <div className="certificate-display-area">
                        <img src={certificate} alt="certificate" />
                      </div>
                      <div className="course-detail-area">
                        <p className="mb-2 fs-16 line-height-19 text-white font-weight-bold">
                          {getDescriptionData(title, 30)}
                        </p>
                        <p className="mb-0 fs-16 line-height-19 text-white font-weight-bold">
                          <Moment format="DD/MM/YYYY">{completionDate}</Moment>
                        </p>
                      </div>
                    </div>
                  </div>
                )
              )}
            </div>
          ) : (
            <p className="py-5 my-3 text-black-50 fs-22 line-height-25 text-center">
              No data found !!!
            </p>
          )}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      certificatesList,
      downloadCerificate,
    },
    dispatch
  );
};

export const Certificates = connect(
  null,
  mapDispatchToProps
)(CertificatesClass);
