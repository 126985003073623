import React, { Component } from "react";
import {
  SamunnatiReg,
  ExternalReg,
  FpoReg,
  IndividualReg,
} from "../../component/auth/registration";
import "../../component/auth/button.scss";

import { history } from "service/helpers";

const Logo = require("../../assets/svg/logo.svg");
const fpo = require("../../assets/svg/registration_logo.svg");

export class Register extends Component {
  state = {
    user_type: "fpo",
    backToLogi: false,
  };

  //handle Radio buttons
  handleRadio = ({ target: { value } }) => {
    this.setState({
      user_type: value,
    });
  };

  //handle Back To login
  handleBackTOlogin = () => {
    this.setState({ backToLogi: true });
  };

  render() {
    const { user_type, backToLogi } = this.state;
    return (
      <div className="center-view-register">
        <div className="register-card shadow rounded ">
          <div className=" d-flex align-items-center flex-column">
            <img className="w-50" src={Logo} alt="Logo" height="75" />
          </div>
          {!backToLogi ? (
            <>
              {/* <div className="d-flex align-items-center justify-content-center py-3">
                <img
                  className="register-logo mr-mc-3 mr-2"
                  src={fpo}
                  alt="fpo"
                />
                <p className="fs-24 line-height-29 text-black fw-500 mb-0">
                  {user_type === "fpo"
                    ? "FPO"
                    : user_type === "other_organization"
                    ? "Other organization"
                    : "Individual"}
                  {" registration request form"}
                </p>
                <p className="fs-24 line-height-29 text-black fw-500 mb-0">
                  {user_type === "samunnati"
                    ? "Samunnati"
                    : user_type === "fpo"
                    ? "FPO"
                    : user_type === "other_organization"
                    ? "Other organization"
                    : "Individual"}
                  {" registration request form"}
                </p>
              </div> */}
              {/* <div className="d-flex justify-content-center flex-row mb-2 flex-wrap">
                <div className="custom-control custom-radio d-flex align-items-center pb-3">
                  <input
                    type="radio"
                    id="samunnati"
                    name="user_type"
                    value="samunnati"
                    checked={user_type === "samunnati"}
                    onChange={this.handleRadio}
                  />
                  <label className="font-weight-bold pl-1 mb-0">
                    Samunnati
                  </label>
                </div>
                <div className="custom-control custom-radio d-flex align-items-center pb-3">
                  <input
                    type="radio"
                    id="fpo"
                    name="user_type"
                    value="fpo"
                    checked={user_type === "fpo"}
                    onChange={this.handleRadio}
                  />
                  <label className="font-weight-bold pl-1 mb-0">FPO</label>
                </div>
                <div className="custom-control custom-radio d-flex align-items-center pb-3">
                  <input
                    type="radio"
                    id="other_organization"
                    name="user_type"
                    value="other_organization"
                    checked={user_type === "other_organization"}
                    onChange={this.handleRadio}
                  />
                  <label className="font-weight-bold pl-1 mb-0">
                    Other organization
                  </label>
                </div>
                <div className="custom-control custom-radio d-flex align-items-center pb-3">
                  <input
                    type="radio"
                    id="individual"
                    name="user_type"
                    value="individual"
                    checked={user_type === "individual"}
                    onChange={this.handleRadio}
                  />
                  <label className="font-weight-bold pl-1 mb-0">
                    Individual
                  </label>
                </div>
              </div> */}
              <div className=" d-flex justify-content-center flex-column">
                {user_type === "samunnati" && (
                  <SamunnatiReg backToLogi={this.handleBackTOlogin} />
                )}
                {user_type === "fpo" && (
                  <FpoReg backToLogi={this.handleBackTOlogin} />
                )}
                {user_type === "other_organization" && (
                  <ExternalReg backToLogi={this.handleBackTOlogin} />
                )}
                {user_type === "individual" && (
                  <IndividualReg backToLogi={this.handleBackTOlogin} />
                )}
              </div>
              <p className="text-center fs-18 pt-3">
                Already a member?{" "}
                <b
                  className="cursor-pointer"
                  onClick={() => {
                    history.push("/login");
                  }}
                >
                  <u>Log in</u>
                </b>
              </p>
              <div className="d-flex justify-content-center pt-2 align-items-center flex-wrap">
                <p className="fs-16 fw-600 cursor-pointer mb-0 px-2">
                  <a
                    href="/termsandcondition"
                    target="_bank"
                    className="text-black text-underline"
                  >
                    Terms & Conditions
                  </a>
                </p>
                <p className="fs-16 fw-600 cursor-pointer mb-0 px-2">
                  <a
                    href="/privacypolicy"
                    target="_bank"
                    className="text-black text-underline"
                  >
                    Privacy Policy
                  </a>
                </p>
              </div>
            </>
          ) : (
            <>
              <div className=" d-flex justify-content-center flex-row mb-2 mt-4">
                <div className="custom-control custom-radio d-flex align-items-center">
                  <h4 className="text-center">
                    {user_type == "fpo"
                      ? "Your FPO has been successfully registered. You will be able to login after Admin approval."
                      : "Your registration is successfully done"}
                  </h4>
                </div>
              </div>

              <div className="d-flex justify-content-center mt-4">
                <input
                  className="btn w-75 py-2 font-weight-bold"
                  type="submit"
                  value="Back to login"
                  onClick={() => {
                    history.push("/login");
                  }}
                />
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
}
