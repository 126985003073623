import React from 'react';

import './ourCrops.scss';

export const OurCropsCard = ({ img, title, className }) => {
   return (
      <>
         <div className={`our-crop-card ${className}`}>
            <div className='img-thumb-about'>
               <img src={img} alt='img-thumb' />
            </div>
            <p>{title}</p>
         </div>
      </>
   );
};
