import React, { Component } from "react";

import {
  myLearningApi,
  courseListApi,
  academyHomePageAPI,
  intoducingVideo,
  blogListApi,
} from "action/Acadamy";

import {
  Banner,
  AcadamyMenu,
  AcadamyInfo,
  OnGoingCourses,
  TrendingNow,
  LatestCourses,
  Blog,
  TrendingVideo,
} from "../../component/Acadamy";
import { getNewsAndTestimonals } from "action/HomeAct";
import { history } from "service/helpers";

import { EventNewsSection } from "../../component/fpo/home";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

export class AcadamyHomeClass extends Component {
  constructor() {
    super();
    this.state = {
      trendingCourseData: [],
      OnGoingCoursesData: [],
      trendingVideos: [],
      sortedValue: "",
      introducingVideoData: {},
      newsAndTestimonals: {},
      blogList: [],
    };
  }

  componentWillMount() {
    if (
      localStorage.getItem("isLighter") != null &&
      localStorage.getItem("isLighter") == 1
    ) {
      history.push("/");
    }
  }

  componentDidMount = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    this.props.myLearningApi("filter/ongoing").then((data) => {
      this.setState({ OnGoingCoursesData: data });
    });
    this.props.courseListApi().then((data) => {
      this.setState({ trendingCourseData: data });
    });
    this.props.academyHomePageAPI().then((data) => {
      this.setState({
        trendingVideos: data.trendingVideos,
      });
    });
    this.props.intoducingVideo().then((data) => {
      this.setState({ introducingVideoData: data });
    });
    this.props.getNewsAndTestimonals().then((data) => {
      this.setState({
        newsAndTestimonals: data,
      });
    });
    this.props.blogListApi().then((data) => {
      this.setState({ blogList: data.results });
    });
  };

  handleChange = (sortedValue) => {
    this.setState({ sortedValue });
  };

  render() {
    let {
      trendingCourseData,
      OnGoingCoursesData,
      trendingVideos,
      sortedValue,
      introducingVideoData,
      newsAndTestimonals,
      blogList,
    } = this.state;
    return (
      <>
        <Banner />
        <AcadamyMenu handleChange={this.handleChange} />
        {introducingVideoData &&
        introducingVideoData.introVideoId &&
        introducingVideoData.introVideoId !== null ? (
          <AcadamyInfo
            introducingVideoData={introducingVideoData}
            blogList={blogList}
          />
        ) : null}
        {sortedValue === "" ? (
          <div className="bg-blue-f9">
            {OnGoingCoursesData.length > 0 ? (
              <OnGoingCourses
                OnGoingCoursesData={OnGoingCoursesData}
                title={"Ongoing courses"}
              />
            ) : null}

            {trendingCourseData.length > 0 ? (
              <TrendingNow trendingCourseData={trendingCourseData} />
            ) : null}

            <Blog />

            <LatestCourses trendingCourseData={trendingCourseData} />

            {trendingVideos.length !== 0 ? (
              <TrendingVideo trendingVideos={trendingVideos} />
            ) : null}

            <EventNewsSection
              news={newsAndTestimonals.news}
              events={newsAndTestimonals.events}
            />
          </div>
        ) : null}
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      myLearningApi,
      courseListApi,
      academyHomePageAPI,
      intoducingVideo,
      getNewsAndTestimonals,
      blogListApi,
    },
    dispatch
  );
};

let component = AcadamyHomeClass;

export const AcadamyHome = connect(null, mapDispatchToProps)(component);
