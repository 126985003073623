import { Gateway, RM } from "service/apiVariables";
import { addQuery } from "service/helperFunctions";
import { Toast } from "service/toast";
import { api } from "service/api";
import { rmCropType } from "service/actionType";

export const onGetAllMasters = () => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    api({ ...RM.getAllMasters })
      .then(({ data }) => {
        dispatch({ type: "GRADING_MASTERS", payload: data });
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

export const setDistrictswithState = (payload) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    dispatch({ type: "DISTRICTS_WITH_STATEWISE", payload });
  });
};

const modifySurveyDetails = (surveyDetails) => {
  if (Object.keys(surveyDetails).length) {
    if (surveyDetails?.facilitiesMapping) {
      surveyDetails.facilities1 = surveyDetails?.facilitiesMapping?.reduce(
        (acc, facilitie, index) => {
          if (facilitie.facilitiesMaster.category === "Governance") {
            acc.push(facilitie.facilityId);
          }
          return acc;
        },
        []
      );
      surveyDetails.facilities2 = surveyDetails?.facilitiesMapping?.reduce(
        (acc, facilitie, index) => {
          if (facilitie.facilitiesMaster.category === "Outreach") {
            acc.push(facilitie.facilityId);
          }
          return acc;
        },
        []
      );
      surveyDetails.facilities3 = surveyDetails?.facilitiesMapping?.reduce(
        (acc, facilitie, index) => {
          if (facilitie.facilitiesMaster.category === "Operations") {
            acc.push(facilitie.facilityId);
          }
          return acc;
        },
        []
      );
    }
    if (surveyDetails.positionMapping) {
      surveyDetails.positions = surveyDetails.positionMapping?.map(
        (position) => position.positionId
      );
    }
    if (surveyDetails.productsMapping) {
      surveyDetails.products = surveyDetails.productsMapping?.map(
        (product) => product.productId
      );
    }
    if (surveyDetails?.licensesMapping) {
      surveyDetails.licenses = surveyDetails?.licensesMapping?.map(
        (license) => license.licenseId
      );
    }
    if (surveyDetails.cropsMapping && surveyDetails.cropsMapping.length > 0) {
      surveyDetails.crops = surveyDetails.cropsMapping;
    }
  }
  return surveyDetails;
};

export const onGetSurveyDetails = (payload) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    RM.getSurveyDetails.id = payload.fpoId;
    api({ ...RM.getSurveyDetails })
      .then(({ message, data }) => {
        data.surveyDetails = modifySurveyDetails(data.surveyDetails);
        //Toast({ type: 'success', message })
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

export const onPostSurveyDetails = (body) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    RM.postSurveyDetails.id = body.fpoId;
    api({ ...RM.postSurveyDetails, body })
      .then(({ message, data }) => {
        let _data = modifySurveyDetails(data);
        Toast({ type: "success", message });

        resolve(_data);
      })
      .catch(({ error, message, status }) => {
        if (status === 400) {
          if (error instanceof Object) {
            Object.keys(error).forEach((key, i) => {
              message += " " + error[key];
            });
          }
        }
        reject(Toast({ type: "error", message }));
      });
  });
};

export const getRMFPOList = (query) => (dispatch, getState) => {
  addQuery(query, RM.getRMFPOList);
  return new Promise((resolve, reject) => {
    api({ ...RM.getRMFPOList })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

export const getFPOMemberList = (id, query) => (dispatch, getState) => {
  RM.getFPOMemberList.id = id;
  addQuery(query, RM.getFPOMemberList);
  return new Promise((resolve, reject) => {
    api({ ...RM.getFPOMemberList })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

export const getRMFPOData = (id) => (dispatch, getState) => {
  RM.getRMFPOData.id = id;
  return new Promise((resolve, reject) => {
    api({ ...RM.getRMFPOData })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

export const getRMFPOMemberData = (id) => (dispatch, getState) => {
  RM.getRMFPOMemberData.id = id;
  return new Promise((resolve, reject) => {
    api({ ...RM.getRMFPOMemberData })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

export const updateRMFPOMemberData = (id, body) => (dispatch, getState) => {
  RM.updateRMFPOMemberData.id = id;
  return new Promise((resolve, reject) => {
    api({ ...RM.updateRMFPOMemberData, body })
      .then(({ data, message }) => {
        resolve(data);
        Toast({ type: "success", message });
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

export const addKYMNew = (body) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    api({ ...RM.addKYMNew, body })
      .then(({ data, message }) => {
        resolve(data);
        Toast({ type: "success", message });
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

export const getCropsCategoryRedux = (query) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    addQuery(query, RM.getCropsCategory);
    api({ ...RM.getCropsCategory })
      .then(({ data }) => {
        let categoryData = [];
        data.forEach((list) =>
          categoryData.push({ value: list.id, label: list.name })
        );
        dispatch({ type: rmCropType.categoryOptions, payload: categoryData });
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

export const getCropsClassificationRedux = (query) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    addQuery(query, RM.getCropsClassification);
    api({ ...RM.getCropsClassification })
      .then(({ data }) => {
        let classificationData = [];
        data.forEach((list) =>
          classificationData.push({
            value: list.id,
            label: list.name,
            masterID: list.kymClassiMasterId,
          })
        );
        dispatch({
          type: rmCropType.cropOptions,
          payload: classificationData,
        });
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

export const getCropsCategory = (query) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    addQuery(query, RM.getCropsCategory);
    api({ ...RM.getCropsCategory })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

export const getCropsClassification = (query) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    addQuery(query, RM.getCropsClassification);
    api({ ...RM.getCropsClassification })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

export const getGradingDetails = (id) => (dispatch, getState) => {
  RM.getGradingDetails.id = id;
  return new Promise((resolve, reject) => {
    api({ ...RM.getGradingDetails })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

export const getGradingDropdownList = (id) => (dispatch, getState) => {
  RM.getGradingDropdownList.id = id;
  return new Promise((resolve, reject) => {
    api({ ...RM.getGradingDropdownList })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

export const getGradingGraphDetails = (id, query) => (dispatch) => {
  RM.getGradingGraphDetails.id = id;
  return new Promise((resolve, reject) => {
    addQuery(query, RM.getGradingGraphDetails);
    api({ ...RM.getGradingGraphDetails })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};
