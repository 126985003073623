import { Home } from "service/apiVariables";
import { Toast } from "service/toast";
import { api } from "service/api";
import { HomeActionType } from "service/actionType";
import { addQuery } from "service/helperFunctions";

export const getNewsAndTestimonals = () => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    api({ ...Home.getNewsAndTestimonals })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

export const getAcademy = () => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    api({ ...Home.getAcademy })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

export const getSuccessStoryById = (id) => (dispatch, getState) => {
  let { getSuccessStoryById } = Home;

  getSuccessStoryById.id = id;

  return new Promise((resolve, reject) => {
    api({ ...Home.getSuccessStoryById })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

export const getCalenderListPost =
  (query) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      addQuery(query, Home.getCalenderListPost);

      api({ ...Home.getCalenderListPost })
        .then(({ data, message }) => {
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

export const getComplianceCalender =
  (query) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      addQuery(query, Home.getComplianceCalender);

      api({ ...Home.getComplianceCalender })
        .then(({ data, message }) => {
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

export const updateCalenderTask = (body) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    api({ ...Home.updateCalenderTask, body })
      .then(({ status, message }) => {
        resolve(status);
        Toast({ type: "success", message, time: 5000 });
      })
      .catch(({ message, status }) => {
        resolve(status);
        reject(Toast({ type: "error", message }));
      });
  });
};

export const complianceNotification = () => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    api({ ...Home.complianceNotification })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

export const forumIdChange = (payload) => (dispatch) => {
  dispatch({ type: HomeActionType.ForumId, payload });
};
