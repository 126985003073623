import React, { Component } from 'react';
import { history } from 'service/helpers';
import { getDescriptionData } from 'service/helperFunctions';
const clock = require('../../../../assets/svg/academy/clock.svg');

export class Card extends Component {
   render() {
      let { course = {} } = this.props;

      let {
         img = '',
         body = '',
         title = '',
         time = '',
         chapters = '',
         courseId = '',
      } = course;

      return (
         <div
            className='card card-width cursor-pointer'
            onClick={() => history.push(`/academy/courses/${courseId}`)}
         >
            <div className=''>
               <img className='card-img-top' src={img} alt='course.svg' />
            </div>
            <div className='card-body mb-2 py-3'>
               <div className='orange card-heading'>
                  {getDescriptionData(title, 35)}
               </div>
               <div
                  className='card-content mb-4'
                  dangerouslySetInnerHTML={{
                     __html: getDescriptionData(body, 65),
                  }}
               ></div>
               <div className='card-success-footer w-100'>
                  <div className='row w-100'>
                     <div className='col-6 pr-0'>
                        <div className='d-flex'>
                           <img className='mr-2' src={clock} alt='clock.svg' />
                           <div className='card-time'>{time}</div>
                        </div>
                     </div>
                     <div className='col-6 pl-0 pr-4'>
                        <div className='font-weight-bold card-chapters d-flex justify-content-end'>
                           {chapters}
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      );
   }
}
