import React, { Component } from "react";
import { Navbar } from "../component/common";
export class PreLoginLayout extends Component {
  render() {
    let { children } = this.props;
    return (
      <>
        <Navbar />
        {children}
      </>
    );
  }
}
