import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getAllPlan } from "action/KeyFinancialAct";
import { getAllPlanRm } from "action/RmAct";
import { getFilePathDownload } from "service/api";
import { CommonPageLoader, NormalButton } from "component/common";
import { history } from "service/helpers";
import pdf from "assets/svg/KeyFinancials/pdf.svg";
import xlsx from "assets/svg/KeyFinancials/xlsx.png";
import docx from "assets/svg/KeyFinancials/docx.jpg";
import { getAccessdata } from "service/helperFunctions";

import "./planlist.scss";

export class PlanListClass extends Component {
  constructor(props) {
    super();
    this.state = {
      plans: [],
      isLoading: false,
      hover: [false, false, false],
    };
  }
  handlePlan = () => {
    const { id } = this.props;
    this.setState({ isLoading: true });
    id
      ? this.props.getAllPlanRm(id).then((data) => {
          this.setState({
            plans: data.rows,
            isLoading: false,
          });
        })
      : this.props.getAllPlan().then((data) => {
          this.setState({
            plans: data.rows,
            isLoading: false,
          });
        });
  };

  toggleHover = (id) => {
    let hover = this.state.hover;
    hover[id] = !hover[id];
    this.setState({
      hover,
    });
  };

  componentDidUpdate(prevProps) {
    if (prevProps.activeTab != this.props.activeTab) {
      let { activeTab } = this.props;
      if (activeTab == 6) this.handlePlan();
    }
  }

  getDocumentUrl = (fileName, fileUrl) => {
    let fileType = String(fileName).split(".")[1];
    switch (fileType) {
      case "pdf":
        return pdf;
      case "xlsx":
        return xlsx;
      case "jpg":
        return fileUrl;
      case "png":
        return fileUrl;
      case "jpeg":
        return fileUrl;
      case "docx":
        return docx;
      default:
        return null;
    }
  };

  render() {
    let { isLoading, plans, hover } = this.state;

    let { accessData } = this.props;

    let permissionData = getAccessdata(accessData, "fpoDocuments");

    let {
      edit = false,
      download = false,
      createAccess = false,
    } = permissionData;

    return (
      <>
        {isLoading ? (
          <>
            {/* Loader */}
            <CommonPageLoader />
          </>
        ) : (
          <div className="container-sa align-items-center">
            {createAccess && (
              <div className="d-flex align-items-center ml-auto">
                <NormalButton
                  className="py-2 px-5 ml-auto"
                  label="Add"
                  outline={true}
                  onClick={() => history.push("/fpo/add-plan")}
                />
              </div>
            )}
            <div className="row h-100 p-md-5 p-0">
              <div className="plan-list w-100">
                <div className="row h-100">
                  <div className="col-md-5 d-flex flex-column justify-content-center">
                    <div className="fw-600 fs-48 mb-4">
                      {"Business plan, projections & more"}
                    </div>
                    <p className="fs-18 line-height-28 text-grey-7b">
                      The Business Plans are drafted based on the current
                      strengths & future potential of the Producer Organization
                    </p>
                    {plans.length > 3 ? (
                      <p
                        className="fs-20 text-green-18 fw-600 cursor-pointer w-50"
                        onClick={() => history.push("/fpo/business-plan")}
                      >
                        {"View more documents"}
                      </p>
                    ) : null}
                  </div>
                  <div className="col-md-7 w-100 h-100">
                    {plans.length !== 0 ? (
                      <div
                        className="position-relative"
                        style={{ height: "450px" }}
                      >
                        {plans.map((list, index) =>
                          index === 0 ? (
                            <div
                              className="d-flex justify-content-center align-items-center flex-column position-absolute plan-doc-2"
                              key={index}
                            >
                              <div className="mb-3 rounded-circle d-flex justify-content-center align-items-center plan-list-pdf">
                                {download ? (
                                  <a
                                    href={
                                      getFilePathDownload + list.documentUrl
                                    }
                                    download
                                    className="text-decoration-none h-100 w-100 rounded-circle d-flex justify-content-center align-items-center plan-list-pdf"
                                  >
                                    <img
                                      className="w-50 h-50"
                                      src={
                                        this.getDocumentUrl(
                                          list.documentName,
                                          list.documentUrl
                                        )
                                        // list.documentName.includes(".pdf")
                                        //   ? pdf
                                        //   : list.documentUrl
                                      }
                                      alt="pdf"
                                    />
                                  </a>
                                ) : (
                                  <img
                                    className="w-50 h-50"
                                    src={
                                      this.getDocumentUrl(
                                        list.documentName,
                                        list.documentUrl
                                      )
                                      // list.documentName.includes(".pdf")
                                      //   ? pdf
                                      //   : list.documentUrl
                                    }
                                    alt="pdf"
                                  />
                                )}
                              </div>
                              <p
                                className="fs-20 text-center text-black-50 line-height-33 font-weight-bold cursor-pointer position-relative d-flex justify-content-center width-120"
                                onMouseEnter={() => this.toggleHover(index)}
                                onMouseLeave={() => this.toggleHover(index)}
                              >
                                <div
                                  className={`position-absolute document-name ${
                                    hover[index] === false ? "show" : "hide"
                                  }`}
                                >
                                  {list.fymYear}
                                </div>
                                {edit && (
                                  <div
                                    className={`icon-edit position-absolute document-edit fs-25 ${
                                      hover[index] === true ? "show" : "hide"
                                    }`}
                                    onClick={() =>
                                      history.push(
                                        `/fpo/edit-plan/${list.businessDpId}`
                                      )
                                    }
                                  ></div>
                                )}
                              </p>
                            </div>
                          ) : index === 1 ? (
                            <div className="d-flex justify-content-center align-items-center flex-column position-absolute plan-doc-1">
                              <div className="mb-3 rounded-circle d-flex justify-content-center align-items-center plan-list-pdf">
                                {download ? (
                                  <a
                                    href={
                                      getFilePathDownload + list.documentUrl
                                    }
                                    download
                                    className="text-decoration-none h-100 w-100 rounded-circle d-flex justify-content-center align-items-center plan-list-pdf"
                                  >
                                    <img
                                      className="w-50 h-50"
                                      src={
                                        this.getDocumentUrl(
                                          list.documentName,
                                          list.documentUrl
                                        )
                                        // list.documentName.includes(".pdf")
                                        //   ? pdf
                                        //   : list.documentUrl
                                      }
                                      alt="pdf"
                                    />
                                  </a>
                                ) : (
                                  <img
                                    className="w-50 h-50"
                                    src={
                                      this.getDocumentUrl(
                                        list.documentName,
                                        list.documentUrl
                                      )
                                      // list.documentName.includes(".pdf")
                                      //   ? pdf
                                      //   : list.documentUrl
                                    }
                                    alt="pdf"
                                  />
                                )}
                              </div>
                              <p
                                className="fs-20 text-center text-black-50 line-height-33 font-weight-bold cursor-pointer position-relative d-flex justify-content-center width-120"
                                onMouseEnter={() => this.toggleHover(index)}
                                onMouseLeave={() => this.toggleHover(index)}
                              >
                                <div
                                  className={`position-absolute document-name ${
                                    hover[index] === false ? "show" : "hide"
                                  }`}
                                >
                                  {list.fymYear}
                                </div>
                                {edit && (
                                  <div
                                    className={`icon-edit position-absolute document-edit fs-25 ${
                                      hover[index] === true ? "show" : "hide"
                                    }`}
                                    onClick={() =>
                                      history.push(
                                        `/fpo/edit-plan/${list.businessDpId}`
                                      )
                                    }
                                  ></div>
                                )}
                              </p>
                            </div>
                          ) : index === 2 ? (
                            <div className="d-flex justify-content-center align-items-center flex-column position-absolute plan-doc-3">
                              <div className="mb-3 rounded-circle d-flex justify-content-center align-items-center plan-list-pdf">
                                {download ? (
                                  <a
                                    href={
                                      getFilePathDownload + list.documentUrl
                                    }
                                    download
                                    className="text-decoration-none h-100 w-100 rounded-circle d-flex justify-content-center align-items-center plan-list-pdf"
                                  >
                                    <img
                                      className="w-50 h-50"
                                      src={
                                        this.getDocumentUrl(
                                          list.documentName,
                                          list.documentUrl
                                        )
                                        // list.documentName.includes(".pdf")
                                        //   ? pdf
                                        //   : list.documentUrl
                                      }
                                      alt="pdf"
                                    />
                                  </a>
                                ) : (
                                  <img
                                    className="w-50 h-50"
                                    src={
                                      this.getDocumentUrl(
                                        list.documentName,
                                        list.documentUrl
                                      )
                                      // list.documentName.includes(".pdf")
                                      //   ? pdf
                                      //   : list.documentUrl
                                    }
                                    alt="pdf"
                                  />
                                )}
                              </div>
                              <p
                                className="fs-20 text-center text-black-50 line-height-33 font-weight-bold cursor-pointer position-relative d-flex justify-content-center width-120"
                                onMouseEnter={() => this.toggleHover(index)}
                                onMouseLeave={() => this.toggleHover(index)}
                              >
                                <div
                                  className={`position-absolute document-name ${
                                    hover[index] === false ? "show" : "hide"
                                  }`}
                                >
                                  {list.fymYear}
                                </div>
                                {edit && (
                                  <div
                                    className={`icon-edit position-absolute document-edit fs-25 ${
                                      hover[index] === true ? "show" : "hide"
                                    }`}
                                    onClick={() =>
                                      history.push(
                                        `/fpo/edit-plan/${list.businessDpId}`
                                      )
                                    }
                                  ></div>
                                )}
                              </p>
                            </div>
                          ) : null
                        )}
                      </div>
                    ) : (
                      <p className="d-flex justify-content-center h-100 align-items-center fs-20 text-black-50 font-weight-bold">
                        {"No data found"}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  accessData: state.home.accessData,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getAllPlan,
      getAllPlanRm,
    },
    dispatch
  );
};

let component = PlanListClass;

export const PlanList = connect(mapStateToProps, mapDispatchToProps)(component);
