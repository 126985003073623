import React, { Component } from "react";
import "./card.scss";
import { history } from "service/helpers";
import moment from "moment";
import User_Profile_1 from "assets/svg/Members/User_Profile_1.svg";
import long_arrow from "assets/svg/price_details/long_arrow.svg";

export class Card extends Component {
  render() {
    const {
      id,
      licenseName,
      licenseImgUrl,
      licenseNumber,
      issuingAuthority,
      validityDate,
    } = this.props.payload;
    const { index, editAccess } = this.props;
    return (
      <div
        className={`col-md-3 col-lg-3 col-6 ${
          index % 4 === 3 ? "pr-md-0" : ""
        } mb-2 mt-3 px-2 ${index % 4 === 0 ? "pl-md-0" : ""}`}
      >
        <div className="compilance-card rounded shadow cursor-pointer">
          <div
            onClick={() => {
              editAccess && history.push(`/fpo/edit-license/${id}`);
            }}
          >
            <div className="d-flex justify-content-between mb-4">
              <div className="d-flex align-items-center">
                <div>
                  <div className="compilance-card-member-name mb-1 gilroy">
                    {licenseName
                      ? licenseName.licenseName.length > 30
                        ? `${licenseName.licenseName.substring(0, 30)}...`
                        : licenseName.licenseName
                      : "-"}
                  </div>
                  <div className="compilance-card-subtitle gilroy">
                    {"Valid till "}
                    {validityDate
                      ? moment(validityDate).format("DD MMM YYYY")
                      : "-"}
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-between mb-4 height-60">
              <div>
                <div className="line-height-21 font-Gilroy text-black-50 fs-16 mb-3 gilroy">
                  {"Licence number"}
                </div>
                <div className="line-height-21 font-Gilroy-Bold fs-18 text-black-1c font-weight-bold gilroy">
                  {licenseNumber ? licenseNumber : "-"}
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-between mb-4">
              <div>
                <div className="line-height-21 font-Gilroy text-black-50 fs-16 mb-3">
                  {"Issuing authority"}
                </div>
                <div className="line-height-21 font-Gilroy-Bold fs-18 text-black-1c font-weight-bold">
                  {issuingAuthority ? issuingAuthority : "-"}
                </div>
              </div>
            </div>
          </div>
          {licenseImgUrl ? (
            <div
              className="row mb-3"
              onClick={() => window.open(licenseImgUrl, "_blank")}
            >
              <div className="text-green font-weight-bold fs-16 gilroy col-7">
                {"View document"}
              </div>
              <div className="col-3">
                <img src={long_arrow} alt="arrow.svg" />
              </div>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}
