import React, { Component } from "react";
// Redux Connection
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { OtpAuth, OtpLogin, PassAuth } from "../../component/auth/login/index";

const Logo = require("../../assets/svg/logo.svg");

export class LoginClass extends Component {
  constructor() {
    super();
    this.state = {
      otp_login: true,
      otp_auth: false,
      pass_login: false,
      user: {},
      user_type: "",
    };
  }

  toggleState = (val1, val2, val3) => {
    this.setState({
      otp_login: val1,
      otp_auth: val2,
      pass_login: val3,
    });
  };

  handleOtp = (data, user_type, rememberme) => {
    if (user_type === "kym") {
      this.setState({ user: { emailOrMobNum: data }, user_type: user_type });
      this.toggleState(false, true, false, false);
    } else {
      this.setState({
        user: {
          emailOrMobNum: data,
          isEmail: data?.includes("@"),
        },
        user_type: user_type,
      });
      this.toggleState(false, true, false, false);
    }
  };

  render() {
    let { pass_login, otp_login, otp_auth, user_type, user } = this.state;

    return (
      <>
        <div className=" d-flex align-items-center flex-column">
          <img className="mb-4 w-75" src={Logo} alt="Logo" height="75" />
          {otp_login && (
            <OtpLogin
              toggleOtpAuth={(data, user_type) => {
                this.handleOtp(data, user_type);
              }}
              togglePass={() => this.toggleState(false, false, true)}
            />
          )}
          {otp_auth && (
            <OtpAuth
              data={user}
              user_type={user_type}
              togglePass={() => this.toggleState(false, false, true)}
            />
          )}
          {pass_login && (
            <PassAuth
              toggleForgot={() => this.toggleState(false, false, false)}
              togglePass={() => this.toggleState(true, false, false)}
            />
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

export const Login = connect(mapStateToProps, mapDispatchToProps)(LoginClass);
