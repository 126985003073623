import React, { Component } from "react";

import { OurBusinessActivitesCard } from "./OurBusinessActivitesCard";

import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from "swiper";

import "./OurBusinessActivites.scss";

export class OurBusinessActivites extends Component {
  constructor() {
    super();
    SwiperCore.use([Navigation]);
  }
  render() {
    const params = {
      slidesPerView: 2,
      spaceBetween: 30,
      loop: false,
      draggable: true,
      breakpoints: {
        600: {
          slidesPerView: 2,
        },
        768: {
          slidesPerView: 4,
        },
        1100: {
          slidesPerView: 6,
        },
      },
    };
    let { businessMapping } = this.props;
    return (
      <>
        <section className="bussiness-activities">
          <div className="container-sa">
            <h3>Our Business Activities</h3>
            <div className="row">
              <Swiper {...params} className="w-100">
                {businessMapping.map((data, index) => {
                  let { businessDetails } = data;
                  return (
                    <SwiperSlide key={index}>
                      <div className="">
                        <OurBusinessActivitesCard
                          img={businessDetails.businessIconUrl}
                          title={businessDetails.businessName}
                        />
                      </div>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div>
          </div>
        </section>
      </>
    );
  }
}
