import React, { Component } from "react";
import "./IncomeTaxTab.scss";
import moment from "moment";

import { TabPane, TabContent } from "reactstrap";

import { debounceFunction } from "service/debounce";

import { Pagination, CommonPageLoader } from "component/common";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getAllIncomeTax, getAllIncomeTds } from "action/ComplianceAct";
import { getAllIncomeTaxRm, getAllIncomeTdsRm } from "action/RmAct";

import { IncometaxCard } from "../IncometaxCard";
import { IncomeTaxStrip } from "../incometaxStrip";
import { IncomeTdsStrip } from "../incomeTdsStrip";

class IncomeTaxTabClass extends Component {
  constructor() {
    super();
    this.state = {
      activeTab: "1",
      view: "1",
      pageMetaTax: {},
      pageMetaTds: {},
      limit: 8,
      page: 1,
      tdsInfo: [],
      taxInfo: [],
      searchValue: "",
      search: "",
      istabOneLoading: false,
      istabTwoLoading: false,
    };
  }

  handleTaxPagination = (page) => {
    this.handleTax(page);
  };
  handleTdsPagination = (page) => {
    this.handleTds(page);
  };

  handleTax = (page) => {
    let { searchValue } = this.state;
    let { getAllIncomeTax, limit, filterBy, filterValue, id } = this.props;
    let payload = {
      limit,
      page,
      search: searchValue,
      filterBy,
      filterValue,
    };
    this.setState({ istabTwoLoading: true });
    id
      ? this.props.getAllIncomeTaxRm(id, payload).then((data) => {
          let dataSet = [];
          data.results.map((result) => {
            dataSet.push({
              id: result.id,
              member_profile: result.filingStatusFileUrl,
              consultatName: result.consultatName,
              financialYear: result.fymYear,
              income: result.incomeOrLoss,
              incomeTaxPaid: result.incomeTaxPaid,
              incometaxPayable: result.incomeTaxPayable,
              remarks: result.remarks,
              filingStatusFileUrl: result.filingStatusFileUrl,
            });
          });
          this.setState({
            taxInfo: dataSet,
            pageMetaTax: data.pageMeta,
            istabTwoLoading: false,
          });
        })
      : getAllIncomeTax(payload).then((data) => {
          let dataSet = [];
          data.results.map((result) => {
            dataSet.push({
              id: result.id,
              member_profile: result.filingStatusFileUrl,
              consultatName: result.consultatName,
              financialYear: result.fymYear,
              income: result.incomeOrLoss,
              incomeTaxPaid: result.incomeTaxPaid,
              incometaxPayable: result.incomeTaxPayable,
              remarks: result.remarks,
              filingStatusFileUrl: result.filingStatusFileUrl,
            });
          });
          this.setState({
            taxInfo: dataSet,
            pageMetaTax: data.pageMeta,
            istabTwoLoading: false,
          });
        });
  };
  handleTds = (page) => {
    let { searchValue } = this.state;
    let { getAllIncomeTds, limit, filterBy, filterValue, id } = this.props;
    this.setState({ istabOneLoading: true });
    let payload = {
      limit,
      page,
      search: searchValue,
      filterBy,
      filterValue,
    };
    id
      ? this.props.getAllIncomeTdsRm(id, payload).then((data) => {
          let dataSet = [];
          data.results.map((result) => {
            dataSet.push({
              id: result.id,
              member_profile: result.filingStatusFileUrl,
              consultatName: result.consultatName,
              financialYear: result.fymYear,
              dueDate: moment(result.dueDate).format("YYYY-MM-DD"),
              dateOfFilling: moment(result.dateOfFilling).format("YYYY-MM-DD"),
              tdsDeducted: result.tdsDeducted,
              tdsPaid: result.tdsPaid,
              remarks: result.remarks,
              filingStatusFileUrl: result.filingStatusFileUrl,
            });
          });
          this.setState({
            tdsInfo: dataSet,
            pageMetaTds: data.pageMeta,
            istabOneLoading: false,
          });
        })
      : getAllIncomeTds(payload).then((data) => {
          let dataSet = [];
          data.results.map((result) => {
            dataSet.push({
              id: result.id,
              member_profile: result.filingStatusFileUrl,
              consultatName: result.consultatName,
              financialYear: result.fymYear,
              dueDate: moment(result.dueDate).format("YYYY-MM-DD"),
              dateOfFilling: moment(result.dateOfFilling).format("YYYY-MM-DD"),
              tdsDeducted: result.tdsDeducted,
              tdsPaid: result.tdsPaid,
              remarks: result.remarks,
              filingStatusFileUrl: result.filingStatusFileUrl,
            });
          });
          this.setState({
            tdsInfo: dataSet,
            pageMetaTds: data.pageMeta,
            istabOneLoading: false,
          });
        });
  };

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.setState({
        search: "",
        searchValue: "",
      });

      if (this.props.mainactiveTab == 3) {
        this.props.activeTab === "1" ? this.handleTds(1) : this.handleTax(1);
      }
      this.setState({
        activeTab: this.props.activeTab,
      });
    }
  }

  handleInput = ({ target: { name, value } }) => {
    //Debounce Function
    this.setState({ search: value });
    debounceFunction(() => this.SearchFunction(value), 1000);
  };

  SearchFunction = (searchValue) => {
    //debounce function
    this.setState({ searchValue });
    this.handleTds(1);
    this.handleTax(1);
  };

  render() {
    const {
      activeTab,
      tdsInfo,
      taxInfo = [],
      pageMetaTax,
      pageMetaTds,
      view,
      istabOneLoading,
      istabTwoLoading,
      search,
    } = this.state;

    let { editAccess } = this.props;

    return (
      <>
        <section className="incometax-section w-100">
          <div className="container-sa">
            <div className="incometax-tab d-flex flex-column">
              <div className="compilance-filters">
                <div className="row align-items-center">
                  <div className="col-sm-9 px-md-5">
                    <ul className="right-filter-align">
                      <li>
                        <span
                          className={`icon-list-view mr-3 pr-1 fs-20 cursor-pointer ${
                            this.state.view === "1"
                              ? "view-active mr-3"
                              : "view"
                          }`}
                          onClick={() => this.setState({ view: "1" })}
                        ></span>
                        <span
                          className={` icon-grid-view fs-20 cursor-pointer ${
                            this.state.view === "2" ? "view-active" : "view"
                          }`}
                          onClick={() => this.setState({ view: "2" })}
                        ></span>
                      </li>
                    </ul>
                  </div>
                  <div className="col-sm-3">
                    <input
                      type-="text"
                      className="form-control form-control-sa"
                      placeholder="Search"
                      value={search}
                      onChange={(e) => this.handleInput(e)}
                    />
                  </div>
                </div>
              </div>
            </div>

            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                {!istabOneLoading && tdsInfo.length === 0 ? (
                  <p className="py-4 mb-0 text-black-50 fs-22 line-height-25 text-center">
                    {"No data found"}
                  </p>
                ) : !istabOneLoading ? (
                  <>
                    {tdsInfo.length != 0 ? (
                      <div className="scroll-x">
                        <div
                          className="d-flex container-sa flex-column  flex-wrap mt-3 px-0 justify-content-center"
                          style={view === "1" ? { minWidth: "1200px" } : null}
                        >
                          <div className="d-flex flex-wrap com-list-card">
                            {tdsInfo.map((info, index) =>
                              view === "2" ? (
                                <IncometaxCard
                                  payload={info}
                                  isTds={true}
                                  index={index}
                                  key={index}
                                  editAccess={editAccess}
                                />
                              ) : (
                                <IncomeTdsStrip
                                  payload={info}
                                  index={index}
                                  key={index}
                                  editAccess={editAccess}
                                />
                              )
                            )}
                          </div>
                        </div>
                      </div>
                    ) : (
                      <p className="py-4 mb-0 text-black-50 fs-22 line-height-25 text-center">
                        {"No data found"}
                      </p>
                    )}
                  </>
                ) : (
                  <>
                    {/* Loader */}
                    <CommonPageLoader />
                  </>
                )}
              </TabPane>
              <TabPane tabId="2">
                {!istabTwoLoading && taxInfo.length === 0 ? (
                  <p className="py-4 mb-0 text-black-50 fs-22 line-height-25 text-center">
                    {"No data found"}
                  </p>
                ) : !istabTwoLoading ? (
                  <>
                    {taxInfo.length != 0 ? (
                      <div className="scroll-x">
                        <div
                          className="d-flex container-sa flex-column  flex-wrap mt-3 px-0 justify-content-center"
                          style={view === "1" ? { minWidth: "1200px" } : null}
                        >
                          <div className="d-flex flex-wrap com-list-card">
                            {taxInfo.map((info, index) =>
                              view === "2" ? (
                                <IncometaxCard
                                  payload={info}
                                  index={index}
                                  key={index}
                                  editAccess={editAccess}
                                />
                              ) : (
                                <IncomeTaxStrip
                                  payload={info}
                                  key={index}
                                  index={index}
                                  editAccess={editAccess}
                                />
                              )
                            )}
                          </div>
                        </div>
                      </div>
                    ) : (
                      <p className="py-4 mb-0 text-black-50 fs-22 line-height-25 text-center">
                        {"No data found"}
                      </p>
                    )}
                  </>
                ) : (
                  <>
                    {/* Loader */}
                    <CommonPageLoader />
                  </>
                )}
              </TabPane>
            </TabContent>
          </div>
          {pageMetaTds && activeTab === "1" && (
            <Pagination
              handleChange={this.handleTdsPagination}
              pageMeta={pageMetaTds}
            />
          )}
          {pageMetaTax && activeTab === "2" && (
            <Pagination
              handleChange={this.handleTaxPagination}
              pageMeta={pageMetaTax}
            />
          )}
        </section>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getAllIncomeTax,
      getAllIncomeTds,
      getAllIncomeTaxRm,
      getAllIncomeTdsRm,
    },
    dispatch
  );
};

let component = IncomeTaxTabClass;

export const IncomeTaxTab = connect(null, mapDispatchToProps)(component);
