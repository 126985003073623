import React, { Component } from 'react';
import Swiper from 'react-id-swiper';

import { arrow_left, arrow_right } from './icons';
import Card from './card';
export class UpcomingEvents extends Component {
   render() {
      const params = {
         slidesPerView: 2,
         spaceBetween: 20,
         loop: false,
         navigation: {
            nextEl: '#academy-next-page',
            prevEl: '#academy-prev-page',
         },
         rebuildOnUpdate: true,
      };
      let {
         upcomingEvents = [],
         title = '',
         ourStories,
         handleDelete,
         getEventId,
         editAccess = false,
         viewAccess = false,
         deleteAccess = false,
      } = this.props;
      return (
         <div className='your-upcoming-events'>
            <div className='container-sa h-100'>
               <div className={`d-flex mb-4 py-4 ${ourStories ? 'justify-content-start' : 'justify-content-end'} `}  >
                  <input
                     type='image'
                     src={arrow_left}
                     alt='left'
                     width='40'
                     height='40'
                     id='academy-prev-page'
                  />
                  <input
                     type='image'
                     src={arrow_right}
                     alt='left'
                     width='40'
                     id='academy-next-page'
                     height='40'
                  />
               </div>
               <div className='row w-100'>
                  {!ourStories && (
                     <div className='col-3'>
                        <div className='d-flex flex-column justify-content-center h-100'>
                           <div className='fs-40 text-green-18 line-height-46 font-weight-bold'>
                              {title}
                           </div>
                        </div>
                     </div>
                  )}
                  <div className='col-9'>
                     <div className=''>
                        {upcomingEvents.length > 0 && !!upcomingEvents ? (
                           <Swiper {...params}>
                              {upcomingEvents.map((event, i) => {
                                 return (
                                    <div className='' key={i}>
                                       <Card
                                          event={event}
                                          handleDelete={handleDelete}
                                          getEventId={getEventId}
                                          ourStories={ourStories}
                                          editAccess={editAccess}
                                          viewAccess={viewAccess}
                                          deleteAccess={deleteAccess}
                                       />
                                    </div>
                                 );
                              })}
                           </Swiper>
                        ) : null}
                     </div>
                  </div>
                  {ourStories && (
                     <div className='col-3'>
                        <div className='d-flex flex-column justify-content-center h-100'>
                           <div className='fs-40 text-green-18 line-height-46 font-weight-bold'>
                              {title}
                           </div>
                        </div>
                     </div>
                  )}
               </div>
            </div>
         </div>
      );
   }
}
