import React, { Component } from "react";
import { getGstCompById } from "action/ComplianceAct";

import { CommonPageLoader } from "component/common";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { history } from "service/helpers/index";

import { getFilePathDownload } from "service/api";

import moment from "moment";
import { formatToINR } from "service/helperFunctions";

import "../Licenseview/style.scss";

const arrow_left = require("assets/svg/add_member/arrow_left.svg");

export class GSTReturnViewClass extends Component {
  constructor(props) {
    super();
    this.state = {
      gstDetails: {},
      isloading: false,
    };
  }
  componentDidMount() {
    let { id } = this.props;
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    this._getData(id);
  }

  _getData = (id) => {
    let { getGstCompById } = this.props;

    this.setState({ isloading: true });

    getGstCompById(id).then((data) => {
      this.setState({
        gstDetails: data,
        isloading: false,
      });
    });
  };

  render() {
    let { gstDetails = {}, isloading } = this.state;

    let {
      consultantName,
      dueDate,
      fymYear,
      gsstNumber,
      igstPaid,
      igstPayable,
      cgstPaid,
      cgstPayable,
      sgstPaid,
      sgstPayable,
      remarks,
      returnPeriodQuarter,
      returnPeriodYear,
      salesIncome,
      fillingStatusFileName,
      fillingStatusFileUrl,
      dateOfFiling,
      returnPeriodMonth,
    } = gstDetails;

    return (
      <div className="view-bod">
        <div className="d-flex my-3 container-sa px-0">
          <div
            className="d-flex"
            onClick={() => history.push("/fpo/compliance")}
          >
            <img className="cursor-pointer" src={arrow_left} alt="back" />
            <p className="fs-18 font-weight-bold font-Gilroy text-black mb-0 cursor-pointer ml-3">
              {"Back"}
            </p>
          </div>
        </div>
        {!isloading ? (
          <div className="bg-area py-4">
            <div className="container">
              <div className="bg-white p-5 shadow mx-5">
                <div className="row">
                  <div className="col-6">
                    <div className="row">
                      <div className="col-5">
                        <p className="text-black font-weight-bold fs-16">
                          Financial year :
                        </p>
                      </div>
                      <div className="col-7">
                        <p className="text-black-50 fs-16">
                          {fymYear ? fymYear : "-"}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-5">
                        <p className="text-black font-weight-bold fs-16">
                          Return period :
                        </p>
                      </div>
                      <div className="col-7">
                        <p className="text-black-50 fs-16">
                          {returnPeriodYear ? returnPeriodYear : "-"}
                        </p>
                      </div>
                    </div>
                    {returnPeriodYear == "Monthly" && (
                      <div className="row">
                        <div className="col-5">
                          <p className="text-black font-weight-bold fs-16">
                            Return Period Month :
                          </p>
                        </div>
                        <div className="col-7">
                          <p className="text-black-50 fs-16">
                            {returnPeriodMonth ? returnPeriodMonth : "-"}
                          </p>
                        </div>
                      </div>
                    )}
                    {returnPeriodYear == "Quarterly" ||
                      (returnPeriodYear == "Quaterly" && (
                        <div className="row">
                          <div className="col-5">
                            <p className="text-black font-weight-bold fs-16">
                              Return Period &nbsp; Quarter :
                            </p>
                          </div>
                          <div className="col-7">
                            <p className="text-black-50 fs-16">
                              {returnPeriodQuarter ? returnPeriodQuarter : "-"}
                            </p>
                          </div>
                        </div>
                      ))}
                    <div className="row">
                      <div className="col-5">
                        <p className="text-black font-weight-bold fs-16">
                          GST number :
                        </p>
                      </div>
                      <div className="col-7">
                        <p className="text-black-50 fs-16">
                          {gsstNumber ? gsstNumber : "-"}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-5">
                        <p className="text-black font-weight-bold fs-16">
                          Due date :
                        </p>
                      </div>
                      <div className="col-7">
                        <p className="text-black-50 fs-16">
                          {dueDate
                            ? moment(dueDate).format("DD MMMM YYYY")
                            : "-"}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-5">
                        <p className="text-black font-weight-bold fs-16">
                          Date of filing :
                        </p>
                      </div>
                      <div className="col-7">
                        <p className="text-black-50 fs-16">
                          {dateOfFiling
                            ? moment(dateOfFiling).format("DD MMMM YYYY")
                            : "-"}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-5">
                        <p className="text-black font-weight-bold fs-16">
                          Consultant name :
                        </p>
                      </div>
                      <div className="col-7">
                        <p className="text-black-50 fs-16">
                          {consultantName ? consultantName : "-"}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-5">
                        <p className="text-black font-weight-bold fs-16">
                          Upload GST Return File :
                        </p>
                      </div>
                      <div className="col-7">
                        <p className="text-black-50 fs-16">
                          {fillingStatusFileName ? (
                            <a
                              className="fs-16 text-green-18 text-underline cursor-pointer"
                              href={getFilePathDownload + fillingStatusFileUrl}
                              download
                            >
                              {"Download"}
                            </a>
                          ) : (
                            <p className="text-black-50 fs-16">{"-"}</p>
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="row">
                      <div className="col-5">
                        <p className="text-black font-weight-bold fs-16">
                          Sales or Income :
                        </p>
                      </div>
                      <div className="col-7">
                        <p className="text-black-50 fs-16">
                          {salesIncome ? formatToINR(salesIncome) : "-"}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-5">
                        <p className="text-black font-weight-bold fs-16">
                          IGST payable :
                        </p>
                      </div>
                      <div className="col-7">
                        <p className="text-black-50 fs-16">
                          {igstPayable ? formatToINR(igstPayable) : "-"}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-5">
                        <p className="text-black font-weight-bold fs-16">
                          IGST paid :
                        </p>
                      </div>
                      <div className="col-7">
                        <p className="text-black-50 fs-16">
                          {igstPaid ? formatToINR(igstPaid) : "-"}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-5">
                        <p className="text-black font-weight-bold fs-16">
                          CGST payable
                        </p>
                      </div>
                      <div className="col-7">
                        <p className="text-black-50 fs-16">
                          {cgstPayable ? formatToINR(cgstPayable) : "-"}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-5">
                        <p className="text-black font-weight-bold fs-16">
                          CGST paid :
                        </p>
                      </div>
                      <div className="col-7">
                        <p className="text-black-50 fs-16">
                          {cgstPaid ? formatToINR(cgstPaid) : "-"}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-5">
                        <p className="text-black font-weight-bold fs-16">
                          SGST payable :
                        </p>
                      </div>
                      <div className="col-7">
                        <p className="text-black-50 fs-16">
                          {sgstPayable ? formatToINR(sgstPayable) : "-"}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-5">
                        <p className="text-black font-weight-bold fs-16">
                          SGST paid :
                        </p>
                      </div>
                      <div className="col-7">
                        <p className="text-black-50 fs-16">
                          {sgstPaid ? formatToINR(sgstPaid) : "-"}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-5">
                        <p className="text-black font-weight-bold fs-16">
                          Remarks :
                        </p>
                      </div>
                      <div className="col-7">
                        <p className="text-black-50 fs-16">
                          {remarks ? remarks : "-"}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <>
            <CommonPageLoader />
          </>
        )}
        <div className="view-members"></div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getGstCompById,
    },
    dispatch
  );
};
let component = GSTReturnViewClass;

export const GSTReturnView = connect(null, mapDispatchToProps)(component);
