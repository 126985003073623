import React, { Component, Fragment } from "react";
import { Col, Row, UncontrolledTooltip } from "reactstrap";
import { CustomDate } from "component/common";
import moment from "moment";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { complianceNotification, getComplianceCalender } from "action/HomeAct";
import { dateDiff } from "service/helperFunctions";
import { history } from "service/helpers";

import "./index.scss";

export class ComplianceCalenderClass extends Component {
  state = {
    complianceTasks: [],
    highlightDates: [],
    tasks: [],
    month: moment(new Date()).format("MM"),
    year: moment(new Date()).format("YYYY"),
    isLoading: false,
    head: ["Particulars", "Due Date", "Days remaining", "Status", "Update"],
  };

  componentDidMount() {
    const { month, year } = this.state;
    this.getCompliance();
    this.getCalenderList({ month, year });
  }

  getCompliance = () => {
    const { complianceNotification } = this.props;
    complianceNotification().then((data) => {
      this.setState({
        complianceTasks: data,
      });
    });
  };

  setMonthYear = ({ month = "12", year = "2020" }) => {
    this.setState({
      month,
      year,
    });
  };

  getCalenderList = ({ month, year }) => {
    const { getComplianceCalender } = this.props;
    getComplianceCalender({ month, year }).then((data) => {
      let date = [];
      let task = [];
      data.map((list) => {
        date.push(moment(list.dueDate).format("YYYY-MM-DD"));
        let isRepeated = data.filter((item) => {
          return item.dueDate == list.dueDate;
        });
        let isDuplicate = task.find((date) => {
          return list.dueDate === date.dueDate;
        });
        if (isRepeated.length > 1 && isDuplicate == undefined) {
          let newTask = isRepeated.map((list) => {
            return list.paticulars;
          });
          task.push({
            dueDate: moment(list.dueDate).format("YYYY-MM-DD"),
            paticulars: newTask.join(", "),
          });
        } else {
          task.push({
            dueDate: moment(list.dueDate).format("YYYY-MM-DD"),
            paticulars: list.paticulars,
          });
        }
      });
      let finalTasks = [
        ...new Map(task.map((item) => [item["dueDate"], item])).values(),
      ];
      let finalDate = [...new Set(date)];
      this.setState({
        highlightDates: finalDate,
        tasks: finalTasks,
      });
    });
  };

  handleEditCalender = (type, id) => {
    switch (type) {
      case "licence":
        return history.push(`/fpo/edit-license/${id}`);
      case "gstInfo":
        return history.push(`/fpo/edit-gst/${id}`);
      case "incometds":
        return history.push(`/fpo/edit-incometds/${id}`);
      case "registrars":
        return history.push(`/fpo/edit-registrar/${id}`);
      case "gstReturn":
        return history.push(`/fpo/edit-gstreturn/${id}`);
      case "bods":
        return history.push(`/fpo/edit-bod-compliance/${id}`);
      case "agminfo":
        return history.push(`/fpo/edit-agm/${id}`);
      default:
        return null;
    }
  };

  render() {
    const { complianceTasks, highlightDates, tasks, isLoading, head } =
      this.state;

    const { isAuth } = this.props;

    return (
      <div className="compliance-calender">
        <div className="container-sa">
          {!isLoading ? (
            <Row>
              <Col sm="6">
                <div className="scroll-x">
                  <div className="calender-section">
                    <div className="title">{"Important dates to remember"}</div>
                    <div className="calender-container">
                      <CustomDate
                        getList={this.getCalenderList}
                        list={highlightDates}
                        tasks={tasks}
                        setMonthYear={this.setMonthYear}
                        isAuth={isAuth}
                        isCompliance={true}
                      />
                    </div>
                  </div>
                </div>
              </Col>
              <Col sm="6">
                <div className="calender-events">
                  <div className="title">Compliance Tasks</div>
                  <div className="compliance-wrapper scroll-x">
                    <table className="compliance_notification">
                      <thead>
                        <tr>
                          {head.map((list) => {
                            return <th>{list}</th>;
                          })}
                        </tr>
                      </thead>
                      <tbody>
                        {complianceTasks.length > 0 ? (
                          complianceTasks.map(
                            ({ paticulars, dueDate, id, type }, index) => {
                              let renderCompliance =
                                (paticulars === "AGM" &&
                                  moment(dueDate).isBefore(
                                    new Date(),
                                    "day"
                                  )) ||
                                (paticulars === "BOD Meeting" &&
                                  moment(dueDate).isBefore(new Date(), "day"))
                                  ? true
                                  : null;
                              return (
                                <tr key={index}>
                                  <td
                                    className={renderCompliance ? "d-none" : ""}
                                  >
                                    {paticulars}
                                  </td>
                                  <td
                                    className={renderCompliance ? "d-none" : ""}
                                  >
                                    {dueDate
                                      ? moment(dueDate).format("DD-MM-YYYY")
                                      : "-"}
                                  </td>
                                  <td
                                    className={renderCompliance ? "d-none" : ""}
                                  >
                                    {dueDate
                                      ? dateDiff(dueDate) === 0
                                        ? "Today"
                                        : dateDiff(dueDate)
                                      : "-"}
                                  </td>
                                  <td
                                    className={renderCompliance ? "d-none" : ""}
                                  >
                                    {dueDate ? (
                                      <Fragment>
                                        <label
                                          id={`compliance-status-${index}`}
                                          className={
                                            dateDiff(dueDate) >= 0 &&
                                            dateDiff(dueDate) <= 30
                                              ? "amber"
                                              : dateDiff(dueDate) > 30
                                              ? "green"
                                              : "red"
                                          }
                                        ></label>
                                        <UncontrolledTooltip
                                          placement="left"
                                          target={`compliance-status-${index}`}
                                          hideArrow={true}
                                        >
                                          <span>
                                            {dateDiff(dueDate) > 30 ? "" : ""}
                                            {dateDiff(dueDate) >= 0 &&
                                            dateDiff(dueDate) <= 30
                                              ? "The due date is approaching soon. Please take immediate action."
                                              : dateDiff(dueDate) > 30
                                              ? "The due date is some time away. Please plan your task accordingly."
                                              : "The due date has already expired. Please take immediate action."}
                                          </span>
                                        </UncontrolledTooltip>
                                      </Fragment>
                                    ) : (
                                      "-"
                                    )}
                                  </td>
                                  <td
                                    className={renderCompliance ? "d-none" : ""}
                                  >
                                    <button
                                      onClick={() =>
                                        this.handleEditCalender(type, id)
                                      }
                                    >
                                      Edit
                                    </button>
                                  </td>
                                </tr>
                              );
                            }
                          )
                        ) : (
                          <tr>
                            <td colSpan="5" align="center">
                              <b>NO TASKS</b>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </Col>
            </Row>
          ) : (
            <div className="text-center">
              <div className="spinner-border text-secondary" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      complianceNotification,
      getComplianceCalender,
    },
    dispatch
  );
};
let component = ComplianceCalenderClass;

export const ComplianceCalender = connect(null, mapDispatchToProps)(component);
