import React, { Component } from "react";
import { getDescriptionData } from "service/helperFunctions";
import "./Licenses.scss";

export class Licenses extends Component {
  render() {
    let { licenseData } = this.props;
    let licenseDetails = licenseData
      .reduce((acc, item) => {
        return `${acc} , ${item.licenseDetails.licenseName}`;
      }, "")
      .slice(2);
    return (
      <>
        <section className="licenses-section">
          <div className="container-sa">
            <div className="row align-items-center">
              <div className="col-sm-12">
                <div className="licenses-left-content">
                  <h3>Our Licenses</h3>
                  {/* <p>
                    We are enaged in multiple business activitives such as input
                    supply,output aggregation,processing etc .We have availed
                    and been renewing the licenses required to conduct
                    thementioned business activities :
                    {getDescriptionData(licenseDetails, 100)}
                  </p> */}
                </div>
              </div>
              <div className="col-sm-12">
                {/* {licenseData.length > 6 ? (
                    <div className="d-flex justify-content-end mr-3">
                      <p className="text-black-1c fs-16 mb-2 font-weight-bold">
                        {"Scroll"}
                        <i className="icon-arrow-down pl-2"></i>
                      </p>
                    </div>
                  ) : (
                    ""
                  )} */}
                <div className="licenses-right-content">
                  {licenseData.map((data, index) => {
                    let { licenseName, licenseIconUrl } = data.licenseDetails;
                    return (
                      <div className="licenses-card" key={index}>
                        <div className="img-thumb-licenses">
                          <img src={licenseIconUrl} alt="img-thumb" />
                        </div>
                        <p>{licenseName ? licenseName : ""}</p>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}
