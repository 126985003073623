import React from "react";
import { connect } from "react-redux";
import { Col, Row } from "reactstrap";
import { ReportCard } from "./ReportCard";
import newsImage from "assets/images/newsImage.png";

const ReportList = () => {
  const newsList = [
    {
      thumb: newsImage,
      date: "26Apr 2021",
      title: "Amid coronavirus chaos and protests, India's farm...",
      description:
        "While India battles soaring COVID-19 infections, on the outskirts of New Delhi thousands of farmers still occupy camps where they are keeping up a...",
      para: "While India battles soaring COVID-19 infections, on the outskirts of New Delhi thousands of farmers still occupy camps where they are keeping up a months-long sit-in protest against government legislation that they say harms them.Underlining the organised nature of the movement as it tries to force Prime Minister Narendra Modi to revoke reforms aimed at making agriculture more efficient, farmers are being ferried to and from villages in order to harvest this year's wheat crop.T ..Read more at:https://economictimes.indiatimes.com/news/economy/agriculture/amid-coronavirus-chaos-and-protests-indias-farmers-eye-record-wheat-crop/articleshow/82304661.cms?utm_source=contentofinterest&utm_medium=text&utm_campaign=cppst.As COVID-19 cases spiralled, Agriculture & Farmers Welfare Minister Narendra Singh Tomar asked farmers to call off their campaign to prevent outbreaks of the coronavirus at protest sites. But farmers say they will not budge until the government concedes to their demands.Volunteers at the camps have started distributing face masks and spraying disinfectant, and hand-washing stations and hand sanitiser dispensers have been installed.As the movement gathered steam last year, f ..Read more at:https://economictimes.indiatimes.com/news/economy/agriculture/amid-coronavirus-chaos-and-protests-indias-farmers-eye-record-wheat-crop/articleshow/82304661.cms?utm_source=contentofinterest&utm_medium=text&utm_campaign=cppst",
    },
    {
      thumb: newsImage,
      date: "27Apr 2021",
      title: "Amid coronavirus chaos and protests, India's farm...",
      description:
        "While India battles soaring COVID-19 infections, on the outskirts of New Delhi thousands of farmers still occupy camps where they are keeping up a...",
      para: "While India battles soaring COVID-19 infections, on the outskirts of New Delhi thousands of farmers still occupy camps where they are keeping up a months-long sit-in protest against government legislation that they say harms them.Underlining the organised nature of the movement as it tries to force Prime Minister Narendra Modi to revoke reforms aimed at making agriculture more efficient, farmers are being ferried to and from villages in order to harvest this year's wheat crop.T ..Read more at:https://economictimes.indiatimes.com/news/economy/agriculture/amid-coronavirus-chaos-and-protests-indias-farmers-eye-record-wheat-crop/articleshow/82304661.cms?utm_source=contentofinterest&utm_medium=text&utm_campaign=cppst.As COVID-19 cases spiralled, Agriculture & Farmers Welfare Minister Narendra Singh Tomar asked farmers to call off their campaign to prevent outbreaks of the coronavirus at protest sites. But farmers say they will not budge until the government concedes to their demands.Volunteers at the camps have started distributing face masks and spraying disinfectant, and hand-washing stations and hand sanitiser dispensers have been installed.As the movement gathered steam last year, f ..Read more at:https://economictimes.indiatimes.com/news/economy/agriculture/amid-coronavirus-chaos-and-protests-indias-farmers-eye-record-wheat-crop/articleshow/82304661.cms?utm_source=contentofinterest&utm_medium=text&utm_campaign=cppst",
    },
    {
      thumb: newsImage,
      date: "28Apr 2021",
      title: "Amid coronavirus chaos and protests, India's farm...",
      description:
        "While India battles soaring COVID-19 infections, on the outskirts of New Delhi thousands of farmers still occupy camps where they are keeping up a...",
      para: "While India battles soaring COVID-19 infections, on the outskirts of New Delhi thousands of farmers still occupy camps where they are keeping up a months-long sit-in protest against government legislation that they say harms them.Underlining the organised nature of the movement as it tries to force Prime Minister Narendra Modi to revoke reforms aimed at making agriculture more efficient, farmers are being ferried to and from villages in order to harvest this year's wheat crop.T ..Read more at:https://economictimes.indiatimes.com/news/economy/agriculture/amid-coronavirus-chaos-and-protests-indias-farmers-eye-record-wheat-crop/articleshow/82304661.cms?utm_source=contentofinterest&utm_medium=text&utm_campaign=cppst.As COVID-19 cases spiralled, Agriculture & Farmers Welfare Minister Narendra Singh Tomar asked farmers to call off their campaign to prevent outbreaks of the coronavirus at protest sites. But farmers say they will not budge until the government concedes to their demands.Volunteers at the camps have started distributing face masks and spraying disinfectant, and hand-washing stations and hand sanitiser dispensers have been installed.As the movement gathered steam last year, f ..Read more at:https://economictimes.indiatimes.com/news/economy/agriculture/amid-coronavirus-chaos-and-protests-indias-farmers-eye-record-wheat-crop/articleshow/82304661.cms?utm_source=contentofinterest&utm_medium=text&utm_campaign=cppst",
    },
    {
      thumb: newsImage,
      date: "29Apr 2021",
      title: "Amid coronavirus chaos and protests, India's farm...",
      description:
        "While India battles soaring COVID-19 infections, on the outskirts of New Delhi thousands of farmers still occupy camps where they are keeping up a...",
      para: "While India battles soaring COVID-19 infections, on the outskirts of New Delhi thousands of farmers still occupy camps where they are keeping up a months-long sit-in protest against government legislation that they say harms them.Underlining the organised nature of the movement as it tries to force Prime Minister Narendra Modi to revoke reforms aimed at making agriculture more efficient, farmers are being ferried to and from villages in order to harvest this year's wheat crop.T ..Read more at:https://economictimes.indiatimes.com/news/economy/agriculture/amid-coronavirus-chaos-and-protests-indias-farmers-eye-record-wheat-crop/articleshow/82304661.cms?utm_source=contentofinterest&utm_medium=text&utm_campaign=cppst.As COVID-19 cases spiralled, Agriculture & Farmers Welfare Minister Narendra Singh Tomar asked farmers to call off their campaign to prevent outbreaks of the coronavirus at protest sites. But farmers say they will not budge until the government concedes to their demands.Volunteers at the camps have started distributing face masks and spraying disinfectant, and hand-washing stations and hand sanitiser dispensers have been installed.As the movement gathered steam last year, f ..Read more at:https://economictimes.indiatimes.com/news/economy/agriculture/amid-coronavirus-chaos-and-protests-indias-farmers-eye-record-wheat-crop/articleshow/82304661.cms?utm_source=contentofinterest&utm_medium=text&utm_campaign=cppst",
    },
    {
      thumb: newsImage,
      date: "30Apr 2021",
      title: "Amid coronavirus chaos and protests, India's farm...",
      description:
        "While India battles soaring COVID-19 infections, on the outskirts of New Delhi thousands of farmers still occupy camps where they are keeping up a...",
      para: "While India battles soaring COVID-19 infections, on the outskirts of New Delhi thousands of farmers still occupy camps where they are keeping up a months-long sit-in protest against government legislation that they say harms them.Underlining the organised nature of the movement as it tries to force Prime Minister Narendra Modi to revoke reforms aimed at making agriculture more efficient, farmers are being ferried to and from villages in order to harvest this year's wheat crop.T ..Read more at:https://economictimes.indiatimes.com/news/economy/agriculture/amid-coronavirus-chaos-and-protests-indias-farmers-eye-record-wheat-crop/articleshow/82304661.cms?utm_source=contentofinterest&utm_medium=text&utm_campaign=cppst.As COVID-19 cases spiralled, Agriculture & Farmers Welfare Minister Narendra Singh Tomar asked farmers to call off their campaign to prevent outbreaks of the coronavirus at protest sites. But farmers say they will not budge until the government concedes to their demands.Volunteers at the camps have started distributing face masks and spraying disinfectant, and hand-washing stations and hand sanitiser dispensers have been installed.As the movement gathered steam last year, f ..Read more at:https://economictimes.indiatimes.com/news/economy/agriculture/amid-coronavirus-chaos-and-protests-indias-farmers-eye-record-wheat-crop/articleshow/82304661.cms?utm_source=contentofinterest&utm_medium=text&utm_campaign=cppst",
    },
  ];
  return (
    <div className="row">
      <div className="col-lg-12 col-md-12 col-12 p-5 mt-5">
        <div className="container-sa">
          <div className="news-events-tab pb-sm-3 pb-1 pt-sm-3 pt-1">
            <div className="d-flex justify-content-center pb-sm-5 pb-2">
              <Row>
                {newsList.map((news, index) => {
                  return (
                    <Col md="4" key={index} className="mb-5">
                      <ReportCard
                        content="News"
                        details={news}
                        detailsKey={index}
                      />
                    </Col>
                  );
                })}
              </Row>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const ReportLists = connect(null)(ReportList);
