import React, { Component } from "react";
import { history } from "service/helpers";
import { currencyConvert } from "service/helperFunctions";
const User_Profile_1 = require("assets/svg/Members/User_Profile_1.svg");

export class IncomeTaxStrip extends Component {
  render() {
    let { payload, index, editAccess } = this.props;
    const {
      id,
      member_profile,
      consultatName,
      financialYear,
      income,
      incomeTaxPaid,
      incometaxPayable,
    } = payload;
    return (
      <div
        key={index}
        className="container-sa license-strip shadow rounded mb-3"
      >
        <div className="row align-items-center h-100 px-2">
          <div className="col-1">
            <img
              className="rounded-circle income-card-profile"
              src={
                member_profile && member_profile.includes("https://")
                  ? member_profile
                  : User_Profile_1
              }
              alt="profile"
            />
          </div>
          <div className="col-2">
            <div className="fs-18 fw-600">
              {consultatName ? consultatName : "-"}
            </div>
            <div className="text-black-50">Consultant name</div>
          </div>

          <div className="col-2">
            <div className="fs-18 fw-600">
              {financialYear ? financialYear : "-"}
            </div>
            <div className="text-black-50">Financial year</div>
          </div>
          <div className="col-2">
            <div className="fs-18 fw-600">
              {income ? currencyConvert(income) : ""}
            </div>
            <div className="text-black-50">
              {"Income/Loss ( "} <span className="icon-rupee"></span> {" )"}
            </div>
          </div>
          <div className="col-2">
            <div className="fs-18 fw-600">
              {incometaxPayable ? currencyConvert(incometaxPayable) : "-"}
            </div>
            <div className="text-black-50">
              {"Income tax payable ( "} <span className="icon-rupee"></span>{" "}
              {" )"}
            </div>
          </div>
          <div className="col-2">
            <div className="fs-18 fw-600">
              {incomeTaxPaid ? currencyConvert(incomeTaxPaid) : "-"}
            </div>
            <div className="text-black-50">
              {"Income tax paid ( "} <span className="icon-rupee"></span> {" )"}
            </div>
          </div>

          <div className="col-1 d-flex">
            <div
              className="text-black-50 cursor-pointer mr-4"
              onClick={() => history.push(`/fpo/compliance/income-tax/${id}`)}
            >
              {"View"}
            </div>
            {editAccess && (
              <div
                className="text-black-50 cursor-pointer"
                onClick={() => history.push(`/fpo/edit-incometax/${id}`)}
              >
                {"Edit"}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
