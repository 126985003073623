import React from "react";
import { Modal, ModalBody } from "reactstrap";
import { history } from "service/helpers";
import "./style.scss";

const SellPopup = (props) => {
  const { isOpen, toggle, sellData } = props;

  return (
    <Modal isOpen={isOpen} centered={true} className="ipl-modal">
      <ModalBody>
        <div className="sell-popup">
          <i className="fas fa-times" onClick={() => toggle()}></i>
          <div className="w-100 mt-3 d-flex align-items-center flex-column">
            <h1>{sellData?.title}</h1>
            <div className="d-flex align-items-center mt-3">
              <button
                class="btn cursor-pointer mainbg-btn px-3 py-2 fs-14 line-height-26 mr-3"
                onClick={() =>
                  window.open(sellData?.redirectURL?.redirectURL, "_blank")
                }
              >
                New request/Track request
              </button>
              <button
                class="btn cursor-pointer mainbg-btn px-3 py-2 fs-14 line-height-26"
                onClick={() => history.push("/fpo/harvest-details")}
              >
                Expected Harvest
              </button>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default SellPopup;
