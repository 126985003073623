import { NormalButton } from 'component/common';
import React, { Component } from 'react';
import { FieldsTemplate } from '../common/FieldsTemplate';
import SimpleReactValidator from 'simple-react-validator';

export const Outreach = (props) => {

  const [, forceUpdate] = React.useState();
  const [outreachInfo, setOutreachInfo] = React.useState({
    facilities2: []
  });
  const [outreachAttr, setOutreachAttr] = React.useState([
    { label: "FPOs catchment Area (in acres)", field: "input", type: "number", name: "fpoCatchment", required: true },
    { label: "Total Number of Households in the FPO catchment area", field: "input", type: "number", name: "catchmentHouseholds", note: "The number can be of a village/Block for which the data is available. , If data not available enter 1", required: true },
    { label: "Total number of farmers in the FPO Catchment Area?", field: "input", type: "number", name: "catchmentFarmers", note: "The number can be of a village/block for which the data is available.", required: true },
    { label: "Percentage (%) of active women participation", field: "input", type: "number", name: "womenpartpercent", note: "Example: if value is 55.5 % enter 55.5", required: true },
    {
      label: "Reason for low active women participation?",
      field: "radioGroup", type: "number", name: "rsnlowwomenpart", required: false,
      note: "Make a selection only if women participation is less than 15%",
      conditions: { attribute: "womenpartpercent", values: [15], action: "show", criteria: "lt" },
      list: [
        { value: "landregnotwom", label: "Land not registered in the name of women" },
        { value: "soctyrestr", label: "Restrictions within the society" },
        { value: "housechore", label: "Busy with household chores" },
        { value: "notawarerole", label: "Lack of awareness about the roles and responsibilities" }
      ]
    },

    { label: "What percentage of members are small holders and tenant farmers?", field: "input", type: "number", name: "smallfarmerpercent", note: "Example: if value is 55.5 % enter 55.5", required: true },
    { label: "What is the percentage growth in enrolling and participation of members?", field: "input", type: "number", name: "enrollgrowthPC", note: "(In percentage for example if the value is 55.5% enter 55.5)", required: true },
    {
      label: "What are the facilities provided by the promoting institution?", field: "checkboxGroup", type: "number", name: "facilities2", required: true,
      list: [

      ]
    },
    {
      label: "Mention if others", field: "input", type: "text", name: "operationothers", required: false,
      conditions: { attribute: "facilities2", values: [26], action: "show" },
    },


  ]);

  const handleInput = ({ target: { name, value } }) => {
    setOutreachInfo({
      ...outreachInfo,
      [name]: value,
    });
  };

  //validation
  const simpleValidator = React.useRef(new SimpleReactValidator({
    element: (message) => (
      <span className="error-message text-danger fs-14">{message}</span>
    ),
    autoForceUpdate: this
  }));
  const handleSubmit = (status) => {
    const formValid = simpleValidator.current.allValid();
    if (formValid) {
      let payload = { ...outreachInfo, status };
      props.updateSurvey("outreachInfo", payload);

    } else {
      simpleValidator.current.showMessages()
      forceUpdate(1);
      props.errorAccordion();
    }
  }
  React.useEffect(() => {
    if (props?.surveyDetails) {

      let _outreachInfo = {
        fpoCatchment: props?.surveyDetails?.fpoCatchment,
        catchmentHouseholds: props?.surveyDetails?.catchmentHouseholds,
        catchmentFarmers: props?.surveyDetails?.catchmentFarmers,
        womenpartpercent: props?.surveyDetails?.womenpartpercent,
        smallfarmerpercent: props?.surveyDetails?.smallfarmerpercent,
        enrollgrowthPC: props?.surveyDetails?.enrollgrowthPC,
      };

      if (props?.surveyDetails?.facilities2) {
        _outreachInfo.facilities2 = props?.surveyDetails?.facilities2;

      }

      //
      setOutreachInfo({
        ...outreachInfo,
        ..._outreachInfo
      });
    }
  }, [props?.surveyDetails]);

  React.useEffect(() => {
    if (props?.submitSurveyClick) {
      handleSubmit('completed');
    }
  }, [props?.submitSurveyClick]);

  const handleCheckbox = (name, value) => {
    let list = [...outreachInfo[name]];
    let index = list.indexOf(value);
    if (index >= 0) {
      list.splice(index, 1);
    } else {
      list.push(value);
    }
    setOutreachInfo({
      ...outreachInfo,
      [name]: list,
    });
  };


  const handleRadio = (name, value) => {
    setOutreachInfo({
      ...outreachInfo,
      [name]: value,
    });
  };


  return (
    <div >
      <div className="container-sa position-relative" >

        <FieldsTemplate
          attributes={outreachAttr}
          simpleValidator={simpleValidator}
          stateAttr={outreachInfo}
          handleInput={handleInput}
          handleRadio={handleRadio}
          handleCheckbox={handleCheckbox}
        />

        <div className="row mt-3">
          <div className="col-md-5 col-sm-12  my-1">
            <NormalButton
              label="Save & Next"
              mainbg={true}
              className="px-3 py-2 mx-auto fs-14 line-height-26 font-Gilroy saveNext"
              onClick={() => handleSubmit('draft')}
              isPopup={true}
            />
          </div>
        </div>
      </div>
    </div>
  )
}