import React, { Component } from 'react';
import './your_events.scss';
import Swiper from 'react-id-swiper';

import { arrow_left, arrow_right } from './icons';
import Card from './card';
export class YourEvents extends Component {
   render() {
      const params = {
         slidesPerView: 3,
         spaceBetween: 0,
         loop: false,
         navigation: {
            nextEl: '#next-btnYe',
            prevEl: '#prev-btnYe',
         },
         rebuildOnUpdate: true,
      };
      let {
         yourEventList = [],
         isLoader,
         getEventId,
         handleDelete,
         title = '',
         editAccess = false,
         viewAccess = false,
         deleteAccess = false,
      } = this.props;
      return (
         <div className='container-sa your-events px-5'>
            <div className={`d-flex justify-content-end mb-5`}>
               <input
                  type='image'
                  src={arrow_left}
                  alt='left'
                  width='40'
                  height='40'
                  id='prev-btnYe'
               />
               <input
                  type='image'
                  src={arrow_right}
                  alt='left'
                  width='40'
                  id='next-btnYe'
                  height='40'
               />
            </div>
            <div className='container-sa d-flex '>
               <div className='your-events-header mr-5 my-auto'>{title}</div>
               <div className='card-swiper'>
                  {yourEventList.length > 0 && !!yourEventList && !isLoader ? (
                     <Swiper {...params}>
                        {yourEventList.map((list, index) => {
                           return (
                              <div className='' key={index}>
                                 <Card
                                    list={list}
                                    handleDelete={handleDelete}
                                    getEventId={getEventId}
                                    editAccess={editAccess}
                                    viewAccess={viewAccess}
                                    deleteAccess={deleteAccess}
                                 />
                              </div>
                           );
                        })}
                     </Swiper>
                  ) : (
                        ''
                     )}
               </div>
            </div>
         </div>
      );
   }
}
