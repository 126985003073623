import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  addIncomeTds,
  getIncomeTdsById,
  updateIncomeTds,
  deleteIncomeTds,
  getAllFinancialYearDropdown,
  complianceCurrentTab,
} from "action/ComplianceAct";
import { fileUpload } from "action/MemberAct";
import moment from "moment";
import { history } from "service/helpers/index";
import { Toast } from "service/toast";
import {
  NormalInput,
  NormalButton,
  NormalDate,
  NormalSelect,
  CommonPageLoader,
  ConfirmationPopup,
} from "component/common";
import SimpleReactValidator from "simple-react-validator";
import trash from "assets/svg/Associations/trash.svg";
import { getAccessdata } from "service/helperFunctions";
const arrow_left = require("assets/svg/add_member/arrow_left.svg");

class AddIncomeTdsClass extends Component {
  state = {
    tds: "",
    financialYear: "",
    consultatName: "",
    filingStatusFileUrl: null,
    filingStatusFileName: "",
    remarks: "",
    dateOfFiling: "",
    tdsDeducted: "",
    tdsPaid: "",
    dueDate: "",
    isImageUploading: false,
    allFinance: [],
    isLoading: false,
    isDelete: false,
    yearlyOptions: [
      {
        value: "Yearly",
        label: "Yearly",
      },
      {
        value: "Quarterly",
        label: "Quarterly",
      },
      {
        value: "Monthly",
        label: "Monthly",
      },
    ],
  };

  //Validation
  validator = new SimpleReactValidator({
    validators: {
      integer: {
        message: "Only non-negative absolute values are allowed",
        rule: (val, params, validator) => {
          return validator.helpers.testRegex(val, /^\s*([0-9])*$/);
        },
        messageReplace: (message, params) =>
          message.replace(":values", this.helpers.toSentence(params)),
      },
    },
    element: (message) => (
      <span className="error-message text-danger fs-14">{message}</span>
    ),
    autoForceUpdate: this,
  });

  handleFile = (e) => {
    let file = e.target.files[0];
    if (file !== undefined) {
      this.setState({ filingStatusFileUrl: null }, () => {
        this.setState({
          isImageUploading: true,
          filingStatusFileName: "Uploading ....",
          filingStatusFileUrl: file,
        });
        const formData = new FormData();
        formData.append("file", file);

        this.props
          .fileUpload(formData)
          .then((data) => {
            document.getElementById("handleFile").value = null;
            this.setState({
              filingStatusFileUrl: data[0].fileUrl,
              filingStatusFileName: data[0].fileName,
              isImageUploading: false,
            });
          })
          .catch(() => {
            this.setState({
              filingStatusFileUrl: null,
              filingStatusFileName: "",
              isImageUploading: false,
            });
          });
      });
    }
  };

  handleInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  handleSubmit = () => {
    this.setState({ isLoading: true });

    if (this.validator.allValid()) {
      const {
        tds,
        financialYear,
        consultatName,
        filingStatusFileUrl,
        filingStatusFileName,
        remarks,
        isImageUploading,
        dateOfFiling,
        tdsDeducted,
        tdsPaid,
        dueDate,
        allFinance,
      } = this.state;
      if (!isImageUploading) {
        let body = {
          fpoId: parseInt(localStorage.getItem("userMasterId")),
          fymId: parseInt(financialYear),
          fymYear: "",
          dateOfFiling: moment(dateOfFiling).format("YYYY-MM-DD"),
          dueDate: moment(dueDate).format("YYYY-MM-DD"),
          tds,
        };

        if (remarks !== "") {
          body.remarks = remarks;
        }
        if (consultatName !== "") {
          body.consultatName = consultatName;
        }
        if (tdsPaid !== "") {
          body.tdsPaid = parseInt(tdsPaid);
        }
        if (tdsDeducted !== "") {
          body.tdsDeducted = parseInt(tdsDeducted);
        }
        if (dueDate !== "") {
          body.dueDate = moment(dueDate).format("YYYY-MM-DD");
        }
        if (filingStatusFileName !== "") {
          body.filingStatusFileName = filingStatusFileName;
          body.filingStatusFileUrl = filingStatusFileUrl;
        }
        let dataSet = allFinance.filter((x) => x.value == financialYear);
        if (dataSet.length > 0) {
          body.fymYear = dataSet[0].label;
        }
        if (this.props.id) {
          body.id = parseInt(this.props.id);
          this.props
            .updateIncomeTds(body)
            .then(() => {
              this.validator.hideMessages();
              this.handleBack();
            })
            .catch(() => {
              this.setState({ isLoading: false });
            });
        } else {
          this.props
            .addIncomeTds(body)
            .then(() => {
              this.validator.hideMessages();
              this.setState({
                tds: "",
                financialYear: "",
                consultatName: "",
                filingStatusFileUrl: null,
                filingStatusFileName: "",
                remarks: "",
                dateOfFiling: "",
                tdsDeducted: "",
                tdsPaid: "",
                dueDate: "",
                isImageUploading: false,
                isLoading: false,
              });
              this.handleBack();
            })
            .catch(() => {
              this.setState({ isLoading: false });
            });
        }
      } else {
        this.setState({ isLoading: false });

        Toast({ type: "error", message: "Please wait Image is uploading" });
      }
    } else {
      this.setState({ isLoading: false });

      this.validator.showMessages();
      this.forceUpdate();
    }
  };
  handleDelete = () => {
    this.setState({ isLoading: true });
    this.props.deleteIncomeTds(this.props.id).then(() => {
      this.handleBack();
    });
  };
  componentDidMount() {
    this.props.getAllFinancialYearDropdown().then((data) => {
      let dataSet = [];

      data.financialYearMaster.map((list) => {
        dataSet.push({ value: list.fymId, label: list.fymYear });
      });
      this.setState({
        allFinance: dataSet,
      });
    });
    if (this.props.id) {
      this.setState({ isLoading: true });
      this.props.getIncomeTdsById(this.props.id).then((data) => {
        let {
          tds = "",
          fymId = "",
          consultatName = "",
          filingStatusFileUrl = null,
          filingStatusFileName = "",
          remarks = "",
          dateOfFiling = "",
          tdsDeducted = "",
          tdsPaid = "",
          dueDate = "",
        } = data;

        this.setState({
          isLoading: false,
          tds: tds ? tds : "",
          financialYear: fymId ? fymId : "",
          consultatName: consultatName ? consultatName : "",
          filingStatusFileUrl: filingStatusFileUrl ? filingStatusFileUrl : null,
          filingStatusFileName: filingStatusFileName
            ? filingStatusFileName
            : "",
          remarks: remarks ? remarks : "",
          dateOfFiling: dateOfFiling ? new Date(dateOfFiling) : "",
          tdsDeducted: tdsDeducted ? tdsDeducted.toString() : "",
          tdsPaid: tdsPaid ? tdsPaid.toString() : "",
          dueDate: dueDate ? new Date(dueDate) : "",
        });
      });
    }
  }

  handleRemoveImage = () => {
    this.setState({
      filingStatusFileUrl: null,
      filingStatusFileName: "",
    });
  };

  handleBack = () => {
    this.props.complianceCurrentTab(3);
    history.push("/fpo/compliance");
  };

  toggleDelete = () => {
    let { isDelete } = this.state;
    this.setState({
      isDelete: !isDelete,
    });
  };

  render() {
    const {
      isLoading,
      tds,
      financialYear,
      allFinance,
      consultatName,
      filingStatusFileUrl,
      filingStatusFileName,
      remarks,
      dateOfFiling,
      tdsDeducted,
      tdsPaid,
      dueDate,
      yearlyOptions,
      isImageUploading,
      isDelete,
    } = this.state;

    let { accessData } = this.props;
    let permissionData = getAccessdata(accessData, "complainceGovernance");

    let { deleteAccess = false } = permissionData;

    return (
      <div>
        <div className="d-flex container-sa my-3 ">
          <div
            onClick={() => this.handleBack()}
            className="d-flex cursor-pointer"
          >
            <img className="cursor-pointer" src={arrow_left} alt="back" />
            <p className="ml-3 add-member-text-back mb-0">
              {this.props.id ? "Edit" : "Add"} income TDS
            </p>
          </div>
        </div>
        {!isLoading ? (
          <div className="add-form">
            <div className="container bg-white  py-4">
              <div className="row">
                <div className="col-6">
                  <NormalSelect
                    required={true}
                    isHalfWidthForMemberProfile={true}
                    label={"TDS Period"}
                    placeholder="Select"
                    value={tds}
                    arrow={true}
                    name="tds"
                    options={yearlyOptions}
                    handleChange={this.handleInput}
                  />
                  <div className="row">
                    <div className="col-8 ml-auto">
                      {this.validator.message("tds", tds, "required")}
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <NormalSelect
                    isHalfWidthForMemberProfile={true}
                    label={"Financial year"}
                    placeholder="Select"
                    value={financialYear}
                    arrow={true}
                    name="financialYear"
                    options={allFinance}
                    handleChange={this.handleInput}
                    required={true}
                  />
                  <div className="row">
                    <div className="col-8 ml-auto">
                      {this.validator.message(
                        "financialYear",
                        financialYear,
                        "required"
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <NormalDate
                    isIcon={true}
                    isHalfWidthForMemberProfile={true}
                    value={dueDate}
                    name="dueDate"
                    placeholder="DD/MM/YYYY"
                    onChange={this.handleInput}
                    mindate={false}
                    maxdate={false}
                    timeInput={false}
                    label={"Due date"}
                    required={true}
                  />
                  <div className="row">
                    <div className="col-8 ml-auto">
                      {this.validator.message("dueDate", dueDate, "required")}
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <NormalDate
                    isIcon={true}
                    isHalfWidthForMemberProfile={true}
                    value={dateOfFiling}
                    name="dateOfFiling"
                    placeholder="DD/MM/YYYY"
                    onChange={this.handleInput}
                    mindate={false}
                    maxdate={false}
                    timeInput={false}
                    label={"Date of filing"}
                    required={true}
                  />
                  <div className="row">
                    <div className="col-8 ml-auto">
                      {this.validator.message(
                        "dateOfFiling",
                        dateOfFiling,
                        "required"
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row compliance-add-row">
                <div className="col-6">
                  <NormalInput
                    label="TDS deducted"
                    type="number"
                    onChange={this.handleInput}
                    value={tdsDeducted}
                    name="tdsDeducted"
                    isHalfWidthForMemberProfile={true}
                    isRupees={true}
                  />
                  <div className="row">
                    <div className="col-8 ml-auto">
                      {this.validator.message(
                        "tds Deducted",
                        tdsDeducted,
                        "max:10"
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <NormalInput
                    isHalfWidthForMemberProfile={true}
                    value={tdsPaid}
                    type="number"
                    name="tdsPaid"
                    onChange={this.handleInput}
                    label={"TDS paid"}
                    isRupees={true}
                  />
                  <div className="row">
                    <div className="col-8 ml-auto">
                      {this.validator.message("tds Paid", tdsPaid, "max:10")}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row compliance-add-row">
                <div className="col-6">
                  <NormalInput
                    label="Consultant name"
                    type="text"
                    onChange={this.handleInput}
                    value={consultatName}
                    name="consultatName"
                    isHalfWidthForMemberProfile={true}
                    i
                  />
                  <div className="row">
                    <div className="col-8 ml-auto">
                      {this.validator.message(
                        "consultant Name",
                        consultatName,
                        "max:30|alpha_space"
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="row upload-area">
                    <div className="col-4">
                      <div className="fs-16 text-black-32 font-weight-bold line-height-19">
                        {"Upload TDS file"}
                      </div>
                    </div>
                    <div className="col-8">
                      <div className="d-flex align-items-center btn-file-input">
                        <button className="">
                          <input
                            type="file"
                            className=""
                            accept=".jpg, .png, .jpeg"
                            onChange={this.handleFile}
                            id="handleFile"
                            title=""
                          />
                          {filingStatusFileUrl == null ? (
                            "Choose file"
                          ) : (
                            <>
                              {filingStatusFileName.length == 10
                                ? filingStatusFileName
                                : filingStatusFileName.substring(0, 9)}
                              {"..."}
                            </>
                          )}
                        </button>
                        {filingStatusFileName != "" && !isImageUploading && (
                          <div className="pl-3 position-relative">
                            <img
                              src={filingStatusFileUrl}
                              alt={filingStatusFileName}
                              height="75"
                              width="75"
                            />
                            <i
                              className="icon-Delete text-danger fs-14 pl-2 cursor-pointer position-absolute"
                              onClick={() => this.handleRemoveImage()}
                            ></i>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row compliance-add-row">
                <div className="col-6">
                  <NormalInput
                    label="Remarks"
                    type="text"
                    onChange={this.handleInput}
                    value={remarks}
                    name="remarks"
                    isHalfWidthForMemberProfile={true}
                  />
                  <div className="row">
                    <div className="col-8 ml-auto">
                      {this.validator.message(
                        "remarks",
                        remarks,
                        "max:75|alpha_space"
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="pt-5 pb-3 d-flex">
                <div className="mx-auto d-flex">
                  {this.props.id && deleteAccess && (
                    <div className="cursor-pointer d-flex align-items-center mr-5">
                      <div
                        className="text-center fs-14 fw-600 text-danger d-flex"
                        onClick={this.toggleDelete}
                      >
                        <img className="mr-2" src={trash} alt="icon" />
                        Delete
                      </div>
                    </div>
                  )}
                  <NormalButton
                    label={`${this.props.id ? "Update" : "Submit & next"}`}
                    mainbg={true}
                    className="px-3 py-2 fs-14 line-height-26"
                    onClick={this.handleSubmit}
                  />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <>
            {/* Loader */}
            <CommonPageLoader />
          </>
        )}
        <ConfirmationPopup
          isOpen={isDelete}
          toggle={this.toggleDelete}
          onSubmit={this.handleDelete}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  accessData: state.home.accessData,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      addIncomeTds,
      fileUpload,
      getAllFinancialYearDropdown,
      getIncomeTdsById,
      updateIncomeTds,
      deleteIncomeTds,
      complianceCurrentTab,
    },
    dispatch
  );
};
let component = AddIncomeTdsClass;

export const AddIncomeTds = connect(
  mapStateToProps,
  mapDispatchToProps
)(component);
