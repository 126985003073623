import React, { Component } from "react";
// import { courses } from './courses';
import { Card } from "./card";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getAcademy } from "action/HomeAct";
import "./academy.scss";

import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from "swiper";

const arrow_left = require("../../../../assets/svg/academy/arrow-left.svg");
const arrow_right = require("../../../../assets/svg/academy/arrow-right.svg");

export class AcademyClass extends Component {
  constructor(params) {
    super();
    SwiperCore.use([Navigation]);
    this.state = {
      courses: [],
    };
  }
  componentDidMount() {
    this.props.getAcademy().then((data) => {
      let dataSet = [];
      data.map((data) => {
        dataSet.push({
          title: data.title,
          img: data.thumbnailFileUrl,
          body: data.description,
          time: `${data.durationHours} hr ${data.durationMinutes} mins`,
          chapters: `${data.noOfChapters} chapters`,
          courseId: data.courseId,
        });
      });
      this.setState({
        courses: dataSet,
      });
    });
  }
  render() {
    const params = {
      slidesPerView: 2,
      spaceBetween: 20,
      breakpoints: {
        500: {
          slidesPerView: 2,
        },
        768: {
          slidesPerView: 2,
        },
      },
      navigation: {
        nextEl: "#academy-next",
        prevEl: "#academy-prev",
      },
    };
    const { courses } = this.state;
    return (
      <>
        {courses.length > 0 && (
          <div className="d-flex align-items-center pl-4 background-academy rounded">
            <div className="container-fluid d-flex align-items-center pl-md-5 row home-page-acad pr-md-0">
              <div className="col-4 pl-md-5">
                <div className="text-bold-academy text-white">Academy for</div>
                <div className="text-bold-academy text-white">FPOs</div>
                <div className="text-white mb-3 text-light-academy ">
                  An e-knowledge sharing platform to provide professional
                  learning and training experience to FPOs and other ecosystem
                  stakeholders
                </div>
                <div>
                  <input
                    type="image"
                    src={arrow_left}
                    alt="left"
                    width="40"
                    height="40"
                    id="academy-prev"
                  />
                  <input
                    type="image"
                    src={arrow_right}
                    alt="left"
                    width="40"
                    height="40"
                    id="academy-next"
                  />
                </div>
              </div>
              <div className="col-8">
                <Swiper {...params}>
                  {courses.map((course, index) => {
                    return (
                      <SwiperSlide key={index}>
                        <div>
                          <Card course={course} />
                        </div>
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              </div>{" "}
            </div>
          </div>
        )}
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getAcademy,
    },
    dispatch
  );
};
let component = AcademyClass;

export const Academy = connect(null, mapDispatchToProps)(component);
