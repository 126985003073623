import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  addRevenue,
  updateRevenue,
  getRevenueById,
  getAllActivity,
  deleteRevenue,
  keyFinancialCurrentTab,
} from "action/KeyFinancialAct";
import "./style.scss";
import { getAllFinancialYearDropdown } from "action/ComplianceAct";
import { getAccessdata } from "service/helperFunctions";
import SimpleReactValidator from "simple-react-validator";
import { history } from "service/helpers/index";
import {
  NormalInput,
  NormalButton,
  NormalSelect,
  CommonPageLoader,
  ConfirmationPopup,
} from "component/common";
import trash from "assets/svg/Associations/trash.svg";

const arrow_left = require("assets/svg/add_member/arrow_left.svg");

class AddRevenueClass extends Component {
  constructor(props) {
    super();
    this.state = {
      FSId: "",
      allRevenueYear: [],
      allActivity: [],
      selectedActivity: [],
      Inputs: [],
      revenueId: "",
      revenueActivity: "",
      businessName: "",
      financialYear: "",
      activitySelect: "",
      isLoading: false,
      isDelete: false,
    };
  }

  validator = new SimpleReactValidator({
    element: (message) => (
      <span className="error-message text-danger fs-14 mt-3">{message}</span>
    ),
    autoForceUpdate: this,
    validators: {
      integer: {
        message: "Only non-negative absolute values are allowed",
        rule: (val, params, validator) => {
          return validator.helpers.testRegex(val, /^\s*([0-9])*$/);
        },
        messageReplace: (message, params) =>
          message.replace(":values", this.helpers.toSentence(params)),
      },
    },
  });

  handleDelete = () => {
    this.setState({ isLoading: true });
    this.props.deleteRevenue(this.props.id).then((data) => {
      history.push("/fpo/key-financial");
    });
  };

  handleSubmit = () => {
    this.setState({ isLoading: true });

    if (this.validator.allValid() || this.props.id) {
      const {
        FSId,
        allRevenueYear,
        Inputs,
        revenueId,
        revenueActivity,
        businessName,
      } = this.state;
      let body = {
        fymId: parseInt(FSId),
        business: Inputs,
        fymYear: "",
      };
      let dataSet = allRevenueYear.filter((x) => x.value == FSId);
      if (dataSet.length > 0) {
        body.fymYear = dataSet[0].label;
      }
      let updateBody = {
        revenueId,
        revenueActivity: parseInt(revenueActivity),
      };
      let financialData = allRevenueYear.filter(
        (x) => x.value === parseInt(FSId)
      );
      if (financialData.length > 0) {
        body.FYear = financialData[0].year;
        body.FyearID = financialData[0].FyearID;
      }

      if (this.props.id) {
        if (this.validator.fieldValid(`${businessName}`)) {
          this.props
            .updateRevenue(updateBody)
            .then(() => {
              this.validator.hideMessages();
              history.push("/fpo/key-financial");
              this.setState({ isLoading: false });
            })
            .catch(() => {
              this.setState({ isLoading: false });
            });
        } else {
          this.validator.showMessageFor(`${businessName}`);
          this.setState({ isLoading: false });
        }
      } else {
        this.props
          .addRevenue(body)
          .then(() => {
            this.validator.hideMessages();
            this.setState({
              FSId: "",
              revenueId: "",
              revenueActivity: "",
              businessName: "",
              financialYear: "",
              Inputs: [],
              selectedActivity: [],
              isLoading: false,
            });
            this.props.getAllActivity().then((data) => {
              this.setState({
                allActivity: data,
              });
            });
            this.handleBack();
          })
          .catch(() => {
            this.setState({ isLoading: false });
          });
      }
    } else {
      this.setState({ isLoading: false });

      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  handleInput = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
    if (name == "FSId") {
      this.setState({ isLoading: true });
      this.props.getAllActivity(value).then((data) => {
        this.setState({
          allActivity: data,
          selectedActivity: [],
          Inputs: [],
          activitySelect: "",
          isLoading: false,
        });
      });
    }
  };

  handleActivityInputs = (e, index) => {
    if (index !== undefined) {
      let data = [...this.state.Inputs];
      data[index].revenueActivity = e.target.value;
      this.setState({
        Inputs: [...data],
      });
    }
  };

  handleActivitySelect = (activity) => {
    let { selectedActivity, Inputs } = this.state;
    if (
      !selectedActivity.find((s_activity) => {
        return s_activity === activity.businessId;
      })
    ) {
      this.setState((prevState) => ({
        Inputs: [
          ...prevState.Inputs,
          {
            businessName: activity.businessName,
            businessId: activity.businessId,
            revenueActivity: "",
          },
        ],
      }));
      this.setState((prevState) => {
        prevState.Inputs.length > 0
          ? (prevState.activitySelect = "1")
          : (prevState.activitySelect = "");
      });
      this.setState({ selectedActivity: [] }, () => {
        selectedActivity.push(activity.businessId);
        this.setState({ selectedActivity });
      });
    } else {
      let index = selectedActivity.findIndex((s_activity) => {
        return s_activity === activity.businessId;
      });
      if (index !== -1) {
        this.setState((prevState) => {
          prevState.selectedActivity.splice(index, 1);
        });
      }

      let input = Inputs.findIndex((s_activity) => {
        return s_activity.businessId === activity.businessId;
      });
      if (input !== -1) {
        this.setState((prevState) => {
          prevState.Inputs.splice(input, 1);
          prevState.Inputs.length > 0
            ? (prevState.activitySelect = "1")
            : (prevState.activitySelect = "");
        });
      }
    }
  };
  componentDidMount() {
    this.props.getAllFinancialYearDropdown().then((data) => {
      let financialData = [];

      data.financialYearMaster.map((list) => {
        financialData.push({ value: list.fymId, label: list.fymYear });
      });
      this.setState({
        allRevenueYear: financialData,
      });
    });
    this.props.getAllActivity().then((data) => {
      this.setState({
        allActivity: data,
      });
    });
    if (this.props.id) {
      this.setState({ isLoading: true });
      this.props.getRevenueById(this.props.id).then((data) => {
        this.setState({
          revenueId: data.revenueId,
          revenueActivity: data.revenueActivity.toString(),
          businessName: data.businessDetails.businessName,
          financialYear: data.fymYear,
          isLoading: false,
        });
      });
    }
  }

  handleBack = () => {
    this.props.keyFinancialCurrentTab(3);
    history.push("/fpo/key-financial");
  };

  toggleDelete = () => {
    let { isDelete } = this.state;
    this.setState({
      isDelete: !isDelete,
    });
  };

  render() {
    const {
      allRevenueYear,
      FSId,
      allActivity,
      selectedActivity,
      Inputs = [],
      revenueActivity,
      businessName,
      financialYear,
      activitySelect,
      isLoading,
      isDelete,
    } = this.state;

    let { accessData } = this.props;
    let permissionData = getAccessdata(accessData, "keyFinancials");

    let { deleteAccess = false } = permissionData;

    return (
      <>
        <div className="key-financials">
          <div className="d-flex container-sa my-3 ">
            <div
              onClick={() => this.handleBack()}
              className="d-flex cursor-pointer"
            >
              <img className="cursor-pointer" src={arrow_left} alt="back" />
              <p className="ml-3 add-member-text-back mb-0">
                {this.props.id ? "Edit" : "Add"} revenue from activity
              </p>
            </div>
          </div>
          {!isLoading ? (
            <div className="add-form">
              <div className="container bg-white">
                {this.props.id ? (
                  <>
                    <div className="row">
                      <div className="col-6">
                        <NormalInput
                          label="Financial year"
                          disabled={true}
                          type="text"
                          onChange={this.handleInput}
                          value={financialYear}
                          name="financialYear"
                          isHalfWidth={true}
                        />
                      </div>
                      <div className="col-6">
                        <NormalInput
                          label={businessName}
                          type="number"
                          onChange={this.handleInput}
                          value={revenueActivity}
                          name="revenueActivity"
                          isHalfWidth={true}
                          required={true}
                        />
                        <div className="row">
                          <div className="col-8 ml-auto">
                            {this.validator.message(
                              businessName,
                              revenueActivity,
                              "required|integer|max:10"
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="row">
                      <div className="col-12">
                        <NormalSelect
                          isFullWidth={true}
                          label={"Financial year"}
                          placeholder="Select"
                          value={FSId}
                          name="FSId"
                          options={allRevenueYear}
                          arrow={true}
                          handleChange={this.handleInput}
                          required={true}
                        />
                        <div className="row">
                          <div className="col-10 ml-auto">
                            {this.validator.message(
                              "Financial year",
                              FSId,
                              "required"
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mt-5">
                      <div className="font-weight-bold mb-4">
                        Select your business activities{" "}
                        <span className="text-danger font-weight-bold pl-2 fs-18">
                          {"*"}
                        </span>
                      </div>
                      <div className="flex-wrap d-flex">
                        {allActivity.map((activity, index) => {
                          return (
                            <div
                              key={index}
                              className={`d-flex flex-column ${
                                selectedActivity.find((s_activity) => {
                                  return s_activity === activity.businessId;
                                }) !== undefined
                                  ? "crops-container-selected mr-2 mb-3"
                                  : "crops-container mr-2 mb-3"
                              } `}
                              onClick={() => {
                                this.handleActivitySelect(activity);
                                this.forceUpdate();
                              }}
                            >
                              <img
                                className="crops-container-img"
                                src={activity.businessIconUrl}
                                alt="crops"
                              />
                              <p className="mb-0 fs-12 text-green-18 text-center">
                                {activity.businessName}
                              </p>
                            </div>
                          );
                        })}
                      </div>
                      {this.validator.message(
                        "business activities",
                        activitySelect,
                        "required"
                      )}
                      {Inputs.length > 0 && (
                        <div className="row activities-margin">
                          {Inputs.map((input, index) => {
                            return (
                              <div key={index} className="col-6">
                                <NormalInput
                                  label={input.businessName}
                                  type="number"
                                  onChange={(e) =>
                                    this.handleActivityInputs(e, index)
                                  }
                                  value={input.revenueActivity}
                                  name={input.businessName.split(" ").join("_")}
                                  isHalfWidth={true}
                                />
                                <div className="row">
                                  <div className="col-8 ml-auto">
                                    {this.validator.message(
                                      input.businessName,
                                      input.revenueActivity,
                                      "required|max:10"
                                    )}
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </div>
                  </>
                )}

                <div className="pt-5 pb-3 d-flex">
                  <div className="mx-auto d-flex">
                    {this.props.id && deleteAccess && (
                      <div className="cursor-pointer d-flex align-items-center mr-5">
                        <div
                          className="text-center fs-14 fw-600 text-danger d-flex"
                          onClick={this.toggleDelete}
                        >
                          <img className="mr-2" src={trash} alt="icon" />
                          {"Delete"}
                        </div>
                      </div>
                    )}
                    <NormalButton
                      label={`${this.props.id ? "Update" : "Submit & Next"}`}
                      mainbg={true}
                      className="px-3 py-2 fs-14 line-height-26"
                      onClick={this.handleSubmit}
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <>
              {/* Loader */}
              <CommonPageLoader />
            </>
          )}
          <ConfirmationPopup
            isOpen={isDelete}
            toggle={this.toggleDelete}
            onSubmit={this.handleDelete}
          />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  accessData: state.home.accessData,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      addRevenue,
      updateRevenue,
      getRevenueById,
      getAllFinancialYearDropdown,
      getAllActivity,
      deleteRevenue,
      keyFinancialCurrentTab,
    },
    dispatch
  );
};
let component = AddRevenueClass;

export const AddRevenue = connect(
  mapStateToProps,
  mapDispatchToProps
)(component);
