import React, { Component } from 'react';
import { } from "action/Acadamy"
import { bindActionCreators } from 'redux';
import arrow_left from 'assets/svg/add_member/arrow_left.svg';
import { connect } from 'react-redux';
import { Pagination } from 'component/Faq/FaqView/pagination';
import { BlogCard } from "./BlogCard"
import { history } from "service/helpers";
import { getAllBlogsList, addfavouriteBlog, deletefavouriteBlog } from "action/Acadamy"
import { CommonPageLoader } from "component/common"
import "./BlogView.scss"

export class ViewAllblogsClass extends Component {
    state = {
        blogList: [],
        pageMeta: {},
        isLoading: false
    }

    componentDidMount() {
        this._getAllBlogsList(1)
    }

    _getAllBlogsList = (page) => {
        let { getAllBlogsList } = this.props
        this.setState({ isLoading: true })
        getAllBlogsList({ page }).then((data) => {
            let blogLists = []
            data.results.map((list) => {
                list.isFavouriteAdding = false
                blogLists.push(list)
            })
            this.setState({
                blogList: blogLists,
                pageMeta: data.pageMeta,
                isLoading: false
            })
        })
    }

    handlePageChange = (page) => {
        this._getAllBlogsList(page)
    }

    //handle add favourities
    handleAddFavourities = (blogId, currentIndex) => {

        let body = {
            "blogId": blogId
        }

        let { blogList } = this.state

        let { addfavouriteBlog } = this.props

        blogList[currentIndex].isFavouriteAdding = true
        this.setState({ blogList })

        addfavouriteBlog(body).then(() => {
            blogList[currentIndex].isFavourite = true
            blogList[currentIndex].isFavouriteAdding = false
            this.setState({ blogList })
        })

    }

    //handle delete favourities
    handledeleteFavourities = (blogId, currentIndex) => {

        let body = {
            "blogId": blogId
        }

        let { blogList } = this.state

        let { deletefavouriteBlog } = this.props

        blogList[currentIndex].isFavouriteAdding = true
        this.setState({ blogList })

        deletefavouriteBlog(body).then(() => {
            blogList[currentIndex].isFavourite = false
            blogList[currentIndex].isFavouriteAdding = false
            this.setState({ blogList })
        })

    }

    render() {
        let { blogList, pageMeta, isLoading } = this.state
        return (
            <>
                <div className="container-sa">
                    <div className='d-flex py-3 w-100'>
                        <div className='d-flex' onClick={() => {
                            history.push('/academy/home');
                        }} >
                            <img
                                className='cursor-pointer'
                                src={arrow_left}
                                alt='back'
                            />
                            <p className='ml-3 text-black fs-18 line-height-25 font-weight-bold mb-0 cursor-pointer'>
                                {'Back'}
                            </p>
                        </div>
                    </div>
                </div>
                <section className="blog-view-all">
                    <div className="container">
                        {!isLoading ?
                            <div className="row py-5">
                                <div className="col-12">
                                    <div className="row">
                                        {blogList.map((list, index) => (
                                            <div className="col-4 mb-5">
                                                <BlogCard
                                                    blogList={list}
                                                    handleAddFavourities={this.handleAddFavourities}
                                                    handledeleteFavourities={this.handledeleteFavourities}
                                                    currentIndex={index}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <Pagination
                                    handleChange={this.handlePageChange}
                                    pageMeta={pageMeta}
                                />
                            </div>
                            :
                            <>
                                {/* loading */}
                                <CommonPageLoader />
                            </>
                        }
                    </div>
                </section>
            </>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        getAllBlogsList,
        addfavouriteBlog,
        deletefavouriteBlog
    }, dispatch)
}

let component = ViewAllblogsClass;

export const ViewAllblogs = connect(null, mapDispatchToProps)(component)
