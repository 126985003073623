import React, { Component } from "react";
import { Navbar } from "../../component/common";
import { AddStaff } from "../../component/Members";
import { UserAuth } from "helpers/UserAuth";
class AddstaffClass extends Component {
  render() {
    return (
      <div>
        <div className="inner-header">
          {/* <Navbar /> */}
          <div className="h-100 w-100">
            <p className="d-flex justify-content-center align-items-center h-100 w-100 text-white fs-32 font-weight-bold">
              Member Engagement
            </p>
          </div>
        </div>
        <AddStaff id={this.props.match.params.id} />
      </div>
    );
  }
}

export const Addstaff = UserAuth(AddstaffClass, { service: "membership" });
