import { NormalButton } from 'component/common';
import React, { Component } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import { FieldsTemplate } from '../common/FieldsTemplate';

export const HRD = (props) => {
  const [, forceUpdate] = React.useState();
  const [hrdInfo, setHrdInfo] = React.useState({
    positions: []
  });
  const [hrdInfoAttr, setHrdInfoAttr] = React.useState([
    {
      label: "Do you have an organization structure?", field: "radioGroup", type: "number", name: "orghasstruct", required: true,
      list: [
        { label: "Yes", value: "yes" },
        { label: "No", value: "no" },
      ]
    },

    {
      label: "What are the different organization position ?", field: "checkboxGroup", type: "number", name: "positions", required: true,
      list: [

      ]
    },
    {
      label: "Mention if others", field: "input", type: "text", name: "hrdothers", required: false,
      conditions: { attribute: "positions", values: [13], action: "show" },
    },

    {
      label: "How does the FPO plan its production? (calculated call on what should be harvested)?", field: "radioGroup", type: "number", name: "planharvest", required: true,
      list: [
        { label: "Demand and supply are forecasted and used proactively in planning inventory management and operations", value: "forecasted" },
        { label: "There is some forecasting of demand and supply done but in an ad-hoc/incoherent manner", value: "partialforecast" },
        { label: "There is minimal/no forecasting/estimation of demand-supply done in the FPO", value: "minimalornoforecast" },
      ]
    },

    {
      label: "Do BOD and staffs have clarity in their roles and responsibility?", field: "radioGroup", type: "number", name: "bodstaffrespclear", required: true,
      list: [
        { label: "Goals and Objectives are clear to various levels of staff. Have a clear strategy to achieve these goals", value: "cleartostaff" },
        { label: "Goals and Objectives are clear to only top management and Board. Strategy reasonably well integrated with these goals", value: "onlytopmgmt" },
        { label: "Objectives have been broken down into specific targets. But not well integrated with Goal of organization/ its strategy", value: "targetbutnointeg" },
        { label: "Target monitoring is weak, especially for line functionaries", value: "weakmonitoring" },
        { label: "Goals/ Objectives not clearly stated. Staff in lower rungs not aware of the targets", value: "unclear" },
      ]
    },

    {
      label: "Do CEO and BOD representatives attend meetings with suppliers and buyers?", field: "radioGroup", type: "number", name: "ceobodmtgsuppbuyr", required: true,
      list: [
        { label: "Yes", value: "yes" },
        { label: "No", value: "no" },
      ]
    },

    {
      label: "Do the Directors have in-depth domain knowledge of the business activates being undertaken by FPC?", field: "radioGroup", type: "number", name: "bodknowsdomain", required: true,
      list: [
        { label: "Yes, only 1 BOD", value: "yes1bod" },
        { label: "Yes, 2-3 BODs", value: "yes2to3bod" },
        { label: "Yes, 4-5 BODs", value: "yes4to5bod" },
        { label: "No", value: "no" },
      ]
    },

    {
      label: "Does your FPC have subcommittees for procurement, finance, grievances redressal, sales etc.?", field: "radioGroup", type: "number", name: "subcommittees", required: true,
      list: [
        { label: "Yes", value: "yes" },
        { label: "No", value: "no" },
      ]
    },

    {
      label: "How frequently do these subcommittees meet?", field: "radioGroup", type: "number", name: "subcomfreq", required: true,
      conditions: { attribute: "subcommittees", values: ["no"], action: "hide" },
      list: [
        { label: "Monthly", value: "monthly" },
        { label: "Quarterly", value: "quarterly" },
        { label: "Once in a cropping season", value: "croppingseason" },
        { label: " Never", value: "never" },
      ]
    },

    {
      label: "How often BoDs are involved in business decisions like identification of business collaboration for backward and forward linkages, sales and procurement etc.?", field: "radioGroup", type: "number", name: "boddecisionfreq", required: true,
      list: [
        { label: "Monthly", value: "monthly" },
        { label: "Quarterly", value: "quarterly" },
        { label: "Once in a cropping season", value: "croppingseason" },
        { label: " Never", value: "never" },
      ]
    },

    {
      label: "Do your FPC have Chairman and some Directors devoting their time in the company even without any other interest or without taking any additional honorarium?", field: "radioGroup", type: "number", name: "ceodirtime", required: true,
      list: [
        { label: "Yes, 4-5 BODs", value: "4to5bods" },
        { label: "Yes, 2-3 BODs", value: "2to3bods" },
        { label: "Yes, only 1 BODs", value: "onebod" },
        { label: "None", value: "none" },
      ]
    },
    {
      label: "Do BoDs take self initiative in community mobilization, share money collection, resolving community issues etc. ?", field: "radioGroup", type: "number", name: "bodinitiative", required: true,
      list: [
        { label: "Yes , Always", value: "always" },
        { label: "Yes, Sometimes", value: "sometimes" },
        { label: "None", value: "none" },
      ]
    },

    {
      label: "Is any member of management team (CEO and other Staffs) closely related to BoDs?", field: "radioGroup", type: "number", name: "bodrelative", required: true,
      list: [
        { label: "Yes", value: "yes" },
        { label: "No", value: "no" },
      ]
    },

    {
      label: "Whether two or more BODs are from same family", field: "radioGroup", type: "number", name: "twobodsfamily", required: true,
      list: [
        { label: "Yes", value: "yes" },
        { label: "No", value: "no" },
      ]
    },

    {
      label: "Whether any BOD is political leader?", field: "radioGroup", type: "number", name: "bodpoliticalldr", required: true,
      list: [
        { label: "Yes", value: "yes" },
        { label: "No", value: "no" },
      ]
    },

    {
      label: "Who is paying salaries for the management team?", field: "radioGroup", type: "number", name: "mgmtsalsrce", required: true,
      list: [
        { label: "FPC from its profit", value: "fromprofit" },
        { label: "Partly by FPC and partly by RI/Project", value: "sharedbyfpoandri" },
        { label: "RI through some Project", value: "byri" },
        { label: "No staff after the closure of the project", value: "nostaffafterproject" },
      ]
    },

    {
      label: "What is the education Qualification of the CEO?", field: "radioGroup", type: "number", name: "ceoqual", required: true,
      list: [
        { label: "Below garduation", value: "ceobelowgrad" },
        { label: "General graduation", value: "ceograd" },
        { label: "Graduation in agriculture and allied sciences", value: "ceoagrigrad" },
        { label: "Post graduation in agriculture and allied sciences", value: "ceoagripg" },
        { label: "Postgraduation in General Studies", value: "ceoagripggen" },
        { label: "FPO have no CEO", value: "ceono" },
      ]
    },

    {
      label: "What is the rational for selection of CEO?", field: "radioGroup", type: "number", name: "ceorational", required: true,
      conditions: { attribute: "ceoqual", values: ["ceobelowgrad"], action: "show" },
      list: [
        { label: "He/She was the candidate with highest educational qualification", value: "ceohighqual" },
        { label: "He/She is a reputed member of village community", value: "ceorepumem" },
        { label: "He/She is the sole opeartional member of the FPO", value: "ceosolopmem" },
        { label: "He/She is a relative of one of the BODs", value: "ceobodrelat" },
      ]
    },

    {
      label: "What is the Work Experience of the CEO?", field: "radioGroup", type: "number", name: "ceoworkex", required: true,
      list: [
        { label: "More than 10 years in agribusiness and allied field", value: "morethan10yrsagri" },
        { label: "More than 10 years, but not in agribusiness and allied field", value: "more10yrsnonagri" },
        { label: "5-10 years in agribusiness and allied field", value: "agri5to10" },
        { label: "5-10 years, but not in agribusiness and allied field", value: "nonagri5to10" },
        { label: "2-5 years in agribusiness and allied field", value: "agri2to5" },
        { label: "2-5 years, but not in agribusiness and allied field", value: "nonagri2to5" },
        { label: "Less than 2 years", value: "lessthan2" },
        { label: "FPO have no CEO", value: "noceo" },
      ]
    },

    {
      label: "What is the rational for selection of CEO?", field: "radioGroup", type: "number", name: "ceorational1", required: true,
      conditions: { attribute: "ceoworkex", values: ["lessthan2", "noceo"], action: "show" },
      list: [
        { label: "He/She was the candidate with highest educational qualification", value: "ceohighqual" },
        { label: "He/She is a reputed member of village community", value: "ceorepumem" },
        { label: "He/She is the sole opeartional member of the FPO", value: "ceosolopmem" },
        { label: "He/She is a relative of one of the BODs", value: "ceobodrelat" },
      ]
    },

    {
      label: "From which area/geography the management team/CEO belongs to", field: "radioGroup", type: "number", name: "mgmtceoarea", required: true,
      list: [
        { label: "FPC operational area or villages", value: "fpcarea" },
        { label: "Same District", value: "samedistrict" },
        { label: "Same State", value: "samestate" },
        { label: "Other State", value: "otherstate" },
        { label: "FPO have no CEO", value: "plcnoceo" },
      ]
    },
  ]);

  //validation
  const simpleValidator = React.useRef(new SimpleReactValidator({
    element: (message) => (
      <span className="error-message text-danger fs-14">{message}</span>
    ),
    autoForceUpdate: this
  }));
  const handleSubmit = (status) => {
    const formValid = simpleValidator.current.allValid();
    if (formValid) {
      let payload = { ...hrdInfo, status };
      props.updateSurvey("hrdInfo", payload);
    } else {
      simpleValidator.current.showMessages()
      forceUpdate(1);
      props.errorAccordion();
    }
  }
  const handleInput = ({ target: { name, value } }) => {
    setHrdInfo({
      ...hrdInfo,
      [name]: value,
    });
  };
  const handleCheckbox = (name, value) => {
    let list = [...hrdInfo[name]];
    let index = list.indexOf(value);
    if (index >= 0) {
      list.splice(index, 1);
    } else {
      list.push(value);
    }
    setHrdInfo({
      ...hrdInfo,
      [name]: list,
    });
  };

  const handleRadio = (name, value) => {
    setHrdInfo({
      ...hrdInfo,
      [name]: value,
    });
  };

  //
  React.useEffect(() => {
    if (props?.surveyDetails) {
      let _hrdInfo = {
        boddecisionfreq: props?.surveyDetails.boddecisionfreq,
        bodinitiative: props?.surveyDetails.bodinitiative,
        bodknowsdomain: props?.surveyDetails.bodknowsdomain,
        bodpoliticalldr: props?.surveyDetails.bodpoliticalldr,
        bodrelative: props?.surveyDetails.bodrelative,
        bodstaffrespclear: props?.surveyDetails.bodstaffrespclear,
        ceobodmtgsuppbuyr: props?.surveyDetails.ceobodmtgsuppbuyr,
        ceodirtime: props?.surveyDetails.ceodirtime,
        ceoqual: props?.surveyDetails.ceoqual,
        ceorational1: props?.surveyDetails.ceorational1,
        ceoworkex: props?.surveyDetails.ceoworkex,
        mgmtceoarea: props?.surveyDetails.mgmtceoarea,
        mgmtsalsrce: props?.surveyDetails.mgmtsalsrce,
        orghasstruct: props?.surveyDetails.orghasstruct,
        planharvest: props?.surveyDetails.planharvest,
        subcomfreq: props?.surveyDetails.subcomfreq,
        subcommittees: props?.surveyDetails.subcommittees,
        twobodsfamily: props?.surveyDetails.twobodsfamily,
      }
      if (props?.surveyDetails.positions) {
        _hrdInfo.positions = props?.surveyDetails.positions;
      }
      setHrdInfo({
        ...hrdInfo,
        ..._hrdInfo
      });
    }
  }, [props?.surveyDetails]);

  React.useEffect(() => {
    if (props?.submitSurveyClick) {
      handleSubmit('completed');
    }
  }, [props?.submitSurveyClick]);

  return (
    <div >
      {/* <h5><b>Basic details of FPO</b></h5> */}
      <div className="container-sa position-relative" >

        <FieldsTemplate
          attributes={hrdInfoAttr}
          simpleValidator={simpleValidator}
          stateAttr={hrdInfo}
          handleInput={handleInput}
          handleRadio={handleRadio}
          handleCheckbox={handleCheckbox}
        />

        <div className="row mt-3">
          <div className="col-md-5 col-sm-12  my-1">
            <NormalButton
              label="Save & Next"
              mainbg={true}
              className="px-3 py-2 mx-auto fs-14 line-height-26 font-Gilroy saveNext"
              onClick={() => handleSubmit('draft')}
              isPopup={true}
            />
          </div>
        </div>
      </div>
    </div>
  )
}