import React, { Component } from "react";
import { history } from "service/helpers";
import "./memberstab.scss";
import { connect } from "react-redux";
import { AddFIGPopup } from "../AddFIGPopup";
import { AddCRPPopup } from "../AddCRPPopup";

export class MembersTabClass extends Component {
  state = {
    isAddCRP: false,
    isAddFIG: false,
  };

  //handle Member Route
  handleMemberRoute = (id) => {
    id ? history.push(`/rm/fpo/member/${id}`) : history.push("/fpo/member");
  };

  //handle Relationship Route
  handleRelationshipRoute = (id) => {
    id
      ? history.push(`/rm/fpo/relationship/${id}`)
      : history.push("/fpo/relationship");
  };

  //handle Mapping Route
  handleMappingRoute = (id) => {
    id
      ? history.push(`/rm/fpo/mapping-members/${id}`)
      : history.push("/fpo/mapping-members");
  };

  //handle Route
  handleRoute = (tab) => {
    switch (tab.toString()) {
      case "1":
        history.push("/fpo/add-member");
        break;
      case "2":
        history.push("/fpo/add-bod");
        break;
      case "3":
        history.push("/fpo/add-staff");
        break;
      case "4":
        this.togglePopup("isAddCRP");
        break;
      case "5":
        this.togglePopup("isAddFIG");
        break;
      default:
        break;
    }
  };

  //toggle popup
  togglePopup = (key) => {
    this.setState((prevState) => ({
      [key]: !prevState[key],
    }));
  };

  render() {
    let { currentTab, createAccess, id, match = {} } = this.props;
    let { path = "" } = match || {};
    let { isAddCRP, isAddFIG } = this.state;

    return (
      <section className="member-section bg-blue-f9">
        <div className="container-sa">
          <div className="row">
            <div className="col-md-12">
              <div className="members-tab scroll-x">
                <div
                  className="d-flex justify-content-start justify-content-md-center"
                  style={{ minWidth: "fit-content" }}
                >
                  <button
                    className={`btn ${
                      path.includes("fpo/member") ? "active" : ""
                    }`}
                    onClick={() =>
                      !path.includes("fpo/member")
                        ? this.handleMemberRoute(id)
                        : {}
                    }
                  >
                    Profile
                  </button>
                  {localStorage.getItem("userGroupName") === "fpo" &&
                  JSON.parse(localStorage.getItem("isKymUser")) ? (
                    <button
                      className={`btn ${
                        path.includes("fpo/report") ? "active" : ""
                      }`}
                      onClick={() =>
                        !path.includes("fpo/report")
                          ? history.push("/fpo/report")
                          : {}
                      }
                    >
                      Member Analytics
                    </button>
                  ) : null}
                  <button
                    className={`btn ${
                      path.includes("fpo/relationship") ? "active" : ""
                    }`}
                    onClick={() =>
                      !path.includes("fpo/relationship")
                        ? this.handleRelationshipRoute(id)
                        : {}
                    }
                  >
                    Relationship history
                  </button>
                  <button
                    className={`btn ${
                      path.includes("fpo/mapping-members") ? "active" : ""
                    }`}
                    onClick={() =>
                      !path.includes("fpo/mapping-members")
                        ? this.handleMappingRoute(id)
                        : {}
                    }
                  >
                    Member Mapping
                  </button>
                </div>
              </div>
            </div>
            {/* <div className="col-md-2">
              <div className="d-flex justify-content-end mt-md-0 mt-4">
                {createAccess &&
                  !path.includes("fpo/report") &&
                  !path.includes("fpo/mapping-members") && (
                    <div className="ml-auto">
                      <button
                        className="add-button"
                        onClick={() => this.handleRoute(currentTab)}
                      >
                        Add
                      </button>
                    </div>
                  )}
              </div>
            </div> */}
          </div>
        </div>
        <AddCRPPopup
          isOpen={isAddCRP}
          toggle={() => this.togglePopup("isAddCRP")}
        />
        <AddFIGPopup
          isOpen={isAddFIG}
          toggle={() => this.togglePopup("isAddFIG")}
        />
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  currentTab: state.home.tab,
});

export const MembersTab = connect(mapStateToProps, null)(MembersTabClass);
