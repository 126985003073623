import React, { Component } from "react";
import { Navbar, NormalSelect } from "component/common";
import "./style.scss";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { Slider } from "./slider.js";
import { history } from "service/helpers";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  commodityList,
  mandiList,
  oneMandiDiffCommodity,
  oneCommodityDiffMandi,
  commodityByMandiList,
  mandiByPriceList,
  getStateList,
  getStateByPrice,
  getMandiByPriceAndCommodity,
  getCommodityVariety,
} from "action/PricingandWeatherAct";

import { allState } from "action/MemberAct";
import {
  optionsForDiffCommodityData,
  optionsData,
  tableContentData,
  oneCommodityDiffMandiData,
} from "./Data";
import moment from "moment";
import arrow_left from "assets/svg/add_member/arrow_left.svg";
class PricingClass extends Component {
  state = {
    oneCommodity: true,
    options: optionsData,
    optionsForDiffCommodity: optionsForDiffCommodityData,
    selectedCommodityOptions: [],
    selectedCommodity: "",
    state: "",
    apmc: "",
    tableData: tableContentData,
    oneCommodityDiffMandi: oneCommodityDiffMandiData,
    days: 7,
    pointerPosition: 23,
    commodityOptions: [],
    varietyOptions: [],
    stateOptions: [],
    apmcOptions: [],
    oneMandiStateOptions: [],
    commodity1: "",
    commodity2: "",
    commodity3: "",
    commodity: "",
    mandi1: "",
    state1: "",
    mandi2: "",
    state2: "",
    variety: "",
    isLoading: false,
    isLoadingForOneCommodity: false,
    daysForOneCommodity: 7,
    pointerPositionForOnecommodity: 23,
    apmcOptionsForsameCommodity1: [],
    apmcOptionsForsameCommodity2: [],
  };

  //lifecycles
  componentDidMount() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    this.getStateOptions();
    this.getAllCommodity();
  }

  getStateOptions = () => {
    let { getStateList } = this.props;
    getStateList().then((data) => {
      let StateData = [];
      data.map((list) => {
        StateData.push({ value: list.stateName, label: list.stateName });
      });
      this.setState({
        oneMandiStateOptions: StateData,
      });
    });
  };

  getCommodity = () => {
    let { apmc } = this.state;
    let mandiName = apmc;
    let { commodityByMandiList } = this.props;
    let body = {
      mandiName,
    };

    commodityByMandiList(body).then((data) => {
      let commdityLists = [];
      data.map((list) => {
        commdityLists.push({
          label: list.commodityName,
          value: list.commodityName,
        });
      });
      this.setState({ commodityOptions: commdityLists });
    });
  };

  getAllCommodity = () => {
    let { commodityList } = this.props;
    commodityList().then((data) => {
      let commdityLists = [];
      data.map((list) => {
        commdityLists.push({
          label: list.commodityName,
          value: list.commodityName,
        });
      });
      this.setState({ commodityOptions: commdityLists });
    });
  };

  //toggle for commondity selection for comparing
  toggle = () => {
    this.setState({
      oneCommodity: !this.state.oneCommodity,
    });
  };

  stateByPrice = () => {
    let { getStateByPrice, getCommodityVariety } = this.props;
    let { commodity } = this.state;
    let commodityId = commodity;
    let body = {
      commodityId,
    };
    getCommodityVariety(body).then((variety) => {
      if (variety.length == 0) {
        getStateByPrice(body).then((data) => {
          let StateData = [];
          data.map((list) => {
            StateData.push({ value: list.state_name, label: list.state_name });
          });
          this.setState({
            stateOptions: StateData,
          });
        });
      } else {
        let varietyData = [];
        variety.map((list) => {
          varietyData.push({ value: list.variety, label: list.variety });
        });
        this.setState({
          varietyOptions: varietyData,
        });
      }
    });
  };

  stateByVariety = (variety) => {
    let { getStateByPrice } = this.props;
    let { commodity } = this.state;
    let commodityId = commodity;
    let body = {
      commodityId,
      variety,
    };
    getStateByPrice(body).then((data) => {
      let StateData = [];
      data.map((list) => {
        StateData.push({ value: list.state_name, label: list.state_name });
      });
      this.setState({
        stateOptions: StateData,
        state1: "",
        state2: "",
        mandi1: "",
        mandi2: "",
        apmcOptions: "",
      });
    });
  };

  //handle inputs
  handleInput = ({ target: { name, value } }) => {
    this.setState(
      {
        [name]: value,
      },
      () => {
        let {
          selectedCommodityOptions,
          commodity1,
          commodity2,
          commodity3,
          commodity,
          state1,
          state2,
          mandi1,
          mandi2,
          variety,
          commodityOptions,
        } = this.state;
        if (name === "apmc") {
          this.getCommodity(value);
        }
        if (name === "commodity") {
          this.setState(
            {
              varietyOptions: [],
            },
            () => {
              this.stateByPrice();
            }
          );
        }
        if (name == "variety") {
          this.stateByVariety(value);
        }
        if (name === "state" || name === "state1" || name === "state2") {
          //get mandi list
          this._getMandiList(value, name);
        }
        if (
          name === "commodity1" ||
          name === "commodity2" ||
          name === "commodity3"
        ) {
          //get mandi details
          this._getMandiDetails(name, 7);
          selectedCommodityOptions = [];
          this.setState({ selectedCommodityOptions });

          if (commodity1 !== "") {
            let commodityItem = [];
            commodityItem = commodityOptions.filter(
              (x) => x.value == commodity1
            );
            selectedCommodityOptions.push({
              label: commodityItem.length !== 0 ? commodityItem[0].label : "",
              value: commodity1,
            });
          }
          if (commodity2 !== "") {
            let commodityItem = [];
            commodityItem = commodityOptions.filter(
              (x) => x.value == commodity2
            );
            selectedCommodityOptions.push({
              label: commodityItem.length !== 0 ? commodityItem[0].label : "",
              value: commodity2,
            });
          }
          if (commodity3 !== "") {
            let commodityItem = [];
            commodityItem = commodityOptions.filter(
              (x) => x.value == commodity3
            );
            selectedCommodityOptions.push({
              label: commodityItem.length !== 0 ? commodityItem[0].label : "",
              value: commodity3,
            });
          }
          this.setState(
            {
              selectedCommodityOptions,
            },
            () => {
              if (value === selectedCommodityOptions[0].value) {
                this.setState(
                  {
                    days: 7,
                    pointerPosition: 23,
                    isLoading: true,
                    selectedCommodity: value,
                  },
                  () => {
                    this.getSelectedCommodityData(value);
                  }
                );
              }
            }
          );
        }

        if (name === "selectedCommodity") {
          this.setState(
            { days: 7, pointerPosition: 23, isLoading: true },
            () => {
              this.getSelectedCommodityData(value);
            }
          );
        }

        if (
          name === "state1" ||
          name === "state2" ||
          name === "mandi1" ||
          name === "mandi2" ||
          name === "commodity"
        ) {
          if (name == "state1") {
            this.setState({
              mandi1: "",
            });
          } else if (name == "state2") {
            this.setState({
              mandi2: "",
            });
          } else if (
            state1 !== "" &&
            state2 !== "" &&
            mandi1 !== "" &&
            mandi2 !== "" &&
            commodity !== ""
          ) {
            this._getDataforOneCommodityDiffMandi(
              variety,
              commodity,
              state1,
              state2,
              mandi1,
              mandi2
            );
          }
        }

        //clear data when state or apmc changes
        if (name === "commodity") {
          this._clearDataforOnecommodityDiffMandi();
        } else if (name === "state" || name === "apmc") {
          this._clearDataforDiffcommodity(name);
        }
      }
    );
  };
  //get Data for One Commodity Different Mandi
  _getDataforOneCommodityDiffMandi = (
    variety,
    commodity,
    state1,
    state2,
    mandi1,
    mandi2
  ) => {
    let { daysForOneCommodity } = this.state;
    let body =
      variety == ""
        ? {
            commodity,
            apmcOne: mandi1,
            apmcTwo: mandi2,
            days: daysForOneCommodity,
          }
        : {
            variety,
            commodity,
            apmcOne: mandi1,
            apmcTwo: mandi2,
            days: daysForOneCommodity,
          };
    this.setState({ isLoadingForOneCommodity: true });
    this.props.oneCommodityDiffMandi(body).then((data) => {
      let { resultOne = {}, resultTwo = {} } = data;
      this._setdataforGraphSameCommodity(resultOne, resultTwo);
      this._setTabledataforOneCommodity(resultOne, resultTwo);
    });
  };

  _setdataforGraphSameCommodity = (resultOne, resultTwo) => {
    let { optionsForDiffCommodity } = this.state;
    let optionsDataresultOne = resultOne.pricingDetails;
    let optionsDataresultTwo = resultTwo.pricingDetails;
    let { xAxis = {}, series = [] } = optionsForDiffCommodity;
    xAxis.categories = [];
    series[0].data = [];
    series[1].data = [];
    series[2].data = [];
    series[3].data = [];
    this.setState({
      optionsForDiffCommodity,
    });
    optionsDataresultOne.map((list) => {
      xAxis.categories.push(moment(list.date).format("DD MMM YYYY"));
      series[0].data.push(list.averagePrice ? list.averagePrice : 0);
      // series[1].data.push(list.averagePrice);
    });
    optionsDataresultTwo.map((list) => {
      series[2].data.push(list.averagePrice ? list.averagePrice : 0);
      // series[3].data.push(list.averagePrice);
    });
    // optionsDataresultOne.map((list) => {
    //   series[1].data.push(resultOne.averagePrice);
    //   series[3].data.push(resultTwo.averagePrice);
    // });
    this.setState({
      isLoadingForOneCommodity: false,
      optionsForDiffCommodity,
    });
  };

  _setTabledataforOneCommodity = (resultOne, resultTwo) => {
    let { oneCommodityDiffMandi } = this.state;
    oneCommodityDiffMandi.map((list) => {
      if (list.head === "Latest Price") {
        list.column1 = resultOne.averagePrice || "-";
        list.column2 = resultTwo.averagePrice || "-";
        list.column3 = resultOne.averagePrice - resultTwo.averagePrice || "-";
        list.date1 = resultOne.latestDate || "-";
        list.date2 = resultTwo.latestDate || "-";
      } else if (list.head === "Average Price") {
        list.column1 = resultOne.averagePrice;
        list.column2 = resultTwo.averagePrice;
        list.column3 = resultOne.averagePrice - resultTwo.averagePrice;
      } else if (list.head === "Minimum Price") {
        list.column1 = resultOne.minPrice;
        list.column2 = resultTwo.minPrice;
        list.column3 = resultOne.minPrice - resultTwo.minPrice;
      } else if (list.head === "Maximum Price") {
        list.column1 = resultOne.maxPrice;
        list.column2 = resultTwo.maxPrice;
        list.column3 = resultOne.maxPrice - resultTwo.maxPrice;
      } else if (list.head === "Price Trend") {
        list.column1 =
          resultOne.priceTrend !== null
            ? resultOne.priceTrend === "decreasing"
              ? "down"
              : "up"
            : "-";
        list.column2 =
          resultTwo.priceTrend !== null
            ? resultTwo.priceTrend === "decreasing"
              ? "down"
              : "up"
            : "-";
      } else if (list.head.includes("MSP")) {
        list.head = "MSP ".concat(
          "(" + resultOne.mspValue ? resultOne.mspValue.year : " - " + ")"
        );
        list.column1 = resultOne.mspValue ? resultOne.mspValue.price : "-";
      }
    });

    this.setState({ oneCommodityDiffMandi });
  };

  //clear data when one commodity diff mandi
  _clearDataforOnecommodityDiffMandi = () => {
    let { optionsForDiffCommodity } = this.state;
    let { xAxis = {}, series = [] } = optionsForDiffCommodity;
    xAxis.categories = [];
    series[0].data = [];
    series[1].data = [];
    this.setState({
      oneCommodityDiffMandi: [
        { head: "Latest Price", column1: "-", column2: "-", column3: "-" },
        { head: "Average Price", column1: "-", column2: "-", column3: "-" },
        { head: "Minimum Price", column1: "-", column2: "-", column3: "-" },
        { head: "Maximum Price", column1: "-", column2: "-", column3: "-" },
        { head: "Price Trend", column1: "-", column2: "-", column3: "" },
        { head: "MSP", column1: "-", column2: "", column3: "" },
      ],
      state1: "",
      state2: "",
      mandi1: "",
      mandi2: "",
      optionsForDiffCommodity,
      daysForOneCommodity: 7,
      pointerPositionForOnecommodity: 23,
    });
  };

  //clear data when diff commodity
  _clearDataforDiffcommodity = (name) => {
    let { options, apmc } = this.state;
    let { xAxis = {}, series = [] } = options;
    xAxis.categories = [];
    series[0].data = [];
    series[1].data = [];
    this.setState({
      tableData: [
        {
          head: "Unit",
          column1: "Per Qtl",
          column2: "Per Qtl",
          column3: "Per Qtl",
        },
        { head: "Latest Price", column1: "-", column2: "-", column3: "-" },
        { head: "Average Price", column1: "-", column2: "-", column3: "-" },
        { head: "Minimum Price", column1: "-", column2: "-", column3: "-" },
        { head: "Maximum Price", column1: "-", column2: "-", column3: "-" },
        { head: "Price Trend", column1: "-", column2: "-", column3: "-" },
        { head: "MSP", column1: "-", column2: "-", column3: "-" },
      ],
      selectedCommodity: [],
      selectedCommodityOptions: [],
      commodity1: "",
      commodity2: "",
      commodity3: "",
      apmc: name == "state" ? "" : apmc,
      options,
      days: 7,
      pointerPosition: 23,
    });
  };

  //get selected commodity data
  getSelectedCommodityData = (value) => {
    let { state, apmc, days } = this.state;
    let body = {
      state,
      apmc,
      commodity: value,
      days,
    };
    this.props.oneMandiDiffCommodity(body).then((data) => {
      let { pricingDetails = [], averagePrice = "" } = data;
      this._setdataforGraph(pricingDetails, averagePrice);
    });
  };

  //get mandi details
  _getMandiDetails = (name, days) => {
    let { commodity1, commodity2, commodity3, state, apmc } = this.state;
    let { oneMandiDiffCommodity } = this.props;
    if (name === "commodity1") {
      let body = {
        apmc,
        commodity: commodity1,
        days,
      };
      oneMandiDiffCommodity(body).then((data) => {
        this.putData(data, 1);
      });
    } else if (name === "commodity2") {
      let body = {
        apmc,
        commodity: commodity2,
        days,
      };
      oneMandiDiffCommodity(body).then((data) => {
        this.putData(data, 2);
      });
    } else if (name === "commodity3") {
      let body = {
        apmc,
        commodity: commodity3,
        days,
      };
      oneMandiDiffCommodity(body).then((data) => {
        this.putData(data, 3);
      });
    }
  };

  //set table data
  putData = (data, column) => {
    let { tableData, selectedCommodity, selectedCommodityOptions } = this.state;
    let {
      minPrice = "-",
      latestPrice = "-",
      averagePrice = "-",
      maxPrice = "-",
      priceTrend = "-",
      mspValue = {},
      latestDate = "-",
      pricingDetails = [],
    } = data;
    tableData.map((list) => {
      if (list.head === "Latest Price") {
        if (column === 1) {
          list.column1 = averagePrice;
          list.date1 = latestDate;
        } else if (column === 2) {
          list.column2 = averagePrice;
          list.date2 = latestDate;
        } else if (column === 3) {
          list.column3 = averagePrice;
          list.date3 = latestDate;
        }
      } else if (list.head === "Minimum Price") {
        if (column === 1) {
          list.column1 = minPrice !== 0 ? minPrice : "-";
        } else if (column === 2) {
          list.column2 = minPrice !== 0 ? minPrice : "-";
        } else if (column === 3) {
          list.column3 = minPrice !== 0 ? minPrice : "-";
        }
      } else if (list.head === "Average Price") {
        if (column === 1) {
          list.column1 = averagePrice !== 0 ? averagePrice : "-";
        } else if (column === 2) {
          list.column2 = averagePrice !== 0 ? averagePrice : "-";
        } else if (column === 3) {
          list.column3 = averagePrice !== 0 ? averagePrice : "-";
        }
      } else if (list.head === "Maximum Price") {
        if (column === 1) {
          list.column1 = maxPrice !== 0 ? maxPrice : "-";
        } else if (column === 2) {
          list.column2 = maxPrice !== 0 ? maxPrice : "-";
        } else if (column === 3) {
          list.column3 = maxPrice !== 0 ? maxPrice : "-";
        }
      } else if (list.head === "Price Trend") {
        if (column === 1) {
          list.column1 =
            priceTrend !== null
              ? priceTrend === "decreasing"
                ? "down"
                : "up"
              : "-";
        } else if (column === 2) {
          list.column2 =
            priceTrend !== null
              ? priceTrend === "decreasing"
                ? "down"
                : "up"
              : "-";
        } else if (column === 3) {
          list.column3 =
            priceTrend !== null
              ? priceTrend === "decreasing"
                ? "down"
                : "up"
              : "-";
        }
      } else if (list.head.includes("MSP")) {
        list.head = "MSP ".concat("(" + mspValue ? mspValue.year : " - " + ")");
        if (column === 1) {
          list.column1 =
            mspValue && mspValue.price !== null ? mspValue.price : "-";
        } else if (column === 2) {
          list.column2 =
            mspValue && mspValue.price !== null ? mspValue.price : "-";
        } else if (column === 3) {
          list.column3 =
            mspValue && mspValue.price !== null ? mspValue.price : "-";
        }
      }
    });
    this.setState({ tableData }, () => {
      if (selectedCommodity === "") {
        this.setState(
          {
            selectedCommodity: selectedCommodityOptions[0].value,
            isLoading: true,
          },
          () => {
            this._setdataforGraph(pricingDetails, averagePrice);
          }
        );
      }
    });
  };

  //set graph data
  _setdataforGraph = (pricingDetails, averagePrice) => {
    let { options } = this.state;
    let optionsData = pricingDetails;
    let { xAxis = {}, series = [] } = options;
    xAxis.categories = [];
    series[0].data = [];
    series[1].data = [];
    this.setState({
      options,
    });
    optionsData.map((list) => {
      xAxis.categories.push(moment(list.date).format("DD MMM YYYY"));
      series[0].data.push(list.averagePrice ? list.averagePrice : 0);
    });
    // optionsData.map(() => {
    //   series[1].data.push(averagePrice);
    // });
    this.setState({
      isLoading: false,
      options,
    });
  };

  //get mandi list
  _getMandiList = (stateName, name) => {
    let { mandiList, mandiByPriceList, getMandiByPriceAndCommodity } =
      this.props;
    let { state1, state2, commodity, variety } = this.state;

    let body = {
      stateName,
    };
    let apmcLists = [];
    if (name === "state") {
      mandiByPriceList(body).then((data) => {
        data.map((list) => {
          apmcLists.push({ label: list.mandiName, value: list.mandiName });
        });
        this.setState({ apmcOptions: apmcLists });
      });
    } else if (name == "state1" || name == "state2") {
      let state = name === "state1" ? state1 : state2;
      let commodityId = commodity;
      let body =
        variety == ""
          ? {
              state,
              commodityId,
            }
          : {
              state,
              commodityId,
              variety,
            };
      getMandiByPriceAndCommodity(body).then((data) => {
        data.map((list) => {
          apmcLists.push({ label: list.mandiName, value: list.mandiName });
        });
        if (name === "state1") {
          this.setState({ apmcOptionsForsameCommodity1: apmcLists });
        } else if (name === "state2") {
          this.setState({ apmcOptionsForsameCommodity2: apmcLists });
        }
      });
    }
  };

  //slider for day duration
  handleSlider = (value) => {
    let {
      selectedCommodity,
      oneCommodity,
      variety,
      commodity,
      state1,
      state2,
      mandi1,
      mandi2,
    } = this.state;
    let position = 23;
    switch (value) {
      case 1: {
        position = -1;
        break;
      }
      case 7: {
        position = 23;
        break;
      }
      case 14: {
        position = 45;
        break;
      }
      case 21: {
        position = 68;
        break;
      }
      case 30: {
        position = 95.5;
        break;
      }
      default: {
        position = -1;
        break;
      }
    }

    if (!oneCommodity) {
      this.setState(
        {
          days: value,
          pointerPosition: position,
        },
        () => {
          if (selectedCommodity !== "") {
            this.setState({ isLoading: true }, () => {
              this.getSelectedCommodityData(selectedCommodity);
            });
          }
        }
      );
    } else {
      this.setState(
        {
          daysForOneCommodity: value,
          pointerPositionForOnecommodity: position,
        },
        () => {
          this.setState({ isLoadingForOneCommodity: true }, () => {
            if (
              state1 !== "" &&
              state2 !== "" &&
              mandi1 !== "" &&
              mandi2 !== "" &&
              commodity !== ""
            ) {
              this._getDataforOneCommodityDiffMandi(
                variety,
                commodity,
                state1,
                state2,
                mandi1,
                mandi2
              );
            }
          });
        }
      );
    }
  };

  render() {
    let {
      oneCommodity,
      options,
      selectedCommodityOptions,
      selectedCommodity,
      state,
      apmc,
      commodityOptions,
      tableData,
      pointerPosition,
      commodity1,
      commodity2,
      commodity3,
      stateOptions,
      apmcOptions,
      commodity,
      mandi1,
      state1,
      mandi2,
      state2,
      variety,
      varietyOptions,
      oneCommodityDiffMandi,
      isLoading,
      optionsForDiffCommodity,
      pointerPositionForOnecommodity,
      apmcOptionsForsameCommodity1,
      apmcOptionsForsameCommodity2,
      isLoadingForOneCommodity,
      oneMandiStateOptions,
    } = this.state;

    let mandiName1 = "",
      option1 = [],
      mandiName2 = "",
      option2 = [];
    option1 = apmcOptionsForsameCommodity1.filter((x) => x.value == mandi1);
    mandiName1 = option1.length !== 0 ? option1[0].label : "";
    option2 = apmcOptionsForsameCommodity2.filter((x) => x.value == mandi2);
    mandiName2 = option2.length !== 0 ? option2[0].label : "";
    return (
      <div>
        <div className="normal-header">{/* <Navbar /> */}</div>
        <div className="pricing-section bg-blue-f9">
          <div className="container-sa">
            <div className="d-flex justify-content-between pt-5 pb-1">
              <div
                onClick={() => history.push("/")}
                className="d-flex cursor-pointer align-items-center"
              >
                <img className="cursor-pointer" src={arrow_left} alt="back" />
                <p className="ml-3 add-member-text-back mb-0">
                  {"Price Details"}
                </p>
              </div>
              <div>
                <div className="d-flex justify-content-center align-items-center toggle-slider">
                  <label className="fs-14 fw-300 mr-4">
                    One Mandi Different Commodities
                  </label>
                  <label className="switch">
                    <input type="checkbox" checked={oneCommodity} />
                    <span
                      className="slider round"
                      onClick={() => this.toggle()}
                    ></span>
                  </label>
                  <label className="fs-14 fw-300 ml-4">
                    One Commodity Different Mandis
                  </label>
                </div>
              </div>
            </div>
            {!oneCommodity ? (
              <div className="row pt-2 pb-5">
                <div className="col-6 pt-3 px-4">
                  <p className="fs-14 line-height-19 text-black pb-1 text-center">
                    Compare prices of various commodities in different mandis
                    using this tool
                  </p>
                  <div className="d-flex flex-column p-4 pricing-area">
                    <div className="mb-4">
                      <div className="row w-100">
                        <div className="d-flex align-items-center col-8 pr-0">
                          <p className="fs-14 line-height-16 text-green mb-0 pr-1">
                            ----
                          </p>
                          <p className="fs-14 line-height-16 text-black-4f mb-0">
                            Average Price/Unit
                          </p>
                        </div>
                        <div className="col-4 px-0">
                          <NormalSelect
                            isFullWidthwithoutLabel={true}
                            placeholder="Select commodity"
                            value={selectedCommodity}
                            arrow={true}
                            name="selectedCommodity"
                            options={selectedCommodityOptions}
                            handleChange={this.handleInput}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="mt-3 mb-2">
                      <div className="w-100 background-price-charts">
                        {isLoading ? (
                          <div className="d-flex justify-content-center align-items-center py-5">
                            <div className="spinner-border ml-2 spinner-border-sm"></div>
                          </div>
                        ) : options.series.length !== 0 &&
                          options.series[0].data.length !== 0 ? (
                          <HighchartsReact
                            highcharts={Highcharts}
                            options={options}
                          />
                        ) : (
                          <div className="d-flex justify-content-center align-items-center h-100">
                            <p className="d-flex justify-content-center align-items-center text-black-50 fw-700 fs-18">
                              No data here !!!
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="d-flex justify-content-center mt-4 mb-3">
                      <p className="fs-14 line-height-16 text-black fw-600">
                        Choose duration to display prices (days)
                      </p>
                    </div>
                    <div className="d-flex justify-content-center days-slider">
                      <Slider
                        pointerPosition={pointerPosition}
                        handleSlider={this.handleSlider}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-6 table-details px-4">
                  <div className="row mb-3 mt-1">
                    <div className="col-6 pr-1">
                      <NormalSelect
                        isFullWidthwithoutLabel={true}
                        placeholder="Select state"
                        value={state}
                        arrow={true}
                        name="state"
                        options={oneMandiStateOptions}
                        handleChange={this.handleInput}
                      />
                    </div>
                    <div className="col-6 pl-1">
                      <NormalSelect
                        isFullWidthwithoutLabel={true}
                        placeholder="Select mandi"
                        value={apmc}
                        arrow={true}
                        disabled={state === ""}
                        name="apmc"
                        options={apmcOptions}
                        handleChange={this.handleInput}
                      />
                    </div>
                  </div>
                  <div>
                    <table className="table">
                      <thead>
                        <tr>
                          <th scope="col"></th>
                          <th scope="col" className="text-orange">
                            <NormalSelect
                              isFullWidthwithoutLabel={true}
                              placeholder="Commodity"
                              value={commodity1}
                              arrow={true}
                              name="commodity1"
                              options={commodityOptions.filter(
                                (x) =>
                                  x.value !== commodity3 &&
                                  x.value !== commodity2
                              )}
                              disabled={state === "" || apmc === ""}
                              handleChange={this.handleInput}
                            />
                          </th>
                          <th scope="col" className="text-green">
                            <NormalSelect
                              isFullWidthwithoutLabel={true}
                              placeholder="Commodity"
                              value={commodity2}
                              arrow={true}
                              name="commodity2"
                              options={commodityOptions.filter(
                                (x) =>
                                  x.value !== commodity3 &&
                                  x.value !== commodity1
                              )}
                              disabled={state === "" || apmc === ""}
                              handleChange={this.handleInput}
                            />
                          </th>
                          <th scope="col">
                            <NormalSelect
                              isFullWidthwithoutLabel={true}
                              placeholder="Commodity"
                              value={commodity3}
                              arrow={true}
                              name="commodity3"
                              options={commodityOptions.filter(
                                (x) =>
                                  x.value !== commodity2 &&
                                  x.value !== commodity1
                              )}
                              disabled={state === "" || apmc === ""}
                              handleChange={this.handleInput}
                            />
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {tableData.map((list, index) => (
                          <tr
                            className={`${
                              index === 0 ? "border-top-none" : ""
                            }`}
                          >
                            <td className="table-side-heading">{list.head}</td>
                            {index === tableData.length - 2 ? (
                              <>
                                <td
                                  className={`table-content 
                                                               ${
                                                                 selectedCommodity !==
                                                                   "" &&
                                                                 selectedCommodity ===
                                                                   commodity1
                                                                   ? "bg-selected"
                                                                   : ""
                                                               }
                                                                `}
                                >
                                  <i
                                    className={`icon-${list.column1}-color fs-16`}
                                  ></i>
                                </td>
                                <td
                                  className={`table-content ${
                                    selectedCommodity !== "" &&
                                    selectedCommodity === commodity2
                                      ? "bg-selected"
                                      : ""
                                  } `}
                                >
                                  <i
                                    className={`icon-${list.column2}-color fs-16`}
                                  ></i>
                                </td>
                                <td
                                  className={`table-content
                                                                ${
                                                                  selectedCommodity !==
                                                                    "" &&
                                                                  selectedCommodity ===
                                                                    commodity3
                                                                    ? "bg-selected"
                                                                    : ""
                                                                }
                                                                `}
                                >
                                  <i
                                    className={`icon-${list.column3}-color fs-16`}
                                  ></i>
                                </td>
                              </>
                            ) : (
                              <>
                                <td
                                  className={`${
                                    index === 0 ||
                                    index + 1 === tableData.length
                                      ? "table-head"
                                      : "table-content"
                                  } ${
                                    selectedCommodity !== "" &&
                                    selectedCommodity === commodity1
                                      ? "bg-selected"
                                      : ""
                                  }`}
                                >
                                  {list.column1 !== "-" ? (
                                    <i className="icon-rupee fs-14 pr-1"></i>
                                  ) : (
                                    ""
                                  )}
                                  {list.column1}
                                  {list.head == "Latest Price" &&
                                    list.column1 !== "-" && (
                                      <span className="fs-11 ml-1">
                                        (
                                        {moment(list.date1).format(
                                          "DD-MM-YYYY"
                                        )}
                                        )
                                      </span>
                                    )}
                                </td>
                                <td
                                  className={`${
                                    index === 0 ||
                                    index + 1 === tableData.length
                                      ? "table-head"
                                      : "table-content"
                                  }  ${
                                    selectedCommodity !== "" &&
                                    selectedCommodity === commodity2
                                      ? "bg-selected"
                                      : ""
                                  }`}
                                >
                                  {list.column2 !== "-" ? (
                                    <i className="icon-rupee fs-14 pr-1"></i>
                                  ) : (
                                    ""
                                  )}
                                  {list.column2}
                                  {list.head == "Latest Price" &&
                                    list.column2 !== "-" && (
                                      <span className="fs-11 ml-1">
                                        (
                                        {moment(list.date2).format(
                                          "DD-MM-YYYY"
                                        )}
                                        )
                                      </span>
                                    )}
                                </td>
                                <td
                                  className={`${
                                    index === 0 ||
                                    index + 1 === tableData.length
                                      ? "table-head"
                                      : "table-content"
                                  }  ${
                                    selectedCommodity !== "" &&
                                    selectedCommodity === commodity3
                                      ? "bg-selected"
                                      : ""
                                  }`}
                                >
                                  {list.column3 !== "-" ? (
                                    <i className="icon-rupee fs-14 pr-1"></i>
                                  ) : (
                                    ""
                                  )}
                                  {list.column3}
                                  {list.head == "Latest Price" &&
                                    list.column3 !== "-" && (
                                      <span className="fs-11 ml-1">
                                        (
                                        {moment(list.date3).format(
                                          "DD-MM-YYYY"
                                        )}
                                        )
                                      </span>
                                    )}
                                </td>
                              </>
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            ) : (
              <div className="row pt-2 pb-5">
                <div className="col-6 pt-3 px-4">
                  <p className="fs-14 line-height-19 text-black pb-1 text-center">
                    Compare prices of various commodities in different mandis
                    using this tool
                  </p>
                  <div className="d-flex flex-column p-4 pricing-area">
                    <div className="mb-4">
                      <div className="row w-100">
                        <div className="d-flex align-items-center col-6 pr-0">
                          <div className="d-flex flex-column">
                            <p className="fs-14 line-height-16 text-green mb-0 pr-1">
                              ----
                            </p>
                            <p className="fs-14 line-height-16 text-orange mb-0 pr-1">
                              ----
                            </p>
                          </div>
                          <p className="fs-14 line-height-16 text-black-4f mb-0 fw-700">
                            {" "}
                            Average Price/Quintal
                          </p>
                        </div>
                        {mandi1 !== "" && mandi2 !== "" && (
                          <div className="col-6 px-0 d-flex justify-content-end">
                            <div className="d-flex align-items-center">
                              <p className="round-orange mb-0"></p>
                              <p className="fs-14 line-height-16 text-black mb-0 fw-600 pr-3 pl-2">
                                {mandiName1}
                              </p>
                            </div>
                            <div className="d-flex align-items-center">
                              <p className="round-green mb-0"></p>
                              <p className="fs-14 line-height-16 text-black mb-0 fw-600 pr-3 pl-2">
                                {mandiName2}
                              </p>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="mt-3 mb-2">
                      <div className="w-100 background-price-charts">
                        {isLoadingForOneCommodity ? (
                          <div className="d-flex justify-content-center align-items-center py-5">
                            <div className="spinner-border ml-2 spinner-border-sm"></div>
                          </div>
                        ) : optionsForDiffCommodity.series.length !== 0 &&
                          state1 !== "" &&
                          state2 !== "" &&
                          mandi1 !== "" &&
                          mandi2 !== "" &&
                          commodity !== "" &&
                          optionsForDiffCommodity.series[0].data.length !==
                            0 ? (
                          <HighchartsReact
                            highcharts={Highcharts}
                            options={optionsForDiffCommodity}
                          />
                        ) : (
                          <div className="d-flex justify-content-center align-items-center h-100">
                            <p className="d-flex justify-content-center align-items-center text-black-50 fw-700 fs-18">
                              No data here !!!
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="d-flex justify-content-center mt-4 mb-3">
                      <p className="fs-14 line-height-16 text-black fw-600">
                        Choose duration to display prices (days)
                      </p>
                    </div>
                    <div className="d-flex justify-content-center days-slider">
                      <Slider
                        pointerPosition={pointerPositionForOnecommodity}
                        handleSlider={this.handleSlider}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-6 table-details px-4">
                  <table className="table">
                    <tbody>
                      <tr className="border-top-none">
                        <td></td>
                        <td colSpan="2" className="pt-0 pb-3 pr-3">
                          <NormalSelect
                            isFullWidthwithoutLabel={true}
                            placeholder="Select commodity"
                            value={commodity}
                            arrow={true}
                            name="commodity"
                            options={commodityOptions}
                            handleChange={this.handleInput}
                          />
                        </td>
                        <td></td>
                      </tr>
                      {varietyOptions.length !== 0 && (
                        <tr className="border-top-none">
                          <td></td>
                          <td colSpan="2" className="pt-0 pb-3 pr-3">
                            <NormalSelect
                              isFullWidthwithoutLabel={true}
                              placeholder="Select Variety"
                              value={variety}
                              arrow={true}
                              name="variety"
                              options={varietyOptions}
                              handleChange={this.handleInput}
                              disabled={commodity === ""}
                            />
                          </td>
                          <td></td>
                        </tr>
                      )}
                      <tr className="border-top-none">
                        <td></td>
                        <td className="py-0 table-buttons text-orange">
                          <NormalSelect
                            isFullWidthwithoutLabel={true}
                            placeholder="Select state"
                            value={state1}
                            arrow={true}
                            name="state1"
                            disabled={
                              commodity === "" ||
                              (variety === "" && varietyOptions.length !== 0)
                            }
                            options={stateOptions}
                            handleChange={this.handleInput}
                          />
                        </td>
                        <td className="py-0 table-buttons text-green">
                          <NormalSelect
                            isFullWidthwithoutLabel={true}
                            placeholder="Select state"
                            value={state2}
                            arrow={true}
                            disabled={
                              commodity === "" ||
                              (variety === "" && varietyOptions.length !== 0)
                            }
                            name="state2"
                            options={stateOptions}
                            handleChange={this.handleInput}
                          />
                        </td>
                        <td></td>
                      </tr>
                      <tr className="border-top-none">
                        <td></td>
                        <td className="py-0 table-buttons text-orange">
                          <NormalSelect
                            isFullWidthwithoutLabel={true}
                            placeholder="Select mandi"
                            value={mandi1}
                            arrow={true}
                            name="mandi1"
                            disabled={state1 === ""}
                            options={apmcOptionsForsameCommodity1.filter(
                              (x) => x.label !== mandi2
                            )}
                            handleChange={this.handleInput}
                          />
                        </td>
                        <td className="py-0 table-buttons text-green">
                          <NormalSelect
                            isFullWidthwithoutLabel={true}
                            placeholder="Select mandi"
                            value={mandi2}
                            arrow={true}
                            name="mandi2"
                            disabled={state2 === ""}
                            options={apmcOptionsForsameCommodity2.filter(
                              (x) => x.label !== mandi1
                            )}
                            handleChange={this.handleInput}
                          />
                        </td>
                        <td className={`table-content py-3`}>Difference</td>
                      </tr>
                      {oneCommodityDiffMandi.map((list, index) =>
                        index + 1 !== oneCommodityDiffMandi.length ? (
                          <tr>
                            <td className="table-side-heading">{list.head}</td>
                            {index === oneCommodityDiffMandi.length - 2 ? (
                              <>
                                <td className={`table-content`}>
                                  <i
                                    className={`icon-${list.column1}-color fs-16`}
                                  ></i>
                                </td>
                                <td className={`table-content`}>
                                  <i
                                    className={`icon-${list.column2}-color fs-16`}
                                  ></i>
                                </td>
                                <td className={`table-content`}>
                                  <i
                                    className={`icon-${list.column3}-color fs-16`}
                                  ></i>
                                </td>
                              </>
                            ) : (
                              <>
                                <td className="table-content">
                                  {list.column1 !== "-" ? (
                                    <i className="icon-rupee fs-14 pr-1"></i>
                                  ) : (
                                    ""
                                  )}
                                  {list.column1}
                                  {list.head === "Latest Price" &&
                                    list.column1 !== "-" && (
                                      <span className="fs-11 ml-1">
                                        (
                                        {moment(list.date1).format(
                                          "DD-MM-YYYY"
                                        )}
                                        )
                                      </span>
                                    )}
                                </td>
                                <td className="table-content">
                                  {list.column2 !== "-" ? (
                                    <i className="icon-rupee fs-14 pr-1"></i>
                                  ) : (
                                    ""
                                  )}
                                  {list.column2}
                                  {list.head === "Latest Price" &&
                                    list.column2 !== "-" && (
                                      <span className="fs-11 ml-1">
                                        (
                                        {moment(list.date2).format(
                                          "DD-MM-YYYY"
                                        )}
                                        )
                                      </span>
                                    )}
                                </td>
                                <td className="table-content">
                                  {list.column3 !== "-" ? (
                                    <i className="icon-rupee fs-14 pr-1"></i>
                                  ) : (
                                    ""
                                  )}
                                  {list.column3}
                                </td>
                              </>
                            )}
                          </tr>
                        ) : (
                          <tr>
                            <td className="table-side-heading">{list.head}</td>
                            <td
                              colSpan="3"
                              className={`${
                                index + 1 === tableData.length
                                  ? "table-head"
                                  : "table-content"
                              }`}
                            >
                              {list.column1 !== "-" ? (
                                <i className="icon-rupee fs-14 pr-1"></i>
                              ) : (
                                ""
                              )}
                              {list.column1}
                            </td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      commodityList,
      mandiList,
      allState,
      oneMandiDiffCommodity,
      oneCommodityDiffMandi,
      getStateList,
      commodityByMandiList,
      mandiByPriceList,
      getStateByPrice,
      getMandiByPriceAndCommodity,
      getCommodityVariety,
    },
    dispatch
  );
};

export const Pricing = connect(null, mapDispatchToProps)(PricingClass);
