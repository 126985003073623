import React, { Component } from "react";
import Moment from "react-moment";
import { getDescriptionData } from "service/helperFunctions";
import { history } from "service/helpers";

import defaultAwardsImage from "assets/images/DefaultAward.jpg";
export default class Card extends Component {
  state = {
    active: false,
  };
  handleDropdown = () => {
    if (!this.state.active) {
      document.addEventListener("click", this.handleOutsideClick, false);
    } else {
      document.removeEventListener("click", this.handleOutsideClick, false);
    }
    this.setState((prevState) => ({
      active: !prevState.active,
    }));
  };

  handleOutsideClick = (e) => {
    if (this.node != null) {
      if (this.node.contains(e.target)) {
        return;
      }
    }
    this.handleDropdown();
  };

  render() {
    let {
      award: {
        title,
        shortDescription,
        receivedDate,
        awardDocs = [],
        awardId,
        awardName,
      },
      getAwardId,
      getId,
      handleDelete,
      editAccess = false,
      viewAccess = false,
      deleteAccess = false,
    } = this.props;

    let { active } = this.state;
    return (
      <div className="award-card rounded">
        {awardDocs.length !== 0 ? (
          <img
            className="award-card-img rounded"
            src={awardDocs[0].documentUrl}
            alt={awardDocs[0].fileName}
          />
        ) : (
          <img
            className="award-card-img rounded"
            src={defaultAwardsImage}
            alt="awards"
          />
        )}
        <div className="award-card-body p-3 h-100 d-flex flex-column justify-content-end">
          <div className="award-card-body-title mb-2">
            {title !== null ? getDescriptionData(title, 30) : ""}
          </div>
          <div
            className="award-card-body-content mb-2"
            dangerouslySetInnerHTML={{
              __html:
                shortDescription !== null
                  ? getDescriptionData(shortDescription, 50)
                  : "",
            }}
          ></div>
          <div className="d-flex flex-column">
            <p className="fs-14 fw-500 line-height-20 mb-1 text-white">
              Received date
            </p>
            <Moment format="MMM DD YYYY" className="text-white mb-2">
              {receivedDate}
            </Moment>
          </div>
          <div className="award-card-body-date d-flex">
            <div className="d-flex flex-column">
              <p className="fs-14 fw-500 line-height-20 mb-1">Award name</p>
              <p className="fs-14 fw-500 line-height-20 mb-1">{awardName}</p>
            </div>
            {editAccess || viewAccess || deleteAccess ? (
              <div className="ml-auto mt-auto">
                <div>
                  <div className="position-relative">
                    <i
                      className="icon-horizontal-dots fs-20 cursor-pointer"
                      onClick={this.handleDropdown}
                    ></i>
                    <div
                      className={`${
                        active ? "view-more-option d-block" : "d-none"
                      }`}
                    >
                      <ul>
                        {getId && editAccess && (
                          <li onClick={getAwardId}>{"Edit"}</li>
                        )}
                        {viewAccess && (
                          <li
                            onClick={() =>
                              history.push(`/fpo/viewDetails/awards&${awardId}`)
                            }
                          >
                            {"View"}
                          </li>
                        )}
                        {getId && deleteAccess && (
                          <li onClick={() => handleDelete(awardId)}>
                            {"Delete"}
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}
