import React from "react";
import RMInput from "component/RM/Common/RMInput";
import RMSelect from "component/RM/Common/RMSelect";
import { liveStockOptions } from "../dataVariables";

const LiveStockDetails = (props) => {
  const {
    liveStockData,
    handleLiveStockChange,
    liveStockCurrentIndex,
    handleRemoveClick,
    showLiveStockError,
    handleAddClick,
  } = props;

  return (
    <div className="d-flex flex-column mb-3 crop-details">
      {liveStockData.map((item, index) => {
        return (
          <React.Fragment key={index}>
            <div className="d-flex justify-content-between align-items-end w-100 mt-3">
              <div className="row col-9">
                <div className="mr-3">
                  <RMSelect
                    label="Livestock"
                    name="liveStockName"
                    value={item?.liveStockName}
                    onChange={(e) => handleLiveStockChange(e, index)}
                    option={liveStockOptions}
                    isRequired={true}
                  />
                </div>
                <div className="mr-3">
                  <RMInput
                    label="Quantity"
                    name="qty"
                    value={item?.qty}
                    onChange={(e) => handleLiveStockChange(e, index)}
                    allowOnlyNumber={true}
                    isRequired={true}
                  />
                </div>
                {item?.liveStockName === "Others" && (
                  <div className="mr-3">
                    <RMInput
                      label="Other Livestock"
                      name="otherLiveStock"
                      value={item?.otherLiveStock}
                      onChange={(e) => handleLiveStockChange(e, index)}
                      isRequired={item?.liveStockName === "Others"}
                    />
                  </div>
                )}
              </div>
              <div className="col-1">
                {liveStockData.length !== 1 && (
                  <button
                    className="add-button red-bg"
                    onClick={() => {
                      handleRemoveClick(index, "liveStock");
                    }}
                  >
                    Delete
                  </button>
                )}
              </div>
            </div>
            <div className="mt-4">
              {showLiveStockError && liveStockCurrentIndex === index && (
                <p className="error-message text-danger fs-14 mb-0">
                  Please Fill all the fields
                </p>
              )}
              {liveStockData.length - 1 === index && (
                <button
                  className="add-button"
                  onClick={() => {
                    handleAddClick(index, "liveStock");
                  }}
                >
                  Add more Live Stocks
                </button>
              )}
            </div>
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default LiveStockDetails;
