export const optionsForDiffCommodityData = {
  chart: {
    type: "spline",
    height: 280,
  },
  title: {
    text: "",
  },
  legend: {
    enabled: false,
  },
  credits: {
    enabled: false,
  },
  xAxis: {
    categories: [],
    labels: {
      style: {
        width: "50px",
        textOverflow: "ellipsis",
        color: "#D0962B",
      },
    },
  },
  yAxis: {
    categories: [],
    labels: {
      format: "र {value}",
      style: {
        width: "75px",
        color: "#D0962B",
      },
    },
    title: {
      enabled: false,
    },
  },
  series: [
    {
      name: "Mandi 1",
      color: "#EC904C",
      data: [],
    },
    {
      name: "Average price of Mandi 1",
      color: "#EC904C",
      type: "line",
      data: [],
      dashStyle: "Dash",
    },
    {
      name: "Mandi 2",
      color: "#189C5C",
      data: [],
    },
    {
      name: "Average price of Mandi 2",
      color: "#189C5C",
      type: "line",
      data: [],
      dashStyle: "Dash",
    },
  ],
};

export const optionsData = {
  chart: {
    type: "spline",
    height: 280,
  },
  title: {
    text: "",
  },
  legend: {
    enabled: false,
  },
  credits: {
    enabled: false,
  },
  xAxis: {
    categories: [],
    labels: {
      style: {
        width: "50px",
        textOverflow: "ellipsis",
        color: "#D0962B",
      },
    },
  },
  yAxis: {
    categories: [],
    labels: {
      format: "र {value}",
      style: {
        width: "75px",
        color: "#D0962B",
      },
    },
    title: {
      enabled: false,
    },
  },
  series: [
    {
      name: "commodity",
      color: "#EC904C",
      data: [],
    },
    {
      name: "Average price",
      color: "#189C5C",
      type: "line",
      data: [],
      dashStyle: "Dash",
    },
  ],
};

export const tableContentData = [
  { head: "Unit", column1: "Per Qtl", column2: "Per Qtl", column3: "Per Qtl" },
  {
    head: "Latest Price",
    date1: "",
    date2: "",
    date3: "",
    column1: "-",
    column2: "-",
    column3: "-",
  },
  { head: "Average Price", column1: "-", column2: "-", column3: "-" },
  { head: "Minimum Price", column1: "-", column2: "-", column3: "-" },
  { head: "Maximum Price", column1: "-", column2: "-", column3: "-" },
  { head: "Price Trend", column1: "-", column2: "-", column3: "-" },
  { head: "MSP", column1: "-", column2: "-", column3: "-" },
];

export const oneCommodityDiffMandiData = [
  {
    head: "Latest Price",
    column1: "-",
    date1: "",
    date2: "",
    column2: "-",
    column3: "-",
  },
  { head: "Average Price", column1: "-", column2: "-", column3: "-" },
  { head: "Minimum Price", column1: "-", column2: "-", column3: "-" },
  { head: "Maximum Price", column1: "-", column2: "-", column3: "-" },
  { head: "Price Trend", column1: "-", column2: "-" },
  { head: "MSP", column1: "-" },
];
