import React, { Component } from "react";
import placeholder from "../../../assets/images/placeholder.png";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import "./OnGoingCourses.scss";
import { history } from "service/helpers";
import play from "../../../assets/images/academy/play_white.svg";
import { getDescriptionData } from "service/helperFunctions";
import favorite from "../../../assets/images/academy/heart_white.svg";

export class OnGoingCourses extends Component {
  constructor() {
    super();
    SwiperCore.use([Navigation, Pagination]);
  }

  redirectCourse = (data) => {
    if (this.props.orititle === "favorites") {
      history.push(
        `/academy/courses/${data.courses.courseId}?favorites=${data.favouriteId}`
      );
    } else {
      history.push(`/academy/courses/${data.courses.courseId}`);
    }
  };

  render() {
    const params = {
      slidesPerView: 1,
      spaceBetween: 30,
      navigation: true,
      breakpoints: {
        500: {
          slidesPerView: 2,
        },
        768: {
          slidesPerView: 4,
        },
      },
    };
    let {
      OnGoingCoursesData,
      title = "Ongoing courses",
      favorites,
    } = this.props;
    return (
      <>
        <section className="on-going-courses-section">
          <div className="container-sa">
            <h4 className="title">{title} </h4>
            <div className="on-going-courses-list slider-top-right">
              {OnGoingCoursesData.length > 0 ? (
                <Swiper {...params}>
                  {OnGoingCoursesData.map((data, index) => (
                    <SwiperSlide key={index + "on-going"}>
                      <div
                        key={index + "on-going"}
                        className=""
                        onClick={() => this.redirectCourse(data)}
                      >
                        <div className="on-going-courses-card shadow">
                          <img
                            className="on-going-courses-card-img"
                            src={data.courses.thumbnailFileUrl}
                          />
                          <div>
                            <div className="on-going-courses-card-content h-100 w-100">
                              <h5 className="d-flex align-items-start mt-3 fs-18">
                                {getDescriptionData(data.courses.title, 28)}
                                {favorites && (
                                  <img
                                    className="heart"
                                    src={favorite}
                                    alt="heart"
                                  />
                                )}
                              </h5>
                              <div className="d-flex justify-content-between align-items-end h-50 mt-4">
                                <img
                                  className="play-course"
                                  src={play}
                                  alt="play"
                                  srcSet=""
                                />
                                <div className="">
                                  {`${
                                    parseInt(data.courses.durationHours) != 0
                                      ? `${parseInt(
                                          data.courses.durationHours
                                        )}:`
                                      : ""
                                  } ${
                                    parseInt(data.courses.durationMinutes) != 0
                                      ? `${parseInt(
                                          data.courses.durationMinutes
                                        )}:`
                                      : null
                                  } ${data.courses.durationSeconds}`}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="on-going-progress">
                            <div className="progress-inner"></div>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              ) : (
                <p className="py-5 my-3 text-black-50 fs-22 line-height-25 text-center">
                  No data found !!!
                </p>
              )}
            </div>
          </div>
        </section>
      </>
    );
  }
}
