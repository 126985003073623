import { contents } from 'service/apiVariables';
import { Toast } from 'service/toast';
import { api } from 'service/api';
import { addQuery } from 'service/helperFunctions';

export const getNewsAndEvents = () => (dispatch, getState) => {
   return new Promise((resolve, reject) => {
      api({ ...contents.getNewsAndEvents })
         .then(({ data }) => {
            resolve(data);
         })
         .catch(({ message }) => {
            reject(Toast({ type: 'error', message }));
         });
   });
};

export const getAllEvents = (query) => (dispatch, getState) => {
   return new Promise((resolve, reject) => {
      addQuery(query, contents.getAllEvents);
      api({ ...contents.getAllEvents })
         .then(({ data }) => {
            resolve(data);
         })
         .catch(({ message }) => {
            reject(Toast({ type: 'error', message }));
         });
   });
};

export const getAllNews = (query) => (dispatch, getState) => {
   return new Promise((resolve, reject) => {
      addQuery(query, contents.getAllNews);
      api({ ...contents.getAllNews })
         .then(({ data }) => {
            resolve(data);
         })
         .catch(({ message }) => {
            reject(Toast({ type: 'error', message }));
         });
   });
};

export const getAllReports = (query) => (dispatch, getState) => {
   return new Promise((resolve, reject) => {
      addQuery(query, contents.getAllReports);
      api({ ...contents.getAllReports })
         .then(({ data }) => {
            resolve(data);
         })
         .catch(({ message }) => {
            reject(Toast({ type: 'error', message }));
         });
   });
};

export const getAllReportsById = (id) => (dispatch, getState) => {
   return new Promise((resolve, reject) => {
      contents.getAllReportsById.id = id;
      api({ ...contents.getAllReportsById })
         .then(({ data }) => {
            resolve(data);
         })
         .catch(({ message }) => {
            reject(Toast({ type: 'error', message }));
         });
   });
};