import React, { Component } from 'react';
import { history } from 'service/helpers';
import moment from 'moment';
import long_arrow from 'assets/svg/price_details/long_arrow.svg';
import { currencyConvert } from 'service/helperFunctions';
import './gstreturnlist.scss';
const User_Profile_1 = require('assets/svg/Members/User_Profile_1.svg');

export class Card extends Component {
   render() {
      let { payload, index, editAccess } = this.props;
      const {
         id,
         fymYear,
         returnPeriodYear,
         gsstNumber,
         dueDate,
         dateOfFiling,
         salesIncome,
         consultantName,
         fillingStatusFileUrl,
         remarks,
      } = payload;
      return (
         <div key={index}
            className={`col-md-3 col-lg-3 col-6 ${index % 4 === 3 ? 'pr-md-0' : ''} mb-2 mt-3 px-2 ${index % 4 === 0 ? 'pl-md-0' : ''}`}
         >
            <div className='income-card rounded shadow cursor-pointer h-100'>
               <div onClick={() => { editAccess && history.push(`/fpo/edit-gstreturn/${id}`); }} >
                  <div className='d-flex justify-content-between mb-4'>
                     <div className='d-flex align-items-center h-100'>
                        <div className='mr-2 pr-1 position-relative profile-container'>
                           <img
                              src={User_Profile_1}
                              alt='member_profile'
                              className='position-absolute'
                           />
                           <div className='position-absolute text-white fs-20 font-weight-bold'>
                              {consultantName ? consultantName.charAt(0) : '-'}
                           </div>
                        </div>
                        <div>
                           <div className='income-card-member-name mb-1'>
                              {consultantName ? consultantName : '-'}
                           </div>
                           <div className='income-card-subtitle'>{fymYear}</div>
                        </div>
                     </div>
                  </div>
                  <div className='row mb-4'>
                     <div className='col-sm-12 '>
                        <div className='line-height-21 font-Gilroy text-black-50 fs-16 mb-3'>
                           {'GST number'}
                        </div>
                        <div className='line-height-21 font-Gilroy-Bold fs-18 text-black-1c font-weight-bold'>
                           {gsstNumber ? gsstNumber : '-'}
                        </div>
                     </div>
                  </div>
                  <div className='row mb-4'>
                     <div className='col-sm-6 '>
                        <div className='line-height-21 font-Gilroy text-black-50 fs-16 mb-3'>
                           {'Due date'}
                        </div>
                        <div className='line-height-21 font-Gilroy-Bold fs-18 text-black-1c font-weight-bold'>
                           {dueDate ? moment(dueDate).format('DD MMMM YYYY') : '-'}
                        </div>
                     </div>
                     <div className='col-sm-6 pr-0'>
                        <div className='line-height-21 font-Gilroy text-black-50 fs-16 mb-3'>
                           {'Date of filing	'}
                        </div>
                        <div className='line-height-21 font-Gilroy-Bold fs-18 text-black-1c font-weight-bold'>
                           {dateOfFiling ? moment(dateOfFiling).format('DD MMMM YYYY') : '-'}
                        </div>
                     </div>
                  </div>
                  <div className='row mb-4'>
                     <div className='col-sm-6'>
                        <div className='line-height-21 font-Gilroy text-black-50 fs-16 mb-3'>
                           {'Return period'}
                        </div>
                        <div className='line-height-21 font-weight-bold font-Gilroy-Bold fs-18 text-black-1c'>
                           {returnPeriodYear ? returnPeriodYear : ''}
                        </div>
                     </div>
                     <div className='col-sm-6 pr-0'>
                        <div className='line-height-21 font-Gilroy text-black-50 fs-16 mb-3'>
                           <div>{'Sales or Income'} </div> {' ( '}
                           <span className='icon-rupee'></span> {' )'}
                        </div>
                        <div className='line-height-21 font-weight-bold font-Gilroy-Bold fs-18 text-black-1c'>
                           {salesIncome ? currencyConvert(salesIncome) : '-'}
                        </div>
                     </div>
                  </div>
                  <div className='d-flex mb-4 height-70'>
                     <div>
                        <div className='line-height-21 font-Gilroy text-black-50 fs-16 mb-3'>
                           {'Remarks'}
                        </div>
                        <div className='line-height-21 font-weight-bold font-Gilroy-Bold fs-18 text-black-1c'>
                           {remarks ? remarks.length > 50 ? `${remarks.substring(0, 50)}...` : remarks : '-'}
                        </div>
                     </div>
                  </div>
               </div>
               {fillingStatusFileUrl ?
                  <div className='d-flex mb-3' onClick={() => window.open(fillingStatusFileUrl, '_blank')} >
                     <div className='text-green font-weight-bold mr-2 fs-16 gilroy'>
                        {'View Document'}
                     </div>
                     <div>
                        <img src={long_arrow} alt='arrow.svg' />
                     </div>
                  </div>
                  : null}
            </div>
         </div>
      );
   }
}
