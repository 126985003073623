import React, { useState } from "react";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import { mainTabHeading } from "./dataVariables";
import NewRecord from "./NewRecord";
import PastRecord from "./PastRecord";
import "./style.scss";

const HarvestForm = () => {
  const [activeTab, setActiveTab] = useState("New record");

  return (
    <div className="container-sa my-3">
      <div className="harvest-details">
        <Nav tabs className="tab-sammunaty w-100 d-flex justify-content-center">
          {mainTabHeading?.map((item, index) => {
            return (
              <NavItem key={index}>
                <NavLink
                  className={`${classnames({
                    active: activeTab === item?.title,
                  })} px-2`}
                  onClick={() => {
                    setActiveTab(item?.title);
                  }}
                >
                  {item?.title}
                </NavLink>
              </NavItem>
            );
          })}
        </Nav>
        <TabContent activeTab={activeTab}>
          {mainTabHeading?.map((item, index) => {
            return (
              <TabPane key={index} tabId={item?.title}>
                {item?.title === "New record" ? (
                  <NewRecord setActiveTab={setActiveTab} />
                ) : (
                  <PastRecord mainTab={activeTab} />
                )}
              </TabPane>
            );
          })}
        </TabContent>
      </div>
    </div>
  );
};

export default HarvestForm;
