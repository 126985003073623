import React, { Component } from "react";
import { Collapse } from "reactstrap";
import moment from "moment";
import arrow_up from "assets/svg/weather/arrow_up.svg";
export class ToggleSlider extends Component {
  state = {
    collapseType: false,
  };

  toggleCollapse = () => {
    //Local Weather open or close action
    let collapseType = !this.state.collapseType;
    this.setState({ collapseType });
  };
  render() {
    let { dailyData = {} } = this.props;

    let {
      datetime = "",
      day_cloudcover = "",
      day_iconcode = "",
      day_precipchance = "",
      day_relativehumidity = "",
      day_temperature = "",
      day_windspeed = "",
      day_wxphraselong = "",
      night_cloudcover = "",
      night_iconcode = "",
      night_precipchance = "",
      night_relativehumidity = "",
      night_temperature = "",
      night_windspeed = "",
      night_wxphraselong = "",
    } = dailyData;
    let { collapseType } = this.state;

    return (
      <div className="slider-container w-100">
        <div
          className="d-flex flex-column toggle-before-area h-100"
          data-toggle={collapseType}
          onClick={() => this.toggleCollapse(`collapseType`)}
        >
          <div className="row h-100">
            <div className="col-2 d-flex align-items-center">
              <p className="fs-20 line-height-23 fw-600 text-green-18 mb-0">
                {moment(datetime).format("ddd D MMM")}
              </p>
            </div>
            <div
              className={`col-4 d-flex align-items-center ${
                collapseType ? "margin-right-cc" : ""
              }`}
            >
              <div className="d-flex align-items-center w-100">
                {day_iconcode ? (
                  <div className="mr-3">
                    <img
                      height="60"
                      width="60"
                      src={require(`assets/svg/WeatherSvgs/${
                        day_iconcode <= 9 ? `0${day_iconcode}` : day_iconcode
                      }.svg`)}
                      alt="cloud.svg"
                    />
                  </div>
                ) : null}
                <div className="w-100">
                  <p className="fs-24 font-weight-bold line-height-28 text-green-18 mb-2">
                    {day_temperature}&deg;C
                  </p>
                  <div className="row mb-2">
                    <div className="col-2">
                      <i className="icon-wind-navigate fs-18"></i>
                    </div>
                    <div className="col-10">
                      <p className="fs-16 line-height-19 text-black-4f fw-500 mb-0">
                        {day_windspeed}km/hr
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-4 pl-4">
              <div className="d-flex align-items-center w-100">
                {night_iconcode ? (
                  <div className="mr-3">
                    <img
                      height="60"
                      width="60"
                      src={require(`assets/svg/WeatherSvgs/${
                        night_iconcode <= 9
                          ? `0${night_iconcode}`
                          : night_iconcode
                      }.svg`)}
                      alt="cloud.svg"
                    />
                  </div>
                ) : null}
                <div className="w-100">
                  <p className="fs-24 font-weight-bold line-height-28 text-green-18 mb-2">
                    {night_temperature}&deg;C
                  </p>
                  <div className="row mb-2">
                    <div className="col-2">
                      <i className="icon-wind-navigate fs-18"></i>
                    </div>
                    <div className="col-10">
                      <p className="fs-16 line-height-19 text-black-4f fw-500 mb-0">
                        {night_windspeed}km/hr
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-2 d-flex justify-content-center px-0">
              <img
                src={arrow_up}
                alt="arrow.svg"
                className={
                  collapseType ? "rotate-0 arrow-size" : "rotate-180 arrow-size"
                }
              />
            </div>
          </div>
        </div>
        <Collapse isOpen={collapseType}>
          <div className="row h-100">
            <div className="col-8 mx-auto px-2">
              <div className="row">
                <div
                  className={`col-6 ${
                    collapseType ? "margin-right-cc-bottom" : ""
                  } h-100`}
                >
                  <div className="row">
                    <div className="col-3 px-2">
                      <p className="mb-0 text-black-4f fs-16 fw-600 line-hieght-21">
                        Day
                      </p>
                      <p className="mb-0 text-black-4f fs-14 fw-400 line-hieght-16">
                        {day_wxphraselong}
                      </p>
                    </div>
                    <div className="col-9">
                      <div className="row mb-2">
                        <div className="col-2 px-2 text-center">
                          <i className="icon-carbon-wind fs-22"></i>
                        </div>
                        <div className="col-10 px-2">
                          <p className="fs-16 line-height-19 text-black-4f fw-500 mb-0">
                            Precipitation {day_precipchance}%
                          </p>
                        </div>
                      </div>
                      <div className="row mb-2">
                        <div className="col-2 px-2 text-center">
                          <i className="icon-humidity fs-20"></i>
                        </div>
                        <div className="col-10 px-2">
                          <p className="fs-16 line-height-19 text-black-4f fw-500 mb-0">
                            Humidity {day_relativehumidity}%
                          </p>
                        </div>
                      </div>
                      <div className="row mb-2">
                        <div className="col-2 px-2 text-center">
                          <i className="icon-wind fs-16"></i>
                        </div>
                        <div className="col-10 px-2">
                          <p className="fs-16 line-height-19 text-black-4f fw-500 mb-0">
                            Clouds cover {day_cloudcover}%
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-6 py-1">
                  <div className="row ml-1">
                    <div className="col-3 pl-0">
                      <p className="mb-0 text-black-4f fs-16 fw-600 line-hieght-21">
                        Night
                      </p>
                      <p className="mb-0 text-black-4f fs-14 fw-400 line-hieght-16">
                        {night_wxphraselong}
                      </p>
                    </div>
                    <div className="col-9 pl-0">
                      <div className="row mb-2">
                        <div className="col-2 px-2 text-center">
                          <i className="icon-carbon-wind fs-22"></i>
                        </div>
                        <div className="col-10 px-2">
                          <p className="fs-16 line-height-19 text-black-4f fw-500 mb-0">
                            Precipitation {night_precipchance}%
                          </p>
                        </div>
                      </div>
                      <div className="row mb-2">
                        <div className="col-2 px-2 text-center">
                          <i className="icon-humidity fs-20"></i>
                        </div>
                        <div className="col-10 px-2">
                          <p className="fs-16 line-height-19 text-black-4f fw-500 mb-0">
                            Humidity {night_relativehumidity}%
                          </p>
                        </div>
                      </div>
                      <div className="row mb-2">
                        <div className="col-2 px-2 text-center">
                          <i className="icon-wind fs-16"></i>
                        </div>
                        <div className="col-10 px-2">
                          <p className="fs-16 line-height-19 text-black-4f fw-500 mb-0">
                            Clouds cover {night_cloudcover}%
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Collapse>
      </div>
    );
  }
}
