import React, { Component } from "react";
import backIcon from "../../assets/images/icon/arrow-left.svg";
import { Banner, Quiz } from "../../component/Acadamy";
import {
  quizListApi,
  courseListApi,
  quizAnswerAPI,
  retakeQuiz,
} from "action/Acadamy";
import { CommonPageLoader } from "component/common";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { history } from "service/helpers";
export class QuizPageClass extends Component {
  constructor() {
    super();
    this.state = {
      quizesQuestion: [],
      loading: false,
      quizes: "",
      currentQuestionId: "",
    };
  }

  //life cycels
  componentDidMount() {
    let {
      match: { params },
      retakeQuiz,
      location: { pathname },
    } = this.props;
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    if (params.id !== undefined) {
      if (pathname.includes("retakeQuiz")) {
        retakeQuiz(params.id)
          .then(() => {
            this._getQuizList(params.id);
          })
          .catch(() => {
            this.setState({
              loading: true,
            });
          });
      } else {
        this._getQuizList(params.id);
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      let {
        match: { params },
        retakeQuiz,
        location: { pathname },
      } = this.props;
      if (params.id !== undefined) {
        if (pathname.includes("retakeQuiz")) {
          retakeQuiz(params.id)
            .then(() => {
              this._getQuizList(params.id);
            })
            .catch(() => {
              this.setState({
                loading: true,
              });
            });
        } else {
          this._getQuizList(params.id);
        }
      }
    }
  }

  //get quiz list
  _getQuizList = (id) => {
    let { quizListApi } = this.props;
    this.setState({ loading: false });
    quizListApi(id)
      .then((data) => {
        if (data) {
          let { questions = [] } = data;
          this.setState({
            quizesQuestion: questions,
            quizes: data,
            loading: true,
          });
          if (data.answerSelected) {
            this.setState({
              currentQuestionId: data.answerSelected.questionId,
            });
          } else {
            if (data.questions.length > 0) {
              this.setState({
                currentQuestionId: data.questions[0].questionId,
              });
            }
          }
        }
      })
      .catch(() => {
        this.setState({
          loading: true,
        });
      });
  };

  updateQuestionId = (quisId) => {
    this.setState({ currentQuestionId: quisId });
  };

  questionUpdateOption = (questionIndex, questionId, optionId) => {
    let quizesQuestion = this.state.quizesQuestion;
    quizesQuestion[questionIndex]["isAnswered"] = true;
    quizesQuestion[questionIndex]["answerSelected"] = {
      optionId: optionId,
      questionId: questionId,
    };
    this.setState({ quizesQuestion });
  };

  render() {
    let { loading, quizesQuestion, quizes, currentQuestionId } = this.state;
    return (
      <>
        <div className="min-vh-100">
          <Banner minSize={true} />
          <div className="d-flex container-sa my-3 ">
            <div
              onClick={() => history.goBack()}
              className="d-flex cursor-pointer"
            >
              <img className="cursor-pointer" src={backIcon} alt="back" />
              <p className="ml-3 add-member-text-back mb-0">{"Quiz"}</p>
            </div>
          </div>
          {loading ? (
            <Quiz
              history={this.props.history}
              quizes={quizes}
              quizAnswerAPI={this.props.quizAnswerAPI}
              quizesQuestion={quizesQuestion}
              quizes={quizes}
              currentQuestionId={currentQuestionId}
              updateQuestionId={this.updateQuestionId}
              questionUpdateOption={this.questionUpdateOption}
              _getQuizList={this._getQuizList}
            />
          ) : (
            <CommonPageLoader />
          )}
        </div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      quizListApi,
      courseListApi,
      quizAnswerAPI,
      retakeQuiz,
    },
    dispatch
  );
};

let component = QuizPageClass;

export const QuizPage = connect(null, mapDispatchToProps)(component);
