import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import "./index.scss";
import MenuCard from "./menuCard";
import Blog from "./blog";
import { Accordain } from "./Accordian";
// import { history } from "service/helpers";

export class WhatsNewPre extends Component {
  state = {
    blog: [],
    accordian: [
      {
        title: "Explore Loans",
        content: "for FPO Loans/Farmer finance",
      },
      {
        title: "Input & Output Platform",
        content: "requests to Buy Inputs and Sell Produce",
      },
      {
        title: "Manage My Books",
        content: "book keeping software specifically designed for FPOs",
      },
    ],
  };

  //life cycles
  // componentDidUpdate(prevProps) {
  //   if (prevProps.blog !== this.props.blog) {
  //     this.setState({
  //       blog: this.props.blog,
  //     });
  //   }
  // }

  render() {
    const { blog, accordian } = this.state;
    return (
      <div className="whats-new py-3">
        <div className="container-sa">
          <Row>
            <Col sm="4">
              {accordian.map((list, index) => {
                return <Accordain key={index} index={index} list={list} />;
              })}
              <Blog blog={blog} />
              {/* <div className="menu">
                <div className={`menu-item`}>
                  <div id="toggler">AgFinMart</div>
                </div>

                <div className={`menu-item`}>
                  <div>Input & Output platform</div>
                </div>
                <div className={`menu-item`}>
                  <div>Services marketplace</div>
                </div>
                <div className={`menu-item`}>
                  <div>Business Management System(BMS)</div>
                </div>
              </div> */}
            </Col>
            <Col sm="8">
              <MenuCard />
            </Col>
            {/* <Col sm="3"></Col> */}
          </Row>
        </div>
      </div>
    );
  }
}
