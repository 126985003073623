export const authType = {
  getUserLoginDetails: "GET_USER_LOGIN_DETAILS",
};

export const HomeActionType = {
  currentTab: "CURRENT_TAB",
  ForumId: "FORUM_ID",
  accessData: "ACCESS_DATA",
  profileImage: "PROFILE_IMAGE",
};

export const FIGCRPType = {
  crpMembers: "CRP_MEMBERS",
  figMembers: "FIG_MEMBERS",
};

export const keyFinancialType = {
  keyFinancialCurrentTab: "KEY_FINANCIAL_CURRENT_TAB",
};

export const documentType = {
  documentCurrentTab: "DOCUMENT_CURRENT_TAB",
};

export const ComplianceType = {
  complianceCurrentTab: "COMPLIANCE_CURRENT_TAB",
};

export const academyType = {
  academySearch: "ACADEMY_SEARCH",
  academyPage: "ACADEMY_PAGE",
  categoryList: "CATEGORY_LIST",
  notificationCount: "NOTIFICATION_COUNT",
};

export const rmCropType = {
  categoryOptions: "CATEGORY_OPTIONS",
  cropOptions: "CROP_OPTIONS",
};
