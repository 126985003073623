import React, { Component } from "react";
import { getDescriptionData } from "service/helperFunctions";
import { history } from "service/helpers";

export default class Blog extends Component {
  state = {
    blog: [],
    readMore: "",
    data: [
      "Send Bulk SMS to your members in 8 different local languages.",
      "Send pre-recorded audio via robocall directly to all your members.",
      "Get calendar alerts for renewing compliance related documents on your home screen.",
      "Create CRP and FIG structures for your organization for better member engagement.",
      "Generate Grading Report to assess FPO performance across various parameters.",
    ],
  };
  // toggleReadMore = (index) => {
  //   this.setState({
  //     readMore: this.state.readMore === index ? "" : index,
  //   });
  // };
  // componentDidUpdate(prevProps) {
  //   if (prevProps.blog !== this.props.blog) {
  //     this.setState({
  //       blog: this.props.blog,
  //     });
  //   }
  // }
  render() {
    const { blog, readMore, data } = this.state;
    return (
      <div className="blog-card">
        <div className="title">
          <h5>What’s New</h5>
        </div>
        <hr />
        {/* {blog.length > 0 ? (
          blog.map((list, index) => {
            return (
              <div key={index}>
                <div
                  className="content"
                  dangerouslySetInnerHTML={{
                    __html:
                      list.description.length > 65
                        ? readMore === index
                          ? list.description
                          : getDescriptionData(list.description, 64)
                        : list.description,
                  }}
                ></div>
                {list.description.length > 65 && (
                  <div className="readmore">
                    <p onClick={() => this.toggleReadMore(index)}>
                      read {readMore === index ? "less" : "more"}
                    </p>
                  </div>
                )}
                {index + 1 != blog.length && <hr />}
              </div>
            );
          })
        ) : (
          <p className="py-5 my-3 fw-500 text-black-50 fs-20 line-height-25 text-center">
            No data found !!!
          </p>
        )} */}
        <ul className="new-feature-list">
          {data.map((list, index) => {
            return <li key={index}>{list}</li>;
          })}
          <li>
            Install the FPO Gateway Android application{" "}
            <a
              className="text-decoration-none cursor-pointer text-green-18 fw-500"
              href="https://play.google.com/store/apps/details?id=com.samunnati.app.fpogateway"
              target={"_blank"}
            >
              Click here to download
            </a>
          </li>
        </ul>
      </div>
    );
  }
}
