import React, { Component } from "react";
import { Col, Row } from "reactstrap";
const fpo = require("assets/svg/preWhatsNew/fpo.svg");
const events = require("assets/svg/preWhatsNew/events.svg");
const member = require("assets/svg/preWhatsNew/member.svg");
const grading = require("assets/svg/preWhatsNew/grading.svg");
const compliance = require("assets/svg/preWhatsNew/compliance.svg");
const frame = require("assets/svg/preWhatsNew/frame.svg");
export default class MenuCard extends Component {
  render() {
    return (
      <>
        <div className="service-card">
          <Row>
            <Col sm="4">
              <div className="service-items">
                <div className="service-head">
                  <div className="service-img">
                    <img className="img" src={member} alt="member" />
                  </div>
                  <div className="service-title">Member Engagement</div>
                </div>
                <div className="service-content">
                  Communicate promptly with all your Member Farmers, Board of
                  Directors (BOD), and Staff using automated Robocalls and Bulk
                  SMS.
                </div>
              </div>
            </Col>
            <Col sm="4">
              <div className="service-items">
                <div className="service-head">
                  <div className="service-img">
                    <img className="img" src={fpo} alt="fpo" />
                  </div>

                  <div className="service-title">FPO Documents</div>
                </div>
                <div className="service-content">
                  Store and manage all the documents related to your FPO in one
                  place.
                </div>
              </div>
            </Col>
            <Col sm="4">
              <div className="service-items">
                <div className="service-head">
                  <div className="service-img">
                    <img className="img" src={grading} alt="grading" />
                  </div>

                  <div className="service-title">Grading Score</div>
                </div>
                <div className="service-content">
                  Know your FPO's Grading score calculated across eight
                  different parameters.
                </div>
              </div>
            </Col>
            <Col sm="4">
              <div className="service-items">
                <div className="service-head">
                  <div className="service-img">
                    <img className="img" src={compliance} alt="compliance" />
                  </div>
                  <div className="service-title">Compliance & Governance</div>
                </div>
                <div className="service-content">
                  Store and manage all compliance related documents and get
                  calendar alerts on your home screen.
                </div>
              </div>
            </Col>
            <Col sm="4">
              <div className="service-items">
                <div className="service-head">
                  <div className="service-img">
                    <img className="img" src={events} alt="events" />
                  </div>
                  <div className="service-title">Events & Impact Stories</div>
                </div>
                <div className="service-content">
                  Share your FPO's awards & achievements, events organized by
                  you and also read about other FPOs around you.
                </div>
              </div>
            </Col>
            <Col sm="4">
              <div className="service-items">
                <div className="service-head">
                  <div className="service-img">
                    <img className="img" src={events} alt="key financial" />
                  </div>
                  <div className="service-title">Key Financials</div>{" "}
                </div>
                <div className="service-content">
                  Store and manage your FPO's audited financials, credit history
                  etc.
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}
