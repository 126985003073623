import React from "react";
import { history } from "service/helpers";
import { currencyConvert } from "service/helperFunctions";

function Card(props) {
  const { payload, index, editAccess } = props;
  const {
    FSId,
    turnOver,
    AuthorisedCapital,
    sanctionedCapital,
    profitAT,
    GrandDonationReceived,
    fymYear,
  } = payload;
  return (
    <div
      className={`col-md-3 col-lg-3 col-6 ${
        index % 4 === 3 ? "pr-md-0" : ""
      } mb-2 mt-3 px-2 ${index % 4 === 0 ? "pl-md-0" : ""}`}
      onClick={() => {
        editAccess && history.push(`/fpo/edit-financialinfo/${FSId}`);
      }}
    >
      <div className="financial-card rounded shadow cursor-pointer">
        <div className="row mb-4 justify-content-between height-60">
          <div className="col-sm-7 ">
            <div className="line-height-21 font-Gilroy text-black-50 fs-16 mb-3">
              {"Financial year"}
            </div>
            <div className="line-height-21 font-Gilroy-Bold fs-18 text-black-1c font-weight-bold">
              {fymYear}
            </div>
          </div>
          <div className="col-sm-5 pr-0">
            <div className="line-height-21 font-Gilroy text-black-50 fs-16 mb-3">
              {"Revenue ( "} <span className="icon-rupee"></span> {" )"}
            </div>
            <div className="line-height-21 font-Gilroy-Bold fs-18 text-black-1c font-weight-bold">
              {currencyConvert(turnOver)}
            </div>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-sm-12 ">
            <div className="line-height-21 font-Gilroy text-black-50 fs-16 mb-3">
              {"Authorised capital ( "} <span className="icon-rupee"></span>{" "}
              {" )"}
            </div>
            <div className="line-height-21 font-Gilroy-Bold fs-18 text-black-1c font-weight-bold">
              {AuthorisedCapital ? currencyConvert(AuthorisedCapital) : "-"}
            </div>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-sm-12 ">
            <div className="line-height-21 font-Gilroy text-black-50 fs-16 mb-3">
              {"Sanctioned capital ( "} <span className="icon-rupee"></span>{" "}
              {" )"}
            </div>
            <div className="line-height-21 font-Gilroy-Bold fs-18 text-black-1c font-weight-bold">
              {sanctionedCapital ? currencyConvert(sanctionedCapital) : "-"}
            </div>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-sm-12 ">
            <div className="line-height-21 font-Gilroy text-black-50 fs-16 mb-3">
              {"Profit after tax ( "} <span className="icon-rupee"></span>{" "}
              {" )"}
            </div>
            <div className="line-height-21 font-Gilroy-Bold fs-18 text-black-1c font-weight-bold">
              {profitAT ? currencyConvert(profitAT) : "-"}
            </div>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-sm-12 ">
            <div className="line-height-21 font-Gilroy text-black-50 fs-16 mb-3">
              {"Grant or Donation Received ( "}{" "}
              <span className="icon-rupee"></span> {" )"}
            </div>
            <div className="line-height-21 font-Gilroy-Bold fs-18 text-black-1c font-weight-bold">
              {GrandDonationReceived
                ? currencyConvert(GrandDonationReceived)
                : "-"}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Card;
