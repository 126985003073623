import React, { Component } from 'react';
import { Pagination, CommonPageLoader } from 'component/common';
import "./style.scss";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getNotificationList, readallNotification } from "action/forum"
import { getDescriptionData, letterAvatar } from "service/helperFunctions"

const moment = require('moment');

export class ForumNotificationsClass extends Component {

    state = {
        isLoading: false,
        notifications: [],
        pageMeta: {}
    }

    //Lifecycles
    componentDidMount() {
        this._getNotificationList(1)
    }

    //get notification list
    _getNotificationList = (page) => {
        let { getNotificationList } = this.props
        this.setState({ isLoading: true })
        getNotificationList({ page }).then(({ data }) => {
            this.setState({
                notifications: data.results,
                pageMeta: data.pageMeta,
                isLoading: false
            })
        })
    }

    //read all notification
    handleReadAll = () => {
        this.props.readallNotification().then(() => {
            this._getNotificationList(1)
        })
    }

    handlePagination = ({ page }) => {
        this._getNotificationList(page)
    }

    render() {

        let { notifications, isLoading, pageMeta } = this.state

        return (
            <div className="bg-blue-f9">
                <div className="container">
                    <div className="forum-notifications">
                        <div className="d-flex justify-content-between mb-4">
                            <div>
                                <p className="fs-18 text-black line-height-24 fw-700 mb-0">Notifications</p>
                            </div>
                            <div>
                                {notifications.length !== 0 &&
                                    <div className="d-flex justify-content-end">
                                        <h5 className="fs-18 text-black line-height-23 cursor-pointer fw-500 mb-0"
                                            onClick={() => this.handleReadAll()}
                                        >
                                            {"Mark all as read"}
                                        </h5>
                                    </div>
                                }
                            </div>
                        </div>
                        {isLoading ?
                            <>
                                {/* Loader */}
                                <CommonPageLoader />
                            </>
                            :
                            <>
                                {notifications.length > 0 ?
                                    <>
                                        {notifications.map((data, index) => (
                                            <div className={`${data.isReadAll ? 'alert-notify-seen' : 'alert-notify'}`} key={index}                                        >
                                                <div className="d-flex align-items-start">
                                                    <div>
                                                        <img className="mr-3 border-radius-50" src={letterAvatar(data.message, 43, true)} alt="" />
                                                    </div>
                                                    <div>
                                                        <div className="message">{getDescriptionData(data.message, 100)}</div>
                                                        <div className="d-flex">
                                                            <p className="time mb-0">
                                                                {`${moment(data.createdAt, "YYYY-MM-DD HH:mm:ss").format('Do MMM')}`}
                                                            </p>
                                                            <p className="time mb-0 ml-3">
                                                                {`${moment(data.createdAt, "YYYY-MM-DD HH:mm:ss").format('LT')}`}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                        <Pagination
                                            handleChange={this.handlePagination}
                                            pageMeta={pageMeta}
                                        />
                                    </>
                                    :
                                    <div className="empty-data">No results found !!!</div>
                                }
                            </>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getNotificationList,
            readallNotification
        },
        dispatch
    );
};

export const ForumNotifications = connect(null, mapDispatchToProps)(ForumNotificationsClass)