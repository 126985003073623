import React, { Component } from "react";
import { NormalInput, NormalButton } from "component/common";
import { Modal, ModalBody } from "reactstrap";
import SimpleReactValidator from "simple-react-validator";
import Select from "react-select";

// Redux Connection
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  addMemberToFIG,
  getFIGById,
  getFIGList,
  getFIGListRm,
  getMemberListforMapping,
} from "action/FigCrpAct";

export class MapFIGPopupClass extends Component {
  state = {
    name: "",
    isButtonClicked: false,
    leadName: "",
    memberOptions: [],
    memberIds: [],
  };

  //validation
  validator = new SimpleReactValidator({
    element: (message) => (
      <span className="error-message text-danger fs-14">{message}</span>
    ),
    autoForceUpdate: this,
  });

  componentDidUpdate(prevProps) {
    let { isOpen, id } = this.props;
    if (prevProps !== this.props && isOpen && id) {
      this.getFIGdataById();
      this._getMemberListforMapping(id);
    }
  }

  _getMemberListforMapping = (figid) => {
    let { getMemberListforMappingApi } = this.props;
    getMemberListforMappingApi(figid).then((data) => {
      if (data) {
        let options = [],
          tempArray = [],
          lastIndex = false;
        let results =
          data.length !== 0 &&
          data.sort((a, b) => (a.firstName > b.firstName && 1) || -1);

        results.map((list, index) => {
          let { lastName = "", firstName, memberId, isMapped } = list;
          if (firstName && firstName !== "#VALUE!") {
            options.push({
              label: `${firstName} ${lastName}`,
              value: memberId,
            });
            // if (isMapped) {
            //   tempArray.push({
            //     label: `${firstName} ${lastName}`,
            //     value: memberId,
            //   });
            // }
          }
          if (index + 1 === results.length) {
            lastIndex = true;
          }
        });
        if (lastIndex) {
          this.setState({
            memberOptions: [...options],
            // memberIds: [...tempArray],
          });
        }
      }
    });
  };

  //get FIG data By Id
  getFIGdataById = () => {
    let { id, getFIGByIdApi } = this.props;
    getFIGByIdApi(id).then((data) => {
      if (data) {
        this.setState({
          name: data.name,
          leadName: data.leadName,
          memberIds:
            data?.memberMappings.length > 0
              ? data.memberMappings.map(
                  ({
                    memberGeneralInfos: {
                      firstName,
                      lasttName = null,
                      memberId,
                    },
                  }) => {
                    return {
                      label: `${firstName} ${lasttName ? lasttName : ""}`,
                      value: memberId,
                    };
                  }
                )
              : [],
        });
      }
    });
  };

  //handle Change dropdown
  handleChange = (newValue) => {
    let value = [];
    if (!!newValue && newValue.length) {
      newValue.forEach((array) => {
        let obj = {
          value: array.value,
          label: array.label,
        };
        value.push(obj);
      });
    }
    this.setState({ memberIds: [...value] });
  };

  //close popup
  closepopup = () => {
    this._clearData();
    this.validator.hideMessages();
  };

  //clear Data
  _clearData = () => {
    this.setState({
      name: "",
      leadName: "",
      memberIds: [],
      memberOptions: [],
    });
    this.props.toggle();
  };

  //handle submit
  handleSubmit = () => {
    if (this.validator.allValid()) {
      let { memberIds } = this.state;
      let { id, addMemberToFIGApi } = this.props;
      let payload = { memberIds: [], figId: id };
      let tempArray = [];
      memberIds.map((list) => {
        tempArray.push(list.value);
      });
      if (tempArray.length === memberIds.length) {
        payload.memberIds = [...tempArray];
        addMemberToFIGApi(payload).then(() => {
          this.closepopup();
          this.handleListData();
        });
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  handleListData = () => {
    let { getFIGListApi, getFIGListRmApi, fpoId } = this.props;
    let payload = { limit: 10, page: 1, search: "" };
    if (fpoId) {
      getFIGListRmApi(fpoId, payload);
    } else {
      getFIGListApi(payload);
    }
  };

  render() {
    let {
      className = "modal-dialog-centered add-crp-fig",
      isOpen = false,
      id,
    } = this.props;

    let { isButtonClicked, name, leadName, memberOptions, memberIds } =
      this.state;

    const customStyles = {
      dropdownIndicator: (base) => ({
        ...base,
        color: "#000000",
      }),
    };

    return (
      <Modal isOpen={isOpen} className={className} size={"md"}>
        <ModalBody className="p-md-4">
          <div>
            <div className="modal-label mb-3">
              <h5>Members to FIG mapping</h5>
              <i
                onClick={() => this.closepopup()}
                className="icon-close cursor-pointer"
              />
            </div>
            <div className="row">
              <div className="col-12">
                <NormalInput label="FIG name" value={name} disabled />
              </div>
              <div className="col-12">
                <NormalInput label="FIG lead name" value={leadName} disabled />
              </div>
              <div className="col-12">
                <div className="mt-4 pt-2">
                  <div className="row d-flex align-items-center">
                    <div className="col-3 pr-0">
                      <div className="fs-16 text-black-32 font-weight-bold">
                        Members
                      </div>
                    </div>
                    <div className="col-9">
                      <Select
                        isSearchable={true}
                        name="memberIds"
                        options={memberOptions}
                        onChange={this.handleChange}
                        isMulti={true}
                        placeholder="Select"
                        styles={customStyles}
                        value={memberIds}
                      />
                      {this.validator.message(
                        "memberIds",
                        memberIds,
                        "required"
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mx-auto py-5">
            <NormalButton
              label={id ? "Save" : "Submit"}
              mainbg={true}
              className="px-4 py-2 mx-auto fs-14 line-height-26"
              onClick={() => this.handleSubmit()}
              disabled={isButtonClicked}
            />
          </div>
        </ModalBody>
      </Modal>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      addMemberToFIGApi: addMemberToFIG,
      getFIGByIdApi: getFIGById,
      getFIGListApi: getFIGList,
      getFIGListRmApi: getFIGListRm,
      getMemberListforMappingApi: getMemberListforMapping,
    },
    dispatch
  );
};

export const MapFIGPopup = connect(null, mapDispatchToProps)(MapFIGPopupClass);
