import React, { Component } from "react";
import "./membercard.scss";
import { history } from "service/helpers";
import profilePicture from "assets/images/default-profile-img.png";
import moment from "moment";
import { getDescriptionData } from "service/helperFunctions";

class MemberCard extends Component {
  state = {
    cropDetails: "",
  };
  componentDidMount() {
    let { memberfarminfo } = this.props.payload;
    if (this.props.isMember === true && memberfarminfo != null) {
      let livecropDetails =
        memberfarminfo !== null && memberfarminfo !== undefined
          ? memberfarminfo !== undefined &&
            memberfarminfo.livecropmapping.length > 0
            ? memberfarminfo.livecropmapping.map((crop) => {
                return crop.cropsDetails.cropName;
              })
            : []
          : [];
      let kharifcropDetails =
        memberfarminfo !== null && memberfarminfo !== undefined
          ? memberfarminfo !== undefined &&
            memberfarminfo.kharifcropmapping.length > 0
            ? memberfarminfo.kharifcropmapping.map((crop) => {
                return crop.cropsDetails.cropName;
              })
            : []
          : [];
      let rabicropDetails =
        memberfarminfo !== null && memberfarminfo !== undefined
          ? memberfarminfo !== undefined &&
            memberfarminfo.rabicropmapping.length > 0
            ? memberfarminfo.rabicropmapping.map((crop) => {
                return crop.cropsDetails.cropName;
              })
            : []
          : [];
      this.setState({
        cropDetails: [
          ...livecropDetails,
          ...kharifcropDetails,
          ...rabicropDetails,
        ].join(", "),
      });
    }
  }

  handleRedirect = () => {
    let {
      isBod = false,
      isStaff = false,
      editAccess = false,
      memberDetail = {},
      payload,
    } = this.props;
    let { bodID = "", staffId = "" } = memberDetail;
    let { memberID } = payload;
    if (editAccess) {
      if (isBod) {
        history.push(`/fpo/add-bod/${bodID}`);
      } else if (isStaff) {
        history.push(`/fpo/add-staff/${staffId}`);
      } else {
        history.push(`/fpo/edit-member/${memberID}`);
      }
    } else {
      if (isBod) {
        history.push(`/fpo/view-bod/${bodID}`);
      } else if (isStaff) {
        history.push(`/fpo/view-staff/${staffId}`);
      } else {
        history.push(`/fpo/view-member/${memberID}`);
      }
    }
  };

  viewMember = (event, id) => {
    event.stopPropagation();
    history.push(`/fpo/view-member/${id}`);
  };

  render() {
    let {
      isStaff = false,
      memberDetail = {},
      payload,
      isMember = false,
      editAccess,
      isMapping,
      toggleKYC,
    } = this.props;

    let { memberID, memberfarminfo } = payload;
    const { cropDetails } = this.state;
    let {
      bodID = "",
      firstName = "",
      lastName = "",
      profileImageUrl = "",
      gender = "",
      din = "",
      AppointedOn = "",
      designation = "",
      dateOfJoining = "",
      employeeId = "",
      experience = "",
      educationmaster = [],
      memberFirstName = "",
      memberPhotoUrl = "",
      village = "",
      phone = null,
    } = memberDetail;

    return (
      <div
        className="member-card rounded shadow p-4 cursor-pointer w-100"
        onClick={() => this.handleRedirect()}
      >
        <div className="d-flex justify-content-between mb-4">
          <div className="d-flex align-items-center">
            <div className="mr-2">
              <img
                src={
                  isMember
                    ? memberPhotoUrl !== null && memberPhotoUrl !== ""
                      ? memberPhotoUrl
                      : profilePicture
                    : profileImageUrl
                    ? profileImageUrl
                    : profilePicture
                }
                alt="member_profile"
                className="member-profile"
              />
            </div>
            <div>
              <div className="member-card-font-name">
                {isMember ? (
                  memberFirstName
                ) : (
                  <>
                    {firstName} {lastName}
                  </>
                )}
              </div>
              <div className="member-card-font-number">
                {isMember ? memberID : employeeId ? employeeId : bodID}
              </div>
            </div>
          </div>
          {!isMapping && (
            <div className="d-flex align-items-center member-card-font-gender">
              {gender ? gender : "-"}
            </div>
          )}
        </div>
        <div className="row">
          {isStaff ? (
            <div className="col-6">
              <div className="member-card-font-heading mb-3">{"Education"}</div>
              <div className="member-card-font-content height-50">
                {educationmaster !== null
                  ? educationmaster.length !== 0 &&
                    educationmaster?.qualificationType
                  : "-"}
              </div>
            </div>
          ) : !isMember ? (
            <div className="col-6">
              <div className="member-card-font-heading mb-3">
                {"Designation"}
              </div>
              <div className="member-card-font-content height-50">
                {designation ? getDescriptionData(designation, 25) : "-"}
              </div>
            </div>
          ) : null}
          {isStaff ? (
            <div className="col-6">
              <div className="member-card-font-heading mb-3">
                {"Experience"}
              </div>
              <div className="member-card-font-content">
                {experience ? experience : "-"}
              </div>
            </div>
          ) : !isMember ? (
            <div className="col-6">
              <div className="member-card-font-heading mb-3">DIN</div>
              <div className="member-card-font-content text-break height-50">
                {din ? getDescriptionData(din, 25) : "-"}
              </div>
            </div>
          ) : null}
          {isMember ? (
            <>
              <div className="col-6">
                <div className="member-card-font-heading mb-3">{"Village"}</div>
                <div className="member-card-font-content">
                  {village ? village : "-"}
                </div>
              </div>
              {!isMapping && (
                <div className="col-6">
                  {/* <div className="member-card-font-heading mb-3">
                    {"Acreage"}
                  </div>
                  <div className="member-card-font-content">
                    {memberfarminfo !== null && memberfarminfo !== undefined
                      ? (memberfarminfo !== undefined &&
                          memberfarminfo.livecropmapping.length) +
                        (memberfarminfo !== undefined &&
                          memberfarminfo.kharifcropmapping.length) +
                        (memberfarminfo !== undefined &&
                          memberfarminfo.rabicropmapping.length)
                      : 0}
                  </div> */}
                  <div className="member-card-font-heading mb-3">
                    {"Mobile"}
                  </div>
                  <div className="member-card-font-content">
                    {phone ? phone : "-"}
                  </div>
                </div>
              )}
              <div className="col-12 mt-3">
                <div className="member-card-font-heading mb-3">
                  {"Main Crops"}
                </div>
                <div className="member-card-font-content height-70">
                  {cropDetails ? getDescriptionData(cropDetails, 80) : "-"}
                </div>
              </div>
            </>
          ) : null}
        </div>
        <div className="row">
          {isStaff ? (
            <div className="col-12">
              <div className="member-card-font-heading mb-3">
                {"Date of joining"}
              </div>
              <div className="member-card-font-content">
                {dateOfJoining
                  ? moment(dateOfJoining).format("DD MMMM YYYY")
                  : "-"}
              </div>
            </div>
          ) : !isMember ? (
            <div className="col-12">
              <div className="member-card-font-heading mb-3">
                {"Date of appointment"}
              </div>
              <div className="member-card-font-content">
                {AppointedOn ? moment(AppointedOn).format("DD MMMM YYYY") : "-"}
              </div>
            </div>
          ) : null}
        </div>
        {editAccess && memberID && !isMapping && (
          <div className="kyc-btns">
            <button onClick={(e) => this.viewMember(e, memberID)}>View</button>
          </div>
        )}
      </div>
    );
  }
}

export default MemberCard;
