import React from "react";
import { Modal, ModalBody } from "reactstrap";

import "./style.scss";

export const ConfirmationPopup = ({
  isOpen,
  toggle,
  onSubmit,
  message = "Are you sure you want to delete?",
}) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} centered={true}>
      <ModalBody>
        <div className="confirm-popup">
          <i className="fas fa-times" onClick={toggle}></i>
          <div>
            <b>{message}</b>
          </div>
          <div className="confirm-btn">
            <button
              onClick={() => {
                onSubmit();
                toggle();
              }}
            >
              Agree
            </button>
            <button onClick={toggle}>Cancel</button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};
