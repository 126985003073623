import React, { Component } from "react";
import { Navbar } from "../../component/common";
import { CapacityBuildingComp } from "../../component/RM";

export class CapacityBuilding extends Component {
  componentDidMount() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  render() {
    return (
      <div>
        <div className="normal-header">{/* <Navbar /> */}</div>
        <CapacityBuildingComp />
      </div>
    );
  }
}
