import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  addPlan,
  updatePlan,
  getPlanById,
  deletePlan,
} from "action/KeyFinancialAct";
import { documentCurrentTab } from "action/DocumentAct";
import { getAllFinancialYearDropdown } from "action/ComplianceAct";
import { fileUpload } from "action/MemberAct";

import SimpleReactValidator from "simple-react-validator";
import { Toast } from "service/toast";
import { history } from "service/helpers/index";
import { getAccessdata } from "service/helperFunctions";
import {
  NormalButton,
  NormalSelect,
  CommonPageLoader,
  ConfirmationPopup,
} from "component/common";
import trash from "assets/svg/Associations/trash.svg";

const arrow_left = require("assets/svg/add_member/arrow_left.svg");

class AddPlanClass extends Component {
  constructor(props) {
    super();
    this.state = {
      FyearID: "",
      documentName: "",
      documentUrl: null,
      allFinancialYear: [],
      isImageUploading: false,
      isLoading: false,
      isDelete: false,
    };
  }

  validator = new SimpleReactValidator({
    element: (message) => (
      <span className="error-message text-danger fs-14 mt-3">{message}</span>
    ),
    autoForceUpdate: this,
  });

  handleFile = (e) => {
    let file = e.target.files[0];
    if (file !== undefined) {
      this.setState({ documentUrl: null }, () => {
        this.setState({
          isImageUploading: true,
          documentName: "Uploading ....",
          documentUrl: file,
        });
        const formData = new FormData();
        formData.append("file", file);

        this.props
          .fileUpload(formData)
          .then((data) => {
            document.getElementById("handleFile").value = null;
            this.setState({
              documentUrl: data[0].fileUrl,
              documentName: data[0].fileName,
              isImageUploading: false,
            });
          })
          .catch(() => {
            this.setState({
              documentUrl: null,
              documentName: "",
              isImageUploading: false,
            });
          });
      });
    }
  };
  handleDelete = () => {
    this.setState({ isLoading: true });
    this.props.deletePlan(this.props.id).then(() => {
      this.handleBack();
    });
  };
  handleSubmit = () => {
    this.setState({ isLoading: true });

    if (this.validator.allValid()) {
      const {
        FyearID,
        documentName,
        documentUrl,
        allFinancialYear,
        isImageUploading,
      } = this.state;
      if (!isImageUploading) {
        let body = {
          businessDpId: parseInt(this.props.id),
          documentName,
          documentUrl,
          fymId: FyearID,
          fymYear: "",
        };

        let dataSet = allFinancialYear.filter((x) => x.value == FyearID);
        if (dataSet.length > 0) {
          body.fymYear = dataSet[0].label;
        }

        this.props.id
          ? this.props
              .updatePlan(body)
              .then(() => {
                this.validator.hideMessages();
                this.handleBack();
              })
              .catch(() => {
                this.setState({ isLoading: false });
              })
          : this.props
              .addPlan(body)
              .then(() => {
                this.validator.hideMessages();
                this.setState({
                  FyearID: "",
                  documentName: "",
                  documentUrl: null,
                  isImageUploading: false,
                  isLoading: false,
                });
                this.handleBack();
              })
              .catch(() => {
                this.setState({ isLoading: false });
              });
      } else {
        this.setState({ isLoading: false });

        Toast({ type: "error", message: "Please wait Image is uploading" });
      }
    } else {
      this.setState({ isLoading: false });

      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  handleInput = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  componentDidMount() {
    this.props.getAllFinancialYearDropdown().then((data) => {
      let financialData = [];

      data.financialYearMaster.map((list) => {
        financialData.push({ value: list.fymId, label: list.fymYear });
      });
      this.setState({
        allFinancialYear: financialData,
      });
    });
    if (this.props.id) {
      this.setState({ isLoading: true });
      this.props.getPlanById(this.props.id).then((data) => {
        this.setState({
          FyearID: data.fymId,
          documentName: data.documentName,
          documentUrl: data.documentUrl,
          isLoading: false,
        });
      });
    }
  }

  handleBack = () => {
    history.goBack();
    this.props.documentCurrentTab("6");
  };

  handleRemoveImage = () => {
    this.setState({
      documentUrl: null,
      documentName: "",
    });
  };

  toggleDelete = () => {
    let { isDelete } = this.state;
    this.setState({
      isDelete: !isDelete,
    });
  };

  render() {
    const {
      FyearID,
      documentName,
      documentUrl,
      allFinancialYear,
      isLoading,
      isImageUploading,
      isDelete,
    } = this.state;

    let { accessData } = this.props;
    let permissionData = getAccessdata(accessData, "keyFinancials");

    let { deleteAccess = false } = permissionData;
    return (
      <>
        {!isLoading ? (
          <div className="key-financials">
            <div className="d-flex container-sa my-3">
              <div
                className="d-flex cursor-pointer"
                onClick={() => this.handleBack()}
              >
                <img className="cursor-pointer" src={arrow_left} alt="back" />
                <p className="ml-3 add-member-text-back mb-0">
                  {this.props.id ? "Edit" : "Add"} Business plan
                </p>
              </div>
            </div>
            <div className="add-form">
              <div className="container bg-white py-4">
                <div className="row">
                  <div className="col-md-6">
                    <NormalSelect
                      isHalfWidthForMemberProfile={true}
                      label={"Financial year"}
                      placeholder="Select"
                      value={FyearID}
                      name="FyearID"
                      options={allFinancialYear}
                      arrow={true}
                      handleChange={this.handleInput}
                      required={true}
                    />
                    <div className="row">
                      <div className="col-md-8 ml-auto">
                        {this.validator.message(
                          "Financial year",
                          FyearID,
                          "required"
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="row upload-area">
                      <div className="col-md-4 mb-md-0 mb-2">
                        <div className="fs-16 text-black-32 font-Gilroy-Bold font-weight-bold line-height-19">
                          Business plan
                          <span className="text-danger font-weight-bold pl-2 fs-18">
                            {"*"}
                          </span>
                        </div>
                      </div>
                      <div className="col-md-8">
                        <div className="d-flex align-items-center btn-file-input">
                          <button className="">
                            <input
                              type="file"
                              className=""
                              accept=".jpg, .pdf, .png, .jpeg, .docx, .xlsx"
                              onChange={this.handleFile}
                              id="handleFile"
                              title=""
                            />
                            {documentUrl == null ? (
                              "Choose file"
                            ) : (
                              <>
                                {documentName.length == 10
                                  ? documentName
                                  : documentName.substring(0, 9)}
                                {"..."}
                              </>
                            )}
                          </button>
                          {documentName != "" && !isImageUploading && (
                            <div className="pl-3 position-relative">
                              <img
                                src={documentUrl}
                                alt={documentName}
                                height="75"
                                width="75"
                              />
                              <i
                                className="icon-Delete text-danger fs-14 pl-2 cursor-pointer position-absolute"
                                onClick={() =>
                                  this.handleRemoveImage("addressProof")
                                }
                              ></i>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-8 ml-auto">
                        {this.validator.message(
                          "documentUrl",
                          documentUrl,
                          "required"
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="pt-5 pb-3 d-flex">
                  <div className="mx-auto d-flex">
                    {this.props.id && deleteAccess && (
                      <div className="cursor-pointer d-flex align-items-center mr-5">
                        <div
                          className="text-center fs-14 fw-600 text-danger d-flex"
                          onClick={this.toggleDelete}
                        >
                          <img className="mr-2" src={trash} alt="icon" />
                          {"Delete"}
                        </div>
                      </div>
                    )}
                    <NormalButton
                      label={`${this.props.id ? "Update" : "Submit & Next"}`}
                      mainbg={true}
                      className="px-3 py-2 fs-14 line-height-26"
                      onClick={this.handleSubmit}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <>
            {/* Loader */}
            <CommonPageLoader />
          </>
        )}
        <ConfirmationPopup
          isOpen={isDelete}
          toggle={this.toggleDelete}
          onSubmit={this.handleDelete}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  accessData: state.home.accessData,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      addPlan,
      updatePlan,
      getPlanById,
      getAllFinancialYearDropdown,
      fileUpload,
      deletePlan,
      documentCurrentTab,
    },
    dispatch
  );
};
let component = AddPlanClass;

export const AddPlan = connect(mapStateToProps, mapDispatchToProps)(component);
