import React from 'react';
import { history } from 'service/helpers';
import { currencyConvert } from 'service/helperFunctions';

const User_Profile_1 = require('assets/svg/Members/User_Profile_1.svg');

function Card(props) {
   const { payload, index, editAccess } = props;
   const {
      creditId,
      member_profile = '',
      fInstitution,
      lenderName,
      LoanAmountAvailed,
      TenurePeriod,
      Tenure,
      RateofInterest,
      interestPayment,
      OutstandingLoanAmount,
      poloanmapping,
      LoanType,
      securityDescription,
   } = payload;
   const pOftheLoanString = poloanmapping.map((purpose, index) => {
      return purpose.poloan.Types;
   });
   return (
      <div
         className={`col-md-3 col-lg-3 col-6 ${
            index % 4 === 3 ? 'pr-md-0' : ''
            } mb-2 mt-3 px-2 ${index % 4 === 0 ? 'pl-md-0' : ''}`}
         onClick={() => {
            editAccess &&
               history.push(`/fpo/edit-credithistory/${creditId}`);
         }}
      >
         <div className='credit-card rounded shadow cursor-pointer'>
            <div className='d-flex align-items-center mb-4'>
               <div className='mr-2 pr-1 position-relative d-flex justify-content-center align-items-center'>
                  {/* Profile */}
                  <img
                     className='rounded-circle income-card-profile'
                     src={
                        member_profile && member_profile.includes('https://')
                           ? member_profile
                           : User_Profile_1
                     }
                     alt='profile'
                  />
                  <span className='fs-20 fw-600 text-white position-absolute'>
                     {lenderName.charAt(0)}
                  </span>
               </div>
               <div>
                  <div className='line-height-21 font-Gilroy-Bold fs-18 text-black-1c font-weight-bold'>
                     {lenderName}
                  </div>
               </div>
            </div>
            <div className='row mb-4'>
               <div className='col-sm-12 '>
                  <div className='line-height-21 font-Gilroy text-black-50 fs-16 mb-3'>
                     {'Loan type'}
                  </div>
                  <div className='line-height-21 font-Gilroy-Bold fs-18 text-black-1c font-weight-bold'>
                     {LoanType ? LoanType : '-'}
                  </div>
               </div>
            </div>
            <div className='row mb-4'>
               <div className='col-sm-12 '>
                  <div className='line-height-21 font-Gilroy text-black-50 fs-16 mb-3'>
                     {'Financial institution'}
                  </div>
                  <div className='line-height-21 font-Gilroy-Bold fs-18 text-black-1c font-weight-bold'>
                     {fInstitution ? fInstitution.institutionType : '-'}
                  </div>
               </div>
            </div>
            <div className='row mb-4'>
               <div className='col-sm-12 '>
                  <div className='line-height-21 font-Gilroy text-black-50 fs-16 mb-3'>
                     {'Loan amount availed ( '}{' '}
                     <span className='icon-rupee'></span> {' )'}
                  </div>
                  <div className='line-height-21 font-Gilroy-Bold fs-18 text-black-1c font-weight-bold'>
                     {LoanAmountAvailed ? currencyConvert(LoanAmountAvailed) : '-'}
                  </div>
               </div>
            </div>
            <div className='row mb-4'>
               <div className='col-sm-6 '>
                  <div className='line-height-21 font-Gilroy text-black-50 fs-16 mb-3'>
                     {'Tenure period'}
                  </div>
                  <div className='line-height-21 font-Gilroy-Bold fs-18 text-black-1c font-weight-bold'>
                     {Tenure ? Tenure : '-'} {TenurePeriod ? TenurePeriod : ''}
                  </div>
               </div>
               <div className='col-sm-6 pr-0'>
                  <div className='line-height-21 font-Gilroy text-black-50 fs-16 mb-3'>
                     {'Rate of interest'}
                  </div>
                  <div className='line-height-21 font-Gilroy-Bold fs-18 text-black-1c font-weight-bold'>
                     {RateofInterest ? `${RateofInterest}%` : '-'}
                  </div>
               </div>
            </div>
            <div className='row mb-4'>
               <div className='col-sm-12  height-70'>
                  <div className='line-height-21 font-Gilroy text-black-50 fs-16 mb-3'>
                     {'Purpose of the Loan'}
                  </div>
                  <div
                     className='line-height-21 font-Gilroy-Bold fs-18 text-black-1c font-weight-bold'
                  >
                     {pOftheLoanString ? pOftheLoanString.join(', ').length > 50
                        ? `${pOftheLoanString.join(', ').substring(0, 50)}...`
                        : pOftheLoanString.join(', ') : '-'}
                  </div>
               </div>
            </div>
            <div className='row mb-4'>
               <div className='col-sm-12 '>
                  <div className='line-height-21 font-Gilroy text-black-50 fs-16 mb-3'>
                     {'Interest payment'}
                  </div>
                  <div className='line-height-21 font-Gilroy-Bold fs-18 text-black-1c font-weight-bold'>
                     {interestPayment ? interestPayment : '-'}
                  </div>
               </div>
            </div>
            <div className='row mb-4'>
               <div className='col-sm-12 '>
                  <div className='line-height-21 font-Gilroy text-black-50 fs-16 mb-3'>
                     {'Outstanding loan amount ( '}{' '}
                     <span className='icon-rupee'></span> {' )'}
                  </div>
                  <div className='line-height-21 font-Gilroy-Bold fs-18 text-black-1c font-weight-bold'>
                     {OutstandingLoanAmount ? currencyConvert(OutstandingLoanAmount) : '-'}
                  </div>
               </div>
            </div>

            <div className='row mb-4'>
               <div className='col-sm-12 height-70'>
                  <div className='line-height-21 font-Gilroy text-black-50 fs-16 mb-3'>
                     {'Security description'}
                  </div>
                  <div className='line-height-21 font-Gilroy-Bold fs-18 text-black-1c font-weight-bold'>
                     {securityDescription ? securityDescription.length > 50
                        ? `${securityDescription.substring(0, 50)}...`
                        : securityDescription : '-'}
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
}

export default Card;
