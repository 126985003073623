import React, { Component } from "react";
const bank = require("../../../assets/svg/view_member/bank.svg");
export class Bank extends Component {
  render() {
    let { memberBankAccount } = this.props;
    return (
      <div>
        <div className="d-flex align-items-center mb-3">
          <img className="mr-3 bg-area-profile" src={bank} alt="bank" />
          <span className="member-profile-card-title">Bank</span>
        </div>
        <div className="member-profile-card rounded shadow">
          <div className="d-flex justify-content-center flex-column justify-content-between">
            <div className="d-flex align-items-center mr-5 mb-5 ml-2">
              <img className="mr-3 bg-area-profile" src={bank} alt="bank" />
              <div className="member-profile-card-heading fs-26">
                {memberBankAccount.acountNumber
                  ? memberBankAccount.acountNumber
                  : "-"}
              </div>
            </div>
            <div className="d-flex justify-content-between mb-5">
              <div>
                <div className="member-assets-card-heading pb-2">
                  {"Account No"}
                </div>
                <div className="member-profile-card-heading">
                  {memberBankAccount.acountNumber
                    ? memberBankAccount.acountNumber
                    : "-"}
                </div>
              </div>
              <div>
                <div className="member-assets-card-heading pb-2">
                  {"Bank name"}
                </div>
                <div className="member-profile-card-heading">
                  {memberBankAccount.bankName
                    ? memberBankAccount.bankName
                    : "-"}
                </div>
              </div>
            </div>
            <div className="d-flex flex-wrap gap-4 justify-content-between">
              <div>
                <div className="member-assets-card-heading pb-2">
                  {"Bank branch"}
                </div>
                <div className="member-profile-card-heading">
                  {memberBankAccount.bankBranch
                    ? memberBankAccount.bankBranch
                    : "-"}
                </div>
              </div>
              <div>
                <div className="member-assets-card-heading pb-2">{"IFSC"}</div>
                <div className="member-profile-card-heading">
                  {memberBankAccount.IFSC ? memberBankAccount.IFSC : "-"}
                </div>
              </div>
              <div>
                <div className="member-assets-card-heading pb-2">
                  {"Account Type"}
                </div>
                <div className="member-profile-card-heading">
                  {memberBankAccount.accountType
                    ? memberBankAccount.accountType
                    : "-"}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
