import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getGatewayForRm } from "action/RmAct";
import { history } from "service/helpers";

class GatewayComp extends Component {
  state = {
    gateway: [],
  };
  componentDidMount() {
    this.props.getGatewayForRm().then((data) => {
      this.setState({
        gateway: data,
      });
    });
  }
  render() {
    const { gateway } = this.state;
    return (
      <div className="w-100 mt-2">
        <div className="gateway-card-header text-gold mb-3">
          Gateway Services
        </div>
        <div>
          <div className="d-flex justify-content-between flex-wrap">
            <div className="row">
              {gateway.map((list, index) => {
                let { redirectURL, icon, title, isThirdParty, isComingSoon } =
                  list;
                return (
                  <div className="col-md-3 col-sm-4 col-6" key={index}>
                    <div
                      className="d-flex flex-column align-items-center cursor-pointer gateway-link"
                      onClick={() => {
                        isThirdParty && redirectURL !== null
                          ? window.open(redirectURL, "_blank")
                          : !isThirdParty &&
                            history.push("/rm/capacity-building");
                      }}
                    >
                      <div className="rounded-circle p-4 shadow mb-3 gateway-image-container">
                        <img
                          className="gateway-image"
                          src={icon}
                          alt="gw-icon.svg"
                        />
                      </div>
                      <div className="font-weight-bold text-break text-center d-flex justify-content-center">
                        {title}
                      </div>
                      <div className="font-weight-bold text-break text-center d-flex justify-content-center mb-2">
                        {isComingSoon ? "(Coming soon)" : null}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getGatewayForRm,
    },
    dispatch
  );
};
let component = GatewayComp;

export const Gateway = connect(null, mapDispatchToProps)(component);
