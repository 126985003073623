import {
  sumunnatiRegistration,
  FpoRegistration,
  ExternalRegistration,
  IndividualRegistration,
} from "service/apiVariables";
import { Toast } from "service/toast";
import { api } from "service/api";

export const samunnatiReg = (body) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    api({ ...sumunnatiRegistration.registrationApi, body })
      .then(({ data, message }) => {
        resolve(data);
        Toast({ type: "success", message, time: 5000 });
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};
export const samunnatiRoles = () => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    api({ ...sumunnatiRegistration.roleApi })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

export const fpoReg = (body) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    api({ ...FpoRegistration.registrationApi, body })
      .then(({ data, message }) => {
        resolve(data);
        Toast({ type: "success", message, time: 5000 });
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

// registration Api Under Fpo
export const registrationApiUnderFpo = (body) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    api({ ...FpoRegistration.registrationApiUnderFpo, body })
      .then(({ data, message }) => {
        resolve(data);
        Toast({ type: "success", message, time: 5000 });
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

export const fpoRoles = () => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    api({ ...FpoRegistration.roleApi })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//Fpo list
export const fpoList = () => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    api({ ...FpoRegistration.fpoList })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

export const getSignUpEntityType = () => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    api({ ...FpoRegistration.signUpEntityType })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

export const emailAndNumberValidation = (body) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    api({ ...FpoRegistration.emailAndNumberValidation, body })
      .then(({ message }) => {
        resolve(message);
        // Toast({ type: "success", message, time: 5000 });
      })
      .catch(({ message }) => {
        reject(message);
        // reject(Toast({ type: "error", message }));
      });
  });
};

export const externalReg = (body) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    api({ ...ExternalRegistration.registrationApi, body })
      .then(({ data, message }) => {
        resolve(data);
        Toast({ type: "success", message, time: 5000 });
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};
export const externalRoles = () => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    api({ ...ExternalRegistration.roleApi })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

export const individualReg = (body) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    api({ ...IndividualRegistration.registrationApi, body })
      .then(({ data, message }) => {
        resolve(data);
        Toast({ type: "success", message, time: 5000 });
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};
export const individualRoles = () => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    api({ ...IndividualRegistration.roleApi })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};
