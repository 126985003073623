import { ComplianceType } from "service/actionType";

const initialState = {
  tab: 1,
};

export default (state = Object.assign({}, initialState), { type, payload }) => {
  switch (type) {
    case ComplianceType.complianceCurrentTab:
      return {
        ...state,
        tab: payload,
      };
    default:
      return state;
  }
};
