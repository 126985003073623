import React, { Component } from "react";
import "./strip.scss";
import moment from "moment";
import { history } from "service/helpers";
export class Strip extends Component {
  render() {
    const {
      licenseName,
      licenseNumber,
      issuingAuthority,
      validityDate,
      licenseId,
      id,
    } = this.props.payload;
    let { editAccess } = this.props;
    return (
      <div className="container-sa license-strip shadow rounded mb-3">
        <div className="row align-items-center h-100 px-2">
          <div className="col-2">
            <div className="fs-18 fw-600">
              {licenseName
                ? licenseName.licenseName.length > 30
                  ? `${licenseName.licenseName.substring(0, 30)}...`
                  : licenseName.licenseName
                : "-"}
            </div>
            <div className="text-black-50">Licence</div>
          </div>
          <div className="col-3">
            <div className="fs-18 fw-600">
              {licenseNumber ? licenseNumber : "-"}
            </div>
            <div className="text-black-50">Licence number</div>
          </div>
          <div className="col-3">
            <div className="fs-18 fw-600">
              {issuingAuthority ? issuingAuthority : "-"}
            </div>
            <div className="text-black-50">Issuing authority</div>
          </div>
          <div className="col-2">
            <div className="fs-18 fw-600">
              {validityDate ? moment(validityDate).format("DD MMMM YYYY") : "-"}
            </div>
            <div className="text-black-50">Validity date</div>
          </div>
          <div className="col-1">
            <div
              className="text-black-50 cursor-pointer"
              onClick={() => history.push(`/fpo/compliance/licenseview/${id}`)}
            >
              {"View"}
            </div>
          </div>
          {editAccess && (
            <div className="col-1">
              <div
                className="text-black-50 cursor-pointer"
                onClick={() => history.push(`/fpo/edit-license/${id}`)}
              >
                {"Edit"}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}
