import React, { Component } from "react";
import { Navbar } from "component/common";
import { MembersTab } from "component/Members/MembersTab";
import { connect } from "react-redux";
import { UserAuth } from "helpers/UserAuth";
import { PowerBIEmbed } from "powerbi-client-react";
import { models } from "powerbi-client";
import { bindActionCreators } from "redux";
import { logout } from "service/utilities";
import { getAccessTokenForPowerBi } from "action/AuthAct";
import { isFirstTimeLoginOrNot } from "action/MemberAct";

export class MembersAnalyticsClass extends Component {
  state = {
    accessToken: null,
    embedUrl: null,
  };

  //life cycles
  componentDidMount() {
    if (
      localStorage.getItem("userGroupName") !== "fpo" ||
      !JSON.parse(localStorage.getItem("isKymUser"))
    ) {
      logout();
    } else {
      this.getAccessToken();
    }
  }

  //get Access oken
  getAccessToken = () => {
    this.props.getAccessTokenForPowerBiApi().then((data) => {
      if (data) {
        this.props.isFirstTimeLoginOrNot().then((res) => {
          if (res) {
            let fpoName = res.fpoName;
            this.setState({
              accessToken: data,
              embedUrl: `https://app.powerbi.com/reportEmbed?groupId=780f648c-c18d-47f9-be58-a92e47c14f3f&reportId=aa4754b2-388a-467b-a790-a35119d0b552&filter=fpo_kym_data_Latest/fpoName eq '${fpoName}'`,
            });
          }
        });
      }
    });
  };

  render() {
    let { accessToken, embedUrl } = this.state;

    return (
      <div>
        <div className="inner-header">
          {/* <Navbar /> */}
          <div className="h-100 w-100">
            <p className="d-flex justify-content-center align-items-center h-100 w-100 text-white fs-32 font-weight-bold">
              Member Analytics
            </p>
          </div>
        </div>
        <MembersTab id={this.props.match.params.id} match={this.props.match} />
        <div className="powerbi-report bg-blue-f9">
          <div className="container-sa">
            {embedUrl && accessToken ? (
              <PowerBIEmbed
                embedConfig={{
                  type: "report",
                  id: "aa4754b2-388a-467b-a790-a35119d0b552",
                  embedUrl: embedUrl,
                  accessToken: accessToken,
                  tokenType: models.TokenType.Aad,
                  settings: {
                    filterPaneEnabled: false,
                    navContentPaneEnabled: false,
                  },
                }}
                cssClassName={"report-style-class"}
              />
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  accessData: state.home.accessData,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getAccessTokenForPowerBiApi: getAccessTokenForPowerBi,
      isFirstTimeLoginOrNot,
    },
    dispatch
  );
};

let component = UserAuth(MembersAnalyticsClass, { service: "membership" });

export const MembersAnalytics = connect(
  mapStateToProps,
  mapDispatchToProps
)(component);
