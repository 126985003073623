import React, { useEffect } from "react";
import BackButton from "component/common/BackButton";
import MemberDetails from "component/RM/RMKYMNew/MemberDetails";
import { UserAuth } from "helpers/UserAuth";

const AddMemberClass = (props) => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  });

  return (
    <div>
      <div className="inner-header">
          <div className="h-100 w-100">
            <p className="d-flex justify-content-center align-items-center h-100 w-100 text-white fs-32 font-weight-bold">
              Member Engagement
            </p>
          </div>
        </div>
      <BackButton />
      <MemberDetails module="member" />
    </div>
  );
};

export const AddMember = UserAuth(AddMemberClass, { service: "membership" });

