import { NormalButton } from 'component/common';
import React, { Component, useState } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import { FieldsTemplate } from '../common/FieldsTemplate';

export const SocioEconomicAndEnvironmentFactors = (props) => {
    const [formData, setFormData] = useState({});
    const [, forceUpdate] = React.useState();

    const formAttr = [
        { label: "Total area of landholding of member farmers as per FPC records", field: "input", type: "number", name: "areaoflandholding", required: true, placeholder: "", note: "if data not available enter 0" },
        { label: "Total area under cultivation as per FPC records", field: "input", type: "number", name: "cultivatedarea", required: true, placeholder: "", note: "if data not available enter 0" },
        { label: "Total irrigated area under cultivation as per FPC records", field: "input", type: "number", name: "irrigatedarea", required: true, placeholder: "", note: "if data not available enter 0" },
        { label: "Total area under organic cultivation as per FPC records", field: "input", type: "number", name: "organicarea", required: true, placeholder: "", note: "if data not available enter 0" },
        { label: "Number of FPO members below the poverty line as per FPC records", field: "input", type: "number", name: "belowpoverty", required: true, placeholder: "", note: "if data not available enter 0" },
        { label: "Percentage Reduction in the Cost of Cultivation after you became the member of the FPO", field: "input", type: "number", name: "percentreductionofcost", required: true, placeholder: "", note: "Example: if value is 55.5 % enter 55.5 (If data not available enter 0)" },
        { label: "What is the percentage of renewable energy to total energy?", field: "input", type: "number", name: "percentrenewableenergy", required: true, placeholder: "", note: "if data not available enter 0" },
        {
            label: "How you dispose the stubbles?",
            field: "radioGroup", type: "number", name: "stubbledisposal", required: true,
            list: [
                { value: "composted", label: "Composting of stubbles" },
                { value: "mulching", label: "Stubbles are used for mulching" },
                { value: "burnt", label: "Stubbles are burned" },
                { value: "nostubble", label: "No stubble available for the crop associated with the FPO" }
            ]
        }
    ]

    React.useEffect(() => {
        if (props?.surveyDetails) {
            let _formData = {
                areaoflandholding: props?.surveyDetails.areaoflandholding,
                cultivatedarea: props?.surveyDetails.cultivatedarea,
                irrigatedarea: props?.surveyDetails.irrigatedarea,
                organicarea: props?.surveyDetails.organicarea,
                belowpoverty: props?.surveyDetails.belowpoverty,
                percentreductionofcost: props?.surveyDetails.percentreductionofcost,
                percentrenewableenergy: props?.surveyDetails.percentrenewableenergy,
                stubbledisposal: props?.surveyDetails.stubbledisposal
            };

            setFormData({
                ...formData,
                ..._formData
            });
        }
    }, [props?.surveyDetails]);

    React.useEffect(() => {
        if (props?.submitSurveyClick) {
            handleSubmit('completed');
        }
    }, [props?.submitSurveyClick]);

    const handleInput = ({ target: { name, value } }) => {
        setFormData((preVal) => {
            return {
                ...preVal,
                [name]: value,
            }
        })
    };

    const handleRadio = (name, value) => {
        setFormData((preVal) => {
            return {
                ...preVal,
                [name]: value,
            }
        })
    }

    //validation
    const simpleValidator = React.useRef(new SimpleReactValidator({
        element: (message) => (
            <span className="error-message text-danger fs-14">{message}</span>
        ),
        autoForceUpdate: this
    }));

    const handleSubmit = (status) => {
        const formValid = simpleValidator.current.allValid();
        if (formValid) {
            let payload = { ...formData, status };
            props.updateSurvey("socioEconomicAndEnvironmentFactorsInfo", payload);

        } else {
            simpleValidator.current.showMessages()
            forceUpdate(1);
            props.errorAccordion();
        }
    }
    return (
        <div>
            <div className="container-sa position-ralative">
                {/* <h5>Technology and Services</h5> */}
                <FieldsTemplate
                    attributes={formAttr}
                    simpleValidator={simpleValidator}
                    stateAttr={formData}
                    handleInput={handleInput}
                    handleRadio={handleRadio}
                // handleCheckbox={handleCheckbox}
                />
                <div className="row mt-3">
                    <div className="col-md-5 col-sm-12  my-1">
                        <NormalButton
                            label="Save as draft"
                            mainbg={true}
                            className="px-3 py-2 mx-auto fs-14 line-height-26 font-Gilroy saveNext"
                            onClick={() => handleSubmit('draft')}
                            isPopup={true}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}