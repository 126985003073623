import React, { Component } from "react";
import SimpleReactValidator from "simple-react-validator";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Modal, ModalBody } from "reactstrap";
import OtpInput from "react-otp-input";
import {
  fpoReg,
  fpoRoles,
  registrationApiUnderFpo,
  fpoList,
  getSignUpEntityType,
  emailAndNumberValidation,
} from "action/RegAct";
import {
  fileUpload,
  getcropBusinessInfra,
  getDistrictApiwithState,
  allState,
  getPincodeApiwithStateDistrict,
} from "action/MemberAct";
import { FpoRegStepOne } from "./FpoRegStepOne";
import { FpoRegStepTwo } from "./FpoRegStepTwo";
import { FpoRegStepThree } from "./FpoRegStepThree";
import { Toast } from "service/toast";
import { encryptData } from "service/helperFunctions";
import { PasswordStrengthMeter } from "component/common";
import Select, { components } from "react-select";
import moment from "moment";
import {
  customStyles,
  FPOformFields,
  RMFormFields,
  formFieldsunderFPO,
} from "./RegFpoDatas";
import msIcon from "assets/svg/ms_signin_light.svg";
import { debounceFunction } from "service/debounce";
const fpo = require("../../../../assets/svg/registration_logo.svg");
export class Fpo extends Component {
  state = {
    roles: [],
    fpoList: [],
    passwordVisible: false,
    reEnterPasswordVisible: false,
    userType: "fpoReg",
    formFieldsFPO: { ...FPOformFields },
    formFields: { ...formFieldsunderFPO },
    formFieldsRM: { ...RMFormFields },
    currPage: "step1",
    business_activties: [],
    crops_list: [],
    infrastructure_list: [],
    license_list: [],
    cropMapping: [],
    stateList: [],
    entityList: [],
    societyTypeOptions: [],
    districtOptions: [],
    pincodeOptions: [],
    emailField: false,
    numberField: false,
    CINField: false,
    entityField: false,
    emailValidate: false,
    numberValidate: false,
    CINValidate: false,
    entityValidate: false,
    verifificationPopup: false,
    verificationType: "",
    otp: "",
    otpSuccess: false,
    mobileVerified: false,
    emailVerified: false,
    timer: false,
  };

  // validation
  validatorForRegUnderFpo = new SimpleReactValidator({
    element: (message) => (
      <span className="error-message text-danger fs-14">{message}</span>
    ),
    autoForceUpdate: this,
    validators: {
      password: {
        message: "The :attribute must be a valid format.",
        rule: (val, params, validator) => {
          return (
            validator.helpers.testRegex(
              val,
              /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$^+=!*()@%&]).{8,20}$/
            ) && params.indexOf(val) === -1
          );
        },
        messageReplace: (message, params) =>
          message.replace("", this.helpers.toSentence(params)),
        required: true,
      },
      checkPassword: {
        message: "Given :attribute does not match",
        rule: function (val, params) {
          return val === params[0];
        },
      },
    },
  });

  validationForRM = new SimpleReactValidator({
    element: (message) => (
      <span className="error-message text-danger fs-14">{message}</span>
    ),
    autoForceUpdate: this,
    validators: {
      password: {
        message: "The :attribute must be a valid format.",
        rule: (val, params, validator) => {
          return (
            validator.helpers.testRegex(
              val,
              /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$^+=!*()@%&]).{8,20}$/
            ) && params.indexOf(val) === -1
          );
        },
        messageReplace: (message, params) =>
          message.replace("", this.helpers.toSentence(params)),
        required: true,
      },
      checkPassword: {
        message: "Given :attribute does not match",
        rule: function (val, params) {
          return val === params[0];
        },
      },
    },
  });

  componentDidMount() {
    this._getcropBusinessInfra();
    this._getStateList();
    this._getEntityList();
    this.setState({
      formFieldsFPO: { ...FPOformFields },
      formFields: { ...formFieldsunderFPO },
    });
    let societyTypeData = [
      { value: "pacs", label: "PACS" },
      { value: "dairy", label: "Dairy Society" },
      { value: "veg_fed", label: "Veg Fed Society" },
      { value: "lamps", label: "LAMPS" },
    ];
    this.setState({
      societyTypeOptions: societyTypeData,
    });
  }

  _getEntityList = () => {
    this.props.getSignUpEntityType().then((data) => {
      let entityData = [];
      data.map((list) => {
        entityData.push({ value: list.entityId, label: list.entityName });
      });
      this.setState({
        entityList: entityData,
      });
    });
  };

  _getStateList = () => {
    this.props.allState().then((data) => {
      let stateData = [];

      data.map((list) => {
        stateData.push({ value: list.stateId, label: list.stateName });
      });
      this.setState({
        stateList: stateData,
      });
    });
  };

  handleInputChange = ({ target: { name, value } }) => {
    let { formFields } = this.state;
    formFields[name] = value;
    this.setState({
      formFields,
    });
  };

  validation = (value) => {
    let body;
    const { entityId } = this.state.formFieldsFPO;
    const { emailField, numberField, CINField, entityField } = this.state;
    let regx = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g.test(value);
    if (value) {
      if (emailField && regx) {
        body = {
          emailId: value,
        };
      } else if (numberField && value?.length === 10) {
        body = {
          mobileNumber: value,
        };
      } else if (entityField) {
        body = {
          fpoName: value,
        };
      } else if (CINField) {
        if (entityId === "2" && value?.length === 21) {
          body = {
            companyCin: value,
          };
        } else if (entityId === "3") {
          body = {
            cooperative_registration_number: value,
          };
        } else if (entityId === "4") {
          body = {
            trust_registration_number: value,
          };
        }
      } else {
        return null;
      }
      this.props.emailAndNumberValidation(body).then((message) => {
        if (message === "success") {
          if (emailField) {
            this.setState({
              emailValidate: false,
            });
          } else if (numberField) {
            this.setState({
              numberValidate: false,
            });
          } else if (entityField) {
            this.setState({
              entityValidate: false,
            });
          } else if (CINField) {
            this.setState({
              CINValidate: false,
            });
          }
        } else {
          if (emailField) {
            let emailMessage = "This email is already registered";
            if (message === emailMessage) {
              this.setState({
                emailValidate: true,
              });
            } else {
              this.setState({
                emailValidate: false,
              });
            }
          } else if (numberField) {
            let numberMessage = "This mobile number is already registered";
            if (message === numberMessage) {
              this.setState({
                numberValidate: true,
              });
            } else {
              this.setState({
                numberValidate: false,
              });
            }
          } else if (entityField) {
            let entityMessage = "Fpo already exist";
            if (message === entityMessage) {
              this.setState({
                entityValidate: true,
              });
            } else {
              this.setState({
                entityValidate: false,
              });
            }
          } else if (CINField) {
            let cinMessage = "This company CIN is already registered";
            let crnMessage =
              "This cooperative registration number is already registered";
            let trnMessage =
              "This trust registration number is already registered";
            let addFieldMesage = "Please Add Any One Fields";
            if (
              message === cinMessage ||
              message === crnMessage ||
              message === trnMessage ||
              message === addFieldMesage
            ) {
              this.setState({
                CINValidate: true,
              });
            } else {
              this.setState({
                CINValidate: false,
              });
            }
          }
        }
      });
    }
  };

  // handle Change for formFields FPO
  handleChange = ({ target: { name, value } }) => {
    let { formFieldsFPO } = this.state;
    if (name == "state") {
      formFieldsFPO[name] = value;
      formFieldsFPO.district = "";
      formFieldsFPO.pincode = "";
      this.setState(
        {
          formFieldsFPO: { ...formFieldsFPO },
        },
        () => {
          this.props.getDistrictApiwithState({ id: value }).then((data) => {
            let districtData = [];

            data.map((list) => {
              districtData.push({
                value: list.districtId,
                label: list.districtName,
              });
            });
            this.setState({
              districtOptions: [...districtData],
            });
          });
        }
      );
    } else if (name === "district") {
      formFieldsFPO[name] = value;
      formFieldsFPO.pincode = "";
      this.setState(
        {
          formFieldsFPO: { ...formFieldsFPO },
        },
        () => {
          this.props
            .getPincodeApiwithStateDistrict({ id: value })
            .then((data) => {
              let pinocodeData = [];

              data.map((list) => {
                pinocodeData.push({
                  value: list.pincodeId,
                  label: list.pincode,
                });
              });
              this.setState({
                pincodeOptions: [...pinocodeData],
              });
            });
        }
      );
    } else if (name === "emailId") {
      formFieldsFPO[name] = value;
      this.setState(
        {
          emailField: true,
          numberField: false,
          CINField: false,
          entityField: false,
          formFieldsFPO: { ...formFieldsFPO },
        },
        () => {
          if (value === "") {
            this.setState({
              emailValidate: false,
            });
          } else {
            this.validation(value);
          }
        }
      );
    } else if (name === "contact_number") {
      formFieldsFPO[name] = value;
      this.setState(
        {
          emailField: false,
          numberField: true,
          CINField: false,
          entityField: false,
          formFieldsFPO: { ...formFieldsFPO },
        },
        () => {
          if (value === "" || value?.length <= 9) {
            this.setState({
              numberValidate: false,
            });
          } else {
            this.validation(value);
          }
        }
      );
    } else if (name === "fpo_name") {
      formFieldsFPO[name] = value;
      this.setState(
        {
          emailField: false,
          numberField: false,
          CINField: false,
          entityField: true,
          formFieldsFPO: { ...formFieldsFPO },
        },
        () => {
          if (value?.length < 3) {
            this.setState({
              entityValidate: false,
            });
          } else {
            debounceFunction(() => {
              if (value) {
                this.validation(value);
              }
            }, 1000);
          }
        }
      );
    } else if (name === "entityId") {
      formFieldsFPO[name] = value;
      this.setState({
        CINValidate: false,
        formFieldsFPO: { ...formFieldsFPO, entity_value: "" },
      });
    } else if (name === "entity_value") {
      formFieldsFPO[name] = value;
      this.setState(
        {
          emailField: false,
          numberField: false,
          CINField: true,
          formFieldsFPO: { ...formFieldsFPO },
        },
        () => {
          if (value === "") {
            this.setState({
              CINValidate: false,
            });
          } else {
            this.validation(value);
          }
        }
      );
    } else if (name === "membership" || name === "femaleMembers") {
      formFieldsFPO[name] = value;
      this.setState({
        formFieldsFPO: {
          ...formFieldsFPO,
          maleMembers: (
            formFieldsFPO?.membership - formFieldsFPO?.femaleMembers
          ).toString(),
        },
      });
    } else {
      formFieldsFPO[name] = value;
      this.setState({
        formFieldsFPO: { ...formFieldsFPO },
      });
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();
    let { userType, formFieldsFPO } = this.state;
    if (userType === "fpoReg") {
      let {
        entityId,
        entity_value,
        societyType,
        fpo_name,
        contact_first_name,
        contact_last_name,
        contact_number,
        emailId,
        password,
        district,
        pincode,
        state,
        foundedOn,
        village,
        logoFileName,
        logoFile,
        teamLogoFileName,
        teamLogo,
        resourseInsitituteLogoFileName,
        resourseInsitituteLogoFile,
        resourseInstitution,
        promotingInstitution,
        insitituteLogoFileName,
        insitituteLogoFile,
        officialWebsite,
        facebookLink,
        instragamLink,
        twitterLink,
        acre,
        shareCapitals,
        femaleMembers,
        maleMembers,
        membership,
        villageCovered,
        selected_business_activties,
        selected_crops,
        selected_infrastructure,
        selected_license,
        cropMapping,
        turnOver,
        FyearId,
      } = formFieldsFPO;
      let { backToLogi } = this.props;

      let body = {
        entityId: entityId,
        companyCin: entityId === "2" ? entity_value : "",
        cooperative_society: entityId === "3" ? entity_value : "",
        trust_registration_number: entityId === "4" ? entity_value : "",
        societyType: entityId === "3" ? societyType : "",
        fpoName: fpo_name,
        firstName: contact_first_name,
        lastName: contact_last_name,
        mobileNumber: contact_number,
        emailId,
        password: encryptData(password),
        businessIds: selected_business_activties,
        cropIds: selected_crops,
        infrastructureIds: selected_infrastructure,
        licenseIds: selected_license,
        foundedOn: moment(foundedOn).format("YYYY-MM-DD"),
        stateId: parseInt(state),
        village,
        districtId: parseInt(district),
        // pincodeId: parseInt(pincode),
        pincode: pincode,
        membershipBase: membership,
        resourcingInstitution: resourseInstitution,
        cropMapping: cropMapping,
        isTCAccepted: 1
      };
      if (logoFile !== "") {
        body.fpoLogoURL = logoFile;
        body.fpoLogoName = logoFileName;
      }
      if (promotingInstitution != "") {
        body.promotingInstitution = promotingInstitution;
      }
      if (insitituteLogoFile !== "") {
        body.promotingInstitutionLogo = insitituteLogoFile;
        body.promotingInstitutionLogoName = insitituteLogoFileName;
      }
      if (resourseInsitituteLogoFile !== "") {
        body.ResourcingInstitutionLogoURL = resourseInsitituteLogoFile;
        body.ResourcingInstitutionLogoName = resourseInsitituteLogoFileName;
      }
      if (femaleMembers !== "") {
        body.femaleMembers = parseInt(femaleMembers);
      }
      if (maleMembers !== "") {
        body.maleMembers = parseInt(maleMembers);
      }
      if (villageCovered !== "") {
        body.villagesCovered = parseInt(villageCovered);
      }
      if (shareCapitals !== "") {
        body.shareCapital = parseInt(shareCapitals);
      }
      if (acre !== "") {
        body.totalAverage = parseInt(acre);
      }
      if (turnOver != "") {
        body.turnOver = parseInt(turnOver);
      }
      if (FyearId != "") {
        body.FyearId = FyearId;
      }
      if (teamLogo !== "") {
        body.teamPhotoName = teamLogoFileName;
        body.teamPhotoURL = teamLogo;
      }
      if (officialWebsite != "") {
        body.officialWebsiteLink = officialWebsite;
      }
      if (facebookLink != "") {
        body.facebookLink = facebookLink;
      }
      if (twitterLink != "") {
        body.twitterLink = twitterLink;
      }
      if (instragamLink != "") {
        body.instagramLink = instragamLink;
      }
      this.props.fpoReg(body).then(() => {
        backToLogi(true);
      });
    } else {
      if (this.validatorForRegUnderFpo.allValid()) {
        const { formFields } = this.state;

        let {
          userRoleId,
          fpoId,
          firstName,
          lastName,
          mobileNumber,
          emailId,
          passwordForUserUnderFPO,
          confirmPasswordForUserUnderFPO,
          fpoName,
        } = formFields;
        let { backToLogi } = this.props;
        if (passwordForUserUnderFPO === confirmPasswordForUserUnderFPO) {
          let body = {
            fpoId,
            firstName,
            lastName,
            userRoleId,
            mobileNumber,
            emailId,
            password: encryptData(passwordForUserUnderFPO),
            fpoName,
          };

          this.props.registrationApiUnderFpo(body).then(() => {
            backToLogi(true);
          });
        } else {
          let message = "Password does not match";
          Toast({ type: "error", message, time: 5000 });
        }
      } else {
        this.validatorForRegUnderFpo.showMessages();
        this.forceUpdate();
      }
    }
  };

  rmSubmit = () => {
    if (this.validationForRM.allValid()) {
      console.log("called!");
    } else {
      this.validationForRM.showMessages();
      this.forceUpdate();
    }
  };

  verification = (type) => {
    console.log("type", type);
    let { verifificationPopup } = this.state;
    this.setState({
      verificationType: type === "mobile" ? "Mobile" : "Email",
      verifificationPopup: true,
    });
  };

  toggle = (key) => {
    let { formFields } = this.state;
    formFields[key] = !formFields[key];
    this.setState({
      formFields: { ...formFields },
    });
  };

  //toggle pass
  togglepass = (key) => {
    let { formFieldsFPO } = this.state;
    formFieldsFPO[key] = !formFieldsFPO[key];
    this.setState({
      formFieldsFPO: { ...formFieldsFPO },
    });
  };

  toggleRMpass = (key) => {
    let { formFieldsRM } = this.state;
    formFieldsRM[key] = !formFieldsRM[key];
    this.setState({
      formFieldsRM: { ...formFieldsRM },
    });
  };

  closePopup = () => {
    this.setState({
      verifificationPopup: false,
    });
  };

  //handle radio buttons
  handleRadioStatus = (e) => {
    this.setState(
      {
        userType: e.target.value,
      },
      () => {
        this.validatorForRegUnderFpo.hideMessages();
        let { userType } = this.state;
        if (userType === "regUnderFpo") {
          this.getDropdownDetails();
        }
      }
    );
  };

  //get Dropdown Details
  getDropdownDetails = () => {
    this.props.fpoRoles().then((data) => {
      this.setState({
        roles: data,
      });
    });
    this.props.fpoList().then((data) => {
      let fpoListArray = [];
      data.map((list) => {
        fpoListArray.push({ label: list.fpoName, value: list.fpoId });
      });
      this.setState({
        fpoList: fpoListArray,
      });
    });
  };

  //upload logo
  uploadLogoFile = (e) => {
    let file = e.target.files[0];
    let { formFieldsFPO } = this.state;
    if (file !== undefined) {
      // document.getElementById("logoFileName").val("");
      formFieldsFPO.logoFile = "";
      formFieldsFPO.logoFileName = "";
      this.setState({ formFieldsFPO: { ...formFieldsFPO } }, () => {
        formFieldsFPO.logoFileName = "Uploading ....";
        formFieldsFPO.logoFile = URL.createObjectURL(file);

        const formData = new FormData();
        formData.append("file", file);
        formFieldsFPO.islogoFileUploading = true;
        this.setState({ formFieldsFPO: { ...formFieldsFPO } });

        this.props
          .fileUpload(formData)
          .then((data) => {
            formFieldsFPO.logoFile = data[0].fileUrl;
            formFieldsFPO.logoFileName = data[0].fileName;
            formFieldsFPO.islogoFileUploading = false;
            document.getElementById("uploadLogoFile").value = null;
            this.setState({
              formFieldsFPO: { ...formFieldsFPO },
            });
          })
          .catch(() => {
            formFieldsFPO.logoFile = "";
            formFieldsFPO.logoFileName = "";
            formFieldsFPO.islogoFileUploading = false;
            this.setState({
              formFieldsFPO: { ...formFieldsFPO },
            });
          });
      });
    }
  };

  //upload team logo file
  uploadTeamLogoFile = (e) => {
    let file = e.target.files[0];
    if (file !== undefined) {
      let { formFieldsFPO } = this.state;
      formFieldsFPO.teamLogo = "";
      this.setState({ formFieldsFPO }, () => {
        formFieldsFPO.teamLogoFileName = "Uploading ....";
        formFieldsFPO.teamLogo = file;

        const formData = new FormData();
        formData.append("file", file);
        formFieldsFPO.isTeamLogoFileuploading = true;
        this.setState({ formFieldsFPO: { ...formFieldsFPO } });

        this.props
          .fileUpload(formData)
          .then((data) => {
            formFieldsFPO.teamLogo = data[0].fileUrl;
            formFieldsFPO.teamLogoFileName = data[0].fileName;
            formFieldsFPO.isTeamLogoFileuploading = false;
            document.getElementById("uploadTeamLogoFile").value = null;
            this.setState({
              formFieldsFPO: { ...formFieldsFPO },
            });
          })
          .catch(() => {
            formFieldsFPO.teamLogo = "";
            formFieldsFPO.teamLogoFileName = "";
            formFieldsFPO.isTeamLogoFileuploading = false;
            this.setState({
              formFieldsFPO: { ...formFieldsFPO },
            });
          });
      });
    }
  };

  //upload resourse institution
  uploadResourseInstitution = (e) => {
    let file = e.target.files[0];
    if (file !== undefined) {
      let { formFieldsFPO } = this.state;
      formFieldsFPO.resourseInsitituteLogoFile = "";
      this.setState({ formFieldsFPO }, () => {
        formFieldsFPO.resourseInsitituteLogoFileName = "Uploading ....";
        formFieldsFPO.resourseInsitituteLogoFile = file;

        const formData = new FormData();
        formData.append("file", file);
        formFieldsFPO.isresourseInsitituteLogoFileUploading = true;
        this.setState({ formFieldsFPO: { ...formFieldsFPO } });

        this.props
          .fileUpload(formData)
          .then((data) => {
            formFieldsFPO.resourseInsitituteLogoFile = data[0].fileUrl;
            formFieldsFPO.resourseInsitituteLogoFileName = data[0].fileName;
            formFieldsFPO.isresourseInsitituteLogoFileUploading = false;
            document.getElementById("uploadResourseInstitution").value = null;
            this.setState({
              formFieldsFPO: { ...formFieldsFPO },
            });
          })
          .catch(() => {
            formFieldsFPO.resourseInsitituteLogoFile = "";
            formFieldsFPO.resourseInsitituteLogoFileName = "";
            formFieldsFPO.isresourseInsitituteLogoFileUploading = false;
            this.setState({
              formFieldsFPO: { ...formFieldsFPO },
            });
          });
      });
    }
  };

  //upload Institution File
  uploadInstitutionFile = (e) => {
    let file = e.target.files[0];
    if (file !== undefined) {
      let { formFieldsFPO } = this.state;
      formFieldsFPO.insitituteLogoFile = "";
      this.setState({ formFieldsFPO }, () => {
        formFieldsFPO.insitituteLogoFileName = "Uploading ....";
        formFieldsFPO.insitituteLogoFile = file;

        const formData = new FormData();
        formData.append("file", file);
        formFieldsFPO.isinstitutelogoFileUploading = true;
        this.setState({ formFieldsFPO: { ...formFieldsFPO } });

        this.props
          .fileUpload(formData)
          .then((data) => {
            formFieldsFPO.insitituteLogoFile = data[0].fileUrl;
            formFieldsFPO.insitituteLogoFileName = data[0].fileName;
            formFieldsFPO.isinstitutelogoFileUploading = false;
            document.getElementById("uploadInstitutionFile").value = null;
            this.setState({
              formFieldsFPO: { ...formFieldsFPO },
            });
          })
          .catch(() => {
            formFieldsFPO.insitituteLogoFile = "";
            formFieldsFPO.insitituteLogoFileName = "";
            formFieldsFPO.isinstitutelogoFileUploading = false;
            this.setState({
              formFieldsFPO: { ...formFieldsFPO },
            });
          });
      });
    }
  };

  //handle fpo dropdown values
  handleInput = (newValue) => {
    let { formFields } = this.state;
    formFields.fpoId = newValue.value;
    this.setState(
      {
        formFields,
      },
      () => {
        let { formFields, fpoList } = this.state;
        fpoList.map((list) => {
          if (list.value == newValue.value) {
            formFields.fpoName = list.label;
          }
        });
        this.setState({
          formFields: { ...formFields },
        });
      }
    );
  };

  handleRMInput = ({ target: { name, value } }) => {
    let { formFieldsRM } = this.state;
    formFieldsRM[name] = value;
    this.setState({
      formFieldsFPO: { ...formFieldsRM },
    });
  };

  handleOtp = () => {
    let { otp } = this.state;
    if (otp?.length < 6) {
      let message = "Please enter valid OTP";
      Toast({ type: "error", message, time: 5000 });
      return null;
    }
    let { verificationType } = this.state;
    if (verificationType === "Mobile") {
      this.setState({ mobileVerified: true });
    } else {
      this.setState({ emailVerified: true });
    }
    this.setState({
      otpSuccess: true,
    });
  };

  handleOTPChange = (otp) => {
    this.setState({
      otp,
    });
  };

  //handle fpo page change
  handlePageChange = (pageName) => {
    this.setState(
      {
        currPage: pageName,
      },
      () => {
        if (pageName === "step3") {
          this._getcropBusinessInfra();
        }
      }
    );
  };
  //get crop business list
  _getcropBusinessInfra = () => {
    this.props.getcropBusinessInfra().then((data) => {
      let { formFieldsFPO } = this.state;
      formFieldsFPO.selected_business_activties = [];
      formFieldsFPO.selected_crops = [];
      formFieldsFPO.selected_infrastructure = [];
      formFieldsFPO.selected_license = [];
      this.setState({
        business_activties: data.businessList,
        crops_list: data.cropsList,
        infrastructure_list: data.infrastructure,
        license_list: data.licensedropdown,
        formFieldsFPO: { ...formFieldsFPO },
      });
    });
  };

  //handle remove image
  handleRemoveImage = (picName) => {
    let { formFieldsFPO } = this.state;
    if (picName === "logoFile") {
      formFieldsFPO.logoFileName = "";
      formFieldsFPO.logoFile = "";
    } else if (picName === "teamlogoFile") {
      formFieldsFPO.teamLogoFileName = "";
      formFieldsFPO.teamLogo = "";
    } else if (picName === "resourseInsititute") {
      formFieldsFPO.resourseInsitituteLogoFile = "";
      formFieldsFPO.resourseInsitituteLogoFileName = "";
    } else if (picName === "insitituteLogoFile") {
      formFieldsFPO.insitituteLogoFile = "";
      formFieldsFPO.insitituteLogoFileName = "";
    }
    this.setState({
      formFieldsFPO: { ...formFieldsFPO },
    });
  };

  //handle business activities
  handlebusinessActivties = (crop) => {
    let { formFieldsFPO } = this.state;
    if (
      !formFieldsFPO.selected_business_activties.find((r_crop) => {
        return r_crop === crop;
      })
    ) {
      formFieldsFPO.selected_business_activties.push(crop);
      this.setState({ formFieldsFPO: { ...formFieldsFPO } });
    } else {
      let index = formFieldsFPO.selected_business_activties.findIndex(
        (r_crop) => {
          return r_crop === crop;
        }
      );
      if (index !== -1) {
        formFieldsFPO.selected_business_activties.splice(index, 1);
        this.setState({
          formFieldsFPO: { ...formFieldsFPO },
        });
      }
    }
  };

  //handle crops
  handlecrops = (crop) => {
    let { formFieldsFPO } = this.state;
    if (
      !formFieldsFPO.selected_crops.find((r_crop) => {
        return r_crop === crop;
      })
    ) {
      formFieldsFPO.selected_crops.push(crop);
      formFieldsFPO.cropMapping.push({
        cropId: crop,
        varietyOne: "",
        varietyTwo: "",
        varietyThree: "",
      });
      this.setState({ formFieldsFPO: { ...formFieldsFPO } });
    } else {
      let index = formFieldsFPO.selected_crops.findIndex((r_crop) => {
        return r_crop === crop;
      });
      if (index !== -1) {
        formFieldsFPO.selected_crops.splice(index, 1);
        formFieldsFPO.cropMapping.splice(index, 1);
        this.setState({
          formFieldsFPO: { ...formFieldsFPO },
        });
      }
    }
  };

  //handle infrasture
  handleInfrasture = (crop) => {
    let { formFieldsFPO } = this.state;
    if (
      !formFieldsFPO.selected_infrastructure.find((r_crop) => {
        return r_crop === crop;
      })
    ) {
      formFieldsFPO.selected_infrastructure.push(crop);
      this.setState({ formFieldsFPO: { ...formFieldsFPO } });
    } else {
      let index = formFieldsFPO.selected_infrastructure.findIndex((r_crop) => {
        return r_crop === crop;
      });
      if (index !== -1) {
        formFieldsFPO.selected_infrastructure.splice(index, 1);
        this.setState({
          formFieldsFPO: { ...formFieldsFPO },
        });
      }
    }
  };

  //handle license
  handleLicense = (crop) => {
    let { formFieldsFPO } = this.state;
    if (
      !formFieldsFPO.selected_license.find((r_crop) => {
        return r_crop === crop;
      })
    ) {
      formFieldsFPO.selected_license.push(crop);
      this.setState({ formFieldsFPO: { ...formFieldsFPO } });
    } else {
      let index = formFieldsFPO.selected_license.findIndex((r_crop) => {
        return r_crop === crop;
      });
      if (index !== -1) {
        formFieldsFPO.selected_license.splice(index, 1);
        this.setState({
          formFieldsFPO: { ...formFieldsFPO },
        });
      }
    }
  };

  handleCropVariety = (cropId, e) => {
    const { value, name } = e.target;
    const { formFieldsFPO } = this.state;
    let index = formFieldsFPO.cropMapping.findIndex((list) => {
      return list.cropId == cropId;
    });
    formFieldsFPO.cropMapping[index][name] = value;
    this.setState({
      formFieldsFPO: { ...formFieldsFPO },
    });
  };

  render() {
    const {
      roles,
      userType,
      formFields,
      fpoList,
      formFieldsFPO,
      formFieldsRM,
      currPage,
      business_activties,
      crops_list,
      infrastructure_list,
      stateList,
      entityList,
      districtOptions,
      license_list,
      pincodeOptions,
      societyTypeOptions,
      verifificationPopup,
      otp,
      verificationType,
      otpSuccess,
      mobileVerified,
      emailVerified,
    } = this.state;

    let {
      userRoleId,
      fpoId,
      firstName,
      lastName,
      mobileNumber,
      emailId,
      passwordForUserUnderFPO,
      confirmPasswordForUserUnderFPO,
      passwordVisibleUnderFPO,
      reEnterPasswordVisibleUnderFPO,
    } = formFields;

    let {
      rmName,
      rmID,
      rmMobile,
      rmEmail,
      rmPassword,
      rmConfirmPassword,
      rmPasswordVisible,
      rmConfirmPasswordVisible,
    } = formFieldsRM;

    const DropdownIndicator = (props) => {
      return (
        components.DropdownIndicator && (
          <components.DropdownIndicator {...props}>
            <div className="d-flex flex-column">
              <span className={`icon-down fs-8`}></span>
            </div>
          </components.DropdownIndicator>
        )
      );
    };

    return (
      <div className="d-flex flex-row mb-2 flex-column registration-area mt-1">
        <div className="d-flex align-items-center justify-content-center py-1">
          <img className="register-logo mr-mc-3 mr-2" src={fpo} alt="fpo" />
          <p className="fs-24 line-height-29 text-black fw-500 mb-0">
            {userType === "fpoReg" ? "FPO" : "Samunnati RM"}
            {" Registration"}
          </p>
        </div>
        {/* <div className="row mb-2">
          <div className=" d-flex justify-content-center w-100 mb-2">
            <div className="custom-control custom-radio d-flex align-items-center">
              <input
                type="radio"
                id="fpoReg"
                name="userType"
                value="fpoReg"
                checked={userType === "fpoReg"}
                onChange={this.handleRadioStatus}
              />
              <label className="font-weight-bold pl-1 mb-0" htmlFor="fpoReg">
                {"FPO registration"}
              </label>
            </div>
            <div className="custom-control custom-radio d-flex align-items-center">
              <input
                type="radio"
                id="regUnderFpo"
                name="userType"
                value="regUnderFpo"
                checked={userType === "regUnderFpo"}
                onChange={this.handleRadioStatus}
              />
              <label
                className="font-weight-bold pl-1 mb-0"
                htmlFor="regUnderFpo"
              >
                {"Registration under FPO"}
              </label>
            </div>
          </div>
        </div> */}
        <div className="row my-3">
          <div className=" d-flex justify-content-center w-100 mb-2">
            <div className="custom-control custom-radio d-flex align-items-center">
              <input
                type="radio"
                id="fpoReg"
                name="userType"
                value="fpoReg"
                checked={userType === "fpoReg"}
                onChange={this.handleRadioStatus}
              />
              <label className="font-weight-bold pl-1 mb-0" htmlFor="fpoReg">
                {"FPO"}
              </label>
            </div>
            <div className="custom-control custom-radio d-flex align-items-center">
              <input
                type="radio"
                id="rmReg"
                name="userType"
                value="rmReg"
                checked={userType === "rmReg"}
                onChange={this.handleRadioStatus}
              />
              <label className="font-weight-bold pl-1 mb-0" htmlFor="rmReg">
                {"Samunnati RM"}
              </label>
            </div>
          </div>
        </div>
        {userType === "fpoReg" ? (
          <>
            {currPage === "step1" ? (
              <FpoRegStepOne
                formFieldsFPO={formFieldsFPO}
                rolesList={roles}
                handlePageChange={this.handlePageChange}
                handleChange={this.handleChange}
                togglepass={this.togglepass}
                stateList={stateList}
                entityList={entityList}
                districtOptions={districtOptions}
                pincodeOptions={pincodeOptions}
                emailField={this.state.emailField}
                numberField={this.state.numberField}
                CINField={this.state.CINField}
                entityField={this.state.entityField}
                emailValidate={this.state.emailValidate}
                numberValidate={this.state.numberValidate}
                CINValidate={this.state.CINValidate}
                entityValidate={this.state.entityValidate}
                societyTypeOptions={societyTypeOptions}
              />
            ) : currPage === "step2" ? (
              <FpoRegStepTwo
                formFieldsFPO={formFieldsFPO}
                handlePageChange={this.handlePageChange}
                handleChange={this.handleChange}
                uploadLogoFile={this.uploadLogoFile}
                handleRemoveImage={this.handleRemoveImage}
                uploadTeamLogoFile={this.uploadTeamLogoFile}
                uploadResourseInstitution={this.uploadResourseInstitution}
                uploadInstitutionFile={this.uploadInstitutionFile}
              />
            ) : (
              <FpoRegStepThree
                formFieldsFPO={formFieldsFPO}
                handlePageChange={this.handlePageChange}
                business_activties={business_activties}
                handlebusinessActivties={this.handlebusinessActivties}
                crops_list={crops_list}
                handlecrops={this.handlecrops}
                infrastructure_list={infrastructure_list}
                handleInfrasture={this.handleInfrasture}
                license_list={license_list}
                handleCropVariety={this.handleCropVariety}
                handleLicense={this.handleLicense}
                handleSubmit={this.handleSubmit}
              />
            )}
          </>
        ) : userType === "rmReg" ? (
          // <div className="row mb-3">
          //   <div className="col-6 mb-3">
          //     <label>
          //       Name
          //       <span className="text-danger font-weight-bold">*</span>
          //     </label>
          //     <input
          //       type="text"
          //       className="form-control w-100"
          //       name="rmName"
          //       value={rmName}
          //       onChange={this.handleRMInput}
          //     />
          //     {this.validationForRM.message(
          //       "rmName",
          //       rmName,
          //       "required|alpha_num_space|min:3|max:75"
          //     )}
          //   </div>
          //   <div className="col-6 mb-3">
          //     <label>Employee ID</label>
          //     <input
          //       type="text"
          //       className="form-control w-100"
          //       name="rmID"
          //       value={rmID}
          //       onChange={this.handleRMInput}
          //     />
          //   </div>
          //   <div className="col-12">
          //     <div className="row">
          //       <div className="col-md-6 col-12 mb-3">
          //         <label>
          //           Mobile
          //           <span className="text-danger font-weight-bold">*</span>
          //         </label>
          //         <input
          //           type="text"
          //           className="form-control w-100"
          //           name="rmMobile"
          //           value={rmMobile}
          //           onChange={this.handleRMInput}
          //           maxLength={10}
          //         />
          //         {rmMobile?.length === 10 && (
          //           <span
          //             className="error-message text-gold fs-16 font-weight-bold cursor-pointer"
          //             onClick={() => {
          //               if (!mobileVerified) {
          //                 this.verification("mobile");
          //               }
          //             }}
          //           >
          //             {mobileVerified ? (
          //               "Mobile verified"
          //             ) : (
          //               <u>Verify Mobile</u>
          //             )}
          //           </span>
          //         )}
          //         {this.validationForRM.message(
          //           "rmMobile",
          //           rmMobile,
          //           "required|numeric|min:10|max:10"
          //         )}
          //       </div>
          //       <div className="col-md-6 col-12 mb-3">
          //         <label>
          //           Email ID
          //           <span className="text-danger font-weight-bold">*</span>
          //         </label>
          //         <input
          //           type="text"
          //           className="form-control w-100"
          //           name="rmEmail"
          //           value={rmEmail}
          //           onChange={this.handleRMInput}
          //         />
          //         {rmEmail.includes("@") && (
          //           <span
          //             className="error-message text-gold fs-16 font-weight-bold cursor-pointer"
          //             onClick={() => {
          //               if (!emailVerified) {
          //                 this.verification("email");
          //               }
          //             }}
          //           >
          //             {emailVerified ? "Email verified" : <u>Verify Email</u>}
          //           </span>
          //         )}
          //         {this.validationForRM.message(
          //           "rmEmail",
          //           rmEmail,
          //           "required|email"
          //         )}
          //       </div>
          //     </div>
          //   </div>
          //   <div className="col-12">
          //     <div className="row">
          //       <div className="col-md-6 col-12">
          //         <label>
          //           Password
          //           <span className="text-danger font-weight-bold">*</span>
          //         </label>
          //         <div className="input-group">
          //           <input
          //             type={rmConfirmPasswordVisible ? "text" : "password"}
          //             className="form-control w-100"
          //             name="rmPassword"
          //             value={rmPassword}
          //             onChange={this.handleRMInput}
          //             autoComplete={
          //               rmConfirmPasswordVisible ? "nope" : "new-password"
          //             }
          //           />
          //           <div className="input-group-addon right">
          //             <span
          //               onClick={() =>
          //                 this.toggleRMpass("rmConfirmPasswordVisible")
          //               }
          //               className={`cursor-pointer icon-${
          //                 rmConfirmPasswordVisible ? "eye" : "eye-hide"
          //               } fs-24`}
          //             ></span>
          //           </div>
          //           {this.validationForRM.message(
          //             "rmPassword",
          //             rmPassword,
          //             "required|min:7|max:15|password"
          //           )}
          //           <PasswordStrengthMeter data={rmPassword} />
          //         </div>
          //       </div>
          //       <div className="col-md-6 col-12">
          //         <label>
          //           Confirm password
          //           <span className="text-danger font-weight-bold">*</span>
          //         </label>
          //         <div className="input-group">
          //           <input
          //             type={rmPasswordVisible ? "text" : "password"}
          //             className="form-control w-100"
          //             name="rmConfirmPassword"
          //             value={rmConfirmPassword}
          //             onChange={this.handleRMInput}
          //             autoComplete={rmPasswordVisible ? "nope" : "new-password"}
          //           />
          //           <div className="input-group-addon right">
          //             <span
          //               onClick={() => this.toggleRMpass("rmPasswordVisible")}
          //               className={`cursor-pointer icon-${
          //                 rmPasswordVisible ? "eye" : "eye-hide"
          //               } fs-24`}
          //             ></span>
          //           </div>
          //           {this.validationForRM.message(
          //             "confirm password",
          //             rmConfirmPassword,
          //             `required|checkPassword:${rmPassword}`
          //           )}
          //         </div>
          //       </div>
          //     </div>
          //     {rmPassword && (
          //       <div className="col-md-12">
          //         <b className="fs-14">
          //           Only the following special characters are allowed :
          //           #$^+=!*()@%&amp;
          //         </b>
          //       </div>
          //     )}
          //   </div>
          // </div>
          <div className="text-center my-md-4 cursor-pointer">
            <img
              src={msIcon}
              alt="msIcon"
              onClick={() =>
                window.open(
                  `https://login.microsoftonline.com/${process.env.REACT_APP_MSID_PREPROD}/oauth2/v2.0/authorize?client_id=${process.env.REACT_APP_MSCLIENTID_PREPROD}&response_type=code&redirect_uri=https://fpogateway.com/adSsoToken&response_mode=query&scope=https://graph.microsoft.com/User.Read&state=1234`,
                  "_blank"
                )
              }
            />
          </div>
        ) : (
          <>
            <div className="row mb-3">
              <div className="col-12">
                <label>
                  {"FPO name "}
                  <span className="text-danger font-weight-bold">*</span>
                </label>
                <Select
                  classNamePrefix="Select"
                  name={fpoId}
                  options={fpoList}
                  onChange={this.handleInput}
                  placeholder={"Select FPO"}
                  styles={customStyles}
                  isSearchable={true}
                  value={
                    fpoList.length > 0
                      ? fpoList.find((data) => data.value == fpoId)
                      : null
                  }
                  components={{ DropdownIndicator }}
                />
                {this.validatorForRegUnderFpo.message("fpo", fpoId, "required")}
              </div>
            </div>
            <label>
              {"Contact Person name "}
              <span className="text-danger font-weight-bold">*</span>
            </label>
            <div className="row my-2">
              <div className="col-6">
                <input
                  type="text"
                  className="form-control w-100"
                  placeholder="First name"
                  name="firstName"
                  value={firstName}
                  autoComplete="off"
                  onChange={(e) => {
                    this.handleInputChange(e);
                  }}
                />
                {this.validatorForRegUnderFpo.message(
                  "firstName",
                  firstName,
                  "required|alpha_space|min:3|max:30"
                )}
              </div>
              <div className="col-6">
                <input
                  type="text"
                  className="form-control w-100"
                  placeholder="Last name"
                  name="lastName"
                  value={lastName}
                  onChange={(e) => {
                    this.handleInputChange(e);
                  }}
                />
                {this.validatorForRegUnderFpo.message(
                  "lastName",
                  lastName,
                  "required|alpha_space|min:1|max:20"
                )}
              </div>
            </div>
            <div className="row my-2">
              <div className="col-6">
                <label>
                  {"Job title"}
                  <span className="text-danger font-weight-bold">*</span>
                </label>
                <select
                  className="custom-select form-control"
                  name="userRoleId"
                  value={userRoleId}
                  onChange={this.handleInputChange}
                >
                  <option value="" disabled defaultValue>
                    {"Select"}
                  </option>
                  {roles.map((role, index) => {
                    return (
                      <option
                        key={index}
                        name={role.userRoleName}
                        value={role.userRoleId}
                      >
                        {role.userRoleName}
                      </option>
                    );
                  })}
                </select>
                {this.validatorForRegUnderFpo.message(
                  "userRoleId",
                  userRoleId,
                  "required"
                )}
              </div>
              <div className="col-6">
                <label>
                  {"Contact number "}
                  <span className="text-danger font-weight-bold">*</span>
                </label>
                <input
                  type="text"
                  className="form-control w-100"
                  name="mobileNumber"
                  value={mobileNumber}
                  onChange={this.handleInputChange}
                  maxLength={10}
                />
                {this.validatorForRegUnderFpo.message(
                  "mobileNumber",
                  mobileNumber,
                  "required|phone"
                )}
              </div>
            </div>
            <div className="row my-2">
              <div className="col-12">
                <label>
                  {"Email id "}
                  <span className="text-danger font-weight-bold">*</span>
                </label>
                <input
                  type="text"
                  className="form-control w-100"
                  name="emailId"
                  value={emailId}
                  onChange={this.handleInputChange}
                />
                {this.validatorForRegUnderFpo.message(
                  "emailId",
                  emailId,
                  "required|email"
                )}
              </div>
            </div>
            <div className="row my-2">
              <div className="col-6">
                <label>
                  {"Password "}
                  <span className="text-danger font-weight-bold">*</span>
                </label>
                <div className="input-group">
                  <input
                    type={passwordVisibleUnderFPO ? "text" : "password"}
                    className="form-control w-100"
                    name="passwordForUserUnderFPO"
                    value={passwordForUserUnderFPO}
                    onChange={this.handleInputChange}
                  />
                  <div className="input-group-addon right">
                    <span
                      onClick={(e) => this.toggle("passwordVisibleUnderFPO")}
                      className={`cursor-pointer icon-${
                        passwordVisibleUnderFPO ? "eye" : "eye-hide"
                      } fs-24`}
                    ></span>
                  </div>
                  {this.validatorForRegUnderFpo.message(
                    "password",
                    passwordForUserUnderFPO,
                    "required|min:7|max:15|password"
                  )}
                  <PasswordStrengthMeter data={passwordForUserUnderFPO} />
                </div>
              </div>
              <div className="col-6">
                <label>
                  {"Confirm password "}
                  <span className="text-danger font-weight-bold">*</span>
                </label>
                <div className="input-group">
                  <input
                    type={reEnterPasswordVisibleUnderFPO ? "text" : "password"}
                    className="form-control w-100"
                    name="confirmPasswordForUserUnderFPO"
                    value={confirmPasswordForUserUnderFPO}
                    onChange={this.handleInputChange}
                  />
                  <div className="input-group-addon right">
                    <span
                      onClick={(e) =>
                        this.toggle("reEnterPasswordVisibleUnderFPO")
                      }
                      className={`cursor-pointer icon-${
                        reEnterPasswordVisibleUnderFPO ? "eye" : "eye-hide"
                      } fs-24`}
                    ></span>
                  </div>
                  {this.validatorForRegUnderFpo.message(
                    "confirm password",
                    confirmPasswordForUserUnderFPO,
                    `required|checkPassword:${passwordForUserUnderFPO}`
                  )}
                </div>
              </div>
            </div>
          </>
        )}
        {userType !== "fpoReg" && userType !== "rmReg" ? (
          <div className="d-flex justify-content-center mt-4">
            <input
              className="btn w-75 py-2 font-weight-bold"
              type="submit"
              value="Submit"
              onClick={userType === "rmReg" ? this.rmSubmit : this.handleSubmit}
            />
          </div>
        ) : null}
        {verifificationPopup && (
          <Modal
            isOpen={verifificationPopup}
            centered={true}
            className="ipl-modal"
          >
            <ModalBody>
              <div className="sell-popup">
                <i
                  className="fas fa-times"
                  onClick={() => {
                    this.closePopup();
                    this.setState({
                      otp: "",
                      otpSuccess: false,
                    });
                  }}
                ></i>
                {otpSuccess ? (
                  <div className="text-center mt-3">
                    <span className="mt-2 text-green fs-20">
                      {verificationType} has been verified successfully.
                    </span>
                  </div>
                ) : (
                  <div className="w-100 mt-3 d-flex align-items-center flex-column">
                    <h1>Verify {verificationType}</h1>
                    <div className="d-flex align-items-center mt-3">
                      <OtpInput
                        value={otp}
                        onChange={this.handleOTPChange}
                        numInputs={6}
                        isInputNum={true}
                        separator={<span>{"\xa0\xa0"}</span>}
                        inputStyle="otp"
                      />
                    </div>
                    <div className="d-flex justify-content-center mt-3">
                      <button
                        className="btn w-100 py-2 font-weight-bold mainbg-btn"
                        onClick={this.handleOtp}
                      >
                        Verify OTP
                      </button>
                    </div>
                    <div className="mt-2 text-center mt-3">
                      <label>
                        If you did not receive any OTP, please click
                      </label>
                      <span className="ml-1 text-dark font-weight-bold cursor-pointer">
                        <u>{"Resend OTP"}</u>
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </ModalBody>
          </Modal>
        )}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      fpoReg,
      fpoRoles,
      registrationApiUnderFpo,
      fpoList,
      fileUpload,
      getcropBusinessInfra,
      getDistrictApiwithState,
      allState,
      getPincodeApiwithStateDistrict,
      getSignUpEntityType,
      emailAndNumberValidation,
    },
    dispatch
  );
};
let component = Fpo;

export const FpoReg = connect(null, mapDispatchToProps)(component);
