import React, { forwardRef } from "react";

const RMSelect = forwardRef((props, ref) => {
  const {
    name,
    value,
    placeholder = "Select",
    className = "",
    onChange,
    option,
    disabled = false,
    label = "",
    isRequired = false,
  } = props;
  const requiredClass = "text-danger font-weight-bold";
  const selectClass = "custom-select form-control";

  return (
    <div className="d-flex flex-column mb-2">
      <label>
        {label}
        {isRequired && <span className={requiredClass}>*</span>}
      </label>
      <select
        className={`${selectClass} ${className}`}
        name={name}
        value={value}
        onChange={onChange}
        disabled={disabled}
        ref={ref}
        required={isRequired}
      >
        <option value="" disabled defaultValue>
          {placeholder}
        </option>
        {option.map((list, index) => (
          <option key={index} value={list.value}>
            {list.label}
          </option>
        ))}
      </select>
    </div>
  );
});

export default RMSelect;
