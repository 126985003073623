import React, { Component } from "react";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import Joyride from "react-joyride";
import { Modal, ModalBody } from "reactstrap";
import {
  currentTab,
  getStaffProfileCsv,
  getBodProfileCsv,
  getMemberProfileCsv,
  allState,
  roboCallingApi,
  getDownloadKYMMembersData,
} from "action/MemberAct";
import Overlay from "react-bootstrap/Overlay";
import Popover from "react-bootstrap/Popover";

import {
  getMemberProfileCsvofFPO,
  getStaffProfileCsvofFPO,
  getBodProfileCsvofFPO,
} from "action/RmAct";

import { getEducationDropdown } from "action/BodAct";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { history } from "service/helpers";

import filterIcon from "assets/images/icon/filter.svg";
import member from "assets/images/members/member.svg";
import bod from "assets/images/members/bod.svg";
import staff from "assets/images/members/staff.svg";
import downloadIcon from "assets/images/icon/download-icon.svg";

import { MemberList } from "../MemberList";
import { BodList } from "../BodList";
import { StaffList } from "../StaffList";
import { CRPList } from "../CRPList";
import { FIGList } from "../FIGList";

import { debounceFunction } from "service/debounce";

import "./MembersView.scss";
import { NormalButton } from "component/common";
import { SendSmsPopup } from "../index";
import moment from "moment";
import { RoboCallingPopup } from "../RoboCallingPopup";
import { RoboCall } from "../RoboCallingPopup/RoboCall";
import { getToolTipById, sendToolTipById } from "action/RoboCall";

export class MembersViewClass extends Component {
  state = {
    activeTab: "1",
    view: "1",
    limit: 10,
    searchValue: "",
    sendSms: false,
    roboCallPopUp: false,
    filteredName: "",
    roboCalling: false,
    roboCallingData: false,
    isApiSuccess: false,
    dropdownList: [
      { value: "Male", label: "Male" },
      { value: "Female", label: "Female" },
      { value: "Other", label: "Other" },
    ],
    stateOptions: [],
    educationQualificationOption: [],
    active: false,
    run: false,
    isSkip: false,
    isSMSSkip: false,
    tooltipContent: [
      {
        target: ".tooltip-one",
        content: "Add individual member profile details here",
        disableBeacon: true,
        isFixed: true,
      },
      {
        target: ".tooltip-two",
        content: "Bulk Upload the basic member information here",
        disableBeacon: true,
        isFixed: true,
      },
      {
        target: ".tooltip-three",
        content: "Upload the Member KYM all at once using template excel file",
        disableBeacon: true,
        isFixed: true,
      },
      {
        target: ".tooltip-four",
        content: "Send audio message to all your members all at once",
        disableBeacon: true,
        isFixed: true,
      },
      {
        target: ".tooltip-five",
        content: "Send SMS to all your members all at once",
        disableBeacon: true,
        isFixed: true,
      },
    ],
    smsRun: false,
    isMemberDownload: false,
    isKYMDownload: false,
    kymModal: false,
    kymMessgae: "",
    addMemberPopout: false,
    downloadMemberPopout: false,
    bulkUploadPopout: false,
    targetFirst: null,
    targetSecond: null,
    showFirst: false,
    showSecond: false,
    searchPage: "",
  };

  //Lifecycles
  componentDidMount() {
    let { activeTab, getEducationDropdown, allState } = this.props;
    this.setState({ activeTab: activeTab.toString() });
    allState().then((data) => {
      this.setState({
        stateOptions: data,
      });
    });
    getEducationDropdown().then((data) => {
      this.setState({
        educationQualificationOption: data,
      });
    });
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.kymModal && !prevState.kymModal) {
      this.setState({ showSecond: false });
    }
  }

  //toggle function (tab change)
  toggle = (tab) => {
    this.setState({ activeTab: tab }, () => {
      this.setState({
        searchValue: "",
        filteredName: "",
        filterType: "",
        filter: "",
        limit: 10,
        searchPage: "",
      });
      this.props.currentTab(tab);
    });
  };

  downloadCsv = () => {
    this.setState({ isMemberDownload: true });
    let { activeTab } = this.state;
    let { id } = this.props;
    if (id) {
      if (activeTab == 1) {
        this.props
          .getMemberProfileCsvofFPO(id)
          .then((data) => {
            this.downloadFunction(data, "member");
          })
          .catch(() => this.setState({ isMemberDownload: false }));
      } else if (activeTab == 2) {
        this.props
          .getBodProfileCsvofFPO(id)
          .then((data) => {
            this.downloadFunction(data, "bod");
          })
          .catch(() => this.setState({ isMemberDownload: false }));
      } else if (activeTab == 3) {
        this.props
          .getStaffProfileCsvofFPO(id)
          .then((data) => {
            this.downloadFunction(data, "staff");
          })
          .catch(() => this.setState({ isMemberDownload: false }));
      }
    } else {
      if (activeTab == 1) {
        this.props
          .getMemberProfileCsv()
          .then((data) => {
            this.downloadFunction(data, "member");
          })
          .catch(() => this.setState({ isMemberDownload: false }));
      } else if (activeTab == 2) {
        this.props
          .getBodProfileCsv()
          .then((data) => {
            this.downloadFunction(data, "bod");
          })
          .catch(() => this.setState({ isMemberDownload: false }));
      } else if (activeTab == 3) {
        this.props
          .getStaffProfileCsv()
          .then((data) => {
            this.downloadFunction(data, "staff");
          })
          .catch(() => this.setState({ isMemberDownload: false }));
      }
    }
  };

  downloadFunction(data, fileName) {
    var a = window.document.createElement("a");
    var blob = new Blob([data], {
      type: "text/csv;charset=utf-8;",
    });
    if (navigator.msSaveOrOpenBlob) {
      navigator.msSaveOrOpenBlob(
        blob,
        `${fileName} (${moment().format("DD/MM/YYYY hh:mm")}).csv`
      );
    } else {
      a.href = window.URL.createObjectURL(blob);
      // supply your own fileName here...
      a.download = `${fileName} (${moment().format("DD/MM/YYYY hh:mm")}).csv`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
    this.setState({ isMemberDownload: false });
  }

  //handle inputs
  handleInput = ({ target: { name, value } }) => {
    //Debounce Function
    debounceFunction(() => this.SearchFunction(value), 1000);
  };

  SearchFunction = (searchValue) => {
    //debounce function
    this.setState({ searchValue });
  };

  //handle inputs
  handlePageInput = ({ target: { value } }) => {
    //Debounce Function
    this.SearchPageFunction(value);
  };

  SearchPageFunction = (searchPage) => {
    //debounce function
    this.setState({ searchPage });
  };

  //open dropdown
  handleDropdown = () => {
    if (!this.state.active) {
      document.addEventListener("click", this.handleOutsideClick, false);
    } else {
      document.removeEventListener("click", this.handleOutsideClick, false);
    }
    this.setState((prevState) => ({
      active: !prevState.active,
    }));
  };

  //close popup when clicked outside
  handleOutsideClick = (e) => {
    if (this.node != null) {
      if (this.node.contains(e.target)) {
        return;
      }
    }
    this.handleDropdown();
  };

  handleClickOutside = (event) => {
    if (this.popupRef && !this.popupRef.contains(event.target)) {
      if (this.state.showFirst) {
        this.setState({
          showFirst: false,
        });
      } else {
        this.setState({
          showSecond: false,
        });
      }
    }
  };

  //send sms popup open
  toggleSms = (key) => {
    this.setState((prevState) => ({
      [key]: !prevState[key],
    }));
    this.props
      .getToolTipById(JSON.parse(localStorage.getItem("fpoId")))
      .then((data) => {
        let filterData = [];
        filterData = data?.filter((i) => Number(i?.toolTipId) === 4);
        setTimeout(() => {
          this.setState({
            smsRun: filterData?.length === 0 ? true : false,
          });
        }, 200);
      });
  };

  //toggle Popup
  togglePopup = (key) => {
    this.setState(
      (prevState) => ({
        [key]: !prevState[key],
        roboCallingData: "",
      }),
      () => {
        let { roboCalling } = this.state;
        // if (roboCalling) {
        //   this.setState({ isApiSuccess: true });
        //   this.props
        //     .roboCallingApi()
        //     .then((data) => {
        //       this.setState({ roboCallingData: data, isApiSuccess: false });
        //     })
        //     .catch(() => {
        //       this.setState({ isApiSuccess: false });
        //     });
        // }
      }
    );
  };

  //handle filter submit
  handleSubmit = (filterType, filteredName, filterId) => {
    if (filterType === "gender") {
      this.setState({ filteredName, filterType, filter: filteredName });
    } else if (filterType === "stateId" || filterType === "educationID") {
      this.setState({ filteredName, filterType, filter: filterId });
    }
  };

  //handle remove filter
  handleRemoveFilter = () => {
    this.setState({
      filteredName: "",
      filterBy: "",
      filter: "",
      filterType: "",
    });
  };

  toggleRoboCall = () => {
    const { roboCallPopUp } = this.state;
    this.setState({
      roboCallPopUp: !roboCallPopUp,
    });
  };

  //handle Route
  handleRoute = (tab) => {
    switch (tab.toString()) {
      case "1":
        history.push("/fpo/add-member");
        break;
      case "2":
        history.push("/fpo/add-bod");
        break;
      case "3":
        history.push("/fpo/add-staff");
        break;
      case "4":
        this.togglePopup("isAddCRP");
        break;
      case "5":
        this.togglePopup("isAddFIG");
        break;
      default:
        break;
    }
  };

  addButton = () => {
    let { activeTab } = this.state;
    switch (activeTab.toString()) {
      case "1":
        return "Add Member";
      case "2":
        return "Add BOD";
      case "3":
        return "Add Staff";
      case "4":
        return "Add CRP";
      case "5":
        return "Add FIG";
      default:
        return "Add";
    }
  };

  downloadKYMData = () => {
    this.setState({ isKYMDownload: true });
    this.props
      .getDownloadKYMMembersData()
      .then(({ data }) => {
        this.setState({
          kymMessgae: data,
          isKYMDownload: false,
          kymModal: true,
        });
      })
      .catch(() => this.setState({ isKYMDownload: false }));
  };

  handleButtonClick = (event, buttonIndex) => {
    if (buttonIndex === 1) {
      if (event.target === this.state.targetFirst) {
        this.setState({
          showFirst: false,
          targetFirst: null,
        });
        return null;
      }
      this.setState({
        showFirst: !this.state.showFirst,
        targetFirst: event.target,
      });
      document.body.classList.add("popup-overlay");
      this.props
        .getToolTipById(JSON.parse(localStorage.getItem("fpoId")))
        .then((data) => {
          let filterData = [];
          filterData = data?.filter((i) => Number(i?.toolTipId) === 5);
          setTimeout(() => {
            this.setState({
              run: filterData?.length === 0 ? true : false,
            });
          }, 200);
        });
    } else {
      this.setState({
        showFirst: false,
        targetFirst: event.target,
      });
    }
    if (buttonIndex === 2) {
      if (event.target === this.state.targetSecond) {
        this.setState({
          showSecond: false,
          targetSecond: null,
        });
        return null;
      }
      this.setState({
        showSecond: !this.state.showSecond,
        targetSecond: event.target,
      });
      document.body.classList.add("popup-overlay");
    } else {
      this.setState({
        showSecond: false,
        targetSecond: event.target,
      });
    }
  };

  kymToggle = () => {
    this.setState({ kymModal: !this.state.kymModal });
  };

  handleClick(event) {
    this.setState({
      show: !this.state.show,
      target: event.target,
    });
  }

  callback = (data) => {
    let { isSkip } = this.state;
    if (data?.action === "skip") {
      this.setState({ isSkip: true });
    }
    if (!isSkip) {
      if (data?.action === "reset") {
        let body = { key: "5" };
        this.props.sendToolTipById(
          JSON.parse(localStorage.getItem("fpoId")),
          body
        );
      }
    }
  };

  smsCallback = (data) => {
    let { isSMSSkip } = this.state;
    if (data?.action === "skip") {
      this.setState({ isSMSSkip: true });
    }
    if (!isSMSSkip) {
      if (data?.action === "reset") {
        let body = { key: "4" };
        this.props.sendToolTipById(
          JSON.parse(localStorage.getItem("fpoId")),
          body
        );
      }
    }
  };

  render() {
    let {
      activeTab,
      view,
      searchValue,
      limit,
      sendSms,
      filterType,
      filter,
      roboCallPopUp,
      run,
      tooltipContent,
      smsRun,
      isMemberDownload,
      isKYMDownload,
      kymModal,
      kymMessgae,
      searchPage,
    } = this.state;

    let {
      edit = false,
      sms = false,
      viewAccess = false,
      createAccess = false,
      match = {},
    } = this.props;

    console.log("searchPage", searchPage);

    let { path = "" } = match || {};

    return (
      <section className="member-section">
        <div className="container-sa">
          <Joyride
            steps={tooltipContent}
            run={run}
            callback={this.callback}
            continuous
            hideBackButton
            hideCloseButton
            scrollToFirstStep
            showProgress
            showSkipButton
            disableScrolling
            styles={{
              options: {
                zIndex: 10000,
              },
              buttonNext: {
                color: "#FFFFFF",
                backgroundColor: "#0a8c5b",
              },
            }}
          />
          <div className="filter-top">
            <div className="row align-items-center">
              <div className="col-md-5">
                <div className="scroll-x mb-md-0 mb-2">
                  <div style={{ minWidth: "max-content" }}>
                    <Nav tabs className="tab-sammunaty">
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: activeTab === "1",
                          })}
                          onClick={() => this.toggle("1")}
                        >
                          <img src={member} alt="member" /> Members
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: activeTab === "2",
                          })}
                          onClick={() => this.toggle("2")}
                        >
                          <img src={bod} alt="bod" /> BOD
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: activeTab === "3",
                          })}
                          onClick={() => this.toggle("3")}
                        >
                          <img src={staff} alt="staff" /> Staff
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </div>
                </div>
              </div>
              <div className="col-md-7">
                <ul className="right-filter-align align-items-lg-center menu">
                  {activeTab == 1 && createAccess ? (
                    <div className="d-flex justify-content-lg-end mt-md-0 mt-4">
                      <button
                        className="add-button p-3 mr-3"
                        onClick={(e) => this.handleButtonClick(e, 1)}
                      >
                        Add Member
                      </button>
                      <button
                        className="add-button p-3"
                        onClick={(e) => this.handleButtonClick(e, 2)}
                      >
                        Download Member
                      </button>
                      <div>
                        {this.state.showFirst ? (
                          <Overlay
                            trigger="hover"
                            show={this.state.showFirst}
                            target={this.state.targetFirst}
                            placement="bottom"
                          >
                            <Popover
                              id="popover-contained-1"
                              className="d-flex justify-content-lg-center"
                            >
                              <div
                                className="d-flex flex-row popover-contained-1"
                                ref={(ref) => (this.popupRef = ref)}
                              >
                                <div className="pr-3">
                                  <button
                                    className="add-button tooltip-one p-3"
                                    onClick={() => this.handleRoute(activeTab)}
                                  >
                                    Add Member Individually
                                  </button>
                                </div>
                                <div className="pr-3">
                                  <button
                                    className="add-button tooltip-two p-3"
                                    onClick={() =>
                                      history.push("/fpo/bulk-upload?type=fpo")
                                    }
                                  >
                                    Bulk Upload Basic Profile
                                  </button>
                                </div>
                                <div>
                                  <button
                                    className="add-button tooltip-three p-3"
                                    onClick={() => {
                                      history.push(`/fpo/bulk-upload?type=kym`);
                                    }}
                                  >
                                    Bulk Upload KYM
                                  </button>
                                </div>
                              </div>
                            </Popover>
                          </Overlay>
                        ) : (
                          <Overlay
                            show={this.state.showSecond}
                            target={this.state.targetSecond}
                            placement="bottom"
                          >
                            <Popover
                              id="popover-contained-2"
                              className="d-flex justify-content-lg-center"
                            >
                              <div
                                className="popover-contained-2"
                                ref={(ref) => (this.popupRef = ref)}
                              >
                                <div className="d-flex flex-row">
                                  <div className="pr-3">
                                    <button
                                      className="add-button px-4 p-3"
                                      onClick={() => this.downloadCsv()}
                                    >
                                      {isMemberDownload ? (
                                        "Downloading..."
                                      ) : (
                                        <>
                                          <img
                                            src={downloadIcon}
                                            className="mr-2"
                                            alt="download"
                                          />
                                          Download Basic Profile
                                        </>
                                      )}
                                    </button>
                                  </div>
                                  <div>
                                    <button
                                      className="add-button px-4 p-3"
                                      onClick={() => this.downloadKYMData()}
                                    >
                                      {isKYMDownload ? (
                                        "Downloading..."
                                      ) : (
                                        <>
                                          <img
                                            src={downloadIcon}
                                            className="mr-2"
                                            alt="download"
                                          />{" "}
                                          Download KYM
                                        </>
                                      )}
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </Popover>
                          </Overlay>
                        )}
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className="d-flex justify-content-end mt-md-0 mt-4">
                        {createAccess &&
                          !path.includes("fpo/report") &&
                          !path.includes("fpo/mapping-members") && (
                            <div className="ml-auto">
                              <button
                                className="add-button tooltip-two p-3"
                                onClick={() => this.handleRoute(activeTab)}
                              >
                                {this.addButton()}
                              </button>
                            </div>
                          )}
                      </div>
                      <li className="">
                        <p
                          id="csv-download"
                          className="add-button px-2 d-flex align-items-center justify-content-center text-decoration-none cursor-pointer text-green-18 mb-0 tooltip-five"
                          onClick={() => this.downloadCsv()}
                        >
                          {isMemberDownload ? (
                            "Downloading..."
                          ) : (
                            <>
                              <img
                                src={downloadIcon}
                                className="mr-2"
                                alt="download"
                              />
                              Download{" "}
                              {activeTab === "1"
                                ? "member"
                                : activeTab === "2"
                                ? "BOD"
                                : "staff"}{" "}
                              Profile
                            </>
                          )}
                        </p>
                      </li>
                    </>
                  )}
                  <li>
                    <span
                      className={`icon-list-view mr-3 fs-20 ${
                        view === "1" ? "view-active" : "view"
                      }`}
                      onClick={() => this.setState({ view: "1" })}
                    />
                    <span
                      className={`icon-grid-view fs-20 ${
                        view === "2" ? "view-active" : "view"
                      }`}
                      onClick={() => this.setState({ view: "2" })}
                    />
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-center pb-3">
            {localStorage.getItem("userGroupName") === "fpo" && (
              <NormalButton
                label="Robo Calling"
                mainbg={true}
                isPopup={true}
                className={`px-3 py-2 fs-14 line-height-26 tooltip-six`}
                onClick={() => this.toggleRoboCall()}
              />
            )}
            {sms && (
              <NormalButton
                label="Send SMS"
                mainbg={true}
                isPopup={true}
                className={`px-3 py-2 fs-14 line-height-26 ml-3 w-100 tooltip-seven`}
                onClick={() => this.toggleSms("sendSms")}
              />
            )}
          </div>
          <div className="search-limit">
            <div className="row align-items-center justify-content-end">
              <div>
                show
                <select
                  className="form-control-sa ml-2 py-2 px-4"
                  value={limit}
                  onChange={(e) =>
                    this.setState({ limit: parseInt(e.target.value) })
                  }
                >
                  <option value="10">10</option>
                  <option value="20">20</option>
                  {activeTab === "1" ? <option value="100">100</option> : null}
                </select>
              </div>
              <div className="col-3">
                <div className="ml-2">
                  <input
                    type-="number"
                    value={searchPage}
                    className="form-control form-control-sa"
                    onChange={(e) => this.handlePageInput(e)}
                    placeholder="Search by page number"
                  />
                </div>
              </div>
              <div className="col-lg-4 mt-md-0 mt-3">
                <input
                  type="text"
                  className="form-control form-control-sa"
                  placeholder="Search"
                  onChange={(e) => this.handleInput(e)}
                />
              </div>
            </div>
          </div>

          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <MemberList
                activeTab={activeTab}
                view={view}
                limit={limit}
                searchValue={searchValue}
                searchPage={searchPage}
                filterType={filterType}
                filter={filter}
                viewAccess={viewAccess}
                editAccess={edit}
                id={this.props.id}
              />
            </TabPane>
            <TabPane tabId="2">
              <BodList
                activeTab={activeTab}
                view={view}
                limit={limit}
                searchValue={searchValue}
                searchPage={searchPage}
                filterType={filterType}
                filter={filter}
                viewAccess={viewAccess}
                editAccess={edit}
                id={this.props.id}
              />
            </TabPane>
            <TabPane tabId="3">
              <StaffList
                activeTab={activeTab}
                view={view}
                limit={limit}
                searchValue={searchValue}
                searchPage={searchPage}
                filterType={filterType}
                filter={filter}
                viewAccess={viewAccess}
                editAccess={edit}
                id={this.props.id}
              />
            </TabPane>
            {/* <TabPane tabId="4">
              <CRPList
                activeTab={activeTab}
                limit={limit}
                view={view}
                searchValue={searchValue}
                editAccess={edit}
                id={this.props.id}
              />
            </TabPane>
            <TabPane tabId="5">
              <FIGList
                activeTab={activeTab}
                limit={limit}
                view={view}
                searchValue={searchValue}
                editAccess={edit}
                id={this.props.id}
              />
            </TabPane> */}
          </TabContent>
        </div>
        <SendSmsPopup
          isOpen={sendSms}
          toggle={() => this.toggleSms("sendSms")}
          activeTab={activeTab}
          smsAccess={sms}
          smsRun={smsRun}
          smsCallback={(data) => this.smsCallback(data)}
        />
        {/* <RoboCallingPopup
          isOpen={roboCalling}
          toggle={() => this.togglePopup("roboCalling")}
          roboCallingData={roboCallingData}
          isApiSuccess={isApiSuccess}
        /> */}
        <RoboCall
          isOpen={roboCallPopUp}
          toggle={() => this.toggleRoboCall()}
          activeTab={activeTab}
          smsAccess={sms}
        />
        <Modal
          isOpen={kymModal}
          toggle={this.kymToggle}
          className={"modal-dialog-centered send-message"}
          size={"lg"}
        >
          <div className="kym-dump-label mb-3">
            <h5>Information</h5>
            <i
              onClick={() => this.kymToggle()}
              className="icon-close cursor-pointer"
            />
          </div>
          <ModalBody>
            <div className="fs-20 text-center p-3">{kymMessgae}</div>
          </ModalBody>
        </Modal>
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  activeTab: state.home.tab,
  accessData: state.home.accessData,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      currentTab,
      getStaffProfileCsv,
      getBodProfileCsv,
      getMemberProfileCsv,
      allState,
      getEducationDropdown,
      getMemberProfileCsvofFPO,
      getStaffProfileCsvofFPO,
      getBodProfileCsvofFPO,
      roboCallingApi,
      getDownloadKYMMembersData,
      sendToolTipById,
      getToolTipById,
    },
    dispatch
  );
};
let component = MembersViewClass;

export const MembersView = connect(
  mapStateToProps,
  mapDispatchToProps
)(component);
