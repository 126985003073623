import React, { Component } from "react";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";

import { NormalButton } from "component/common";
import filter from "../../../assets/images/icon/filter.svg";
import downloadIcon from "../../../assets/images/icon/download-icon.svg";

import classnames from "classnames";
import { history } from "service/helpers";

import info from "../../../assets/svg/KeyFinancials/info.svg";
import credit_history from "../../../assets/svg/KeyFinancials/history.svg";
import revenue from "../../../assets/svg/KeyFinancials/revenue.svg";
import plan from "../../../assets/svg/KeyFinancials/plan.svg";

import { debounceFunction } from "service/debounce";

import { FinancialInfoList } from "../FinancialInfoList";
import { CreditHistoryList } from "../CreditHistoryList";
import { RevenueList } from "../RevenueList";
import { PlanList } from "../PlanList";
import { FinancialAnalysis } from "../FinancialAnalysis";
import { BankDetailList } from "../BankDetails";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { downloadKeyFinancialCsvForFpo } from "action/KeyFinancialAct";
import { downloadKeyFinancialCsv } from "action/RmAct";
import moment from "moment";
import { getAllFinancialYearDropdown } from "action/ComplianceAct";
import { getAccessdata } from "service/helperFunctions";
import { UserAuth } from "helpers/UserAuth";
class KeyFinancialViewClass extends Component {
  state = {
    activeTab: "1",
    view: "1",
    searchValue: "",
    csvUrl: "",
    search: "",
    dropdownList: [],
    filteredName: "",
    filterType: "",
    filterBy: "",
    active: false,
    tenurePeriod: [
      { value: "Year", label: "Year" },
      { value: "Month", label: "Month" },
      { value: "Days", label: "Days" },
    ],
  };

  //toggle function (tab change)
  toggle = (tab) => {
    this.setState({
      activeTab: tab,
      search: "",
      filteredName: "",
      filterType: "",
      filterBy: "",
      searchValue: "",
    });
  };
  // handle inputs
  handleInput = ({ target: { name, value } }) => {
    //Debounce Function
    this.setState({ search: value });
    debounceFunction(() => this.SearchFunction(value), 1000);
  };

  SearchFunction = (searchValue) => {
    //debounce function
    this.setState({ searchValue });
  };

  handleAdd = () => {
    switch (this.state.activeTab) {
      case "1":
        history.push("/fpo/add-financialinfo");
        break;
      case "2":
        history.push("/fpo/add-credithistory");
        break;
      case "3":
        history.push("/fpo/add-revenue");
        break;
      case "4":
        history.push("/fpo/add-plan");
        break;
      case "6":
        history.push("/fpo/add-bankDetails");
      default:
        break;
    }
  };

  //Lifecycles
  componentDidMount = () => {
    let { keyFinancialCurrentTab } = this.props;
    this.setState({ activeTab: keyFinancialCurrentTab.toString() });
    this.props.getAllFinancialYearDropdown().then((data) => {
      this.setState({
        dropdownList: data.financialYearMaster,
      });
    });
  };
  downloadCsv = () => {
    let { activeTab } = this.state;
    let { id, downloadKeyFinancialCsv, downloadKeyFinancialCsvForFpo } =
      this.props;
    if (id) {
      if (activeTab == 1) {
        downloadKeyFinancialCsv("financials", id).then((data) => {
          this.downloadFunction(data, "finacial summary");
        });
      } else if (activeTab == 2) {
        downloadKeyFinancialCsv("credits", id).then((data) => {
          this.downloadFunction(data, "credit history");
        });
      } else if (activeTab == 3) {
        downloadKeyFinancialCsv("revenue", id).then((data) => {
          this.downloadFunction(data, "business revenue");
        });
      }
    } else {
      if (activeTab == 1) {
        downloadKeyFinancialCsvForFpo("financials").then((data) => {
          this.downloadFunction(data, "finacial summary");
        });
      } else if (activeTab == 2) {
        downloadKeyFinancialCsvForFpo("credits").then((data) => {
          this.downloadFunction(data, "credit history");
        });
      } else if (activeTab == 3) {
        downloadKeyFinancialCsvForFpo("revenue").then((data) => {
          this.downloadFunction(data, "business revenue");
        });
      }
    }
  };

  downloadFunction(data, fileName) {
    var a = window.document.createElement("a");
    var blob = new Blob([data], {
      type: "text/csv;charset=utf-8;",
    });
    if (navigator.msSaveOrOpenBlob) {
      navigator.msSaveOrOpenBlob(
        blob,
        `${fileName} (${moment().format("DD/MM/YYYY hh:mm")}).csv`
      );
    } else {
      a.href = window.URL.createObjectURL(blob);
      // supply your own fileName here...
      a.download = `${fileName} (${moment().format("DD/MM/YYYY hh:mm")}).csv`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  }

  //open dropdown
  handleDropdown = () => {
    if (!this.state.active) {
      document.addEventListener("click", this.handleOutsideClick, false);
    } else {
      document.removeEventListener("click", this.handleOutsideClick, false);
    }
    this.setState((prevState) => ({
      active: !prevState.active,
    }));
  };

  //close popup when clicked outside
  handleOutsideClick = (e) => {
    if (this.node != null) {
      if (this.node.contains(e.target)) {
        return;
      }
    }
    this.handleDropdown();
  };

  //handle filter submit
  handleSubmit = (filterType, filterId, filteredName) => {
    if (filterType === "fymId") {
      this.setState({
        filteredName,
        filterType: filterId,
        filterBy: filterType,
      });
    } else if (filterType === "TenurePeriod") {
      this.setState({
        filteredName: filterId,
        filterType: filterId,
        filterBy: filterType,
      });
      // this.setState({ filteredName, filterType: filterId, filterBy: filterType });
    }
  };

  //handle remove filter
  handleRemoveFilter = () => {
    this.setState({ filteredName: "", filterBy: "", filterType: "" });
  };

  render() {
    let {
      activeTab,
      searchValue,
      view,
      search,
      dropdownList,
      filteredName,
      active,
      filterType,
      filterBy,
      tenurePeriod,
    } = this.state;
    let { accessData } = this.props;

    let permissionData = getAccessdata(accessData, "keyFinancials");

    let {
      download = false,
      edit = false,
      createAccess = false,
    } = permissionData;

    return (
      <>
        <section className="member-section bg-blue-f9 key-financial-view">
          <div className="container-sa">
            <div className="filter-top mb-3 ">
              {activeTab != 4 && activeTab != 5 && (
                <div className="compilance-filters my-4 container-sa">
                  <div className="row align-items-end">
                    <div className="col-12">
                      <ul className="right-filter-align menu">
                        {activeTab != "6" && (
                          <li
                            className="cursor-pointer filter-dropdown"
                            onClick={this.handleDropdown}
                          >
                            <img src={filter} alt="filter" />
                            <span className="fs-16 font-Gilroy ml-2">
                              Filter
                            </span>
                            <div
                              className={`dropdown ${active ? "active" : ""}`}
                            >
                              <ul className="h-100 w-100 d-flex flex-column flex-wrap">
                                {filteredName !== "" ? (
                                  <li
                                    className="cursor-pointer text-left"
                                    onClick={() => this.handleRemoveFilter()}
                                  >
                                    {"None"}
                                  </li>
                                ) : null}
                                {activeTab !== "2" && (
                                  <>
                                    <p>Financial year</p>
                                    {dropdownList.map((list, index) =>
                                      filteredName === list.fymYear ? (
                                        <li
                                          className="cursor-pointer active text-left"
                                          key={index}
                                        >
                                          {list.fymYear}
                                        </li>
                                      ) : (
                                        <li
                                          className="cursor-pointer text-left"
                                          key={index}
                                          onClick={() =>
                                            this.handleSubmit(
                                              "fymId",
                                              list.fymId,
                                              list.fymYear
                                            )
                                          }
                                        >
                                          {list.fymYear}
                                        </li>
                                      )
                                    )}
                                  </>
                                )}
                                {tenurePeriod.length !== 0 &&
                                  activeTab === "2" && (
                                    <>
                                      <p>Financial year</p>
                                      {tenurePeriod.map((list, index) =>
                                        filteredName === list.value ? (
                                          <li
                                            className="cursor-pointer active text-left"
                                            key={index}
                                          >
                                            {list.label}
                                          </li>
                                        ) : (
                                          <li
                                            className="cursor-pointer text-left"
                                            key={index}
                                            onClick={() =>
                                              this.handleSubmit(
                                                "TenurePeriod",
                                                list.label
                                              )
                                            }
                                          >
                                            {list.label}
                                          </li>
                                        )
                                      )}
                                    </>
                                  )}
                              </ul>
                            </div>
                          </li>
                        )}
                        {download && activeTab != "6" && (
                          <li className="pl-3">
                            <p
                              id="csv-download"
                              className="text-decoration-none cursor-pointer text-green-18 mb-0"
                              onClick={() => this.downloadCsv()}
                            >
                              <img
                                src={downloadIcon}
                                className="mr-2"
                                alt="downloadIcon"
                              />
                              {"Download list"}
                            </p>
                          </li>
                        )}
                        {createAccess && (
                          <div className=" pl-5">
                            <NormalButton
                              label="Add"
                              outline={true}
                              onClick={this.handleAdd}
                            />
                          </div>
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
              )}
              <div className="row align-items-center">
                <div className="col-md-12 d-flex justify-content-start">
                  <div className="scroll-x">
                    <Nav
                      tabs
                      className="tab-sammunaty"
                      style={{ width: "max-content" }}
                    >
                      <NavItem>
                        <NavLink
                          className={`${classnames({
                            active: activeTab === "1",
                          })} px-3`}
                          onClick={() => this.toggle("1")}
                        >
                          <img src={info} alt="info" /> Financial summary
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={`${classnames({
                            active: activeTab === "2",
                          })} px-3`}
                          onClick={() => this.toggle("2")}
                        >
                          <img src={credit_history} alt="credit_history" />{" "}
                          Credit history
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={`${classnames({
                            active: activeTab === "3",
                          })} px-3`}
                          onClick={() => this.toggle("3")}
                        >
                          <img src={revenue} alt="revenue" /> Business revenue
                        </NavLink>
                      </NavItem>
                      {/* <NavItem>
                      <NavLink
                        className={`${classnames({
                          active: activeTab === "4",
                        })} px-3`}
                        onClick={() => this.toggle("4")}
                      >
                        <img src={plan} alt="plan" />
                        Business plan
                      </NavLink>
                    </NavItem> */}
                      <NavItem>
                        <NavLink
                          className={`${classnames({
                            active: activeTab === "5",
                          })} px-3`}
                          onClick={() => this.toggle("5")}
                        >
                          <img src={plan} alt="plan" />
                          Financial Analysis
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={`${classnames({
                            active: activeTab === "6",
                          })} px-3`}
                          onClick={() => this.toggle("6")}
                        >
                          <img src={info} alt="info" /> Bank Details
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </div>
                  {/* {activeTab == 4 && createAccess && (
                    <span className="d-flex align-items-center ml-auto">
                      <div>
                        <NormalButton
                          className="py-2 px-5"
                          label="Add"
                          outline={true}
                          onClick={this.handleAdd}
                        />
                      </div>
                    </span>
                  )} */}
                </div>
              </div>
              <div className="container-sa bg-white pb-4 pt-1">
                {activeTab != 4 && activeTab != 5 && (
                  <div className="compilance-filters my-4">
                    <div className="row align-items-center">
                      <div className="col-sm-9 px-md-5">
                        <ul className="right-filter-align">
                          <li>
                            <span
                              className={`icon-list-view mr-3 pr-1 fs-20 cursor-pointer ${
                                this.state.view === "1"
                                  ? "view-active mr-3"
                                  : "view"
                              }`}
                              onClick={() => this.setState({ view: "1" })}
                            ></span>
                            <span
                              className={` icon-grid-view fs-20 cursor-pointer  ${
                                this.state.view === "2" ? "view-active" : "view"
                              }`}
                              onClick={() => this.setState({ view: "2" })}
                            ></span>
                          </li>
                        </ul>
                      </div>
                      <div className="col-sm-3">
                        <input
                          type-="text"
                          className="form-control form-control-sa"
                          placeholder="Search"
                          value={search}
                          onChange={(e) => this.handleInput(e)}
                        />
                      </div>
                    </div>
                  </div>
                )}
                <TabContent activeTab={activeTab}>
                  <TabPane tabId="1">
                    <FinancialInfoList
                      view={view}
                      activeTab={activeTab}
                      searchValue={searchValue}
                      filterBy={filterBy}
                      filterType={filterType}
                      edit={edit}
                      id={this.props.id}
                    />
                  </TabPane>
                  <TabPane tabId="2">
                    <CreditHistoryList
                      view={view}
                      activeTab={activeTab}
                      searchValue={searchValue}
                      filterBy={filterBy}
                      filterType={filterType}
                      edit={edit}
                      id={this.props.id}
                    />
                  </TabPane>
                  <TabPane tabId="3">
                    <RevenueList
                      view={view}
                      activeTab={activeTab}
                      searchValue={searchValue}
                      filterBy={filterBy}
                      filterType={filterType}
                      edit={edit}
                      id={this.props.id}
                    />
                  </TabPane>
                  <TabPane tabId="4">
                    <PlanList
                      view={view}
                      activeTab={activeTab}
                      edit={edit}
                      download={download}
                      id={this.props.id}
                    />
                  </TabPane>
                  <TabPane tabId="5">
                    <FinancialAnalysis
                      view={view}
                      activeTab={activeTab}
                      id={this.props.id}
                    />
                  </TabPane>
                  <TabPane tabId="6">
                    <BankDetailList
                      view={view}
                      edit={edit}
                      activeTab={activeTab}
                      searchValue={searchValue}
                      id={this.props.id}
                    />
                  </TabPane>
                </TabContent>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  keyFinancialCurrentTab: state.keyfinancial.tab,
  accessData: state.home.accessData,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getAllFinancialYearDropdown,
      downloadKeyFinancialCsv,
      downloadKeyFinancialCsvForFpo,
    },
    dispatch
  );
};
let component = UserAuth(KeyFinancialViewClass, { service: "keyFinancials" });

export const KeyFinancialView = connect(
  mapStateToProps,
  mapDispatchToProps
)(component);
