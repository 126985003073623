import React, { Component } from "react";
import { NormalInput, NormalButton } from "component/common";
import { Modal, ModalBody } from "reactstrap";
import SimpleReactValidator from "simple-react-validator";

// Redux Connection
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  addCRP,
  updateCRP,
  getCRPById,
  getCRPList,
  getCRPListRm,
} from "action/FigCrpAct";

export class AddCRPPopupClass extends Component {
  state = {
    name: "",
    emailId: "",
    mobileNumber: "",
    isButtonClicked: false,
  };

  //validation
  validator = new SimpleReactValidator({
    element: (message) => (
      <span className="error-message text-danger fs-14">{message}</span>
    ),
    autoForceUpdate: this,
  });

  componentDidUpdate(prevProps) {
    let { isOpen, id } = this.props;
    if (prevProps !== this.props && isOpen && id) {
      this.getCRPdataById();
    }
  }

  getCRPdataById = () => {
    let { id, getCRPByIdApi } = this.props;
    getCRPByIdApi(id).then((data) => {
      if (data) {
        this.setState({
          name: data.name,
          emailId: data.emailId,
          mobileNumber: data.mobileNumber,
        });
      }
    });
  };

  handleInput = ({ target: { name, value } }) => {
    this.setState({
      [name]: value,
    });
  };

  //close popup
  closepopup = () => {
    this._clearData();
    this.validator.hideMessages();
  };

  //clear Data
  _clearData = () => {
    this.setState({
      name: "",
      emailId: "",
      mobileNumber: "",
    });
    this.props.toggle();
  };

  //handle submit
  handleSubmit = () => {
    if (this.validator.allValid()) {
      let { name, mobileNumber, emailId } = this.state;
      let { id, addCRPApi, updateCRPApi } = this.props;
      let payload = {
        name,
        mobileNumber,
        emailId,
      };
      if (id) {
        payload.crpId = id;
        updateCRPApi(payload).then(() => {
          this.closepopup();
          this.handleListData();
        });
      } else {
        addCRPApi(payload).then(() => {
          this.closepopup();
          this.handleListData();
        });
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  handleListData = () => {
    let { getCRPListApi, getCRPListRmApi, fpoId } = this.props;
    let payload = { limit: 10, page: 1, search: "" };
    if (fpoId) {
      getCRPListRmApi(fpoId, payload);
    } else {
      getCRPListApi(payload);
    }
  };

  render() {
    let {
      className = "modal-dialog-centered add-crp-fig",
      isOpen = false,
      id,
    } = this.props;

    let { isButtonClicked, name, mobileNumber, emailId } = this.state;

    return (
      <Modal isOpen={isOpen} className={className} size="md">
        <ModalBody className="p-md-4">
          <div>
            <div className="modal-label mb-3">
              <h5>{id ? "Edit" : "Add"} CRP</h5>
              <i
                onClick={() => this.closepopup()}
                className="icon-close cursor-pointer"
              />
            </div>
            <div className="row">
              <div className="col-12">
                <NormalInput
                  required={true}
                  label="CRP name"
                  type="text"
                  onChange={this.handleInput}
                  value={name}
                  placeholder="Enter here"
                  name="name"
                />
                <div className="row">
                  <div className="col-9 ml-auto">
                    {this.validator.message(
                      "name",
                      name,
                      "required|alpha_num_space|max:50"
                    )}
                  </div>
                </div>
              </div>
              <div className="col-12">
                <NormalInput
                  required={false}
                  label="Email"
                  type="text"
                  onChange={this.handleInput}
                  value={emailId}
                  placeholder="Enter here"
                  name="emailId"
                />
                {/* <div className="row">
                  <div className="col-9 ml-auto">
                    {this.validator.message("email", emailId, "required|email")}
                  </div>
                </div> */}
              </div>
              <div className="col-12">
                <NormalInput
                  required={true}
                  label="Mobile number"
                  type="text"
                  onChange={this.handleInput}
                  value={mobileNumber}
                  placeholder="Enter here"
                  name="mobileNumber"
                />
                <div className="row">
                  <div className="col-9 ml-auto">
                    {this.validator.message(
                      "mobile number",
                      mobileNumber,
                      "required|phone|min:10|max:10"
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mx-auto py-5">
            <NormalButton
              label={id ? "Save" : "Submit"}
              mainbg={true}
              className="px-4 py-2 mx-auto fs-14 line-height-26"
              onClick={() => this.handleSubmit()}
              disabled={isButtonClicked}
            />
          </div>
        </ModalBody>
      </Modal>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      addCRPApi: addCRP,
      updateCRPApi: updateCRP,
      getCRPByIdApi: getCRPById,
      getCRPListApi: getCRPList,
      getCRPListRmApi: getCRPListRm,
    },
    dispatch
  );
};

export const AddCRPPopup = connect(null, mapDispatchToProps)(AddCRPPopupClass);
