import React, { Component } from "react";
import { Navbar } from "../../component/common";
import HarvestForm from "component/fpo/home/HarvestForm/index";
import { UserAuth } from "helpers/UserAuth";

class HarvestFormClass extends Component {
  render() {
    return (
      <div>
        <div className="inner-header">
          {/* <Navbar /> */}
          <div className="h-100 w-100">
            <p className="d-flex justify-content-center align-items-center h-100 w-100 text-white fs-32 font-weight-bold">
              Harvest Details
            </p>
          </div>
        </div>
        <HarvestForm />
      </div>
    );
  }
}

export const HarvestFormComponent = UserAuth(HarvestFormClass, {
  service: "membership",
});
