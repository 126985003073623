import React, { Component, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "./style.scss";
import {
    NormalSelect,
    NormalInput,
    NormalButton,
    NormalCheckbox,
    NormalRadio,
    NormalDate,
    NormalTextArea,

} from "component/common";


export class ListClass extends Component {

    //Lifecycles
    componentDidMount() {
    }
    
    render() {

        return (
            <div className="container-sa position-relative mt-4 createbuyer">
                {/* INFO SECTION */}
                <div className="add-form-section mb-15">
                    <div className="row">
                        <h2 className="m-0 fs-18 mt-0 font-weight-bold">Buyer Order Information</h2>
                    </div>
                </div>
                <div className="clearfix"></div>
                {/* INFO SECTION */}

                <div className="rht-frm buyerorder">

                    <div className="grid grid-cols-4 gap-x-4">
                        <div className='mb-15'>
                            <NormalInput
                                label="Delivery Location: "
                                type="text"
                                name="qualitySpecification"
                                onChange={this.handleInput}
                                value={""}
                                isHalfWidth={false}
                                isFullWidth={false}
                                required={true}
                            />
                        </div>
                        <div className='mb-15'>
                            <NormalInput
                                label="Commodity: "
                                type="text"
                                name="qualitySpecification"
                                onChange={this.handleInput}
                                value={""}
                                isHalfWidth={false}
                                isFullWidth={false}
                                required={true}
                            />
                        </div>

                        <div className='mb-15'>
                            <NormalInput
                                label="Variety: "
                                type="text"
                                name="qualitySpecification"
                                onChange={this.handleInput}
                                value={""}
                                isHalfWidth={false}
                                isFullWidth={false}
                                required={true}
                            />
                        </div>

                        <div className='mb-15'>

                        <NormalSelect
                            isHalfWidth={true}
                            label={"Unit OF Measurement: "}
                            placeholder="-None-"
                            value={''}
                            arrow={true}
                            name="state"
                            options={''}
                            handleChange={this.handleInput}
                            required={true}
                        />
                        </div>
                        <div className='mb-15'>
                            <NormalInput
                                label="Quantity: "
                                type="text"
                                name="qualitySpecification"
                                onChange={this.handleInput}
                                value={""}
                                isHalfWidth={false}
                                isFullWidth={false}
                                required={true}
                            />
                        </div>

                        <div className='mb-15'>
                            <NormalInput
                                label="Price(₹):"
                                type="text"
                                name="qualitySpecification"
                                onChange={this.handleInput}
                                value={""}
                                isHalfWidth={false}
                                isFullWidth={false}
                                required={true}
                            />
                        </div>
                        <div className='mb-15'>

                        <NormalSelect
                            isHalfWidth={true}
                            label={"Price Group:"}
                            placeholder="-None-"
                            value={''}
                            arrow={true}
                            name="state"
                            options={''}
                            handleChange={this.handleInput}
                            required={true}
                        />                            
                        </div>
                        <div className='mb-15'>

                            <NormalSelect
                                isHalfWidth={true}
                                label={"Currency: "}
                                placeholder="INR"
                                value={''}
                                arrow={true}
                                name="currency"
                                options={''}
                                handleChange={this.handleInput}
                                required={true}
                            />
                        </div>
                        <div className='mb-15'>

                            <NormalSelect
                                isHalfWidth={true}
                                label={"Buyer Order Owner: "}
                                placeholder="Samunnati Dhanasekaran"
                                value={''}
                                arrow={true}
                                name="currency"
                                options={''}
                                handleChange={this.handleInput}
                                required={true}
                            />
                        </div>
                        <div className='mb-15'>
                            <NormalInput
                                label="Account: "
                                type="text"
                                name="qualitySpecification"
                                onChange={this.handleInput}
                                value={""}
                                isHalfWidth={false}
                                isFullWidth={false}
                                required={true}
                            />
                        </div>
                        <div className='mb-15'>
                            <NormalInput
                                label="Contact: "
                                type="text"
                                name="qualitySpecification"
                                onChange={this.handleInput}
                                value={""}
                                isHalfWidth={false}
                                isFullWidth={false}
                                required={true}
                            />
                        </div>
                        <div className='mb-15'>
                            <NormalInput
                                label="Exchange Rate: "
                                type="text"
                                name="qualitySpecification"
                                onChange={this.handleInput}
                                value={""}
                                isHalfWidth={false}
                                isFullWidth={false}
                                required={true}
                            />
                        </div>
                        <div className='mb-15 col-span-2'>
                            <NormalTextArea
                                label="Demand Details: "
                                onChange={this.handleInput}
                                value={''}
                                required={true}
                                name="agendaHighlights"
                                isHalfWidthForMemberProfile={true}
                                rows="6"
                            />
                        </div>
                        <div className='mb-15 col-span-2'>
                            <NormalTextArea
                                label="Specifications: "
                                onChange={this.handleInput}
                                value={''}
                                required={true}
                                name="agendaHighlights"
                                isHalfWidthForMemberProfile={true}
                                rows="6"
                            />
                        </div>
                        <div className='mb-15'>
                            <NormalInput
                                label="Payment Terms: "
                                type="text"
                                name="qualitySpecification"
                                onChange={this.handleInput}
                                value={""}
                                isHalfWidth={false}
                                isFullWidth={false}
                                required={true}
                            />
                        </div>
                        <div className='mb-15'>

                            <NormalDate
                                isIcon={true}
                                onChange={this.handleInput}
                                name="dateAvailableFrom"
                                value={''}
                                placeholder="DD/MM/YYYY"
                                mindate={false}
                                maxdate={false}
                                timeInput={false}
                                dateAvailableFrom
                                label={"Demand From: "}
                                required={true}
                            />
                        </div>
                        <div className='mb-15'>
                            <NormalDate
                                isIcon={true}
                                onChange={this.handleInput}
                                name="dateAvailableFrom"
                                value={''}
                                placeholder="DD/MM/YYYY"
                                mindate={false}
                                maxdate={false}
                                timeInput={false}
                                dateAvailableFrom
                                label={"Demand Till: "}
                                required={true}
                            />
                        </div>
                    </div>

                    <hr class="mt-5" />
                    <NormalButton
                        label="Submit"
                        className="px-3 py-2 ml-auto mb-15 fs-14 line-height-26 mainbg-btn"
                    />
<div>&nbsp;</div>
                </div>
            </div>

        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {},
        dispatch
    );
};
let component = ListClass;

export const ListView = connect(
    null,
    mapDispatchToProps
)(component);
