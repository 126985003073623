import React, { Component } from "react";
import { Modal, ModalBody } from "reactstrap";

// Redux Connection
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { getCRPById, getFIGListforMapping } from "action/FigCrpAct";

export class CRPViewPopupClass extends Component {
  state = {
    name: "",
    memberIds: [],
    emailId: "",
    mobileNumber: "",
    memberMappings: [],
  };

  componentDidUpdate(prevProps) {
    let { isOpen, id } = this.props;
    if (prevProps !== this.props && isOpen && id) {
      this.getCRPdataById();
      // this._getFIGListforMapping(id);
    }
  }

  _getFIGListforMapping = (crpid) => {
    let { getFIGListforMappingApi } = this.props;
    getFIGListforMappingApi(crpid).then((data) => {
      if (data) {
        let options = [],
          tempArray = [];
        data.map((list) => {
          let { name, figId, isMapped } = list;
          options.push({ label: name, value: figId });
          if (isMapped) {
            tempArray.push({
              label: name,
            });
          }
        });
        if (options.length === data.length) {
          this.setState({
            memberIds: [...tempArray],
          });
        }
      }
    });
  };

  //get CRP data By Id
  getCRPdataById = () => {
    let { id, getCRPByIdApi } = this.props;
    getCRPByIdApi(id).then((data) => {
      if (data) {
        this.setState({
          name: data.name,
          emailId: data.emailId,
          mobileNumber: data.mobileNumber,
          memberMappings:
            data?.crpMappings.length > 0
              ? data.crpMappings.map(({ figUsers: { name } }) => {
                  return name;
                })
              : [],
        });
      }
    });
  };

  //close popup
  closepopup = () => {
    this.setState({
      name: "",
      memberIds: [],
      emailId: "",
      mobileNumber: "",
    });
    this.props.toggle();
  };

  render() {
    let { className = "modal-dialog-centered add-crp-fig", isOpen = false } =
      this.props;

    let { name, memberIds, emailId, mobileNumber, memberMappings } = this.state;

    return (
      <Modal isOpen={isOpen} className={className} size={"md"}>
        <ModalBody className="p-md-4">
          <div>
            <div className="modal-label mb-5">
              <h5>FIG to CRP mapping details</h5>
              <i
                onClick={() => this.closepopup()}
                className="icon-close cursor-pointer"
              />
            </div>
            <div className="row">
              <div className="col-4">
                <p className="text-black font-weight-bold fs-16 pr-2">
                  CRP name
                </p>
              </div>
              <div className="col-8">
                <p className="text-black fw-500 fs-16">{name ? name : "-"}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-4">
                <p className="text-black font-weight-bold fs-16 pr-2">Email</p>
              </div>
              <div className="col-8">
                <p className="text-black fw-500 fs-16">
                  {emailId ? emailId : "-"}
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-4">
                <p className="text-black font-weight-bold fs-16 pr-2">
                  Mobile number
                </p>
              </div>
              <div className="col-8">
                <p className="text-black fw-500 fs-16">
                  {mobileNumber ? mobileNumber : "-"}
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-4">
                <p className="text-black font-weight-bold fs-16 pr-2">
                  FIG's mapped
                </p>
              </div>
              <div className="col-8">
                <p className="text-black fw-500 fs-16 mb-1">
                  {/* {memberIds.length !== 0
                    ? memberIds
                        .reduce((acc, item) => {
                          return `${acc} , ${item.label}`;
                        }, "")
                        .slice(2)
                    : "-"} */}
                  {memberMappings.length > 0 ? memberMappings.join(", ") : "-"}
                </p>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getCRPByIdApi: getCRPById,
      getFIGListforMappingApi: getFIGListforMapping,
    },
    dispatch
  );
};

export const CRPViewPopup = connect(
  null,
  mapDispatchToProps
)(CRPViewPopupClass);
