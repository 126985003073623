import React, { Component, Fragment } from "react";
import "./style.scss";

import {
  NormalInput,
  NormalSelect,
  NormalDate,
  NormalButton,
  ConfirmationPopup,
} from "component/common";

import moment from "moment";

import SimpleReactValidator from "simple-react-validator";

import { history } from "service/helpers/index";

import {
  getAddressProofDropdown,
  getIdentityProofDropdown,
  getEducationDropdown,
  addStaff,
  updateStaff,
  getStaffById,
  deleteStaff,
} from "action/BodAct";

// Redux Connection
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { fileUpload, currentTab } from "action/MemberAct";

import { Toast } from "service/toast";
import { getAccessdata } from "service/helperFunctions";
import pdf from "assets/svg/KeyFinancials/pdf.svg";
const arrow_left = require("assets/svg/add_member/arrow_left.svg");

export class AddStaffClass extends Component {
  state = {
    first_name: "",
    last_name: "",
    gender: "",
    contact: "",
    emailId: "",
    designation: "",
    experiance: "",
    qualification: "",
    dateOfJoining: "",
    address_proof: "",
    dob: "",
    addressProofFile: "",
    profile_photo: "",
    profilePicName: "",
    address_proof_name: "",
    identity_proof: "",
    identity_proof_file: "",
    identity_proof_name: "",
    isProfilePicUPloading: false,
    isaddressProofUploading: false,
    isIdentityProofUploading: false,
    isResumUploading: false,
    resumeDocument: "",
    userMasterId: "",
    resumeDocumentName: "",
    educationQualificationOption: [],
    addressProofOption: [],
    genderOptions: [
      { value: "Male", label: "Male" },
      { value: "Female", label: "Female" },
      { value: "Other", label: "Other" },
    ],
    identityProofOption: [
      { value: "Aadhar Card", label: "Aadhar Card" },
      { value: "PAN Card", label: "PAN Card" },
    ],
    isDataUploading: false,
    isDelete: false,
  };

  validator = new SimpleReactValidator({
    element: (message) => (
      <span className="error-message text-danger fs-14">{message}</span>
    ),
    autoForceUpdate: this,
  });

  componentDidMount() {
    let {
      getAddressProofDropdown,
      getIdentityProofDropdown,
      getEducationDropdown,
      id,
    } = this.props;
    getAddressProofDropdown().then((data) => {
      let addressProofOptions = [];
      data.map((list) => {
        addressProofOptions.push({
          value: list.kycDocID,
          label: list.kycDoc,
        });
      });
      this.setState({
        addressProofOption: addressProofOptions,
      });
    });

    getIdentityProofDropdown().then((data) => {
      let identityProofOptions = [];
      data.map((list) => {
        identityProofOptions.push({
          value: list.kycDocID,
          label: list.kycDoc,
        });
      });
      this.setState({
        identityProofOption: identityProofOptions,
      });
    });
    getEducationDropdown().then((data) => {
      let educationQualificationOptions = [];
      data.map((list) => {
        educationQualificationOptions.push({
          value: list.educationID,
          label: list.qualificationType,
        });
      });
      this.setState({
        educationQualificationOption: educationQualificationOptions,
      });
    });

    if (id !== undefined) {
      this._getData(id);
    }
  }

  _getData = (id) => {
    let { getStaffById } = this.props;

    let body = {
      id: id,
    };

    getStaffById(body).then((data) => {
      this.setState({
        first_name: data.firstName,
        last_name: data.lastName,
        gender: data.gender,
        contact: data.mobileNumber,
        employeeId: data.employeeId ? data.employeeId.toString() : "",
        emailId: data.emailId ? data.emailId : "",
        designation: data.designation ? data.designation : "",
        experiance: data.experience ? data.experience.toString() : "",
        qualification: data.educationID ? data.educationID : "",
        dateOfJoining: data.dateOfJoining ? new Date(data.dateOfJoining) : "",
        address_proof: data.addressProofId ? data.addressProofId : "",
        dob: data.dob ? new Date(data.dob) : "",
        addressProofFile: data.addressProofUrl ? data.addressProofUrl : "",
        profile_photo: data.profileImageUrl ? data.profileImageUrl : "",
        profilePicName: data.profileImageName ? data.profileImageName : "",
        address_proof_name: data.addressProofName ? data.addressProofName : "",
        identity_proof: data.identityProofId ? data.identityProofId : "",
        identity_proof_file: data.identityProofUrl ? data.identityProofUrl : "",
        identity_proof_name: data.identityProofName
          ? data.identityProofName
          : "",
        resumeDocument: data.resumeDocUrl ? data.resumeDocUrl : "",
        resumeDocumentName: data.resumeDocName ? data.resumeDocName : "",
        userMasterId: data.userMasterId,
      });
    });
  };

  handleInput = ({ target: { name, value } }) => {
    this.setState({
      [name]: value,
    });
  };

  uploadProofFile = (e) => {
    //upload profile picture
    let file = e.target.files[0];
    if (file !== undefined) {
      this.setState({ profile_photo: "" }, () => {
        this.setState({
          isProfilePicUploading: true,
          profilePicName: "Uploading ....",
          profile_photo: file,
        });
        const formData = new FormData();
        formData.append("file", file);

        this.props
          .fileUpload(formData)
          .then((data) => {
            document.getElementById("uploadProofFile").value = null;
            this.setState({
              profile_photo: data[0].fileUrl,
              profilePicName: data[0].fileName,
              isProfilePicUploading: false,
            });
          })
          .catch(() => {
            this.setState({
              profile_photo: "",
              profilePicName: "",
              isProfilePicUploading: false,
            });
          });
      });
    }
  };

  uploadResume = (e) => {
    //upload Resume
    let file = e.target.files[0];
    if (file !== undefined) {
      this.setState({ resumeDocument: "" }, () => {
        this.setState({
          isResumUploading: true,
          resumeDocumentName: "Uploading ....",
          resumeDocument: file,
        });
        const formData = new FormData();
        formData.append("file", file);

        this.props
          .fileUpload(formData)
          .then((data) => {
            document.getElementById("uploadResume").value = null;
            this.setState({
              resumeDocument: data[0].fileUrl,
              resumeDocumentName: data[0].fileName,
              isResumUploading: false,
            });
          })
          .catch(() => {
            this.setState({
              resumeDocument: "",
              resumeDocumentName: "",
              isResumUploading: false,
            });
          });
      });
    }
  };

  handleAddressProofFile = (e) => {
    //upload address proof
    let file = e.target.files[0];
    if (file !== undefined) {
      this.setState({ addressProofFile: "" }, () => {
        this.setState({
          isaddressProofUploading: true,
          address_proof_name: "Uploading ....",
          addressProofFile: file,
        });
        const formData = new FormData();
        formData.append("file", file);

        this.props
          .fileUpload(formData)
          .then((data) => {
            document.getElementById("handleAddressProofFile").value = null;
            this.setState({
              addressProofFile: data[0].fileUrl,
              address_proof_name: data[0].fileName,
              isaddressProofUploading: false,
            });
          })
          .catch(() => {
            this.setState({
              addressProofFile: "",
              address_proof_name: "",
              isaddressProofUploading: false,
            });
          });
      });
    }
  };

  handleIdentityProofFile = (e) => {
    //upload identity proof
    let file = e.target.files[0];
    this.setState({ identity_proof_file: "" }, () => {
      this.setState({
        isIdentityProofUploading: true,
        identity_proof_name: "Uploading ....",
        identity_proof_file: file,
      });
      const formData = new FormData();
      formData.append("file", file);

      this.props
        .fileUpload(formData)
        .then((data) => {
          document.getElementById("handleIdentityProofFile").value = null;
          this.setState({
            identity_proof_file: data[0].fileUrl,
            identity_proof_name: data[0].fileName,
            isIdentityProofUploading: false,
          });
        })
        .catch(() => {
          this.setState({
            identity_proof_file: "",
            identity_proof_name: "",
            isIdentityProofUploading: false,
          });
        });
    });
  };

  //delete Bod
  handleDelete = () => {
    this.setState({ isDataUploading: true });
    let { id } = this.props;
    let { deleteStaff } = this.props;
    let body = {
      id,
    };
    deleteStaff(body).then(() => {
      this.props.currentTab(3);
      history.push("/fpo/member");
    });
  };

  toggleDelete = () => {
    let { isDelete } = this.state;
    this.setState({
      isDelete: !isDelete,
    });
  };

  handleSubmit = () => {
    let { id } = this.props;

    let {
      first_name,
      last_name,
      employeeId,
      gender,
      designation,
      experiance,
      qualification,
      emailId,
      dateOfJoining,
      address_proof,
      contact,
      dob,
      profilePicName,
      profile_photo,
      identity_proof,
      addressProofFile,
      address_proof_name,
      identity_proof_name,
      identity_proof_file,
      resumeDocumentName,
      resumeDocument,
      isProfilePicUploading,
      isResumUploading,
      isIdentityProofUploading,
      isaddressProofUploading,
      userMasterId,
    } = this.state;

    if (this.validator.allValid()) {
      let body = {
        firstName: first_name,
        lastName: last_name,
        mobileNumber: contact,
        emailId: emailId,
        gender: gender,
        userMasterId: "",
        staffId: "",
      };

      if (profilePicName !== "") {
        body.profileImageName = profilePicName;
        body.profileImageUrl = profile_photo;
      }
      if (resumeDocumentName !== "") {
        body.resumeDocName = resumeDocumentName;
        body.resumeDocUrl = resumeDocument;
      }
      if (address_proof_name !== "") {
        body.addressProofName = address_proof_name;
        body.addressProofUrl = addressProofFile;
      }
      if (identity_proof_name !== "") {
        body.identityProofName = identity_proof_name;
        body.identityProofUrl = identity_proof_file;
      }
      if (employeeId !== "") {
        body.employeeId = parseInt(employeeId);
      }
      if (identity_proof !== "") {
        body.identityProofId = parseInt(identity_proof);
      }
      if (address_proof !== "") {
        body.addressProofId = parseInt(address_proof);
      }
      if (experiance !== "") {
        body.experience = parseInt(experiance);
      }
      if (designation !== "") {
        body.designation = designation;
      }
      if (qualification !== "") {
        body.educationID = parseInt(qualification);
      }
      if (dateOfJoining !== "") {
        body.dateOfJoining = moment(dateOfJoining).format("YYYY-MM-DD");
      }
      if (dob !== "") {
        body.dob = moment(dob).format("YYYY-MM-DD");
      }
      if (
        !isIdentityProofUploading &&
        !isaddressProofUploading &&
        !isProfilePicUploading &&
        !isResumUploading
      ) {
        this.setState({ isDataUploading: true });
        if (id == undefined) {
          delete body.userMasterId;
          delete body.staffId;
          this.props.addStaff(body).then((data) => {
            if (data === 200) {
              this.validator.hideMessages();
              this.props.currentTab(3);
              history.push("/fpo/member");
              this._clearData();
            }
            this.setState({ isDataUploading: false });
          });
        } else {
          body.staffId = id;
          body.userMasterId = userMasterId;

          this.props.updateStaff(body).then((data) => {
            if (data === 200) {
              this.validator.hideMessages();
              this.props.currentTab(3);
              history.push("/fpo/member");
            }
            this.setState({ isDataUploading: false });
          });
        }
      } else {
        Toast({ type: "error", message: "Please wait Image is uploading" });
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  _clearData = () => {
    this.setState({
      first_name: "",
      last_name: "",
      gender: "",
      contact: "",
      emailId: "",
      designation: "",
      experiance: "",
      qualification: "",
      employeeId: "",
      dateOfJoining: "",
      address_proof: "",
      dob: "",
      addressProofFile: "",
      profile_photo: "",
      profilePicName: "",
      address_proof_name: "",
      identity_proof: "",
      identity_proof_file: "",
      identity_proof_name: "",
      resumeDocument: "",
      userMasterId: "",
      resumeDocumentName: "",
      isDataUploading: false,
    });
  };

  handleRemoveImage = (picName) => {
    if (picName === "profile") {
      this.setState({
        profile_photo: "",
        profilePicName: "",
      });
    } else if (picName === "addressProof") {
      this.setState({
        addressProofFile: "",
        address_proof_name: "",
      });
    } else if (picName === "identityProof") {
      this.setState({
        identity_proof_file: "",
        identity_proof_name: "",
      });
    }
  };

  render() {
    const {
      first_name,
      last_name,
      emailId,
      employeeId,
      gender,
      genderOptions,
      designation,
      experiance,
      qualification,
      dateOfJoining,
      address_proof,
      identityProofOption,
      addressProofOption,
      educationQualificationOption,
      contact,
      dob,
      profilePicName,
      profile_photo,
      identity_proof,
      addressProofFile,
      address_proof_name,
      identity_proof_name,
      identity_proof_file,
      resumeDocumentName,
      resumeDocument,
      isDataUploading,
      isProfilePicUploading,
      isaddressProofUploading,
      isIdentityProofUploading,
      isDelete,
    } = this.state;

    let { id, accessData } = this.props;
    let permissionData = getAccessdata(accessData, "membership");

    let { deleteAccess = false } = permissionData;

    return (
      <Fragment>
        <div className="add-staff-area">
          <div className="d-flex container-sa">
            <div className="d-flex bod-header-area w-100">
              <div
                className="d-flex"
                onClick={() => {
                  history.push("/fpo/member");
                }}
              >
                <img className="cursor-pointer" src={arrow_left} alt="back" />
                <p className="ml-3 text-black fs-18 line-height-25 font-weight-bold mb-0 cursor-pointer">
                  {id ? "Edit" : "Add"} staff
                </p>
              </div>
              <div>
                {isDataUploading ? (
                  <div className="d-flex align-items-center h-100">
                    {id && deleteAccess && (
                      <div className="mr-3 d-flex align-items-center h-100">
                        <p className="mb-1 font-weight-bold fs-20 d-flex align-items-center">
                          <i className="icon-Delete fs-18" />
                          <span className="text-danger fs-18 pl-3">Delete</span>
                        </p>
                      </div>
                    )}
                    <div>
                      <NormalButton
                        label="Submit & next"
                        mainbg={true}
                        className="mx-auto fs-14 line-height-16"
                      />
                    </div>
                  </div>
                ) : (
                  <div className="d-flex align-items-center h-100">
                    {id ? (
                      <div className="mr-3 d-flex align-items-center h-100">
                        <p
                          className="mb-1 font-weight-bold fs-20 cursor-pointer d-flex align-items-center"
                          onClick={() => this.toggleDelete()}
                        >
                          <i className="icon-Delete fs-18" />
                          <span className="text-danger fs-18 pl-3">Delete</span>
                        </p>
                      </div>
                    ) : null}
                    <div>
                      <NormalButton
                        label="Submit & next"
                        mainbg={true}
                        className="mx-auto fs-14 line-height-16"
                        onClick={() => this.handleSubmit()}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="add-staff">
            <div className="container">
              <div className="add-staff-form-container">
                <div className="px-md-5 py-md-4 px-0 py-0">
                  <div className="px-md-3 px-0">
                    <div className="row">
                      <div className="col-md-6">
                        <NormalInput
                          required={true}
                          label="First name"
                          type="text"
                          onChange={this.handleInput}
                          value={first_name}
                          placeholder="Enter here"
                          name="first_name"
                        />
                        <div className="row">
                          <div className="col-md-9 ml-auto">
                            {this.validator.message(
                              "first name",
                              first_name,
                              "required|alpha_space|max:20"
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <NormalInput
                          required={true}
                          label="Last name"
                          type="text"
                          onChange={this.handleInput}
                          value={last_name}
                          placeholder="Enter here"
                          name="last_name"
                        />
                        <div className="row">
                          <div className="col-md-9 ml-auto">
                            {this.validator.message(
                              "last name",
                              last_name,
                              "required|alpha_space|max:20"
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <NormalInput
                          label="Email"
                          type="text"
                          onChange={this.handleInput}
                          value={emailId}
                          placeholder="Enter here"
                          name="emailId"
                        />
                        <div className="row">
                          <div className="col-md-9 ml-auto">
                            {this.validator.message(
                              "emailId",
                              emailId,
                              "email"
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <NormalInput
                          label="Employee ID"
                          type="number"
                          onChange={this.handleInput}
                          value={employeeId}
                          placeholder="Enter here"
                          name="employeeId"
                        />
                        <div className="row">
                          <div className="col-md-9 ml-auto">
                            {this.validator.message(
                              "employeed id",
                              employeeId,
                              "max:10"
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="row mt-3 pt-1 upload-area">
                          <div className="col-md-3 pr-0">
                            <div className="fs-16 text-black-32 font-weight-bold line-height-19">
                              {"Profile photo"}
                            </div>
                          </div>
                          <div className="col-md-9">
                            <div className="d-flex align-items-center w-100 btn-file-input">
                              <button className="">
                                <input
                                  type="file"
                                  className=""
                                  accept=".jpg, .png, .jpeg"
                                  onChange={this.uploadProofFile}
                                  id="uploadProofFile"
                                  title=""
                                />
                                {profile_photo == "" ? (
                                  "Choose file"
                                ) : (
                                  <>
                                    {profilePicName.length == 10
                                      ? profilePicName
                                      : profilePicName.substring(0, 9)}
                                    {"..."}
                                  </>
                                )}
                              </button>
                              {profilePicName != "" && !isProfilePicUploading && (
                                <div className="pl-3 position-relative">
                                  <img
                                    src={profile_photo}
                                    alt={profilePicName}
                                    height="75"
                                    width="75"
                                  />
                                  <i
                                    className="icon-Delete text-danger fs-14 pl-2 cursor-pointer position-absolute"
                                    onClick={() =>
                                      this.handleRemoveImage("profile")
                                    }
                                  ></i>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="row mt-3 pt-1 upload-area">
                          <div className="col-md-3 pr-0">
                            <div className="fs-16 text-black-32 font-weight-bold line-height-19">
                              {"Resume"}
                            </div>
                          </div>
                          <div className="col-md-9">
                            <div className="d-flex align-items-center w-100 btn-file-input">
                              <button className="">
                                <input
                                  type="file"
                                  className=""
                                  accept="image/*, .pdf"
                                  onChange={this.uploadResume}
                                  id="uploadResume"
                                  title=""
                                />
                                {resumeDocument == "" ? (
                                  "Choose file"
                                ) : (
                                  <>
                                    {resumeDocumentName.length == 10
                                      ? resumeDocumentName
                                      : resumeDocumentName.substring(0, 9)}
                                    {"..."}
                                  </>
                                )}
                              </button>
                              {resumeDocument !== "" ? (
                                resumeDocumentName.includes(".pdf") ? (
                                  <div className="pl-3">
                                    <img
                                      src={pdf}
                                      alt={"pdf"}
                                      height="75"
                                      width="75"
                                    />
                                  </div>
                                ) : (
                                  <div className="pl-3">
                                    <img
                                      src={resumeDocument}
                                      alt={resumeDocumentName}
                                      height="75"
                                      width="75"
                                    />
                                  </div>
                                )
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <NormalInput
                          label="Experience (in years)"
                          type="number"
                          onChange={this.handleInput}
                          value={experiance}
                          name="experiance"
                        />
                        <div className="row">
                          <div className="col-md-9 ml-auto">
                            {this.validator.message(
                              "experiance",
                              experiance,
                              "max:2"
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <NormalSelect
                          label={"Gender"}
                          placeholder="Select"
                          value={gender}
                          arrow={true}
                          name="gender"
                          options={genderOptions}
                          handleChange={this.handleInput}
                          required={true}
                        />
                        <div className="row">
                          <div className="col-md-9 ml-auto">
                            {this.validator.message(
                              "gender",
                              gender,
                              "required"
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <NormalSelect
                          label="Education qualification"
                          placeholder="Select"
                          value={qualification}
                          arrow={true}
                          name="qualification"
                          options={educationQualificationOption}
                          handleChange={this.handleInput}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <NormalDate
                          isIcon={true}
                          value={dob}
                          name="dob"
                          placeholder="dd/mm/yyyy"
                          onChange={this.handleInput}
                          mindate={false}
                          maxdate={true}
                          timeInput={false}
                          label={"DOB"}
                        />
                      </div>
                      <div className="col-md-6">
                        <NormalInput
                          label="Designation"
                          type="text"
                          onChange={this.handleInput}
                          value={designation}
                          name="designation"
                        />
                        <div className="row">
                          <div className="col-md-9 ml-auto">
                            {this.validator.message(
                              "designation",
                              designation,
                              "max:50|alpha_space"
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <NormalInput
                          label="Contact number"
                          type="text"
                          onChange={this.handleInput}
                          value={contact}
                          name="contact"
                          required={true}
                          max={10}
                        />
                        <div className="row">
                          <div className="col-md-9 ml-auto">
                            {this.validator.message(
                              "number",
                              contact,
                              "required|phone"
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <NormalDate
                          isIcon={true}
                          value={dateOfJoining}
                          name="dateOfJoining"
                          placeholder="dd/mm/yyyy"
                          onChange={this.handleInput}
                          mindate={false}
                          maxdate={false}
                          timeInput={false}
                          label={"Date of joining"}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <NormalSelect
                          label="Identity proof"
                          placeholder="Select"
                          value={identity_proof}
                          arrow={true}
                          name="identity_proof"
                          options={identityProofOption}
                          handleChange={this.handleInput}
                        />
                      </div>
                      <div className="col-md-6">
                        <div className="row mt-3 pt-1 upload-area">
                          <div className="col-md-3 pr-0">
                            <div className="fs-16 text-black-32 font-weight-bold line-height-19">
                              Identity proof document
                            </div>
                          </div>
                          <div className="col-md-9">
                            <div className="d-flex align-items-center w-100 btn-file-input">
                              <button className="">
                                <input
                                  type="file"
                                  className=""
                                  accept=".jpg, .png, .jpeg"
                                  onChange={this.handleIdentityProofFile}
                                  id="handleIdentityProofFile"
                                  title=""
                                />
                                {identity_proof_file == "" ? (
                                  "Choose file"
                                ) : (
                                  <>
                                    {identity_proof_name.length == 10
                                      ? identity_proof_name
                                      : identity_proof_name.substring(0, 9)}
                                    {"..."}
                                  </>
                                )}
                              </button>
                              {identity_proof_name != "" &&
                                !isIdentityProofUploading && (
                                  <div className="pl-3 position-relative">
                                    <img
                                      src={identity_proof_file}
                                      alt={identity_proof_name}
                                      height="75"
                                      width="75"
                                    />
                                    <i
                                      className="icon-Delete text-danger fs-14 pl-2 cursor-pointer position-absolute"
                                      onClick={() =>
                                        this.handleRemoveImage("identityProof")
                                      }
                                    ></i>
                                  </div>
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <NormalSelect
                          label="Address proof"
                          placeholder="Select"
                          value={address_proof}
                          arrow={true}
                          name="address_proof"
                          options={addressProofOption}
                          handleChange={this.handleInput}
                        />
                      </div>
                      <div className="col-md-6">
                        <div className="row mt-3 pt-1 upload-area">
                          <div className="col-md-3 pr-0">
                            <div className="fs-16 text-black-32 font-weight-bold line-height-19">
                              {"Address proof document"}
                            </div>
                          </div>
                          <div className="col-md-9">
                            <div className="d-flex align-items-center w-100 btn-file-input">
                              <button className="">
                                <input
                                  type="file"
                                  className=""
                                  accept=".jpg, .png, .jpeg"
                                  onChange={this.handleAddressProofFile}
                                  id="handleAddressProofFile"
                                  title=""
                                />
                                {address_proof_name == "" ? (
                                  "Choose file"
                                ) : (
                                  <>
                                    {address_proof_name.length == 10
                                      ? address_proof_name
                                      : address_proof_name.substring(0, 9)}
                                    {"..."}
                                  </>
                                )}
                              </button>
                              {address_proof_name != "" &&
                                !isaddressProofUploading && (
                                  <div className="pl-3 position-relative">
                                    <img
                                      src={addressProofFile}
                                      alt={address_proof_name}
                                      height="75"
                                      width="75"
                                    />
                                    <i
                                      className="icon-Delete text-danger fs-14 pl-2 cursor-pointer position-absolute"
                                      onClick={() =>
                                        this.handleRemoveImage("addressProof")
                                      }
                                    ></i>
                                  </div>
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ConfirmationPopup
          isOpen={isDelete}
          toggle={this.toggleDelete}
          onSubmit={this.handleDelete}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  accessData: state.home.accessData,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      fileUpload,
      getAddressProofDropdown,
      getIdentityProofDropdown,
      getEducationDropdown,
      addStaff,
      currentTab,
      updateStaff,
      getStaffById,
      deleteStaff,
    },
    dispatch
  );
};
let component = AddStaffClass;

export const AddStaff = connect(mapStateToProps, mapDispatchToProps)(component);
