import React, { Component } from "react";
import "./style.scss";
import { getBODById } from "action/BodAct";

import { CommonPageLoader } from "component/common";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { history } from "service/helpers/index";

import moment from "moment";

import { getFilePathDownload } from "service/api";

import { currentTab } from "action/MemberAct";

import { getBODByIdForRM } from "action/RmAct";
import userProfile from "assets/images/default-profile-img.png";
import arrow_left from "assets/svg/add_member/arrow_left.svg";

export class ViewBODClass extends Component {
  constructor(props) {
    super();
    this.state = {
      bodDetails: {},
      isloading: false,
    };
  }
  componentDidMount() {
    let { id } = this.props;
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    this._getData(id);
  }

  _getData = (id) => {
    let { getBODById, fpoid, getBODByIdForRM } = this.props;
    this.setState({ isloading: true });
    if (fpoid) {
      let body = {
        id: id,
        fpoid: fpoid,
      };
      getBODByIdForRM(body).then((data) => {
        this.setState({
          bodDetails: data,
          isloading: false,
        });
      });
    } else {
      let body = {
        id: id,
      };
      getBODById(body).then((data) => {
        this.setState({
          bodDetails: data,
          isloading: false,
        });
      });
    }
  };

  handleBack = () => {
    this.props.currentTab(2);
    history.goBack();
  };

  render() {
    let { bodDetails = {}, isloading } = this.state;

    let {
      profileImageUrl = "",
      firstName,
      lastName,
      gender,
      dob,
      mobileNumber,
      hasSmartPhone,
      AppointedOn,
      din,
      educationmaster = {},
      address,
      identityProofName,
      addressProofName,
      identityProofUrl,
      addressProofUrl,
    } = bodDetails;

    let { qualificationType } = educationmaster !== null ? educationmaster : {};

    return (
      <div className="view-bod">
        <div className="d-flex my-3 container-sa px-0">
          <div className="d-flex" onClick={() => this.handleBack()}>
            <img className="cursor-pointer" src={arrow_left} alt="back" />
            <p className="fs-18 font-weight-bold font-Gilroy text-black mb-0 cursor-pointer ml-3">
              {" BOD detail page"}
            </p>
          </div>
        </div>
        {!isloading ? (
          <div className="bg-area py-md-4 py-0">
            <div className="container">
              <div className="bg-white p-md-5 p-3 shadow mx-md-5 mx-0">
                <div className="row">
                  <div className="col-md-3 mx-auto">
                    <div className="profile-area">
                      <div className="profile-pic">
                        <img
                          src={
                            profileImageUrl !== "" && profileImageUrl
                              ? profileImageUrl
                              : userProfile
                          }
                          className="shadow"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mt-5">
                  <div className="col-md-6">
                    <div className="row">
                      <div className="col-md-4">
                        <p className="text-black font-weight-bold fs-16 pr-2">
                          {"Name :"}
                        </p>
                      </div>
                      <div className="col-md-8">
                        <p className="text-black-50 fs-16">
                          {firstName} {lastName}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <p className="text-black font-weight-bold fs-16 pr-2">
                          {"Gender :"}
                        </p>
                      </div>
                      <div className="col-md-8">
                        <p className="text-black-50 fs-16">
                          {gender && gender !== "" ? gender : "-"}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <p className="text-black font-weight-bold fs-16 pr-2">
                          {"DOB :"}
                        </p>
                      </div>
                      <div className="col-md-8">
                        <p className="text-black-50 fs-16">
                          {dob ? moment(dob).format("DD MMMM YYYY") : "-"}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <p className="text-black font-weight-bold fs-16 pr-2">
                          {"Phone :"}
                        </p>
                      </div>
                      <div className="col-md-8">
                        <p className="text-black-50 fs-16">{mobileNumber}</p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <p className="text-black font-weight-bold fs-16 pr-2">
                          {"Smart Phone :"}
                        </p>
                      </div>
                      <div className="col-md-8">
                        <p className="text-black-50 fs-16">
                          {hasSmartPhone === true
                            ? "Yes"
                            : hasSmartPhone === false
                            ? "No"
                            : "-"}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <p className="text-black font-weight-bold fs-16 pr-2">
                          Education{" "}
                          <span className="text-nowrap">
                            {"qualification :"}
                          </span>
                        </p>
                      </div>
                      <div className="col-md-8">
                        <p className="text-black-50 fs-16">
                          {qualificationType ? qualificationType : "-"}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="row">
                      <div className="col-md-4">
                        <p className="text-black font-weight-bold fs-16 pr-2">
                          {"DIN :"}
                        </p>
                      </div>
                      <div className="col-md-8">
                        <p className="text-black-50 fs-16">{din ? din : "-"}</p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <p className="text-black font-weight-bold fs-16 pr-2">
                          Address :{" "}
                        </p>
                      </div>
                      <div className="col-md-8">
                        <p className="text-black-50 fs-16">
                          {address ? address : "-"}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <p className="text-black font-weight-bold fs-16 pr-2">
                          {"Identity proof :"}
                        </p>
                      </div>
                      <div className="col-md-8">
                        {identityProofName ? (
                          <a
                            className="fs-16 text-green-18 text-underline cursor-pointer mb-3"
                            href={
                              identityProofName &&
                              getFilePathDownload + identityProofUrl
                            }
                            download
                          >
                            {"Download"}
                          </a>
                        ) : (
                          "-"
                        )}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <p className="text-black font-weight-bold fs-16 pr-2">
                          {" Address proof :"}
                        </p>
                      </div>
                      <div className="col-md-8">
                        {addressProofName ? (
                          <a
                            className="fs-16 text-green-18 text-underline cursor-pointer mb-3"
                            href={
                              addressProofName &&
                              getFilePathDownload + addressProofUrl
                            }
                            download
                          >
                            {"Download"}
                          </a>
                        ) : (
                          "-"
                        )}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <p className="text-black font-weight-bold fs-16 pr-2">
                          {"Appointed on :"}
                        </p>
                      </div>
                      <div className="col-md-8">
                        <p className="text-black-50 fs-16">
                          {AppointedOn
                            ? moment(AppointedOn).format("DD MMMM YYYY")
                            : "-"}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <>
            {/* Loader */}
            <CommonPageLoader />
          </>
        )}
        <div className="view-members"></div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getBODById,
      currentTab,
      getBODByIdForRM,
    },
    dispatch
  );
};
let component = ViewBODClass;

export const ViewBOD = connect(null, mapDispatchToProps)(component);
