import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import "./style.scss";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { getAboutusMemberDetail } from "action/MemberAct";
import classnames from "classnames";
import { KeyFinancialsTab } from "../KeyFinancialsTab";
import { RatioAnalysis } from "../RatioAnalysis";
import { PerformanceTab } from "../PerformanceTab";
import { NormalButton } from "component/common";
export class FinancialAnalysisClass extends Component {
  state = {
    financialsTabs: "1",
    isDownload: false,
    userData: null,
  };

  componentWillMount() {
    let { getAboutusMemberDetail } = this.props;
    getAboutusMemberDetail().then((data) =>
      this.setState({
        userData: data,
      })
    );
  }

  toggle = (tab) => {
    let { financialsTabs } = this.state;
    if (financialsTabs !== tab) {
      this.setState({
        financialsTabs: tab,
      });
    }
  };

  donwloadScore = () => {
    this.setState(
      {
        isDownload: true,
      },
      () => {
        let { financialsTabs } = this.state;
        const reportOne = document.getElementById("keyFinancialReport");
        const reportTwo = document.getElementById("ratioAnalysisReport");
        const reportThree = document.getElementById(
          "financialPerformanceReport"
        );
        let report = null;
        switch (financialsTabs) {
          case "1":
            report = reportOne;
            break;
          case "2":
            report = reportTwo;
            break;
          case "3":
            report = reportThree;
            break;
          default:
            break;
        }
        html2canvas(report, {
          useCORS: true,
          allowTaint: true,
          scrollY: 0,
        }).then((canvas) => {
          const image = { type: "jpeg", quality: 0.98 };
          const margin = [0.5, 0.5];
          var imgWidth = 8.5;
          var pageHeight = 11;
          var innerPageWidth = imgWidth - margin[0] * 2;
          // Calculate the number of pages.
          var pxFullHeight = canvas.height;
          var pxPageHeight = Math.floor(canvas.width * (pageHeight / imgWidth));
          var nPages = Math.ceil(pxFullHeight / pxPageHeight);
          // Create a one-page canvas to split up the full image.
          var pageCanvas = document.createElement("canvas");
          var pageCtx = pageCanvas.getContext("2d");
          pageCanvas.width = canvas.width;
          pageCanvas.height = pxPageHeight;
          // Initialize the PDF.
          var pdf = new jsPDF("p", "in", [8.5, 11]);
          for (var page = 0; page < nPages; page++) {
            // Trim the final page to reduce file size.
            if (page === nPages - 1 && pxFullHeight % pxPageHeight !== 0) {
              pageCanvas.height = pxFullHeight % pxPageHeight;
              pageHeight =
                (pageCanvas.height * innerPageWidth) / pageCanvas.width;
            }
            // Display the page.
            var w = pageCanvas.width;
            var h = pageCanvas.height;
            pageCtx.fillStyle = "white";
            pageCtx.fillRect(0, 0, w, h);
            pageCtx.drawImage(canvas, 0, page * pxPageHeight, w, h, 0, 0, w, h);
            // Add the page to the PDF.
            if (page > 0) pdf.addPage();
            var imgData = pageCanvas.toDataURL(
              "image/" + image.type,
              image.quality
            );
            pdf.addImage(
              imgData,
              image.type,
              margin[1],
              margin[0],
              innerPageWidth,
              pageHeight
            );
          }
          pdf.save(
            financialsTabs === "1"
              ? "keyFinancialReport.pdf"
              : financialsTabs === "2"
              ? "ratioAnalysisReport"
              : financialsTabs === "3"
              ? "financialPerformanceReport"
              : ""
          );
          this.setState({
            isDownload: false,
          });
        });
      }
    );
  };

  render() {
    let { financialsTabs, isDownload, userData } = this.state;
    let { activeTab, id } = this.props;

    return (
      <section className="finacial-analysis-list">
        <div className="container-sa align-items-center d-flex justify-content-between">
          <div className="scroll-x">
            <Nav
              className="tab-sammunaty d-flex justify-content-center"
              style={{ width: "max-content" }}
            >
              <NavItem>
                <NavLink
                  className={classnames({ active: financialsTabs === "1" })}
                  onClick={() => {
                    this.toggle("1");
                  }}
                >
                  <button
                    className={`btn ${financialsTabs === "1" ? "active" : ""}`}
                  >
                    {" "}
                    Key Financials{" "}
                  </button>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: financialsTabs === "2" })}
                  onClick={() => {
                    this.toggle("2");
                  }}
                >
                  <button
                    className={`btn ${financialsTabs === "2" ? "active" : ""}`}
                  >
                    {" "}
                    Ratio Analysis{" "}
                  </button>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: financialsTabs === "3" })}
                  onClick={() => {
                    this.toggle("3");
                  }}
                >
                  <button
                    className={`btn ${financialsTabs === "3" ? "active" : ""}`}
                  >
                    Financial performance{" "}
                  </button>
                </NavLink>
              </NavItem>
            </Nav>
          </div>
          <div>
            <NormalButton
              label={isDownload ? "Downloading Report..." : "Download Report"}
              mainbg={true}
              className="fs-14 line-height-26 mt-2 mb-2 al grading-score-btn"
              style={{ width: 100, display: "unset" }}
              onClick={() => this.donwloadScore()}
              disabled={isDownload}
              isPopup={true}
            />
          </div>
        </div>
        <TabContent activeTab={financialsTabs}>
          <TabPane tabId="1">
            <div id="keyFinancialReport">
              <div className="fs-22 fw-600 text-black text-center mt-3">
                Key Financials of {userData?.fpoName}
              </div>
              <KeyFinancialsTab
                financialsTabs={financialsTabs}
                activeTab={activeTab}
                id={id}
              />
            </div>
          </TabPane>
          <TabPane tabId="2">
            <div id="ratioAnalysisReport">
              <div className="fs-22 fw-600 text-black text-center mt-3">
                Ratio Analysis of {userData?.fpoName}
              </div>
              <RatioAnalysis
                financialsTabs={financialsTabs}
                activeTab={activeTab}
                id={id}
              />
            </div>
          </TabPane>
          <TabPane tabId="3">
            <PerformanceTab
              financialsTabs={financialsTabs}
              activeTab={activeTab}
              id={id}
              fpoName={userData?.fpoName}
            />
          </TabPane>
        </TabContent>
      </section>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getAboutusMemberDetail,
    },
    dispatch
  );
};

let component = FinancialAnalysisClass;

export const FinancialAnalysis = connect(null, mapDispatchToProps)(component);
