import React, { Component, Fragment } from "react";
import { Modal } from "reactstrap";
import Joyride from "react-joyride";
import Multiselect from "multiselect-react-dropdown";
import {
  NormalCheckbox,
  CommonPageLoader,
  NormalSelect,
  NormalInput,
  NormalDate,
  Pagination,
} from "component/common";
import {
  getListOfMembersToSms,
  sendSmsForMember,
  getListOfStaffToSms,
  sendSmsForStaff,
  getListOfBODToSms,
  sendSmsForBod,
  getMessageDetails,
  getMessageDetailsByLanguage,
  getSmsLanguage,
} from "action/MemberAct";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SimpleReactValidator from "simple-react-validator";
import { Toast } from "service/toast";
import moment from "moment";
import { ReactTransliterate } from "react-transliterate";
import { debounceFunction } from "service/debounce";

import "react-transliterate/dist/index.css";

import "./forward.scss";

export class SendSmsPopupClass extends Component {
  constructor() {
    super();
    this.state = {
      userList: [],
      selecteduserList: [],
      isSelectAll: false,
      message: "",
      isLoading: false,
      searchValue: "",
      optionLists: [],
      optionLang: [],
      lang: "",
      langCode: "",
      optionSelected: "",
      fpoName: "",
      productName: "",
      agmDate: "",
      isButtonClicked: false,
      pageMeta: {
        pageCount: undefined,
        page: 1,
      },
      searchPage: 1,
      cropOptions: [],
      villageOptions: [],
      figOptions: [],
      filterOptions: [],
      sampleOption: [],
      searchedUserList: [],
      searchSelectedArray: [],
    };
    this.cropsRef = React.createRef();
    this.villageRef = React.createRef();
    this.figRef = React.createRef();
  }

  //validation
  validator = new SimpleReactValidator({
    element: (message) => (
      <span className="error-message text-danger fs-14">{message}</span>
    ),
    autoForceUpdate: this,
  });

  //lifeCycles
  componentDidMount = () => {
    this._getUserProfileList();
    // this._getMessageDetails();
    this.getLanguageDropDown();
  };

  componentDidUpdate(prevProps) {
    if (prevProps.activeTab !== this.props.activeTab) {
      this._getUserProfileList();
      // this._getMessageDetails();
    }
  }

  _getMessageDetails = () => {
    this.props.getMessageDetails().then((data) => {
      let optionData = [];
      data.map((list) => {
        optionData.push({
          label: list.templateDescription,
          value: list.templateId,
          messageData: list.templateData,
        });
      });
      this.setState({
        optionLists: optionData,
      });
    });
  };

  //get user list
  _getUserProfileList = () => {
    let { pageMeta } = this.state;
    let { activeTab, smsAccess } = this.props;
    if (smsAccess) {
      let { userList, selecteduserList } = this.state;
      userList = [];
      selecteduserList = [];
      this.setState({ userList, selecteduserList });
      if (activeTab === "1") {
        this.props.getListOfMembersToSms().then((data) => {
          let memberList = [];
          data.map((list) => {
            memberList.push({
              ...list,
              isChecked: false,
              crops:
                list.crops && list.crops.length > 0
                  ? String(list.crops.join(",")).toLowerCase().split(",")
                  : [],
            });
          });

          let crops = [];
          let newCrops = [];
          let finalCrops = [];
          memberList.map((list) => {
            crops.push(list.crops);
          });
          crops.forEach((list) => list.forEach((crop) => newCrops.push(crop)));
          let uniqueCrops = [...new Set(newCrops)];
          let newUniqueCrops = uniqueCrops.filter((list) => list !== null);
          newUniqueCrops.forEach((list) => {
            finalCrops.push({
              label: list,
              value: list,
              type: "crops",
            });
          });

          let village = [];
          let finalVillage = [];
          memberList.map((list) => {
            village.push(list.village);
          });
          let uniqueVillage = [...new Set(village)];
          let newUnqueVillage = uniqueVillage.filter((list) => list !== null);
          newUnqueVillage.forEach((list) => {
            finalVillage.push({
              label: list,
              value: list,
              type: "village",
            });
          });

          let fig = [];
          let finalFIG = [];
          memberList.map((list) => {
            fig.push(list.figName);
          });
          let uniqueFIG = [...new Set(fig)];
          let newUniqueFIG = uniqueFIG.filter((list) => list !== null);
          newUniqueFIG.forEach((list) => {
            finalFIG.push({
              label: list,
              value: list,
              type: "fig",
            });
          });

          this.setState({
            userList: [...memberList],
            pageMeta: { ...pageMeta, pageCount: Math.ceil(data.length / 10) },
            isLoading: true,
            cropOptions: finalCrops,
            villageOptions: finalVillage,
            figOptions: finalFIG,
          });
        });
      } else if (activeTab === "2") {
        this.props.getListOfBODToSms().then((data) => {
          let staffList = [];
          data.map((list) => {
            list.isChecked = false;
            staffList.push(list);
          });
          this.setState({
            userList: [...staffList],
            pageMeta: { ...pageMeta, pageCount: Math.ceil(data.length / 10) },
            isLoading: true,
          });
        });
      } else if (activeTab === "3") {
        this.props.getListOfStaffToSms().then((data) => {
          let staffList = [];
          data.map((list) => {
            list.isChecked = false;
            staffList.push(list);
          });
          this.setState({
            userList: [...staffList],
            pageMeta: { ...pageMeta, pageCount: Math.ceil(data.length / 10) },
            isLoading: true,
          });
        });
      }
    }
  };

  getLanguageDropDown = () => {
    const { getSmsLanguage } = this.props;
    const { optionLang } = this.state;
    getSmsLanguage().then((data) => {
      data.map((list) => {
        optionLang.push({
          label: list,
          value: list,
        });
      });
      this.setState({
        optionLang: optionLang,
      });
    });
  };

  getLangCode = (lang) => {
    switch (lang) {
      case "Marathi":
        return "mr";
      case "Tamil":
        return "ta";
      case "Hindi":
        return "hi";
      case "Kannada":
        return "kn";
      case "Odia":
        return "or";
      case "English":
        return "en";
      case "Telugu":
        return "te";
      case "Gujarati":
        return "gu";
      default:
        return "en";
    }
  };

  getSmsTemplate = (lang) => {
    const { activeTab, getMessageDetailsByLanguage } = this.props;
    getMessageDetailsByLanguage({ language: lang }).then((data) => {
      let optionData = [];
      data.map((list) => {
        optionData.push({
          label: list.templateDescription,
          value: list.templateType,
          messageData: list.templateData,
        });
      });
      this.setState({
        optionLists: optionData,
        langCode: this.getLangCode(lang),
      });
    });
  };

  //handle input
  handleInput = async ({ target: { value, name } }) => {
    this.setState(
      {
        [name]: value,
      },
      () => {
        if (name == "lang") {
          this.setState(
            {
              optionSelected: "",
              fpoName: "",
              productName: "",
              agmDate: "",
            },
            () => {
              this.getSmsTemplate(value);
            }
          );
        } else if (name == "productName") {
          this.setState({
            agmDate: "",
          });
        } else if (name == "optionSelected") {
          this.setState({
            productName: "",
            agmDate: "",
            fpoName: "",
          });
        }
      }
    );
  };

  //handle select users
  handleToggle = (id, type) => {
    let { userList, selecteduserList, searchedUserList } = this.state;
    let { activeTab } = this.props;
    let index = "";
    if (activeTab === "1") {
      index = userList.findIndex((x) => x.memberID === id);
    } else if (activeTab === "2") {
      index = userList.findIndex((x) => x.bodID === id);
    } else if (activeTab === "3") {
      index = userList.findIndex((x) => x.staffId === id);
    }

    userList[index].isChecked = !userList[index].isChecked;
    if (userList[index].isChecked) {
      if (activeTab === "1") {
        selecteduserList.push(userList[index].memberID);
      } else if (activeTab === "2") {
        selecteduserList.push(userList[index].bodID);
      } else if (activeTab === "3") {
        selecteduserList.push(userList[index].staffId);
      }
      this.setState({ userList, selecteduserList });
      if (selecteduserList.length !== userList.length) {
        this.setState({ isSelectAll: false });
      } else {
        this.setState({ isSelectAll: true });
      }

      let newUserList = [];
      newUserList.push(userList[index].memberID);
      let finalList = [...new Set(newUserList)];
      this.setState({
        searchedUserList: finalList,
      });
    } else {
      if (index === 0) {
        selecteduserList.shift();
        searchedUserList.shift();
      } else {
        selecteduserList.pop();
        searchedUserList.pop();
      }
      if (selecteduserList.length !== userList.length) {
        this.setState({ isSelectAll: false });
      }
      this.setState({
        selecteduserList,
      });
      if (selecteduserList?.length === 0) {
        this.cropsRef.current.resetSelectedValues();
        this.villageRef.current.resetSelectedValues();
        this.figRef.current.resetSelectedValues();
        this.setState({
          selecteduserList: [],
          searchedUserList: [],
          filterOptions: [],
        });
      }
    }
  };

  //handle select all users
  handleToggleForSelectAll = (isChecked) => {
    let { userList, selecteduserList, isSelectAll, searchValue } = this.state;
    let { activeTab } = this.props;
    let search = searchValue.toLocaleLowerCase().trim();
    let filteredList =
      search.length > 0
        ? userList.filter((list) => {
            const {
              crops = [],
              memberFirstName = "",
              memberID = "",
              memberLastName = null,
              village = null,
              firstName = "",
              lastName = null,
              figName = null,
              bodID = "",
              staffId = "",
            } = list;
            if (activeTab === "1") {
              // if (crops.includes(search)) {
              //   return list;
              // } else
              if (
                String(memberFirstName)
                  .concat(" ", memberLastName ? memberLastName : "")
                  .toLocaleLowerCase()
                  .includes(search) ||
                String(memberID).toLocaleLowerCase().includes(search)
                //  ||
                // (village &&
                //   String(village).toLocaleLowerCase().includes(search)) ||
                // (figName &&
                //   String(figName).toLocaleLowerCase().includes(search))
              ) {
                return list;
              }
            } else if (activeTab === "2") {
              if (
                String(firstName)
                  .concat(" ", lastName ? lastName : "")
                  .toLocaleLowerCase()
                  .includes(search) ||
                String(bodID).toLocaleLowerCase().includes(search) ||
                (village &&
                  String(village).toLocaleLowerCase().includes(search))
              ) {
                return list;
              }
            } else if (activeTab === "3") {
              if (
                String(memberFirstName)
                  .concat(" ", lastName ? lastName : "")
                  .toLocaleLowerCase()
                  .includes(search) ||
                String(staffId).toLocaleLowerCase().includes(search)
              ) {
                return list;
              }
            }
          })
        : userList;
    // selecteduserList = [];
    this.setState({ selecteduserList, isSelectAll: !isSelectAll }, () => {
      filteredList.map((list) => {
        if (isChecked === true) {
          list.isChecked = false;
          selecteduserList = [];
        } else {
          list.isChecked = true;
          if (activeTab === "1") {
            selecteduserList.push(list.memberID);
          } else if (activeTab === "2") {
            selecteduserList.push(list.bodID);
          } else if (activeTab === "3") {
            selecteduserList.push(list.staffId);
          }
        }
      });
      let uniqueList = [...new Set(selecteduserList)];
      if (
        uniqueList?.length === 0 &&
        searchValue?.length === 0 &&
        isSelectAll
      ) {
        this.cropsRef.current.resetSelectedValues();
        this.villageRef.current.resetSelectedValues();
        this.figRef.current.resetSelectedValues();
        this.setState({
          userList,
          selecteduserList: [],
          searchedUserList: [],
          filterOptions: [],
        });
        return null;
      }
      this.setState({ selecteduserList: uniqueList });
    });
  };

  //handle submit
  handleSubmit = () => {
    let { optionSelected } = this.state;
    if (optionSelected === "agmMeeting") {
      this.validator.fields.bodDate = true;
      this.validator.errorMessages.bodDate = null;
      this.validator.fields.productName = true;
      this.validator.errorMessages.productName = null;
    } else if (optionSelected === "BODMeeting") {
      this.validator.fields.agmDate = true;
      this.validator.errorMessages.agmDate = null;
      this.validator.fields.productName = true;
      this.validator.errorMessages.productName = null;
    } else if (
      optionSelected === "procurement" ||
      optionSelected === "inputProductAvailabitliyt"
    ) {
      this.validator.fields.agmDate = true;
      this.validator.errorMessages.agmDate = null;
      this.validator.fields.bodDate = true;
      this.validator.errorMessages.bodDate = null;
    }
    if (this.validator.allValid()) {
      let { activeTab } = this.props;
      let {
        selecteduserList,
        message,
        userList,
        agmDate,
        optionSelected,
        optionLists,
        fpoName,
        productName,
      } = this.state;
      // if (agmDate !== "" || productName !== "") {
      if (selecteduserList.length !== 0) {
        let messageData = "";
        this.setState({ isButtonClicked: true });
        if (selecteduserList.length === userList.length) {
          optionLists.map((list) => {
            if (list.value == optionSelected) {
              messageData = String(
                list.messageData.replace(
                  "{#var#}",
                  productName !== ""
                    ? productName
                    : agmDate !== ""
                    ? moment(agmDate).format("DD/MM/YYYY")
                    : "_______"
                )
              ).replace("{#fpoName#}", fpoName);
            }
          });
          let body = {
            sendAll: true,
            message: messageData,
            userType:
              activeTab === "1"
                ? "member"
                : activeTab === "2"
                ? "bod"
                : activeTab === "3"
                ? "staff"
                : null,
            smsType:
              optionSelected === "agmMeeting"
                ? "Annual General Meeting"
                : optionSelected === "BODMeeting"
                ? "BOD Meeting"
                : optionSelected === "inputProductAvailabitliyt"
                ? "Input Product availability"
                : optionSelected === "procurement"
                ? "Procurement"
                : null,
          };
          if (activeTab === "1") {
            this.props
              .sendSmsForMember(body)
              .then(() => {
                this._clearData();
              })
              .catch(() => {
                this.setState({
                  isButtonClicked: false,
                });
              });
          } else if (activeTab === "2") {
            this.props
              .sendSmsForBod(body)
              .then(() => {
                this._clearData();
              })
              .catch(() => {
                this.setState({
                  isButtonClicked: false,
                });
              });
          } else if (activeTab === "3") {
            this.props
              .sendSmsForStaff(body)
              .then(() => {
                this._clearData();
              })
              .catch(() => {
                this.setState({
                  isButtonClicked: false,
                });
              });
          }
        } else {
          optionLists.map((list) => {
            if (list.value == optionSelected) {
              messageData = String(
                list.messageData.replace(
                  "{#var#}",
                  productName !== ""
                    ? productName
                    : agmDate !== ""
                    ? moment(agmDate).format("DD/MM/YYYY")
                    : "_______"
                )
              ).replace("{#fpoName#}", fpoName);
            }
          });
          let body = {
            sendAll: false,
            message: messageData,
            memberIds: selecteduserList,
            staffIds: selecteduserList,
            bodIds: selecteduserList,
            userType:
              activeTab === "1"
                ? "member"
                : activeTab === "2"
                ? "bod"
                : activeTab === "3"
                ? "staff"
                : null,
            smsType:
              optionSelected === "agmMeeting"
                ? "Annual General Meeting"
                : optionSelected === "BODMeeting"
                ? "BOD Meeting"
                : optionSelected === "inputProductAvailabitliyt"
                ? "Input Product availability"
                : optionSelected === "procurement"
                ? "Procurement"
                : null,
          };
          if (activeTab === "1") {
            delete body.staffIds;
            delete body.bodIds;
            this.props
              .sendSmsForMember(body)
              .then(() => {
                this._clearData();
              })
              .catch(() => {
                this.setState({
                  isButtonClicked: false,
                });
              });
          } else if (activeTab === "2") {
            delete body.memberIds;
            delete body.staffIds;
            this.props
              .sendSmsForBod(body)
              .then(() => {
                this._clearData();
              })
              .catch(() => {
                this.setState({
                  isButtonClicked: false,
                });
              });
          } else if (activeTab === "3") {
            delete body.memberIds;
            delete body.bodIds;
            this.props
              .sendSmsForStaff(body)
              .then(() => {
                this._clearData();
              })
              .catch(() => {
                this.setState({
                  isButtonClicked: false,
                });
              });
          }
        }
      } else {
        Toast({ type: "error", message: "Please select atleast one user" });
      }
      // } else {
      //   if (optionSelected == "BODMeeting" || optionSelected == "agmMeeting") {
      //     Toast({
      //       type: "error",
      //       message: `Please select ${
      //         optionSelected == "agmMeeting" ? "AGM" : "BOD"
      //       } meeting date`,
      //     });
      //   } else {
      //     Toast({ type: "error", message: "Please enter the product name" });
      //   }
      // }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  //clear data
  _clearData = () => {
    let { selecteduserList, userList } = this.state;
    this.validator.hideMessages();
    selecteduserList = [];
    userList.map((list) => {
      list.isChecked = false;
    });
    this.setState({
      selecteduserListL: [],
      message: "",
      userList,
      isButtonClicked: false,
      agmDate: "",
      productName: "",
      fpoName: "",
      optionSelected: "",
      searchValue: "",
      lang: "",
      langCode: "",
      isSelectAll: false,
    });
    this.props.toggle();
  };

  //close popup
  closepopup = () => {
    let { selecteduserList, userList } = this.state;
    selecteduserList = [];
    userList.map((list) => {
      list.isChecked = false;
    });
    this._clearData();
    this.setState({ selecteduserList, userList }, () => {
      this.validator.hideMessages();
    });
  };

  //search inputs
  handleSearchInput = (event) => {
    let { selecteduserList, pageMeta } = this.state;
    let { value } = event.target;
    if (selecteduserList?.length > 0) {
      this.setState({
        isSelectAll: false,
      });
    }
    debounceFunction(
      () =>
        this.setState({
          searchValue: value,
          searchPage: 1,
          pageMeta: { ...pageMeta, page: 1 },
        }),
      500
    );
  };

  handlePageChange = ({ page }, isSearch) => {
    let { pageMeta } = this.state;
    if (isSearch) {
      this.setState({
        searchPage: page,
      });
    } else {
      this.setState({
        pageMeta: { ...pageMeta, page },
      });
    }
  };

  onSelect = (list, changingList, method) => {
    let { filterOptions, searchedUserList } = this.state;
    let concatinateList = filterOptions.concat(list);
    let filteredConcatenatedList = (concatinateList = concatinateList.filter(
      (value, index, self) =>
        index ===
        self.findIndex(
          (list) => list.value === value.value && list.label === value.label
        )
    ));

    if (method === "remove") {
      filteredConcatenatedList = filteredConcatenatedList.filter(function (
        item
      ) {
        return item.value !== changingList.value;
      });
    }

    this.setState({ filterOptions: filteredConcatenatedList });
    let { userList } = this.state;
    let newArray = [];
    let unCheck = [];
    for (let i = 0; i < filteredConcatenatedList.length; i++) {
      userList.filter((newList) => {
        const { crops = [], village = null, figName = null } = newList;
        let filterBy =
          filteredConcatenatedList[i].type === "crops"
            ? crops
            : filteredConcatenatedList[i].type === "village"
            ? village
            : figName;
        let filterMethod =
          filteredConcatenatedList[i].type === "crops"
            ? filterBy.includes(filteredConcatenatedList[i].value)
            : filterBy === filteredConcatenatedList[i].value;
        if (filterMethod) {
          newArray.push(newList);
        }
      });
    }

    let searchedList = [];
    for (let i = 0; i < searchedUserList.length; i++) {
      userList.filter((newList) => {
        if (searchedUserList[i] === newList?.memberID) {
          searchedList.push(newList);
        }
      });
    }

    let concatenateTwoArrays = newArray.concat(searchedList);
    let setSelectedUserList = [];
    concatenateTwoArrays.map((list) => {
      list.isChecked = true;
      setSelectedUserList.push(list.memberID);
    });

    if (filteredConcatenatedList.length === 0 && searchedList.length === 0) {
      userList.map((list) => {
        list.isChecked = false;
      });
    }

    let uniqueList = [...new Set(setSelectedUserList)];
    let concatenatedList = uniqueList;
    let finalList = [...new Set(concatenatedList)];

    unCheck = userList.filter(function (item) {
      return finalList.indexOf(item.memberID) === -1;
    });

    unCheck.map((list) => {
      list.isChecked = false;
    });

    this.setState({ selecteduserList: finalList });
  };

  render() {
    let {
      userList = [],
      isLoading,
      selecteduserList = [],
      isSelectAll,
      message,
      searchValue,
      optionLists = [],
      optionSelected,
      agmDate,
      isButtonClicked,
      fpoName,
      optionLang,
      lang,
      langCode,
      productName,
      pageMeta,
      searchPage,
      cropOptions,
      villageOptions,
      figOptions,
    } = this.state;

    let {
      className = "modal-dialog-centered send-message",
      isOpen = true,
      activeTab,
      smsRun,
    } = this.props;

    let search = searchValue.toLocaleLowerCase().trim();

    let userData =
      search.length > 0
        ? userList.filter((list) => {
            const {
              crops = [],
              memberFirstName = "",
              memberID = "",
              memberLastName = null,
              village = null,
              firstName = "",
              lastName = null,
              figName = null,
              bodID = "",
              staffId = "",
            } = list;
            if (activeTab === "1") {
              // if (crops.includes(search)) {
              //   return list;
              // } else
              if (
                String(memberFirstName)
                  .concat(" ", memberLastName ? memberLastName : "")
                  .toLocaleLowerCase()
                  .includes(search) ||
                String(memberID).toLocaleLowerCase().includes(search)
                //  ||
                // (village &&
                //   String(village).toLocaleLowerCase().includes(search)) ||
                // (figName &&
                //   String(figName).toLocaleLowerCase().includes(search))
              ) {
                return list;
              }
            } else if (activeTab === "2") {
              if (
                String(firstName)
                  .concat(" ", lastName ? lastName : "")
                  .toLocaleLowerCase()
                  .includes(search) ||
                String(bodID).toLocaleLowerCase().includes(search) ||
                (village &&
                  String(village).toLocaleLowerCase().includes(search))
              ) {
                return list;
              }
            } else if (activeTab === "3") {
              if (
                String(firstName)
                  .concat(" ", lastName ? lastName : "")
                  .toLocaleLowerCase()
                  .includes(search) ||
                String(staffId).toLocaleLowerCase().includes(search)
              ) {
                return list;
              }
            }
          })
        : selecteduserList?.length > 0
        ? userList.sort((item) => {
            if (item.isChecked) {
              return -1;
            }
            if (!item.isChecked) {
              return 1;
            }
            return 0;
          })
        : userList;

    let tooltipContent = [
      {
        target: ".sms-three",
        content: "Select this to send message to all the members of the FPO",
        disableBeacon: true,
        isFixed: true,
      },
      {
        target: ".sms-four",
        // content: "Search for specific farmers by name",
        content:
          "Search for specific farmers by name, Crop type or Village name (use select all for selecting the searched items all at once)",
        disableBeacon: true,
        isFixed: true,
      },
    ];

    if (userList?.length === 0) {
      delete tooltipContent[0];
    }

    // let isMeetingOption =
    //   optionSelected === "agmMeeting" || optionSelected === "BODMeeting";

    return (
      <Modal isOpen={isOpen} className={className} size={"lg"}>
        <Joyride
          steps={tooltipContent}
          run={smsRun}
          callback={this.props.smsCallback}
          continuous
          hideBackButton
          hideCloseButton
          scrollToFirstStep
          showProgress
          showSkipButton
          disableScrolling
          styles={{
            options: {
              zIndex: 10000,
            },
            buttonNext: {
              color: "#FFFFFF",
              backgroundColor: "#0a8c5b",
            },
          }}
        />
        <div className="send-section">
          <div className="modal-label mb-3">
            <h5>Send message</h5>
            <i
              onClick={() => this.closepopup()}
              className="icon-close cursor-pointer"
            />
          </div>
          <div id="modal-container-send-message">
            <div className="px-3 py-3">
              <NormalSelect
                isHalfWidth={true}
                label={"Select Language"}
                placeholder="Select"
                value={lang}
                arrow={true}
                name="lang"
                options={optionLang}
                handleChange={this.handleInput}
                required={true}
              />
              <div className="row d-flex align-items-center">
                <div className="col-8 ml-auto">
                  {this.validator.message("optionSelected", lang, "required")}
                </div>
              </div>
            </div>
            {lang !== "" && (
              <div className="px-3 py-3">
                <NormalSelect
                  isHalfWidth={true}
                  label={"Select SMS type"}
                  placeholder="Select"
                  value={optionSelected}
                  arrow={true}
                  name="optionSelected"
                  options={optionLists}
                  handleChange={this.handleInput}
                  required={true}
                />
                <div className="row d-flex align-items-center">
                  <div className="col-8 ml-auto">
                    {this.validator.message(
                      "optionSelected",
                      optionSelected,
                      "required"
                    )}
                  </div>
                </div>
              </div>
            )}
            {optionSelected != "" &&
            optionSelected != "agmMeeting" &&
            optionSelected != "BODMeeting" ? (
              <Fragment>
                <ReactTransliterate
                  offsetX={220}
                  renderComponent={(props) => (
                    <div className="px-3 pb-3">
                      {/* <div className={`${!isMeetingOption && "sms-one"}`}> */}
                      <NormalInput
                        isHalfWidth={true}
                        translate={props}
                        label="Enter product name"
                        type="text"
                        placeholder="Enter here"
                        name="productName"
                        required={true}
                        max={50}
                      />
                      <div className="row d-flex align-items-center">
                        <div className="col-8 ml-auto">
                          {this.validator.message(
                            "productName",
                            productName,
                            "required"
                          )}
                        </div>
                      </div>
                      {/* </div> */}
                    </div>
                  )}
                  value={productName}
                  onChangeText={(text) => {
                    this.setState({
                      productName: text,
                    });
                  }}
                  lang={langCode}
                  enabled={langCode != "en"}
                />
              </Fragment>
            ) : null}
            {optionSelected != "" &&
            (optionSelected == "agmMeeting" ||
              optionSelected == "BODMeeting") ? (
              <div className="px-3 pb-3">
                {/* <div className={`${isMeetingOption && "sms-one"}`}> */}
                <NormalDate
                  isIcon={true}
                  isHalfWidth={true}
                  value={agmDate}
                  name="agmDate"
                  placeholder="DD/MM/YYYY"
                  onChange={this.handleInput}
                  mindate={true}
                  maxdate={false}
                  timeInput={false}
                  label={`Enter ${
                    optionSelected == "agmMeeting"
                      ? "AGM date"
                      : "BOD meeting date"
                  }`}
                  required={true}
                />
                <div className="row d-flex align-items-center">
                  <div className="col-8 ml-auto">
                    {this.validator.message(
                      optionSelected === "agmMeeting" ? "agmDate" : "bodDate",
                      agmDate,
                      "required"
                    )}
                  </div>
                </div>
                {/* </div> */}
              </div>
            ) : null}
            {optionSelected !== "" ? (
              <ReactTransliterate
                offsetX={220}
                renderComponent={(props) => (
                  <div className="px-3 pb-3">
                    <div className="sms-two">
                      <NormalInput
                        isHalfWidth={true}
                        translate={props}
                        label="Enter FPO name"
                        type="text"
                        placeholder="Enter here"
                        name="fpoName"
                        required={true}
                        max={75}
                      />
                      <div className="row d-flex align-items-center">
                        <div className="col-8 ml-auto">
                          {this.validator.message(
                            "fpoName",
                            fpoName,
                            "required"
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                value={fpoName}
                onChangeText={(text) => {
                  this.setState({
                    fpoName: text,
                  });
                }}
                lang={langCode}
                enabled={langCode != "en"}
              />
            ) : null}

            {optionLists.map((list, index) => {
              return list.value == optionSelected ? (
                <div className="send-message px-3 pb-4" key={index}>
                  <p className="text-black-1c">
                    {productName === "" && agmDate === "" && fpoName == ""
                      ? String(
                          list.messageData.replace("{#var#}", "_______")
                        ).replace("{#fpoName#}", "_______")
                      : String(
                          list.messageData.replace(
                            "{#var#}",
                            productName !== ""
                              ? productName
                              : agmDate !== ""
                              ? moment(agmDate).format("DD/MM/YYYY")
                              : "_______"
                          )
                        ).replace(
                          "{#fpoName#}",
                          fpoName !== "" ? fpoName : "_______"
                        )}
                  </p>
                </div>
              ) : null;
            })}
            {userList.length !== 0 && (
              <div className="px-3" id="check-in">
                <div className="d-flex sms-three">
                  <h5 className="text-green-18 fs-16 mr-3 line-height-24 font-weight-bold">
                    Select all
                  </h5>
                  <NormalCheckbox
                    className="custom-checkbox check-box"
                    value=""
                    name={"toggleEvent"}
                    checked={isSelectAll}
                    onChange={() => this.handleToggleForSelectAll(isSelectAll)}
                  />
                </div>
              </div>
            )}
            <div className="col-12">
              <input
                type="text"
                className="form-control form-control-sa sms-four"
                placeholder={
                  activeTab === "1"
                    ? "Search by name"
                    : "Search by name or village"
                }
                onChange={this.handleSearchInput}
              />
            </div>
            <div className="col-12">
              <Multiselect
                options={cropOptions}
                onSelect={(selectedList, selectedItem) =>
                  this.onSelect(selectedList, selectedItem, "add")
                }
                onRemove={(selectedList, removedItem) =>
                  this.onSelect(selectedList, removedItem, "remove")
                }
                displayValue="label"
                showCheckbox={true}
                placeholder="Filter by crops"
                closeIcon={true}
                showArrow={true}
                ref={this.cropsRef}
                className="mt-2"
              />
              <Multiselect
                options={villageOptions}
                onSelect={(selectedList, removedItem) =>
                  this.onSelect(selectedList, removedItem, "add")
                }
                onRemove={(selectedList, removedItem) =>
                  this.onSelect(selectedList, removedItem, "remove")
                }
                displayValue="label"
                showCheckbox={true}
                placeholder="Filter by village"
                closeIcon={true}
                showArrow={true}
                ref={this.villageRef}
                className="mt-2"
              />
              <Multiselect
                options={figOptions}
                onSelect={(selectedList, removedItem) =>
                  this.onSelect(selectedList, removedItem, "add")
                }
                onRemove={(selectedList, removedItem) =>
                  this.onSelect(selectedList, removedItem, "remove")
                }
                displayValue="label"
                showCheckbox={true}
                placeholder="Filter by FIG"
                closeIcon={true}
                showArrow={true}
                ref={this.figRef}
                className="mt-2"
              />
            </div>
            {isLoading ? (
              <Fragment>
                {userData.length !== 0 ? (
                  userData
                    .slice(
                      search.length == 0
                        ? (pageMeta.page - 1) * 10
                        : (searchPage - 1) * 10,
                      search.length == 0 ? pageMeta.page * 10 : searchPage * 10
                    )
                    .map((list, index) => {
                      let {
                        memberFirstName = "",
                        memberLastName = "",
                        contact = null,
                        isChecked,
                        firstName = "",
                        crops = [],
                        lastName = "",
                        mobileNumber = "",
                        memberID,
                        bodID,
                        village = null,
                        figName = null,
                        staffId,
                      } = list;
                      return (
                        <div key={index}>
                          <div
                            className={`px-3 d-flex justify-content-between align-items-center ${
                              isChecked ? "bg-blue-f9" : "bg-white"
                            }`}
                          >
                            <div className="details m-0" id={`sms-${index}`}>
                              {activeTab === "1" ? (
                                <div className="">
                                  <p className="user-name">
                                    {memberFirstName} {memberLastName}
                                  </p>
                                  <p className="contact">{contact}</p>
                                </div>
                              ) : (
                                <div className="">
                                  <p className="user-name">
                                    {firstName} {lastName}
                                  </p>
                                  <p className="contact">{mobileNumber}</p>
                                </div>
                              )}
                              <div>
                                <div>
                                  <b>Crops : </b>
                                  <span>
                                    {crops.length > 0 ? crops.join(", ") : "-"}
                                  </span>
                                </div>
                                <div>
                                  <b>Village : </b>
                                  <span>{village ? village : "-"}</span>
                                </div>
                                <div>
                                  <b>FIG name : </b>
                                  <span>{figName ? figName : "-"}</span>
                                </div>
                              </div>
                            </div>
                            <div className="" id="check-in">
                              <NormalCheckbox
                                className="custom-checkbox check-box"
                                value=""
                                name={`toggleEvent-${index}`}
                                checked={isChecked}
                                onChange={() =>
                                  this.handleToggle(
                                    memberID
                                      ? memberID
                                      : bodID
                                      ? bodID
                                      : staffId
                                  )
                                }
                              />
                            </div>
                          </div>
                          <div id="hr-line"></div>
                        </div>
                      );
                    })
                ) : (
                  <p className="py-4 mb-0 text-black-50 fs-22 line-height-25 text-center">
                    {"No data found"}
                  </p>
                )}
              </Fragment>
            ) : (
              <CommonPageLoader />
            )}
          </div>
        </div>
        {(search.length == 0
          ? pageMeta.pageCount > 1
          : Math.ceil(userData.length / 10) > 1) && (
          <Pagination
            isTotalEntriesNeeded={true}
            totalMembers={
              search.length == 0 ? userList.length : userData.length
            }
            handleChange={(e) =>
              this.handlePageChange(e, search.length > 0 ? true : false)
            }
            pageMeta={
              search.length == 0
                ? pageMeta
                : {
                    pageCount: Math.ceil(userData.length / 10),
                    page: searchPage,
                  }
            }
          />
        )}
        <div className="d-flex justify-content-end selected-details align-items-center px-3">
          {selecteduserList.length !== 0 ? (
            <p>{selecteduserList.length} Selected</p>
          ) : null}
          <button
            className="col-3 btn w-100 text-white bg-green d-flex align-items-center justify-content-center "
            onClick={() => {
              this.handleSubmit();
            }}
            disabled={isButtonClicked}
          >
            Send
          </button>
        </div>
      </Modal>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getListOfMembersToSms,
      sendSmsForMember,
      getListOfStaffToSms,
      sendSmsForStaff,
      getListOfBODToSms,
      sendSmsForBod,
      getMessageDetails,
      getMessageDetailsByLanguage,
      getSmsLanguage,
    },
    dispatch
  );
};
let component = SendSmsPopupClass;

export const SendSmsPopup = connect(null, mapDispatchToProps)(component);
