import React, { Component } from "react";

export class Pagination extends Component {
  handlePagination = (direction, num = null) => {
    try {
      let { page = 1 } = this.props.pageMeta || {};
      let pageNumber = eval(`${page} ${direction} ${1}`);

      this.props.handleChange({ page: num ? num : pageNumber });
    } catch (err) {
      console.log(err);
    }
  };

  handleLastPage = (pageCount) => {
    this.props.handleChange({ page: pageCount });
  };

  render() {
    let { uploadDocument, totalDocuments = false } = this.props;
    let { pageCount = uploadDocument ? totalDocuments : 10, page = 1 } =
      this.props.pageMeta || {};
    if (pageCount <= 1) {
      return "";
    }

    let { totalMembers = 0, isTotalEntriesNeeded = false } = this.props;

    return (
      <div className="pagination-area flex-column">
        {isTotalEntriesNeeded ? (
          <div>
            <p className="fs-16 mb-2 fw-600 text-black-1c d-flex justify-content-center align-items-center pr-4">
              You have total of {totalMembers} members
            </p>
          </div>
        ) : null}
        <div className="d-flex text-center w-100 justify-content-center pr-4">
          <p className="m-0 d-flex align-items-center" style={{ gap: "10px" }}>
            <button
              className={`btn-effect table-left-arrow ${
                page == 1 ? "disabled" : "cursor-pointer"
              }`}
              onClick={() => page != 1 && this.handlePagination("-", 1)}
            >
              <span className="icon-page-left" />
            </button>
            <button
              className={`btn-effect table-left-arrow ${
                page == 1 ? "disabled" : "cursor-pointer"
              }`}
              disabled={page == 1}
              onClick={() => page != 1 && this.handlePagination("-")}
            >
              Previous
            </button>
            {page == pageCount && (
              <span
                className="total-page cursor-pointer"
                onClick={() => this.handlePagination("+", 1)}
              >
                1
              </span>
            )}
            <span className="current-page">{page}</span>
            {page !== pageCount && (
              <span
                className="total-page cursor-pointer"
                onClick={() =>
                  pageCount !== page && this.handleLastPage(pageCount)
                }
              >
                {pageCount}
              </span>
            )}
            <button
              className={`btn-effect table-right-arrow ${
                page == pageCount ? "disabled" : "cursor-pointer"
              }`}
              disabled={page == pageCount}
              onClick={() => page != pageCount && this.handlePagination("+")}
            >
              Next
            </button>
            <button
              className={`btn-effect table-right-arrow ${
                page == pageCount ? "disabled" : "cursor-pointer"
              }`}
              onClick={() =>
                page != pageCount && this.handlePagination("+", pageCount)
              }
            >
              <span className="icon-page-right" />
            </button>
          </p>
        </div>
      </div>
    );
  }
}
