import React, { Component } from "react";
import { Navbar } from "../../component/common";
import { ViewMembership } from "../../component/Members";
import { UserAuth } from "helpers/UserAuth";
class ViewMemberClass extends Component {
  render() {
    return (
      <div>
        <div className="normal-header">{/* <Navbar /> */}</div>
        <ViewMembership id={this.props.match.params.id} />
      </div>
    );
  }
}

export const ViewMember = UserAuth(ViewMemberClass, { service: "membership" });
