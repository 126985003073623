import React, { Component } from "react";
import { getRegistrarById } from "action/ComplianceAct";

import { CommonPageLoader } from "component/common";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { history } from "service/helpers/index";

import { getFilePathDownload } from "service/api";

import moment from "moment";

import "../Licenseview/style.scss";

const arrow_left = require("assets/svg/add_member/arrow_left.svg");

export class RegisterViewClass extends Component {
  constructor(props) {
    super();
    this.state = {
      registerDetails: {},
      isloading: false,
    };
  }
  componentDidMount() {
    let { id } = this.props;
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    this._getData(id);
  }

  _getData = (id) => {
    let { getRegistrarById } = this.props;

    this.setState({ isloading: true });

    getRegistrarById(id).then((data) => {
      this.setState({
        registerDetails: data,
        isloading: false,
      });
    });
  };

  render() {
    let { registerDetails = {}, isloading } = this.state;

    let {
      auditReportFileUrl,
      auditReportFileName,
      balanceSheetFileName,
      balanceSheetFileUrl,
      directorsReportFileName,
      directorsReportFileUrl,
      profitLossAccountFileName,
      profitLossAccountFileUrl,
      consultatName,
      dateOfFiling,
      dueDate,
      fymYear,
      location,
      remarks,
    } = registerDetails;

    return (
      <div className="view-bod">
        <div className="d-flex my-3 container-sa px-0">
          <div
            className="d-flex"
            onClick={() => history.push("/fpo/compliance")}
          >
            <img className="cursor-pointer" src={arrow_left} alt="back" />
            <p className="fs-18 font-weight-bold font-Gilroy text-black mb-0 cursor-pointer ml-3">
              {"Back"}
            </p>
          </div>
        </div>
        {!isloading ? (
          <div className="bg-area py-4">
            <div className="container">
              <div className="bg-white p-5 shadow mx-5">
                <div className="row">
                  <div className="col-6">
                    <div className="row">
                      <div className="col-5">
                        <p className="text-black font-weight-bold fs-16">
                          Financial year :
                        </p>
                      </div>
                      <div className="col-7">
                        <p className="text-black-50 fs-16">
                          {fymYear ? fymYear : "-"}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-5">
                        <p className="text-black font-weight-bold fs-16">
                          Date of filing
                        </p>
                      </div>
                      <div className="col-7">
                        <p className="text-black-50 fs-16">
                          {dateOfFiling
                            ? moment(dateOfFiling).format("DD MMMM YYYY")
                            : "-"}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-5">
                        <p className="text-black font-weight-bold fs-16">
                          Consultant name :
                        </p>
                      </div>
                      <div className="col-7">
                        <p className="text-black-50 fs-16">
                          {consultatName ? consultatName : "-"}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-5">
                        <p className="text-black font-weight-bold fs-16">
                          Upload Balance Sheet
                        </p>
                      </div>
                      <div className="col-7">
                        <p className="text-black-50 fs-16">
                          {balanceSheetFileName ? (
                            <a
                              className="fs-16 text-green-18 text-underline cursor-pointer"
                              href={getFilePathDownload + balanceSheetFileUrl}
                              download
                            >
                              {"Download"}
                            </a>
                          ) : (
                            <p className="text-black-50 fs-16">{"-"}</p>
                          )}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-5">
                        <p className="text-black font-weight-bold fs-16">
                          Upload Audit Report :
                        </p>
                      </div>
                      <div className="col-7">
                        <p className="text-black-50 fs-16">
                          {auditReportFileName ? (
                            <a
                              className="fs-16 text-green-18 text-underline cursor-pointer"
                              href={getFilePathDownload + auditReportFileUrl}
                              download
                            >
                              {"Download"}
                            </a>
                          ) : (
                            <p className="text-black-50 fs-16">{"-"}</p>
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="row">
                      <div className="col-5">
                        <p className="text-black font-weight-bold fs-16">
                          Due date :
                        </p>
                      </div>
                      <div className="col-7">
                        <p className="text-black-50 fs-16">
                          {dueDate
                            ? moment(dueDate).format("DD MMMM YYYY")
                            : "-"}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-5">
                        <p className="text-black font-weight-bold fs-16">
                          Location filed :
                        </p>
                      </div>
                      <div className="col-7">
                        <p className="text-black-50 fs-16">
                          {location ? location : "-"}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-5">
                        <p className="text-black font-weight-bold fs-16">
                          Remarks :
                        </p>
                      </div>
                      <div className="col-7">
                        <p className="text-black-50 fs-16">
                          {remarks ? remarks : "-"}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-5">
                        <p className="text-black font-weight-bold fs-16">
                          Upload Profit &amp; Loss Statement :
                        </p>
                      </div>
                      <div className="col-7">
                        <p className="text-black-50 fs-16">
                          {profitLossAccountFileName ? (
                            <a
                              className="fs-16 text-green-18 text-underline cursor-pointer"
                              href={
                                getFilePathDownload + profitLossAccountFileUrl
                              }
                              download
                            >
                              {"Download"}
                            </a>
                          ) : (
                            <p className="text-black-50 fs-16">{"-"}</p>
                          )}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-5">
                        <p className="text-black font-weight-bold fs-16">
                          Upload Director’s report
                        </p>
                      </div>
                      <div className="col-7">
                        <p className="text-black-50 fs-16">
                          {directorsReportFileName ? (
                            <a
                              className="fs-16 text-green-18 text-underline cursor-pointer"
                              href={
                                getFilePathDownload + directorsReportFileUrl
                              }
                              download
                            >
                              {"Download"}
                            </a>
                          ) : (
                            <p className="text-black-50 fs-16">{"-"}</p>
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <>
            <CommonPageLoader />
          </>
        )}
        <div className="view-members"></div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getRegistrarById,
    },
    dispatch
  );
};
let component = RegisterViewClass;

export const RegisterView = connect(null, mapDispatchToProps)(component);
