import React, { Component } from "react";
import { Col, Row } from "reactstrap";
import { CustomDate } from "component/common";
import moment from "moment";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { updateCalenderTask } from "action/HomeAct";
import { getDescriptionData } from "service/helperFunctions";
import "./index.scss";

export class ComplianceCalenderPreClass extends Component {
  state = {
    calenderList: [],
    highlightDates: [],
    tasks: [],
    month: "",
    year: "",
    calenderContentstatus: [],
    isLoading: false,
  };
  setMonthYear = ({ month = "12", year = "2020" }) => {
    this.setState({
      month,
      year,
    });
  };
  handleUpdateTask = (id, value) => {
    this.setState({
      isLoading: true,
    });
    let body = {
      fpoTasksId: id,
      taskStatus: value,
    };
    this.props.updateCalenderTask(body).then((data) => {
      this.props.getList({ month: this.state.month, year: this.state.year });
      this.setState({
        isLoading: false,
      });
    });
  };
  componentDidMount() {
    this.setState({
      calenderList: this.props.list,
      month: this.props.listDate.month,
      year: this.props.listDate.year,
    });
  }
  componentDidUpdate(prevProps) {
    if (prevProps.list !== this.props.list) {
      let listDate = [];
      let listContent = [];
      let listStatus = [];
      this.props.list.length > 0 && !this.props.isAuth
        ? this.props.list.map((list) => {
            listContent.push({
              dueDate: list.date,
              fpoTasks: list.taskDetails,
            });
            list.taskDetails.map((data) => {
              listDate.push(data.dueDate);
            });
          })
        : this.props.list.map((list) => {
            listContent.push({
              dueDate: list.date,
              fpoTasks: list.taskDetails,
            });
            list.taskDetails.map((data) => {
              listDate.push(data.fpoTasks.dueDate);
              listStatus.push(data.taskStatus);
            });
          });
      let finalDate = [...new Set(listDate)];
      this.setState({
        highlightDates: finalDate,
        calenderList: this.props.list,
        tasks: listContent,
        calenderContentstatus: listStatus,
        month: this.props.listDate.month,
        year: this.props.listDate.year,
      });
    }
  }
  render() {
    const {
      calenderList,
      highlightDates,
      tasks,
      calenderContentstatus,
      isLoading,
    } = this.state;
    const { isAuth } = this.props;
    return (
      <div className="compliance-calender">
        <div className="container-sa">
          {!isLoading ? (
            <Row>
              <Col sm="8">
                <div className="calender-section">
                  <div className="title">{"Important dates to remember"}</div>
                  <div className="calender-container">
                    <CustomDate
                      getList={this.props.getList}
                      list={highlightDates}
                      tasks={tasks}
                      setMonthYear={this.setMonthYear}
                      status={calenderContentstatus}
                      isAuth={this.props.isAuth}
                    />
                  </div>
                </div>
              </Col>
              <Col sm="4">
                <div className="calender-events">
                  <div className="title">
                    {this.props.listDate?.month
                      ? moment(this.props.listDate.month).format("MMMM")
                      : null}{" "}
                    {this.props.listDate?.year}
                  </div>
                  {calenderList.length > 0 ? (
                    calenderList.map((list, index) => {
                      return (
                        <div
                          key={index}
                          className={`calender-list mb-3 d-flex justify-content-between ${"bg-green-db"}`}
                          // isAuth ? status == 0 ? 'bg-blue-b5' : status == '1' ? 'bg-green-db' : 'bg-red-ff' :
                        >
                          <div className="w-100">
                            <div className="list-content mb-2">
                              {"Due on "}
                              {list.date
                                ? moment(list.date).format("DD MMM yyyy")
                                : "-"}
                            </div>
                            {list.taskDetails.map((data, taskIndex) => {
                              let status = data.taskStatus;
                              let fpoTasks = isAuth ? data.fpoTasks : data;
                              return (
                                <div
                                  className="d-flex w-100 pb-2 align-items-center"
                                  key={taskIndex}
                                >
                                  <div
                                    className={`${
                                      isAuth &&
                                      localStorage.getItem("userGroupName") ===
                                        "fpo"
                                        ? "w-75"
                                        : "w-100"
                                    }`}
                                  >
                                    <div className="list-content">
                                      <b>Task-{taskIndex + 1} : </b>
                                      {isAuth &&
                                      localStorage.getItem("userGroupName") ===
                                        "fpo"
                                        ? getDescriptionData(
                                            fpoTasks.taskName,
                                            25
                                          )
                                        : getDescriptionData(
                                            fpoTasks.taskName,
                                            40
                                          )}
                                    </div>
                                  </div>
                                  {isAuth &&
                                    localStorage.getItem("userGroupName") ===
                                      "fpo" && (
                                      <div className={`w-25`}>
                                        <div className="action-container ml-2">
                                          <div
                                            className={`tick-container ${
                                              status == "2" ? "inactive" : ""
                                            } ${
                                              status == 0
                                                ? "cursor-pointer"
                                                : ""
                                            }`}
                                            onClick={() =>
                                              status == 0 &&
                                              this.handleUpdateTask(
                                                fpoTasks.fpoTasksId,
                                                "1"
                                              )
                                            }
                                          >
                                            <span className="icon-calender-section-tick"></span>
                                          </div>
                                          <div
                                            className={`cross-container mr-0 ${
                                              status == "1" ? "inactive" : ""
                                            } ${
                                              status == 0
                                                ? "cursor-pointer"
                                                : ""
                                            }`}
                                            onClick={() =>
                                              status == 0 &&
                                              this.handleUpdateTask(
                                                fpoTasks.fpoTasksId,
                                                "2"
                                              )
                                            }
                                          >
                                            <span className="icon-close"></span>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div className="text-center my-5 py-5 fw-600 text-black-1c">
                      {" No task for this month"}
                    </div>
                  )}
                </div>
              </Col>
            </Row>
          ) : (
            <div className="text-center">
              <div className="spinner-border text-secondary" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      updateCalenderTask,
    },
    dispatch
  );
};
let component = ComplianceCalenderPreClass;

export const ComplianceCalenderPre = connect(
  null,
  mapDispatchToProps
)(component);
