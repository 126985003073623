import React, { Component } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination } from "swiper";
import { getDescriptionData } from "service/helperFunctions";
import moment from "moment";
const arrow_left = require("../../../assets/svg/Events/AllEvents/arrow-left.svg");
const arrow_right = require("../../../assets/svg/Events/AllEvents/arrow-right.svg");
const agricloud = require("../../../assets/svg/view_member/agricloud.jpg");
export class MemberAssets extends Component {
  constructor() {
    super();
    SwiperCore.use([Navigation, Pagination]);
  }

  render() {
    let { memberAsset } = this.props;
    const params = {
      spaceBetween: 30,
      draggable: true,
      breakpoints: {
        600: {
          slidesPerView: 1,
        },
        768: {
          slidesPerView: 2,
        },
      },
      navigation: {
        nextEl: "#member-next",
        prevEl: "#member-prev",
      },
    };
    return (
      <div>
        <div className="member-assets d-flex justify-content-between mb-4">
          <div className="member-assets-heading">Assets</div>
          <div className="d-flex align-items-end">
            <input
              type="image"
              src={arrow_left}
              alt="left"
              width="40"
              height="40"
              id="member-prev"
            />
            <input
              type="image"
              src={arrow_right}
              alt="left"
              width="40"
              height="40"
              id="member-next"
            />
          </div>
        </div>
        <div className="member-assets-swiper mb-5 d-flex w-100">
          <Swiper {...params} className="w-100">
            {memberAsset.map((list, index) => {
              return (
                <SwiperSlide key={index}>
                  <div>
                    <div className="member-assets-card d-flex flex-md-row flex-column rounded shadow">
                      <div className="mx-auto w-md-100 w-50">
                        <img src={agricloud} alt="vechicle" />
                      </div>
                      <div className="d-flex flex-column p-3 m-1">
                        <div className="mb-3 member-assets-card-title">
                          {list.assetmaster && list.assetmaster.assetName
                            ? list.assetmaster.assetName
                            : null}
                        </div>
                        <div className="d-flex mb-3 pb-1">
                          <div className="mr-5">
                            <div className="member-assets-card-heading mb-2">
                              {"Asset Type"}
                            </div>
                            <div className="member-assets-card-content">
                              {list.assetmaster && list.assetmaster.assetName
                                ? list.assetmaster.assetName
                                : null}
                            </div>
                          </div>
                          <div className="">
                            <div className="member-assets-card-heading mb-2">
                              {"Quantity"}
                            </div>
                            <div className="member-assets-card-content">
                              {list.quantity ? list.quantity : null}
                            </div>
                          </div>
                        </div>
                        <div className="d-flex flex-wrap gap-4 mb-3 pb-1">
                          <div className="mr-4">
                            <div className="member-assets-card-heading mb-2">
                              {"Acquired on"}
                            </div>
                            <div className="member-assets-card-content">
                              {list.AcquiredOn
                                ? moment(list.AcquiredOn).format("DD MMMM YYYY")
                                : ""}
                            </div>
                          </div>
                          <div className="mr-4">
                            <div className="member-assets-card-heading mb-2">
                              {"Status"}
                            </div>
                            <div className="member-assets-card-content">
                              {list.assetStatus ? list.assetStatus : null}
                            </div>
                          </div>
                          <div className="">
                            <div className="member-assets-card-heading mb-2">
                              {"Location"}
                            </div>
                            <div className="member-assets-card-content">
                              {list.location ? list.location : null}
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className="member-assets-card-heading mb-2">
                            {"Description"}
                          </div>
                          <div>
                            {list.description
                              ? getDescriptionData(list.description, 30)
                              : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>
    );
  }
}
