import React from "react";
import { MultiSelect } from "react-multi-select-component";

const RMMultiSelect = (props) => {
  const { value, onChange, options, label = "", isRequired = false } = props;
  const requiredClass = "text-danger font-weight-bold";

  return (
    <div className="mb-2">
      <label>
        {label}
        {isRequired && <span className={requiredClass}>*</span>}
      </label>
      <MultiSelect
        options={options}
        value={value}
        onChange={onChange}
        labelledBy="Select"
        overrideStrings={{
          selectSomeItems: "Select",
          allItemsAreSelected: "All",
        }}
      />
    </div>
  );
};

export default RMMultiSelect;
