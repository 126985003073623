import {
  onGetAllMasters,
  onGetSurveyDetails,
  onPostSurveyDetails,
} from "action/GradingAct";
import { NormalButton } from "component/common";
import Accordion from "component/RM/SurveyToolComp/includes/common/Accordion";
import React, { Component } from "react";
import { useDispatch } from "react-redux";
import {
  Button,
  Card,
  CardBody,
  Collapse,
  Modal,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { AccountsAndFinance } from "./includes/AccountsAndFinance";
import { BasicFPOInfo } from "./includes/BasicFPOInfo";
import { BasicRmInfo } from "./includes/BasicRmInfo";
import { Compliances } from "./includes/Compliances";
import { FinancialRatios } from "./includes/FinancialRatios";
import { Governance } from "./includes/Governance";
import { HRD } from "./includes/HRD";
import { InputMarketing } from "./includes/InputMarketing";
import { Operations } from "./includes/Operations";
import { OutputMarketing } from "./includes/OutputMarketing";
import { Outreach } from "./includes/Outreach";
import { SocioEconomicAndEnvironmentFactors } from "./includes/SocioEconomicAndEnvironmentFactors";
import { TechnologyAndServices } from "./includes/TechnologyAndServices";
import "./style.scss";

export const SurveyToolComp = (props) => {
  const dispatch = useDispatch();
  const [surverystartsno, setSurverystartsno] = React.useState(1);
  const [surveyDetails, setSurveyDetails] = React.useState({});
  const [fpoDetails, setFpoDetails] = React.useState(null);
  const [submitSurveyClick, setSubmitSurveyClick] = React.useState(0);
  const [showThankYouModal, setShowThankYouModal] = React.useState(false);
  let [showAccordion, setShowAccrodion] = React.useState([1]);
  let surveyDetailsPayload = {};
  let parametersList = []; // 13
  let errorAccordionList = [];

  const queryParams = new URLSearchParams(window?.location?.search);
  const token = queryParams.get("token");
  const fpoIdRoute = queryParams.get("fpoId");
  const rmNameRoute = queryParams.get("rmName");
  const rmEmailRoute = queryParams.get("rmEmail");

  React.useEffect(() => {
    if (token) {
      localStorage.setItem("AuthToken", token);
    }
    setSurveyDetails({
      ...surveyDetails,
      fpoId: token ? fpoIdRoute : localStorage.getItem("fpoId"),
      rmName: token ? rmNameRoute : localStorage.getItem("userName"),
      RM_Email_ID: token ? rmEmailRoute : localStorage.getItem("userEmail"),
    });
    setTimeout(() => {
      dispatch(onGetAllMasters({}));
    }, 1000);
  }, [token]);

  React.useEffect(() => {
    if (parametersList.length === 13) {
      setTimeout(() => {
        dispatch(
          onPostSurveyDetails({
            ...surveyDetailsPayload,
            status: "completed",
          })
        ).then(() => {
          setShowThankYouModal(true);
        });
      }, 1000);
    }
  }, [parametersList]);

  React.useEffect(() => {
    if (token || localStorage.getItem("fpoId")) {
      setTimeout(() => {
        dispatch(
          onGetSurveyDetails({
            fpoId: token ? fpoIdRoute : localStorage.getItem("fpoId"),
          })
        ).then((res) => {
          setSurverystartsno(res?.surveryno);
          if (Object.keys(res?.surveyDetails).length) {
            const userName = localStorage.getItem("userName");
            const userEmail = localStorage.getItem("userEmail");
            res.surveyDetails.rmName = token
              ? rmNameRoute
              : userName
              ? userName
              : res?.surveyDetails?.rmName;
            res.surveyDetails.RM_Email_ID = token
              ? rmEmailRoute
              : userEmail
              ? userEmail
              : res?.surveyDetails?.RM_Email_ID;
            setSurveyDetails(res?.surveyDetails);
          }
          setFpoDetails(res?.fpoDetails);
        });
      }, 1000);
    }
  }, [localStorage.getItem("fpoId")]);

  const handleSubmit = () => {
    errorAccordionList = [];
    surveyDetailsPayload = {};
    parametersList = [];
    setSubmitSurveyClick(submitSurveyClick + 1);
  };

  const toggleAccordion = (id) => {
    let newValue = [...showAccordion];
    if (newValue.includes(id)) {
      let index = newValue.indexOf(id);
      newValue.splice(index, 1);
    } else {
      newValue.push(id);
    }
    setShowAccrodion(newValue);
  };

  const errorAccordion = async (id) => {
    errorAccordionList.push(id);
    setShowAccrodion(errorAccordionList);
  };

  const updateSurvey = (key, value) => {
    parametersList.push(key);
    surveyDetailsPayload = { ...surveyDetailsPayload, ...value };
    if (value.status === "draft") {
      dispatch(
        onPostSurveyDetails({
          status: "draft",
          ...surveyDetails,
          ...value,
        })
      ).then((res) => {
        setSurveyDetails(res);
        if (showAccordion.length >= 1) {
          toggleAccordion(showAccordion[showAccordion.length - 1] + 1);
        }
      });
    }
  };

  const goBack = () => {
    window.history.back();
  };

  return (
    <div className="surveyForm container">
      {/* <h3> FPO Grading Tool - Survey {surverystartsno}</h3> */}
      <div>
        <Accordion>
          <Accordion.Item>
            <Accordion.Header
              id={1}
              onClick={() => {
                toggleAccordion(1);
              }}
              iconClass={showAccordion.includes(1) ? "rotate" : ""}
            >
              Collect GPS of the Place
            </Accordion.Header>
            <Accordion.Body isOpen={showAccordion.includes(1)}>
              <BasicRmInfo
                submitSurveyClick={submitSurveyClick}
                surveyDetails={surveyDetails}
                updateSurvey={updateSurvey}
                errorAccordion={() => {
                  errorAccordion(1);
                }}
              />
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item>
            <Accordion.Header
              id={2}
              onClick={() => {
                toggleAccordion(2);
              }}
              iconClass={showAccordion.includes(2) ? "rotate" : ""}
            >
              Basic details of FPO
            </Accordion.Header>
            <Accordion.Body isOpen={showAccordion.includes(2)}>
              <BasicFPOInfo
                submitSurveyClick={submitSurveyClick}
                surveyDetails={surveyDetails}
                fpoDetails={fpoDetails}
                updateSurvey={updateSurvey}
                errorAccordion={() => {
                  errorAccordion(2);
                }}
              />
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item>
            <Accordion.Header
              id={3}
              onClick={() => {
                toggleAccordion(3);
              }}
              iconClass={showAccordion.includes(3) ? "rotate" : ""}
            >
              Governance
            </Accordion.Header>
            <Accordion.Body isOpen={showAccordion.includes(3)}>
              <Governance
                submitSurveyClick={submitSurveyClick}
                surveyDetails={surveyDetails}
                updateSurvey={updateSurvey}
                errorAccordion={() => {
                  errorAccordion(3);
                }}
              />
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item>
            <Accordion.Header
              id={4}
              onClick={() => {
                toggleAccordion(4);
              }}
              iconClass={showAccordion.includes(4) ? "rotate" : ""}
            >
              Outreach
            </Accordion.Header>
            <Accordion.Body isOpen={showAccordion.includes(4)}>
              <Outreach
                submitSurveyClick={submitSurveyClick}
                surveyDetails={surveyDetails}
                updateSurvey={updateSurvey}
                errorAccordion={() => {
                  errorAccordion(4);
                }}
              />
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item>
            <Accordion.Header
              id={5}
              onClick={() => {
                toggleAccordion(5);
              }}
              iconClass={showAccordion.includes(5) ? "rotate" : ""}
            >
              Compliances
            </Accordion.Header>
            <Accordion.Body isOpen={showAccordion.includes(5)}>
              <Compliances
                submitSurveyClick={submitSurveyClick}
                surveyDetails={surveyDetails}
                updateSurvey={updateSurvey}
                errorAccordion={() => {
                  errorAccordion(5);
                }}
              />
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item>
            <Accordion.Header
              id={6}
              onClick={() => {
                toggleAccordion(6);
              }}
              iconClass={showAccordion.includes(6) ? "rotate" : ""}
            >
              HRD
            </Accordion.Header>
            <Accordion.Body isOpen={showAccordion.includes(6)}>
              <HRD
                submitSurveyClick={submitSurveyClick}
                surveyDetails={surveyDetails}
                updateSurvey={updateSurvey}
                errorAccordion={() => {
                  errorAccordion(6);
                }}
              />
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item>
            <Accordion.Header
              id={7}
              onClick={() => {
                toggleAccordion(7);
              }}
              iconClass={showAccordion.includes(7) ? "rotate" : ""}
            >
              Operations
            </Accordion.Header>
            <Accordion.Body isOpen={showAccordion.includes(7)}>
              <Operations
                submitSurveyClick={submitSurveyClick}
                surveyDetails={surveyDetails}
                updateSurvey={updateSurvey}
                errorAccordion={() => {
                  errorAccordion(7);
                }}
              />
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item>
            <Accordion.Header
              id={8}
              onClick={() => {
                toggleAccordion(8);
              }}
              iconClass={showAccordion.includes(8) ? "rotate" : ""}
            >
              Input Marketing
            </Accordion.Header>
            <Accordion.Body isOpen={showAccordion.includes(8)}>
              <InputMarketing
                submitSurveyClick={submitSurveyClick}
                surveyDetails={surveyDetails}
                updateSurvey={updateSurvey}
                errorAccordion={() => {
                  errorAccordion(8);
                }}
              />
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item>
            <Accordion.Header
              id={9}
              onClick={() => {
                toggleAccordion(9);
              }}
              iconClass={showAccordion.includes(9) ? "rotate" : ""}
            >
              Output Marketing
            </Accordion.Header>
            <Accordion.Body isOpen={showAccordion.includes(9)}>
              <OutputMarketing
                submitSurveyClick={submitSurveyClick}
                surveyDetails={surveyDetails}
                updateSurvey={updateSurvey}
                errorAccordion={() => {
                  errorAccordion(9);
                }}
              />
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item>
            <Accordion.Header
              id={10}
              onClick={() => {
                toggleAccordion(10);
              }}
              iconClass={showAccordion.includes(10) ? "rotate" : ""}
            >
              Accounts and Finance
            </Accordion.Header>
            <Accordion.Body isOpen={showAccordion.includes(10)}>
              <AccountsAndFinance
                submitSurveyClick={submitSurveyClick}
                surveyDetails={surveyDetails}
                updateSurvey={updateSurvey}
                errorAccordion={() => {
                  errorAccordion(10);
                }}
              />
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item>
            <Accordion.Header
              id={11}
              onClick={() => {
                toggleAccordion(11);
              }}
              iconClass={showAccordion.includes(11) ? "rotate" : ""}
            >
              Technology and Services
            </Accordion.Header>
            <Accordion.Body
              isOpen={showAccordion.includes(11)}
              className="px-3"
            >
              <TechnologyAndServices
                submitSurveyClick={submitSurveyClick}
                surveyDetails={surveyDetails}
                updateSurvey={updateSurvey}
                errorAccordion={() => {
                  errorAccordion(11);
                }}
              />
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item>
            <Accordion.Header
              id={12}
              onClick={() => {
                toggleAccordion(12);
              }}
              iconClass={showAccordion.includes(12) ? "rotate" : ""}
            >
              Socio, Economic and Environment Factors
            </Accordion.Header>
            <Accordion.Body
              isOpen={showAccordion.includes(12)}
              className="px-3"
            >
              <SocioEconomicAndEnvironmentFactors
                submitSurveyClick={submitSurveyClick}
                surveyDetails={surveyDetails}
                updateSurvey={updateSurvey}
                errorAccordion={() => {
                  errorAccordion(12);
                }}
              />
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item>
            <Accordion.Header
              id={13}
              onClick={() => {
                toggleAccordion(13);
              }}
              iconClass={showAccordion.includes(13) ? "rotate" : ""}
            >
              Financial Ratios ( can be obtained from audit reports or CAM)
            </Accordion.Header>
            <Accordion.Body
              isOpen={showAccordion.includes(13)}
              className="px-3"
            >
              <FinancialRatios
                submitSurveyClick={submitSurveyClick}
                surveyDetails={surveyDetails}
                updateSurvey={updateSurvey}
                errorAccordion={() => {
                  errorAccordion(13);
                }}
              />
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
      <div className="row  ">
        <div className="col-12 d-flex justify-content-center justify-content-md-end">
          <NormalButton
            label={"Submit Survey " + surverystartsno}
            mainbg={true}
            className="px-3 mt-4 py-2 mx-auto ml-md-auto fs-14 line-height-26 font-Gilroy"
            onClick={handleSubmit}
            isPopup={true}
          />
        </div>
      </div>

      <Modal isOpen={showThankYouModal}>
        <ModalBody>Thank you for submitting the grading form.</ModalBody>
        <ModalFooter>
          <NormalButton
            label={"Go Back"}
            mainbg={true}
            className="px-3 py-2 mx-auto ml-md-auto fs-14 line-height-26 font-Gilroy"
            onClick={goBack}
            isPopup={true}
          />
        </ModalFooter>
      </Modal>
    </div>
  );
};
