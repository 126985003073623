import React, { Fragment } from "react";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import HighchartsReact from "highcharts-react-official";
import moment from "moment-timezone";
import * as Highcharts from "highcharts";

import {
  optionData1,
  optionData2,
  optionData3,
  optionData4,
  optionData5,
  optionData6,
  optionData7,
  optionData8,
  optionDataForFPo,
} from "../Data";
import { CommonPageLoader } from "component/common";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getGradingDetail } from "action/BodAct";
import { getGradingDetails } from "action/GradingAct";
import { getGradingDetailsOfFPO } from "action/RmAct";

export class GradingReportTool extends React.Component {
  state = {
    outreachPercentage: optionData1,
    governance: optionData2,
    operations: optionData3,
    management: optionData4,
    socioEconomic: optionData5,
    technology: optionData6,
    accountsFinance: optionData7,
    compliance: optionData8,
    fpoData: optionDataForFPo,
    isLoading: false,
    fpoName: "",
    isDownload: false,
    fpoGradingDetail: {},
    grades: [
      " A+ : Ready to Excel : 90-100%",
      "A : Ready to Establish : 70-89%",
      "B : Ready to Engage : 50-69%",
      "C : Ready to Enable : 20-49%",
      "D : Ready to Build : 0-19%",
    ],
    compareSurvey: null,
    surveyReport: null,
    hasReport: true,
  };

  //Lifecycles
  componentDidMount() {
    this._getGradingDetail();
  }

  _getGradingDetail = () => {
    const fpoId = localStorage.getItem("fpoId");
    this.setState({ isLoading: true });
    let {
      getGradingDetail,
      id,
      getGradingDetailsOfFPO,
      getGradingDetailsApi,
      isFPO,
    } = this.props;
    console.log("props :-", this.props);
    if (isFPO) {
      if (id) {
        getGradingDetailsOfFPO(id)
          .then((data) => {
            this._setData(data);
          })
          .catch(() => {
            this.setState({ isLoading: false });
          });
      } else {
        getGradingDetail()
          .then((data) => {
            if (data?.fpoGradingDetail) {
              this._setData(data);
            } else {
              this.props.showDownload(false);
              this.setState({ hasReport: false });
            }
          })
          .catch(() => {
            this.setState({ isLoading: false });
          });
      }
    } else {
      getGradingDetailsApi(fpoId)
        .then((data) => {
          if (data?.fpoGradingDetail) {
            this._setData(data);
          } else {
            this.props.showDownload(false);
            this.setState({ hasReport: false });
          }
        })
        .catch(() => {
          this.setState({ isLoading: false });
        });
    }
  };

  _setData = (data) => {
    let {
      outreachPercentage,
      governance,
      operations,
      management,
      socioEconomic,
      technology,
      accountsFinance,
      compliance,
      fpoData,
    } = this.state;
    if (data !== null) {
      let { fpoGradingDetail } = data;

      let {
        outReachPercentage,
        governancePercentage,
        operationsPercentage,
        managementPercentage,
        socioImpactPercentage,
        technologyAndServicesPercentage,
        accountsAndFinancePercentage,
        compliancePercentage,
        fpcPercentage,
        fpoName,
      } = fpoGradingDetail;

      outreachPercentage.series[0].data = [];
      outreachPercentage.series[0].data.push(
        Math.sign(outReachPercentage) !== -1
          ? parseInt((outReachPercentage * 100).toFixed(1))
          : 0
      );

      governance.series[0].data = [];
      governance.series[0].data.push(
        Math.sign(governancePercentage) !== -1
          ? parseInt((governancePercentage * 100).toFixed(1))
          : 0
      );

      operations.series[0].data = [];
      operations.series[0].data.push(
        Math.sign(operationsPercentage) !== -1
          ? parseInt((operationsPercentage * 100).toFixed(1))
          : 0
      );

      management.series[0].data = [];
      management.series[0].data.push(
        Math.sign(managementPercentage) !== -1
          ? parseInt((managementPercentage * 100).toFixed(1))
          : 0
      );

      socioEconomic.series[0].data = [];
      socioEconomic.series[0].data.push(
        Math.sign(socioImpactPercentage) !== -1
          ? parseInt((socioImpactPercentage * 100).toFixed(1))
          : 0
      );

      technology.series[0].data = [];
      technology.series[0].data.push(
        Math.sign(technologyAndServicesPercentage) !== -1
          ? parseInt((technologyAndServicesPercentage * 100).toFixed(1))
          : 0
      );

      accountsFinance.series[0].data = [];
      accountsFinance.series[0].data.push(
        Math.sign(accountsAndFinancePercentage) !== -1
          ? parseInt((accountsAndFinancePercentage * 100).toFixed(1))
          : 0
      );

      compliance.series[0].data = [];
      compliance.series[0].data.push(
        Math.sign(compliancePercentage) !== -1
          ? parseInt((compliancePercentage * 100).toFixed(1))
          : 0
      );

      fpoData.series[0].data = [];
      fpoData.series[0].data.push(
        Math.sign(fpcPercentage) !== -1
          ? parseInt((fpcPercentage * 100).toFixed(1))
          : 0
      );

      this.setState({
        outreachPercentage,
        governance,
        operations,
        management,
        socioEconomic,
        technology,
        accountsFinance,
        compliance,
        fpoData,
        isLoading: false,
        fpoName,
        fpoGradingDetail,
      });
    } else {
      this.setState({
        isNoData: true,
        isLoading: false,
      });
    }
  };

  donwloadScore = () => {
    this.setState(
      {
        isDownload: true,
      },
      () => {
        const invoice = document.getElementById("score-card");
        html2canvas(invoice, {
          useCORS: true,
          allowTaint: true,
          scrollY: 0,
        }).then((canvas) => {
          const image = { type: "jpeg", quality: 0.98 };
          const margin = [0.5, 0.5];

          var imgWidth = 8.5;
          var pageHeight = 11;

          var innerPageWidth = imgWidth - margin[0] * 2;
          var innerPageHeight = pageHeight - margin[1] * 2;

          // Calculate the number of pages.
          var pxFullHeight = canvas.height;
          var pxPageHeight = Math.floor(canvas.width * (pageHeight / imgWidth));
          var nPages = Math.ceil(pxFullHeight / pxPageHeight);

          // Define pageHeight separately so it can be trimmed on the final page.
          var pageHeight = innerPageHeight;

          // Create a one-page canvas to split up the full image.
          var pageCanvas = document.createElement("canvas");
          var pageCtx = pageCanvas.getContext("2d");
          pageCanvas.width = canvas.width;
          pageCanvas.height = pxPageHeight;

          // Initialize the PDF.
          var pdf = new jsPDF("p", "in", [8.5, 11]);

          for (var page = 0; page < nPages; page++) {
            // Trim the final page to reduce file size.
            if (page === nPages - 1 && pxFullHeight % pxPageHeight !== 0) {
              pageCanvas.height = pxFullHeight % pxPageHeight;
              pageHeight =
                (pageCanvas.height * innerPageWidth) / pageCanvas.width;
            }

            // Display the page.
            var w = pageCanvas.width;
            var h = pageCanvas.height;
            pageCtx.fillStyle = "white";
            pageCtx.fillRect(0, 0, w, h);
            pageCtx.drawImage(canvas, 0, page * pxPageHeight, w, h, 0, 0, w, h);

            // Add the page to the PDF.
            if (page > 0) pdf.addPage();
            var imgData = pageCanvas.toDataURL(
              "image/" + image.type,
              image.quality
            );
            pdf.addImage(
              imgData,
              image.type,
              margin[1],
              margin[0],
              innerPageWidth,
              pageHeight
            );
          }
          pdf.save("grading-score.pdf");
          this.setState({
            isDownload: false,
          });
        });
      }
    );
  };

  render() {
    let {
      outreachPercentage,
      governance,
      operations,
      management,
      socioEconomic,
      technology,
      accountsFinance,
      compliance,
      fpoData,
      isLoading,
      fpoName,
      fpoGradingDetail,
      isNoData,
      isDownload,
      grades,
      activeTab,
      hasReport,
    } = this.state;

    let {
      outReachPercentage = 0,
      operationsGrade = "",
      governancePercentage = 0,
      governanceGrade = "",
      fpcPercentage = 0,
      compliancePercentage = 0,
      complianceGrade = "",
      accountsAndFinancePercentage = 0,
      accountsAndFinanceGrade = "",
      fpcGrade = "",
    } = fpoGradingDetail;

    return (
      <Fragment>
        {hasReport ? (
          <>
            {!isLoading ? (
              <div className="bg-white grading-shadow pt-4 p-3">
                <div className="grading_note">
                  <div className="row">
                    <div className="col-md-9 ">
                      <div className="row justify-content-between">
                        <div className="col-md-2 col-sm-6">
                          <div className="grading-score-percent">
                            <p className="grade">A+</p>
                            <div className="grade-text">
                              Ready to Excel <br /> 90-100%
                            </div>
                          </div>
                        </div>
                        <div className="col-md-2 col-sm-6">
                          <div className="grading-score-percent">
                            <p className="grade">A</p>
                            <div className="grade-text">
                              Ready to Establish <br /> 70-89%
                            </div>
                          </div>
                        </div>
                        <div className="col-md-2 col-sm-6">
                          <div className="grading-score-percent">
                            <p className="grade">B</p>
                            <div className="grade-text">
                              Ready to Engage <br /> 50-69%
                            </div>
                          </div>
                        </div>
                        <div className="col-md-2 col-sm-6">
                          <div className="grading-score-percent">
                            <p className="grade">C</p>
                            <div className="grade-text">
                              Ready to Enable <br /> 20-49%
                            </div>
                          </div>
                        </div>
                        <div className="col-md-2 col-sm-6">
                          <div className="grading-score-percent">
                            <p className="grade">D</p>
                            <div className="grade-text">
                              Ready to Build <br /> 0-19%
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3 text-center pt-4">
                      <span className="fs-14 fw-600">
                        Report generated date:{" "}
                        {moment(fpoGradingDetail?.updatedAt).format(
                          "DD-MM-YYYY"
                        )}
                        {/* {" - "}
                  {moment(fpoGradingDetail?.updatedAt)
                    .tz("Asia/Kolkata")
                    .format("hh:mm a")} */}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="pb-4">
                  {/* <div className="d-flex justify-content-end mb-1">
                <span className="fs-18 fw-600">
                  Report generated date:{" "}
                  {moment(fpoGradingDetail?.updatedAt).format("DD-MM-YYYY")} */}
                  {/* {" - "}
                  {moment(fpoGradingDetail?.updatedAt)
                    .tz("Asia/Kolkata")
                    .format("hh:mm a")} */}
                  {/* </span>
                </div> */}
                  <div className="row ">
                    <div className="col-md-3 swap-order">
                      <div className="grading-card">
                        <div className="d-flex justify-content-between">
                          <h5 className="fs-16 fw-700 line-height-20 text-green-18">
                            {"Outreach Percentage"}
                          </h5>
                          <h5 className="fs-22 fw-700 line-height-29 text-green-18">
                            {(outReachPercentage * 100).toFixed(1)}
                            {"%"}
                          </h5>
                        </div>
                        <div className="row graph-area">
                          <div className="col-md-5 pr-0 d-flex justify-content-center flex-column">
                            <p className="text-black-50 fs-14 fw-300 line-height-16 mb-2">
                              Your Grade
                            </p>
                            <p className="text-green-18 fs-18 fw-700 line-height-22">
                              {fpoGradingDetail &&
                              fpoGradingDetail.outReachGrade
                                ? fpoGradingDetail.outReachGrade
                                : "-"}
                            </p>
                          </div>
                          <div className="col-md-7 px-0">
                            <HighchartsReact
                              highcharts={Highcharts}
                              options={outreachPercentage}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="grading-card">
                        <div className="d-flex justify-content-between">
                          <h5 className="fs-16 fw-700 line-height-20 text-green-18">
                            {"Governance"}
                          </h5>
                          <h5 className="fs-22 fw-700 line-height-29 text-green-18">
                            {(governancePercentage * 100).toFixed(1)}
                            {"%"}
                          </h5>
                        </div>
                        <div className="row graph-area">
                          <div className="col-md-5 pr-0 d-flex justify-content-center flex-column">
                            <p className="text-black-50 fs-14 fw-300 line-height-16 mb-2">
                              Your Grade
                            </p>
                            <p className="text-green-18 fs-18 fw-700 line-height-22">
                              {governanceGrade ? governanceGrade : "-"}
                            </p>
                          </div>
                          <div className="col-md-7 px-0">
                            <HighchartsReact
                              highcharts={Highcharts}
                              options={governance}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="grading-card-fpo px-md-5 py-md-4 px-2 py-3">
                        <div className="grading_card_fpo">
                          <h5 className="fs-24 fw-700 line-height-20 text-green-18 text-center w-100 mb-3">
                            {fpoName}
                          </h5>
                          <p className="text-green-18 fs-24 fw-700 line-height-44">
                            {fpcPercentage
                              ? (fpcPercentage * 100).toFixed(1)
                              : 0}
                            {"%"}
                          </p>
                        </div>
                        <div className="row graph-area w-100">
                          <div className="col-md-8 pr-0 ">
                            <HighchartsReact
                              highcharts={Highcharts}
                              options={fpoData}
                            />
                          </div>
                          <div className="col-md-4 px-0 d-flex justify-content-center flex-column align-items-end">
                            {/* <p className="text-black fs-24 fw-700 line-height-29 mb-0">
                        FPC Score
                      </p> */}
                            {/* <p className="text-green-18 fs-24 fw-700 line-height-44">
                        {fpcPercentage
                          ? (fpcPercentage * 100).toFixed(1)
                          : 0}
                        {"%"}
                      </p> */}
                            <p className="text-black fs-24 fw-700 line-height-29 mb-2">
                              Your Grade
                            </p>
                            <p className="text-green-18 fs-24 fw-700 line-height-44">
                              {fpcGrade ? fpcGrade : "-"}
                            </p>
                          </div>
                        </div>
                        <div>
                          <p className="fs-18 fw-300 text-black-50 line-height-21 text-center">
                            {fpcGrade === "A+"
                              ? "Congrats for being a model FPO, you are considered as an Established FPO. Continue the good work."
                              : fpcGrade.toLowerCase() === "a"
                              ? "Congrats for engaging maximum number of farmers, activities such as building infrastructure to procure & process members produce would lead higher grades."
                              : fpcGrade.toLowerCase() === "b"
                              ? "Congrats for the good work, increased engagement with members & higher quantity of procurement and infrastructure building would lead to higher grades."
                              : fpcGrade.toLowerCase() === "c"
                              ? "Congrats for the good work, activities such as building proper business plan, engagement with member farmers would take to higher grades."
                              : fpcGrade.toLowerCase() === "d"
                              ? "Congrats for the good work, increase in the involvement of BODs, FIG representatives, CEO & staff in capacity building and focus on digitization would take to higher grades."
                              : fpcGrade}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="grading-card">
                        <div className="d-flex justify-content-between">
                          <h5 className="fs-16 fw-700 line-height-20 text-green-18">
                            {"Compliance"}
                          </h5>
                          <h5 className="fs-22 fw-700 line-height-29 text-green-18">
                            {(compliancePercentage * 100).toFixed(1)}
                            {"%"}
                          </h5>
                        </div>
                        <div className="row graph-area">
                          <div className="col-md-5 pr-0 d-flex justify-content-center flex-column">
                            <p className="text-black-50 fs-14 fw-300 line-height-16 mb-2">
                              Your Grade
                            </p>
                            <p className="text-green-18 fs-18 fw-700 line-height-22">
                              {complianceGrade ? complianceGrade : "-"}
                            </p>
                          </div>
                          <div className="col-md-7 px-0">
                            <HighchartsReact
                              highcharts={Highcharts}
                              options={compliance}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="grading-card">
                        <div className="d-flex justify-content-between">
                          <h5 className="fs-16 fw-700 line-height-20 text-green-18">
                            {"Accounts & Finance"}
                          </h5>
                          <h5 className="fs-22 fw-700 line-height-29 text-green-18">
                            {(accountsAndFinancePercentage * 100).toFixed(1)}
                            {"%"}
                          </h5>
                        </div>
                        <div className="row graph-area">
                          <div className="col-md-5 pr-0 d-flex justify-content-center flex-column">
                            <p className="text-black-50 fs-14 fw-300 line-height-16 mb-2">
                              Your Grade
                            </p>
                            <p className="text-green-18 fs-18 fw-700 line-height-22">
                              {accountsAndFinanceGrade
                                ? accountsAndFinanceGrade
                                : "-"}
                            </p>
                          </div>
                          <div className="col-md-7 px-0">
                            <HighchartsReact
                              highcharts={Highcharts}
                              options={accountsFinance}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="grading-card">
                        <div className="d-flex justify-content-between">
                          <h5 className="fs-16 fw-700 line-height-20 text-green-18">
                            {"Operations"}
                          </h5>
                          <h5 className="fs-22 fw-700 line-height-29 text-green-18">
                            {(
                              fpoGradingDetail.operationsPercentage * 100
                            ).toFixed(1)}
                            {"%"}
                          </h5>
                        </div>
                        <div className="row graph-area">
                          <div className="col-md-5 pr-0 d-flex justify-content-center flex-column">
                            <p className="text-black-50 fs-14 fw-300 line-height-16 mb-2">
                              Your Grade
                            </p>
                            <p className="text-green-18 fs-18 fw-700 line-height-22">
                              {fpoGradingDetail &&
                              fpoGradingDetail.operationsGrade
                                ? fpoGradingDetail.operationsGrade
                                : "-"}
                            </p>
                          </div>
                          <div className="col-md-7 px-0">
                            <HighchartsReact
                              highcharts={Highcharts}
                              options={operations}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="grading-card">
                        <div className="d-flex justify-content-between">
                          <h5 className="fs-16 fw-700 line-height-20 text-green-18">
                            {"Management"}
                          </h5>
                          <h5 className="fs-22 fw-700 line-height-29 text-green-18">
                            {(
                              fpoGradingDetail.managementPercentage * 100
                            ).toFixed(1)}
                            {"%"}
                          </h5>
                        </div>
                        <div className="row graph-area">
                          <div className="col-md-5 pr-0 d-flex justify-content-center flex-column">
                            <p className="text-black-50 fs-14 fw-300 line-height-16 mb-2">
                              Your Grade
                            </p>
                            <p className="text-green-18 fs-18 fw-700 line-height-22">
                              {fpoGradingDetail &&
                              fpoGradingDetail.managementGrade
                                ? fpoGradingDetail.managementGrade
                                : "-"}
                            </p>
                          </div>
                          <div className="col-md-7 px-0">
                            <HighchartsReact
                              highcharts={Highcharts}
                              options={management}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="grading-card">
                        <div className="d-flex justify-content-between">
                          <h5 className="fs-16 fw-700 line-height-20 text-green-18">
                            {"Socio Economic"}
                          </h5>
                          <h5 className="fs-22 fw-700 line-height-29 text-green-18">
                            {(
                              fpoGradingDetail.socioImpactPercentage * 100
                            ).toFixed(1)}
                            {"%"}
                          </h5>
                        </div>
                        <div className="row graph-area">
                          <div className="col-md-5 pr-0 d-flex justify-content-center flex-column">
                            <p className="text-black-50 fs-14 fw-300 line-height-16 mb-2">
                              Your Grade
                            </p>
                            <p className="text-green-18 fs-18 fw-700 line-height-22">
                              {fpoGradingDetail &&
                              fpoGradingDetail.socioImpactGrade
                                ? fpoGradingDetail.socioImpactGrade
                                : "-"}
                            </p>
                          </div>
                          <div className="col-md-7 px-0">
                            <HighchartsReact
                              highcharts={Highcharts}
                              options={socioEconomic}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="grading-card">
                        <div className="d-flex justify-content-between">
                          <h5 className="fs-16 fw-700 line-height-20 text-green-18">
                            {"Technology"}
                          </h5>
                          <h5 className="fs-22 fw-700 line-height-29 text-green-18">
                            {(
                              fpoGradingDetail.technologyAndServicesPercentage *
                              100
                            ).toFixed(1)}
                            {"%"}
                          </h5>
                        </div>
                        <div className="row graph-area">
                          <div className="col-md-5 pr-0 d-flex justify-content-center flex-column">
                            <p className="text-black-50 fs-14 fw-300 line-height-16 mb-2">
                              Your Grade
                            </p>
                            <p className="text-green-18 fs-18 fw-700 line-height-22">
                              {fpoGradingDetail &&
                              fpoGradingDetail.technologyAndServicesGrade
                                ? fpoGradingDetail.technologyAndServicesGrade
                                : "-"}
                            </p>
                          </div>
                          <div className="col-md-7 px-0">
                            <HighchartsReact
                              highcharts={Highcharts}
                              options={technology}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <CommonPageLoader />
            )}
          </>
        ) : (
          <div className="fs-20 fw-500 text-black-50 line-height-21 text-center">
            No Grading survey has been completed yet.
          </div>
        )}
      </Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getGradingDetail,
      getGradingDetailsOfFPO,
      getGradingDetailsApi: getGradingDetails,
    },
    dispatch
  );
};

export const GradingReport = connect(
  null,
  mapDispatchToProps
)(GradingReportTool);
