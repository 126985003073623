import { createStore, applyMiddleware, compose } from 'redux';
import { reducers } from '../../reducer';
import thunk from 'redux-thunk';
import { api } from 'service/api';
import { Toast } from 'service/toast';

export const store = createStore(
   reducers,
   compose(
      applyMiddleware(thunk.withExtraArgument({ api, Toast })),
      window.devToolsExtension ? window.devToolsExtension() : (f) => f
   )
);

export const history = require('history').createBrowserHistory();
