import React, { Component } from "react";
import "./posts.scss";
import arrow_right from "assets/svg/posts/arrow-right.svg";
import { letterAvatar, getDescriptionData } from "service/helperFunctions";
import moment from "moment";
import { history } from "service/helpers";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { forumIdChange } from "action/HomeAct";
export class PostsClass extends Component {
  //handle redirect
  handleRedirect = (id) => {
    this.props.forumIdChange(id);
    history.push("/academy/communityForum");
  };

  render() {
    let { forumList = [] } = this.props;
    return (
      <div>
        {forumList.map((post, index) => {
          let { commentsCount = 0, title = "", createdAt, id } = post;
          return (
            <div
              className="d-flex container-posts align-items-center background mb-3 shadow rounded"
              key={index}
            >
              <div className="rectangle-orange"></div>
              <div className="d-flex  w-100">
                <div className="d-flex justify-content-center mx-3">
                  <img
                    src={letterAvatar(title, 78, true)}
                    alt="profile.svg"
                    className="border-radius-50"
                    height="70"
                    width="70"
                  />
                </div>
                <div className="d-flex justify-content-center flex-column w-75">
                  <div
                    className="text-green font-weight-bold mb-2 fs-20 line-height-31 cursor-pointer"
                    onClick={() => this.handleRedirect(id)}
                  >
                    {title ? getDescriptionData(title, 150) : ""}
                  </div>
                  <div className="d-flex">
                    <div className="mr-3 fs-16 text-blac-50 fw-600 line-height-24">
                      {`${moment(createdAt, "YYYY-MM-DD HH:mm:ss").toNow(
                        true
                      )} ago`}
                    </div>
                    <div className="s-16 text-blac-50 fw-600 line-height-24">
                      {commentsCount + " "}comments
                    </div>
                  </div>
                </div>
              </div>
              {/* <div
                className="arrow rounded cursor-pointer"
                onClick={() => this.handleRedirect(id)}
              >
                <img src={arrow_right} alt="arrow.svg" />
              </div> */}
            </div>
          );
        })}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      forumIdChange,
    },
    dispatch
  );
};
let component = PostsClass;

export const Posts = connect(null, mapDispatchToProps)(component);
