import { HomeActionType } from "service/actionType";

const initialState = {
  tab: 1,
  forum_id: "",
  accessData: [],
  profileImage: null,
};

export default (state = Object.assign({}, initialState), { type, payload }) => {
  switch (type) {
    case HomeActionType.currentTab:
      return {
        ...state,
        tab: payload,
      };
    case HomeActionType.ForumId:
      return {
        ...state,
        forum_id: payload,
      };
    case HomeActionType.accessData:
      return {
        ...state,
        accessData: payload,
      };
    case HomeActionType.profileImage:
      return {
        ...state,
        profileImage: payload,
      };
    default:
      return state;
  }
};
