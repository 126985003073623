import React, { Component } from "react";
import { Link } from "react-router-dom";

import search1 from "assets/images/academy/search.svg";
import filter from "assets/images/academy/filter.svg";

import { history } from "service/helpers";

import "./AcadamyMenu.scss";

import { academyExplore, searchChange, pageChange } from "action/Acadamy";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { debounceFunction } from "service/debounce";

export class AcadamyMenuClass extends Component {
  constructor() {
    super();
    this.state = {
      active: false,
      dropdownList: [],
      categoryName: "",
      courseData: [],
      blogData: [],
      trendingVideos: [],
      search: "",
      selectedId: 0,
      searchShow: false,
    };
  }

  handleDropdown = () => {
    if (!this.state.active) {
      document.addEventListener("click", this.handleOutsideClick, false);
    } else {
      document.removeEventListener("click", this.handleOutsideClick, false);
    }
    this.setState((prevState) => ({
      active: !prevState.active,
    }));
  };

  handleOutsideClick = (e) => {
    if (this.node != null) {
      if (this.node.contains(e.target)) {
        return;
      }
    }
    this.handleDropdown();
  };

  handleSearchShow = () => {
    this.setState((prevState) => ({
      searchShow: !prevState.searchShow,
      search: "",
    }));
  };

  componentDidMount() {
    let { academyExplore, id } = this.props;
    academyExplore().then((data) => {
      this.setState({ dropdownList: data }, () => {
        if (id !== undefined) {
          data.map((list) => {
            if (list.courseCategoryId == id) {
              this.setState({ categoryName: list.categoryName });
            }
          });
        }
      });
    });
  }

  handleSearch = (e) => {
    this.setState({
      search: e.target.value,
    });
    var value = e.target.value;
    debounceFunction(() => {
      this.handleSubmit(this.props.page, value);
    }, 1000);
  };

  handleSubmit = (id, searchValue) => {
    let { search } = this.state;
    if (id === "All") {
      this.setState(
        {
          categoryName: "",
          selectedId: 0,
        },
        () => {
          history.push(`/academy/explore/${0}`);
        }
      );
    } else {
      this.props.searchChange(searchValue !== "" ? searchValue : search);
      history.push(`/academy/explore/${id}`);
    }
  };

  render() {
    let { active, dropdownList, search, searchShow } = this.state;

    let { categoryName = "", pageChange } = this.props;

    return (
      <>
        <section className="acadamy-menu-section">
          <div className="container-sa">
            <div className="acadamy-menu-wrapper">
              <div className="row">
                <div className="col-md-8">
                  <ul className="left-menu">
                    <li
                      className="cursor-pointer explore-dropdown"
                      onClick={this.handleDropdown}
                    >
                      {categoryName == "" ? "Explore" : categoryName}
                      <i className="icon-down fs-12 ml-2"></i>
                      <div className={`dropdown ${active ? "active" : ""}`}>
                        <ul className="h-100 w-100 d-flex flex-column flex-wrap">
                          {categoryName !== "" ? (
                            <li
                              className="cursor-pointer"
                              onClick={() => {
                                this.setState(
                                  {
                                    categoryName: "",
                                    selectedId: 0,
                                    search: search,
                                  },
                                  () => {
                                    this.handleSubmit("All", "");
                                  }
                                );
                              }}
                            >
                              All
                            </li>
                          ) : null}
                          {dropdownList.map((list, index) => (
                            <li
                              className="cursor-pointer"
                              key={index}
                              onClick={() => {
                                this.setState({
                                  selectedId: list.courseCategoryId,
                                  search: search,
                                });
                                pageChange(list.courseCategoryId);
                                this.handleSubmit(list.courseCategoryId, "");
                              }}
                            >
                              {list.categoryName}
                            </li>
                          ))}
                        </ul>
                      </div>
                    </li>
                    <li>
                      <Link to="/academy/my-learning">My Learning</Link>
                    </li>
                    {/* <li><Link to="/academy/courses">From your FPO</Link></li> */}
                  </ul>
                </div>
                <div className="col-md-4 d-flex justify-content-end">
                  {searchShow ? (
                    <input
                      autoFocus={true}
                      className="bg-white form-control mr-3"
                      type="text"
                      placeholder="search..."
                      value={search}
                      onChange={this.handleSearch}
                      name="search"
                    />
                  ) : null}

                  <img
                    src={search1}
                    alt="search"
                    srcSet=""
                    onClick={this.handleSearchShow}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  page: state.academy.page,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      academyExplore,
      searchChange,
      pageChange,
    },
    dispatch
  );
};

let component = AcadamyMenuClass;

export const AcadamyMenu = connect(
  mapStateToProps,
  mapDispatchToProps
)(component);
