import React, { Component } from "react";
import { SearchList } from "../SearchList";
import "./searchview.scss";
const map = require("assets/svg/search/map.svg");

export class SearchView extends Component {
  state = {
    view: "3",
    searchValue: "",
  };
  render() {
    return (
      <section className="member-section py-0">
        <div className="container-sa position-relative">
          <div className="">
            <div className="row align-items-center">
              <div className="col-12 d-flex justify-content-center">
                <p className="text-center fs-20 bg-white px-2 position-absolute search-term mb-0">
                  {"Search result for "}
                  <span className="fw-600">"{this.props.fpoName}"</span>
                </p>
              </div>
            </div>
          </div>
          <div className="compilance-filters d-flex justify-content-end bg-danger p-0">
            <div className="row align-items-center position-absolute toogle-menu">
              <div className="col-sm-9 col-md-12 px-md-5">
                <ul className="right-filter-align p-2 bg-white">
                  <li className="ml-0">
                    <img
                      className={` mr-3 fs-20 cursor-pointer map-fix ${
                        this.state.view === "1" ? "view-active" : "view"
                      }`}
                      src={map}
                      alt="map"
                      onClick={() => this.setState({ view: "1" })}
                    />
                    <span
                      className={`icon-list-view mr-3 pr-1 fs-20 cursor-pointer ${
                        this.state.view === "2" ? "view-active" : "view"
                      }`}
                      onClick={() => this.setState({ view: "2" })}
                    ></span>
                    <span
                      className={` icon-grid-view fs-20 cursor-pointer ${
                        this.state.view === "3" ? "view-active" : "view"
                      }`}
                      onClick={() => this.setState({ view: "3" })}
                    ></span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <SearchList view={this.state.view} fpoName={this.props.fpoName} />
        </div>
      </section>
    );
  }
}
