import { setDistrictswithState } from 'action/GradingAct';
import { getDistrictApiwithState } from 'action/MemberAct';
import { NormalButton, NormalDate, NormalInput, NormalSelect } from 'component/common';
import React, { Component } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';
import { FieldsTemplate } from '../common/FieldsTemplate';
import { RadioGroup } from '../common/RadioGroup';
import { addComma } from '../common/utils';


export const BasicFPOInfo = (props) => {

  const dispatch = useDispatch();
  const { stateList, districtList } = useSelector(
    (state) => state.grading
  );


  const [, forceUpdate] = React.useState();
  const [fpoInfo, setFpoInfo] = React.useState({

  });
  const [fpoInfoAttr, setfpoInfoAttr] = React.useState([
    { label: "FPO ID", field: "input", type: "number", name: "fpoId", required: true, disabled: true },
    //{ label: "Month and Year", field: "input", type: "number", name: "yrmonth", required: true, disabled: true },
    { label: "Name of FPO", field: "input", type: "text", name: "fpoName", required: true, disabled: true },
    { label: "Number of Operational Villages", field: "input", type: "number", name: "noOfVillages", required: true },
    { label: "Number of Operational Blocks", field: "input", type: "number", name: "noOfBlocks", required: true },
    {
      label: "State", field: "select", type: "number", name: "selState", required: true,
      list: []
    },
    {
      label: "District", field: "select", type: "number", name: "selDistrict", required: true,
      list: []
    },
    { label: "Registration date of FPO", field: "date", type: "number", name: "regDateFPO", note: "As per the CIN (Certificate of Incorporation) document", required: true, },
    { label: "Operational since", field: "date", type: "number", name: "opsSince", note: "When did they start their operations", required: true, },

    { label: "Contact number of FPO in Indian phone no format", field: "input", type: "number", name: "fpoContactNumber", required: true },
    { label: "Objectives of the FPO", field: "input", type: "text", name: "fpoObjectives", required: true },
    { label: "Name of Promoting Institution", field: "input", type: "text", name: "promotingInstitute", required: true },
    {
      label: "Authorised Share Capital", field: "input", type: "text", alt: "authShareCapStr", name: "authShareCap", note: "(Example: 5,55,000)", required: true,
      errConditions: { criteria: "lte:999999999" }
    },
    {
      label: "Paid up Share Capital", field: "input", type: "text", alt: "paidShareCapStr", name: "paidShareCap", note: "(Example: 5,55,000)", required: true,
      errConditions: { criteria: "lte:999999999" }
    },
    {
      label: "Constitution", field: "radioGroup", type: "number", name: "constitution", required: true,
      list: [
        { label: "Producer Company", value: "producerco" },
        { label: "Producer Organisation", value: "producerorg" },
        { label: "Cooperative", value: "cooperative" }
      ]
    },
    { label: "Name and designation of respondent(s):", field: "input", type: "text", name: "nameDesgResp", required: true },
  ]);

  const handleInput = ({ target: { name, value } }) => {
    if (name === "selState") {
      dispatch(getDistrictApiwithState({ id: value })).then(data => {
        dispatch(setDistrictswithState(data));
      });
      setFpoInfo({
        ...fpoInfo,
        [name]: value,
        selDistrict: ""
      });
    } else {
      let data = {
        [name]: value,
      }
      if (["authShareCap", "paidShareCap"].includes(name)) {
        data[`${name}Str`] = addComma(value);
        let _value = value.replace(/,/g, '');
        data[`${name}`] = parseFloat(_value);
      }
      setFpoInfo({
        ...fpoInfo,
        ...data
      });
    }
  };

  React.useEffect(() => {
    if (props?.surveyDetails) {

      let _fpoInfo = {
        fpoId: props?.surveyDetails?.fpoId,
        //fpoName: props?.surveyDetails?.fpoName || null,
        //yrmonth: props?.surveyDetails?.yearmonth,
        noOfVillages: props?.surveyDetails?.noOfVillages,
        noOfBlocks: props?.surveyDetails?.noOfBlocks,
        fpoContactNumber: props?.surveyDetails?.fpoContactNumber,
        fpoObjectives: props?.surveyDetails?.fpoObjectives,
        promotingInstitute: props?.surveyDetails?.promotingInstitute,
        constitution: props?.surveyDetails?.constitution,
        nameDesgResp: props?.surveyDetails?.nameDesgResp,

      };
      if (props?.surveyDetails?.authShareCap) {
        _fpoInfo.authShareCap = parseFloat(props?.surveyDetails?.authShareCap) || 0;
        _fpoInfo.authShareCapStr = addComma(props?.surveyDetails?.authShareCap) || 0;
      }
      if (props?.surveyDetails?.paidShareCap) {
        _fpoInfo.paidShareCap = parseFloat(props?.surveyDetails?.paidShareCap) || 0;
        _fpoInfo.paidShareCapStr = addComma(props?.surveyDetails?.paidShareCap) || 0;
      }
      if (props?.fpoDetails) {
        _fpoInfo.fpoName = props?.fpoDetails?.fpoName;

      }
      if (props?.surveyDetails?.state) {
        let stateId = ""
        let _stateObj = stateList.filter((state) => {
          return state.label === props?.surveyDetails?.state;
        });
        if (_stateObj.length > 0) {
          stateId = _stateObj[0].value;

          dispatch(getDistrictApiwithState({ id: stateId })).then(data => {
            dispatch(setDistrictswithState(data));
          })
        }
        _fpoInfo.selState = stateId;
      }

      if (props?.surveyDetails?.regDateFPO) {
        _fpoInfo.regDateFPO = new Date(props?.surveyDetails?.regDateFPO);
      }
      if (props?.surveyDetails?.opsSince) {
        _fpoInfo.opsSince = new Date(props?.surveyDetails?.opsSince);
      }

      setFpoInfo({
        ...fpoInfo,
        ..._fpoInfo
      });
    }
  }, [props?.surveyDetails, props?.fpoDetails]);

  React.useEffect(() => {
    if (props?.submitSurveyClick) {
      handleSubmit('completed');
    }
  }, [props?.submitSurveyClick]);


  React.useEffect(() => {
    if (props?.surveyDetails && props?.surveyDetails?.district && districtList) {
      let _fpoInfo = {};
      let districtId = "";
      let districtObj = districtList.filter((district) => {
        return district.label === props?.surveyDetails?.district;
      });
      if (districtObj.length > 0) {
        districtId = districtObj[0].value;
      }
      _fpoInfo.selDistrict = districtId;
      setFpoInfo({
        ...fpoInfo,
        ..._fpoInfo
      });
    }
  }, [districtList]);

  //validation
  const simpleValidator = React.useRef(new SimpleReactValidator({
    validators: {
      lte: {
        message: "Please enter a value lessthan 100.",
        rule: (val, params, validator) => {
          return (
            val <= parseInt(params[0])
          );
        },
        messageReplace: (message, params) =>
          message.replace("100", parseInt(params[0])),
        required: true,
      },
    },
    element: (message) => (
      <span className="error-message text-danger fs-14">{message}</span>
    ),
    autoForceUpdate: this
  }));
  const handleSubmit = (status) => {
    const formValid = simpleValidator.current.allValid();
    if (formValid) {
      let payload = { ...fpoInfo, status };
      payload.stateId = fpoInfo.selState;
      payload.districtId = fpoInfo.selDistrict;
      if (stateList) {
        let _stateObj = stateList.filter((state) => {
          return state.value === parseInt(fpoInfo.selState);
        });
        payload.state = _stateObj[0].label;
      }
      if (districtList) {
        //
        let districtObj = districtList.filter((district) => {
          return district.value === parseInt(fpoInfo.selDistrict);
        });
        payload.district = districtObj[0].label;
      }
      props.updateSurvey("fpoInfo", payload);

    } else {
      simpleValidator.current.showMessages()
      forceUpdate(1);
      props.errorAccordion();
    }
  }
  const handleRadio = (name, value) => {
    setFpoInfo({
      ...fpoInfo,
      [name]: value,
    });
  };
  return (
    <div >
      {/* <h5><b>Basic details of FPO</b></h5> */}
      <div className="container-sa position-relative" >

        <FieldsTemplate
          attributes={fpoInfoAttr}
          simpleValidator={simpleValidator}
          stateAttr={fpoInfo}
          handleInput={handleInput}
          handleRadio={handleRadio}
        // handleCheckbox={() => { }}
        />

        <div className="row mt-4">
          <div className="col-md-5 col-sm-12  my-1">
            <NormalButton
              label="Save & Next"
              mainbg={true}
              className="px-3 py-2 mx-auto fs-14 line-height-26 font-Gilroy saveNext"
              onClick={() => handleSubmit('draft')}
              isPopup={true}
            />
          </div>
        </div>
      </div>
    </div>
  )
}