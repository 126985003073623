import React, { Component } from "react";
import moment from "moment";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import './dashboard.scss';
import { Piechart } from './piechart';
import { Barchart } from './barchart';
import filter from "assets/images/icon/filter.svg";
import {
    dashboardImpact,
    getfpocommodityList,
    getrmcommodityList,
    getfponameList,
    getfpoStateList
} from "action/SellrequestAct";
import { CommonPageLoader, NormalSelect, Pagination } from "component/common";
import { MultiSelect } from "react-multi-select-component";
const arrow_left_white = require("assets/svg/add_member/arrow_left_white.svg");
export class DashboardClass extends Component {
    constructor() {
        super();
        this.state = {
            bar1: {},
            bar2: {},
            bar3: {},
            bar4: {},
            isLoading: true,
            list: {},
            commodityList: [],
            fpoCommodity: [0],
            monthList: [],
            month: moment().month() + 1,
            yearList: [],
            year: moment().year(),
            selected: [],
            actualTxn: false,
            actualTxnArray: {},
            fpoName: '',
            fpoNameList: [],
            state: '',
            stateList: [],
            totalquantity: 0,
            totaltrade: 0,
            fposelected: [],
            stateselected: [],
            benchmarket: 0,
            destinationmarket: 0,
            avg_trade_value: 0,
            pageMeta: {},
        }
    }
    async componentDidMount() {
        await this.getStateList();
        await this.getCommodityList();
        await this.getFpoNameList();
        this.setState({
            monthList: [
                { value: 0, label: "All" },
                { value: 1, label: "Jan" },
                { value: 2, label: "Feb" },
                { value: 3, label: "Mar" },
                { value: 4, label: "Apr" },
                { value: 5, label: "May" },
                { value: 6, label: "Jun" },
                { value: 7, label: "Jul" },
                { value: 8, label: "Aug" },
                { value: 9, label: "Sep" },
                { value: 10, label: "Oct" },
                { value: 11, label: "Nov" },
                { value: 12, label: "Dec" },
            ],
            yearList: [
                { value: 2020, label: 2020 },
                { value: 2021, label: 2021 },
                // { value: 2022, label: 2022 },
            ]
        });
        await this.impactApi(1);
    }

    async getCommodityList() {
        var list = localStorage.getItem("userGroupName") === "fpo" && localStorage.getItem("userRoleName") === "fpo" ? this.props.getfpocommodityList() : this.props.getrmcommodityList();
        await list.then((data) => {
            let response = [];
            var fpoCommodity = [];

            data.map((list) => {
                response.push({ value: list.cropId, label: list.cropName });
                fpoCommodity.push(list.cropId);
            });
            this.setState({
                fpoCommodity: fpoCommodity,
                selected: response,
                commodityList: response
            }
            );
        });
    }

    async getFpoNameList() {
        var list = this.props.getfponameList();
        await list.then((data) => {
            let response = [];
            var name = [];


            data.map((list) => {
                response.push({ value: list.fpoId, label: list.fpoName });
                name.push(list.fpoId);
            });
            this.setState({
                fpoName: name,
                fposelected: response,
                fpoNameList: response
            }
            );
        });
    }

    async getStateList() {
        var list = this.props.getfpoStateList();
        await list.then((data) => {
            let response = [];
            var name = [];

            data.map((list) => {
                response.push({ value: list.stateId, label: list.stateName });
                name.push(list.stateId);
            });
            this.setState({
                state: name,
                stateselected: response,
                stateList: response
            }
            );
        });
    }
    //handle page change
    handlePagination = (page) => {
        this.impactApi(page.page);
    };

    impactApi = async (page) => {
        this.setState({ isLoading: true });
        var totalquantity = 0;
        var totaltrade = 0;
        var benchmarket = 0;
        var destinationmarket = 0;
        var done = 0;
        var pending = 0;
        var avg_trade_value = 0;
        var completed_farmers_engaged = 0
        var total_farmers_engaged = 0
        var avg_fpo_margin = 0;
        var total_buyer = 0;
        var total_completed_buyer = 0;
        var postdata = {
            "commodityId": this.state.fpoCommodity,
            "year": [this.state.year],
            "fpoId": this.state.fpoName,
            "state": this.state.state,
            page, limit: 1000
        };
        if (this.state.month != 0 && this.state.month != '0') {
            postdata["month"] = [this.state.month]
        }
        this.props.dashboardImpact(postdata).then((data) => {
            console.log(data);
            data.percentage_of_received_request_processed = isNaN(data?.percentage_of_received_request_processed) == true ? data.percentage_of_received_request_processed = 0 : data.percentage_of_received_request_processed;
            if (data?.listresult?.results?.length != 0) {

                var all_request = data?.listresult?.results;
                var completed_request = data?.listresult?.results.filter(list => list.status == 4 || list.status == '4');
                var un_completed_request = data?.listresult?.results.filter(list => list.status != 4 && list.status != '4');

                // /* =============No of times higher price offered start
                    benchmarket = all_request.filter(list => list.price_offered > list?.market_price && list.price_offered != list?.market_price).length; // benchmarket
                    destinationmarket = all_request.filter(list => list.price_offered > list?.destination_market_price && list.price_offered != list?.destination_market_price).length; //destination market
                // No of times higher price offered end============= */

                
                // /*  =============centre box calculatioin start
                    totalquantity = all_request.reduce((total, obj) => Number(obj.existing_stock_level) + Number(total),0);  // Total Qty Traded
                    totaltrade = all_request.reduce((total, obj) => Number(obj.trade_value) + Number(total),0) / 100000;  // Total Trade Value
                    avg_trade_value = (all_request.reduce((total, obj) => Number(obj.trade_value) + Number(total),0)/all_request.length) / 100000; // Avg Trade Value / Txn
                // centre box calculatioin end============= */


                // /*  =============Requesed proccessed start
                    done = completed_request.length; // done
                    pending = un_completed_request.length; // processed
                // Requesed proccessed end============= */


                // /*  =============Number of farmers Engaged start
                    completed_farmers_engaged = completed_request.reduce((total, obj) => obj.total_farmer_engaged + total,0);
                    total_farmers_engaged = data.total_farmers_engaged;
                // Number of farmers Engaged end============= */
                
                
                // /*  =============avg_fpo_margin start
                    avg_fpo_margin = completed_request.map(obj => {
                        return Math.round((parseFloat(obj.fpo_margin_per_unit) / parseFloat(obj.price_offered))*100);
                    });
                    avg_fpo_margin = Math.round(avg_fpo_margin.reduce((total, obj) => obj + total,0) / completed_request.length);
                // avg_fpo_margin end============= */


                // /*  =============avg_fpo_margin start
                total_buyer = Math.round((data?.total_company_buyer/all_request.length));
                total_completed_buyer = Math.round((data.total_buyer/completed_request.length));
                // avg_fpo_margin end============= */

                
            } else {
                totalquantity = 0;
                totaltrade = 0;
                benchmarket = 0;
                destinationmarket = 0;
                done = 0;
                pending = 0;
                avg_trade_value = 0; 
                completed_farmers_engaged = 0; 
                total_farmers_engaged = 0;
                avg_fpo_margin = 0;
                total_buyer = 0;
                total_completed_buyer = 0;
            }
            this.setState({
                list: data,
                pageMeta: data?.listresult?.pageMeta,
                totalquantity: totalquantity,
                totaltrade: totaltrade,
                benchmarket: benchmarket,
                destinationmarket: destinationmarket,
                done: done,
                avg_trade_value: avg_trade_value,
                pending: pending,
                completed_farmers_engaged: completed_farmers_engaged,
                total_farmers_engaged: total_farmers_engaged,
                avg_fpo_margin: avg_fpo_margin,
                total_buyer: total_buyer,
                total_completed_buyer: total_completed_buyer,
                bar1: {
                    chart: {
                        type: 'column',
                        height: 300
                    },
                    title: {
                        text: 'Number of farmers engaged',
                        style: {
                            fontSize: '15px',
                            fontFamily: 'Gilroy'
                        }
                    },
                    xAxis: {
                        title: {
                            // text: 'Number of farmers engaged'
                        },
                        labels: {
                            enabled: false,//default is true
                        },
                        categories: [
                            'Number of farmers engaged'
                        ],


                    },
                    yAxis: {
                        min: 0,
                        title: {
                            text: ''
                        }
                    },
                    plotOptions: {
                        column: {
                            pointPadding: 0.2,
                            borderWidth: 0
                        }
                    },
                    series: [{
                        name: 'Potential Farmers',
                        data: [total_farmers_engaged],
                        color: "#1f8e59",
                        showInLegend: false,

                    }, {
                        name: 'Actual Farmers',
                        data: [completed_farmers_engaged],
                        color: "#FFB800",
                        showInLegend: false,

                    }]

                },
                bar2: {
                    chart: {
                        type: 'column',
                        height: 300
                    },
                    title: {
                        text: 'Average FPO Margins/Txn',
                        style: {
                            fontSize: '15px',
                            fontFamily: 'Gilroy'
                        }
                    },
                    tooltip: {
                        formatter: function () {
                            return '' + this.series.name + ': ' + this.y + '%';
                        }
                    },

                    xAxis: {
                        title: {
                            // text: 'Number of farmers engaged'
                        },
                        labels: {
                            enabled: false,//default is true
                        },
                        categories: [
                            'Average FPO Margins/Txn'
                        ],
                    },
                    yAxis: {
                        //min: '',
                        title: {
                            text: ''
                        },
                        labels: {
                            formatter: function () {
                                return this.value + '%';
                            }
                        }
                    },
                    plotOptions: {
                        column: {
                            pointPadding: 0.2,
                            borderWidth: 0
                        }
                    },
                    series: [{
                        name: 'FPO Margins/Txn',
                        data: [Number(avg_fpo_margin)],
                        color: "#1f8e59",
                        showInLegend: false,

                    }, {
                        name: 'All req raised',
                        data: [0],
                        color: "#FFB800",
                        showInLegend: false,

                    }]

                },
                bar3: {
                    chart: {
                        type: 'column',
                        height: 300
                    },
                    title: {
                        text: 'Avg FPO Buyer Options',
                        style: {
                            fontSize: '15px',
                            fontFamily: 'Gilroy'
                        }
                    },
                    xAxis: {
                        title: {
                            // text: 'Number of farmers engaged'
                        },
                        labels: {
                            enabled: false,//default is true
                        },
                        categories: [
                            'Avg FPO Buyer Options'
                        ],
                    },
                    yAxis: {
                        min: 0,
                        title: {
                            text: ''
                        }
                    },
                    plotOptions: {
                        column: {
                            pointPadding: 0.2,
                            borderWidth: 0
                        }
                    },
                    series: [{
                        name: 'All Requests',
                        data: [total_buyer],
                        color: "#1f8e59",
                        showInLegend: false,

                    }, {
                        name: 'Actual Txn',
                        data: [total_completed_buyer],
                        color: "#FFB800",
                        showInLegend: false,

                    }]

                },
                bar4: {
                    chart: {
                        type: 'column',
                        height: 273
                    },
                    title: {
                        text: 'Number of times Higher price offered',
                        style: {
                            fontSize: '15px',
                            fontFamily: 'Gilroy'
                        }
                    },
                    xAxis: {
                        title: {
                            // text: 'Number of farmers engaged'
                        },
                        labels: {
                            enabled: false,//default is true
                        },
                        categories: [
                            'Number of times Higher price offered'
                        ],
                    },
                    yAxis: {
                        min: 0,

                        title: {
                            text: ''
                        }
                    },
                    plotOptions: {
                        column: {
                            pointPadding: 0.2,
                            borderWidth: 0
                        }
                    },
                    series: [{
                        name: 'Benchmark Market',
                        data: [benchmarket],
                        color: "#e94f42",
                        showInLegend: false,

                    }, {
                        name: 'Destination Market',
                        data: [destinationmarket],
                        color: "#9ac24c",
                        showInLegend: false,

                    }]

                },
            }, () => {
                this.setState({ isLoading: false })
            })

        });

    }

    //open dropdown
    handleDropdown = () => {
        this.setState((prevState) => ({
            active: !prevState.active,
        }));
    };

    //close popup when clicked outside
    handleOutsideClick = (e) => {
        if (this.node != null) {
            if (this.node.contains(e.target)) {
                return;
            }
        }
        this.handleDropdown();
    };

    handleInput = ({ target: { name, value } }) => {
        this.setState({
            [name]: value,
        }, () => {
            this.impactApi(1);
        });
    };

    setSelected = (e) => {
        this.setState({
            selected: e,
        });
        var fpoCommodity = []
        if (e.length > 0) {
            e.forEach(element => {
                fpoCommodity.push(element.value);
            });
        } else {
            fpoCommodity = [0];
        }
        this.setState({
            fpoCommodity: fpoCommodity,
        }, () => {
            this.impactApi(1);
        });
    }

    setSelectedFpoName = (e) => {
        this.setState({
            fposelected: e,
        });
        var fpoName = []
        if (e.length > 0) {
            e.forEach(element => {
                fpoName.push(element.value);
            });
        } else {
            fpoName = [];
        }
        this.setState({
            fpoName: fpoName,
        }, () => {
            this.impactApi(1);
        });
    }

    setSelectedstate = (e) => {
        this.setState({
            stateselected: e,
        });
        var state = []
        if (e.length > 0) {
            e.forEach(element => {
                state.push(element.value);
            });
        } else {
            state = [0];
        }
        this.setState({
            state: state,
        }, () => {
            this.impactApi(1);
        });
    }

    actualTXnimpacttrue = (array) => {
        this.setState({
            actualTxn: !this.state.actualTxn,
            actualTxnArray: array
        });
    }

    numberWithDecimal(val) {
        var value = (isNaN(val) === false && val != null && val != '') ? Number(val).toLocaleString('en-IN', { maximumFractionDigits: 2 }) : 0;
        return value;
    }

    getDiffDays(txn, request) {
        if (txn != '' && txn != null && request != '' && request != null) {
            var result = moment(txn).diff(request, 'days');
            return result + ' Days'
        } else {
            return '-'
        }
    }

    render() {
        const {
            bar1, pageMeta,
            bar2,
            bar3,
            bar4,
            isLoading,
            list,
            commodityList,
            totalquantity,
            totaltrade,
            month,
            monthList,
            year,
            yearList,
            selected,
            actualTxn,
            actualTxnArray,
            active,
            fposelected,
            fpoNameList,
            stateselected,
            stateList,
            benchmarket,
            destinationmarket,
            done, pending, avg_trade_value,
            completed_farmers_engaged,
            total_farmers_engaged,
            avg_fpo_margin,
            total_buyer,
            total_completed_buyer
        } = this.state


        return (

            <div className="container-sa position-relative mt-4">
                {/* Sammunathi impact */}
                {
                    actualTxn == false ?
                        <div className="sammunathi-impact">
                            <div className="d-flex justify-content-between align-items-center">
                                <h2 className="m-0 fs-18 mt-0 font-weight-bold">Samunnati Impact</h2>
                                <div className="d-flex align-items-center">

                                    <div className="d-flex align-items-center samunnatiimpact menu">

                                        <div className="cursor-pointer filter-dropdown"  >
                                            <p className="m-0" onClick={this.handleDropdown}>
                                                <img src={filter} alt="filter" />
                                                <span className="fs-12 font-Gilroy ml-2 font-weight-bold">
                                                    Filter
                                                </span>
                                            </p>
                                            <div className={`dropdown ${active ? "active" : ""}`}>
                                                <div className="flex justify-between pophead">
                                                    <p className="recommend-popup-heading">Filter</p>
                                                    <i onClick={this.handleDropdown} className="icon-close fs-16 cursor-pointer"></i>
                                                </div>
                                                <ul className="h-100 w-100 d-flex flex-column flex-wrap">
                                                    <span className="fs-12 font-Gilroy font-weight-bold">Commodity</span>
                                                    <MultiSelect
                                                        options={commodityList}
                                                        value={selected}
                                                        onChange={this.setSelected}
                                                        labelledBy="Select"
                                                        className="mul-sel"
                                                    />
                                                    <span className="fs-12 font-Gilroy font-weight-bold">FPO Name</span>
                                                    <MultiSelect
                                                        options={fpoNameList}
                                                        value={fposelected}
                                                        onChange={this.setSelectedFpoName}
                                                        labelledBy="FPO Name"
                                                        className="mul-sel"
                                                    />
                                                    <span className="fs-12 font-Gilroy font-weight-bold">State</span>
                                                    <MultiSelect
                                                        options={stateList}
                                                        value={stateselected}
                                                        onChange={this.setSelectedstate}
                                                        labelledBy="State"
                                                        className="mul-sel"
                                                    />
                                                </ul>
                                            </div>
                                        </div>

                                        <div className="mr-1 ml-1">
                                        </div>
                                        <div className="mr-1 ml-1">
                                            <NormalSelect
                                                isFullWidth={true}
                                                label={""}
                                                placeholder="Year"
                                                name="year"
                                                handleChange={this.handleInput}
                                                arrow={true}
                                                options={yearList}
                                                value={year}
                                                isHalfWidth={false}
                                                isFullWidth={false}
                                                required={true}
                                            />

                                        </div>
                                        <div className="mr-1 ml-1">
                                            <NormalSelect
                                                isFullWidth={true}
                                                label={""}
                                                placeholder="Month"
                                                name="month"
                                                handleChange={this.handleInput}
                                                arrow={true}
                                                options={monthList}
                                                value={month}
                                                isHalfWidth={false}
                                                isFullWidth={false}
                                                required={true}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* INFO PANEL */}
                            <>
                                {isLoading == false && (Object.keys(list)?.length === 0) ? (
                                    <p className="py-4 mb-0 text-black-50 fs-22 line-height-25 text-center">
                                        No list found
                                    </p>
                                ) : isLoading == false ? (
                                    <>
                                        {Object.keys(list)?.length > 0 ? (
                                            <>
                                                <div className="container-sa dashwrpchart position-relative mt-2">
                                                    <div className="grid grid-cols-3 gap-4">
                                                        <div className="bgwhite">
                                                            <Barchart options={bar4} />
                                                            <div className="legendwrp">
                                                                <span className="benchmark">Benchmark Market : <b>{benchmarket}</b></span>
                                                                <span className="destination">Destination Market : <b>{destinationmarket}</b></span>
                                                            </div>
                                                        </div>

                                                        <div className="gap-4">
                                                            <div className="grid grid-cols-2 gap-4 mb-4">
                                                                <div className="bgwhite flex fixed-height gr-bg">
                                                                    <div className="left-area">
                                                                        <p className="mb-0"><small>Total Qty <br></br>Traded</small><b className="yellowtxt">{this.numberWithDecimal(totalquantity)} MT</b></p>
                                                                    </div>
                                                                </div>

                                                                <div className="bgwhite flex fixed-height gr-bg">
                                                                    <div className="left-area">
                                                                        <p className="mb-0"><small>Total Trade Value <br />(In Lakhs):</small><b className="bluetxt">₹&nbsp;{this.numberWithDecimal(totaltrade)}</b></p>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                            <div className="grid grid-cols-2 gap-4">
                                                                <div className="bgwhite flex fixed-height gr-bg no-mar-top">
                                                                    <div className="left-area">
                                                                        <p className="mb-0"><small>No of Buyers Identified</small><b className="greentxt">{list?.total_buyer}</b></p>
                                                                    </div>

                                                                </div>

                                                                <div className="bgwhite flex fixed-height gr-bg no-mar-top">
                                                                    <div className="left-area">
                                                                        <p className="mb-0"><small>Avg Trade Value / Txn<br />(In Lakhs)</small><b className="martxt">₹&nbsp;{this.numberWithDecimal(avg_trade_value)}</b></p>
                                                                    </div>
                                                                </div>

                                                            </div>

                                                        </div>
                                                        {
                                                            isLoading == false && list ?
                                                                <div className="bgwhite">
                                                                    <div className="fonametxt">
                                                                        <p className="mb-0 d-flex items-center">FPOs: {this.numberWithDecimal(list?.total_number_of_fpos)}</p>
                                                                        <p className="mb-0 d-flex items-center">Requests: {list?.total_request}</p>
                                                                    </div>
                                                                    <Piechart piechartValue={list?.percentage_of_received_request_processed} pending={pending} done={done} />
                                                                </div>
                                                                : ''
                                                        }

                                                        <div className="bgwhite">
                                                            <Barchart options={bar1} />
                                                            <div className="legendwrp bottomchart">
                                                                <span className="benchmark">
                                                                    Potential Farmers : <b>{total_farmers_engaged}</b>
                                                                </span>
                                                                <span className="destination bottomchart ">
                                                                    Actual Farmers : <b>{completed_farmers_engaged}
                                                                    </b></span>
                                                            </div>
                                                        </div>

                                                        <div className="bgwhite">
                                                            <Barchart options={bar2} />
                                                            <div className="legendwrp bottomchart">
                                                                <span className="benchmark">FPO Margins/Txn : <b>{avg_fpo_margin + '%'}</b></span>
                                                            </div>
                                                        </div>

                                                        <div className="bgwhite">
                                                            <Barchart options={bar3} />
                                                            <div className="legendwrp bottomchart">
                                                                <span className="benchmark">All Requests : <b>{total_buyer}</b></span>
                                                                <span className="destination bottomchart">Actual Txn : <b>{total_completed_buyer}</b></span>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div>&nbsp;</div>
                                                    <div className="tableresponsive">
                                                        <>
                                                            {!isLoading && list?.listresult?.results?.length === 0 ? (
                                                                <p className="py-4 mb-0 text-black-50 fs-22 line-height-25 text-center">
                                                                    No list found
                                                                </p>
                                                            ) : !isLoading ? (
                                                                <>
                                                                    {list?.listresult?.results?.length != 0 ? (
                                                                        <>
                                                                            <table className="table buyer-comparison-table">
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th>Txn ID</th>
                                                                                        <th>Txn Date</th>
                                                                                        <th>TAT</th>
                                                                                        <th width="19%">FPO Name</th>
                                                                                        <th width="10%">Commodity</th>
                                                                                        <th width="10%">Farmers Engaged</th>
                                                                                        <th width="10%">Quantity</th>
                                                                                        <th width="8%">Farmer Rate</th>
                                                                                        <th>Buyer Rate</th>
                                                                                        <th>Trade Value</th>
                                                                                        <th width="10%">State</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    {
                                                                                        list?.listresult?.results?.map((answer, i) => {
                                                                                            return (
                                                                                                <tr key={i}>
                                                                                                    <td ><a onClick={() => this.actualTXnimpacttrue(answer)} className="cursor-pointer view-detial-screen">{answer?.id}</a></td>
                                                                                                    <td><div className="text-nowrap">{answer.txn_date !== '' ? moment(answer?.txn_date).format("DD MMM YYYY") : ''}</div></td>
                                                                                                    <td><div className="text-nowrap">{this.getDiffDays(answer.txn_date, answer.fpo_request_date)}</div></td>
                                                                                                    <td>{answer?.fpoName}</td>
                                                                                                    <td>{answer?.commodity_name}</td>
                                                                                                    <td>{Math.round(answer?.total_farmer_engaged)}</td>
                                                                                                    <td>{answer?.existing_stock_level} {answer?.custom_measurement}</td>
                                                                                                    <td>₹&nbsp;{answer?.farmer_rate}</td>
                                                                                                    <td>₹&nbsp;{answer?.buyer_rate}</td>
                                                                                                    <td className="text-nowrap">₹&nbsp;{answer?.trade_value}</td>
                                                                                                    <td>{answer?.stateName}</td>
                                                                                                </tr>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </tbody>


                                                                            </table>
                                                                        </>
                                                                    ) : null}
                                                                </>
                                                            ) : (
                                                                <>
                                                                    {/* Loader */}
                                                                    {/* <CommonPageLoader /> */}
                                                                </>
                                                            )}
                                                        </>
                                                    </div>
                                                </div>
                                                {pageMeta.pageCount !== undefined &&
                                                    pageMeta.pageCount !== 1 && (
                                                        <Pagination
                                                            handleChange={this.handlePagination}
                                                            pageMeta={pageMeta}
                                                        />
                                                    )}
                                            </>
                                        ) : null}
                                    </>
                                ) : (
                                    <>
                                        {/* Loader */}
                                        <CommonPageLoader />
                                    </>
                                )}
                            </>
                        </div>
                        :
                        <div className="actual-txn-impact">

                            <div className="add-re-scr dealclosure acttxn">
                                <div className="container-sa position-relative mt-4">
                                    <div className="add-form-section">
                                        <div className="row items-center justify-between">
                                            <h2 className="m-0 fs-18 mt-0 font-weight-bold">Actual Txn Impact - {actualTxnArray?.id}</h2>
                                            <div className="inner-bg-sec text-center">
                                                <h4>Buyer Recommendation</h4>
                                                <div className="notes-info">This is the Buyer Recommended by your FPO - <b>Suresh</b></div>
                                            </div>
                                            <div className=" pr-0">
                                                <div className="text-right d-flex align-items-center justify-content-end">
                                                    <p className="btn btn-secondary btn-sm add-member-text-back mt-0 mb-0 cursor-pointer d-flex align-items-center justify-content-center w-150" onClick={() => this.actualTXnimpacttrue({})}>
                                                        <img className="mr-2" src={arrow_left_white} alt="name" width="15" />
                                                        Back
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="container-sa mt-4">
                                    <div className="grid grid-cols-4 gap-8 amtwhitewrp">

                                        <div className="mb-0 shadow Our-Crops-section">
                                            <small className="w-90per">Procurement Price offered to farmers</small>
                                            <b className="yellowtxt">₹&nbsp;{actualTxnArray?.farmer_rate}</b>
                                        </div>

                                        {
                                            actualTxnArray?.farmer_rate >= actualTxnArray?.market_price && actualTxnArray?.farmer_rate >= actualTxnArray?.destination_market_price ?
                                                <div className="mb-0 shadow Our-Crops-section">
                                                    <small className="w-90per">Best Procurement Price</small>
                                                    <b className="greentxt">₹&nbsp;{actualTxnArray?.fpo_procurement_cost_per_unit}</b>
                                                </div>
                                                : ''
                                        }
                                        <div className="mb-0 shadow Our-Crops-section">
                                            <small className="w-90per">Timeline of payment to FPO</small><b className="bluetxt">{actualTxnArray?.expected_timeline_for_date_of_procurement} {actualTxnArray?.expected_timeline_for_date_of_procurement_type}</b>
                                        </div>
                                        <div className="mb-0 shadow Our-Crops-section">
                                            <small className="w-90per">FPO Margins Per Unit</small><b className="bluetxt">{actualTxnArray?.fpo_margin_per_unit}</b>
                                        </div>
                                        <div className="mb-0 shadow Our-Crops-section">
                                            <small className="w-90per">Price offered by Buyers</small><b className="drakgreentxt">₹&nbsp;{actualTxnArray?.buyer_rate}</b>
                                        </div>
                                        <div className="mb-0 shadow Our-Crops-section">
                                            <small className="w-90per">No. of farmers engaged</small><b className="drakredtxt">{actualTxnArray?.total_farmer_engaged}</b>
                                        </div>
                                        <div className="mb-0 shadow Our-Crops-section">
                                            <small className="w-90per">Nearest market price</small><b className="drakgreentxt">₹&nbsp;{actualTxnArray?.market_price}<br /><span className="actualpricetxt">{actualTxnArray?.market_price_date != '' && actualTxnArray?.market_price_date != null ? '(' + moment(actualTxnArray?.market_price_date).format("DD-MM-YYYY") + ')' : ""}</span></b>
                                        </div>
                                        <div className="mb-0 shadow Our-Crops-section">
                                            <small className="w-90per">Destination market price</small><b className="drakredtxt">₹&nbsp;{actualTxnArray?.destination_market_price}<br /><span className="actualpricetxt">{actualTxnArray?.destination_market_price_date != '' && actualTxnArray?.destination_market_price_date != null ? '(' + moment(actualTxnArray?.destination_market_price_date).format("DD-MM-YYYY") + ')' : ""}</span></b>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                }

            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            dashboardImpact,
            getfpocommodityList,
            getrmcommodityList,
            getfpoStateList,
            getfponameList
        },
        dispatch
    );
};
let component = DashboardClass;

export const DashboardView = connect(
    null,
    mapDispatchToProps
)(component);