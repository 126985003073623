import React, { Component } from "react";
import user_feedback from "assets/svg/feedback/user_feedback.svg";
import "./feedback.scss";
import { history } from "service/helpers";
export class FpoProfile extends Component {
  render() {
    return (
      <div
        className="background-feedback px-4 py-3 rounded cursor-pointer"
        onClick={() => {
          history.push("/fpo/addaboutus");
        }}
      >
        <div className=" d-flex align-items-center mb-3">
          <div className="mr-3">
            <img src={user_feedback} alt="user.svg" />
          </div>
          <div className="text-white feedback-header">
            {"Update FPO Profile"}
          </div>
        </div>
        <div>
          <p className="text-white feedback-body">
            To create your digital profile you are required to update modules
            given here like member engagement, compliance, financial summary
            etc. For any assistance needed please write to{" "}
            <a href="mailto:team.neo@samunnaticom">team.neo@samunnaticom</a> .
          </p>
        </div>
      </div>
    );
  }
}
