export const optionData1 = {
  chart: {
    type: "gauge",
    height: 140,
  },

  title: {
    text: "",
  },

  pane: {
    startAngle: -125,
    endAngle: 125,
    center: ["50%", "50%"],
    size: 130,
  },
  credits: {
    enabled: false,
  },
  yAxis: {
    min: 0,
    max: 100,
    minorTickWidth: 0,
    minorTickLength: 0,
    tickPixelInterval: 0,
    tickWidth: 0,
    tickLength: 20,
    labels: {
      step: 2,
      rotation: "auto",
    },
    title: {
      text: "",
    },
    labels: {
      enabled: false,
    },
    plotBands: [
      {
        from: 0,
        to: 20,
        thickness: "20",
        color: "#e94f42",
      },
      {
        from: 20,
        to: 50,
        thickness: "20",
        color: "#f2964c",
      },
      {
        from: 50,
        to: 70,
        thickness: "20",
        color: "#fdc84a",
      },
      {
        from: 70,
        to: 90,
        thickness: "20",
        color: "#9ac24c",
      },
      {
        from: 90,
        to: 100,
        thickness: "20",
        color: "#7da744",
        opacity: 0.1,
      },
    ],
  },
  plotOptions: {
    series: {
      dataLabels: {
        enabled: false,
      },
    },
  },
  series: [
    {
      name: "Score",
      data: [0],
      tooltip: {
        valueSuffix: "",
      },
    },
  ],
};

export const optionData2 = {
  chart: {
    type: "gauge",
    height: 140,
  },

  title: {
    text: "",
  },

  pane: {
    startAngle: -125,
    endAngle: 125,
    center: ["50%", "50%"],
    size: 130,
  },
  credits: {
    enabled: false,
  },
  yAxis: {
    min: 0,
    max: 100,
    minorTickWidth: 0,
    minorTickLength: 0,
    tickPixelInterval: 0,
    tickWidth: 0,
    tickLength: 20,
    labels: {
      step: 2,
      rotation: "auto",
    },
    title: {
      text: "",
    },
    labels: {
      enabled: false,
    },
    plotBands: [
      {
        from: 0,
        to: 20,
        thickness: "20",
        color: "#e94f42",
      },
      {
        from: 20,
        to: 50,
        thickness: "20",
        color: "#f2964c",
      },
      {
        from: 50,
        to: 70,
        thickness: "20",
        color: "#fdc84a",
      },
      {
        from: 70,
        to: 90,
        thickness: "20",
        color: "#9ac24c",
      },
      {
        from: 90,
        to: 100,
        thickness: "20",
        color: "#7da744",
        opacity: 0.1,
      },
    ],
  },
  plotOptions: {
    series: {
      dataLabels: {
        enabled: false,
      },
    },
  },
  series: [
    {
      name: "Score",
      data: [0],
      tooltip: {
        valueSuffix: "",
      },
    },
  ],
};

export const optionData3 = {
  chart: {
    type: "gauge",
    height: 140,
  },

  title: {
    text: "",
  },

  pane: {
    startAngle: -125,
    endAngle: 125,
    center: ["50%", "50%"],
    size: 130,
  },
  credits: {
    enabled: false,
  },
  yAxis: {
    min: 0,
    max: 100,
    minorTickWidth: 0,
    minorTickLength: 0,
    tickPixelInterval: 0,
    tickWidth: 0,
    tickLength: 20,
    labels: {
      step: 2,
      rotation: "auto",
    },
    title: {
      text: "",
    },
    labels: {
      enabled: false,
    },
    plotBands: [
      {
        from: 0,
        to: 20,
        thickness: "20",
        color: "#e94f42",
      },
      {
        from: 20,
        to: 50,
        thickness: "20",
        color: "#f2964c",
      },
      {
        from: 50,
        to: 70,
        thickness: "20",
        color: "#fdc84a",
      },
      {
        from: 70,
        to: 90,
        thickness: "20",
        color: "#9ac24c",
      },
      {
        from: 90,
        to: 100,
        thickness: "20",
        color: "#7da744",
        opacity: 0.1,
      },
    ],
  },
  plotOptions: {
    series: {
      dataLabels: {
        enabled: false,
      },
    },
  },
  series: [
    {
      name: "Score",
      data: [0],
      tooltip: {
        valueSuffix: "",
      },
    },
  ],
};
export const optionData4 = {
  chart: {
    type: "gauge",
    height: 140,
  },

  title: {
    text: "",
  },

  pane: {
    startAngle: -125,
    endAngle: 125,
    center: ["50%", "50%"],
    size: 130,
  },
  credits: {
    enabled: false,
  },
  yAxis: {
    min: 0,
    max: 100,
    minorTickWidth: 0,
    minorTickLength: 0,
    tickPixelInterval: 0,
    tickWidth: 0,
    tickLength: 20,
    labels: {
      step: 2,
      rotation: "auto",
    },
    title: {
      text: "",
    },
    labels: {
      enabled: false,
    },
    plotBands: [
      {
        from: 0,
        to: 20,
        thickness: "20",
        color: "#e94f42",
      },
      {
        from: 20,
        to: 50,
        thickness: "20",
        color: "#f2964c",
      },
      {
        from: 50,
        to: 70,
        thickness: "20",
        color: "#fdc84a",
      },
      {
        from: 70,
        to: 90,
        thickness: "20",
        color: "#9ac24c",
      },
      {
        from: 90,
        to: 100,
        thickness: "20",
        color: "#7da744",
        opacity: 0.1,
      },
    ],
  },
  plotOptions: {
    series: {
      dataLabels: {
        enabled: false,
      },
    },
  },
  series: [
    {
      name: "Score",
      data: [0],
      tooltip: {
        valueSuffix: "",
      },
    },
  ],
};

export const optionData5 = {
  chart: {
    type: "gauge",
    height: 140,
  },

  title: {
    text: "",
  },

  pane: {
    startAngle: -125,
    endAngle: 125,
    center: ["50%", "50%"],
    size: 130,
  },
  credits: {
    enabled: false,
  },
  yAxis: {
    min: 0,
    max: 100,
    minorTickWidth: 0,
    minorTickLength: 0,
    tickPixelInterval: 0,
    tickWidth: 0,
    tickLength: 20,
    labels: {
      step: 2,
      rotation: "auto",
    },
    title: {
      text: "",
    },
    labels: {
      enabled: false,
    },
    plotBands: [
      {
        from: 0,
        to: 20,
        thickness: "20",
        color: "#e94f42",
      },
      {
        from: 20,
        to: 50,
        thickness: "20",
        color: "#f2964c",
      },
      {
        from: 50,
        to: 70,
        thickness: "20",
        color: "#fdc84a",
      },
      {
        from: 70,
        to: 90,
        thickness: "20",
        color: "#9ac24c",
      },
      {
        from: 90,
        to: 100,
        thickness: "20",
        color: "#7da744",
        opacity: 0.1,
      },
    ],
  },
  plotOptions: {
    series: {
      dataLabels: {
        enabled: false,
      },
    },
  },
  series: [
    {
      name: "Score",
      data: [0],
      tooltip: {
        valueSuffix: "",
      },
    },
  ],
};

export const optionData6 = {
  chart: {
    type: "gauge",
    height: 140,
  },

  title: {
    text: "",
  },

  pane: {
    startAngle: -125,
    endAngle: 125,
    center: ["50%", "50%"],
    size: 130,
  },
  credits: {
    enabled: false,
  },
  yAxis: {
    min: 0,
    max: 100,
    minorTickWidth: 0,
    minorTickLength: 0,
    tickPixelInterval: 0,
    tickWidth: 0,
    tickLength: 20,
    labels: {
      step: 2,
      rotation: "auto",
    },
    title: {
      text: "",
    },
    labels: {
      enabled: false,
    },
    plotBands: [
      {
        from: 0,
        to: 20,
        thickness: "20",
        color: "#e94f42",
      },
      {
        from: 20,
        to: 50,
        thickness: "20",
        color: "#f2964c",
      },
      {
        from: 50,
        to: 70,
        thickness: "20",
        color: "#fdc84a",
      },
      {
        from: 70,
        to: 90,
        thickness: "20",
        color: "#9ac24c",
      },
      {
        from: 90,
        to: 100,
        thickness: "20",
        color: "#7da744",
        opacity: 0.1,
      },
    ],
  },
  plotOptions: {
    series: {
      dataLabels: {
        enabled: false,
      },
    },
  },
  series: [
    {
      name: "Score",
      data: [0],
      tooltip: {
        valueSuffix: "",
      },
    },
  ],
};

export const optionData7 = {
  chart: {
    type: "gauge",
    height: 140,
  },

  title: {
    text: "",
  },

  pane: {
    startAngle: -125,
    endAngle: 125,
    center: ["50%", "50%"],
    size: 130,
  },
  credits: {
    enabled: false,
  },
  yAxis: {
    min: 0,
    max: 100,
    minorTickWidth: 0,
    minorTickLength: 0,
    tickPixelInterval: 0,
    tickWidth: 0,
    tickLength: 20,
    labels: {
      step: 2,
      rotation: "auto",
    },
    title: {
      text: "",
    },
    labels: {
      enabled: false,
    },
    plotBands: [
      {
        from: 0,
        to: 20,
        thickness: "20",
        color: "#e94f42",
      },
      {
        from: 20,
        to: 50,
        thickness: "20",
        color: "#f2964c",
      },
      {
        from: 50,
        to: 70,
        thickness: "20",
        color: "#fdc84a",
      },
      {
        from: 70,
        to: 90,
        thickness: "20",
        color: "#9ac24c",
      },
      {
        from: 90,
        to: 100,
        thickness: "20",
        color: "#7da744",
        opacity: 0.1,
      },
    ],
  },
  plotOptions: {
    series: {
      dataLabels: {
        enabled: false,
      },
    },
  },
  series: [
    {
      name: "Score",
      data: [0],
      tooltip: {
        valueSuffix: "",
      },
    },
  ],
};

export const optionData8 = {
  chart: {
    type: "gauge",
    height: 140,
  },

  title: {
    text: "",
  },

  pane: {
    startAngle: -125,
    endAngle: 125,
    center: ["50%", "50%"],
    size: 130,
  },
  credits: {
    enabled: false,
  },
  yAxis: {
    min: 0,
    max: 100,
    minorTickWidth: 0,
    minorTickLength: 0,
    tickPixelInterval: 0,
    tickWidth: 0,
    tickLength: 20,
    labels: {
      step: 2,
      rotation: "auto",
    },
    title: {
      text: "",
    },
    labels: {
      enabled: false,
    },
    plotBands: [
      {
        from: 0,
        to: 20,
        thickness: "20",
        color: "#e94f42",
      },
      {
        from: 20,
        to: 50,
        thickness: "20",
        color: "#f2964c",
      },
      {
        from: 50,
        to: 70,
        thickness: "20",
        color: "#fdc84a",
      },
      {
        from: 70,
        to: 90,
        thickness: "20",
        color: "#9ac24c",
      },
      {
        from: 90,
        to: 100,
        thickness: "20",
        color: "#7da744",
        opacity: 0.1,
      },
    ],
  },
  plotOptions: {
    series: {
      dataLabels: {
        enabled: false,
      },
    },
  },
  series: [
    {
      name: "Score",
      data: [0],
      tooltip: {
        valueSuffix: "",
      },
    },
  ],
};

export const optionDataForFPo = {
  chart: {
    type: "gauge",
    height: 250,
  },

  title: {
    text: "",
  },

  pane: {
    startAngle: -125,
    endAngle: 125,
    center: ["50%", "50%"],
    size: 225,
  },
  credits: {
    enabled: false,
  },
  yAxis: {
    min: 0,
    max: 100,
    minorTickWidth: 0,
    minorTickLength: 0,
    tickPixelInterval: 0,
    tickWidth: 0,
    tickLength: 20,
    labels: {
      step: 2,
      rotation: "auto",
    },
    title: {
      text: "",
    },
    labels: {
      enabled: false,
    },
    plotBands: [
      {
        from: 0,
        to: 20,
        thickness: "20",
        color: "#e94f42",
      },
      {
        from: 20,
        to: 50,
        thickness: "20",
        color: "#f2964c",
      },
      {
        from: 50,
        to: 70,
        thickness: "20",
        color: "#fdc84a",
      },
      {
        from: 70,
        to: 90,
        thickness: "20",
        color: "#9ac24c",
      },
      {
        from: 90,
        to: 100,
        thickness: "20",
        color: "#7da744",
      },
    ],
  },
  plotOptions: {
    series: {
      dataLabels: {
        enabled: false,
      },
    },
  },
  series: [
    {
      name: "Score",
      data: [0],
      tooltip: {
        valueSuffix: "",
      },
    },
  ],
};
