import React, { Component } from "react";
import certifying from "../../../assets/images/academy/certifying-institute.png";
import { getDescriptionData } from "service/helperFunctions";
import { SharePopup } from "../SharePopup";
import faverate from "../../../assets/images/icon/faverate.svg";
import faverateColor from "assets/images/icon/heart.svg";
import share from "assets/images/icon/share.svg";

import { Pagination } from "component/Faq/FaqView/pagination";
import { PracticeFileCard } from "./PracticeFileCard";

import {
  addfaviorites,
  addReview,
  getReviewList,
  deletefaviorites,
  getRecentViewList,
} from "action/Acadamy";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { CommonPageLoader } from "component/common";
import SimpleReactValidator from "simple-react-validator";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from "swiper";

import "./CoursesDetails.scss";

export class CoursesDetailsClass extends Component {
  constructor() {
    super();
    SwiperCore.use([Navigation]);
    this.state = {
      review: "",
      isFavouriteAdding: false,
      reviewList: [],
      isLoading: false,
      isSharePopup: false,
    };
    this.validator = new SimpleReactValidator();
  }

  componentDidMount() {
    this._getReviewList(1);
  }

  _getReviewList = (page) => {
    let { getReviewList, selectedSectionsData } = this.props;
    let { courseId } = selectedSectionsData;
    getReviewList(courseId, { page }).then((data) => {
      this.setState({
        reviewList: data.results,
        pageMeta: data.pageMeta,
        isLoading: false,
      });
    });
  };

  handleAddFavourities = (chapterId, courseId) => {
    let body = {
      courseId: courseId,
      chapterId: chapterId,
    };

    let { addfaviorites } = this.props;

    this.setState({ isFavouriteAdding: true });

    addfaviorites(body).then(() => {
      this.props.handleChange();
      this.setState({ isFavouriteAdding: false });
    });
  };

  handledeleteFavourities = (chapterId) => {
    let body = {
      chapterId: chapterId,
    };

    let { deletefaviorites } = this.props;

    this.setState({ isFavouriteAdding: true });

    deletefaviorites(body).then(() => {
      this.props.handleChange();
      this.setState({ isFavouriteAdding: false });
    });
  };

  handleInput = ({ target: { name, value } }) => {
    this.setState({
      [name]: value,
    });
  };

  handleSubmit = (courseId) => {
    if (this.validator.allValid()) {
      let { review } = this.state;
      let { addReview } = this.props;
      let body = {
        courseId,
        reviewComment: review,
      };

      addReview(body).then(() => {
        this.validator.hideMessages();
        this.setState({ review: "" }, () => {
          this._getReviewList(1);
        });
      });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  //handle page change
  handlePagination = (page) => {
    this.setState({ isLoading: true });
    this._getReviewList(page);
  };

  //open share popup
  togglePopup = (key) => {
    this.setState((prevState) => ({
      [key]: !prevState[key],
    }));
  };

  render() {
    let {
      coursesData,
      selectedSectionsData,
      recentViewList,
      recentViewCountData,
      currentChaptersId,
    } = this.props;
    let {
      skillsCovered,
      Author,
      certifyingInstitutions = {},
      difficultyLevel,
      practices = [],
    } = coursesData;
    let { title, objectiveSection, chapters, courseId } = selectedSectionsData;
    let { noOfCourseViews } = recentViewCountData;

    let { instituteName = "" } = certifyingInstitutions;
    let selectedCourse = [];
    selectedCourse = chapters.filter((x) => x.chapterId === currentChaptersId);

    let {
      review,
      isFavouriteAdding,
      reviewList,
      pageMeta = {},
      isLoading,
      isSharePopup,
    } = this.state;

    const params = {
      slidesPerView: 1,
      spaceBetween: 30,
      loop: false,
      draggable: true,
      breakpoints: {
        500: {
          slidesPerView: 1,
        },
        768: {
          slidesPerView: 1,
        },
      },
      navigation: {
        nextEl: "#academy-next",
        prevEl: "#academy-prev",
      },
    };
    return (
      <section className="courses-details-section">
        {practices.length !== 0 ? (
          <div className="row mb-3">
            <div className="col-sm-12">
              <div className="practice-card">
                <div className="d-flex w-100">
                  {practices.length > 1 ? (
                    <div className="d-flex align-items-center">
                      <i
                        id="academy-prev"
                        className="icon-keyboard-arrow-left fs-30"
                      ></i>
                    </div>
                  ) : null}
                  <Swiper {...params} className="w-100">
                    {practices.map((list, index) => (
                      <SwiperSlide key={index}>
                        <PracticeFileCard practiceFile={list} key={index} />
                      </SwiperSlide>
                    ))}
                  </Swiper>
                  {practices.length > 1 ? (
                    <div className="d-flex align-items-center">
                      <i
                        id="academy-next"
                        className="icon-keyboard-arrow-right fs-30"
                      ></i>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        ) : null}
        <div className="row">
          <div className="col-sm-8">
            <div className="d-flex">
              <h4>{title}</h4>
              <p className="difficulty-level-text pl-3 mb-0">
                {difficultyLevel}
              </p>
            </div>
            <p dangerouslySetInnerHTML={{ __html: objectiveSection }}></p>
          </div>
          <div className="col-sm-4 d-flex align-items-start justify-content-center">
            <div className="cours-certifying">
              <img src={certifying} />
              <p>{certifyingInstitutions && instituteName}</p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-8">
            <div className="name-card">
              <div className="pick-letter color">
                {Author && Author.authorName
                  ? Author.authorName.substring(0, 1)
                  : ""}
              </div>
              <div className="fs-16 fw-600 line-height-27 text-black">
                {Author && Author.authorName ? Author.authorName : ""}
              </div>
            </div>
          </div>
          <div className="col-sm-4">
            <div className="cours-icon-list">
              <ul>
                <li className="text-green-18">
                  {noOfCourseViews} {"views"}
                </li>
                {isFavouriteAdding && selectedCourse[0].isFavourite ? (
                  <li>
                    <img src={faverateColor} />
                  </li>
                ) : isFavouriteAdding && !selectedCourse[0].isFavourite ? (
                  <li>
                    <img src={faverate} />
                  </li>
                ) : selectedCourse[0].isFavourite ? (
                  <li
                    className="cursor-pointer"
                    onClick={() =>
                      this.handledeleteFavourities(
                        selectedCourse[0].chapterId,
                        courseId
                      )
                    }
                  >
                    <img src={faverateColor} />
                  </li>
                ) : (
                  <li
                    className="cursor-pointer"
                    onClick={() =>
                      this.handleAddFavourities(
                        selectedCourse[0].chapterId,
                        courseId
                      )
                    }
                  >
                    <img src={faverate} />
                  </li>
                )}
                <li
                  className="cursor-pointer"
                  onClick={() => this.togglePopup("isSharePopup")}
                >
                  <img src={share} />
                </li>
              </ul>
            </div>
          </div>
        </div>
        <hr />

        <div className="courses-detail-description">
          <h4>Skills</h4>
          <p
            dangerouslySetInnerHTML={{
              __html: getDescriptionData(skillsCovered, 125),
            }}
          ></p>
        </div>
        <hr />

        <div className="courses-add-review">
          <h4 className="line-height-34">Add comments</h4>
          <div className="text-area">
            <textarea
              name="review"
              value={review}
              id=""
              cols="30"
              rows="2"
              onChange={this.handleInput}
              placeholder="Type Text here"
            ></textarea>
            <div
              className="send-area cursor-pointer"
              onClick={() => this.handleSubmit(courseId)}
            >
              <i className="icon-send fs-20"></i>
            </div>
          </div>
          {this.validator.message("review", review, "required|max:200", {
            className: "text-danger",
          })}
        </div>
        <div id="scrollableReview">
          <div className="">
            <h5 className="mb-3 font-weight-bold fs-24">Comments</h5>
          </div>
          {!isLoading ? (
            reviewList.length !== 0 ? (
              reviewList.map((list, index) => {
                let { userDetails = {}, reviewComment = "" } = list;
                return (
                  <React.Fragment key={index}>
                    <div className="review-list">
                      <div className="d-flex">
                        <div className="profile-area">
                          {userDetails &&
                          userDetails.profileImageUrl !== null ? (
                            <img
                              src={userDetails.profileImageUrl}
                              alt="profileImageUrl"
                            />
                          ) : (
                            <div className="pick-letter color">
                              {userDetails.userName.substring(0, 1)}
                            </div>
                          )}
                        </div>
                        <div className="d-flex flex-column">
                          <div className="d-flex flex-column justify-content-center">
                            <h4>{userDetails.userName}</h4>
                          </div>
                          <div className="d-flex">
                            <p className="fs-18 text-black-50 mb-2">
                              {reviewComment}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    {pageMeta.pageCount !== undefined &&
                      pageMeta.pageCount !== 1 && (
                        <Pagination
                          handleChange={this.handlePagination}
                          pageMeta={pageMeta}
                        />
                      )}
                  </React.Fragment>
                );
              })
            ) : (
              <p className="my-3 text-black-50 text-center fs-18">
                No reviews !!!
              </p>
            )
          ) : (
            <>
              {/* Loader */}
              <CommonPageLoader />
            </>
          )}
        </div>
        {recentViewList.length !== 0 ? (
          <div className="courses-user-list my-5">
            <div className="d-flex profile-list-color">
              {recentViewList.map((list, index) => (
                <div className="name-area" key={index}>
                  <div className="name-card">
                    <div className="pick-letter">{list.userName.charAt(0)}</div>
                    <div className="with-destination">
                      {list.userName}
                      {/* <div className="location">Madurai</div> */}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : null}
        <SharePopup
          isOpen={isSharePopup}
          toggle={() => this.togglePopup("isSharePopup")}
          isCourseTab={true}
          courseId={courseId}
        />
      </section>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      addfaviorites,
      addReview,
      deletefaviorites,
      getRecentViewList,
      getReviewList,
    },
    dispatch
  );
};

let component = CoursesDetailsClass;

export const CoursesDetails = connect(null, mapDispatchToProps)(component);
