import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useHistory } from "react-router-dom";
import { getRMFPOList } from "action/GradingAct";
import { CommonPageLoader, Pagination } from "component/common";
import { getKYMNewFPOList } from "action/KYMAct";
import { debounceFunction } from "service/debounce";
import TermsPolicyPopup from "../TermsPolicyPopup";
import ".././style.scss";

const tableHeader = [
  { title: "FPO ID" },
  { title: "FPO Name" },
  { title: "Member List" },
];

const RMKYMNew = ({ module = "rm", getRMFPOListApi, getKYMNewFPOListApi }) => {
  const history = useHistory();
  const [fpoList, setFPOList] = useState([]);
  const [pageMeta, setPageMeta] = useState({});
  const [loading, setLoading] = useState(false);
  const [searchData, setSearchData] = useState("");
  const [memberListModal, setMemberListModal] = useState(false);
  const isRM = module === "rm";

  const getFPOList = useCallback(
    (page, search = "") => {
      setLoading(true);
      const api = isRM ? getRMFPOListApi : getKYMNewFPOListApi;
      api({ page, search })
        .then(({ results, pageMeta }) => {
          setFPOList(results);
          setPageMeta(pageMeta);
          setLoading(false);
        })
        .catch(() => setLoading(false));
    },
    [getRMFPOListApi, getKYMNewFPOListApi, isRM]
  );

  useEffect(() => {
    getFPOList(1);
  }, [getFPOList]);

  const handlePagination = (page) => {
    getFPOList(page);
  };

  const handleSearch = ({ target: { value } }) => {
    setSearchData(value);
    debounceFunction(() => getFPOList(1, value), 1000);
  };

  const handleMemberListRoute = (isAccepted, fpoId) => {
    if (isAccepted) {
      history.push(`/${module}/kym-new-list/${fpoId}`);
      setMemberListModal(false);
    } else {
      setMemberListModal(true);
    }
  };

  return (
    <div className="container-sa my-3">
      {loading ? (
        <CommonPageLoader />
      ) : (
        <div className="rm-dashboard">
          <div className="d-flex align-items-center justify-content-between kym_new_list">
            <div></div>
            <div className="mt-md-0 mt-3">
              <input
                type="text"
                value={searchData}
                className="form-control form-control-sa w-350"
                placeholder="Search by FPO ID or Name"
                onChange={(e) => handleSearch(e)}
              />
            </div>
          </div>
          {fpoList.length !== 0 ? (
            <div className="d-flex container-sa flex-column flex-wrap mt-3 px-0 justify-content-center table_responsive">
              <table>
                <thead>
                  <tr>
                    {tableHeader?.map(({ title }, index) => (
                      <th key={index}>{title}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {fpoList?.map(({ fpoDetails }, index) => {
                    const { fpoId, fpoName, userMaster } = fpoDetails;
                    return (
                      <React.Fragment key={index}>
                        <tr>
                          <td>{fpoId}</td>
                          <td>{fpoName}</td>
                          <td
                            className="edit-button"
                            onClick={() => {
                              handleMemberListRoute(
                                userMaster?.isTCAccepted,
                                fpoId
                              );
                              localStorage.setItem("rmFPOID", fpoId);
                              localStorage.setItem("rmFPOName", fpoName);
                            }}
                          >
                            Member List
                          </td>
                        </tr>
                        <tr className="trans-bg">
                          <td className="empty-height" height="12"></td>
                        </tr>
                      </React.Fragment>
                    );
                  })}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="col-12">
              <p className="py-5 mb-0 text-black-50 fs-22 line-height-25 text-center">
                No data found
              </p>
            </div>
          )}
          <Pagination handleChange={handlePagination} pageMeta={pageMeta} />
        </div>
      )}
      {memberListModal ? (
        <TermsPolicyPopup
          isOpen={memberListModal}
          toggle={() => setMemberListModal(false)}
        />
      ) : null}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { getRMFPOListApi: getRMFPOList, getKYMNewFPOListApi: getKYMNewFPOList },
    dispatch
  );
};

export default connect(null, mapDispatchToProps)(RMKYMNew);
