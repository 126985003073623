import React, { Component } from "react";
import moment from "moment";
import { getDescriptionData } from "service/helperFunctions";
import rightCircle from "assets/images/play-circle.png";
import { history } from "service/helpers";
export class VideoCard extends Component {
  render() {
    const {
      videoDetails = {},
      handleAddFavourities = {},
      handledeleteFavourities = {},
      currentIndex,
    } = this.props;

    let {
      thumbnailImageUrl = "",
      title = "",
      trendingvideoId = "",
      publishDate = "",
      isFavourite = false,
      isFavouriteAdding = false,
    } = videoDetails;

    return (
      <div
        className="video-card-list"
        onClick={() =>
          history.push(`/academy/trending-video/${trendingvideoId}`)
        }
      >
        <div
          className={`video-thumb info-thumb `}
          style={{ backgroundImage: "url(" + `${thumbnailImageUrl}` + ")" }}
        >
          <div className="d-flex justify-content-between h-100 video-body flex-column p-3">
            <div className="video-content pt-3">
              <h5 className="fs-22 font-weight-bold text-white line-height-31">
                {getDescriptionData(title, 30)}
              </h5>
              <div className="text-white fs-14 line-height-27 fw-500">
                {moment(publishDate).format("MMM DD, YYYY")}
              </div>
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <div className="play-link w-100 pb-1">
                <img src={rightCircle} alt="rightCircle" />
              </div>
              <div className="" onClick={(e) => e.stopPropagation()}>
                {!isFavouriteAdding ? (
                  isFavourite ? (
                    <i
                      className="icon-heart-white fs-18 cursor-pointer"
                      onClick={() =>
                        handledeleteFavourities(trendingvideoId, currentIndex)
                      }
                    ></i>
                  ) : (
                    <i
                      className="icon-heart-white-border fs-18 cursor-pointer"
                      onClick={() =>
                        handleAddFavourities(trendingvideoId, currentIndex)
                      }
                    ></i>
                  )
                ) : (
                  <div
                    className="spinner-border ml-2 spinner-border-sm"
                    role="status"
                  ></div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default VideoCard;
