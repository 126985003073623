import React, { Component } from "react";
import BankDetailCard from "./Card";
import Strip from "./strip";
import { Pagination, CommonPageLoader } from "component/common";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getAllBankDetails } from "action/KeyFinancialAct";

export default class BankDetailClass extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bankDetails: [],
      pageMeta: {},
      page: 1,
      isLoading: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.activeTab != this.props.activeTab) {
      let { activeTab } = this.props;
      if (activeTab == 6) {
        this.handleBankDetails(1);
      }
    }
    if (this.props.activeTab == 6) {
      this.props.searchValue !== prevProps.searchValue &&
        this.handleBankDetails(1);
    }
  }

  handleBankDetails = (page) => {
    let { searchValue } = this.props;
    this.setState({ isLoading: true });
    let payload = {
      limit: 4,
      page,
      search: searchValue,
    };
    this.props.getAllBankDetails(payload).then((data) => {
      this.setState({
        bankDetails: data.results,
        pageMeta: data.pageMeta,
        isLoading: false,
      });
    });
  };

  handlePagination = (page) => {
    this.handleBankDetails(page.page);
  };

  render() {
    const { bankDetails, pageMeta, isLoading } = this.state;
    let { edit = false } = this.props;
    return (
      <>
        {!isLoading && bankDetails.length === 0 ? (
          <p className="py-4 mb-0 text-black-50 fs-22 line-height-25 text-center">
            No data found
          </p>
        ) : !isLoading ? (
          <>
            {bankDetails.length != 0 ? (
              <>
                <div className="scroll-x">
                  <div
                    className="d-flex container-sa flex-column  flex-wrap mt-3 px-0 justify-content-center"
                    style={
                      this.props.view === "1" ? { minWidth: "1200px" } : null
                    }
                  >
                    <div className="d-flex flex-wrap com-list-card">
                      {bankDetails.map((credit, index) => {
                        return this.props.view === "2" ? (
                          <div className="col-md-3 col-lg-3 col-6  mb-2 mt-3 px-2">
                            <BankDetailCard key={index} {...credit} />
                          </div>
                        ) : (
                          <Strip
                            payload={credit}
                            key={index}
                            editAccess={edit}
                          />
                        );
                      })}
                    </div>
                  </div>
                </div>
                {pageMeta.pageCount !== undefined &&
                  pageMeta.pageCount !== 1 && (
                    <Pagination
                      handleChange={this.handlePagination}
                      pageMeta={pageMeta}
                    />
                  )}
              </>
            ) : (
              <p className="py-4 mb-0 text-black-50 fs-22 line-height-25 text-center">
                No data found
              </p>
            )}
          </>
        ) : (
          <>
            <CommonPageLoader />
          </>
        )}
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getAllBankDetails,
    },
    dispatch
  );
};

let component = BankDetailClass;

export const BankDetailList = connect(null, mapDispatchToProps)(component);
