import React, { Component } from 'react';
import { AllEvents, Stories } from '../../component/fpo/events';
import { connect } from 'react-redux';
import { getAccessdata } from "service/helperFunctions"
import { UserAuth } from "helpers/UserAuth";
export class EventsClass extends Component {
   state = {
      component: 'AllEvents',
   }
   handleToggle = (component_name) => {
      this.setState({
         component: component_name,
      });
   };

   //lifecycles
   componentDidMount = () => {
      window.scrollTo({
         top: 0,
         behavior: 'smooth',
      });
   }

   render() {
      let { accessData, match } = this.props
      let permissionData = getAccessdata(accessData, 'eventsImpacts')

      let {
         edit = false,
         viewAccess = false,
         createAccess = false,
         deleteAccess = false
      } = permissionData

      let { component } = this.state
      return (
         <div>
            {component === 'AllEvents' ?
               <AllEvents
                  handleToggle={this.handleToggle}
                  editAccess={edit}
                  viewAccess={viewAccess}
                  createAccess={createAccess}
                  deleteAccess={deleteAccess}
                  id={match.params.id}
               />
               :
               <Stories
                  handleToggle={this.handleToggle}
                  editAccess={edit}
                  viewAccess={viewAccess}
                  createAccess={createAccess}
                  deleteAccess={deleteAccess}
                  id={match.params.id}
               />
            }
         </div>
      );
   }
}

const mapStateToProps = (state) => ({
   accessData: state.home.accessData
});

let component = UserAuth(EventsClass, { service: 'eventsImpacts' });

export const Events = connect(mapStateToProps, null)(component);