import React, { Component } from "react";
import {
  NormalInput,
  NormalButton,
  NormalDate,
  NormalSelect,
} from "component/common";
import "./style.scss";
// Redux Connection
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SimpleReactValidator from "simple-react-validator";
import moment from "moment";
import { getSignUpEntityType } from "action/RegAct";
import {
  fileUpload,
  getAboutusMemberDetail,
  updateAboutUsDetails,
  getDistrictApiwithState,
  allState,
  getPincodeApiwithStateDistrict,
  getcropBusinessInfra,
} from "action/MemberAct";
import { emailAndNumberValidation } from "action/RegAct";
import { getAllFinancialYearDropdown } from "action/ComplianceAct";
import { debounceFunction } from "service/debounce";
import { Toast } from "service/toast";

import { history } from "service/helpers";

import arrow_left from "assets/svg/add_member/arrow_left.svg";
import { FPOformFields } from "component/auth/registration/FPO/RegFpoDatas";

export class AddAboutUsClass extends Component {
  constructor() {
    super();
    this.entityIdRef = React.createRef();
    this.state = {
      fpoName: "",
      promotingInstitution: "",
      resourseInstitution: "",
      logoFileName: "",
      logoFile: "",
      islogoFileUploading: false,
      insitituteLogoFileName: "",
      insitituteLogoFile: "",
      teamLogoFileName: "",
      teamLogo: "",
      isTeamLogoFileuploading: false,
      isinstitutelogoFileUploading: false,
      resourseInsitituteLogoFileName: "",
      resourseInsitituteLogoFile: "",
      isresourseInsitituteLogoFileUploading: false,
      officialWebsite: "",
      instragamLink: "",
      twitterLink: "",
      facebookLink: "",
      village: "",
      pincode: "",
      district: "",
      state: "",
      districtOptions: [],
      pincodeOptions: [],
      stateOption: [],
      foundedDate: "",
      membership: "",
      villageCovered: 0,
      femaleMembers: 0,
      maleMembers: 0,
      acre: 0,
      shareCapitals: 0,
      turnOver: 0,
      business_activties: [],
      selected_business_activties: [],
      selected_crops: [],
      crops_list: [],
      license_list: [],
      selected_license: [],
      infrastructure_list: [],
      selected_infrastructure: [],
      cropMapping: [],
      allFinancialYear: [],
      FyearId: "",
      companyCin: "",
      entityList: [],
      societyTypeOptions: [],
      entityId: "",
      entity_value: "",
      societyType: "",
      formFieldsFPO: { ...FPOformFields },
      CINValidate: false,
    };
  }

  //validation
  validator = new SimpleReactValidator({
    element: (message) => (
      <span className="error-message text-danger fs-14">{message}</span>
    ),
    autoForceUpdate: this,
  });

  Validation = (value) => {
    const { entityId } = this.state;
    let body = {
      companyCin: value,
      cooperative_registration_number: value,
      trust_registration_number: value,
    };
    if (entityId === "2") {
      delete body.cooperative_registration_number;
      delete body.trust_registration_number;
    } else if (entityId === "3") {
      delete body.companyCin;
      delete body.trust_registration_number;
    } else if (entityId === "4") {
      delete body.companyCin;
      delete body.cooperative_registration_number;
    }
    this.props.emailAndNumberValidation(body).then((message) => {
      if (
        message === "This company CIN is already registered" ||
        message ===
          "This cooperative registration number is already registered" ||
        message === "This trust registration number is already registered" ||
        message === "Please Add Any One Fields"
      ) {
        this.setState({
          CINValidate: true,
        });
      } else {
        this.setState({
          CINValidate: false,
        });
      }
    });
  };

  handleInput = ({ target: { name, value } }) => {
    let { membership, femaleMembers } = this.state;
    if (name === "state") {
      this.setState(
        {
          [name]: value,
          district: "",
          pincode: "",
        },
        () => {
          this.props.getDistrictApiwithState({ id: value }).then((data) => {
            let districtData = [];

            data.map((list) => {
              districtData.push({
                value: list.districtId,
                label: list.districtName,
              });
            });
            this.setState({
              districtOptions: districtData,
            });
          });
        }
      );
    } else if (name === "district") {
      this.setState(
        {
          [name]: value,
          pincode: "",
        },
        () => {
          this.props
            .getPincodeApiwithStateDistrict({ id: value })
            .then((data) => {
              let pinocodeData = [];
              data.map((list) => {
                pinocodeData.push({
                  value: list.pincodeId,
                  label: list.pincode,
                });
              });
              this.setState({
                pincodeOptions: pinocodeData,
              });
            });
        }
      );
    } else if (name === "membership") {
      this.setState({
        [name]: value,
        maleMembers: (value - femaleMembers).toString(),
      });
    } else if (name === "femaleMembers") {
      this.setState({
        [name]: value,
        maleMembers: (membership - value).toString(),
      });
    } else if (name === "entityId") {
      this.setState({
        [name]: value,
        entity_value: "",
        societyType: "",
        CINValidate: false,
      });
    } else if (name === "entity_value") {
      this.setState({
        [name]: value,
      });
      if (value === "") {
        this.setState({
          CINValidate: false,
        });
      } else {
        debounceFunction(() => {
          this.Validation(value);
        }, 1000);
      }
    } else {
      this.setState({
        [name]: value,
      });
    }
  };

  componentDidMount = () => {
    let { id } = this.props;

    this.getFinancialYear();

    this.props.allState().then((data) => {
      let StateData = [];

      data.map((list) => {
        StateData.push({ value: list.stateId, label: list.stateName });
      });
      this.setState({
        stateOption: StateData,
      });
    });

    this.props.getcropBusinessInfra().then((data) => {
      this.setState({
        business_activties: data.businessList,
        crops_list: data.cropsList,
        infrastructure_list: data.infrastructure,
        license_list: data.licensedropdown,
      });
    });

    this._getData(id);
    this._getEntityList();
    this.setState({
      formFieldsFPO: { ...FPOformFields },
    });
    let societyTypeData = [
      { value: "pacs", label: "PACS" },
      { value: "dairy", label: "Dairy Society" },
      { value: "veg_fed", label: "Veg Fed Society" },
      { value: "lamps", label: "LAMPS" },
    ];
    this.setState({
      societyTypeOptions: societyTypeData,
    });
  };

  _getEntityList = () => {
    this.props.getSignUpEntityType().then((data) => {
      let entityData = [];
      data.map((list) => {
        entityData.push({ value: list.entityId, label: list.entityName });
      });
      this.setState({
        entityList: entityData,
      });
    });
  };

  getFinancialYear() {
    this.props.getAllFinancialYearDropdown().then((data) => {
      let financialData = [];
      data.financialYearMaster.map((list) => {
        financialData.push({ value: list.fymYear, label: list.fymYear });
      });
      this.setState({
        allFinancialYear: financialData,
      });
    });
  }

  _getData = (id) => {
    let { getAboutusMemberDetail, emailAndNumberValidation } = this.props;

    getAboutusMemberDetail().then((data) => {
      this.setState({
        fpoName: data.fpoName,
        promotingInstitution:
          data.promotingInstitution !== null ? data.promotingInstitution : "",
        resourseInstitution:
          data.resourcingInstitution !== null ? data.resourcingInstitution : "",
        logoFileName: data.fpoLogoName !== null ? data.fpoLogoName : "",
        logoFile: data.fpoLogoURL !== null ? data.fpoLogoURL : "",
        insitituteLogoFileName:
          data.promotingInstitutionLogoName !== null
            ? data.promotingInstitutionLogoName
            : "",
        insitituteLogoFile:
          data.promotingInstitutionLogo !== null
            ? data.promotingInstitutionLogo
            : "",
        resourseInsitituteLogoFileName:
          data.ResourcingInstitutionLogoName !== null
            ? data.ResourcingInstitutionLogoName
            : "",
        resourseInsitituteLogoFile:
          data.ResourcingInstitutionLogoURL !== null
            ? data.ResourcingInstitutionLogoURL
            : "",
        officialWebsite:
          data.officialWebsiteLink !== null ? data.officialWebsiteLink : "",
        instragamLink: data.instagramLink !== null ? data.instagramLink : "",
        twitterLink: data.twitterLink !== null ? data.twitterLink : "",
        facebookLink: data.facebookLink !== null ? data.facebookLink : "",
        village: data.Village !== null ? data.Village : "",
        // pincode: data.pincodeId !== null ? data.pincodeId : "",
        pincode: data.pincode !== null ? String(data.pincode) : "",
        district: data.districtId !== null ? data.districtId : "",
        state: data.stateId !== null ? data.stateId : "",
        foundedDate: data.foundedOn !== null ? new Date(data.foundedOn) : "",
        membership:
          data.membershipBase !== null ? data.membershipBase.toString() : "",
        villageCovered:
          data.villagesCovered !== null ? data.villagesCovered.toString() : "",
        femaleMembers:
          data.femaleMembers !== null ? data.femaleMembers.toString() : "",
        maleMembers:
          data.maleMembers !== null ? data.maleMembers.toString() : "",
        acre: data.totalAverage !== null ? data.totalAverage.toString() : "",
        shareCapitals:
          data.shareCapital !== null ? data.shareCapital.toString() : "",
        selected_business_activties:
          data.businessIds !== null ? data.businessIds : [],
        selected_crops: data.cropIds !== null ? data.cropIds : [],
        selected_infrastructure:
          data.infrastructureIds !== null ? data.infrastructureIds : [],
        selected_license: data.licenseIds !== null ? data.licenseIds : [],
        teamLogoFileName: data.teamPhotoName !== null ? data.teamPhotoName : "",
        teamLogo: data.teamPhotoURL !== null ? data.teamPhotoURL : "",
        turnOver: data.turnOver !== null ? data.turnOver.toString() : "",
        FyearId: data.FyearId != null ? data.FyearId : "",
        cropMapping: data.cropMapping,
        entityId: String(data?.entityId),
        companyCin: data.companyCin ? data.companyCin : "",
        entity_value:
          String(data?.entityId) === "2"
            ? data?.companyCin
            : String(data?.entityId) === "3"
            ? data?.cooperative_registration_number
            : String(data?.entityId) === "4"
            ? data?.trust_registration_number
            : "",
        societyType: data.societyType,
      });

      let body = {
        companyCin: data?.companyCin,
        cooperative_registration_number: data?.cooperative_registration_number,
        trust_registration_number: data?.trust_registration_number,
      };
      if (String(data?.entityId) === "2") {
        delete body.cooperative_registration_number;
        delete body.trust_registration_number;
      } else if (String(data?.entityId) === "3") {
        delete body.companyCin;
        delete body.trust_registration_number;
      } else if (String(data?.entityId) === "4") {
        delete body.companyCin;
        delete body.cooperative_registration_number;
      }
      emailAndNumberValidation(body).then((message) => {
        if (
          message === "This company CIN is already registered" ||
          message ===
            "This cooperative registration number is already registered" ||
          message === "This trust registration number is already registered"
        ) {
          this.setState({
            CINValidate: true,
          });
        } else {
          this.setState({
            CINValidate: false,
          });
        }
      });

      //get district data
      if (data.stateId !== null && data.stateId) {
        this.props
          .getDistrictApiwithState({ id: data.stateId })
          .then((data) => {
            let districtData = [];

            data.map((list) => {
              districtData.push({
                value: list.districtId,
                label: list.districtName,
              });
            });
            this.setState({
              districtOptions: districtData,
            });
          });
      }

      //get pincode data
      if (data.districtId !== null && data.districtId) {
        this.props
          .getPincodeApiwithStateDistrict({ id: data.districtId })
          .then((data) => {
            let pinocodeData = [];

            data.map((list) => {
              pinocodeData.push({ value: list.pincodeId, label: list.pincode });
            });
            this.setState({
              pincodeOptions: pinocodeData,
            });
          });
      }
    });
  };

  uploadLogoFile = (e) => {
    let file = e.target.files[0];
    if (file !== undefined) {
      this.setState({ logoFile: "" }, () => {
        this.setState({
          islogoFileUploading: true,
          logoFileName: "Uploading ....",
          logoFile: file,
        });
        const formData = new FormData();
        formData.append("file", file);

        this.props
          .fileUpload(formData)
          .then((data) => {
            document.getElementById("uploadLogoFile").value = null;
            this.setState({
              logoFile: data[0].fileUrl,
              islogoFileUploading: false,
              logoFileName: data[0].fileName,
            });
          })
          .catch(() => {
            this.setState({
              logoFileName: "",
              logoFile: "",
              islogoFileUploading: false,
            });
          });
      });
    }
  };

  uploadInstitutionFile = (e) => {
    let file = e.target.files[0];
    if (file !== undefined) {
      this.setState({ insitituteLogoFile: "" }, () => {
        this.setState({
          isinstitutelogoFileUploading: true,
          insitituteLogoFileName: "Uploading ....",
          insitituteLogoFile: file,
        });
        const formData = new FormData();
        formData.append("file", file);

        this.props
          .fileUpload(formData)
          .then((data) => {
            document.getElementById("uploadInstitutionFile").value = null;
            this.setState({
              insitituteLogoFile: data[0].fileUrl,
              isinstitutelogoFileUploading: false,
              insitituteLogoFileName: data[0].fileName,
            });
          })
          .catch(() => {
            this.setState({
              insitituteLogoFileName: "",
              insitituteLogoFile: "",
              isinstitutelogoFileUploading: false,
            });
          });
      });
    }
  };

  uploadResourseInstitutionFile = (e) => {
    let file = e.target.files[0];
    if (file !== undefined) {
      this.setState({ resourseInsitituteLogoFile: "" }, () => {
        this.setState({
          isresourseInsitituteLogoFileUploading: true,
          resourseInsitituteLogoFileName: "Uploading ....",
          resourseInsitituteLogoFile: file,
        });

        const formData = new FormData();
        formData.append("file", file);

        this.props
          .fileUpload(formData)
          .then((data) => {
            document.getElementById("uploadResourseInstitutionFile").value =
              null;
            this.setState({
              resourseInsitituteLogoFile: data[0].fileUrl,
              isresourseInsitituteLogoFileUploading: false,
              resourseInsitituteLogoFileName: data[0].fileName,
            });
          })
          .catch(() => {
            this.setState({
              resourseInsitituteLogoFileName: "",
              resourseInsitituteLogoFile: "",
              isresourseInsitituteLogoFileUploading: false,
            });
          });
      });
    }
  };

  uploadTeamLogoFile = (e) => {
    let file = e.target.files[0];
    if (file !== undefined) {
      this.setState({ teamLogo: "" }, () => {
        this.setState({
          isTeamLogoFileuploading: true,
          teamLogoFileName: "Uploading ....",
          teamLogo: file,
        });
        const formData = new FormData();
        formData.append("file", file);

        this.props
          .fileUpload(formData)
          .then((data) => {
            document.getElementById("uploadTeamLogoFile").value = null;
            this.setState({
              teamLogoFileName: data[0].fileName,
              isTeamLogoFileuploading: false,
              teamLogo: data[0].fileUrl,
            });
          })
          .catch(() => {
            this.setState({
              teamLogoFileName: "",
              teamLogo: "",
              isTeamLogoFileuploading: false,
            });
          });
      });
    }
  };

  handlebusinessActivties = (crop) => {
    let { selected_business_activties } = this.state;

    if (
      !selected_business_activties.find((r_crop) => {
        return r_crop === crop;
      })
    ) {
      selected_business_activties.push(crop);
      this.setState({ selected_business_activties });
    } else {
      let index = selected_business_activties.findIndex((r_crop) => {
        return r_crop === crop;
      });
      if (index !== -1) {
        this.setState((prevState) => {
          prevState.selected_business_activties.splice(index, 1);
        });
      }
    }
  };

  handlecrops = (crop) => {
    let { selected_crops, cropMapping } = this.state;
    if (
      !selected_crops.find((r_crop) => {
        return r_crop === crop;
      })
    ) {
      selected_crops.push(crop);
      cropMapping.push({
        cropId: crop,
        varietyOne: "",
        varietyTwo: "",
        varietyThree: "",
      });
      this.setState({
        selected_crops: selected_crops,
        cropMapping: cropMapping,
      });
    } else {
      let index = selected_crops.findIndex((r_crop) => {
        return r_crop === crop;
      });
      if (index !== -1) {
        this.setState((prevState) => {
          prevState.selected_crops.splice(index, 1);
          prevState.cropMapping.splice(index, 1);
        });
      }
    }
  };

  handleInfrasture = (crop) => {
    let { selected_infrastructure } = this.state;
    if (
      !selected_infrastructure.find((r_crop) => {
        return r_crop === crop;
      })
    ) {
      selected_infrastructure.push(crop);
      this.setState({ selected_infrastructure }, () => {});
    } else {
      let index = selected_infrastructure.findIndex((r_crop) => {
        return r_crop === crop;
      });
      if (index !== -1) {
        this.setState((prevState) => {
          prevState.selected_infrastructure.splice(index, 1);
        });
      }
    }
  };

  handleLicense = (crop) => {
    let { selected_license } = this.state;
    if (
      !selected_license.find((r_crop) => {
        return r_crop === crop;
      })
    ) {
      selected_license.push(crop);
      this.setState({ selected_license });
    } else {
      let index = selected_license.findIndex((r_crop) => {
        return r_crop === crop;
      });
      if (index !== -1) {
        this.setState((prevState) => {
          prevState.selected_license.splice(index, 1);
        });
      }
    }
  };

  handleCropVariety = (cropId, e) => {
    const { value, name } = e.target;
    const { cropMapping, selected_crops } = this.state;
    let index = cropMapping.findIndex((list) => {
      return list.cropId == cropId;
    });
    cropMapping[index][name] = value;
    this.setState({
      cropMapping: cropMapping,
    });
  };

  getCropIndex = (cropId) => {
    let id = this.state.cropMapping.findIndex((list) => {
      return list.cropId == cropId;
    });
    return id;
  };

  handleSubmit = () => {
    let {
      fpoName,
      entityId,
      entity_value,
      societyType,
      promotingInstitution,
      resourseInstitution,
      logoFileName,
      logoFile,
      insitituteLogoFileName,
      insitituteLogoFile,
      resourseInsitituteLogoFileName,
      resourseInsitituteLogoFile,
      teamLogoFileName,
      teamLogo,
      officialWebsite,
      instragamLink,
      twitterLink,
      facebookLink,
      village,
      pincode,
      district,
      state,
      foundedDate,
      membership,
      villageCovered,
      femaleMembers,
      maleMembers,
      acre,
      shareCapitals,
      selected_business_activties,
      selected_crops,
      selected_infrastructure,
      selected_license,
      isinstitutelogoFileUploading,
      islogoFileUploading,
      isresourseInsitituteLogoFileUploading,
      isTeamLogoFileuploading,
      turnOver,
      FyearId,
      cropMapping,
      companyCin,
      stateOption,
      districtOptions,
      entityList,
      CINValidate,
    } = this.state;
    if (entityId === "1") {
      this.validator.fields.entity_value = true;
      this.validator.errorMessages.entity_value = null;
      this.validator.fields.cin = true;
      this.validator.errorMessages.cin = null;
      this.validator.fields.cooperative_registration_number = true;
      this.validator.errorMessages.cooperative_registration_number = null;
      this.validator.fields.trust_registration_number = true;
      this.validator.errorMessages.trust_registration_number = null;
      this.validator.fields.societyType = true;
      this.validator.errorMessages.societyType = null;
    }
    if (entityId === "2") {
      this.validator.fields.cooperative_registration_number = true;
      this.validator.errorMessages.cooperative_registration_number = null;
      this.validator.fields.trust_registration_number = true;
      this.validator.errorMessages.trust_registration_number = null;
      this.validator.fields.societyType = true;
      this.validator.errorMessages.societyType = null;
    }
    if (entityId === "3") {
      this.validator.fields.cin = true;
      this.validator.errorMessages.cin = null;
      this.validator.fields.trust_registration_number = true;
      this.validator.errorMessages.trust_registration_number = null;
    }
    if (entityId === "4") {
      this.validator.fields.cin = true;
      this.validator.errorMessages.cin = null;
      this.validator.fields.cooperative_registration_number = true;
      this.validator.errorMessages.cooperative_registration_number = null;
      this.validator.fields.societyType = true;
      this.validator.errorMessages.societyType = null;
    }
    if (this.validator.allValid() && CINValidate === false) {
      if (
        !isinstitutelogoFileUploading &&
        !islogoFileUploading &&
        !isresourseInsitituteLogoFileUploading &&
        !isTeamLogoFileuploading
      ) {
        if (
          selected_business_activties.length !== 0 &&
          selected_crops.length !== 0 &&
          selected_infrastructure.length !== 0 &&
          selected_license.length !== 0
        ) {
          let body = {
            fpoName: fpoName,
            entityId: Number(entityId),
            // companyCin: entityId === "2" ? entity_value : "",
            // cooperative_society: entityId === "3" ? entity_value : "",
            // trust_registration_number: entityId === "4" ? entity_value : "",
            // cooperative_registration_number:
            //   entityId === "3" ? entity_value : "",
            societyType: entityId === "3" ? societyType : "",
            businessIds: selected_business_activties,
            cropIds: selected_crops,
            licenseIds: selected_license,
            infrastructureIds: selected_infrastructure,
            village,
            state: stateOption.find(
              (stateOpt) => stateOpt.value === parseInt(state)
            )?.label,
            stateId: parseInt(state),
            district: districtOptions.find(
              (districtOpt) => districtOpt.value === parseInt(district)
            )?.label,
            districtId: parseInt(district),
            // pincodeId: parseInt(pincode),
            pincode: pincode,
            foundedOn: moment(foundedDate).format("YYYY-MM-DD"),
            membershipBase: membership,
            resourcingInstitution: resourseInstitution,
            cropMapping: cropMapping,
          };
          if (promotingInstitution != "") {
            body.promotingInstitution = promotingInstitution;
          } else {
            body.promotingInstitution = "";
          }
          if (femaleMembers !== "") {
            body.femaleMembers = parseInt(femaleMembers);
          } else {
            body.femaleMembers = null;
          }
          if (maleMembers !== "") {
            body.maleMembers = parseInt(maleMembers);
          } else {
            body.maleMembers = null;
          }
          if (villageCovered !== "") {
            body.villagesCovered = parseInt(villageCovered);
          } else {
            body.villagesCovered = null;
          }
          if (shareCapitals !== "") {
            body.shareCapital = parseInt(shareCapitals);
          } else {
            body.shareCapital = null;
          }
          if (acre !== "") {
            body.totalAverage = parseInt(acre);
          } else {
            body.totalAverage = null;
          }
          if (logoFile !== "") {
            body.fpoLogoURL = logoFile;
            body.fpoLogoName = logoFileName;
          } else {
            body.fpoLogoURL = "";
            body.fpoLogoName = "";
          }
          if (turnOver !== "") {
            body.turnOver = parseInt(turnOver);
          } else {
            body.turnOver = null;
          }
          if (FyearId !== "") {
            body.FyearId = FyearId;
          } else {
            body.FyearId = "";
          }
          if (insitituteLogoFile !== "") {
            body.promotingInstitutionLogo = insitituteLogoFile;
            body.promotingInstitutionLogoName = insitituteLogoFileName;
          } else {
            body.promotingInstitutionLogo = "";
            body.promotingInstitutionLogoName = "";
          }
          if (resourseInsitituteLogoFile !== "") {
            body.ResourcingInstitutionLogoURL = resourseInsitituteLogoFile;
            body.ResourcingInstitutionLogoName = resourseInsitituteLogoFileName;
          } else {
            body.ResourcingInstitutionLogoURL = "";
            body.ResourcingInstitutionLogoName = "";
          }
          if (teamLogo !== "") {
            body.teamPhotoName = teamLogoFileName;
            body.teamPhotoURL = teamLogo;
          } else {
            body.teamPhotoName = "";
            body.teamPhotoURL = "";
          }
          if (officialWebsite != "") {
            body.officialWebsiteLink = officialWebsite;
          } else {
            body.officialWebsiteLink = "";
          }
          if (facebookLink != "") {
            body.facebookLink = facebookLink;
          } else {
            body.facebookLink = "";
          }
          if (twitterLink != "") {
            body.twitterLink = twitterLink;
          } else {
            body.twitterLink = "";
          }
          if (instragamLink != "") {
            body.instagramLink = instragamLink;
          } else {
            body.instagramLink = "";
          }
          if (companyCin != "") {
            body.companyCin = companyCin;
          } else {
            body.companyCin = "";
          }
          if (entity_value !== "" && Number(entityId) === 2) {
            body.companyCin = entity_value ? entity_value : null;
          } else if (entity_value !== "" && Number(entityId) === 3) {
            body.cooperative_registration_number = entity_value
              ? entity_value
              : null;
          } else if (entity_value !== "" && Number(entityId) === 4) {
            body.trust_registration_number = entity_value ? entity_value : null;
          }
          this.props.updateAboutUsDetails(body).then(() => {
            this._getData(this.props.id);
            history.push("/fpo/about-us");
          });
        } else {
          if (selected_business_activties.length === 0) {
            Toast({
              type: "error",
              message: "Please select atleast one business activities",
            });
          } else if (selected_crops.length === 0) {
            Toast({
              type: "error",
              message: "Please select atleast one crops",
            });
          } else if (selected_infrastructure.length === 0) {
            Toast({
              type: "error",
              message: "Please select atleast one infrastructure",
            });
          } else if (selected_license.length === 0) {
            Toast({
              type: "error",
              message: "Please select atleast one license",
            });
          }
        }
      } else {
        Toast({ type: "error", message: "Please wait image is uploading" });
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  handleBack = () => {
    history.push("/fpo/about-us");
  };

  handleRemoveImage = (picName) => {
    if (picName === "logoFile") {
      this.setState({
        logoFileName: "",
        logoFile: "",
      });
    } else if (picName === "teamlogoFile") {
      this.setState({
        teamLogoFileName: "",
        teamLogo: "",
      });
    } else if (picName === "resourseInsititute") {
      this.setState({
        resourseInsitituteLogoFile: "",
        resourseInsitituteLogoFileName: "",
      });
    } else if (picName === "insitituteLogoFile") {
      this.setState({
        insitituteLogoFile: "",
        insitituteLogoFileName: "",
      });
    }
  };

  render() {
    let {
      fpoName,
      entityId,
      entity_value,
      societyType,
      promotingInstitution,
      resourseInstitution,
      logoFileName,
      logoFile,
      insitituteLogoFileName,
      insitituteLogoFile,
      resourseInsitituteLogoFileName,
      resourseInsitituteLogoFile,
      teamLogoFileName,
      teamLogo,
      officialWebsite,
      instragamLink,
      twitterLink,
      facebookLink,
      village,
      pincode,
      district,
      state,
      foundedDate,
      membership,
      villageCovered,
      femaleMembers,
      maleMembers,
      acre,
      shareCapitals,
      districtOptions = [],
      pincodeOptions = [],
      stateOption = [],
      business_activties = [],
      selected_business_activties = [],
      selected_crops = [],
      crops_list = [],
      infrastructure_list = [],
      selected_infrastructure = [],
      selected_license = [],
      license_list,
      islogoFileUploading,
      isTeamLogoFileuploading,
      isinstitutelogoFileUploading,
      isresourseInsitituteLogoFileUploading,
      turnOver,
      cropMapping,
      allFinancialYear,
      FyearId,
      companyCin,
      entityList,
      societyTypeOptions,
    } = this.state;

    return (
      <>
        <div className="d-flex my-3 container-sa px-0">
          <div
            onClick={() => {
              this.handleBack();
            }}
            className="d-flex"
          >
            <img className="cursor-pointer" src={arrow_left} alt="back" />
            <p className="fs-18 font-weight-bold text-black mb-0 cursor-pointer ml-3">
              {"Back"}
            </p>
          </div>
        </div>
        <section className="add-aboutus-section">
          <div className="bg-white">
            <div className="row">
              <div className="col-md-8">
                <NormalInput
                  label="FPO name"
                  type="text"
                  onChange={this.handleInput}
                  value={fpoName}
                  placeholder="Enter here"
                  name="fpoName"
                  required={true}
                />
                {this.validator.message(
                  "fpoName",
                  fpoName,
                  "required|min:3|max:75"
                )}
              </div>
              <div className="col-md-4">
                <div className="row upload-area">
                  <div className="col-md-7">
                    <div className="fs-16 text-black-32 font-weight-bold line-height-19">
                      {"FPO logo"}
                    </div>
                  </div>
                  <div className="col-md-5">
                    <div className="d-flex flex-column justify-content-start">
                      <div className="btn-file-input">
                        <button className="">
                          <input
                            type="file"
                            className=""
                            accept=".jpg, .png, .jpeg"
                            onChange={this.uploadLogoFile}
                            id="uploadLogoFile"
                            title=""
                          />
                          {logoFileName == "" ? (
                            "Choose file"
                          ) : (
                            <>
                              {logoFileName.length == 10
                                ? logoFileName
                                : logoFileName.substring(0, 9)}
                              ...
                            </>
                          )}
                        </button>
                      </div>
                      {logoFileName != "" && !islogoFileUploading && (
                        <div className="d-flex mb-1 align-items-center justify-content-between delete-area-width">
                          <div className="text-danger">{"Remove"}</div>
                          <i
                            className="icon-Delete text-danger fs-14 pl-2 cursor-pointer"
                            onClick={() => this.handleRemoveImage("logoFile")}
                          ></i>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-8">
                <NormalInput
                  label="Resource institution"
                  type="text"
                  onChange={this.handleInput}
                  value={resourseInstitution}
                  placeholder="Enter here"
                  name="resourseInstitution"
                  // required={true}
                />
                {this.validator.message(
                  "resourseInstitution",
                  resourseInstitution,
                  "max:75"
                )}
              </div>
              <div className="col-md-4">
                <div className="row upload-area">
                  <div className="col-md-7">
                    <div className="fs-16 text-black-32 font-weight-bold line-height-19">
                      {"Resource institution logo"}
                    </div>
                  </div>
                  <div className="col-md-5">
                    <div className="d-flex flex-column justify-content-start">
                      <div className="btn-file-input">
                        <button className="">
                          <input
                            type="file"
                            className=""
                            accept=".jpg, .png, .jpeg"
                            onChange={this.uploadResourseInstitutionFile}
                            id="uploadResourseInstitutionFile"
                            title=""
                          />
                          {resourseInsitituteLogoFile == "" ? (
                            "Choose file"
                          ) : (
                            <>
                              {resourseInsitituteLogoFileName.length == 10
                                ? resourseInsitituteLogoFileName
                                : resourseInsitituteLogoFileName.substring(
                                    0,
                                    9
                                  )}
                              ...
                            </>
                          )}
                        </button>
                      </div>
                      {resourseInsitituteLogoFileName != "" &&
                        !isresourseInsitituteLogoFileUploading && (
                          <div className="d-flex mb-1 align-items-center justify-content-between delete-area-width">
                            <div className="text-danger">{"Remove"}</div>
                            <i
                              className="icon-Delete text-danger fs-14 pl-2 cursor-pointer"
                              onClick={() =>
                                this.handleRemoveImage("resourseInsititute")
                              }
                            ></i>
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-8">
                <NormalInput
                  label="Promoting institution"
                  type="text"
                  onChange={this.handleInput}
                  value={promotingInstitution}
                  placeholder="Enter here"
                  name="promotingInstitution"
                  max={75}
                />
              </div>
              <div className="col-md-4">
                <div className="row upload-area">
                  <div className="col-md-7">
                    <div className="fs-16 text-black-32 font-weight-bold line-height-19">
                      {"Promoting institution logo"}
                    </div>
                  </div>
                  <div className="col-md-5">
                    <div className="d-flex flex-column justify-content-start">
                      <div className="btn-file-input-left">
                        <button className="">
                          <input
                            type="file"
                            className=""
                            accept=".jpg, .png, .jpeg"
                            onChange={this.uploadInstitutionFile}
                            id="uploadInstitutionFile"
                            title=""
                          />
                          {insitituteLogoFile == "" ? (
                            "Choose file"
                          ) : (
                            <>
                              {insitituteLogoFileName.length == 10
                                ? insitituteLogoFileName
                                : insitituteLogoFileName.substring(0, 9)}
                              ...
                            </>
                          )}
                        </button>
                        {insitituteLogoFileName != "" &&
                          !isinstitutelogoFileUploading && (
                            <div className="d-flex mb-1 align-items-center justify-content-between delete-area-width">
                              <div className="text-danger">{"Remove"}</div>
                              <i
                                className="icon-Delete text-danger fs-14 pl-2 cursor-pointer"
                                onClick={() =>
                                  this.handleRemoveImage("insitituteLogoFile")
                                }
                              ></i>
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row pt-4 entityFormContainer">
              <div className="col-lg-2 col-12">
                <div className="row">
                  <div className="col-12 pb-5">
                    <label className="fs-16 text-black-32 font-weight-bold">
                      {"Entity Type"}
                      <span className="text-danger font-weight-bold">*</span>
                    </label>
                  </div>
                  {entityId === "3" ? (
                    <div className={`col-12`}>
                      <label className="fs-16 text-black-32 font-weight-bold">
                        {"Cooperative society type"}
                        <span className="text-danger font-weight-bold">*</span>
                      </label>
                    </div>
                  ) : null}
                  {entityId === "2" || entityId === "3" || entityId === "4" ? (
                    <div className="col-12">
                      <label
                        className={`${
                          entityId === "3" ? "pt-3" : ""
                        } fs-16 text-black-32 font-weight-bold `}
                      >
                        {(entityId === "2" && "CIN") ||
                          (entityId === "3" &&
                            "Cooperative Registration Number") ||
                          (entityId === "4" && "Trust Registration Number")}
                        <span className="text-danger font-weight-bold">*</span>
                      </label>
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="col-lg-6 col-12">
                <div className="row">
                  <div className="col-12 pb-5">
                    <select
                      className="custom-select form-control entitySelectInput"
                      name="entityId"
                      value={entityId}
                      onChange={this.handleInput}
                      required={true}
                    >
                      <option value="" disabled defaultValue>
                        {"Select"}
                      </option>
                      {entityList?.map((list, index) => (
                        <option key={index} value={list.value}>
                          {list.label}
                        </option>
                      ))}
                    </select>
                    {this.validator.message("entityId", entityId, "required")}
                  </div>
                  {entityId === "3" ? (
                    <div className="col-12 pb-5">
                      <select
                        className="custom-select form-control entitySelectInput"
                        name="societyType"
                        value={societyType}
                        onChange={this.handleInput}
                      >
                        <option value="" disabled defaultValue>
                          {"Select"}
                        </option>
                        {societyTypeOptions?.map((list, index) => (
                          <option key={index} value={list.value}>
                            {list.label}
                          </option>
                        ))}
                      </select>
                      {this.validator.message(
                        "societyType",
                        societyType,
                        "required"
                      )}
                    </div>
                  ) : null}
                  {entityId === "2" || entityId === "3" || entityId === "4" ? (
                    <>
                      <div className="col-12">
                        <input
                          type="text"
                          className="form-control w-100 entityForm"
                          name="entity_value"
                          placeholder="Enter here"
                          value={entity_value}
                          onChange={this.handleInput}
                          required={true}
                        />
                        {this.state.CINValidate && (
                          <span className="error-message text-danger fs-14">
                            {entityId === "2"
                              ? "This CIN is already registered. Please try with some other CIN."
                              : entityId === "3"
                              ? "This CRN is already registered. Please try with some other CRN."
                              : entityId === "4"
                              ? "This TRN is already registered. Please try with some other TRN."
                              : null}
                          </span>
                        )}
                        {entityId !== "1"
                          ? this.validator.message(
                              entityId === "2"
                                ? "companyCin"
                                : entityId === "3"
                                ? "cooperative_registration_number"
                                : entityId === "4"
                                ? "trust_registration_number"
                                : null,
                              entity_value,
                              entityId === "2"
                                ? "required|alpha_num|min:21|max:21"
                                : entityId === "3"
                                ? "required"
                                : entityId === "4"
                                ? "required|alpha_num"
                                : null
                            )
                          : null}
                      </div>
                    </>
                  ) : null}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-4">
                <div className="row upload-area">
                  <div className="col-md-6">
                    <div className="fs-16 text-black-32 font-weight-bold line-height-19">
                      {"Team photo"}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="d-flex flex-column justify-content-start">
                      <div className="btn-file-input">
                        <button className="">
                          <input
                            type="file"
                            className=""
                            accept=".jpg, .png, .jpeg"
                            onChange={this.uploadTeamLogoFile}
                            id="uploadTeamLogoFile"
                            title=""
                          />
                          {teamLogo == "" ? (
                            "Choose file"
                          ) : (
                            <>
                              {teamLogoFileName.length == 10
                                ? teamLogoFileName
                                : teamLogoFileName.substring(0, 9)}
                              ...
                            </>
                          )}
                        </button>
                      </div>
                      {teamLogoFileName != "" && !isTeamLogoFileuploading && (
                        <div className="d-flex mb-1 align-items-center justify-content-between delete-area-width">
                          <div className="text-danger">{"Remove"}</div>
                          <i
                            className="icon-Delete text-danger fs-14 pl-2 cursor-pointer"
                            onClick={() =>
                              this.handleRemoveImage("teamlogoFile")
                            }
                          ></i>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <NormalInput
                  isFullWidth={true}
                  label="Official website"
                  type="text"
                  onChange={this.handleInput}
                  value={officialWebsite}
                  placeholder="Enter here"
                  name="officialWebsite"
                  max={100}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <NormalInput
                  isFullWidth={true}
                  label="Facebook link"
                  type="text"
                  onChange={this.handleInput}
                  value={facebookLink}
                  placeholder="Enter here"
                  name="facebookLink"
                  max={100}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <NormalInput
                  isFullWidth={true}
                  label="Twitter link"
                  type="text"
                  onChange={this.handleInput}
                  value={twitterLink}
                  placeholder="Enter here"
                  name="twitterLink"
                  max={100}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <NormalInput
                  isFullWidth={true}
                  label="Instagram link"
                  type="text"
                  onChange={this.handleInput}
                  value={instragamLink}
                  placeholder="Enter here"
                  name="instragamLink"
                  max={100}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <NormalInput
                  isFullWidth={true}
                  label="Village"
                  type="text"
                  onChange={this.handleInput}
                  value={village}
                  placeholder="Enter here"
                  name="village"
                  required={true}
                  max={150}
                />
                <div className="row d-flex align-items-center">
                  <div className="col-md-10 ml-auto">
                    {this.validator.message(
                      "village",
                      village,
                      "required|max:150"
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <NormalSelect
                  isHalfWidth={true}
                  label={"State/UT"}
                  placeholder="Select"
                  value={state}
                  arrow={true}
                  name="state"
                  options={stateOption}
                  handleChange={this.handleInput}
                  required={true}
                />
                <div className="row d-flex align-items-center">
                  <div className="col-md-8 ml-auto">
                    {this.validator.message("state", state, "required")}
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <NormalSelect
                  isHalfWidth={true}
                  label={"District"}
                  placeholder="Select"
                  value={district}
                  arrow={true}
                  name="district"
                  disabled={state == "" ? true : false}
                  options={districtOptions}
                  handleChange={this.handleInput}
                  required={true}
                />
                <div className="row d-flex align-items-center">
                  <div className="col-md-8 ml-auto">
                    {this.validator.message("district", district, "required")}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <NormalInput
                  isHalfWidth={true}
                  label="Pincode"
                  type="text"
                  onChange={this.handleInput}
                  value={pincode}
                  placeholder="Enter here"
                  name="pincode"
                  max={6}
                  required={true}
                />
                <div className="row d-flex align-items-center">
                  <div className="col-md-8 ml-auto">
                    {this.validator.message(
                      "pincode",
                      pincode,
                      "required|numeric|min:6"
                    )}
                  </div>
                </div>
              </div>
              {/* <div className="col-md-6">
                  <NormalSelect
                    isHalfWidth={true}
                    label={"Pincode"}
                    placeholder="Select"
                    value={pincode}
                    arrow={true}
                    name="pincode"
                    disabled={state == "" || district == "" ? true : false}
                    options={pincodeOptions}
                    handleChange={this.handleInput}
                    required={true}
                  />
                  <div className="row d-flex align-items-center">
                    <div className="col-md-8 ml-auto">
                      {this.validator.message("pincode", pincode, "required")}
                    </div>
                  </div>
                </div> */}
              <div className="col-md-6 col-sm-12 col-12">
                <NormalDate
                  isIcon={true}
                  isHalfWidth={true}
                  value={foundedDate}
                  name="foundedDate"
                  placeholder="DD/MM/YYYY"
                  onChange={this.handleInput}
                  mindate={false}
                  maxdate={true}
                  timeInput={false}
                  label={"Founded on"}
                  required={true}
                />
                <div className="row d-flex align-items-center">
                  <div className="col-md-8 ml-auto">
                    {this.validator.message(
                      "foundedDate",
                      foundedDate,
                      "required"
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 col-sm-12 col-12">
                <NormalInput
                  isHalfWidth={true}
                  label="Villages covered"
                  type="number"
                  onChange={this.handleInput}
                  value={villageCovered}
                  placeholder="Enter here"
                  name="villageCovered"
                />
                <div className="row d-flex align-items-center">
                  <div className="col-md-8 ml-auto">
                    {this.validator.message(
                      "villages covered",
                      villageCovered,
                      "max:10"
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-sm-12 col-12">
                <NormalInput
                  isHalfWidth={true}
                  label="Membership"
                  type="number"
                  onChange={this.handleInput}
                  value={membership}
                  placeholder="Enter here"
                  name="membership"
                  required={true}
                />
                <div className="row d-flex align-items-center">
                  <div className="col-md-8 ml-auto">
                    {this.validator.message(
                      "membership",
                      membership,
                      "required|max:10"
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 col-sm-12 col-12">
                <NormalInput
                  isHalfWidth={true}
                  label="Female members"
                  type="number"
                  onChange={this.handleInput}
                  value={femaleMembers}
                  placeholder="Enter here"
                  name="femaleMembers"
                  required={true}
                />
                <div className="row d-flex align-items-center">
                  <div className="col-md-8 ml-auto">
                    {this.validator.message(
                      "female Members",
                      femaleMembers,
                      "required|max:10"
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-sm-12 col-12">
                <NormalInput
                  isHalfWidth={true}
                  label="Male members"
                  type="number"
                  onChange={this.handleInput}
                  value={maleMembers}
                  placeholder="Enter here"
                  name="maleMembers"
                  required={true}
                  readOnly={true}
                />
                <div className="row d-flex align-items-center">
                  <div className="col-md-8 ml-auto">
                    {this.validator.message(
                      "male Members",
                      maleMembers,
                      "required|max:10"
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 col-sm-12 col-12">
                <NormalInput
                  isHalfWidth={true}
                  label="Share capital"
                  type="number"
                  onChange={this.handleInput}
                  value={shareCapitals}
                  placeholder="Enter here"
                  name="shareCapitals"
                />
                <div className="row d-flex align-items-center">
                  <div className="col-md-8 ml-auto">
                    {this.validator.message(
                      "share Capitals",
                      shareCapitals,
                      "max:10"
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-sm-12 col-12">
                <NormalInput
                  isHalfWidth={true}
                  label="Total Acreage"
                  type="text"
                  onChange={this.handleInput}
                  value={acre}
                  placeholder="Enter here"
                  name="acre"
                  required={true}
                />
                <div className="row d-flex align-items-center">
                  <div className="col-md-8 ml-auto">
                    {this.validator.message("acre", acre, "required|max:10")}
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-sm-12 col-12">
                <NormalInput
                  isHalfWidth={true}
                  label="Turn Over"
                  type="number"
                  onChange={this.handleInput}
                  value={turnOver}
                  placeholder="Enter here"
                  name="turnOver"
                />
                <div className="row d-flex align-items-center">
                  <div className="col-md-8 ml-auto">
                    {this.validator.message("turnOver", turnOver, "max:10")}
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-sm-12 col-12">
                <NormalSelect
                  isHalfWidth={true}
                  label={"Financial year"}
                  placeholder="Select"
                  value={""}
                  arrow={true}
                  name="FyearId"
                  options={allFinancialYear}
                  handleChange={this.handleInput}
                  // required={true}
                />
                {/* <div className="row d-flex align-items-center">
                  <div className="col-md-8 ml-auto">
                    {this.validator.message(
                      "financialYear",
                      FyearId,
                      "required"
                    )}
                  </div>
                </div> */}
              </div>
            </div>
            <div>
              <div className="about-us-selection-area mb-3">
                {"Business activities"}
              </div>
              <div className="flex-wrap d-flex">
                {business_activties.map((activity, index) => {
                  return (
                    <div
                      key={index}
                      className={`d-flex flex-column ${
                        selected_business_activties.find((s_crop) => {
                          return s_crop === activity.businessId;
                        }) !== undefined
                          ? "activity-selected mr-3 mb-3"
                          : "activity-container mr-3 mb-3"
                      } `}
                      onClick={() => {
                        this.handlebusinessActivties(activity.businessId);
                        this.forceUpdate();
                      }}
                    >
                      <img
                        className="crops-container-img"
                        src={activity.businessIconUrl}
                        alt="crops"
                      />
                      <p className="mb-0 fs-10 text-green-18 text-center mt-1">
                        {activity.businessName}
                      </p>
                    </div>
                  );
                })}
              </div>
            </div>
            <div>
              <div className="about-us-selection-area mb-3">{"Crops"}</div>
              <div className="flex-wrap d-flex">
                {crops_list.map((crop, index) => {
                  this.getCropIndex(crop.cropId);
                  return (
                    <div
                      key={index}
                      className={`d-flex flex-column ${
                        selected_crops.find((s_crop) => {
                          return s_crop === crop.cropId;
                        }) !== undefined
                          ? "activity-selected mr-3 mb-3"
                          : "activity-container mr-3 mb-3"
                      } `}
                    >
                      <div
                        onClick={() => {
                          this.handlecrops(crop.cropId);
                          this.forceUpdate();
                        }}
                      >
                        <img
                          className="crops-container-img"
                          src={crop.cropIconUrl}
                          alt="crops"
                        />
                        <p className="mb-0 fs-10 text-green-18 text-center mt-1">
                          {crop.cropName}
                        </p>
                      </div>
                      <div>
                        <input
                          placeholder="variety 1"
                          name="varietyOne"
                          onChange={this.handleCropVariety.bind(
                            this,
                            crop.cropId
                          )}
                          value={
                            cropMapping[this.getCropIndex(crop.cropId)]
                              ?.varietyOne
                          }
                        />
                        <input
                          placeholder="variety 2"
                          name="varietyTwo"
                          onChange={this.handleCropVariety.bind(
                            this,
                            crop.cropId
                          )}
                          value={
                            cropMapping[this.getCropIndex(crop.cropId)]
                              ?.varietyTwo
                          }
                        />
                        <input
                          placeholder="variety 3"
                          name="varietyThree"
                          onChange={this.handleCropVariety.bind(
                            this,
                            crop.cropId
                          )}
                          value={
                            cropMapping[this.getCropIndex(crop.cropId)]
                              ?.varietyThree
                          }
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div>
              <div className="about-us-selection-area mb-3">Infrastructure</div>
              <div className="flex-wrap d-flex">
                {infrastructure_list.map((crop, index) => {
                  return (
                    <div
                      key={index}
                      className={`d-flex flex-column ${
                        selected_infrastructure.find((s_crop) => {
                          return s_crop === crop.infrastructureId;
                        }) !== undefined
                          ? "activity-selected mr-3 mb-3"
                          : "activity-container mr-3 mb-3"
                      } `}
                      onClick={() => {
                        this.handleInfrasture(crop.infrastructureId);
                        this.forceUpdate();
                      }}
                    >
                      <img
                        className="crops-container-img"
                        src={crop.InfrastructureIconUrl}
                        alt="crops"
                      />
                      <p className="mb-0 fs-10 text-green-18 text-center mt-1">
                        {crop.infrastructureName}
                      </p>
                    </div>
                  );
                })}
              </div>
            </div>
            <div>
              <div className="about-us-selection-area mb-3"> License</div>
              <div className="flex-wrap d-flex">
                {license_list.map((crop, index) => {
                  return (
                    <div
                      key={index}
                      className={`d-flex flex-column ${
                        selected_license.find((s_crop) => {
                          return s_crop === crop.id;
                        }) !== undefined
                          ? "activity-selected mr-3 mb-3"
                          : "activity-container mr-3 mb-3"
                      } `}
                      onClick={() => {
                        this.handleLicense(crop.id);
                        this.forceUpdate();
                      }}
                    >
                      <img
                        className="crops-container-img"
                        src={crop.licenseIconUrl}
                        alt="crops"
                      />
                      <p className="mb-0 fs-10 text-green-18 text-center mt-1">
                        {crop.licenseName}
                      </p>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="mx-auto mt-md-5 mt-0 pt-5">
              <NormalButton
                label="Update FPO Profile"
                mainbg={true}
                className="px-3 py-2 mx-auto fs-14 line-height-26 font-Gilroy"
                onClick={() => this.handleSubmit()}
              />
            </div>
          </div>
        </section>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      fileUpload,
      getAboutusMemberDetail,
      updateAboutUsDetails,
      getDistrictApiwithState,
      allState,
      getPincodeApiwithStateDistrict,
      getcropBusinessInfra,
      getAllFinancialYearDropdown,
      getSignUpEntityType,
      emailAndNumberValidation,
    },
    dispatch
  );
};

export const AddAboutUs = connect(null, mapDispatchToProps)(AddAboutUsClass);
