import React, { Component } from "react";
import { history } from "service/helpers/index";
import SimpleReactValidator from "simple-react-validator";
import moment from "moment";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  addGst,
  updateGst,
  getGstById,
  deleteGst,
  complianceCurrentTab,
} from "action/ComplianceAct";
import {
  fileUpload,
  getDistrictApiwithState,
  allState,
  getPincodeApiwithStateDistrict,
} from "action/MemberAct";
import { Toast } from "service/toast";
import {
  NormalInput,
  NormalButton,
  NormalDate,
  NormalSelect,
  NormalTextArea,
  NormalRadio,
  CommonPageLoader,
  ConfirmationPopup,
} from "component/common";
import "./addgst.scss";
import { getAccessdata } from "service/helperFunctions";
import trash from "assets/svg/Associations/trash.svg";

const arrow_left = require("assets/svg/add_member/arrow_left.svg");

export class AddGstClass extends Component {
  constructor() {
    super();
    this.state = {
      licenseNumber: "",
      validityDate: "",
      OriginalLicense: "Yes",
      remarks: "",
      storeAddress: "",
      village: "",
      pincode: "",
      district: "",
      state: "",
      licenseImgFileName: "",
      licenseImgUrl: null,
      isImageUploading: false,
      districtOptions: [],
      pincodeOptions: [],
      stateOption: [],
      isLoading: false,
      registrationDate: "",
      isDelete: false,
    };
  }

  //Validation
  validator = new SimpleReactValidator({
    element: (message) => (
      <span className="error-message text-danger fs-14">{message}</span>
    ),
    autoForceUpdate: this,
  });

  handleFile = (e) => {
    let file = e.target.files[0];
    if (file !== undefined) {
      this.setState({ licenseImgUrl: null }, () => {
        this.setState({
          isImageUploading: true,
          licenseImgFileName: "Uploading ....",
          licenseImgUrl: file,
        });
        const formData = new FormData();
        formData.append("file", file);

        this.props
          .fileUpload(formData)
          .then((data) => {
            document.getElementById("handleFile").value = null;
            this.setState({
              licenseImgUrl: data[0].fileUrl,
              licenseImgFileName: data[0].fileName,
              isImageUploading: false,
            });
          })
          .catch(() => {
            this.setState({
              licenseImgUrl: null,
              licenseImgFileName: "",
              isImageUploading: false,
            });
          });
      });
    }
  };
  handleInput = ({ target: { name, value } }) => {
    if (name === "state") {
      this.setState(
        {
          [name]: value,
          district: "",
        },
        () => {
          this.props.getDistrictApiwithState({ id: value }).then((data) => {
            let districtData = [];

            data.map((list) => {
              districtData.push({
                value: list.districtId,
                label: list.districtName,
              });
            });
            this.setState({
              districtOptions: districtData,
            });
          });
        }
      );
    } else {
      this.setState({
        [name]: value,
      });
    }
  };
  handleSubmit = () => {
    this.setState({ isLoading: true });
    if (this.validator.allValid()) {
      const {
        licenseNumber,
        validityDate,
        OriginalLicense,
        remarks,
        storeAddress,
        village,
        pincode,
        district,
        state,
        licenseImgFileName,
        licenseImgUrl,
        isImageUploading,
        registrationDate,
      } = this.state;
      if (!isImageUploading) {
        let body = {
          licenseNumber,
          registrationDate,
        };
        if (licenseImgFileName !== "") {
          body.licenseImgFileName = licenseImgFileName;
          body.licenseImgUrl = licenseImgUrl;
        }
        if (validityDate !== "") {
          body.validityDate = moment(validityDate).format("YYYY-MM-DD");
        }
        if (OriginalLicense !== "") {
          body.OriginalLicense = OriginalLicense;
        }
        if (remarks !== "") {
          body.remarks = remarks;
        }
        if (storeAddress !== "") {
          body.storeAddress = storeAddress;
        }
        if (village !== "") {
          body.village = village;
        }
        if (state != "") {
          body.stateId = parseInt(state);
        }
        if (district !== "") {
          body.districtId = parseInt(district);
        }
        if (pincode !== "") {
          body.pincode = parseInt(pincode);
        }

        if (this.props.id) {
          body.id = parseInt(this.props.id);
          this.props
            .updateGst(body)
            .then(() => {
              this.validator.hideMessages();
              this.handleBack();
            })
            .catch(() => {
              this.setState({ isLoading: false });
            });
        } else {
          this.props
            .addGst(body)
            .then(() => {
              this.validator.hideMessages();
              this.setState({
                licenseNumber: "",
                validityDate: "",
                OriginalLicense: "Yes",
                remarks: "",
                storeAddress: "",
                village: "",
                pincode: "",
                district: "",
                state: "",
                licenseImgFileName: "",
                licenseImgUrl: null,
                isImageUploading: false,
                isLoading: false,
                registrationDate: "",
              });
              this.handleBack();
            })
            .catch(() => {
              this.setState({ isLoading: false });
            });
        }
      } else {
        this.setState({ isLoading: false });

        Toast({ type: "error", message: "Please wait Image is uploading" });
      }
    } else {
      this.setState({ isLoading: false });

      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  handleDelete = () => {
    this.setState({ isLoading: true });
    this.props.deleteGst(this.props.id).then(() => {
      this.handleBack();
    });
  };

  componentDidMount() {
    this.props.allState().then((data) => {
      let StateData = [];

      data.map((list) => {
        StateData.push({ value: list.stateId, label: list.stateName });
      });
      this.setState({
        stateOption: StateData,
      });
    });
    if (this.props.id) {
      this.setState({ isLoading: true });
      this.props.getGstById(this.props.id).then((data) => {
        let {
          licenseNumber = "",
          validityDate = "",
          OriginalLicense = "Yes",
          remarks = "",
          storeAddress = "",
          village = "",
          pincode = "",
          districtId = "",
          stateId = "",
          licenseImgFileName = "",
          licenseImgUrl = null,
          registrationDate = "",
        } = data;
        this.setState({
          isLoading: false,
          licenseNumber: licenseNumber,
          validityDate: validityDate ? new Date(validityDate) : "",
          OriginalLicense: OriginalLicense ? OriginalLicense : "Yes",
          remarks: remarks ? remarks : "",
          storeAddress: storeAddress ? storeAddress : "",
          village: village ? village : "",
          pincode: pincode ? pincode : "",
          district: districtId ? districtId : "",
          state: stateId ? stateId : "",
          licenseImgFileName: licenseImgFileName ? licenseImgFileName : "",
          licenseImgUrl: licenseImgUrl ? licenseImgUrl : null,
          registrationDate: registrationDate ? new Date(registrationDate) : "",
        });
        if (stateId !== "" && stateId) {
          this.props.getDistrictApiwithState({ id: stateId }).then((data) => {
            let districtData = [];

            data.map((list) => {
              districtData.push({
                value: list.districtId,
                label: list.districtName,
              });
            });
            this.setState({
              districtOptions: districtData,
            });
          });
        }
      });
    }
  }

  handleRemoveImage = () => {
    this.setState({
      licenseImgUrl: null,
      licenseImgFileName: "",
    });
  };
  handleBack = () => {
    this.props.complianceCurrentTab(2);
    history.push("/fpo/compliance");
  };

  toggleDelete = () => {
    let { isDelete } = this.state;
    this.setState({
      isDelete: !isDelete,
    });
  };

  render() {
    const {
      isLoading,
      licenseNumber,
      validityDate,
      OriginalLicense,
      remarks,
      storeAddress,
      village,
      pincode,
      district,
      state,
      licenseImgFileName,
      licenseImgUrl,
      stateOption,
      districtOptions,
      pincodeOptions,
      isImageUploading,
      registrationDate,
      isDelete,
    } = this.state;

    let { accessData } = this.props;
    let permissionData = getAccessdata(accessData, "complainceGovernance");

    let { deleteAccess = false } = permissionData;

    return (
      <div>
        <div className="d-flex container-sa my-3 ">
          <div
            onClick={() => this.handleBack()}
            className="d-flex cursor-pointer"
          >
            <img className="cursor-pointer" src={arrow_left} alt="back" />
            <p className="ml-3 add-member-text-back mb-0">
              {this.props.id ? "Edit" : "Add"} GST Registration Certificate
            </p>
          </div>
        </div>
        {!isLoading ? (
          <div className="add-form">
            <div className="container bg-white py-4">
              <div className="row">
                <div className="col-6">
                  <NormalInput
                    label="GSTN"
                    type="text"
                    onChange={this.handleInput}
                    value={licenseNumber}
                    name="licenseNumber"
                    isHalfWidthForMemberProfile={true}
                    required={true}
                  />
                  <div className="row">
                    <div className="col-8 ml-auto">
                      {this.validator.message(
                        "gstn",
                        licenseNumber,
                        "required|max:20"
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <NormalRadio
                    label="Original license"
                    name="OriginalLicense"
                    isHalfWidthForMemberProfile={true}
                    onChange={this.handleInput}
                    checked1={OriginalLicense === "Yes"}
                    checked2={OriginalLicense === "No"}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <NormalDate
                    isIcon={true}
                    isHalfWidthForMemberProfile={true}
                    value={registrationDate}
                    name="registrationDate"
                    placeholder="DD/MM/YYYY"
                    onChange={this.handleInput}
                    mindate={false}
                    maxdate={false}
                    timeInput={false}
                    required={true}
                    label={"GST Registration date"}
                  />
                  <div className="row">
                    <div className="col-8 ml-auto">
                      {this.validator.message(
                        "gst Registration date",
                        registrationDate,
                        "required"
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <NormalDate
                    isIcon={true}
                    isHalfWidthForMemberProfile={true}
                    value={validityDate}
                    name="validityDate"
                    placeholder="DD/MM/YYYY"
                    onChange={this.handleInput}
                    mindate={false}
                    maxdate={false}
                    timeInput={false}
                    label={"Expiry Date (if any)"}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <NormalInput
                    label="Remarks"
                    type="text"
                    onChange={this.handleInput}
                    value={remarks}
                    name="remarks"
                    isHalfWidthForMemberProfile={true}
                  />
                  <div className="row">
                    <div className="col-8 ml-auto">
                      {this.validator.message("remarks", remarks, "max:75")}
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <NormalTextArea
                    label="Store address"
                    onChange={this.handleInput}
                    value={storeAddress}
                    name="storeAddress"
                    isHalfWidthForMemberProfile={true}
                  />
                  <div className="row">
                    <div className="col-8 ml-auto">
                      {this.validator.message(
                        "store Address",
                        storeAddress,
                        "max:75"
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="row">
                    <div className="col-12">
                      <NormalInput
                        label="Village"
                        type="text"
                        onChange={this.handleInput}
                        value={village}
                        name="village"
                        isHalfWidthForMemberProfile={true}
                      />
                      <div className="row">
                        <div className="col-8 ml-auto">
                          {this.validator.message(
                            "village",
                            village,
                            "max:75|alpha_space"
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <NormalSelect
                    isHalfWidthForMemberProfile={true}
                    label={"State"}
                    placeholder="Select"
                    value={state}
                    arrow={true}
                    name="state"
                    options={stateOption}
                    handleChange={this.handleInput}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <NormalSelect
                    isHalfWidthForMemberProfile={true}
                    label={"District"}
                    placeholder="Select"
                    value={district}
                    arrow={true}
                    name="district"
                    disabled={state == "" ? true : false}
                    options={districtOptions}
                    handleChange={this.handleInput}
                  />
                </div>
                <div className="col-6">
                  <NormalInput
                    label="Pincode"
                    type="text"
                    onChange={this.handleInput}
                    value={pincode}
                    name="pincode"
                    max={6}
                    isHalfWidthForMemberProfile={true}
                  />
                  <div className="row">
                    <div className="col-8 ml-auto">
                      {this.validator.message(
                        "pincode",
                        pincode,
                        "min:6|max:6|numeric"
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <div className="row upload-area">
                    <div className="col-4">
                      <div className="fs-16 text-black-32 font-weight-bold line-height-19">
                        {"Upload GST Registration certificate"}
                      </div>
                    </div>
                    <div className="col-8">
                      <div className="d-flex align-items-center btn-file-input">
                        <button className="">
                          <input
                            type="file"
                            className=""
                            accept=".jpg, .png, .jpeg"
                            onChange={this.handleFile}
                            id="handleFile"
                            title=""
                          />
                          {licenseImgUrl == null ? (
                            "Choose file"
                          ) : (
                            <>
                              {licenseImgFileName.length == 10
                                ? licenseImgFileName
                                : licenseImgFileName.substring(0, 9)}
                              {"..."}
                            </>
                          )}
                        </button>
                        {licenseImgFileName != "" && !isImageUploading && (
                          <div className="pl-3 position-relative">
                            <img
                              src={licenseImgUrl}
                              alt={licenseImgFileName}
                              height="75"
                              width="75"
                            />
                            <i
                              className="icon-Delete text-danger fs-14 pl-2 cursor-pointer position-absolute"
                              onClick={() => this.handleRemoveImage()}
                            ></i>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="pt-5 pb-3 d-flex">
                <div className="mx-auto d-flex">
                  {this.props.id && deleteAccess && (
                    <div className="cursor-pointer d-flex align-items-center mr-5">
                      <div
                        className="text-center fs-14 fw-600 text-danger d-flex"
                        onClick={this.toggleDelete}
                      >
                        <img className="mr-2" src={trash} alt="icon" />
                        {"Delete"}
                      </div>
                    </div>
                  )}
                  <NormalButton
                    label={`${this.props.id ? "Update" : "Submit & next"}`}
                    mainbg={true}
                    className="px-3 py-2 fs-14 line-height-26"
                    onClick={this.handleSubmit}
                  />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <>
            {/* Loader */}
            <CommonPageLoader />
          </>
        )}
        <ConfirmationPopup
          isOpen={isDelete}
          toggle={this.toggleDelete}
          onSubmit={this.handleDelete}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  accessData: state.home.accessData,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      addGst,
      fileUpload,
      getDistrictApiwithState,
      allState,
      getPincodeApiwithStateDistrict,
      getGstById,
      updateGst,
      deleteGst,
      complianceCurrentTab,
    },
    dispatch
  );
};
let component = AddGstClass;

export const AddGst = connect(mapStateToProps, mapDispatchToProps)(component);
