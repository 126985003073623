import React from "react";

import "./about.scss";

export const AboutCard = ({ img, number, title }) => {
  return (
    <div className="about-card">
      <div className="img-thumb-about">
        <img src={img} alt="img-thumb" />
      </div>
      <h4 className="text-center text-black fs-26 fw-600">{number}</h4>
      <p className="text-center text-black fs-18 fw-500">{title}</p>
    </div>
  );
};
