import React, { Component } from "react";
import "../ListView/style.scss";
import { history } from "service/helpers/index";
import { CommonPageLoader } from "component/common";
import { sellRequestDetail, getStageTwoList } from "action/SellrequestAct";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";

const city_svg = require("../../../../assets/svg/SellRequest/city.svg");
const state_svg = require("../../../../assets/svg/SellRequest/state.svg");
const arrow_left_white = require("assets/svg/add_member/arrow_left_white.svg");
const commodity_svg = require("../../../../assets/svg/SellRequest/commodity.svg");
const name_svg = require("../../../../assets/svg/SellRequest/name.svg");
const reasongray_png = require("../../../../assets/images/reasongray.png");


export class DetailClass extends Component {
  constructor() {
    super();
    this.state = {
      list: {},
      isLoading: false,
      cancelReason: '',
    }
  }
  componentDidMount() {
    this.detailApi();
  }

  detailApi() {
    let { id } = this.props;
    this.setState({ isLoading: true });
    this.props.sellRequestDetail(id).then((data) => {
      this.setState({
        list: data[0],
        isLoading: false,
      });
      if (data[0]?.status == 5) {
        this.props.getStageTwoList(id).then((data) => {
          if (data.length > 0 && data[data.length - 1]) {
            this.setState({
              cancelReason: data[data.length - 1]?.cancel_reason,
            });
          }
        });
      }
    });
  }
  ruppees(type, int) {
    var value = '';
    type = parseFloat(type).toFixed(2)
    var number = Number(type).toLocaleString('en-IN');

    if ((isNaN(type) == false && type != null && type != '' && type != 0)) {
      if (int == 0) {
        value = number + ' ' + (this.state.list?.custom_measurement == '' ? this.state.list?.unit_of_measurement_name : this.state.list?.custom_measurement);
      } else {
        value = '₹' + number + ' Per ' + (this.state.list?.custom_measurement == '' ? this.state.list?.unit_of_measurement_name : this.state.list?.custom_measurement)
      }
    } else {
      value = '';
    }
    return value;
  }

  render() {
    const {
      list,
      isLoading,
      fpoDetails,
      cancelReason
    } = this.state;
    return (
      <div className="container-sa position-relative mt-4 mb-4 detail-card">
        <>
          {!isLoading && (list === undefined || list === '' || list === null) ? (
            <p className="py-4 mb-0 text-black-50 fs-22 line-height-25 text-center">
              No list found
            </p>
          ) : !isLoading ? (
            <>
              {list !== undefined && list !== '' && list !== null ? (
                <>
                  <div>
                    {/* INFO SECTION */}
                    <div className="add-form-section">
                      <div className="row">


                        <h2 className="m-0 fs-18 mt-0 font-weight-bold">{list?.fpoName}</h2>


                        <div className="col pr-0 text-right d-flex align-items-center justify-content-end">


                          <span onClick={() => history.push("/fpo/sell-request")} className="btn btn-secondary btn-sm add-member-text-back mt-0 mb-0 cursor-pointer d-flex align-items-center justify-content-center w-150">
                            <img className="mr-2" src={arrow_left_white} alt="name" width="15" />
                            Back
                          </span>
                        </div>
                      </div>
                    </div>
                    {/* INFO SECTION */}

                    {/* DETAIL VIEW SECTION */}
                    {
                      cancelReason != '' ?
                        <div className="card mt-3 ">
                          <div className="card-header d-flex align-items-center">
                            <div className="mr-3"><img src={reasongray_png} alt="mani" width="20" /></div>
                            <h3 className="m-0 fs-14 font-weight-bold">Reason</h3>
                          </div>
                          <div className="card-body reasonwrp">
                            <p class="reason-text">{cancelReason}</p>
                          </div>
                        </div>
                        : ''}

                    {/* DETAIL VIEW SECTION */}
                    <div className="card mt-3">
                      <div className="card-header d-flex align-items-center">
                        <div className="mr-3"><img src={commodity_svg} alt="mani" width="25" /></div>
                        <h3 className="m-0 fs-14 font-weight-bold">FPO Basic Info</h3>
                      </div>
                      <div className="card-body">
                        <div className="row">
                          <div className="col-sm-4">
                            <div className="row">
                              {/*  */}
                              <div className="col-sm-6 labe-view fs-14">
                                <p>Commodity:</p>
                              </div>
                              <div className="col-sm-6 ans-view fs-14">
                                <p><b>{list?.commodity_name}</b></p>
                              </div>
                              {/*  */}

                              {/*  */}
                              <div className="col-sm-6 labe-view fs-14">
                                <p>Unit Of Measurement:</p>
                              </div>
                              <div className="col-sm-6 ans-view fs-14">
                                <p><b>{list?.custom_measurement == '' ? list?.unit_of_measurement_name : list?.custom_measurement}</b></p>
                              </div>
                              {/*  */}

                              <div className="col-sm-6 labe-view fs-14">
                                <p>Available for Sale:</p>
                              </div>
                              <div className="col-sm-6 ans-view fs-14">
                                <p><b>{this.ruppees(list?.existing_stock_level, 0)}</b></p>
                              </div>



                            </div>
                          </div>




                          <div className="col-sm-4">
                            <div className="row">

                              <div className="col-sm-6 labe-view fs-14">
                                <p>Category:</p>
                              </div>
                              <div className="col-sm-6 ans-view fs-14">
                                <p><b>{list?.fpo_industry_name}</b></p>
                              </div>
                              <div className="col-sm-6 labe-view fs-14">
                                <p>Expected Price:</p>
                              </div>
                              <div className="col-sm-6 ans-view fs-14">
                                <p><b>{this.ruppees(list?.expected_price_per_unit, 1)}</b></p>
                              </div>
                              {/*  */}
                              <div className="col-sm-6 labe-view fs-14">
                                <p>Contact Number:</p>
                              </div>
                              <div className="col-sm-6 ans-view fs-14">
                                <p><b>{list?.contact_number}</b></p>
                              </div>


                              {/*  */}





                            </div>
                          </div>

                          <div className="col-sm-4">
                            <div className="row">
                              {/*  */}

                              <div className="col-sm-6 labe-view fs-14">
                                <p>Quality Specification:</p>
                              </div>
                              <div className="col-sm-6 ans-view fs-14">
                                <p><b>{list?.quality_specification}</b></p>
                              </div>
                              {/*  */}
                              <div className="col-sm-6 labe-view fs-14">
                                <p>Variety:</p>
                              </div>
                              <div className="col-sm-6 ans-view fs-14">
                                <p><b>{list?.variety}</b></p>
                              </div>

                            </div>
                          </div>

                        </div>

                      </div>
                    </div>
                    {/* DETAIL VIEW SECTION */}





                    {/* DETAIL VIEW SECTION */}
                    <div className="card mt-3">
                      <div className="card-header d-flex align-items-center">
                        <div className="mr-3"><img src={commodity_svg} alt="mani" width="25" /></div>
                        <h3 className="m-0 fs-14 font-weight-bold">Capacity</h3>
                      </div>
                      <div className="card-body">
                        <div className="row">



                          <div className="col-sm-4">
                            <div className="row">
                              <div className="col-sm-6 labe-view fs-14">
                                <p>Supply Capacity:</p>
                              </div>
                              <div className="col-sm-6 ans-view fs-14">
                                <p><b>{list?.monthly_procurement_quantity}</b></p>
                              </div>
                              {/*  */}

                            </div>
                          </div>

                          <div className="col-sm-4">
                            <div className="row">
                              <div className="col-sm-6 labe-view fs-14">
                                <p>Quantity:</p>
                              </div>
                              <div className="col-sm-6 ans-view fs-14">
                                <p><b>{this.ruppees(list?.weekly_procurement_quantity, 0)}</b></p>
                              </div>
                            </div>

                          </div>
                        </div>




                      </div>
                    </div>
                    {/* DETAIL VIEW SECTION */}


                    {/* DETAIL VIEW SECTION */}
                    <div className="card mt-3">
                      <div className="card-header d-flex align-items-center">
                        <div className="mr-3"><img src={commodity_svg} alt="mani" width="25" /></div>
                        <h3 className="m-0 fs-14 font-weight-bold">Farmer Benefits</h3>
                      </div>
                      <div className="card-body">
                        <div className="row">
                          <div className="col-sm-4">
                            <div className="row">
                              <div className="col-sm-6 labe-view fs-14">
                                <p>No. of farmers engaged:</p>
                              </div>
                              <div className="col-sm-3 ans-view fs-14">
                                <p><b>{list?.number_of_farmers != 0 ? list?.number_of_farmers : ''}</b></p>
                              </div>
                              {/*  */}




                            </div>
                          </div>


                          <div className="col-sm-4">
                            <div className="row">
                              <div className="col-sm-6 labe-view fs-14">
                                <p>Expected timeline of payments
                                  :</p>
                              </div>
                              <div className="col-sm-6 ans-view fs-14">
                                <p><b>{list?.expected_timeline_for_date_of_procurement != 0 ? list?.expected_timeline_for_date_of_procurement + ' ' + 'Days' : ''}</b></p>
                              </div>
                              {/*  */}


                            </div>
                          </div>

                          <div className="col-sm-4">
                            <div className="row">
                              <div className="col-sm-8 labe-view fs-14">
                                <p>Promised Procurement Price:</p>
                              </div>
                              <div className="col-sm-4 ans-view fs-14">
                                <p><b>{this.ruppees(list?.promised_procurement_price, 1)}</b></p>
                              </div>
                              {/*  */}
                            </div>
                          </div>


                        </div>

                      </div>
                    </div>
                    {/* DETAIL VIEW SECTION */}

                    {/* DETAIL VIEW SECTION */}
                    <div className="card mt-3">
                      <div className="card-header d-flex align-items-center">
                        <div className="mr-3"><img src={commodity_svg} alt="mani" width="25" /></div>
                        <h3 className="m-0 fs-14 font-weight-bold">Nearest Market</h3>
                      </div>
                      <div className="card-body">
                        <div className="row">
                          <div className="col-sm-4">
                            <div className="row">
                              {/*  */}
                              <div className="col-sm-6 labe-view fs-14">
                                <p>Destination State:</p>
                              </div>
                              <div className="col-sm-6 ans-view fs-14">
                                <p><b>{list?.stateName}</b></p>
                              </div>
                              {/*  */}
                            </div>
                          </div>


                          <div className="col-sm-4">
                            <div className="row">
                              <div className="col-sm-6 labe-view fs-14">
                                <p>Destination District:</p>
                              </div>
                              <div className="col-sm-6 ans-view fs-14">
                                <p><b>{list?.districtName}</b></p>
                              </div>
                              {/*  */}
                            </div>
                          </div>

                          <div className="col-sm-4">
                            <div className="row">
                              <div className="col-sm-6 labe-view fs-14">
                                <p>Destination Market:</p>
                              </div>
                              <div className="col-sm-6 ans-view fs-14">
                                <p><b>{list?.fpo_destination_market}</b></p>
                              </div>
                              {/*  */}
                            </div>
                          </div>


                        </div>

                      </div>
                    </div>
                    {/* DETAIL VIEW SECTION */}
                    <div className="card mt-3 ">
                      <div className="card-header d-flex align-items-center">
                        <div className="mr-3"><img src={commodity_svg} alt="mani" width="25" /></div>
                        <h3 className="m-0 fs-14 font-weight-bold">Other Info</h3>
                      </div>
                      <div className="card-body">
                        <div className="row">
                          <div className="col-sm-4">
                            <div className="row">
                              {/*  */}
                              <div className="col-sm-6 labe-view fs-14">
                                <p>Available From:</p>
                              </div>
                              <div className="col-sm-6 ans-view fs-14">
                                <p><b>{list?.date_available_from != '' ? moment(list?.date_available_from).format("DD MMM YYYY") : ''}</b></p>
                              </div>
                              {/*  */}
                            </div>
                          </div>


                          <div className="col-sm-4">
                            <div className="row">
                              {/*  */}
                              <div className="col-sm-6 labe-view fs-14">
                                <p>Available Till:</p>
                              </div>
                              <div className="col-sm-6 ans-view fs-14">
                                <p><b>{list?.date_available_till && list?.date_available_till != '' ? moment(list?.date_available_till).format("DD MMM YYYY") : ''}</b></p>
                              </div>
                              {/*  */}
                            </div>
                          </div>


                          <div className="col-sm-4">
                            <div className="row">
                              <div className="col-sm-6 labe-view fs-14">
                                <p>Available at Location:</p>
                              </div>
                              <div className="col-sm-6 ans-view fs-14">
                                <p><b>{list?.location_of_availability_produce}</b></p>
                              </div>
                              {/*  */}
                            </div>
                          </div>


                          <div className="col-sm-4">
                            <div className="row">
                              <div className="col-sm-6 labe-view fs-14">
                                <p>Attachments:</p>
                              </div>
                              <div className="col-sm-12 ans-view fs-14">
                                {/* <p><b></b></p> */}
                                <p>
                                  {list?.fpo_attachment_url != '' ?
                                    list?.fpo_attachment_url?.split('.')?.pop() == 'mp4' ?
                                      <video
                                        src={list?.fpo_attachment_url}
                                        alt={'attachement'}
                                        height="150"
                                        controls={true}
                                        width="300"
                                      />
                                      : <img
                                        src={list?.fpo_attachment_url}
                                        alt={'attachement'}
                                        height="75"
                                        width="75"
                                      />
                                    : ''
                                  }</p>
                              </div>
                              {/*  */}
                            </div>
                          </div>




                        </div>

                      </div>
                    </div>


                    <div>&nbsp;</div>
                  </div>

                </>
              ) : null}
            </>
          ) : (
            <>
              {/* Loader */}
              <CommonPageLoader />
            </>
          )}
        </>


      </div>

    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      sellRequestDetail,
      getStageTwoList
    },
    dispatch
  );
};
let component = DetailClass;

export const DetailView = connect(
  null,
  mapDispatchToProps
)(component);