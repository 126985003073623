import React, { useState } from "react";
import { blogListApi } from "action/Acadamy";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { history } from "service/helpers";
import { getDescriptionData } from "service/helperFunctions";
import "./Blog.scss";

import SwiperCore, {
  Navigation,
  Pagination,
  Controller,
  EffectFade,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

// install Swiper's Thumbs component
SwiperCore.use([Navigation, Pagination, Controller, EffectFade]);

function FilteredBlogClass({ blogList = [], isfromFavouriteList }) {
  const [firstSwiper, setFirstSwiper] = useState(null);
  const [secondSwiper, setSecondSwiper] = useState(null);

  let redirectCourse = (data) => {
    history.push(`/academy/blog/${data.blogId}`);
  };

  const params = {
    navigation: false,
    pagination: {
      clickable: true,
    },
    EffectFade: false,
  };

  return (
    <>
      {blogList !== undefined && blogList.length > 0 ? (
        <section className="blog-section">
          <div className="container-sa">
            <div className="row align-items-center">
              <div className="col-sm-3">
                <div className="blog-our-mission">
                  {isfromFavouriteList ? (
                    <Swiper
                      effect="fade"
                      onSwiper={setFirstSwiper}
                      controller={{ control: secondSwiper }}
                    >
                      {blogList.map((data, index) => (
                        <SwiperSlide key={index + "on-going"}>
                          <div
                            className="slide-blog-our-mission"
                            style={{
                              backgroundImage: `url('${data.blog.blogImageFileUrl}')`,
                            }}
                          ></div>
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  ) : (
                    <Swiper
                      effect="fade"
                      onSwiper={setFirstSwiper}
                      controller={{ control: secondSwiper }}
                    >
                      {blogList.map((data, index) => (
                        <SwiperSlide key={index + "on-going"}>
                          <div
                            className="slide-blog-our-mission"
                            style={{
                              backgroundImage: `url('${data.blogImageFileUrl}')`,
                            }}
                          ></div>
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  )}
                </div>
              </div>
              <div className="col-sm-9">
                <div className="blog-single-view">
                  <div className="d-flex justify-content-between align-items-start mb-4 pt-3">
                    <h4 className="fs-24 line-height-28 font-weight-bold">
                      Blog
                    </h4>
                    <p
                      className="mb-0 text-white fs-18 line-height-31 fw-700 cursor-pointer"
                      onClick={() =>
                        history.push("/academy/blog/management/allblog")
                      }
                    >
                      {"View all"}
                    </p>
                  </div>
                  {isfromFavouriteList ? (
                    <Swiper
                      {...params}
                      onSwiper={setSecondSwiper}
                      controller={{ control: firstSwiper }}
                    >
                      {blogList.map((data, index) => {
                        let { blog = {} } = data;
                        return (
                          <SwiperSlide key={index + "on-going"}>
                            <p className="fs-14 line-height-24 fw-600 mb-2 pb-1">
                              {blog.category && blog.category.categoryName
                                ? blog.category.categoryName
                                : ""}
                            </p>
                            <h3 className="fs-36 line-height-42 mb-2 pb-1 font-weight-bold">
                              {blog.title}
                            </h3>
                            <div className="name-card mt-2 mb-4">
                              <div className="pick-letter">
                                {blog.author.substring(0, 1)}
                              </div>
                              <div>{blog.author}</div>
                            </div>
                            <p
                              className="description_blog"
                              dangerouslySetInnerHTML={{
                                __html: getDescriptionData(
                                  blog.shortDescription,
                                  125
                                ),
                              }}
                            ></p>

                            <div className="blog-button-alig">
                              <button
                                onClick={() => redirectCourse(blog)}
                                className="btn"
                              >
                                Read more
                              </button>
                            </div>
                          </SwiperSlide>
                        );
                      })}
                    </Swiper>
                  ) : (
                    <Swiper
                      {...params}
                      onSwiper={setSecondSwiper}
                      controller={{ control: firstSwiper }}
                    >
                      {blogList.map((data, index) => (
                        <SwiperSlide key={index + "on-going"}>
                          <p className="fs-14 line-height-24 fw-600 mb-2 pb-1">
                            {data.category && data.category.categoryName
                              ? data.category.categoryName
                              : ""}
                          </p>
                          <h3 className="fs-36 line-height-42 mb-2 pb-1 font-weight-bold">
                            {data.title}
                          </h3>
                          <div className="name-card mt-2 mb-4">
                            <div className="pick-letter">
                              {data.author.substring(0, 1)}
                            </div>
                            <div>{data.author}</div>
                          </div>
                          <p
                            className="description_blog"
                            dangerouslySetInnerHTML={{
                              __html: getDescriptionData(
                                data.shortDescription,
                                125
                              ),
                            }}
                          ></p>

                          <div className="blog-button-alig">
                            <button
                              onClick={() => redirectCourse(data)}
                              className="btn"
                            >
                              Read more
                            </button>
                          </div>
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : (
        ""
      )}
    </>
  );
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      blogListApi,
    },
    dispatch
  );
};

let component = FilteredBlogClass;

export const FilteredBlog = connect(null, mapDispatchToProps)(component);
