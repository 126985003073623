import React, { Component } from "react";
import { connect } from "react-redux";
import { Navbar } from "component/common";
import { ViewAllTrendingVideos } from "component/Acadamy";

export class ViewAllVideoClass extends Component {
  //life cycles
  componentDidMount = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  render() {
    return (
      <>
        <div className="normal-header">{/* <Navbar /> */}</div>
        <ViewAllTrendingVideos />
      </>
    );
  }
}

export const ViewAllVideo = connect(null, null)(ViewAllVideoClass);
