import { NormalButton } from 'component/common';
import React, { Component } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import { FieldsTemplate } from '../common/FieldsTemplate';

export const Governance = (props) => {

  const [, forceUpdate] = React.useState();
  const [facilities1List, setFacilities1List] = React.useState([]);
  const [governance, setGovernance] = React.useState({
    facilities1: []
  });
  const [governanceAttr, setGovernanceAttr] = React.useState([
    { label: "Number of members/ Share holders", field: "input", type: "number", name: "noOfShareHolders", required: true },
    { label: "Number of women members/ Share holders", field: "input", type: "number", name: "noOfWomenShareHolders", required: true },
    { label: "Number of active members (in %)", field: "input", type: "number", name: "noOfActiveMembers", note: "Example: if value is 55.5 % enter 55.5", required: true },
    {
      label: "Reason for low active members?",
      field: "radioGroup", type: "number", name: "rsnlowactivemem", required: false,
      note: "Make a selection only if the active members are less than 40% of total shareholders",
      conditions: { attribute: "noOfActiveMembers", values: [40], action: "show", criteria: "lt" },
      list: [
        { value: "memnotaware", label: "Lack of awareness among farmers" },
        { value: "mgmtnotintrstd", label: "Indifference from FPO management" },
        { value: "highdropout", label: "High drop out of existing members" },
        { value: "memsnotintrstd", label: "Lack of interest among farmers who are already doing well" }
      ]
    },
    { label: "Number of non members availing different services from the FPO", field: "input", type: "number", name: "noOfNonMembers", required: true },
    { label: "Number of BODs", field: "input", type: "number", name: "noOfBODs", note: "Board of directors", required: true },
    { label: "Number of women BODs", field: "input", type: "number", name: "noOfWomenBOD", required: true },
    { label: "Number of FIGs/SHGs/Farmer groups/Any other association of members", field: "input", type: "number", name: "noOfFIGs", note: "Farmer interest group: It can be Producer groups, Farmer Groups, SHGs or any kind of farmer groups under the FPO", required: true },
    { label: "What is the average number of members in FIG?", field: "input", type: "number", name: "avgNoOfFIGMembers", required: true },
    { label: "What is the average attendance (%) in these farmer group meetings?", field: "input", type: "number", name: "avgPercentAttendance", note: "Example: if value is 55.5 % enter 55.5", required: true },

    {
      label: "How often FIG meeting is conducted?", field: "radioGroup", type: "number", name: "figfreq", required: true,
      list: [
        { label: "Weekly", value: "figweekly" },
        { label: "Fortnightly", value: "figfortnight" },
        { label: "Monthly", value: "figmonthly" },
        { label: "Once in Two Months", value: "figoncetwomonth" },
        { label: "Quarterly", value: "figquarterly" },
        { label: "Biannually", value: "figbiannually" },
        { label: "No Meetings", value: "fignomeetings" },
      ]
    },
    {
      label: "Reason for less number of FIG meetings", field: "radioGroup", type: "number", name: "rsnlowfigmtgs", required: true,
      conditions: { attribute: "figfreq", values: ["figbiannually", "fignomeetings"], action: "show" },
      list: [
        { label: "Low member base", value: "lowmembase" },
        { label: "Low or negative net profit", value: "loworlessprof" },
        { label: "Capital investment projects undertaken", value: "capinvprojs" },
        { label: "Funds rolled out on credit to members", value: "credtomems" },
      ]
    },

    {
      label: " How often the BOD meeting is conducted?", field: "radioGroup", type: "number", name: "bodfreq", required: true,
      list: [
        { label: "Fortnightly or less", value: "fortnightlyorless" },
        { label: "Monthly", value: "monthly" },
        { label: "Once in Two Months", value: "onceintwomonths" },
        { label: "Quarterly", value: "quarterly" },
        { label: "Biannually", value: "biannually" },
        { label: "Annually or No Meetings", value: "annuallyornomeetings" },
      ]
    },

    { label: "What is the average attendance (%) in BOD meeting?", field: "input", type: "number", name: "avgBODAttenPC", note: "Example: if value is 55.5 % enter 55.5", required: true },

    {
      label: "Reason for low attendance in BOD meetings?",
      field: "radioGroup", type: "number", name: "rsnlowbodatten", required: false,
      note: "Make a selection only if attendance to BOD meetings is less than 50%",
      conditions: { attribute: "avgBODAttenPC", values: [50], action: "show", criteria: "lt" },
      list: [
        { value: "overdombod", label: "Over dominance by certain number of BODs" },
        { value: "notinterstd", label: "Lack of Interest" },
        { value: "bodmemmob", label: "BOD selected for Member mobilization" },
        { value: "mgmtprob", label: "Problems within the management" }
      ]
    },


    {
      label: " How often the Annual General body meeting is conducted?", field: "radioGroup", type: "number", name: "agmfreq", required: true,
      list: [
        { label: "Once in a year", value: "onceinayear" },
        { label: "Once in 15 months", value: "oncein15months" },
        { label: "More than once in 15 months", value: "morethanoncein15months" },
      ]
    },
    { label: "What is the average attendance(%) in Annual general body meeting?", field: "input", type: "number", name: "avgAGMAttenPC", note: "Example: if value is 55.5 % enter 55.5", required: true },

    {
      label: "In what format are the Minutes of the meeting recorded?", field: "radioGroup", type: "number", name: "momformat", required: true,
      list: [
        { label: "Soft File", value: "softfile" },
        { label: "Hard File", value: "hardfile" },
        { label: "Both Hard File & Soft file", value: "hardfile & softfile" },
        { label: "Not Recorded", value: "notrecorded" },
      ]
    },

    {
      label: "Sharing of Minutes of BOD meetings with the members", field: "radioGroup", type: "number", name: "bodmom", required: true,
      list: [
        { label: "MoM is discussed with members immediately", value: "membersimmediately" },
        { label: "MoM is discussed with members by the BODs during Farmer group meetings", value: "membersbybod" },
        { label: "MoM is discussed in AGM only", value: "inagmonly" },
        { label: "MoM is not discussed", value: "notdiscussed" },
      ]
    },

    { label: "What is the percentage of active women participation in BOD?", field: "input", type: "number", name: "womeninBODPC", note: "Example: if value is 55.5 % enter 55.5", required: true },
    {
      label: "How are conflicts resolved?", field: "radioGroup", type: "number", name: "conflictresol", required: true,
      list: [
        { label: "Conflicts are resolved among themselves", value: "amongthemselves" },
        { label: "Conflicts are resolved within the FIGs", value: "withinfigs" },
        { label: "Conflicts are resolved with intervention of BODs", value: "bodintervention" },
        { label: "Hierarchy", value: "hierarchy" },
        { label: "Conflicts are left as it is", value: "leftasis" },
      ]
    },

    {
      label: "Selection process facilitated by POPI or RI?", field: "radioGroup", type: "number", name: "selectprocpopiri", required: true,
      list: [
        { label: "Yes", value: "yes" },
        { label: "No", value: "no" },
        { label: "Not promoted by POPI or RI", value: "notpopiri" },
      ]
    },

    {
      label: "Selection Process of BODs", field: "radioGroup", type: "number", name: "bodselectproc", required: true,
      list: [
        { label: "BOD are selected through election", value: "throughelection" },
        { label: "Lead Farmers are promoted as BOD and approved by AGM", value: "leadfarmers" },
        { label: "BODs are not selected by members", value: "notbymembers" },
        { label: "BODs are political leaders or Family members", value: "political" },
      ]
    },

    {
      label: "Rotation of BODs", field: "radioGroup", type: "number", name: "bodrotation", required: true,
      list: [
        { label: "Partial Rotation of BODs", value: "partial" },
        { label: "Complete Rotation of BODs", value: "complete" },
        { label: "FPO is less than 3 year old and hence no rotation of BODs", value: "less" },
        { label: "FPO is more than 3 year old and there is no rotation of BODs", value: "more" },
      ]
    },

    {
      label: "Frequency of Rotation of BODs", field: "radioGroup", type: "number", name: "bodrotationfreq", required: true,
      list: [
        { label: "Rotation of BOD members occurs Yearly", value: "yearly" },
        { label: "Rotation of BOD members occurs triennial and less (once in every 3 years)", value: "threeyears" },
        { label: "Rotation of BOD members occurs quadrennial (once in every 4 years)", value: "fouryears" },
        { label: "Rotation of BOD members occurs once in five years or more", value: "fiveormore" },
      ]
    },

    {
      label: "What is the process followed for decision making in the FPO?", field: "radioGroup", type: "number", name: "decisionprocess", required: true,
      list: [
        { label: "Participatory/consultative decision making process with final decisions emerging in the meetings. Inputs provided by members are duly considered and documented while making decisions", value: "participatory" },
        { label: "Infrequent participation of members in the decision making process with limited consultation", value: "infrequent" },
        { label: "Non-participatory, centralized decision making process. Farmer members not interested", value: "nonparticipatory" },
      ]
    },

    {
      label: "Can the farmers access financial information like audit reports or books of account? Are the members aware of the process of auditing and accounting?", field: "radioGroup", type: "number", name: "membersawareofauditacc", required: true,
      list: [
        { label: "All financial documentation is present and is accessible to members at any point in time. Financial results are disclosed during AGMs. Members are aware of the ability and means to access such documentation", value: "alldocs" },
        { label: "Key documents are present, which the leadership is willing to disclose. The financial results are shared during AGMs, however, members lacks knowledge or have limited interest", value: "keydocs" },
        { label: "Minimal/no documentation of key processes of FPO. There leadership is unwilling to disclose information and thus financial results are not shared during AGMs", value: "minimalornodocs" },
      ]
    },

    {
      label: "What are the facilities from local stake holders?", field: "checkboxGroup", type: "number", name: "facilities1", required: true,
      list: [

      ]
    },
    {
      label: "Mention if others", field: "input", type: "text", name: "governothers", required: false,
      conditions: { attribute: "facilities1", values: [12], action: "show" },
    },

    {
      label: "Whether promoted by any organisation?", field: "radioGroup", type: "number", name: "prombyanyorg", required: true,
      list: [
        { label: "Yes", value: "yes" },
        { label: "No", value: "no" },
      ]
    },
    {
      label: "Networking with other FPOS", field: "radioGroup", type: "number", name: "networkotherfpos", required: true,
      note: "(meetings with other FPos under promoting institution ) Part of FPO Federations/ Exposure Visits to other FPOs/ Guided by other FPOs (Training Programmes)",
      list: [
        { label: "Yes", value: "yes" },
        { label: "No", value: "no" },
      ]
    },

  ]);

  React.useEffect(() => {
    setFacilities1List(props?.facilities);
  }, [props?.facilities]);

  React.useEffect(() => {
    if (props?.submitSurveyClick) {
      handleSubmit('completed');
    }
  }, [props?.submitSurveyClick]);

  const handleInput = ({ target: { name, value } }) => {
    setGovernance({
      ...governance,
      [name]: value,
    });
  };

  const handleRadio = (name, value) => {
    setGovernance({
      ...governance,
      [name]: value,
    });
  };

  const handleCheckbox = (name, value) => {
    let list = [...governance[name]];
    let index = list.indexOf(value);
    if (index >= 0) {
      list.splice(index, 1);
    } else {
      list.push(value);
    }
    setGovernance({
      ...governance,
      [name]: list,
    });
  };

  //validation
  const simpleValidator = React.useRef(new SimpleReactValidator({
    element: (message) => (
      <span className="error-message text-danger fs-14">{message}</span>
    ),
    autoForceUpdate: this
  }));
  const handleSubmit = (status) => {
    const formValid = simpleValidator.current.allValid();
    if (formValid) {
      let payload = { ...governance, status };
      props.updateSurvey("governance", payload);
    } else {
      simpleValidator.current.showMessages()
      forceUpdate(1);
      props.errorAccordion();
    }
  }
  React.useEffect(() => {
    if (props?.surveyDetails) {
      let _governance = {
        noOfShareHolders: props?.surveyDetails.noOfShareHolders,
        noOfWomenShareHolders: props?.surveyDetails.noOfWomenShareHolders,
        noOfActiveMembers: props?.surveyDetails.noOfActiveMembers,
        noOfNonMembers: props?.surveyDetails.noOfNonMembers,
        noOfBODs: props?.surveyDetails.noOfBODs,
        noOfWomenBOD: props?.surveyDetails.noOfWomenBOD,
        noOfFIGs: props?.surveyDetails.noOfFIGs,
        avgNoOfFIGMembers: props?.surveyDetails.avgNoOfFIGMembers,
        avgPercentAttendance: props?.surveyDetails.avgPercentAttendance,
        avgBODAttenPC: props?.surveyDetails.avgBODAttenPC,
        avgAGMAttenPC: props?.surveyDetails.avgAGMAttenPC,
        womeninBODPC: props?.surveyDetails.womeninBODPC,

        figfreq: props?.surveyDetails.figfreq,
        rsnlowfigmtgs: props?.surveyDetails.rsnlowfigmtgs,
        bodfreq: props?.surveyDetails.bodfreq,
        agmfreq: props?.surveyDetails.agmfreq,
        momformat: props?.surveyDetails.momformat,
        bodmom: props?.surveyDetails.bodmom,
        conflictresol: props?.surveyDetails.conflictresol,
        selectprocpopiri: props?.surveyDetails.selectprocpopiri,
        bodselectproc: props?.surveyDetails.bodselectproc,
        bodrotation: props?.surveyDetails.bodrotation,
        bodrotationfreq: props?.surveyDetails.bodrotationfreq,
        decisionprocess: props?.surveyDetails.decisionprocess,
        decisionprocess: props?.surveyDetails.decisionprocess,
        membersawareofauditacc: props?.surveyDetails.membersawareofauditacc,
        prombyanyorg: props?.surveyDetails.prombyanyorg,
        networkotherfpos: props?.surveyDetails.prombyanyorg,
      };
      if (props?.surveyDetails.facilities1) {
        _governance.facilities1 = props?.surveyDetails.facilities1;
      }
      //
      setGovernance({
        ...governance,
        ..._governance
      });
    }
  }, [props?.surveyDetails])

  return (
    <div>
      {/* <h5>Governance</h5> */}
      <div className="container-sa position-relative" >

        <FieldsTemplate
          attributes={governanceAttr}
          simpleValidator={simpleValidator}
          stateAttr={governance}
          handleInput={handleInput}
          handleRadio={handleRadio}
          handleCheckbox={handleCheckbox}
        />

        <div className="row mt-3">
          <div className="col-md-5 col-sm-12  my-1">
            <NormalButton
              label="Save & Next"
              mainbg={true}
              className="px-3 py-2 mx-auto fs-14 line-height-26 font-Gilroy saveNext"
              onClick={() => handleSubmit('draft')}
              isPopup={true}
            />
          </div>
        </div>
      </div>
    </div>
  )
}