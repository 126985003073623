import { CustomSelect, NormalButton, NormalInput } from 'component/common';
import React, { Component, useState } from 'react';
import { useSelector } from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';
import { FieldsTemplate } from '../common/FieldsTemplate';
import { RadioGroup } from '../common/RadioGroup';
import { addComma } from '../common/utils';

export const AccountsAndFinance = (props) => {
	const [formData, setFormData] = useState({
		crops: []
	});
	const [, forceUpdate] = React.useState();
	const [cropCount, setCropCount] = useState([
		{ crop_name: "", croparea: "", crop1farmersct: "" },
		{ crop_name: "", croparea: "", crop1farmersct: "" }
	])

	const { cropsList } = useSelector(
		(state) => state.grading
	);

	const formAttr = [
		{
			label: "Whether the FPO is into on-lending?", field: "radioGroup", type: "number", name: "onlending", required: true,
			list: [
				{ value: "yes", label: "Yes" },
				{ value: "no", label: "No" }
			]
		},
		{
			label: "What percentage of members are covered for on-lending?", field: "input", type: "number", name: "onlendmempc", required: false, placeholder: "Enter percentage", note: "(in lakhs)",
			conditions: { attribute: "onlending", values: ["yes"], action: "show" },
		},
		{
			label: "What is the repayment pattern for on-lending?", field: "radioGroup", type: "number", name: "repaypattern", required: false,
			conditions: { attribute: "onlending", values: ["yes"], action: "show" },
			list: [
				{ label: "Prompt repayment from all members", value: "promptbyall" },
				{ label: "Prompt repayment from majority members but few delays. Delay less than 30 days", value: "promptbymost30" },
				{ label: "Delay in repayment from majority members. Delay less than 30 days", value: "delaybymost30" },
				{ label: "Delay in repayment from majority members. Delay more than 30 days", value: "delaybymostgt30" },
				{ label: "Majority of members are defaulters", value: "mostdefault" }
			]
		},
		{
			label: "Have you availed loan or fininancial support from any institutions(Banks, NBFC, Others)?", field: "radioGroup", type: "number", name: "loanfrominst", required: true,
			list: [
				{ value: "loanfrominstyes", label: "Yes" },
				{ value: "loanfrominstno", label: "No" }
			]
		},
		{
			label: "Number of active loans?", field: "input", type: "number", name: "noofloans", required: false, placeholder: "Enter percentage", note: "(in lakhs)",
			conditions: { attribute: "loanfrominst", values: ["loanfrominstyes"], action: "show" },
		},
		{
			label: "What is the repayment pattern for loan or fininancial support from any institutions(Banks, NBFC, Others)", field: "radioGroup", type: "number", name: "repaypattern1", required: false,
			conditions: { attribute: "loanfrominst", values: ["loanfrominstyes"], action: "show" },
			list: [
				{ label: "Always prompt, no history of default", value: "alwaysprompt" },
				{ label: "Majority pay promptly, no history of default but few payments are delayed", value: "majprompt" },
				{ label: "Majority make delayed payments, but not history of default", value: "majdelayed" },
				{ label: "There is history of loan defaults", value: "loandefaults" }
			]
		},
		{
			label: "How the share capital is paid?",
			field: "radioGroup", type: "number", name: "sharecappaidmethod", required: true,
			list: [
				{ value: "equalbyall", label: "Paid by all the members equally(equal no: of shares)" },
				{ value: "unequal", label: "Not paid by all the members equally(unequal no: of shares)" }
			]
		},
		{
			label: "What is the net worth of FPO for the last financial year? (in Rs.)", field: "input", type: "text", alt: "networthlastyearStr", name: "networthlastyear", required: true, placeholder: "", note: "Example: 5,55,000",
			errConditions: { criteria: "lte:999999999" }
		},
		{
			label: "Reason for low net worth?",
			field: "radioGroup", type: "number", name: "rsnlowwomenpart", required: false,
			note: "Make a selection only if net worth was less than 0.5 lakhs last year",
			conditions: { attribute: "networthlastyear", values: [50000], action: "show", criteria: "lt" },
			list: [
				{ value: "lowmembase", label: "Low member base" },
				{ value: "loworlessprof", label: "Low or negative net profit" },
				{ value: "capinvprojs", label: "Capital investment projects undertaken" },
				{ value: "credtomems", label: "Funds rolled out on credit to members" }
			]
		},

		{
			label: "What is the net worth of FPO for the previous year to last financial year? (in Rs.)", field: "input", type: "text", alt: "networthprevyearStr", name: "networthprevyear", required: true, placeholder: "", note: "Example: 5,55,000",
			errConditions: { criteria: "lte:999999999" }
		},
		{
			label: "What is the profit of FPO for the last financial year? (in Rs.)", field: "input", type: "text", alt: "profitlastyearStr", name: "profitlastyear", required: true, placeholder: "", note: "Example: 5,55,000",
			errConditions: { criteria: "lte:999999999" }
		},
		{
			label: "What is the profit of FPO for the previous year to last financial year? (in Rs.)", field: "input", type: "text", alt: "profitprevyearStr", name: "profitprevyear", required: true, placeholder: "", note: "Example: 5,55,000",
			errConditions: { criteria: "lte:999999999" }
		},
		{
			label: "The total turnover of the FPO for the current financial year (in Rs.)", field: "input", type: "text", alt: "totalturnoverStr", name: "totalturnover", required: true, placeholder: "", note: "(in lakhs)",
			errConditions: { criteria: "lte:999999999" }
		},
		{
			label: "Reason for low turnover?",
			field: "radioGroup", type: "number", name: "rsnlowturnovr", required: false,
			note: "Make a selection only if turnover was less than 1 lakh last year",
			conditions: { attribute: "totalturnover", values: [100000], action: "show", criteria: "lt" },
			list: [
				{ value: "lowcustbase", label: "Low customer base" },
				{ value: "bettershops", label: "Presence of better shops in village selling low cost inputs" },
				{ value: "farmersselling", label: "Farmers selling outputs on their own" },
				{ value: "contractfarm", label: "Contract farming" }
			]
		},

		{
			label: "Income tax returns",
			field: "radioGroup", type: "number", name: "itreturns", required: true,
			list: [
				{ value: "filesontime", label: "Files ITR on time" },
				{ value: "fileslate", label: "Delay in ITR" },
				{ value: "doesntfile", label: "NO ITR" },
				{ value: "oneyearfpo", label: "FPO is less than one year old" }
			]
		},
		{
			label: "How did you manage Book keeping",
			field: "radioGroup", type: "number", name: "bookkeepingfreq", required: true,
			list: [
				{ value: "dailytally", label: "Tally with all entry on daily basis" },
				{ value: "weeklytally", label: "Tally with all entry once in a week" },
				{ value: "monthlytally", label: "Tally with all entry once in a month" },
				{ value: "onlymanual", label: "only Manual Ledgers" },
				{ value: "duringaudit", label: "At the time of audit information from passbook is transferred to ledger" },
				{ value: "othersoftware", label: "Any other Software" }
			]
		},
		{ label: "Name of the software used for Managing Book keeping", field: "input", type: "text", name: "bookkeepingsoftware", required: true, placeholder: "" },
		{
			label: "Cashless and paperless transactions",
			field: "radioGroup", type: "number", name: "cashlesspapless", required: true,
			list: [
				{ value: "onlinestored", label: "FPO makes majority of its transactions through online and stored digitally" },
				{ value: "onlinenotstored", label: "FPO makes majority of its transactions through online but not stored digitally" },
				{ value: "storedigitally", label: "FPO stores the documents of transactions digitally" },
				{ value: "onlymanual", label: "Every Transaction happens manually" }
			]
		},
		{ label: "Average Number of alternative sources of income for the Members?", field: "input", type: "number", name: "avgnoofaltincomesources", required: true, placeholder: "" },
		{ label: "What are the alternative source of income?", field: "input", type: "text", name: "altincomesources", required: true, placeholder: "" },
		{ label: "Number of Institutions offering financial services for the FPO", field: "input", type: "number", name: "nooffininst", required: true, placeholder: "" },
		{ label: "List of Financial Institutions", field: "input", type: "text", name: "fininstlist", required: true, placeholder: "" },
		{
			label: "Has FPC given any bonus or dividend distributed in last FY?",
			field: "radioGroup", type: "number", name: "bonusdiv", required: true,
			list: [
				{ value: "yes", label: "Yes" },
				{ value: "no", label: "No" },
				{ value: "oneyearfpos", label: "FPO is less than one year old" }
			]
		}
	]

	const handleInput = ({ target: { name, value } }) => {
		let data = {
			[name]: value,
		}
		if (["networthlastyear", "networthprevyear", "profitlastyear", "profitprevyear", "totalturnover"].includes(name)) {
			data[`${name}Str`] = addComma(value);
			let _value = value.replace(/,/g, '');
			data[`${name}`] = parseFloat(_value);
		}
		setFormData((preVal) => {
			return {
				...preVal,
				...data,
			}
		})
	};

	// updating detail
	React.useEffect(() => {
		if (props?.surveyDetails) {
			let _formData = {
				onlending: props?.surveyDetails.onlending,
				onlendmempc: props?.surveyDetails.onlendmempc || 0,
				repaypattern: props?.surveyDetails.repaypattern,
				loanfrominst: props?.surveyDetails.loanfrominst,
				noofloans: props?.surveyDetails.noofloans || 0,
				repaypattern1: props?.surveyDetails.repaypattern1,
				sharecappaidmethod: props?.surveyDetails.sharecappaidmethod,
				networthlastyear: parseFloat(props?.surveyDetails.networthlastyear) || 0,
				networthlastyearStr: addComma(props?.surveyDetails.networthlastyear) || 0,
				networthprevyear: parseFloat(props?.surveyDetails.networthprevyear) || 0,
				networthprevyearStr: addComma(props?.surveyDetails.networthprevyear) || 0,
				profitlastyear: parseFloat(props?.surveyDetails.profitlastyear) || 0,
				profitlastyearStr: addComma(props?.surveyDetails.profitlastyear) || 0,
				profitprevyear: parseFloat(props?.surveyDetails.profitprevyear) || 0,
				profitprevyearStr: addComma(props?.surveyDetails.profitprevyear) || 0,
				totalturnover: parseFloat(props?.surveyDetails.totalturnover) || 0,
				totalturnoverStr: addComma(props?.surveyDetails.totalturnover) || 0,
				itreturns: props?.surveyDetails.itreturns,
				bookkeepingfreq: props?.surveyDetails.bookkeepingfreq,
				bookkeepingsoftware: props?.surveyDetails.bookkeepingsoftware,
				cashlesspapless: props?.surveyDetails.cashlesspapless,
				avgnoofaltincomesources: props?.surveyDetails.avgnoofaltincomesources,
				altincomesources: props?.surveyDetails.altincomesources,
				//majorcrops: props?.surveyDetails.majorcrops || [],
				nooffininst: props?.surveyDetails.nooffininst,
				fininstlist: props?.surveyDetails.fininstlist,
				bonusdiv: props?.surveyDetails.bonusdiv,
				crops: props?.surveyDetails.crops || [
					{ cropId: "", cropArea: "", cropFarmersCount: "" },
					{ cropId: "", cropArea: "", cropFarmersCount: "" }
				]
			};

			setFormData({
				...formData,
				..._formData
			});
		}
	}, [props?.surveyDetails]);

	React.useEffect(() => {
		if (props?.submitSurveyClick) {
			handleSubmit('completed');
		}
	}, [props?.submitSurveyClick]);

	const handleRadio = (name, value) => {
		setFormData((preVal) => {
			return {
				...preVal,
				[name]: value,
			}
		})
	}

	//validation
	const simpleValidator = React.useRef(new SimpleReactValidator({
		validators: {
			lte: {
				message: "Please enter a value lessthan 100.",
				rule: (val, params, validator) => {
					return (
						val <= parseInt(params[0])
					);
				},
				messageReplace: (message, params) =>
					message.replace("100", parseInt(params[0])),
				required: true,
			},
		},
		element: (message) => (
			<span className="error-message text-danger fs-14">{message}</span>
		),
		autoForceUpdate: this
	}));
	const handleSubmit = (status) => {
		const formValid = simpleValidator.current.allValid();
		if (formValid) {
			let payload = { ...formData, status };
			payload.crops = payload.crops.filter(crop => {
				return !(crop.cropId === "" || crop.cropArea === "" || crop.cropFarmersCount === "")
			});
			props.updateSurvey("accountsAndFinanceInfo", payload);

		} else {
			simpleValidator.current.showMessages()
			forceUpdate(1);
			props.errorAccordion();
		}
	}

	const addCropsRow = () => {
		if (formData.crops.length < 5) {
			let crops = formData.crops;
			crops.push({ cropId: "", cropArea: "", cropFarmersCount: "" });
			setFormData((preVal) => {
				return {
					...preVal,
					crops: crops
				}
			})
		}
	}

	const removeCropRow = (index) => {
		if (formData.crops.length > 2) {
			let crops = formData.crops;
			crops.splice(index, 1);
			// setCropCount([...crops]);
			setFormData((preVal) => {
				return {
					...preVal,
					crops: crops
				}
			})
		}
	}

	const handleChange = (e, index) => {
		let { name, value } = e.target;
		let crops = formData.crops;
		crops[index][name] = value;
		setFormData((preVal) => {
			return {
				...preVal,
				crops: crops
			}
		});
	}
	return (
		<div>
			<div className="container-sa position-ralative">
				{/* <h5>Technology and Services</h5> */}
				<FieldsTemplate
					attributes={formAttr}
					simpleValidator={simpleValidator}
					stateAttr={formData}
					handleInput={handleInput}
					handleRadio={handleRadio}
				// handleCheckbox={handleCheckbox}
				/>

				<div className="grading-major-crops">
					<div className="row">
						<div className="col-12">
							<h6 className='font-Gilroy-Bold font-weight-bold'>Major crops</h6>
						</div>
					</div>
					<div className="row d-none d-md-flex">
						<div className="col-12 col-md-6 col-lg-3">
							<label className="grading-normal-label">Crops Name</label>
						</div>
						<div className="col-12 col-md-6 col-lg-3">
							<label className="grading-normal-label">Total area under crop (in acres)</label>
						</div>
						<div className="col-12 col-md-6 col-lg-3">
							<label className="grading-normal-label">Average number of farmers cultivating Crop</label>
						</div>
						<div className="col-12 col-md-6 col-lg-3">
							<label className="grading-normal-label"></label>
						</div>
					</div>
					{
						formData.crops.map((item, index) => (
							<div className={`row ${formData.crops.length - 1 === index ? "mb-3" : "major-crop"}`} key={index}>
								<div className="col-12 col-md-6 col-lg-3 major-crop-col">
									<CustomSelect
										label={""}
										placeholder="Select crop"
										value={item.cropId}
										arrow={true}
										name={`cropId`}
										isSearchable={true}
										isClearable={false}
										options={cropsList}
										required={index > 1 ? false : true}
										onChange={(e) => handleChange(e, index)}
										className="grading-normal-select"
									/>
									{simpleValidator.current.message("crop name", item.cropId, "required")}
								</div>
								<div className="col-12 col-md-6 col-lg-3 major-crop-col">
									<NormalInput
										label=""
										type="number"
										value={item.cropArea}
										placeholder="Total area under crop (in acres)"
										name={`cropArea`}
										required={index > 1 ? false : true}
										onChange={(e) => handleChange(e, index)}
										// disabled={false}
										className="grading-normal-input form-control"
										labelClassName="grading-normal-label"
										spaceTop={false}
									/>
									{simpleValidator.current.message("Total area under crop", item.cropArea, "required")}
								</div>
								<div className="col-12 col-md-6 col-lg-3 major-crop-col ">
									<NormalInput
										label=""
										type="number"
										value={item.cropFarmersCount}
										placeholder="Average number of farmers cultivating Crop"
										name={`cropFarmersCount`}
										required={index > 1 ? false : true}
										onChange={(e) => handleChange(e, index)}
										// disabled={false}
										className="grading-normal-input form-control"
										labelClassName="grading-normal-label"
										spaceTop={false}
									/>
									{simpleValidator.current.message("Average number of farmers cultivating Crop", item.cropFarmersCount, "required")}
								</div>
								<div className="col-12 col-md-6 col-lg-3 major-crop-col">
									{
										index > 1 &&
										<button type='button' className='btn btn-danger mt-2 mt-md-1 py-2' onClick={() => removeCropRow(index)}>
											<i className="fas fa-minus"></i>
										</button>
									}
								</div>
							</div>
						))
					}
					{
						formData.crops.length < 5 &&
						<div className="row">
							<div className="col-12">
								<NormalButton
									label="Add more"
									outline={true}
									mainbg={true}
									className="px-3 py-2 mx-auto fs-14 line-height-26 font-Gilroy saveNext"
									onClick={addCropsRow}
									isPopup={true}
								/>
							</div>
						</div>
					}
				</div>
				<div className="row mt-3">
					<div className="col-md-5 col-sm-12  my-1">
						<NormalButton
							label="Save as draft"
							mainbg={true}
							className="px-3 py-2 mx-auto fs-14 line-height-26 font-Gilroy saveNext"
							onClick={() => handleSubmit('draft')}
							isPopup={true}
						/>
					</div>
				</div>
			</div>
		</div>
	)
}