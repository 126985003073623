import React, { Component } from "react";
import "./feedback.scss";
import { AddFeedback } from "./AddFeedback";
export class FeedbackContent extends Component {
  state = {
    open: false,
  };
  //toggle area
  toggle = () => {
    this.setState((prevState) => ({
      open: !prevState.open,
    }));
  };

  render() {
    const { open } = this.state;
    return (
      <div className="feedback-area px-4 py-3 rounded ">
        <div className=" d-flex align-items-center mb-3">
          <div className="text-white feedback-header">
            {"Share your feedback"}
          </div>
        </div>
        <div>
          <p className="text-white feedback-body">
            {"Your valuable feedback would help us serve you better"}
          </p>
          <p
            className="text-white feedback-area text-right cursor-pointer"
            onClick={this.toggle}
          >
            {"Leave feedback"}
          </p>
        </div>

        <AddFeedback toggle={this.toggle} open={open} />
      </div>
    );
  }
}
