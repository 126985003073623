import React, { Component } from "react";
import arrow_left from "assets/svg/add_member/arrow_left.svg";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "./style.scss";
import { EventEmitter } from "helpers/EventEmitter";
import {
  getNotificationList,
  updateAllNotificationStatus,
  updateSingleNotificationStatus,
} from "action/notification";
import { Navbar, Pagination, CommonPageLoader } from "component/common";
import { history } from "service/helpers";

const moment = require("moment");
export class NotificationListClass extends Component {
  state = {
    notifications: [],
    pageMeta: {},
    isLoading: false,
  };

  //Get notificaton data
  _getNotificationList = (page = 1) => {
    this.setState({ isLoading: true });
    this.props.getNotificationList({ page }).then(({ data }) => {
      let notifyData = data.data;
      this.setState({
        notifications: notifyData.results,
        pageMeta: notifyData.pageMeta,
        isLoading: false,
      });
    });
  };

  //Lifecycles
  componentDidMount() {
    EventEmitter.addEventListener("navBar", this._getNotificationList);
    this._getNotificationList(1);
  }

  componentWillUnmount() {
    EventEmitter.removeEvent("navBar");
  }

  //handle redirect
  handleRedirect = (
    isViewed,
    notificationType,
    notificationTypeId,
    notificationId
  ) => {
    let { updateSingleNotificationStatus } = this.props;
    if (notificationType === "course") {
      if (!isViewed) {
        updateSingleNotificationStatus(notificationId);
      }
      history.push(`/academy/courses/${notificationTypeId}`);
    } else if (notificationType === "blog") {
      if (!isViewed) {
        updateSingleNotificationStatus(notificationId);
      }
      history.push(`/academy/blog/${notificationTypeId}`);
    } else if (notificationType === "informativeVideo") {
      if (!isViewed) {
        updateSingleNotificationStatus(notificationId);
      }
      history.push(`/academy/trending-video/${notificationTypeId}`);
    }
  };

  //handle page change
  handlePagination = ({ page }) => {
    this._getNotificationList(page);
  };

  //handle read all
  handleReadAll = () => {
    this.props.updateAllNotificationStatus();
  };

  render() {
    const { notifications, pageMeta, isLoading } = this.state;

    return (
      <div>
        <div className="normal-header">{/* <Navbar /> */}</div>
        <div className="container-sa">
          <div className="d-flex py-3 w-100">
            <div className="d-flex" onClick={() => history.goBack()}>
              <img className="cursor-pointer" src={arrow_left} alt="back" />
              <p className="ml-3 text-black fs-18 line-height-25 font-weight-bold mb-0 cursor-pointer">
                {"Back"}
              </p>
            </div>
          </div>
        </div>
        <div className="notification-page bg-blue-f9">
          <div className="container">
            {notifications.length !== 0 && (
              <div className="d-flex justify-content-end">
                <h5
                  className="fs-16 text-green-18 line-height-23 cursor-pointer font-weight-bold mb-3"
                  onClick={() => this.handleReadAll()}
                >
                  Read all
                </h5>
              </div>
            )}
            {isLoading ? (
              <>
                {/* Loader */}
                <CommonPageLoader />
              </>
            ) : (
              <>
                {notifications.length > 0 ? (
                  <>
                    {notifications.map((data, index) => (
                      <div
                        className={`cursor-pointer ${
                          data.isViewed ? "alert-notify-seen" : "alert-notify"
                        }`}
                        key={index}
                        onClick={() => {
                          this.handleRedirect(
                            data.isViewed,
                            data.notificationType,
                            data.notificationTypeId,
                            data.notificationId
                          );
                        }}
                      >
                        <div className="message">{data.message}</div>
                        <div className="time">
                          {`${moment(
                            data.createdAt,
                            "YYYY-MM-DD HH:mm:ss"
                          ).toNow(true)} ago`}
                        </div>
                      </div>
                    ))}
                    <Pagination
                      handleChange={this.handlePagination}
                      pageMeta={pageMeta}
                    />
                  </>
                ) : (
                  <div className="empty-data">No results found !!!</div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getNotificationList,
      updateAllNotificationStatus,
      updateSingleNotificationStatus,
    },
    dispatch
  );
};

const component = NotificationListClass;

export const NotificationList = connect(null, mapDispatchToProps)(component);
