import React, { Component, Fragment } from "react";
import MemberCard from "../MemberCard";
import { Pagination, CommonPageLoader } from "component/common";
import { BodStaffStrip } from "../MemberStrip";
import { getStaffList } from "action/BodAct";
import { getStaffListRm } from "action/RmAct";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

export class StaffListClass extends Component {
  constructor() {
    super();
    this.state = {
      members: [],
      pageMeta: {},
      isLoading: false,
    };
  }
  handleChange = (val) => {
    let { limit, getStaffList, searchValue, filterType, filter, id } =
      this.props;
    this.setState({
      isLoading: true,
    });
    let payload = {
      limit: limit,
      page: val,
      search: searchValue,
      filter: filter,
      filterType: filterType,
    };
    id
      ? this.props
          .getStaffListRm(id, payload)
          .then((data) => {
            this.setState({
              members: data.results,
              pageMeta: data.pageMeta,
              isLoading: false,
            });
          })
          .catch(() => {
            this.setState({
              isLoading: false,
            });
          })
      : getStaffList(payload)
          .then((data) => {
            this.setState({
              members: data.results,
              pageMeta: data.pageMeta,
              isLoading: false,
            });
          })
          .catch(() => {
            this.setState({
              isLoading: false,
            });
          });
  };

  componentDidUpdate(prevProps) {
    if (this.props.activeTab == 3) {
      const { searchPage } = this.props;
      this.props.activeTab != prevProps.activeTab && this.handleChange(1);
      this.props.limit !== prevProps.limit && this.handleChange(1);
      this.props.searchValue !== prevProps.searchValue && this.handleChange(1);
      this.props.searchPage !== prevProps.searchPage &&
        this.handleChange(searchPage ? searchPage : 1);
    }
  }

  render() {
    let { members, pageMeta, isLoading } = this.state;
    let { viewAccess = false, editAccess = false, id, view } = this.props;
    return (
      <Fragment>
        {!isLoading ? (
          <Fragment>
            {view === "1" ? (
              <div className="scroll-x">
                <div style={{ minWidth: "1200px" }}>
                  {members.length != 0 ? (
                    <div className="d-flex container-sa flex-column flex-wrap mt-3 px-0 justify-content-center">
                      {members.map((member, index) => {
                        return (
                          <BodStaffStrip
                            key={index}
                            memberDetail={member}
                            isStaff={true}
                            viewAccess={viewAccess}
                            editAccess={editAccess}
                            id={id}
                          />
                        );
                      })}
                    </div>
                  ) : (
                    <div className="col-12">
                      <p className="py-5 mb-0 text-black-50 fs-22 line-height-25 text-center">
                        No data found
                      </p>
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <div className="row">
                {members.length > 0 ? (
                  members.map((member, index) => {
                    return (
                      <div
                        key={index}
                        className={`col-md-4 col-lg-3 ${
                          index % 4 === 3 ? "pr-md-0" : ""
                        } mb-2 mt-3 px-2 ${index % 4 === 0 ? "pl-md-0" : ""}`}
                      >
                        <MemberCard
                          payload={member}
                          memberDetail={member}
                          isStaff={true}
                          viewAccess={viewAccess}
                          editAccess={editAccess}
                        />
                      </div>
                    );
                  })
                ) : (
                  <div className="col-12">
                    <p className="py-5 mb-0 text-black-50 fs-22 line-height-25 text-center">
                      No data found
                    </p>
                  </div>
                )}
              </div>
            )}
            <div className="my-0 w-100">
              {pageMeta.pageCount !== undefined && pageMeta.pageCount !== 1 && (
                <Pagination
                  handleChange={this.handleChange}
                  pageMeta={pageMeta}
                />
              )}
            </div>
          </Fragment>
        ) : (
          <CommonPageLoader />
        )}
      </Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getStaffList,
      getStaffListRm,
    },
    dispatch
  );
};
let component = StaffListClass;

export const StaffList = connect(null, mapDispatchToProps)(component);
