import React from "react";
import "./footer.scss";
export function Footer() {
  return (
    <div className="footer">
      <div className="d-flex justify-content-center pb-2 align-items-center">
        <p className="fs-16 fw-600 cursor-pointer mb-0 text-white pr-3">
          <a
            href="/termsandcondition"
            target="_bank"
            className="text-white text-decoration-none"
          >
            Terms & Conditions
          </a>
        </p>
        <p className="fs-16 fw-600 cursor-pointer mb-0 text-white">
          <a
            href="/privacypolicy"
            target="_bank"
            className="text-white text-decoration-none"
          >
            Privacy Policy
          </a>
        </p>
      </div>
      <div className="text-white d-flex align-items-center justify-content-center footer-link">
        <span>
          Samunnati |{" "}
          <a href="https://www.samunnati.com" target="_blank">
            {" "}
            www.samunnati.com{" "}
          </a>{" "}
          | Contact us at :{" "}
          <a href="tel:+91 7550005732" target="_blank">
            7550005732
          </a>{" "}
          | Mail us at :{" "}
          <a
            href="https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=gatewaysupport@samunnati.com"
            target="_blank"
          >
            gatewaysupport@samunnati.com
          </a>
        </span>
      </div>
    </div>
  );
}
