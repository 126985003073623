import { Chart, registerables } from "chart.js";
import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import GradingBarGraph from "./BarGraph";
import { getGradingSurvey } from "action/BodAct";
import { getGradingGraphDetails } from "action/GradingAct";
import { CommonPageLoader } from "component/common";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

const barGraphStatic = {
  type: "bar",
  borderColor: "rgb(54, 162, 235)",
  barPercentage: 0.5,
  barThickness: 28,
  maxBarThickness: 30,
  minBarLength: 2,
  borderRadius: 5,
};

function GradingComparison(props) {
  const {
    getGradingSurvey,
    getGradingGraphDetailsApi,
    selectedSurvey = [],
  } = props;

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  const [isLoading, setLoading] = useState(true);

  const [graphs, setGraph] = useState({
    graph1: {
      data: [],
      labels: ["Survey 1", "Survey 2", "Survey 3"],
    },
    graph2: {
      labels: ["Governance", "Compliance"],
      datasets: [],
    },
    graph3: {
      labels: ["Management", "Outreach"],
      datasets: [],
    },
    graph4: {
      labels: ["Accounts & Finance", "Operations"],
      datasets: [],
    },
    graph5: {
      labels: ["Technology", "Socio Economic"],
      datasets: [],
    },
  });

  React.useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  React.useEffect(() => {
    setLoading(true);
    let ids = "";
    for (let i = 0; i < selectedSurvey.length; i++) {
      ids += i > 0 ? `,${selectedSurvey[i].value}` : selectedSurvey[i].value;
    }
    if (selectedSurvey.length < 1) {
      setLoading(false);
      return;
    }
    let surveyIds = ids.split(",").sort().join(",");
    const fpoId = localStorage.getItem("fpoId");
    const { isFPO } = props;
    const query = { ids: surveyIds };
    const api = isFPO
      ? getGradingSurvey(surveyIds)
      : getGradingGraphDetailsApi(fpoId, query);
    api
      .then((res) => {
        let _graphs = graphs;
        const colors = ["#BADCCA", "#189C5C", "#306D4C"];
        // graph 1 data
        _graphs.graph1 = res.graph1;

        // graph 2 data
        _graphs.graph2.datasets = [];
        res.graph2.labels.map((item, index) => {
          _graphs.graph2.datasets.push({
            ...barGraphStatic,
            label: item,
            data: res.graph2.data[index],
            backgroundColor: colors[index % colors.length],
          });
        });

        // graph 3 data
        _graphs.graph3.datasets = [];
        res.graph3.labels.map((item, index) => {
          _graphs.graph3.datasets.push({
            ...barGraphStatic,
            label: item,
            data: res.graph3.data[index],
            backgroundColor: colors[index % colors.length],
          });
        });

        // graph 4 data
        _graphs.graph4.datasets = [];
        res.graph4.labels.map((item, index) => {
          _graphs.graph4.datasets.push({
            ...barGraphStatic,
            label: item,
            data: res.graph4.data[index],
            backgroundColor: colors[index % colors.length],
          });
        });

        // graph 5 data
        _graphs.graph5.datasets = [];
        res.graph5.labels.map((item, index) => {
          _graphs.graph5.datasets.push({
            ...barGraphStatic,
            label: item,
            data: res.graph5.data[index],
            backgroundColor: colors[index % colors.length],
          });
        });
        setGraph({ ..._graphs });
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [selectedSurvey]);

  React.useEffect(() => {
    Chart.register(...registerables);
    const ctx = document.getElementById("linechart");
    const myChart = new Chart(ctx, {
      type: "line",
      data: {
        datasets: [
          {
            fill: false,
            data: graphs.graph1.data,
            borderColor: "#189C5C",
            tension: 0,
          },
        ],
      },
      options: {
        layout: {
          padding: {
            left: 10,
            right: 20,
          },
        },
        hover: {
          mode: "dataset",
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            enabled: false,
            position: "nearest",
            external: externalTooltipHandler,
          },
        },
        scales: {
          y: {
            beginAtZero: true,
            suggestedMax: 100,
            ticks: {
              stepSize: 20,
            },
            grid: {
              borderDash: [3, 3],
            },
          },
          x: {
            type: "category",
            labels: graphs.graph1.label,
            // labels: [["Survey 1", "14-04-2012"], ["Survey 2", "25-05-2013"], ["Survey 3", "25-07-2014"]],
            offset: true,
            ticks: {
              color: "#306D4C",
              font: {
                size: 12,
                weight: "bold",
                family: "Gilroy",
              },
            },
            grid: {
              lineWidth: 0,
            },
          },
        },
      },
      plugins: [
        {
          id: "annotationline",
          beforeDraw: (chart) => {
            if (chart.tooltip._active && chart.tooltip._active.length) {
              const ctx = chart.ctx;
              ctx.save();
              const activePoint = chart.tooltip._active[0];

              ctx.beginPath();
              ctx.moveTo(
                chart.tooltip._active[0].element.x,
                chart.chartArea.top
              );
              ctx.lineTo(
                chart.tooltip._active[0].element.x,
                chart.chartArea.bottom
              );
              ctx.setLineDash([3, 3]);
              ctx.strokeStyle = "#bfbfbf";
              ctx.stroke();
              ctx.restore();
            }
          },
        },
      ],
    });
    myChart.data.datasets[0].data = graphs.graph1.data;
    myChart.options.scales.x.labels = graphs.graph1.labels;
    myChart.update();
    return () => {
      myChart.destroy();
    };
  }, [graphs]);

  const getOrCreateTooltip = (chart, context) => {
    let tooltipEl = chart.canvas.parentNode.querySelector("div");

    if (!tooltipEl) {
      tooltipEl = document.createElement("div");
      tooltipEl.style.background = "#fff";
      tooltipEl.style.borderRadius = "3px";
      tooltipEl.style.color = "#000";
      tooltipEl.style.opacity = 1;
      tooltipEl.style.pointerEvents = "none";
      tooltipEl.style.position = "absolute";
      tooltipEl.style.transform = "translate(-50%, 0)";
      tooltipEl.style.transition = "all .1s ease";
      tooltipEl.style.boxShadow = "rgb(0 0 0 / 42%) 0px 0px 20px";
      tooltipEl.style.width = "90px";

      const table = document.createElement("table");
      table.style.margin = "0px";

      tooltipEl.appendChild(table);
      chart.canvas.parentNode.appendChild(tooltipEl);
    }

    return tooltipEl;
  };

  const externalTooltipHandler = (context) => {
    // Tooltip Element
    const { chart, tooltip } = context;
    const tooltipEl = getOrCreateTooltip(chart, context);

    // Hide if no tooltip
    if (tooltip.opacity === 0) {
      tooltipEl.style.opacity = 0;
      return;
    }

    // Set Text
    if (tooltip.body) {
      const titleLines = tooltip.title || [];
      const bodyLines = tooltip.body.map((b) => b.lines);

      const tableHead = document.createElement("thead");

      const tableBody = document.createElement("tbody");
      bodyLines.forEach((body, i) => {
        const colors = tooltip.labelColors[i];

        const span = document.createElement("span");
        span.style.display = "block";
        span.style.fontSize = "12px";
        span.style.fontFamily = "Gilroy";
        span.style.fontWeight = "600";
        span.style.color = "#000000";

        const tr = document.createElement("tr");
        tr.style.backgroundColor = "inherit";
        tr.style.borderWidth = 0;

        const td = document.createElement("td");
        td.style.borderWidth = 0;
        td.style.paddingBottom = "0px";
        console.log(context.tooltip);
        if (context.tooltip.dataPoints.length > 0) {
          span.style.textAlign = "center";

          const span2 = document.createElement("span");
          span2.style.display = "block";
          span2.style.color = "#F97943";
          span2.style.fontSize = "16px";
          span2.style.textAlign = "center";
          span.style.fontFamily = "Gilroy";
          span.style.fontWeight = "600";
          const text = document.createTextNode(
            context.tooltip.dataPoints[0].label
          );
          span.appendChild(text);
          span2.appendChild(
            document.createTextNode(
              context.tooltip.dataPoints[0].parsed.y + "%"
            )
          );
          td.appendChild(span);
          td.appendChild(span2);
        } else {
          span.style.background = colors.backgroundColor;
          span.style.borderColor = colors.borderColor;
          span.style.borderWidth = "2px";
          span.style.marginRight = "10px";
          span.style.height = "10px";
          span.style.width = "10px";
          span.style.display = "block";
          const text = document.createTextNode(body);
          td.appendChild(span);
          td.appendChild(text);
        }
        tr.appendChild(td);
        tableBody.appendChild(tr);
      });

      const tableRoot = tooltipEl.querySelector("table");

      // Remove old children
      while (tableRoot.firstChild) {
        tableRoot.firstChild.remove();
      }

      // Add new children
      tableRoot.appendChild(tableHead);
      tableRoot.appendChild(tableBody);
    }

    const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

    // Display, position, and set styles for font
    tooltipEl.style.opacity = 1;
    tooltipEl.style.left = positionX + tooltip.caretX + "px";
    if (
      context.tooltip.dataPoints.length > 0 &&
      context.tooltip.dataPoints[0].parsed.y > 75
    ) {
      tooltipEl.className = "grading-chart-tooltip-b";
      tooltipEl.style.top = positionY + (tooltip.caretY + 10) + "px";
    } else {
      tooltipEl.className = "grading-chart-tooltip";
      tooltipEl.style.top = positionY + (tooltip.caretY - 85) + "px";
    }
    tooltipEl.style.font = tooltip.options.bodyFont.string;
    tooltipEl.style.padding =
      tooltip.options.padding + "px " + tooltip.options.padding + "px";
  };

  const barGraphHeigth = () => {
    if (windowDimensions.width > 1100) {
      return 267;
    } else if (windowDimensions.width > 900) {
      return 260;
    } else if (windowDimensions.width > 767) {
      return 280;
    } else if (windowDimensions.width > 575) {
      return 260;
    } else {
      return 250;
    }
  };

  return (
    <>
      {/* {
                isLoading ?
                    <CommonPageLoader />
                    : */}
      <div className="row grading-score-chart justify-content-evenly">
        <div className="col-md-4 col-12 mb-3 pl-lg-5">
          <div className="card border-0 py-2">
            <p className="line-bar-title">OVERALL</p>
            <canvas id="linechart" height={340}></canvas>
          </div>
          {graphs.graph1.data.length > 1 ? (
            <div className={`card border-0 mt-3 p-3 grading-card-height`}>
              <h5 className="font-Gilroy-Bold font-weight-bold text-success text-center">
                Remark
              </h5>
              {graphs.graph1.data[graphs.graph1.data.length - 1] >
              graphs.graph1.data[graphs.graph1.data.length - 2] ? (
                <p
                  className={`${
                    windowDimensions.width > 1100 ? "fs-18" : "fs-16"
                  } fs-18 fw-300 text-black-50 line-height-21 text-center`}
                >
                  Congrats for engaging maximum number of farmers. Continue the
                  good work.
                </p>
              ) : graphs.graph1.data[graphs.graph1.data.length - 2] ===
                graphs.graph1.data[graphs.graph1.data.length - 1] ? (
                <p
                  className={`${
                    windowDimensions.width > 1100 ? "fs-18" : "fs-16"
                  } fs-18 fw-300 text-black-50 line-height-21 text-center`}
                >
                  Congrats for the good work, increased engagement with members
                  & higher quantity of procurement and infrastructure building
                  would lead to higher grades.
                </p>
              ) : (
                <p
                  className={`${
                    windowDimensions.width > 1100 ? "fs-18" : "fs-16"
                  } fs-18 fw-300 text-black-50 line-height-21 text-center`}
                >
                  Congrats for the good work, increase in the involvement of
                  BODs, FIG representatives, CEO & staff in capacity building
                  and focus on digitization would take to higher grades.
                </p>
              )}
            </div>
          ) : (
            graphs.graph1.data.length === 1 && (
              <div className={`card border-0 mt-3 p-3 grading-card-height`}>
                <h5 className="font-Gilroy-Bold font-weight-bold text-success text-center">
                  Remark
                </h5>
                <p
                  className={`${
                    windowDimensions.width > 1100 ? "fs-18" : "fs-16"
                  } fs-18 fw-300 text-black-50 line-height-21 text-center`}
                >
                  {graphs.graph1.data[0] >= 90
                    ? "Congrats for being a model FPO, you are considered as an Established FPO. Continue the good work."
                    : graphs.graph1.data[0] >= 70
                    ? "Congrats for engaging maximum number of farmers, activities such as building infrastructure to procure & process members produce would lead higher grades."
                    : graphs.graph1.data[0] >= 50
                    ? "Congrats for the good work, increased engagement with members & higher quantity of procurement and infrastructure building would lead to higher grades."
                    : graphs.graph1.data[0] >= 20
                    ? "Congrats for the good work, activities such as building proper business plan, engagement with member farmers would take to higher grades."
                    : "Congrats for the good work, increase in the involvement of BODs, FIG representatives, CEO & staff in capacity building and focus on digitization would take to higher grades."}
                  Congrats for engaging maximum number of farmers. Continue the
                  good work.
                </p>
              </div>
            )
          )}
        </div>
        <div className="col-lg-8 col-md-8 col-12 pr-lg-5">
          <div className="row">
            <div className="col-md-6  col-sm-6 col-12 mb-3">
              <div className="card border-0 p-2">
                {/* <canvas id="barchart1" height="220"></canvas> */}
                <GradingBarGraph
                  height={barGraphHeigth()}
                  graphId="barchart1"
                  graphLabel={graphs.graph2.labels}
                  datasets={graphs.graph2.datasets}
                />
              </div>
            </div>
            <div className="col-md-6  col-sm-6 col-12 mb-3">
              <div className="card border-0 p-2">
                {/* <canvas id="barchart2" height="220"></canvas> */}
                <GradingBarGraph
                  height={barGraphHeigth()}
                  graphId="barchart2"
                  graphLabel={graphs.graph3.labels}
                  datasets={graphs.graph3.datasets}
                />
              </div>
            </div>
            <div className="col-md-6 col-sm-6 col-12 mb-3">
              <div className="card border-0 p-2">
                {/* <canvas id="barchart3" height="220"></canvas> */}
                <GradingBarGraph
                  height={barGraphHeigth()}
                  graphId="barchart3"
                  graphLabel={graphs.graph4.labels}
                  datasets={graphs.graph4.datasets}
                />
              </div>
            </div>
            <div className="col-md-6 col-sm-6 col-12 mb-3">
              <div className="card border-0 p-2">
                {/* <canvas id="barchart4" height="220"></canvas> */}
                <GradingBarGraph
                  height={barGraphHeigth()}
                  graphId="barchart4"
                  graphLabel={graphs.graph5.labels}
                  datasets={graphs.graph5.datasets}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* } */}
    </>
  );
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getGradingSurvey,
      getGradingGraphDetailsApi: getGradingGraphDetails,
    },
    dispatch
  );
};

const GradingComparisonChart = connect(
  null,
  mapDispatchToProps
)(GradingComparison);

export default GradingComparisonChart;
