import React, { useEffect } from "react";
import { withRouter } from "react-router";
import RMKYMNew from "component/RM/RMKYMNew/index";

const KYMNewPage = () => {
  const kymUserName = localStorage.getItem("userName");

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  });

  return (
    <div>
      <div className="inner-header">
        <div className="h-100 w-100">
          <p className="rm_title d-flex justify-content-center align-items-center h-100 w-100 text-white fs-32 font-weight-bold">
            KYM Survey - Mapped FPOs List
          </p>
        </div>
      </div>
      <div className="container-sa">
        <div className="fs-28 fw-800 text-green m-3">Hi {kymUserName},</div>
      </div>
      <RMKYMNew module="kym" />
    </div>
  );
};

export default withRouter(KYMNewPage);
