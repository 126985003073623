import React, { Component } from "react";
import { history } from "../../service/helpers";
import "./relationshiptab.scss";
import { NormalButton, CommonPageLoader } from "component/common";
import {
  getRelationshipHistoryList,
  updateRelationshipHistory,
} from "action/MemberAct";
import { getRelationshipHistoryListofFpo } from "action/RmAct";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

export class RelationshipTabClass extends Component {
  state = {
    isEdit: false,
    relationshipHistoryList: [],
    isLoading: false,
  };

  //Lifecycles
  componentDidMount() {
    this._getRelationshipHistoryList();
  }

  //get details
  _getRelationshipHistoryList = () => {
    let { getRelationshipHistoryList, id, getRelationshipHistoryListofFpo } =
      this.props;
    this.setState({ isLoading: true });
    if (id) {
      getRelationshipHistoryListofFpo(id).then((data) => {
        this._setData(data);
      });
    } else {
      getRelationshipHistoryList().then((data) => {
        this._setData(data);
      });
    }
  };

  _setData = (data) => {
    let relationshipList = [];
    data.map((list) => {
      relationshipList.push({
        realationHistoryMasterId: list.realationHistoryMasterId,
        relationHistoryType: list.relationHistoryType,
        relationHistoryTypeUrl: list.relationHistoryTypeUrl,
        isSelectedRelationship: list.isSelectedRelationship,
      });
    });
    this.setState({ relationshipHistoryList: data, isLoading: false });
  };

  //handle edit
  handleEdit = () => {
    this.setState({ isEdit: true });
  };

  //handle cancel
  handleCancel = () => {
    this.setState({ isEdit: false });
  };

  //handleChange
  handleChange = (index) => {
    let { relationshipHistoryList } = this.state;

    relationshipHistoryList[index].isSelectedRelationship =
      !relationshipHistoryList[index].isSelectedRelationship;

    this.setState({ relationshipHistoryList });
  };

  //handle save data
  handleSave = () => {
    let { relationshipHistoryList } = this.state;

    let payloadData = [];

    relationshipHistoryList.map((list) => {
      payloadData.push({
        realationHistoryMasterId: list.realationHistoryMasterId,
        isSelectedRelationship: list.isSelectedRelationship,
      });
    });
    if (payloadData.length === relationshipHistoryList.length) {
      this.props.updateRelationshipHistory(payloadData).then(() => {
        this.handleCancel();
        this._getRelationshipHistoryList();
      });
    }
  };

  //handle Mapping Route
  handleMappingRoute = (id) => {
    id
      ? history.push(`/rm/fpo/mapping-members/${id}`)
      : history.push("/fpo/mapping-members");
  };

  render() {
    let { isEdit, relationshipHistoryList, isLoading } = this.state;

    let { edit = false, id, match = {} } = this.props;
    let { path = "" } = match || {};

    return (
      <section className="relationship-section bg-blue-f9">
        <div className="container-sa">
          <div className="row">
            <div className="col-md-10">
              <div className="scroll-x">
                <div
                  style={{ minWidth: "max-content" }}
                  className="relationship-tab d-flex justify-content-center"
                >
                  <button
                    className="btn"
                    onClick={() => {
                      id
                        ? history.push(`/rm/fpo/member/${id}`)
                        : history.push("/fpo/member");
                    }}
                  >
                    Profile
                  </button>
                  {localStorage.getItem("userGroupName") === "fpo" &&
                  JSON.parse(localStorage.getItem("isKymUser")) ? (
                    <button
                      className="btn"
                      onClick={() => history.push("/fpo/report")}
                    >
                      Member Analytics
                    </button>
                  ) : null}
                  <button className="btn active">Relationship history</button>
                  <button
                    className={`btn ${
                      path.includes("fpo/mapping-members") ? "active" : ""
                    }`}
                    onClick={() =>
                      !path.includes("fpo/mapping-members")
                        ? this.handleMappingRoute(id)
                        : {}
                    }
                  >
                    Member Mapping
                  </button>
                </div>
              </div>
            </div>
            <div className="col-md-2 mt-md-0 mt-4">
              {edit ? (
                relationshipHistoryList.length !== 0 ? (
                  <div className="ml-auto add-buttons">
                    {isEdit ? (
                      <div className="d-flex">
                        <NormalButton
                          label="Cancel"
                          isPopup={true}
                          outline={true}
                          className="px-3 py-2 fs-14 line-height-26 mr-3"
                          onClick={() => this.handleCancel()}
                        />
                        <NormalButton
                          label="Save"
                          isPopup={true}
                          mainbg={true}
                          className="px-3 py-2 fs-14 line-height-26"
                          onClick={() => this.handleSave()}
                        />
                      </div>
                    ) : (
                      <div className="">
                        <NormalButton
                          label="Edit"
                          isPopup={true}
                          outline={true}
                          className="px-3 py-2 fs-14 line-height-26"
                          onClick={() => this.handleEdit()}
                        />
                      </div>
                    )}
                  </div>
                ) : null
              ) : null}
            </div>
          </div>
        </div>
        <div className="container-sa bg-white my-5">
          {!isLoading ? (
            <div className="p-4">
              {relationshipHistoryList.length !== 0 ? (
                <div className="row">
                  {relationshipHistoryList.map((list, index) => {
                    let {
                      isSelectedRelationship = false,
                      relationHistoryType = "",
                      relationHistoryTypeUrl = "",
                    } = list;
                    return (
                      <div className="col-md-3" key={index}>
                        {isEdit ? (
                          <div
                            className="relationship-card cursor-pointer"
                            onClick={() => this.handleChange(index)}
                          >
                            {isSelectedRelationship ? (
                              <div className="selection-marker"></div>
                            ) : (
                              <div className="selection-marker-empty"></div>
                            )}
                            <div className="d-flex justify-content-center align-items-center h-100">
                              <div>
                                <img
                                  className="card-img"
                                  src={relationHistoryTypeUrl}
                                />
                              </div>
                              <div>
                                <p className="pl-2 mb-0 fs-18 line-height-21 text-green-18">
                                  {relationHistoryType}
                                </p>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div
                            className={`${
                              isSelectedRelationship
                                ? "relationship-card"
                                : "relationship-card-disabled"
                            }`}
                          >
                            <div className="d-flex justify-content-center align-items-center h-100">
                              <div>
                                <img
                                  className="card-img"
                                  src={relationHistoryTypeUrl}
                                />
                              </div>
                              <div>
                                <p className="pl-2 mb-0 fs-18 line-height-21 text-green-18">
                                  {relationHistoryType}
                                </p>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              ) : (
                <p className="py-5 my-5 text-center text-black-50 fw-600 fs-20">
                  No data found !!!
                </p>
              )}
            </div>
          ) : (
            <>
              {/* Loading */}
              <CommonPageLoader />
            </>
          )}
        </div>
      </section>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getRelationshipHistoryList,
      updateRelationshipHistory,
      getRelationshipHistoryListofFpo,
    },
    dispatch
  );
};

export const RelationshipTab = connect(
  null,
  mapDispatchToProps
)(RelationshipTabClass);
