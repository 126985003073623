import React, { Component } from "react";
import { Navbar } from "component/common";
import { DocumentsView } from "../../component/Documents";
import { UserAuth } from "helpers/UserAuth";
class DocumentsClass extends Component {
  componentDidMount() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }
  render() {
    return (
      <div>
        <div className="inner-header">
          {/* <Navbar /> */}
          <div className="h-100 w-100">
            <p className="d-flex justify-content-center align-items-center h-100 w-100 text-white fs-32 font-weight-bold">
              FPO Documents
            </p>
          </div>
        </div>
        <div>
          <DocumentsView id={this.props.match.params.id} />
        </div>
      </div>
    );
  }
}
export const Documents = UserAuth(DocumentsClass, { service: "fpoDocuments" });
