import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  addLicense,
  getLicenseById,
  getAllLicenseAndFinance,
  updateLicense,
  deleteLicense,
  complianceCurrentTab,
} from "action/ComplianceAct";
import {
  fileUpload,
  getDistrictApiwithState,
  getPincodeApiwithStateDistrict,
  allState,
} from "action/MemberAct";

import SimpleReactValidator from "simple-react-validator";
import { Toast } from "service/toast";
import { history } from "service/helpers/index";
import {
  NormalInput,
  NormalButton,
  NormalDate,
  NormalSelect,
  NormalRadio,
  NormalTextArea,
  CommonPageLoader,
  ConfirmationPopup,
} from "component/common";
import moment from "moment";
import trash from "assets/svg/Associations/trash.svg";
import { getAccessdata } from "service/helperFunctions";
const arrow_left = require("assets/svg/add_member/arrow_left.svg");

class AddLicenseClass extends Component {
  state = {
    license: "",
    licenseNumber: "",
    issuingAuthority: "",
    issueDate: "",
    validityDate: "",
    RenewalApplied: "Yes",
    OriginalLicense: "Yes",
    remarks: "",
    LicenseName: "",
    productsCovered: "",
    storeAddress: "",
    village: "",
    pincode: "",
    district: "",
    state: "",
    licenseImgUrl: null,
    licenseImgFileName: "",
    isImageUploading: false,
    districtOptions: [],
    stateOptions: [],
    pincodeOptions: [],
    allLicense: [],
    isLoading: false,
    isDelete: false,
  };

  //Validation
  validator = new SimpleReactValidator({
    element: (message) => (
      <span className="error-message text-danger fs-14">{message}</span>
    ),
    autoForceUpdate: this,
  });

  handleFile = (e) => {
    let file = e.target.files[0];
    if (file !== undefined) {
      this.setState({ licenseImgUrl: null }, () => {
        this.setState({
          isImageUploading: true,
          licenseImgFileName: "Uploading ....",
          licenseImgUrl: file,
        });
        const formData = new FormData();
        formData.append("file", file);

        this.props
          .fileUpload(formData)
          .then((data) => {
            document.getElementById("handleFile").value = null;
            this.setState({
              licenseImgUrl: data[0].fileUrl,
              licenseImgFileName: data[0].fileName,
              isImageUploading: false,
            });
          })
          .catch(() => {
            this.setState({
              licenseImgUrl: null,
              licenseImgFileName: "",
              isImageUploading: false,
            });
          });
      });
    }
  };

  handleInput = (e) => {
    const { name, value } = e.target;
    if (name === "state") {
      this.setState(
        {
          [name]: value,
          district: "",
        },
        () => {
          this.props.getDistrictApiwithState({ id: value }).then((data) => {
            let DistrictData = [];

            data.map((list) => {
              DistrictData.push({
                value: list.districtId,
                label: list.districtName,
              });
            });
            this.setState({
              districtOptions: DistrictData,
            });
          });
        }
      );
    } else {
      this.setState({
        [name]: value,
      });
    }
  };

  handleSubmit = () => {
    this.setState({ isLoading: true });

    if (this.validator.allValid()) {
      const {
        license,
        licenseNumber,
        issuingAuthority,
        issueDate,
        validityDate,
        RenewalApplied,
        OriginalLicense,
        remarks,
        productsCovered,
        storeAddress,
        village,
        pincode,
        district,
        state,
        licenseImgUrl,
        licenseImgFileName,
        LicenseName,
        isImageUploading,
      } = this.state;
      if (!isImageUploading) {
        let body = {
          licenseId: license,
          LicenseName: license == 16 ? LicenseName : "",
        };

        if (licenseNumber !== "") {
          body.licenseNumber = licenseNumber;
        }
        if (issueDate !== "") {
          body.issueDate = moment(issueDate).format("YYYY-MM-DD");
        }
        if (validityDate !== "") {
          body.validityDate = moment(validityDate).format("YYYY-MM-DD");
        }
        if (issuingAuthority !== "") {
          body.issuingAuthority = issuingAuthority;
        }
        if (RenewalApplied !== "") {
          body.RenewalAppliced = RenewalApplied;
        }
        if (OriginalLicense !== "") {
          body.OriginalLicense = OriginalLicense;
        }
        if (remarks !== "") {
          body.remarks = remarks;
        }
        if (productsCovered !== "") {
          body.productsCovered = productsCovered;
        }
        if (storeAddress !== "") {
          body.storeAddress = storeAddress;
        }
        if (village !== "") {
          body.village = village;
        }
        if (licenseImgFileName !== "") {
          body.licenseImgFileName = licenseImgFileName;
          body.licenseImgUrl = licenseImgUrl;
        }
        if (state != "") {
          body.stateId = parseInt(state);
        }
        if (district !== "") {
          body.districtId = parseInt(district);
        }
        if (pincode !== "") {
          body.pincode = parseInt(pincode);
        }

        if (this.props.id) {
          body.id = parseInt(this.props.id);
          this.props
            .updateLicense(body)
            .then(() => {
              this.validator.hideMessages();
              this.handleBack();
            })
            .catch(() => {
              this.setState({ isLoading: false });
            });
        } else {
          this.props
            .addLicense(body)
            .then(() => {
              this.validator.hideMessages();
              this.setState({
                license: "",
                licenseNumber: "",
                issuingAuthority: "",
                issueDate: "",
                validityDate: "",
                RenewalApplied: "Yes",
                OriginalLicense: "Yes",
                remarks: "",
                productsCovered: "",
                storeAddress: "",
                village: "",
                pincode: "",
                district: "",
                state: "",
                licenseImgUrl: null,
                licenseImgFileName: "",
                isImageUploading: false,
                isLoading: false,
              });
              this.handleBack();
            })
            .catch(() => {
              this.setState({ isLoading: false });
            });
        }
      } else {
        this.setState({ isLoading: false });

        Toast({ type: "error", message: "Please wait Image is uploading" });
      }
    } else {
      this.setState({ isLoading: false });

      this.validator.showMessages();
      this.forceUpdate();
    }
  };
  handleDelete = () => {
    this.setState({ isLoading: true });
    this.props.deleteLicense(this.props.id).then(() => {
      this.handleBack();
    });
  };

  componentDidMount() {
    this.props.allState().then((data) => {
      let StateData = [];

      data.map((list) => {
        StateData.push({ value: list.stateId, label: list.stateName });
      });
      this.setState({
        stateOptions: StateData,
      });
    });
    this.props.getAllLicenseAndFinance().then((data) => {
      let dataSet = [];
      data.license.map((list) => {
        dataSet.push({ value: list.id, label: list.licenseName });
      });
      // let sortData = dataSet.sort((curr, prev) => {
      //   return curr.label.localeCompare(prev.label);
      // });
      this.setState({
        allLicense: dataSet,
      });
    });
    if (this.props.id) {
      this.setState({ isLoading: true });
      this.props.getLicenseById(this.props.id).then((data) => {
        let {
          licenseId = "",
          licenseNumber = "",
          issuingAuthority = "",
          issueDate = "",
          validityDate = "",
          RenewalAppliced = "Yes",
          OriginalLicense = "Yes",
          productsCovered = "",
          storeAddress = "",
          village = "",
          districtId = "",
          stateId = "",
          licenseImgUrl = null,
          licenseImgFileName = "",
          remarks = "",
          pincode = "",
        } = data;
        this.setState({
          isLoading: false,
          license: licenseId ? licenseId : "",
          licenseNumber: licenseNumber ? licenseNumber : "",
          issuingAuthority: issuingAuthority ? issuingAuthority : "",
          issueDate: issueDate ? new Date(issueDate) : "",
          validityDate: validityDate ? new Date(validityDate) : "",
          RenewalApplied: RenewalAppliced ? RenewalAppliced : "Yes",
          OriginalLicense: OriginalLicense ? OriginalLicense : "Yes",
          remarks: remarks ? remarks : "",
          productsCovered: productsCovered ? productsCovered : "",
          storeAddress: storeAddress ? storeAddress : "",
          village: village ? village : "",
          pincode: pincode ? pincode : "",
          district: districtId ? districtId : "",
          state: stateId ? stateId : "",
          licenseImgUrl: licenseImgUrl ? licenseImgUrl : "",
          licenseImgFileName: licenseImgFileName ? licenseImgFileName : "",
        });
        if (stateId !== "" && stateId) {
          this.props.getDistrictApiwithState({ id: stateId }).then((data) => {
            let districtData = [];
            data.map((list) => {
              districtData.push({
                value: list.districtId,
                label: list.districtName,
              });
            });
            this.setState({
              districtOptions: districtData,
            });
          });
        }
      });
    }
  }

  handleRemoveImage = () => {
    this.setState({
      licenseImgUrl: null,
      licenseImgFileName: "",
    });
  };

  handleBack = () => {
    this.props.complianceCurrentTab(1);
    history.push("/fpo/compliance");
  };

  toggleDelete = () => {
    let { isDelete } = this.state;
    this.setState({
      isDelete: !isDelete,
    });
  };

  render() {
    const {
      isLoading,
      license,
      allLicense,
      licenseNumber,
      issuingAuthority,
      issueDate,
      validityDate,
      RenewalApplied,
      OriginalLicense,
      remarks,
      productsCovered,
      storeAddress,
      village,
      pincode,
      pincodeOptions,
      district,
      districtOptions,
      state,
      stateOptions,
      LicenseName,
      licenseImgUrl,
      licenseImgFileName,
      isImageUploading,
      isDelete,
    } = this.state;

    let { accessData } = this.props;
    let permissionData = getAccessdata(accessData, "complainceGovernance");

    let { deleteAccess = false } = permissionData;

    return (
      <div>
        <div className="d-flex container-sa my-3 ">
          <div
            onClick={() => this.handleBack()}
            className="d-flex cursor-pointer"
          >
            <img className="cursor-pointer" src={arrow_left} alt="back" />
            <p className="ml-3 add-member-text-back mb-0">
              {this.props.id ? "Edit" : "Add"} License
            </p>
          </div>
        </div>
        {!isLoading ? (
          <div className="add-form">
            <div className="container bg-white py-4">
              <div className="row">
                <div className="col-6">
                  <NormalSelect
                    isHalfWidthForMemberProfile={true}
                    label={"Licence"}
                    placeholder="Select"
                    value={license}
                    name="license"
                    options={allLicense}
                    arrow={true}
                    handleChange={this.handleInput}
                    required={true}
                  />
                  <div className="row">
                    <div className="col-8 ml-auto">
                      {this.validator.message("license", license, "required")}
                    </div>
                  </div>
                </div>
                {license == 16 && (
                  <div className="col-6">
                    <NormalInput
                      label="Licence name"
                      type="text"
                      onChange={this.handleInput}
                      value={LicenseName}
                      required={true}
                      name="LicenseName"
                      isHalfWidthForMemberProfile={true}
                    />
                    <div className="row">
                      <div className="col-8 ml-auto">
                        {this.validator.message(
                          "License Name",
                          LicenseName,
                          "required"
                        )}
                      </div>
                    </div>
                  </div>
                )}
                <div className="col-6">
                  <NormalInput
                    label="Licence number"
                    type="text"
                    onChange={this.handleInput}
                    value={licenseNumber}
                    required={true}
                    name="licenseNumber"
                    isHalfWidthForMemberProfile={true}
                  />
                  <div className="row">
                    <div className="col-8 ml-auto">
                      {this.validator.message(
                        "license Number",
                        licenseNumber,
                        "required|max:50"
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <NormalInput
                    label="Issuing authority"
                    type="text"
                    onChange={this.handleInput}
                    value={issuingAuthority}
                    name="issuingAuthority"
                    isHalfWidthForMemberProfile={true}
                  />
                  <div className="row">
                    <div className="col-8 ml-auto">
                      {this.validator.message(
                        "Issuing authority",
                        issuingAuthority,
                        "max:75"
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <NormalDate
                    isIcon={true}
                    isHalfWidthForMemberProfile={true}
                    value={issueDate}
                    name="issueDate"
                    placeholder="DD/MM/YYYY"
                    onChange={this.handleInput}
                    mindate={false}
                    maxdate={true}
                    timeInput={false}
                    label={"Licence Date"}
                    required={true}
                  />
                  <div className="row">
                    <div className="col-8 ml-auto">
                      {this.validator.message(
                        "Licence Date",
                        issueDate,
                        "required"
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <NormalDate
                    isIcon={true}
                    isHalfWidthForMemberProfile={true}
                    value={validityDate}
                    name="validityDate"
                    placeholder="DD/MM/YYYY"
                    onChange={this.handleInput}
                    mindate={true}
                    maxdate={false}
                    timeInput={false}
                    label={"Expiry Date"}
                    required={true}
                  />
                  <div className="row">
                    <div className="col-8 ml-auto">
                      {this.validator.message(
                        "Expiry Date",
                        validityDate,
                        "required"
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <NormalRadio
                    label="Renewal applied"
                    name="RenewalApplied"
                    isHalfWidthForMemberProfile={true}
                    onChange={this.handleInput}
                    checked1={RenewalApplied === "Yes"}
                    checked2={RenewalApplied === "No"}
                  />
                </div>
                <div className="col-6">
                  <NormalRadio
                    label="Original Licence"
                    name="OriginalLicense"
                    isHalfWidthForMemberProfile={true}
                    onChange={this.handleInput}
                    checked1={OriginalLicense === "Yes"}
                    checked2={OriginalLicense === "No"}
                  />
                </div>
                <div className="col-6">
                  <NormalInput
                    label="Remarks"
                    type="text"
                    onChange={this.handleInput}
                    value={remarks}
                    name="remarks"
                    isHalfWidthForMemberProfile={true}
                  />
                  <div className="row">
                    <div className="col-8 ml-auto">
                      {this.validator.message(
                        "Remarks",
                        remarks,
                        "max:100|alpha_space"
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <NormalInput
                    label="Products covered"
                    type="text"
                    onChange={this.handleInput}
                    value={productsCovered}
                    name="productsCovered"
                    isHalfWidthForMemberProfile={true}
                  />
                  <div className="row">
                    <div className="col-8 ml-auto">
                      {this.validator.message(
                        "Products covered",
                        productsCovered,
                        "max:50|alpha_space"
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <NormalInput
                    label="Village"
                    type="text"
                    onChange={this.handleInput}
                    value={village}
                    name="village"
                    isHalfWidthForMemberProfile={true}
                  />
                  <div className="row">
                    <div className="col-8 ml-auto">
                      {this.validator.message(
                        "village",
                        village,
                        "max:75|alpha_space"
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <NormalTextArea
                    label="Store address"
                    onChange={this.handleInput}
                    value={storeAddress}
                    name="storeAddress"
                    isHalfWidthForMemberProfile={true}
                  />
                  <div className="row">
                    <div className="col-8 ml-auto">
                      {this.validator.message(
                        "address",
                        storeAddress,
                        "max:75"
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <NormalSelect
                    isHalfWidthForMemberProfile={true}
                    label={"State"}
                    placeholder="Select"
                    value={state}
                    arrow={true}
                    name="state"
                    options={stateOptions}
                    handleChange={this.handleInput}
                  />
                </div>
                <div className="col-6">
                  <NormalSelect
                    isHalfWidthForMemberProfile={true}
                    label={"District"}
                    placeholder="Select"
                    value={district}
                    arrow={true}
                    name="district"
                    disabled={state == "" ? true : false}
                    options={districtOptions}
                    handleChange={this.handleInput}
                  />
                </div>
                <div className="col-6">
                  <NormalInput
                    label="Pincode"
                    type="text"
                    onChange={this.handleInput}
                    value={pincode}
                    name="pincode"
                    max={6}
                    isHalfWidthForMemberProfile={true}
                  />
                  <div className="row">
                    <div className="col-8 ml-auto">
                      {this.validator.message(
                        "pincode",
                        pincode,
                        "min:6|max:6|numeric"
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="row upload-area">
                    <div className="col-4">
                      <div className="fs-16 text-black-32 font-weight-bold line-height-19">
                        Upload Licence Document
                      </div>
                    </div>
                    <div className="col-8">
                      <div className="d-flex align-items-center btn-file-input">
                        <button className="">
                          <input
                            type="file"
                            className=""
                            accept=".jpg, .png, .jpeg"
                            onChange={this.handleFile}
                            id="handleFile"
                            title=""
                          />
                          {licenseImgUrl == null ? (
                            "Choose file"
                          ) : (
                            <>
                              {licenseImgFileName.length == 10
                                ? licenseImgFileName
                                : licenseImgFileName.substring(0, 9)}
                              {"..."}
                            </>
                          )}
                        </button>
                        {licenseImgFileName != "" && !isImageUploading && (
                          <div className="pl-3 position-relative">
                            <img
                              src={licenseImgUrl}
                              alt={licenseImgFileName}
                              height="75"
                              width="75"
                            />
                            <i
                              className="icon-Delete text-danger fs-14 pl-2 cursor-pointer position-absolute"
                              onClick={() => this.handleRemoveImage()}
                            ></i>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="pt-5 pb-3 d-flex">
                <div className="mx-auto d-flex">
                  {this.props.id && deleteAccess && (
                    <div className="cursor-pointer d-flex align-items-center mr-5">
                      <div
                        className="text-center fs-14 fw-600 text-danger d-flex"
                        onClick={this.toggleDelete}
                      >
                        <img className="mr-2" src={trash} alt="icon" />
                        Delete
                      </div>
                    </div>
                  )}
                  <NormalButton
                    label={`${this.props.id ? "Update" : "Submit & next"}`}
                    mainbg={true}
                    className="px-3 py-2 fs-14 line-height-26"
                    onClick={this.handleSubmit}
                  />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <>
            {/* Loader */}
            <CommonPageLoader />
          </>
        )}
        <ConfirmationPopup
          isOpen={isDelete}
          toggle={this.toggleDelete}
          onSubmit={this.handleDelete}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  accessData: state.home.accessData,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      addLicense,
      fileUpload,
      getDistrictApiwithState,
      getPincodeApiwithStateDistrict,
      allState,
      getAllLicenseAndFinance,
      getLicenseById,
      updateLicense,
      deleteLicense,
      complianceCurrentTab,
    },
    dispatch
  );
};
let component = AddLicenseClass;

export const AddLicense = connect(
  mapStateToProps,
  mapDispatchToProps
)(component);
