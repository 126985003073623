import React, { Component } from "react";
import { connect } from "react-redux";
import { Navbar } from "component/common";
import filter from "assets/images/icon/filter.svg";
import { bindActionCreators } from "redux";
import { MyForumComp } from "component/Acadamy";
import { history } from "service/helpers";
import backIcon from "../../assets/images/icon/arrow-left.svg";

export class MyForumClass extends Component {
  //life cycles
  componentDidMount = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  render() {
    let { history = {} } = this.props;

    let { location = {} } = history;

    let { pathname = "" } = location;

    let loginDetails = localStorage.getItem("userGroupName");

    return (
      <>
        <div className="normal-header">{/* <Navbar /> */}</div>
        <div className="shadow">
          <div className="d-flex container-sa py-3 bg-white">
            <div
              onClick={() =>
                loginDetails.includes("fpo")
                  ? history.push("/fpo/home")
                  : loginDetails.includes("samunnati")
                  ? history.push("/rm/home")
                  : history.push("/other/home")
              }
              className="d-flex cursor-pointer"
            >
              <img className="cursor-pointer" src={backIcon} alt="back" />
              <p className="ml-3 add-member-text-back mb-0">{"Back"}</p>
            </div>
          </div>
        </div>
        <div className="community-forum">
          <div className="container-sa">
            <div className="row forum-tabs">
              <div className="col-md-6 col-xs-6">
                <ul className="nav nav-pills">
                  <li
                    className={`nav-item ${
                      !pathname.includes("myforum") &&
                      !pathname.includes("notifications")
                        ? "active"
                        : ""
                    }`}
                    onClick={() => history.push("/academy/communityForum")}
                  >
                    {"Discussion forum"}
                  </li>
                  <li
                    className={`nav-item ${
                      pathname.includes("myforum") ? "active" : ""
                    }`}
                    onClick={() =>
                      history.push("/academy/communityForum/myforum")
                    }
                  >
                    {"My activity"}
                  </li>
                  <li
                    className={`nav-item ${
                      pathname.includes("notifications") ? "active" : ""
                    }`}
                    onClick={() =>
                      history.push("/academy/communityForum/notifications")
                    }
                  >
                    {"Notifications"}
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="bg-blue-f9 pt-4">
            <div className="container-sa">
              <div className="row">
                <div className="col-md-12">
                  <MyForumComp />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

let component = MyForumClass;

export const MyForum = connect(null, mapDispatchToProps)(component);
