import React, { Component } from "react";
import SimpleReactValidator from "simple-react-validator";
import { Link } from "react-router-dom";
import Cookies from "universal-cookie";
import { history } from "service/helpers";
import {
  verifyPassword,
  login,
  getAccessDetails,
  googleLogin,
} from "action/AuthAct";
import jwt_decode from "jwt-decode";
import "./passlogin.scss";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  encryptData,
  setCookies,
  termsandPolicyLinks,
} from "service/helperFunctions";
import { GoogleLogin } from "react-google-login";

const cookies = new Cookies();
export class PassAuthClass extends Component {
  state = {
    emailOrMobNum: cookies.get("user_emailOrMobNum") || "",
    password: cookies.get("user_password") || "",
    rememberme:
      cookies.get("user_emailOrMobNum") && cookies.get("user_password"),
    loginLoader: false,
    mask: true,
  };

  validator = new SimpleReactValidator({
    validators: {
      email: {
        message: "must be a valid mobile number or Email.",
        rule: (val, params, validator) => {
          return (
            validator.helpers.testRegex(val, /^[0-9]{10}$/) ||
            validator.helpers.testRegex(
              val,
              /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}/
            )
          );
        },
        messageReplace: (message, params) =>
          message.replace("", this.helpers.toSentence(params)),
        required: true,
      },
      element: (message) => (
        <span className="error-message font-md">{message}</span>
      ),
      autoForceUpdate: this,
    },
  });

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  toggle = (key) => {
    this.setState((prevState) => ({
      [key]: !prevState[key],
    }));
  };

  handleSubmit = () => {
    if (this.validator.allValid()) {
      let { rememberme, emailOrMobNum, password } = this.state;
      let body = {
        emailOrMobNum: emailOrMobNum,
        isEmail: emailOrMobNum.includes("@") ? true : false,
      };
      this.setState({ loginLoader: true });
      this.props
        .login(body)
        .then((data) => {
          let userGroupName =
            data.userRoles &&
            data.userRoles.userGroup !== undefined &&
            data.userRoles.userGroup.userGroupName !== undefined
              ? data.userRoles.userGroup.userGroupName
              : "";
          let userRoleName =
            data.userRoles &&
            data.userRoles !== undefined &&
            data.userRoles.userRoleName !== undefined
              ? data.userRoles.userRoleName
              : "";
          let inputData = {
            userGroupName,
            emailOrMobNum: emailOrMobNum,
            isEmail: emailOrMobNum.includes("@") ? true : false,
            password: encryptData(password),
          };
          this.props
            .verifyPassword(inputData)
            .then((data) => {
              if (
                userRoleName !== "Others Organization" &&
                userRoleName !== "Individual" &&
                userRoleName !== "Risk And Audit Team" &&
                userRoleName !== "Credit Team"
              ) {
                this.props.getAccessDetails();
              }
              this.setState({ loginLoader: false });
              this.validator.hideMessages();
              localStorage.setItem("languagePopup", 1);
              localStorage.setItem("userGroupName", userGroupName);
              localStorage.setItem("userRoleName", userRoleName);
              localStorage.getItem("userRoleName") == "Relationship Manager"
                ? history.push("/rm/home")
                : localStorage.getItem("userGroupName") == "fpo"
                ? history.push("/fpo/home")
                : history.push("/other/home");
              if (rememberme) {
                setCookies({
                  name: "user_emailOrMobNum",
                  value: emailOrMobNum,
                  days: 30,
                });
                setCookies({
                  name: "user_password",
                  value: password,
                  days: 30,
                });
              } else {
                cookies.remove("user_emailOrMobNum");
                cookies.remove("user_password");
              }
            })
            .catch(() => {
              this.validator.hideMessages();
              this.setState({
                loginLoader: false,
                emailOrMobNum: "",
                password: "",
              });
            });
        })
        .catch(() => {
          this.validator.hideMessages();
          this.setState({
            loginLoader: false,
            emailOrMobNum: "",
            password: "",
          });
        });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  responseGoogle = (response) => {
    let body = {
      email: response.profileObj.email,
      idToken: response.tokenId,
    };
    this.props.googleLogin(body).then((data) => {
      localStorage.setItem("languagePopup", 1);
      localStorage.setItem("AuthToken", data.token);
      const { userRoles, isKym, LHFPO_IND } = data;
      localStorage.setItem(
        "userGroupName",
        userRoles?.userGroup?.userGroupName
      );
      localStorage.setItem("isKymUser", isKym == 1 ? true : false);
      localStorage.setItem("isLightHouse", LHFPO_IND ? LHFPO_IND : false);
      localStorage.setItem("userRoleName", userRoles?.userRoleName);
      localStorage.setItem("userName", data.userName);
      localStorage.getItem("userRoleName") == "Relationship Manager"
        ? history.push("/rm/home")
        : localStorage.getItem("userGroupName") == "fpo"
        ? history.push("/fpo/home")
        : history.push("/other/home");
      if (userRoles?.userGroup?.userGroupName == "fpo") {
        this.props.getAccessDetails();
      }
    });
  };

  responseGoogleFailure = (error) => {
    console.log(error);
  };

  toggleEye = () => {
    this.setState({
      mask: !this.state.mask,
    });
  };

  render() {
    let { emailOrMobNum, password, rememberme, loginLoader, mask } = this.state;
    return (
      <div className="w-100">
        <label className="font-weight-bold" htmlFor="mobileOrEmail">
          {"Email"}
        </label>
        <input
          type="text"
          className="form-control w-100 bg-light"
          id="mobileOrEmail"
          name="emailOrMobNum"
          value={emailOrMobNum}
          onChange={this.handleChange}
          onKeyUp={(e) => {
            if (e.keyCode == 13) {
              this.handleSubmit();
            }
          }}
        />
        {this.validator.message(
          "email or mobile number",
          emailOrMobNum,
          "required|email|min:7|max:50",
          { className: "text-danger" }
        )}
        <div className="mb-1 mt-3 position-relative">
          <label className="font-weight-bold mt-1" htmlFor="password">
            {"Password"}
          </label>
          <input
            type={mask ? "password" : "text"}
            className="form-control w-100 bg-light"
            id="password"
            name="password"
            value={password}
            style={{ paddingRight: "40px" }}
            onChange={this.handleChange}
            onKeyUp={(e) => {
              if (e.keyCode == 13) {
                this.handleSubmit();
              }
            }}
          />
          <div className="password-icon">
            <span
              onClick={() => this.toggleEye()}
              className={`cursor-pointer icon-${
                mask ? "eye-hide" : "eye"
              } fs-24`}
            ></span>
          </div>
          {this.validator.message("password", password, "required|min:7", {
            className: "text-danger",
          })}
        </div>
        <div className="d-flex justify-content-between mt-3 mb-4">
          <div className="custom-control custom-checkbox login-checkbox">
            <input
              type="checkbox"
              className="custom-control-input bg-dark"
              id="remember"
              checked={rememberme}
              onChange={() => this.toggle("rememberme")}
            />
            <label className="custom-control-label" htmlFor="remember">
              {"Remember me"}
            </label>
          </div>
          <div
            className="pass-login-link d-flex align-items-center"
            onClick={() => history.push("/login/forgot-pass")}
          >
            {"Forgot Password?"}
          </div>
        </div>
        <div className="d-flex justify-content-center mt-2">
          <button
            className="btn w-100 py-2 font-weight-bold mainbg-btn"
            disabled={loginLoader}
            onClick={this.handleSubmit}
          >
            Login with Email/Password
            {loginLoader ? (
              <div
                className="spinner-border ml-2 spinner-border-sm"
                role="status"
              >
                <span className="sr-only">Loading...</span>
              </div>
            ) : null}
          </button>
        </div>
        <div className="d-flex justify-content-center">
          <label className="my-2">or</label>
        </div>
        <div className="d-flex justify-content-center">
          <button
            className="btn w-100 py-2 font-weight-bold mainbg-btn outline-btn"
            onClick={this.props.togglePass}
          >
            Login with Mobile/OTP
          </button>
        </div>
        {/* <div>
          <p className="text-center fs-18 pt-3 ">
            {"Already a member? "}
            <b
              className="cursor-pointer"
              onClick={() => history.push("/login")}
            >
              <u>Log in</u>
            </b>
          </p>
        </div> */}
        <GoogleLogin
          className="btn-google w-100 d-flex justify-content-center my-4"
          clientId="1023428040603-1pca6qha0s3tckomh9arj1a1gj3ibund.apps.googleusercontent.com"
          buttonText="Login with Google"
          onSuccess={this.responseGoogle}
          onFailure={this.responseGoogleFailure}
        />
        {/* <div className="d-flex justify-content-center mt-2">
          <label>New to FPO Gateway?</label>
          <label className="ml-1">Register</label>
          <Link className="ml-1 text-dark font-weight-bold" to={"/register"}>
            <u> here</u>
          </Link>
        </div> */}
        {termsandPolicyLinks()}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      login,
      verifyPassword,
      getAccessDetails,
      googleLogin,
    },
    dispatch
  );
};

let component = PassAuthClass;

export const PassAuth = connect(null, mapDispatchToProps)(component);
