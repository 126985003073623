import React, { Component } from "react";
import { DetailView } from "../../component/fpo/SellRequest";
import { SearchView } from "component/Search";
import { Navbar } from "../../component/common";

export class SellRequestDetail extends Component {
  //lifecycles
  componentDidMount = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  render() {
    return (
      <div>
        <div className="inner-header">
          {/* <Navbar /> */}
          <div className="h-100 w-100">
            <p className="d-flex justify-content-center align-items-center h-100 w-100 text-white fs-32 font-weight-bold">
              Sell Request Details
            </p>
          </div>
        </div>
        <DetailView id={this.props.match.params.id} />
      </div>
    );
  }
}
