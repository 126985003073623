import { Compliance } from "service/apiVariables";
import { Toast } from "service/toast";
import { api } from "service/api";
import { addQuery } from "service/helperFunctions";
import { ComplianceType } from "service/actionType";
//Get License Dropdown List Items
export const getAllLicenseAndFinance = () => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    api({ ...Compliance.getAllLicenseAndFinance })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//Return Period Dropdown List Items
export const getAllMasters = () => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    api({ ...Compliance.getAllMasters })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//Get Financial Year Dropdown List Items
export const getAllFinancialYearDropdown = () => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    api({ ...Compliance.getAllFinancialYearDropdown })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//Get All License List
export const getAllLicense = (query) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    addQuery(query, Compliance.getAllLicense);
    api({ ...Compliance.getAllLicense })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//Get License Prefill by ID
export const getLicenseById = (id) => () => {
  Compliance.getLicenseById.id = id;
  return new Promise((resolve, reject) => {
    api({ ...Compliance.getLicenseById })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//get License By Id For View
export const getLicenseByIdForView = (id) => () => {
  Compliance.getLicenseByIdForView.id = id;
  return new Promise((resolve, reject) => {
    api({ ...Compliance.getLicenseByIdForView })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//Return Period Dropdown API
export const getMonthdata = (body) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    api({ ...Compliance.getMonthdata, body })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//Add License Api
export const addLicense = (body) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    api({ ...Compliance.addLicense, body })
      .then(({ data, message }) => {
        resolve(data);
        Toast({ type: "success", message, time: 5000 });
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//Update License API
export const updateLicense = (body) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    api({ ...Compliance.updateLicense, body })
      .then(({ data, message }) => {
        resolve(data);
        Toast({ type: "success", message, time: 5000 });
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//Delete License API
export const deleteLicense = (id) => (dispatch, getState) => {
  Compliance.deleteLicense.id = id;
  return new Promise((resolve, reject) => {
    api({ ...Compliance.deleteLicense })
      .then(({ data, message }) => {
        resolve(data);
        Toast({ type: "success", message, time: 5000 });
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//Get All Income TDS API
export const getAllIncomeTds = (query) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    addQuery(query, Compliance.getAllIncomeTds);
    api({ ...Compliance.getAllIncomeTds })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//Get Income TDS by ID API
export const getIncomeTdsById = (id) => (dispatch, getState) => {
  Compliance.getIncomeTdsById.id = id;
  return new Promise((resolve, reject) => {
    api({ ...Compliance.getIncomeTdsById })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//Add Income TDS Api
export const addIncomeTds = (body) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    api({ ...Compliance.addIncomeTds, body })
      .then(({ data, message }) => {
        resolve(data);
        Toast({ type: "success", message, time: 5000 });
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//Update Income TDS Api
export const updateIncomeTds = (body) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    api({ ...Compliance.updateIncomeTds, body })
      .then(({ data, message }) => {
        resolve(data);
        Toast({ type: "success", message, time: 5000 });
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//Delete Income TDS Api
export const deleteIncomeTds = (id) => (dispatch, getState) => {
  Compliance.deleteIncomeTds.id = id;
  return new Promise((resolve, reject) => {
    api({ ...Compliance.deleteIncomeTds })
      .then(({ data, message }) => {
        resolve(data);
        Toast({ type: "success", message, time: 5000 });
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//Get All Income Tax Api
export const getAllIncomeTax = (query) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    addQuery(query, Compliance.getAllIncomeTax);
    api({ ...Compliance.getAllIncomeTax })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//Get Income Tax by ID Api
export const getIncomeTaxById = (id) => (dispatch, getState) => {
  Compliance.getIncomeTaxById.id = id;
  return new Promise((resolve, reject) => {
    api({ ...Compliance.getIncomeTaxById })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//Add Income Tax API
export const addIncomeTax = (body) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    api({ ...Compliance.addIncomeTax, body })
      .then(({ data, message }) => {
        resolve(data);
        Toast({ type: "success", message, time: 5000 });
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//Update Income Tax API
export const updateIncomeTax = (body) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    api({ ...Compliance.updateIncomeTax, body })
      .then(({ data, message }) => {
        resolve(data);
        Toast({ type: "success", message, time: 5000 });
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//Delete Income Tax API
export const deleteIncomeTax = (id) => (dispatch, getState) => {
  Compliance.deleteIncomeTax.id = id;
  return new Promise((resolve, reject) => {
    api({ ...Compliance.deleteIncomeTax })
      .then(({ data, message }) => {
        resolve(data);
        Toast({ type: "success", message, time: 5000 });
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//Get all registrar API
export const getAllRegistrar = (query) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    addQuery(query, Compliance.getAllRegistrar);
    api({ ...Compliance.getAllRegistrar })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//Get Registrar by ID API
export const getRegistrarById = (id) => (dispatch, getState) => {
  Compliance.getRegistrarById.id = id;
  return new Promise((resolve, reject) => {
    api({ ...Compliance.getRegistrarById })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//Add Registrar Api
export const addRegistrar = (body) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    api({ ...Compliance.addRegistrar, body })
      .then(({ data, message }) => {
        resolve(data);
        Toast({ type: "success", message, time: 5000 });
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//Update Registrar API
export const updateRegistrar = (body) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    api({ ...Compliance.updateRegistrar, body })
      .then(({ data, message }) => {
        resolve(data);
        Toast({ type: "success", message, time: 5000 });
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//Delete Registrar Api
export const deleteRegistrar = (id) => (dispatch, getState) => {
  Compliance.deleteRegistrar.id = id;
  return new Promise((resolve, reject) => {
    api({ ...Compliance.deleteRegistrar })
      .then(({ data, message }) => {
        resolve(data);
        Toast({ type: "success", message, time: 5000 });
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//Get All GST API
export const getAllGst = (query) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    addQuery(query, Compliance.getAllGst);
    api({ ...Compliance.getAllGst })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//Get GST by ID Api
export const getGstById = (id) => (dispatch, getState) => {
  Compliance.getGstById.id = id;
  return new Promise((resolve, reject) => {
    api({ ...Compliance.getGstById })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//Add Gst Api
export const addGst = (body) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    api({ ...Compliance.addGst, body })
      .then(({ data, message }) => {
        resolve(data);
        Toast({ type: "success", message, time: 5000 });
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//Update GST Api
export const updateGst = (body) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    api({ ...Compliance.updateGst, body })
      .then(({ data, message }) => {
        resolve(data);
        Toast({ type: "success", message, time: 5000 });
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//Delete GST Api
export const deleteGst = (id) => (dispatch, getState) => {
  Compliance.deleteGst.id = id;
  return new Promise((resolve, reject) => {
    api({ ...Compliance.deleteGst })
      .then(({ data, message }) => {
        resolve(data);
        Toast({ type: "success", message, time: 5000 });
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//Get All GST Return Compliance API
export const getAllGstComp = (query) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    addQuery(query, Compliance.getAllGstComp);
    api({ ...Compliance.getAllGstComp })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//Get GST Return Compliance By Id API
export const getGstCompById = (id) => (dispatch, getState) => {
  Compliance.getGstCompById.id = id;
  return new Promise((resolve, reject) => {
    api({ ...Compliance.getGstCompById })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//Add GST Return Compliance API
export const addGstComp = (body) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    api({ ...Compliance.addGstComp, body })
      .then(({ data, message }) => {
        resolve(data);
        Toast({ type: "success", message, time: 5000 });
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//Update GST Return Compliance API
export const updateGstComp = (body) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    api({ ...Compliance.updateGstComp, body })
      .then(({ data, message }) => {
        resolve(data);
        Toast({ type: "success", message, time: 5000 });
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//Delete GST Return Compliance API
export const deleteGstComp = (id) => (dispatch, getState) => {
  Compliance.deleteGstComp.id = id;
  return new Promise((resolve, reject) => {
    api({ ...Compliance.deleteGstComp })
      .then(({ data, message }) => {
        resolve(data);
        Toast({ type: "success", message, time: 5000 });
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//Get all AGM API
export const getAllAgm = (query) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    addQuery(query, Compliance.getAllAgm);
    api({ ...Compliance.getAllAgm })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//Get AGM by ID API
export const getAgmById = (id) => (dispatch, getState) => {
  Compliance.getAgmById.id = id;
  return new Promise((resolve, reject) => {
    api({ ...Compliance.getAgmById })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//Add AGM API
export const addAgm = (body) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    api({ ...Compliance.addAgm, body })
      .then(({ data, message }) => {
        resolve(data);
        Toast({ type: "success", message, time: 5000 });
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//Update AGM API
export const updateAgm = (body) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    api({ ...Compliance.updateAgm, body })
      .then(({ data, message }) => {
        resolve(data);
        Toast({ type: "success", message, time: 5000 });
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//Delete AGM API
export const deleteAgm = (id) => (dispatch, getState) => {
  Compliance.deleteAgm.id = id;
  return new Promise((resolve, reject) => {
    api({ ...Compliance.deleteAgm })
      .then(({ data, message }) => {
        resolve(data);
        Toast({ type: "success", message, time: 5000 });
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//Get All BOD API
export const getAllBod = (query) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    addQuery(query, Compliance.getAllBod);
    api({ ...Compliance.getAllBod })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//Get BOD by ID API
export const getBodById = (id) => (dispatch, getState) => {
  Compliance.getBodById.id = id;
  return new Promise((resolve, reject) => {
    api({ ...Compliance.getBodById })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//Add BOD API
export const addBod = (body) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    api({ ...Compliance.addBod, body })
      .then(({ data, message }) => {
        resolve(data);
        Toast({ type: "success", message, time: 5000 });
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//Update BOD API
export const updateBod = (body) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    api({ ...Compliance.updateBod, body })
      .then(({ data, message }) => {
        resolve(data);
        Toast({ type: "success", message, time: 5000 });
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//Delete BOD API
export const deleteBod = (id) => (dispatch, getState) => {
  Compliance.deleteBod.id = id;
  return new Promise((resolve, reject) => {
    api({ ...Compliance.deleteBod })
      .then(({ data, message }) => {
        resolve(data);
        Toast({ type: "success", message, time: 5000 });
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

// downloadComplianceCsvForFPO
export const downloadComplianceCsvForFPO =
  (tabName) => (dispatch, getState) => {
    let { downloadComplianceCsvForFPO } = Compliance;
    downloadComplianceCsvForFPO.tabName = tabName;
    return new Promise((resolve, reject) => {
      api({ ...Compliance.downloadComplianceCsvForFPO })
        .then((data) => {
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

//get License Data
export const getLicenseData =
  () =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...Compliance.getLicenseData })
        .then((data) => {
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

//getLicenseDataWithId
export const getLicenseDataWithId =
  (id) =>
  (dispatch, getState, { api, Toast }) => {
    let { getLicenseDataWithId } = Compliance;
    getLicenseDataWithId.id = id;
    return new Promise((resolve, reject) => {
      api({ ...Compliance.getLicenseDataWithId })
        .then((data) => {
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

export const complianceCurrentTab = (payload) => (dispatch) => {
  dispatch({ type: ComplianceType.complianceCurrentTab, payload });
};
