import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";

import { NormalButton } from "component/common";

import classnames from "classnames";
import { history } from "service/helpers";

import filter from "assets/images/icon/filter.svg";
import downloadIcon from "assets/images/icon/download-icon.svg";
import member from "assets/images/members/member.svg";
import bod from "assets/images/members/bod.svg";
import staff from "assets/images/members/staff.svg";

import { CompilanceList } from "../CompilanceList";
import { GstList } from "../GstList";
import { IncometaxList } from "../IncometaxList";
import { RegisterList } from "../RegisterList";
import { GstReturnList } from "../GstReturnList";
import { BodList } from "../BodList";
import moment from "moment";
import { downloadcomplianceCsv } from "action/RmAct";
import { debounceFunction } from "service/debounce";

import {
  downloadComplianceCsvForFPO,
  getAllFinancialYearDropdown,
  getAllLicenseAndFinance,
} from "action/ComplianceAct";

import { allState } from "action/MemberAct";

import "./CompilanceView.scss";
import { getAccessdata } from "service/helperFunctions";
export class CompilanceViewClass extends Component {
  constructor() {
    super();
    this.state = {
      activeTab: "1",
      view: "1",
      taxActiveTab: "1",
      bodActiveTab: "1",
      searchValue: "",
      dropdownList: [],
      filteredName: "",
      filterBy: "",
      active: false,
      filterValue: "",
      finnacialYearList: [],
    };
  }

  handleInput = ({ target: { name, value } }) => {
    //Debounce Function
    debounceFunction(() => this.SearchFunction(value), 1000);
  };

  SearchFunction = (searchValue) => {
    //debounce function
    this.setState({ searchValue });
  };

  toggle = (tab) => {
    this.setState({
      filteredName: "",
      filterBy: "",
      filterValue: "",
      searchValue: "",
      activeTab: tab,
      taxActiveTab: "1",
      bodActiveTab: "1",
    });
  };

  componentDidMount = () => {
    let { complianceCurrentTab } = this.props;
    this.setState({ activeTab: complianceCurrentTab.toString() });
    this.props.getAllLicenseAndFinance().then((data) => {
      this.setState({
        dropdownList: data.license,
      });
    });
    this.props.getAllFinancialYearDropdown().then((data) => {
      this.setState({
        finnacialYearList: data.financialYearMaster,
      });
    });
  };

  toggleSub = (tab) => {
    let { activeTab } = this.state;
    activeTab === "3"
      ? this.setState({
          taxActiveTab: tab,
          filteredName: "",
          filterBy: "",
          filterValue: "",
          searchValue: "",
        })
      : this.setState({
          bodActiveTab: tab,
          filteredName: "",
          filterBy: "",
          filterValue: "",
          searchValue: "",
        });
  };

  handleAdd = () => {
    switch (this.state.activeTab) {
      case "1":
        history.push("/fpo/add-license");
        break;
      case "2":
        history.push("/fpo/add-gst");
        break;
      case "3":
        switch (this.state.taxActiveTab) {
          case "1":
            history.push("/fpo/add-incometds");
            break;
          case "2":
            history.push("/fpo/add-incometax");
            break;
        }
        break;
      case "4":
        history.push("/fpo/add-registrar");
        break;
      case "5":
        history.push("/fpo/add-gstreturn");
        break;
      case "6":
        switch (this.state.bodActiveTab) {
          case "1":
            history.push("/fpo/add-bod-compliance");
            break;
          case "2":
            history.push("/fpo/add-agm");
            break;
        }
        break;
      default:
        break;
    }
  };

  //open dropdown
  handleDropdown = () => {
    if (!this.state.active) {
      document.addEventListener("click", this.handleOutsideClick, false);
    } else {
      document.removeEventListener("click", this.handleOutsideClick, false);
    }
    this.setState((prevState) => ({
      active: !prevState.active,
    }));
  };

  //close popup when clicked outside
  handleOutsideClick = (e) => {
    if (this.node != null) {
      if (this.node.contains(e.target)) {
        return;
      }
    }
    this.handleDropdown();
  };

  //handle filter submit
  handleSubmit = (filterType, filterId, filteredName) => {
    this.setState({
      filteredName,
      filterBy: filterType,
      filterValue: filterId,
    });
  };

  //handle remove filter
  handleRemoveFilter = () => {
    this.setState({ filteredName: "", filterBy: "", filterValue: "" });
  };

  downloadCsv = () => {
    let { activeTab, taxActiveTab, bodActiveTab } = this.state;
    let { id, downloadcomplianceCsv, downloadComplianceCsvForFPO } = this.props;
    if (id) {
      if (activeTab == 1) {
        downloadcomplianceCsv("license", id).then((data) => {
          this.downloadFunction(data, "license");
        });
      } else if (activeTab == 2) {
        downloadcomplianceCsv("gstinfo", id).then((data) => {
          this.downloadFunction(data, "gst");
        });
      } else if (activeTab == 3) {
        if (taxActiveTab == 1) {
          downloadcomplianceCsv("incometds", id).then((data) => {
            this.downloadFunction(data, "incometax tds");
          });
        } else {
          downloadcomplianceCsv("incometax", id).then((data) => {
            this.downloadFunction(data, "incometax");
          });
        }
      } else if (activeTab == 4) {
        downloadcomplianceCsv("registrar", id).then((data) => {
          this.downloadFunction(data, "registrar");
        });
      } else if (activeTab == 5) {
        downloadcomplianceCsv("gstcomp", id).then((data) => {
          this.downloadFunction(data, "Gst return");
        });
      } else if (activeTab == 6) {
        if (bodActiveTab == 1) {
          downloadcomplianceCsv("bodinfo", id).then((data) => {
            this.downloadFunction(data, "bod meeting");
          });
        } else {
          downloadcomplianceCsv("agminfo", id).then((data) => {
            this.downloadFunction(data, "agm meeting");
          });
        }
      }
    } else {
      if (activeTab == 1) {
        downloadComplianceCsvForFPO("license/getcsv").then((data) => {
          this.downloadFunction(data, "license");
        });
      } else if (activeTab == 2) {
        downloadComplianceCsvForFPO("gstinfo").then((data) => {
          this.downloadFunction(data, "gst");
        });
      } else if (activeTab == 3) {
        if (taxActiveTab == 1) {
          downloadComplianceCsvForFPO("incometds").then((data) => {
            this.downloadFunction(data, "incometax tds");
          });
        } else {
          downloadComplianceCsvForFPO("incometax").then((data) => {
            this.downloadFunction(data, "incometax");
          });
        }
      } else if (activeTab == 4) {
        downloadComplianceCsvForFPO("registrar").then((data) => {
          this.downloadFunction(data, "registrar");
        });
      } else if (activeTab == 5) {
        downloadComplianceCsvForFPO("gstcomp").then((data) => {
          this.downloadFunction(data, "Gst return");
        });
      } else if (activeTab == 6) {
        if (bodActiveTab == 1) {
          downloadComplianceCsvForFPO("bodinfo").then((data) => {
            this.downloadFunction(data, "bod meeting");
          });
        } else {
          downloadComplianceCsvForFPO("agminfo").then((data) => {
            this.downloadFunction(data, "agm meeting");
          });
        }
      }
    }
  };

  downloadFunction(data, fileName) {
    var a = window.document.createElement("a");
    var blob = new Blob([data], {
      type: "text/csv;charset=utf-8;",
    });
    if (navigator.msSaveOrOpenBlob) {
      navigator.msSaveOrOpenBlob(
        blob,
        `${fileName} (${moment().format("DD/MM/YYYY hh:mm")}).csv`
      );
    } else {
      a.href = window.URL.createObjectURL(blob);
      // supply your own fileName here...
      a.download = `${fileName} (${moment().format("DD/MM/YYYY hh:mm")}).csv`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  }

  render() {
    let {
      activeTab,
      taxActiveTab,
      bodActiveTab,
      searchValue,
      dropdownList = [],
      filteredName,
      active,
      filterBy,
      filterValue,
      finnacialYearList,
    } = this.state;

    let { accessData } = this.props;
    let permissionData = getAccessdata(accessData, "complainceGovernance");

    let {
      download = false,
      edit = false,
      createAccess = false,
    } = permissionData;

    return (
      <>
        <section className="member-section bg-blue-f9">
          <div className="container-fluid">
            <div className="filter-top mb-3 ">
              <div className="compilance-filters my-4 container-sa">
                <div className="row align-items-end">
                  <div className="col-12">
                    <ul className="right-filter-align menu">
                      {activeTab !== "6" && activeTab !== "2" ? (
                        <li
                          className="cursor-pointer filter-dropdown"
                          onClick={this.handleDropdown}
                        >
                          <img src={filter} alt="filter" />
                          <span className="fs-16 font-Gilroy ml-2">Filter</span>
                          <div className={`dropdown ${active ? "active" : ""}`}>
                            <ul className="h-100 w-100 d-flex flex-column flex-wrap">
                              {activeTab === "1" ? (
                                <>
                                  <p>License</p>
                                  {filteredName !== "" ? (
                                    <li
                                      className="cursor-pointer text-left"
                                      onClick={() => {
                                        this.handleRemoveFilter();
                                      }}
                                    >
                                      {" None "}
                                    </li>
                                  ) : null}
                                  {dropdownList.map((list, index) =>
                                    filteredName === list.licenseName ? (
                                      <li
                                        className="cursor-pointer active text-left"
                                        key={index}
                                      >
                                        {list.licenseName}
                                      </li>
                                    ) : (
                                      <li
                                        className="cursor-pointer text-left"
                                        key={index}
                                        onClick={() => {
                                          this.handleSubmit(
                                            "licenseId",
                                            list.id,
                                            list.licenseName
                                          );
                                        }}
                                      >
                                        {list.licenseName}
                                      </li>
                                    )
                                  )}
                                </>
                              ) : activeTab !== "2" ? (
                                <>
                                  <p>Financial year</p>
                                  {finnacialYearList.map((list, index) =>
                                    filteredName === list.fymYear ? (
                                      <li
                                        className="cursor-pointer active text-left"
                                        key={index}
                                      >
                                        {list.fymYear}
                                      </li>
                                    ) : (
                                      <li
                                        className="cursor-pointer text-left"
                                        key={index}
                                        onClick={() => {
                                          this.handleSubmit(
                                            "fymId",
                                            list.fymId,
                                            list.fymYear
                                          );
                                        }}
                                      >
                                        {list.fymYear}
                                      </li>
                                    )
                                  )}
                                </>
                              ) : null}
                            </ul>
                          </div>
                        </li>
                      ) : null}
                      {download && (
                        <li className="pl-3">
                          <p
                            id="csv-download"
                            className="text-decoration-none cursor-pointer text-green-18 mb-0"
                            onClick={() => this.downloadCsv()}
                          >
                            <img
                              src={downloadIcon}
                              alt="downloadIcon"
                              className="mr-2"
                            />
                            {"Download list"}
                          </p>
                        </li>
                      )}
                      {createAccess && (
                        <div className=" pl-5">
                          <NormalButton
                            label="Add"
                            outline={true}
                            onClick={this.handleAdd}
                          />
                        </div>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="container-sa">
                <div className="row align-items-center compliance">
                  <div className="col-md-12 d-flex justify-content-center px-md-0">
                    <div className="scroll-x">
                      <Nav
                        tabs
                        className="tab-sammunaty"
                        style={{ width: "max-content" }}
                      >
                        <NavItem>
                          <NavLink
                            className={`${classnames({
                              active: activeTab === "1",
                            })} px-2`}
                            onClick={() => {
                              this.toggle("1");
                            }}
                          >
                            <img src={member} alt="member" /> Licence
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={`${classnames({
                              active: activeTab === "2",
                            })} px-2`}
                            onClick={() => {
                              this.toggle("2");
                            }}
                          >
                            <img src={bod} alt="bod" /> GST Registration
                            Certificate
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={`${classnames({
                              active: activeTab === "3",
                            })} px-2`}
                            onClick={() => {
                              this.toggle("3");
                            }}
                          >
                            <img src={staff} alt="staff" /> Income tax
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={`${classnames({
                              active: activeTab === "4",
                            })} px-2`}
                            onClick={() => {
                              this.toggle("4");
                            }}
                          >
                            <img src={staff} alt="staff" />
                            Registrar of Companies
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={`${classnames({
                              active: activeTab === "5",
                            })} px-2`}
                            onClick={() => {
                              this.toggle("5");
                            }}
                          >
                            <img src={staff} alt="staff" /> GST return
                            compliances
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={`${classnames({
                              active: activeTab === "6",
                            })} px-2`}
                            onClick={() => {
                              this.toggle("6");
                            }}
                          >
                            <img src={staff} alt="staff" /> BOD meeting & AGM
                          </NavLink>
                        </NavItem>
                      </Nav>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className=" bg-white pb-4 pt-1">
              <div className="container-sa">
                {activeTab != 3 && activeTab != 6 ? (
                  <div className="compilance-filters my-4">
                    <div className="row align-items-center">
                      <div className="col-sm-9 px-md-5">
                        <ul className="right-filter-align">
                          <li>
                            <span
                              className={`icon-list-view mr-3 pr-1 fs-20 cursor-pointer ${
                                this.state.view === "1"
                                  ? "view-active mr-3"
                                  : "view"
                              }`}
                              onClick={() => {
                                this.setState({
                                  view: "1",
                                });
                              }}
                            ></span>
                            <span
                              className={` icon-grid-view fs-20 cursor-pointer  ${
                                this.state.view === "2" ? "view-active" : "view"
                              }`}
                              onClick={() => {
                                this.setState({
                                  view: "2",
                                });
                              }}
                            ></span>
                          </li>
                        </ul>
                      </div>
                      <div className="col-sm-3">
                        <input
                          type-="text"
                          className="form-control form-control-sa"
                          placeholder="Search"
                          onChange={(e) => this.handleInput(e)}
                        />
                      </div>
                    </div>
                  </div>
                ) : activeTab == 3 ? (
                  <div className="incometax-tab mt-5">
                    <div className="d-flex justify-content-center">
                      <div className="scroll-x">
                        <Nav
                          tabs
                          className="tab-sammunaty"
                          style={{ width: "max-content" }}
                        >
                          <NavItem>
                            <NavLink
                              className={`${classnames({
                                active: taxActiveTab === "1",
                              })} p-0`}
                              onClick={() => {
                                this.toggleSub("1");
                              }}
                            >
                              <button
                                className={`btn ${
                                  taxActiveTab === "1" ? "active" : ""
                                }`}
                              >
                                Income TDS info
                              </button>
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              className={`${classnames({
                                active: taxActiveTab === "2",
                              })} p-0`}
                              onClick={() => {
                                this.toggleSub("2");
                              }}
                            >
                              <button
                                className={`btn ${
                                  taxActiveTab === "2" ? "active" : ""
                                }`}
                              >
                                Income tax info
                              </button>
                            </NavLink>
                          </NavItem>
                        </Nav>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="incometax-tab mt-5">
                    <div className="d-flex justify-content-center">
                      <div className="scroll-x">
                        <Nav
                          tabs
                          className="tab-sammunaty"
                          style={{ width: "max-content" }}
                        >
                          <NavItem>
                            <NavLink
                              className={`${classnames({
                                active: bodActiveTab === "1",
                              })} p-0`}
                              onClick={() => {
                                this.toggleSub("1");
                              }}
                            >
                              <button
                                className={`btn ${
                                  bodActiveTab === "1" ? "active" : ""
                                }`}
                              >
                                BOD meeting
                              </button>
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              className={`${classnames({
                                active: bodActiveTab === "2",
                              })} p-0`}
                              onClick={() => {
                                this.toggleSub("2");
                              }}
                            >
                              <button
                                className={`btn ${
                                  bodActiveTab === "2" ? "active" : ""
                                }`}
                              >
                                AGM
                              </button>
                            </NavLink>
                          </NavItem>
                        </Nav>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                  <CompilanceList
                    limit={4}
                    view={this.state.view}
                    activeTab={activeTab}
                    searchValue={searchValue}
                    filterBy={filterBy}
                    filterValue={filterValue}
                    editAccess={edit}
                    id={this.props.id}
                  />
                </TabPane>
                <TabPane tabId="2">
                  <GstList
                    limit={4}
                    view={this.state.view}
                    activeTab={activeTab}
                    searchValue={searchValue}
                    filterBy={filterBy}
                    filterValue={filterValue}
                    editAccess={edit}
                    id={this.props.id}
                  />
                </TabPane>
                <TabPane tabId="3">
                  <IncometaxList
                    limit={4}
                    view={this.state.view}
                    activeTab={taxActiveTab}
                    searchValue={searchValue}
                    mainactiveTab={activeTab}
                    filterBy={filterBy}
                    filterValue={filterValue}
                    editAccess={edit}
                    id={this.props.id}
                  />
                </TabPane>
                <TabPane tabId="4">
                  <RegisterList
                    limit={4}
                    view={this.state.view}
                    activeTab={activeTab}
                    searchValue={searchValue}
                    filterBy={filterBy}
                    filterValue={filterValue}
                    editAccess={edit}
                    id={this.props.id}
                  />
                </TabPane>
                <TabPane tabId="5">
                  <GstReturnList
                    limit={4}
                    view={this.state.view}
                    activeTab={activeTab}
                    searchValue={searchValue}
                    filterBy={filterBy}
                    filterValue={filterValue}
                    editAccess={edit}
                    id={this.props.id}
                  />
                </TabPane>
                <TabPane tabId="6">
                  <BodList
                    limit={4}
                    view={this.state.view}
                    activeTab={bodActiveTab}
                    searchValue={searchValue}
                    mainactiveTab={activeTab}
                    editAccess={edit}
                    id={this.props.id}
                  />
                </TabPane>
              </TabContent>
            </div>
          </div>
        </section>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  complianceCurrentTab: state.Compliance.tab,
  accessData: state.home.accessData,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      allState,
      getAllFinancialYearDropdown,
      downloadcomplianceCsv,
      downloadComplianceCsvForFPO,
      getAllLicenseAndFinance,
    },
    dispatch
  );
};
let component = CompilanceViewClass;

export const CompilanceView = connect(
  mapStateToProps,
  mapDispatchToProps
)(component);
