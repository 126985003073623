import React, { Component } from "react";
import SimpleReactValidator from "simple-react-validator";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { emailAndNumberValidation } from "action/RegAct";
import CheckBox from "component/common/CheckBox";
import { PasswordStrengthMeter, NormalDate } from "component/common";
import { Toast } from "service/toast";
import { getMarketCentreList } from "action/SellrequestAct";
export class FpoRegStepOneClass extends Component {
  constructor(props) {
    super(props);
    this.entityIdRef = React.createRef();
    this.fpo_nameRef = React.createRef();
    this.contact_first_nameRef = React.createRef();
    this.emailIdRef = React.createRef();
    this.contact_numberRef = React.createRef();
    this.villageRef = React.createRef();
    this.societyTypeRef = React.createRef();
    this.entity_valueRef = React.createRef();
    this.stateRef = React.createRef();
    this.districtRef = React.createRef();
    this.pincodeRef = React.createRef();
    this.foundedOnRef = React.createRef();
    this.passwordRef = React.createRef();
    this.confirm_passwordRef = React.createRef();
  }

  validator = new SimpleReactValidator({
    element: (message) => (
      <span className="error-message text-danger fs-14">{message}</span>
    ),
    autoForceUpdate: this,
    validators: {
      password: {
        message: "The :attribute must be a valid format.",
        rule: (val, params, validator) => {
          return (
            validator.helpers.testRegex(
              val,
              /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$^+=!*()@%&]).{8,20}$/
            ) && params.indexOf(val) === -1
          );
        },
        messageReplace: (message, params) =>
          message.replace("", this.helpers.toSentence(params)),
        required: true,
      },
      checkPassword: {
        message: "Given :attribute does not match",
        rule: function (val, params) {
          return val === params[0];
        },
      },
    },
  });

  //handle submit
  handleSubmit = (e) => {
    e.preventDefault();
    const { formFieldsFPO } = this.props;
    const {
      entityId,
      societyType,
      fpo_name,
      contact_first_name,
      emailId,
      contact_number,
      village,
      entity_value,
      state,
      district,
      pincode,
      foundedOn,
      password,
      confirm_password,
    } = formFieldsFPO;
    const mobileRegx = /[a-zA-Z]/.test(contact_number);
    const pinRegx = /[a-zA-Z]/.test(pincode);
    if (entityId === "") {
      this.entityIdRef.current.focus();
    } else if (fpo_name === "" || fpo_name?.length < 3) {
      this.fpo_nameRef.current.focus();
    } else if (contact_first_name === "" || contact_first_name?.length < 3) {
      this.contact_first_nameRef.current.focus();
    } else if (emailId === "") {
      this.emailIdRef.current.focus();
    } else if (
      contact_number === "" ||
      mobileRegx ||
      contact_number?.length !== 10
    ) {
      this.contact_numberRef.current.focus();
    } else if (village === "") {
      this.villageRef.current.focus();
    } else if (entityId === "2") {
      if (entity_value === "" || entity_value?.length !== 21) {
        this.entity_valueRef.current.focus();
      }
    } else if (entityId === "3") {
      if (entity_value === "") {
        this.entity_valueRef.current.focus();
      } else if (societyType === "") {
        this.societyTypeRef.current.focus();
      }
    } else if (entityId === "4") {
      if (entity_value === "") {
        this.entity_valueRef.current.focus();
      }
    } else if (state === "") {
      this.stateRef.current.focus();
    } else if (district === "") {
      this.districtRef.current.focus();
    } else if (pincode === "" || pinRegx || pincode?.length !== 6) {
      this.pincodeRef.current.focus();
    } else if (foundedOn === "") {
      this.foundedOnRef.current.focus();
    } else if (password === "") {
      this.passwordRef.current.focus();
    } else if (confirm_password === "" || password !== confirm_password) {
      this.confirm_passwordRef.current.focus();
    }
    if (entityId === "1") {
      this.validator.fields.entity_value = true;
      this.validator.errorMessages.entity_value = null;
      this.validator.fields.cin = true;
      this.validator.errorMessages.cin = null;
      this.validator.fields.cooperative_registration_number = true;
      this.validator.errorMessages.cooperative_registration_number = null;
      this.validator.fields.trust_registration_number = true;
      this.validator.errorMessages.trust_registration_number = null;
      this.validator.fields.societyType = true;
      this.validator.errorMessages.societyType = null;
    }
    if (entityId === "2") {
      this.validator.fields.cooperative_registration_number = true;
      this.validator.errorMessages.cooperative_registration_number = null;
      this.validator.fields.trust_registration_number = true;
      this.validator.errorMessages.trust_registration_number = null;
      this.validator.fields.societyType = true;
      this.validator.errorMessages.societyType = null;
    }
    if (entityId === "3") {
      this.validator.fields.cin = true;
      this.validator.errorMessages.cin = null;
      this.validator.fields.trust_registration_number = true;
      this.validator.errorMessages.trust_registration_number = null;
    }
    if (entityId === "4") {
      this.validator.fields.cin = true;
      this.validator.errorMessages.cin = null;
      this.validator.fields.cooperative_registration_number = true;
      this.validator.errorMessages.cooperative_registration_number = null;
      this.validator.fields.societyType = true;
      this.validator.errorMessages.societyType = null;
    }
    if (this.props.emailValidate) {
      this.emailIdRef.current.focus();
      return null;
    }
    if (this.props.numberValidate) {
      this.contact_numberRef.current.focus();
      return null;
    }
    if (this.props.CINValidate) {
      this.entity_valueRef.current.focus();
      return null;
    }
    if (this.props.entityValidate) {
      this.fpo_nameRef.current.focus();
      return null;
    }
    console.log("validator", this.validator);
    if (this.validator.allValid()) {
      if (this.props.formFieldsFPO.isPrivacy) {
        this.props.handlePageChange("step2");
      } else {
        Toast({
          type: "error",
          message: "Please Accept Terms & Conditions and Privacy Policy",
          time: 5000,
        });
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  render() {
    const {
      formFieldsFPO,
      handleChange,
      togglepass,
      stateList,
      entityList,
      districtOptions,
      pincodeOptions,
      societyTypeOptions,
    } = this.props;

    let {
      entityId,
      entity_value,
      societyType,
      fpo_name,
      contact_first_name,
      contact_last_name,
      contact_number,
      emailId,
      password,
      confirm_password,
      passwordVisible,
      reEnterPasswordVisible,
      district,
      pincode,
      state,
      foundedOn,
      village,
      isPrivacy,
    } = formFieldsFPO;

    return (
      <div>
        <form
          autocomplete="off"
          onSubmit={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <div className="row mb-3">
            <div className="col-12">
              <p className="text-black-1c fw-600 mb-0 text-center fs-18">
                Step 1 of 3
              </p>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-12 mb-3">
              <label>
                {"Entity Type"}
                <span className="text-danger font-weight-bold">*</span>
              </label>
              <select
                className="custom-select form-control"
                name="entityId"
                value={entityId}
                onChange={handleChange}
                ref={this.entityIdRef}
              >
                <option value="" disabled defaultValue>
                  {"Select"}
                </option>
                {entityList.map((list, index) => (
                  <option key={index} value={list.value}>
                    {list.label}
                  </option>
                ))}
              </select>
              {this.validator.message("entityId", entityId, "required")}
            </div>
            {entityId === "3" ? (
              <div className="col-12 mb-3">
                <label>
                  {"Cooperative society type"}
                  <span className="text-danger font-weight-bold">*</span>
                </label>
                <select
                  className="custom-select form-control"
                  name="societyType"
                  value={societyType}
                  onChange={handleChange}
                  ref={this.societyTypeRef}
                >
                  <option value="" disabled defaultValue>
                    {"Select"}
                  </option>
                  {societyTypeOptions.map((list, index) => (
                    <option key={index} value={list.value}>
                      {list.label}
                    </option>
                  ))}
                </select>
                {this.validator.message("societyType", societyType, "required")}
              </div>
            ) : null}
            {entityId === "2" || entityId === "3" || entityId === "4" ? (
              <div className="col-12 mb-3">
                <label>
                  {(entityId === "2" && "CIN") ||
                    (entityId === "3" && "Cooperative Registration Number") ||
                    (entityId === "4" && "Trust Registration Number")}
                  <span className="text-danger font-weight-bold">*</span>
                </label>
                <input
                  type="text"
                  className="form-control w-100"
                  name="entity_value"
                  value={entity_value}
                  onChange={handleChange}
                  maxLength={
                    entityId === "2"
                      ? 21
                      : entityId === "3" || entityId === "4"
                      ? null
                      : null
                  }
                  ref={this.entity_valueRef}
                />
                {this.props.CINValidate && (
                  <span className="error-message text-danger fs-14">
                    {entityId === "2"
                      ? "This CIN is already registered. Please try with some other CIN."
                      : entityId === "3"
                      ? "This CRN is already registered. Please try with some other CRN."
                      : entityId === "4"
                      ? "This TRN is already registered. Please try with some other TRN."
                      : null}
                  </span>
                )}
                {entityId !== "1"
                  ? this.validator.message(
                      entityId === "2"
                        ? "cin"
                        : entityId === "3"
                        ? "cooperative_registration_number"
                        : entityId === "4"
                        ? "trust_registration_number"
                        : null,
                      entity_value,
                      entityId === "2"
                        ? "required|alpha_num|min:21|max:21"
                        : entityId === "3"
                        ? "required"
                        : entityId === "4"
                        ? "required|alpha_num"
                        : null
                    )
                  : null}
              </div>
            ) : null}
            <div className="col-12">
              <label>
                Entity name
                <span className="text-danger font-weight-bold">*</span>
              </label>
              <input
                type="text"
                className="form-control w-100"
                name="fpo_name"
                value={fpo_name}
                onChange={handleChange}
                ref={this.fpo_nameRef}
              />
              {this.props.entityValidate && (
                <span className="error-message text-danger fs-14">
                  This Entity name is already registered. Please try with some
                  other Entity name.
                </span>
              )}
              {this.validator.message(
                "entity_name",
                fpo_name,
                "required|alpha_num_space|min:3|max:75"
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-12 d-flex flex-column mb-3">
              <label>
                {"Founded on"}
                <span className="text-danger font-weight-bold">*</span>
              </label>
              <NormalDate
                isIcon={true}
                value={foundedOn}
                name="foundedOn"
                placeholder="DD/MM/YYYY"
                onChange={handleChange}
                mindate={false}
                maxdate={true}
                timeInput={false}
                isregPage={true}
                ref={this.foundedOnRef}
              />
              {this.validator.message("founded On", foundedOn, "required")}
            </div>
            <div className="col-md-6 col-12 mb-3">
              <label>
                Contact Person name
                <span className="text-danger font-weight-bold">*</span>
              </label>
              <input
                type="text"
                className="form-control w-100"
                placeholder="Full name"
                name="contact_first_name"
                value={contact_first_name}
                onChange={handleChange}
                autoComplete="nope"
                ref={this.contact_first_nameRef}
              />
              {this.validator.message(
                "contact_first_name",
                contact_first_name,
                "required|alpha_space|min:3|max:30"
              )}
            </div>
            {/* <div className="col-md-6 col-12 mb-3">
              <input
                type="text"
                className="form-control w-100"
                placeholder="Last name"
                name="contact_last_name"
                value={contact_last_name}
                onChange={handleChange}
                autoComplete="nope"
              />
            </div> */}
          </div>
          <div className="row">
            <div className="col-md-6 col-12 mb-3">
              <label>
                Email id
                <span className="text-danger font-weight-bold">*</span>
              </label>
              <input
                type="text"
                className="form-control w-100"
                name="emailId"
                value={emailId}
                onChange={handleChange}
                ref={this.emailIdRef}
              />
              {this.props.emailValidate && (
                <span className="error-message text-danger fs-14">
                  This Email ID is already registered. Please try with some
                  other Email ID.
                </span>
              )}
              {this.validator.message("emailId", emailId, "required|email")}
            </div>
            <div className="col-md-6 col-12 mb-3">
              <label>
                Contact number
                <span className="text-danger font-weight-bold">*</span>
              </label>
              <input
                type="text"
                className="form-control w-100"
                name="contact_number"
                value={contact_number}
                onChange={handleChange}
                maxLength={10}
                ref={this.contact_numberRef}
              />
              {this.props.numberValidate && (
                <span className="error-message text-danger fs-14">
                  This Contact number is already registered. Please try with
                  some other Contact number.
                </span>
              )}
              {this.validator.message(
                "contact_number",
                contact_number,
                "required|numeric|min:10|max:10"
              )}
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-md-6 col-12">
              <label>
                Village
                <span className="text-danger font-weight-bold">*</span>
              </label>
              <input
                type="text"
                className="form-control w-100"
                name="village"
                value={village}
                onChange={handleChange}
                ref={this.villageRef}
              />
              {this.validator.message("village", village, "required|max:75")}
            </div>
            <div className="col-md-6 col-12">
              <label>
                {"State/UT"}
                <span className="text-danger font-weight-bold">*</span>
              </label>
              <select
                className="custom-select form-control"
                name="state"
                value={state}
                onChange={handleChange}
                ref={this.stateRef}
              >
                <option value="" disabled defaultValue>
                  {"Select"}
                </option>
                {stateList.map((list, index) => (
                  <option key={index} value={list.value}>
                    {list.label}
                  </option>
                ))}
              </select>
              {this.validator.message("state", state, "required")}
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-12 mb-3">
              <label>
                {"District"}
                <span className="text-danger font-weight-bold">*</span>
              </label>
              <select
                className="custom-select form-control"
                name="district"
                value={district}
                onChange={handleChange}
                disabled={state == "" ? true : false}
                ref={this.districtRef}
              >
                <option value="" disabled defaultValue>
                  {"Select"}
                </option>
                {districtOptions.map((list, index) => (
                  <option key={index} value={list.value}>
                    {list.label}
                  </option>
                ))}
              </select>
              {this.validator.message("district", district, "required")}
            </div>
            <div className="col-md-6 col-12 mb-3">
              <label>
                Pincode
                <span className="text-danger font-weight-bold">*</span>
              </label>
              <input
                type="text"
                className="form-control w-100"
                name="pincode"
                value={pincode}
                onChange={handleChange}
                maxLength={6}
                ref={this.pincodeRef}
              />
              {this.validator.message(
                "pincode",
                pincode,
                "required|numeric|min:6"
              )}
            </div>
          </div>
          <div className="row">
            {/* <div className="col-md-6 col-12 mb-3">
              <label>
                {"Pincode "}
                <span className="text-danger font-weight-bold">*</span>
              </label>
              <select
                className="custom-select form-control"
                name="pincode"
                value={pincode}
                onChange={handleChange}
                disabled={state == "" || district == "" ? true : false}
              >
                <option value="" disabled defaultValue>
                  {"Select"}
                </option>
                {pincodeOptions.map((list, index) => (
                  <option key={index} value={list.value}>
                    {list.label}
                  </option>
                ))}
              </select>
              {this.validator.message("pincode", pincode, "required")}
            </div> */}
          </div>
          <div className="row">
            <div className="col-md-6 col-12">
              <label>
                Password
                <span className="text-danger font-weight-bold">*</span>
              </label>
              <div className="input-group">
                <input
                  type={reEnterPasswordVisible ? "text" : "password"}
                  className="form-control w-100"
                  name="password"
                  value={password}
                  onChange={handleChange}
                  autoComplete={
                    reEnterPasswordVisible ? "nope" : "new-password"
                  }
                  ref={this.passwordRef}
                />
                <div className="input-group-addon right">
                  <span
                    onClick={() => togglepass("reEnterPasswordVisible")}
                    className={`cursor-pointer icon-${
                      reEnterPasswordVisible ? "eye" : "eye-hide"
                    } fs-24`}
                  ></span>
                </div>
                {this.validator.message(
                  "password",
                  password,
                  "required|min:7|max:15|password"
                )}
                <PasswordStrengthMeter data={password} />
              </div>
            </div>
            <div className="col-md-6 col-12">
              <label>
                Confirm password
                <span className="text-danger font-weight-bold">*</span>
              </label>
              <div className="input-group">
                <input
                  type={passwordVisible ? "text" : "password"}
                  className="form-control w-100"
                  name="confirm_password"
                  value={confirm_password}
                  onChange={handleChange}
                  autoComplete={passwordVisible ? "nope" : "new-password"}
                  ref={this.confirm_passwordRef}
                />
                <div className="input-group-addon right">
                  <span
                    onClick={() => togglepass("passwordVisible")}
                    className={`cursor-pointer icon-${
                      passwordVisible ? "eye" : "eye-hide"
                    } fs-24`}
                  ></span>
                </div>
                {this.validator.message(
                  "confirm password",
                  confirm_password,
                  `required|checkPassword:${password}`
                )}
              </div>
            </div>
            {password && (
              <div className="col-md-12">
                <b className="fs-14">
                  Only the following special characters are allowed :
                  #$^+=!*()@%&amp;
                </b>
              </div>
            )}
          </div>
          <div className="d-flex justify-content-center mt-4">
            <CheckBox
              label="I agree to the Terms & Conditions and Privacy Policy"
              onChange={handleChange}
              name="isPrivacy"
              isChecked={isPrivacy}
              needLine={true}
              hover={false}
            />
          </div>
          <div className="d-flex justify-content-center mt-4">
            <input
              className="btn w-75 py-2 font-weight-bold"
              type="submit"
              value="Next"
              onClick={this.handleSubmit}
            />
          </div>
        </form>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ emailAndNumberValidation }, dispatch);
};
let component = FpoRegStepOneClass;

export const FpoRegStepOne = connect(null, mapDispatchToProps)(component);
