import React, { Component } from "react";
import Moment from "react-moment";
import { getDescriptionData } from "service/helperFunctions";
import { history } from "service/helpers";
import defaultEventImage from "assets/images/DefaultEvent.jpg";
export default class Card extends Component {
  state = {
    active: false,
  };
  handleDropdown = () => {
    if (!this.state.active) {
      document.addEventListener("click", this.handleOutsideClick, false);
    } else {
      document.removeEventListener("click", this.handleOutsideClick, false);
    }
    this.setState((prevState) => ({
      active: !prevState.active,
    }));
  };

  handleOutsideClick = (e) => {
    if (this.node != null) {
      if (this.node.contains(e.target)) {
        return;
      }
    }
    this.handleDropdown();
  };
  render() {
    let {
      list,
      handleDelete,
      getEventId,
      editAccess = false,
      viewAccess = false,
      deleteAccess = false,
    } = this.props;
    let {
      eventDate,
      description,
      agendaName,
      eventId,
      eventDocs,
      location,
      eventTitle,
    } = list;
    const { active } = this.state;
    return (
      <div className="card your-events-card shadow">
        {eventDocs.length !== 0 &&
        !eventDocs[0].documentUrl.includes(".pdf") ? (
          <img
            className="card-img-top"
            src={eventDocs[0].documentUrl}
            alt={eventDocs[0].fileName}
          />
        ) : (
          <img className="card-img-top" src={defaultEventImage} alt="events" />
        )}
        <div className="card-body d-flex flex-column justify-content-between p-3">
          <div className="">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <div>
                  <h6 className="fs-14 text-black-1c mb-0">Event date</h6>
                </div>
                <div className="d-flex align-items-center">
                  <div className="your-events-card-date mr-2">
                    <Moment format="DD">{eventDate}</Moment>
                  </div>
                  <div className="your-events-card-month">
                    <Moment format="MMMM YYYY">{eventDate}</Moment>
                  </div>
                </div>
              </div>
              {editAccess || deleteAccess ? (
                <div>
                  <div className="position-relative">
                    <i
                      className="icon-horizontal-dots fs-20 cursor-pointer"
                      onClick={this.handleDropdown}
                    ></i>
                    <div
                      className={`${
                        active ? "view-more-option d-block" : "d-none"
                      }`}
                    >
                      <ul>
                        {editAccess && (
                          <li onClick={() => getEventId(eventId, "event")}>
                            {"Edit"}
                          </li>
                        )}
                        {deleteAccess && (
                          <li onClick={() => handleDelete(eventId)}>
                            {"Delete"}
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
            <div className="your-events-card-heading fw-500">{agendaName}</div>
            <div className="text-green-18 font-weight-bold fs-19">
              {eventTitle !== null ? getDescriptionData(eventTitle, 20) : ""}
            </div>
            <div
              className="text-wrap your-events-card-body"
              dangerouslySetInnerHTML={{
                __html:
                  description !== "" ? getDescriptionData(description, 35) : "",
              }}
            ></div>
            <div className="mb-1">
              <div>
                <h6 className="fs-14 line-height-24 text-black-1c mb-0">
                  Event location
                </h6>
              </div>
              <div className="d-flex pr-3">
                <p className="fs-18 mb-0 pr-2">
                  <i className="icon-location-outline text-green-18"></i>
                </p>
                <p className="fs-14 line-height-24 text-black-1c mb-0">
                  {location !== "" ? getDescriptionData(location, 15) : "-"}
                </p>
              </div>
            </div>
          </div>
          {viewAccess && (
            <div
              className="mx-auto text-green-18 font-weight-bold cursor-pointer fs-16"
              onClick={() => history.push(`/fpo/viewDetails/events&${eventId}`)}
            >
              {"View more"}
            </div>
          )}
        </div>
      </div>
    );
  }
}
