import { NormalButton } from 'component/common';
import React, { Component } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import { FieldsTemplate } from '../common/FieldsTemplate';

export const OutputMarketing = (props) => {
  const [, forceUpdate] = React.useState();
  const [outputMarketingInfo, setOutputMarketingInfo] = React.useState({
  });
  const [outputMarketingAttr, setOutputMarketingInfoAttr] = React.useState([
    {
      label: "Output collection centers", field: "radioGroup", type: "number", name: "opcollcentersdist", note: "Please consider the point of Aggregation.", required: true,
      list: [
        { label: "Available within 3KM for majority of farmers", value: "within3km" },
        { label: "Available within 5KM for majority of farmers", value: "within5km" },
        { label: "Available within 10KM for majority of farmers", value: "within10km" },
        { label: "Available within 15KM for majority of farmers", value: "within15km" },
        { label: "No output collection centers", value: "nooutputcenter" },
      ]
    },
    {
      label: "Whether there is facility to procure produce directly from farm?", field: "radioGroup", type: "number", name: "directprocure", required: true,
      list: [
        { label: "Yes", value: "yes" },
        { label: "No", value: "no" },
      ]
    },

    { label: "Names of Institutional Buyers associated with FPO", field: "input", type: "text", name: "nameofbuyfpo", required: true },
    { label: "Number of Institutional Buyers associated with FPO", field: "input", type: "number", name: "noofinstbuyers", required: true },

    {
      label: "Reason for less number of institutional buyers?",
      field: "radioGroup", type: "number", name: "rsnlowinstbuyr", required: false,
      note: "Make a selection only if number of institutional buyers is less than 2",
      conditions: { attribute: "noofinstbuyers", values: [2], action: "show", criteria: "lt" },
      list: [
        { value: "lowaggreg", label: "Low volume of yield aggregation" },
        { value: "qualnotmet", label: "Inability to meet quality requirements" },
        { value: "nomarklinks", label: "Lack of market linkage" },
        { value: "mgmtnointerest", label: "Lack of interest from FPO management" }
      ]
    },

    { label: "Volume of Output Produce procured by the FPO form the Members (in tonnes)", field: "input", type: "number", name: "outputvolprocured", required: true },
    { label: "What is the average difference (%) between the market price and FPO's procurement price?", field: "input", type: "number", name: "avgoutputpricediff", note: "Example: if value is 55.5 % enter 55.5", required: true },
    { label: "How long you have been associated with a major buyer? (in years)", field: "input", type: "number", name: "assocwithbuyer", required: true },

    {
      label: "How many agreements you have signed with Government or any Private institutions and those are working in the ground?", field: "radioGroup", type: "number", name: "noofagreements", note: "Eg: MSP procurement, Contract Farming, Seed village", required: true,
      list: [
        { label: "More than 5", value: "morethan5" },
        { label: "3-5", value: "3to5" },
        { label: "2", value: "two" },
        { label: "Single", value: "single" },
        { label: "Not at all", value: "notatall" },
      ]
    },

    {
      label: "Do you have received grant/investment from the Government side?", field: "radioGroup", type: "number", name: "govtinvestrecvd", required: true,
      list: [
        { label: "Yes, more than 10 lakhs", value: "govmore10lakhs" },
        { label: "Yes, 5-10 lakhs", value: "goclakh5to10" },
        { label: "Yes,1-5 lakhs", value: "govlakh1to5" },
        { label: "Yes, less than 1 lakhs", value: "govbelow1lakh" },
        { label: "Nil", value: "govnil" },
      ]
    },

    {
      label: "Reason for no grant/investment/support from Government schemes?", field: "radioGroup", type: "number", name: "rsnnogovtsche", required: true,
      conditions: { attribute: "govtinvestrecvd", values: ["govnil"], action: "show" },
      list: [
        { label: "Unaware of government schemes", value: "donnogovtsche" },
        { label: "Unable to make the application due to difficult process", value: "donnohowto" },
        { label: "Application made but no response", value: "applnorespons" },
        { label: "Issues with release of funds from govt. department", value: "issueswfundrel" },
      ]
    },

    {
      label: "Is there any private investment in FPC or in promoting business related to FPC other than shareholding by BoDs or other members?", field: "radioGroup", type: "number", name: "pvtinvestrecvd", required: true,
      list: [
        { label: "Yes, more than 10 lakhs", value: "morethan10lakhs" },
        { label: "Yes, 5-10 lakhs", value: "5to10lakhs" },
        { label: "Yes,1-5 lakhs", value: "1to5lakhs" },
        { label: "Yes, less than 1 lakhs", value: "lessthan1lakh" },
        { label: "Nil", value: "nil" },
      ]
    },

    {
      label: "Has FPC invested on equipment for the Value Addition?", field: "radioGroup", type: "number", name: "investedoneqpt", required: true,
      list: [
        { label: "Yes, more than 10 lakhs", value: "morethan10lakhs" },
        { label: "Yes, 5-10 lakhs", value: "5to10lakhs" },
        { label: "Yes,1-5 lakhs", value: "1to5lakhs" },
        { label: "Yes, less than 1 lakhs", value: "lessthan1lakh" },
        { label: "Nil", value: "nil" },
      ]
    },

  ]);

  //validation
  const simpleValidator = React.useRef(new SimpleReactValidator({
    element: (message) => (
      <span className="error-message text-danger fs-14">{message}</span>
    ),
    autoForceUpdate: this
  }));
  const handleSubmit = (status) => {
    const formValid = simpleValidator.current.allValid();
    if (formValid) {
      let payload = { ...outputMarketingInfo, status };
      if(outputMarketingInfo.assocwithbuyer){
        payload.assocwithbuyer = parseFloat(outputMarketingInfo.assocwithbuyer);
      }
      props.updateSurvey("outputMarketingInfo", payload);

    } else {
      simpleValidator.current.showMessages()
      forceUpdate(1);
      props.errorAccordion();
    }
  }
  const handleInput = ({ target: { name, value } }) => {
    setOutputMarketingInfo({
      ...outputMarketingInfo,
      [name]: value,
    });
  };
  const handleCheckbox = (name, value) => {
    let list = [...outputMarketingInfo[name]];
    let index = list.indexOf(value);
    if (index >= 0) {
      list.splice(index, 1);
    } else {
      list.push(value);
    }
    setOutputMarketingInfo({
      ...outputMarketingInfo,
      [name]: list,
    });
  };

  const handleRadio = (name, value) => {
    setOutputMarketingInfo({
      ...outputMarketingInfo,
      [name]: value,
    });
  };

  React.useEffect(() => {
    if (props?.surveyDetails) {
      let _outputMarketingInfo = {
        "opcollcentersdist": props?.surveyDetails.opcollcentersdist,
        "directprocure": props?.surveyDetails.directprocure,
        "nameofbuyfpo": props?.surveyDetails.nameofbuyfpo,
        "noofinstbuyers": props?.surveyDetails.noofinstbuyers,
        "outputvolprocured": props?.surveyDetails.outputvolprocured,
        "avgoutputpricediff": props?.surveyDetails.avgoutputpricediff,
        "assocwithbuyer": props?.surveyDetails.assocwithbuyer,
        "noofagreements": props?.surveyDetails.noofagreements,
        "govtinvestrecvd": props?.surveyDetails.govtinvestrecvd,
        "rsnnogovtsche": props?.surveyDetails.rsnnogovtsche,
        "pvtinvestrecvd": props?.surveyDetails.pvtinvestrecvd,
        "investedoneqpt": props?.surveyDetails.investedoneqpt,
      }

      setOutputMarketingInfo({
        ...outputMarketingInfo,
        ..._outputMarketingInfo
      });
    }
  }, [props?.surveyDetails]);

  React.useEffect(() => {
    if (props?.submitSurveyClick) {
      handleSubmit('completed');
    }
  }, [props?.submitSurveyClick]);

  return (
    <div >
      {/* <h5><b>Basic details of FPO</b></h5> */}
      <div className="container-sa position-relative" >

        <FieldsTemplate
          attributes={outputMarketingAttr}
          simpleValidator={simpleValidator}
          stateAttr={outputMarketingInfo}
          handleInput={handleInput}
          handleRadio={handleRadio}
          handleCheckbox={handleCheckbox}
        />

        <div className="row mt-3">
          <div className="col-md-5 col-sm-12  my-1">
            <NormalButton
              label="Save & Next"
              mainbg={true}
              className="px-3 py-2 mx-auto fs-14 line-height-26 font-Gilroy saveNext"
              onClick={() => handleSubmit('draft')}
              isPopup={true}
            />
          </div>
        </div>
      </div>
    </div>
  )
}