import { Association } from 'service/apiVariables';
import { Toast } from 'service/toast';
import { api } from 'service/api';
import { addQuery } from 'service/helperFunctions';

//Get all Associations and Partnership List
export const getAllAssociations = (query) => (dispatch, getState) => {
   return new Promise((resolve, reject) => {
      addQuery(query, Association.getAllAssociations);
      api({ ...Association.getAllAssociations })
         .then(({ data, message }) => {
            resolve(data);
         })
         .catch(({ message }) => {
            reject(Toast({ type: 'error', message }));
         });
   });
};

//Get Association and Partnership Prefill by ID
export const getAssociationById = (id) => (dispatch, getState) => {
   Association.getAssociationById.id = id;
   return new Promise((resolve, reject) => {
      api({ ...Association.getAssociationById })
         .then(({ data }) => {
            resolve(data);
         })
         .catch(({ message }) => {
            reject(Toast({ type: 'error', message }));
         });
   });
};

//Add Associations and Partnership
export const addAssociation = (body) => (dispatch, getState) => {
   return new Promise((resolve, reject) => {
      api({ ...Association.addAssociation, body })
         .then(({ data, message }) => {
            resolve(data);
            Toast({ type: 'success', message, time: 5000 });
         })
         .catch(({ message }) => {
            reject(Toast({ type: 'error', message }));
         });
   });
};

//Update Association and Partnership
export const updateAssociation = (body) => (dispatch, getState) => {
   return new Promise((resolve, reject) => {
      api({ ...Association.updateAssociation, body })
         .then(({ data, message }) => {
            resolve(data);
            Toast({ type: 'success', message, time: 5000 });
         })
         .catch(({ message }) => {
            reject(Toast({ type: 'error', message }));
         });
   });
};

//Delete Association and Partnership
export const deleteAssociation = (id) => (dispatch, getState) => {
   Association.deleteAssociation.id = id;
   return new Promise((resolve, reject) => {
      api({ ...Association.deleteAssociation })
         .then(({ data, message }) => {
            resolve(data);
            Toast({ type: 'success', message, time: 5000 });
         })
         .catch(({ message }) => {
            reject(Toast({ type: 'error', message }));
         });
   });
};

//Get all Institution Name Dropdown Items
export const getAllInstitutiontypes = (query) => (dispatch, getState) => {
   return new Promise((resolve, reject) => {
      addQuery(query, Association.getAllInstitutiontypes);
      api({ ...Association.getAllInstitutiontypes })
         .then(({ data }) => {
            resolve(data);
         })
         .catch(({ message }) => {
            reject(Toast({ type: 'error', message }));
         });
   });
};

//Get all Association Types Dropdown Items
export const getAllAssociationtypes = (query) => (dispatch, getState) => {
   return new Promise((resolve, reject) => {
      addQuery(query, Association.getAllAssociationtypes);
      api({ ...Association.getAllAssociationtypes })
         .then(({ data }) => {
            resolve(data);
         })
         .catch(({ message }) => {
            reject(Toast({ type: 'error', message }));
         });
   });
};

//Get Download list URL for Associations and Partnership
export const getAssociationCsv = () => (dispatch, getState) => {
   return new Promise((resolve, reject) => {
      api({ ...Association.getAssociationCsv })
         .then((data) => {
            resolve(data);
         })
         .catch(({ message }) => {
            reject(Toast({ type: 'error', message }));
         });
   });
};
