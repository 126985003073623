import { NormalButton, NormalInput } from 'component/common';
import React, { Component, useState } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import { FieldsTemplate } from '../common/FieldsTemplate';

export const TechnologyAndServices = (props) => {
	const [formData, setFormData] = useState({});
	const [, forceUpdate] = React.useState();

	const formAttr = [
		{
			label: "Is any technology leveraged for enhancing the operations and governance of the FPO?",
			field: "radioGroup", type: "number", name: "techforgov", required: true,
			list: [
				{ value: "proactively", label: "FPO proactively uses technology in operations/governance and is looking forward for further advancement as well as other areas to embed technology" },
				{ value: "limiteduse", label: "FPO currently uses limited technology but is open to exploring technology as a means of improving efficiency in operations and governance" },
				{ value: "almostnil", label: "Use of technology is almost nil. Embedding technology into operations/governance is not even considered due to low awareness or willingness" }
			]
		},
		{
			label: "MIS (Management Information System)",
			field: "radioGroup", type: "number", name: "mistype", required: true,
			list: [
				{ value: "ownmis", label: "FPO has own MIS" },
				{ value: "hiredmis", label: "FPO has hired MIS" },
				{ value: "planning", label: "FPO Planning to implement MIS" },
				{ value: "noplans", label: "FPO has no plans to implement MIS" }
			]
		},
		{
			label: "Does FPO train the members with Packages of Practices", field: "radioGroup", type: "number", name: "poptraining", required: true,
			list: [
				{ value: "yes", label: "Yes" },
				{ value: "no", label: "No" }
			]
		},
		{ label: "Percentage of members following Package of practices?", field: "input", type: "number", name: "mempercentpop", required: true, placeholder: "", note: "Example: if value is 55.5 % enter 55.5" },
		{
			label: "Is there any activities the FPO did as Innovative?", field: "radioGroup", type: "number", name: "innovativeacts", required: true,
			list: [
				{ value: "innovativeactyes", label: "Yes" },
				{ value: "innovativeactno", label: "No" }
			]
		},
		{
			label: "If Yes, Mention the activities", field: "input", type: "text", name: "innovactivities", required: true, placeholder: "",
			conditions: { attribute: "innovativeacts", values: ["innovativeactyes"], action: "show" },
		},
	]

	React.useEffect(() => {
		if (props?.surveyDetails) {
			let _formData = {
				techforgov: props?.surveyDetails.techforgov,
				mistype: props?.surveyDetails.mistype,
				poptraining: props?.surveyDetails.poptraining,
				mempercentpop: props?.surveyDetails.mempercentpop,
				innovativeacts: props?.surveyDetails.innovativeacts,
				innovactivities: props?.surveyDetails.innovactivities
			};

			setFormData({
				...formData,
				..._formData
			});
		}
	}, [props?.surveyDetails]);

	React.useEffect(() => {
		if (props?.submitSurveyClick) {
			handleSubmit('completed');
		}
	}, [props?.submitSurveyClick]);

	const handleInput = ({ target: { name, value } }) => {
		setFormData((preVal) => {
			return {
				...preVal,
				[name]: value,
			}
		})
	};

	const handleRadio = (name, value) => {
		setFormData((preVal) => {
			return {
				...preVal,
				[name]: value,
			}
		})
	}

	//validation
	const simpleValidator = React.useRef(new SimpleReactValidator({
		element: (message) => (
			<span className="error-message text-danger fs-14">{message}</span>
		),
		autoForceUpdate: this
	}));

	const handleSubmit = (status) => {
		const formValid = simpleValidator.current.allValid();
		if (formValid) {
			let payload = { ...formData, status };
			props.updateSurvey("technologyAndServicesInfo", payload);

		} else {
			simpleValidator.current.showMessages()
			forceUpdate(1);
			props.errorAccordion();
		}
	}
	return (
		<div>
			<div className="container-sa position-ralative">
				{/* <h5>Technology and Services</h5> */}
				<FieldsTemplate
					attributes={formAttr}
					simpleValidator={simpleValidator}
					stateAttr={formData}
					handleInput={handleInput}
					handleRadio={handleRadio}
				// handleCheckbox={handleCheckbox}
				/>
				<div className="row mt-3">
					<div className="col-md-5 col-sm-12  my-1">
						<NormalButton
							label="Save as draft"
							mainbg={true}
							className="px-3 py-2 mx-auto fs-14 line-height-26 font-Gilroy saveNext"
							onClick={() => handleSubmit('draft')}
							isPopup={true}
						/>
					</div>
				</div>
			</div>
		</div>
	)
}