import React, { Component } from "react";
import GoogleMap from "google-map-react";
import { UncontrolledPopover } from "reactstrap";
import SearchCard from "./SearchCard";

const User_Profile_1 = require("assets/svg/Members/User_Profile_1.svg");
const Marker = ({ children }) => children;
export default class SearchMap extends Component {
  state = {
    popoverOpen: false,
  };

  static defaultProps = {
    center: [21.6, 79.32],
    zoom: 6,
    greatPlaceCoords: {
      lat: 0,
      lng: 0,
    },
  };

  //toggle
  toggle = () => {
    this.setState((prevState) => ({
      popoverOpen: !prevState.popoverOpen,
    }));
  };

  //toggle hover
  toggleHover = () => {
    this.setState({
      popoverOpen: true,
    });
  };
  render() {
    return (
      <div className="search-map">
        <GoogleMap
          bootstrapURLKeys={{
            key: "AIzaSyAYb0HaLfz3pOvVAE4ovmFtz0kNt5baOPo",
          }}
          defaultCenter={
            this.props.center && this.props.center.lat && this.props.center.lng
              ? this.props.center
              : { lat: 0, lng: 0 }
          }
          defaultZoom={this.props.zoom}
          options={{ fullscreenControl: false }}
        >
          {this.props.payload.map((searchResult, index) => {
            const { profile, ComapanyName, lat = 0, lng = 0 } = searchResult;
            return (
              // lat={13.0619} lng={80.2468}
              <Marker key={index} lat={lat} lng={lng}>
                <div className="search-map-pointer">
                  <img
                    id={ComapanyName.split(" ").join("_")}
                    className="rounded-circle map-profile p-1 cursor-pointer search-map-pointer"
                    src={profile ? profile : User_Profile_1}
                    alt="profile"
                  />
                </div>
                <UncontrolledPopover
                  placement="left"
                  target={ComapanyName.split(" ").join("_")}
                  trigger="legacy"
                  fade={true}
                  flip={true}
                  modifiers={{
                    offset: { offset: "0, 30", enabled: true },
                  }}
                  hideArrow={true}
                >
                  <div>
                    <SearchCard payload={searchResult} />
                  </div>
                </UncontrolledPopover>
              </Marker>
            );
          })}
        </GoogleMap>
      </div>
    );
  }
}
