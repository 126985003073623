const initialState = {
  audioDetail: {},
};

export default (state = Object.assign({}, initialState), { type, payload }) => {
  switch (type) {
    case "AUDIO_MESSAGE":
      return {
        ...state,
        audioDetail: payload,
      };
    default:
      return state;
  }
};
