import { RmLogin, RM } from 'service/apiVariables';
import { Toast } from 'service/toast';
import { api } from 'service/api';
import { addQuery } from 'service/helperFunctions';

export const getFpoList = () => (dispatch, getState) => {
   return new Promise((resolve, reject) => {
      api({ ...RmLogin.getFpoList })
         .then(({ data }) => {
            resolve(data);
         })
         .catch(({ message }) => {
            reject(Toast({ type: 'error', message }));
         });
   });
};

export const getAboutUsById = (id) => (dispatch, getState) => {
   let { getAboutUsById } = RmLogin;
   getAboutUsById.id = id;

   return new Promise((resolve, reject) => {
      api({ ...RmLogin.getAboutUsById })
         .then(({ data }) => {
            resolve(data);
         })
         .catch(({ message }) => {
            reject(Toast({ type: 'error', message }));
         });
   });
};

//get Capacity Building List
export const getCapacityBuildingList = (query) => (dispatch, getState) => {
   return new Promise((resolve, reject) => {
      addQuery(query, RM.getCapacityBuildingList);
      api({ ...RM.getCapacityBuildingList })
         .then(({ data }) => {
            resolve(data);
         })
         .catch(({ message }) => {
            reject(Toast({ type: 'error', message }));
         });
   });
};

//get FPO list Capacity Building
export const getFPOlistCapacityBuilding = () => (dispatch, getState) => {
   return new Promise((resolve, reject) => {
      api({ ...RM.getFPOlistCapacityBuilding })
         .then(({ data }) => {
            resolve(data);
         })
         .catch(({ message }) => {
            reject(Toast({ type: 'error', message }));
         });
   });
};

//add Capacity Building
export const addCapacityBuilding = (body) => (dispatch, getState) => {
   return new Promise((resolve, reject) => {
      api({ ...RM.addCapacityBuilding, body })
         .then(({ data, message }) => {
            resolve(data);
            Toast({ type: 'success', message });
         })
         .catch(({ message }) => {
            reject(Toast({ type: 'error', message }));
         });
   });
};

//get Calender Fpo List Rm
export const getCalenderFpoListRm = (query) => (
   dispatch,
   getState,
   { api, Toast }
) => {
   return new Promise((resolve, reject) => {
      addQuery(query, RmLogin.getCalenderFpoListRm);

      api({ ...RmLogin.getCalenderFpoListRm })
         .then(({ data }) => {
            resolve(data);
         })
         .catch(({ message }) => {
            reject(Toast({ type: 'error', message }));
         });
   });
};

//get Calender List Rm
export const getCalenderListRm = (query) => (
   dispatch,
   getState,
   { api, Toast }
) => {
   return new Promise((resolve, reject) => {
      addQuery(query, RmLogin.getCalenderListRm);

      api({ ...RmLogin.getCalenderListRm })
         .then(({ data }) => {
            resolve(data);
         })
         .catch(({ message }) => {
            reject(Toast({ type: 'error', message }));
         });
   });
};

//get Compliance For FPO By Id
export const getComplianceForFPOById = (id) => (dispatch, getState) => {
   let { getComplianceForFPOById } = RmLogin;
   getComplianceForFPOById.id = id;

   return new Promise((resolve, reject) => {
      api({ ...RmLogin.getComplianceForFPOById })
         .then(({ data }) => {
            resolve(data);
         })
         .catch(({ message }) => {
            reject(Toast({ type: 'error', message }));
         });
   });
};

//get Events and Impacts Of FPO By Id
export const getEventsandImpactsOfFPOById = (id) => (dispatch, getState) => {
   let { getEventsandImpactsOfFPOById } = RmLogin;
   getEventsandImpactsOfFPOById.id = id;

   return new Promise((resolve, reject) => {
      api({ ...RmLogin.getEventsandImpactsOfFPOById })
         .then(({ data }) => {
            resolve(data);
         })
         .catch(({ message }) => {
            reject(Toast({ type: 'error', message }));
         });
   });
};

//get Association List
export const getAssociationList = (query, id) => (dispatch, getState) => {
   let { getAssociationList } = RmLogin;
   getAssociationList.id = id;
   return new Promise((resolve, reject) => {
      addQuery(query, RmLogin.getAssociationList);
      api({ ...RmLogin.getAssociationList })
         .then(({ data }) => {
            resolve(data);
         })
         .catch(({ message }) => {
            reject(Toast({ type: 'error', message }));
         });
   });
};

//downlaod Association and Partners Of FPO
export const downlaodAssociationandPartnersOfFPO = (id) => (dispatch, getState) => {
   let { downlaodAssociationandPartnersOfFPO } = RmLogin
   downlaodAssociationandPartnersOfFPO.id = id
   return new Promise((resolve, reject) => {
      api({ ...RmLogin.downlaodAssociationandPartnersOfFPO })
         .then((data) => {
            resolve(data);
         })
         .catch(({ message }) => {
            reject(Toast({ type: 'error', message }));
         });
   });
};

//get Grading Details Of FPO
export const getGradingDetailsOfFPO = (id) => (dispatch, getState) => {
   let { getGradingDetailsOfFPO } = RmLogin;
   getGradingDetailsOfFPO.id = id;
   return new Promise((resolve, reject) => {
      api({ ...RmLogin.getGradingDetailsOfFPO })
         .then(({ data }) => {
            resolve(data);
         })
         .catch(({ message }) => {
            reject(Toast({ type: 'error', message }));
         });
   });
};

//Get All Financial summary List API
export const getAllKeyFinancialInfoRm = (id, query) => (dispatch, getState) => {
   RmLogin.getAllKeyFinancialInfoRm.id = id;
   return new Promise((resolve, reject) => {
      addQuery(query, RmLogin.getAllKeyFinancialInfoRm);
      api({ ...RmLogin.getAllKeyFinancialInfoRm })
         .then(({ data }) => {
            resolve(data);
         })
         .catch(({ message }) => {
            reject(Toast({ type: 'error', message }));
         });
   });
};

//Get All Revenue List API
export const getAllRevenueRm = (id, query) => (dispatch, getState) => {
   RmLogin.getAllRevenueRm.id = id;
   return new Promise((resolve, reject) => {
      addQuery(query, RmLogin.getAllRevenueRm);
      api({ ...RmLogin.getAllRevenueRm })
         .then(({ data }) => {
            resolve(data);
         })
         .catch(({ message }) => {
            reject(Toast({ type: 'error', message }));
         });
   });
};

//Get All Plans List API
export const getAllPlanRm = (id) => (dispatch, getState) => {
   RmLogin.getAllPlanRm.id = id;
   return new Promise((resolve, reject) => {
      api({ ...RmLogin.getAllPlanRm })
         .then(({ data }) => {
            resolve(data);
         })
         .catch(({ message }) => {
            reject(Toast({ type: 'error', message }));
         });
   });
};

//Get All Credit history List API
export const getAllCreditHistoryRm = (id, query) => (dispatch, getState) => {
   RmLogin.getAllCreditHistoryRm.id = id;
   return new Promise((resolve, reject) => {
      addQuery(query, RmLogin.getAllCreditHistoryRm);
      api({ ...RmLogin.getAllCreditHistoryRm })
         .then(({ data }) => {
            resolve(data);
         })
         .catch(({ message }) => {
            reject(Toast({ type: 'error', message }));
         });
   });
};

//Get All License List
export const getAllLicenseRm = (id, query) => (dispatch, getState) => {
   RmLogin.getAllLicenseRm.id = id;
   return new Promise((resolve, reject) => {
      addQuery(query, RmLogin.getAllLicenseRm);
      api({ ...RmLogin.getAllLicenseRm })
         .then(({ data }) => {
            resolve(data);
         })
         .catch(({ message }) => {
            reject(Toast({ type: 'error', message }));
         });
   });
};

//Get All GST List
export const getAllGstRm = (id, query) => (dispatch, getState) => {
   RmLogin.getAllGstRm.id = id;
   return new Promise((resolve, reject) => {
      addQuery(query, RmLogin.getAllGstRm);
      api({ ...RmLogin.getAllGstRm })
         .then(({ data }) => {
            resolve(data);
         })
         .catch(({ message }) => {
            reject(Toast({ type: 'error', message }));
         });
   });
};
//Get All Income TDS API
export const getAllIncomeTdsRm = (id, query) => (dispatch, getState) => {
   RmLogin.getAllIncomeTdsRm.id = id;
   return new Promise((resolve, reject) => {
      addQuery(query, RmLogin.getAllIncomeTdsRm);
      api({ ...RmLogin.getAllIncomeTdsRm })
         .then(({ data }) => {
            resolve(data);
         })
         .catch(({ message }) => {
            reject(Toast({ type: 'error', message }));
         });
   });
};

//Get All Income Tax API
export const getAllIncomeTaxRm = (id, query) => (dispatch, getState) => {
   RmLogin.getAllIncomeTaxRm.id = id;
   return new Promise((resolve, reject) => {
      addQuery(query, RmLogin.getAllIncomeTaxRm);
      api({ ...RmLogin.getAllIncomeTaxRm })
         .then(({ data }) => {
            resolve(data);
         })
         .catch(({ message }) => {
            reject(Toast({ type: 'error', message }));
         });
   });
};

//Get All Registrar API
export const getAllRegistrarRm = (id, query) => (dispatch, getState) => {
   RmLogin.getAllRegistrarRm.id = id;
   return new Promise((resolve, reject) => {
      addQuery(query, RmLogin.getAllRegistrarRm);
      api({ ...RmLogin.getAllRegistrarRm })
         .then(({ data }) => {
            resolve(data);
         })
         .catch(({ message }) => {
            reject(Toast({ type: 'error', message }));
         });
   });
};

//Get All GST comp API
export const getAllGstCompRm = (id, query) => (dispatch, getState) => {
   RmLogin.getAllGstCompRm.id = id;
   return new Promise((resolve, reject) => {
      addQuery(query, RmLogin.getAllGstCompRm);
      api({ ...RmLogin.getAllGstCompRm })
         .then(({ data }) => {
            resolve(data);
         })
         .catch(({ message }) => {
            reject(Toast({ type: 'error', message }));
         });
   });
};

//Get All Agm API
export const getAllAgmRm = (id, query) => (dispatch, getState) => {
   RmLogin.getAllAgmRm.id = id;
   return new Promise((resolve, reject) => {
      addQuery(query, RmLogin.getAllAgmRm);
      api({ ...RmLogin.getAllAgmRm })
         .then(({ data }) => {
            resolve(data);
         })
         .catch(({ message }) => {
            reject(Toast({ type: 'error', message }));
         });
   });
};

//Get All BOD API
export const getAllBodRm = (id, query) => (dispatch, getState) => {
   RmLogin.getAllBodRm.id = id;
   return new Promise((resolve, reject) => {
      addQuery(query, RmLogin.getAllBodRm);
      api({ ...RmLogin.getAllBodRm })
         .then(({ data }) => {
            resolve(data);
         })
         .catch(({ message }) => {
            reject(Toast({ type: 'error', message }));
         });
   });
}

//get Documents Of FPO common for all tabs
export const getDocumentsOfFPO = (id, tabName) => (dispatch, getState) => {
   let { getDocumentsOfFPO } = RmLogin
   getDocumentsOfFPO.id = id
   getDocumentsOfFPO.tabName = tabName
   return new Promise((resolve, reject) => {
      api({ ...RmLogin.getDocumentsOfFPO })
         .then(({ data }) => {
            resolve(data);
         })
         .catch(({ message }) => {
            reject(Toast({ type: 'error', message }));
         });
   });
};

//get Company Kyc Bulk download
export const getCompanyKycBulkdownloadOfFpo = (query) => (
   dispatch,
   getState,
   { api, Toast }
) => {
   return new Promise((resolve, reject) => {
      addQuery(query, RmLogin.getCompanyKycBulkdownloadOfFpo);
      api({ ...RmLogin.getCompanyKycBulkdownloadOfFpo })
         .then(({ data }) => {
            resolve(data);
         })
         .catch(({ message }) => {
            reject(Toast({ type: 'error', message }));
         });
   });
};
//get Financial Bulk download
export const getFinancialBulkdownloadOfFpo = (query) => (
   dispatch,
   getState,
   { api, Toast }
) => {
   return new Promise((resolve, reject) => {
      addQuery(query, RmLogin.getFinancialBulkdownloadOfFpo);
      api({ ...RmLogin.getFinancialBulkdownloadOfFpo })
         .then(({ data }) => {
            resolve(data);
         })
         .catch(({ message }) => {
            reject(Toast({ type: 'error', message }));
         });
   });
};

//ge tDirectdoc Bulk download
export const getDirectdocBulkdownloadOfFpo = (query) => (
   dispatch,
   getState,
   { api, Toast }
) => {
   return new Promise((resolve, reject) => {
      addQuery(query, RmLogin.getDirectdocBulkdownloadOfFpo);
      api({ ...RmLogin.getDirectdocBulkdownloadOfFpo })
         .then(({ data }) => {
            resolve(data);
         })
         .catch(({ message }) => {
            reject(Toast({ type: 'error', message }));
         });
   });
};

//get creditdata Bulk download
export const getcreditdataBulkdownloadOfFpo = (query) => (
   dispatch,
   getState,
   { api, Toast }
) => {
   return new Promise((resolve, reject) => {
      addQuery(query, RmLogin.getcreditdataBulkdownloadOfFpo);
      api({ ...RmLogin.getcreditdataBulkdownloadOfFpo })
         .then(({ data }) => {
            resolve(data);
         })
         .catch(({ message }) => {
            reject(Toast({ type: 'error', message }));
         });
   });
};

//get statutory Bulk download
export const getstatutoryBulkdownloadOfFpo = (query) => (
   dispatch,
   getState,
   { api, Toast }
) => {
   return new Promise((resolve, reject) => {
      addQuery(query, RmLogin.getstatutoryBulkdownloadOfFpo);
      api({ ...RmLogin.getstatutoryBulkdownloadOfFpo })
         .then(({ data }) => {
            resolve(data);
         })
         .catch(({ message }) => {
            reject(Toast({ type: 'error', message }));
         });
   });
};

// get Gateway For Rm
export const getGatewayForRm = () => (
   dispatch,
   getState,
   { api, Toast }
) => {
   return new Promise((resolve, reject) => {
      api({ ...RmLogin.getGatewayForRm })
         .then(({ data }) => {
            resolve(data);
         })
         .catch(({ message }) => {
            reject(Toast({ type: 'error', message }));
         });
   });
}

//members
export const getMembersCardApiRm = (id, query) => (dispatch, getState) => {
   RmLogin.getMembersCardApiRm.id = id;
   return new Promise((resolve, reject) => {
      addQuery(query, RmLogin.getMembersCardApiRm);
      api({ ...RmLogin.getMembersCardApiRm })
         .then(({ data, message }) => {
            resolve(data);
         })
         .catch(({ message }) => {
            reject(Toast({ type: 'error', message }));
         });
   });
};

export const getBodListRm = (id, query) => (dispatch, getState) => {
   RmLogin.getBodListRm.id = id;
   return new Promise((resolve, reject) => {
      addQuery(query, RmLogin.getBodListRm);
      api({ ...RmLogin.getBodListRm })
         .then(({ data }) => {
            resolve(data);
         })
         .catch(({ message }) => {
            reject(Toast({ type: 'error', message }));
         });
   });
};

export const getStaffListRm = (id, query) => (dispatch, getState) => {
   RmLogin.getStaffListRm.id = id;
   return new Promise((resolve, reject) => {
      addQuery(query, RmLogin.getStaffListRm);
      api({ ...RmLogin.getStaffListRm })
         .then(({ data }) => {
            resolve(data);
         })
         .catch(({ message }) => {
            reject(Toast({ type: 'error', message }));
         });
   });
};

//get member csv
export const getMemberProfileCsvofFPO = (id) => (dispatch, getState) => {
   let { getMemberProfileCsvofFPO } = RmLogin
   getMemberProfileCsvofFPO.id = id
   return new Promise((resolve, reject) => {
      api({ ...RmLogin.getMemberProfileCsvofFPO })
         .then((data) => {
            resolve(data);
         })
         .catch(({ message }) => {
            reject(Toast({ type: 'error', message }));
         });
   });
};

//get BOD csv
export const getBodProfileCsvofFPO = (id) => (dispatch, getState) => {
   let { getBodProfileCsvofFPO } = RmLogin
   getBodProfileCsvofFPO.id = id
   return new Promise((resolve, reject) => {
      api({ ...RmLogin.getBodProfileCsvofFPO })
         .then((data) => {
            resolve(data);
         })
         .catch(({ message }) => {
            reject(Toast({ type: 'error', message }));
         });
   });
};

//get staff csv
export const getStaffProfileCsvofFPO = (id) => (dispatch, getState) => {
   let { getStaffProfileCsvofFPO } = RmLogin
   getStaffProfileCsvofFPO.id = id
   return new Promise((resolve, reject) => {
      api({ ...RmLogin.getStaffProfileCsvofFPO })
         .then((data) => {
            resolve(data);
         })
         .catch(({ message }) => {
            reject(Toast({ type: 'error', message }));
         });
   });
};

//get Relationship History List of Fpo
export const getRelationshipHistoryListofFpo = (id) => (dispatch, getState) => {
   let { getRelationshipHistoryListofFpo } = RmLogin
   getRelationshipHistoryListofFpo.id = id
   return new Promise((resolve, reject) => {
      api({ ...RmLogin.getRelationshipHistoryListofFpo })
         .then(({ data }) => {
            resolve(data);
         })
         .catch(({ message }) => {
            reject(Toast({ type: 'error', message }));
         });
   });
};

//download ompliance Csv

export const downloadcomplianceCsv = (tabName, id) => (dispatch, getState) => {
   let { downloadcomplianceCsv } = RmLogin
   downloadcomplianceCsv.id = id
   downloadcomplianceCsv.tabName = tabName
   return new Promise((resolve, reject) => {
      api({ ...RmLogin.downloadcomplianceCsv })
         .then((data) => {
            resolve(data);
         })
         .catch(({ message }) => {
            reject(Toast({ type: 'error', message }));
         });
   });
};

//download Key Financial Csv

export const downloadKeyFinancialCsv = (tabName, id) => (dispatch, getState) => {
   let { downloadKeyFinancialCsv } = RmLogin
   downloadKeyFinancialCsv.id = id
   downloadKeyFinancialCsv.tabName = tabName
   return new Promise((resolve, reject) => {
      api({ ...RmLogin.downloadKeyFinancialCsv })
         .then((data) => {
            resolve(data);
         })
         .catch(({ message }) => {
            reject(Toast({ type: 'error', message }));
         });
   });
};

//banner Api For Rm
export const bannerApiForRm = () => (dispatch, getState) => {
   return new Promise((resolve, reject) => {
      api({ ...RmLogin.bannerApiForRm })
         .then(({ data }) => {
            resolve(data);
         })
         .catch(({ message }) => {
            reject(Toast({ type: 'error', message }));
         });
   });
};

//get BOD By Id For RM
export const getBODByIdForRM = (body) => (dispatch, getState) => {
   let { getBODByIdForRM } = RmLogin
   getBODByIdForRM.id = body.id
   getBODByIdForRM.fpoId = body.fpoid
   return new Promise((resolve, reject) => {
      api({ ...RmLogin.getBODByIdForRM })
         .then(({ data }) => {
            resolve(data);
         })
         .catch(({ message }) => {
            reject(Toast({ type: 'error', message }));
         });
   });
};

//get Staff By Id For RM
export const getStaffByIdForRM = (body) => (dispatch, getState) => {
   let { getStaffByIdForRM } = RmLogin
   getStaffByIdForRM.id = body.id
   getStaffByIdForRM.fpoId = body.fpoid
   return new Promise((resolve, reject) => {
      api({ ...RmLogin.getStaffByIdForRM })
         .then(({ data }) => {
            resolve(data);
         })
         .catch(({ message }) => {
            reject(Toast({ type: 'error', message }));
         });
   });
};

//get key financial list data By Id
export const getkeyfinanciallistdataById = (id) => (dispatch, getState) => {
   let { getkeyfinanciallistdataById } = RmLogin
   getkeyfinanciallistdataById.id = id
   return new Promise((resolve, reject) => {
      api({ ...RmLogin.getkeyfinanciallistdataById })
         .then(({ data }) => {
            resolve(data);
         })
         .catch(({ message }) => {
            reject(Toast({ type: 'error', message }));
         });
   });
};

//get Role Analysis data By Id
export const getRoleAnalysisdataById = (id) => (dispatch, getState) => {
   let { getRoleAnalysisdataById } = RmLogin
   getRoleAnalysisdataById.id = id
   return new Promise((resolve, reject) => {
      api({ ...RmLogin.getRoleAnalysisdataById })
         .then(({ data }) => {
            resolve(data);
         })
         .catch(({ message }) => {
            reject(Toast({ type: 'error', message }));
         });
   });
};

//get performance data By Id

export const getperformancedataById = (id) => (dispatch, getState) => {
   let { getperformancedataById } = RmLogin
   getperformancedataById.id = id
   return new Promise((resolve, reject) => {
      api({ ...RmLogin.getperformancedataById })
         .then(({ data }) => {
            resolve(data);
         })
         .catch(({ message }) => {
            reject(Toast({ type: 'error', message }));
         });
   });
};