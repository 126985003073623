

export const addComma = (value, firstLoad = false) => {
  if (typeof value === 'number') {
    value = value.toString();
  }
  try {

    if (!value || value.trim() === "" || isNaN(parseFloat(value)) || parseFloat(value) < 1) {
      return "";
    }
    let x = value;
    if (!firstLoad) {
      x = value.replace(/,/g, '');
      x = parseFloat(x);
    }
    x = x.toString();
    let lastThree = x.substring(x.length - 3);
    let otherNumbers = x.substring(0, x.length - 3);
    if (otherNumbers !== '') lastThree = ',' + lastThree;
    let res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
    return res;

  } catch (error) {
    console.log(error);
  }
}