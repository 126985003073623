import React, { Component } from 'react';

import { IncomeTaxTab } from '../IncomeTaxTab';

export class IncometaxList extends Component {
   render() {
      let { editAccess = false } = this.props;
      return (
         <div className='d-flex flex-wrap mt-3 px-0 justify-content-center'>
            <IncomeTaxTab
               activeTab={this.props.activeTab}
               view={this.props.view}
               limit={this.props.limit}
               searchValue={this.props.searchValue}
               mainactiveTab={this.props.mainactiveTab}
               filterBy={this.props.filterBy}
               filterValue={this.props.filterValue}
               editAccess={editAccess}
               id={this.props.id}
            />
         </div>
      );
   }
}
