import React, { Component } from "react";
import { Footer } from "component/fpo/home";
import { Navbar } from "component/common";
import "./style.scss";
class TermsandconditionClass extends Component {
  constructor(props) {
    super(props);
    this.state = {
      innerWidth: window.innerWidth,
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  handleResize = () => {
    this.setState({
      innerWidth: window.innerWidth,
    });
  };

  render() {
    const { innerWidth } = this.state;
    const isMobile = innerWidth < 768;

    return (
      <div className={`${!isMobile ? "terms-and-condition" : null}`}>
        {!isMobile ? (
          <Navbar
            isLanding={
              window.location.href.includes("termsandcondition") ? true : false
            }
          />
        ) : null}
        <div className="bg-blue-f9">
          <div className="container">
            <div className="py-4 ">
              <div className="fs-28 fw-700 text-center text-green-18 pb-4">
                Terms & Conditions
              </div>
              <div>
                <p className="fs-18 text-black-1c line-height-28">
                  This agreement between you and Samunnati Financial
                  Intermediation &amp; Services Private Limited{" "}
                  <b>(“Samunnati”)</b> sets out the legally binding terms{" "}
                  <b>(&quot;Terms&quot;)</b> governing the use of the FPO
                  Gateway website and mobile applications, including all
                  content, features, functionality, programs, applications, or
                  services provided in or through the website or the mobile
                  application (collectively, the <b>“Platform”</b>).
                </p>
                <p className="fs-18 text-black-1c line-height-28">
                  These Terms, together with the{" "}
                  <a href="/privacypolicy">Privacy Policy</a>, and any other
                  applicable rules, policies, and legal terms posted within the
                  Platform <b>(“Platform Terms”)</b>, govern your use of the
                  Platform which are incorporated herein by reference and forms
                  an integral part of these Terms.
                </p>
                <ol className="pl-4">
                  <li>
                    <b>Intended Audience and Territorial Restrictions.</b> This
                    Platform is designed and intended for use by residents of
                    India who are at least 18 years of age. You may not register
                    for or use the Platform if: (i) you are under 18 years of
                    age; or (ii) we have previously terminated your Account and
                    advised you that you may no longer use the Platform or any
                    of our other services or functionalities provided by the
                    Platform <b>(&quot;Services&quot;)</b>. If you are accessing
                    or using our Services on behalf of, or to represent the
                    interests of another person or entity, you represent that
                    you are authorized to accept this Agreement on that person
                    or entity’s behalf and that you intend to bind such
                    represented person or entity to this Agreement, if you or
                    the other person or entity violates these Terms. You
                    undertake not to misrepresent your identity or your
                    authority to act on behalf of such represented person or
                    entity in connection with the use of this Platform.
                  </li>
                  <li>
                    <b>Acceptance.</b> By clicking <b>&quot;Agree&quot;</b>, or
                    by installing or using the Platform and/or Digital Content,
                    you represent to Samunnati that: you are at least 18 years
                    of age and have reached the age of majority in your
                    jurisdiction, and you have read, understood, and agreed to
                    these Terms.
                  </li>
                  <li>
                    <b>Your Privacy.</b> Samunnati respects your privacy. Please
                    refer to <a href="/privacypolicy">Privacy Policy</a> for
                    information on how we collect, process,store, transfer and
                    use your personal data in connection with your use of the
                    Platform, for what purpose(s) we use your personal data, and
                    your rights in relation to your personal data, and how to
                    exercise them. It is important that you read these details
                    prior to providing any personal information to us. Please be
                    aware that despite our reasonable efforts to adopt security
                    measures commensurate to the sensitivity of the information
                    being protected, the transmission of data over the internet
                    can never be guaranteed to be completely private or secure.
                  </li>
                  <li>
                    <b>Feedback.</b> You may elect to provide or make available
                    to Samunnati suggestions, comments, ideas, improvements, or
                    other feedback through the App, or separately provide other
                    materials to Samunnati (collectively, <b>“Feedback”</b>)
                    which are governed by the following terms:
                    <ul>
                      <li>
                        Any Feedback that you send to us through the Platform is
                        and will be deemed non- confidential, and Samunnati will
                        have no obligation of any kind with respect to such
                        information. Samunnati will be free to use, disclose,
                        reproduce, modify, license, transfer and otherwise
                        distribute such Feedback in any manner, and you will
                        gain no right, title, or interest in or to the Platform,
                        or Services by virtue of Samunnati doing so. Samunnati
                        will be free to use any ideas, concepts, know-how, or
                        techniques contained in such communications for any
                        purpose, including but not limited to, developing,
                        manufacturing, and marketing products. Samunnati
                        reserves the right to display or insert advertisements
                        in connection with such Feedback and to use your
                        Feedback for marketing, advertising, and promotional
                        purposes.
                      </li>
                      <li>
                        You agree that you are solely responsible for all your
                        Feedback. Samunnati is not required to host, display, or
                        distribute any Feedback, and may refuse any Feedback for
                        any reason. Samunnati is not responsible for any loss or
                        theft of, or damage of any kind to, any Feedback. You
                        represent and warrant that: (a) you own all rights in
                        your Feedback or, alternatively, you have acquired all
                        necessary rights in your Feedback to enable you to grant
                        to Samunnati all the rights described herein; and (b)
                        your Feedback does not infringe the intellectual
                        property rights, privacy, or any other legal or moral
                        rights of any third party.
                      </li>
                      <li>
                        You are prohibited from posting content or offering
                        Feedback, or otherwise using the Platform to transmit
                        any unlawful, threatening, libellous, defamatory,
                        obscene, inflammatory, pornographic, or profane material
                        or any material that could constitute or encourage
                        conduct that would be considered a criminal offence,
                        give rise to civil liability, or otherwise violate the
                        law or copyright, trademark, or other intellectual
                        property rights of another. Samunnati will cooperate
                        with any law enforcement authorities or court order
                        requesting or directing Samunnati to disclose the
                        identity of anyone posting information or materials in
                        connection with the App. Samunnati may, from time to
                        time, monitor, review, block or remove the discussions,
                        chats, postings, transmissions, bulletin boards and the
                        like; however, Samunnati is under no obligation to do so
                        and assumes no responsibility or liability arising from
                        such content nor for any error, defamation, libel,
                        slander, omission, falsehood, obscenity, pornography,
                        profanity, danger or inaccuracy contained in any
                        Feedback.
                      </li>
                    </ul>
                  </li>
                  <li>
                    <b>Updates.</b> Samunnati may choose to provide updates to
                    the Platform and/or the Services from time to time at its
                    sole discretion. In addition, Samunnati&#39;s distribution
                    of the Platform via third-party services (e.g., the Google
                    Play and App Store) may require users to download new or
                    updated versions of the Platform on a periodic basis. You
                    acknowledge that you will be bound by the version of these
                    Terms which is applicable as of the time you download and
                    use any such update to the Platform. Samunnati does not
                    represent or warrant that any future updates to the Platform
                    will provide the same functionality that is provided by the
                    current version of the Platform. When changes are made,
                    Samunnati will provide an updated version of the Terms
                    within our Platform. We will also update the “Last Updated”
                    date at the top of these Terms. If we make any material
                    changes, we will require you to consent or sign up to such
                    updated Terms by way of an in-Platform pop-up before
                    continuing to use the Platform and send push notification
                    via the Platform (unless you have turned such functionality
                    off). Any changes to these Terms will be effective
                    immediately.
                  </li>
                  <li>
                    <b>Links.</b> The Platform may contain links to third-party
                    web sites or internet resources{" "}
                    <b>(&quot;Linked Sites&quot;)</b>. The Linked Sites are not
                    under the control of Samunnati and Samunnati is not
                    responsible for the availability, contents, or performance
                    of any Linked Site. Samunnati is providing these links to
                    you only as a convenience, and the inclusion of any link
                    does not imply endorsement by Samunnati of such Linked Site,
                    its contents or any products or services available through
                    such Linked Site or any association with its operators. You
                    are responsible for complying with any privacy statements or
                    terms of use of the Linked Sites. You should direct any
                    concerns regarding these third-party sites to those
                    sites&#39; administrators. You agree that Samunnati will
                    have no responsibility or liability for any information,
                    software, or materials found at any Linked Site.
                  </li>
                  <li>
                    <b>
                      Security via Wireless Carrier/ Internet Service Provider.
                    </b>{" "}
                    Samunnati does not control wireless or internet access. Your
                    use of these networks may not be secure and may expose your
                    personal information sent over such networks.
                  </li>
                  <li>
                    <b>Platform Security.</b> You must maintain the security of
                    your third-party account and promptly notify us if you
                    discover or suspect that someone has accessed your account
                    without your permission. If you permit others to use your
                    account credentials, you are responsible for the activities
                    of such users that occur in connection with your account.
                  </li>
                  <li>
                    <b>Ownership of Platform and Intellectual Property.</b> The
                    Platform is protected under Indian and international
                    copyright laws and are subject to other intellectual
                    property and proprietary treaties and laws. You must abide
                    by all copyright notices, information, or restrictions
                    contained in or attached to any Platform including but not
                    limited to the following:
                    <ul>
                      <li>
                        The names, logos, and materials displayed in the
                        Platform constitute trademarks, trade names, service
                        marks or logos (collectively,{" "}
                        <b>&quot;Trademarks&quot;</b>) owned by, or licensed to
                        Samunnati, its affiliates, subsidiaries, or related
                        companies. Ownership of all Trademarks and the goodwill
                        associated therewith remains with Samunnati or in the
                        case of licensed Trademarks, the licensor.
                      </li>
                      <li>
                        The Platform is licensed, and not sold, to you under
                        these Terms, and all rights not expressly granted in
                        these Terms are reserved to Samunnati. You have no
                        ownership rights in the Platform, or any Services. All
                        right, title, and interest in and to the Platform and
                        Services, including all copies, translations,
                        compilations, derivative works, modifications, changes,
                        or corrections thereto remain with Samunnati or its
                        licensors.
                      </li>
                      <li>
                        You retain all rights in and to the user data,
                        information, and content you which you have input into
                        the Platform(“<b>User Content</b>”), and Samunnati does
                        not validate and mention the correctness of such User
                        Content which is created on the Platform which includes,
                        without limitation, farmer surveys, farmer producer
                        organisation profiles, financial and ‘know your
                        customer’ documents and any other data which the App
                        user has created or uploaded on the Platform. You hereby
                        agree and acknowledge that the Samunnati retains the
                        right to represent the User Information at its
                        discretion to multiple service providers without re-
                        verification of the accuracy of such User Content.
                      </li>
                      <li>
                        The App, including without limitation its structure,
                        organization, and code, are Samunnati&#39;s or its
                        licensors' confidential information and constitute trade
                        secrets. You will not directly or indirectly, disclose
                        such information to any third party and will use such
                        information only as expressly permitted by these Terms.
                      </li>
                    </ul>
                  </li>
                  <li>
                    <b>User Content Restrictions.</b> You may not, nor may you
                    permit any other person or party to create, post, store,
                    upload, share, publish, transmit or otherwise make available
                    on or through the Platform any User Content that: (i)
                    encourages any unlawful activity, or otherwise in itself
                    unlawful in any manner whatever (ii) infringes a patent,
                    trademark, copyright or other proprietary rights of any
                    person or party, (iii) harms or exploits any person (whether
                    adult or minor) in any manner, (iv) contains software
                    viruses or any other computer code, files or programs
                    designed to interrupt, destroy or limit the functionality of
                    any computer resource, (v) deceives or misleads the
                    addressee about the origin of such messages, (vi)
                    communicates any information which is grossly offensive or
                    menacing in nature, (vii) contains or depicts any
                    statements, remarks or claims that do not reflect your
                    honest views and experiences and (viii)contains any private
                    or personal information of a third party without such third
                    party’s consent. You may also only post or otherwise share
                    User Content that you have all necessary rights to disclose.
                    <br></br>
                  </li>
                  <li>
                    <b>Third-Party Content.</b> We are not responsible for, and
                    we do not endorse, support, or guarantee the lawfulness,
                    accuracy or reliability of any third-party content, linked
                    websites owned by third parties, third-party software which
                    may be linked with, or interacts with the App, or
                    third-party software or services submitted to, transmitted,
                    displayed or made available on or through the Platform
                    (collectively <b>&quot;Third-Party Content&quot;</b>
                    ). In this regard, please note that:
                    <ul>
                      <li>
                        Samunnati does not endorse or take responsibility for
                        any Third-Party Content, which is not within Samunnati’s
                        control, and Samunnati cannot and will not take
                        responsibility for the information or content thereon.
                        Links with Third-Party Content should not be viewed as
                        an endorsement of Third-Party Content.
                      </li>
                      <li>
                        You are solely responsible for any software (whether
                        your own software or software supplied by third parties)
                        used by you in connection with your use of the App,
                        including any Third-Party Content, the use of which will
                        be governed inter alia by the terms of use and privacy
                        policy of the Third-Party Content provider.
                      </li>
                      <li>
                        We are not responsible for and are not liable for any
                        damages or losses arising from your use of any
                        Third-Party Content and we do not endorse, support, or
                        guarantee the quality, reliability, or suitability of
                        any Third-Party Content. You must comply with any terms
                        and conditions applicable to all Third-Party Content
                        that you use or may use.
                      </li>
                      <li>
                        You acknowledge and agree that by using the App, you may
                        be exposed to Third-Party content or services and any
                        reliance on or use of any Third-Party content or
                        services on or accessible from the Platform by you is at
                        your own risk. Your use of the Platform does not give
                        you any rights in or to any Third-Party content and/or
                        services you may access or obtain in connection with
                        your use of the App.
                      </li>
                      <li>
                        We do not provide any technical support for any
                        Third-Party Content. Please contact the relevant
                        supplying Third-Party for technical support.
                      </li>
                    </ul>
                  </li>
                  <li>
                    <b>Prohibited Activities &amp; Conduct</b> We reserve the
                    right to block or remove, with or without notice to you,
                    your User Content for any reason, including, if in our
                    opinion your User Content violates the Terms, or as may be
                    required by applicable laws and regulations.
                    <br />
                    <div className="pt-3"></div>
                    You agree that your usage of the Platform and/or the
                    Services shall be subject to and governed by any usage
                    requirements established from time to time by Samunnati.
                    Accordingly, you agree to use the Platform and/or the
                    Services in a manner that complies with all applicable laws
                    in India.
                    <br />
                    <div className="pt-3"></div>
                    Unless expressly permitted in writing by Samunnati, you
                    agree not to engage in any of the following prohibited
                    activities when using the App, or allow any person to use
                    your access to the Platform to do the same:
                    <ul>
                      <li>
                        violate any law, regulation, ordinance, or rule (or
                        carry out any actions which may result in violation of
                        any law, regulation, ordinance or rule when used in a
                        manner permitted by these Terms);
                      </li>
                      <li>
                        infringe our rights or any third party&#39;s rights,
                        without limitation including any intellectual property
                        rights, contractual rights, confidentiality rights or
                        privacy rights;
                      </li>
                      <li>
                        adapt, translate into any language, distribute,
                        broadcast, stream or create derivative works based on
                        the Platform;
                      </li>
                      <li>
                        remove, obscure or modify any copyright, trademark or
                        other proprietary rights notice, marks or indications
                        found in or on the Platform, or misappropriate work
                        protected by the copyright or other intellectual
                        property laws;
                      </li>
                      <li>
                        use the Platform for any illegal, deceptive, malicious,
                        misleading or fraudulent activities or other activities;
                      </li>
                      <li>
                        sub-license, rent, lease, sell or otherwise distribute,
                        transfer or assign the Platform to any person or entity
                        or charge any person or entity for use or access to the
                        Platform;
                      </li>
                      <li>
                        modify, decompile, disassemble, reverse engineer, or
                        attempt to reconstruct, identify, or discover any source
                        code, underlying ideas, underlying user interface
                        techniques, or algorithms of the Platform or software
                        embedded in the Platform by any means whatsoever, except
                        to the extent that foregoing restriction is prohibited
                        by applicable law;
                      </li>
                      <li>
                        perform any acts (including, without limitation,
                        hacking, attacking or overloading our networks or
                        servers) with the intention of adversely affecting the
                        performance or disrupting the Platform or otherwise
                        interfering with, or attempting to interfere with, any
                        user&#39;s or any other party&#39;s access to the
                        Platform;
                      </li>
                      <li>
                        upload viruses or malicious code or technologically
                        harmful material to the Platform, our systems or the
                        systems of other users of the Platform;
                      </li>
                      <li>
                        use any robot, spider, crawler, off-line reader, or
                        other technical means to intercept, mine, scrape,
                        monitor, copy, collect or extract information or data
                        from the Platform;
                      </li>
                      <li>
                        exploit technical defects in the Platform to benefit
                        yourself and/or others in any manner or engage in any
                        other misconduct, or probe or test the vulnerability of,
                        or otherwise circumvent or attempt to circumvent, defeat
                        or tamper with security measures on the Platform, our
                        systems or the systems of other users;
                      </li>
                      <li>
                        You may not use the Platform in conjunction with any
                        device, program or service designed to, modify,
                        circumvent, defeat or tamper with technological measures
                        employed to control access to, or the rights in the
                        Platform;
                      </li>
                      <li>
                        engage in any &quot;framing,&quot;
                        &quot;mirroring,&quot; or other techniques directed at
                        simulating the appearance or function of the Platform or
                        misrepresenting the source, or ownership of the
                        Platform;
                      </li>
                      <li>
                        develop any plug-ins, external components, or
                        interconnection elements or other technology that
                        inter-operate with the Platform, except where we
                        expressly permit you to do so;
                      </li>
                      <li>
                        use the Platform to gain unauthorized access to any
                        system, account or data or engage in the malicious
                        creation or use of an account, such as creating multiple
                        accounts for cybersquatting purposes;
                      </li>
                      <li>
                        register or login to an account or access the Platform
                        by using unauthorized means (including, without
                        limitation, by third-party software or systems not
                        developed, authorized or approved by us) or by
                        impersonating any person or misrepresenting your
                        identity or impersonating another person;
                      </li>
                      <li>
                        use the Platform other than for their intended purpose
                        or in any manner inconsistent with these Terms; or
                      </li>
                      <li>
                        engage in any other activity that encourages any person
                        or entity to breach these Terms.
                      </li>
                    </ul>
                  </li>
                  <li>
                    <b>Discontinuation and Changes.</b> Samunnati may change the
                    form or nature of the Platform or Services and reserves the
                    right to modify, suspend or discontinue your access to them
                    for any reason without notice, at its sole discretion.
                    Samunnati will have no liability whatsoever to you or any
                    third party for any failure of the Platform or Services
                    (including transmitting your data to or from Samunnati&#39;s
                    or Third-Party servers) and has no obligation to retain any
                    of your data including Feedback on its servers or to return
                    your data or Feedback to you before deleting them from its
                    servers.
                  </li>
                  <li>
                    <b>Termination of Rights.</b> Your rights under these Terms
                    will automatically terminate if you fail to comply with any
                    of these Terms. In the case of termination, you must cease
                    all use of the Platform or Services. Samunnati may
                    immediately revoke your access to the Platform and/or
                    Services. Samunnati&#39;s failure to insist upon or enforce
                    your strict compliance with these Terms will not constitute
                    a waiver of any of its rights.
                  </li>
                  <li>
                    <b>Disclaimer of Warranties.</b>You use the Platform at your
                    own risk and subject to the following disclaimers:
                    <ul>
                      <li>
                        We do not guarantee that the app, digital content, and
                        services will always function without disruptions,
                        delays, or imperfections. Samunnati does not warrant
                        that the Platform, digital content, or services will
                        meet your requirements in any respect or be always
                        available, or that the operation of the Platform or
                        services will be uninterrupted or error-free or assume
                        liability for the correction of any or that errors in
                        the Platform or Services. The Platform is provided
                        &quot;as is&quot; and &quot;with all faults&quot; and
                        the entire risk as to the accuracy and performance of
                        the Platform is borne by you. We will not be liable to
                        you for any modification, suspension, or discontinuation
                        of the Services, or the loss of any data stored in the
                        Platform or for any liability should it prove defective,
                        or malfunctions, or causes or results in destruction or
                        loss of your data or impair the functioning of your
                        device.
                      </li>
                      <li>
                        To the fullest extent permitted by law, Samunnati and
                        its suppliers hereby disclaim all warranties with
                        respect to the Platform and Services, express or
                        implied, including any warranties of merchantability,
                        non-infringement, or fitness for a particular use or
                        purpose. You acknowledge that the provisions of this
                        clause are a material inducement and consideration to
                        Samunnati to grant the licenses contained in these Terms
                        and to provide you with access to the Platform and
                        Services.
                      </li>
                    </ul>
                  </li>
                  <li>
                    <b>Limitation of Liability.</b>To the fullest extent
                    permitted by law, except as may be expressly stated herein,
                    in no event will Samunnati, its affiliates, subsidiaries,
                    directors, officers, employees, agents, suppliers, licensors
                    or Third-Party partners be liable for any direct, indirect,
                    special, consequential, incidental, or punitive damages
                    whatsoever (including, without limitation, damages for loss
                    of profits, loss of savings, business interruptions, loss of
                    data, loss of time, loss of goodwill or other pecuniary
                    loss) arising out of or in connection with these Terms, its
                    subject matter, your use of or inability to use the Platform
                    or Services, or your use of or reliance on any data you may
                    access in connection with your use of the Platform or
                    Services, regardless of whether Samunnati or its suppliers
                    or licensors knows or should know of the possibility of such
                    damages.
                  </li>
                  <li>
                    <b>Indemnification.</b>You agree to indemnify, defend and
                    hold harmless Samunnati, its affiliates, subsidiaries,
                    directors, officers, employees, agents, suppliers, licensors
                    and third-party partners from and against all losses,
                    expenses, damages and costs, including reasonable
                    attorneys&#39; fees, resulting from (i) an inaccuracy or
                    breach of any of the warranties made by you, (ii) any
                    misrepresentation or fraud committed by you, (iii) a
                    violation by you of these Terms, to the fullest extent
                    allowed by law.
                  </li>
                  <li>
                    <b>Third-Party Claims:</b>If any Third-Party brings a claim
                    (<b>&quot;Third-Party Claim&quot;</b>) against us related to
                    your actions, information, or content on the Platform, you
                    will, to the extent permitted by law, indemnify and hold
                    Samunnati harmless from and against all liabilities,
                    damages, losses, and expenses of any kind (including
                    reasonable legal fees and costs) relating to, arising out
                    of, or in any way in connection with any of the following:
                    (a) your access to or use of the Platform, including
                    information provided in connection therewith; (b) your
                    breach of our Terms or of applicable law; or (c) any
                    misrepresentation made by you. You will cooperate as fully
                    as required by us in the defence or settlement of any
                    Third-Party Claim. Your rights with respect to this Platform
                    are not modified by the foregoing indemnification if the
                    laws of your country of residence, applicable because of
                    your use of the Platform, do not permit it.
                  </li>
                  <li>
                    <b>Disclaimer of Liability:</b>Samunnati takes utmost care
                    and diligence when collecting and presenting certain
                    historical or predictive data sets on the Platform, such as
                    price trends and weather details. However, any acts done by
                    the users by placing reliance on such information and the
                    profits or losses incurred upon the use of such trends,
                    charts and information are solely at the discretion of the
                    user and Samunnati will not be held accountable for any loss
                    on account of reliance upon historical or predictive data
                    sets provided by Samunnati. Samunnati does not control and
                    is not responsible for controlling how or when our users use
                    the Platform or Services. We are not responsible for, and
                    are not obligated to control the actions or information
                    (including content) of our users or other third parties.
                    Accordingly, you release us, our subsidiaries, affiliates,
                    and our and their directors, officers, employees, partners,
                    and agents from any claim, complaint, cause of action, or
                    liability that you have against any third parties relating
                    to, arising out of, or in any way connected with the use of
                    the App. Your rights with respect to Platform are not
                    modified by the foregoing disclaimer if the laws of your
                    country of residence, applicable because of your use of our
                    Services, do not permit it.
                  </li>
                  <li>
                    <b>Exclusions.</b>Some jurisdictions do not allow the
                    exclusion of certain warranties or the limitation or
                    exclusion of liability for direct, incidental, or
                    consequential damages. Accordingly, some of the above
                    limitations and disclaimers may not apply to you. To the
                    extent that Samunnati may not, as a matter of applicable
                    law, disclaim any implied warranty or limit its liabilities,
                    the scope and duration of such warranty and the extent of
                    Samunnati&#39;s liability will be limited to the fullest
                    extent permitted by applicable law.
                  </li>
                  <li>
                    <b>Events Beyond our Control.</b>We are not responsible for
                    events beyond our control and if we have to cancel or delay
                    providing access to the Platform or to Services on account
                    of any event beyond our control, then we will use
                    commercially reasonable efforts to take steps to minimize
                    the effect of the delay or cancellation, but you expressly
                    acknowledge and agree that we have no obligation to you for
                    any such delay or cancellation.
                  </li>
                  <li>
                    <b>Applicable Law &amp; Dispute Resolution.</b> These Terms
                    are governed by the laws of India. You agree that any
                    dispute arising out of or relating to these Terms and the
                    Privacy Policy shall be deemed to be a dispute under a
                    single agreement and will be brought exclusively to the
                    courts of Chennai, India.
                  </li>
                  <li>
                    <b>Miscellaneous.</b>If any provision of these Terms is
                    found to be invalid or unenforceable, the Terms will be
                    enforced to the extent permissible and, to the extent
                    invalid or unenforceable, such provision shall be deemed
                    modified to the most limited extent necessary to be valid
                    and enforceable, in accordance with applicable law, while
                    still as fully as possible carrying out the intent of the
                    original provision, and the remainder of these Terms will
                    remain in full force and effect. Failure by Samunnati to
                    prosecute any right with respect to a default hereunder will
                    not constitute a waiver by Samunnati of the right to enforce
                    rights with respect to the same or any other breach.
                  </li>
                  <li>
                    <b>Entire Agreement.</b>These Terms together with any
                    In-Platform Terms constitute the entire agreement between
                    you and Samunnati with respect to the subject matter
                    thereof. Unless a mutually executed agreement between you
                    and us states otherwise, no additional or inconsistent terms
                    in any other agreement or document shall modify these Terms
                    or the In-Platform Terms, and these Terms supersede any
                    previous oral or written communications or documents
                    (including, if you are obtaining an update, any agreement
                    that may have been included with an earlier version of the
                    Platform).
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export const Termsandcondition = TermsandconditionClass;
