import React, { Fragment } from "react";
import { NavLink } from "react-router-dom";
import { UncontrolledCollapse } from "reactstrap";
import { getAccessdata } from "service/helperFunctions";
import { history } from "service/helpers";

import "./SideBar.scss";

const SideBar = ({
  isOpen,
  toggle,
  accessData,
  fpo,
  onLogout,
  profileImg = false,
  toggleFeedBack,
}) => {
  const nav_user = require("assets/images/default-profile-img.png");

  const handleRedirect = (link) => {
    console.log(link);
    history.push(link);
    toggle();
  };

  return (
    <Fragment>
      <div className={`side_bar ${isOpen ? "open" : ""}`}>
        <div className="user-profile-pic">
          <b>Hi {localStorage.getItem("userName")}</b>
          {profileImg ? (
            <img src={profileImg} alt="user" />
          ) : (
            <img src={nav_user} alt="user" />
          )}
        </div>
        <ul>
          <NavLink
            to={
              localStorage.getItem("userGroupName") !== "samunnati" &&
              localStorage.getItem("userGroupName") !== "fpo"
                ? "/other/home"
                : localStorage.getItem("userRoleName") ===
                  "Relationship Manager"
                ? "/rm/home"
                : "/fpo/home"
            }
            onClick={toggle}
          >
            <li>Home</li>
          </NavLink>
          {localStorage.getItem("userGroupName") === "fpo" && (
            <NavLink to="/fpo/about-us" onClick={toggle}>
              <li>About Us</li>
            </NavLink>
          )}
          <NavLink
            to={
              localStorage.getItem("userGroupName") !== "samunnati" &&
              localStorage.getItem("userGroupName") !== "fpo"
                ? "/other/faq"
                : localStorage.getItem("userRoleName") ===
                  "Relationship Manager"
                ? "/rm/faq"
                : "/fpo/faq"
            }
            onClick={toggle}
          >
            <li>FAQ</li>
          </NavLink>
          {localStorage.getItem("userRoleName") != "fpo" && (
            <NavLink to="/academy/home" onClick={toggle}>
              <li>Academy</li>
            </NavLink>
          )}
          {(localStorage.getItem("userGroupName") === "fpo" ||
            localStorage.getItem("userGroupName") === "Ceo") &&
            !window.location.pathname.includes("fpo/home") && (
              <li>
                <div id="fpo-dropdown">
                  <span>FPO Management</span>
                  <i className="fas fa-chevron-down ml-2"></i>
                </div>
                <UncontrolledCollapse toggler="#fpo-dropdown">
                  {fpo.map((comp, index) => {
                    let permissionData = getAccessdata(
                      accessData,
                      comp.serviceName
                    );
                    let { viewAccess = false } = permissionData;
                    return (
                      viewAccess && (
                        <div
                          className="d-flex align-items-center py-2"
                          key={index}
                          onClick={() => handleRedirect(comp.link)}
                        >
                          <p className="mb-0 ml-2">{comp.name}</p>
                        </div>
                      )
                    );
                  })}
                </UncontrolledCollapse>
              </li>
            )}
          <NavLink to="/fpo/profile" onClick={toggle}>
            <li>My Profile</li>
          </NavLink>
          {localStorage.getItem("userGroupName") === "fpo" && (
            <li onClick={toggleFeedBack}>Feedback</li>
          )}
          <li onClick={onLogout}>Logout</li>
        </ul>
      </div>
      <div
        className={`nav-overlay ${isOpen ? "open" : ""}`}
        onClick={() => toggle()}
      ></div>
    </Fragment>
  );
};

export default SideBar;
