import React, { Component, Fragment } from "react";

import { Swiper, SwiperSlide } from "swiper/react";

import SwiperCore, { Navigation } from "swiper";

import { connect } from "react-redux";

import { bindActionCreators } from "redux";

import { getNewsAndTestimonals } from "action/HomeAct";

import { getDescriptionData } from "service/helperFunctions";

import "./testimonials.scss";

const quote1 = require("assets/svg/testimonials/quote1.svg");
const quote2 = require("assets/svg/testimonials/quote2.svg");
const arrow_left = require("assets/images/icon/arrow-left.svg");
const arrow_right = require("assets/svg/posts/arrow-right.svg");

export class TestimonialsClass extends Component {
  constructor() {
    super();
    SwiperCore.use([Navigation]);
    this.state = {
      testimonials: [],
    };
  }

  //life cucles
  componentDidMount() {
    this._getTestimonialsFromProps();
  }

  //get testimonials details
  _getTestimonialsFromProps = () => {
    let dataSet = [];
    this.props.testimonials &&
      this.props.testimonials.map((testimonial) => {
        dataSet.push({
          profile: testimonial.uploadImage,
          content: testimonial.description,
          name: testimonial.name,
          position: testimonial.designation,
          title: testimonial.title,
        });
      });
    this.setState({
      testimonials: dataSet,
    });
  };

  //life cucles
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.testimonials !== this.props.testimonials) {
      this._getTestimonialsFromProps();
    }
  }

  render() {
    const params = {
      spaceBetween: 10,
      loop: false,
      breakpoints: {
        600: {
          slidesPerView: 1,
        },
        768: {
          slidesPerView: 3,
        },
      },
      navigation: {
        nextEl: "#academy-next",
        prevEl: "#academy-prev",
      },
    };
    const { testimonials } = this.state;
    return (
      <Fragment>
        <div className="container-sa d-flex mb-4">
          <p className="mx-auto fs-32 fw-600 text-grey-1e mt-5">Testimonials</p>
        </div>
        <div className="testimonial-page">
          {testimonials.length !== 0 ? (
            <Swiper {...params}>
              {testimonials.map((testimony, index) => {
                return (
                  <SwiperSlide key={index}>
                    <div className="testimonial-card px-3 py-3 d-flex align-items-center justify-content-center mr-4 shadow">
                      <div className="row mb-3">
                        <div className="col-12 text-center fw-600 fs-20 text-black mb-3">
                          {testimony.title}
                        </div>
                        <div className="col-12 text-center fw-600 fs-16 text-grey-7b mb-3 content-disc">
                          <span>
                            <img className="quote1" alt="quote" src={quote1} />
                          </span>
                          <span
                            className="content-text"
                            dangerouslySetInnerHTML={{
                              __html: getDescriptionData(
                                testimony.content,
                                200
                              ),
                            }}
                          ></span>
                          <span>
                            <img className="quote2" alt="quote" src={quote2} />
                          </span>
                        </div>
                        <div className="col-12 text-center fw-600 fs-16 text-grey-7b mb-3 d-flex justify-content-center">
                          <div className="media">
                            <img
                              className="rounded-circle testimonial-profile mr-3"
                              src={testimony.profile}
                              alt="profile"
                            />
                          </div>
                          <div className="d-flex flex-column">
                            <h5 className="mt-4 mb-2 text-grey-1e fs-18 fw-600">
                              {testimony.name}
                            </h5>
                            <h5 className="mt-0 mb-2 text-grey-1e fs-18 fw-600">
                              {testimony.position}
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          ) : (
            <p className="py-4 mb-0 text-black-50 fs-22 line-height-25 text-center">
              No data found
            </p>
          )}
          {testimonials.length !== 0 ? (
            <div className="d-flex justify-content-center">
              <input
                type="image"
                src={arrow_left}
                alt="left"
                width="40"
                height="40"
                id="academy-prev"
              />
              <input
                type="image"
                src={arrow_right}
                alt="left"
                width="40"
                height="40"
                id="academy-next"
              />
            </div>
          ) : (
            ""
          )}
        </div>
      </Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getNewsAndTestimonals,
    },
    dispatch
  );
};
let component = TestimonialsClass;

export const Testimonials = connect(null, mapDispatchToProps)(component);
