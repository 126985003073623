import React, { Component } from "react";
import "./LatestCourses.scss";
import placeholder from "assets/images/placeholder.png";
import rightCircle from "assets/images/academy/arrow-right-circle.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from "swiper";
import { getDescriptionData } from "service/helperFunctions";
import clock from "assets/images/icon/clock.svg";
import { history } from "service/helpers";
export class LatestCourses extends Component {
  constructor() {
    super();
    SwiperCore.use([Navigation]);
    this.state = {
      latestCoursesList: [],
    };
  }
  render() {
    const params = {
      slidesPerView: 1,
      spaceBetween: 20,
      breakpoints: {
        500: {
          slidesPerView: 2,
        },
        768: {
          slidesPerView: 3,
        },
      },
    };

    let { trendingCourseData = [] } = this.props;

    return (
      <>
        {trendingCourseData.length !== 0 ? (
          <section className="latest-courses-section">
            <div className="container-fluid">
              <div className="row align-items-center">
                <div className="col-sm-3 p-0">
                  <div className="latest-courses-bg-title">
                    <h2>
                      Latest <br /> courses
                    </h2>
                  </div>
                </div>
                <div className="col-sm-9">
                  <div className="d-flex justify-content-end mr-4">
                    <p
                      className="my-3 text-black-50 fs-18 text-underline line-height-23 fw-700 cursor-pointer"
                      onClick={() => history.push("/academy/allcourse")}
                    >
                      {"View all"}
                    </p>
                  </div>
                  <div className="row">
                    <Swiper {...params}>
                      {trendingCourseData.map((data, index) => {
                        let {
                          title,
                          description,
                          durationHours = 0,
                          durationMinutes = 0,
                        } = data;
                        return (
                          <SwiperSlide key={"latestCou" + index}>
                            <div key={"latestCou" + index} className="">
                              <div
                                className="latest-courses-card"
                                style={{
                                  backgroundImage: `url('${
                                    data.thumbnailFileUrl === "bannerFileUrl" ||
                                    data.thumbnailFileUrl === null
                                      ? placeholder
                                      : data.thumbnailFileUrl
                                  }')`,
                                }}
                              >
                                <div className="latest-courses-content d-flex justify-content-between flex-column">
                                  <div>
                                    <h5 className="fs-18 line-height-31 text-green-18 font-weight-bold mb-1">
                                      {" "}
                                      {getDescriptionData(title, 25)}{" "}
                                    </h5>
                                    <p
                                      dangerouslySetInnerHTML={{
                                        __html: getDescriptionData(
                                          description,
                                          25
                                        ),
                                      }}
                                    ></p>
                                  </div>
                                  <div className="d-flex justify-content-between align-items-center">
                                    <div className="time-trending-now">
                                      <img src={clock} alt="clock" />{" "}
                                      {data.durationHours} {"hr "}
                                      {data.durationMinutes} {"min"}
                                    </div>
                                    <img
                                      onClick={() =>
                                        history.push(
                                          `/academy/courses/${data.courseId}`
                                        )
                                      }
                                      src={rightCircle}
                                      alt="rightCircle"
                                      className="cursor-pointer"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </SwiperSlide>
                        );
                      })}
                    </Swiper>
                  </div>
                </div>
              </div>
            </div>
          </section>
        ) : null}
      </>
    );
  }
}
