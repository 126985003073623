import React, { Component } from "react";
import moment from "moment";
import profilePicture from "assets/images/default-profile-img.png";
import { getFilePathDownload } from "service/api";
export class MemberProfile extends Component {
  constructor(props) {
    super();
    this.state = {
      randomKey: "",
    };
  }

  componentDidMount() {
    this.handleDownload();
  }

  handleDownload = () => {
    this.setState({
      randomKey: Math.random().toString().substr(2, 4),
    });
  };

  render() {
    let { memberDetail = {} } = this.props;
    let { randomKey } = this.state;

    let {
      memberfarminfo,
      memberPhotoName,
      memberFirstName = "",
      memberPhotoUrl = "",
      memberLastName = "",
      memberDOB = "",
      gender = "",
      phone = "",
      address = "",
      village = "",
      hasSmartPhone,
      stateMaster,
      districtNames,
      pincode = "",
      addressProofDocumentUrl,
      identityProofDocumentUrl,
      memberLiveStockInfo = [],
      memberasset = [],
      memberMapping = {},
      memberID = "",
    } = memberDetail;

    let { figUsers = {} } = memberMapping || {};

    let { name } = figUsers || {};
    return (
      <>
        <div className="member-profile member-profile-view-area px-3 pb-2 d-flex align-items-center shadow rounded px-0 container-sa">
          <div className="row w-100">
            <div className="col-md-2 d-flex align-items-center justify-content-center">
              <div className="member-profile-image-area">
                <img
                  className="member-profile-image"
                  // src={
                  //   memberPhotoUrl !== null ? memberPhotoUrl : profilePicture
                  // }
                  src={
                    memberPhotoName !== null && memberPhotoName !== ""
                      ? memberPhotoUrl
                      : profilePicture
                  }
                  alt="profile"
                />
              </div>
            </div>
            <div className="col-md-7 py-3">
              <div className="member-profile-user-container">
                <div className="member-profile-user mb-3">
                  {`${memberFirstName ? memberFirstName : null} ${
                    memberLastName ? memberLastName : null
                  }`}
                </div>
                <div className="row">
                  <div className="col-md-4 mb-2">
                    <div className="mb-2 member-profile-key">{"Member ID"}</div>
                    <div className="mb-3 member-profile-content">
                      {memberDOB !== "" ? memberID : "-"}
                    </div>
                  </div>
                  <div className="col-md-4 mb-2">
                    <div className="mb-2 member-profile-key">
                      {"Date of birth"}
                    </div>
                    <div className="mb-3 member-profile-content">
                      {memberDOB !== null
                        ? moment(memberDOB).format("DD MMMM YYYY")
                        : "-"}
                    </div>
                  </div>
                  <div className="col-md-4 mb-2">
                    <div className="mb-2 member-profile-key">{"Gender"}</div>
                    <div className="member-profile-content">
                      {gender && gender !== "" ? gender : "-"}
                    </div>
                  </div>
                  <div className="col-md-4 mb-2">
                    <div className="mb-2 member-profile-key">
                      {"Phone number"}
                    </div>
                    <div className="member-profile-content mb-3">
                      {phone ? phone : "-"}
                    </div>
                  </div>
                  <div className="col-md-4 mb-2">
                    <div className="mb-2 member-profile-key">Address</div>
                    <div className="member-profile-content">
                      {address ? address : "-"}
                    </div>
                  </div>
                  <div className="col-md-4 mb-2">
                    <div className="mb-2 member-profile-key">Address Proof</div>
                    <div className="member-profile-content mb-3">
                      {addressProofDocumentUrl ? (
                        <a
                          className="fs-16 text-green-18 text-underline cursor-pointer"
                          href={getFilePathDownload + addressProofDocumentUrl}
                          download
                        >
                          {"Download"}
                        </a>
                      ) : (
                        "-"
                      )}
                    </div>
                  </div>
                  <div className="col-md-4 mb-2">
                    <div className="mb-2 member-profile-key">
                      Identity proof
                    </div>
                    <div className="member-profile-content">
                      {identityProofDocumentUrl ? (
                        <a
                          className="fs-16 text-green-18 text-underline cursor-pointer"
                          href={getFilePathDownload + identityProofDocumentUrl}
                          download
                        >
                          {"Download"}
                        </a>
                      ) : (
                        "-"
                      )}
                    </div>
                  </div>
                  <div className="col-md-4 mb-2">
                    <div className="mb-2 member-profile-key">
                      {"Smart Phone"}
                    </div>
                    <div className="member-profile-content mb-3">
                      {hasSmartPhone ? hasSmartPhone : "-"}
                    </div>
                  </div>
                  <div className="col-md-4 mb-2">
                    <div className="mb-2 member-profile-key">Village</div>
                    <div className="member-profile-content">
                      {village ? village : "-"}
                    </div>
                  </div>
                  <div className="col-md-4 mb-2">
                    <div className="mb-2 member-profile-key">State</div>
                    <div className="member-profile-content mb-3">
                      {stateMaster ? stateMaster.stateName : "-"}
                    </div>
                  </div>
                  <div className="col-md-4 mb-2">
                    <div className="mb-2 member-profile-key">District</div>
                    <div className="member-profile-content">
                      {districtNames ? districtNames.districtName : "-"}
                    </div>
                  </div>
                  <div className="col-md-4 mb-2">
                    <div className="mb-2 member-profile-key">Pincode</div>
                    <div className="member-profile-content">
                      {pincode ? pincode : "-"}
                    </div>
                  </div>
                  <div className="col-md-4 mb-2">
                    <div className="mb-2 member-profile-key">FIG name</div>
                    <div className="member-profile-content">
                      {name ? name : "-"}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3 d-flex align-items-center scroll-x">
              <div className="d-flex flex-md-wrap flex-nowrap gap-2 justify-content-center">
                <div className="rounded-circle member-profile-info-container-farm m-2">
                  <div>
                    {memberfarminfo !== null && memberfarminfo !== undefined
                      ? (memberfarminfo.rabiMapId &&
                          memberfarminfo.rabiMapId.length) +
                        (memberfarminfo.kharifMapId &&
                          memberfarminfo.kharifMapId.length) +
                        (memberfarminfo.livecropId &&
                          memberfarminfo.livecropId.length)
                      : 0}
                  </div>
                  <p className="mb-0 fs-24 font-weight-bold text-white">
                    Crops
                  </p>
                </div>
                <div className="rounded-circle member-profile-info-container-farm m-2">
                  <div>
                    {memberfarminfo && memberfarminfo.plotAcreage !== null
                      ? memberfarminfo.plotAcreage
                      : 0}
                  </div>
                  <p className="mb-0 fs-24 font-weight-bold text-white">
                    Acres
                  </p>
                </div>
                <div className="rounded-circle member-profile-info-container-farm m-2">
                  <div>
                    {memberLiveStockInfo.length !== 0
                      ? memberLiveStockInfo.length
                      : 0}
                  </div>
                  <p className="mb-0 fs-24 font-weight-bold text-white">
                    Livestock
                  </p>
                </div>
                <div className="rounded-circle member-profile-info-container-acres m-2">
                  <div>{memberasset.length !== 0 ? memberasset.length : 0}</div>
                  <p className="mb-0 fs-24 font-weight-bold text-white">
                    Assets
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
