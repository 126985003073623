import React, { Component, Fragment } from "react";

import { Form } from "reactstrap";

import { NormalInput, NormalSelect, ConfirmationPopup } from "component/common";

import { Toast } from "service/toast";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  membersView,
  memberBankAdd,
  fileUpload,
  getMemberBankDetail,
  EditBankDetail,
  getMember,
  deleteBankDetails,
} from "action/MemberAct";
import SimpleReactValidator from "simple-react-validator";
import { history } from "service/helpers";

import arrow_up from "assets/svg/add_member/arrow_up.svg";
import upload from "assets/svg/add_member/upload.svg";

class BankForm extends Component {
  constructor(props) {
    super();
    this.state = {
      members: [],
      memberId: "",
      account_number: "",
      bank_name: "",
      bank_branch: "",
      IFSC: "",
      account_type: "Current",
      chequeImage: null,
      chequeImage_name: "",
      isImageUploading: false,
      bank_data: null,
      isDataUploading: false,
      isDelete: false,
      accountType: [
        { value: "Current", label: "Current" },
        { value: "Savings", label: "Savings" },
      ],
    };
  }
  validator = new SimpleReactValidator({
    element: (message) => (
      <span className="error-message text-danger fs-14">{message}</span>
    ),
    autoForceUpdate: this,
  });

  handleFile = (e) => {
    let file = e.target.files[0];
    if (file !== undefined) {
      this.setState({ chequeImage: null }, () => {
        this.setState({
          isImageUploading: true,
          chequeImage_name: "Uploading ....",
          chequeImage: file,
        });
        const formData = new FormData();
        formData.append("file", file);

        this.props
          .fileUpload(formData)
          .then((data) => {
            document.getElementById("handleFile").value = null;
            this.setState({
              chequeImage: data[0].fileUrl,
              chequeImage_name: data[0].fileName,
              isImageUploading: false,
            });
          })
          .catch(() => {
            this.setState({
              chequeImage: null,
              chequeImage_name: "",
              isImageUploading: false,
            });
          });
      });
    }
  };

  handleChange = ({ target: { name, value } }) => {
    this.setState(
      {
        [name]: value,
      },
      () => {
        if (name == "memberId") {
          this.props.getMemberBankDetail({ id: value }).then((data) => {
            if (data == null) {
              this.setState({
                account_number: "",
                bank_name: "",
                bank_branch: "",
                IFSC: "",
                account_type: "",
                chequeImage: null,
                chequeImage_name: "",
                isImageUploading: false,
                bank_data: null,
                isDataUploading: false,
              });
            } else {
              this.setState({
                bank_data: data,
                memberId: data.memberID ? data.memberID : "",
                account_number: data.acountNumber
                  ? data.acountNumber.toString()
                  : "",
                bank_name: data.bankName ? data.bankName : "",
                bank_branch: data.bankBranch ? data.bankBranch : "",
                IFSC: data.IFSC ? data.IFSC : "",
                account_type: data.accountType ? data.accountType : "",
                chequeImage:
                  data.chequeImageUrl !== null ? data.chequeImageUrl : "",
                chequeImage_name:
                  data.chequeImageName !== "" ? data.chequeImageName : "",
              });
            }
          });
        }
      }
    );
  };

  handleSave = (e) => {
    if (this.validator.allValid()) {
      const {
        memberId,
        account_number,
        bank_name,
        bank_branch,
        IFSC,
        account_type,
        chequeImage,
        chequeImage_name,
        isImageUploading,
        bank_data,
      } = this.state;
      if (!isImageUploading) {
        let body = {
          memberID: parseInt(memberId),
        };

        if (chequeImage_name !== "") {
          body.chequeImageName = chequeImage_name;
          body.chequeImageUrl = chequeImage;
        }
        if (account_type !== "") {
          body.accountType = account_type;
        }
        if (IFSC !== "") {
          body.IFSC = IFSC;
        }
        if (bank_branch !== "") {
          body.bankBranch = bank_branch;
        }
        if (bank_name !== "") {
          body.bankName = bank_name;
        }
        if (account_number !== "") {
          body.acountNumber = parseInt(account_number);
        }

        this.setState({ isDataUploading: true });

        if (bank_data == null) {
          this.props.memberBankAdd(body).then((data) => {
            if (data === 200) {
              this.validator.hideMessages();
              history.push("/fpo/member");
            }
            this.setState({ isDataUploading: false });
          });
        } else {
          this.props.EditBankDetail(body).then((data) => {
            if (data === 200) {
              this.validator.hideMessages();
              history.push("/fpo/member");
            }
            this.setState({ isDataUploading: false });
          });
        }
      } else {
        Toast({ type: "error", message: "Please wait Image is uploading" });
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  _clearData = () => {
    this.setState({
      account_number: "",
      bank_name: "",
      bank_branch: "",
      IFSC: "",
      account_type: "Current",
      chequeImage: null,
      chequeImage_name: "",
      isImageUploading: false,
      bank_data: null,
      isDataUploading: false,
    });
  };

  //delete bank details
  handleDelete = () => {
    this.setState({ isDataUploading: true });
    let { memberId } = this.state;
    let { deleteBankDetails } = this.props;
    let body = {
      id: memberId,
    };
    deleteBankDetails(body).then(() => {
      this._clearData();
    });
  };

  toggleDelete = () => {
    let { isDelete } = this.state;
    this.setState({
      isDelete: !isDelete,
    });
  };

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      if (this.props.isOpen) {
        this.props.membersView().then((data) => {
          let memberDetials = [];
          data.map((list) => {
            memberDetials.push({
              label: list.memberFirstName + " | " + list.phone,
              value: list.memberID,
            });
          });
          this.setState({
            members: memberDetials,
          });
        });
        let { id } = this.props;
        this.props.getMember({ id }).then((data) => {
          let memberBankAccount = data.memberBankAccount;
          if (memberBankAccount.length !== 0) {
            this.setState({
              bank_data: memberBankAccount,
              memberId: memberBankAccount[0].memberID
                ? memberBankAccount[0].memberID
                : "",
              account_number: memberBankAccount[0].acountNumber
                ? memberBankAccount[0].acountNumber
                : "",
              bank_name: memberBankAccount[0].bankName
                ? memberBankAccount[0].bankName
                : "",
              bank_branch: memberBankAccount[0].bankBranch
                ? memberBankAccount[0].bankBranch
                : "",
              IFSC: memberBankAccount[0].IFSC ? memberBankAccount[0].IFSC : "",
              account_type: memberBankAccount[0].accountType
                ? memberBankAccount[0].accountType
                : "",
              chequeImage:
                memberBankAccount[0].chequeImageUrl !== null
                  ? memberBankAccount[0].chequeImageUrl
                  : "",
              chequeImage_name:
                memberBankAccount[0].chequeImageName !== ""
                  ? memberBankAccount[0].chequeImageName
                  : "",
            });
          } else {
            this.setState({
              memberId: id,
              bank_data: null,
            });
          }
        });
      }
    }
  }

  handleRemoveImage = () => {
    this.setState({
      chequeImage: null,
      chequeImage_name: "",
    });
  };

  render() {
    const {
      members,
      memberId,
      account_number,
      bank_name,
      bank_branch,
      IFSC,
      account_type,
      chequeImage,
      chequeImage_name,
      accountType,
      isDataUploading,
      bank_data,
      isImageUploading,
      isDelete,
    } = this.state;
    let { deleteAccess = false } = this.props;
    return (
      <Fragment>
        <div className="px-md-5 px-3 py-4 add-member-area">
          <div className="d-flex flex-md-row flex-column justify-content-between align-items-md-center align-items-start mb-3">
            <div className="add-member-form-container-heading w-md-50 w-100">
              Bank
            </div>
            <div className="d-flex justify-content-md-end justify-content-between align-items-center mt-md-0 mt-2 w-md-50 w-100">
              {isDataUploading ? (
                <div className="d-flex align-items-center h-100">
                  {bank_data != null && deleteAccess && (
                    <div className="mr-3 d-flex align-items-center h-100">
                      <p className="mb-1 font-weight-bold fs-20 d-flex align-items-center mt-2">
                        <i className="icon-Delete fs-18" />
                        <span className="text-danger fs-18 pl-3">
                          {"Delete"}
                        </span>
                      </p>
                    </div>
                  )}
                  <div className="add-member-form-container-button d-flex align-items-center">
                    {"Save & next"}
                  </div>
                </div>
              ) : (
                <div className="d-flex align-items-center h-100">
                  {bank_data != null && deleteAccess && (
                    <div className="mr-3 d-flex align-items-center h-100">
                      <p
                        className="mb-1 font-weight-bold fs-20 cursor-pointer d-flex align-items-center mt-2"
                        onClick={() => this.toggleDelete()}
                      >
                        <i className="icon-Delete fs-18" />
                        <span className="text-danger fs-18 pl-3">
                          {"Delete"}
                        </span>
                      </p>
                    </div>
                  )}
                  <div
                    className="add-member-form-container-button cursor-pointer d-flex align-items-center"
                    onClick={this.handleSave}
                  >
                    {"Save & next"}
                  </div>
                </div>
              )}
              <img
                className="ml-3 cursor-pointer fs-12"
                src={arrow_up}
                alt="arrow_up"
                name="bank"
                height="10"
                width="10"
                onClick={this.props.collapse}
              />
            </div>
          </div>
          <div className="row">
            {/* <div className="col-6">
            <NormalSelect
              isHalfWidthForMemberProfile={true}
              label="Member"
              placeholder="Select"
              value={memberId}
              arrow={true}
              name="memberId"
              options={members}
              handleChange={this.handleChange}
              required={true}
              disabled={true}
            />
            <div className="row">
              <div className="col-9 ml-auto">
                {this.validator.message("member", memberId, "required")}
              </div>
            </div>
          </div> */}
            <div className="col-md-6">
              <NormalInput
                isHalfWidthForMemberProfile={true}
                label="Account number"
                type="number"
                onChange={this.handleChange}
                value={account_number}
                name="account_number"
                disabled={memberId == "" ? true : false}
              />
              <div className="row">
                <div className="col-md-9 ml-auto">
                  {this.validator.message(
                    "account_number",
                    account_number,
                    "max:16"
                  )}
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <NormalInput
                isHalfWidthForMemberProfile={true}
                label="Bank name"
                type="text"
                onChange={this.handleChange}
                value={bank_name}
                name="bank_name"
                disabled={memberId == "" ? true : false}
              />
              <div className="row">
                <div className="col-md-9 ml-auto">
                  {this.validator.message("bank name", bank_name, "max:50")}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <NormalInput
                isHalfWidthForMemberProfile={true}
                label="Bank branch"
                type="text"
                onChange={this.handleChange}
                value={bank_branch}
                name="bank_branch"
                disabled={memberId == "" ? true : false}
              />
              <div className="row">
                <div className="col-md-9 ml-auto">
                  {this.validator.message("bank branch", bank_branch, "max:50")}
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <NormalInput
                isHalfWidthForMemberProfile={true}
                label="IFSC"
                type="text"
                onChange={this.handleChange}
                value={IFSC}
                name="IFSC"
                disabled={memberId == "" ? true : false}
              />
              <div className="row">
                <div className="col-md-9 ml-auto">
                  {this.validator.message("IFSC", IFSC, "max:16")}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <NormalSelect
                isHalfWidthForMemberProfile={true}
                label="Account type"
                placeholder="Select"
                value={account_type}
                arrow={true}
                name="account_type"
                options={accountType}
                handleChange={this.handleChange}
                disabled={memberId == "" ? true : false}
              />
            </div>
          </div>
          <div className="form-container mt-4 pb-3">
            <Form className="d-flex flex-column">
              <div className="mb-4">
                <div className="add-member-form-container-proof">Uploads</div>
                <div className="d-flex justify-content-between align-items-center mb-2 pb-1 container-width">
                  <div className="add-member-form-container-proof-sub">
                    Check/Passbook image
                  </div>
                  {chequeImage != null ? (
                    <div>
                      {!isImageUploading ? (
                        <i
                          className="icon-Delete fs-14 cursor-pointer"
                          onClick={() => this.handleRemoveImage()}
                        ></i>
                      ) : null}
                    </div>
                  ) : null}
                </div>
                <div className="add-member-form-container-proof-container d-flex justify-content-center align-items-center">
                  <input
                    type="file"
                    accept=".jpg, .pdf, .jpeg"
                    name="chequeImage"
                    onChange={this.handleFile}
                    disabled={memberId == "" ? true : false}
                    id="handleFile"
                    title=""
                  />
                  {chequeImage !== null ? (
                    <div className="h-100">
                      <img
                        src={chequeImage}
                        alt={chequeImage_name}
                        className="h-100"
                      />
                    </div>
                  ) : (
                    <>
                      <img className="mr-2" src={upload} alt="upload" />
                      {"Upload files"}
                    </>
                  )}
                </div>
              </div>
            </Form>
          </div>
        </div>
        <ConfirmationPopup
          isOpen={isDelete}
          toggle={this.toggleDelete}
          onSubmit={this.handleDelete}
        />
      </Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      membersView,
      memberBankAdd,
      fileUpload,
      getMemberBankDetail,
      EditBankDetail,
      getMember,
      deleteBankDetails,
    },
    dispatch
  );
};
let component = BankForm;

export const Bank = connect(null, mapDispatchToProps)(component);
