import { CommonPageLoader } from "component/common";
import React, { Component } from "react";
import { Modal } from "reactstrap";
export class RoboCallingPopupClass extends Component {
  state = {};

  render() {
    let {
      className = "modal-dialog-centered robo-calling",
      isOpen = true,
      isApiSuccess = false,
      toggle,
      roboCallingData = "",
    } = this.props;
    return (
      <Modal isOpen={isOpen} toggle={toggle} className={className}>
        {isApiSuccess ? (
          <CommonPageLoader />
        ) : (
          <div className="p-4">
            <div className="d-flex justify-content-end w-100 mb-3">
              <i
                onClick={() => toggle()}
                className="icon-close cursor-pointer"
              />
            </div>
            <div className="d-flex justify-content-center">
              <p className="text-green-18 fs-20 text-center line-height-26 ">
                {roboCallingData}
              </p>
            </div>
          </div>
        )}
      </Modal>
    );
  }
}
let component = RoboCallingPopupClass;

export const RoboCallingPopup = component;
