import React, { Component } from "react";
import { letterAvatar, getDescriptionData } from "service/helperFunctions";
import { history } from "service/helpers";
const User_Profile_1 = require("assets/svg/Members/User_Profile_1.svg");

export default class SearchStrip extends Component {
  render() {
    const {
      profile,
      ComapanyName,
      location,
      crops,
      fpoId,
      isLoginedInUser,
    } = this.props.payload;

    return (
      <div className="search-strip shadow row w-100">
        <div className=" row align-items-center col-5 py-0">
          <div className="col-3">
            {profile ? (
              <img
                src={profile}
                alt="profile"
                className="rounded-circle search-card-profile"
              />
            ) : (
              <img
                src={letterAvatar(ComapanyName, 60)}
                alt="profile"
                className="rounded-circle"
              />
            )}
          </div>
          <div className="col-6 p-0 fs-18 fw-600 gilroy">{ComapanyName}</div>
        </div>
        <div className="col-3 d-flex flex-column justify-content-center">
          <div className="fs-18 fw-600">{location}</div>
          <div className="text-black-50">Location</div>
        </div>
        <div className="col-3 d-flex flex-column justify-content-center">
          <div className="fs-18 fw-600">
            <p className="mb-1">
              {crops === "" ? "-" : getDescriptionData(crops, 50)}
            </p>
          </div>
          <div className="text-black-50">Crops</div>
        </div>
        <div className="col-1 d-flex flex-column justify-content-center">
          <div
            className="text-green-18 fw-600 cursor-pointer"
            onClick={() => {
              localStorage.getItem("userGroupName") === "fpo" && isLoginedInUser
                ? history.push("/fpo/about-us")
                : localStorage.getItem("userGroupName") === "fpo" &&
                  !isLoginedInUser
                ? history.push(`/fpo/about-us/search/${fpoId}`)
                : localStorage.getItem("userGroupName") === "samunnati"
                ? history.push(`/rm/about-us/${fpoId}`)
                : window.open(profile ? profile : User_Profile_1, "_blank");
            }}
          >
            Access
          </div>
        </div>
      </div>
    );
  }
}
