import React, { useRef, forwardRef } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./style.scss";

const RMDate = forwardRef((props, ref) => {
  const {
    name,
    selected,
    className = "",
    onChange,
    label = "",
    isRequired = false,
    dateFormat = "dd/MM/yyyy",
  } = props;
  const datepickerRef = useRef(null);
  const requiredClass = "text-danger font-weight-bold";
  const formControl = "form-control w-100";

  const openDatePicker = () => {
    const datepickerElement = datepickerRef.current;
    datepickerElement.setFocus(true);
  };

  return (
    <div className="d-flex flex-column w-100 mb-2 date_picker">
      <label>
        {label}
        {isRequired && <span className={requiredClass}>*</span>}
      </label>
      <DatePicker
        className={`${formControl} ${className}`}
        selected={selected}
        onChange={(date) => {
          let body = {};
          body = {
            target: {
              name: name,
              value: date,
            },
          };
          onChange(body);
        }}
        showMonthDropdown={true}
        showYearDropdown={true}
        maxDate={new Date()}
        dateFormat={dateFormat}
        ref={datepickerRef || ref}
        required={isRequired}
      />
      <div className="calendar_icon" onClick={() => openDatePicker()}>
        <i className="icon-chevron-down calender-icon fs-22"></i>
      </div>
    </div>
  );
});

export default RMDate;
