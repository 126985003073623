import React, { Component } from "react";
import { Footer } from "component/fpo/home";
import { Navbar } from "component/common";
import "./style.scss";
class PrivacyPolicyClass extends Component {
  constructor(props) {
    super(props);
    this.state = {
      innerWidth: window.innerWidth,
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  handleResize = () => {
    this.setState({
      innerWidth: window.innerWidth,
    });
  };

  render() {
    const { innerWidth } = this.state;
    const isMobile = innerWidth < 768;

    return (
      <div className={`${!isMobile ? "privacy-policy" : null}`}>
        {!isMobile ? (
          <Navbar
            isLanding={
              window.location.href.includes("privacypolicy") ? true : false
            }
          />
        ) : null}
        <div className="bg-blue-f9">
          <div className="container">
            <div className="py-4 ">
              <div className="fs-28 fw-700 text-center text-green-18 pb-4">
                Combined Privacy Policy
              </div>
              <div>
                <ol className="pl-4">
                  <li>
                    Samunnati Agro Solutions Private Limited (hereinafter
                    referred as “<b>Samunnati</b>”, “<b>Company</b>”, “
                    <b>Our</b>”, “<b>We</b>”), a company registered under the
                    Companies Act 2013, having its registered office at Baid Hi
                    Tech Park, 8th Floor, No. 129- B, East Coast Road,
                    Thiruvanmiyur Chennai 600041, owns, manages, and operates
                    this platform under the brand name ‘FPO Gateway’. The term
                    Company/Samunnati/Our/We shall include its affiliates and
                    subsidiaries.
                  </li>
                  <li>
                    <b>Policy & Purpose: </b> This privacy policy{" "}
                    <b>(“Privacy Policy”) </b> adopted by Samunnati governs the
                    collection, possession, use, storage, and transfer of
                    Personally Identifiable Information (<b>PII</b>, which is
                    any information capable of identifying a person, hereinafter
                    referred to as “<b>Personal Information</b>”) or Non
                    Personally Identifiable Information (<b>non-PII</b>, which
                    is the information that cannot be used to distinguish or
                    trace an individual's identity) of individuals accessed or
                    shared with the Company by virtue of use or access of
                    Company’s Services (defined below in Point No 4) through
                    Samunnati’s mobile applications (
                    <a
                      href="https://play.google.com/store/apps/details?id=com.rmapp.rmapplication "
                      target="_blank"
                    >
                      https://play.google.com/store/apps/details?id=com.rmapp.rmapplication 
                    </a>
                    {" , "}
                    <a
                      href="https://play.google.com/store/apps/details?id=com.samunnati.app.fpogateway"
                      target="_blank"
                    >
                      https://play.google.com/store/apps/details?id=com.samunnati.app.fpogateway
                    </a>
                    ) and/or Samunnati’s website (
                    <a href="https://fpogateway.com/) " target="_blank">
                      https://fpogateway.com/)
                    </a>
                    (collectively referred to as the “<b>Platform(s)</b>”).
                    Unless specified otherwise, this Privacy Policy applies only
                    to the Platforms and Services, features, software, and
                    functionalities offered by Samunnati, via these Platforms.
                  </li>
                  <li>
                    <b>Company’s Business & Policy Applicability:</b>
                    Samunnati is engaged in the business of offering working
                    capital, market linkage solutions and advisory including
                    capacity building services to various stakeholders in the
                    agri value chain starting from farmers, Farmer Producer
                    Organisations (“<b>FPOs</b>”), Farmer Producer Companies (“
                    <b>FPCs</b>”), Cooperative Societies and Trusts (hereinafter
                    collectively referred to as “<b>Farmer Collective/s</b>”) to
                    merchants, traders, commission agents, processors,
                    distributors and agri start-ups. By visiting the Platforms,
                    stakeholders such as farmers, representatives of Farmer
                    Collectives, merchants, traders, commission agents,
                    institutional buyers, input manufacturing companies and
                    allied players involved in agricultural value chain or any
                    natural or legal person who accesses or uses the Platforms
                    (hereinafter referred to as “<b>User</b>”, “<b>Users</b>”, “
                    <b>You</b>” or “<b>Your”</b>) can access the Company’s
                    Services (defined in Point No. 4 below). If a natural person
                    is representing a business entity or a body corporate,
                    reference to such terms shall include a reference to such
                    business entity and/or body corporate as well. The Company
                    respects the User’s privacy and is committed to protecting
                    it through the Company’s compliance with this Privacy
                    Policy.
                  </li>
                  <li>
                    Samunnati’s services include (hereinafter collectively
                    referred to as “<b>Services</b>”)
                    <ul>
                      <li>
                        member engagement through Samunnati’s multiple digital
                        products which enable Farmer Collectives to run surveys,
                        the outcomes of which are presented to the Farmer
                        Collectives with actionable insights and offer
                        additional services to farmers and Farmer Collectives;{" "}
                      </li>
                      <li>
                        market linkages: to enable the use of the data collected
                        from Users to discover, match and enable monetary
                        transactions between Farmer Collectives and various
                        other agri-value chain stakeholders including, but not
                        limited to, input companies, financial institutions,
                        start-ups and other agri enterprises;
                      </li>
                      <li>
                        advisory on subjects ranging from optimization of agri
                        resources, financial audits, to quantifying quality of
                        harvest, or any other use case, as deemed fit for
                        empowering Farmer Collectives.{" "}
                      </li>
                    </ul>
                  </li>
                  <li>
                    For the purpose of this Privacy Policy, “
                    <b>Sensitive Personal Data</b> or <b>Information</b>” or “
                    <b>Sensitive Information</b>” means Personal Information,
                    that consists of (i) passwords/OTPs, (ii) financial
                    information, such as bank account and bank statements, debit
                    or credit card details or other payment instrument details,
                    (iii) physical, psychological and mental health condition,
                    (iv) sexual orientation, (v) medical records and history,
                    (vi) biometric information, (vii) official identifier,
                    (viii) sex life, (ix) genetic data, (x) transgender status,
                    (xi) intersex status, (xii) caste or tribe, (xiii) religious
                    or political belief or affiliation, (xiv) any detail
                    relating to the above as provided to the Company for
                    providing a Service, and (xv) any of the information
                    received under each of the aforesaid heads by the Company
                    for processing, or storage or possession under a lawful
                    contract or otherwise.
                  </li>
                  <li>
                    This Privacy Policy describes:
                    <ul>
                      <li>
                        the types of information including Personal Information
                        that the Company may collect from You when You access or
                        use the Platforms to provide the Services chosen by You
                        on these Platforms;
                      </li>
                      <li>
                        Samunnati’s practices for collecting, receiving,
                        possessing, storing, using, handling, maintaining,
                        protecting, and disclosing that information including
                        Security Practices and procedures adopted by Samunnati
                        to protect the User’s Personal Information collected by
                        Samunnati.
                      </li>
                    </ul>
                  </li>
                  <li>
                    This Privacy Policy applies only to the Personal Information
                    Samunnati collects through the Platforms, in email, text and
                    other electronic communications sent through or in
                    connection therewith (collectively “<b>User Information</b>
                    ”). When the Users submit User Information on the Platform,
                    it shall be deemed that the Users have granted Samunnati the
                    right to collect, receive, possess, store, process, handle,
                    share and use such User Information, in accordance with this
                    Privacy Policy (as amended from time to time).
                  </li>
                  <li>
                    This Privacy Policy incorporates the requirements under the
                    Information Technology Act, 2000, as amended from time to
                    time, read with the Information Technology (Reasonable
                    Security Practices and Procedures and Sensitive Personal
                    Data or Information) Rules, 2011.
                  </li>
                  <li>
                    The aim of the Platforms is to enable the Farmer Collectives
                    to:
                    <ul>
                      <li>
                        Manage member, staff, and Board of Directors profiles;
                      </li>
                      <li>
                        Visualize member analytics, grading profiles and scores,
                        financial analysis, and business targets through
                        dashboards;
                      </li>
                      <li>
                        Reach out to its member farmers using focused
                        communication tools;
                      </li>
                      <li>Enable multi-level member mapping;</li>
                      <li>
                        Store all their legal documents digitally in one place;
                      </li>
                      <li>
                        Ease out the process of compliance and governance by
                        tracking important dates related to critical
                        regulations;
                      </li>
                      <li>
                        Provide market linkages with other stakeholders in the
                        agri value chain;
                      </li>
                      <li>Explore market prices and weather information;</li>
                      <li>Share their impact stories;</li>
                      <li>Explore various lending options from Samunnati;</li>
                      <li>
                        Manage their books of accounts, expenses, and fixed
                        assets;
                      </li>
                      <li>
                        Provide farmer procurement module with quality check;
                      </li>
                      <li>Initiate the purchase/sale of inputs and outputs;</li>
                      <li>Enable SKU-based inventory management;</li>
                      <li>Access to Custom Hiring Services module;</li>
                      <li>
                        Manage the individual accounts of members, suppliers &
                        third-party vendors;
                      </li>
                      <li>
                        Receive SMS alerts and digitally generated invoices.
                      </li>
                    </ul>
                    Please note that the Platforms may add additional features
                    and services from time to time, which will be updated to the
                    Privacy Policy made available online.
                  </li>
                  <li>
                    Samunnati may at any time introduce additional features and
                    sub-platforms from time to time on this Platform which will
                    be governed by this Privacy Policy. The Users hereby agree
                    and acknowledge that this Privacy Policy is an overarching
                    policy that governs the use of Samunnati’s Platforms. For
                    disclosures and disclaimers specific to each
                    Service/sub-platform offered by Samunnati now or in the
                    future, Samunnati requests the Users to review and
                    understand the Terms of Use specific to such
                    Service/sub-platform.
                  </li>
                  <li>
                    By accessing the Platforms, the Users consent to be bound by
                    this Privacy Policy and are accepting the measures,
                    protocols and practices involving the collection, use and
                    transfer of Personal Information collected from the Users,
                    as set out hereinbelow.
                  </li>
                  <li>
                    This Privacy Policy does not apply to mobile applications or
                    websites which are not owned or controlled by Samunnati,
                    including third party links and applications with which the
                    User may interact while using the Platforms. Samunnati shall
                    not assume any liability for any harm caused to the User by
                    accessing any third-party websites or links or applications
                    advertised on the Platforms.
                  </li>
                  <li>
                    <b>Collection & Use of Information</b>
                    <ul>
                      <li>
                        <b>Personal Information Collected: </b>When the Users
                        access the Platforms for carrying out certain activities
                        (as listed in Point No. 9) or to avail certain Services
                        (as listed in Point No. 4) or to avail any services that
                        Samunnati may add on the Platforms in the future,
                        Samunnati may ask the Users to provide some information
                        for collecting PII and Non-PII (defined in Point No. 2)
                        data to fulfil certain purposes (defined below). Please
                        note that Samunnati collects Personal Information only
                        for business requirements. Personal Information about
                        Farmer Collectives and/or their member farmers shall be
                        collected for the purposes that are set out in the table
                        below:
                        <br />
                        <div className="pt-3"></div>
                        <b className="fw-600 pl-0">
                          Data Collected by Samunnati and its Purposes:{" "}
                        </b>
                        <ul className="list-style-circle">
                          <li>
                            <b>Name, Date of Birth: </b>Identity validation
                            prior to provision of Services, conducting credit
                            bureau checks, onboarding Users for providing them
                            Services or introducing them to various insurance
                            schemes.
                          </li>
                          <li>
                            <b>Email and/or Mobile Number: </b>For the purposes
                            of credit check by credit bureaus and identification
                            by only such resource institutions which have
                            promoted the Users, to enable secured login on the
                            platforms.
                          </li>
                          <li>
                            <b>
                              Names, phone numbers or email IDs of Chief
                              Executive Officer (“CEO”), Board of Directors and
                              Contact Person/s of the User’s company:{" "}
                            </b>
                            Reaching out to farmers /individuals associated with
                            the company, providing remote accounting, auditing,
                            and company compliance services, conducting credit
                            checks, obtaining support from resource
                            institutions.
                          </li>
                          <li>
                            <b>
                              Details provided by the User for Know Your Member
                              check at{" "}
                              <a
                                href="https://fpoportal.azurewebsites.net/rm-app/ "
                                target="_blank"
                              >
                                https://fpoportal.azurewebsites.net/rm-app/
                              </a>
                              :{" "}
                            </b>
                            One time data collection activity to onboard the
                            members into the Platform’s systems.
                          </li>
                        </ul>
                        While most information the Company collects can be
                        shared only at the discretion of the Users, some
                        Personal Information (an indicative list of which is set
                        out above) is required to be shared to enable the User
                        to access certain Services provided on the Platform/s.
                        If the Users do not provide the information required by
                        Samunnati to provide the Users with a specific Service
                        or feature, the Users may not be able to access such a
                        Service or feature of the Platform/s.
                        <br />
                        If any User avails any Services through Our Platform,
                        Samunnati may collect and store certain information
                        about the User to process Your requests and
                        automatically complete forms for future transactions,
                        including (but not limited to) the User’s phone number,
                        addresses and email.
                      </li>
                      <li>
                        <b>
                          Sensitive Personal Data or Information Collected:{" "}
                        </b>
                        Sensitive Personal Data or Information may include, but
                        not limited to (i) gender of the User, (ii) KYC-related
                        data and/or documents, (iii) bank details such as bank
                        statements and accounts (iv) audited and provisional
                        financial statements and projections and business plans
                        of the User’s company, (v) credit history of the User’s
                        company, its CEO and Board of Directors, (vi)
                        transaction history with Farmer Collectives with respect
                        to their input purchases, output sales and repayment
                        credit purchases, (vii) Passwords/OTPs, (ix) Financial
                        details about the Farmer Collective such as its Share
                        Capital and Turnover. The collection of such Sensitive
                        Personal Data or Information is required to (i)
                        ascertain the identity of the User before giving access
                        to the Platforms for the User or before any lending
                        activity is initiated on the Platform, (ii)assisting
                        strategic partnerships between stakeholders, (iii)
                        providing remote accounting, auditing and company
                        compliance services, and (iv)enabling reaching out to
                        farmers or carrying out post disbursal monitoring of
                        farmer loans / lending products.
                        <br />
                        <div className="pt-3"></div>
                        Samunnati is committed to always keeping all such
                        Sensitive Personal Data or Information safe and ensure
                        that such data/information is only stored in secure
                        servers that are digitally encrypted and provide the
                        highest possible degree of care available under the
                        technology currently in use. Samunnati will not use the
                        User’s Sensitive Personal Data or Information for any
                        purpose other than for such specific purposes as you
                        have expressly consented to its use.
                        <br />
                        <div className="pt-3"></div>
                        Upon registering on the Platforms, the User may from
                        time to time choose to provide payment related financial
                        information (credit card, debit card, bank account
                        details, bank statements, billing address etc.) which is
                        also classified as Sensitive Personal Data or
                        Information. Such payment data may need to be retained
                        only within India to comply with directives of the
                        Reserve Bank of India that apply to the payment service
                        providers Samunnati engages with to provide the
                        Services.
                        <br />
                        <div className="pt-3"></div>
                        Where Samunnati permits any third parties to collect,
                        store and use the User’s Sensitive Personal Data or
                        Information, the Company shall (i) ensure that such
                        third parties maintain the same security standards that
                        are adhered to by Samunnati for the protection of the
                        User’s Sensitive Personal Information and (ii) take
                        reasonable measures to ensure that the third parties do
                        not disclose the Sensitive Personal Information unless
                        such disclosure has been expressly consented to by the
                        User.
                      </li>
                      <li>
                        <b>Non-Personal / Aggregated Data: </b>Apart from the
                        information the Users provide to Samunnati, Samunnati
                        may also collect information related to the User’s
                        crops, Farmer Collective profile, farm details,
                        livestock details, asset/infrastructure details,
                        agronomic content, device type, browser, internet
                        protocol address, demographic information, SMS read
                        permissions, websites the User visits before or after
                        accessing Samunnati’s Platforms, geographic information,
                        details about the Farmer Collective’s promoting and
                        resource institution, business activities in which the
                        User’s associated Farmer Collective is engaged in, and
                        the User’s activity trends or any other related data
                        when using the Platform. Samunnati uses this information
                        to understand Users’ demographics, interests and
                        behaviour to better understand, protect and serve our
                        Users and create anonymous User personas.
                      </li>
                      <li>
                        <b>User Supplied Data: </b>If a User sends the Company
                        personal correspondence, such as emails or letters, or
                        if other Users or third parties send us correspondence
                        about the User’s activities on the Platform, the Company
                        may collect such information into a file specific to the
                        User. Upon contacting Samunnati, the Company may also
                        use the User’s contact information to send the User
                        updates regarding Our products and Services and inform
                        the User about alterations or upgrades to our Services,
                        features, software, and functionalities offered by
                        Samunnati, via the Platform. The User may opt out of
                        receiving any non-essential communications from
                        Samunnati by contacting Samunnati’s Grievance Officer
                        (details in Point No.24 below).
                      </li>
                    </ul>
                    All this information is compiled, analyzed, and shared with
                    relevant stakeholders on an aggregated basis to better serve
                    the Farmer Collectives.{" "}
                    <u>
                      Note that Samunnati may combine the User’s Personal
                      Information or Sensitive Personal Data/Information, other
                      information and information collected from tracking
                      technologies and aggregate it with information collected
                      from other Users using Samunnati’s applications to attempt
                      to provide the Users with the Services and a better
                      experience on the Website.
                    </u>
                  </li>
                  <li>
                    <b>Terms of Usage: </b>Prior to registration, the User will
                    be asked to agree to the ‘Terms of Usage’ to use the
                    Platforms. The Terms of Usage will inter-alia set out the
                    nature of information/data collected and stored, the manner
                    of usage of the data, the manner of taking consent for
                    sharing of data if required, and the actions that a User can
                    take on the Platforms.
                    <br />
                    <div className="pt-3"></div>
                    By using this Platform, the Users consent to the collection,
                    possession, storage, and use of the information that the
                    Users provide for any of the Services that Samunnati offers,
                    and the Users consent to Samunnati’s collection of any
                    changes or updates that the Users may provide to the User
                    Information. Samunnati collects only such User Information
                    that We believe to be relevant and necessary for the purpose
                    of identification and verification and is required to
                    understand the User or User’s interests or provide Services
                    that the User may require or request. It is clarified that
                    the Company shall not be liable, for any reason whatsoever,
                    for the authenticity of any User Information provided by the
                    User to the Company. The User hereby represents, warrants,
                    and confirms that the User Information provided by the User
                    is and shall continue to be valid, true, and accurate.
                  </li>
                  <li>
                    <b>Storage &amp; Security: </b> All information provided by
                    the User is stored and retained on secure cloud storage
                    servers and systems of Samunnati located in India. Samunnati
                    maintains managerial, technical, operational, and physical
                    security practices and procedures to protect data from
                    misuse and unauthorized access and maintain the accuracy of
                    data (<b>“Security Practices”</b>).
                  </li>
                  <li>
                    Such Security Practices involves measures including, but not
                    limited to encryption, firewalls, password protection,
                    limitation of access to specified personnel on a
                    need-to-know basis, de-identification and encryption and
                    other physical security and access control measures that are
                    commensurate to the sensitivity of the information shared by
                    the Users to guard against unauthorized access to the server
                    and systems and information residing on such server and
                    systems. Samunnati’s Security Practices protect against
                    unauthorized access to, or unauthorized alteration,
                    disclosure or destruction of the information. Samunnati’s
                    Security Practices are commensurate to the information
                    assets being protected and with the nature of the business.
                    By using the Platforms, the Users accept and proceed to use
                    the Platforms on the understanding that the Security
                    Practices and procedures as mentioned above, which are
                    designed to protect the information provided by the Users,
                    are reasonable.
                  </li>
                  <li>
                    Samunnati has deployed appropriate physical, electronic, and
                    managerial procedures required under Information Technology
                    Act, 2000, as amended from time to time, read with the
                    Information Technology (Reasonable security practices and
                    procedures and sensitive personal data or information)
                    Rules, 2011, with a view to safeguard the User’s information
                    and (i) help prevent unauthorized access, (ii) maintain data
                    security and (iii) avoid loss of User Information. However,
                    transacting over the internet has inherent risks and
                    absolute security of data when transmitting data online
                    cannot be guaranteed, therefore, Samunnati does not assume
                    any responsibility for any transmission of the User’s
                    information that is at the User’s own risk and/or for the
                    disclosure of the User’s information due to errors in
                    transmission and/or unauthorized acts of third parties.
                  </li>
                  <li>
                    <b>Use of Cookies: </b>Samunnati, and third parties with
                    whom Samunnati partners, shall use pixel tags, cookies, web
                    beacons, mobile device IDs, “flash cookies” and similar
                    files or technologies to collect and store information in
                    respect to the User’s use of the Platform and third-party
                    websites. A cookie can be understood as a small text file
                    that is stored on the User’s computer/ mobile that enables
                    the Company to recognize the User (for example, as a
                    registered User). When the User visits Our Platforms, the
                    website stores the User’s preferences and settings, enhances
                    User’s experience by delivering content and advertising
                    specific to the User’s interests, perform research and
                    analytics, track the User’s use of Our Platforms, and assist
                    with security and administrative functions. This anonymous
                    information is maintained distinctly and is not linked to
                    the User Information the Users submit to Samunnati. A pixel
                    tag (also called a web beacon) can be understood as a small
                    graphic embedded invisibly on a webpage (or an online ad or
                    email) and a unique identifier and is used to count or track
                    things like activity on a webpage or ad impressions or
                    clicks, as well as to access cookies stored on Your
                    computers. Additionally, Users may encounter cookies or
                    other similar devices on certain pages of the Platform/s
                    that are placed by third parties. Samunnati does not control
                    the use of cookies by third parties and shall not be liable
                    for any reason whatsoever for these third-party cookies.
                    Samunnati advises the Users to check their privacy policies
                    for information about third party cookies and other
                    practices. Samunnati does not control the practices of such
                    third parties/ partners and their privacy policies/ cookies
                    policies which govern their interactions with the User.
                    <br />
                    <div className="pt-3"></div>
                    Kindly note that most browsers are set to automatically
                    allow cookies. Most cookies are session cookies that are
                    automatically deleted from the User’s hard drive when the
                    User closes the browser. IT MAY BE POSSIBLE TO DISABLE SOME
                    (BUT NOT ALL) COOKIES THROUGH USER’S MOBILE DEVICE OR
                    BROWSER SETTINGS BUT DOING SO MAY INTERFERE WITH CERTAIN
                    FUNCTIONALITIES ON THE PLATFORMS.
                  </li>
                  <li>
                    <b>Data Transfers & Disclosures: </b> Samunnati will only
                    share the User’s Personal Information or Sensitive Personal
                    Data or Information with third parties with the User’s
                    consent or where expressly identified, such as in the
                    following circumstances:
                    <ul>
                      <li>
                        <b>Provision of Services: </b>
                        Where any Service requested by the User involves a third
                        party, such information, as is reasonably necessary for
                        Samunnati to carry out a Service request involving such
                        third party, may be shared with the third party.
                        Samunnati may also engage with such third party entities
                        to perform functions including but not limited to (i)
                        providing educational content for the Users, (ii)
                        analyzing data, (iii) providing marketing assistance,
                        (iv) providing search results and links (including paid
                        listings and links), (v) validating the identity of a
                        potential borrower before making lending decisions, (vi)
                        reaching out to farmers, (vii) gathering promoters,
                        (viii) performing credit checks, (ix) providing credit
                        assistance services, (x) providing remote accounting,
                        auditing and secretarial services, (xi) converting text
                        data into a machine-readable format for analysis, and
                        (xii) making insurance accessible to Users.{" "}
                        <u>
                          Such companies are only granted access to the Personal
                          Information needed to perform their functions upon
                          entering into Non-Disclosure Agreements and Statements
                          of Work which protect the Users’ rights as provided in
                          this Privacy Policy.
                        </u>
                      </li>
                      <li>
                        <b>Third parties: </b>
                        Samunnati may share the User’s Personal Information with
                        other corporate entities to facilitate transactions on
                        the Platforms, such as input manufacturing companies,
                        dealers, bank and non-bank lenders, institutional and
                        value chain buyers, resource institutions, credit
                        bureaus, insurance companies, financial analytics
                        service providers, chartered accountants, ag-tech
                        start-ups, governmental and quasi-governmental
                        institutions and company secretaries. Samunnati also
                        takes efforts to ensure that any agent or third party
                        that Samunnati engages comply with the same security
                        standards as Samunnati for the protection of the User’s
                        Personal Information and Sensitive Personal Data or
                        Information in Samunnati’s custody.
                      </li>
                      <li>
                        <b>Samunnati and its Affiliates: </b>
                        User’s Personal Information may be shared with
                        Samunnati’s group companies, subsidiaries, affiliates,
                        and their employees for the purposes of the development
                        of new products. Such persons will only use the User’s
                        Personal Information in accordance with this Privacy
                        Policy.
                      </li>
                      <li>
                        <b>Law Enforcement: </b>
                        Samunnati may also use and disclose the information
                        which User provides as we believe to be necessary or
                        appropriate: (a) under applicable law, including laws
                        outside the User’s country of residence; (b) to comply
                        with investigations or legal process; (c) to respond to
                        requests from public and government authorities,
                        including authorities outside the User’s country of
                        residence; (d) to enforce Samunnati’s{" "}
                        <a href="/termsandcondition">Terms & Conditions</a>; (e)
                        to defend Samunnati’s business, or those of any of
                        Samunnati’s affiliates from third party claims; (f) to
                        protect Samunnati’s rights, privacy, safety or property,
                        and/or that of Samunnati’s affiliates, You or others;
                        and (g) to allow Samunnati to pursue available remedies
                        in law or equity to limit damages that Samunnati may
                        sustain.
                      </li>
                      <li>
                        <b>Change in control: </b>
                        We may also share User’s information with third parties
                        if we either: (a) sell, transfer, merge, consolidate or
                        re-organise any part(s) of Samunnati’s business, or
                        merge with, acquire, or form a joint venture with, such
                        third party; or (b) sell or transfer any of Samunnati’s
                        businesses or any of its assets, in which case the
                        information We hold about the Users may be transferred
                        to any prospective buyer, new owner, or other third
                        party involved in such sale or transfer.
                      </li>
                    </ul>
                  </li>
                  <li>
                    <b>USAGE OF YOUR PERSONAL INFORMATION: </b>
                    Samunnati does not keep Your data for longer than is
                    necessary unless Samunnati is required to do so under the
                    applicable law/s. We will use the User’s Personal
                    Information only for so long as is necessary:
                    <ul>
                      <li>
                        to achieve the purposes that have been specifically
                        identified in this Privacy Policy or such purposes as
                        having been identified in any other agreement/s entered
                        with the User
                      </li>
                      <li>
                        as required by applicable laws and regulations, or as
                        may be required for evidentiary purposes
                      </li>
                      <li>
                        as deemed necessary for tax, fraud control, or
                        mitigation, and to conduct a defence in relation to any
                        disputes or claims whether potential or actual
                        (considering statute of limitations under applicable
                        laws).
                      </li>
                    </ul>
                    Your data will be erased/deleted when it has served the
                    purpose for which it was being collected and is no longer
                    necessary, where consent has been withdrawn, or where
                    retention of such data is contrary to or prohibited by
                    applicable law. Further, We will not publish Your Personal
                    Information.
                  </li>
                  <li>
                    <b>YOUR RIGHTS: </b> Users can review, modify, and delete
                    the information they have provided to Samunnati at any time
                    by accessing the Platform and/or withdraw Your consent from
                    allowing us to collect, process or transfer Your information
                    by contacting Our Grievance Officer (See Point No. 24
                    below). Please note that if You withdraw Your consent,
                    Samunnati may choose not to provide any of the Services on
                    the Platforms for which the requirement of information was
                    necessary. Also, please note that Your requests with respect
                    to Your data shall not be complied with if it adversely
                    affects another User or prejudices such User’s rights.
                    <ul>
                      <li>
                        Users have the right to request Samunnati to correct any
                        information they believe is inaccurate. Users also have
                        the right to request Samunnati to complete the
                        information they believe is incomplete.
                      </li>
                      <li>
                        Users have the right to withdraw their consent which
                        they had given earlier. User’s right to withdraw consent
                        relates to any further collection or processing, of the
                        User’s PII, Non-PII or Sensitive Personal
                        Data/Information, however such withdrawal of consent
                        shall not: (i) be retrospective or require the deletion
                        of records required for statutory purposes; or (ii)
                        operate where required for the discharge of ongoing
                        contractual obligations unless the contract under which
                        the PII or Non-PII or Sensitive Personal
                        Data/Information is required to be collected is also
                        terminated with the request for withdrawal of consent to
                        collect such Personal Information, Non-PII or Sensitive
                        Personal Information/Data.
                      </li>
                      <li>
                        Users have the right to data portability, i.e., the
                        Users have the right to receive a copy of their personal
                        data in a commonly used and structured, machine-readable
                        format and have it transferred to another service
                        provider or third party after paying Samunnati a fee
                        which shall be communicated to the Users upon their
                        request.
                      </li>
                    </ul>
                  </li>
                  <li>
                    This Platform may have pop-ups of other websites and the
                    information given to them is not Samunnati’s responsibility.
                    Samunnati’s Platforms may contain links to other
                    websites/applications. Samunnati’s terms, privacy policy
                    applies only to Samunnati’s Platform. If the Users click on
                    a link to another website/application, they are requested to
                    read and understand the privacy policy and terms of such
                    websites/applications.
                  </li>
                  <li>
                    These Platforms may integrate Google’s Voice Assistant to
                    enable the Users to interact with the Platform. The usage of
                    Google’s Voice Assistant will be governed by Google’s
                    privacy policy, which is available at{" "}
                    <a
                      href="https://policies.google.com/privacy"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      https://policies.google.com/privacy
                    </a>
                    . Users are requested to read and understand their privacy
                    policy and terms.
                  </li>
                  <li>
                    <b>GRIEVANCE REDRESSAL: </b>In respect of the Personal
                    Information or Sensitive Personal Data or Information which
                    the Users have provided to Samunnati (“
                    <b>Collected Information</b>”), should the Users wish to (i)
                    request deletion of the Collected Information from
                    Samunnati’s records, (ii) correct or update the Collected
                    Information, (iii) raise any queries or concerns regarding
                    the purpose of retention, end-use(s) of the Collected
                    Information, or (iv) report a breach of this Privacy Policy,
                    please contact Samunnati’s grievance officer, Ramakrishnan
                    CS at{" "}
                    <a href="mailto:gro@samunnati.com" target="_blank">
                      gro@samunnati.com
                    </a>{" "}
                    or 97908 97909.
                  </li>
                  <li>
                    Samunnati’s Grievance Officer is accessible between 09:30 AM
                    to 06:30 PM on weekdays (except public holidays) and will
                    address the User’s queries or grievances within one month
                    from the date of receipt of such queries or grievances
                  </li>
                  <li>
                    To the extent Users are exercising any of their rights under
                    applicable law, Samunnati will respond as soon as possible,
                    and in any event within 30 days or in accordance with the
                    timeframe permitted under applicable law. Users have the
                    right to file a complaint with the Data Protection Authority
                    once constituted, against the refusal to hear a complaint or
                    dissatisfaction in the way the complaint was resolved. We
                    may seek certain information from the User (such as User’s
                    name and registered contact number or email address) during
                    the grievance redressal or feedback process to verify the
                    User’s identity and establish secure modes of
                    correspondence.
                  </li>
                  <li>
                    Kindly note that in such the event that you wish to withdraw
                    your consent for us to collect, retain, use, or process your
                    Collected Information, You may not be able to take full
                    advantage of the entire scope of features and Services
                    offered to You which rely on such Collected Information, and
                    we reserve the right not to provide such Services that
                    require the Collected Information to be supplied to us
                  </li>
                  <li>
                    <b>UPDATES: </b>From time to time, Samunnati may update this
                    Privacy Policy. If Samunnati makes any material changes, we
                    will notify You by email or SMS message or by means of a
                    notice on the Website prior to the change becoming
                    effective. Any changes will become effective when Samunnati
                    posts the revised Privacy Policy on the Platforms. Your
                    continued use of the Platform/s following these changes
                    means that You accept the revised Privacy Policy, as
                    amended. Users are encouraged to review this Privacy Policy
                    from time to time.
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export const PrivacyPolicy = PrivacyPolicyClass;
