import React, { useEffect, useRef, useState } from "react";

import "./Accordian.scss";

export const Accordain = (props) => {
  const { index, list } = props;
  const { title, content } = list;
  const type = index === 0 ? "Apply" : index === 1 ? "Raise" : "Access";
  let contentRef = useRef(null);

  let [isOpen, setIsOpen] = useState(false);

  let [contentHeight, setContentHeight] = useState();

  useEffect(() => {
    const { scrollHeight } = contentRef.current;
    setContentHeight(scrollHeight);
  }, [isOpen]);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="accordian-wrapper">
      <div className="accordian-title" onClick={() => toggle()}>
        <label dangerouslySetInnerHTML={{ __html: title }}></label>
        <i
          className="fas fa-chevron-down"
          style={{
            transform: isOpen ? "rotate(180deg)" : "rotate(0deg)",
          }}
        ></i>
      </div>
      <div
        className="accordian-body"
        ref={contentRef}
        style={{
          height: isOpen ? `${contentHeight}px` : "0px",
        }}
      >
        <div className="accordian-content">
          <span>
            <a href="/login">{type}</a>
          </span>{" "}
          {content}
        </div>
      </div>
    </div>
  );
};
