import React from "react";
import { useHistory } from "react-router-dom";
import "./style.scss";

export const RMHome = ({ module = "rm" }) => {
  const history = useHistory();
  const userName = localStorage.getItem("userName");
  const isRM = module === "rm";

  const cardInfo = [
    {
      label: "Grading Survey",
      image: require("../../../assets/svg/fpo_gateway/engage_prof.svg"),
      redirectTo: `/${module}/rm-grading`,
    },
    {
      label: "KYM Survey",
      image: require("../../../assets/svg/fpo_gateway/events_impact.svg"),
      redirectTo: `/${module}/kym-new`,
    },
  ];

  return (
    <div className="container-sa my-3">
      <div className="rm-dashboard">
        <div className="user-data">
          <h3>Hi {userName},</h3>
        </div>
        <div className="card-details col-12 row justify-content-center">
          {cardInfo?.map(({ label, subLabel, image, redirectTo }, index) => {
            if (!isRM && index === 0) return null;
            return (
              <div
                key={index}
                className="card col-3"
                onClick={() => history.push(redirectTo)}
              >
                <img src={image} alt="card_image" height="75px" width="75px" />
                <h2>{label}</h2>
                {subLabel ? <p>({subLabel})</p> : null}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
