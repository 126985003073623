import React, { Component } from "react";
import Moment from "react-moment";
import { connect } from "react-redux";
import "./events.scss";

import { getDescriptionData } from "service/helperFunctions";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from "swiper";
import defaultEventImage from "assets/images/DefaultEvent.jpg";
import { history } from "service/helpers";

const arrow_left = require("assets/images/icon/arrow-left.svg");
const arrow_right = require("assets/images/icon/arrow-left.svg");

export class EventsClass extends Component {
  constructor() {
    super();
    SwiperCore.use([Navigation]);
    this.state = {
      pastEvent: false,
      fpoevents: [],
    };
  }
  toggle = () => {
    this.props.eventToggle();
  };
  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.setState({
        fpoevents: this.props.fpoevents,
        pastEvent: this.props.pastEvent,
      });
    }
  }
  componentDidMount() {
    this.setState({
      pastEvent: this.props.pastEvent,
      fpoevents: this.props.fpoevents,
    });
  }
  render() {
    const params = {
      slidesPerView: 3,
      spaceBetween: 25,
      loop: false,
      breakpoints: {
        500: {
          slidesPerView: 2,
        },
        768: {
          slidesPerView: 2,
        },
        1044: {
          slidesPerView: 3,
        },
      },
      navigation: {
        nextEl: "#academy-next-page",
        prevEl: "#academy-prev-page",
      },
    };
    const { pastEvent, fpoevents } = this.state;
    return (
      <div className="container-fluid d-flex align-items-center pl-md-5 row aboutus-page-events pr-md-0">
        <div className="col-8">
          <Swiper {...params}>
            {fpoevents.map((event, index) => {
              let {
                eventDocs = [],
                eventDate,
                agendaName,
                description = "",
                eventTitle = "",
                eventId = "",
              } = event;
              return (
                <SwiperSlide key={index}>
                  <div>
                    <div className="card card-width">
                      <div className="">
                        {eventDocs.length !== 0 ? (
                          <img
                            className="card-img-top"
                            src={eventDocs[0].documentUrl}
                            alt={eventDocs[0].fileName}
                          />
                        ) : (
                          <img
                            className="card-img-top"
                            src={defaultEventImage}
                            alt="events.svg"
                          />
                        )}
                      </div>
                      <div className="card-body d-flex flex-column justify-content-between p-3">
                        <div>
                          <div className="d-flex justify-content-center flex-column">
                            <div>
                              <p className="fs-14 line-height-24 text-grey-1c pr-1 mb-0">
                                Event date
                              </p>
                            </div>
                            <div className="d-flex align-items-center w-100">
                              <div className="your-events-card-date mr-2">
                                <Moment format="DD">{eventDate}</Moment>
                              </div>
                              <div className="your-events-card-month">
                                <Moment format="MMMM YYYY">{eventDate}</Moment>
                              </div>
                            </div>
                          </div>
                          <div className="orange card-heading mb-0">
                            {agendaName !== "" && agendaName !== null
                              ? getDescriptionData(agendaName, 20)
                              : ""}
                          </div>
                          <div className="fs-16 font-weight-bold text-green-18 line-height-31">
                            {eventTitle !== "" && eventTitle !== null
                              ? getDescriptionData(eventTitle, 25)
                              : ""}
                          </div>
                          <div className="card-content">
                            <p className="mb-1 line-height-21 fs-14">
                              {description !== "" && description !== null
                                ? getDescriptionData(description, 50)
                                : ""}
                            </p>
                          </div>
                        </div>
                        <div className="d-flex justify-content-center">
                          <p
                            className="text-green-18 font-weight-bold cursor-pointer fs-16 mb-0"
                            onClick={() =>
                              history.push(`/fpo/viewDetails/events&${eventId}`)
                            }
                          >
                            {"View more"}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
        <div className="col-4 pl-md-5 about-us-event">
          <div className="text-bold-awards">Our Events</div>
          <div className="d-flex justify-content-center align-items-center">
            <input
              type="image"
              src={arrow_left}
              alt="left"
              width="40"
              height="40"
              id="academy-prev-page"
            />
            <input
              className="arrow_right"
              type="image"
              src={arrow_right}
              alt="left"
              width="40"
              height="40"
              id="academy-next-page"
            />
          </div>
          <div className="d-flex justify-content-center align-items-center mt-4">
            <label className="fs-20 fw-500 mr-4">Past events</label>
            <label class="switch">
              <input type="checkbox" checked={pastEvent} />
              <span class="slider round" onClick={this.toggle}></span>
            </label>
            <label className="fs-20 fw-500 ml-4">Upcoming events</label>
          </div>
        </div>
      </div>
    );
  }
}

let component = EventsClass;

export const Events = connect(null, null)(component);
