import React, { Fragment } from "react";
import RMNavbar from "component/common/RMNavbar";
import { useEffect } from "react";
import { history } from "service/helpers";

export const RmLayout = (props) => {
  const queryParams = new URLSearchParams(window?.location?.search);
  const token = queryParams.get("token");
  const url = window?.location?.href;
  const isGradingTool = url?.includes("/rm/grading-tool");
  const isMobileLogin = isGradingTool && token !== null ? true : false;

  useEffect(() => {
    let authToken = localStorage.getItem("AuthToken");
    if (!authToken) history.push("/");
  }, [token]);

  return (
    <Fragment>
      {!isMobileLogin ? (
        <RMNavbar location={props.location} module="rm" />
      ) : null}
      {props.children}
    </Fragment>
  );
};
