import React, { Component } from "react";
import { Modal, ModalBody } from "reactstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { deleteCRP, getCRPList } from "action/FigCrpAct";

export class DeleteCRPPopupClass extends Component {
  state = {
    isLoading: false,
  };

  deleteCRPOption = () => {
    const { deleteCRP, id, handleChange } = this.props;
    deleteCRP(id);
    this.props.toggle();
    setTimeout(() => {
      handleChange();
    }, 2000);
  };

  closepopup = () => {
    this.props.toggle();
  };

  render() {
    let { className = "modal-dialog-centered add-crp-fig", isOpen = false } =
      this.props;

    return (
      <Modal isOpen={isOpen} className={className} size={"md"}>
        <ModalBody className="p-md-4">
          <div>
            <div className="modal-label mb-5">
              <h5>Do you want to delete this CRP?</h5>
              <i
                onClick={() => this.closepopup()}
                className="icon-close cursor-pointer"
              />
            </div>
            <div className="delete-button">
              <button onClick={() => this.deleteCRPOption()}>Agree</button>
              <button onClick={() => this.closepopup()}>Cancel</button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      deleteCRP,
      getCRPList,
    },
    dispatch
  );
};

export const DeleteCRPPopup = connect(
  null,
  mapDispatchToProps
)(DeleteCRPPopupClass);
