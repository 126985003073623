import React, { Component, Fragment } from "react";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import staff from "assets/images/members/staff.svg";

import { CRPListMapping } from "../CRPListMapping";
// import { CRPList } from "../CRPList";
import { FIGListMapping } from "../FIGListMapping";

import { debounceFunction } from "service/debounce";

import "./MembersView.scss";
import { MemberList } from "../MemberList";
import { AddFIGPopup } from "../AddFIGPopup";
import { AddCRPPopup } from "../AddCRPPopup";
import { getAllFig } from "action/MemberAct";

export class MembersMappingViewClass extends Component {
  state = {
    activeTab: "4",
    view: "1",
    searchValue: "",
    isPopupOpen: false,
    figOptions: [],
    isOpen: false,
    searchPage: "",
    limit: 10,
  };

  componentDidMount() {
    this.getFig();
  }

  //toggle function (tab change)
  toggle = (tab) => {
    this.setState({
      limit: 10,
      searchValue: "",
      searchPage: "",
      activeTab: tab,
    });
  };

  //handle inputs
  handleInput = ({ target: { value } }) => {
    //Debounce Function
    debounceFunction(() => this.SearchFunction(value), 1000);
  };

  SearchFunction = (searchValue) => {
    //debounce function
    this.setState({ searchValue });
  };

  //handle inputs
  handlePageInput = ({ target: { value } }) => {
    //Debounce Function
    this.SearchPageFunction(value);
  };

  SearchPageFunction = (searchPage) => {
    //debounce function
    this.setState({ searchPage });
  };

  getFig = () => {
    const { getAllFig } = this.props;
    getAllFig().then((data) => {
      let figList = data.map((list) => {
        return { label: list.figName, value: list.figId };
      });
      this.setState({
        figOptions: figList,
      });
    });
  };

  toggleAddFig = () => {
    let { isOpen } = this.state;
    this.setState({
      isOpen: !isOpen,
    });
  };

  toggleAddCrp = () => {
    let { isPopupOpen } = this.state;
    this.setState({
      isPopupOpen: !isPopupOpen,
    });
  };

  handleAdd = () => {
    const { activeTab } = this.state;
    if (activeTab == 2) {
      this.toggleAddCrp();
    } else {
      this.toggleAddFig();
    }
  };

  render() {
    let {
      activeTab,
      searchValue,
      searchPage,
      view,
      figOptions,
      isOpen,
      isPopupOpen,
      limit,
    } = this.state;

    let { edit = false, viewAccess } = this.props;

    return (
      <Fragment>
        <section className="member-section">
          <div className="container-sa">
            <div className="filter-top">
              <div className="row align-items-center">
                <div className="col-md-8">
                  <div className="scroll-x">
                    <Nav
                      tabs
                      className="tab-sammunaty"
                      style={{ minWidth: "max-content" }}
                    >
                      {/* <NavItem>
                    <NavLink
                      className={classnames({
                        active: activeTab === "1",
                      })}
                      onClick={() => this.toggle("1")}
                    >
                      <img src={staff} alt="FIG" />
                      CRP to FPO mapping
                    </NavLink>
                  </NavItem> */}
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: activeTab === "4",
                          })}
                          onClick={() => this.toggle("4")}
                        >
                          <img src={staff} alt="FIG" />
                          Member - FIG Mapping
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: activeTab === "3",
                          })}
                          onClick={() => this.toggle("3")}
                        >
                          <img src={staff} alt="FIG" />
                          FIG - Member Mapping
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: activeTab === "2",
                          })}
                          onClick={() => this.toggle("2")}
                        >
                          <img src={staff} alt="CRP" />
                          CRP - FIG Mapping
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </div>
                </div>
                <div className="col-md-4 mt-md-0 mt-2">
                  <ul className="right-filter-align align-items-center menu">
                    <li>
                      <button
                        className="add-button"
                        onClick={() => this.handleAdd()}
                      >
                        Add {activeTab == 2 ? "CRP" : "FIG"}
                      </button>
                    </li>
                    <li>
                      <span
                        className={`icon-list-view mr-3 fs-20 ${
                          view === "1" ? "view-active" : "view"
                        }`}
                        onClick={() => this.setState({ view: "1" })}
                      />
                      <span
                        className={`icon-grid-view fs-20 ${
                          view === "2" ? "view-active" : "view"
                        }`}
                        onClick={() => this.setState({ view: "2" })}
                      />
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="search-limit">
              <div className="row align-items-center justify-content-end">
                <div>
                  show
                  <select
                    className="form-control-sa ml-2 py-2 px-4"
                    value={limit}
                    onChange={(e) =>
                      this.setState({ limit: parseInt(e.target.value) })
                    }
                  >
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="100">100</option>
                  </select>
                </div>
                <div className="col-md-3 col-10">
                  <div className="ml-2">
                    <input
                      type-="number"
                      value={searchPage}
                      className="form-control form-control-sa"
                      onChange={(e) => this.handlePageInput(e)}
                      placeholder="Search by page number"
                    />
                  </div>
                </div>
                <div className="col-md-4 col-10">
                  <div className="ml-2">
                    <input
                      type-="text"
                      className="form-control form-control-sa"
                      placeholder={
                        activeTab == 4
                          ? "Search by Name or Village or Crop"
                          : activeTab == 3
                          ? "Search by FIG name or Lead Name or Mobile"
                          : activeTab == 2
                          ? "Search by CRP name or Email or Mobile"
                          : ""
                      }
                      onChange={(e) => this.handleInput(e)}
                    />
                  </div>
                </div>
              </div>
            </div>

            <TabContent activeTab={activeTab}>
              {/* <TabPane tabId="1">
              <CRPList
                activeTab={activeTab}
                searchValue={searchValue}
                editAccess={edit}
                id={this.props.id}
                isMappingPage={true}
                view={view}
              />
            </TabPane> */}
              <TabPane tabId="2">
                <CRPListMapping
                  activeTab={activeTab}
                  limit={limit}
                  searchValue={searchValue}
                  searchPage={searchPage}
                  editAccess={edit}
                  id={this.props.id}
                  view={view}
                />
              </TabPane>
              <TabPane tabId="3">
                <FIGListMapping
                  activeTab={activeTab}
                  limit={limit}
                  searchValue={searchValue}
                  searchPage={searchPage}
                  editAccess={edit}
                  id={this.props.id}
                  view={view}
                />
              </TabPane>
              <TabPane tabId="4">
                <MemberList
                  activeTab={activeTab}
                  searchValue={searchValue}
                  searchPage={searchPage}
                  editAccess={edit}
                  viewAccess={viewAccess}
                  id={this.props.id}
                  view={view}
                  limit={limit}
                  isMapping={true}
                  figOptions={figOptions}
                />
              </TabPane>
            </TabContent>
          </div>
        </section>
        <AddFIGPopup
          isOpen={isOpen}
          toggle={() => this.toggleAddFig()}
          id={null}
          fpoId={this.props.id}
          getFig={this.getFig}
          isMapping={true}
        />
        <AddCRPPopup
          isOpen={isPopupOpen}
          toggle={() => this.toggleAddCrp()}
          id={null}
          fpoId={this.props.id}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  accessData: state.home.accessData,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getAllFig,
    },
    dispatch
  );
};
let component = MembersMappingViewClass;

export const MembersMappingView = connect(
  mapStateToProps,
  mapDispatchToProps
)(component);
