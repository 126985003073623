import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  addCreditHistory,
  updateCreditHistory,
  getCreditHistoryById,
  getAllFinancialInstitution,
  deleteCreditHistory,
  keyFinancialCurrentTab,
} from "action/KeyFinancialAct";

import SimpleReactValidator from "simple-react-validator";
import { history } from "service/helpers/index";
import moment from "moment";
import {
  NormalInput,
  NormalButton,
  NormalDate,
  NormalSelect,
  CommonPageLoader,
  ConfirmationPopup,
} from "component/common";
import trash from "assets/svg/Associations/trash.svg";
import { getAccessdata } from "service/helperFunctions";
const arrow_left = require("assets/svg/add_member/arrow_left.svg");

class AddCreditHistoryClass extends Component {
  constructor(props) {
    super();
    this.state = {
      lenderName: "",
      fInsId: "",
      LoanAmountAvailed: "",
      LoanSancationDate: "",
      OutstandingLoanAmount: "",
      RateofInterest: "",
      TenurePeriod: "",
      Tenure: "",
      interestPayment: "",
      LoanType: "",
      securityDescription: "",
      PofLoanRemark: "",
      inputLoan: false,
      cattle: false,
      produce: false,
      infrastructure: false,
      machineries: false,
      other: false,
      reason: "",
      allFinancialInstitution: [],
      isLoading: false,
      isDelete: false,
    };
  }

  validator = new SimpleReactValidator({
    element: (message) => (
      <span className="error-message text-danger fs-14 mt-3">{message}</span>
    ),
    autoForceUpdate: this,
    validators: {
      integer: {
        message: "Only non-negative absolute values are allowed",
        rule: (val, params, validator) => {
          return validator.helpers.testRegex(val, /^\s*([0-9])*$/);
        },
        messageReplace: (message, params) =>
          message.replace(":values", this.helpers.toSentence(params)),
      },
      ip: {
        message: "Atleast one :attribute must be selected",
        rule: (val, params, validator) => {
          return (
            this.state.inputLoan === true ||
            this.state.cattle === true ||
            this.state.produce === true ||
            this.state.infrastructure === true ||
            this.state.other === true
          );
        },
        messageReplace: (message, params) =>
          message.replace(":values", this.helpers.toSentence(params)),
        required: true,
      },
    },
  });

  handleInput = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };
  handleDelete = () => {
    this.setState({ isLoading: true });
    this.props.deleteCreditHistory(this.props.id).then((data) => {
      history.push("/fpo/key-financial");
    });
  };
  handleSubmit = () => {
    this.setState({ isLoading: true });
    const {
      lenderName,
      fInsId,
      LoanAmountAvailed,
      LoanSancationDate,
      OutstandingLoanAmount,
      RateofInterest,
      TenurePeriod,
      Tenure,
      interestPayment,
      LoanType,
      securityDescription,
      PofLoanRemark,
      inputLoan,
      cattle,
      produce,
      infrastructure,
      other,
      machineries,
      reason,
    } = this.state;
    if (this.validator.allValid()) {
      let body = {
        creditId: parseInt(this.props.id),
        lenderName,
        fInsId: parseInt(fInsId),
        PofLoan: [],
      };
      if (LoanAmountAvailed !== "") {
        body.LoanAmountAvailed = parseFloat(LoanAmountAvailed);
      }
      if (LoanSancationDate !== "") {
        body.LoanSancationDate = moment(LoanSancationDate).format("YYYY-MM-DD");
      }
      if (OutstandingLoanAmount !== "") {
        body.OutstandingLoanAmount = parseFloat(OutstandingLoanAmount);
      }
      if (RateofInterest !== "") {
        body.RateofInterest = parseFloat(RateofInterest);
      }
      if (TenurePeriod !== "") {
        body.TenurePeriod = TenurePeriod;
      }
      if (Tenure !== "") {
        body.Tenure = parseInt(Tenure);
      }
      if (interestPayment !== "") {
        body.interestPayment = interestPayment;
      }
      if (LoanType !== "") {
        body.LoanType = LoanType;
      }
      if (securityDescription !== "") {
        body.securityDescription = securityDescription;
      }
      if (PofLoanRemark !== "") {
        body.PofLoanRemark = PofLoanRemark;
      }
      inputLoan === true && body.PofLoan.push({ POLoanID: 6 });
      cattle === true && body.PofLoan.push({ POLoanID: 7 });
      produce === true && body.PofLoan.push({ POLoanID: 8 });
      infrastructure === true && body.PofLoan.push({ POLoanID: 9 });
      machineries === true && body.PofLoan.push({ POLoanID: 10 });
      other === true &&
        body.PofLoan.push({
          POLoanID: 5,
          reason: reason,
        });

      this.props.id
        ? this.props
            .updateCreditHistory(body)
            .then(() => {
              this.validator.hideMessages();
              history.push("/fpo/key-financial");
              this.setState({ isLoading: false });
            })
            .catch(() => {
              this.setState({ isLoading: false });
            })
        : this.props
            .addCreditHistory(body)
            .then(() => {
              this.validator.hideMessages();
              this.setState({
                lenderName: "",
                fInsId: "",
                LoanAmountAvailed: "",
                LoanSancationDate: "",
                OutstandingLoanAmount: "",
                RateofInterest: "",
                TenurePeriod: "",
                Tenure: "",
                interestPayment: "",
                LoanType: "",
                securityDescription: "",
                PofLoanRemark: "",
                inputLoan: false,
                cattle: false,
                produce: false,
                infrastructure: false,
                machineries: false,
                other: false,
                reason: "",
                isLoading: false,
              });
              this.handleBack();
            })
            .catch(() => {
              this.setState({ isLoading: false });
            });
    } else {
      this.setState({ isLoading: false });
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  componentDidMount() {
    this.props.getAllFinancialInstitution().then((data) => {
      let financialData = [];

      data.map((list) => {
        financialData.push({
          value: list.fInsId,
          label: list.institutionType,
        });
      });
      this.setState({
        allFinancialInstitution: financialData,
      });
    });
    if (this.props.id) {
      this.setState({ isLoading: true });
      this.props.getCreditHistoryById(this.props.id).then((data) => {
        this.setState(() => ({
          isLoading: false,
          lenderName: data.lenderName,
          fInsId: data.fInsId,
          LoanAmountAvailed: data.LoanAmountAvailed
            ? data.LoanAmountAvailed.toString()
            : "",
          LoanSancationDate: data.LoanSancationDate
            ? new Date(data.LoanSancationDate)
            : "",
          OutstandingLoanAmount: data.OutstandingLoanAmount
            ? data.OutstandingLoanAmount.toString()
            : "",
          RateofInterest: data.RateofInterest ? data.RateofInterest : "",
          TenurePeriod: data.TenurePeriod ? data.TenurePeriod : "",
          Tenure: data.Tenure ? data.Tenure.toString() : "",
          interestPayment: data.interestPayment ? data.interestPayment : "",
          LoanType: data.LoanType ? data.LoanType : "",
          securityDescription: data.securityDescription
            ? data.securityDescription
            : "",
          PofLoanRemark: data.PofLoanRemark ? data.PofLoanRemark : "",
        }));
        data.poloanmapping.map((purpose) => {
          purpose.POLoanID === 5 && purpose.reason.length > 0
            ? this.setState({ other: true, reason: purpose.reason })
            : purpose.POLoanID === 5 && this.setState({ other: true });
          purpose.POLoanID === 6 && this.setState({ inputLoan: true });
          purpose.POLoanID === 7 && this.setState({ cattle: true });
          purpose.POLoanID === 8 && this.setState({ produce: true });
          purpose.POLoanID === 9 && this.setState({ infrastructure: true });
          purpose.POLoanID === 9 && this.setState({ machineries: true });
        });
      });
    }
  }

  handleBack = () => {
    history.push("/fpo/key-financial");
    this.props.keyFinancialCurrentTab(2);
  };

  toggleDelete = () => {
    let { isDelete } = this.state;
    this.setState({
      isDelete: !isDelete,
    });
  };

  render() {
    const {
      lenderName,
      fInsId,
      allFinancialInstitution,
      LoanAmountAvailed,
      LoanSancationDate,
      OutstandingLoanAmount,
      RateofInterest,
      TenurePeriod,
      Tenure,
      interestPayment,
      LoanType,
      securityDescription,
      PofLoanRemark,
      inputLoan,
      cattle,
      produce,
      infrastructure,
      other,
      reason,
      isLoading,
      machineries,
      isDelete,
    } = this.state;

    let { accessData } = this.props;
    let permissionData = getAccessdata(accessData, "keyFinancials");

    let { deleteAccess = false } = permissionData;

    return (
      <>
        {!isLoading ? (
          <div className="key-financials">
            <div className="d-flex container-sa my-3 ">
              <div
                onClick={() => {
                  this.handleBack();
                }}
                className="d-flex cursor-pointer"
              >
                <img className="cursor-pointer" src={arrow_left} alt="back" />
                <p className="ml-3 add-member-text-back mb-0">
                  {this.props.id ? "Edit" : "Add"} credit history
                </p>
              </div>
            </div>
            <div className="add-form">
              <div className="container bg-white py-4">
                <div className="row">
                  <div className="col-6">
                    <NormalInput
                      label="Lender name"
                      type="text"
                      onChange={this.handleInput}
                      value={lenderName}
                      name="lenderName"
                      isHalfWidthForMemberProfile={true}
                      required={true}
                      max={25}
                    />
                    <div className="row">
                      <div className="col-8 ml-auto">
                        {this.validator.message(
                          "Lender name",
                          lenderName,
                          "required|alpha_space"
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <NormalSelect
                      isHalfWidthForMemberProfile={true}
                      label={"Financial institution"}
                      placeholder="Select"
                      value={fInsId}
                      name="fInsId"
                      options={allFinancialInstitution}
                      arrow={true}
                      handleChange={this.handleInput}
                      required={true}
                    />
                    <div className="row">
                      <div className="col-8 ml-auto">
                        {this.validator.message(
                          "Financial institution",
                          fInsId,
                          "required"
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <NormalInput
                      label="Loan amount availed"
                      type="number"
                      onChange={this.handleInput}
                      value={LoanAmountAvailed}
                      name="LoanAmountAvailed"
                      isHalfWidthForMemberProfile={true}
                      isRupees={true}
                      required={true}
                    />
                    <div className="row">
                      <div className="col-8 ml-auto">
                        {this.validator.message(
                          "Loan amount availed",
                          LoanAmountAvailed,
                          "required|integer|max:10"
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <NormalDate
                      isIcon={true}
                      isHalfWidthForMemberProfile={true}
                      value={LoanSancationDate}
                      name="LoanSancationDate"
                      placeholder="DD/MM/YYYY"
                      onChange={this.handleInput}
                      mindate={false}
                      maxdate={true}
                      timeInput={false}
                      label={"Loan sanction date"}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <NormalInput
                      label="Outstanding loan amount"
                      type="number"
                      onChange={this.handleInput}
                      value={OutstandingLoanAmount}
                      name="OutstandingLoanAmount"
                      isHalfWidthForMemberProfile={true}
                      isRupees={true}
                    />
                    <div className="row">
                      <div className="col-8 ml-auto">
                        {this.validator.message(
                          "Outstanding loan amount",
                          OutstandingLoanAmount,
                          "integer|max:10"
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <NormalInput
                      label={"Rate of interest (in %)"}
                      type="number"
                      onChange={this.handleInput}
                      value={RateofInterest}
                      name="RateofInterest"
                      isHalfWidthForMemberProfile={true}
                    />
                    <div className="row">
                      <div className="col-8 ml-auto">
                        {this.validator.message(
                          "Rate of interest ",
                          RateofInterest,
                          "max:10"
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <NormalSelect
                      isHalfWidthForMemberProfile={true}
                      label={"Tenure period"}
                      placeholder="Select"
                      value={TenurePeriod}
                      name="TenurePeriod"
                      options={[
                        { value: "Year", label: "Year" },
                        { value: "Month", label: "Month" },
                        { value: "Days", label: "Days" },
                      ]}
                      arrow={true}
                      handleChange={this.handleInput}
                    />
                  </div>
                  <div className="col-6">
                    <NormalInput
                      label="Tenure"
                      type="number"
                      onChange={this.handleInput}
                      value={Tenure}
                      name="Tenure"
                      isHalfWidthForMemberProfile={true}
                    />
                    <div className="row">
                      <div className="col-8 ml-auto">
                        {this.validator.message(
                          "Tenure",
                          Tenure,
                          "integer|max:10"
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <NormalSelect
                      isHalfWidthForMemberProfile={true}
                      label={"Interest payment"}
                      placeholder="Select"
                      value={interestPayment}
                      name="interestPayment"
                      options={[
                        { value: "Yearly", label: "Yearly" },
                        { value: "Half-Yearly", label: "Half-Yearly" },
                        { value: "Quaterly", label: "Quaterly" },
                        { value: "Monthly", label: "Monthly" },
                        { value: "BiMonthly", label: "BiMonthly" },
                        { value: "Days", label: "Days" },
                      ]}
                      arrow={true}
                      handleChange={this.handleInput}
                    />
                  </div>
                  <div className="col-6">
                    <NormalSelect
                      isHalfWidthForMemberProfile={true}
                      label={"Loan type"}
                      placeholder="Select"
                      value={LoanType}
                      name="LoanType"
                      options={[
                        { value: "Secured", label: "Secured" },
                        { value: "Unsecured", label: "Unsecured" },
                      ]}
                      arrow={true}
                      handleChange={this.handleInput}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <NormalInput
                      label="Security description"
                      type="text"
                      onChange={this.handleInput}
                      value={securityDescription}
                      name="securityDescription"
                      isHalfWidthForMemberProfile={true}
                      max={75}
                    />
                    <div className="row">
                      <div className="col-8 ml-auto">
                        {this.validator.message(
                          "Security description",
                          securityDescription,
                          "alpha_space"
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <NormalInput
                      label="Remark"
                      type="text"
                      onChange={this.handleInput}
                      value={PofLoanRemark}
                      name="PofLoanRemark"
                      isHalfWidthForMemberProfile={true}
                      max={75}
                    />
                    <div className="row">
                      <div className="col-8 ml-auto">
                        {this.validator.message(
                          "Remark",
                          PofLoanRemark,
                          "alpha_space"
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row align-items-center mt-3">
                  <div className="col-2">
                    <div className="fs-16 text-black-32 font-Gilroy-Bold font-weight-bold line-height-19">
                      {"Purpose of loan "}
                    </div>
                  </div>
                  <div className="col-10 row px-0 mx-0 pl-3">
                    <div className="col-4 d-flex align-items-center px-0">
                      <input
                        className="mr-2"
                        type="checkbox"
                        id="inputLoan"
                        name="inputLoan"
                        value="1"
                        checked={inputLoan}
                        onClick={() => {
                          this.setState((prevState) => ({
                            inputLoan: !prevState.inputLoan,
                          }));
                        }}
                      />
                      <label
                        className="mt-2 font-weight-bold fs-16"
                        for="inputLoan"
                      >
                        {"Purchase of Input"}
                      </label>
                    </div>
                    <div className="col-4 d-flex align-items-center">
                      <input
                        className="mr-2"
                        type="checkbox"
                        id="cattle"
                        name="cattle"
                        value="2"
                        checked={cattle}
                        onClick={() => {
                          this.setState((prevState) => ({
                            cattle: !prevState.cattle,
                          }));
                        }}
                      />
                      <label
                        className="mt-2 font-weight-bold fs-16"
                        for="cattle"
                      >
                        {"Purchase of non-licensed input"}
                      </label>
                    </div>
                    <div className="col-4 d-flex align-items-center">
                      <input
                        className="mr-2"
                        type="checkbox"
                        id="produce"
                        name="produce"
                        value="3"
                        checked={produce}
                        onClick={() => {
                          this.setState((prevState) => ({
                            produce: !prevState.produce,
                          }));
                        }}
                      />
                      <label
                        className="mt-2 font-weight-bold fs-16"
                        for="produce"
                      >
                        {"Purchase of Output"}
                      </label>
                    </div>
                    <div className="col-4 d-flex align-items-center px-0">
                      <input
                        className="mr-2"
                        type="checkbox"
                        id="infrastructure"
                        name="infrastructure"
                        value="4"
                        checked={infrastructure}
                        onClick={() => {
                          this.setState((prevState) => ({
                            infrastructure: !prevState.infrastructure,
                          }));
                        }}
                      />
                      <label
                        className="mt-2 font-weight-bold fs-16"
                        for="infrastructure"
                      >
                        Production of Seeds
                      </label>
                    </div>
                    <div className="col-4 d-flex align-items-center">
                      <input
                        className="mr-2"
                        type="checkbox"
                        id="machineries"
                        name="machineries"
                        value="4"
                        checked={machineries}
                        onClick={() => {
                          this.setState((prevState) => ({
                            machineries: !prevState.machineries,
                          }));
                        }}
                      />
                      <label
                        className="mt-2 font-weight-bold fs-16"
                        for="machineries"
                      >
                        Purchase of Machineries/Equipments
                      </label>
                    </div>

                    <div className="col-4 d-flex align-items-center">
                      <input
                        className="mr-2"
                        type="checkbox"
                        id="other"
                        name="other"
                        value="5"
                        checked={other}
                        onClick={() => {
                          this.setState((prevState) => ({
                            other: !prevState.other,
                          }));
                        }}
                      />
                      <label
                        className="mt-2 font-weight-bold fs-16"
                        for="other"
                      >
                        Other
                      </label>
                    </div>
                  </div>
                </div>
                {other === true && (
                  <div className="row">
                    <div className="col-6">
                      <NormalInput
                        label="Reason"
                        type="text"
                        onChange={this.handleInput}
                        value={reason}
                        name="reason"
                        isHalfWidthForMemberProfile={true}
                        max={50}
                      />
                      <div className="row">
                        <div className="col-8 ml-auto">
                          {this.validator.message(
                            "Reason",
                            reason,
                            "alpha_space"
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div className="pt-5 pb-3 d-flex">
                  <div className="mx-auto d-flex">
                    {this.props.id && deleteAccess && (
                      <div className="cursor-pointer d-flex align-items-center mr-5">
                        <div
                          className="text-center fs-14 fw-600 text-danger d-flex"
                          onClick={this.toggleDelete}
                        >
                          <img className="mr-2" src={trash} alt="icon" />
                          Delete
                        </div>
                      </div>
                    )}
                    <NormalButton
                      label={`${this.props.id ? "Update" : "Submit & Next"}`}
                      mainbg={true}
                      className="px-3 py-2 fs-14 line-height-26"
                      onClick={this.handleSubmit}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <>
            {/* Loader */}
            <CommonPageLoader />
          </>
        )}
        <ConfirmationPopup
          isOpen={isDelete}
          toggle={this.toggleDelete}
          onSubmit={this.handleDelete}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  accessData: state.home.accessData,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      addCreditHistory,
      updateCreditHistory,
      getCreditHistoryById,
      getAllFinancialInstitution,
      deleteCreditHistory,
      keyFinancialCurrentTab,
    },
    dispatch
  );
};
let component = AddCreditHistoryClass;

export const AddCreditHistory = connect(
  mapStateToProps,
  mapDispatchToProps
)(component);
