import React, { Component } from "react";
import Card from "./card";
import Strip from "./strip";
import { Pagination, CommonPageLoader } from "component/common";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getAllRevenue } from "action/KeyFinancialAct";
import { getAllRevenueRm } from "action/RmAct";
import "./revenuelist.scss";

export default class RevenueListClass extends Component {
  constructor(props) {
    super();
    this.state = {
      revenues: [],
      pageMeta: {},
      page: 1,
      isLoading: false,
    };
  }
  handleRevenue = (page) => {
    let { searchValue, filterBy, filterType, id } = this.props;
    this.setState({ isLoading: true });
    let payload = {
      limit: 4,
      page,
      search: searchValue,
      filterBy: filterBy,
      filterValue: filterType,
    };
    this.setState({ isLoading: true });
    id
      ? this.props.getAllRevenueRm(id, payload).then((data) => {
          this.setState({
            revenues: data.results,
            pageMeta: data.pageMeta,
            isLoading: false,
          });
        })
      : this.props.getAllRevenue(payload).then((data) => {
          this.setState({
            revenues: data.results,
            pageMeta: data.pageMeta,
            isLoading: false,
          });
        });
  };

  handlePagination = (page) => {
    this.handleRevenue(page);
  };

  componentDidUpdate(prevProps) {
    if (prevProps.activeTab != this.props.activeTab) {
      let { activeTab } = this.props;
      if (activeTab == 3) {
        this.handleRevenue(1);
      }
    }
    if (this.props.activeTab == 3) {
      this.props.searchValue !== prevProps.searchValue && this.handleRevenue(1);
      this.props.filterType !== prevProps.filterType && this.handleRevenue(1);
    }
  }
  render() {
    const { revenues, pageMeta, isLoading } = this.state;
    let { edit = false } = this.props;
    return (
      <>
        {!isLoading && revenues.length === 0 ? (
          <p className="py-4 mb-0 text-black-50 fs-22 line-height-25 text-center">
            No data found
          </p>
        ) : !isLoading ? (
          <>
            {revenues.length != 0 ? (
              <>
                <div className="scroll-x">
                  <div
                    className="d-flex container-sa flex-column  flex-wrap mt-3 px-0 justify-content-center"
                    style={
                      this.props.view === "1" ? { minWidth: "1200px" } : null
                    }
                  >
                    <div
                      className={`d-flex flex-wrap ${
                        this.props.view === "2" ? "com-list-card" : ""
                      }`}
                    >
                      {revenues.map((revenue, index) => {
                        return this.props.view === "2" ? (
                          <Card
                            payload={revenue}
                            index={index}
                            key={index}
                            editAccess={edit}
                          />
                        ) : (
                          <Strip
                            payload={revenue}
                            index={index}
                            key={index}
                            editAccess={edit}
                          />
                        );
                      })}
                    </div>
                  </div>
                </div>
                {pageMeta.pageCount !== undefined &&
                  pageMeta.pageCount !== 1 && (
                    <Pagination
                      handleChange={this.handlePagination}
                      pageMeta={pageMeta}
                    />
                  )}
              </>
            ) : (
              <p className="py-4 mb-0 text-black-50 fs-22 line-height-25 text-center">
                No data found
              </p>
            )}
          </>
        ) : (
          <>
            {/* Loader */}
            <CommonPageLoader />
          </>
        )}
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getAllRevenue,
      getAllRevenueRm,
    },
    dispatch
  );
};

let component = RevenueListClass;

export const RevenueList = connect(null, mapDispatchToProps)(component);
