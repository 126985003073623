import React, { Component } from "react";
import { connect } from "react-redux";
import { getDescriptionData } from "service/helperFunctions";
import moment from "moment";
import "./style.scss";
import { Pagination } from "component/Faq/FaqView/pagination";
import { history } from "service/helpers";
import defaultAwardsImage from "assets/images/DefaultAward.jpg";
import defaultStoryImage from "assets/images/DefaultSuccessStory.jpg";
import defaultEventImage from "assets/images/DefaultEvent.jpg";

class OtherEventsClass extends Component {
  render() {
    let {
      activeTabName,
      otherRecommededEvents = [],
      otherRecommededAwards = [],
      otherRecommededStories = [],
      pageMeta = {},
      handlePageChange,
    } = this.props;

    return (
      <>
        {otherRecommededEvents.length !== 0 ||
        otherRecommededAwards.length !== 0 ||
        otherRecommededStories.length !== 0 ? (
          <div className="other-recommendations">
            <h4 className="fs-20 line-height-29 text-green-18 mb-3 pb-1 font-weight-bold">
              {activeTabName === "events"
                ? "Other events"
                : activeTabName === "awards"
                ? "Other awards"
                : activeTabName === "successstories"
                ? "Other stories"
                : ""}
            </h4>
            {activeTabName === "events"
              ? otherRecommededEvents.length !== 0 &&
                otherRecommededEvents.map((list, index) => {
                  let {
                    eventTitle = "",
                    eventDate = "",
                    location = "",
                    eventDocs = [],
                    eventId = "",
                  } = list;
                  return (
                    <div
                      className="recommended-cards"
                      onClick={() =>
                        history.push(`/fpo/viewDetails/events&${eventId}`)
                      }
                    >
                      <div className="row">
                        <div className="col-9 pr-0">
                          <p className="fs-16 line-height-23 text-black-1c">
                            {eventTitle !== null
                              ? getDescriptionData(eventTitle, 50)
                              : "-"}
                          </p>
                        </div>
                        <div className="col-3 px-0">
                          <div className="img-area">
                            {eventDocs.length !== 0 &&
                            !eventDocs[0].documentUrl.includes(".pdf") ? (
                              <img
                                src={eventDocs[0].documentUrl}
                                alt={eventDocs[0].fileName}
                              />
                            ) : (
                              <img src={defaultEventImage} alt="events" />
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="row px-3 event-infomation my-auto pb-3 pt-1">
                        <p className="fs-14 line-height-24 text-grey-1c pr-1">
                          Event date :
                        </p>
                        <p className="fs-14 line-height-24 text-gold-d0">
                          {eventDate !== null
                            ? moment(eventDate).format("DD MMM, YYYY")
                            : "-"}
                        </p>
                        <div className="margin-right-black-50 mx-2"></div>
                        <p className="fs-14 line-height-24 text-grey-1c pr-1">
                          Event location :
                        </p>
                        <p className="fs-14 line-height-24 text-gold-d0">
                          {location !== null
                            ? getDescriptionData(location, 20)
                            : "-"}
                        </p>
                      </div>
                      {index !== otherRecommededEvents.length - 1 ? <hr /> : ""}
                    </div>
                  );
                })
              : activeTabName === "awards"
              ? otherRecommededAwards.length !== 0 &&
                otherRecommededAwards.map((list, index) => {
                  let {
                    title = "",
                    receivedDate = "",
                    awardDocs = [],
                    awardId = "",
                  } = list;
                  return (
                    <div
                      className="recommended-cards"
                      onClick={() =>
                        history.push(`/fpo/viewDetails/awards&${awardId}`)
                      }
                    >
                      <div className="row">
                        <div className="col-9 pr-0">
                          <p className="fs-16 line-height-23 text-black-1c">
                            {title !== null
                              ? getDescriptionData(title, 50)
                              : "-"}
                          </p>
                        </div>
                        <div className="col-3 px-0">
                          <div className="img-area">
                            {awardDocs.length !== 0 &&
                            !awardDocs[0].documentUrl.includes(".pdf") ? (
                              <img
                                src={awardDocs[0].documentUrl}
                                alt={awardDocs[0].fileName}
                              />
                            ) : (
                              <img src={defaultAwardsImage} alt="awards" />
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="row px-3 event-infomation my-auto pb-3 pt-1">
                        <p className="fs-14 line-height-24 text-grey-1c pr-1">
                          Recieved date :
                        </p>
                        <p className="fs-14 line-height-24 text-gold-d0">
                          {receivedDate !== null
                            ? moment(receivedDate).format("DD MMM, YYYY")
                            : "-"}
                        </p>
                      </div>
                      {index !== otherRecommededAwards.length - 1 ? <hr /> : ""}
                    </div>
                  );
                })
              : activeTabName === "successstories"
              ? otherRecommededStories.length !== 0 &&
                otherRecommededStories.map((list, index) => {
                  let {
                    title = "",
                    storyDocs = [],
                    publishOn = "",
                    storyId = "",
                  } = list;
                  return (
                    <div
                      className="recommended-cards"
                      onClick={() =>
                        history.push(
                          `/fpo/viewDetails/successstories&${storyId}`
                        )
                      }
                    >
                      <div className="row">
                        <div className="col-9 pr-0">
                          <p className="fs-16 line-height-23 text-black-1c">
                            {title !== null
                              ? getDescriptionData(title, 50)
                              : "-"}
                          </p>
                        </div>
                        <div className="col-3 px-0">
                          <div className="img-area">
                            {storyDocs.length !== 0 &&
                            !storyDocs[0].documentUrl.includes(".pdf") ? (
                              <img
                                src={storyDocs[0].documentUrl}
                                alt={storyDocs[0].fileName}
                              />
                            ) : (
                              <img src={defaultStoryImage} alt="stories" />
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="row px-3 event-infomation my-auto pb-3 pt-1">
                        <p className="fs-14 line-height-24 text-grey-1c pr-1">
                          Published date :
                        </p>
                        <p className="fs-14 line-height-24 text-gold-d0">
                          {publishOn !== null
                            ? moment(publishOn).format("DD MMM, YYYY")
                            : "-"}
                        </p>
                      </div>
                      {index !== otherRecommededStories.length - 1 ? (
                        <hr />
                      ) : (
                        ""
                      )}
                    </div>
                  );
                })
              : ""}

            {activeTabName === "events"
              ? otherRecommededEvents.length !== 0 && (
                  <Pagination
                    handleChange={handlePageChange}
                    pageMeta={pageMeta}
                  />
                )
              : activeTabName === "awards"
              ? otherRecommededAwards.length !== 0 && (
                  <Pagination
                    handleChange={handlePageChange}
                    pageMeta={pageMeta}
                  />
                )
              : activeTabName === "successstories"
              ? otherRecommededStories.length !== 0 && (
                  <Pagination
                    handleChange={handlePageChange}
                    pageMeta={pageMeta}
                  />
                )
              : ""}
          </div>
        ) : (
          ""
        )}
      </>
    );
  }
}
let component = OtherEventsClass;

export const OtherEvents = connect(null, null)(component);
