import React, { Component } from "react";
import { Col, Row } from "reactstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination } from "swiper";
import "./index.scss";
import { getDescriptionData } from "service/helperFunctions";
import { history } from "service/helpers";
const clock = require("../../../../assets/svg/academy/clock.svg");

export class AcademyPre extends Component {
  constructor() {
    super();
    SwiperCore.use([Navigation, Pagination]);
    this.state = {
      courses: [],
    };
  }
  componentDidMount() {
    this.setState({
      courses: this.props.courses,
    });
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.courses !== this.props.courses) {
      this.setState({
        courses: this.props.courses,
      });
    }
  }

  render() {
    const params = {
      slidesPerView: 3,
      loop: false,
      breakpoints: {
        500: {
          slidesPerView: 2,
        },
        768: {
          slidesPerView: 3,
        },
      },
    };
    const { courses } = this.state;
    return (
      <div className="academy-pre">
        <div className="academy-container">
          <Row>
            <Col sm="3">
              <div className="title">
                <p>Explore Samunnati Academy for FPOs</p>
              </div>
            </Col>
            <Col sm="9">
              <div className="academy-card-container">
                <Swiper {...params}>
                  {courses.length > 0 &&
                    courses.map((course, index) => {
                      return (
                        <SwiperSlide key={index}>
                          <div
                            className="academy-card shadow cursor-pointer"
                            onClick={() => {
                              history.push("/login");
                            }}
                          >
                            <div>
                              <img
                                className="img"
                                src={course.thumbnailFileUrl}
                                alt="thumbnailFileUrl"
                              />
                            </div>
                            <div className="academy-content">
                              <div>
                                <div className="content-title">
                                  <p>{course.title}</p>
                                </div>
                                <div
                                  className="content-description"
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      course.description.length > 100
                                        ? getDescriptionData(
                                            course.description,
                                            100
                                          )
                                        : course.description,
                                  }}
                                ></div>
                              </div>
                              <div className="content-details">
                                <div>
                                  <img
                                    className="mr-2"
                                    src={clock}
                                    alt="clock.svg"
                                  />
                                  <span className="time">
                                    {course.durationHours} hr{" "}
                                    {course.durationMinutes} min
                                  </span>
                                </div>
                                <div className="chapter">
                                  <p>
                                    {course.noOfChapters
                                      ? course.noOfChapters
                                      : 0}{" "}
                                    chapters
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </SwiperSlide>
                      );
                    })}
                </Swiper>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}
