import React from "react";
import profilePicture from "assets/images/default-profile-img.png";
import { history } from "service/helpers";

const FIGMemberListStripView = () => {
  const FIGData = [
    {
      name: "Testing01",
      gender: "Male",
      village: "Jaipur",
      mobile: "9878987897",
    },
    {
      name: "Testing02",
      gender: "Female",
      village: "Pune",
      mobile: "7655456557",
    },
    {
      name: "Testing03",
      gender: "Male",
      village: "Mangalore",
      mobile: "8765567809",
    },
    {
      name: "Testing04",
      gender: "Male",
      village: "Karaikal",
      mobile: "6789098767",
    },
    {
      name: "Testing05",
      gender: "Female",
      village: "Idukki",
      mobile: "6778550987",
    },
  ];

  return (
    <div>
      {FIGData?.map((item, index) => {
        return (
          <div
            className="member-strip shadow rounded p-3 d-flex align-items-center pr-2 w-100 mb-3"
            key={index}
          >
            <div className="row w-100">
              <div className="col-3">
                <div className="d-flex align-items-center">
                  <div className="mr-4">
                    <img
                      src={profilePicture}
                      className="member-profile"
                      alt="member_profile"
                    />
                  </div>
                  <div>
                    <div className="member-card-font-name">{item?.name}</div>
                  </div>
                </div>
              </div>
              <div className="col-1">
                <div className="member-card-font-content mb-2">
                  {item?.gender}
                </div>
                <div className="member-card-font-heading">Gender</div>
              </div>
              <div className="col-1">
                <div className="member-card-font-content mb-2">
                  {item?.village}
                </div>
                <div className="member-card-font-heading">Village</div>
              </div>
              <div className="col-1">
                <div className="member-card-font-content mb-2">
                  {item?.mobile}
                </div>
                <div className="member-card-font-heading">Mobile</div>
              </div>
              <div className="col-4">
                <div className="d-flex align-items-center h-100 justify-content-center">
                  <p className="d-flex justify-content-center align-items-center mb-0 w-100 fs-18 text-black-1c h-100 fw-600">
                    No crops
                  </p>
                </div>
              </div>
              <div className="col-1 p-0">
                <div className="d-flex flex-column align-items-center justify-content-center h-100">
                  <div className="d-flex">
                    <div
                      className="mr-4 cursor-pointer fs-16 line-height text-black-50 d-flex align-items-center"
                      onClick={() => history.push("/fig/member-profile")}
                    >
                      View
                    </div>
                    <div className="cursor-pointer fs-16 line-height text-black-50 d-flex align-items-center">
                      Edit
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default FIGMemberListStripView;
