import React, { Component } from "react";

import {
  Banner,
  AcadamyMenu,
  OnGoingCourses,
  TrendingNow,
  TrendingVideoFav,
} from "component/Acadamy";
import { EventNewsSection } from "component/fpo/home";

import { Certificates } from "component/fpo/home/academy/Certificates";

import { history } from "service/helpers";

import {
  myLearningApi,
  courseListApi,
  myFavorities,
  myFavoritiesForVideos,
  myFavoritiesForBlog,
} from "action/Acadamy";

import { FilteredBlog } from "component/Acadamy/Blog/FilteredBlog";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

const arrow_left = require("assets/svg/add_member/arrow_left.svg");

export class MyLearningClass extends Component {
  constructor() {
    super();
    this.state = {
      trendingCourseData: [],
      OnGoingCoursesData: [],
      tabTitle: "Ongoing",
      trendingVideos: [],
      blogData: [],
    };
  }

  componentDidMount = () => {
    this.handelTabChange("Ongoing");
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    this.props.courseListApi().then((data) => {
      this.setState({ trendingCourseData: data });
    });
  };

  handelTabChange = (data) => {
    this.setState({ tabTitle: data });
    if (data !== "Certificates" && data !== "Favorites") {
      this.props.myLearningApi(`filter/${data}`).then((payload) => {
        this.setState({ OnGoingCoursesData: payload });
      });
    }
    if (data === "Favorites") {
      this.props.myFavorities().then((payload) => {
        this.setState({ OnGoingCoursesData: payload });
      });
      this.props.myFavoritiesForVideos().then((payload) => {
        this.setState({ trendingVideos: payload });
      });
      this.props.myFavoritiesForBlog().then((payload) => {
        this.setState({ blogData: payload });
      });
    }
  };

  render() {
    let {
      trendingCourseData,
      OnGoingCoursesData,
      tabTitle,
      trendingVideos,
      blogData,
    } = this.state;
    return (
      <>
        <Banner />
        <AcadamyMenu />
        <div className="bg-blue-f9">
          <div className="d-flex container-sa my-3 ">
            <div
              onClick={() => {
                history.push("/academy/home");
              }}
              className="d-flex"
            >
              <img className="cursor-pointer" src={arrow_left} alt="back" />
              <p className="ml-3 add-member-text-back mb-0 cursor-pointer">
                Home
              </p>
            </div>
          </div>
          <div className="my-learnings-list">
            <div className="container-sa">
              <ul>
                <li
                  onClick={() => this.handelTabChange("Ongoing")}
                  className={tabTitle == "Ongoing" ? "active" : ""}
                >
                  Ongoing
                </li>
                <li
                  onClick={() => this.handelTabChange("Completed")}
                  className={tabTitle == "Completed" ? "active" : ""}
                >
                  Completed
                </li>
                <li
                  onClick={() => this.handelTabChange("Favorites")}
                  className={tabTitle == "Favorites" ? "active" : ""}
                >
                  Favourites
                </li>
                <li
                  onClick={() => this.handelTabChange("Certificates")}
                  className={tabTitle == "Certificates" ? "active" : ""}
                >
                  Certificates
                </li>
              </ul>
            </div>
          </div>

          {tabTitle !== "Certificates" ? (
            <>
              <OnGoingCourses
                OnGoingCoursesData={OnGoingCoursesData}
                title={tabTitle + " courses"}
                orititle={tabTitle}
                favorites={tabTitle === "Favorites" ? true : false}
              />

              {trendingCourseData.length > 0 && tabTitle !== "Favorites" ? (
                <TrendingNow
                  trendingCourseData={trendingCourseData}
                  title="Courses"
                  favorites={tabTitle === "Favorites" ? true : false}
                />
              ) : null}
              {tabTitle === "Favorites" && blogData.length > 0 ? (
                <FilteredBlog blogList={blogData} isfromFavouriteList={true} />
              ) : null}
              {tabTitle === "Favorites" && trendingVideos.length !== 0 ? (
                <TrendingVideoFav
                  isfromFavouriteList={true}
                  trendingVideos={trendingVideos}
                  title="Informative videos"
                  favorites={tabTitle === "Favorites" ? true : false}
                />
              ) : null}

              {/* <EventNewsSection academy={true} /> */}
            </>
          ) : (
            <Certificates />
          )}
        </div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      myLearningApi,
      courseListApi,
      myFavorities,
      myFavoritiesForVideos,
      myFavoritiesForBlog,
    },
    dispatch
  );
};

let component = MyLearningClass;

export const MyLearning = connect(null, mapDispatchToProps)(component);
