import React, { Component } from "react";
import "./gstcard.scss";
import { history } from "service/helpers";
import { getDescriptionData } from "service/helperFunctions";
import moment from "moment";
import long_arrow from "assets/svg/price_details/long_arrow.svg";

export class GstCard extends Component {
  render() {
    const {
      id,
      licenseImgUrl,
      licenseNumber,
      validityDate,
      OriginalLicense,
      remarks,
      storeAddress,
      village = "",
    } = this.props.payload;
    let { index, editAccess } = this.props;
    return (
      <div
        key={index}
        className={`col-md-3 col-lg-3 col-6 ${
          index % 4 === 3 ? "pr-md-0" : ""
        } mb-2 mt-3 px-2 ${index % 4 === 0 ? "pl-md-0" : ""}`}
      >
        <div className="gst-card rounded shadow cursor-pointer">
          <div
            onClick={() => {
              editAccess && history.push(`/fpo/edit-gst/${id}`);
            }}
          >
            <div className="d-flex justify-content-between mb-4">
              <div className="d-flex align-items-center">
                <div>
                  <div className="gst-card-member-name mb-1 gilroy">
                    {licenseNumber}
                  </div>
                  <div className="gst-card-subtitle gilroy">
                    {validityDate
                      ? `Valid till ${moment(validityDate).format(
                          "DD MMM YYYY"
                        )}`
                      : "-"}
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-between mb-4">
              <div>
                <div className="line-height-21 gilroy text-black-50 fs-16 mb-3">
                  {"Village"}
                </div>
                <div className="line-height-21 gilroy fs-18 text-black-1c font-weight-bold">
                  {village ? getDescriptionData(village, 25) : "-"}
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-between mb-4">
              <div>
                <div className="line-height-21 gilroy text-black-50 fs-16 mb-3">
                  {"Original licence"}
                </div>
                <div className="line-height-21 gilroy fs-18 text-black-1c font-weight-bold">
                  {OriginalLicense ? OriginalLicense : "-"}
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-between mb-4">
              <div>
                <div className="line-height-21 gilroy text-black-50 fs-16 mb-3">
                  {"Remarks"}
                </div>
                <div className="line-height-21 gilroy fs-18 text-black-1c font-weight-bold height-30">
                  {remarks ? getDescriptionData(remarks, 25) : "-"}
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-between mb-4">
              <div>
                <div className="line-height-21 gilroy text-black-50 fs-16 mb-3">
                  {"Store address	"}
                </div>
                <div className="line-height-21 gilroy fs-18 text-black-1c font-weight-bold height-30">
                  {storeAddress ? getDescriptionData(storeAddress, 25) : "-"}
                </div>
              </div>
            </div>
          </div>
          {licenseImgUrl ? (
            <div
              className="row mb-3"
              onClick={() => window.open(licenseImgUrl, "_blank")}
            >
              <div className="text-green font-weight-bold fs-16 gilroy col-7">
                {"View document"}
              </div>
              <div className="col-3">
                <img src={long_arrow} alt="arrow.svg" />
              </div>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}
