import React, { Component } from "react";
import { history } from "service/helpers/index";
import SimpleReactValidator from "simple-react-validator";
import moment from "moment";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  addAgm,
  getAgmById,
  updateAgm,
  deleteAgm,
  complianceCurrentTab,
} from "action/ComplianceAct";
import { fileUpload } from "action/MemberAct";
import { Toast } from "service/toast";
import {
  NormalInput,
  NormalButton,
  NormalDate,
  NormalTextArea,
  CommonPageLoader,
  ConfirmationPopup,
} from "component/common";
import trash from "assets/svg/Associations/trash.svg";
import { getAccessdata } from "service/helperFunctions";
const arrow_left = require("assets/svg/add_member/arrow_left.svg");

export class AddAgmClass extends Component {
  constructor(props) {
    super();
    this.state = {
      dateOfLastMeeting: "",
      location: "",
      agendaHighlights: "",
      numberOfAttendees: "",
      attendeesListFileName: "",
      attendeesListUrl: null,
      supportingDocumentFileName: "",
      supportingDocumentUrl: null,
      isImageUploading: false,
      isLoading: false,
      issupportingDocUploading: false,
      isDelete: false,
    };
  }

  //Validation
  validator = new SimpleReactValidator({
    validators: {
      integer: {
        message: "Only non-negative absolute values are allowed",
        rule: (val, params, validator) => {
          return validator.helpers.testRegex(val, /^\s*([0-9])*$/);
        },
        messageReplace: (message, params) =>
          message.replace(":values", this.helpers.toSentence(params)),
      },
    },
    element: (message) => (
      <span className="error-message text-danger fs-14">{message}</span>
    ),
    autoForceUpdate: this,
  });

  handleSubmit = () => {
    this.setState({ isLoading: true });
    if (this.validator.allValid()) {
      const {
        dateOfLastMeeting,
        location,
        agendaHighlights,
        numberOfAttendees,
        attendeesListFileName,
        attendeesListUrl,
        supportingDocumentFileName,
        supportingDocumentUrl,
        isImageUploading,
      } = this.state;
      if (!isImageUploading) {
        let body = {
          dateOfLastMeetingD: moment(dateOfLastMeeting).format("YYYY-MM-DD"),
          numberOfAttendees,
        };
        if (location !== "") {
          body.location = location;
        }
        if (agendaHighlights !== "") {
          body.agendaHighlights = agendaHighlights;
        }
        if (supportingDocumentFileName !== "") {
          body.supportingDocumentFileName = supportingDocumentFileName;
          body.supportingDocumentUrl = supportingDocumentUrl;
        }
        if (attendeesListFileName !== "") {
          body.attendeesListFileName = attendeesListFileName;
          body.attendeesListUrl = attendeesListUrl;
        }
        if (this.props.id) {
          body.id = parseInt(this.props.id);
          this.props
            .updateAgm(body)
            .then(() => {
              this.validator.hideMessages();
              this.handleBack();
            })
            .catch(() => {
              this.setState({ isLoading: false });
            });
        } else {
          this.props
            .addAgm(body)
            .then(() => {
              this.validator.hideMessages();
              this.setState({
                dateOfLastMeeting: "",
                location: "",
                agendaHighlights: "",
                numberOfAttendees: "",
                attendeesListFileName: "",
                attendeesListUrl: null,
                supportingDocumentFileName: "",
                supportingDocumentUrl: null,
                isImageUploading: false,
                isLoading: false,
              });
              this.handleBack();
            })
            .catch(() => {
              this.setState({ isLoading: false });
            });
        }
      } else {
        this.setState({ isLoading: false });
        Toast({ type: "error", message: "Please wait Image is uploading" });
      }
    } else {
      this.setState({ isLoading: false });
      this.validator.showMessages();
      this.forceUpdate();
    }
  };
  handleDelete = () => {
    this.setState({ isLoading: true });
    this.props.deleteAgm(this.props.id).then((data) => {
      this.handleBack();
    });
  };
  handleAttendeesFile = (e) => {
    let file = e.target.files[0];
    if (file !== undefined) {
      this.setState({ attendeesListUrl: null }, () => {
        this.setState({
          isImageUploading: true,
          attendeesListFileName: "Uploading ....",
          attendeesListUrl: file,
        });
        const formData = new FormData();
        formData.append("file", file);

        this.props
          .fileUpload(formData)
          .then((data) => {
            document.getElementById("handleAttendeesFile").value = null;
            this.setState({
              attendeesListUrl: data[0].fileUrl,
              attendeesListFileName: data[0].fileName,
              isImageUploading: false,
            });
          })
          .catch(() => {
            this.setState({
              attendeesListUrl: null,
              attendeesListFileName: "",
              isImageUploading: false,
            });
          });
      });
    }
  };

  handleSupportingFile = (e) => {
    let file = e.target.files[0];
    if (file !== undefined) {
      this.setState({ supportingDocumentUrl: null }, () => {
        let { supportingDocumentUrl, supportingDocumentFileName } = this.state;

        supportingDocumentFileName = "Uploading ....";
        supportingDocumentUrl = file;

        const formData = new FormData();
        formData.append("file", file);

        this.props
          .fileUpload(formData)
          .then((data) => {
            document.getElementById("handleSupportingFile").value = null;
            this.setState({
              supportingDocumentUrl: data[0].fileUrl,
              supportingDocumentFileName: data[0].fileName,
              issupportingDocUploading: false,
            });
          })
          .catch(() => {
            this.setState({
              supportingDocumentUrl: null,
              supportingDocumentFileName: "",
              issupportingDocUploading: false,
            });
          });
      });
    }
  };

  handleInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  componentDidMount() {
    if (this.props.id) {
      this.setState({ isLoading: true });
      this.props.getAgmById(this.props.id).then((data) => {
        let {
          dateOfLastMeetingD = "",
          agendaHighlights = "",
          numberOfAttendees = "",
          attendeesListFileName = "",
          attendeesListUrl = null,
          supportingDocumentFileName = "",
          supportingDocumentUrl = null,
          location = "",
        } = data;
        this.setState({
          isLoading: false,
          dateOfLastMeeting: dateOfLastMeetingD
            ? new Date(dateOfLastMeetingD)
            : "",
          location: location ? location : "",
          agendaHighlights: agendaHighlights ? agendaHighlights : "",
          numberOfAttendees: numberOfAttendees
            ? numberOfAttendees.toString()
            : "",
          attendeesListFileName: attendeesListFileName
            ? attendeesListFileName
            : "",
          attendeesListUrl: attendeesListUrl ? attendeesListUrl : null,
          supportingDocumentFileName: supportingDocumentFileName
            ? supportingDocumentFileName
            : "",
          supportingDocumentUrl: supportingDocumentUrl
            ? supportingDocumentUrl
            : null,
        });
      });
    }
  }

  handleRemoveImage = (picName) => {
    if (picName === "supportingDoc") {
      this.setState({
        attendeesListUrl: null,
        attendeesListFileName: "",
      });
    } else {
      this.setState({
        supportingDocumentUrl: null,
        supportingDocumentFileName: "",
      });
    }
  };

  handleBack = () => {
    this.props.complianceCurrentTab(6);
    history.push("/fpo/compliance");
  };

  toggleDelete = () => {
    let { isDelete } = this.state;
    this.setState({
      isDelete: !isDelete,
    });
  };

  render() {
    const {
      dateOfLastMeeting,
      location,
      agendaHighlights,
      numberOfAttendees,
      attendeesListFileName,
      attendeesListUrl,
      supportingDocumentFileName,
      supportingDocumentUrl,
      isLoading,
      isImageUploading,
      issupportingDocUploading,
      isDelete,
    } = this.state;

    let { accessData } = this.props;
    let permissionData = getAccessdata(accessData, "complainceGovernance");

    let { deleteAccess = false } = permissionData;
    return (
      <div>
        <div className="d-flex container-sa my-3 ">
          <div
            onClick={() => {
              this.handleBack();
            }}
            className="d-flex cursor-pointer"
          >
            <img className="cursor-pointer" src={arrow_left} alt="back" />
            <p className="ml-3 add-member-text-back mb-0">
              {this.props.id ? "Edit" : "Add"} AGM
            </p>
          </div>
        </div>
        {!isLoading ? (
          <div className="add-form">
            <div className="container bg-white py-4">
              <div className="row">
                <div className="col-6">
                  <NormalDate
                    isIcon={true}
                    isHalfWidth={true}
                    value={dateOfLastMeeting}
                    name={"dateOfLastMeeting"}
                    placeholder="DD/MM/YYYY"
                    onChange={this.handleInput}
                    mindate={false}
                    maxdate={false}
                    timeInput={false}
                    label={"Date of meeting"}
                    required={true}
                  />
                  <div className="row">
                    <div className="col-8 ml-auto">
                      {this.validator.message(
                        "Date of meeting",
                        dateOfLastMeeting,
                        "required"
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <NormalInput
                    label="Location"
                    type="text"
                    onChange={this.handleInput}
                    value={location}
                    name="location"
                    isHalfWidthForMemberProfile={true}
                  />
                  <div className="row">
                    <div className="col-8 ml-auto">
                      {this.validator.message(
                        "location",
                        location,
                        "max:30|alpha_space"
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <NormalTextArea
                    label="Agenda or Highlights"
                    onChange={this.handleInput}
                    value={agendaHighlights}
                    name="agendaHighlights"
                    isHalfWidthForMemberProfile={true}
                    rows="6"
                  />
                  <div className="row">
                    <div className="col-8 ml-auto">
                      {this.validator.message(
                        "agendaHighlights",
                        agendaHighlights,
                        "max:100|alpha_space"
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="row">
                    <div className="col-12">
                      <NormalInput
                        label="Number of attendees"
                        type="number"
                        onChange={this.handleInput}
                        value={numberOfAttendees}
                        name="numberOfAttendees"
                        isHalfWidthForMemberProfile={true}
                        required={true}
                      />
                      <div className="row">
                        <div className="col-8 ml-auto">
                          {this.validator.message(
                            "numberOfAttendees",
                            numberOfAttendees,
                            "required|integer|max:5"
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="row upload-area">
                        <div className="col-4">
                          <div className="fs-16 text-black-32 font-weight-bold line-height-19">
                            {"Total attendees list"}
                          </div>
                        </div>
                        <div className="col-8">
                          <div className="d-flex align-items-center btn-file-input">
                            <button className="">
                              <input
                                type="file"
                                className=""
                                accept=".jpg, .png, .jpeg"
                                onChange={this.handleAttendeesFile}
                                id="handleAttendeesFile"
                                title=""
                              />
                              {attendeesListUrl == null ? (
                                "Choose file"
                              ) : (
                                <>
                                  {attendeesListFileName.length == 10
                                    ? attendeesListFileName
                                    : attendeesListFileName.substring(0, 9)}
                                  {"..."}
                                </>
                              )}
                            </button>
                            {attendeesListFileName != "" && !isImageUploading && (
                              <div className="pl-3 position-relative">
                                <img
                                  src={attendeesListUrl}
                                  alt={attendeesListFileName}
                                  height="75"
                                  width="75"
                                />
                                <i
                                  className="icon-Delete text-danger fs-14 pl-2 cursor-pointer position-absolute"
                                  onClick={() => this.handleRemoveImage()}
                                ></i>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="row upload-area">
                        <div className="col-4">
                          <div className="fs-16 text-black-32 font-weight-bold line-height-19">
                            {"Supporting document"}
                          </div>
                        </div>
                        <div className="col-8">
                          <div className="d-flex align-items-center btn-file-input">
                            <button className="">
                              <input
                                type="file"
                                className=""
                                accept=".jpg, .png, .jpeg"
                                onChange={this.handleSupportingFile}
                                id="handleSupportingFile"
                                title=""
                              />
                              {supportingDocumentUrl == null ? (
                                "Choose file"
                              ) : (
                                <>
                                  {supportingDocumentFileName.length == 10
                                    ? supportingDocumentFileName
                                    : supportingDocumentFileName.substring(
                                        0,
                                        9
                                      )}
                                  {"..."}
                                </>
                              )}
                            </button>
                            {supportingDocumentFileName != "" &&
                              !issupportingDocUploading && (
                                <div className="pl-3 position-relative">
                                  <img
                                    src={supportingDocumentUrl}
                                    alt={supportingDocumentFileName}
                                    height="75"
                                    width="75"
                                  />
                                  <i
                                    className="icon-Delete text-danger fs-14 pl-2 cursor-pointer position-absolute"
                                    onClick={() =>
                                      this.handleRemoveImage("supportingDoc")
                                    }
                                  ></i>
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="pt-5 pb-3 d-flex">
                <div className="mx-auto d-flex">
                  {this.props.id && deleteAccess && (
                    <div className="cursor-pointer d-flex align-items-center mr-5">
                      <div
                        className="text-center fs-14 fw-600 text-danger d-flex"
                        onClick={this.toggleDelete}
                      >
                        <img className="mr-2" src={trash} alt="icon" />
                        {"Delete"}
                      </div>
                    </div>
                  )}
                  <NormalButton
                    label={`${this.props.id ? "Update" : "Submit & next"}`}
                    mainbg={true}
                    className="px-3 py-2 fs-14 line-height-26"
                    onClick={this.handleSubmit}
                  />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <>
            {/* Loader */}
            <CommonPageLoader />
          </>
        )}
        <ConfirmationPopup
          isOpen={isDelete}
          toggle={this.toggleDelete}
          onSubmit={this.handleDelete}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  accessData: state.home.accessData,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      addAgm,
      updateAgm,
      getAgmById,
      fileUpload,
      deleteAgm,
      complianceCurrentTab,
    },
    dispatch
  );
};
let component = AddAgmClass;

export const AddAgm = connect(mapStateToProps, mapDispatchToProps)(component);
