import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "./style.scss";
import {
  addKeyFinancialInfo,
  updateKeyFinancialInfo,
  getAllFinancialYear,
  getKeyFinancialInfoById,
  deleteKeyFinancialInfo,
  keyFinancialCurrentTab,
} from "action/KeyFinancialAct";
import { getAllFinancialYearDropdown } from "action/ComplianceAct";

import SimpleReactValidator from "simple-react-validator";
import { history } from "service/helpers/index";
import {
  NormalInput,
  NormalButton,
  NormalSelect,
  CommonPageLoader,
  ConfirmationPopup,
} from "component/common";
import trash from "assets/svg/Associations/trash.svg";
import { getAccessdata } from "service/helperFunctions";
const arrow_left = require("assets/svg/add_member/arrow_left.svg");

class AddFinancialInfoClass extends Component {
  state = {
    FyearID: "",
    turnOver: "",
    AuthorisedCapital: "",
    sanctionedCapital: "",
    profitAT: "",
    GrandDonationReceived: "",
    FYear: "",
    allFinancialYear: [],
    isLoading: false,
    isDelete: false,
  };

  validator = new SimpleReactValidator({
    element: (message) => (
      <span className="error-message text-danger fs-14 mt-3">{message}</span>
    ),
    autoForceUpdate: this,
    validators: {
      ip: {
        message: "Atleast one :attribute must be selected",
        rule: (val, params, validator) => {
          return (
            this.state.inputLoan === true ||
            this.state.cattle === true ||
            this.state.produce === true ||
            this.state.infrastructure === true ||
            this.state.other === true
          );
        },
        messageReplace: (message, params) =>
          message.replace(":values", this.helpers.toSentence(params)),
        required: true,
      },

      integer: {
        message: "Only non-negative absolute values are allowed",
        rule: (val, params, validator) => {
          return validator.helpers.testRegex(val, /^\s*([0-9])*$/);
        },
        messageReplace: (message, params) =>
          message.replace(":values", this.helpers.toSentence(params)),
      },
      ninteger: {
        message: "Only absolute values are allowed",
        rule: (val, params, validator) => {
          return validator.helpers.testRegex(val, /^([+-]?[0-9]\d*)$/);
        },
        messageReplace: (message, params) =>
          message.replace(":values", this.helpers.toSentence(params)),
      },
    },
  });

  handleSubmit = () => {
    this.setState({ isLoading: true });

    if (this.validator.allValid()) {
      const {
        FyearID,
        turnOver,
        AuthorisedCapital,
        sanctionedCapital,
        profitAT,
        GrandDonationReceived,
        allFinancialYear,
      } = this.state;
      let body = {
        FSId: parseInt(this.props.id),
        fymId: parseInt(FyearID),
        fymYear: "",
        turnOver: parseInt(turnOver),
      };

      let dataSet = allFinancialYear.filter((x) => x.value == FyearID);
      if (dataSet.length > 0) {
        body.fymYear = dataSet[0].label;
      }
      if (GrandDonationReceived !== "") {
        body.GrandDonationReceived = parseInt(GrandDonationReceived);
      }
      if (profitAT !== "") {
        body.profitAT = parseInt(profitAT);
      }
      if (sanctionedCapital !== "") {
        body.sanctionedCapital = parseInt(sanctionedCapital);
      }
      if (AuthorisedCapital !== "") {
        body.AuthorisedCapital = parseInt(AuthorisedCapital);
      }
      this.props.id
        ? this.props.updateKeyFinancialInfo(body).then(() => {
            this.validator.hideMessages();
            history.push("/fpo/key-financial");
            this.setState({ isLoading: false });
          })
        : this.props
            .addKeyFinancialInfo(body)
            .then(() => {
              this.validator.hideMessages();
              this.setState({
                FyearID: "",
                turnOver: "",
                AuthorisedCapital: "",
                sanctionedCapital: "",
                profitAT: "",
                GrandDonationReceived: "",
                isLoading: false,
              });
              this.handleBack();
            })
            .catch(() => {
              this.setState({ isLoading: false });
            });
    } else {
      this.setState({ isLoading: false });
      this.validator.showMessages();
      this.forceUpdate();
    }
  };
  handleDelete = () => {
    this.setState({ isLoading: true });
    this.props.deleteKeyFinancialInfo(this.props.id).then((data) => {
      history.push("/fpo/key-financial");
    });
  };

  toggleDelete = () => {
    let { isDelete } = this.state;
    this.setState({
      isDelete: !isDelete,
    });
  };

  handleInput = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  componentDidMount() {
    this.props.getAllFinancialYearDropdown().then((data) => {
      let financialData = [];

      data.financialYearMaster.map((list) => {
        financialData.push({ value: list.fymId, label: list.fymYear });
      });
      this.setState({
        allFinancialYear: financialData,
      });
    });
    if (this.props.id) {
      this.setState({ isLoading: true });
      this.props.getKeyFinancialInfoById(this.props.id).then((data) => {
        this.setState({
          FyearID: data.fymId,
          turnOver: data.turnOver.toString(),
          AuthorisedCapital: data.AuthorisedCapital
            ? data.AuthorisedCapital.toString()
            : "",
          sanctionedCapital: data.sanctionedCapital
            ? data.sanctionedCapital.toString()
            : "",
          profitAT: data.profitAT ? data.profitAT.toString() : "",
          GrandDonationReceived: data.GrandDonationReceived
            ? data.GrandDonationReceived.toString()
            : "",
          isLoading: false,
        });
      });
    }
  }

  handleBack = () => {
    this.props.keyFinancialCurrentTab(1);
    history.push("/fpo/key-financial");
  };

  render() {
    const {
      FyearID,
      allFinancialYear,
      turnOver,
      AuthorisedCapital,
      sanctionedCapital,
      profitAT,
      GrandDonationReceived,
      isLoading,
      isDelete,
    } = this.state;

    let { accessData } = this.props;
    let permissionData = getAccessdata(accessData, "keyFinancials");

    let { deleteAccess = false } = permissionData;

    return (
      <>
        {!isLoading ? (
          <div className="key-financials">
            <div className="d-flex container-sa my-3 ">
              <div
                onClick={() => {
                  this.handleBack();
                }}
                className="d-flex cursor-pointer"
              >
                <img className="cursor-pointer" src={arrow_left} alt="back" />
                <p className="ml-3 add-member-text-back mb-0">
                  {this.props.id ? "Edit" : "Add"} financial summary
                </p>
              </div>
            </div>
            <div className="add-form">
              <div className="container bg-white py-4">
                <div className="row">
                  <div className="col-6">
                    <NormalSelect
                      isHalfWidthForMemberProfile={true}
                      label={"Financial year"}
                      placeholder="Select"
                      value={FyearID}
                      name="FyearID"
                      options={allFinancialYear}
                      arrow={true}
                      handleChange={this.handleInput}
                      required={true}
                    />
                    <div className="row">
                      <div className="col-8 ml-auto">
                        {this.validator.message(
                          "Financial year",
                          FyearID,
                          "required"
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <NormalInput
                      label="Revenue"
                      type="text"
                      onChange={this.handleInput}
                      value={turnOver}
                      name="turnOver"
                      isHalfWidthForMemberProfile={true}
                      isRupees={true}
                      required={true}
                      max={10}
                    />
                    <div className="row">
                      <div className="col-8 ml-auto">
                        {this.validator.message(
                          "Revenue",
                          turnOver,
                          "required|integer|max:10"
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <NormalInput
                      label="Authorised capital"
                      type="text"
                      onChange={this.handleInput}
                      value={AuthorisedCapital}
                      name="AuthorisedCapital"
                      isHalfWidthForMemberProfile={true}
                      isRupees={true}
                      max={10}
                    />
                    <div className="row">
                      <div className="col-8 ml-auto">
                        {this.validator.message(
                          "Authorised capital",
                          AuthorisedCapital,
                          "integer|max:10"
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <NormalInput
                      label="Sanctioned capital"
                      type="text"
                      onChange={this.handleInput}
                      value={sanctionedCapital}
                      name="sanctionedCapital"
                      isHalfWidthForMemberProfile={true}
                      isRupees={true}
                      max={10}
                    />
                    <div className="row">
                      <div className="col-8 ml-auto">
                        {this.validator.message(
                          "Sanctioned capital",
                          sanctionedCapital,
                          "integer|max:10"
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <NormalInput
                      label="Profit after tax"
                      type="text"
                      onChange={this.handleInput}
                      value={profitAT}
                      name="profitAT"
                      isHalfWidthForMemberProfile={true}
                      isRupees={true}
                      max={10}
                    />
                    <div className="row">
                      <div className="col-8 ml-auto">
                        {this.validator.message(
                          "Profit after tax",
                          profitAT,
                          "integer|max:10"
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <NormalInput
                      label="Any grant donation received"
                      type="text"
                      onChange={this.handleInput}
                      value={GrandDonationReceived}
                      name="GrandDonationReceived"
                      isHalfWidthForMemberProfile={true}
                      isRupees={true}
                      max={10}
                    />
                    <div className="row">
                      <div className="col-8 ml-auto">
                        {this.validator.message(
                          "grant donation received",
                          GrandDonationReceived,
                          "integer|max:10"
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="pt-5 pb-3 d-flex">
                  <div className="mx-auto d-flex">
                    {this.props.id && deleteAccess && (
                      <div className="cursor-pointer d-flex align-items-center mr-5">
                        <div
                          className="text-center fs-14 fw-600 text-danger d-flex"
                          onClick={this.toggleDelete}
                        >
                          <img className="mr-2" src={trash} alt="icon" />
                          {"Delete"}
                        </div>
                      </div>
                    )}
                    <NormalButton
                      label={`${this.props.id ? "Update" : "Submit & Next"}`}
                      mainbg={true}
                      className="px-3 py-2 fs-14 line-height-26"
                      onClick={this.handleSubmit}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <>
            {/* Loader */}
            <CommonPageLoader />
          </>
        )}
        <ConfirmationPopup
          isOpen={isDelete}
          toggle={this.toggleDelete}
          onSubmit={this.handleDelete}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  accessData: state.home.accessData,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      addKeyFinancialInfo,
      updateKeyFinancialInfo,
      getAllFinancialYearDropdown,
      getAllFinancialYear,
      getKeyFinancialInfoById,
      deleteKeyFinancialInfo,
      keyFinancialCurrentTab,
      // fileUpload,
    },
    dispatch
  );
};
let component = AddFinancialInfoClass;

export const AddFinancialInfo = connect(
  mapStateToProps,
  mapDispatchToProps
)(component);
