import React, { Component } from "react";
import { DragFileUpload, NormalEditor } from "../../../../../common";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// Redux Connection
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import pdfImage from "assets/svg/Documents/pdf.svg";
export class StoriesClass extends Component {
  constructor(props) {
    super(props);
    this.state = {
      storyDocs: [],
    };
  }

  componentDidMount() {
    let { storyObj = {} } = this.props;
    let { storyDocs = [] } = storyObj;
    this.setState({ storyDocs });
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps != this.props) {
      this.setState({
        storyDocs:
          this.props.storyObj && this.props.storyObj.storyDocs
            ? this.props.storyObj.storyDocs
            : [],
      });
    }
  };
  render() {
    let {
      validator,
      storyObj,
      handleInputChange,
      handleDateChange,
      handleFileUpload,
      isLoderFileUpload,
      onEditorChange,
      uploadCoverImg,
      removeCoverImg,
      removepostImage,
    } = this.props;

    let { storyDocs } = this.state;

    return (
      <div>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label className="required">
                Title
                <span className="text-danger font-weight-bold pl-2 fs-18">
                  *
                </span>
              </label>
              <input
                className="form-control"
                value={storyObj.title}
                name="title"
                onChange={handleInputChange}
              />
              {validator.message(
                "title",
                storyObj.title,
                "required|max:50|alpha_space"
              )}
            </div>
          </div>

          <div className="col-md-6">
            <div className="form-group">
              <label>
                Published on
                <span className="text-danger font-weight-bold pl-2 fs-18">
                  *
                </span>
              </label>
              <div className="input-group">
                <DatePicker
                  selected={storyObj.publishOn}
                  className="form-control border-right-0"
                  onChange={handleDateChange}
                  showMonthDropdown={true}
                  showYearDropdown={true}
                  minDate={new Date()}
                />
                <div className="input-group-append">
                  <span className="input-group-text" id="basic-addon2">
                    <img src="/icon/calendar.svg" alt="Girl in a jacket" />
                  </span>
                </div>
              </div>
              {validator.message(
                "publish date",
                storyObj.publishOn,
                "required"
              )}
            </div>
          </div>
          <div className="col-md-6 mb-3">
            <label className="event-cover">
              Upload Cover Image{" "}
              <span className="text-danger font-weight-bold pl-2 fs-18">*</span>
            </label>
            <div className="d-flex align-items-center w-100 btn-file-input">
              <button className="mr-2">
                <input
                  type="file"
                  accept=".jpg, .png, .jpeg"
                  onChange={(e) => uploadCoverImg(e, "storyDocs")}
                  id="coverImg"
                />
                Upload Image
              </button>
              {validator.message(
                "cover image",
                storyObj.storyDocs[0]?.fileName,
                "required"
              )}
              {storyObj.storyDocs.length != 0 && (
                <div className="pl-3 position-relative">
                  <img
                    src={storyObj.storyDocs[0]?.documentUrl}
                    alt=""
                    height="75"
                    width="75"
                  />
                  <i
                    className="icon-Delete text-danger fs-14 pl-2 cursor-pointer position-absolute"
                    onClick={() => removeCoverImg("storyDocs")}
                  ></i>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 mb-4">
            <NormalEditor
              label="Description"
              data={storyObj.description}
              onChange={onEditorChange}
              required={true}
            />
            {validator.message("Description", storyObj.description, "required")}
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label>Supporting Images</label>
              <DragFileUpload
                className="form-control drag-drop d-flex align-items-center"
                value={storyObj.storyImages}
                name="imageURL"
                acceptFileFormat={["jpg", "jpeg", "png"]}
                children={
                  <HelloMessage isLoderFileUpload={isLoderFileUpload} />
                }
                handleFileUpload={(e) => handleFileUpload(e, "successImages")}
              />
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-group">
              <div className="image-upload-area">
                {storyObj.successImages.length !== 0 &&
                  storyObj.successImages.map((list, index) => (
                    <div className="position-relative" key={index}>
                      <img
                        src={list.documentUrl}
                        alt="..."
                        className="image-upload position-relative"
                      />
                      <div className="close-icon">
                        <span
                          className="icon-close fs-10 cursor-pointer"
                          onClick={() =>
                            removepostImage(index, "successImages")
                          }
                        ></span>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label>Supporting Documents</label>
              <DragFileUpload
                className="form-control drag-drop d-flex align-items-center"
                value={storyObj.successPdf}
                name="imageURL"
                acceptFileFormat={["pdf"]}
                children={
                  <HelloMessage isLoderFileUpload={isLoderFileUpload} />
                }
                handleFileUpload={(e) => handleFileUpload(e, "successPdf")}
              />
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-group">
              <div className="image-upload-area">
                {storyObj.successPdf.length !== 0 &&
                  storyObj.successPdf.map((list, index) => (
                    <div className="position-relative" key={index}>
                      <img
                        src={pdfImage}
                        alt="..."
                        className="image-upload position-relative"
                      />
                      <div className="close-icon">
                        <span
                          className="icon-close fs-10 cursor-pointer"
                          onClick={() => removepostImage(index, "successPdf")}
                        ></span>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label>Supporting link </label>
              <input
                className="form-control"
                value={storyObj.supportingLink}
                name="supportingLink"
                onChange={handleInputChange}
              />
              {validator.message(
                "supporting link",
                storyObj.supportingLink,
                "url"
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

export const AwardsTab = connect(
  mapStateToProps,
  mapDispatchToProps
)(StoriesClass);

class HelloMessage extends React.Component {
  render() {
    let { isLoderFileUpload, supportingFIleName } = this.props;
    return (
      <div className=" d-flex upload-drag">
        <div className="ml-3">
          <h4>Upload photo</h4>
          <label>Drag & drop your file here {supportingFIleName}</label>
        </div>
        <div className="ml-3 mr-3 align-self-center">
          <label>or</label>
        </div>
        <div className="ml-5 align-self-center">
          <button
            type="button"
            className="btn mainbg-btn brows-btn outline-btn float-right"
          >
            Browse files{" "}
            {isLoderFileUpload ? (
              <span
                className="spinner-border spinner-border-sm ml-1"
                role="status"
                aria-hidden="true"
              ></span>
            ) : (
              ""
            )}
          </button>
        </div>
      </div>
    );
  }
}
