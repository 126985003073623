import React, { Component } from "react";
import moment from "moment";
import { history } from "service/helpers";

const User_Profile_1 = require("assets/svg/Members/User_Profile_1.svg");

export class Strip extends Component {
  render() {
    let { payload, index, editAccess } = this.props;
    const {
      id,
      profile = "",
      fymYear,
      gsstNumber,
      dueDate,
      dateOfFiling,
      consultantName,
      remarks = "",
    } = payload;
    return (
      <div
        index={index}
        className="container-sa license-strip shadow rounded mb-3"
      >
        <div className="row align-items-center h-100 px-2">
          <div className="col-1 position-relative h-100 d-flex justify-content-center align-items-center">
            <img
              className="rounded-circle income-card-profile position-absolute"
              src={profile.includes("https://") ? profile : User_Profile_1}
              alt="profile"
            />
            <div className="position-absolute text-white fs-20 font-weight-bold">
              {consultantName ? consultantName.charAt(0) : "-"}
            </div>
          </div>
          <div className="col-2">
            <div className="fs-18 fw-600">
              {consultantName ? consultantName : "-"}
            </div>
            <div className="text-black-50">Consultant name</div>
          </div>
          <div className="col-2">
            <div className="fs-18 fw-600">{gsstNumber ? gsstNumber : "-"}</div>
            <div className="text-black-50">GST No</div>
          </div>
          <div className="col-2">
            <div className="fs-18 fw-600">{fymYear}</div>
            <div className="text-black-50">Financial year</div>
          </div>
          <div className="col-2">
            <div className="fs-18 fw-600">
              {dueDate ? moment(dueDate).format("DD MMMM YYYY") : "-"}
            </div>
            <div className="text-black-50">Due date</div>
          </div>
          <div className="col-2">
            <div className="fs-18 fw-600">
              {dateOfFiling ? moment(dateOfFiling).format("DD MMMM YYYY") : "-"}
            </div>
            <div className="text-black-50">Date Of filing</div>
          </div>
          <div className="col-1 d-flex">
            <div
              className="text-black-50 cursor-pointer mr-3"
              onClick={() => history.push(`/fpo/compliance/gst-return/${id}`)}
            >
              {"View"}
            </div>
            {editAccess && (
              <div
                className="text-black-50 cursor-pointer"
                onClick={() => history.push(`/fpo/edit-gstreturn/${id}`)}
              >
                {"Edit"}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
