import React from "react";

import styles from "./CheckBox.module.scss";

const CheckBox = ({
  label,
  name,
  onChange,
  isChecked,
  value = null,
  hover = true,
  disabled = false,
  link = "/termsandcondition",
  needLine = false,
}) => {
  return (
    <div className={styles.checkbox}>
      <input
        type="checkbox"
        name={name}
        className={styles.custom_checkbox}
        checked={isChecked}
        value={label}
        onChange={(e) =>
          onChange({
            target: {
              name: name,
              value: value !== null ? value : e.target.checked,
              checked: e.target.checked,
            },
          })
        }
        disabled={disabled}
      />
      {hover ? (
        <a href={link} target="_bank">
          <label className="mt-0">{label}</label>
        </a>
      ) : (
        <>
          {needLine ? (
            <span className={`${styles.termsConditionliner}`}>{label}</span>
          ) : (
            <b>{label}</b>
          )}
        </>
      )}
    </div>
  );
};

export default CheckBox;
