import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Modal, ModalBody } from 'reactstrap';
import { NormalButton, NormalInput, NormalDate, NormalSelect } from "component/common";
import SimpleReactValidator from 'simple-react-validator';
import "./style.scss"
import {
    getFPOlistCapacityBuilding,
    addCapacityBuilding
} from "action/RmAct"
import moment from "moment"
export class CreateCapacityBuildingPopupClass extends Component {

    state = {
        fpoId: '',
        advisoryService: '',
        date: '',
        noOfAttendees: '',
        fpoOptions: []
    }

    //validation
    validator = new SimpleReactValidator({
        element: message => <span className="error-message text-danger fs-14">{message}</span>,
        autoForceUpdate: this,
    });

    handleInput = ({ target: { name, value } }) => {
        this.setState({
            [name]: value
        })
    }

    //handle submit
    handleSubmit = () => {
        if (this.validator.allValid()) {
            let {
                fpoId,
                advisoryService,
                date,
                noOfAttendees,
            } = this.state
            let body = {
                fpoId,
                advisoryService,
                date: moment(date).format('YYYY-MM-DD'),
                noOfAttendees,
            }
            this.props.addCapacityBuilding(body).then(() => {
                this._clearData()
                this.props.handleChange()
            })
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    _clearData = () => {
        this.validator.hideMessages()
        this.setState({
            fpoId: '',
            advisoryService: '',
            date: '',
            noOfAttendees: '',
            fpoOptions: []
        }, () => {
            this.props.toggle()
        })
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            if (this.props.isOpen) {
                this.getFPOList()
            }
        }
    }

    getFPOList = () => {
        let { getFPOlistCapacityBuilding } = this.props

        getFPOlistCapacityBuilding().then((data) => {
            let listofFpo = []
            data.map((list) => {
                if (list.fpoDetails !== null && list.fpoDetails.fpoName) {
                    listofFpo.push({ value: list.fpoId, label: list.fpoDetails.fpoName })
                }
            })
            this.setState({ fpoOptions: listofFpo })
        })
    }

    render() {
        let {
            className = 'modal-dialog-centered create-capacity-building-popup',
            isOpen = true,
            toggle
        } = this.props;

        let {
            fpoId,
            advisoryService,
            date,
            noOfAttendees,
            fpoOptions
        } = this.state

        return (
            <>
                <Modal isOpen={isOpen} toggle={toggle} className={className} size="lg">
                    <ModalBody className='p-0'>
                        <div className='d-flex justify-content-between'>
                            <p className="text-green-18 fs-28 line-height-31 font-weight-bold text-center">
                                {'Create'}
                            </p>
                            <i
                                className='icon-close fs-16 cursor-pointer'
                                onClick={() => this._clearData()}
                            ></i>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <NormalSelect
                                    label="Name of the FPO"
                                    placeholder="Select"
                                    value={fpoId}
                                    arrow={true}
                                    name="fpoId"
                                    options={fpoOptions}
                                    handleChange={this.handleInput}
                                />
                                <div className='row'>
                                    <div className='col-9 ml-auto'>
                                        {this.validator.message(
                                            "fpoId",
                                            fpoId,
                                            "required"
                                        )}
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <NormalInput
                                    label="Type of advisory service"
                                    type="text"
                                    onChange={this.handleInput}
                                    value={advisoryService}
                                    placeholder="Enter here"
                                    name="advisoryService"
                                />
                                <div className='row'>
                                    <div className='col-9 ml-auto'>
                                        {this.validator.message(
                                            "advisoryService",
                                            advisoryService,
                                            "required|max:50"
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <NormalDate
                                    isIcon={true}
                                    value={date}
                                    name="date"
                                    placeholder="DD/MM/YYYY"
                                    onChange={this.handleInput}
                                    mindate={false}
                                    maxdate={false}
                                    timeInput={false}
                                    label={"Date"}
                                />
                                <div className='row'>
                                    <div className='col-9 ml-auto'>
                                        {this.validator.message(
                                            "date",
                                            date,
                                            "required"
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <NormalInput
                                    label="No. of Attendees"
                                    type="number"
                                    onChange={this.handleInput}
                                    value={noOfAttendees}
                                    placeholder="Enter here"
                                    name="noOfAttendees"
                                />
                                <div className='row'>
                                    <div className='col-9 ml-auto'>
                                        {this.validator.message(
                                            "noOfAttendees",
                                            noOfAttendees,
                                            "required|max:6"
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="w-100 mt-5">
                            <div className="d-flex justify-content-end my-3">
                                <NormalButton
                                    label="Create"
                                    mainbg={true}
                                    onClick={() => this.handleSubmit()}
                                    className="ml-auto"
                                />
                            </div>
                        </div>
                    </ModalBody>
                </Modal >
            </>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getFPOlistCapacityBuilding,
            addCapacityBuilding
        },
        dispatch
    );
};


let component = CreateCapacityBuildingPopupClass;

export const CreateCapacityBuildingPopup = connect(null, mapDispatchToProps)(component);