import { Documemts } from "service/apiVariables";
import { Toast } from "service/toast";
import { api } from "service/api";
import { documentType } from "service/actionType";

//add Fpo Documents for all tabs
export const addFpoDocuments = (body, tabName) => (dispatch, getState) => {
  let { addFpoDocuments } = Documemts;
  addFpoDocuments.tabName = tabName;
  return new Promise((resolve, reject) => {
    api({ ...Documemts.addFpoDocuments, body })
      .then(({ data, message }) => {
        resolve(data);
        Toast({ type: "success", message, time: 5000 });
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//view Fpo Documents
export const viewFpoDocuments = (tabName) => (dispatch, getState) => {
  let { viewFpoDocuments } = Documemts;
  viewFpoDocuments.tabName = tabName;
  return new Promise((resolve, reject) => {
    api({ ...Documemts.viewFpoDocuments })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

export const getMasterData = (body) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    api({ ...Documemts.getMasterData, body })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//delete Fpo Documents for all tabs
export const deleteFpoDocuments = (body, tabName) => (dispatch, getState) => {
  let { deleteFpoDocuments } = Documemts;
  deleteFpoDocuments.tabName = tabName;
  return new Promise((resolve, reject) => {
    api({ ...Documemts.deleteFpoDocuments, body })
      .then(({ data, message }) => {
        resolve(data);
        Toast({ type: "success", message, time: 5000 });
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

export const getCompanyKycBulkdownload = (body) => (dispatch, getState) => {
  let { getCompanyKycBulkdownload } = Documemts;

  getCompanyKycBulkdownload.id = body.id;

  return new Promise((resolve, reject) => {
    api({ ...Documemts.getCompanyKycBulkdownload })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

export const getFinancialBulkdownload = (body) => (dispatch, getState) => {
  let { getFinancialBulkdownload } = Documemts;

  getFinancialBulkdownload.id = body.id;

  return new Promise((resolve, reject) => {
    api({ ...Documemts.getFinancialBulkdownload })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

export const getDirectdocBulkdownload = (body) => (dispatch, getState) => {
  let { getDirectdocBulkdownload } = Documemts;

  getDirectdocBulkdownload.id = body.id;

  return new Promise((resolve, reject) => {
    api({ ...Documemts.getDirectdocBulkdownload })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

export const getcreditdataBulkdownload = (body) => (dispatch, getState) => {
  let { getcreditdataBulkdownload } = Documemts;

  getcreditdataBulkdownload.id = body.id;

  return new Promise((resolve, reject) => {
    api({ ...Documemts.getcreditdataBulkdownload })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

export const getstatutoryBulkdownload = (body) => (dispatch, getState) => {
  let { getstatutoryBulkdownload } = Documemts;

  getstatutoryBulkdownload.id = body.id;

  return new Promise((resolve, reject) => {
    api({ ...Documemts.getstatutoryBulkdownload })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

export const documentCurrentTab = (payload) => (dispatch) => {
  dispatch({ type: documentType.documentCurrentTab, payload });
};

export const statutoryEdit = (body) => () => {
  return new Promise((resolve, reject) => {
    api({ ...Documemts.statutoryEdit, body })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

export const companyKYCEdit = (body) => () => {
  return new Promise((resolve, reject) => {
    api({ ...Documemts.companyKYCEdit, body })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

export const directDocEdit = (body) => () => {
  return new Promise((resolve, reject) => {
    api({ ...Documemts.directDocEdit, body })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

export const financialEdit = (body) => () => {
  return new Promise((resolve, reject) => {
    api({ ...Documemts.financialEdit, body })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

export const creditDataEdit = (body) => () => {
  return new Promise((resolve, reject) => {
    api({ ...Documemts.creditDataEdit, body })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};
