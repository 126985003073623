import React, { useEffect } from "react";
import BackButton from "component/common/BackButton";
import { SurveyToolComp } from "component/RM";

export const SurveyTool = () => {
  const fpoName = localStorage.getItem("rmFPOName");
  const queryParams = new URLSearchParams(window?.location?.search);
  const token = queryParams.get("token");
  const url = window?.location?.href;
  const isGradingTool = url?.includes("rm/grading-tool");
  const isMobileLogin = isGradingTool && token !== null ? true : false;

  useEffect(() => {
    if (isMobileLogin) localStorage.setItem("AuthToken", token);
  }, [token, isMobileLogin]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div>
      {!isMobileLogin ? (
        <div className="inner-header">
          <div className="h-100 w-100">
            <p className="rm_title d-flex justify-content-center align-items-center h-100 w-100 text-white fs-32 font-weight-bold">
              Grading tool of {fpoName} FPO
            </p>
          </div>
        </div>
      ) : null}
      {!isMobileLogin ? <BackButton /> : null}
      <SurveyToolComp />
    </div>
  );
};
