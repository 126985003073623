import React, { Component } from "react";
import "./PasswordStrengthMeter.scss";
import { NormalCheckbox } from "../NormalCheckbox";

export class PasswordStrengthMeter extends Component {
  state = {
    checkboxType: [
      {
        name: "UpperCase",
        status: false,
      },
      {
        name: "LowerCase",
        status: false,
      },
      {
        name: "Number",
        status: false,
      },
      {
        name: "Special",
        status: false,
      },
    ],
  };

  // handle password
  createPasswordLabel = (result) => {
    switch (result) {
      case 0:
      case 1:
        return "Weak";
      case 2:
        return "Fair";
      case 3:
        return "Good";
      case 4:
        return "Strong";
      case 5:
        return "Excellent";
      default:
        return "Weak";
    }
  };

  //Validating the password
  validateStrength = (password) => {
    const smallLetter = RegExp("(?=.*[a-z])", "g");
    const capitalLetter = RegExp("(?=.*[A-Z])", "g");
    const number = RegExp("(?=.*[0-9])", "g");
    const special = RegExp("(?=.*[#$^+=!*()@%&])", "g");

    let checkboxType = Object.assign({}, this.state.checkboxType);

    let score = 0;
    checkboxType[0].status = false;
    checkboxType[1].status = false;
    checkboxType[2].status = false;
    checkboxType[3].status = false;

    if (capitalLetter.test(password)) {
      score = score + 1;
      checkboxType[0].status = true;
    }
    if (smallLetter.test(password)) {
      score = score + 1;
      checkboxType[1].status = true;
    }
    if (number.test(password)) {
      score = score + 1;
      checkboxType[2].status = true;
    }
    if (special.test(password)) {
      score = score + 1;
      checkboxType[3].status = true;
    }
    if (password && password.length >= 8) {
      score = score + 1;
    }

    return score;
  };

  render() {
    let { checkboxType } = this.state;

    let { data, checkboxDesign = true } = this.props;

    const testScore = this.validateStrength(data);

    return (
      <>
        {data ? (
          <div className="password-strength-meter">
            <label className="password-strength-meter-label w-100 mb-0">
              {data && (
                <>
                  <b>Strength:</b> {this.createPasswordLabel(testScore)}
                </>
              )}
            </label>
            <progress
              className={`password-strength-meter-progress w-100 strength-${this.createPasswordLabel(
                testScore
              )}`}
              value={testScore}
              max="5"
            />
            {checkboxDesign ? (
              <ul className="d-flex flex-wrap justify-content-center mb-0 pt-3 px-0">
                {checkboxType.map((item, index) => {
                  let { name, status } = item;

                  return (
                    <li key={index} className="px-2">
                      <NormalCheckbox
                        label={name}
                        name={name}
                        value={status}
                        checked={status}
                        inputclassName="not-allowed"
                      />
                    </li>
                  );
                })}
              </ul>
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )}
      </>
    );
  }
}
