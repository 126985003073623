import { noification } from "../service/apiVariables";
import { addQuery } from "service/helperFunctions";
import { academyType } from "service/actionType";
//get notification list
export const getNotificationList = (query) => (
  dispatch,
  getState,
  { api, Toast }
) => {
  return new Promise((resolve, reject) => {
    addQuery(query, noification.getNotificationList);
    api({ ...noification.getNotificationList })
      .then((data) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//get Notification Count
export const getNotificationCount = () => (
  dispatch,
  getState,
  { api, Toast }
) => {
  return new Promise((resolve, reject) => {
    api({ ...noification.getNotificationCount })
      .then(({ data }) => {
        dispatch({
          type: academyType.notificationCount,
          payload: data.nonViewedCount,
        });
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//update All Notification Status
export const updateAllNotificationStatus = () => (
  dispatch,
  getState,
  { api, Toast }
) => {
  return new Promise((resolve, reject) => {
    api({ ...noification.updateAllNotificationStatus })
      .then(({ data, message }) => {
        Toast({ type: "success", message });
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//update single Notification Status
export const updateSingleNotificationStatus = (id) => (
  dispatch,
  getState,
  { api, Toast }
) => {
  let { updateSingleNotificationStatus } = noification;
  updateSingleNotificationStatus.id = id;
  return new Promise((resolve, reject) => {
    api({ ...noification.updateSingleNotificationStatus })
      .then(({ data, message }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};
