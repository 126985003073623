import React, { Component } from "react";
import backIcon from "../../assets/images/icon/arrow-left.svg";
import { Banner } from "component/Acadamy";
import { infomativeDetailApi, relatedTrendingVideo } from "action/Acadamy";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { history } from "service/helpers";
import { InfomativeView } from "component/Acadamy";
import { CommonPageLoader } from "component/common";

export class ViewInfomativeVideoClass extends Component {
  state = {
    infomativeDetail: {},
    isLoading: false,
    relatedVideos: [],
  };

  //life cycles
  componentDidMount = () => {
    this.getDetails();
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  getDetails = () => {
    this.setState({ isLoading: true });
    let { id } = this.props.match.params;
    this.props.infomativeDetailApi(id).then((data) => {
      this.setState({ infomativeDetail: data });
      this.props.relatedTrendingVideo(id).then((data) => {
        this.setState({ relatedVideos: data, isLoading: false });
      });
    });
  };

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.id != this.props.match.params.id) {
      this.getDetails();
    }
  }

  render() {
    let { infomativeDetail, isLoading, relatedVideos } = this.state;
    return (
      <div className="min-vh-100">
        <Banner minSize={true} />
        <div className="d-flex container-sa my-3 ">
          <div
            onClick={() => history.goBack()}
            className="d-flex cursor-pointer"
          >
            <img className="cursor-pointer" src={backIcon} alt="back" />
            <p className="ml-3 add-member-text-back mb-0">{"Back"}</p>
          </div>
        </div>
        {isLoading ? (
          <>
            {/* Loader */}
            <CommonPageLoader />
          </>
        ) : (
          <InfomativeView
            infomativeDetail={infomativeDetail}
            handleChange={() => this.getDetails()}
            relatedVideos={relatedVideos}
          />
        )}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      infomativeDetailApi,
      relatedTrendingVideo,
    },
    dispatch
  );
};

let component = ViewInfomativeVideoClass;

export const ViewInfomativeVideo = connect(null, mapDispatchToProps)(component);
