import React, { Component } from "react";
import "./style.scss";
import { getStaffById } from "action/BodAct";

import { CommonPageLoader } from "component/common";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { history } from "service/helpers/index";

import moment from "moment";

import { getFilePathDownload } from "service/api";
import userProfile from "assets/images/default-profile-img.png";
import arrow_left from "assets/svg/add_member/arrow_left.svg";
import { currentTab } from "action/MemberAct";

import { getStaffByIdForRM } from "action/RmAct";

export class ViewstaffClass extends Component {
  constructor(props) {
    super();
    this.state = {
      staffDetails: {},
      isloading: false,
    };
  }
  componentDidMount() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    let { id } = this.props;
    this._getData(id);
  }

  _getData = (id) => {
    let { getStaffById, fpoid, getStaffByIdForRM } = this.props;

    this.setState({ isloading: true });

    if (fpoid) {
      let body = {
        id: id,
        fpoid: fpoid,
      };
      getStaffByIdForRM(body).then((data) => {
        this.setState({
          staffDetails: data,
          isloading: false,
        });
      });
    } else {
      let body = {
        id: id,
      };
      getStaffById(body).then((data) => {
        this.setState({
          staffDetails: data,
          isloading: false,
        });
      });
    }
  };

  handleBack = () => {
    this.props.currentTab(3);
    history.goBack();
  };

  render() {
    let { staffDetails = {}, isloading } = this.state;

    let {
      profileImageUrl = "",
      firstName,
      lastName,
      gender,
      dob,
      mobileNumber,
      employeeId,
      designation,
      educationmaster = {},
      identityProofName,
      addressProofName,
      resumeDocName,
      identityProofUrl,
      addressProofUrl,
    } = staffDetails;

    let { qualificationType } = educationmaster !== null ? educationmaster : {};

    return (
      <div className="view-staff">
        <div className="d-flex my-3 container-sa px-0">
          <div
            onClick={() => {
              this.handleBack();
            }}
            className="d-flex"
          >
            <img className="cursor-pointer" src={arrow_left} alt="back" />
            <p className="fs-18 font-weight-bold font-Gilroy text-black mb-0 cursor-pointer ml-3">
              {"Staff detail page"}
            </p>
          </div>
        </div>
        {!isloading ? (
          <div className="bg-area py-md-4 py-0">
            <div className="container">
              <div className="bg-white p-md-5 p-3 shadow mx-md-5 mx-0">
                <div className="row">
                  <div className="col-md-3 mx-auto">
                    <div className="profile-area">
                      <div className="profile-pic">
                        <img
                          src={
                            profileImageUrl !== "" && profileImageUrl
                              ? profileImageUrl
                              : userProfile
                          }
                          className="shadow"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mt-5">
                  <div className="col-md-6">
                    <div className="row">
                      <div className="col-4">
                        <p className="text-black font-weight-bold fs-16 pr-2">
                          Name :
                        </p>
                      </div>
                      <div className="col-md-8">
                        <p className="text-black-50 fs-16">
                          {firstName} {lastName}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <p className="text-black font-weight-bold fs-16 pr-2">
                          Gender :
                        </p>
                      </div>
                      <div className="col-md-8">
                        <p className="text-black-50 fs-16">
                          {gender && gender !== "" ? gender : "-"}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <p className="text-black font-weight-bold fs-16 pr-2">
                          DOB :
                        </p>
                      </div>
                      <div className="col-md-8">
                        <p className="text-black-50 fs-16">
                          {dob ? moment(dob).format("DD MMMM YYYY") : "-"}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <p className="text-black font-weight-bold fs-16 pr-2">
                          Phone :
                        </p>
                      </div>
                      <div className="col-md-8">
                        <p className="text-black-50 fs-16">{mobileNumber}</p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <p className="text-black font-weight-bold fs-16 pr-2">
                          Employee ID :
                        </p>
                      </div>
                      <div className="col-md-8">
                        <p className="text-black-50 fs-16">
                          {employeeId ? employeeId : "-"}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="row">
                      <div className="col-md-4">
                        <p className="text-black font-weight-bold fs-16 pr-2">
                          Designation:{" "}
                        </p>
                      </div>
                      <div className="col-md-8">
                        <p className="text-black-50 fs-16">
                          {designation ? designation : "-"}
                        </p>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-4">
                        <p className="text-black font-weight-bold fs-16 pr-2">
                          Identity proof :
                        </p>
                      </div>
                      <div className="col-md-8">
                        {identityProofName ? (
                          <a
                            className="fs-16 text-green-18 text-underline cursor-pointer mb-3"
                            href={
                              identityProofName &&
                              getFilePathDownload + identityProofUrl
                            }
                            download
                          >
                            {"Download"}
                          </a>
                        ) : (
                          "-"
                        )}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <p className="text-black font-weight-bold fs-16 pr-2">
                          Address proof :
                        </p>
                      </div>
                      <div className="col-md-8">
                        {addressProofName ? (
                          <a
                            className="fs-16 text-green-18 text-underline cursor-pointer mb-3"
                            href={
                              addressProofName &&
                              getFilePathDownload + addressProofUrl
                            }
                            download
                          >
                            {"Download"}
                          </a>
                        ) : (
                          "-"
                        )}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <p className="text-black font-weight-bold fs-16 pr-2">
                          Resume :
                        </p>
                      </div>
                      <div className="col-md-8">
                        <p className="text-black-50 fs-16">
                          {resumeDocName ? resumeDocName : "-"}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <p className="text-black font-weight-bold fs-16 pr-2">
                          Education{" "}
                          <span className="text-nowrap">
                            {"qualification :"}
                          </span>
                        </p>
                      </div>
                      <div className="col-md-8">
                        <p className="text-black-50 fs-16">
                          {qualificationType ? qualificationType : "-"}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <>
            {/* Loader */}
            <CommonPageLoader />
          </>
        )}
        <div className="view-members"></div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getStaffById,
      currentTab,
      getStaffByIdForRM,
    },
    dispatch
  );
};
let component = ViewstaffClass;

export const Viewstaff = connect(null, mapDispatchToProps)(component);
