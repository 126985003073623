import React, { Component } from "react";
import { history } from "service/helpers";
import "./CoursesUpNext.scss";
import { getDescriptionData } from "service/helperFunctions";
import placeholder from "assets/images/placeholder.png";
import play from "assets/images/icon/play.svg";

export class CoursesUpNext extends Component {
  quizHandel = (data, isQuizStarted) => {
    if (isQuizStarted) {
      history.push(`/academy/quiz/${data.courseId}/retakeQuiz`);
    } else {
      history.push(`/academy/quiz/${data.courseId}`);
    }
  };

  render() {
    let {
      sectionsData = [],
      quizes = [],
      handelUpComing,
      coursesData,
    } = this.props;
    let {
      thumbnailFileUrl,
      myLearnings = false,
      isQuizStarted = false,
    } = coursesData;
    let posterImgUrl =
      thumbnailFileUrl === "bannerFileUrl" || thumbnailFileUrl === null
        ? placeholder
        : thumbnailFileUrl;

    return (
      <>
        <section className="up-next-section">
          <h4>Sections</h4>
          {sectionsData.map((data, index) => {
            let { title } = data;
            return (
              <div
                key={index + "upComing"}
                className="up-next-card cursor-pointer"
                onClick={() => handelUpComing(data, true)}
              >
                <div className="row align-items-center">
                  <div className="col-sm-6">
                    <div className="up-next-img-thumb play-center">
                      <img src={posterImgUrl} alt="posterImgUrl" srcSet="" />
                      <div className="play-button">
                        <img
                          className="play-icon"
                          src={play}
                          alt="play-icon"
                          srcSet=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="up-next-content">
                      <h4>{title}</h4>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: getDescriptionData(data.objectiveSection, 70),
                        }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
          {myLearnings ? (
            <div className="mb-5">
              {quizes.length > 0 ? <h4 className="mt-4">Quiz</h4> : null}
              {quizes.map((data, index) => {
                let { questions = [] } = data;
                return (
                  <div
                    key={index + "upComing"}
                    className="up-next-card cursor-pointer"
                    onClick={() => this.quizHandel(data, isQuizStarted)}
                  >
                    <div className="row align-items-center">
                      <div className="col-sm-6">
                        <div className="up-next-img-thumb play-center">
                          <img src={posterImgUrl} alt="play-icon" srcSet="" />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="up-next-content">
                          <h4>Quiz {index + 1}</h4>
                          <p>
                            total question : <b>{questions.length}</b>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : null}
        </section>
      </>
    );
  }
}
