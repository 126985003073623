import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { history } from "service/helpers/index";
import moment from "moment";
import {
    getStageTwoList,
    sellRequestDetail,
    stageTwoAction,
    getMarketCentreListRM,
    getMarketCentreList
} from "action/SellrequestAct";

import { CommonPageLoader } from "component/common";

import "../ListView/style.scss";
const arrow_left_white = require("assets/svg/add_member/arrow_left_white.svg");

export class StageTwoClass extends Component {
    constructor() {
        super();
        this.state = {
            detail: {},
            list: [],
            isLoading: false,
            otherProcurement: 0,
            transportCost: 0,
            loadUnloadCosts: 0,
            spillageWastageCosts: 0,
            otherTaxes: 0,
            govCost: 0,
            totalProcurimentPrice: 0,
            status0Array: [],
            mandiprice: 0
        }
    }
    // Lifecycles
    componentDidMount() {
        this.stagetwoApi();
        this.detailApi();
    }

    handleInput = ({ target: { name, value } }) => {
        this.setState({
            [name]: value,
        });
    }

    numberWithDecimal(type) {
        return (isNaN(type) === false && type != null && type != '') ? parseInt(type).toLocaleString('en-IN', { maximumFractionDigits: 2 }) : 0;
    }

    getTotalCosts = (answer) => {
        return this.getProcurementsCosts(answer, 0, 0) + this.getLogisticsCosts(answer, 0, 0);
    }

    getProcurementsCosts(answer, type, multiply = '') {
        var value = 0;
        if (type === 0) {
            value = (this.state.detail?.promised_procurement_price || 0) + Number(this.state.otherProcurement);
        } else {
            value = (this.state.detail?.promised_procurement_price || 0) + Number(answer?.fpo_sell_request_buyer_cost?.procurement_other_cost);
        }
        if (multiply === '') {
            value = value * Number(answer?.total_quantity);
        } else {
            value = value;
        }
        return value;
    }

    getLogisticsCosts(answer, type, multiply = '') {
        var value = 0;
        if (type === 0) {
            value = (Number(this.state.transportCost) + Number(this.state.loadUnloadCosts) + Number(this.state.spillageWastageCosts) + Number(this.state.otherTaxes)) - Number(this.state.govCost);
        } else {
            value = (Number(answer?.fpo_sell_request_buyer_cost?.logistic_transport_cost) + Number(answer?.fpo_sell_request_buyer_cost?.logistic_loaded_unloaded_cost) + Number(answer?.fpo_sell_request_buyer_cost?.logistic_wastage_cost) + Number(answer?.fpo_sell_request_buyer_cost?.logistic_tax_charge)) - Number(answer?.fpo_sell_request_buyer_cost?.logistic_per_unit_redeemed_by_govt);
        }
        if (multiply === '') {
            value = value * Number(answer?.total_quantity);
        } else {
            value = value;
        }
        return value;
    }

    getsellingCosts(answer, type) {
        return Number(answer?.price_offered * answer?.total_quantity);
    }

    detailApi() {
        let { id } = this.props;
        this.props.sellRequestDetail(id).then((data) => {
            
            this.props.getMarketCentreList({
                "commodityName": data[0]?.commodity_name,
                "districtName": data[0]?.districtName,
            }).then((resp) => {

                var marketList = resp?.filter((list) => list?.Market_Centre === data[0].fpo_destination_market);
                var mandiprice = 0;
                if (marketList.length > 0) {
                    mandiprice = marketList[0]?.Modal_Price
                } else {
                    mandiprice = 0;
                }
                
                this.setState({
                    mandiprice: mandiprice
                });
            });
            this.setState({
                detail: data[0],
            });
        });
    }
    // Functions
    stagetwoApi() {
        let { id } = this.props;
        this.setState({ isLoading: true });
        this.props.getStageTwoList(id).then((data) => {
            
            this.setState({
                list: data,
                isLoading: false,
            });
            var status0Array = [];
            if (status0Array.length == 0) {
                status0Array.push(data[data.length - 1]);
            }

            if (status0Array[0] && status0Array[0]?.fpo_sell_request_buyer_cost) {
                var count = status0Array[0]?.fpo_sell_request_buyer_cost;
                
                this.setState({
                    status0Array: status0Array[0],
                    otherProcurement: count?.procurement_other_cost,
                    transportCost: count?.logistic_transport_cost,
                    loadUnloadCosts: count?.logistic_loaded_unloaded_cost,
                    spillageWastageCosts: count?.logistic_wastage_cost,
                    otherTaxes: count?.logistic_tax_charge,
                    govCost: count?.logistic_per_unit_redeemed_by_govt,
                });
            }
        });
    }

    // Approve Decline api call
    action(id, type) {
        this.setState({ isLoading: true });
        this.props.stageTwoAction({
            "fpo_sell_request_buyer_id": id,
            "status": type,
            "procurement_promised_cost": this.state.detail?.promised_procurement_price || 0,
            "procurement_other_cost": this.state.otherProcurement || 0,
            "logistic_transport_cost": this.state.transportCost || 0,
            "logistic_loaded_unloaded_cost": this.state.loadUnloadCosts || 0,
            "logistic_wastage_cost": this.state.spillageWastageCosts || 0,
            "logistic_tax_charge": this.state.otherTaxes || 0,
            "logistic_per_unit_redeemed_by_govt": this.state.govCost || 0
        }).then((data) => {
            
            this.setState({ isLoading: false });
            // this.stagetwoApi();
            history.push("/fpo/sell-request");
        });
    }

    // Approve Decline api call
    onBlur = ({ target: { name, value } }) => {
        var postdata = {
            "fpo_sell_request_buyer_id": this.state.status0Array.id,
            "procurement_promised_cost": this.state.detail?.promised_procurement_price || 0,
            "procurement_other_cost": this.state.otherProcurement || 0,
            "logistic_transport_cost": this.state.transportCost || 0,
            "logistic_loaded_unloaded_cost": this.state.loadUnloadCosts || 0,
            "logistic_wastage_cost": this.state.spillageWastageCosts || 0,
            "logistic_tax_charge": this.state.otherTaxes || 0,
            "logistic_per_unit_redeemed_by_govt": this.state.govCost || 0,
            "status": this.state.status0Array.status
        }
        
        this.props.stageTwoAction(postdata).then((data) => {
            
        });
    }

    render() {
        const {
            list,
            isLoading,
            detail,
            otherProcurement,
            transportCost,
            loadUnloadCosts,
            spillageWastageCosts,
            otherTaxes,
            govCost,
            mandiprice,
            status0Array
        } = this.state;
        return (
            <div className="add-re-scr">

                <div className="container-sa position-relative mt-4">

                    {/* INFO SECTION */}
                    <div className="add-form-section">
                        <div className="row items-center justify-between">

                            <React.Fragment>
                                <h2 className="m-0 fs-18 mt-0 font-weight-bold">Stage 2: <span className="green-txt">Approval</span> {detail?.commodity_name ? '- ' + detail?.commodity_name : ''}</h2>
                                {list?.length > 0 ? (
                                    <div className="inner-bg-sec text-center">
                                        <h4>Buyer Recommendation</h4>
                                        <div className="notes-info">This is the Buyer Recommended by your RM - <b>Rames</b></div>

                                    </div>
                                ) : ''}
                            </React.Fragment>


                            <div className=" pr-0">
                                <div onClick={() => history.push("/fpo/sell-request")} className="text-right d-flex align-items-center justify-content-end">
                                    <p className="btn btn-secondary btn-sm add-member-text-back mt-0 mb-0 cursor-pointer d-flex align-items-center justify-content-center w-150">
                                        <img className="mr-2" src={arrow_left_white} alt="name" width="15" />
                                        Back
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* INFO SECTION */}
                <div className="clearfix"></div>
                <>
                    {!isLoading && (list?.length === 0) ? (
                        <p className="py-4 mb-0 text-black-50 fs-22 line-height-25 text-center">
                            No list found
                        </p>
                    ) : !isLoading ? (
                        <>
                            {list?.length > 0 ? (
                                <>
                                    <div className="container-sa position-relative mt-3">
                                        <div className="tableresponsive">
                                            <table className="table-bordered step-two-table">
                                                <thead>
                                                    <tr>
                                                        <th>Price Offered</th>
                                                        <th>Buyer Market Price</th>
                                                        <th>Quantity Demanded</th>
                                                        <th>Sale Value</th>
                                                        <th>Logistics Cost</th>
                                                        {/* <th>Expected<br /> Farmer No</th> */}
                                                        <th>Procurement Cost</th>
                                                        <th>FPO Total<br /> Margins</th>
                                                        <th width="100">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    {list.map((answer, i) => {
                                                        return (
                                                            <React.Fragment key={i}>
                                                                <tr>
                                                                    <td colSpan="9" className="buyer-name">Buyer {i + 1} - {answer?.districtNames?.districtName}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td className={answer?.price_offered > mandiprice && i == list.length - 1 ? 'active-green' : 'not-active-green'}>₹ {this.numberWithDecimal(answer?.price_offered)}/{detail?.custom_measurement === '' ? detail?.unit_of_measurement_name : detail?.custom_measurement}</td>
                                                                    <td>
                                                                        <p className="mb-0 valuetxt">{'₹ ' + this.numberWithDecimal(answer?.modelPrice) + '/Quintal'}<br /> <small className="opacity-50"><b><i>{answer?.modelPriceDate != '' ? '(' + moment(answer?.modelPriceDate).format("DD-MM-YYYY") + ')' : ''}</i></b></small></p>
                                                                    </td>
                                                                    {/* <td> {answer?.modelPrice != '' && answer?.modelPrice != 0 ? '₹ ' + this.numberWithDecimal(answer?.modelPrice) : 'Unavailable'}</td> */}
                                                                    <td>{this.numberWithDecimal(answer?.total_quantity)} {detail?.custom_measurement === '' ? detail?.unit_of_measurement_name : detail?.custom_measurement}</td>
                                                                    {
                                                                        i == list.length - 1 ?
                                                                            <React.Fragment>
                                                                                <td>₹ {this.numberWithDecimal(this.getsellingCosts(answer, 0))}</td>
                                                                                <td>₹ {this.numberWithDecimal(this.getLogisticsCosts(answer, 0))}</td>
                                                                            </React.Fragment>
                                                                            :
                                                                            <React.Fragment>
                                                                                <td>₹ {this.numberWithDecimal(this.getsellingCosts(answer))}</td>
                                                                                <td>₹ {this.numberWithDecimal(this.getLogisticsCosts(answer))}</td>
                                                                            </React.Fragment>
                                                                    }
                                                                    {/* <td>{this.numberWithDecimal(detail?.number_of_farmers)}</td> */}
                                                                    {
                                                                        i == list.length - 1 ?
                                                                            <React.Fragment>
                                                                                <td>₹ {this.numberWithDecimal(this.getProcurementsCosts(answer, 0))}</td>
                                                                                <td className={Math.sign(this.getsellingCosts(answer, 0) - this.getLogisticsCosts(answer, 0) - this.getProcurementsCosts(answer, 0)) == 0 || Math.sign(this.getsellingCosts(answer, 0) - this.getLogisticsCosts(answer, 0) - this.getProcurementsCosts(answer, 0)) == 1 ? 'positive' : 'negative'}>{this.numberWithDecimal(this.getsellingCosts(answer, 0) - this.getLogisticsCosts(answer, 0) - this.getProcurementsCosts(answer, 0))}</td>
                                                                            </React.Fragment>
                                                                            :
                                                                            <React.Fragment>
                                                                                <td>₹ {this.numberWithDecimal(this.getProcurementsCosts(answer))}</td>
                                                                                <td className={Math.sign(this.getsellingCosts(answer) - this.getLogisticsCosts(answer) - this.getProcurementsCosts(answer)) == 0 || Math.sign(this.getsellingCosts(answer) - this.getLogisticsCosts(answer) - this.getProcurementsCosts(answer)) == 1 ? 'positive' : 'negative'}>{this.numberWithDecimal(this.getsellingCosts(answer) - this.getLogisticsCosts(answer) - this.getProcurementsCosts(answer))}</td>
                                                                            </React.Fragment>
                                                                    }
                                                                    <td>
                                                                        <div className="d-flex actio-btn">
                                                                            {
                                                                                answer.status === 1
                                                                                    ? <div className="confirmed">Confirmed</div>
                                                                                    : answer.status === 2
                                                                                        ? <div className="declined">Declined</div>
                                                                                        : <React.Fragment>
                                                                                            <button className="btn btn-confirm" onClick={() => this.action(answer.id, 1)}>Confirm</button>
                                                                                            <button className="btn  btn-decline" onClick={() => this.action(answer.id, 2)}>Decline</button>
                                                                                        </React.Fragment>
                                                                            }
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </React.Fragment>
                                                        )
                                                    })}
                                                </tbody>



                                            </table>

                                        </div>
                                        {
                                            status0Array?.price_offered > mandiprice ?
                                                <div className="note-wrp">Note: Buyer is offering ₹{status0Array?.price_offered - mandiprice} higher than nearest market price'
                                                    {/* , where {status0Array?.price_offered - status0Array?.modelPrice} is the actual difference between Price Offered and Benchmark Market price */}
                                                </div>
                                                : ''
                                        }
                                        <div className="d-flex cost-info totbox justify-between">
                                            <div className="payterms">
                                                <h2 className="m-0 fs-16 mt-0 font-weight-bold">Payment Terms:</h2>

                                                <div className="row">
                                                    <div className="col-6">
                                                        <div className="bgwhite flex fixed-height gr-bg">
                                                            <div className="left-area">
                                                                <p className="mb-0">
                                                                    <small>Expected Quantity per delivery:</small>
                                                                    <b className="yellowtxt">{this.numberWithDecimal(status0Array?.expected_quantity_per_delivery)} <small>{detail?.custom_measurement === '' ? detail?.unit_of_measurement_name : detail?.custom_measurement}</small> </b>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="bgwhite flex fixed-height gr-bg">
                                                            <div className="left-area">
                                                                <p className="mb-0">
                                                                    <small>Frequency of delivery:</small>
                                                                    <b className="greentxt"><small>{status0Array?.frequency_of_delivery}</small></b>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="bgwhite flex fixed-height gr-bg">
                                                            <div className="left-area">
                                                                <p className="mb-0">
                                                                    <small>Duration of Contract:</small>
                                                                    {
                                                                        status0Array?.duration_of_contract != 0 ?
                                                                            <b className="bluetxt">{this.numberWithDecimal(status0Array?.duration_of_contract)} <small>{status0Array?.duration_of_contract_type}</small></b>
                                                                            : ''
                                                                    }
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="bgwhite flex fixed-height gr-bg">
                                                            <div className="left-area">
                                                                <p className="mb-0">
                                                                    <small>Expected Payment<br /> After:</small>
                                                                    {
                                                                        status0Array?.expected_payment_after != 0 ?
                                                                            <b className="redtxt">{this.numberWithDecimal(status0Array?.expected_payment_after)} <small>{status0Array?.expected_payment_after_type}</small></b>
                                                                            : ''
                                                                    }
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="step-two-table protable">

                                                <h2 className="m-0 fs-16 mt-0 font-weight-bold">Procurement Costs:</h2>

                                                <table className="table-bordered rht-frm">
                                                    <tbody>

                                                        <tr>
                                                            <td>Promised Procurement Price:</td>
                                                            <td className="w-20per">₹ {this.numberWithDecimal(detail?.promised_procurement_price)}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Other Procurement Costs (Loading, transportation)</td>
                                                            <td className="w-20per">
                                                                <input
                                                                    label=""
                                                                    type="number"
                                                                    className="form-control"
                                                                    name="otherProcurement"
                                                                    value={otherProcurement}
                                                                    onChange={this.handleInput}
                                                                    onBlur={this.onBlur}

                                                                    required={false}
                                                                />
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <h2 className="m-0 fs-16 mt-0 font-weight-bold">Logistics Costs:</h2>
                                                <table className="table-bordered  rht-frm">

                                                    <tbody>
                                                        <tr>
                                                            <td>Transportation Costs (Per Unit)</td>
                                                            <td className="w-20per">
                                                                <input
                                                                    label=""
                                                                    type="number"
                                                                    className="form-control"
                                                                    name="transportCost"
                                                                    value={transportCost}
                                                                    onChange={this.handleInput}
                                                                    onBlur={this.onBlur}

                                                                    required={false}
                                                                />
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td>Loading/ Unloading Costs (Per Unit)</td>
                                                            <td className="w-20per">
                                                                <input
                                                                    label=""
                                                                    type="number"
                                                                    className="form-control"
                                                                    name="loadUnloadCosts"
                                                                    value={loadUnloadCosts}
                                                                    onChange={this.handleInput}
                                                                    onBlur={this.onBlur}

                                                                    required={false}
                                                                />
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td>Spillage/Wastage Costs (Per Unit)</td>
                                                            <td className="w-20per">
                                                                <input
                                                                    label=""
                                                                    type="number"
                                                                    className="form-control"
                                                                    name="spillageWastageCosts"
                                                                    value={spillageWastageCosts}
                                                                    onChange={this.handleInput}
                                                                    onBlur={this.onBlur}

                                                                    required={false}
                                                                />
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td>Other Taxes and Charges (if applicable) (Per Unit)</td>
                                                            <td className="w-20per">
                                                                <input
                                                                    label=""
                                                                    type="number"
                                                                    className="form-control"
                                                                    name="otherTaxes"
                                                                    value={otherTaxes}
                                                                    onChange={this.handleInput}
                                                                    onBlur={this.onBlur}
                                                                    required={false}
                                                                />
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td>Per unit cost redeemed by the government (only applicable for MSP)</td>
                                                            <td className="w-20per">
                                                                <input
                                                                    label=""
                                                                    type="number"
                                                                    className="form-control"
                                                                    name="govCost"
                                                                    value={govCost}
                                                                    onChange={this.handleInput}
                                                                    onBlur={this.onBlur}

                                                                    required={false}
                                                                />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td><b>Total Costs:</b></td>
                                                            <td className="w-20per"><b className="greenclr">₹ {this.numberWithDecimal(this.getTotalCosts(status0Array))}</b></td>
                                                        </tr>
                                                    </tbody>
                                                </table>

                                            </div>

                                        </div>
                                        <div>&nbsp;</div>

                                    </div>
                                </>
                            ) : null}
                        </>
                    ) : (
                        <>
                            {/* Loader */}
                            <CommonPageLoader />
                        </>
                    )}
                </>
            </div>

        )

    }
}
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getStageTwoList,
            sellRequestDetail,
            stageTwoAction,
            getMarketCentreListRM,
            getMarketCentreList
        },
        dispatch
    );
};
let component = StageTwoClass;
export const StageTwo = connect(
    null,
    mapDispatchToProps
)(component);