import React from "react";
import profilePicture from "assets/images/default-profile-img.png";
import { history } from "service/helpers";

const FIGMemberListGridView = () => {
  const FIGData = [
    {
      name: "Testing01",
      gender: "Male",
      village: "Jaipur",
      mobile: "9878987897",
    },
    {
      name: "Testing02",
      gender: "Female",
      village: "Pune",
      mobile: "7655456557",
    },
    {
      name: "Testing03",
      gender: "Male",
      village: "Mangalore",
      mobile: "8765567809",
    },
    {
      name: "Testing04",
      gender: "Male",
      village: "Karaikal",
      mobile: "6789098767",
    },
    {
      name: "Testing05",
      gender: "Female",
      village: "Idukki",
      mobile: "6778550987",
    },
  ];

  return (
    <div className="row">
      {FIGData?.map((item, index) => {
        return (
          <div
            key={index}
            className={`col-md-4 col-lg-3  ${
              index % 4 === 3 ? "pr-md-0" : ""
            } mb-2 mt-3 px-2 ${index % 4 === 0 ? "pl-md-0" : ""}`}
          >
            <div className="member-card rounded shadow p-4 cursor-pointer w-100">
              <div className="d-flex justify-content-between mb-4">
                <div className="d-flex align-items-center">
                  <div className="mr-2">
                    <img
                      src={profilePicture}
                      alt="member_profile"
                      className="member-profile"
                    />
                  </div>
                  <div className="member-card-font-name">{item?.name}</div>
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <div className="member-card-font-heading mb-3">Gender</div>
                  <div className="member-card-font-content height-50">
                    {item?.gender}
                  </div>
                </div>
                <div className="col-6">
                  <div className="member-card-font-heading mb-3">Village </div>
                  <div className="member-card-font-content height-50">
                    {item?.village}
                  </div>
                </div>
                <div className="col-6">
                  <div className="member-card-font-heading mb-3">Mobile</div>
                  <div className="member-card-font-content">{item?.mobile}</div>
                </div>
              </div>
              <div className="kyc-btns">
                <button onClick={() => history.push("/fig/member-profile")}>
                  View
                </button>
                <button>Edit</button>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default FIGMemberListGridView;
