import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { forumList, yourForumList, getSavedForumById } from "action/forum";
import { forumIdChange } from "action/HomeAct";

import { Card } from "./Card";
import InfiniteScroll from "react-infinite-scroll-component";
export class ForumCardClass extends Component {
  constructor() {
    super();
    this.state = {
      forumList: [],
      page: 1,
      pageMeta: {},
      savedForum: [],
    };
  }

  componentWillMount = () => {
    if (this.props.homeForumId !== "") {
      this.getForumList("homeForumId");
    } else {
      this.getForumList();
    }
  };
  componentWillUnmount = () => {
    this.props.forumIdChange("");
  };
  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      if (this.props.filteredValue === "" || this.props.filteredValue !== "") {
        this.getForumList();
      }
    }
    if (prevProps.reload !== this.props.reload) {
      this.getForumList("reset");
    }
    if (prevProps.forumId !== this.props.forumId) {
      if (this.props.forumId !== "") {
        this.getForumList("forumId");
      }
    }
    if (prevProps.savedId !== this.props.savedId) {
      if (this.props.savedId !== "") {
        this.handleSavedForum(this.props.savedId);
      }
    }
  }
  handleSavedForum = (id) => {
    this.props.getSavedForumById(id).then((data) => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      this.setState(
        {
          savedForum: data.data.length > 0 ? data.data : [],
        },
        () => {
          this.props.getSavedForumList(1);
        }
      );
    });
  };
  getForumList = (action, page) => {
    if (!this.props.myForum) {
      if (action === "reset") {
        let { filteredValue } = this.props;
        this.setState({ forumList: [] });
        this.props
          .forumList({
            categoryId: filteredValue,
            limit: 10,
            page: 1,
          })
          .then(({ success, data: { results, pageMeta } }) => {
            if (!!success) {
              this.setState({
                forumList: results,
                page: pageMeta.nextPage !== null ? pageMeta.nextPage : 1,
                pageMeta,
              });
            }
          })
          .catch(() => {
            this.setState({ isFormLoder: false });
          });
      } else if (action === "forumId") {
        let { filteredValue } = this.props;
        this.setState({ forumList: [] });
        this.props
          .forumList({
            categoryId: filteredValue,
            limit: 10,
            page: 1,
            forumId: this.props.forumId,
          })
          .then(({ success, data: { results, pageMeta } }) => {
            if (!!success) {
              this.setState({
                forumList: results,
                page: pageMeta.nextPage !== null ? pageMeta.nextPage : 1,
                pageMeta,
              });
            }
          })
          .catch(() => {
            this.setState({ isFormLoder: false });
          });
      } else if (action === "homeForumId") {
        let { filteredValue } = this.props;
        this.props
          .forumList({
            categoryId: filteredValue,
            limit: 10,
            page: 1,
            forumId: this.props.homeForumId,
          })
          .then(({ success, data: { results, pageMeta } }) => {
            if (!!success) {
              this.setState({ forumList: [] }, () => {
                this.setState({
                  forumList: results,
                  page: pageMeta.nextPage !== null ? pageMeta.nextPage : 1,
                  pageMeta,
                });
              });
            }
          })
          .catch(() => {
            this.setState({ isFormLoder: false });
          });
      } else if (page) {
        let { filteredValue } = this.props;
        this.props
          .forumList({
            categoryId: filteredValue,
            limit: 10,
            page: page !== "" && page ? page : 1,
            forumId: "",
          })
          .then(({ success, data: { results, pageMeta } }) => {
            if (!!success) {
              this.setState({
                forumList: this.state.forumList.concat(results),
                page: pageMeta.nextPage !== null ? pageMeta.nextPage : 1,
                pageMeta,
              });
            }
          })
          .catch(() => {
            this.setState({ isFormLoder: false });
          });
      } else {
        let { filteredValue } = this.props;
        this.props
          .forumList({
            categoryId: filteredValue,
            limit: 10,
            page: page !== "" && page ? page : 1,
            forumId: "",
          })
          .then(({ success, data: { results, pageMeta } }) => {
            if (!!success) {
              this.setState({
                forumList: results,
                page: pageMeta.nextPage !== null ? pageMeta.nextPage : 1,
                pageMeta,
              });
            }
          })
          .catch(() => {
            this.setState({ isFormLoder: false });
          });
      }
    } else {
      this.props
        .yourForumList()
        .then(({ success, data: { results } }) => {
          if (!!success) {
            this.setState({ forumList: results });
          }
        })
        .catch(() => {
          this.setState({ isFormLoder: false });
        });
    }
  };

  render() {
    let { forumList, pageMeta, savedForum } = this.state;

    return (
      <div>
        {forumList.length !== 0 || savedForum.length !== 0 ? (
          <>
            {savedForum.length > 0 && (
              <Card
                list={savedForum[0]}
                getForumList={this.getForumList}
                reload={this.props.handleReload}
              />
            )}
            <InfiniteScroll
              dataLength={forumList.length}
              next={() =>
                this.getForumList(
                  "",
                  pageMeta.nextPage ? pageMeta.nextPage : ""
                )
              }
              hasMore={pageMeta.nextPage ? true : false}
              loader={
                <div className="d-flex justify-content-center align-items-center py-3">
                  <p className="fs-18 text-black-50 line-height-23 mb-0">
                    {"Loading"}
                  </p>
                  <div className="spinner-border ml-2 spinner-border-sm"></div>
                </div>
              }
            >
              {forumList?.map((list, index) => (
                <div key={index}>
                  <Card
                    list={list}
                    getForumList={this.getForumList}
                    myForum={this.props.myForum}
                    handleReloadSavedForums={this.props.handleReloadSavedForums}
                  />
                </div>
              ))}
            </InfiniteScroll>
          </>
        ) : (
          <p className="py-5 my-5 text-black-50 fs-22 line-height-25 text-center">
            {"No data found"}
          </p>
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    homeForumId: state.home.forum_id,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      forumList,
      yourForumList,
      getSavedForumById,
      forumIdChange,
    },
    dispatch
  );
};

let component = ForumCardClass;

export const ForumCard = connect(
  mapStateToProps,
  mapDispatchToProps
)(component);
