import React, { Component } from "react";
import { Modal, ModalBody } from "reactstrap";

// Redux Connection
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { getFIGById, getMemberListforMapping } from "action/FigCrpAct";

export class FIGViewPopupClass extends Component {
  state = {
    name: "",
    leadName: "",
    emailId: "",
    memberIds: [],
    mobileNumber: "",
    memberMappings: [],
  };

  componentDidUpdate(prevProps) {
    let { isOpen, id } = this.props;
    if (prevProps !== this.props && isOpen && id) {
      this.getFIGdataById();
      // this._getMemberListforMapping(id);
    }
  }

  _getMemberListforMapping = (figid) => {
    let { getMemberListforMappingApi } = this.props;
    getMemberListforMappingApi(figid).then((data) => {
      if (data) {
        let options = [],
          tempArray = [];
        data.map((list) => {
          let { lastName = "", firstName, memberId, isMapped } = list;
          options.push({
            label: `${firstName} ${lastName}`,
            value: memberId,
          });
          if (isMapped) {
            tempArray.push({
              label: `${firstName} ${lastName}`,
              value: memberId,
            });
          }
        });
        if (options.length === data.length) {
          this.setState({
            memberIds: [...tempArray],
          });
        }
      }
    });
  };

  //get FIG data By Id
  getFIGdataById = () => {
    let { id, getFIGByIdApi } = this.props;

    getFIGByIdApi(id).then((data) => {
      if (data) {
        this.setState({
          name: data.name,
          leadName: data.leadName,
          emailId: data.emailId,
          mobileNumber: data.mobileNumber,
          memberMappings:
            data?.memberMappings.length > 0
              ? data.memberMappings.map(
                  ({ memberGeneralInfos: { firstName, lasttName = null } }) => {
                    return `${firstName} ${lasttName ? lasttName : ""}`;
                  }
                )
              : [],
        });
      }
    });
  };

  //close popup
  closepopup = () => {
    this.setState({
      name: "",
      leadName: "",
      memberIds: [],
      emailId: "",
      mobileNumber: "",
    });
    this.props.toggle();
  };

  render() {
    let { className = "modal-dialog-centered add-crp-fig", isOpen = false } =
      this.props;

    let { name, leadName, memberIds, emailId, mobileNumber, memberMappings } =
      this.state;

    return (
      <Modal isOpen={isOpen} className={className} size={"md"}>
        <ModalBody className="p-md-4">
          <div>
            <div className="modal-label mb-5">
              <h5>Members to FIG mapping details</h5>
              <i
                onClick={() => this.closepopup()}
                className="icon-close cursor-pointer"
              />
            </div>
            <div className="row">
              <div className="col-4">
                <p className="text-black font-weight-bold fs-16 pr-2">
                  FIG name
                </p>
              </div>
              <div className="col-8">
                <p className="text-black fw-500 fs-16">{name ? name : "-"}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-4">
                <p className="text-black font-weight-bold fs-16 pr-2">
                  Lead Name
                </p>
              </div>
              <div className="col-8">
                <p className="text-black fw-500 fs-16">
                  {leadName ? leadName : "-"}
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-4">
                <p className="text-black font-weight-bold fs-16 pr-2">Email</p>
              </div>
              <div className="col-8">
                <p className="text-black fw-500 fs-16">{emailId ? emailId : "-"}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-4">
                <p className="text-black font-weight-bold fs-16 pr-2">
                  Mobile number
                </p>
              </div>
              <div className="col-8">
                <p className="text-black fw-500 fs-16">
                  {mobileNumber ? mobileNumber : "-"}
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-4">
                <p className="text-black font-weight-bold fs-16 pr-2">
                  Members mapped
                </p>
              </div>
              <div className="col-8">
                <p className="text-black fw-500 fs-16 mb-1">
                  {memberMappings.length > 0 ? memberMappings.join(", ") : "-"}
                </p>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getFIGByIdApi: getFIGById,
      getMemberListforMappingApi: getMemberListforMapping,
    },
    dispatch
  );
};

export const FIGViewPopup = connect(
  null,
  mapDispatchToProps
)(FIGViewPopupClass);
