import React from "react";
import "./NormalInput.scss";

export const NormalInput = ({
  className = "form-control",
  placeholder = "",
  label = "",
  onChange,
  value = "",
  name,
  disabled = false,
  type = "text",
  isLink = false,
  isFullWidth = false,
  isHalfWidth = false,
  isHalfWidthForMemberProfile = false,
  required = false,
  isRupees = false,
  max = "",
  translate = {},
  labelClassName="fs-16 text-black-32 font-weight-bold line-height-19",
  spaceTop=true,
  readOnly = false
}) => {
  return (
    <div className={`normal-input ${spaceTop ? "" : "mt-0"}`}>
      <form
        autocomplete="off"
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <div className="row d-flex align-items-center">
          <div
            className={`${
              isFullWidth ? "col-md-2" : isHalfWidth ? "col-md-4" : "col-md-3"
            } pr-0 `}
          >
            {label !== "" ? (
              <>
                {!isRupees ? (
                  <div
                    className={`${labelClassName} ${
                      label.length < 10 && "text-nowrap"
                    }`}
                  >
                    {label}
                    {required && (
                      <span className="text-danger font-weight-bold pl-2 fs-18">
                        {"*"}
                      </span>
                    )}
                  </div>
                ) : (
                  <div className="d-flex">
                    <div className={labelClassName}>{label} </div>
                    <div>
                      {isRupees && (
                        <div className="d-flex align-items-center">
                          (<span className="icon-rupee"></span>)
                        </div>
                      )}
                    </div>
                    <div>
                      {required ? (
                        <span className="text-danger font-weight-bold pl-2 fs-18">
                          {"*"}
                        </span>
                      ) : null}
                    </div>
                  </div>
                )}
              </>
            ) : null}
          </div>
          <div
            className={`${
              isFullWidth
                ? "col-md-10"
                : isHalfWidth || isHalfWidthForMemberProfile
                ? "col-md-8"
                : "col-md-9"
            }  `}
          >
            <input
              className={`${
                isLink ? `${className} islink` : className
              } mt-md-0 mt-2`}
              name={name}
              type={type}
              disabled={disabled}
              value={value}
              min={0}
              autoComplete="off"
              placeholder={placeholder}
              maxLength={max}
              readOnly={readOnly}
              onChange={(e) => {
                let body = {};

                body = {
                  target: {
                    name: e.target.name,
                    value: e.target.value,
                  },
                };

                body = {
                  target: {
                    name: e.target.name,
                    value: e.target.value,
                  },
                };

                onChange(body);
              }}
              {...translate}
            />
            {isLink ? (
              <span className="link-area">
                <i className="fs-24 icon-link"></i>
              </span>
            ) : null}
          </div>
        </div>
      </form>
    </div>
  );
};