import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { addHarvest } from "action/HarvestAct";
import { harvestCrops } from "action/HarvestAct";
import { CommonPageLoader } from "component/common";
import { cropForm, seasonOption, unitOption } from "../dataVariables";

const requiredClass = "text-danger font-weight-bold";
const errorClass = "error-message text-danger fs-14 mb-0";
const inputClass = "form-control w-100";
const selectClass = "custom-select form-control";

const NewRecord = (props) => {
  const { addHarvestApi, harvestCropsApi, setActiveTab } = props;
  const [season, setSeason] = useState("");
  const [showError, setShowError] = useState(false);
  const [currentIndex, setCurrentindex] = useState(null);
  const [errortype, setErrortype] = useState("");
  const [cropOptions, setCropOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [cropData, setCropData] = useState([{ ...cropForm }]);

  const handleCropChange = (e, index) => {
    setShowError(false);
    const { name, value } = e.target || e || {};
    const list = [...cropData];
    list[index][name] = value;
    setCropData(list);
  };

  useEffect(() => {
    harvestCropsApi().then((data) => {
      let newData = [];
      data.forEach((list) => {
        newData.push({
          label: list.cropName,
          value: list.cropId,
        });
      });
      setCropOptions(newData);
    });
  }, [harvestCropsApi]);

  const handleAddClick = (type, index) => {
    setCurrentindex(index);
    setErrortype(type);
    let currentData = cropData[index];
    let { cropId, variety, qty, unitOfMeasurement, others, price } =
      currentData;
    let validation =
      cropId === "" ||
      variety === "" ||
      qty === "" ||
      unitOfMeasurement === "" ||
      price === "";
    if (validation) {
      setShowError(true);
      return null;
    } else {
      setShowError(false);
    }
    if (unitOfMeasurement === "others") {
      if (others === "") {
        setShowError(true);
        return null;
      }
    } else {
      setShowError(false);
    }
    if (type === "add") {
      setCropData([
        ...cropData,
        {
          cropId: "",
          variety: "",
          qty: "",
          unitOfMeasurement: "",
          others: "",
          price: "",
          seasonType: season,
        },
      ]);
    }
    if (type === "submit" && !showError) {
      let body = cropData;
      addHarvestApi(body).then(() => resetValues());
    }
  };

  const handleRemoveClick = (index) => {
    const crop = [...cropData];
    crop.splice(index, 1);
    setCropData(crop);
  };

  const resetValues = () => {
    setLoading(true);
    setSeason("");
    setCropData([{ ...cropForm }]);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
    setActiveTab("View past records");
  };

  return (
    <React.Fragment>
      {loading ? (
        <CommonPageLoader />
      ) : (
        <div className="d-flex container-sa my-3">
          <div className="harvest-details w-100">
            <div className="col-md-6 col-6 d-flex flex-column mb-3">
              <label>
                Select Season
                <span className={requiredClass}>*</span>
              </label>
              <select
                className={selectClass}
                name="season"
                value={season}
                onChange={(e) => {
                  setSeason(e.target.value);
                  cropData[0].seasonType = e.target.value;
                }}
              >
                <option value="" disabled defaultValue>
                  Select Season
                </option>
                {seasonOption.map((list, index) => (
                  <option key={index} value={list.value}>
                    {list.label}
                  </option>
                ))}
              </select>
            </div>
            {season !== "" && (
              <div className="d-flex flex-column mb-3 crop-details">
                {cropData.map((item, index) => {
                  return (
                    <React.Fragment key={index}>
                      <div className="d-flex justify-content-between align-items-end w-100 mt-3 col-12">
                        <div className="row justify-content-between align-items-end col-9">
                          <div className="d-flex flex-column">
                            <label>
                              Crop
                              <span className={requiredClass}>*</span>
                            </label>
                            <select
                              className={selectClass}
                              name="cropId"
                              value={item?.cropId}
                              onChange={(e) => handleCropChange(e, index)}
                            >
                              <option value="" disabled defaultValue>
                                Select
                              </option>
                              {cropOptions.map((list, index) => (
                                <option key={index} value={list.value}>
                                  {list.label}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div>
                            <label>
                              Variety
                              <span className={requiredClass}>*</span>
                            </label>
                            <input
                              type="text"
                              className={inputClass}
                              name="variety"
                              value={item?.variety}
                              maxLength={20}
                              onChange={(e) => handleCropChange(e, index)}
                            />
                          </div>
                          <div>
                            <label>
                              Quantity
                              <span className={requiredClass}>*</span>
                            </label>
                            <input
                              type="number"
                              className={inputClass}
                              name="qty"
                              value={item?.qty}
                              maxLength={20}
                              onKeyDown={(evt) =>
                                evt.key === "e" && evt.preventDefault()
                              }
                              onChange={(e) => handleCropChange(e, index)}
                            />
                          </div>
                          <div>
                            <label>
                              Price per unit
                              <span className={requiredClass}>*</span>
                            </label>
                            <input
                              type="number"
                              className={inputClass}
                              name="price"
                              value={item?.price}
                              maxLength={20}
                              onKeyDown={(evt) =>
                                evt.key === "e" && evt.preventDefault()
                              }
                              onChange={(e) => handleCropChange(e, index)}
                            />
                          </div>
                          <div className="d-flex flex-column">
                            <label>
                              Unit
                              <span className={requiredClass}>*</span>
                            </label>
                            <select
                              className={selectClass}
                              name="unitOfMeasurement"
                              value={item?.unitOfMeasurement}
                              onChange={(e) => handleCropChange(e, index)}
                            >
                              <option value="" disabled defaultValue>
                                Select
                              </option>
                              {unitOption.map((list, index) => (
                                <option key={index} value={list.value}>
                                  {list.label}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        {item?.unitOfMeasurement === "Others" && (
                          <div className="col-2">
                            <label>
                              Other Unit
                              <span className={requiredClass}>*</span>
                            </label>
                            <input
                              type="text"
                              className={inputClass}
                              name="others"
                              value={item?.others}
                              maxLength={20}
                              onChange={(e) => handleCropChange(e, index)}
                            />
                          </div>
                        )}
                        <div className="col-1">
                          {cropData.length !== 1 && (
                            <button
                              className="add-button red-bg"
                              onClick={() => {
                                handleRemoveClick(index);
                              }}
                            >
                              Delete
                            </button>
                          )}
                        </div>
                      </div>
                      <div className="col-2 mt-4">
                        {showError &&
                          currentIndex === index &&
                          errortype === "add" && (
                            <p className={errorClass}>
                              Please Fill all the fields
                            </p>
                          )}
                        {cropData.length - 1 === index && (
                          <button
                            className="add-button"
                            onClick={() => {
                              handleAddClick("add", index);
                            }}
                          >
                            Add more Crops
                          </button>
                        )}
                      </div>
                      <div className="d-flex justify-content-end col-12">
                        <div className="d-flex flex-column">
                          {showError &&
                            currentIndex === index &&
                            errortype === "submit" && (
                              <p className={errorClass}>
                                Please Fill all the fields
                              </p>
                            )}
                          {cropData.length - 1 === index && (
                            <button
                              className="add-button"
                              onClick={() => {
                                handleAddClick("submit", index);
                              }}
                            >
                              Submit
                            </button>
                          )}
                        </div>
                      </div>
                    </React.Fragment>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

let mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      addHarvestApi: addHarvest,
      harvestCropsApi: harvestCrops,
    },
    dispatch
  );
};

export default connect(null, mapDispatchToProps)(NewRecord);
