import React, { Component } from "react";

import { Navbar } from "../component/common/index";
import { Footer } from "../component/fpo/home";
import { history } from "service/helpers";
import { BottomNav } from "../component/common/BottomNav";

export class AcadamyLayout extends Component {
  state = {
    headerFixed: false,
  };
  componentDidMount() {
    let {
      location: { pathname },
    } = this.props;
    let authToken = localStorage.getItem("AuthToken");
    if (!authToken) {
      history.push("/");
    }
    let headerFixed = pathname === "/academy/communityForum" ? true : false;
    this.setState({ headerFixed });
  }

  render() {
    let { children } = this.props;
    let { headerFixed } = this.state;

    return (
      <>
        <Navbar headerFixed={headerFixed} />
        {children}
        <Footer />
        {/* <BottomNav location={this.props.location} /> */}
      </>
    );
  }
}
