import React, { Component, Fragment } from "react";
import { Pagination, CommonPageLoader } from "component/common";
import { getCRPList, getCRPListRm } from "action/FigCrpAct";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { MapCRPPopup } from "./MapCRPPopup";
import { CRPViewPopup } from "./MapCRPPopup/CRPViewPopup";
import { AddCRPPopup } from "../AddCRPPopup";
import { DeleteCRPPopup } from "./DeleteCRP";
import CrpCard from "../CRPList/Card";

export class CRPListMappingClass extends Component {
  state = {
    isLoading: false,
    id: null,
    crp: null,
    isOpen: false,
    isPopupOpen: false,
    isDeletePopup: false,
  };

  //handle Change
  handleChange = (val) => {
    let { limit, getCRPListApi, getCRPListRmApi, searchValue, id } = this.props;
    this.setState({ isLoading: true });
    let payload = {
      limit: limit,
      page: val,
      search: searchValue,
    };
    if (id) {
      getCRPListRmApi(id, payload)
        .then((data) => {
          data && this.setState({ isLoading: false });
        })
        .catch(() => this.setState({ isLoading: false }));
    } else {
      getCRPListApi(payload)
        .then((data) => {
          data && this.setState({ isLoading: false });
        })
        .catch(() => this.setState({ isLoading: false }));
    }
  };

  handleListData = () => {
    let { getCRPListApi } = this.props;
    let payload = { limit: 10, page: 1, search: "" };
    getCRPListApi(payload);
  };

  componentDidMount() {
    let { activeTab } = this.props;
    if (activeTab == "2") {
      this.handleChange(1);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.activeTab == 2) {
      const { searchPage } = this.props;
      this.props.activeTab != prevProps.activeTab && this.handleChange(1);
      this.props.searchValue !== prevProps.searchValue && this.handleChange(1);
      this.props.searchPage !== prevProps.searchPage &&
        this.handleChange(searchPage ? searchPage : 1);
    }
  }

  handleEdit = (id) => {
    let { isPopupOpen } = this.state;
    this.setState({
      isPopupOpen: !isPopupOpen,
      id: id ? id : null,
    });
  };

  handleView = (id) => {
    let { isViewPopupOpen } = this.state;
    this.setState({
      isViewPopupOpen: !isViewPopupOpen,
      id: id ? id : null,
    });
  };

  toggleCrp = (id = null) => {
    let { isOpen } = this.state;
    this.setState({
      isOpen: !isOpen,
      crp: id,
    });
  };

  handleDelete = (id) => {
    let { isDeletePopup } = this.state;
    this.setState({
      isDeletePopup: !isDeletePopup,
      crp: id,
    });
  };

  render() {
    let {
      isLoading,
      isPopupOpen,
      id,
      isViewPopupOpen,
      isOpen,
      crp,
      isDeletePopup,
    } = this.state;
    let { editAccess = false, crpMembers = [], view } = this.props;
    let { results = [], pageMeta = {} } = crpMembers || {};
    return (
      <Fragment>
        {!isLoading ? (
          <Fragment>
            {view === "1" ? (
              <div className="scroll-x">
                <div style={{ minWidth: "1100px" }}>
                  <div className="contaienr-sa">
                    <div className="table_head">
                      <div className="row">
                        <div className="col-3">
                          <b>CRP Name</b>
                        </div>
                        <div className="col-2">
                          <b>CRP Email</b>
                        </div>
                        <div className="col-2">
                          <b>CRP Mobile</b>
                        </div>
                        <div className="col-2 d-flex justify-content-center">
                          <b>FIG Mapped</b>
                        </div>
                        <div className="col-3 d-flex justify-content-center">
                          <b>Action</b>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex container-sa flex-column flex-wrap mt-3 px-0 justify-content-center">
                    {results && results.length > 0 ? (
                      results.map((member, index) => {
                        let {
                          name = "",
                          emailId,
                          mobileNumber,
                          crpId,
                          figMappedCount = 0,
                        } = member;
                        return (
                          <div className="mb-3" key={index}>
                            <div className="member-strip shadow rounded p-3 d-flex align-items-center w-100">
                              <div className="row w-100">
                                <div className="col-3 mt-md-0 mt-2">
                                  <div className="d-flex flex-column justify-content-center h-100">
                                    <div className="member-card-font-content mb-1">
                                      {name}
                                    </div>
                                    {/* <div className="member-card-font-heading">
                                     CRP name
                                   </div> */}
                                  </div>
                                </div>
                                <div className="col-2 mt-md-0 mt-2">
                                  <div className="d-flex flex-column justify-content-center h-100">
                                    <div className="member-card-font-content mb-1">
                                      <span style={{ wordWrap: "break-word" }}>
                                        {emailId ? emailId : "-"}
                                      </span>
                                    </div>
                                    {/* <div className="member-card-font-heading">
                                     CRP email
                                   </div> */}
                                  </div>
                                </div>
                                <div className="col-2 mt-md-0 mt-2">
                                  <div className="d-flex flex-column justify-content-center h-100">
                                    <div className="member-card-font-content mb-1">
                                      {mobileNumber ? mobileNumber : "-"}
                                    </div>
                                    {/* <div className="member-card-font-heading">
                                     Mobile number
                                   </div> */}
                                  </div>
                                </div>
                                <div className="col-2 d-flex justify-content-center mt-md-0 mt-2">
                                  <div className="member-card-font-content mb-1">
                                    {figMappedCount}
                                  </div>
                                </div>
                                <div className="col-3 mt-md-0 mt-2">
                                  <div className="d-flex align-items-center justify-content-center h-100">
                                    <div
                                      className="mr-3 cursor-pointer fw-600 fs-16 text-black-50"
                                      onClick={() => this.handleView(crpId)}
                                    >
                                      View
                                    </div>
                                    {editAccess && (
                                      <Fragment>
                                        <div
                                          className="fs-16 line-height-19 mr-3 text-black-50 fw-600 cursor-pointer"
                                          onClick={() => this.toggleCrp(crpId)}
                                        >
                                          Edit
                                        </div>
                                        <div
                                          className="fs-16 line-height-19 mr-3 text-danger fw-600 cursor-pointer"
                                          onClick={() =>
                                            this.handleDelete(crpId)
                                          }
                                        >
                                          Delete
                                        </div>
                                        <div
                                          className="fs-16 fw-600 text-black-50 cursor-pointer"
                                          onClick={() => this.handleEdit(crpId)}
                                        >
                                          Map FIG's
                                        </div>
                                      </Fragment>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <div className="col-12">
                        <p className="py-5 mb-0 text-black-50 fs-22 line-height-25 text-center">
                          No data found
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <div className="row">
                {results && results.length > 0 ? (
                  results.map((member, index) => {
                    return (
                      <div
                        className="col-md-4 col-lg-3 mb-2 mt-3 px-2"
                        key={index}
                      >
                        <CrpCard
                          {...member}
                          isMapping={true}
                          editAccess={editAccess}
                          onEdit={(e) => this.handleEdit(e)}
                          onView={(e) => this.handleView(e)}
                        />
                      </div>
                    );
                  })
                ) : (
                  <div className="col-12">
                    <p className="py-5 mb-0 text-black-50 fs-22 line-height-25 text-center">
                      No data found
                    </p>
                  </div>
                )}
              </div>
            )}
            <div className="my-0 w-100">
              {pageMeta.pageCount !== undefined && pageMeta.pageCount !== 1 && (
                <Pagination
                  handleChange={this.handleChange}
                  pageMeta={pageMeta}
                />
              )}
            </div>
          </Fragment>
        ) : (
          <CommonPageLoader />
        )}
        <MapCRPPopup
          isOpen={isPopupOpen}
          toggle={() => this.handleEdit()}
          id={id}
          fpoId={this.props.id}
        />
        <CRPViewPopup
          isOpen={isViewPopupOpen}
          toggle={() => this.handleView()}
          id={id}
        />
        <DeleteCRPPopup
          isOpen={isDeletePopup}
          toggle={() => this.handleDelete()}
          id={crp}
          handleChange={() => this.handleListData()}
        />
        <AddCRPPopup
          isOpen={isOpen}
          toggle={() => this.toggleCrp()}
          id={crp}
          fpoId={this.props.id}
        />
      </Fragment>
      // <React.Fragment>
      //   {!isLoading ? (
      //     results.length !== 0 ? (
      //       <Fragment>
      //         {view == 1 && (
      //           <div className="contaienr-sa">
      //             <div className="table_head">
      //               <div className="row">
      //                 <div className="col-3">
      //                   <b>CRP name</b>
      //                 </div>
      //                 <div className="col-4">
      //                   <b>CRP email</b>
      //                 </div>
      //                 <div className="col-3">
      //                   <b>Mobile number</b>
      //                 </div>
      //                 <div className="col-2">
      //                   <b>Action</b>
      //                 </div>
      //               </div>
      //             </div>
      //           </div>
      //         )}
      //         <div className="d-flex container-sa flex-column flex-wrap mt-3 px-0 justify-content-center">
      //           <div className="row">
      //             {results.map((member, index) => {
      //               let { name = "", emailId, mobileNumber, crpId } = member;
      //               return (
      //                 <Fragment>
      //                   {view == "1" ? (
      //                     <div className="col-12 mb-3" key={index}>
      //                       <div className="member-strip shadow rounded p-3 d-flex align-items-center w-100">
      //                         <div className="row w-100">
      //                           <div className="col-md-3 col-12 mt-md-0 mt-2">
      //                             <div className="d-flex flex-column justify-content-center h-100">
      //                               <div className="member-card-font-content mb-1">
      //                                 {name}
      //                               </div>
      //                               {/* <div className="member-card-font-heading">
      //                               CRP name
      //                             </div> */}
      //                             </div>
      //                           </div>
      //                           <div className="col-md-4 col-12 mt-md-0 mt-2">
      //                             <div className="d-flex flex-column justify-content-center h-100">
      //                               <div className="member-card-font-content mb-1">
      //                                 {emailId ? emailId : "-"}
      //                               </div>
      //                               {/* <div className="member-card-font-heading">
      //                               CRP email
      //                             </div> */}
      //                             </div>
      //                           </div>
      //                           <div className="col-md-3 col-12 mt-md-0 mt-2">
      //                             <div className="d-flex flex-column justify-content-center h-100">
      //                               <div className="member-card-font-content mb-1">
      //                                 {mobileNumber ? mobileNumber : "-"}
      //                               </div>
      //                               {/* <div className="member-card-font-heading">
      //                               Mobile number
      //                             </div> */}
      //                             </div>
      //                           </div>
      //                           <div className="col-md-2 col-12 mt-md-0 mt-2">
      //                             <div className="d-flex align-items-center justify-content-center h-100">
      //                               <div
      //                                 className="mr-3 cursor-pointer fw-600 fs-16 text-black-50"
      //                                 onClick={() => this.handleView(crpId)}
      //                               >
      //                                 View
      //                               </div>
      //                               {editAccess && (
      //                                 <Fragment>
      //                                   <div
      //                                     className="fs-16 line-height-19 mr-3 text-black-50 fw-600 cursor-pointer"
      //                                     onClick={() => this.toggleCrp(crpId)}
      //                                   >
      //                                     Edit
      //                                   </div>
      //                                   <div
      //                                     className="fs-16 fw-600 text-black-50 cursor-pointer"
      //                                     onClick={() => this.handleEdit(crpId)}
      //                                   >
      //                                     Map FIG's
      //                                   </div>
      //                                 </Fragment>
      //                               )}
      //                             </div>
      //                           </div>
      //                         </div>
      //                       </div>
      //                     </div>
      //                   ) : (
      //                     <div className="col-md-3 col-lg-3 col-6  mb-2 mt-3 px-2">
      //                       <CrpCard
      //                         {...member}
      //                         isMapping={true}
      //                         editAccess={editAccess}
      //                         onEdit={(e) => this.handleEdit(e)}
      //                         onView={(e) => this.handleView(e)}
      //                       />
      //                     </div>
      //                   )}
      //                 </Fragment>
      //               );
      //             })}
      //           </div>
      //           <div className="my-0 w-100">
      //             {pageMeta.pageCount !== undefined &&
      //               pageMeta.pageCount !== 1 && (
      //                 <Pagination
      //                   handleChange={this.handleChange}
      //                   pageMeta={pageMeta}
      //                 />
      //               )}
      //           </div>
      //         </div>
      //       </Fragment>
      //     ) : (
      //       <p className="py-5 mb-0 text-black-50 fs-22 line-height-25 text-center">
      //         No data found
      //       </p>
      //     )
      //   ) : (
      //     <CommonPageLoader />
      //   )}
      //   <MapCRPPopup
      //     isOpen={isPopupOpen}
      //     toggle={() => this.handleEdit()}
      //     id={id}
      //     fpoId={this.props.id}
      //   />
      //   <CRPViewPopup
      //     isOpen={isViewPopupOpen}
      //     toggle={() => this.handleView()}
      //     id={id}
      //   />
      //   <AddCRPPopup
      //     isOpen={isOpen}
      //     toggle={() => this.toggleCrp()}
      //     id={crp}
      //     fpoId={this.props.id}
      //   />
      // </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  crpMembers: state.FigCrp.crpMembers,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getCRPListApi: getCRPList,
      getCRPListRmApi: getCRPListRm,
    },
    dispatch
  );
};

export const CRPListMapping = connect(
  mapStateToProps,
  mapDispatchToProps
)(CRPListMappingClass);
