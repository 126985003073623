import React, { Component } from "react";
import VideoPlayer from "react-video-js-player";
import "./CoursesVideoPlayList.scss";
import placeholder from "../../../assets/images/placeholder.png";
import { getFilePath } from "service/api";
import FileViewer from "react-file-viewer";
export class CoursesVideoPlayList extends Component {
  state = {
    videoSrc: "",
    poster: "",
    videoDuration: "",
  };

  onPlayerReady(player, duration) {
    player.currentTime(duration);

    this.player = player;
  }

  componentWillMount = () => {
    let { coursesData, chapterFileUrl } = this.props;
    let { thumbnailFileUrl } = coursesData;
    let posterImgUrl =
      thumbnailFileUrl === "bannerFileUrl" || thumbnailFileUrl === null
        ? placeholder
        : thumbnailFileUrl;

    this.setState({
      poster: posterImgUrl,
      videoSrc: getFilePath + chapterFileUrl,
    });
  };

  shouldComponentUpdate(nextProps) {
    if (this.props.chapterFileUrl != nextProps.chapterFileUrl) {
      this.setState({ videoSrc: getFilePath + nextProps.chapterFileUrl });
    }
    return true;
  }

  onVideoPlay(duration) {
    let { selectedSectionsData = {}, currentChaptersId } = this.props;
    let data = {
      chapterId: currentChaptersId,
      courseId: selectedSectionsData.courseId,
      sectionId: selectedSectionsData.sectionId,
      userMasterId: localStorage.getItem("userMasterId"),
      currentTimeline: duration + "",
    };
    this.updateOnGoing(data);
  }

  updateOnGoing = (data) => {
    this.props.onGoingUpdateApi(data);
  };

  componentDidMount = () => {
    let { selectedSectionsData = {}, currentChaptersId, fileType } = this.props;
    if (fileType !== "video") {
      let data = {
        chapterId: currentChaptersId,
        courseId: selectedSectionsData.courseId,
        sectionId: selectedSectionsData.sectionId,
        userMasterId: localStorage.getItem("userMasterId"),
        currentTimeline: "0",
      };
      this.props.onGoingUpdateApi(data);
    }
  };

  onVideoEnd() {
    let {
      handelAutoSectionChange,
      chaptersIndex,
      selectedSectionsData,
    } = this.props;

    if (chaptersIndex + 1 === selectedSectionsData.chapters.length) {
      handelAutoSectionChange().then((data) => {
        this.setState({ videoSrc: getFilePath + data.chapterFileName });
        this.player.src({
          type: "video/mp4",
          src: getFilePath + data.chapterFileName,
        });
      });
    }

    if (chaptersIndex + 1 < selectedSectionsData.chapters.length) {
      this.playVideo(
        selectedSectionsData.chapters[chaptersIndex + 1].chapterFileName,
        selectedSectionsData.chapters[chaptersIndex + 1].chapterId,
        chaptersIndex + 1,
        selectedSectionsData.chapters[chaptersIndex + 1]
      );
    }
  }

  playVideo = (chapterFileName, chapterId, chapterIndex, data) => {
    let { updateChapterId, fileType } = this.props;
    this.setState({ videoSrc: getFilePath + chapterFileName });
    if (
      data.fileType === "" ||
      data.fileType === "video" ||
      data.fileType === null
    ) {
      if (fileType === "" || fileType === "video" || fileType === null) {
        this.player.src({
          type: "video/mp4",
          src: getFilePath + chapterFileName,
        });
      }
    } else {
      let { selectedSectionsData = {} } = this.props;
      let dataUpdateOnGoing = {
        chapterId: chapterId,
        courseId: selectedSectionsData.courseId,
        sectionId: selectedSectionsData.sectionId,
        userMasterId: localStorage.getItem("userMasterId"),
        currentTimeline: "0",
      };
      this.updateOnGoing(dataUpdateOnGoing);
    }
    updateChapterId(chapterId, chapterIndex, data.fileType);
  };

  render() {
    let {
      selectedSectionsData = {},
      currentChaptersId,
      fileType,
      videoPlayerRest,
      chapterFileUrl,
    } = this.props;
    let { chapters = [], title = "" } = selectedSectionsData;
    return (
      <section className="courses-video-play bg-blue-f9 py-2">
        <div className="container-sa">
          <div className="row">
            <div className="col-sm-8">
              <div className="video-player">
                {fileType === "video" ||
                fileType === "" ||
                fileType === null ? (
                  videoPlayerRest ? (
                    <div>
                      <VideoPlayer
                        controls={true}
                        src={this.state.videoSrc}
                        poster={this.state.poster}
                        onReady={this.onPlayerReady.bind(this)}
                        onPlay={this.onVideoPlay.bind(this)}
                        onEnd={this.onVideoEnd.bind(this)}
                      />
                    </div>
                  ) : null
                ) : null}
                {fileType === "odp" ? (
                  videoPlayerRest ? (
                    <div className="odp-file-view">
                      <iframe
                        src={`http://doodlebluelive.com:2189/api/v1/documents/file/stream/docs/Academyvendor-v19849.odp`}
                      ></iframe>
                    </div>
                  ) : null
                ) : null}
                {fileType === "pdf" ? (
                  videoPlayerRest ? (
                    <div className="pdf-view-wrapper">
                      <FileViewer
                        fileType={"pdf"}
                        filePath={
                          "http://doodlebluelive.com:2189/api/v1/documents/file/stream/docs/" +
                          chapterFileUrl
                        }
                      />
                    </div>
                  ) : null
                ) : null}
              </div>
            </div>
            <div className="col-sm-4">
              <div className="courses-video-list">
                <h4> {title} </h4>
                {chapters.map((data, index) => {
                  let { title, chapterFileName, chapterId } = data;
                  return (
                    <div
                      key={index + "chapter"}
                      className={`list-of-videos ${
                        chapterId === currentChaptersId ? "active" : ""
                      }`}
                      onClick={() =>
                        this.playVideo(chapterFileName, chapterId, index, data)
                      }
                    >
                      <div className="video-title">{title}</div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
