import React, { Component } from "react";
import { Modal, ModalBody } from "reactstrap";
import CheckBox from "component/common/CheckBox";
import SimpleReactValidator from "simple-react-validator";

import "./style.scss";

export class BMSPopup extends Component {
  //validation
  validator = new SimpleReactValidator({
    element: (message) => (
      <span className="error-message text-danger fs-14">{message}</span>
    ),
    autoForceUpdate: this,
  });

  onAgree = () => {
    let { onSubmit } = this.props;
    if (this.validator.allValid()) {
      onSubmit();
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  render() {
    let { isOpen, toggle, onChange, bms } = this.props;

    return (
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        centered={true}
        className="bms-modal"
      >
        <ModalBody>
          <div className="bms-popup">
            <i className="fas fa-times" onClick={toggle}></i>
            <div className="mb-3">
              <span className="fs-18">
                Please click on agree to use Business Management Software
              </span>
            </div>
            <div>
              <div className="d-flex flex-column mt-3">
                <CheckBox
                  label="Terms & Conditions"
                  onChange={onChange}
                  name="terms"
                  value={true}
                  isChecked={bms !== "" ? true : false}
                />
                {this.validator.message("terms", bms, "required")}
              </div>
            </div>
            <div className="mt-2">
              <span className="fs-18">
                <b>Note :</b> Business Management Software is free for the first
                year
              </span>
              <br />
              <span className="fs-18">
                <b>Contact us :</b> bms@samunnati.com | 7338881789
              </span>
            </div>
            <div className="confirm-btn mt-3">
              <button onClick={this.onAgree}>Agree</button>
              <button onClick={toggle}>Cancel</button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    );
  }
}
