import React, { Component } from "react";
import "./streaks.scss";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { getDescriptionData } from "service/helperFunctions";
import { history } from "service/helpers";
import { forumIdChange } from "action/HomeAct";

export class StreaksClass extends Component {
  handleRedirect = (id) => {
    this.props.forumIdChange(id);
    history.push("/academy/communityForum");
  };
  render() {
    let { popularTopics = [] } = this.props;
    return (
      <div className="popular-topics-home">
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <h5 className="card-title mb-0">Popular topics</h5>
          </div>
          <div>
            <p
              className="text-black font-weight-bold cursor-pointer mb-0"
              onClick={() => history.push("/academy/communityForum")}
            >
              {"View all"}
            </p>
          </div>
        </div>
        <div className="popular-list">
          {popularTopics.map((list, index) => {
            let { commentsCount = 0, title = "", forumId = 11 } = list;
            return (
              <div
                className={`list-group-item border-bottom-forum`}
                key={index}
              >
                <div
                  className="row cursor-pointer"
                  onClick={() => {
                    this.handleRedirect(forumId);
                  }}
                >
                  <div className="col-9">
                    <div className="d-flex">
                      <div>
                        <span className="bullet-point float-left"></span>
                      </div>
                      <div>
                        <p className="fs-16 line-height-24 text-black-50 mb-1">
                          {getDescriptionData(title, 50)}{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-3">
                    <span className="float-right last-text mt-1">
                      <i className="icon-leaf fs-16 pr-2"></i>
                      {commentsCount}
                    </span>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      forumIdChange,
    },
    dispatch
  );
};
let component = StreaksClass;

export const Streaks = connect(null, mapDispatchToProps)(component);
