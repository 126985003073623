import React, { Component } from "react";
import { history } from "service/helpers";
import { connect } from "react-redux";
import {
  membership,
  fpo_docs,
  engage_prof,
  grade_score,
  compliance,
  events_impact,
  arrow,
} from "./icons";
import { getAccessdata } from "service/helperFunctions";
import "./fpo.scss";
const fpo = [
  {
    icon: membership,
    name: "Member Engagement",
    link: "/fpo/member",
    serviceName: "membership",
  },
  {
    icon: fpo_docs,
    name: "FPO Documents",
    link: "/fpo/documents",
    serviceName: "fpoDocuments",
  },
  {
    icon: grade_score,
    name: "Grading Profile",
    link: "/fpo/grading-score",
    serviceName: "gradeProfile",
  },
  {
    icon: compliance,
    name: "Compliance & Governance",
    link: "/fpo/compliance",
    serviceName: "complainceGovernance",
  },
  {
    icon: events_impact,
    name: "Events & Impacts Stories",
    link: "/fpo/events",
    serviceName: "eventsImpacts",
  },
  {
    icon: events_impact,
    name: "Key Financials",
    link: "/fpo/key-financial",
    serviceName: "keyFinancials",
  },
  {
    icon: events_impact,
    name: "Associations & Partnerships",
    link: "/fpo/association",
    serviceName: "associationPartnerships",
  },
  // {
  //   icon: events_impact,
  //   name: "Role Management",
  //   link: "/fpo/rm-dashboard",
  //   serviceName: "rmDashboard",
  // },
];
export class FpoClass extends Component {
  render() {
    let { accessData } = this.props;
    return (
      <div className="shadow rounded p-4 w-50 fpo-move-up mb-4 bg-white w-100 height-450">
        <div className="mb-3">
          <h3 className="fpo-card-header primary-color-dark">
            {"FPO Management"}
          </h3>
        </div>
        {fpo.map((comp, index) => {
          // let permissionData = getAccessdata(accessData, comp?.serviceName);
          // let { viewAccess = false } = permissionData;
          // console.log("viewAccess", permissionData?.viewAccess);
          return (
            // permissionData?.viewAccess && (
            <div key={index}>
              <div className="d-flex d-flex align-items-center w-100 justify-content-between p-2 hover-primary-dark">
                <div className="mr-2">
                  <img src={comp?.icon} alt="icon.svg" />
                </div>
                <div
                  className="w-75 d-flex align-items-center fpo-card-body"
                  onClick={() => history.push(`${comp?.link}`)}
                >
                  {comp?.name}
                </div>
                <div
                  className="d-flex align-items-center cursor-pointer"
                  onClick={() => history.push(`${comp?.link}`)}
                >
                  <img src={arrow} alt="membership.svg" />
                </div>
              </div>
              {index !== fpo.length - 1 && <div className="dropdown-divider" />}
            </div>
            // )
          );
        })}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  accessData: state.home.accessData,
});

let component = FpoClass;

export const Fpo = connect(mapStateToProps, null)(component);
