import React, { Component } from "react";
import "./searchlist.scss";
import long_arrow from "assets/svg/price_details/long_arrow.svg";
import { letterAvatar, getDescriptionData } from "service/helperFunctions";
import { history } from "service/helpers";
const User_Profile_1 = require("assets/svg/Members/User_Profile_1.svg");

export default class SearchCard extends Component {
  render() {
    const {
      profile,
      ComapanyName,
      location,
      crops,
      fpoId,
      isLoginedInUser,
    } = this.props.payload;
    return (
      <div className="search-card rounded shadow py-4 w-100">
        <div className="row mx-auto align-items-center mb-3">
          <div className="col-4">
            {profile ? (
              <img
                src={profile}
                alt="profile"
                className="rounded-circle search-card-profile"
              />
            ) : (
              <img
                src={letterAvatar(ComapanyName, 55)}
                alt="profile"
                className="rounded-circle"
              />
            )}
          </div>
          <div className="col-8 p-0 fs-18 fw-600">{ComapanyName}</div>
        </div>
        <div className="row mx-auto flex-colum mb-3">
          <div className="col-12">
            <div className="mb-2">Location</div>
            <div className="fw-600">{location}</div>
          </div>
        </div>
        <div className="row mx-auto flex-column mb-3 height-100">
          <div className="col-12">
            <div className="mb-2">Crops</div>
            <div className="fw-600">
              <p className="mb-1">
                {crops === "" ? "-" : getDescriptionData(crops, 70)}
              </p>
            </div>
          </div>
        </div>
        <div
          className="row mb-3 mx-auto mt-2 cursor-pointer"
          onClick={() => {
            localStorage.getItem("userGroupName") === "fpo" && isLoginedInUser
              ? history.push("/fpo/about-us")
              : localStorage.getItem("userGroupName") === "fpo" &&
                !isLoginedInUser
              ? history.push(`/fpo/about-us/search/${fpoId}`)
              : localStorage.getItem("userGroupName") === "samunnati"
              ? history.push(`/rm/about-us/${fpoId}`)
              : window.open(profile ? profile : User_Profile_1, "_blank");
          }}
        >
          <div className="text-green font-weight-bold fs-16 col-4 ">
            {"Access"}
          </div>
          <div className="col-4">
            <img src={long_arrow} alt="arrow.svg" />
          </div>
        </div>
      </div>
    );
  }
}
