import React, { Component } from "react";
import "./success_story.scss";
import { getDescriptionData } from "service/helperFunctions";
import { history } from "service/helpers";
export class Card extends Component {
  render() {
    let { story = {} } = this.props;

    let {
      img = "",
      title = "",
      content = "",
      date = "",
      successId = "",
    } = story;

    return (
      <div className="d-flex flex-column align-items-center">
        <div className="">
          <img className="card-success-profile" src={img} alt="story.svg" />
        </div>
        <div className="card-success-content">
          <div className="mx-auto py-3 px-4 bg-white card-success w-75">
            <div className="fs-18 fw-600 mb-2 text-green">
              {getDescriptionData(title, 50)}
            </div>
            <div className="fs-14 fw-600 mb-3 text-black-50">{date}</div>
            <div
              className="mb-3 fs-14 gray-4f card-success-story"
              dangerouslySetInnerHTML={{
                __html: getDescriptionData(content, 100),
              }}
            ></div>
            <div className="row card-success-footer w-100">
              <div
                className="col-7 fs-14 fw-600 text-green-18 align-self-center cursor-pointer"
                onClick={() => {
                  localStorage.getItem("AuthToken")
                    ? history.push(
                        localStorage.getItem("userGroupName") !== "samunnati" &&
                          localStorage.getItem("userGroupName") !== "fpo"
                          ? `/other/viewDetails/content/successStories&${successId}`
                          : localStorage.getItem("userRoleName") ===
                            "Relationship Manager"
                          ? `/rm/viewDetails/content/successStories&${successId}`
                          : `/fpo/viewDetails/successStories&${successId}`
                      )
                    : history.push(
                        `/landing/viewDetails/content/successStories&${successId}`
                      );
                }}
              >
                Read more
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
