import React from "react";
import RMInput from "component/RM/Common/RMInput";

const LandDetails = (props) => {
  const { memberData, handleMemberChange, validator, fieldRef } = props;

  return (
    <div>
      <h3 className="fs-20 fw-600 my-4">Land Details</h3>
      <RMInput
        label="12. Total Land (Acre)"
        name="total_land"
        value={memberData.total_land}
        max={20}
        onChange={(e) => handleMemberChange(e)}
        allowOnlyNumber={true}
        allowDecimal={true}
        disabled={true}
        isRequired={true}
        ref={fieldRef}
      />
      {validator.current.message(
        "total_land",
        memberData.total_land,
        "required"
      )}
      <RMInput
        label="a. Own Land - Irrigated (Acre)"
        name="own_irrigated"
        value={memberData.own_irrigated}
        max={20}
        onChange={(e) => handleMemberChange(e)}
        allowOnlyNumber={true}
        allowDecimal={true}
        isRequired={true}
        ref={fieldRef}
      />
      {validator.current.message(
        "own_irrigated",
        memberData.own_irrigated,
        "required"
      )}
      <RMInput
        label="b. Own Land - Rainfed (Acre)"
        name="own_rainfed"
        value={memberData.own_rainfed}
        max={20}
        onChange={(e) => handleMemberChange(e)}
        allowOnlyNumber={true}
        allowDecimal={true}
        isRequired={true}
        ref={fieldRef}
      />
      {validator.current.message(
        "own_rainfed",
        memberData.own_rainfed,
        "required"
      )}
      <RMInput
        label="c. Lease Land - Irrigated (Acre)"
        name="lease_irrigated"
        value={memberData.lease_irrigated}
        max={20}
        onChange={(e) => handleMemberChange(e)}
        allowOnlyNumber={true}
        allowDecimal={true}
        isRequired={true}
        ref={fieldRef}
      />
      {validator.current.message(
        "lease_irrigated",
        memberData.lease_irrigated,
        "required"
      )}
      <RMInput
        label="d. Lease Land - Rainfed (Acre)"
        name="lease_rainfed"
        value={memberData.lease_rainfed}
        max={20}
        onChange={(e) => handleMemberChange(e)}
        allowOnlyNumber={true}
        allowDecimal={true}
        isRequired={true}
        ref={fieldRef}
      />
      {validator.current.message(
        "lease_rainfed",
        memberData.lease_rainfed,
        "required"
      )}
    </div>
  );
};

export default LandDetails;
