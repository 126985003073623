import React, {
  Fragment,
  useState,
  useEffect,
  useCallback,
  useRef,
} from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Modal, ModalBody } from "reactstrap";
import SimpleReactValidator from "simple-react-validator";
import { createKymUser, deleteKymUser, getKYMFPOList } from "action/KYMAct";
import { CommonPageLoader } from "../CommonPageLoader";
import "./style.scss";

const kymListHeader = [
  { title: "S.No" },
  { title: "Name" },
  { title: "Mobile" },
  { title: "Delete" },
];

const formDefaultValue = {
  name: "",
  mobile: "",
};

const KYMPopup = (props) => {
  const {
    isOpen,
    toggle,
    createKymUserApi,
    deleteKymUserApi,
    getKYMFPOListApi,
  } = props;
  const [, forceUpdate] = useState();
  const [isForm, setIsForm] = useState(true);
  const [isKYMList, setIsKYMList] = useState(false);
  const [kymList, setKYMList] = useState([]);
  const [deletePopup, setDeletePopup] = useState(false);
  const [deleteID, setDeleetID] = useState(null);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState(formDefaultValue);

  const validator = useRef(
    new SimpleReactValidator({
      element: (message) => (
        <span className="error-message text-danger fs-14">{message}</span>
      ),
      autoForceUpdate: this,
    })
  );

  const onChange = (e) => {
    const { name, value } = e.target || e || {};
    setFormData({ ...formData, [name]: value });
  };

  const allowOnlynumber = (event) => {
    var regex = new RegExp("^[0-9]+$");
    var key = String.fromCharCode(
      !event.charCode ? event.which : event.charCode
    );
    if (!regex.test(key)) {
      event.preventDefault();
      return false;
    }
  };

  const onSubmit = () => {
    if (validator.current.allValid()) {
      const { name, mobile } = formData;
      let body = {
        name: name,
        mobileNumber: mobile,
      };
      createKymUserApi(body).then(() => setIsForm(false));
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };

  const getKYMList = useCallback(() => {
    setLoading(true);
    getKYMFPOListApi()
      .then((data) => {
        setKYMList(data);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  }, [getKYMFPOListApi]);

  useEffect(() => {
    if (isKYMList) getKYMList();
  }, [getKYMList, isKYMList]);

  const handleDeleteKYM = () => setDeletePopup(!deletePopup);

  const deleteKYM = () =>
    deleteKymUserApi(deleteID).then(() => {
      getKYMList();
      setDeletePopup(!deletePopup);
    });

  const closePopup = () => {
    setIsKYMList(false);
    setIsForm(true);
    setFormData(formDefaultValue);
    toggle();
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={closePopup}
      centered={true}
      className="kym-modal"
    >
      <ModalBody>
        <div className="kym-popup">
          <i className="fas fa-times" onClick={closePopup}></i>
          <div className="confirm-btn d-flex justify-content-end mt-1">
            <button onClick={() => setIsKYMList(!isKYMList)}>
              {isKYMList ? "Back" : "KYM user list"}
            </button>
          </div>
          {isKYMList ? (
            !loading ? (
              kymList.length > 0 ? (
                <table>
                  <thead>
                    <tr>
                      {kymListHeader.map(({ title }, index) => (
                        <th key={index}>{title}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {kymList.length > 0 &&
                      kymList.map(
                        (
                          { kymUsers: { name, mobileNumber, userId } },
                          index
                        ) => {
                          return (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{name}</td>
                              <td>{mobileNumber}</td>
                              <td
                                className="fw-600 text-danger cursor-pointer"
                                onClick={() => {
                                  setDeleetID(userId);
                                  handleDeleteKYM();
                                }}
                              >
                                Delete
                              </td>
                            </tr>
                          );
                        }
                      )}
                  </tbody>
                </table>
              ) : (
                <p className="py-5 my-5 text-black-50 fs-22 line-height-25 text-center">
                  No data found
                </p>
              )
            ) : (
              <CommonPageLoader />
            )
          ) : (
            <Fragment>
              {isForm ? (
                <div>
                  <div className="col-12">
                    <label>
                      KYM user Name
                      <span className="text-danger font-weight-bold">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control w-100"
                      name="name"
                      value={formData.name}
                      onChange={onChange}
                      maxLength={100}
                    />
                    {validator.current.message(
                      "name",
                      formData.name,
                      "required|min:3|max:150"
                    )}
                  </div>
                  <div className="col-12">
                    <label>
                      KYM user Mobile
                      <span className="text-danger font-weight-bold">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control w-100"
                      name="mobile"
                      value={formData.mobile}
                      onChange={onChange}
                      onKeyPress={allowOnlynumber}
                      maxLength={10}
                    />
                    {validator.current.message(
                      "mobile",
                      formData.mobile,
                      "required|min:10|max:10"
                    )}
                  </div>
                </div>
              ) : (
                <div className="fs-20 fw-500 m-3">
                  Thanks for creating KYM user. They will be able to login with
                  Mobile/OTP.
                </div>
              )}
              <div className="confirm-btn mt-4">
                <button
                  onClick={() => {
                    if (isForm) {
                      onSubmit();
                    } else {
                      setFormData(formDefaultValue);
                      setIsForm(true);
                    }
                  }}
                >
                  {isForm ? "Submit" : "Add another KYM User"}
                </button>
                <button onClick={closePopup}>
                  {isForm ? "Cancel" : "Close"}{" "}
                </button>
              </div>
            </Fragment>
          )}
          <Modal isOpen={deletePopup} toggle={handleDeleteKYM} centered={true}>
            <ModalBody>
              <div className="confirm-popup">
                <i className="fas fa-times" onClick={handleDeleteKYM}></i>
                <div>
                  <b>Are you sure you want to delete?</b>
                </div>
                <div className="confirm-btn">
                  <button onClick={deleteKYM}>Agree</button>
                  <button onClick={handleDeleteKYM}>Cancel</button>
                </div>
              </div>
            </ModalBody>
          </Modal>
        </div>
      </ModalBody>
    </Modal>
  );
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      createKymUserApi: createKymUser,
      deleteKymUserApi: deleteKymUser,
      getKYMFPOListApi: getKYMFPOList,
    },
    dispatch
  );
};

export default connect(null, mapDispatchToProps)(KYMPopup);
