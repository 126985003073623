import React from "react";

import "./style.scss";

const BankDetailCard = ({
  bankName,
  beneficiaryBankAccountNumber,
  bankAccountType,
  IFSCCode,
  beneficiaryName,
  others,
}) => {
  return (
    <div className="bank_detail_card">
      <div className="bank_detail">
        <span>Beneficiary Bank Account Number</span>
        <br />
        <b>
          {beneficiaryBankAccountNumber ? beneficiaryBankAccountNumber : "-"}
        </b>
      </div>
      <div className="bank_detail">
        <span>Bank Name</span>
        <br />
        <b>{bankName ? bankName : "-"}</b>
      </div>
      <div className="bank_detail">
        <span>Bank Account Type</span>
        <br />
        <b>
          {bankAccountType
            ? bankAccountType == "Others"
              ? others
              : bankAccountType
            : "-"}
        </b>
      </div>
      <div className="bank_detail">
        <span>IFSC Code</span>
        <br />
        <b>{IFSCCode ? IFSCCode : "-"}</b>
      </div>
      <div className="bank_detail">
        <span>Beneficiary Name</span>
        <br />
        <b>{beneficiaryName ? beneficiaryName : "-"}</b>
      </div>
    </div>
  );
};

export default BankDetailCard;
