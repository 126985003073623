import React, { useState } from "react";

const arrow_left = require("../../../../assets/svg/Documents/arrow-left.svg");
const arrow_right = require("../../../../assets/svg/Documents/arrow-right.svg");

const ImageViewer = ({ images }) => {
  let [activeIndex, setActiveIndex] = useState(0);

  const next = () => {
    if (activeIndex === images.length - 1) {
      setActiveIndex(0);
    } else {
      setActiveIndex(activeIndex + 1);
    }
  };

  const previous = () => {
    if (activeIndex === 0) {
      setActiveIndex(images.length - 1);
    } else {
      setActiveIndex(activeIndex - 1);
    }
  };

  if (images.length === 0) return null;

  return (
    <div>
      <h4>Images</h4>
      <div className="file-viewer align-items-center">
        <span>{images[activeIndex]?.fileName}</span>
        <div className="pdf-file">
          <img
            src={images[activeIndex]?.documentUrl}
            className="pdf-icon"
            alt="eventImages"
          />
          {images.length > 1 && (
            <div className="file-arrow">
              <div>
                <img
                  src={arrow_left}
                  onClick={() => previous()}
                  alt="prevIcon"
                />
              </div>
              <div>
                <img src={arrow_right} onClick={() => next()} alt="nextIcon" />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ImageViewer;
