import React, { Component } from "react";
import { NormalButton, Pagination, CommonPageLoader } from "component/common";
import filter from "assets/images/icon/filter.svg";
import download from "assets/images/icon/download-icon.svg";
import { history } from "service/helpers";
import { debounceFunction } from "service/debounce";
import { getDescriptionData } from "service/helperFunctions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  getAllAssociations,
  getAssociationCsv,
  getAllInstitutiontypes,
} from "action/AssociationAct";
import {
  getAssociationList,
  downlaodAssociationandPartnersOfFPO,
} from "action/RmAct";
import moment from "moment";
import "./associationsview.scss";

class AssociationsViewClass extends Component {
  state = {
    associations: [],
    searchValue: "",
    limit: 5,
    pageMeta: {},
    isLoading: false,
    active: false,
    dropdownList: [],
    filterType: "",
    filterId: "",
    filteredName: "",
  };

  handleInput = ({ target: { name, value } }) => {
    //Debounce Function
    this.setState({ searchValue: value });
    debounceFunction(() => this.SearchFunction(value), 1000);
  };

  //handle pagination
  handlePagination = (page) => {
    this.handleAssociation(page);
  };

  SearchFunction = (searchValue) => {
    //debounce function
    const { limit, page, filterId, filterType } = this.state;
    let payload = {
      limit,
      page,
      search: searchValue,
      filterBy: filterType,
      filterValue: filterId,
    };
    this.props.getAllAssociations(payload).then((data) => {
      const { results, pageMeta } = data;
      this.setState({
        associations: results,
        pageMeta: pageMeta,
      });
    });
  };

  //handle association
  handleAssociation = (page) => {
    this.setState({ isLoading: true });
    let { limit, searchValue, filterId, filterType } = this.state;
    let { id } = this.props;
    let payload = {
      limit,
      page,
      search: searchValue,
      filterBy: filterType,
      filterValue: filterId,
    };
    if (id) {
      this.props.getAssociationList(payload, id).then((data) => {
        const { results, pageMeta } = data;
        this.setState({
          associations: results,
          pageMeta: pageMeta,
          isLoading: false,
        });
      });
    } else {
      this.props.getAllAssociations(payload).then((data) => {
        const { results, pageMeta } = data;
        this.setState({
          associations: results,
          pageMeta: pageMeta,
          isLoading: false,
        });
      });
    }
  };

  //open dropdown
  handleDropdown = () => {
    if (!this.state.active) {
      document.addEventListener("click", this.handleOutsideClick, false);
    } else {
      document.removeEventListener("click", this.handleOutsideClick, false);
    }
    this.setState((prevState) => ({
      active: !prevState.active,
    }));
  };

  //close popup when clicked outside
  handleOutsideClick = (e) => {
    if (this.node != null) {
      if (this.node.contains(e.target)) {
        return;
      }
    }
    this.handleDropdown();
  };

  //Lifecycles
  componentDidMount() {
    this.handleAssociation(1);
    this.props.getAllInstitutiontypes().then((data) => {
      this.setState({
        dropdownList: data,
      });
    });
  }

  //download csv
  downloadCsv = () => {
    let { id } = this.props;
    if (id) {
      this.props.downlaodAssociationandPartnersOfFPO(id).then((data) => {
        this.downloadFunction(data, "association and partners");
      });
    } else {
      this.props.getAssociationCsv().then((data) => {
        this.downloadFunction(data, "association and partners");
      });
    }
  };

  //download function
  downloadFunction(data, fileName) {
    var a = window.document.createElement("a");
    var blob = new Blob([data], {
      type: "text/csv;charset=utf-8;",
    });
    if (navigator.msSaveOrOpenBlob) {
      navigator.msSaveOrOpenBlob(
        blob,
        `${fileName} (${moment().format("DD/MM/YYYY hh:mm")}).csv`
      );
    } else {
      a.href = window.URL.createObjectURL(blob);
      // supply your own fileName here...
      a.download = `${fileName} (${moment().format("DD/MM/YYYY hh:mm")}).csv`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  }

  //handle filter submit
  handleSubmit = (filterType, filterId, filteredName) => {
    this.setState({ filteredName, filterType, filterId }, () => {
      this.handleAssociation(1);
    });
  };

  //handle remove filter
  handleRemoveFilter = () => {
    this.setState({ filteredName: "", filterType: "", filterId: "" }, () => {
      this.handleAssociation(1);
    });
  };

  render() {
    const {
      associations,
      pageMeta,
      isLoading,
      filteredName,
      active,
      dropdownList,
    } = this.state;
    let {
      downloadAccess = false,
      edit = false,
      createAccess = false,
    } = this.props;

    let associationsList = associations.slice(0, 3);

    let contentList = associationsList
      .reduce((acc, item) => {
        return `${acc} , ${
          item.institutionmaster && item.institutionmaster.institutionType
        }`;
      }, "")
      .slice(2);
    return (
      <>
        <section className="member-section bg-blue-f9">
          <div className="container-sa">
            <div className="filter-top mb-3">
              <div className="compilance-filters my-4 container-sa">
                <div className="row align-items-end">
                  <div className="col-12">
                    <ul className="right-filter-align">
                      <div className="fs-30 fw-600 mr-auto">
                        {"Associations & Partnerships"}
                      </div>
                      {createAccess && (
                        <div className=" pl-5">
                          <NormalButton
                            label="Add"
                            outline={true}
                            onClick={() => history.push("/fpo/add-association")}
                          />
                        </div>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-white">
              <div className="container-sa pb-4 pt-1">
                <div className="compilance-filters mt-4 mb-5">
                  <div className="row align-items-center">
                    <div className="col-sm-9 px-md-5">
                      <ul className="right-filter-align menu">
                        <li
                          className="cursor-pointer filter-dropdown"
                          onClick={this.handleDropdown}
                        >
                          <img src={filter} alt="filter" />
                          <span className="fs-16 font-Gilroy ml-2">
                            {"Filter"}
                          </span>
                          <div className={`dropdown ${active ? "active" : ""}`}>
                            <ul className="h-100 w-100 d-flex flex-column flex-wrap">
                              <p>Institution type</p>
                              {filteredName !== "" ? (
                                <li
                                  className="cursor-pointer text-left"
                                  onClick={() => this.handleRemoveFilter()}
                                >
                                  None
                                </li>
                              ) : null}
                              {dropdownList.map((list, index) =>
                                filteredName === list.institutionType ? (
                                  <li
                                    className="cursor-pointer active text-left"
                                    key={index}
                                  >
                                    {list.institutionType}
                                  </li>
                                ) : (
                                  <li
                                    className="cursor-pointer text-left"
                                    key={index}
                                    onClick={() =>
                                      this.handleSubmit(
                                        "associationTypeId",
                                        list.institutionTypeId,
                                        list.institutionType
                                      )
                                    }
                                  >
                                    {list.institutionType}
                                  </li>
                                )
                              )}
                            </ul>
                          </div>
                        </li>
                        {downloadAccess && (
                          <li className="pl-3">
                            <p
                              id="csv-download"
                              className="text-decoration-none cursor-pointer text-green-18 mb-0"
                              onClick={() => this.downloadCsv()}
                            >
                              <img
                                src={download}
                                alt="download"
                                className="mr-2"
                              />
                              {"Download list"}
                            </p>
                          </li>
                        )}
                      </ul>
                    </div>
                    <div className="col-sm-3">
                      <input
                        type-="text"
                        className="form-control form-control-sa"
                        placeholder="Search"
                        onChange={(e) => this.handleInput(e)}
                      />
                    </div>
                  </div>
                </div>
                {!isLoading && associations.length === 0 ? (
                  <p className="py-4 mb-0 text-black-50 fs-22 line-height-25 text-center">
                    {"No data found"}
                  </p>
                ) : !isLoading ? (
                  <>
                    <section className="row accociation graph-list-card">
                      {/* <div className="col-4 association-card fs-24 text-black-32 px-4 text-left mb-3 h-100 line-height-31">
                        {`We maintain deep collaborations with several eco-system stakeholders ensuring the overall development of our member farmers and inturn our organizations. ${
                          contentList !== "" && contentList
                            ? `We are associated & partnered with ${contentList}${
                                associations.length > 3 ? " etc" : ""
                              }.`
                            : ""
                        }`}
                      </div> */}

                      {associations.map((association, index) => (
                        <div
                          key={index}
                          className="col-4 association-card fs-20 fw-600 px-4 mb-5"
                        >
                          <div
                            className="shadow p-3 association-card-content cursor-pointer h-100 position-relative "
                            onClick={() =>
                              edit &&
                              history.push(
                                `/fpo/edit-association/${association.associationId}`
                              )
                            }
                          >
                            <div className={`h-100 pl-4 w-100`}>
                              <div className="row">
                                <div className="col-2 d-flex align-items-center logo-area px-0">
                                  {association.logoUrl &&
                                  association.logoUrl !== "" &&
                                  !association.logoUrl.includes(".pdf") ? (
                                    <img
                                      src={association.logoUrl}
                                      alt="logo"
                                      className="logo"
                                    />
                                  ) : (
                                    <div className="rounded-circle d-flex align-items-center height-50 width-50">
                                      <div className="text-white mx-auto">
                                        {association.institutionName.charAt(0)}
                                      </div>
                                    </div>
                                  )}
                                </div>
                                <div className="col-10 d-flex align-items-center pl-2">
                                  {association.institutionName}
                                </div>
                              </div>
                              <div className="row mt-2">
                                <div className="col-10 ml-auto fs-18 fw-600 mb-2 pl-2">
                                  {association.institutionmaster
                                    ? association.institutionmaster
                                        .institutionType
                                    : "-"}
                                </div>
                                <div className="col-12  mb-2">
                                  <div className="row">
                                    <div className="col-2 text-black-50 fs-16 d-flex align-ietms-center px-0">
                                      {"Type"}
                                    </div>
                                    <div className="col-10 fs-18 fw-600 d-flex align-ietms-center pl-2">
                                      {association.associationType
                                        ? getDescriptionData(
                                            association.associationType,
                                            40
                                          )
                                        : "-"}
                                    </div>
                                  </div>
                                </div>
                                <div className="col-12">
                                  <div className="row">
                                    <div className="col-2 text-black-50 fs-16 d-flex align-ietms-center px-0">
                                      {"Since"}
                                    </div>
                                    <div className="col-10 fs-18 fw-600 d-flex align-ietms-center pl-2">
                                      {association.associatedSince !== ""
                                        ? association.associatedSince
                                        : "-"}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </section>
                  </>
                ) : (
                  <>
                    {/* Loader */}
                    <CommonPageLoader />
                  </>
                )}

                {pageMeta.pageCount !== undefined &&
                  pageMeta.pageCount !== 1 && (
                    <Pagination
                      handleChange={this.handlePagination}
                      pageMeta={pageMeta}
                    />
                  )}
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getAllAssociations,
      getAssociationCsv,
      getAllInstitutiontypes,
      getAssociationList,
      downlaodAssociationandPartnersOfFPO,
    },
    dispatch
  );
};

let component = AssociationsViewClass;

export const AssociationsView = connect(null, mapDispatchToProps)(component);
