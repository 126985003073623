export const memberDataVariables = {
  fpo_name: "",
  state: "",
  district: "",
  unique_id: "",
  name: "",
  gender: "",
  dob: "",
  mobile: "",
  other_mobile: "",
  village: "",
  block: "",
  pincode: "",
  father_name: "",
  education: "",
  total_land: "",
  own_irrigated: "",
  own_rainfed: "",
  lease_irrigated: "",
  lease_rainfed: "",
  machinery: [],
  machinery_isOther: false,
  machinery_other: "",
  insurance: [],
  insurance_isOther: false,
  inusrance_other: "",
  vehicle: [],
  vehicle_isOther: false,
  vehicle_other: "",
  house: "",
  house_type: "",
  income: "",
  aadhaar: "",
  pan: "",
  marital: "",
  spouse_name: "",
  spouse_aadhaar: "",
  spouse_pan: "",
};

export const cropDataVariables = {
  season: "",
  classification: "",
  cropName: "",
  othercrop: "",
  categoryOptions: [],
  cropOptions: [],
};

export const liveStockDataVariables = {
  liveStockName: "",
  qty: "",
  otherLiveStock: "",
};

export const seasonOption = [
  { label: "Kharif", value: "Kharif" },
  { label: "Rabi", value: "Rabi" },
  { label: "Zaid", value: "Zaid" },
];

export const liveStockOptions = [
  { label: "Cow", value: "Cow" },
  { label: "Buffalow", value: "Buffalow" },
  { label: "Goat", value: "Goat" },
  { label: "Sheep", value: "Sheep" },
  { label: "Chicken", value: "Chicken" },
  { label: "Duck", value: "Duck" },
  { label: "Pig", value: "Pig" },
  { label: "Honey", value: "Honey" },
  { label: "None", value: "None" },
  { label: "Others", value: "Others" },
];

export const machineryOptions = [
  { label: "Disk Harrow", value: "Disk Harrow" },
  { label: "Plough", value: "Plough" },
  { label: "Land Leveler", value: "Land Leveler" },
  { label: "Ridger", value: "Ridger" },
  { label: "Cultivator", value: "Cultivator" },
  { label: "Seed Drill", value: "Seed Drill" },
  { label: "Rotavator", value: "Rotavator" },
  { label: "Pump", value: "Pump" },
  { label: "Sprayer", value: "Sprayer" },
  { label: "Transplanter", value: "Transplanter" },
  { label: "Tractor", value: "Tractor" },
  { label: "Harvester", value: "Harvester" },
  { label: "Mini Truck", value: "Mini Truck" },
  { label: "Truck", value: "Truck" },
  { label: "Mobile processing unit", value: "Mobile processing unit" },
  { label: "Software", value: "Software" },
  { label: "Trademark", value: "Trademark" },
  { label: "Plants & Warehouse", value: "Plants & Warehouse" },
  { label: "Plots", value: "Plots" },
  { label: "Building", value: "Building" },
  { label: "Machinery", value: "Machinery" },
  { label: "None", value: "None" },
  { label: "Others", value: "Others" },
];

export const insuranceOptions = [
  { label: "Life", value: "Life" },
  { label: "Health", value: "Health" },
  { label: "Crop", value: "Crop" },
  { label: "Cattle", value: "Cattle" },
  { label: "None", value: "None" },
  { label: "Other", value: "Other" },
];

export const vehicleOptions = [
  { label: "Cycle", value: "Cycle" },
  { label: "Motorcycle", value: "Motorcycle" },
  { label: "Car", value: "Car" },
  { label: "Lorry", value: "Lorry" },
  { label: "None", value: "None" },
  { label: "Other", value: "Others" },
];

export const genderOptions = [
  { label: "Male", value: "Male" },
  { label: "Female", value: "Female" },
];

export const educationOptions = [
  { label: "Middle School", value: "middle_school" },
  { label: "High School", value: "high_school" },
  { label: "College Graduate", value: "college_graduate" },
  { label: "PG and above", value: "pg" },
  { label: "No formal education", value: "no_education" },
];

export const houseTypeOptions = [
  { label: "Pucca", value: "pucca" },
  { label: "Kutcha", value: "kutcha" },
];

export const houseOptions = [
  { label: "Owned", value: "owned" },
  { label: "Rented", value: "rented" },
];

export const maritalOptions = [
  { label: "Yes", value: "Yes" },
  { label: "No", value: "No" },
];
