import React, { Component } from "react";
import { Navbar } from "../../component/common";
import { UserAuth } from "helpers/UserAuth";
import { AddLicense } from "component/compliance";
class addLicenseClass extends Component {
  render() {
    return (
      <div>
        <div className="normal-header">{/* <Navbar /> */}</div>
        <AddLicense id={this.props.match.params.id} />
      </div>
    );
  }
}

export const addLicense = UserAuth(addLicenseClass, {
  service: "complainceGovernance",
});
