import React, { Component } from "react";
import backIcon from "../../assets/images/icon/arrow-left.svg";
import { Banner, Quiz } from "component/Acadamy";
import { blogDetailApi } from "action/Acadamy";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { history } from "service/helpers";
import { BlogView } from "component/Blog";
import { CommonPageLoader } from "component/common";

export class BlogClass extends Component {
  constructor() {
    super();
    this.state = {
      blogDetail: {},
      isLoading: false,
      viewCountforBlock: 0,
    };
  }

  componentDidMount = () => {
    this._getBlogDetail();
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  _getBlogDetail = () => {
    this.setState({ isLoading: true });
    this.props.blogDetailApi(this.props.match.params.id).then((data) => {
      this.setState({
        blogDetail: data.blogList,
        viewCountforBlock: data.blogsCount,
        isLoading: false,
      });
    });
  };

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this._getBlogDetail();
    }
  }

  render() {
    let { blogDetail, isLoading, viewCountforBlock } = this.state;
    return (
      <>
        <div className="min-vh-100">
          <Banner minSize={true} />
          <div className="d-flex container-sa my-3 ">
            <div
              onClick={() => history.goBack()}
              className="d-flex cursor-pointer"
            >
              <img className="cursor-pointer" src={backIcon} alt="back" />
              <p className="ml-3 add-member-text-back mb-0">{"Blog"}</p>
            </div>
          </div>
          {isLoading ? (
            <>
              {/* Loader */}
              <CommonPageLoader />
            </>
          ) : (
            <BlogView
              blogDetail={blogDetail}
              handleChange={() => this._getBlogDetail()}
              viewCountforBlock={viewCountforBlock}
            />
          )}
        </div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      blogDetailApi,
    },
    dispatch
  );
};

let component = BlogClass;

export const BlogPage = connect(null, mapDispatchToProps)(component);
