import React, { Component } from "react";
import { Events, EventsList } from "../../index";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getEventNewsApi } from "action/Acadamy";
import { getNewsAndEvents } from "action/contentAct";

class EventNewsSectionClass extends Component {
  state = {
    first: {},
    newsList: [],
    eventList: [],
    isAuth: false,
  };

  //lifecycles
  componentDidMount = () => {
    //data
    localStorage.getItem("AuthToken") &&
      this.setState({
        isAuth: true,
      });
    this._getNewsAndEventsFromProps();
  };

  //get news and events details
  _getNewsAndEventsFromProps = () => {
    let { news = [], events = [] } = this.props;
    if (news.length > 0) {
      this.setState({
        first: {
          uploadImage: news[0].uploadImage,
          publishOn: news[0].publishOn,
          title: news[0].newsTitle,
          description: news[0].description,
        },
      });
    } else if (events.length > 0) {
      this.setState({
        first: {
          uploadImage: events[0].uploadImage,
          publishOn: events[0].publishOn,
          title: events[0].eventTitle,
          description: events[0].description,
        },
      });
    }
    this.setState({ newsList: news, eventList: events });
  };

  //lifecycles
  componentDidUpdate(prevProps, prevState) {
    if (prevProps !== this.props) {
      this._getNewsAndEventsFromProps();
    }
  }

  render() {
    let { first, newsList, eventList, isAuth } = this.state;
    return (
      <div className="event-section py-5">
        <div className="container-sa">
          <div className={`row  ${!isAuth ? "flex-row-reverse" : ""} `}>
            <div className="col-md-6">
              {newsList.length > 0 || eventList.length > 0 ? (
                <Events first={first} />
              ) : null}
            </div>
            <div className="col-md-6">
              <EventsList newsList={newsList} eventList={eventList} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getNewsAndEvents,
      getEventNewsApi,
    },
    dispatch
  );
};

let component = EventNewsSectionClass;

export const EventNewsSection = connect(null, mapDispatchToProps)(component);
