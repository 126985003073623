import React, { Fragment, useState } from "react";
import { UncontrolledTooltip } from "reactstrap";
import DatePicker from "react-datepicker";
import moment from "moment";
import "./index.scss";

export const CustomDate = ({
  list,
  tasks,
  getList,
  setMonthYear,
  isCompliance = false,
}) => {
  const getTask = (date) => {
    let index = list.findIndex((list) => {
      return moment(date).format("YYYY-MM-DD") == list;
    });
    if (index != -1) {
      if (isCompliance) {
        return tasks[index].paticulars;
      } else {
        return (
          tasks[index] &&
          tasks[index].fpoTasks &&
          `${tasks[index].fpoTasks.length} Tasks`
        );
      }
    } else {
      return null;
    }
  };
  return (
    <div className="custom-date customDatePickerWidth">
      <DatePicker
        renderCustomHeader={({
          date,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <div className="custom-date-header">
            <button
              onClick={() => decreaseMonth()}
              disabled={prevMonthButtonDisabled}
            >
              <span className="icon-keyboard-arrow-left"></span>
            </button>
            <div className="month-year">
              <span className="icon-calender_pre mr-2"></span>{" "}
              {date ? moment(date).format("MMMM,YYYY") : "-"}
            </div>

            <button
              onClick={() => increaseMonth()}
              disabled={nextMonthButtonDisabled}
            >
              <span className="icon-keyboard-arrow-right"></span>
            </button>
          </div>
        )}
        renderDayContents={(day, date) => {
          return (
            <div
              id={`compliance-${day}`}
              className={`compliance_calender_day ${
                list.includes(moment(date).format("YYYY-MM-DD")) ? "active" : ""
              }`}
            >
              <div>
                <span>{day}</span>
                <label>{getTask(date)}</label>
                {isCompliance &&
                  list.includes(moment(date).format("YYYY-MM-DD")) && (
                    <UncontrolledTooltip
                      placement="left"
                      target={`compliance-${day}`}
                      hideArrow={true}
                    >
                      {getTask(date)}
                    </UncontrolledTooltip>
                  )}
              </div>
            </div>
          );
        }}
        inline
        disabled={true}
        highlightDates={list}
        onMonthChange={(date) => {
          let body = {
            month: moment(date).format("MM"),
            year: moment(date).format("YYYY"),
          };
          getList(body);
          setMonthYear(body);
        }}
      />
    </div>
  );
};
