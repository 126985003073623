import React from 'react';
import { history } from 'service/helpers';
import { currencyConvert } from 'service/helperFunctions';
const User_Profile_1 = require('assets/svg/Members/User_Profile_1.svg');

function Strip(props) {
   const { payload, index, editAccess } = props;
   const {
      creditId,
      member_profile = '',
      fInstitution,
      lenderName,
      LoanAmountAvailed,
      TenurePeriod,
      Tenure,
      interestPayment,
   } = payload;
   return (
      <div
         key={index}
         className='container-sa license-strip shadow rounded mb-3'
      >
         <div className='row align-items-center h-100 px-2'>
            <div className='col-1 position-relative d-flex justify-content-center align-items-center'>
               <img
                  className='rounded-circle income-card-profile '
                  src={member_profile && member_profile.includes('https://') ? member_profile : User_Profile_1}
                  alt='profile'
               />
               <span className='fs-20 fw-600 text-white position-absolute'>
                  {lenderName.charAt(0)}
               </span>
            </div>
            <div className='col-2'>
               <div className='fs-18 fw-600'>{lenderName}</div>
               <div className='text-black-50'>{'Lender name	'}</div>
            </div>
            <div className='col-2'>
               <div className='fs-18 fw-600'>
                  {fInstitution ? fInstitution.institutionType : '-'}
               </div>
               <div className='text-black-50'>{'Financial institution'}</div>
            </div>
            <div className='col-2'>
               <div className='fs-18 fw-600'>
                  {LoanAmountAvailed ? currencyConvert(LoanAmountAvailed) : '-'}
               </div>
               <div className='text-black-50'>
                  <div>{'Loan amount availed'} </div>
                  {' ( '} <span className='icon-rupee'></span> {' )'}
               </div>
            </div>
            <div className='col-2'>
               <div className='fs-18 fw-600'>
                  {Tenure ? Tenure : '-'} {TenurePeriod ? TenurePeriod : ''}
               </div>
               <div className='text-black-50'>{'Tenure period'}</div>
            </div>
            <div className='col-2'>
               <div className='fs-18 fw-600'>{interestPayment ? interestPayment : '-'}</div>
               <div className='text-black-50'>{'Interest payment'}</div>
            </div>
            <div className='col-1'>
               {editAccess &&
                  <div className='text-black-50 cursor-pointer'
                     onClick={() => history.push(`/fpo/edit-credithistory/${creditId}`)}
                  >
                     {'Edit'}
                  </div>
               }
            </div>
         </div>
      </div>
   );
}

export default Strip;
