import { addQuery } from "service/helperFunctions";
import { kymApi } from "service/apiVariables";
import { history } from "service/helpers";

export const kymOTPLogin =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...kymApi.kymOTPLogin, body })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

export const KYMOTPVerify =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...kymApi.KYMOTPVerify, body })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ message }) => {
          history.push("/login");
          reject(Toast({ type: "error", message }));
        });
    });
  };

export const createKymUser =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...kymApi.createKymUser, body })
        .then(({ data, message }) => {
          resolve(data);
          Toast({ type: "success", message, time: 5000 });
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

export const deleteKymUser =
  (id) =>
  (dispatch, getState, { api, Toast }) => {
    kymApi.deleteKymUser.id = id;
    return new Promise((resolve, reject) => {
      api({ ...kymApi.deleteKymUser })
        .then(({ data, message }) => {
          resolve(data);
          Toast({ type: "success", message });
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

export const getKYMFPOList =
  () =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...kymApi.getKYMFPOList })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

export const getKYMNewFPOList =
  (query) => 
  (dispatch, getState, { api, Toast }) => {
    addQuery(query, kymApi.getKYMNewFPOList);
    return new Promise((resolve, reject) => {
      api({ ...kymApi.getKYMNewFPOList })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

export const getKYMNewFPOMemberList =
  (id, query) =>
  (dispatch, getState, { api, Toast }) => {
    kymApi.getKYMNewFPOMemberList.id = id;
    addQuery(query, kymApi.getKYMNewFPOMemberList);
    return new Promise((resolve, reject) => {
      api({ ...kymApi.getKYMNewFPOMemberList })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

export const getKYMFPOData =
  (id) =>
  (dispatch, getState, { api, Toast }) => {
    kymApi.getKYMFPOData.id = id;
    return new Promise((resolve, reject) => {
      api({ ...kymApi.getKYMFPOData })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

export const getKYMModuleFPOMemberData =
  (id) =>
  (dispatch, getState, { api, Toast }) => {
    kymApi.getKYMModuleFPOMemberData.id = id;
    return new Promise((resolve, reject) => {
      api({ ...kymApi.getKYMModuleFPOMemberData })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

export const updateKYMModuleFPOMemberData =
  (id, body) =>
  (dispatch, getState, { api, Toast }) => {
    kymApi.updateKYMModuleFPOMemberData.id = id;
    return new Promise((resolve, reject) => {
      api({ ...kymApi.updateKYMModuleFPOMemberData, body })
        .then(({ data, message }) => {
          resolve(data);
          Toast({ type: "success", message });
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

export const addKYMModuleMember =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...kymApi.addKYMModuleMember, body })
        .then(({ data, message }) => {
          resolve(data);
          Toast({ type: "success", message });
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };
