import React, { Component } from "react";
import "./documentsview.scss";
import { Company } from "../Company";
import { Statutory } from "../Statutory";
import { Director } from "../Director";
import { DocumentFooter } from "../DocumentFooter";
import { Credit } from "../Credit";
import { Financial } from "../Financial";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import { connect } from "react-redux";
import { PlanList } from "component/KeyFinancial/PlanList";
import { documentCurrentTab } from "action/DocumentAct";
import { bindActionCreators } from "redux";
export class DocumentsViewClass extends Component {
  state = {
    activeTab: "1",
  };

  toggle = (tab) => {
    this.setState({ activeTab: tab }, () => this.props.documentCurrentTab(tab));
  };

  componentDidMount = () => {
    let { currentTab } = this.props;
    if (currentTab == 6) {
      this.setState({ activeTab: currentTab.toString() });
    }
  };

  render() {
    let { activeTab } = this.state;
    let { id } = this.props;
    return (
      <div>
        <section className="member-section">
          <div className="container-sa documents-view px-0">
            <div className="filter-top">
              <div className="scroll-x">
                <Nav
                  tabs
                  className="tab-sammunaty"
                  style={{ width: "max-content" }}
                >
                  <NavItem className="">
                    <NavLink
                      className={classnames({
                        active: activeTab === "1",
                      })}
                      onClick={() => this.toggle("1")}
                    >
                      {"Company KYC"}
                    </NavLink>
                  </NavItem>
                  <NavItem className="">
                    <NavLink
                      className={classnames({
                        active: activeTab === "2",
                      })}
                      onClick={() => this.toggle("2")}
                    >
                      {"Statutory license"}
                    </NavLink>
                  </NavItem>
                  <NavItem className="">
                    <NavLink
                      className={classnames({
                        active: activeTab === "3",
                      })}
                      onClick={() => this.toggle("3")}
                    >
                      {"Director & Management KYC"}
                    </NavLink>
                  </NavItem>
                  <NavItem className="">
                    <NavLink
                      className={classnames({
                        active: activeTab === "4",
                      })}
                      onClick={() => this.toggle("4")}
                    >
                      {"Financials"}
                    </NavLink>
                  </NavItem>
                  <NavItem className="">
                    <NavLink
                      className={classnames({
                        active: activeTab === "5",
                      })}
                      onClick={() => this.toggle("5")}
                    >
                      {"Credit data"}
                    </NavLink>
                  </NavItem>
                  <NavItem className="">
                    <NavLink
                      className={classnames({
                        active: activeTab === "6",
                      })}
                      onClick={() => this.toggle("6")}
                    >
                      {"Business plan"}
                    </NavLink>
                  </NavItem>
                </Nav>
              </div>
            </div>
          </div>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <Company activeTab={activeTab} id={id} />
            </TabPane>
            <TabPane tabId="2">
              <Statutory activeTab={activeTab} id={id} />
            </TabPane>
            <TabPane tabId="3">
              <Director activeTab={activeTab} id={id} />
            </TabPane>
            <TabPane tabId="4">
              <Financial activeTab={activeTab} id={id} />
            </TabPane>
            <TabPane tabId="5">
              <Credit activeTab={activeTab} id={id} />
            </TabPane>
            <TabPane tabId="6">
              <PlanList activeTab={activeTab} id={id} />
            </TabPane>
          </TabContent>
        </section>
        <DocumentFooter />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  currentTab: state.document.tab,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      documentCurrentTab,
    },
    dispatch
  );
};
export const DocumentsView = connect(
  mapStateToProps,
  mapDispatchToProps
)(DocumentsViewClass);
