import React, { Component } from "react";
import rightCircle from "assets/images/play-circle.png";
import { history } from "service/helpers";
import "./TrendingVideoFav.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination } from "swiper";

import favorite from "../../../assets/images/academy/heart_white.svg";
import moment from "moment";
import { getDescriptionData } from "service/helperFunctions";
const arrow_left = require("assets/images/icon/arrow-left.svg");
const arrow_right = require("assets/images/icon/arrow-left.svg");
export class TrendingVideoFav extends Component {
  constructor() {
    super();
    SwiperCore.use([Navigation, Pagination]);
  }
  render() {
    const params = {
      slidesPerView: 1,
      navigation: true,
      draggable: true,
      spaceBetween: 20,
      breakpoints: {
        500: {
          slidesPerView: 2,
        },
        768: {
          slidesPerView: 4,
        },
      },
      navigation: {
        nextEl: "#academy-next",
        prevEl: "#academy-prev",
      },
    };

    let { trendingVideos = [], title, favorites } = this.props;
    return (
      <>
        <section className="video-section-fav mx-3 on-going-courses-section">
          <div className="container-sa">
            <div className="d-flex justify-content-between align-items-center">
              <h4 className="title mb-0">{title}</h4>
              <div className="d-flex align-items-center">
                <p
                  className="mb-0 mr-2 text-black-50 fs-18 text-underline line-height-23 fw-700 cursor-pointer"
                  onClick={() => history.push("/academy/trendingvideos/all")}
                >
                  {"View all"}
                </p>
                <div>
                  <input
                    type="image"
                    src={arrow_left}
                    alt="left"
                    width="40"
                    height="40"
                    id="academy-prev"
                  />
                  <input
                    type="image"
                    src={arrow_right}
                    alt="left"
                    width="40"
                    height="40"
                    id="academy-next"
                    className="rotate-180"
                  />
                </div>
              </div>
            </div>
            <div className="video-list">
              <Swiper {...params}>
                {trendingVideos.map((data, index) => {
                  let { trendingvideo } = data;
                  return (
                    <SwiperSlide key={index + "video"}>
                      <div className={`${favorites && "info"}`}>
                        <div
                          className="video-card-fav "
                          onClick={() =>
                            history.push(
                              `/academy/trending-video/${trendingvideo.trendingvideoId}`
                            )
                          }
                        >
                          <div
                            className={`video-thumb ${
                              favorites && "info-thumb"
                            } `}
                            style={{
                              backgroundImage:
                                "url(" +
                                `${trendingvideo.thumbnailImageUrl}` +
                                ")",
                            }}
                          >
                            <div className="d-flex justify-content-between h-100 flex-column p-3 m-1 video-body">
                              <div className="video-content pt-3">
                                <h5>
                                  {" "}
                                  {getDescriptionData(
                                    trendingvideo.title,
                                    30
                                  )}{" "}
                                </h5>
                                <div className="text-white">
                                  {moment(trendingvideo.publishDate).format(
                                    "MMM DD, YYYY"
                                  )}
                                </div>
                              </div>
                              <div className="d-flex justify-content-between align-items-center">
                                <div className="play-link w-100 pb-1">
                                  <img src={rightCircle} alt="rightCircle" />
                                </div>
                                {favorites && (
                                  <div className="">
                                    <img
                                      className="video-card-fav-heart"
                                      src={favorite}
                                      alt="favorite"
                                    />
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div>
          </div>
        </section>
      </>
    );
  }
}
