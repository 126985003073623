import React, { Component } from "react";
import "./OtherFpos.scss";
import { arrow_left, arrow_right } from "./icons";
import Card from "./card";
import Swiper from "react-id-swiper";

export class OtherFpos extends Component {
  render() {
    const params = {
      slidesPerView: 2,
      spaceBetween: 0,
      navigation: {
        nextEl: "#next-btn",
        prevEl: "#prev-btn",
      },
      rebuildOnUpdate: true,
    };
    let { yourEventList = [], viewAccess = false } = this.props;
    return (
      <div className="other-fpos p-5">
        <div className="other-fpos-container mt-5 d-flex">
          <div className="d-flex other-fpos-container-card mr-3">
            {/* Card */}
            {yourEventList.length > 0 && !!yourEventList ? (
              <Swiper {...params}>
                {yourEventList.map((event, index) => {
                  return (
                    <div key={index}>
                      <Card event={event} viewAccess={viewAccess} />
                    </div>
                  );
                })}
              </Swiper>
            ) : null}
          </div>
          <div className="d-flex flex-column justify-content-center p-5">
            <div className="other-fpos-container-header mb-3">
              Events happened of other FPOs
            </div>
            <div className="other-fpos-container-content mb-3">
              Look out for the events organised by the FPOs around you
            </div>
            <div>
              <input
                type="image"
                src={arrow_left}
                alt="left"
                width="40"
                height="40"
                id="prev-btn"
                onClick={() => {}}
              />
              <input
                type="image"
                src={arrow_right}
                alt="left"
                width="40"
                id="next-btn"
                height="40"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
