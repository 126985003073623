import React, { Component } from "react";
import { NormalButton } from "component/common";
import "./Quiz.scss";
import star from "assets/images/star.svg";
// Redux Connection
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getCertificatesData, downloadCerificate } from "action/certificates";
import { Toast } from "service/toast";
export class QuizClass extends Component {
  constructor() {
    super();
    this.state = {
      selectedOption: 1,
      optionId: "",
      showPopup: false,
      quizLength: 0,
      score: 0,
    };
  }

  // handel Anser Save
  handelAnserSave = (data, id, index) => {
    let { optionId } = this.state;
    let { quizes, quizAnswerAPI } = this.props;

    let { quizesQuestion = [] } = this.props;
    if (data.isAnswered) {
      this.moveNextQuestion(quizesQuestion.length, index);
    } else {
      if (optionId === "") {
        Toast({ type: "error", message: "please select option" });
      } else {
        let quizData = {
          courseId: quizes.courseId,
          quizId: quizes.quizId,
          questionId: data.questionId,
          optionId: optionId,
        };

        quizAnswerAPI(quizData, id).then(() => {
          this.props.questionUpdateOption(index, quizes.quizId, optionId);
          this.moveNextQuestion(quizesQuestion.length, index);
          this.setState({ optionId: "" });
        });
      }
    }
  };

  //handel Anser Submit
  handelAnserSubmit = (data, id, index) => {
    let { quizes, quizAnswerAPI } = this.props;
    let { optionId } = this.state;

    if (optionId === "") {
      Toast({ type: "error", message: "please select option" });
    } else {
      let quizData = {
        courseId: quizes.courseId,
        quizId: quizes.quizId,
        questionId: data.questionId,
        optionId: optionId,
      };

      quizAnswerAPI(quizData, id).then((data) => {
        this.setState({
          optionId: "",
          showPopup: true,
          quizLength: data.noOfQuestions,
          score: data.marks,
        });
      });
    }
  };

  //move Next Question
  moveNextQuestion = (questionLength, currentQuestionIndex) => {
    let { quizesQuestion = [], updateQuestionId } = this.props;

    if (currentQuestionIndex + 1 < questionLength) {
      updateQuestionId(quizesQuestion[currentQuestionIndex + 1].questionId);
    }
  };

  //handel Select Option
  handelSelectOption = (data) => {
    this.setState({ optionId: data.optionId });
  };

  //handel Back Question
  handelBackQuestion = (currentQuestionIndex) => {
    let { quizesQuestion = [], updateQuestionId } = this.props;

    if (currentQuestionIndex - 1 >= 0) {
      updateQuestionId(quizesQuestion[currentQuestionIndex - 1].questionId);
    }
  };

  //handle Back
  handleBack = () => {
    let { history = {} } = this.props;
    this.setState({ showPopup: false });
    history.push(`/academy/my-learning`);
  };

  //handle view
  handleview = (courseId) => {
    let { history = {} } = this.props;
    this.setState({ showPopup: false });
    this.props._getQuizList(courseId);
    history.push(`/academy/quiz/${courseId}/viewAnswer`);
  };

  //handle Retake
  handleRetake = (courseId) => {
    let { history = {} } = this.props;
    this.setState({ showPopup: false });
    // this.props._getQuizList(courseId);
    history.push(`/academy/quiz/${courseId}/retakeQuiz`);
  };

  //handle Certificate Download
  handleCertificateDownload = () => {
    let { quizes } = this.props;
    let { courseId = "" } = quizes;
    this.props.downloadCerificate(courseId).then((data) => {
      window.open(
        "https://portal.fpogateway.com/academy/api/v1/download/certificate?fileName=" +
          data.filename,
        "_blank"
      );
    });
  };

  render() {
    let { optionId, showPopup, quizLength, score } = this.state;
    let {
      quizesQuestion,
      currentQuestionId,
      quizes,
      history = {},
    } = this.props;

    let { courseId = "" } = quizes;

    let { location = {} } = history;

    let { pathname = {} } = location;
    return (
      <>
        {showPopup ? (
          <div className="booking-sucess">
            <div className="wrapper-booking-sucess">
              <img src={star} alt="star" />
              <h3>
                {score}/{quizLength}
              </h3>
              <p>{"Your score"}</p>
              <div className="para">
                That’s Quite <div>Awesome</div>
              </div>
              <div
                className="text-underline fs-18 text-white mb-3 cursor-pointer"
                onClick={() => this.handleCertificateDownload()}
              >
                {"Get certificate"}
              </div>
              <div className="d-flex justify-content-center">
                <NormalButton
                  label="View Answers"
                  normal={true}
                  isPopup={true}
                  className="px-3 py-2 fs-14 line-height-26 mr-3"
                  onClick={() => this.handleview(courseId)}
                />
                {pathname.includes("retakeQuiz") ? (
                  <NormalButton
                    label="Back to home"
                    isPopup={true}
                    normal={true}
                    className="px-3 py-2 fs-14 line-height-26"
                    onClick={() => this.handleBack()}
                  />
                ) : (
                  <NormalButton
                    label="Retake quiz"
                    normal={true}
                    isPopup={true}
                    className="px-3 py-2 fs-14 line-height-26"
                    onClick={() => this.handleRetake(courseId)}
                  />
                )}
              </div>
            </div>
          </div>
        ) : null}

        <section className="quiz-section">
          <div className="quiz-wrapper">
            <div className="quiz-image"></div>
            <div className="quiz-number-list">
              {quizesQuestion.map((data, index) => (
                <div key={"questionListLeft" + index} className="quiz-list">
                  <p className="p-0 d-flex justify-content-center align-items-center h-100 m-0">
                    {index + 1}
                  </p>
                </div>
              ))}
            </div>
            <div className="quiz-content">
              {quizesQuestion.map((data, index) => {
                let { options = [] } = data;
                return currentQuestionId === data.questionId ? (
                  <div
                    key={"questionList" + index}
                    className="quiz-content-wrapper"
                  >
                    <h4>Q{index + 1}</h4>
                    <p className="question">{data.questionName}</p>
                    {pathname.includes("viewAnswer") ? (
                      <div className="quiz-option-section">
                        <div className="row">
                          {data.isAnswered
                            ? options.map((dataOption, indexOption) => (
                                <div className="col-sm-6">
                                  <div
                                    className={`optionList ${
                                      dataOption.isAnswer
                                        ? "active"
                                        : data.answerSelected.optionId ===
                                            dataOption.optionId &&
                                          !dataOption.isAnswe
                                        ? "wrong bg-danger"
                                        : "deselect"
                                    }`}
                                    key={
                                      indexOption +
                                      "questionOptionsList" +
                                      index
                                    }
                                  >
                                    {dataOption.optionName}
                                  </div>
                                </div>
                              ))
                            : options.map((dataOption, indexOption) => (
                                <div className="col-sm-6">
                                  <div
                                    onClick={() =>
                                      this.handelSelectOption(
                                        dataOption,
                                        indexOption,
                                        index
                                      )
                                    }
                                    className={`optionList ${
                                      dataOption.optionId === optionId
                                        ? "active"
                                        : ""
                                    }`}
                                    key={
                                      indexOption +
                                      "questionOptionsList" +
                                      index
                                    }
                                  >
                                    {dataOption.optionName}
                                  </div>
                                </div>
                              ))}
                        </div>
                      </div>
                    ) : (
                      <div className="quiz-option-section">
                        <div className="row">
                          {data.isAnswered
                            ? options.map((dataOption, indexOption) => (
                                <div className="col-sm-6">
                                  <div
                                    className={`optionList ${
                                      data.answerSelected.optionId ===
                                      dataOption.optionId
                                        ? "active"
                                        : "deselect"
                                    }`}
                                    key={
                                      indexOption +
                                      "questionOptionsList" +
                                      index
                                    }
                                  >
                                    {dataOption.optionName}
                                  </div>
                                </div>
                              ))
                            : options.map((dataOption, indexOption) => (
                                <div className="col-sm-6">
                                  <div
                                    onClick={() =>
                                      this.handelSelectOption(
                                        dataOption,
                                        indexOption,
                                        index
                                      )
                                    }
                                    className={`optionList ${
                                      dataOption.optionId === optionId
                                        ? "active"
                                        : ""
                                    }`}
                                    key={
                                      indexOption +
                                      "questionOptionsList" +
                                      index
                                    }
                                  >
                                    {dataOption.optionName}
                                  </div>
                                </div>
                              ))}
                        </div>
                      </div>
                    )}
                    {pathname.includes("viewAnswer") ? (
                      <div className="answer-justifications">
                        <h5 className="text-black-1c fs-16 line-height-19 mb-3 fw-700">
                          Answer{" "}
                        </h5>
                        {options.map((list) =>
                          list.isAnswer ? (
                            <p className="correct-answer mb-1 pb-2">
                              Correct answer : {list.optionName}
                            </p>
                          ) : null
                        )}
                        <p className="jusitifications mb-0">
                          {data.answerJustification}
                        </p>
                      </div>
                    ) : null}
                    <div className="button-wrapper">
                      {index !== 0 && (
                        <div className="">
                          <NormalButton
                            label="Previous"
                            mainbg={true}
                            className="px-3 py-2 mx-auto fs-14 line-height-26 font-Gilroy"
                            onClick={() => this.handelBackQuestion(index)}
                          />
                        </div>
                      )}
                      <div className="ml-auto">
                        {index + 1 === quizesQuestion.length &&
                        pathname.includes("viewAnswer") ? (
                          <div className="d-flex">
                            <div className="mr-3">
                              <NormalButton
                                label="Back to home"
                                mainbg={true}
                                className="px-3 py-2 mx-auto fs-14 line-height-26 font-Gilroy"
                                onClick={() =>
                                  history.push("/academy/my-learning")
                                }
                              />
                            </div>
                            <div>
                              <NormalButton
                                label="Retake Quiz"
                                mainbg={true}
                                className="px-3 py-2 mx-auto fs-14 line-height-26 font-Gilroy"
                                onClick={() => this.handleRetake(courseId)}
                              />
                            </div>
                          </div>
                        ) : null}
                        {quizesQuestion.length === index + 1 ? (
                          data.isAnswered ? (
                            !pathname.includes("viewAnswer") && (
                              <NormalButton
                                label="Retake Quiz"
                                mainbg={true}
                                className="px-3 py-2 mx-auto fs-14 line-height-26 font-Gilroy"
                                onClick={() => this.handleRetake(courseId)}
                              />
                            )
                          ) : (
                            !pathname.includes("viewAnswer") && (
                              <NormalButton
                                label="Submit"
                                mainbg={true}
                                className="px-3 py-2 mx-auto fs-14 line-height-26 font-Gilroy"
                                onClick={() =>
                                  this.handelAnserSubmit(data, "submit", index)
                                }
                              />
                            )
                          )
                        ) : (
                          <NormalButton
                            label="Save & Next"
                            mainbg={true}
                            className="px-3 py-2 mx-auto fs-14 line-height-26 font-Gilroy"
                            onClick={() =>
                              this.handelAnserSave(data, "save", index)
                            }
                          />
                        )}
                      </div>
                    </div>
                  </div>
                ) : null;
              })}
            </div>
          </div>
        </section>
      </>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getCertificatesData,
      downloadCerificate,
    },
    dispatch
  );
};

export const Quiz = connect(null, mapDispatchToProps)(QuizClass);
