import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { SSOLogin } from "action/AuthAct";
import { CommonPageLoader } from "component/common";
import { history } from "service/helpers";

const SSOPage = ({ SSOLoginApi }) => {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const paramsCode = params.get("code");
  const [code, setCode] = useState();
  const [loading, setLoading] = useState(true);
  const [isSuccessLogin, setSuccessLogin] = useState(true);

  useEffect(() => {
    setCode(paramsCode);
  }, [paramsCode]);

  const apiCall = useCallback(() => {
    if (code) {
      SSOLoginApi({ code, isMobileLogin: false })
        .then((data) => {
          if (data) {
            const { userDetails, token } = data;
            const {
              userName,
              emailId,
              userMasterId,
              userRoles: {
                userRoleName,
                userGroup: { userGroupName },
              },
            } = userDetails;
            localStorage.setItem("AuthToken", token);
            localStorage.setItem("userMasterId", userMasterId);
            localStorage.setItem("userName", userName);
            localStorage.setItem("userEmail", emailId);
            localStorage.setItem("userRoleName", userRoleName);
            localStorage.setItem("userGroupName", userGroupName);
            setLoading(false);
            history.push("/rm/home");
          }
        })
        .catch(() => {
          setSuccessLogin(false);
          setLoading(false);
          history.push("/login");
        });
    }
  }, [SSOLoginApi, code]);

  useEffect(() => {
    apiCall();
  }, [apiCall]);

  return (
    <div>
      {loading ? (
        <>
          <CommonPageLoader />
          <div className="fs-20 fw-600 text-green-18 text-center">
            {isSuccessLogin
              ? "Please wait logging you in..."
              : "Something went wrong! Please try logging in again..."}
          </div>
        </>
      ) : null}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ SSOLoginApi: SSOLogin }, dispatch);
};

export default connect(null, mapDispatchToProps)(SSOPage);
