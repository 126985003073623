import React, { Component } from "react";
import { connect } from "react-redux";
import { Navbar, NormalCheckbox } from "component/common";
import filter from "assets/images/icon/filter.svg";
import { bindActionCreators } from "redux";
import { DiscussionForum } from "component/Acadamy";
import { categoryList, categoryListChange } from "action/forum";
import { history } from "service/helpers";
import backIcon from "../../assets/images/icon/arrow-left.svg";

export class CommunityForumClass extends Component {
  state = {
    active: false,
    allCategoryList: [],
    filteredValue: "",
    filteredName: "",
  };

  componentDidMount() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    this.props.categoryList().then(({ success, data }) => {
      if (!!success) {
        let list = data.map(({ id, name }) => ({
          value: id,
          label: name,
          isChecked: false,
        }));
        this.setState({ allCategoryList: list });
        this.props.categoryListChange(list);
      }
    });
  }

  //open dropdown
  handleDropdown = () => {
    if (!this.state.active) {
      document.addEventListener("click", this.handleOutsideClick, false);
    } else {
      document.removeEventListener("click", this.handleOutsideClick, false);
    }
    this.setState((prevState) => ({
      active: !prevState.active,
    }));
  };

  //close popup when clicked outside
  handleOutsideClick = (e) => {
    if (this.node != null) {
      if (this.node.contains(e.target)) {
        return;
      }
    }
    this.handleDropdown();
  };

  //handle select users
  handleToggle = (index, filteredValue) => {
    let { allCategoryList } = this.state;

    if (!allCategoryList[index].isChecked) {
      allCategoryList.map((list) => {
        if (list.isChecked) {
          list.isChecked = false;
        }
      });
      allCategoryList[index].isChecked = true;
      this.setState({ allCategoryList, filteredValue });
    } else {
      allCategoryList[index].isChecked = false;
      this.setState({ allCategoryList, filteredValue: "" });
    }
  };

  handleCategoryFilter = (filteredValue) => {
    let { allCategoryList } = this.state;
    allCategoryList.map((list) => {
      if (list.isChecked) {
        list.isChecked = false;
      }
      if (list.value == filteredValue) {
        list.isChecked = true;
      }
    });
    this.setState({ allCategoryList, filteredValue });
  };

  render() {
    let { history = {} } = this.props;

    let { location = {} } = history;

    let { pathname = "" } = location;

    let { active, allCategoryList, filteredValue } = this.state;

    let loginDetails = localStorage.getItem("userGroupName");

    return (
      <>
        <div className="normal-header">{/* <Navbar /> */}</div>
        <div className="shadow">
          <div className="d-flex container-sa py-3 bg-white">
            <div
              onClick={() =>
                loginDetails.includes("fpo")
                  ? history.push("/fpo/home")
                  : loginDetails.includes("samunnati")
                  ? history.push("/rm/home")
                  : history.push("/other/home")
              }
              className="d-flex cursor-pointer"
            >
              <img className="cursor-pointer" src={backIcon} alt="back" />
              <p className="ml-3 add-member-text-back mb-0">{"Back"}</p>
            </div>
          </div>
        </div>
        <div className="community-forum">
          <div className="container-sa">
            <div className="row forum-tabs">
              <div className="col-md-6 col-xs-6">
                <ul className="nav nav-pills">
                  <li
                    className={`nav-item ${
                      !pathname.includes("myforum") &&
                      !pathname.includes("notifications")
                        ? "active"
                        : ""
                    }`}
                    onClick={() => history.push("/academy/communityForum")}
                  >
                    {"Discussion forum"}
                  </li>
                  <li
                    className={`nav-item ${
                      pathname.includes("myforum") ? "active" : ""
                    }`}
                    onClick={() =>
                      history.push("/academy/communityForum/myforum")
                    }
                  >
                    {"My activity"}
                  </li>
                  <li
                    className={`nav-item ${
                      pathname.includes("notifications") ? "active" : ""
                    }`}
                    onClick={() =>
                      history.push("/academy/communityForum/notifications")
                    }
                  >
                    {"Notifications"}
                  </li>
                </ul>
              </div>
              <div className="col-md-6 col-xs-6 d-flex justify-content-end align-items-center menu">
                <div
                  className="cursor-pointer filter-dropdown"
                  onClick={this.handleDropdown}
                >
                  <img src={filter} alt="filter" />
                  <span className="fs-16 line-height-19 text-green-18 ml-2">
                    {"Filter"}
                  </span>
                  <div className={`dropdown ${active ? "active" : ""}`}>
                    <div className="row">
                      {filteredValue !== "" ? (
                        <div className="col-6 mb-3">
                          <div
                            className="text-danger fs-16 fw-600 pr-3 cursor-pointer"
                            onClick={() => this.handleCategoryFilter("")}
                          >
                            {"Remove filter"}
                          </div>
                        </div>
                      ) : null}
                    </div>
                    <div className="row">
                      {allCategoryList.map((list, index) => (
                        <div className="col-6 mb-3" key={index}>
                          <div className="d-flex">
                            <div className="" id="check-in">
                              <NormalCheckbox
                                className="custom-checkbox check-box"
                                value=""
                                name={`toggleEvent-${index}`}
                                checked={list.isChecked}
                                onChange={() =>
                                  this.handleToggle(index, list.value)
                                }
                              />
                            </div>
                            <div className="filter-text">{list.label}</div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-blue-f9 pt-4">
            <div className="container-sa">
              <div className="row">
                <div className="col-md-12">
                  <DiscussionForum
                    filteredValue={filteredValue}
                    handleCategoryFilter={this.handleCategoryFilter}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      categoryList,
      categoryListChange,
    },
    dispatch
  );
};

let component = CommunityForumClass;

export const CommunityForum = connect(null, mapDispatchToProps)(component);
