import React from 'react';

import './OurInfrastructure.scss';

export const OurInfrastructureCard = ({ img, number, title }) => {
   return (
      <>
         <div className='OurInfrastructure-card'>
            <div className='img-thumb-OurInfrastructure'>
               <img src={img} alt='img-thumb' />
            </div>
            <p>{title}</p>
         </div>
      </>
   );
};
