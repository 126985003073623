import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getAcademy } from "action/HomeAct";
import "./awards.scss";
import moment from "moment";
import { getDescriptionData } from "service/helperFunctions";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from "swiper";
import defaultAwardsImage from "assets/images/DefaultAward.jpg";
import { history } from "service/helpers";

const arrow_left = require("assets/svg/academy/arrow-left.svg");
const arrow_right = require("assets/svg/academy/arrow-right.svg");

export class AwardsClass extends Component {
  constructor() {
    super();
    SwiperCore.use([Navigation]);
  }
  render() {
    const params = {
      slidesPerView: 2,
      spaceBetween: 20,
      breakpoints: {
        500: {
          slidesPerView: 2,
        },
        768: {
          slidesPerView: 2,
        },
      },
      navigation: {
        nextEl: "#academy-next",
        prevEl: "#academy-prev",
      },
    };
    const { fpoawards = [] } = this.props;

    return (
      <div className="container-fluid d-flex align-items-center pl-md-5 row aboutus-page-awards pr-md-0">
        <div className="col-4 pl-md-5">
          <div className="text-bold-academy text-white mb-4">
            Awards and Recognitions
          </div>
          <div>
            <input
              type="image"
              src={arrow_left}
              alt="left"
              width="40"
              height="40"
              id="academy-prev"
            />
            <input
              type="image"
              src={arrow_right}
              alt="left"
              width="40"
              height="40"
              id="academy-next"
            />
          </div>
        </div>
        <div className="col-8">
          <Swiper {...params}>
            {fpoawards.map((awards, index) => {
              let {
                receivedDate,
                title,
                shortDescription,
                awardDocs = [],
                awardId = "",
                awardName = "",
              } = awards;
              return (
                <SwiperSlide key={index}>
                  <div>
                    <div className="card card-width">
                      <div className="">
                        {awardDocs.length !== 0 ? (
                          <img
                            className="card-img-top"
                            src={awardDocs[0].documentUrl}
                            alt={awardDocs[0].fileName}
                          />
                        ) : (
                          <img
                            className="card-img-top"
                            src={defaultAwardsImage}
                            alt="awards"
                          />
                        )}
                      </div>
                      <div className="card-body d-flex flex-column justify-content-between p-3">
                        <div>
                          <div className="orange card-heading mb-1">
                            {title !== "" ? getDescriptionData(title, 20) : ""}
                          </div>
                          <div className="card-content mb-2">
                            <p className="mb-1 line-height-21 fs-14">
                              {getDescriptionData(shortDescription, 75)}
                            </p>
                          </div>
                          <div className="d-flex align-items-end">
                            <div className="w-50">
                              <p className="fs-16 font-weight-bold line-height-23 text-grey-1c pr-1 mb-0">
                                Received date
                              </p>
                              <p className="fs-14 line-height-20 text-grey-1c mb-0">
                                {receivedDate !== null
                                  ? moment(receivedDate).format("DD MMM, YYYY")
                                  : "-"}
                              </p>
                            </div>
                            <div className="w-50">
                              <p className="fs-16 font-weight-bold line-height-23 text-grey-1c pr-1 mb-0 text-right">
                                Award name
                              </p>
                              <p className="fs-14 line-height-20 text-grey-1c mb-0 text-right">
                                {awardName !== null
                                  ? getDescriptionData(awardName, 20)
                                  : "-"}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex justify-content-center">
                          <p
                            className="text-green-18 font-weight-bold cursor-pointer fs-16 mb-0"
                            onClick={() =>
                              history.push(`/fpo/viewDetails/awards&${awardId}`)
                            }
                          >
                            {"View more"}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>{" "}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getAcademy,
    },
    dispatch
  );
};
let component = AwardsClass;

export const Awards = connect(null, mapDispatchToProps)(component);
