import React, { Component } from "react";
import moment from "moment";
import { history } from "service/helpers";
import { getDescriptionData } from "service/helperFunctions";
export class GstStrip extends Component {
  render() {
    const { id, licenseNumber, validityDate, OriginalLicense, village } =
      this.props.payload;

    let { editAccess } = this.props;
    return (
      <div className="container-sa license-strip shadow rounded mb-3">
        <div className="row align-items-center h-100 px-2">
          <div className="col-3">
            <div className="fs-18 fw-600">
              {licenseNumber ? licenseNumber : "-"}
            </div>
            <div className="text-black-50">GSTN</div>
          </div>
          <div className="col-3">
            <div className="fs-18 fw-600">
              {validityDate ? moment(validityDate).format("DD MMMM YYYY") : "-"}
            </div>
            <div className="text-black-50">Validity date</div>
          </div>
          <div className="col-3">
            <div className="fs-18 fw-600">
              {village ? getDescriptionData(village, 25) : "-"}
            </div>
            <div className="text-black-50">Village</div>
          </div>
          <div className="col-2">
            <div className="fs-18 fw-600">
              {OriginalLicense ? OriginalLicense : "-"}
            </div>
            <div className="text-black-50">Original licence</div>
          </div>
          <div className="col-1 d-flex">
            <div
              className="text-black-50 cursor-pointer mr-4"
              onClick={() => history.push(`/fpo/compliance/gst/${id}`)}
            >
              {"View"}
            </div>
            {editAccess && (
              <div
                className="text-black-50 cursor-pointer"
                onClick={() => history.push(`/fpo/edit-gst/${id}`)}
              >
                {"Edit"}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
