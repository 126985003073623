import React, { forwardRef } from "react";

const RMInput = forwardRef((props, ref) => {
  const {
    type = "text",
    name,
    value,
    placeholder = "",
    className = "",
    onChange,
    label = "",
    max = null,
    onKeyPress,
    allowOnlyText = false,
    allowOnlyNumber = false,
    allowDecimal = false,
    disabled = false,
    isRequired = false,
    readOnly = false,
  } = props;
  const requiredClass = "text-danger font-weight-bold";
  const formControl = "form-control w-100";

  const allowNumberOnly = (event, decimal = false) => {
    const numberRegex = new RegExp("^[0-9]+$");
    const decimalRegex = new RegExp(/^\d*\.?\d*$/);
    const regex = decimal ? decimalRegex : numberRegex;
    const key = String.fromCharCode(
      !event.charCode ? event.which : event.charCode
    );
    if (!regex.test(key)) {
      event.preventDefault();
      return false;
    }
  };

  const allowTextOnly = (event) => {
    const regex = new RegExp("^[A-Za-z ]+$");
    const key = String.fromCharCode(
      !event.charCode ? event.which : event.charCode
    );
    if (!regex.test(key)) {
      event.preventDefault();
      return false;
    }
  };

  return (
    <div className="mb-2">
      <label>
        {label}
        {isRequired && <span className={requiredClass}>*</span>}
      </label>
      <input
        type={type}
        name={name}
        value={value}
        placeholder={placeholder}
        className={`${formControl} ${className}`}
        onChange={onChange}
        onKeyPress={(e) =>
          allowOnlyNumber
            ? allowNumberOnly(e, allowDecimal)
            : allowOnlyText
            ? allowTextOnly(e)
            : onKeyPress
        }
        maxLength={max}
        disabled={disabled}
        readOnly={readOnly}
        ref={ref}
        required={isRequired}
      />
    </div>
  );
});

export default RMInput;
