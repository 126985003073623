import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { history } from "service/helpers/index";
import "./style.scss";
import moment from "moment";
import { Modal, ModalBody } from "reactstrap";
import bod from "assets/images/members/bod.svg";
import { NormalInput, NormalTextArea, NormalButton } from "component/common";
import {
    getStageTwoList,
    sellRequestDetail,
    stageTwoAction,
    getMarketCentreList,
    addRecommentForm,
    addSellRequest
} from "action/SellrequestAct";
import { CommonPageLoader } from "component/common";


const arrow_left_white = require("assets/svg/add_member/arrow_left_white.svg");

export class DealClosureClass extends Component {
    constructor() {
        super();
        this.state = {
            isOpen: false,
            detail: {},
            list: [],
            isLoading: false,
            otherProcurement: 0,
            transportCost: 0,
            loadUnloadCosts: 0,
            spillageWastageCosts: 0,
            otherTaxes: 0,
            govCost: 0,
            price_offered: 0,
            totalProcurimentPrice: 0,
            procurementPerUnit: 0,
            status0Array: [],
            procurementPrice: 0,
            farmers: 0,
            totalcost: 0,
            reason: '',
            latestPrice: {},
            commodityName: '',
            unit: "",
            quantity: 0,
            taxOpen: false,
            buyerName: "Buyer 1",
            payment: 0,
            frequency: ''
        }
    }
    // Lifecycles
    componentDidMount() {
        this.stagetwoApi();
        this.detailApi();
    }

    detailApi() {
        let { id } = this.props;
        this.props.sellRequestDetail(id).then((data) => {
            this.setState({
                detail: data[0],
                procurementPerUnit: data[0]?.promised_procurement_price,
                farmers: data[0]?.number_of_farmers,
                commodityName: data[0]?.commodity_name,
                unit: data[0]?.unit_of_measurement_name,
                frequency: data[0]?.frequency_of_delivery,
            });
            this.props.getMarketCentreList({
                "commodityName": data[0]?.commodity_name,
                "districtName": data[0]?.districtName,
            }).then((resp) => {
                var latestPrice = resp.filter(list => list.Market_Centre === data[0]?.fpo_destination_market);
                if (latestPrice.length > 0) {
                    this.setState({ latestPrice: latestPrice[0] });
                } else {
                    this.setState({ latestPrice: {} });
                }
            });

        });
    }
    // Functions
    stagetwoApi() {
        let { id } = this.props;
        this.setState({ isLoading: true });
        this.props.getStageTwoList(id).then((data) => {
            this.setState({
                list: data,
                isLoading: false,
            });
            var status0Array = [];
            if (data[data.length - 1]) {
                status0Array.push(data[data.length - 1]);
            } else {
                this.setState({
                    list: [],
                });
            }

            if (status0Array[0] && status0Array[0]?.fpo_sell_request_buyer_cost) {
                var count = status0Array[0]?.fpo_sell_request_buyer_cost;
                this.setState({
                    status0Array: status0Array[0],
                    otherProcurement: count?.procurement_other_cost,
                    transportCost: count?.logistic_transport_cost,
                    loadUnloadCosts: count?.logistic_loaded_unloaded_cost,
                    spillageWastageCosts: count?.logistic_wastage_cost,
                    otherTaxes: count?.logistic_tax_charge,
                    govCost: count?.logistic_per_unit_redeemed_by_govt,
                    price_offered: status0Array[0]?.price_offered,
                    quantity: status0Array[0]?.total_quantity,
                    payment: status0Array[0]?.expected_payment_after
                });
                setTimeout(() => {
                    this.setState({
                        procurementPrice: this.getProcurementsCosts(),
                        totalcost: this.getTotalCosts(),
                    });
                }, 2000);
            }
        });
    }

    // Approve Decline api logistic_wastage_cost
    action(type) {
        this.setState({ isOpen: false, taxOpen: false, isLoading: true });
        this.props.stageTwoAction({
            "fpo_sell_request_buyer_id": this.state.status0Array?.id,
            "status": type,
            "price_offered_per_unit": this.state.price_offered || 0,
            "fpo_procurement_cost_per_unit": this.state.procurementPrice || 0,
            "total_farmer_engaged": this.state.farmers || 0,
            "fpo_margin_per_unit": this.state.totalcost || 0,
            "cancel_reason": this.state.reason || '',
            'buyer_name': this.state.buyerName,
            'commodity': this.state.commodityName,
            "quantity": this.state.quantity,
            "unit": this.state.unit,
            "frequency": this.state.frequency,
            "expected_payment_after": this.state.payment
        }).then((data) => {
            this.setState({ isLoading: false });
            history.push("/rm/sell-request");
        });
    }

    handleInput = ({ target: { name, value } }) => {
        this.setState({
            [name]: value,
        });
    }

    numberWithDecimal(type) {
        return (isNaN(type) === false && type != null && type != '') ? parseInt(type).toLocaleString('en-IN', { maximumFractionDigits: 2 }) : 0;
    }

    getTotalCosts = () => {
        return (this.getsellingCosts() - this.getLogisticsCosts() - this.getProcurementsCosts()).toFixed(2);
    }

    getProcurementsCosts() {
        var value = 0;
        value = (this.state.detail?.promised_procurement_price || 0) + Number(this.state.otherProcurement);
        return value.toFixed(2);
    }

    getLogisticsCosts() {
        var value = 0;
        value = (Number(this.state.transportCost) + Number(this.state.loadUnloadCosts) + Number(this.state.spillageWastageCosts) + Number(this.state.otherTaxes)) - Number(this.state.govCost);
        return value.toFixed(2);
    }

    getsellingCosts() {
        return Number(this.state.status0Array?.price_offered).toFixed(2);
    }


    render() {
        const {
            isOpen,
            list,
            isLoading,
            detail,
            procurementPrice,
            farmers,
            totalcost,
            price_offered,
            status0Array,
            reason,
            latestPrice,
            commodityName,
            unit,
            quantity,
            taxOpen,
            buyerName,
            payment,
            frequency
        } = this.state;
        return (

            <div className="add-re-scr dealclosure">
                <div className="container-sa position-relative mt-4">

                    {/* INFO SECTION */}
                    <div className="add-form-section">
                        <div className="row items-center justify-between">
                            <h2 className="m-0 fs-18 mt-0 font-weight-bold">Stage 3: <span className="green-txt">Deal Closure</span></h2>
                            <div className="inner-bg-sec text-center">
                            </div>
                            <div className=" pr-0">
                                <div onClick={() => history.push("/rm/sell-request")} className="text-right d-flex align-items-center justify-content-end">
                                    <p className="btn btn-secondary btn-sm add-member-text-back mt-0 mb-0 cursor-pointer d-flex align-items-center justify-content-center w-150">
                                        <img className="mr-2" src={arrow_left_white} alt="name" width="15" />
                                        Back
                                    </p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <>
                    {!isLoading && (list?.length === 0) ? (
                        <p className="py-4 mb-0 text-black-50 fs-22 line-height-25 text-center">
                            No list found
                        </p>
                    ) : !isLoading ? (
                        <>
                            {list?.length > 0 ? (
                                <>
                                    <div className="container-sa position-relative mt-3 rht-frm">
                                        <div className="tableresponsive">
                                            <table className="table-bordered step-two-table">
                                                <thead>
                                                    <tr>
                                                        <th width="10%"></th>
                                                        <th width="12%">Commodity Name</th>
                                                        <th width="8%">Quantity</th>
                                                        <th width="8%">Unit</th>
                                                        <th width="12%">Price Offered per Unit</th>
                                                        <th width="12%">FPO Procurement Costs per Unit</th>
                                                        <th width="12%">Total Farmers Engaged</th>
                                                        <th width="12%">FPO Margins per Unit</th>
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <div className="w-250px">
                                                                <h6 className="mb-0"><b>Buyer 1</b></h6>
                                                                <span className="d-flex align-items-center mt-2">
                                                                    <img src={bod} alt="bod" className="w-6" />
                                                                    <b className="txtgreen ml-2">FPO Approved</b>
                                                                </span>
                                                            </div>
                                                        </td>
                                                        <td>{commodityName}</td>
                                                        <td>
                                                            <input
                                                                label=""
                                                                type="number"
                                                                className="form-control w-100px"
                                                                value={quantity}
                                                                name="quantity"
                                                                onChange={this.handleInput}
                                                                required={true}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                label=""
                                                                type="text"
                                                                className="form-control w-100px"
                                                                name="unit"
                                                                value={unit}
                                                                onChange={this.handleInput}
                                                                required={true}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                label=""
                                                                type="number"
                                                                className="form-control"
                                                                value={price_offered}
                                                                name="price_offered"
                                                                onChange={this.handleInput}
                                                                required={true}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                label=""
                                                                type="number"
                                                                className="form-control"
                                                                name="procurementPrice"
                                                                value={procurementPrice}
                                                                onChange={this.handleInput}
                                                                required={true}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                label=""
                                                                type="number"
                                                                className="form-control"
                                                                name="farmers"
                                                                value={farmers}
                                                                onChange={this.handleInput}
                                                                required={true}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                label=""
                                                                type="number"
                                                                className="form-control"
                                                                name="totalcost"
                                                                value={totalcost}
                                                                onChange={this.handleInput}
                                                                required={true}
                                                            />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>


                                        <div className="mt-4 fbobuyerwrp w-100 inline-block">
                                            <table className="table-bordered step-two-table border-0 w-50per flright">
                                                <thead>
                                                    <tr className="active">
                                                        <th><center><h3 className="mb-0">Quantity Expectations</h3></center></th>
                                                        <th><center><h3 className="mb-0">Payment Timeline</h3></center></th>
                                                        <th><center><h3 className="mb-0">Mandi Prices</h3></center></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td className="lightbg" colSpan="3">FPO (Source)</td>
                                                    </tr>
                                                    <tr>
                                                        <td><p className="mb-0 valuetxt">{detail?.existing_stock_level}<small className="">{detail?.custom_measurement === '' ? detail?.unit_of_measurement_name : detail?.custom_measurement}</small></p></td>
                                                        <td><p className="mb-0 valuetxt">{detail?.expected_timeline_for_date_of_procurement}<small className="">{detail?.expected_timeline_for_date_of_procurement_type}</small></p></td>
                                                        <td>
                                                            {
                                                                latestPrice?.Modal_Price ? <p className="mb-0 valuetxt">₹ {latestPrice?.Modal_Price}<small className="">({moment(latestPrice?.Date).format("DD-MM-YYYY")})</small></p>
                                                                    : <p className="mb-0 valuetxt">-</p>}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="lightbg" colSpan="3">Buyer (Destination)</td>
                                                    </tr>
                                                    <tr>
                                                        <td><p className="mb-0 valuetxt">{status0Array?.total_quantity}<small className="">{detail?.custom_measurement === '' ? detail?.unit_of_measurement_name : detail?.custom_measurement}</small></p></td>
                                                        <td><p className="mb-0 valuetxt">{status0Array?.expected_payment_after}<small className="">{status0Array?.expected_payment_after_type}</small></p></td>
                                                        <td>
                                                            {
                                                                status0Array?.modelPrice != '' && status0Array?.modelPrice != 0 ? <p className="mb-0 valuetxt">₹ {status0Array?.modelPrice}<small className="">{status0Array?.modelPriceDate != '' ? '(' + moment(status0Array?.modelPriceDate).format("DD-MM-YYYY") + ')' : ''}</small></p>
                                                                    : <p className="mb-0 valuetxt">-</p>}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                        </div>

                                        <div className="step-two-table mb-4 inline-block">

                                            <div className="col-12 edit_profile_btn w-50per flright text-right">
                                                <div className="w-100 " onClick={() => this.setState({ taxOpen: true })}>
                                                    <button className="btn cursor-pointer  mainbg-btn px-3 py-2 mx-auto fs-14 line-height-26">Deal Done</button>
                                                </div>
                                                <div className="w-100 " onClick={() => this.setState({ isOpen: true })}>
                                                    <button className="btn btn-decline cursor-pointer  mainbg-btn px-3 py-2  mx-auto fs-14 line-height-26">Deal Cancelled</button>
                                                </div>
                                            </div>

                                        </div>


                                    </div>
                                </>
                            ) : null}
                        </>
                    ) : (
                        <>
                            {/* Loader */}
                            <CommonPageLoader />
                        </>
                    )}
                </>
                {/* tax popup */}
                <Modal isOpen={taxOpen === true} className={'modal-dialog-centered upload-popup-box'} size="lg">
                    <ModalBody className="p-4 reasonpopup">
                        <div className="flex justify-between">
                            <p className="recommend-popup-heading">
                                Enter Actual Txn Details
                            </p>
                            <i className="icon-close fs-16 cursor-pointer"
                                onClick={() => this.setState({ taxOpen: false })} ></i>
                        </div>
                        <hr />

                        <div className="grid grid-cols-2 gap-4 txnwrp mb-20">

                            <div className="relative">
                                <label className="mb-2">Buyer Name:</label>
                                <NormalInput
                                    label=""
                                    type="text"
                                    value={buyerName}
                                    name="buyerName"
                                    onChange={this.handleInput}
                                    isHalfWidth={false}
                                    isFullWidth={false}
                                    required={true}
                                />
                            </div>

                            <div className="relative">
                                <label>Commodity:</label>
                                <NormalInput
                                    label=""
                                    type="text"
                                    value={commodityName}
                                    name="commodityName"
                                    onChange={this.handleInput}
                                    isHalfWidth={false}
                                    isFullWidth={false}
                                    required={true}
                                />
                            </div>

                            <div className="relative">
                                <label>Quantity:</label>
                                <NormalInput
                                    label=""
                                    type="text"
                                    value={quantity}
                                    name="quantity"
                                    onChange={this.handleInput}
                                    isHalfWidth={false}
                                    isFullWidth={false}
                                    required={true}
                                />
                            </div>

                            <div className="relative">
                                <label>Unit:</label>
                                <NormalInput
                                    label=""
                                    type="text"
                                    value={unit}
                                    name="unit"
                                    onChange={this.handleInput}
                                    isHalfWidth={false}
                                    isFullWidth={false}
                                    required={true}
                                />
                            </div>

                            <div className="relative">
                                <label>Frequency:</label>
                                <NormalInput
                                    label=""
                                    type="text"
                                    value={frequency}
                                    name="frequency"
                                    onChange={this.handleInput}
                                    isHalfWidth={false}
                                    isFullWidth={false}
                                    required={true}
                                />
                            </div>

                            <div className="relative">
                                <label>Farmers Engaged:</label>
                                <NormalInput
                                    label=""
                                    type="text"
                                    value={farmers}
                                    name="farmers"
                                    onChange={this.handleInput}
                                    isHalfWidth={false}
                                    isFullWidth={false}
                                    required={true}
                                />
                            </div>

                            <div className="relative">
                                <label>Price Offered:</label>
                                <NormalInput
                                    label=""
                                    type="text"
                                    value={price_offered}
                                    name="price_offered"
                                    onChange={this.handleInput}
                                    isHalfWidth={false}
                                    isFullWidth={false}
                                    required={true}
                                />
                            </div>

                            <div className="relative">
                                <label>FPO Costs:</label>
                                <NormalInput
                                    label=""
                                    type="text"
                                    value={procurementPrice}
                                    name="procurementPrice"
                                    onChange={this.handleInput}
                                    isHalfWidth={false}
                                    isFullWidth={false}
                                    required={true}
                                />
                            </div>

                            <div className="relative">
                                <label>FPO Margins:</label>
                                <NormalInput
                                    label=""
                                    type="text"
                                    value={totalcost}
                                    name="totalcost"
                                    onChange={this.handleInput}
                                    isHalfWidth={false}
                                    isFullWidth={false}
                                    required={true}
                                />
                            </div>

                            <div className="relative">
                                <label>Actual Payment Timeline:</label>
                                <NormalInput
                                    label=""
                                    type="text"
                                    value={payment}
                                    name="payment"
                                    onChange={this.handleInput}
                                    isHalfWidth={false}
                                    isFullWidth={false}
                                    required={true}
                                />
                            </div>

                        </div>

                        <hr />
                        <div className="d-flex w-100 newrecpop mt-20">
                            <NormalButton
                                label="Save"
                                mainbg={true}
                                onClick={() => this.action(3)}
                                className="py-2 fs-14 line-height-26 mr-2 submit-button"
                            />
                        </div>



                    </ModalBody>
                </Modal>

                {/* Reason popup */}
                <Modal isOpen={isOpen === true} className={'modal-dialog-centered upload-popup-box'} size="lg">
                    <ModalBody className="p-4 reasonpopup">
                        <div className="flex justify-between">
                            <p className="recommend-popup-heading">
                                Reason
                            </p>
                            <i className="icon-close fs-16 cursor-pointer"
                                onClick={() => this.setState({ isOpen: false })} ></i>
                        </div>
                        <hr />

                        <div className="relative mb-20 sup-width">
                            <label>Enter Reason: <span className="text-danger font-weight-bold pl-0 fs-18">*</span></label>
                            <div className="w-100">
                                <NormalTextArea
                                    label="Enter Reason"
                                    onChange={this.handleInput}
                                    value={reason}
                                    className="form-control"
                                    name="reason"
                                    isHalfWidthForMemberProfile={true}
                                    rows="6"
                                />
                            </div>
                        </div>

                        <div className="d-flex w-100 newrecpop">
                            <NormalButton
                                label="Submit"
                                mainbg={true}
                                disabled={this.state.reason == ''}
                                onClick={() => this.action(4)}
                                className="py-2 fs-14 line-height-26 mr-2 submit-button"
                            />
                        </div>



                    </ModalBody>
                </Modal>

            </div>


        )

    }
}
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getStageTwoList,
            sellRequestDetail,
            stageTwoAction,
            getMarketCentreList,
            addRecommentForm,
            addSellRequest
        },
        dispatch
    );
};
let component = DealClosureClass;
export const DealClosure = connect(
    null,
    mapDispatchToProps
)(component);