import React, { Component } from "react";
import { AddAboutUs } from "component/Aboutus/AddAboutUs";
import { Navbar } from "component/common";
export class Addform extends Component {
  componentDidMount() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }
  render() {
    return (
      <div>
        <div className="normal-header">{/* <Navbar /> */}</div>
        <AddAboutUs id={this.props.match.params.id} />
      </div>
    );
  }
}
