import React, { Component } from "react";
import BackButton from "component/common/BackButton";
import RMKYMNew from "component/RM/RMKYMNew/index";
export class RMKYMNewPage extends Component {
  componentDidMount() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  render() {
    return (
      <div>
        <div className="inner-header">
          <div className="h-100 w-100">
            <p className="rm_title d-flex justify-content-center align-items-center h-100 w-100 text-white fs-32 font-weight-bold">
              KYM Survey - Mapped FPOs List
            </p>
          </div>
        </div>
        <BackButton />
        <RMKYMNew module="rm" />
      </div>
    );
  }
}
