import { forum } from "service/apiVariables";
import { addQuery } from "service/helperFunctions";
import { api } from "service/api";
import { Toast } from "service/toast";
import { academyType } from "service/actionType";

export const createForum = (body) => (dispatch, getState, { api, Toast }) => {
  return new Promise((resolve, reject) => {
    api({ ...forum.create, body })
      .then(({ data, message }) => {
        Toast({ type: "success", message });
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//update Forum
export const updateForum = (body, id) => (
  dispatch,
  getState,
  { api, Toast }
) => {
  let { updateForum } = forum;
  updateForum.id = id;
  return new Promise((resolve, reject) => {
    api({ ...forum.updateForum, body })
      .then(({ data, message }) => {
        Toast({ type: "success", message });
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

export const deleteForum = (id) => (dispatch, getState) => {
  forum.deleteForum.id = id;
  return new Promise((resolve, reject) => {
    api({ ...forum.deleteForum })
      .then(({ data }) => {
        resolve(data);
        Toast({
          type: "success",
          message: "Forum deleted successfully",
          time: 5000,
        });
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};
export const forumList = (query) => (dispatch, getState, { api, Toast }) => {
  return new Promise((resolve, reject) => {
    addQuery(query, forum.forumList);
    api({ ...forum.forumList })
      .then((data) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};
export const yourForumList = () => (dispatch, getState, { api, Toast }) => {
  return new Promise((resolve, reject) => {
    api({ ...forum.yourForumList })
      .then((data) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

export const forumComment = (body) => (dispatch, getState, { api, Toast }) => {
  return new Promise((resolve, reject) => {
    api({ ...forum.forumComment, body })
      .then(({ data, message }) => {
        Toast({ type: "success", message });

        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};
export const deleteComment = (id) => (dispatch, getState) => {
  forum.deleteComment.id = id;
  return new Promise((resolve, reject) => {
    api({ ...forum.deleteComment })
      .then(({ data, message }) => {
        resolve(data);
        Toast({ type: "success", message, time: 5000 });
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};
export const getForumComment = (id) => (dispatch, getState, { api, Toast }) => {
  let { getForumComment } = forum;
  getForumComment.id = id ? id : "";
  return new Promise((resolve, reject) => {
    api({ ...forum.getForumComment, id })
      .then((data) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};
export const getForumComments = (id, query) => (
  dispatch,
  getState,
  { api, Toast }
) => {
  let { getForumComments } = forum;
  getForumComments.id = id ? id : "";
  return new Promise((resolve, reject) => {
    addQuery(query, forum.getForumComments);

    api({ ...forum.getForumComments, id })
      .then((data) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

export const forumLike = (body) => (dispatch, getState, { api, Toast }) => {
  return new Promise((resolve, reject) => {
    api({ ...forum.forumLike, body })
      .then(({ data, message }) => {
        Toast({ type: "success", message });

        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//forum Un Like
export const forumUnLike = (body) => (dispatch, getState, { api, Toast }) => {
  return new Promise((resolve, reject) => {
    api({ ...forum.forumUnLike, body })
      .then(({ data, message }) => {
        Toast({ type: "success", message });

        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//comment Like
export const commentLike = (body) => (dispatch, getState, { api, Toast }) => {
  return new Promise((resolve, reject) => {
    api({ ...forum.commentLike, body })
      .then(({ data, message }) => {
        Toast({ type: "success", message });

        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//comment Un Like
export const commentUnLike = (body) => (dispatch, getState, { api, Toast }) => {
  return new Promise((resolve, reject) => {
    api({ ...forum.commentUnLike, body })
      .then(({ data, message }) => {
        Toast({ type: "success", message });

        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//get Popular Topics
export const getPopularTopics = () => (dispatch, getState, { api, Toast }) => {
  return new Promise((resolve, reject) => {
    api({ ...forum.getPopularTopics })
      .then((data) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//get Category Details
export const getCategoryDetails = () => (
  dispatch,
  getState,
  { api, Toast }
) => {
  return new Promise((resolve, reject) => {
    api({ ...forum.getCategoryDetails })
      .then((data) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//get Notification List
export const getNotificationList = (query) => (
  dispatch,
  getState,
  { api, Toast }
) => {
  return new Promise((resolve, reject) => {
    addQuery(query, forum.getNotificationList);
    api({ ...forum.getNotificationList })
      .then((data) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//read all Notification
export const readallNotification = () => (
  dispatch,
  getState,
  { api, Toast }
) => {
  return new Promise((resolve, reject) => {
    api({ ...forum.readallNotification })
      .then((data) => {
        resolve(data);
        Toast({ type: "success", message: "Notification readed successfully" });
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

export const categoryList = (id) => (dispatch, getState, { api, Toast }) => {
  return new Promise((resolve, reject) => {
    api({ ...forum.categoryList, id })
      .then((data) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//save Forum
export const saveForum = (body) => (dispatch, getState, { api, Toast }) => {
  return new Promise((resolve, reject) => {
    api({ ...forum.saveForum, body })
      .then(({ data, message }) => {
        Toast({ type: "success", message });
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//get saved Forum By Id
export const getSavedForumById = (id) => (
  dispatch,
  getState,
  { api, Toast }
) => {
  let { getSavedForumById } = forum;
  getSavedForumById.id = id;
  return new Promise((resolve, reject) => {
    api({ ...forum.getSavedForumById })
      .then((data) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//get Forum By Id
export const getForumById = (id) => (dispatch, getState, { api, Toast }) => {
  let { getForumById } = forum;
  getForumById.id = id;
  return new Promise((resolve, reject) => {
    api({ ...forum.getForumById })
      .then((data) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//get Forum Details Home
export const getForumDetailsHome = () => (
  dispatch,
  getState,
  { api, Toast }
) => {
  return new Promise((resolve, reject) => {
    api({ ...forum.getForumDetailsHome })
      .then((data) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//get Saved Forum List
export const getSavedForumList = (query) => (
  dispatch,
  getState,
  { api, Toast }
) => {
  return new Promise((resolve, reject) => {
    addQuery(query, forum.getSavedForumList);
    api({ ...forum.getSavedForumList })
      .then((data) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

export const categoryListChange = (payload) => (dispatch) => {
  dispatch({ type: academyType.categoryList, payload });
};
