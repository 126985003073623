import React, { Component } from "react";
import { history } from "service/helpers";
import moment from "moment";
import userProfile from "assets/images/default-profile-img.png";
export class BodStaffStrip extends Component {
  render() {
    let { memberDetail = {}, isStaff = false, id } = this.props;

    let {
      firstName = "",
      lastName = "",
      profileImageUrl = "",
      gender = "",
      din = "",
      AppointedOn = "",
      designation = "",
      dateOfJoining = "",
      employeeId = "",
      experience = "",
      educationmaster = [],
      bodID = "",
      staffId = "",
      mobileNumber,
    } = memberDetail;

    let { viewAccess = false, editAccess = false } = this.props;

    return (
      <>
        <div className="member-strip shadow rounded p-3 d-flex align-items-center w-100 mb-3">
          <div className="row w-100">
            <div className="col-3">
              <div className="row">
                <div className="col-3 pr-0">
                  <img
                    src={profileImageUrl ? profileImageUrl : userProfile}
                    className="member-profile"
                    alt="member_profile"
                  />
                </div>
                <div className="col-9 pr-0">
                  <div className="d-flex flex-column justify-content-center h-100">
                    <div className="member-card-font-name">
                      {firstName} {lastName}
                    </div>
                    {/* <div className="member-card-font-number">
                      {employeeId ? employeeId : bodID ? bodID : ""}
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-1">
              <div className="d-flex flex-column justify-content-center h-100">
                <div className="member-card-font-content mb-2">
                  {gender && gender !== "" ? gender : "-"}
                </div>
                <div className="member-card-font-heading">Gender</div>
              </div>
            </div>
            <div className="col-2">
              <div className="d-flex flex-column justify-content-center h-100">
                <div className="member-card-font-content mb-2">
                  {designation ? designation : "-"}
                </div>
                <div className="member-card-font-heading">{"Designation"}</div>
              </div>
            </div>
            <div className="col-2">
              <div className="d-flex flex-column justify-content-center h-100">
                <div className="member-card-font-content mb-2">
                  {mobileNumber ? mobileNumber : "-"}
                </div>
                <div className="member-card-font-heading">
                  {"Mobile Number"}
                </div>
              </div>
            </div>
            {/* <div className="col">
                  <div className="d-flex flex-column justify-content-center h-100">
                    <div className="member-card-font-content mb-2">
                      {din ? din : "-"}
                    </div>
                    <div className="member-card-font-heading">{"DIN"}</div>
                  </div>
                </div> */}
            <div className="col-2">
              <div className="d-flex flex-column justify-content-center h-100">
                <div className="member-card-font-content mb-2">
                  {isStaff
                    ? dateOfJoining
                      ? moment(dateOfJoining).format("DD MMMM YYYY")
                      : "-"
                    : AppointedOn
                    ? moment(AppointedOn).format("DD MMMM YYYY")
                    : "-"}
                </div>
                <div className="member-card-font-heading">
                  {isStaff ? "Date of Joining" : "Date of appointment"}
                </div>
              </div>
            </div>
            {/* <div className="col-3">
              <div className="d-flex flex-column justify-content-center h-100">
                <div className="member-card-font-content mb-2">
                  {educationmaster !== null
                    ? educationmaster.length !== 0 &&
                      educationmaster?.qualificationType
                    : "-"}
                </div>
                <div className="member-card-font-heading">{"Education"}</div>
              </div>
            </div>
            <div className="col-1">
              <div className="d-flex flex-column justify-content-center h-100">
                <div className="member-card-font-content mb-2">
                  {experience ? experience : "-"}
                </div>
                <div className="member-card-font-heading">{"Experience"}</div>
              </div>
            </div> */}
            <div className="col-2">
              <div className="d-flex align-items-center justify-content-center h-100">
                {viewAccess && (
                  <div
                    className="fs-16 line-height-19 text-black-50 cursor-pointer pr-4"
                    onClick={() => {
                      id
                        ? isStaff
                          ? history.push(`/rm/fpo/view-staff/${id}/${staffId}`)
                          : history.push(`/rm/fpo/view-bod/${id}/${bodID}`)
                        : isStaff
                        ? history.push(`/fpo/view-staff/${staffId}`)
                        : history.push(`/fpo/view-bod/${bodID}`);
                    }}
                  >
                    {"View"}
                  </div>
                )}
                {editAccess && (
                  <div
                    className="fs-16 line-height-19 text-black-50 cursor-pointer"
                    onClick={() =>
                      isStaff
                        ? history.push(`/fpo/add-staff/${staffId}`)
                        : history.push(`/fpo/add-bod/${bodID}`)
                    }
                  >
                    {"Edit"}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
