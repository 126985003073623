import React, { Component } from "react";
import { NormalInput, NormalTextArea, NormalButton } from "component/common";
import SimpleReactValidator from "simple-react-validator";
import { Modal } from "reactstrap";
import "./feedback.scss";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { addFeedback } from "action/FeedbackAct";
export class AddFeedbackClass extends Component {
  constructor() {
    super();
    this.state = {
      email: "",
      contactNumber: "",
      feedback1: "",
    };
  }

  //validation
  validator = new SimpleReactValidator({
    element: (message) => (
      <span className="error-message text-danger fs-14">{message}</span>
    ),
    autoForceUpdate: this,
  });

  //handle input
  handleInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  //handle submit
  handleSubmit = () => {
    if (this.validator.allValid()) {
      const { email, contactNumber, feedback1 } = this.state;
      const body = {
        emailId: email,
        contactNo: contactNumber,
        improvementsText: feedback1,
      };
      this.props.addFeedback(body).then(() => {
        this.handleClose();
      });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };
  //close popup
  handleClose = () => {
    this.validator.hideMessages();
    this.setState(
      {
        email: "",
        contactNumber: "",
        feedback1: "",
      },
      () => {
        this.props.toggle();
      }
    );
  };

  render() {
    const { email, contactNumber, feedback1 } = this.state;
    const { open } = this.props;
    return (
      <Modal
        isOpen={open}
        backdrop={true}
        keyboard={true}
        modalClassName="feedback-center"
      >
        <div className="rounded add-feedback bg-grey-f6 ">
          <div className="d-flex justify-content-between">
            <span className="fs-24 fw-800">Leave feedback </span>
            <span
              className="icon-close cursor-pointer"
              onClick={this.handleClose}
            ></span>
          </div>
          <div className="mt-3">
            <p>{"Your valuable feedback would help us serve you better"}</p>
          </div>
          <div className="bg-white pt-2 p-md-3 p-0 mb-4">
            <div className="row">
              <div className="col-md-6 mb-md-0 mb-4">
                <div className="text-grey-5a fw-600">
                  Email id{" "}
                  <span className="text-danger font-weight-bold pl-1 fs-18">
                    {"*"}
                  </span>
                </div>
                <div>
                  <NormalInput
                    type="text"
                    onChange={this.handleInput}
                    value={email}
                    name="email"
                  />
                </div>
                {this.validator.message("Email Id", email, "required|email")}
              </div>
              <div className="col-md-6">
                <div className="text-grey-5a fw-600">
                  {"Contact Number "}
                  <span className="text-danger font-weight-bold pl-1 fs-18">
                    {"*"}
                  </span>
                </div>
                <div>
                  <NormalInput
                    type="text"
                    onChange={this.handleInput}
                    value={contactNumber}
                    name="contactNumber"
                    max={10}
                  />
                </div>
                {this.validator.message(
                  "contact number",
                  contactNumber,
                  "required|phone"
                )}
              </div>
            </div>
          </div>
          <div className="bg-white pt-2 p-3">
            <div className="mb-3 d-flex">
              <div className="feedback-tab-active rounded cursor-pointer mr-2"></div>
            </div>
            <div className="fw-600 mb-2">
              {"Anything that can be improved?"}
            </div>
            <div className="row feedback-text-area">
              <div className="col-12">
                <NormalTextArea
                  name="feedback1"
                  value={feedback1}
                  isHalfWidth={true}
                  onChange={this.handleInput}
                  placeholder="Your feedback"
                />
                {this.validator.message(
                  "Feedback",
                  feedback1,
                  "required|min:7|max:200"
                )}
              </div>
            </div>
            <div className="mt-4">
              <NormalButton
                label={`Submit`}
                mainbg={true}
                className="ml-auto fs-16 line-height-26 px-4 py-2"
                onClick={() => this.handleSubmit()}
              />
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      addFeedback,
    },
    dispatch
  );
};

let component = AddFeedbackClass;

export const AddFeedback = connect(null, mapDispatchToProps)(component);
