import React, { Component } from "react";
import "./events.scss";
import moment from "moment";
import { getDescriptionData } from "service/helperFunctions";
import { history } from "service/helpers";

export class Events extends Component {
  render() {
    let { first = {} } = this.props;
    let { uploadImage = "", publishOn = "", title = "" } = first;
    return (
      <>
        {" "}
        {localStorage.getItem("AuthToken") ? (
          <div
            className="background-news p-4 d-flex align-items-end rounded"
            style={{ backgroundImage: `url('${uploadImage}')` }}
          >
            <div className="row align-items-end w-100 mb-1">
              <div className="col-9 pr-0">
                <div className="fs-16 line-height-28 fw-700 text-white">
                  <p className="fs-32 line-height-46 text-white font-weight-bold mb-1">
                    {"News"}
                  </p>
                  {getDescriptionData(title, 50)}
                </div>
              </div>
              <div className="col-3 pr-0 text-right">
                <div className="text-white fs-16 line-height-23 fw-700">
                  {moment(publishOn).format("MMM Do, YYYY ")}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <div className="view-button">
              <p
                onClick={() => history.push("/landing/contents/newsandevents")}
              >
                {"View all"}
              </p>
            </div>
            <div
              className="background-news d-flex align-items-end rounded"
              style={{ backgroundImage: `url('${uploadImage}')` }}
            >
              <div className="row align-items-end w-100 event-content">
                <div className="col-sm-9 col-12 pr-0 mb-2">
                  <div className="fs-20 line-height-28 fw-400 text-white ">
                    <p className="fs-30 line-height-46 text-white font-weight-bold mb-1">
                      {"NEWS"}
                    </p>
                    {getDescriptionData(title, 50)}
                  </div>
                </div>
                <div className="col-sm-3 col-12 pr-3 text-right mb-4">
                  <div className="text-white fs-16 line-height-23 fw-500">
                    {moment(publishOn).format("MMM Do, YYYY ")}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}
