import React, { Component } from 'react';
import "./RadioGroup.scss";

export const RadioGroup1 = (props) => {
  return (
    <div className="form-group">
      <label>
        <b>{props.title}</b>
        {
          (props?.required) && (
            <span className="text-danger font-weight-bold pl-2 fs-18">
              {"*"}
            </span>
          )
        }
      </label> <br />
      {
        props.groupList.map((obj, key) => {
          return (
            <div key={key} className="d-flex">
              <input className='mt-1' key={key} type="radio" name={props.name} checked={props.selValue === obj.value} value={obj.value} onChange={() => props.onChange(obj.value)} />
              <span className='pl-2'>{obj.label}</span>
            </div>
          );
        })
      }
    </div>
  )
}

export const RadioGroup = (props) => {
  return (
    <div className="form-group normal-radio-group">
      <label className='grading-normal-label'>
        <b>{props.title}</b>
        {
          (props?.required) && (
            <span className="text-danger font-weight-bold pl-2 fs-18">
              {"*"}
            </span>
          )
        }
      </label> <br />
      {
        props.groupList.map((obj, key) => {
          return (
            <div key={key}>
              <input type="radio" name={props.name} checked={props.selValue === obj.value} value={obj.value} onChange={() => props.onChange(obj.value)} className='fpo-grading-tool-radio' id={`${props.name}-${obj.value}`} />
              <label htmlFor={`${props.name}-${obj.value}`} className="grading-radio-label">
                <div className='grading-radio-icon'>
                  <div className='grading-radio-icon-selected'></div>
                </div>
                <div className="pl-2">{obj.label}</div>
              </label>
            </div>
          );
        })
      }
    </div>
  )
}