import React, { Component } from "react";
import placeholder from "assets/images/placeholder.png";
import rightCircle from "assets/images/icon/arrow-right-circle.svg";
import clock from "assets/images/icon/clock.svg";
import { history } from "service/helpers";
import "./TrendingNow.scss";
import { getDescriptionData } from "service/helperFunctions";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination } from "swiper";
import favorite from "../../../assets/images/academy/heart_white.svg";
import play from "../../../assets/images/icon/play.svg";

export class TrendingNow extends Component {
  constructor() {
    super();
    SwiperCore.use([Navigation, Pagination]);
  }

  render() {
    const params = {
      slidesPerView: 1,
      spaceBetween: 30,
      navigation: true,
      breakpoints: {
        500: {
          slidesPerView: 2,
        },
        768: {
          slidesPerView: 4,
        },
      },
    };
    let {
      title = "Trending now",
      trendingCourseData = [],
      favorites,
    } = this.props;
    return (
      <>
        <section className="trending-now-section slider-top-right">
          <div className="container-sa">
            <div className="d-flex justify-content-between justify-content-center">
              <div>
                <h4 className="title">{title} </h4>
              </div>
              <div className="d-flex justify-content-end mr-5 pr-5">
                <p
                  className="my-3 text-black-50 fs-18 text-underline line-height-23 fw-700 cursor-pointer"
                  onClick={() => history.push("/academy/allcourse")}
                >
                  {"View all"}
                </p>
              </div>
            </div>
            <div className="trending-now-list">
              <Swiper {...params}>
                {trendingCourseData.map((data, index) => (
                  <SwiperSlide key={index + "trending-now"}>
                    <div className="px-1">
                      <div
                        className="trending-now-card"
                        onClick={() =>
                          history.push(`/academy/courses/${data.courseId}`)
                        }
                      >
                        <div className="trending-now-thumb">
                          <div className="play-button-center">
                            <div className="play-button">
                              <img
                                className="play-icon"
                                src={play}
                                alt="play"
                                srcSet=""
                              />
                            </div>
                          </div>

                          {favorites && (
                            <img
                              className="trending-now-thumb-heart"
                              src={favorite}
                              alt="favorite"
                              srcSet=""
                            />
                          )}
                          <img
                            className="trending-now-thumb-img"
                            alt="bannerFileUrl"
                            src={
                              data.thumbnailFileUrl === "bannerFileUrl" ||
                              data.thumbnailFileUrl === null
                                ? placeholder
                                : data.thumbnailFileUrl
                            }
                          />
                        </div>
                        <div className="trending-now-content">
                          <h5> {getDescriptionData(data.title, 25)} </h5>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: getDescriptionData(data.description, 70),
                            }}
                          ></p>
                        </div>
                        <div className="trending-now-action-time">
                          <div className="time-trending-now">
                            <img src={clock} alt="clock" /> {data.durationHours}{" "}
                            hr {data.durationMinutes} min
                          </div>
                          <div className="trending-now-link">
                            <a href="">
                              <img src={rightCircle} alt="rightCircle" />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </section>
      </>
    );
  }
}
