import React from 'react';
import { history } from 'service/helpers';
import { currencyConvert } from 'service/helperFunctions';

function Card(props) {
   const { payload, index, editAccess } = props;
   const { revenueId, businessDetails, fymYear, revenueActivity } = payload;
   return (
      <div
         className={`col-md-3 col-lg-3 col-6 ${
            index % 4 === 3 ? 'pr-md-0' : ''
            } mb-2 mt-3 px-2 ${index % 4 === 0 ? 'pl-md-0' : ''}`}
         onClick={() => {
            editAccess &&
               history.push(`/fpo/edit-revenue/${revenueId}`);
         }}
      >
         <div className='revenue-card rounded shadow cursor-pointer'>
            <div className='row mb-4'>
               <div className='col-sm-12 '>
                  <div className='line-height-21 font-Gilroy text-black-50 fs-16 mb-3'>
                     {'Financial year'}
                  </div>
                  <div className='line-height-21 font-Gilroy-Bold fs-18 text-black-1c font-weight-bold'>
                     {fymYear}
                  </div>
               </div>
            </div>
            <div className='row mb-4'>
               <div className='col-sm-12 '>
                  <div className='line-height-21 font-Gilroy text-black-50 fs-16 mb-3'>
                     {'Activity'}
                  </div>
                  <div className='line-height-21 font-Gilroy-Bold fs-18 text-black-1c font-weight-bold'>
                     {businessDetails.businessName}
                  </div>
               </div>
            </div>
            <div className='row mb-4'>
               <div className='col-sm-12 '>
                  <div className='line-height-21 font-Gilroy text-black-50 fs-16 mb-3'>
                     {'Revenue ( '} <span className='icon-rupee'></span> {' )'}
                  </div>
                  <div className='line-height-21 font-Gilroy-Bold fs-18 text-black-1c font-weight-bold'>
                     {revenueActivity ? currencyConvert(revenueActivity) : '-'}
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
}

export default Card;
