import React, { Component } from "react";
import { Card } from "../Card";
import { Strip } from "../Strip";
import { Pagination, CommonPageLoader } from "component/common";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getAllLicense } from "action/ComplianceAct";
import { getAllLicenseRm } from "action/RmAct";

class CompilanceListClass extends Component {
  state = {
    Licenses: [],
    pageMeta: {},
    page: 1,
    isLoading: false,
  };

  //handle license
  handleLicense = (page) => {
    let { limit, searchValue, filterValue, filterBy, id } = this.props;
    this.setState({ isLoading: true });
    let payload = {
      limit,
      page,
      search: searchValue,
      filterValue,
      filterBy,
    };
    id
      ? this.props.getAllLicenseRm(id, payload).then((data) => {
          this.setState({
            Licenses: data.results,
            pageMeta: data.pageMeta,
            isLoading: false,
          });
        })
      : this.props.getAllLicense(payload).then((data) => {
          this.setState({
            Licenses: data.results,
            pageMeta: data.pageMeta,
            isLoading: false,
          });
        });
  };

  //handle page change
  handlePagination = (page) => {
    this.handleLicense(page);
  };

  //Lifecycles
  componentDidMount() {
    this.handleLicense(1);
  }

  componentDidUpdate(prevProps) {
    if (this.props.activeTab == 1) {
      this.props.limit !== prevProps.limit && this.handleLicense(1);
      this.props.searchValue !== prevProps.searchValue && this.handleLicense(1);
      this.props.filterValue !== prevProps.filterValue && this.handleLicense(1);
    }
    if (prevProps.activeTab != this.props.activeTab) {
      let { activeTab } = this.props;
      if (activeTab == 1) this.handleLicense(1);
    }
  }

  render() {
    let { pageMeta, Licenses, isLoading } = this.state;

    let { editAccess = false } = this.props;
    return (
      <>
        {!isLoading && Licenses.length === 0 ? (
          <p className="py-4 mb-0 text-black-50 fs-22 line-height-25 text-center">
            No data found
          </p>
        ) : !isLoading ? (
          <>
            {Licenses.length != 0 ? (
              <>
                <div className="scroll-x">
                  <div
                    className="d-flex container-sa flex-column  flex-wrap mt-3 px-0 justify-content-center"
                    style={
                      this.props.view === "1" ? { minWidth: "1200px" } : null
                    }
                  >
                    <div className="d-flex flex-wrap com-list-card">
                      {Licenses.map((license, index) => {
                        return this.props.view === "2" ? (
                          <Card
                            payload={license}
                            index={index}
                            key={index}
                            editAccess={editAccess}
                          />
                        ) : (
                          <Strip
                            payload={license}
                            index={index}
                            key={index}
                            editAccess={editAccess}
                          />
                        );
                      })}
                    </div>
                  </div>
                </div>
                {pageMeta.pageCount !== undefined &&
                  pageMeta.pageCount !== 1 && (
                    <Pagination
                      handleChange={this.handlePagination}
                      pageMeta={pageMeta}
                    />
                  )}
              </>
            ) : null}
          </>
        ) : (
          <>
            {/* Loader */}
            <CommonPageLoader />
          </>
        )}
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getAllLicense,
      getAllLicenseRm,
    },
    dispatch
  );
};

let component = CompilanceListClass;

export const CompilanceList = connect(null, mapDispatchToProps)(component);
