import { FIGCRP } from "service/apiVariables";
import { Toast } from "service/toast";
import { api } from "service/api";
import { addQuery } from "service/helperFunctions";
import { FIGCRPType } from "service/actionType";

//get CRP List
export const getCRPList = (query) => (dispatch) => {
  return new Promise((resolve, reject) => {
    addQuery(query, FIGCRP.getCRPList);
    api({ ...FIGCRP.getCRPList })
      .then(({ data }) => {
        dispatch({ type: FIGCRPType.crpMembers, payload: data });
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//get CRP List Rm
export const getCRPListRm = (id, query) => (dispatch) => {
  FIGCRP.getCRPListRm.id = id;
  return new Promise((resolve, reject) => {
    addQuery(query, FIGCRP.getCRPListRm);
    api({ ...FIGCRP.getCRPListRm })
      .then(({ data }) => {
        dispatch({ type: FIGCRPType.crpMembers, payload: data });
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//Add CRP
export const addCRP = (body) => () => {
  return new Promise((resolve, reject) => {
    api({ ...FIGCRP.addCRP, body })
      .then(({ data, message }) => {
        Toast({ type: "success", message });
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//get CRP By Id
export const getCRPById = (id) => () => {
  return new Promise((resolve, reject) => {
    FIGCRP.getCRPById.id = id;
    api({ ...FIGCRP.getCRPById })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//Update CRP
export const updateCRP = (body) => () => {
  return new Promise((resolve, reject) => {
    api({ ...FIGCRP.updateCRP, body })
      .then(({ data, message }) => {
        Toast({ type: "success", message });
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//get FIG List
export const getFIGList = (query) => (dispatch) => {
  return new Promise((resolve, reject) => {
    addQuery(query, FIGCRP.getFIGList);
    api({ ...FIGCRP.getFIGList })
      .then(({ data }) => {
        dispatch({ type: FIGCRPType.figMembers, payload: data });
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//get FIG List Rm
export const getFIGListRm = (id, query) => (dispatch) => {
  FIGCRP.getFIGListRm.id = id;
  return new Promise((resolve, reject) => {
    addQuery(query, FIGCRP.getFIGListRm);
    api({ ...FIGCRP.getFIGListRm })
      .then(({ data }) => {
        dispatch({ type: FIGCRPType.figMembers, payload: data });
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//Add FIG
export const addFIG = (body) => () => {
  return new Promise((resolve, reject) => {
    api({ ...FIGCRP.addFIG, body })
      .then(({ data, message }) => {
        Toast({ type: "success", message });
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//get FIG By Id
export const getFIGById = (id) => () => {
  return new Promise((resolve, reject) => {
    FIGCRP.getFIGById.id = id;
    api({ ...FIGCRP.getFIGById })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//Update FIG
export const updateFIG = (body) => () => {
  return new Promise((resolve, reject) => {
    api({ ...FIGCRP.updateFIG, body })
      .then(({ data, message }) => {
        Toast({ type: "success", message });
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//get Member List for Mapping
export const getMemberListforMapping = (id) => (dispatch) => {
  FIGCRP.getMemberListforMapping.id = id;
  return new Promise((resolve, reject) => {
    api({ ...FIGCRP.getMemberListforMapping })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//add Member To FIG
export const addMemberToFIG = (body) => (dispatch) => {
  return new Promise((resolve, reject) => {
    api({ ...FIGCRP.addMemberToFIG, body })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//add FIG To CRP
export const addFIGToCRP = (body) => (dispatch) => {
  return new Promise((resolve, reject) => {
    api({ ...FIGCRP.addFIGToCRP, body })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

//get FIG List for Mapping
export const getFIGListforMapping = (id) => (dispatch) => {
  FIGCRP.getFIGListforMapping.id = id;
  return new Promise((resolve, reject) => {
    api({ ...FIGCRP.getFIGListforMapping })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

export const deleteFIG = (id) => () => {
  FIGCRP.deleteFIG.id = id;
  return new Promise((resolve, reject) => {
    api({ ...FIGCRP.deleteFIG })
      .then(({ data, message }) => {
        resolve(data);
        Toast({ type: "success", message });
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

export const deleteCRP = (id) => () => {
  FIGCRP.deleteCRP.id = id;
  return new Promise((resolve, reject) => {
    api({ ...FIGCRP.deleteCRP })
      .then(({ data, message }) => {
        resolve(data);
        Toast({ type: "success", message });
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};
