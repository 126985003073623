import React from "react";
import moment from "moment";
import cryptLib from "@skavinvarnan/cryptlib";

export const addQuery = (dataObject, apiObject) => {
  if (!dataObject) {
    return "";
  }

  const keys = [
    "page",
    "limit",
    "search",
    "memberName",
    "fpoName",
    "id",
    "search",
    "companyKycId",
    "eventId",
    "awardId",
    "storyId",
    "filterType",
    "filter",
    "filterBy",
    "filterValue",
    "categoryId",
    "commodityId",
    "forumId",
    "state",
    "stateName",
    "commodity",
    "apmc",
    "language",
    "days",
    "variety",
    "stateOne",
    "stateTwo",
    "apmcOne",
    "mandiId",
    "apmcTwo",
    "month",
    "year",
    "mandiName",
    "fpoId",
    "docId",
    "ids",
    "kymclassificationId",
  ];

  keys.map((key) => {
    if (dataObject.hasOwnProperty(key) && typeof dataObject[key] != "object") {
      if (apiObject.query.hasOwnProperty(key)) {
        apiObject.addQuery = { key, payload: dataObject[key] };
      }
    } else {
      dataObject[key] &&
        Object.keys(dataObject[key]).map((keyName) => {
          if (apiObject.query.hasOwnProperty(keyName)) {
            apiObject.addQuery = {
              key: keyName,
              payload: dataObject[key][keyName],
            };
          }
        });
    }
  });
};

export const generateQuery = (query) => {
  let url = "";

  if (query.hasOwnProperty("url_id")) {
    url = `/${query.url_id}`;
  }

  return (
    url +
    Object.keys(query).reduce((accumulator, key, index) => {
      if (
        query[key] == "" ||
        query[key] == null ||
        key == "url_id" ||
        (query[key] !== null && query[key].toString().trim() == "")
      ) {
        return accumulator;
      } else {
        return accumulator + `${index != 0 ? "&" : "?"}${key}=${query[key]}`;
      }
    }, "")
  );
};

export const getToken = () => {
  return localStorage.getItem("AuthToken")
    ? localStorage.getItem("AuthToken")
    : "";
};

export const letterAvatar = (name, size, letterRestriction) => {
  name = name || "";
  size = size || 60;

  var colours = [
      "#1abc9c",
      "#2ecc71",
      "#3498db",
      "#9b59b6",
      "#34495e",
      "#16a085",
      "#27ae60",
      "#2980b9",
      "#8e44ad",
      "#2c3e50",
      "#f1c40f",
      "#e67e22",
      "#e74c3c",
      "#ecf0f1",
      "#95a5a6",
      "#f39c12",
      "#d35400",
      "#c0392b",
      "#bdc3c7",
      "#7f8c8d",
    ],
    nameSplit = String(name).toUpperCase().split(" "),
    initials,
    charIndex,
    colourIndex,
    canvas,
    context,
    dataURI;
  if (letterRestriction) {
    initials = nameSplit[0] ? nameSplit[0].charAt(0) : "?";
  } else {
    if (nameSplit.length === 1) {
      initials = nameSplit[0] ? nameSplit[0].charAt(0) : "?";
    } else {
      initials = nameSplit[0].charAt(0) + nameSplit[1].charAt(0);
    }
  }

  if (window.devicePixelRatio) {
    size = size * window.devicePixelRatio;
  }

  charIndex = (initials === "?" ? 72 : initials.charCodeAt(0)) - 64;
  colourIndex = charIndex % 20;
  canvas = document.createElement("canvas");
  canvas.width = size;
  canvas.height = size;
  context = canvas.getContext("2d");

  context.fillStyle = colours[colourIndex - 1];
  context.fillRect(0, 0, canvas.width, canvas.height);
  context.font = Math.round(canvas.width / 2) + "px Arial";
  context.textAlign = "center";
  context.fillStyle = "#FFF";
  context.fillText(initials, size / 2, size / 1.5);

  dataURI = canvas.toDataURL();
  canvas = null;

  return dataURI;
};

export const getDescriptionData = (data, charLength) => {
  if (data.length < charLength) {
    return data;
  } else {
    let detail = data.substring(0, charLength - 1) + "...";
    return detail;
  }
};

export const currencyConvert = (data) => {
  data = parseInt(data);
  if (data < 10000) {
    return data;
  } else {
    let detail = (data / 100000).toFixed(2);
    if (detail % 1 === 0) {
      detail = parseInt(detail);
    }
    if (detail > 1) {
      detail = detail + " Lakhs";
    } else {
      detail = detail + " Lakh";
    }
    return detail;
  }
};

//decrpyt data
export const decryptData = (data) => {
  try {
    const decryptedString = cryptLib.decryptCipherTextWithRandomIV(
      data,
      "passworddecryptionkey"
    );
    return decryptedString;
  } catch {
    return data;
  }
};

//encrypt data
export const encryptData = (data) => {
  try {
    const decryptedString = cryptLib.encryptPlainTextWithRandomIV(
      data,
      "passworddecryptionkey"
    );
    return decryptedString;
  } catch {
    return data;
  }
};

//encrypt data
export const getAccessdata = (accessData, service) => {
  let accessArray = [];
  accessArray = accessData.filter((x) => x.serviceKeyName === service);
  let body = {
    download: false,
    edit: false,
    sms: false,
    viewAccess: false,
    createAccess: false,
    deleteAccess: false,
  };
  accessArray.map((list, index) => {
    if (index === 0) {
      let { permissions = [] } = list;
      let downloadArray = [],
        editArray = [],
        smsArray = [],
        viewArray = [],
        createArray = [],
        deleteArray = [];
      downloadArray = permissions.filter(
        (x) => x.permissionKeyName === "download"
      );
      editArray = permissions.filter((x) => x.permissionKeyName === "edit");
      smsArray = permissions.filter((x) => x.permissionKeyName === "sms");
      viewArray = permissions.filter((x) => x.permissionKeyName === "view");
      createArray = permissions.filter((x) => x.permissionKeyName === "create");
      deleteArray = permissions.filter((x) => x.permissionKeyName === "delete");
      if (downloadArray.length !== 0) {
        body.download = true;
      }
      if (editArray.length !== 0) {
        body.edit = true;
      }
      if (smsArray.length !== 0) {
        body.sms = true;
      }
      if (viewArray.length !== 0) {
        body.viewAccess = true;
      }
      if (createArray.length !== 0) {
        body.createAccess = true;
      }
      if (deleteArray.length !== 0) {
        body.deleteAccess = true;
      }
    }
  });
  return body;
};

export const formatToINR = (value) => {
  if (!value) return "0";
  return new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
    minimumFractionDigits: 0,
  }).format(value);
};

export const formatToINRWithoutSymbol = (value) => {
  if (!value) return "0";
  return new Intl.NumberFormat("en-IN").format(value);
};
export function moneyFormatBySymbol(value, isFullname = false) {
  var val = Math.round(value);
  if (isFullname) {
    if (val >= 10000000) {
      val = (val / 10000000).toFixed(1) + " Crore";
    } else if (val >= 100000) {
      val = (val / 100000).toFixed(1) + " Lakhs";
    }
  } else {
    if (val >= 1000000000) {
      val = (val / 1000000000).toFixed(0) + " M";
    } else if (val >= 10000000) {
      val = (val / 10000000).toFixed(0) + " C";
    } else if (val >= 100000) {
      val = (val / 100000).toFixed(0) + " L";
    } else if (val >= 1000) {
      val = (val / 1000).toFixed(0) + " K";
    }
  }
  return val;
}

export const setCookies = ({ name, value, days }) => {
  let expireDate = new Date();
  expireDate.setTime(expireDate.getTime() + days * 24 * 60 * 60 * 1000);
  let expires = "; expires=" + expireDate.toUTCString();
  document.cookie = name + "=" + value + expires + "; path=/";
};

export const dateDiff = (date) => {
  let currentDate = moment([
    new Date().getFullYear(),
    new Date().getMonth(),
    new Date().getDate(),
  ]);

  let endDate = moment([
    moment(date).year(),
    moment(date).month(),
    moment(date).date(),
  ]);

  return endDate.diff(currentDate, "days");
};

export const storeLoginData = (data) => {
  localStorage.setItem("fpoId", data?.fpoId);
  localStorage.setItem("AuthToken", data.token);
  localStorage.setItem("isKymUser", data.isKym == 1 ? true : false);
  localStorage.setItem("isTCAccepted", JSON.stringify(data?.isTCAccepted));
  if (data.userRoles.userRoleName == "fpo") {
    localStorage.setItem("isLighter", data.isLighter);
    localStorage.setItem("isLightHouse", data.LHFPO_IND);
    localStorage.setItem("isFederation", data.isFederation);
    localStorage.setItem("Sam_IPL", data?.Sam_IPL);
    localStorage.setItem("userMasterId", data.userMasterId);
    localStorage.setItem("userName", data.userName);
    localStorage.setItem("SBI", data?.SBI);
    localStorage.setItem("isMandi", data?.isMandiProc == 1 ? true : false);
  }
};

export const termsandPolicyLinks = (isTerms = false) => {
  const textColor = isTerms ? "text-green" : "text-black";
  return (
    <div className="d-flex justify-content-center pt-2 align-items-center">
      <p className="fs-16 fw-600 cursor-pointer mb-0 pr-3">
        <div
          className={`${textColor} text-underline`}
          onClick={() =>
            window.open("/termsandcondition", "_blank", "noreferrer")
          }
        >
          Terms & Conditions
        </div>
      </p>
      <p className="fs-16 fw-600 cursor-pointer mb-0">
        <div
          className={`${textColor} text-underline`}
          onClick={() => window.open("/privacypolicy", "_blank", "noreferrer")}
        >
          Privacy Policy
        </div>
      </p>
    </div>
  );
};
