import { NormalButton } from "component/common";
import React, { Component } from "react";
import SimpleReactValidator from "simple-react-validator";
import { FieldsTemplate } from "../common/FieldsTemplate";

export const BasicRmInfo = (props) => {
  const [, forceUpdate] = React.useState();
  const [rmInfo, setRmInfo] = React.useState({
    altitude: 0,
  });
  const [rmInfoAttr, setRmInfoAttr] = React.useState([
    {
      label: "latitude (x.y°)",
      field: "input",
      type: "number",
      name: "latitude",
      required: false,
      placeholder: "0.00000000",
    },
    {
      label: "longitude (x.y°)",
      field: "input",
      type: "number",
      name: "longitude",
      required: false,
      placeholder: "0.00000000",
    },
    {
      label: "altitude (m)",
      field: "input",
      type: "number",
      name: "altitude",
      required: false,
      placeholder: "0.00",
    },
    {
      label: "accuracy (m)",
      field: "input",
      type: "number",
      name: "accuracy",
      required: false,
      placeholder: "0.00",
    },
    {
      label: "Date of Assessment",
      field: "date",
      type: "number",
      name: "dateAssessed",
      required: true,
    },
    {
      label: "Name of Relationship Manager",
      field: "input",
      type: "text",
      name: "rmName",
      required: true,
      disabled: true,
    },
    {
      label: "Relationship Manager's Email",
      field: "input",
      type: "text",
      name: "rmEmail",
      required: true,
      disabled: true,
    },
  ]);
  const handleInput = ({ target: { name, value } }) => {
    setRmInfo({
      ...rmInfo,
      [name]: value,
    });
  };
  //
  React.useEffect(() => {
    if (props?.surveyDetails) {
      let _rmInfo = {
        latitude: props?.surveyDetails?.latitude,
        longitude: props?.surveyDetails?.longitude,
        altitude: props?.surveyDetails?.altitude,
        accuracy: props?.surveyDetails?.accuracy,
        rmName: props?.surveyDetails?.rmName,
        rmEmail: props?.surveyDetails?.RM_Email_ID,
      };
      if (props?.surveyDetails.dateAssessed) {
        _rmInfo.dateAssessed = new Date(props?.surveyDetails.dateAssessed);
      } else {
        _rmInfo.dateAssessed = new Date();
      }

      setRmInfo({
        ...rmInfo,
        ..._rmInfo,
      });
    }
  }, [props?.surveyDetails]);

  React.useEffect(() => {
    if (props?.submitSurveyClick) {
      handleSubmit("completed");
    }
  }, [props?.submitSurveyClick]);

  //
  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition);
    }
  };
  const showPosition = (position) => {
    try {
      const gps_altitude = position.coords?.altitude?.toFixed(2);
      const accuracy = position.coords?.accuracy.toFixed(2);
      const gpslatitude = position.coords?.latitude.toFixed(6);
      const gpslongitude = position.coords?.longitude.toFixed(6);
      //
      let _rmInfo = {
        latitude: gpslatitude,
        longitude: gpslongitude,
        altitude: gps_altitude,
        accuracy: accuracy,
      };
      setRmInfo({
        ...rmInfo,
        ..._rmInfo,
      });
    } catch (error) {}
  };

  //validation
  const simpleValidator = React.useRef(
    new SimpleReactValidator({
      element: (message) => (
        <span className="error-message text-danger fs-14">{message}</span>
      ),
      autoForceUpdate: this,
    })
  );

  const handleSubmit = (status) => {
    const formValid = simpleValidator.current.allValid();
    if (formValid) {
      let payload = { ...rmInfo, status };
      payload.RM_Email_ID = rmInfo.rmEmail;
      payload.latitude = rmInfo.latitude || 0;
      payload.longitude = rmInfo.longitude || 0;
      payload.altitude = rmInfo.altitude || 0;
      payload.accuracy = rmInfo.accuracy || 0;
      props.updateSurvey("rmInfo", payload);
    } else {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      props.errorAccordion();
    }
  };
  return (
    <div>
      {/* <h5 ><b>Collect GPS of the Place</b></h5> */}
      <p className="fs-11 px-3 pt-3">
        <i>
          <strong>Note:</strong> RM can have FPO send location via WhatsApp, can
          see coordinates by opening it in Google Maps
        </i>
      </p>
      <p className="fs-11 px-3">
        <i>
          <strong>Note:</strong> The below GPS refresh button is not supported
          in IE8 or earlier versions.
        </i>
      </p>
      {/* <div className="container-sa position-relative" > */}
      <div>
        <button
          type="button"
          className="btn bg-transparent"
          onClick={getLocation}
        >
          <b>Refresh GPS</b> <i className="fas fa-sync-alt pl-2"></i>
        </button>

        <FieldsTemplate
          attributes={rmInfoAttr}
          simpleValidator={simpleValidator}
          stateAttr={rmInfo}
          handleInput={handleInput}
          // handleRadio={() => {}}
          // handleCheckbox={() => { }}
        />

        <div className="row mt-4">
          <div className="col-md-5 col-sm-12  my-1">
            <NormalButton
              label="Save & Next"
              mainbg={true}
              className="px-3 py-2 mx-auto fs-14 line-height-26 font-Gilroy saveNext"
              onClick={() => handleSubmit("draft")}
              isPopup={true}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
