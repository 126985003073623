import React, { Component } from "react";
import './dashboard.scss';
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

export class Piechart extends Component {
    constructor() {
        super();
        this.state = {
            options: {}
        }
    }
    componentDidMount() {
        let { piechartValue, done, pending } = this.props;
        this.setState({
            options: {
                chart: {
                    plotBackgroundColor: null,
                    plotBorderWidth: null,
                    plotShadow: false,
                    type: 'pie',
                    height: 280
                },
                title: {
                    text: 'Requests Processed',
                    style: {
                        fontSize: '15px',
                        fontFamily: 'Gilroy'
                    }
                },
                tooltip: {
                    useHTML: true,
                    formatter: function () {
                        if (this.point.name == 'Processed') {
                            return '<b>' + this.point.name + "</b>: " + Number(done) + "<br/>";
                        } else {
                            return '<b>' + this.point.name + "</b>: " + Number(pending) + "<br/>";
                        }
                    }
                },

                accessibility: {
                    point: {
                        valueSuffix: '%'
                    }
                },
                plotOptions: {
                    pie: {
                        allowPointSelect: true,
                        cursor: 'pointer',
                        dataLabels: {
                            enabled: true,
                            format: '<b>{point.name}</b>: {point.percentage:.1f} %'
                        }
                    }
                },
                series: [{
                    name: 'Requests Processed',
                    colorByPoint: true,

                    data: [{
                        name: 'Pending',
                        y: Number(100 - Number(piechartValue)),
                        sliced: false,
                        selected: false,
                        color: '#1f8e59'

                    }, {
                        name: 'Processed',
                        y: Number(piechartValue),
                        color: '#FFB800'

                    }]
                }]
            }
        })
    }

    render() {
        const {
            options
        } = this.state;
        return (
            <div>

                <HighchartsReact
                    highcharts={Highcharts}
                    options={options}
                />


            </div>
        );
    }
}