import React, { Component } from "react";
import { Navbar } from "component/common";
import { Viewstaff } from "component/Members";
import { UserAuth } from "helpers/UserAuth";
class ViewStaffClass extends Component {
  render() {
    return (
      <div>
        <div className="normal-header">{/* <Navbar /> */}</div>
        <Viewstaff
          id={this.props.match.params.id}
          fpoid={this.props.match.params.fpoid}
        />
      </div>
    );
  }
}

export const ViewStaff = UserAuth(ViewStaffClass, { service: "membership" });
