import React from "react";
import arrow_left from "assets/svg/add_member/arrow_left.svg";
import { history } from "helpers";
import "./style.scss";

const BackButton = () => {
  return (
    <div className="container-sa back_button">
      <div className="d-flex">
        <div className="d-flex" onClick={() => history.goBack()}>
          <img className="cursor-pointer" src={arrow_left} alt="back" />
          <p className="fs-18 font-weight-bold text-black mb-0 cursor-pointer ml-2">
            Back
          </p>
        </div>
      </div>
    </div>
  );
};

export default BackButton;
