import React from "react";
import { history } from "service/helpers";
import { currencyConvert } from "service/helperFunctions";

function Strip(props) {
  const { payload, index, editAccess } = props;
  const {
    FSId,
    turnOver,
    AuthorisedCapital,
    sanctionedCapital,
    profitAT,
    GrandDonationReceived,
    fymYear,
  } = payload;
  return (
    <div key={index} className="container-sa license-strip shadow rounded mb-3">
      <div className="row align-items-center h-100 px-2 col-2-fix">
        <div className="col-1">
          <div className="fs-18 fw-600">{fymYear}</div>
          <div className="text-black-50">{"Financial year"}</div>
        </div>
        <div className="col-2">
          <div className="fs-18 fw-600">{currencyConvert(turnOver)}</div>
          <div className="text-black-50">
            {"Revenue ( "} <span className="icon-rupee"></span> {" )"}
          </div>
        </div>
        <div className="col-2">
          <div className="fs-18 fw-600">
            {AuthorisedCapital ? currencyConvert(AuthorisedCapital) : "-"}
          </div>
          <div className="text-black-50">
            {"Authorised capital ( "} <span className="icon-rupee"></span>{" "}
            {" )"}
          </div>
        </div>
        <div className="col-2">
          <div className="fs-18 fw-600">
            {sanctionedCapital ? currencyConvert(sanctionedCapital) : "-"}
          </div>
          <div className="text-black-50">
            <div>{"Sanctioned capital"} </div>
            {" ( "}
            <span className="icon-rupee"></span> {" )"}
          </div>
        </div>
        <div className="col-2">
          <div className="fs-18 fw-600">
            {profitAT ? currencyConvert(profitAT) : "-"}
          </div>
          <div className="text-black-50">
            {"Profit after tax ( "} <span className="icon-rupee"></span> {" )"}
          </div>
        </div>
        <div className="col-2">
          <div className="fs-18 fw-600">
            {GrandDonationReceived
              ? currencyConvert(GrandDonationReceived)
              : "-"}
          </div>
          <div className="text-black-50">
            {"Grant or Donation Received ( "}{" "}
            <span className="icon-rupee"></span> {" )"}
          </div>
        </div>
        <div className="col-1">
          {editAccess && (
            <div
              className="text-black-50 cursor-pointer pl-4"
              onClick={() => history.push(`/fpo/edit-financialinfo/${FSId}`)}
            >
              {"Edit"}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Strip;
