import React, { Component } from "react";

import { Modal } from "reactstrap";

import { NormalCheckbox, CommonPageLoader } from "component/common";
import { getUserListForShareForCourse, academyShare } from "action/Acadamy";
import { debounceFunction } from "service/debounce";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Toast } from "service/toast";
import "./sharePopup.scss";
export class SharePopupClass extends Component {
  state = {
    userList: [],
    selecteduserList: [],
    isSelectAll: false,
  };

  //lifeCycles
  componentDidMount = () => {
    this._getUserProfileList();
  };

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      if (this.props.isOpen) {
        this._getUserProfileList();
      }
    }
  }

  //handle input
  handleInput = ({ target: { value } }) => {
    //Debounce Function
    this.setState({ searchValue: value });
    debounceFunction(() => this.SearchFunction(value), 1000);
  };

  SearchFunction = (value) => {
    this._getUserListForShareForCourse(value);
  };

  //get user list
  _getUserProfileList = () => {
    let { userList, selecteduserList } = this.state;
    userList = [];
    selecteduserList = [];
    this.setState({ userList, selecteduserList });
    this._getUserListForShareForCourse("");
  };

  _getUserListForShareForCourse = (searchValue) => {
    this.setState({ isLoading: true });
    this.props
      .getUserListForShareForCourse({ search: searchValue })
      .then((data) => {
        let memberList = [];
        data.map((list) => {
          list.isChecked = false;
          memberList.push(list);
        });
        this.setState({ userList: memberList, isLoading: false });
      });
  };

  //handle select users
  handleToggle = (index) => {
    let { userList, selecteduserList } = this.state;

    userList[index].isChecked = !userList[index].isChecked;

    if (userList[index].isChecked) {
      selecteduserList.push(userList[index].userMasterId);
      this.setState({ userList, selecteduserList });
      if (selecteduserList.length !== userList.length) {
        this.setState({ isSelectAll: false });
      } else {
        this.setState({ isSelectAll: true });
      }
    } else {
      if (index === 0) {
        selecteduserList.shift();
      } else {
        selecteduserList.pop();
      }
      if (selecteduserList.length !== userList.length) {
        this.setState({ isSelectAll: false });
      }
      this.setState({
        selecteduserList,
      });
    }
  };

  //handle select all users
  handleToggleForSelectAll = (isChecked) => {
    let { userList, selecteduserList, isSelectAll } = this.state;
    selecteduserList = [];
    this.setState({ selecteduserList, isSelectAll: !isSelectAll }, () => {
      userList.map((list) => {
        if (isChecked === true) {
          list.isChecked = false;
          selecteduserList = [];
        } else {
          list.isChecked = true;
          selecteduserList.push(list.userMasterId);
        }
      });
      this.setState({ userList, selecteduserList });
    });
  };

  //handle submit
  handleSubmit = () => {
    let {
      isCourseTab,
      courseId,
      academyShare,
      isBlog,
      blogId,
      isInfomativeVideo,
      trendingvideoId,
    } = this.props;
    let { selecteduserList } = this.state;
    if (selecteduserList.length !== 0) {
      let body = {
        receiverUserMasterId: selecteduserList,
        module: "academy",
        notificationType: "",
        notificationTypeId: "",
      };
      if (isCourseTab) {
        body.notificationType = "course";
        body.notificationTypeId = courseId;
      } else if (isBlog) {
        body.notificationType = "blog";
        body.notificationTypeId = blogId;
      } else if (isInfomativeVideo) {
        body.notificationType = "informativeVideo";
        body.notificationTypeId = trendingvideoId;
      }
      academyShare(body).then(() => {
        this._clearData();
      });
    } else {
      Toast({ type: "error", message: "Please select atleast one user" });
    }
  };

  //clear data
  _clearData = () => {
    let { selecteduserList, userList } = this.state;
    selecteduserList = [];
    userList.map((list) => {
      list.isChecked = false;
    });
    this.setState({ selecteduserList, userList });
    this.props.toggle();
  };

  //close popup
  closepopup = () => {
    let { selecteduserList, userList } = this.state;
    userList.map((list) => {
      list.isChecked = false;
    });
    selecteduserList = [];

    this.setState({ selecteduserList, userList }, () => {
      this.props.toggle();
    });
  };

  render() {
    let {
      userList = [],
      isLoading,
      selecteduserList = [],
      isSelectAll,
    } = this.state;

    let {
      className = "modal-dialog-centered share-section-modal",
      isOpen = true,
      isCourseTab = false,
      isBlog = false,
    } = this.props;

    return (
      <Modal isOpen={isOpen} className={className}>
        <div className="share-section">
          <div className="modal-label mb-2">
            <h5>
              Share this{" "}
              {isCourseTab ? "course" : isBlog ? "blog" : "informative video"}
            </h5>
            <i
              onClick={() => this.closepopup()}
              className="icon-close cursor-pointer"
            ></i>
          </div>
          <div className="px-4 pb-3">
            <div className="row">
              <div className="col-sm-12">
                <input
                  type-="text"
                  className="form-control form-control-sa"
                  placeholder="Search"
                  onChange={(e) => this.handleInput(e)}
                />
              </div>
            </div>
          </div>
          <div>
            {userList.length !== 0 && (
              <div className="px-4" id="check-in">
                <h5 className="text-green-18 fs-16 mr-3 line-height-24 font-weight-bold">
                  Select all
                </h5>
                <NormalCheckbox
                  className="custom-checkbox check-box"
                  value=""
                  name={"toggleEvent"}
                  checked={isSelectAll}
                  onChange={() => this.handleToggleForSelectAll(isSelectAll)}
                />
              </div>
            )}
          </div>
          <div id="modal-container-share">
            {!isLoading ? (
              <>
                {userList.length !== 0 ? (
                  userList.map((list, index) => {
                    let { userName = "", isChecked, mobileNumber = "" } = list;
                    return (
                      <div key={index}>
                        <div
                          className={`px-4 d-flex justify-content-between align-items-center ${
                            isChecked ? "bg-blue-f9" : "bg-white"
                          }`}
                        >
                          <div className="details m-0">
                            <div className="">
                              <p className="user-name">{userName} </p>
                              <p className="contact">{mobileNumber}</p>
                            </div>
                          </div>
                          <div className="" id="check-in">
                            <NormalCheckbox
                              className="custom-checkbox check-box"
                              value=""
                              name={`toggleEvent-${index}`}
                              checked={isChecked}
                              onChange={() => this.handleToggle(index)}
                            />
                          </div>
                        </div>
                        <div id="hr-line"></div>
                      </div>
                    );
                  })
                ) : (
                  <p className="py-5 my-5 text-black-50 fs-22 line-height-25 text-center">
                    No data found !!!
                  </p>
                )}
              </>
            ) : (
              <CommonPageLoader />
            )}
          </div>
        </div>
        <div className="d-flex justify-content-end selected-details align-items-center px-4">
          {selecteduserList.length !== 0 ? (
            <p>{selecteduserList.length} Selected</p>
          ) : (
            ""
          )}
          <button
            className="col-4 btn w-100 text-white bg-green d-flex align-items-center justify-content-center "
            onClick={() => {
              this.handleSubmit();
            }}
          >
            Send
          </button>
        </div>
      </Modal>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getUserListForShareForCourse,
      academyShare,
    },
    dispatch
  );
};
let component = SharePopupClass;

export const SharePopup = connect(null, mapDispatchToProps)(component);
