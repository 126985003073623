import React, { Component } from "react";
import "./resetpassword.scss";
import SimpleReactValidator from "simple-react-validator";
import { forgotPasswordTokeValidation, changePassword } from "action/AuthAct";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { history } from "service/helpers";
import { encryptData } from "service/helperFunctions";
import { PasswordStrengthMeter } from "component/common";
export class ResetPasswordClass extends Component {
  constructor() {
    super();
    this.state = {
      password: "",
      reEnterPassword: "",
      status: "",
      passwordVisible: false,
      reEnterPasswordVisible: false,
    };
  }

  validator = new SimpleReactValidator({
    element: (message) => (
      <span className="error-message text-danger fs-14">{message}</span>
    ),
    autoForceUpdate: this,
    validators: {
      password: {
        message: "The :attribute must be a valid format.",
        rule: (val, params, validator) => {
          return (
            validator.helpers.testRegex(
              val,
              /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$^+=!*()@%&]).{8,20}$/
            ) && params.indexOf(val) === -1
          );
        },
        messageReplace: (message, params) =>
          message.replace("", this.helpers.toSentence(params)),
        required: true,
      },
      checkPassword: {
        message: "Given :attribute does not match",
        rule: function (val, params) {
          return val === params[0];
        },
      },
    },
  });

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.validator.allValid()) {
      let { password, reEnterPassword } = this.state;
      let body = {
        password: encryptData(password),
        confirmPassword: encryptData(reEnterPassword),
      };

      this.props.changePassword(body).then(() => {
        localStorage.removeItem("forgotPassauthToken");
        history.push("/");
      });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  componentDidMount() {
    let { token } = this.props;
    if (!token) {
      history.push("/");
    } else {
      localStorage.setItem("forgotPassauthToken", token);
      this.props.forgotPasswordTokeValidation().then((status) => {
        this.setState({ status });
      });
    }
  }

  toggle = (key) => {
    this.setState((prevState) => ({
      [key]: !prevState[key],
    }));
  };

  render() {
    const {
      password,
      reEnterPassword,
      status,
      passwordVisible,
      reEnterPasswordVisible,
    } = this.state;

    return (
      <>
        {status == 200 ? (
          <div className="w-100 mt-3 d-flex align-items-center flex-column">
            <label className="font-weight-bold text-success mb-4 h4">
              Forgot Password
            </label>
            <div className="w-100 mb-4">
              <label className="font-weight-bold" htmlFor="mobileOrEmail">
                Enter New Password
              </label>
              <div className="input-group">
                <input
                  type={reEnterPasswordVisible ? "text" : "password"}
                  className="form-control w-100 bg-light"
                  id="password"
                  name="password"
                  value={password}
                  onChange={this.handleChange}
                />
                <div className="input-group-addon right">
                  <span
                    onClick={(e) => this.toggle("reEnterPasswordVisible")}
                    className={`cursor-pointer icon-${
                      reEnterPasswordVisible ? "eye" : "eye-hide"
                    } fs-24`}
                  ></span>
                </div>
              </div>
              {this.validator.message(
                "new password",
                password,
                "required|min:8|max:15|password"
              )}
            </div>
            <div className="w-100">
              <label className="font-weight-bold" htmlFor="mobileOrEmail">
                Confirm Password
              </label>
              <div className="input-group">
                <input
                  type={passwordVisible ? "text" : "password"}
                  className="form-control w-100 bg-light"
                  id="reEnterPassword"
                  name="reEnterPassword"
                  value={reEnterPassword}
                  onChange={this.handleChange}
                />
                <div className="input-group-addon right">
                  <span
                    onClick={(e) => this.toggle("passwordVisible")}
                    className={`cursor-pointer icon-${
                      passwordVisible ? "eye" : "eye-hide"
                    } fs-24`}
                  ></span>
                </div>
              </div>
              {this.validator.message(
                "confirm password",
                reEnterPassword,
                `required|min:8|max:15|checkPassword:${password}`
              )}
              <PasswordStrengthMeter data={reEnterPassword} />
            </div>
            <div className="d-flex mt-5 w-100">
              <input
                className="btn w-100 py-2 font-weight-bold"
                type="submit"
                value="Submit"
                onClick={this.handleSubmit}
              />
            </div>
          </div>
        ) : (
          <>
            <label className="text-black fs-18 text-center">
              Link Expired , Please try again later
            </label>
            <div className="d-flex mt-4 w-100">
              <input
                className="btn w-100 py-2 font-weight-bold"
                type="submit"
                value="Return to login"
                onClick={() => {
                  history.push("/");
                }}
              />
            </div>
          </>
        )}
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      forgotPasswordTokeValidation,
      changePassword,
    },
    dispatch
  );
};

let component = ResetPasswordClass;

export const ResetPassword = connect(null, mapDispatchToProps)(component);
