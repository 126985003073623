import React from "react";
import profilePicture from "assets/images/default-profile-img.png";
import { getFilePathDownload } from "service/api";

const PersonalInfo = () => {
  return (
    <div className="member-profile member-profile-view-area px-3 pb-2 d-flex align-items-center shadow rounded px-0 container-sa">
      <div className="row w-100">
        <div className="col-md-2 d-flex align-items-center justify-content-center">
          <div className="member-profile-image-area">
            <img
              className="member-profile-image"
              src={profilePicture}
              alt="profile"
            />
          </div>
        </div>
        <div className="col-md-7 py-3">
          <div className="member-profile-user-container">
            <div className="member-profile-user mb-3">Testing</div>
            <div className="row">
              <div className="col-md-4 mb-2">
                <div className="mb-2 member-profile-key">{"Member ID"}</div>
                <div className="mb-3 member-profile-content">Testing01</div>
              </div>
              <div className="col-md-4 mb-2">
                <div className="mb-2 member-profile-key">{"Date of birth"}</div>
                <div className="mb-3 member-profile-content">25/12/1990</div>
              </div>
              <div className="col-md-4 mb-2">
                <div className="mb-2 member-profile-key">{"Gender"}</div>
                <div className="member-profile-content">Male</div>
              </div>
              <div className="col-md-4 mb-2">
                <div className="mb-2 member-profile-key">{"Phone number"}</div>
                <div className="member-profile-content mb-3">9887788987</div>
              </div>
              <div className="col-md-4 mb-2">
                <div className="mb-2 member-profile-key">Address</div>
                <div className="member-profile-content">Kolkata</div>
              </div>
              <div className="col-md-4 mb-2">
                <div className="mb-2 member-profile-key">Address Proof</div>
                <div className="member-profile-content mb-3">
                  <a
                    className="fs-16 text-green-18 text-underline cursor-pointer"
                    href={getFilePathDownload}
                    download
                  >
                    {"Download"}
                  </a>
                </div>
              </div>
              <div className="col-md-4 mb-2">
                <div className="mb-2 member-profile-key">Identity proof</div>
                <div className="member-profile-content">
                  <a
                    className="fs-16 text-green-18 text-underline cursor-pointer"
                    href={getFilePathDownload}
                    download
                  >
                    {"Download"}
                  </a>
                </div>
              </div>
              <div className="col-md-4 mb-2">
                <div className="mb-2 member-profile-key">{"Smart Phone"}</div>
                <div className="member-profile-content mb-3">-</div>
              </div>
              <div className="col-md-4 mb-2">
                <div className="mb-2 member-profile-key">Village</div>
                <div className="member-profile-content">Bombay</div>
              </div>
              <div className="col-md-4 mb-2">
                <div className="mb-2 member-profile-key">State</div>
                <div className="member-profile-content mb-3">UP</div>
              </div>
              <div className="col-md-4 mb-2">
                <div className="mb-2 member-profile-key">District</div>
                <div className="member-profile-content">Chatisgargh</div>
              </div>
              <div className="col-md-4 mb-2">
                <div className="mb-2 member-profile-key">Pincode</div>
                <div className="member-profile-content">544554</div>
              </div>
              <div className="col-md-4 mb-2">
                <div className="mb-2 member-profile-key">FIG name</div>
                <div className="member-profile-content">Testing</div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-3 d-flex align-items-center scroll-x">
          <div className="d-flex flex-md-wrap flex-nowrap gap-2 justify-content-center">
            <div className="rounded-circle member-profile-info-container-farm m-2">
              <div>0</div>
              <p className="mb-0 fs-24 font-weight-bold text-white">Crops</p>
            </div>
            <div className="rounded-circle member-profile-info-container-farm m-2">
              <div>0</div>
              <p className="mb-0 fs-24 font-weight-bold text-white">Acres</p>
            </div>
            <div className="rounded-circle member-profile-info-container-farm m-2">
              <div>0</div>
              <p className="mb-0 fs-24 font-weight-bold text-white">
                Livestock
              </p>
            </div>
            <div className="rounded-circle member-profile-info-container-acres m-2">
              <div>0</div>
              <p className="mb-0 fs-24 font-weight-bold text-white">Assets</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PersonalInfo;
