import React, { Component } from "react";
import { NormalSelect } from "component/common";
import "./price_details.scss";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  commodityList,
  mandiList,
  getGraphDataforOneMandi,
  getStateByPrice,
  getMandiByPriceAndCommodity,
  getCommodityVariety,
  oneMandiDiffCommodity,
} from "action/PricingandWeatherAct";

import { allState, getAboutusMemberDetail } from "action/MemberAct";
import moment from "moment";
import { Modal, ModalBody } from "reactstrap";

export class PriceDetailsClass extends Component {
  state = {
    priceData: [],
    varietyOptions: [],
    commodityOptions: [],
    stateOptions: [],
    apmcOptions: [],
    commodity: "",
    apmc: "",
    state: "",
    variety: "",
    isOpen: false,
    isLoading: true,
  };
  //lifecycles
  componentDidMount() {
    this.getCommodityList();
  }

  getFpoPriceData = (crops) => {
    const {
      getCommodityVariety,
      getStateByPrice,
      getMandiByPriceAndCommodity,
      oneMandiDiffCommodity,
    } = this.props;
    const { priceData, commodityOptions } = this.state;
    let newData = [];
    crops.map(async (list) => {
      commodityOptions.filter((id) => {
        if (id.label === list) {
          return newData.push(list);
        }
      });
    });
    if (newData?.length > 0) {
      if (crops.length !== 0) {
        crops.map(async (list) => {
          let commodityId = commodityOptions.filter((id) => {
            return id.label === list;
          });
          let priceDetail = {
            commodity: list,
            variety: "",
            state: "",
            apmc: "",
            price: "",
            stateOptions: [],
            varietyOptions: [],
            apmcOptions: [],
            date: "",
          };
          if (commodityId.length != 0) {
            await getCommodityVariety({
              commodityId: commodityId[0]?.value,
            }).then((variety) => {
              if (variety.length !== 0) {
                let varietyData = [];
                variety.map((list) => {
                  varietyData.push({
                    value: list.variety,
                    label: list.variety,
                  });
                });
                priceDetail.varietyOptions = varietyData;
                priceDetail.variety = varietyData[0]?.value;
              }
            });
            let stateBody =
              priceDetail.variety == ""
                ? {
                    commodityId: commodityId[0]?.value,
                  }
                : {
                    variety: priceDetail?.varietyOptions?.[0]?.value,
                    commodityId: commodityId[0]?.value,
                  };
            await getStateByPrice(stateBody).then((state) => {
              let stateData = [];
              if (state.length != 0) {
                state.map((list) => {
                  stateData.push({
                    value: list.state_name,
                    label: list.state_name,
                  });
                });
                priceDetail.stateOptions = stateData;
                priceDetail.state = stateData[0]?.value;
              }
            });
            if (priceDetail.state !== "") {
              let mandiBody =
                priceDetail.variety == ""
                  ? {
                      state: priceDetail?.state,
                      commodityId: commodityId[0]?.value,
                    }
                  : {
                      state: priceDetail?.state,
                      commodityId: commodityId[0]?.value,
                      variety: priceDetail?.variety,
                    };
              await getMandiByPriceAndCommodity(mandiBody).then((mandi) => {
                if (mandi.length != 0) {
                  let apmcLists = [];
                  mandi.map((list) => {
                    apmcLists.push({
                      label: list.mandiName,
                      value: list.mandiName,
                    });
                  });
                  priceDetail.apmcOptions = apmcLists;
                  priceDetail.apmc = apmcLists[0]?.value;
                }
              });
            }
            if (priceDetail.apmc !== "") {
              let graphBody =
                priceDetail?.variety == ""
                  ? {
                      commodity: commodityId[0]?.value,
                      apmc: priceDetail?.apmc,
                    }
                  : {
                      variety: priceDetail?.variety,
                      commodity: commodityId[0]?.value,
                      apmc: priceDetail?.apmc,
                    };
              await oneMandiDiffCommodity(graphBody).then((price) => {
                priceDetail.price = price?.latestPrice;
                priceDetail.date = price?.latestDate;
                priceData.push(priceDetail);
              });
              this.setState({
                priceData: priceData,
                isLoading: false,
              });
            }
          } else {
            return null;
          }
        });
      } else {
        this.setState({
          isLoading: false,
        });
      }
    } else {
      this.setState({
        isLoading: false,
      });
    }
  };

  getCommodityList = () => {
    let { commodityList, getAboutusMemberDetail } = this.props;
    commodityList().then((data) => {
      let commdityLists = [];
      data.map((list) => {
        commdityLists.push({
          label: list.commodityName,
          value: list.commodityName,
        });
      });
      this.setState({ commodityOptions: commdityLists }, () => {
        getAboutusMemberDetail().then((fpoDetail) => {
          let cropList = [];
          fpoDetail.cropMapping.map((list) => {
            cropList.push(list.cropsDetails.cropName);
          });
          this.getFpoPriceData(cropList);
        });
      });
    });
  };

  getStateOption = (variety = "") => {
    let { getStateByPrice } = this.props;
    let { commodity } = this.state;
    let body =
      variety == ""
        ? {
            commodityId: commodity,
          }
        : {
            variety,
            commodityId: commodity,
          };
    this.setState(
      {
        apmc: "",
        state: "",
        stateOptions: [],
        apmcOptions: [],
      },
      () => {
        getStateByPrice(body).then((data) => {
          let StateData = [];
          data.map((list) => {
            StateData.push({ value: list.state_name, label: list.state_name });
          });
          this.setState({
            stateOptions: StateData,
          });
        });
      }
    );
  };

  handleInput = ({ target: { name, value } }) => {
    this.setState(
      {
        [name]: value,
      },
      () => {
        if (name === "commodity") {
          this.setState(
            {
              varietyOptions: [],
            },
            () => {
              this.getVariety(value);
            }
          );
        }
        if (name === "variety") {
          this.getStateOption(value);
        }
        if (name === "state") {
          this._getMandiList(value);
        }
        let { commodity, apmc, state } = this.state;
        if (commodity !== "" && apmc !== "" && state !== "") {
          this.getGraphData(commodity, apmc, state);
        }
      }
    );
  };

  getGraphData = (commodity, apmc, state) => {
    let { oneMandiDiffCommodity } = this.props;
    let {
      options,
      stateOptions,
      apmcOptions,
      varietyOptions,
      priceData,
      isOpen,
      variety,
      commodityOptions,
    } = this.state;
    let body =
      variety == ""
        ? {
            variety,
            commodity,
            apmc,
          }
        : {
            commodity,
            apmc,
          };
    oneMandiDiffCommodity(body).then((data) => {
      let item = commodityOptions.filter((list) => {
        return list.value == commodity;
      });
      let priceDetail = {
        commodity: item[0].label,
        variety: variety,
        state: state,
        apmc: apmc,
        price: data?.latestPrice,
        stateOptions: stateOptions,
        varietyOptions: varietyOptions,
        apmcOptions: apmcOptions,
        date: data?.latestDate,
      };
      priceData.push(priceDetail);
      this.setState({
        priceData: priceData,
        isOpen: !isOpen,
        stateOptions: [],
        apmcOptions: [],
        varietyOptions: [],
        state: "",
        variety: "",
        commodity: "",
        apmc: "",
      });
    });
  };

  getVariety = () => {
    let { getCommodityVariety } = this.props;
    let { commodity } = this.state;
    let commodityId = commodity;
    let body = {
      commodityId,
    };
    getCommodityVariety(body).then((variety) => {
      if (variety.length !== 0) {
        let varietyData = [];
        variety.map((list) => {
          varietyData.push({ value: list.variety, label: list.variety });
        });
        this.setState({
          varietyOptions: varietyData,
          variety: "",
          state: "",
          apmc: "",
        });
      } else {
        this.getStateOption();
      }
    });
  };

  //get mandi list
  _getMandiList = (state) => {
    let { mandiList, getMandiByPriceAndCommodity } = this.props;
    let { commodity, variety } = this.state;
    let commodityId = commodity;
    let body =
      variety == ""
        ? {
            state,
            commodityId,
          }
        : {
            variety,
            state,
            commodityId,
          };
    this.setState(
      {
        apmc: "",
        apmcOptions: [],
      },
      () => {
        getMandiByPriceAndCommodity(body).then((data) => {
          let apmcLists = [];
          data.map((list) => {
            apmcLists.push({ label: list.mandiName, value: list.mandiName });
          });
          this.setState({ apmcOptions: apmcLists });
        });
      }
    );
  };

  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  handleDropDown = async (event, cropData, index) => {
    const { value, name } = event.target;
    const { priceData, commodityOptions } = this.state;
    const {
      getStateByPrice,
      getMandiByPriceAndCommodity,
      oneMandiDiffCommodity,
    } = this.props;

    let commodityId = commodityOptions.filter((id) => {
      return id.label === cropData?.commodity;
    });

    if (name == "variety") {
      let priceDetail = {
        commodity: cropData?.commodity,
        variety: value,
        state: "",
        apmc: "",
        price: "",
        stateOptions: [],
        varietyOptions: cropData?.varietyOptions,
        apmcOptions: [],
        date: "",
      };
      await getStateByPrice({
        variety: value,
        commodityId: commodityId[0]?.value,
      }).then((state) => {
        let stateData = [];
        state.map((list) => {
          stateData.push({
            value: list.state_name,
            label: list.state_name,
          });
        });
        priceDetail.stateOptions = stateData;
        priceData[index] = priceDetail;
        this.setState({
          priceData: priceData,
        });
      });
    } else if (name == "state") {
      let priceDetail = {
        commodity: cropData?.commodity,
        variety: cropData?.variety,
        state: value,
        apmc: "",
        price: "",
        stateOptions: cropData?.stateOptions,
        varietyOptions: cropData?.varietyOptions,
        apmcOptions: [],
        date: "",
      };
      let body =
        priceDetail.variety == ""
          ? {
              state: value,
              commodityId: commodityId[0]?.value,
            }
          : {
              state: value,
              commodityId: commodityId[0]?.value,
              variety: priceDetail.variety,
            };
      await getMandiByPriceAndCommodity(body).then((mandi) => {
        let apmcLists = [];
        mandi.map((list) => {
          apmcLists.push({ label: list.mandiName, value: list.mandiName });
        });
        priceDetail.apmcOptions = apmcLists;
        priceData[index] = priceDetail;
        this.setState({
          priceData: priceData,
        });
      });
    } else if (name == "apmc") {
      let priceDetail = {
        commodity: cropData?.commodity,
        variety: cropData?.variety,
        state: cropData?.state,
        apmc: value,
        price: "",
        stateOptions: cropData?.stateOptions,
        varietyOptions: cropData?.varietyOptions,
        apmcOptions: cropData?.apmcOptions,
        date: "",
      };
      let body =
        cropData?.variety == ""
          ? {
              commodity: commodityId[0]?.value,
              apmc: value,
            }
          : {
              variety: priceDetail?.variety,
              commodity: commodityId[0]?.value,
              apmc: value,
            };
      oneMandiDiffCommodity(body).then((data) => {
        priceDetail.price = data?.averagePrice;
        priceDetail.date =
          data?.pricingDetails[data?.pricingDetails.length - 1].date;
        priceData[index] = priceDetail;
        this.setState({
          priceData: priceData,
        });
      });
    }
  };

  render() {
    let {
      commodityOptions,
      options,
      commodity,
      state,
      apmc,
      stateOptions,
      apmcOptions,
      varietyOptions,
      isLoading,
      variety,
      priceData,
      isOpen,
    } = this.state;
    return (
      <div className="d-flex flex-column p-4 background-price pricing-area">
        <div className="primary-color-dark mb-2 d-flex justify-content-between align-items-center">
          <div className="price-header">Market Price</div>
          <div
            className="price-header-sub cursor-pointer"
            onClick={() => window.open("/fpo/pricing", "_blank")}
          >
            View details
          </div>
        </div>
        <div className="scroll-x">
          <table className="price-data-table">
            <thead>
              <tr>
                <th>Commodity</th>
                <th>Variety</th>
                <th>State</th>
                <th>APMC</th>
                <th>Latest Price</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              {priceData.length !== 0 ? (
                priceData.map((list, index) => {
                  return (
                    <tr key={index}>
                      <td>{list.commodity}</td>
                      <td>
                        <select
                          value={list.variety}
                          name="variety"
                          onChange={(e) => this.handleDropDown(e, list, index)}
                        >
                          <option value="" disabled>
                            Select Variety
                          </option>
                          {list.varietyOptions.map((state, index) => {
                            return (
                              <option key={index} value={state.value}>
                                {state.label}
                              </option>
                            );
                          })}
                        </select>
                      </td>
                      <td>
                        <select
                          value={list.state}
                          name="state"
                          onChange={(e) => this.handleDropDown(e, list, index)}
                          disabled={list.variety == ""}
                        >
                          <option value="" disabled>
                            Select State
                          </option>
                          {list.stateOptions.map((state, index) => {
                            return (
                              <option key={index} value={state.value}>
                                {state.label}
                              </option>
                            );
                          })}
                        </select>
                      </td>
                      <td>
                        <select
                          value={list.apmc}
                          name="apmc"
                          onChange={(e) => this.handleDropDown(e, list, index)}
                          disabled={list.variety == "" || list.state == ""}
                        >
                          <option value="" disabled>
                            Select Mandi
                          </option>
                          {list.apmcOptions.map((state, index) => {
                            return (
                              <option key={index} value={state.value}>
                                {state.label}
                              </option>
                            );
                          })}
                        </select>
                      </td>
                      <td>
                        {list.variety && list.state && list.apmc && list.price
                          ? `₹ ${list.price}`
                          : "--"}
                      </td>
                      <td>
                        {list.date !== ""
                          ? moment(list.date).format("DD-MM-YYYY")
                          : "--"}
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan="6" align="center">
                    {isLoading ? "Loading..." : "No Data Found"}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className="add-price-data">
          <button onClick={() => this.toggle()}>Add More</button>
        </div>
        <Modal isOpen={isOpen} toggle={this.toggle}>
          <ModalBody>
            <div className="price-data-popup">
              <i className="fas fa-times" onClick={() => this.toggle()}></i>
              <div className="row">
                <div className="col-md-12 mt-0">
                  <label>Commodity</label>
                  <NormalSelect
                    isFullWidthwithoutLabel={true}
                    placeholder="Select Commodity"
                    value={commodity}
                    arrow={true}
                    name="commodity"
                    options={commodityOptions}
                    handleChange={this.handleInput}
                  />
                </div>
                {varietyOptions.length !== 0 && (
                  <div className="col-md-12">
                    <label>Variety</label>
                    <NormalSelect
                      isFullWidthwithoutLabel={true}
                      placeholder="Select Variety"
                      value={variety}
                      arrow={true}
                      disabled={commodity === ""}
                      name="variety"
                      options={varietyOptions}
                      handleChange={this.handleInput}
                    />
                  </div>
                )}
                <div className="col-md-12">
                  <label>State</label>
                  <NormalSelect
                    isFullWidthwithoutLabel={true}
                    placeholder="Select State"
                    value={state}
                    arrow={true}
                    disabled={
                      commodity === "" ||
                      (variety === "" && varietyOptions.length !== 0)
                    }
                    name="state"
                    options={stateOptions}
                    handleChange={this.handleInput}
                  />
                </div>
                <div className="col-md-12">
                  <label>Mandi</label>
                  <NormalSelect
                    isFullWidthwithoutLabel={true}
                    placeholder="Select Mandi"
                    value={apmc}
                    arrow={true}
                    disabled={
                      commodity === "" ||
                      state == "" ||
                      (variety === "" && varietyOptions.length !== 0)
                    }
                    name="apmc"
                    options={apmcOptions}
                    handleChange={this.handleInput}
                  />
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      commodityList,
      mandiList,
      allState,
      getGraphDataforOneMandi,
      getStateByPrice,
      getMandiByPriceAndCommodity,
      getCommodityVariety,
      getAboutusMemberDetail,
      oneMandiDiffCommodity,
    },
    dispatch
  );
};

export const PriceDetails = connect(
  null,
  mapDispatchToProps
)(PriceDetailsClass);
