import React, { Component } from "react";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { CommonPageLoader } from "component/common";
import "./style.scss";
import { getAllPlan } from "action/KeyFinancialAct";
import { documentCurrentTab } from "action/DocumentAct";
import { getFilePathDownload } from "service/api";
import { history } from "service/helpers";
import { getDescriptionData } from "service/helperFunctions";
import pdf from "assets/svg/KeyFinancials/pdf.svg";
import xlsx from "assets/svg/KeyFinancials/xlsx.png";
import docx from "assets/svg/KeyFinancials/docx.jpg";
import arrow_left from "assets/svg/add_member/arrow_left.svg";
import { getAccessdata } from "service/helperFunctions";
class AllBusinessViewClass extends Component {
  constructor() {
    super();
    this.state = {
      plans: [],
      isLoading: false,
      hover: [],
    };
  }

  componentDidMount() {
    this.setState({ isLoading: true });
    this.props.getAllPlan().then((data) => {
      let tempData = [];
      for (let i = 0; i < data.count; i++) {
        tempData.push(false);
      }
      this.setState({
        plans: data.rows,
        isLoading: false,
        hover: tempData,
      });
    });
  }
  toggleHover = (id) => {
    let hover = this.state.hover;
    hover[id] = !hover[id];
    this.setState({
      hover,
    });
  };
  handleback = () => {
    history.goBack();
    this.props.documentCurrentTab("6");
  };

  getDocumentUrl = (fileName, fileUrl) => {
    let fileType = String(fileName).split(".")[1];
    switch (fileType) {
      case "pdf":
        return pdf;
      case "xlsx":
        return xlsx;
      case "jpg":
        return fileUrl;
      case "png":
        return fileUrl;
      case "jpeg":
        return fileUrl;
      case "docx":
        return docx;
      default:
        break;
    }
  };

  render() {
    let { plans, isLoading, hover } = this.state;
    let { accessData } = this.props;
    let permissionData = getAccessdata(accessData, "keyFinancials");

    let { download = false, edit = false } = permissionData;
    return (
      <section>
        <div className="d-flex container-sa my-3">
          <div
            onClick={() => {
              this.handleback();
            }}
            className="d-flex cursor-pointer"
          >
            <img className="cursor-pointer" src={arrow_left} alt="back" />
            <p className="ml-3 text-black font-weight-bold fs-18 mb-0">
              {"Business plan"}
            </p>
          </div>
        </div>
        {isLoading ? (
          <>
            {/* Loader */}
            <CommonPageLoader />
          </>
        ) : (
          <div className="all-business-plan-list bg-blue-f9 py-5">
            <div className="container bg-white py-4">
              <div className="row">
                {plans.map((list, index) => (
                  <div className="col-3">
                    <div className="business-plan-card mb-4 h-100">
                      <div className="mb-3 d-flex justify-content-center align-items-center">
                        {download ? (
                          <a
                            href={getFilePathDownload + list.documentUrl}
                            download
                            className="text-decoration-none h-50 w-50 d-flex justify-content-center align-items-center"
                          >
                            <img
                              className="w-50 h-50"
                              src={
                                // list.documentName.includes(".pdf")
                                //   ? pdf
                                //   : list.documentUrl
                                this.getDocumentUrl(
                                  list.documentName,
                                  list.documentUrl
                                )
                              }
                              alt="pdf"
                            />
                          </a>
                        ) : (
                          <img
                            className="w-50 h-50"
                            src={
                              this.getDocumentUrl(
                                list.documentName,
                                list.documentUrl
                              )
                              // list.documentName.includes(".pdf")
                              //   ? pdf
                              //   : list.documentUrl
                            }
                            alt="pdf"
                          />
                        )}
                      </div>
                      <div>
                        <p className="mb-1 text-center text-black-50 font-weight-bold fs-16">
                          {getDescriptionData(list.documentName, 20)}
                        </p>
                      </div>
                      {edit ? (
                        <div
                          className="fs-18 text-center text-black-50 line-height-33 font-weight-bold cursor-pointer position-relative d-flex justify-content-center"
                          onMouseEnter={() => {
                            this.toggleHover(index);
                          }}
                          onMouseLeave={() => {
                            this.toggleHover(index);
                          }}
                        >
                          <p
                            className={`position-absolute document-name mb-0 ${
                              hover[index] === false ? "show" : "hide"
                            }`}
                          >
                            {list.fymYear}
                          </p>

                          <p
                            className={`icon-edit position-absolute document-edit fs-22 mb-0 ${
                              hover[index] === false ? "hide" : "show"
                            }`}
                            onClick={() => {
                              history.push(
                                `/fpo/edit-plan/${list.businessDpId}`
                              );
                            }}
                          ></p>
                        </div>
                      ) : (
                        <div className="fs-18 text-center text-black-50 line-height-33 font-weight-bold position-relative d-flex justify-content-center">
                          <p
                            className={`position-absolute document-name mb-0 show`}
                          >
                            {list.fymYear}
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  accessData: state.home.accessData,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getAllPlan,
      documentCurrentTab,
    },
    dispatch
  );
};
let component = AllBusinessViewClass;

export const AllBusinessView = connect(
  mapStateToProps,
  mapDispatchToProps
)(component);
