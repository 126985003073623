import React, { Component } from "react";
import "./checkbox.scss";
export class NormalCheckbox extends Component {
  render() {
    let {
      className = "custom-checkbox",
      label = "",
      value = "",
      name = "",
      onChange,
      checked,
      disable = false,
      inputclassName = "",
    } = this.props;
    return (
      <label className={className}>
        <input
          className={inputclassName}
          type="checkbox"
          name={name}
          value={value}
          checked={checked}
          disabled={disable}
          onChange={({ target: { name, checked: Checked } }) => {
            onChange && onChange({ target: { name, value: Checked } });
          }}
        />
        <span className="checkbox-tick">
          <i className="icon-tick-transparent"></i>
        </span>
        {label ? <span className="label-txt">{label}</span> : ""}
      </label>
    );
  }
}
