import React from 'react';

import './OurBusinessActivites.scss';

export const OurBusinessActivitesCard = ({ img, number, title }) => {
   return (
      <>
         <div className='business-activites-card'>
            <div className='img-thumb-about'>
               <img src={img} alt='img-thumb' />
            </div>
            <p>{title}</p>
         </div>
      </>
   );
};
