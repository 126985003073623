import React from "react";
import PersonalInfo from "./PersonalInfo";
import { history } from "service/helpers/index";
import "./viewmember.scss";
const arrow_left = require("../../../assets/svg/add_member/arrow_left.svg");

const ViewFIGMember = () => {
  return (
    <div className="view-fig-member">
      <div className="d-flex my-3 container-sa px-0">
        <div onClick={() => history.goBack()} className="d-flex">
          <img className="cursor-pointer" src={arrow_left} alt="back" />
          <p className="fs-18 font-weight-bold font-Gilroy text-black mb-0 cursor-pointer ml-3">
            {"Member detail page"}
          </p>
        </div>
      </div>
      <div className="bg-area">
        <div className="container-sa">
          <PersonalInfo />
          {/* <div className="member-assets">
            <div className="row">
              {memberfarminfo ? (
                <div className="col-md-4">
                  <Farm memebrFarmInfo={memberfarminfo} />
                </div>
              ) : (
                ""
              )}
              {memberLiveStockInfo.length !== 0 ? (
                <div className="col-md-4 mt-md-0 mt-4">
                  <LiveStock liveStock={memberDetail.memberLiveStockInfo} />
                </div>
              ) : (
                ""
              )}
              <div className="col-md-4 mt-md-0 mt-4">
                {memberBankAccount.length !== 0 ? (
                  <Bank memberBankAccount={memberDetail.memberBankAccount[0]} />
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          {memberasset.length !== 0 ? (
            <MemberAssets memberAsset={memberasset} />
          ) : (
            ""
          )} */}
        </div>
      </div>
      <div className="view-members"></div>
    </div>
  );
};

export default ViewFIGMember;
