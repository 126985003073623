import React, { Component } from 'react';
import { } from "action/Acadamy"
import { bindActionCreators } from 'redux';
import arrow_left from 'assets/svg/add_member/arrow_left.svg';
import { connect } from 'react-redux';
import { Pagination } from 'component/Faq/FaqView/pagination';
import { VideoCard } from "./VideoCard"
import { history } from "service/helpers";
import { getAllTrendingVideosList, deletefavouriteVideo, addfavouriteVideo } from "action/Acadamy"
import { CommonPageLoader } from "component/common"
import "./VideoCard.scss"

export class ViewAllTrendingVideosClass extends Component {
    state = {
        videoList: [],
        pageMeta: {},
        isLoading: false,
    }

    //lifecycles
    componentDidMount() {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
        this._getAllTrendingVideosList(1)
    }

    //get all video list
    _getAllTrendingVideosList = (page) => {
        let { getAllTrendingVideosList } = this.props
        this.setState({ isLoading: true })
        getAllTrendingVideosList({ page }).then((data) => {
            let videoLists = []
            data.results.map((list) => {
                list.isFavouriteAdding = false
                videoLists.push(list)
            })
            this.setState({
                videoList: videoLists,
                pageMeta: data.pageMeta,
                isLoading: false
            })
        })
    }

    //handle page change
    handlePageChange = (page) => {
        this._getAllTrendingVideosList(page)
    }

    //handle add favorites
    handleAddFavourities = (trendingId, currentIndex) => {

        let { videoList } = this.state

        let body = {
            "trendingvideoId": trendingId
        }

        let { addfavouriteVideo } = this.props

        videoList[currentIndex].isFavouriteAdding = true
        this.setState({ videoList })

        addfavouriteVideo(body).then(() => {
            videoList[currentIndex].isFavourite = true
            videoList[currentIndex].isFavouriteAdding = false
            this.setState({ videoList })
        })

    }

    //hanlde remove favourites
    handledeleteFavourities = (trendingId, currentIndex) => {

        let { videoList } = this.state
        let body = {
            "trendingvideoId": trendingId
        }

        let { deletefavouriteVideo } = this.props

        videoList[currentIndex].isFavouriteAdding = true
        this.setState({ videoList })

        deletefavouriteVideo(body).then(() => {
            videoList[currentIndex].isFavourite = false
            videoList[currentIndex].isFavouriteAdding = false
            this.setState({ videoList })
        })

    }

    render() {
        let { videoList, pageMeta, isLoading } = this.state
        return (
            <>
                <div className="container-sa">
                    <div className='d-flex py-3 w-100'>
                        <div className='d-flex' onClick={() => {
                            history.push('/academy/home');
                        }} >
                            <img
                                className='cursor-pointer'
                                src={arrow_left}
                                alt='back'
                            />
                            <p className='ml-3 text-black fs-18 line-height-25 font-weight-bold mb-0 cursor-pointer'>
                                {'Informative videos'}
                            </p>
                        </div>
                    </div>
                </div>
                <section className="trending-view-all">
                    <div className="container">
                        {!isLoading ?
                            <div className="row py-5">
                                <div className="col-12">
                                    <div className="row">
                                        {videoList.map((list, index) => (
                                            <div className="col-4 mb-3" key={index}>
                                                <VideoCard
                                                    videoDetails={list}
                                                    handleAddFavourities={this.handleAddFavourities}
                                                    handledeleteFavourities={this.handledeleteFavourities}
                                                    currentIndex={index}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <Pagination
                                    handleChange={this.handlePageChange}
                                    pageMeta={pageMeta}
                                />
                            </div>
                            :
                            <>
                                {/* loading */}
                                <CommonPageLoader />
                            </>
                        }
                    </div>
                </section>
            </>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        getAllTrendingVideosList,
        deletefavouriteVideo,
        addfavouriteVideo
    }, dispatch)
}

let component = ViewAllTrendingVideosClass;

export const ViewAllTrendingVideos = connect(null, mapDispatchToProps)(component)
