import React, { Fragment, useState, useEffect } from "react";
import Joyride from "react-joyride";
import { useRecorder } from "hooks/useRecorder";
import { Toast } from "service/toast";
import CheckBox from "component/common/CheckBox";
import { connect, useDispatch } from "react-redux";
import {
  audioMessage,
  getRoboCallById,
  sendRoboCallAudio,
  sendToolTipById,
  getToolTipById,
} from "action/RoboCall";
import { fileUpload } from "action/MemberAct";
import { CommonPageLoader } from "component/common";
import "./VoiceRecorder.scss";
import { bindActionCreators } from "redux";

const VoiceRecorder = ({
  onNext,
  showBtn,
  showCallHistory,
  sendToolTipById,
  getToolTipById,
}) => {
  const dispatch = useDispatch();

  const { startRecording, stopRecording, isRecording, isBlocked } =
    useRecorder();

  let [mainMsg, setMainMsg] = useState({ url: "", isRecording: false });

  let [alertName, setAlertName] = useState("");

  let [alertError, setAlertError] = useState(false);

  let [isLoading, setIsLoading] = useState(true);

  let [time, setTime] = useState({
    minutes: 0,
    seconds: 0,
  });

  let [collect, setCollect] = useState(false);

  let [audioResponse, setAudioRespose] = useState([]);

  let [timer, setTimer] = useState(false);

  let [isUploading, setIsUploading] = useState(false);

  let [, setState] = useState();

  const [run, setRun] = useState(false);

  const [isSkip, setIsSkip] = useState(false);

  const withCallHistory = [
    {
      target: ".call-history-button",
      content: "View past call records and download them in Excel format",
      disableBeacon: true,
      isFixed: true,
    },
    {
      target: ".alert-field",
      content: "Enter the purpose of the call you wish to launch.",
      disableBeacon: true,
      isFixed: true,
    },
    {
      target: ".main-audio-track",
      content:
        "Record or Upload the audio message here; you can ask your members to press 1 or 2 or 3 for various scenarios as required. Each of those options must have a separate audio message.",
      disableBeacon: true,
      isFixed: true,
    },
    {
      target: ".response-checkbox",
      content:
        "Click this to send additional audio messages along with your main message.",
      disableBeacon: true,
      isFixed: true,
    },
  ];

  const withoutCallHistory = [
    {
      target: ".alert-field",
      content: "Enter the purpose of the call you wish to launch.",
      disableBeacon: true,
      isFixed: true,
    },
    {
      target: ".upload-new",
      content:
        "Record or Upload the audio message here; you can ask your members to press 1 or 2 or 3 for various scenarios as required. Each of those options must have a separate audio message.",
      disableBeacon: true,
      isFixed: true,
    },
    {
      target: ".response-checkbox",
      content:
        "Click this to send additional audio messages along with your main message.",
      disableBeacon: true,
      isFixed: true,
    },
  ];

  useEffect(() => {
    document.addEventListener("stopAudio", getAudioFile);
    return () => {
      document.removeEventListener("stopAudio", getAudioFile);
    };
  }, []);

  useEffect(() => {
    getRoboCall();
  }, []);

  useEffect(() => {
    if (mainMsg?.url === "") {
      setAudioRespose([{ url: "", isRecording: false }]);
      setCollect(false);
    }
  }, [mainMsg]);

  const getRoboCall = async () => {
    dispatch(getRoboCallById())
      .then(({ responseUrls, alertName, audioUrl }) => {
        setAlertName(alertName ? alertName : "");
        setMainMsg({
          ...mainMsg,
          url: audioUrl ? audioUrl : "",
        });
        setCollect(responseUrls && responseUrls.length > 0 ? true : false);
        if (responseUrls && responseUrls.length > 0) {
          let newAudioUrl = audioResponse;
          responseUrls.map((list) => {
            newAudioUrl.push({ url: list, isRecording: false });
          });
          setAudioRespose(newAudioUrl);
        } else {
          let newAudioUrl = audioResponse;
          newAudioUrl.push({ url: "", isRecording: false });
          setAudioRespose(newAudioUrl);
        }
      })
      .finally(() => {
        setIsLoading(false);
        getToolTipById(JSON.parse(localStorage.getItem("fpoId"))).then(
          (data) => {
            let filterData = [];
            filterData = data?.filter((i) => Number(i?.toolTipId) === 1);
            setTimeout(() => {
              setRun(filterData?.length === 0 ? true : false);
            }, 200);
          }
        );
      });
  };

  const getAudioFile = async (event) => {
    const { name, url, position } = event.detail;
    if (name == "mainMsg") {
      setMainMsg({ ...mainMsg, url: url, isRecording: false });
    } else if (name == "responseMsg") {
      let msg = audioResponse;
      msg[position].url = url;
      msg[position].isRecording = false;
      setAudioRespose(msg);
      setState(Math.random());
    }
  };

  useEffect(() => {
    if (timer) {
      setTimeout(() => {
        updateTime();
      }, 1000);
    }
  }, [timer, time]);

  const updateTime = () => {
    if (timer) {
      if (time.seconds == 60) {
        setTime({ ...time, seconds: 0, minutes: time.minutes + 1 });
      } else {
        setTime({ ...time, seconds: time.seconds + 1 });
      }
    }
  };

  useEffect(() => {
    if (collect) {
      setTime((prevState) => {
        return {
          ...prevState,
          seconds: 0,
        };
      });
    }
  }, [collect]);

  const start = async (event, index = false) => {
    if (!isBlocked) {
      if (event == "mainMsg") {
        setMainMsg({ ...mainMsg, isRecording: true });
        setTimer(true);
        startRecording(event, index);
        setAudioRespose([{ url: "", isRecording: false }]);
      } else if (event == "responseMsg") {
        let msg = audioResponse;
        msg[index].isRecording = true;
        setAudioRespose(msg);
        setTimer(true);
        startRecording(event, index);
      }
    } else {
      Toast({ type: "error", message: "Requested device not found" });
    }
  };

  const stop = async () => {
    stopRecording();
    setTimer(false);
    setTimeout(() => {
      setTime({ ...timer, seconds: 0, minutes: 0 });
    }, 500);
  };

  const onDelete = (name, index) => {
    if (!isRecording) {
      setTime({ ...timer, seconds: 0, minutes: 0 });
      if (name == "mainMsg") {
        // if (audioResponse.length > 0) {
        //   let id = audioResponse.findIndex((list) => {
        //     return list.url === "";
        //   });
        //   if (id !== -1) {
        //     let msg = audioResponse;
        //     msg.splice(id, 1);
        //     setAudioRespose(msg);
        //   }
        // }
        setMainMsg({ url: "", isRecording: false });
        setState(Math.random());
      } else if (name == "responseMsg") {
        let isEmpty = audioResponse.every((list) => {
          return list.url !== "";
        });
        if (!isEmpty) {
          let id = audioResponse.findIndex((list) => {
            return list.url == "";
          });
          let msg = audioResponse;
          msg.splice(id, 1);
          msg[index].url = "";
          setAudioRespose(msg);
          setState(Math.random());
        } else {
          let msg = audioResponse;
          msg[index].url = "";
          setAudioRespose(msg);
          setState(Math.random());
        }
      }
    } else {
      Toast({
        type: "error",
        message: "Cannot delete while recording",
      });
    }
  };

  const addResponse = () => {
    if (!isRecording) {
      const isEmpty = audioResponse.filter((list) => {
        return list.url == "";
      });
      if (isEmpty.length === 0) {
        setTime({ ...timer, seconds: 0, minutes: 0 });
        if (
          audioResponse[audioResponse.length - 1].url !== "" &&
          mainMsg.url !== ""
        ) {
          let msg = audioResponse;
          msg.push({ url: "", isRecording: false });
          setAudioRespose(msg);
          setState(Math.random());
        } else {
          Toast({
            type: "error",
            message:
              mainMsg.url === ""
                ? "Main message is empty"
                : `Response ${audioResponse.length} is empty`,
          });
        }
      } else {
        const index = audioResponse.findIndex((list) => {
          return list.url === isEmpty[0].url;
        });
        Toast({
          type: "error",
          message: `Cannot add new when response ${index + 1} is empty`,
        });
      }
    } else {
      Toast({
        type: "error",
        message: "Cannot add new while recording",
      });
    }
  };

  const handleCheckBox = (event) => {
    const { value } = event.target;
    setCollect(value);
  };

  const handleChange = (event) => {
    const { value } = event.target;
    setAlertName(value);
  };

  const onSumit = async () => {
    if (!collect) {
      if (mainMsg.url !== "" && alertName !== "") {
        let body = { alertName, audioUrl: mainMsg.url, responseUrls: [] };
        dispatch(sendRoboCallAudio(body)).then(() => {
          dispatch(audioMessage(body));
          onNext();
        });
      } else {
        if (alertName == "") {
          setAlertError(true);
        } else if (mainMsg.url == "") {
          Toast({
            type: "error",
            message: "Main message is empty",
          });
        }
      }
    } else {
      let checkAudioFile = audioResponse.every((data) => {
        return data.url !== "";
      });
      if (!checkAudioFile) {
        let index = audioResponse.findIndex((data) => {
          return data.url === "";
        });
        Toast({
          type: "error",
          message: `Response ${index + 1} is empty`,
        });
      } else if (alertName == "") {
        setAlertError(true);
      } else if (mainMsg.url === "") {
        Toast({ type: "error", message: "Main message is empty" });
      } else {
        let responseUrls = [];
        await audioResponse.map((list) => {
          responseUrls.push(list.url);
        });
        let body = { alertName, audioUrl: mainMsg.url, responseUrls };
        dispatch(sendRoboCallAudio(body)).then(() => {
          dispatch(audioMessage(body));
          onNext();
        });
      }
    }
  };

  const handlePlay = async (event) => {
    event.persist();
    const audio = document.querySelectorAll("audio");
    const { id } = event.target;
    await audio.forEach((list) => {
      if (list.id !== id) {
        list.currentTime = 0;
        list.pause();
      }
    });
    document.getElementById(id).play();
  };

  const clearAudio = async (audioIndex) => {
    let msg = audioResponse;
    msg.splice(audioIndex, 1);
    setAudioRespose(msg);
    setState(Math.random());
    const audio = document.querySelectorAll("audio");
    await audio.forEach((list) => {
      list.load();
    });
  };

  const handleAudioUpload = (event, name, index) => {
    event.persist();
    setIsUploading(true);
    let {
      target: { files },
    } = event;
    let type = String(files[0].type).split("/")[1];
    if (type === "mp3" || type === "mpeg") {
      let formData = new FormData();
      formData.append("file", files[0]);
      dispatch(fileUpload(formData))
        .then((response) => {
          let data =
            name === "mainMsg"
              ? {
                  url: response[0].fileUrl,
                  name,
                }
              : {
                  url: response[0].fileUrl,
                  name,
                  position: index,
                };
          getAudioFile({ detail: data });
          if (name === "mainMsg") {
            setAudioRespose([{ url: "", isRecording: false }]);
          }
          setIsUploading(false);
        })
        .catch(() => {
          setIsUploading(false);
        });
    } else {
      setIsUploading(false);
      Toast({ type: "error", message: "Please upload the file in mp3 format" });
    }
  };

  // const callback = (data) => {
  //   console.log("data", data);
  //   if (data.action === "close" && data.type === "step:after") {
  //     // This explicitly stops the tour (otherwise it displays a "beacon" to resume the tour)
  //   }
  // };

  const callback = (data) => {
    if (data?.action === "skip") {
      setIsSkip(true);
    }
    if (!isSkip) {
      if (data?.action === "reset") {
        let body = { key: "1" };
        sendToolTipById(JSON.parse(localStorage.getItem("fpoId")), body);
      }
    }
  };

  return isLoading ? (
    <CommonPageLoader />
  ) : (
    <Fragment>
      <Joyride
        // steps={audioResponse?.[0]?.url === "" ? rideOne : rideTwo}
        steps={showBtn ? withCallHistory : withoutCallHistory}
        run={run}
        callback={callback}
        continuous
        hideBackButton
        hideCloseButton
        scrollToFirstStep
        showProgress
        showSkipButton
        // callback={callback}
        styles={{
          options: {
            zIndex: 10000,
          },
          buttonNext: {
            color: "#FFFFFF",
            backgroundColor: "#0a8c5b",
          },
        }}
      />
      <div className="robocall-title">
        <b>Voice Message</b>
        {showBtn && (
          <button
            className="call-history-button"
            onClick={() => showCallHistory()}
          >
            Call History
          </button>
        )}
      </div>
      <div className="alert-field">
        <label>
          Alert Name <span>*</span>
        </label>
        <input
          placeholder="Enter here"
          name="alert"
          type="text"
          value={alertName}
          onChange={handleChange}
        />
        {alertError && alertName == "" && (
          <span className="alert-error">Please enter alert name</span>
        )}
      </div>
      <div className="main-msg-title">
        <b>Main Message</b>
      </div>
      {mainMsg.url === "" ? (
        <div className="d-flex align-items-center">
          <div className="audio-card upload-new">
            <div className="play-audio">
              <div>
                <i className="far fa-play-circle"></i>
              </div>
              <div className="audio-timer">
                <span>
                  00:
                  {time.minutes.toLocaleString("en-US", {
                    minimumIntegerDigits: 2,
                  })}
                  :
                  {time.seconds.toLocaleString("en-US", {
                    minimumIntegerDigits: 2,
                  })}
                </span>
              </div>
            </div>
            <div className="mic-btn">
              {isRecording && <canvas id="audio-wave"></canvas>}
              <div>
                {mainMsg.isRecording ? (
                  <i
                    className="far fa-stop-circle"
                    onClick={() => stop()}
                    style={{
                      color: mainMsg.isRecording ? "#FF1E1E" : "#DADADA",
                    }}
                  ></i>
                ) : (
                  !isUploading && (
                    <div
                      className="d-flex align-items-center cursor-pointer"
                      style={{ color: "#189C5C", gap: "5px" }}
                      onClick={() => start("mainMsg")}
                    >
                      <b>Record</b>
                      <i className="fas fa-microphone"></i>
                    </div>
                  )
                )}
              </div>
            </div>
          </div>
          <div>
            <input
              type="file"
              id="mainMsg-input"
              onChange={(e) => handleAudioUpload(e, "mainMsg", null)}
              accept="audio/mp3"
              disabled={isUploading ? true : false}
              hidden={true}
            />
            {!isRecording && (
              <label htmlFor="mainMsg-input" className="audio_upload_btn">
                {isUploading ? "Uploading..." : "Upload"}
              </label>
            )}
          </div>
        </div>
      ) : (
        <div className="main-audio-track">
          <audio controls onPlay={handlePlay} id="mainMsg">
            <source src={mainMsg.url}></source>
          </audio>
          <div className="trash">
            <i onClick={() => onDelete("mainMsg")} className="fas fa-trash"></i>
          </div>
        </div>
      )}
      <div className="response-checkbox my-3">
        <CheckBox
          label="Collect Response Yes/No"
          hover={false}
          isChecked={collect}
          onChange={handleCheckBox}
          disabled={isRecording}
        />
      </div>
      {collect && (
        <div className="response-audio">
          {audioResponse?.map((list, index) => {
            return (
              <div className="mb-4" key={index}>
                <div className="main-msg-title">
                  <b>Press {index + 1} Response</b>
                </div>
                {!list?.url ? (
                  <div className="d-flex align-items-center">
                    <div className="audio-card upload-new">
                      <div className="play-audio">
                        <div>
                          <i className="far fa-play-circle"></i>
                        </div>

                        <div className="audio-timer">
                          <span>
                            00:
                            {time.minutes.toLocaleString("en-US", {
                              minimumIntegerDigits: 2,
                            })}
                            :
                            {time.seconds.toLocaleString("en-US", {
                              minimumIntegerDigits: 2,
                            })}
                          </span>
                        </div>
                      </div>
                      <div className="mic-btn">
                        {isRecording && <canvas id="audio-wave"></canvas>}
                        <div>
                          {list?.isRecording ? (
                            <i
                              className="far fa-stop-circle"
                              onClick={() => stop()}
                              style={{
                                color: list?.isRecording
                                  ? "#FF1E1E"
                                  : "#DADADA",
                              }}
                            ></i>
                          ) : (
                            !isUploading && (
                              <div
                                className="d-flex align-items-center cursor-pointer"
                                style={{ color: "#189C5C", gap: "5px" }}
                                onClick={() => start("responseMsg", index)}
                              >
                                <b>Record</b>
                                <i
                                  className="fas fa-microphone"
                                  style={{ color: "#189C5C" }}
                                ></i>
                              </div>
                            )
                          )}
                        </div>
                      </div>
                    </div>
                    <input
                      type="file"
                      id={`audio-input-${index}`}
                      onChange={(e) =>
                        handleAudioUpload(e, "responseMsg", index)
                      }
                      accept="audio/mp3"
                      disabled={isUploading ? true : false}
                      hidden={true}
                    />
                    {!isRecording && (
                      <label
                        htmlFor={`audio-input-${index}`}
                        className="audio_upload_btn"
                      >
                        {isUploading ? "Uploading..." : "Upload"}
                      </label>
                    )}
                    {index !== 0 && !isRecording && (
                      <button
                        className="clear-audio"
                        onClick={() => clearAudio(index)}
                      >
                        Clear
                      </button>
                    )}
                  </div>
                ) : (
                  <div className="main-audio-track">
                    <audio
                      controls
                      onPlay={handlePlay}
                      id={`response-${index + 1}`}
                    >
                      <source src={list.url}></source>
                    </audio>
                    <div className="trash">
                      <i
                        onClick={() => onDelete("responseMsg", index)}
                        className="fas fa-trash"
                      ></i>
                      {index !== 0 && (
                        <button
                          className="clear_btn"
                          onClick={() => clearAudio(index)}
                        >
                          Clear
                        </button>
                      )}
                    </div>
                  </div>
                )}
              </div>
            );
          })}
          {audioResponse.length < 5 && (
            <button onClick={() => addResponse()} className="add-audio">
              Add More
            </button>
          )}
        </div>
      )}
      <div className="d-flex justify-content-end">
        <button onClick={() => onSumit()} className="next-btn">
          Next
        </button>
      </div>
    </Fragment>
  );
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ sendToolTipById, getToolTipById }, dispatch);
};

export default connect(null, mapDispatchToProps)(VoiceRecorder);
