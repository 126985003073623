import React, { Component } from "react";
import "./header.scss";
import moment from "moment";
import { members_icon, land_icon, farm_icon } from "./icons";
import lightHouse from "assets/images/lighthouse.png";
import BMGF from "assets/images/BMGF-Logo.png";
import FPOnEXT from "assets/images/FPOnEXT-Logo.png";
import fpoBazaar from "assets/images/fpobazaar.png";
import SAM_IPL from "assets/images/sam-ipl.png";
import SBI_Image from "assets/images/sbi_logo.png";
import jwt_decode from "jwt-decode";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from "swiper";
import { formatToINRWithoutSymbol } from "service/helperFunctions";
import { debounceFunction } from "service/debounce";
export class Header extends Component {
  constructor() {
    super();
    SwiperCore.use([Navigation]);
  }
  state = { windowWidth: window.innerWidth };
  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }
  handleResize = () => {
    this.setState({ windowWidth: window.innerWidth });
  };
  render() {
    let { fpoDetails = {}, rmDetails = {}, memberCount } = this.props;

    let {
      membershipBase = 0,
      fpoName = "",
      villagesCovered = 0,
      totalAverage = 0,
      fpolastupdatedtime = {},
    } = fpoDetails;

    let { lastUpdatedTime = "" } =
      fpolastupdatedtime !== null ? fpolastupdatedtime : {};

    let {
      noOfFarmers = 0,
      noOfFpos = 0,
      noOfStates = 0,
      villageslist = 0,
    } = rmDetails !== null ? rmDetails : {};

    let {
      FPOn_EXT_FPO,
      FPO_360,
      BMGF_FPO,
      LHFPO_IND,
      isFederation,
      Sam_IPL,
      SBI,
    } = jwt_decode(localStorage.getItem("AuthToken"));

    const isProgram =
      LHFPO_IND ||
      BMGF_FPO ||
      FPOn_EXT_FPO ||
      FPO_360 ||
      SBI ||
      isFederation === 1;

    const params = {
      navigation: true,
    };

    return (
      <div className="header">
        <div className="container-sa position-relative">
          <div
            className={`${
              this.state.windowWidth <= 570
                ? "banner-area-mobile"
                : "banner-area"
            } d-flex flex-column align-items-center justify-content-center position-relative`}
          >
            {localStorage.getItem("userGroupName") === "samunnati" ? (
              <div
                className={
                  this.state.windowWidth <= 570
                    ? ""
                    : "d-flex align-items-center justify-content-between h-100"
                }
              >
                <div className="row">
                  <div className="col-sm-4 col-12 pl-5 my-auto">
                    <h1 className="mb-3 fs-36 fw-500 word-wrap text-white">
                      Welcome
                    </h1>
                  </div>
                  <div className="col-sm-8 col-12 d-flex justify-content-end my-auto pr-5">
                    <div className="d-flex text-white">
                      <div className="d-flex align-items-start ml-3 ">
                        <img
                          className="mr-2"
                          src={members_icon}
                          alt="members_icon.svg"
                        />
                        <div className="info-back">
                          <h3 className="mb-0 font-weight-bold fs-30">
                            {formatToINRWithoutSymbol(noOfFpos)}
                          </h3>
                          <p className="font-weight-light text-Gilroy fs-18">
                            No. of FPOs
                          </p>
                        </div>
                      </div>
                      <div className="d-flex align-items-start ml-3 ">
                        <img
                          className="mr-2"
                          src={land_icon}
                          alt="village_icon.svg"
                        />
                        <div className="info-back">
                          <h3 className="mb-0 font-weight-bold fs-30">
                            {formatToINRWithoutSymbol(villageslist)}
                          </h3>
                          <p className="font-weight-light text-Gilroy fs-18">
                            {"Villages"}
                          </p>
                        </div>
                      </div>
                      <div className="d-flex align-items-start ml-3 ">
                        <img
                          className="mr-2"
                          src={farm_icon}
                          alt="state_icon.svg"
                        />
                        <div className="info-back">
                          <h3 className="mb-0 font-weight-bold fs-30">
                            {formatToINRWithoutSymbol(noOfStates)}
                          </h3>
                          <p className="font-weight-light text-Gilroy fs-18">
                            States
                          </p>
                        </div>
                      </div>
                      <div className="d-flex align-items-start ml-3 ">
                        <img
                          className="mr-2"
                          src={farm_icon}
                          alt="farmers_icon.svg"
                        />
                        <div className="info-back">
                          <h3 className="mb-0 font-weight-bold fs-30">
                            {formatToINRWithoutSymbol(noOfFarmers)}
                          </h3>
                          <p className="font-weight-light text-Gilroy fs-18">
                            {"Farmers"}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : localStorage.getItem("userGroupName") === "fpo" ? (
              <div
                className={
                  this.state.windowWidth <= 570
                    ? ""
                    : "d-flex align-items-center justify-content-between h-100"
                }
              >
                <div className="row">
                  <div className="col-12 pl-sm-5 pl-2 my-auto">
                    <h1
                      className={`${
                        this.state.windowWidth <= 570 && "word-wrap-mobile"
                      } mb-3 fs-36 fw-600 word-wrap text-white`}
                    >
                      Welcome <span className="fw-500">{fpoName}</span>
                    </h1>
                  </div>
                </div>
                <div className="col-sm-6 col-12 d-md-flex justify-content-end">
                  <div className="my-auto pr-md-5 pr-0 pt-md-0 pt-2 text-white">
                    <div className="row">
                      <div className="col-md-4 col-6 d-flex">
                        <img
                          className="mr-2"
                          src={members_icon}
                          alt="fpo_icon.svg"
                        />
                        <div className="info-back">
                          <h3 className="mb-0 font-weight-bold fs-30">
                            {formatToINRWithoutSymbol(memberCount)}
                          </h3>
                          <p className="font-weight-light text-Gilroy fs-18">
                            Members
                          </p>
                        </div>
                      </div>
                      <div className="col-md-4 col-6 d-flex">
                        <img
                          className="mr-2"
                          src={land_icon}
                          alt="village_icon.svg"
                        />
                        <div className="info-back">
                          <h3 className="mb-0 font-weight-bold fs-30">
                            {formatToINRWithoutSymbol(villagesCovered)}
                          </h3>
                          <p className="font-weight-light text-Gilroy fs-18">
                            {"Villages"}
                          </p>
                        </div>
                      </div>
                      <div className="col-md-4 col-12 d-flex">
                        <img
                          className="mr-2"
                          src={farm_icon}
                          alt="members_icon.svg"
                        />
                        <div className="info-back">
                          <h3 className="mb-0 font-weight-bold fs-30">
                            {formatToINRWithoutSymbol(totalAverage)}
                          </h3>
                          <p className="font-weight-light text-Gilroy fs-18">
                            {"Total acres"}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div
                className={
                  this.state.windowWidth <= 570
                    ? ""
                    : "d-flex align-items-center justify-content-between h-100"
                }
              >
                <div className="row">
                  <div className="col-6 pl-5 my-auto">
                    <h1 className="mb-3 fs-36 fw-600 word-wrap text-white">
                      Welcome <span className="fw-500">{fpoName}</span>
                    </h1>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        {localStorage.getItem("userGroupName") === "fpo" && (
          <div className="pt-5">
            {isProgram ? <h5 className="programs-text">Programs</h5> : null}
            <Swiper
              {...params}
              breakpoints={{
                300: {
                  width: 300,
                  slidesPerView: 1,
                },

                768: {
                  width: 768,
                  slidesPerView: 2,
                },
              }}
              className={
                isFederation === 1 ? "fpo-programs mbm-70" : "fpo-programs"
              }
            >
              {LHFPO_IND && (
                <SwiperSlide>
                  <div className="light-house">
                    <span>Lighthouse FPO</span>
                  </div>
                </SwiperSlide>
              )}
              {BMGF_FPO && (
                <SwiperSlide>
                  <img
                    alt="bmf_logo"
                    src={BMGF}
                    className="bg-white px-2"
                    data-type="bmgf"
                  />
                </SwiperSlide>
              )}
              {FPOn_EXT_FPO && (
                <SwiperSlide>
                  <img alt="fpoNext_logo" src={FPOnEXT} />
                </SwiperSlide>
              )}
              {Sam_IPL && (
                <SwiperSlide>
                  <img alt="samIPL_logo" src={SAM_IPL} />
                </SwiperSlide>
              )}
              {FPO_360 && <SwiperSlide>""</SwiperSlide>}
              {isFederation === 1 ? (
                <SwiperSlide>
                  <img
                    alt="fpoBazaar_logo"
                    className="bazaar-image"
                    src={fpoBazaar}
                  />
                </SwiperSlide>
              ) : null}
              {SBI && (
                <SwiperSlide>
                  <div className="sbi_container">
                    <img alt="sbi_logo" src={SBI_Image} />
                  </div>
                </SwiperSlide>
              )}
            </Swiper>
          </div>
        )}
      </div>
    );
  }
}
