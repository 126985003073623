import React, { Component } from "react";
import moment from "moment";
import { getDescriptionData } from "service/helperFunctions";
import rightCircle from "assets/images/play-circle.png";
import { history } from "service/helpers";
export class CourseCard extends Component {
  render() {
    const { videoDetails = {} } = this.props;

    let {
      thumbnailFileUrl = "",
      title = "",
      courseId = "",
      releaseDate = "",
    } = videoDetails;

    return (
      <div
        className="course-card-list"
        onClick={() => history.push(`/academy/courses/${courseId}`)}
      >
        <div
          className={`course-thumb info-thumb `}
          style={{
            backgroundImage: "url(" + `${thumbnailFileUrl}` + ")",
            backgroundPosition: "center",
            backgroundSize: "contain",
          }}
        >
          <div className="d-flex justify-content-between h-100 flex-column p-3 course-body">
            <div className="course-content pt-3">
              <h5 className="fs-22 font-weight-bold text-white line-height-31">
                {getDescriptionData(title, 30)}
              </h5>
              <div className="text-white fs-14 line-height-27 fw-500">
                {moment(releaseDate).format("MMM DD, YYYY")}
              </div>
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <div className="play-link w-100 pb-1">
                <img src={rightCircle} alt="rightCircle" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CourseCard;
