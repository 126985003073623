import React, { Component } from "react";
import { NormalInput, NormalButton } from "component/common";
import { Modal, ModalBody } from "reactstrap";
import SimpleReactValidator from "simple-react-validator";
import Select from "react-select";

// Redux Connection
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  addFIGToCRP,
  getCRPById,
  getCRPList,
  getCRPListRm,
  getFIGListforMapping,
} from "action/FigCrpAct";

export class MapCRPPopupClass extends Component {
  state = {
    name: "",
    isButtonClicked: false,
    memberOptions: [],
    memberIds: [],
  };

  //validation
  validator = new SimpleReactValidator({
    element: (message) => (
      <span className="error-message text-danger fs-14">{message}</span>
    ),
    autoForceUpdate: this,
  });

  componentDidUpdate(prevProps) {
    let { isOpen, id } = this.props;
    if (prevProps !== this.props && isOpen && id) {
      this.getCRPdataById();
      this._getFIGListforMapping(id);
    }
  }

  _getFIGListforMapping = (crpid) => {
    let { getFIGListforMappingApi } = this.props;
    getFIGListforMappingApi(crpid).then((data) => {
      if (data) {
        let options = [],
          tempArray = [],
          lastIndex = false;
        let results =
          data.length !== 0 &&
          data.sort((a, b) => (a.name > b.name && 1) || -1);
        results.map((list, index) => {
          let { name, figId, isMapped } = list;
          if (name && name !== "#VALUE!") {
            options.push({ label: name, value: figId });
            // if (isMapped) {
            //   tempArray.push({
            //     label: name,
            //     value: figId,
            //   });
            // }
          }
          if (index + 1 === results.length) {
            lastIndex = true;
          }
        });
        if (lastIndex) {
          this.setState({
            memberOptions: [...options],
            // memberIds: [...tempArray],
          });
        }
      }
    });
  };

  getCRPdataById = () => {
    let { id, getCRPByIdApi } = this.props;
    getCRPByIdApi(id).then((data) => {
      console.log(data);
      if (data) {
        this.setState({
          name: data.name,
          memberIds:
            data?.crpMappings.length > 0
              ? data.crpMappings.map(({ figUsers: { name }, figId }) => {
                  return {
                    label: name,
                    value: figId,
                  };
                })
              : [],
        });
      }
    });
  };

  //handle Change dropdown
  handleChange = (newValue) => {
    let value = [];
    if (!!newValue && newValue.length) {
      newValue.forEach((array) => {
        let obj = {
          value: array.value,
          label: array.label,
        };
        value.push(obj);
      });
    }
    this.setState({ memberIds: [...value] });
  };

  //close popup
  closepopup = () => {
    this._clearData();
    this.validator.hideMessages();
  };

  //clear Data
  _clearData = () => {
    this.setState({
      name: "",
      memberIds: [],
      memberOptions: [],
    });
    this.props.toggle();
  };

  //handle submit
  handleSubmit = () => {
    if (this.validator.allValid()) {
      let { memberIds } = this.state;
      let { id, addFIGToCRPApi } = this.props;
      let payload = { figIds: [], crpId: id };
      let tempArray = [];
      memberIds.map((list) => {
        tempArray.push(list.value);
      });
      if (tempArray.length === memberIds.length) {
        payload.figIds = [...tempArray];
        addFIGToCRPApi(payload).then(() => {
          this.closepopup();
          this.handleListData();
        });
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  handleListData = () => {
    let { getCRPListApi, getCRPListRmApi, fpoId } = this.props;
    let payload = { limit: 10, page: 1, search: "" };
    if (fpoId) {
      getCRPListRmApi(fpoId, payload);
    } else {
      getCRPListApi(payload);
    }
  };

  render() {
    let {
      className = "modal-dialog-centered add-crp-fig",
      isOpen = false,
      id,
    } = this.props;

    let { isButtonClicked, name, memberOptions, memberIds } = this.state;

    const customStyles = {
      dropdownIndicator: (base) => ({
        ...base,
        color: "#000000",
      }),
    };

    return (
      <Modal isOpen={isOpen} className={className} size={"md"}>
        <ModalBody className="p-md-4">
          <div>
            <div className="modal-label mb-3">
              <h5>FIG to CRP mapping</h5>
              <i
                onClick={() => this.closepopup()}
                className="icon-close cursor-pointer"
              />
            </div>
            <div className="row">
              <div className="col-12">
                <NormalInput label="CRP name" value={name} disabled />
              </div>
              <div className="col-12 mt-4 pt-2">
                <div className="row d-flex align-items-center">
                  <div className="col-3">
                    <div className="fs-16 text-black-32 font-weight-bold">
                      FIG's
                    </div>
                  </div>
                  <div className="col-9">
                    <Select
                      isSearchable={true}
                      name="memberIds"
                      options={memberOptions}
                      onChange={this.handleChange}
                      isMulti={true}
                      placeholder="Select"
                      styles={customStyles}
                      value={memberIds}
                    />
                    {this.validator.message("fig's", memberIds, "required")}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mx-auto py-5">
            <NormalButton
              label={id ? "Save" : "Submit"}
              mainbg={true}
              className="px-4 py-2 mx-auto fs-14 line-height-26"
              onClick={() => this.handleSubmit()}
              disabled={isButtonClicked}
            />
          </div>
        </ModalBody>
      </Modal>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getCRPByIdApi: getCRPById,
      getCRPListApi: getCRPList,
      getCRPListRmApi: getCRPListRm,
      getFIGListforMappingApi: getFIGListforMapping,
      addFIGToCRPApi: addFIGToCRP,
    },
    dispatch
  );
};

export const MapCRPPopup = connect(null, mapDispatchToProps)(MapCRPPopupClass);
