import React, { Component } from "react";
import {
  addfavouriteBlog,
  deletefavouriteBlog,
  getReviewListForBlog,
  addReviewForBlog,
  getRelatedBlogs,
} from "action/Acadamy";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import faverate from "assets/images/icon/faverate.svg";
import faverateColor from "assets/images/icon/heart.svg";
import share from "assets/images/icon/share.svg";
import { Pagination } from "component/Faq/FaqView/pagination";
import { CommonPageLoader } from "component/common";
import SimpleReactValidator from "simple-react-validator";
import { getDescriptionData } from "service/helperFunctions";
import moment from "moment";
import { SharePopup } from "../../Acadamy/SharePopup";
import { history } from "service/helpers";
import "./BlogView.scss";

export class BlogViewClass extends Component {
  constructor() {
    super();
    this.state = {
      isFavouriteAdding: false,
      reviewList: [],
      isLoading: false,
      pageMeta: {},
      review: "",
      relatedBlogData: [],
      isSharePopup: false,
    };
    this.validator = new SimpleReactValidator();
  }

  //handle add favourities
  handleAddFavourities = (blogId) => {
    let body = {
      blogId: blogId,
    };

    let { addfavouriteBlog } = this.props;

    this.setState({ isFavouriteAdding: true });

    addfavouriteBlog(body).then(() => {
      this.props.handleChange();
      this.setState({ isFavouriteAdding: false });
    });
  };

  //handle delete favourities
  handledeleteFavourities = (blogId) => {
    let body = {
      blogId: blogId,
    };

    let { deletefavouriteBlog } = this.props;

    this.setState({ isFavouriteAdding: true });

    deletefavouriteBlog(body).then(() => {
      this.props.handleChange();
      this.setState({ isFavouriteAdding: false });
    });
  };

  //lifecycles
  componentDidMount() {
    this._getReviewList(1);
  }

  //get review list
  _getReviewList = (page) => {
    let { getReviewListForBlog, blogDetail, getRelatedBlogs } = this.props;
    let { blogId, categoryId } = blogDetail;
    if (blogId) {
      this.setState({ isLoading: true });
      getReviewListForBlog(blogId, { page }).then((data) => {
        this.setState({
          reviewList: data.results,
          pageMeta: data.pageMeta,
        });
        if (categoryId && page === 1) {
          getRelatedBlogs(blogId, categoryId).then((data) => {
            this.setState({
              relatedBlogData: data,
              isLoading: false,
            });
          });
        }
      });
    }
  };

  //handle input
  handleInput = ({ target: { name, value } }) => {
    this.setState({
      [name]: value,
    });
  };

  //handle page change
  handlePagination = (page) => {
    this.setState({ isLoading: true });
    this._getReviewList(page);
  };

  //handle submit review
  handleSubmit = (blogId) => {
    if (this.validator.allValid()) {
      let { review } = this.state;
      let { addReviewForBlog } = this.props;
      let body = {
        blogId: blogId,
        reviewComment: review,
      };

      addReviewForBlog(body).then(() => {
        this.validator.hideMessages();
        this.setState({ review: "" }, () => {
          this._getReviewList(1);
        });
      });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  //open share popup
  togglePopup = (key) => {
    this.setState((prevState) => ({
      [key]: !prevState[key],
    }));
  };

  render() {
    let { blogDetail = {}, viewCountforBlock = 0 } = this.props;

    let {
      title = "",
      description = "",
      shortDescription = "",
      blogImageFileUrl = "",
      author = "",
      isFavourite = false,
      blogId = "",
      category = {},
      link = "",
      publishDate = "",
    } = blogDetail;

    let { categoryName = "" } = category;

    let {
      isFavouriteAdding,
      pageMeta,
      isLoading,
      reviewList,
      review,
      relatedBlogData,
      isSharePopup,
    } = this.state;
    return (
      <>
        <section className="blog-view-section py-5">
          <div className="container">
            <div className="row">
              <div className="col-8">
                <div className="blog-view-top-header">
                  <p className="text-green-18 line-height-31 fs-18 mb-2">
                    {categoryName}
                  </p>
                  <div className="row">
                    <div className="col-10">
                      <h2 className="fs-32 text-black-1c line-height-42 font-weight-bold mb-1">
                        {" "}
                        {title}{" "}
                      </h2>
                    </div>
                    <div className="col-2 mt-2">
                      <p className="mb-0 fs-14 line-height-24 text-gold-d0 fw-700">
                        {moment(publishDate).format("MMM, DD  YYYY")}
                      </p>
                    </div>
                  </div>
                  <p className="text-black-50 fw-500 fs-16 line-height-27 pt-1">
                    {shortDescription}
                  </p>
                  <div className="d-flex justify-content-between">
                    <div className="name-card color">
                      <div className="pick-letter color">
                        {author.substring(0, 1)}
                      </div>
                      <div>{author}</div>
                    </div>
                    <div className="info-icon-list my-auto">
                      <ul>
                        <li className="fw-600 fs-16 text-green-18">
                          {viewCountforBlock} views
                        </li>
                        {isFavouriteAdding && isFavourite ? (
                          <li>
                            <img src={faverateColor} />
                          </li>
                        ) : isFavouriteAdding && !isFavourite ? (
                          <li>
                            <img src={faverate} />
                          </li>
                        ) : isFavourite ? (
                          <li
                            className="cursor-pointer"
                            onClick={() => this.handledeleteFavourities(blogId)}
                          >
                            <img src={faverateColor} />
                          </li>
                        ) : (
                          <li
                            className="cursor-pointer"
                            onClick={() => this.handleAddFavourities(blogId)}
                          >
                            <img src={faverate} />
                          </li>
                        )}
                        <li
                          className="cursor-pointer"
                          onClick={() => this.togglePopup("isSharePopup")}
                        >
                          <img src={share} />
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="blog-view-detail">
                  <img className="mb-4 blog_img" src={blogImageFileUrl} />
                  <div className="blog_content">
                    <p dangerouslySetInnerHTML={{ __html: description }}></p>
                  </div>
                </div>
                {link !== null ? (
                  <div className="my-3">
                    <p className="fs-16 line-height-27 text-green-18 fw-600">
                      Please go through the link for more information{" "}
                      <a
                        className="text-black-50 fs-14 text-underline"
                        href={
                          link.includes("http" || "https")
                            ? link
                            : `https://${link}`
                        }
                        target="_blank"
                      >
                        {link}
                      </a>
                    </p>
                  </div>
                ) : (
                  ""
                )}
                <div className="add-review">
                  <h4 className="fs-18 font-weight-bold line-height-34 text-black-1c">
                    Add comments
                  </h4>
                  <div className="text-area">
                    <textarea
                      name="review"
                      value={review}
                      id=""
                      cols="30"
                      rows="2"
                      onChange={this.handleInput}
                    ></textarea>
                    <div
                      className="send-area cursor-pointer"
                      onClick={() => {
                        this.handleSubmit(blogId);
                      }}
                    >
                      <i className="icon-send fs-20"></i>
                    </div>
                  </div>
                  {this.validator.message(
                    "review",
                    review,
                    "required|max:200",
                    {
                      className: "text-danger",
                    }
                  )}
                </div>
                <div id="scrollableReview">
                  <div className="">
                    <h5 className="mb-3 font-weight-bold fs-24">Comments</h5>
                  </div>
                  {!isLoading ? (
                    reviewList.length !== 0 ? (
                      reviewList.map((list, index) => {
                        let { userDetails = {}, reviewComment = "" } = list;
                        return (
                          <React.Fragment key={index}>
                            <div className="review-list">
                              <div className="d-flex">
                                <div className="profile-area">
                                  {userDetails &&
                                  userDetails.profileImageUrl !== null ? (
                                    <img
                                      src={userDetails.profileImageUrl}
                                      alt="profileImageUrl"
                                    />
                                  ) : (
                                    <div className="pick-letter color">
                                      {userDetails.userName.substring(0, 1)}
                                    </div>
                                  )}
                                </div>
                                <div className="d-flex flex-column">
                                  <div className="d-flex flex-column justify-content-center">
                                    <h4 className="fs-18 font-weight-bold text-black-1c mb-0">
                                      {userDetails.userName}
                                    </h4>
                                  </div>
                                  <div className="d-flex">
                                    <p className="fs-18 text-black-50 mb-2">
                                      {reviewComment}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {pageMeta.pageCount !== undefined &&
                              pageMeta.pageCount !== 1 && (
                                <Pagination
                                  handleChange={this.handlePagination}
                                  pageMeta={pageMeta}
                                />
                              )}
                          </React.Fragment>
                        );
                      })
                    ) : (
                      <p className="my-3 text-black-50 text-center fs-18">
                        No reviews !!!
                      </p>
                    )
                  ) : (
                    <>
                      {/* Loader */}
                      <CommonPageLoader />
                    </>
                  )}
                </div>
              </div>
              <div className="col-4 pl-0">
                {relatedBlogData.length !== 0 ? (
                  <div className="other-recommendations">
                    <h4 className="fs-20 line-height-29 text-green-18 font-weight-bold mb-3 pb-1">
                      {"Related blogs"}
                    </h4>
                    {relatedBlogData.map((list, index) => {
                      let {
                        shortDescription = "",
                        publishDate = "",
                        blogImageFileUrl = "",
                        blogId = "",
                        blogImageFileName = "",
                        title = "",
                      } = list;
                      return (
                        <div
                          className="recommended-cards"
                          onClick={() =>
                            history.push(`/academy/blog/${blogId}`)
                          }
                        >
                          <div className="row">
                            <div className="col-9 pr-0">
                              <p className="fs-16 line-height-23 text-black-1c fw-600 mb-1">
                                {title !== null
                                  ? getDescriptionData(title, 30)
                                  : "-"}
                              </p>
                              <p className="fs-16 line-height-23 text-black-1c mb-1">
                                {shortDescription !== null
                                  ? getDescriptionData(shortDescription, 50)
                                  : "-"}
                              </p>
                            </div>
                            <div className="col-3 px-0">
                              <div className="img-area">
                                {blogImageFileUrl !== "" &&
                                !blogImageFileName.includes(".pdf") ? (
                                  <img
                                    src={blogImageFileUrl}
                                    alt={blogImageFileName}
                                  />
                                ) : (
                                  <img src={blogImageFileUrl} alt="events" />
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="row px-3 event-infomation my-auto pb-3 pt-1">
                            <p className="fs-14 line-height-24 fw-500 text-gold-d0">
                              {publishDate !== null
                                ? moment(publishDate).format("DD MMM, YYYY")
                                : "-"}
                            </p>
                          </div>
                          {index !== relatedBlogData.length - 1 ? <hr /> : ""}
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          <SharePopup
            isOpen={isSharePopup}
            toggle={() => this.togglePopup("isSharePopup")}
            isBlog={true}
            blogId={blogId}
          />
        </section>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      addfavouriteBlog,
      deletefavouriteBlog,
      getReviewListForBlog,
      addReviewForBlog,
      getRelatedBlogs,
    },
    dispatch
  );
};

let component = BlogViewClass;

export const BlogView = connect(null, mapDispatchToProps)(component);
