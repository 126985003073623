import React, { Component } from "react";
import { Navbar } from "component/common";
import { ViewAllblogs } from "component/Blog";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
export class ViewallBlogClass extends Component {
  //lifecycles
  componentDidMount = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  render() {
    return (
      <>
        <div className="normal-header">{/* <Navbar /> */}</div>
        <ViewAllblogs />
      </>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

let component = ViewallBlogClass;

export const ViewallBlog = connect(null, mapDispatchToProps)(component);
