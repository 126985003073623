import React, { Component } from 'react';
import './IncometaxCard.scss';
import { history } from 'service/helpers';
import { currencyConvert } from 'service/helperFunctions';
import long_arrow from 'assets/svg/price_details/long_arrow.svg';

import moment from 'moment';

const User_Profile_1 = require('assets/svg/Members/User_Profile_1.svg');

export class IncometaxCard extends Component {
   handleRoute = (id, isTds) => {
      let { editAccess } = this.props
      if (editAccess) {
         if (isTds) {
            history.push(`/fpo/edit-incometds/${id}`)
         } else {
            history.push(`/fpo/edit-incometax/${id}`);
         }
      }
   }
   render() {
      let { payload, isTds, index, } = this.props;
      const {
         id,
         consultatName,
         financialYear,
         dueDate,
         dateOfFilling,
         tdsDeducted,
         tdsPaid,
         remarks,
         income,
         incomeTaxPaid,
         incometaxPayable,
         filingStatusFileUrl,
      } = payload;
      return (
         <div key={index}
            className={`col-md-3 col-lg-3 col-6 ${index % 4 === 3 ? 'pr-md-0' : ''} mb-2 mt-3 px-2 ${index % 4 === 0 ? 'pl-md-0' : ''}`}
         >
            <div className='income-card rounded shadow cursor-pointer h-100'>
               <div onClick={() => this.handleRoute(id, isTds)} >
                  <div className='d-flex justify-content-between mb-4'>
                     <div className='d-flex align-items-center'>
                        <div className='mr-2 pr-1'>
                           <img
                              className='rounded-circle income-card-profile'
                              src={filingStatusFileUrl && filingStatusFileUrl.includes('https://') ? filingStatusFileUrl : User_Profile_1}
                              alt='profile'
                           />
                        </div>
                        <div>
                           <div className='income-card-member-name mb-1 gilroy'>
                              {consultatName ? consultatName : '-'}
                           </div>
                           <div className='income-card-subtitle gilroy'>
                              {financialYear}
                           </div>
                        </div>
                     </div>
                  </div>
                  {isTds ? (
                     <>
                        <div className='d-flex justify-content-between mb-4'>
                           <div className='col-sm-6 pl-0'>
                              <div className='line-height-21 font-Gilroy text-black-50 fs-16 mb-3'>
                                 {'Due date'}
                              </div>
                              <div className='line-height-21 font-Gilroy-Bold fs-18 text-black-1c font-weight-bold'>
                                 {dueDate ? moment(dueDate).format('DD MMMM YYYY') : '-'}
                              </div>
                           </div>
                           <div className='col-sm-6 pr-0'>
                              <div className='line-height-21 font-Gilroy text-black-50 fs-16 mb-3'>
                                 {'Date of filing	'}
                              </div>
                              <div className='line-height-21 font-Gilroy-Bold fs-18 text-black-1c font-weight-bold'>
                                 {dateOfFilling ? moment(dateOfFilling).format('DD MMMM YYYY') : ''}
                              </div>
                           </div>
                        </div>
                        <div className='d-flex justify-content-between mb-4'>
                           <div className='col-sm-6 pl-0'>
                              <div className='line-height-21 font-Gilroy text-black-50 fs-16 mb-3'>
                                 {'TDS deducted '}&nbsp;{' ( '}
                                 <span className='icon-rupee'></span> {' )'}
                              </div>
                              <div className='line-height-21 font-weight-bold font-Gilroy-Bold fs-18 text-black-1c'>
                                 {tdsDeducted ? currencyConvert(tdsDeducted) : '-'}
                              </div>
                           </div>
                           <div className='col-sm-6 pr-0'>
                              <div className='line-height-21 font-Gilroy text-black-50 fs-16 mb-3'>
                                 <div>{'TDS paid'}</div> {' ( '}
                                 <span className='icon-rupee'></span> {' )'}
                              </div>
                              <div className='line-height-21 font-weight-bold font-Gilroy-Bold fs-18 text-black-1c'>
                                 {tdsPaid ? currencyConvert(tdsPaid) : '-'}
                              </div>
                           </div>
                        </div>
                     </>
                  ) : (
                        <>
                           <div className='d-flex justify-content-between mb-4'>
                              <div className='col-sm-6 pl-0'>
                                 <div className='line-height-21 font-Gilroy text-black-50 fs-16 mb-3'>
                                    <div>{'Income/Loss'} </div> {' ( '}
                                    <span className='icon-rupee'></span> {' )'}
                                 </div>
                                 <div className='line-height-21 font-weight-bold font-Gilroy-Bold fs-18 text-black-1c'>
                                    {income ? currencyConvert(income) : '-'}
                                 </div>
                              </div>
                              <div className='col-sm-7 pr-0'>
                                 <div className='line-height-21 font-Gilroy text-black-50 fs-16 mb-3'>
                                    <div>{'Income tax paid'}</div> {' ( '}
                                    <span className='icon-rupee'></span> {' )'}
                                 </div>
                                 <div className='line-height-21 font-weight-bold font-Gilroy-Bold fs-18 text-black-1c'>
                                    {incomeTaxPaid ? currencyConvert(incomeTaxPaid) : '-'}
                                 </div>
                              </div>
                           </div>
                           <div className='d-flex justify-content-between mb-4'>
                              <div className='col-sm-12 pl-0'>
                                 <div className='line-height-21 font-Gilroy text-black-50 fs-16 mb-3'>
                                    {'Income tax payable ( '}{' '}
                                    <span className='icon-rupee'></span> {' )'}
                                 </div>
                                 <div className='line-height-21 font-weight-bold font-Gilroy-Bold fs-18 text-black-1c'>
                                    {incometaxPayable ? currencyConvert(incometaxPayable) : '-'}
                                 </div>
                              </div>
                           </div>
                        </>
                     )}

                  <div className='d-flex justify-content-between mb-4 height-60'>
                     <div>
                        <div className='line-height-21 font-Gilroy text-black-50 fs-16 mb-3'>
                           {'Remarks'}
                        </div>
                        <div className='line-height-21 font-weight-bold font-Gilroy-Bold fs-18 text-black-1c'>
                           {remarks ? remarks.length > 50 ? `${remarks.substring(0, 50)}...` : remarks : '-'}
                        </div>
                     </div>
                  </div>
               </div>
               {filingStatusFileUrl ?
                  <div className='row mb-3' onClick={() => window.open(filingStatusFileUrl, '_blank')} >
                     <div className='text-green font-weight-bold fs-16 gilroy col-7'>
                        {'View document'}
                     </div>
                     <div className='col-3'>
                        <img src={long_arrow} alt='arrow.svg' />
                     </div>
                  </div>
                  : null}
            </div>
         </div>
      );
   }
}
